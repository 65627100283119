import clsx from "clsx"
import React from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import { removeBlur } from "./CommonHelpers"

const DatePickerCustom = (props: any) => {
    return (
        < DateRangePicker
            onCallback={(start, end, label) => {
                removeBlur()
                props.onCallback(start, end, label)
            }}
            initialSettings={
                props.initialSettings
            }
        >
            <input readOnly type="text" className={clsx('form-control calendar', props.dateValidation ? props.dateValidation : '')}
                {...(props.dateValue && { value: props.dateValue })}
            />
        </DateRangePicker >
    )
}

export default DatePickerCustom





