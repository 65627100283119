import clsx from 'clsx'
import $ from 'jquery'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  brandGetUrl,
  discountBrand,
  discountProduct,
  productGetUrl,
} from '../../../_eaFruitsDms/apiFunctions/master/apiList'
import {
  promotionalDiscountPostUrl,
  promotionalDiscountUrl,
} from '../../../_eaFruitsDms/apiFunctions/priceDiscount/apiListPrice'
import {
  checkPermissions,
  dateFormat,
  defaultPermission,
  focusVatidateField,
  GLOBAL_DATE_FORMAT,
  globalDateFormate,
  isModalValidation,
  KTSVG,
  removeBlur,
  retrieveFilter,
  saveFilter,
  toAbsoluteUrl,
} from '../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import ValidationHandler from '../../../_eaFruitsDms/helpers/components/validationHandler'
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import Banner from '../../../_eaFruitsDms/layout/components/forms/banner'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../_eaFruitsDms/layout/components/pagination/pagination'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../TenantVariables'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'

const brandoptions = [
  {value: 'all', label: 'All Brands'},
  {value: 'Onja', label: 'Onja'},
]

const productOptions = [
  {value: 'all', label: 'All Products'},
  {value: 'Golden-Banana', label: 'Golden Banana'},
]

type Props = {
  className: string
}
type modalType = {
  discountOn: string
  dropDownValue: object[]
  discount: string
  discountTitle: string
  image: any
  startDateApi: string
  startDateView: string
  endDateApi: string
  endDateView: string
}
const PromotionalDiscount: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const priceHeaderTitle = {
    title: 'Promotional Discount',
    subTitle: 'Price & Discounts',
  }
  let promotionalDiscountFiltered: any = retrieveFilter(filters.wm_promotional_discount)

  const initialState = {
    product: promotionalDiscountFiltered?.product || {value: '', label: 'All Products'},
    brand: promotionalDiscountFiltered?.brand || {value: '', label: 'All Brands'},
    status: promotionalDiscountFiltered?.status || {value: '', label: 'All Status'},
    selected: promotionalDiscountFiltered?.selected || '',
    search: promotionalDiscountFiltered?.search || '',
    currentOffSet: promotionalDiscountFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  const [getList, setGetList] = useState<any>([]) // getdata
  const [getListDropDown, setGetListDropDown] = useState<any>([]) // getdata
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>(promotionalDiscountFiltered?.search || '')
  const [selected, setSelected] = useState<number>(promotionalDiscountFiltered?.selected || 0)
  const [currentOffset, setCurrentOffset] = useState<any>(
    promotionalDiscountFiltered?.currentOffSet || '0'
  )
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('add')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [messageTitle, setMessageTitle] = useState('')
  const [image, setImage] = useState('')
  const [scrollToParent, setScrollToParent] = useState<any>(false)

  const [listDropDown, setListDropdown] = useState({
    brands: [],
    products: [],
  })

  const [listDropDownValue, setListDropdownValue] = useState({
    brands: [],
    products: [],
  }) // getdata

  const defaultSrc = toAbsoluteUrl('/media/icons/img_crop_banner.png')

  const [imageRequire, setImageRequire] = useState('')
  const [imgSizeInvalid, setimgSizeInvalid] = useState('')
  const [cropData, setCropData] = useState(defaultSrc)

  const [discount, setDiscount] = useState<modalType>({
    discountOn: 'BRAND',
    dropDownValue: [],
    discount: '',
    discountTitle: '',
    image: '',
    startDateApi: '',
    startDateView: 'Select',
    endDateApi: '',
    endDateView: '',
  })

  const [validation, setValidation] = useState<any>({
    dropDownValue: '',
    discount: '',
    discountTitle: '',
    image: '',
    imageSize: '',
    startDateView: '',
  })

  const [errorMessage, setErrorMessage] = useState('')

  const [disableFeature, setDisableFeature] = useState(true)

  const [dropDown, setDropdown] = useState({
    brand: [],
    product: [],
  })

  const [status, setStatus] = useState<any>(
    promotionalDiscountFiltered?.status || {value: '', label: 'All Status'}
  )
  const [brand, setBrand] = useState<any>(
    promotionalDiscountFiltered?.brand || {value: '', label: 'All Brands'}
  )
  const [product, setProduct] = useState<any>(
    promotionalDiscountFiltered?.product || {value: '', label: 'All Products'}
  )
  const [brandOffset, setBrandOffset] = useState<any>('')
  const [brandOption, setBrandOption] = useState<any>([])
  const [productOffset, setProductOffset] = useState<any>('')
  const [roles, setRoles] = useState<any>([])
  const [addBrandOffset, setAddBrandOffset] = useState<any>('')
  const [addBrand, setAddBrand] = useState<any>([])
  const [addProductOffset, setAddProductOffset] = useState<any>('')
  const [addProduct, setAddProduct] = useState<any>([])
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchSelect, setSearchSelect] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    promotionalDiscountFiltered?.buttonDisable || false
  )
  const [filterStates, setFilterStates] = useState<any>(initialState)

  const debounceSearch = useDebounce(search, 500)

  let obj: any = []

  const nav = useNavigate()

  // const limit = '&limit=1000'

  useEffect(() => {}, [addProductOffset])

  useEffect(() => {}, [addBrandOffset])

  useEffect(() => {
    fetchPermission()
    // Develop BY: Kashyap
    // Description: get table list and dropdown list

    // getListFromApi('10', '0', '', '', '', '')

    getRequest(brandGetUrl + '?ordering=name' + limit, true).then((res) => {
      let obj: any = [{value: '', label: 'All Brands'}]
      res.results.forEach((item: any) => {
        obj.push({value: item.id, label: item.name})
      })
      setListDropdown((preValue: any) => ({
        ...preValue,
        brands: obj,
      }))
    })

    getRequest(productGetUrl + '?ordering=name' + limit, true).then((res) => {
      let obj: any = [{value: '', label: 'All Products'}]
      res.results.forEach((item: any) => {
        obj.push({value: item.id, label: item.name})
      })
      setListDropdown((preValue: any) => ({
        ...preValue,
        products: obj,
      }))
    })
  }, [])

  useEffect(() => {
    getListFromApi(limit, currentOffset, debounceSearch, status.value, brand.value, product.value)

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.wm_promotional_discount, filterStates)
    if (product.value == '' && brand.value == '' && status.value == '' && search == '') {
      setButtonDisable(false)
    }
  }, [currentOffset, debounceSearch, status, brand, product])

  useEffect(() => {
    setAddBrandOffset('')
    setAddProductOffset('')
    setBrandOffset('')
    setProductOffset('')
  }, [searchSelect])

  const fetchPermission = async () => {
    let res = await checkPermissions('price_and_discount_promotional_discount_management')
    setPermission(res)
  }

  const fetchAddBrand = async (search?: any, offset?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let hasMore: boolean = false
    let options: any = []
    let startDate: any = discount.startDateApi
    let endDate: any = discount.endDateApi

    let brandOffset = offset
    if (offset === '1') {
      brandOffset = ''
    } else {
      brandOffset = offset
    }

    // addBrandOffset
    let url = `&start_date=${startDate}&end_date=${endDate}&ordering=name&search=${search}&limit=${limit}&offset=${brandOffset}`
    const roleResponse = await getRequest(discountBrand + url, true)
    if (roleResponse.next !== null && offset !== '1') {
      const queryParams = new URLSearchParams(roleResponse.next)
      let newOffset: any = queryParams.get('offset')
      setAddBrandOffset(newOffset)
      hasMore = true
    }
    if (roleResponse.results.length > 0) {
      roleResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (addBrand.length > 0) {
      setAddBrand(addBrand.concat(options))
    } else {
      setAddBrand(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchAddProduct = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let hasMore: boolean = false
    let options: any = []
    let startDate: any = discount.startDateApi
    let endDate: any = discount.endDateApi

    let url = `&start_date=${startDate}&end_date=${endDate}&ordering=name&search=${search}&limit=${limit}&offset=${addProductOffset}`

    const roleResponse = await getRequest(discountProduct + url, true)
    if (roleResponse.next !== null) {
      const queryParams = new URLSearchParams(roleResponse.next)
      let newOffset: any = queryParams.get('offset')
      setAddProductOffset(newOffset)
      hasMore = true
    }
    if (roleResponse.results.length > 0) {
      roleResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (addProduct.length > 0) {
      setAddProduct(addProduct.concat(options))
    } else {
      setAddProduct(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchBrand = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const BRAND_API = `?ordering=name&search=${search}&limit=${limit}&offset=${brandOffset}`
    const brandResponse = await getRequest(brandGetUrl + BRAND_API, true)
    let hasMore: boolean = false

    if (brandResponse.next !== null) {
      const queryParams = new URLSearchParams(brandResponse.next)
      let newOffset: any = queryParams.get('offset')
      setBrandOffset(newOffset)
      hasMore = true
    }
    if (brandOption.length == 0) {
      options.push({
        label: 'All Brand',
        value: '',
      })
    }
    if (brandResponse.results.length > 0) {
      brandResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }
    if (brandOption.length > 0) {
      setBrandOption(brandOption.concat(options))
    } else {
      setBrandOption(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const extendedLoadOptions = useCallback(
    async (search, offset) => {
      if (discount.discountOn == 'BRAND') {
        return await fetchAddBrand(search, offset)
      } else {
        return await fetchAddProduct(search)
      }
    },
    [
      discount.discountOn,
      addBrandOffset,
      addProductOffset,
      discount.startDateApi,
      discount.endDateApi,
      discount.dropDownValue,
    ]
  )

  const fetchProducts = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []

    const PRODUCT_API = `?ordering=name&search=${search}&limit=${limit}&offset=${productOffset}`
    const productResponse = await getRequest(productGetUrl + PRODUCT_API, true)

    let hasMore: boolean = false

    if (productResponse.next !== null) {
      const queryParams = new URLSearchParams(productResponse.next)
      let newOffset: any = queryParams.get('offset')
      setProductOffset(newOffset)
      hasMore = true
    }

    if (roles.length == 0) {
      options.push({
        label: 'All Products',
        value: '',
      })
    }

    if (productResponse.results.length > 0) {
      productResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (roles.length > 0) {
      setRoles(roles.concat(options))
    } else {
      setRoles(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchStatus = async (search?: any) => {
    return {
      options: [
        {
          value: '',
          label: 'All Status',
        },
        {
          value: 'Ongoing',
          label: 'Ongoing',
        },
        {
          value: 'Scheduled',
          label: 'Scheduled',
        },
        {
          value: 'Past',
          label: 'Past',
        },
      ],
      hasMore: false,
    }
  }

  const getListFromApi = (
    limit: any,
    offset: any,
    search: any,
    status: any,
    brands: any,
    products: any
  ) => {
    let url = `?limit=${limit}&offset=${offset}&search=${search}&status=${status}&product_id__id=${products}&brand_id__id=${brands}`
    setListLoader(true)
    getRequest(promotionalDiscountUrl + url, true).then((res: any) => {
      setGetList(res.results)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.previous)
      if (res.results.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    setFilterStates({...filterStates, ['search']: e.target.value})
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
  }

  // const numberClick = (val: any, index: any) => {
  //   if (val) {
  //     setSelected(val - 1)
  //     let newOffset = index * 10
  //     setCurrentOffset(newOffset)
  //     getListFromApi('10', newOffset, search, status.value, brand.value, product.value)
  //   }
  // }

  // const prevPage = () => {
  //   if (prev !== null) {
  //     const queryParams = new URLSearchParams(prev)
  //     const newOffset = queryParams.get('offset')
  //     setCurrentOffset(newOffset)
  //     setSelected(selected - 1)
  //     getListFromApi('10', newOffset, search, status.value, brand.value, product.value)
  //   }
  // }

  // const nextPage = () => {
  //   if (next !== null) {
  //     const queryParams = new URLSearchParams(next)
  //     const newOffset = queryParams.get('offset')
  //     setCurrentOffset(newOffset)
  //     setSelected(selected + 1)
  //     getListFromApi('10', newOffset, search, status.value, brand.value, product.value)
  //   }
  // }

  const [dateHandler, setDateHandler] = useState({
    firstDate: '',
    lastDate: '',
  })

  const modalValueHandler = (e: any, checkBox: any, key?: any) => {
    // Develop BY: Kashyap
    // Description: handling modal value when user add details

    if (checkBox === 'checkBox') {
      setDiscount((preValue: any) => ({
        ...preValue,
        discountOn: key === 'checkBoxBrand' ? 'BRAND' : 'PRODUCT',
      }))
      setDiscount((preValue: any) => ({...preValue, dropDownValue: []}))
      setAddBrandOffset('')
      setAddProductOffset('')
      //
    }
    if (key === 'DROPDOWN') {
      setDiscount((preValue: any) => ({...preValue, dropDownValue: e}))
    }

    if (key === 'INPUT') {
      const {value, name} = e.target
      setDiscount((preValue: any) => ({
        ...preValue,
        [name]: value,
      }))
    }

    if (key === 'DATERANGE') {
      // change date code

      setDiscount((preValue: any) => ({
        ...preValue,
        startDateApi: e.startDateApi,
        endDateApi: e.endDateApi,
        startDateView: e.startDateView,
        endDateView: e.endDateView,
      }))

      setDateHandler({firstDate: e.startDateApi, lastDate: e.endDateApi})
    }
  }

  useEffect(() => {
    if (discount.startDateView !== 'Select') {
      setDisableFeature(false)

      extendedLoadOptions('', '1')
      setBrandOffset('')
      setProductOffset('')
    }
  }, [discount.startDateView])

  const submitModal = () => {
    let navigateField = {
      startDateView: discount.startDateView == 'Select' ? '' : discount.startDateView,
      brand: !scrollToParent && discount.dropDownValue.length == 0 ? '' : 'Parent',
      product: discount.dropDownValue.length == 0 ? '' : 'Parent',
      discount: discount.discount,
      discountTitle: discount.discountTitle,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (discount.dropDownValue.length == 0) {
      setValidation((preValue: any) => ({...preValue, dropDownValue: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, dropDownValue: ''}))
    }
    if (discount.discount == '') {
      setValidation((preValue: any) => ({...preValue, discount: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, discount: ''}))
    }
    if (discount.discountTitle == '') {
      setValidation((preValue: any) => ({...preValue, discountTitle: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, discountTitle: ''}))
    }
    if (discount.image == '') {
      setValidation((preValue: any) => ({...preValue, image: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, image: ''}))
    }
    if (discount.startDateView == 'Select') {
      setValidation((preValue: any) => ({...preValue, startDateView: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, startDateView: ''}))
    }

    if (
      discount.dropDownValue.length !== 0 &&
      discount.discount !== '' &&
      discount.discountTitle !== '' &&
      discount.image !== '' &&
      discount.startDateView !== 'Select'
    ) {
      setDisableBtn(true)
      setIsLoading('Please Wait...')
      let dropDownValue: any = []

      discount.dropDownValue.forEach((item: any) => {
        dropDownValue.push(item.value)
      })

      // console.log("dropDownValue == ", dropDownValue)
      // dropDownValue = JSON.stringify(dropDownValue)
      setMessageTitle(discount.discountTitle)

      let formData: any = new FormData()
      formData.append('warehouse_id', localStorage.getItem('w_id'))
      formData.append('discount', discount.discount)
      formData.append('discount_title', discount.discountTitle)
      if (requestHandler === 'add') {
        formData.append('start_date', discount.startDateApi)
        formData.append('end_date', discount.endDateApi)
      }

      if (requestHandler === 'edit') {
        // if (dateHandler.firstDate !== "" && dateHandler.lastDate !== "") {
        formData.append('start_date', discount.startDateApi)
        formData.append('end_date', discount.endDateApi)
        // }
      }

      formData.append('total_claimed', 0)
      formData.append('discount_on', discount.discountOn === 'BRAND' ? 'Brand' : 'ProductSKU')

      if (discount.image !== '1') {
        formData.append('promotional_banner', discount.image, discount.image.name)
      }

      var array = dropDownValue

      if (discount.discountOn === 'BRAND') {
        for (var i = 0; i < array.length; i++) {
          formData.append('brand_id', array[i])
        }
        // formData.append('brand_id', dropDownValue);
      } else {
        for (var i = 0; i < array.length; i++) {
          formData.append('product_id', array[i])
        }
        // formData.append('product_id', dropDownValue);
      }
      // Develop BY: Kashyap
      // Description: make the requestHandler for post and put request with same code.

      if (requestHandler === 'add') {
        postRequest(promotionalDiscountPostUrl, formData, true).then((res) => {
          setDisableBtn(false)
          setIsLoading('Submit')

          if (res.status === 400) {
            if (res.data.error) {
              setErrorMessage(
                'You cannot add previously selected brand product skus within the same date range.'
              )
            }
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
          } else {
            setIsAlertSuccess(true)
            $('#closeDisModal').trigger('click')
            getListFromApi('10', currentOffset, search, status.value, brand.value, product.value)
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setTimeout(() => {
              clearFunction()
            }, 200)
          }
        })
      }

      if (requestHandler === 'edit') {
        putRequest(promotionalDiscountPostUrl + `/${id}/`, formData, true).then((res) => {
          setDisableBtn(false)
          setIsLoading('Submit')
          if (res.status === 400) {
            if (res.data.error) {
              setErrorMessage(
                'You cannot add previously selected brand product skus within the same date range.'
              )
            }

            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
          } else {
            $('#closeDisModal').trigger('click')
            getListFromApi('10', currentOffset, search, status.value, brand.value, product.value)
            setIsAlertSuccess(true)
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setTimeout(() => {
              clearFunction()
            }, 200)
          }
        })
      }
    }
  }

  const getImage = (val: any) => {
    // $('.daterangepicker').css("display", "none");

    setDiscount((preValue: any) => ({...preValue, image: val}))
  }

  const cancleImage = () => {
    removeBlur()
    setTimeout(() => {
      // $('.daterangepicker').css("display", "none");
    }, 100)
    // daterangepicker ltr show-calendar opensright
  }

  const clearFunction = () => {
    setInitialSettingsEdit({
      startDate: moment(),
      endDate: moment(),
      cancelClass: 'btn-secondary',
      parentEl: '.modal',
      drops: 'down',
      locale: {
        format: GLOBAL_DATE_FORMAT,
      },
      alwaysShowCalendars: true,
    })

    setInitialSettings({
      startDate: moment(),
      endDate: moment(),
      autoUpdateInput: false,
      parentEl: '.modal',
      cancelClass: 'btn-secondary',
      drops: 'down',
      locale: {
        format: GLOBAL_DATE_FORMAT,
      },
      alwaysShowCalendars: true,
    })

    setStatus([])
    setErrorMessage('')
    setDisableFeature(true)
    setRequest('add')
    setDiscount({
      discountOn: 'BRAND',
      dropDownValue: [],
      discount: '',
      discountTitle: '',
      image: '',
      startDateApi: '',
      startDateView: 'Select',
      endDateApi: '',
      endDateView: '',
    })

    setValidation({
      dropDownValue: '',
      discount: '',
      discountTitle: '',
      image: '',
      imageSize: '',
      startDateView: '',
    })
    setCropData(defaultSrc)
    setDateHandler({firstDate: '', lastDate: ''})
    setScrollToParent(false)
    setModalLoader(false)
  }

  const onImageChange = (e: any) => {
    setValidation((preValue: any) => ({...preValue, image: ''}))
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        // setImg(URL.createObjectURL(file))
        // setBrandImage(e.target.files[0])
      }
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          // console.log('width ==>>', width, 'height ==>>', height)
          if (size > 5.0) {
            setValidation((preValue: any) => ({...preValue, imageSize: true}))
          } else {
            setValidation((preValue: any) => ({...preValue, imageSize: false}))
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  const editModalHandler = (item: any, masterArray: any) => {
    setModalLoader(true)
    let startDate = item.start_date
    let endDate = item.end_date

    let formatStartDate = moment(startDate).format('DD-MM-YYYY')
    let formatEndDate = moment(endDate).format('DD-MM-YYYY')

    let formatStartDateApi = moment(startDate).format('YYYY-MM-DD')
    let formatEndDateApi = moment(endDate).format('YYYY-MM-DD')

    let obj: any = []
    masterArray.forEach((item: any) => {
      obj.push({value: item.id, label: item.name})
    })

    let newStartDate = dateFormat(startDate)
    let newEndDate = dateFormat(endDate)

    setInitialSettingsEdit((pre: any) => ({
      ...pre,
      startDate: newStartDate.displayDate,
      endDate: newEndDate.displayDate,
    }))

    setId(item.id)
    // initital serrings here pending
    setDiscount({
      discountOn: item.discount_on === 'Brand' ? 'BRAND' : 'PRODUCT',
      dropDownValue: obj,
      discount: item.discount,
      discountTitle: item.discount_title,
      image: item.promotional_banner !== null ? '1' : '',
      startDateApi: newStartDate.apiDate,
      startDateView: newStartDate.displayDate ? newStartDate.displayDate : 'Select',
      endDateApi: newEndDate.apiDate,
      endDateView: newEndDate.displayDate,
    })
    setCropData(item.promotional_banner !== null ? item.promotional_banner : defaultSrc)
    setModalLoader(false)
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const deleteFunction = () => {
    let promotionalDiscountDeleteUrl = promotionalDiscountPostUrl + id
    deleteRequest(promotionalDiscountDeleteUrl, true).then((res) => {
      setIsAlertSuccess(true)
      $('#closeDeleteEaModalDiscount').trigger('click')
      getListFromApi('10', currentOffset, search, status.value, brand.value, product.value)
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 2000)
    })
  }

  const filterStatus = (value: any) => {
    setStatus(value)
    setFilterStates({...filterStates, ['status']: value})
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    // if (value.value === 'All Status') {
    //   getListFromApi('10', '0', search, '', brand.value, product.value)
    //   setSelected(0)
    // } else {
    //   getListFromApi('10', '0', search, value.value, brand.value, product.value)
    //   setSelected(0)
    // }
  }

  const filterBrands = (value: any) => {
    setBrand(value)
    setFilterStates({...filterStates, ['brand']: value})
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    // if (value.label === 'All Brands') {
    //   getListFromApi('10', '0', search, status.value, '', product.value)
    //   setSelected(0)
    // } else {
    //   getListFromApi('10', '0', search, status.value, value.value, product.value)
    //   setSelected(0)
    // }
  }
  const filterProducts = (value: any) => {
    setProduct(value)
    setFilterStates({...filterStates, ['product']: value})
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    // if (value.label === 'All Products') {
    //   getListFromApi('10', '0', search, status.value, brand.value, '')
    //   setSelected(0)
    // } else {
    //   getListFromApi('10', '0', search, status.value, brand.value, value.value)
    //   setSelected(0)
    // }
  }

  const [initialSettings, setInitialSettings] = useState({
    startDate: moment(),
    endDate: moment(),
    autoUpdateInput: false,
    cancelClass: 'btn-secondary',
    drops: 'down',
    parentEl: '.modal',
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    alwaysShowCalendars: true,
  })

  const [initialSettingsEdit, setInitialSettingsEdit] = useState({
    startDate: moment(),
    endDate: moment(),
    cancelClass: 'btn-secondary',
    parentEl: '.modal',
    drops: 'down',
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    alwaysShowCalendars: true,
  })

  const CustomDateRangePicker = () => {
    return (
      <DateRangePicker
        initialSettings={requestHandler === 'add' ? initialSettings : initialSettingsEdit}
        onEvent={(e) => {
          e.preventDefault()
        }}
        onCallback={(start, end, label) => {
          setDiscount((preValue: any) => ({
            ...preValue,
            dropDownValue: [],
          }))
          setValidation((preValue: any) => ({...preValue, startDateView: ''}))

          if (requestHandler === 'add') {
            setInitialSettings({
              startDate: start._d,
              endDate: end._d,
              parentEl: '.modal',
              cancelClass: 'btn-secondary',
              drops: 'down',
              autoUpdateInput: false,
              locale: {
                format: GLOBAL_DATE_FORMAT,
              },
              alwaysShowCalendars: true,
            })
          } else {
            setInitialSettingsEdit({
              startDate: start._d,
              endDate: end._d,
              cancelClass: 'btn-secondary',
              parentEl: '.modal',
              drops: 'down',
              locale: {
                format: GLOBAL_DATE_FORMAT,
              },
              alwaysShowCalendars: true,
            })
          }

          let newStartDate = dateFormat(start._d)
          let newEndDate = dateFormat(end._d)

          let startDate = start._d
          let endDate = end._d

          let formatStartDate = moment(startDate).format('DD-MM-YYYY')
          let formatEndDate = moment(endDate).format('DD-MM-YYYY')

          let formatStartDateApi = moment(startDate).format('YYYY-MM-DD')
          let formatEndDateApi = moment(endDate).format('YYYY-MM-DD')

          let dateObj = {
            startDateView: newStartDate.displayDate,
            endDateView: newEndDate.displayDate,

            startDateApi: newStartDate.apiDate,
            endDateApi: newEndDate.apiDate,
          }
          modalValueHandler(dateObj, '', 'DATERANGE')
        }}
        onCancel={(event: any, picker: any) => {
          setDiscount((preValue: any) => ({
            ...preValue,
            startDateView: 'Select',
          }))
        }}
      >
        <input
          id='datePickerFocus'
          type='text'
          onFocus={() => {
            // $('.daterangepicker').css("display", "block");
          }}
          className={clsx('form-control calendar', validation.startDateView)}
          readOnly
          value={
            discount.startDateView === 'Select'
              ? discount.startDateView
              : discount.startDateView + ' - ' + discount.endDateView
          }
        />
      </DateRangePicker>
    )
  }

  const selectStyles = {
    menuList: (base: any) => ({
      ...base,
      maxHeight: 200,
    }),
  }

  const handleChangeReset = () => {
    setSearch('')
    setProduct({value: '', label: 'All Products'})
    setBrand({value: '', label: 'All Brands'})
    setStatus({value: '', label: 'All Status'})
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = {...filterStates}
    updatedState['product'] = ''
    updatedState['brand'] = ''
    updatedState['status'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title='Promotional Discount' />
      <div>
        <PageTitle breadcrumbs={[priceHeaderTitle]}>
          {intl.formatMessage({id: 'Promotional Discount'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('PROMOTIONAL.DISCOUNT.ADD', messageTitle)
              : FormatMessage('PROMOTIONAL.DISCOUNT.UPDATE', messageTitle)
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message={FormatMessage('test')}
        />

        <div className={`card ${className}`}>
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
              <input
                type='text'
                className='form-control form-search'
                placeholder='Search'
                onChange={searchList}
                disabled={listLoadder}
                value={search}
              />
            </form>
            <div className='ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                {/* <EaDropDown list={listDropDown.brands} placeholder="All Brands" onChange={filterBrands} value={brand} /> */}
                <AsyncPaginate
                  loadOptions={fetchProducts}
                  isSearchable
                  placeholder='All Products'
                  className='react-select-container w-130px me-3'
                  classNamePrefix='react-select'
                  value={product}
                  onChange={filterProducts}
                  isDisabled={listLoadder}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchBrand}
                  isSearchable
                  placeholder='All Brands'
                  className='react-select-container w-120px me-3'
                  classNamePrefix='react-select'
                  name='role'
                  value={brand}
                  // value={roles.filter((option: any) => option.value === roleId)}
                  onChange={filterBrands}
                  isDisabled={listLoadder}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {/*
                <EaDropDown
                  list={listDropDown.products}
                  placeholder='All Products'
                  onChange={filterProducts}
                  value={product}
                /> */}

                {/* <EaDropDown
                  list={statusoptions}
                  placeholder='All Status'
                  onChange={filterStatus}
                  value={status}
                /> */}

                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  placeholder='All Status'
                  className='react-select-container w-115px me-3'
                  classNamePrefix='react-select'
                  value={status}
                  onChange={filterStatus}
                  isDisabled={listLoadder}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {permission.write && (
                  <div className='me-3'>
                    <EaButton
                      isModal={true}
                      onClick={() => {
                        setRequest('add')
                        setMessageHandler('successAdd')
                      }}
                      dataTarget='#ea_modal_create_discount'
                      btnName='Add Discount'
                    />
                  </div>
                )}
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
                {/* </div> */}
              </form>
            </div>
          </div>
          <div className='card-body py-4 '>
            <div className='table-responsive'>
              {listLoadder ? (
                <IsLoader />
              ) : (
                <>
                  {getLength !== '1' ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                      <thead>
                        <tr className='text-muted fw-500'>
                          <th className='min-w-180px'>Discount Title</th>
                          <th className='min-w-110px'>Discount (%)</th>
                          <th className='min-w-150px'>Discount On</th>
                          <th className='min-w-220px'>Duration</th>
                          <th className='min-w-130px'>
                            Total Claimed
                            <br />
                            (No. of Orders)
                          </th>
                          <th className='min-w-120px'>Impressions</th>
                          <th className='min-w-120px'>Clicks</th>
                          <th className='min-w-120px'>Usage</th>
                          <th className='min-w-100px'>Status</th>
                          <th className='min-w-80px action-th py-2'>
                            {permission.write && 'Actions'}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getList.map((item: any) => {
                          let masterArray: any = []
                          if (item.discount_on === 'Brand') {
                            item.brand_id.forEach((element: any) => {
                              masterArray.push(element)
                            })
                          }

                          if (item.discount_on === 'ProductSKU') {
                            item.product_id.forEach((element: any) => {
                              masterArray.push(element)
                            })
                          }

                          // status

                          const formate = 'YYYY-MM-DD'
                          const date = moment().format(formate)
                          const start = moment(item.start_date).format(formate)
                          const end = moment(item.end_date).format(formate)

                          let onGoing = false
                          let shedule = false
                          let past = false

                          if (date >= start && date <= end) {
                            onGoing = true
                          }

                          if (date < start) {
                            shedule = true
                          }

                          if (date > end) {
                            past = true
                          }

                          return (
                            <tr>
                              <td>{item.discount_title}</td>
                              <td>{item.discount}%</td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <span>
                                    {item.brand_id.length !== 0 &&
                                      item.brand_id.length + ' ' + 'Brand'}{' '}
                                    {item.product_id.length !== 0 &&
                                      item.product_id.length + ' ' + 'SKUs'}{' '}
                                  </span>
                                  <OverlayTrigger
                                    delay={{hide: 450, show: 300}}
                                    overlay={(props) => (
                                      <Tooltip {...props} className='tooltip-dark'>
                                        {masterArray.map((item: any, index: any) => {
                                          return (
                                            <>
                                              <span>
                                                {index ? ',' : ''} {item.name}{' '}
                                              </span>
                                            </>
                                          )
                                        })}
                                      </Tooltip>
                                    )}
                                    placement='top'
                                  >
                                    <span className='ms-2 cursor-pointer'>
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen045.svg'
                                        className='ic text-primary mr-0'
                                      />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </td>
                              <td>
                                {moment(item.start_date).format(globalDateFormate())} -{' '}
                                {moment(item.end_date).format(globalDateFormate())}
                              </td>
                              <td>{/* TZS {item.total_claimed}<br />(20 Orders) */}-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>
                                {onGoing && (
                                  <span className='badge badge-light-success text-dark'>
                                    Ongoing
                                  </span>
                                )}
                                {shedule && (
                                  <span className='badge badge-light-primary text-dark'>
                                    Scheduled
                                  </span>
                                )}
                                {past && <span className='badge badge-light text-dark'>Past</span>}
                              </td>
                              <td className='text-end'>
                                {permission.write && (
                                  <a
                                    href='#'
                                    onClick={() => {
                                      setRequest('edit')
                                      setMessageHandler('successEdit')
                                      editModalHandler(item, masterArray)
                                      setDisableFeature(false)
                                    }}
                                    data-bs-toggle='modal'
                                    data-bs-target='#ea_modal_create_discount'
                                    className='btn py-0 ps-1 pe-0 btn-active-color-primary btn-sm me-3'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )}
                                {/* {!past && permission.delete && <a
                                                                    href='#'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-target='#delete_discount'
                                                                    onClick={() => {
                                                                        setMessageTitle(item.name)
                                                                        setId(item.id)
                                                                        // deleteRequest()
                                                                    }}
                                                                    className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                                                    <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                                                </a>} */}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
                } of ${getList.length == 0 ? 0 : count} entries`}
            </div>
            <div className="col">
              <CustomPagination
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
                count={getList.length == 0 ? 0 : count}
                selected={selected}
              />
            </div>
          </div>
        </div> */}
        <CustomPaginate
          data={getList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.wm_promotional_discount}
          filterStates={filterStates}
          isDisabled={listLoadder}
        />
      </div>

      <EaModal
        cancel={clearFunction}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        closeAuto='closeDisModal'
        id='ea_modal_create_discount'
        modalTitle={requestHandler === 'add' ? 'Add Discount' : 'Edit Discount'}
        onSubmit={submitModal}
        loader={modalLoader}
      >
        {/* <Form> */}
        <div className='row mb-4'>
          <div className='col-sm-12 mb-4 mb-sm-0'>
            <label className='form-label' id='startDateView'>
              Select Date<span className='text-danger'>*</span>
            </label>
            <CustomDateRangePicker />
          </div>

          {/* <div className='mt-3'>
                        <ErrorHandler errorMessage={errorMessage} />
                    </div> */}
        </div>
        <div className='mb-4'>
          <label className='form-label'>Discount On</label>
          <div className='row'>
            <div className='col-auto'>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  disabled={disableFeature}
                  checked={discount.discountOn === 'BRAND' ? true : false}
                  className='form-check-input'
                  name='discounton'
                  type='radio'
                  value=''
                  id='Brand'
                  onClick={() => {
                    modalValueHandler('', 'checkBox', 'checkBoxBrand')
                    setScrollToParent(false)
                  }}
                />
                <label className='form-check-label' htmlFor='Brand'>
                  Brand
                </label>
              </div>
            </div>
            <div className='col-auto'>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  disabled={disableFeature}
                  checked={discount.discountOn === 'PRODUCT' ? true : false}
                  className='form-check-input'
                  name='discounton'
                  type='radio'
                  value=''
                  id='product-SKUs'
                  onClick={() => {
                    modalValueHandler('', 'checkBox', 'checkBoxProduct')
                    setScrollToParent(true)
                  }}
                />
                <label className='form-check-label' htmlFor='product-SKUs'>
                  Product SKUs
                </label>
              </div>
            </div>
          </div>
        </div>
        {discount.discountOn === 'BRAND' ? (
          <div className='mb-4'>
            <label className='form-label' id='brand'>
              Brand(s)<span className='text-danger'>*</span>
            </label>
            <AsyncPaginate
              loadOptions={(search) => extendedLoadOptions(search, addBrandOffset)}
              isSearchable
              placeholder='Select'
              styles={selectStyles}
              isDisabled={disableFeature}
              isMulti
              cacheUniqs={[
                discount.discountOn,
                addBrandOffset,
                addProductOffset,
                discount.startDateApi,
                discount.endDateApi,
                discount.dropDownValue,
              ]}
              value={discount.dropDownValue}
              onChange={(e) => {
                modalValueHandler(e, '', 'DROPDOWN')
                setAddBrandOffset('')
                setAddProductOffset('')
                let event: any = e
                if (event.length === 0) {
                  event = ''
                }
                const validation = ValidationHandler(event, 'is-invalid')
                setValidation((preValue: any) => ({...preValue, dropDownValue: validation}))
              }}
              className={clsx('react-select-container mb-4', validation.dropDownValue)}
              classNamePrefix='react-select'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
        ) : (
          <div className='mb-4'>
            <label className='form-label' id='product'>
              Product SKUs<span className='text-danger'>*</span>
            </label>
            <AsyncPaginate
              loadOptions={extendedLoadOptions}
              placeholder='Select'
              cacheUniqs={[
                discount.discountOn,
                addBrandOffset,
                addProductOffset,
                discount.startDateApi,
                discount.endDateApi,
                discount.dropDownValue,
              ]}
              isSearchable
              isDisabled={disableFeature}
              styles={selectStyles}
              isMulti
              value={discount.dropDownValue}
              onChange={(e) => {
                setAddBrandOffset('')
                setAddProductOffset('')
                modalValueHandler(e, '', 'DROPDOWN')
                let event: any = e
                if (event.length === 0) {
                  event = ''
                }
                const validation = ValidationHandler(event, 'is-invalid')
                setValidation((preValue: any) => ({...preValue, dropDownValue: validation}))
              }}
              className={clsx('react-select-container mb-4', validation.dropDownValue)}
              classNamePrefix='react-select'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
        )}
        {errorMessage !== '' && (
          <div className='mb-4'>
            <ErrorHandler errorMessage={errorMessage} />
          </div>
        )}

        <div className='mb-4'>
          <label className='form-label'>
            Discount (%)<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='discount'
            className={clsx('form-control', validation.discount)}
            placeholder='Type here...'
            name={'discount'}
            value={discount.discount}
            onChange={(e) => {
              // const re = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
              const re = /^[0-9]*(\.[0-9]{0,2})?$/
              // const re = /^[0-9\b]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                modalValueHandler(e, '', 'INPUT')
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setValidation((preValue: any) => ({...preValue, discount: validation}))
              }
            }}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Discount Title<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='discountTitle'
            className={clsx('form-control', validation.discountTitle)}
            placeholder='Type here...'
            name='discountTitle'
            value={discount.discountTitle}
            onChange={(e) => {
              modalValueHandler(e, '', 'INPUT')
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidation((preValue: any) => ({...preValue, discountTitle: validation}))
            }}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Promotional Banner<span className='text-danger'>*</span>
          </label>
          <Banner
            text='File Size: Max 5 MB • 700px X 300px'
            isUpload={false}
            isBanner={true}
            getImage={getImage}
            onCancle={cancleImage}
            onImageChange={onImageChange}
            imageRequire={validation.image == 'is-invalid' ? true : false}
            imageSize={validation.imageSize}
            cropData={cropData}
            setCrop={setCropData}
            id='datePickerFocus'
          />
        </div>
      </EaModal>

      <EaModal
        onSubmit={deleteFunction}
        closeAuto='closeDeleteEaModalDiscount'
        modalTitle='Delete Discount'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{messageTitle}?</span>
          </p>
        }
        id={'delete_discount'}
        actionBtnName='Yes, Proceed'
      />
    </>
  )
}

export default PromotionalDiscount
