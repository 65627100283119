import clsx from "clsx";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiBaseURL,
  focusVatidateField,
  isModalValidation,
  staticSearch,
  toAbsoluteUrl
} from "../../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import { getRequest, postRequest, putRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageLink, PageTitle } from "../../../../_eaFruitsDms/layout/core";
import Imagecropper from "./imageCropper";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";

const cmsTestimonial: Array<PageLink> = [
  {
    title: 'CMS',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
]

const AddTestimonials = () => {
  const navigate = useNavigate()
  const testimonialId = localStorage.getItem('testimonialsId')

  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'CMS',
  }

  const initialTestimonial = {
    person_name: '',
    Sub_title: '',
    testimonial_text: '',
    testimonial_from: '',
    person_logo: '',
    testimonial_url: '',
    is_featured: false,
  }
  const initialTestimonialValidation = {
    person_name: '',
    Sub_title: '',
    testimonial_text: '',
    testimonial_from: '',
    person_logo: '',
    testimonial_url: '',
    is_featured: '',
  }

  const [testimonial, setTestimonial] = useState<any>(initialTestimonial)
  const [imageValidation, setImageValidation] = useState<boolean>(false)
  const [img, setImg] = useState<any>('')
  const [imageSize, setImageSize] = useState('')
  const [imageRequire, setImageRequire] = useState('')
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [cropData, setCropData] = useState(defaultSrc)
  const [imgSizeInvalid, setimgSizeInvalid] = useState('')
  const [limit, setLimit] = useState<number>(10)
  const [testimonialValidation, setTestimonialValidation] = useState<any>(
    initialTestimonialValidation
  )
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [testimonialDetailInitial, setTestimonialDetailInitial] = useState<any>(initialTestimonial)
  const [imageRequireMessage, setImageRequireMessage] = useState('')
  const [isButtonLoader, setIsButtonLoader] = useState<any>('Submit')
  const [testimonialsFrom, setTestimonialFrom] = useState<any>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isResonseLoading, setIsResponseLoading] = useState<any>('')

  useEffect(() => {
    fetchTestimonialDetail().then()
  }, [])

  useEffect(() => {
    setTestimonial(testimonialDetailInitial)
  }, [testimonialDetailInitial])

  // Develop BY: Kriti
  // Description: fetchDetail from get ID

  const fetchTestimonialDetail = async () => {
    setTestimonial(initialTestimonial)
    if (testimonialId != null) {
      setIsResponseLoading(true)
      let testimonialResponse = await getRequest(
        `${apiBaseURL()}cms/testimonial/${testimonialId}/`,
        true
      )
      if (testimonialResponse) {
        let testimonialDetail: any = { ...testimonialDetailInitial }

        Object.entries(testimonialResponse).map((object: any) => {
          if (object[0] in testimonialDetail) {
            return (testimonialDetail[object[0]] = object[1])
          }
        })
        setTestimonialFrom([
          {
            label: testimonialResponse.testimonial_from,
            value: testimonialResponse.testimonial_from,
          },
        ])
        if (testimonialResponse.person_logo != null) {
          setCropData(testimonialResponse.person_logo)
        } else {
          setCropData(defaultSrc)
        }
        setTestimonialDetailInitial(testimonialDetail)
        setIsResponseLoading(false)
      }
    }
  }

  const defaultOptions: any = [
    {
      label: 'Customer',
      value: 'Customer',
    },
    {
      label: 'Farmer',
      value: 'Farmer',
    },
  ]

  const fetchTestimonialFrom = async (search: any) => {
    let options = defaultOptions

    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options,
      hasMore: false,
    }
  }

  // Develop BY: Kriti
  // Description: Selected Image Details
  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.Jfif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImg(URL.createObjectURL(file))
        setTestimonial({ ...testimonial, ['person_logo']: e.target.files[0] })
      }
      let img: any = ''
      if (e.target.files[0]) {
        setImageRequire('')
        setImageRequireMessage('')
        img = new Image()
        let objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setimgSizeInvalid('text-danger')
            setImageValidation(false)
            setImageRequireMessage('')
          } else {
            setimgSizeInvalid('')
            setImageValidation(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }
  const getImage = (val: any) => {
    setTestimonial({ ...testimonial, ['person_logo']: val })
  }

  // Develop BY: Kriti
  // Description: function to obtain the target value

  const handleChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setTestimonialValidation({ ...testimonialValidation, [inputName]: '' })
    let updatedTestimonial: any = { ...testimonial }

    switch (inputName) {
      case 'person_name':
        updatedTestimonial[inputName] = inputValue
        break
      case 'Sub_title':
        updatedTestimonial[inputName] = inputValue
        break
      case 'testimonial_text':
        updatedTestimonial[inputName] = inputValue
        break
      case 'testimonial_from':
        setTestimonialFrom(e)
        updatedTestimonial[inputName] = inputValue
        break
      case 'testimonial_url':
        let urlPattern =
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/
        const validURL = urlPattern.test(inputValue)
        if (validURL === false) {
          setErrorMessage('Please enter valid url.')
        } else {
          setErrorMessage('')
        }
        updatedTestimonial[inputName] = inputValue
        break
      case 'is_featured':
        updatedTestimonial[inputName] = e.target.checked
        break
    }
    setTestimonial(updatedTestimonial)
  }

  // Develop BY: Kriti
  // Description: function to verify the fileld

  const validateForm = () => {
    const validation = { ...testimonial }
    let isValid = true

    let navigateField = {
      person_name: testimonial.person_name,
      Sub_title: testimonial.Sub_title,
      testimonial_text: testimonial.testimonial_text,
      person_logo: testimonial.person_logo,
      testimonial_from: testimonial.testimonial_from,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (testimonial.person_name === '') {
      validation.person_name = 'is-invalid'
      isValid = false
    }
    if (testimonial.Sub_title === '') {
      validation.Sub_title = 'is-invalid'
      isValid = false
    }
    if (testimonial.testimonial_text === '') {
      validation.testimonial_text = 'is-invalid'
      isValid = false
    }
    if (testimonial.testimonial_from === '') {
      validation.testimonial_from = 'is-invalid'
      isValid = false
    }

    if (errorMessage === 'Please enter valid url.') {
      validation.testimonial_url = 'is-invalid'
      isValid = false
    }

    if (testimonial.person_logo === '' || imgSizeInvalid == 'text-danger') {
      setImageRequire('file-icon-inValid')
      if (imgSizeInvalid != 'text-danger') {
        setImageRequireMessage('file-icon-inValid')
      }
      validation.person_logo = 'is-invalid'
      isValid = false
    }
    setTestimonialValidation(validation)
    return isValid
  }

  // Develop BY: Kriti
  // Description: function to call post/put api
  const addTestimonial = () => {
    const testimonialInfo: any = { ...testimonial }
    const isValid = validateForm()
    if (isValid) {
      setIsButtonLoader('Please Wait...')
      let testimonialData = new FormData()
      const testimonialInfo: any = { ...testimonial }

      Object.entries(testimonialInfo).map((object: any) => {
        if (object[0] == 'person_logo') {
          if (object[1] != null && typeof object[1] != 'string') {
            return testimonialData.append(object[0], object[1])
          }
        } else {
          return testimonialData.append(object[0], object[1])
        }
      })

      if (testimonialId != null) {
        putRequest(`${apiBaseURL()}cms/testimonial/${testimonialId}/`, testimonialData, true).then(
          (res) => {
            handleResponse(res, 'Edit')
          }
        )
      } else {
        postRequest(`${apiBaseURL()}cms/testimonial/`, testimonialData, true).then((res) => {
          handleResponse(res, 'Add')
        })
      }
    }
  }

  const fetchRedirect = () => {
    navigate('/cms/testimonials')
  }

  const handleResponse = (res: any, type: string) => {
    setIsButtonLoader('Submit')
    if (res.status === 201 || res.status === 200 || res.status === 204) {
      setIsAlertSuccess(true)
      setTestimonial(initialTestimonial)
      localStorage.setItem('type', type)
      localStorage.removeItem('testimonialsId')
      fetchRedirect()
    } else {
      // setIsAlertFailed(true)
      setErrorMessage('Please enter valid url.')
    }
    setTimeout(() => {
      setIsAlertFailed(false)
      setIsAlertSuccess(false)
    }, 1500)
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <div>
        {testimonialId == null ? (
          <>
            <EATitle title='Add Testimonial - ' />
          </>
        ) : (
          <>
            <EATitle title='Edit Testimonial - ' />
          </>
        )}

        <PageTitle breadcrumbs={cmsTestimonial} backNavigate='/cms/testimonials'>
          {intl.formatMessage({
            id: testimonialId == null ? 'Add Testimonial' : 'Edit Testimonial',
          })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={FormatMessage('success')}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        {isResonseLoading !== '' && isResonseLoading == true && <IsLoader />}
        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
            {/* begin::Title */}
            <h3 className='card-title align-items-start flex-column my-0'>
              {testimonialId == null ? (
                <>
                  <span className='card-label fw-bold font-17'>Add Testimonial</span>
                </>
              ) : (
                <span className='card-label fw-bold font-17'>Edit Testimonial</span>
              )}
            </h3>
            {/* end::Title */}
          </div>
          {/* <div className='card-body pt-4'> */}
          <form className='card-body pt-4' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='mb-4'>
                  <label className='form-label'>
                    Person Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type='text'
                    id='person_name'
                    className={clsx('form-control', testimonialValidation.person_name)}
                    name='person_name'
                    value={testimonial.person_name}
                    placeholder='Type here...'
                    onChange={(e: any) => handleChange(e, 'person_name')}
                  />
                </div>
                <div className='row'>
                  <div className='mb-4'>
                    <label className='form-label'>
                      Sub Title<span className="text-danger">*</span>
                    </label>
                    <input
                      className={clsx('form-control', testimonialValidation.Sub_title)}
                      placeholder='Type here…'
                      id='Sub_title'
                      value={testimonial.Sub_title}
                      name='Sub_title'
                      onChange={(e: any) => handleChange(e, 'Sub_title')}
                    ></input>
                  </div>
                  <div className='mb-4'>
                    <label className='form-label'>
                      Testimonial Text<span className="text-danger">*</span>
                    </label>
                    <textarea
                      id='testimonial_text'
                      className={clsx('form-control', testimonialValidation.testimonial_text)}
                      rows={6}
                      placeholder='Type here…'
                      value={testimonial.testimonial_text}
                      name='testimonial_text'
                      onChange={(e: any) => handleChange(e, 'testimonial_text')}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div id='person_logo'>
                  <Imagecropper
                    getImage={getImage}
                    onImageChange={onImageChange}
                    imageRequire={imageRequire}
                    imageRequireMessage={imageRequireMessage}
                    imageSize={imgSizeInvalid}
                    cropData={cropData}
                    setCrop={setCropData}
                  />
                </div>
                <div className='row'>
                  <div className='mb-4'>
                    <label className='form-label' id='testimonialsFrom'>
                      Select Testimonial From<span className="text-danger">*</span>
                    </label>
                    <AsyncPaginate
                    
                      loadOptions={fetchTestimonialFrom}
                      placeholder='Select'
                      isSearchable
                      className={clsx(
                        'react-select-container my-1 w-100%',
                        testimonialValidation.testimonial_from
                      )}
                      classNamePrefix='react-select'
                      name='testimonial_from'
                      onChange={(e: any) => handleChange(e, 'testimonial_from')}
                      value={testimonialsFrom}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='form-label'>Testimonial Video</label>
                    <input
                      type='text'
                      className={clsx('form-control', testimonialValidation.testimonial_url)}
                      placeholder='Youtube url'
                      value={testimonial.testimonial_url}
                      name='testimonial_url'
                      onChange={(e: any) => {
                        handleChange(e, 'testimonial_url')
                        if (e.target.value == '') {
                          setErrorMessage('')
                        }
                      }}
                    ></input>
                    <div className='mb-1 mt-3'>
                      <ErrorHandler errorMessage={errorMessage} />
                    </div>
                  </div>
                  <div className='mb-4'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className={clsx(
                          'form-check-input widget-13-check',
                          testimonialValidation.is_featured
                        )}
                        name='is_featured'
                        checked={testimonial.is_featured}
                        type='checkbox'
                        onChange={(e: any) => handleChange(e, 'is_featured')}
                        value=''
                      />
                      <label className='form-check-label'>Featured Testimonial</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* </div> */}
          <div className='card-footer py-2'>
            <button
              type='button'
              className='btn btn-secondary me-3'
              onClick={() => {
                fetchRedirect()
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              disabled={isButtonLoader !== 'Submit'}
              onClick={() => {
                addTestimonial()
              }}
            >
              {isButtonLoader}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddTestimonials
