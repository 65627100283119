import moment from 'moment'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  apiBaseURL,
  checkEmpty,
  contactNumber,
  dateFormat,
  isEmpty,
  KTSVG,
} from '../../../../_eaFruitsDms/helpers'
import HeaderTitle from '../../../../_eaFruitsDms/layout/components/HeaderTitle'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import DynamicTable from '../../../../_eaFruitsDms/helpers/components/DynamicTable'

const CustomerLeadDetails = () => {
  const location: any = useLocation()
  const navigate = useNavigate()
  const id = isEmpty(location?.state?.id) ? '' : location?.state?.id

  const [details, setDetails] = useState<any>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchCustomerLeadDetails = async () => {
    setLoading(true)

    const url = `${apiBaseURL()}customers/leads/${id}`

    const response = await getRequest(url, true)

    response && setDetails(response)

    setLoading(false)
  }

  useEffect(() => {
    if (isEmpty(id)) {
      navigate(-1)
    } else {
      fetchCustomerLeadDetails()
    }
  }, [location])

  const columns = [
    {
      label: 'Product SKU',
      format: (val: any) => checkEmpty(val?.product),
    },
    {
      label: 'Qty.',
      format: (val: any) => checkEmpty(val?.qty),
    },
  ]

  return (
    <>
      <HeaderTitle
        pageTitle='Customer Lead Details'
        headerSubTitle='Customers'
        headerTitle='Customer Lead Details'
      />
      {isLoading ? (
        <div className='card p-5 mb-3'>
          <FetchingRecords />
        </div>
      ) : isEmpty(details) ? (
        <div className='card p-5 mb-3'>
          <NoRecords />
        </div>
      ) : (
        <>
          <div className='card p-5 mb-3'>
            <div className='d-flex justify-content-between'>
              <div>
                <div className='fw-bolder fs-3 mb-4'>{checkEmpty(details?.business_name)}</div>

                <div className='d-flex'>
                  <div className='me-2 d-flex align-items-center'>
                    <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                    <div>
                      {isEmpty(details?.created_at)
                        ? '-'
                        : `Lead Created On ${dateFormat(details?.created_at).displayDate}`}{' '}
                    </div>
                  </div>

                  <div className='d-flex align-items-center'>
                    <KTSVG path='/media/icons/duotune/general/gen018.svg' className='me-1' />
                    <div>{checkEmpty(details?.district)}</div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <div className='me-4'>
                  {isEmpty(details?.status) ? (
                    '-'
                  ) : details?.status === 'lead' ? (
                    <span className='badge badge-light-primary text-dark p-2'>Lead</span>
                  ) : details?.status === 'invalid' ? (
                    <span className='badge badge-light-danger text-dark p-2'>Invalid</span>
                  ) : details?.status === 'registered' ? (
                    <span className='badge badge-light-success text-dark p-2'>Registered</span>
                  ) : details?.status === 'pending' ? (
                    <span className='badge badge-light-warning text-dark p-2'>Pending</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card p-5'>
            <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>Contact Information</h3>

            <div className='row'>
              <div className='col-md-2 mb-6'>
                <label className='font-14 text-muted mb-2'>Business Name</label>
                <div>
                  <span className='font-14 text-dark'>{checkEmpty(details?.business_name)}</span>
                </div>
              </div>

              <div className='col-md-2 mb-6'>
                <label className='font-14 text-muted mb-2'>Promoter Name</label>
                <div>
                  <span className='font-14 text-dark'>{checkEmpty(details?.full_name)}</span>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-6 col-xl-6 mb-6'>
                <label className='font-14 text-muted mb-2'>Address</label>
                <div>
                  <span className='font-14 text-dark'>{checkEmpty(details?.address)}</span>
                </div>
              </div>
            </div>

            <div className='row'>
              {/* <div className='col-md-2 mb-6'>
        <label className='font-14 text-muted mb-2'>Region</label>
        <div>
          <span className='font-14 text-dark'>Dar es salam</span>
        </div>
      </div> */}
              <div className='col-md-2 mb-6'>
                <label className='font-14 text-muted mb-2'>District</label>
                <div>
                  <span className='font-14 text-dark'>{checkEmpty(details?.district)}</span>
                </div>
              </div>
              <div className='col-md-2 mb-6'>
                <label className='font-14 text-muted mb-2'>Ward</label>
                <div>
                  <span className='font-14 text-dark'>{checkEmpty(details?.ward)}</span>
                </div>
              </div>
              <div className='col-md-2 mb-6'>
                <label className='font-14 text-muted mb-2'>Street</label>
                <div>
                  <span className='font-14 text-dark'>{checkEmpty(details?.street)}</span>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-6 col-xl-2 mb-6'>
                <label className='font-14 text-muted mb-2'>Mobile Number</label>
                <div>
                  <span className='font-14 text-dark'>
                    {isEmpty(details?.contact_no) ? '-' : contactNumber(details?.contact_no)}
                  </span>
                </div>
              </div>
              <div className='col-sm-6 col-xl-2 mb-6'>
                <label className='font-14 text-muted mb-2'>Email</label>
                <div>
                  <span className='font-14 text-dark'>{checkEmpty(details?.email)}</span>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-2 mb-6'>
                <label className='font-14 text-muted mb-2'>Lead Created By</label>
                <div>
                  <span className='font-14 text-dark'>{checkEmpty(details?.created_by_name)}</span>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-3 mb-6'>
                <label className='font-14 text-muted mb-2'>Note</label>
                <div>
                  <span className='font-14 text-dark'>{checkEmpty(details?.note)}</span>
                </div>
              </div>
            </div>

            <>
              {details?.status === 'invalid' && (
                <>
                  <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>
                    Invalid Information
                  </h3>
                  <div className='row'>
                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Reason Type</label>
                      <div>
                        <span className='font-14 text-dark'>{checkEmpty(details?.reason)}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Rejection Note</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {checkEmpty(details?.reason_note)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Rejected By</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {checkEmpty(details?.action_by_name)}
                        </span>
                      </div>
                    </div>

                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Rejected on</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {dateFormat(details?.action_at).displayDate}
                        </span>
                      </div>
                    </div>
                  </div>{' '}
                </>
              )}
            </>
          </div>

          <div className='card px-5 mt-3'>
            {!isEmpty(details?.products) && (
              <>
                <h3 className='font-14 fw-500 border-bottom pb-4 pt-5'>Product Information</h3>

                <div className='row'>
                  <div className='col-md-6'>
                    <DynamicTable
                      data={details?.products?.length > 0 ? details?.products : []}
                      columns={columns}
                      displayLoader={false}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default CustomerLeadDetails
