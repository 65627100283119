import React from "react"
import { useIntl } from "react-intl"
import { Navigate, Route, Routes } from "react-router-dom"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import CreditRequest from "./components/creditRequest"
import CustomerOnBoarding from "./components/customerOnBoarding"
import LoadOutRequests from "./components/loadOutRequests"
import OrderDiscount from "./components/orderDiscount"
import GatePassRejectionReport from "./components/gatePassRejection"

type Props = {
    className: string
}
const RejectionReport: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "RejectionReport"
    }
    return (
        <>
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'RejectionReport' })}</PageTitle>
            <Routes>
                <Route path='customer-on-boarding' element={<CustomerOnBoarding className="customer-on-boarding" />} />
                <Route path='credit-request' element={<CreditRequest className="credit-request" />} />
                <Route path='order-discount' element={<OrderDiscount className="order-discount" />} />
                <Route path='load-out-requests' element={<LoadOutRequests className="load-out-requests" />} />
                <Route path='gate-pass-rejection' element={<GatePassRejectionReport />} />
                <Route path='*' element={<Navigate to="/reports/rejection-report/customer-on-boarding" />} />
            </Routes>
        </>
    )
}

export default RejectionReport
