import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  roleCreate,
  roleEdit,
} from '../../../../_eaFruitsDms/apiFunctions/rolesAndPermission/RoleAndPermission'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  fetchStaticAsyncDropdown,
  KTSVG,
} from '../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import ValidationHandler from '../../../../_eaFruitsDms/helpers/components/validationHandler'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import TableWarehouseRole from './components/tableWarehouseRole'
import {AsyncPaginate} from 'react-select-async-paginate'

type Props = {
  className: string
}

const WareHouseRoles: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const masterHeaderTitle = {
        title: "Warehouse",
        subTitle: "Branch Manager Roles"
    }

    const limit = '&limit=1000'

    const [offset, setOffset] = useState(0)
    const [search, setSearch] = useState('')
    const [role, setRole] = useState({ label: 'Branch Manager Role', value: 'wm' },)
    const [roleModal, setRoleModal] = useState('wm')
    const [roleList, setRoleList] = useState([])
    const [featureList, setFeatureList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [requestHandler, setRequestHandler] = useState('add')
    const [isModalLoading, setIsModalLoading] = useState('Submit')
    const [disableBtn, setDisableBtn] = useState(false)

    const [roleName, setRoleName] = useState('')
    const [description, setDescription] = useState('')

    const [roleValidation, setRoleValidation] = useState('')
    const [id, setId] = useState('')

    const [isAlertSuccess, setIsAlertSuccess] = useState(false)
    const [isAlertFailed, setIsAlertFailed] = useState(false)
    const [messageHandler, setMessageHandler] = useState('successAdd');
    const [messageTitle, setMessageTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [permission, setPermission] = useState(defaultPermission)
    const [modalLoader, setModalLoader] = useState<any>(false)

    useEffect(() => {
        setOffset(0)
    }, [search])

    const warehouseRolesArray = [
        // { label: 'Account Officer Role', value: 'ao' },
        // { label: 'Logistics Officer Role', value: 'lo' },
        // { label: 'Marketing Officer Role', value: 'mo' },
        // { label: 'Operations Officer Role', value: 'oo' },
        // { label: 'Sales Supervisor Role', value: 'ss' },
        { label: 'Sales Representative Role', value: 'sr' },
        // { label: 'Tele Sales Role', value: 'ts' },
        { label: 'Branch Manager Role', value: 'wm' },
    ]

    const fetchWarehouseRoles = (search: string) => {
        // label : 'label'
        // let url = 'auth/application/role/list/?user_type=WM&ordering=name'
        // return fetchAsyncDropdown(offset, setOffset, dropDownList, setDropDownList, url, search, setSearch, false, true, "All Branch Manager Roles")

        return fetchStaticAsyncDropdown(warehouseRolesArray, search)
    }

    const getRole = async () => {
        let url = apiBaseURL() + `auth/role?limit=1000&user_type=${role.value}`
        const response = await getRequest(url, true)
        setRoleList(response.results)
    }

    const getFeature = async () => {
        let url = apiBaseURL() + `auth/roles-features?user_type=${role.value}`
        const response = await getRequest(url, true)
        setFeatureList(response)
        if (response) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchPermission()

        getRole()
        getFeature()
        setRoleModal(role.value)
    }, [role.value])


    const fetchPermission = async () => {
        let res = await checkPermissions("warehouse_roles")
        setPermission(res)
    }

    const getProps = async (val: any) => {
        setId(val.id)
        setMessageHandler('successEdit')
        setRequestHandler('edit')
        setModalLoader(true)
        // RolesSingle(val.id).then((res: any) => {
        const res = await getRequest(`${apiBaseURL()}auth/role/${val.id}?user_type=${role.value}`, true)
        setRoleName(res.name)
        setDescription(res.short_description)
        setRoleModal(res.user_type)
        setModalLoader(false)
        // })
    }

    const clearFunction = () => {
        setRoleName('')
        setRoleValidation('')
        setDescription('')
        setRoleModal('wm')
        setErrorMessage("")
        setModalLoader(false)
    }

    const addRole = () => {
        if (roleName === "") { setRoleValidation('is-invalid') } else { setRoleValidation('') }
        if (roleName !== "") {
            setDisableBtn(true)
            setIsModalLoading("Please Wait..")
            if (requestHandler === 'add') {
                roleCreate(roleName, roleModal, description == "" ? null : description, role.value)
                    .then((res) => {
                        setDisableBtn(false)
                        setIsModalLoading("Submit")
                        if (res.success) {
                            setIsAlertSuccess(true)
                            getRole()
                            getFeature()
                            $('#closeAddUser').trigger('click');
                            setTimeout(() => {
                                setIsAlertSuccess(false)
                                setIsAlertFailed(false)
                            }, 1500);
                        }
                        if (res.failed) {
                            // setIsAlertFailed(true)
                        }
                        if (res.errorField == 'name') {
                            setErrorMessage(
                                'Your added data is already exist in this system! Please try again with unique data.'
                            )
                        }
                    })
            } else {
                roleEdit(id, roleName, roleModal, description == "" ? null : description
                ).then((res) => {
                    setDisableBtn(false)
                    setIsModalLoading("Submit")
                    if (res.success) {
                        setIsAlertSuccess(true)
                        getRole()
                        getFeature()
                        $('#closeAddUser').trigger('click');
                        setTimeout(() => {
                            setIsAlertSuccess(false)
                            setIsAlertFailed(false)
                        }, 1500);
                    }
                    if (res.failed) {
                        // setIsAlertFailed(true)
                    }
                    if (res.errorField == 'name') {
                        setErrorMessage(
                            'Your added data is already exist in this system! Please try again with unique data.'
                        )
                    }
                })
            }
        }
    }

    useEffect(() => {
        setMessageTitle(roleName)
    }, [roleName])


    const isAlertSHow = (val: any) => {
        if (val == 'success') {
            setIsAlertSuccess(false)
        }
        if (val == 'failed') {
            setIsAlertFailed(false)
        }
    }

    const scroll: any = document.getElementById('smoothscrolling')
    const slide = (shift: any) => {
        scroll.scrollLeft += shift
    }

    return (
        <>
            <EATitle title='Branch Roles' />
            <div>
                <PageTitle breadcrumbs={[masterHeaderTitle]} >{intl.formatMessage({ id: 'Branch Manager Roles' })}</PageTitle>
                <EaAlertSuccess
                    onClick={() => isAlertSHow('success')}
                    show={isAlertSuccess}
                    message={
                        messageHandler === 'successAdd' ? FormatMessage('ROLE.ADD', messageTitle) : FormatMessage('ROLE.UPDATE', messageTitle)
                    }
                />

                <EaAlertDanger
                    onClick={() => isAlertSHow('failed')}
                    show={isAlertFailed}
                    message={
                        messageHandler === 'successAdd' ? "Role Add Failed" : "Role Edit Failed"
                    }
                />
                <div className={`card ${className}`}>
                    <div className='card-header border-0 px-7'>
                        <div className='card-toolbar row g-0 w-100'>
                            {permission.write &&
                            <AsyncPaginate
                                loadOptions={fetchWarehouseRoles}
                                isSearchable
                                className='react-select-container w-220px'
                                classNamePrefix='react-select'
                                value={role}
                                onChange={(e: any) => setRole(e)}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />}
                            <div className="col-12 col-md-auto ms-auto mt-3 mt-md-0">
                                <button
                                    onClick={() => slide(-300)}
                                    className='btn btn-icon py-0 text-hover-primary btn-sm'
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr063.svg'
                                        className='svg-icon-1 me-0'
                                    />
                                </button>
                                <button
                                    onClick={() => slide(300)}
                                    className='btn btn-icon py-0 text-hover-primary btn-sm me-3'
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-1' />
                                </button>
                                {permission.write &&
                                    <EaButton isModal={true} btnName='Add Role' dataTarget='#ea_modal_warehouserole' onClick={() => {
                                        setRequestHandler('add')
                                        setMessageHandler('successAdd')
                                    }} />}
                            </div>
                        </div>
                    </div>
                    <div className='card-body py-4'>
                        <div className='table-responsive smoothscroll' id='smoothscrolling'>
                            {
                                isLoading ? <IsLoader /> :
                                    <>
                                        {featureList.length === 0 ? <NoRecords /> : <TableWarehouseRole
                                            roleList={roleList}
                                            featureList={featureList}
                                            getProps={getProps}
                                            showWrite={permission.write}
                                        />}
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* <AddWarehouseRole /> */}

            <EaModal id='ea_modal_warehouserole' modalTitle={
                requestHandler === 'add' ? 'Add New Role' : 'Edit New Role'
            }
                actionBtnName={isModalLoading}
                btnDisabled={disableBtn}
                onSubmit={addRole}
                cancel={clearFunction}
                closeAuto="closeAddUser"
                loader={modalLoader}
            >
                <div className="row">
                    <div className="col-sm-12">
                        <div className="mb-4">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                className={`form-control ${roleValidation}`}
                                placeholder="Type here..."
                                value={roleName}
                                onChange={(e) => {
                                    setRoleName(e.target.value)
                                    const validation = ValidationHandler(e.target.value, "is-invalid")
                                    setRoleValidation(validation)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="">
                    <label className="form-label">Short Description (Optional)</label>
                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="form-control" placeholder="Type here..."></textarea>
                </div>
                <div className='mt-4'>
                    <ErrorHandler errorMessage={errorMessage} />
                </div>
            </EaModal>
        </>
    )
}
export default WareHouseRoles
