import React from "react"

const TableVehicalReport: React.FC = () => {

    return (
        <>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                {/* begin::Table head */}
                <thead>
                    <tr className='text-muted fw-500'>
                        <th className='min-w-150px'>Warehouse Name</th>
                        <th className='min-w-140px'>Warehouse Type</th>
                        <th className='min-w-120px'>Parent Warehouse</th>
                        <th className='min-w-120px'>Region</th>
                        <th className='min-w-80px py-2'>Vehicle Count</th>
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    <tr>
                        <td className=''>Warehouse 1</td>
                        <td className=''>Headquarter</td>
                        <td className=''>—</td>
                        <td className=''>Dar es Salaam</td>
                        <td className=''>32</td>
                    </tr>
                    <tr>
                        <td className=''>Warehouse 2</td>
                        <td className=''>Distribution Center</td>
                        <td className=''>Warehouse 1</td>
                        <td className=''>Kahama</td>
                        <td className=''>42</td>
                    </tr>
                    <tr>
                        <td className=''>Warehouse 3</td>
                        <td className=''>Headquarter</td>
                        <td className=''>—</td>
                        <td className=''>Dodoma</td>
                        <td className=''>22</td>
                    </tr>
                    <tr>
                        <td className=''>Warehouse 4</td>
                        <td className=''>Headquarter</td>
                        <td className=''>—</td>
                        <td className=''>Dar es Salaam</td>
                        <td className=''>54</td>
                    </tr>
                    <tr>
                        <td className=''>Warehouse 5</td>
                        <td className=''>Distribution Center</td>
                        <td className=''>Warehouse 3</td>
                        <td className=''>Zanzibar City</td>
                        <td className=''>32</td>
                    </tr>
                    <tr>
                        <td className=''>Warehouse 6</td>
                        <td className=''>Headquarter</td>
                        <td className=''>—</td>
                        <td className=''>Singida</td>
                        <td className=''>85</td>
                    </tr>
                    <tr>
                        <td className=''>Warehouse 7</td>
                        <td className=''>Headquarter</td>
                        <td className=''>—</td>
                        <td className=''>Tanga</td>
                        <td className=''>12</td>
                    </tr>
                    <tr>
                        <td className=''>Warehouse 8</td>
                        <td className=''>Distribution Center</td>
                        <td className=''>Warehouse 7</td>
                        <td className=''>Iringa</td>
                        <td className=''>22</td>
                    </tr>
                    <tr>
                        <td className=''>Warehouse 9</td>
                        <td className=''>Headquarter</td>
                        <td className=''>—</td>
                        <td className=''>Dar es Salaam</td>
                        <td className=''>32</td>
                    </tr>
                    <tr>
                        <td className=''>Warehouse 10</td>
                        <td className=''>Distribution Center</td>
                        <td className=''>Warehouse 7</td>
                        <td className=''>Iringa</td>
                        <td className=''>10</td>
                    </tr>
                </tbody>
                {/* end::Table body */}
            </table>
        </>
    )
}

export default TableVehicalReport
