import clsx from 'clsx'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  dateFormat,
  eaCloseModal,
  goToTop,
  isEmpty,
  KTSVG,
  measurementLabel,
  numberFormat,
} from '../../../_eaFruitsDms/helpers'
import {getRequest, patchRequest} from '../../../_eaFruitsDms/https/apiCall'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {rejectionValidation} from '../customers/WMcustomers/CustomerConst'
import $ from 'jquery'
import {EaAlertDanger} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'

type Props = {
  className: string
}
const LoadOutRequestsSR: React.FC<Props> = ({ className }) => {
	const intl = useIntl()
	const navigate = useNavigate()
	const headerTitle = {
		title: "Stock-Out",
		subTitle: "Load-Out Requests from SR"
	}
	const { state }: any = useLocation()

	const requestType: any = {
		WithPreorder: "With Pre-order(s)",
		WithoutPreorder: "Without Pre-Order"
	}

	const initialModalData: any = {
		title: "",
		request_id: 0,
		requested_qty: 0,
		product_sku_name: "",
		inventories: []
	}

	const initialLoadOutRequestObj = {
		load_out_products: [],
		request_status: "",
		oo_status: "",
		inventory_batch: []
	}

	const initialPartialProduct = {
		current_index: 0,
		product_name: "-",
		required_quantity: 0,
		allowed_quantity: 0
	}

	let initialRejectReason = {
		reason: "",
		reason_label: "",
		reason_note: ""
	}

	const [loadOutRequest, setLoadOutRequest] = useState<any>(null)
	const [loadOutRequestObject, setLoadOutRequestObject] = useState<any>(initialLoadOutRequestObj)
	const [partialApproveProducts, setPartialApproveProducts] = useState<any>([])
	const [partialProductObject, setPartialProductObject] = useState<any>(initialPartialProduct)
	const [errorMessage, setErrorMessage] = useState("")
	const [isAlertFailed, setIsAlertFailed] = useState(false)
	const [displayLoader, setDisplayLoader] = useState<boolean>(false)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [disableQuantity, setDisableQuantity] = useState<boolean>(false)
	const [disableButton, setDisableButton] = useState<boolean>(false)
	const [modalData, setModalData] = useState<any>(initialModalData)
	const [totalQuantity, setTotalQuantity] = useState<any>(0)
	const [loadOutRequestType, setLoadOutRequestType] = useState<string>("PartiallyApproved")
	const [loadOutRequestStatus, setLoadOutRequestStatus] = useState<string>("Approved")
	const [loadOutRejectionValidation, setLoadOutRejectionValidation] = useState<any>(rejectionValidation)
	const [rejectReasonOffSet, setRejectReasonOffSet] = useState<any>("")
	const [rejectReasonOption, setRejectReasonOption] = useState<any>([])
	const [rejectReason, setRejectReason] = useState<any>(initialRejectReason)
	const [partialApproveReasonOffSet, setPartialApproveReasonOffSet] = useState<any>("")
	const [partialApproveReasonOption, setPartialApproveReasonOption] = useState<any>([])
	const [partialApproveReason, setPartialApproveReason] = useState<any>(initialRejectReason)
	const [partialApproveReasonValidation, setPartialApproveReasonValidation] = useState<any>(rejectionValidation)
	const [partialApproveReasonMultiple, setPartialApproveReasonMultiple] = useState<any>([initialRejectReason])
	const [partialApproveReasonValidationMultiple, setPartialApproveReasonValidationMultiple] = useState<any>([rejectionValidation])
	const [searchSelect, setSearchSelect] = useState<string>("")
	const [buttonText, setButtonText] = useState<any>([])
	const [showRejectModal, setShowRejectModal] = useState<boolean>(false)
	const [zeroQty, setZeroQty] = useState<boolean>(true)
	const [reasonModelRequired, setReasonModelRequired] = useState<boolean>(false)

	useEffect(() => {
		if (isEmpty(state)) {
			navigate('/load-out-requests')
		}
		fetchLoadOutRequestDetails().then()
	}, [])

	useEffect(() => {
		let qty: boolean = true
		let modelVisible: boolean = false

		const updatedButtonText: any = [...buttonText]
		const updatedProducts: any = []

		if (loadOutRequestObject.load_out_products.length > 0) {
			loadOutRequestObject.load_out_products.map((request: any, index: number) => {
				if (request.approved_qty > 0 || (!["", null, undefined].includes(request.reason) || !["", null, undefined].includes(request.reason_note))) {
					updatedButtonText[index] = "Update"
				}
				let quantityLabel = measurementLabel(
					request.product_sku.fix_size
						? request.product_sku.material.name
						: request.product_sku.approx_weight > 0
							? request.product_sku.material.name
							: request.product_sku.measurement_unit
				)
				//
				let multiplyValue = (request.product_sku.measurement_unit.toLowerCase() === "gram") ? (request.product_sku.size / 1000) : request.product_sku.size
				let canPush = false

				if (request.approved_qty === 0 || request.approved_qty < request.requested_qty) {
					if (["", null, undefined].includes(request.reason)) {
						if (["", null, undefined].includes(request.reason_note)) {
							modelVisible = true
							canPush = true
						} else {
							modelVisible = false
						}
					} else {
						modelVisible = false
					}
				} else {
					modelVisible = false
				}

				if (canPush) {
					updatedProducts.push({
						id: loadOutRequestObject.load_out_products[index].id,
						product_id: loadOutRequestObject.load_out_products[index].product_sku.id,
						product_name: loadOutRequestObject.load_out_products[index].product_sku.name,
						requested_qty: loadOutRequestObject.load_out_products[index].requested_qty,
						quantity_label: quantityLabel,
						multiply_value: multiplyValue
					})
				}

				if (request.approved_qty > 0) {
					qty = false
				}
			})
		}
		setPartialApproveProducts(updatedProducts)
		setReasonModelRequired(modelVisible)
		setButtonText(updatedButtonText)

		if (qty) {
			setLoadOutRequestType("PartiallyApproved")
		}
		setZeroQty(qty)
	}, [loadOutRequestObject])

	const fetchLoadOutRequestDetails = async () => {
		if (!isEmpty(state)) {
			setDisplayLoader(true)
			const updatedButtonText: any = [...buttonText]
			const response = await getRequest(`${apiBaseURL()}preorder/load_out_request/${state.requestId}/`, true)

			if (response) {
				const updatedProducts = [...partialApproveProducts]
				const updatedPartialApproveReasonValidationMultiple = [...partialApproveReasonValidationMultiple]
				const updatedLoadOutRequestObject = { ...loadOutRequestObject }

				response.load_out_products.map((res: any, index: number) => {
					updatedButtonText.push("Process")
					let quantityLabel = measurementLabel(
						res.product_sku.fix_size
							? res.product_sku.material.name
							: res.product_sku.approx_weight > 0
								? res.product_sku.material.name
								: res.product_sku.measurement_unit
					)

					let multiplyValue = (res.product_sku.measurement_unit.toLowerCase() === "gram") ? (res.product_sku.size / 1000) : res.product_sku.size

					updatedProducts.push({
						id: res.id,
						product_id: res.product_sku.id,
						product_name: res.product_sku.name,
						requested_qty: res.requested_qty,
						quantity_label: quantityLabel,
						multiply_value: multiplyValue
					})

					if (index === 0) {
						updatedPartialApproveReasonValidationMultiple[index] = rejectionValidation
					} else {
						updatedPartialApproveReasonValidationMultiple.push(rejectionValidation)
					}

					res.approved_qty = res.approved_qty || 0
					res.reason_label = res.reason_note || ""

					updatedLoadOutRequestObject.load_out_products.push(res)
				})

				setButtonText(updatedButtonText)
				setPartialApproveProducts(updatedProducts)
				setPartialApproveReasonValidationMultiple(updatedPartialApproveReasonValidationMultiple)
				setLoadOutRequestObject(updatedLoadOutRequestObject)
				setLoadOutRequest(response)
			}

			setDisplayLoader(false)
		}
	}

	const fetchRejectReasons = async () => {
		if (searchSelect !== "") {
			setRejectReasonOffSet("")
		}
		let rejectReasonResponse = await getRequest(
			`${apiBaseURL()}master/options-master/?type=Loadout_Request_Rejection&search=${searchSelect}&limit=10&offset=${rejectReasonOffSet}`,
			true
		)

		let options: any = []
		let optionData: any = []

		let hasMore: boolean = false

		if (rejectReasonResponse.next !== null) {
			const queryParams = new URLSearchParams(rejectReasonResponse.next)
			let newOffset: any = queryParams.get("offset")
			setRejectReasonOffSet(newOffset)
			hasMore = true
		}

		if (rejectReasonResponse.results.length > 0) {
			rejectReasonResponse.results.map((option: any) => {
				optionData.push(option)
				options.push({
					label: option.option,
					value: option.id,
				})
			})
		}

		if (rejectReasonOption.length > 0) {
			setRejectReasonOption(rejectReasonOption.concat(options))
		} else {
			setRejectReasonOption(options)
		}

		const otherOptionExist = options.some((obj: any) => Object.is(obj.label, "Other"))

		if (!otherOptionExist) {
			options.push({
				label: "Other",
				value: null
			})
		}

		return {
			options: options,
			hasMore: hasMore,
		}
	}

	const fetchPartialApproveReasons = async () => {
		if (searchSelect !== "") {
			setPartialApproveReasonOffSet("")
		}
		let partialApproveReasonResponse = await getRequest(
			`${apiBaseURL()}master/options-master/?type=Loadout_Partial_Approve&search=${searchSelect}&limit=10&offset=${partialApproveReasonOffSet}`,
			true
		)

		let options: any = []
		let optionData: any = []

		let hasMore: boolean = false

		if (partialApproveReasonResponse.next !== null) {
			const queryParams = new URLSearchParams(partialApproveReasonResponse.next)
			let newOffset: any = queryParams.get("offset")
			setPartialApproveReasonOffSet(newOffset)
			hasMore = true
		}

		if (partialApproveReasonResponse.results.length > 0) {
			partialApproveReasonResponse.results.map((option: any) => {
				optionData.push(option)
				options.push({
					label: option.option,
					value: option.id,
				})
			})
		}

		if (partialApproveReasonOption.length > 0) {
			setPartialApproveReasonOption(partialApproveReasonOption.concat(options))
		} else {
			setPartialApproveReasonOption(options)
		}

		const otherOptionExist = options.some((obj: any) => Object.is(obj.label, "Other"))

		if (!otherOptionExist) {
			options.push({
				label: "Other",
				value: null
			})
		}

		return {
			options: options,
			hasMore: hasMore,
		}
	}

	const generateModalData = (request: any, quantityLabel: string, multiplyValue: number) => {
		setModalData(initialModalData)
		setDisableQuantity(false)
		const updatedModalData: any = { ...modalData }

		updatedModalData["title"] = `Select Batch Wise ${request.requested_qty} Qty. for ${request.product_sku.name}`
		updatedModalData["request_id"] = request.id
		updatedModalData["product_sku_name"] = request.product_sku.name
		updatedModalData["requested_qty"] = request.requested_qty
		updatedModalData["quantity_label"] = quantityLabel
		updatedModalData["multiply_value"] = multiplyValue

		let calculateQuantity: any = 0

		request.inventory.map((inventory: any) => {
			const index = loadOutRequestObject.inventory_batch.findIndex((x: any) => x.id === inventory.id)

			let quantity = 0

			if (index > -1) {
				quantity = parseInt(loadOutRequestObject.inventory_batch[index].units)
				calculateQuantity += quantity
			}

			if (request.requested_qty === parseInt(calculateQuantity)) {
				setDisableQuantity(true)
			}

			setTotalQuantity(calculateQuantity)

			const inventoryObj = {
				id: inventory.id,
				batch_number: inventory.batch_number,
				expiry_date: inventory.expiry_date,
				quantity: quantity > 0 ? quantity : "",
				units: inventory.units,
				updated_date: inventory.updated_date
			}

			updatedModalData["inventories"].push(inventoryObj)

			return updatedModalData
		})
		setModalData(updatedModalData)
	}

	const handleChangeRow = (e: any, index: number, units: number) => {
		let updatedModalData: any = { ...modalData }
		setDisableQuantity(false)
		let { name, value } = e.target
		let isValid: boolean = true
		value = value.replace(/\D/g, "")

		if (value === "") {
			value = 0
		}
		switch (name) {
			case "quantity":
				updatedModalData.inventories[index].quantity = parseInt(value)

				if (value > units) {
					updatedModalData.inventories[index].quantity_validation = "is-invalid"
					updatedModalData.inventories[index].quantity_validation_color = "#FF0000"
					isValid = false
				} else {
					updatedModalData.inventories[index].quantity_validation = ""
					updatedModalData.inventories[index].quantity_validation_color = ""
					isValid = true
				}
				break
		}
		let totalEnteredQuantity: number = 0

		updatedModalData.inventories.map((inventory: any, newIndex: number) => {
			if (inventory.quantity) {
				totalEnteredQuantity += parseInt(inventory.quantity)

				setDisableQuantity(false)
				if (totalEnteredQuantity === parseInt(modalData.requested_qty)) {
					setDisableQuantity(true)
				} else {
					if (totalEnteredQuantity > parseInt(modalData.requested_qty)) {
						updatedModalData.inventories[index].quantity_validation = "is-invalid"
						updatedModalData.inventories[index].quantity_validation_color = "#FF0000"
						setDisableQuantity(true)
						isValid = false
					} else {
						if (units >= parseInt(modalData.requested_qty)) {
							updatedModalData.inventories[newIndex].quantity_validation = ""
							updatedModalData.inventories[newIndex].quantity_validation_color = ""
						}
					}
				}
			}
		})

		if (!isValid) {
			setDisableButton(true)
		} else {
			setDisableButton(false)
		}

		setTotalQuantity((prevState: any) => {
			if (totalEnteredQuantity > parseInt(modalData.requested_qty)) {
				return prevState
			}
			return totalEnteredQuantity
		})

		setModalData(updatedModalData)
	}

	const onSubmit = () => {
		const updatedLoadOutRequestObject: any = { ...loadOutRequestObject }
		let type: string = "Approved"
		if (updatedLoadOutRequestObject.load_out_products.length > 0) {
			let index = updatedLoadOutRequestObject.load_out_products.findIndex((x: any) => x.id === modalData.request_id)
			if (modalData.requested_qty !== totalQuantity) {
				type = "PartiallyApproved"
			}

			if (index > -1) {
				updatedLoadOutRequestObject.load_out_products[index].approved_qty = parseInt(totalQuantity)
				updatedLoadOutRequestObject.load_out_products[index].reason = partialApproveReason.reason
				updatedLoadOutRequestObject.load_out_products[index].reason_label = partialApproveReason.reason
				updatedLoadOutRequestObject.load_out_products[index].reason_note = partialApproveReason.reason_note
				updatedLoadOutRequestObject.load_out_products[index].status = (modalData.requested_qty === totalQuantity) ? "Full" : "Partial"
			} else {
				updatedLoadOutRequestObject.load_out_products.push({
					id: modalData.request_id,
					approved_qty: parseInt(totalQuantity),
					reason: partialApproveReason.reason,
					reason_label: partialApproveReason.reason,
					reason_note: partialApproveReason.reason_note,
					status: (modalData.requested_qty === totalQuantity) ? "Full" : "Partial"
				})
			}
		} else {
			updatedLoadOutRequestObject.load_out_products.push({
				id: modalData.request_id,
				approved_qty: parseInt(totalQuantity),
				reason: partialApproveReason.reason,
				reason_label: partialApproveReason.reason,
				reason_note: partialApproveReason.reason_note,
				status: (modalData.requested_qty === totalQuantity) ? "Full" : "Partial"
			})
		}

		if (modalData.inventories.length > 0) {
			modalData.inventories.map((inventory: any) => {
				if (inventory.quantity !== "") {
					const index = updatedLoadOutRequestObject.inventory_batch.findIndex((x: any) => x.id === inventory.id)

					if (index > -1) {
						updatedLoadOutRequestObject.inventory_batch[index].units = inventory.quantity
					} else {
						updatedLoadOutRequestObject.inventory_batch.push({
							id: inventory.id,
							units: inventory.quantity
						})
					}
				}
			})
		}

		updatedLoadOutRequestObject.load_out_products.map((loadOutRequest: any, index: number) => {
			if (loadOutRequest.status === "Partial") {
				setPartialProductObject({
					...partialProductObject,
					["current_index"]: index,
					["product_name"]: modalData.product_sku_name,
					["required_quantity"]: modalData.requested_qty,
					["allowed_quantity"]: loadOutRequest.approved_qty
				})
			}
		})
		updateLoadOutRequestType()
		setLoadOutRequestObject(updatedLoadOutRequestObject)

		eaCloseModal()
		if (type === "PartiallyApproved") {
			setTimeout(() => {
				setShowModal(true)
			}, 300)

		}
	}

	const submitLoadOutRequest = async () => {
		let isValid: boolean = true

		const updateReason: any = [...partialApproveReasonValidationMultiple]

		let updatedLoadOutRequest: any = {
			inventory_batch: loadOutRequestObject.inventory_batch || [],
			load_out_products: [],
			request_status: loadOutRequestStatus,
			oo_status: "Processed"
		}

		loadOutRequestObject.load_out_products.map((request: any, index: number) => {
			if (request.status === "Partial") {
				if (updateReason[index] && updateReason[index].reason === null) {
					if (updateReason[index].reason_note === "") {
						isValid = false
						updateReason[index].comments = "is-invalid"
					}
				} else {
					if (updateReason[index] && updateReason[index].reason === "") {
						isValid = false
						updateReason[index].option = "is-invalid"
					}
				}
			}
			updatedLoadOutRequest.load_out_products.push({
				id: request.id,
				approved_qty: request.approved_qty,
				reason: request.reason,
				reason_note: request.reason_note,
				status: request.status ? request.status : "Partial"
			})
		})

		setPartialApproveReasonValidationMultiple(updateReason)
		if (isValid) {
			loadOutRequestObject.request_status = loadOutRequestStatus
			loadOutRequestObject.oo_status = "Processed"

			setDisplayLoader(true)
			const res = await patchRequest(`${apiBaseURL()}preorder/update_load_out_request/${state.requestId}/`, updatedLoadOutRequest, true)

			if (res && !isEmpty(res.status) && res.status === 400) {
				setErrorMessage("This order already processing by some one else.!")
				clearFunction()
				goToTop()
				setIsAlertFailed(true)

				setDisplayLoader(false)
				if (requestType !== "Approved") {
					eaCloseModal("partial-approve")
				}

				setTimeout(() => {
					setIsAlertFailed(false)
				}, 3500)
			} else {
				setDisplayLoader(false)
				if (requestType !== "Approved") {
					eaCloseModal("partial-approve")
				}
				navigate("/load-out-requests")
			}
		}
	}

	const handleRejectReason = (e: any, fromDropDown?: boolean) => {
		if (fromDropDown) {
			setLoadOutRejectionValidation({ ...loadOutRequestObject, ["option"]: "" })
			setRejectReason({
				reason: e.value || null,
				reason_label: e.lable || null,
				reason_note: ""
			})
		} else {
			setLoadOutRejectionValidation({ ...loadOutRequestObject, ["comments"]: "" })
			setRejectReason({
				reason: null,
				reason_label: null,
				reason_note: e.target.value
			})
		}
	}

	const handlePartialApproveReason = (e: any, fromDropDown?: boolean) => {
		setPartialApproveReasonValidation(rejectionValidation)
		const updatedPartialReason = { ...partialApproveReason }
		if (fromDropDown) {
			updatedPartialReason["reason"] = e.value
			updatedPartialReason["reason_label"] = e.label
			updatedPartialReason["reason_note"] = ""
		} else {
			updatedPartialReason["reason"] = null
			updatedPartialReason["reason_label"] = ""
			updatedPartialReason["reason_note"] = e.target.value
		}

		setPartialApproveReason(updatedPartialReason)
	}

	const handlePartialApproveReasonMultiple = (e: any, index: number, fromDropDown?: boolean) => {
		const updatedPartialReason = [...partialApproveReasonMultiple]
		const updatedPartialReasonValidation: any = [...partialApproveReasonValidationMultiple]
		const updateLoadOutRequestObj = { ...loadOutRequestObject }
		const loadOutProducts = [...partialApproveProducts]

		let reason = fromDropDown ? e.value : null
		let reason_label = fromDropDown ? e.label : ""

		if (updateLoadOutRequestObj.load_out_products.length === 0) {
			loadOutRequest.load_out_products.map((request: any) => {
				updateLoadOutRequestObj.load_out_products.push({
					id: request.id,
					approved_qty: 0,
					reason: "",
					reason_label: "",
					reason_note: "",
					status: "Partial"
				})
			})
		}

		updatedPartialReasonValidation[index] = {
			option: "",
			comments: "",
		}

		setPartialApproveReasonValidationMultiple(updatedPartialReasonValidation)

		if (fromDropDown) {
			reason = e.value
			reason_label = e.label

			if (updatedPartialReason[index]) {
				updatedPartialReason[index].reason = reason
				updatedPartialReason[index].reason_label = reason_label
				updatedPartialReason[index].reason_note = ""
			} else {
				updatedPartialReason.push({
					reason: reason,
					reason_label: reason_label,
					reason_note: ""
				})
			}
		} else {
			if (updatedPartialReason[index]) {
				updatedPartialReason[index].reason = null
				updatedPartialReason[index].reason_label = ""
				updatedPartialReason[index].reason_note = e.target.value
			} else {
				updatedPartialReason.push({
					reason: null,
					reason_label: "",
					reason_note: e.target.value
				})
			}
		}

		const findIndexObj = updateLoadOutRequestObj.load_out_products.findIndex((product: any) => product.id === loadOutProducts[index].id)

		if (findIndexObj > -1) {
			updateLoadOutRequestObj.load_out_products[findIndexObj].reason = reason
			updateLoadOutRequestObj.load_out_products[findIndexObj].reason_label = reason_label
			updateLoadOutRequestObj.load_out_products[findIndexObj].reason_note = e.target ? e.target.value : ""
		}

		setLoadOutRequestObject(updateLoadOutRequestObj)
		setPartialApproveReasonMultiple(updatedPartialReason)
	}

	const rejectLoadOutRequest = async () => {
		let isValid: boolean = true
		if (rejectReason.reason === null) {
			if (rejectReason.reason_note === "") {
				setLoadOutRejectionValidation({ ...loadOutRequestObject, ["comments"]: "is-invalid" })
				isValid = false
			}
		} else {
			if (rejectReason.reason === "") {
				isValid = false
				setLoadOutRejectionValidation({ ...loadOutRequestObject, ["option"]: "is-invalid" })
			}
		}

		if (isValid) {
			const rejectedData = {
				request_status: "Rejected",
				oo_status: "Processed",
				loadout_reason: rejectReason.reason,
				loadout_reason_note: rejectReason.reason_note
			}
			setDisplayLoader(true)
			const res = await patchRequest(`${apiBaseURL()}preorder/update_load_out_request/${state.requestId}/`, rejectedData, true)

			setDisplayLoader(false)


			if (res && !isEmpty(res.status) && res.status === 400) {
				setErrorMessage("This order already processing by some one else.!")
				goToTop()
				setShowRejectModal(false)
				setIsAlertFailed(true)
				setRejectReasonOffSet("")
				setRejectReasonOption([])

				setTimeout(() => {
					setIsAlertFailed(false)
				}, 3500)
			} else {
				navigate("/load-out-requests")
			}
		}
	}

	const clearFunction = () => {
		setModalData(initialModalData)
		setTotalQuantity(0)
		setDisableQuantity(false)
		setDisableButton(false)
		setPartialApproveReasonValidation(rejectionValidation)
	}


	const hideModel = () => {
		setShowModal(false)
		setPartialApproveReasonValidation(rejectionValidation)
		setPartialApproveReason(initialRejectReason)
		setPartialProductObject(initialPartialProduct)
	}

	const submitPartialApproveReason = () => {
		const updateLoadOutRequestObj = { ...loadOutRequestObject }
		let isValid: boolean = true

		if (partialApproveReason.reason === null) {
			if (partialApproveReason.reason_note === "") {
				setPartialApproveReasonValidation({ ...partialApproveReasonValidation, ["comments"]: "is-invalid" })
				isValid = false
			}
		} else {
			if (partialApproveReason.reason === "") {
				isValid = false
				setPartialApproveReasonValidation({ ...partialApproveReasonValidation, ["option"]: "is-invalid" })
			}
		}

		if (isValid) {
			updateLoadOutRequestObj.load_out_products[partialProductObject.current_index].reason = partialApproveReason.reason
			updateLoadOutRequestObj.load_out_products[partialProductObject.current_index].reason_label = partialApproveReason.reason_note !== "" ? partialApproveReason.reason_note : partialApproveReason.reason_label
			updateLoadOutRequestObj.load_out_products[partialProductObject.current_index].reason_note = partialApproveReason.reason_note

			setLoadOutRequestType("PartialApproved")

			setLoadOutRequestObject(updateLoadOutRequestObj)
			hideModel()
		}
	}

	const updateLoadOutRequestType = () => {
		let isApproved: boolean = true
		const updateLoadOutRequestObj = { ...loadOutRequestObject }

		let updatedProducts: any = [...partialApproveProducts]
		let requestStatus: string = "Approved"
		updateLoadOutRequestObj.load_out_products.map((request: any) => {
			if (request.status === "Partial") {
				requestStatus = "PartiallyApproved"
			}
			if (request.status !== "Full" && (["", null].includes(request.reason) || ["", null].includes(request.reason_note))) {
				isApproved = false
				const findIndexFromObject = loadOutRequest.load_out_products.findIndex((req: any) => req.id === request.id)

				if (findIndexFromObject > -1) {
					const findIndexOfProduct = updatedProducts.findIndex((req: any) => req.id === request.id)

					if (findIndexOfProduct === -1) {
						let quantityLabel = measurementLabel(
							loadOutRequest.load_out_products[findIndexFromObject].product_sku.fix_size
								? loadOutRequest.load_out_products[findIndexFromObject].product_sku.material.name
								: loadOutRequest.load_out_products[findIndexFromObject].product_sku.approx_weight > 0
									? loadOutRequest.load_out_products[findIndexFromObject].product_sku.material.name
									: loadOutRequest.load_out_products[findIndexFromObject].product_sku.measurement_unit
						)

						let multiplyValue = (loadOutRequest.load_out_products[findIndexFromObject].product_sku.measurement_unit.toLowerCase() === "gram") ? (loadOutRequest.load_out_products[findIndexFromObject].product_sku.size / 1000) : loadOutRequest.load_out_products[findIndexFromObject].product_sku.size

						updatedProducts.push({
							id: loadOutRequest.load_out_products[findIndexFromObject].id,
							product_id: loadOutRequest.load_out_products[findIndexFromObject].product_sku.id,
							product_name: loadOutRequest.load_out_products[findIndexFromObject].product_sku.name,
							requested_qty: loadOutRequest.load_out_products[findIndexFromObject].requested_qty,
							quantity_label: quantityLabel,
							multiply_value: multiplyValue
						})
					}
				}
			} else {
				updatedProducts = updatedProducts.filter((value: any) => value.id !== request.id)
			}
		})

		setLoadOutRequestStatus(requestStatus)

		if (updateLoadOutRequestObj.load_out_products.length === loadOutRequest.load_out_products.length && isApproved) {
			setLoadOutRequestType("Approved")
		} else {
			setPartialApproveProducts(updatedProducts)
			setLoadOutRequestType("PartiallyApproved")
		}
	}

	const extendedLoadOptions = useCallback(
		async () => {
			return await fetchPartialApproveReasons()
		},
		[]
	)

	const zeroQtySubmit = () => {
		eaCloseModal("reject_cancel_zero_qty")
		setShowRejectModal(true)

	}
	const hideRejectModel = () => {
		setShowRejectModal(false)
		setLoadOutRejectionValidation(rejectionValidation)
		setRejectReasonOffSet("")
		setRejectReason(initialRejectReason)
	}

	const isAlertShow = (val: any) => {
		if (val === "failed") {
			setIsAlertFailed(false)
		}
	}

	return (
		<>
			<PageTitle
				breadcrumbs={[headerTitle]} backNavigate='/load-out-requests'>{intl.formatMessage({ id: "Load-Out Requests from SR" })}</PageTitle>
			<EaAlertDanger onClick={() => isAlertShow("failed")} show={isAlertFailed} message={errorMessage} />

			<div className={`card ${className}`}>
				{displayLoader && <IsLoader />}
				{/* begin::Header */}
				<div className="card-header border-0 py-3 py-md-2 px-5 px-md-7">
					{/* begin::Title */}
					<h3 className="card-title align-items-start flex-column my-0">
						<span
							className="card-label fw-bold font-17">Load-Out Requests #{loadOutRequest && loadOutRequest.request_id}# from #{loadOutRequest && loadOutRequest.request_from ? loadOutRequest.request_from.first_name + " " + loadOutRequest.request_from.last_name : "-"}#</span>
					</h3>
					{/* end::Title */}
				</div>
				{/* end::Header */}
				{/* begin::Body */}
				<div className="card-body pt-3 pb-3">
					<div className="row align-items-end mb-3">
						<div className="col-md-4 col-lg-3 col-xl mb-2">
							<label className="form-label text-muted">Request ID</label>
							<div>#{loadOutRequest && loadOutRequest.request_id}</div>
						</div>
						<div className="col-md-4 col-lg-3 col-xl mb-2">
							<label className="form-label text-muted">Requested On</label>
							<div>{loadOutRequest && dateFormat(loadOutRequest.load_out_request_date).displayDate} {loadOutRequest && moment(loadOutRequest.load_out_request_time, "HH:mm:ss.SSSSSS").format("hh:mm A")}</div>
						</div>
						{/*load_out_request_time*/}
						<div className="col-md-4 col-lg-3 col-xl mb-2">
							<label className="form-label text-muted">Request Type</label>
							<div>{loadOutRequest ? requestType[loadOutRequest.request_type] : "-"}</div>
						</div>
						<div className="col-md-4 col-lg-3 col-xl mb-2">
							<label className="form-label text-muted">Requested By</label>
							<div>{loadOutRequest && loadOutRequest.request_from ? loadOutRequest.request_from.first_name + " " + loadOutRequest.request_from.last_name : "-"}</div>
						</div>
						{loadOutRequest && requestType[loadOutRequest.request_type] === "With Pre-order(s)" && <>
							<div className="col-md-4 col-lg-3 col-xl mb-2">
								<label className="form-label text-muted">Connected Pre-order(s)</label>
								<div>
									{loadOutRequest ? loadOutRequest.pre_order.length : 0} Pre-Orders
									{/*<span*/}
									{/*className="text-primary cursor-pointer ms-1">View</span>*/}
								</div>
							</div>
						</>}
					</div>
					{/* begin::Table container */}
					<div className="table-responsive border-top">
						{
							!loadOutRequest || loadOutRequest.load_out_products.length === 0 ? <NoRecords />
								:
								<table
									className="table table-row-gray-100 table-row-bordered align-middle gy-3 mt-4 gx-10">
									{/* begin::Table head */}
									<thead>
										<tr className="text-muted fw-500 bg-light2 border-bottom">
											<th className="min-w-220px">Product SKU</th>
											<th className="w-120px min-w-150px">Requested Qty.</th>
											<th className="w-120px min-w-180px">Other's Requested Qty.</th>
											<th className="w-120px min-w-120px">Current Stock</th>
											<th className="w-120px min-w-120px">Allow Qty.</th>
											<th className="min-w-200px">Reason</th>
											<th className="min-w-80px"></th>
										</tr>
									</thead>
									{/* end::Table head */}
									{/* begin::Table body */}
									<tbody>
										{
											loadOutRequest.load_out_products.map((request: any, index: number) => {
												let quantityLabel = measurementLabel(
													request.product_sku.fix_size
														? request.product_sku.material.name
														: request.product_sku.approx_weight > 0
															? request.product_sku.material.name
															: request.product_sku.measurement_unit
												)

												let multiplyValue = (request.product_sku.measurement_unit.toLowerCase() === "gram") ? (request.product_sku.size / 1000) : request.product_sku.size

												return <tr>
													<td>{request.product_sku.name}</td>
													<td>
														{numberFormat(request.requested_qty).displayFormat} {quantityLabel}
														<div
															className="text-muted mt-1">{numberFormat(request.requested_qty * multiplyValue).displayFormat} Kgs.
														</div>
													</td>
													<td>
														{numberFormat(request.others_requested).displayFormat} {quantityLabel}
														<div
															className="text-muted mt-1">{numberFormat(request.others_requested * multiplyValue).displayFormat} Kgs.
														</div>
													</td>
													<td>
														{numberFormat(request.total_available_qty).displayFormat} {quantityLabel}
														<div
															className="text-muted mt-1">{numberFormat(request.total_available_qty * multiplyValue).displayFormat} Kgs.
														</div>
													</td>
													<td>
														{loadOutRequestObject && loadOutRequestObject.load_out_products.length > 0 && loadOutRequestObject.load_out_products.hasOwnProperty(index) ? loadOutRequestObject.load_out_products[index].approved_qty + " " + quantityLabel : "-"}
														<div
															className="text-muted mt-1">{loadOutRequestObject && loadOutRequestObject.load_out_products.length > 0 && loadOutRequestObject.load_out_products.hasOwnProperty(index) ? loadOutRequestObject.load_out_products[index].approved_qty * multiplyValue + " Kgs." : "-"} </div>
													</td>
													<td>{(loadOutRequestObject && loadOutRequestObject.load_out_products.length > 0 && loadOutRequestObject.load_out_products.hasOwnProperty(index) && loadOutRequestObject.load_out_products[index].reason_label) ? loadOutRequestObject.load_out_products[index].reason_label : "-"}</td>
													<td>
														{
															buttonText[index] === "Process" ?
																<EaButton
																	isModal={true}
																	dataTarget="#ea_modal_select_batch_wise_qty"
																	btnName="Process"
																	isDisable={state && state.buttonDisabled}
																	className="btn btn-primary h-28px py-1 font-13"
																	onClick={() => generateModalData(request, quantityLabel, multiplyValue)}
																/>
																:
																<EaButton
																	isModal={true}
																	dataTarget="#ea_modal_select_batch_wise_qty"
																	btnName="Update"
																	isDisable={state && state.buttonDisabled}
																	className="btn btn-secondary h-28px py-1 font-13"
																	onClick={() => generateModalData(request, quantityLabel, multiplyValue)}
																/>
														}
													</td>
												</tr>
											})
										}
									</tbody>
									{/* end::Table body */}
								</table>
						}
					</div>
					{/* end::Table container */}
					<div className="card-footer py-2 px-0">
						<EaButton
							// isModal={ true }
							// dataTarget="#ea_modal_reject_loadout_request___"
							btnName="Reject Load-out Requests"
							className="btn btn-danger me-3"
							isDisable={state && state.buttonDisabled}
							onClick={() => setShowRejectModal(true)}
						/>
						<div className="ms-auto">
							<button
								type="button"
								className="btn btn-secondary me-3"
								onClick={() => {
									navigate("/load-out-requests")
								}}
							>
								Cancel
							</button>
							{
								(!zeroQty && ((loadOutRequestObject.load_out_products.length > 0 && loadOutRequestType === "Approved") || !reasonModelRequired)) ?
									<button
										type="button"
										className="btn btn-primary"
										disabled={state && state.buttonDisabled || (buttonText.length > 0 && buttonText.indexOf("Update") === -1)}
										onClick={submitLoadOutRequest}
									>
										Approve the Load-out
									</button>
									:
									<>
										{!zeroQty ?
											<EaButton
												isModal={true}
												isDisable={state && state.buttonDisabled || (buttonText.length > 0 && buttonText.indexOf("Update") === -1)}
												dataTarget="#ea_modal_partially_loadout_request"
												btnName="Approve the Load-out"
											/> :
											<EaButton
												isModal={true}
												isDisable={state && state.buttonDisabled || (buttonText.length > 0 && buttonText.indexOf("Update") === -1)}
												dataTarget="#ea_modal_reject_loadout_request_zero_qty"
												btnName="Approve the Load-out"
											/>
										}

									</>
							}
						</div>
					</div>
				</div>
				{/* begin::Body */}
			</div>

			{/* Select Batch Wise 400 Qty */}
			<EaModal
				widthcls="modal-lg"
				modalTitle={modalData && modalData.title}
				id={"ea_modal_select_batch_wise_qty"}
				actionBtnName="Process"
				btnDisabled={disableButton}
				cancel={clearFunction}
				onSubmit={onSubmit}
				children={
					<>
						<div className="table-responsive">
							{/* begin::Table */}
							<table
								className="table table-row-bordered table-row-gray-100 align-baseline gy-2 gs-3 mb-0">
								{/* begin::Table head */}
								<thead>
									<tr className="text-muted fw-500 bg-light2">
										<th className="min-w-120px py-3">Batch Number</th>
										<th className="min-w-100px py-3">Qty.</th>
										<th className="min-w-130px py-3">Expiry Date</th>
										<th className="min-w-120px py-3">Last Updated</th>
										<th className="min-w-200px py-3">Allow Qty.</th>
									</tr>
								</thead>
								{/* end::Table head */}
								{/* begin::Table body */}
								<tbody>
									{
										modalData.inventories.map((inventory: any, index: number) => {
											return <tr>
												<td>{inventory.batch_number}</td>
												<td>{inventory.units} {inventory.quantity_label}</td>
												<td>{dateFormat(inventory.expiry_date).displayDate}</td>
												<td>{dateFormat(inventory.updated_date).displayDate}</td>
												<td>
													<div className="d-flex align-items-center">
														<div className="input-group me-1"
															style={{ borderColor: inventory.quantity_validation_color }}>
															<input
																type="text"
																placeholder="0"
																name="quantity"
																className={clsx("form-control min-w-70px", inventory.quantity_validation)}
																disabled={(!inventory.quantity || inventory.quantity == 0) && disableQuantity}
																onChange={(e: any) => handleChangeRow(e, index, inventory.units)}
																value={inventory.quantity}
															/>
															<span
																className="input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px">
																{modalData.quantity_label}
															</span>
														</div>
														<span
															className="min-w-70px text-muted ms-1">{(inventory.quantity * modalData.multiply_value) || 0} Kgs.</span>
													</div>
												</td>
											</tr>
										})
									}
									<tr>
										<td colSpan={2} className="fw-500 pt-3 pb-0">
											Required
											- {modalData.requested_qty} {modalData.quantity_label} ({modalData.requested_qty * modalData.multiply_value} Kgs.)
										</td>
										<td colSpan={2} className="pt-3 pb-0"></td>
										<td colSpan={2}
											className={clsx("fw-500 text-md-end pt-3 pb-0", (totalQuantity === modalData.requested_qty) ? "text-success" : "text-danger")}>
											Processing
											- {totalQuantity} {modalData.quantity_label} ({totalQuantity * modalData.multiply_value} Kgs.)
										</td>
									</tr>
								</tbody>
								{/* end::Table body */}
							</table>
							{/* end::Table */}
						</div>
					</>
				}
			/>

			{/* Processing Partial Loadout */}
			<Modal show={showModal} centered
				onHide={() => setShowModal(false)}
				backdrop="static"
				onExited={() => setTimeout(() => { $('body').css('overflow', 'scroll'); }, 300)}

			>
				<Modal.Header closeButton onClick={hideModel}>
					<Modal.Title><b>Processing Partial Load-out</b></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<>
						<p>You are about to process this load-out request partially. Are you sure you want to proceed
							further?</p>
						<table className="gy-1 table w-75">
							<tr>
								<td>Product :</td>
								<td>
									{partialProductObject && partialProductObject.product_name}
								</td>
							</tr>
							<tr>
								<td>Required Qty. :</td>
								<td>
									{partialProductObject && partialProductObject.required_quantity} Bags (433 KG)
								</td>
							</tr>
							<tr>
								<td>Allow Qty. :</td>
								<td>{partialProductObject && partialProductObject.allowed_quantity} Bags (400 KG)
								</td>
							</tr>
						</table>
						<div>
							<label className="form-label">Select Reason</label>
							<AsyncPaginate

								loadOptions={fetchPartialApproveReasons}
								isSearchable
								placeholder="Select"
								className={clsx("react-select-container w-100", partialApproveReasonValidation.option)}
								classNamePrefix="react-select"
								onChange={(e: any) => handlePartialApproveReason(e, true)}
								value={partialApproveReasonOption.find((x: any) => x.value === partialApproveReason.reason) || ""}
								theme={(theme) => ({
									...theme,
									colors: {
										...theme.colors,
										primary: "#0c78a4",
									},
								})}
							/>
							{
								partialApproveReason && partialApproveReason.reason === null && <div className="mt-3">
									<label className="form-label">Reason</label>
									<textarea
										className={clsx("form-control", partialApproveReasonValidation.comments)}
										rows={4}
										name="comments"
										value={partialApproveReason.reason_note || ""}
										onChange={(e: any) => handlePartialApproveReason(e)}
									></textarea>
								</div>
							}
						</div>
					</>
				</Modal.Body>
				<Modal.Footer>
					<div className="w-100 d-flex justify-content-center">
						<button className="btn btn-secondary me-3" onClick={hideModel}>
							Cancel
						</button>
						<button className="btn btn-primary" onClick={submitPartialApproveReason}>
							Continue
						</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/* Reject Loadout Request */}
			{/* <EaModal
				modalTitle="Reject Loadout Request"
				id="ea_modal_reject_loadout_request___"
				actionBtnName="Confirm, Reject"
				onSubmit={ rejectLoadOutRequest }
				closeAuto="reject_cancel"
				cancel={ () => {
					setLoadOutRejectionValidation(rejectionValidation)
					setRejectReasonOffSet("")
					setRejectReason(initialRejectReason)
				} }
				children={
					<>
						<p>You are about to reject the whole loadout request from SR. Kindly specify the reason to
							reject the request. </p>
						<div>
							<label className="form-label">Select Reason</label>
							<AsyncPaginate

								loadOptions={ fetchRejectReasons }
								isSearchable
								placeholder="Select"
								className={ clsx("react-select-container w-100", loadOutRejectionValidation.option) }
								classNamePrefix="react-select"
								onChange={ (e: any) => handleRejectReason(e, true) }
								value={ rejectReasonOption.find((x: any) => x.value === rejectReason.reason) || "" }
								theme={ (theme) => ({
									...theme,
									colors: {
										...theme.colors,
										primary: "#0c78a4",
									},
								}) }
							/>
						</div>
						{
							rejectReason.reason === null && <div className="mt-3">
                                <label className="form-label">Reason</label>
                                <textarea
                                    className={ clsx("form-control", loadOutRejectionValidation.comments) }
                                    rows={ 4 }
                                    name="comments"
                                    value={ rejectReason.reason_note || "" }
                                    onChange={ handleRejectReason }
                                ></textarea>
                            </div>
						}
					</>
				}
			/> */}


			{/* Partially Loadout */}
			<EaModal
				modalTitle="Partially Loadout"
				id={"ea_modal_partially_loadout_request"}
				actionBtnName="Process the Load-out"
				onSubmit={submitLoadOutRequest}
				cancel={() => {
					setPartialApproveReasonValidationMultiple([rejectionValidation])
				}}
				closeAuto={"partial-approve"}
				children={
					<>
						<p>You are about to process 0 quantity for below mentioned SKUs so kindly specify reason for
							each.</p>
						{
							partialApproveProducts.length > 0 && partialApproveProducts.map((request: any, index: number) => {
								return <div className="mb-4">
									<div className="d-flex flex-wrap justify-content-between">
										<label
											className="form-label">{request.product_name}</label>
										<label className="form-label ms-2 text-danger fw-600">Req.
											Qty. {request.requested_qty} {request.quantity_label}</label>
									</div>
									<div id={index.toString()}>
										<AsyncPaginate

											loadOptions={extendedLoadOptions}
											isSearchable
											className={clsx("react-select-container w-100", partialApproveReasonValidationMultiple[index] && partialApproveReasonValidationMultiple[index].option)}
											onChange={(e) => handlePartialApproveReasonMultiple(e, index, true)}
											classNamePrefix="react-select"
											theme={(theme) => ({
												...theme,
												colors: {
													...theme.colors,
													primary: "#0c78a4",
												},
											})}
										/>
										{
											partialApproveReasonMultiple[index] && partialApproveReasonMultiple[index].reason === null &&
											<div className="mt-3">
												<label className="form-label">Reason</label>
												<textarea
													className={clsx("form-control", partialApproveReasonValidationMultiple[index] && partialApproveReasonValidationMultiple[index].comments)}
													rows={4}
													name="comments"
													value={partialApproveReasonMultiple[index].reason_note || ""}
													onChange={(e: any) => handlePartialApproveReasonMultiple(e, index)}
												></textarea>
											</div>
										}
									</div>
								</div>
							})
						}
					</>
				}
			/>

			{/* by : kriti */}
			<EaModal
				modalTitle="Reject Loadout Request"
				id="ea_modal_reject_loadout_request_zero_qty"
				actionBtnName="Yes , Reject Request"
				onSubmit={zeroQtySubmit}
				closeAuto="reject_cancel_zero_qty"
				children={
					<>
						<p>You have allowed 0 quantity for all the items. That will be considered as Load-out request rejected. Are you sure you want to continue? </p>
					</>
				}
			/>

			{/* Reject load-out-request modal */}
			<Modal show={showRejectModal} centered
				onHide={() => setShowRejectModal(false)}
				backdrop="static"
			>
				<Modal.Header>
					<Modal.Title><h2 className='modal-title fw-bold'>Reject Loadout Request</h2></Modal.Title>
					<div onClick={hideRejectModel} className='btn p-1 mh-24 btn-active-light-primary ms-2' data-bs-dismiss='modal' >
						<KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
					</div>
				</Modal.Header>
				<Modal.Body>
					<>
						<p>You are about to reject the whole loadout request from SR. Kindly specify the reason to
							reject the request. </p>
						<div>
							<label className="form-label">Select Reason</label>
							<AsyncPaginate

								loadOptions={fetchRejectReasons}
								isSearchable
								placeholder="Select"
								className={clsx("react-select-container w-100", loadOutRejectionValidation.option)}
								classNamePrefix="react-select"
								onChange={(e: any) => handleRejectReason(e, true)}
								value={rejectReasonOption.find((x: any) => x.value === rejectReason.reason) || ""}
								theme={(theme) => ({
									...theme,
									colors: {
										...theme.colors,
										primary: "#0c78a4",
									},
								})}
							/>
						</div>
						{
							rejectReason.reason === null && <div className="mt-3">
								<label className="form-label">Reason</label>
								<textarea
									className={clsx("form-control", loadOutRejectionValidation.comments)}
									rows={4}
									name="comments"
									value={rejectReason.reason_note || ""}
									onChange={handleRejectReason}
								></textarea>
							</div>
						}
					</>
				</Modal.Body>
				<Modal.Footer>
					<div className="w-100 d-flex justify-content-center">
						<button className="btn btn-secondary me-3" onClick={hideRejectModel}>
							Cancel
						</button>
						<button className="btn btn-primary" onClick={rejectLoadOutRequest}>
							Confirm, Reject
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default LoadOutRequestsSR
