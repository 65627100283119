import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../../_eaFruitsDms/layout/core'
import Messages from './messages'
// import Overview from "./overview"
import Overview from '../../../../supportRequest/components/overview'
// import SupportRequestHeader from "./supportRequestHeader"
import {apiBaseURL} from '../../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import SupportRequestHeader from '../../../../supportRequest/components/supportRequestHeader'

const SupportRequestTitle: React.FC = () => {
  const intl = useIntl()
  const teleSalesName: any = localStorage.getItem('teleSalesName')
  const supportRequestId = localStorage.getItem('supportRequestId')
  const supportRequestTitle: any = localStorage.getItem('supportRequestTitle')

  const teleSalesBreadCrumbs: Array<PageLink> = [
    {
      title: 'Tele-Sales',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: teleSalesName ? teleSalesName : 'Tele Sales Name',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Complaints',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
  ]

  const [requestDetails, setRequestDetails] = useState<any>('')

  useEffect(() => {
    supportRequest()
  }, [])

  const supportRequest = async () => {
    const api = `${apiBaseURL()}support_request/web/${supportRequestId}`
    const requestResponce = await getRequest(api, true)
    setRequestDetails(requestResponce)
  }

  return (
    <>
      <PageTitle
        breadcrumbs={teleSalesBreadCrumbs}
        backNavigate='/tele-sales/tele-sales-name/complaints'
      >
        {intl.formatMessage({
          id: supportRequestTitle !== undefined ? supportRequestTitle : 'Complaint Title',
        })}
      </PageTitle>
      <SupportRequestHeader requestDetails={requestDetails} />
      <Routes>
        <Route path='overview' element={<Overview requestDetails={requestDetails} />} />
        <Route path='messages' element={<Messages />} />
        <Route index element={<Navigate to='/tele-sales/complaint-title/overview' />} />
      </Routes>
    </>
  )
}

export default SupportRequestTitle
