import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import { apiBaseURL, KTSVG } from "../../../_eaFruitsDms/helpers";
import { getRequest } from "../../../_eaFruitsDms/https/apiCall";
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomSearchBar from "../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import SkuThumbnail from "../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail";
import { PageTitle } from "../../../_eaFruitsDms/layout/core";
import tenantConfiguration from "../../../TenantVariables";
import EATitle from "../../../_eaFruitsDms/layout/components/title/title";
import { categoryStyle } from "../../../_eaFruitsDms/helpers/components/categoryStyle";
import CustomPaginate from "../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import useDebounce from "../../../_eaFruitsDms/helpers/components/useDebounce";

type Props = {
  className: string
}

const OOProductSku: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Product SKUs',
    subTitle: 'Product SKUs',
  }
  const API_BASE_URL = tenantConfiguration.apiBaseUrl

  const [products, setProducts] = useState([])
  const [brands, setBrands] = useState([])
  const [categories, setCategories] = useState([])
  const [packagingMaterials, setPackagingMaterials] = useState([])
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(0)
  const [searchBy, setSearchBy] = useState<string>('')
  const [brandName, setBrandName] = useState<string>('')
  const [categoryName, setCategoryName] = useState<string>('')
  const [packagingMaterialName, setPackagingMaterialName] = useState<string>('')
  const [timer, setTimer] = useState<number>(0)
  const [brandOffSet, setBrandOffSet] = useState<any>('')
  const [categoryOffSet, setCategoryOffSet] = useState<any>('')
  const [packagingMaterialOffSet, setPackagingMaterialOffSet] = useState<any>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [groupOffSet, setGroupOffSet] = useState<any>('')
  const [groupOption, setGroupOption] = useState<any>([])
  const [selectedGroupId, setSelectedGroupId] = useState<any>('')

  const debounceSearch = useDebounce(searchBy, 500);

  useEffect(() => {
    setTimeout(() => retrieveProductSKUAPI().then(), timer)
  }, [
    selected,
    currentOffset,
    debounceSearch,
    brandName,
    categoryName,
    selectedGroupId,
    packagingMaterialName,
  ])

  useEffect(() => {
    setBrandOffSet('')
    setCategoryOffSet('')
    setPackagingMaterialOffSet('')
    setGroupOffSet('')
  }, [searchSelect])

  const fetchBrands = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const brandResponse = await getRequest(
      `${apiBaseURL()}master/product/brand?ordering=name&search=${search}&limit=${limit}&offset=${brandOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (brandResponse.next !== null) {
      const queryParams = new URLSearchParams(brandResponse.next)
      let newOffset: any = queryParams.get('offset')
      setBrandOffSet(newOffset)
      hasMore = true
    }

    if (brands.length == 0) {
      options.push({
        label: 'All Brand',
        value: '',
      })
    }

    if (brandResponse.results.length > 0) {
      brandResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (brands.length > 0) {
      setBrands(brands.concat(options))
    } else {
      setBrands(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategories = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (categoriesResponse.next !== null) {
      const queryParams = new URLSearchParams(categoriesResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffSet(newOffset)
      hasMore = true
    }

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    if (categoriesResponse.results.length > 0) {
      categoriesResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          color: '#6d6e6f',
          bg: '#f7f7f7',
          transform: 'uppercase',
          boxSizing: 'borderBox',
          weight: '500',
          cursor: 'default',
          size: '.75rem !important',
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchGroups = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${limit}&offset=${groupOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setGroupOffSet(newOffset)
      hasMore = true
    }
    if (groupOption.length == 0) {
      options.push({
        label: 'All Groups',
        value: '',
      })
    }
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (groupOption.length > 0) {
      setGroupOption(groupOption.concat(options))
    } else {
      setGroupOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchPackagingMaterials = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const packagingMaterialsResponse = await getRequest(
      `${apiBaseURL()}master/product/material?ordering=name&search=${search}&limit=${limit}&offset=${packagingMaterialOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (packagingMaterialsResponse.next !== null) {
      const queryParams = new URLSearchParams(packagingMaterialsResponse.next)
      let newOffset: any = queryParams.get('offset')
      setPackagingMaterialOffSet(newOffset)
      hasMore = true
    }

    if (packagingMaterials.length == 0) {
      options.push({
        label: 'All Packaging',
        value: '',
      })
    }

    if (packagingMaterialsResponse.results.length > 0) {
      packagingMaterialsResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (packagingMaterials.length > 0) {
      setPackagingMaterials(packagingMaterials.concat(options))
    } else {
      setPackagingMaterials(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const retrieveProductSKUAPI = async () => {
    let API_URL = `${API_BASE_URL}master/product/view?ordering=name&limit=${limit}&offset=${currentOffset}`

    if (brandName != '') {
      API_URL += `&brand_id__id=${brandName}`
    }

    if (categoryName != '') {
      API_URL += `&category_id__id=${categoryName}`
    }

    if (selectedGroupId != '') {
      API_URL += `&variants__group_name__id=${selectedGroupId}`
    }

    if (packagingMaterialName != '') {
      API_URL += `&material__id=${packagingMaterialName}`
    }

    if (debounceSearch != '') {
      API_URL += `&search=${debounceSearch}`
    }

    setDisplayLoader(true)

    const response = await getRequest(API_URL, true)

    setProducts(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setDisplayLoader(false)
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setTimer(0)
    if (inputName === 'brand') {
      setBrandName(inputValue)
    } else if (inputName === 'category') {
      setCategoryName(inputValue)
    } else if (inputName === 'group') {
      setSelectedGroupId(inputValue)
    } else if (inputName === 'packaging_material') {
      setPackagingMaterialName(inputValue)
    } else {
      setTimer(1000)
      setCurrentOffset('')
      setSelected(0)
      setSearchBy(inputValue)
    }
  }

  return (
    <>
      <EATitle title='Product SKUs' />
      <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({ id: 'Product SKUs' })}</PageTitle>
      <div className={`card ${className}`}>
        {displayLoader && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={handleChange} />
            <div className='col-auto ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form className='card-toolbar d-flex my-0' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                <AsyncPaginate
                    
                  loadOptions={fetchBrands}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Brands'
                  value={brands.find((option: any) => option.value === brandName)}
                  onChange={(e: any) => handleChange(e, 'brand')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchCategories}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  placeholder='All Categories'
                  styles={categoryStyle}
                  name='categories'
                  value={categories.filter((option: any) => option.value === categoryName)}
                  onChange={(e: any) => handleChange(e, 'category')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchGroups}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  value={groupOption.filter((option: any) => option.value == selectedGroupId)}
                  onChange={(e: any) => handleChange(e, 'group')}
                  placeholder='All Group'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchPackagingMaterials}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  placeholder='All Packaging'
                  name='packaging'
                  onChange={(e: any) => handleChange(e, 'packaging_material')}
                  value={packagingMaterials.find(
                    (option: any) => option.value === packagingMaterialName
                  )}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {/* </div> */}
              </form>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {products.length == 0 ? (
            <NoRecords />
          ) : (
            <>
              {/* begin::Table container */}
              <div className='table-responsive'>
                <table className='table table-row-bordered align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-100px py-2'>Product SKU</th>
                      <th className='min-w-100px py-2'>Brand</th>
                      <th className='min-w-100px py-2'>Category</th>
                      <th className='min-w-100px py-2'>Product Group</th>
                      <th className='min-w-100px py-2'>Variant</th>
                      <th className='min-w-100px py-2'>Size</th>
                      <th className='min-w-100px py-2'>Packaging</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table bod*/}
                  <tbody>
                    {products.map((product: any) => {
                      return (
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              {/* <span>{product.name}</span> */}
                              <SkuThumbnail skuName={product.name} src={product.product_thumbnail} />
                              {product.additional_notes != '' && (
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props} className='tooltip-dark'>
                                      {product.additional_notes}
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <span className='ms-2'>
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen045.svg'
                                      className='ic text-primary mr-0'
                                    />
                                  </span>
                                </OverlayTrigger>
                              )}
                            </div>
                          </td>
                          <td>{product.brand_id}</td>
                          <td>
                            <div>
                              <div className='text-muted font-13 mb-1 font-medium'>
                                {product.parent_category_name}
                              </div>
                              {product.category_name}
                            </div>
                          </td>
                          <td>{product.group_name}</td>
                          <td>{product.variants.name}</td>
                          <td>
                            {(product.measurement_unit.toLowerCase() === 'gram') ? product.size / 1000 : product.size}
                            {' Kgs'}
                          </td>
                          <td>{product.material}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              </div>
              {/* end::Table container */}
            </>
          )}
        </div>
        {/* begin::Body */}
      </div>
      <CustomPaginate
        data={products}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default OOProductSku
