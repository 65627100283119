import React from 'react'
import {useIntl} from 'react-intl'
import {connect} from 'react-redux'
import {useNavigate} from 'react-router'
import {Navigate, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import CallNotes from './components/callNotes'
import Overview from './components/overview'
import SalesOrderFeedback from './components/salesOrderFeedback'
import SupportRequests from './components/supportRequests'
import SurveyFeedback from './components/surveyFeedback'
import TeleSalesNameHeader from './components/teleSalesNameHeader'

const TeleSalesName: React.FC = (props: any) => {
  const intl = useIntl()
  const navigator = useNavigate()

  const teleSalesHeaderTitle = {
    title: 'Tele-Sales',
    subTitle: 'Tele-Sales Name',
  }

  const user = props.user

  if (user == undefined) {
    navigator('/tele-sales')
  }

  return (
    <>
      <PageTitle breadcrumbs={[teleSalesHeaderTitle]}>
        {intl.formatMessage({id: 'Tele-Sales Name'})}
      </PageTitle>
      <PageTitle breadcrumbs={[teleSalesHeaderTitle]} backNavigate='/tele-sales'>
        {intl.formatMessage({
          id: user !== undefined ? user.first_name + ' ' + user.last_name : 'Tele-Sales Name',
        })}
      </PageTitle>
      <TeleSalesNameHeader />
      <Routes>
        <Route path='overview' element={<Overview />} />
        <Route path='complaints/*'>
          <Route index element={<SupportRequests />} />
        </Route>
        <Route path='survey-feedback/*' element={<SurveyFeedback />} />
        <Route path='sales-order-feedback' element={<SalesOrderFeedback />} />
        <Route path='call-notes' element={<CallNotes />} />
        <Route index element={<Navigate to='/tele-sales/tele-sales-name/overview' />} />
      </Routes>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.teleSalesUserReducer.user,
  }
}

export default connect(mapStateToProps, null)(TeleSalesName)
