import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  WareHouseCreate,
  WareHouseList,
  WareHouseSingle
} from "../../../../_eaFruitsDms/apiFunctions/wareHouse/warehouse";
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  fetchAsyncDropdown,
  focusVatidateField,
  isModalValidation,
  isEmpty,
  isValidLatitude,
  isValidLongitude
} from "../../../../_eaFruitsDms/helpers";
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import MobileNumberInput from "../../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput";
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import { getToken } from "../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage";
import { validateEmail } from "../../../../_eaFruitsDms/validations/eaValidation";
import TableWarehouse from "./components/tableWarehouse";
import { useNavigate } from "react-router-dom";

type Props = {
  className: string
}

const WareHouseDc: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const masterHeaderTitle = {
    title: "Warehouse",
    subTitle: "Warehouse & DC"
  };

  // all states
  const [getList, setGetList] = useState<any>([])
  const [id, setId] = useState('')
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [count, setCount] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [getLength, setLength] = useState('1')
  const [btnName, setBtn] = useState('Submit')
  const [getParent, setParent] = useState<any>([])
  const [getRegion, setRegion] = useState<any>([])
  const [getWard, setWard] = useState<any>([])
  const [getWardModal, setWardModal] = useState<any>([])
  const [warehouseName, setWarehouseName] = useState('')
  const [warehouseType, setWarehouseType] = useState('DistributionCenter')
  const [parentwarehouse, setparentWarehouse] = useState('')
  const [regionwarehouse, setregionWarehouse] = useState('')
  const [wardwarehouse, setwardWarehouse] = useState<any>('')
  const [warehouseaddress, setWAddress] = useState('')
  const [warehousepostcode, setWPostcode] = useState('')
  const [wManualLatitude, setWManualLatitude] = useState("");
  const [wManualLongitude, setWManualLongitude] = useState("");
  const [warehouseward, setWward] = useState('')
  const [warehouseGmFirstname, setGmFirstname] = useState('')
  const [warehouseGmLastname, setGmLastname] = useState('')
  const [warehousephoneNumber, setPhoneNumber] = useState('')
  const [warehouseEmail, setEmail] = useState('')
  const [warehouseNameValidation, setWarehouseNameValidation] = useState('')
  const [warehouseTypeValidation, setWarehouseTypeValidation] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [parentwarehouseValidation, setparentWarehouseValidation] = useState('')
  const [regionwarehouseValidation, setregionWarehouseValidation] = useState('')
  const [wardwarehouseValidation, setwardWarehouseValidation] = useState('')
  const [warehousewardValidation, setWwValidation] = useState('')
  const [warehouseaddressValidation, setWAddressValidation] = useState('')
  const [warehousepostcodeValidation, setWPostcodeValidation] = useState('')
  const [wlatitudeValidation, setWLatitudeValidation] = useState("");
  const [wlongitudeValidation, setWLongitudeValidation] = useState("");
  const [warehouseGmFirstnameValidation, setGmFirstnameValidation] = useState('')
  const [warehouseGmLastnameValidation, setGmLastnameValidation] = useState('')
  const [warehousephoneNumberValidation, setPhoneNumberValidation] = useState('')
  const [warehouseemailValidation, setEmailValidation] = useState('')
  const [shoGmDetails, setShowGmDetails] = useState<any>(false)
  const [headquarterChecked, setHeadquarterChecked] = useState(false)
  const [parentSelectValidation, setParentSelectValidation] = useState(false)
  const [regionSelectValidation, setRegionSelectValidation] = useState(true)
  const [validationEmail, setValidationEmail] = useState(false)
  const [listLoadder, setListLoader] = useState<any>(true)
  const [showWrite, setShowWrite] = useState<any>(false)
  const [showDelete, setShowDelete] = useState<any>(false)
  const [showOverview, setShowOverview] = useState<any>(false)
  const [showArea, setShowArea] = useState<any>(false)
  const [showTeam, setShowTeam] = useState<any>(false)
  const [showSales, setShowSales] = useState<any>(false)
  const [showTargets, setShowTargets] = useState<any>(false)
  const [showStock, setShowStock] = useState<any>(false)
  const [showCustomers, setShowCustomers] = useState<any>(false)
  const [showCredit, setShowCredit] = useState<any>(false)
  const [showVehicles, setShowVehicles] = useState<any>(false)
  const [chekValue, setChekValue] = useState(true)
  const [messageTitle, setMessageTitle] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const [scrollToParent, setScrollToParent] = useState<any>(false)

  const goToMap = () => {
    if (!isEmpty(wManualLatitude) && !isEmpty(wManualLongitude)) {
      window.open('https://maps.google.com/maps?q=' + wManualLatitude + ',' + wManualLongitude, '_blank')
    }
  }

  const limit = "&limit=1000";

  const showSingleWH = {
    showOverview,
    showArea,
    showTeam,
    showSales,
    showTargets,
    showStock,
    showCustomers,
    showCredit,
    showVehicles
  };

  const [permission, setPermission] = useState(defaultPermission);

  // Authorization token
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`
    }
  };

  useEffect(() => {
    localStorage.removeItem('navigateBackToReport')
    fetchPermission()

    WareHouseList('10', '0', '').then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength("2");
      } else {
        setLength("1");
      }
      setCount(res.count);
      setNext(res.next);
      setPrev(res.prev);
      setListLoader(false);
    });
    getParentList();
    // getWards()
    getRegions();
  }, []);

  const fetchPermission = async () => {
    let res: any
    res = await checkPermissions("warehouse_and_dc")
    setPermission(res)

    res = await checkPermissions("warehouse_overview")
    setShowOverview(res)

    res = await checkPermissions("warehouse_area_coverage")
    setShowArea(res)

    res = await checkPermissions("warehouse_team")
    setShowTeam(res)

    res = await checkPermissions("warehouse_sales_orders")
    setShowSales(res)

    res = await checkPermissions("warehouse_targets")
    setShowTargets(res)

    res = await checkPermissions("warehouse_stocks")
    setShowStock(res)

    res = await checkPermissions("warehouse_customers")
    setShowCustomers(res)

    res = await checkPermissions("warehouse_credit_issues")
    setShowCredit(res)

    res = await checkPermissions("warehouse_vehicles")
    setShowVehicles(res)
  }

  // react-select default data
  let optGroup = [
    {
      label: "",
      options: [{ label: "", value: "" }]
    }
  ];
  let optionData: any = [];

  // get parent warehouse
  const getParentList = () => {
    getRequest(`${apiBaseURL()}warehouse/list?ordering=name` + limit, true).then((res) =>
      setParent(res.results)
    );
  };

  // get regions list
  const getRegions = () => {
    getRequest(`${apiBaseURL()}master/region_warehouse/list/?ordering=name` + limit, true).then(
      (res) => {
        setRegion(res.results);
      }
    );
  };

  const getProps = (val: any) => {
    setId(val.id);
  };

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev);
      const newOffset = queryParams.get("offset");
      setCurrentOffset(newOffset);
      setSelected(selected - 1);
      WareHouseList("10", newOffset, search).then((res) => {
        setGetList(res.data);
        if (res.data.length == 0) {
          setLength("2");
        } else {
          setLength("1");
        }
        setCount(res.count);
        setNext(res.next);
        setPrev(res.prev);
      });
    }
  };

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next);
      const newOffset = queryParams.get("offset");
      setCurrentOffset(newOffset);
      setSelected(selected + 1);
      WareHouseList("10", newOffset, search).then((res) => {
        setGetList(res.data);
        setCount(res.count);
        setNext(res.next);
        setPrev(res.prev);
      });
    }
  };

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1);
      let newOffset = index * 10;
      setCurrentOffset(newOffset);
      WareHouseList("10", newOffset, search).then((res) => {
        setGetList(res.data);
        setCount(res.count);
        setNext(res.next);
        setPrev(res.prev);
      });
    }
  };

  const searchList = (e: any) => {
    clearTimeout(timer);
    setSearch(e.target.value);
    const newTimer = setTimeout(() => {
      WareHouseList("10", "0", e.target.value).then((res) => {
        setGetList(res.data);
        if (res.data.length == 0) {
          setLength("2");
        } else {
          setLength("1");
        }
        setCount(res.count);
        setNext(res.next);
        setPrev(res.prev);
        setSelected(0);
      });
    }, 500);
    setTimer(newTimer);
  };

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == "success") {
      setIsAlertSuccess(false);
    }
    if (val == "failed") {
      setIsAlertFailed(false);
    }
  };

  const addFunction = () => {
    let navigateField = {
      warehouseName: warehouseName,
      parentValue: !scrollToParent ? parentValue : "parent",
      wardwarehouse: wardwarehouse.length === 0 ? "" : wardwarehouse,
      warehouseaddress: warehouseaddress,
      wardsValue: wardsValue,
      warehouseGmFirstname: warehouseGmFirstname,
      warehouseGmLastname: warehouseGmLastname,
      warehousephoneNumber: warehousephoneNumber,
      warehouseEmail: warehouseEmail
    };

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0]);

    // ui validation [ SAME IN ALL INSERT FUNCTIONS ]
    if (warehouseName == "") {
      setWarehouseNameValidation("is-invalid");
    } else {
      setWarehouseNameValidation("");
    }
    if (warehouseType == "") {
      setWarehouseTypeValidation("is-invalid");
    } else {
      setWarehouseTypeValidation("");
    }
    if (!parentwarehouse) {
      setparentWarehouseValidation("is-invalid");
    } else {
      setparentWarehouseValidation("");
    }
    if (regionwarehouse == "") {
      setregionWarehouseValidation("is-invalid");
    } else {
      setregionWarehouseValidation("");
    }
    if (wardwarehouse == "") {
      setwardWarehouseValidation("is-invalid");
    } else {
      setwardWarehouseValidation("");
    }
    if (warehouseaddress == "") {
      setWAddressValidation("is-invalid");
    } else {
      setWAddressValidation("");
    }
    if (warehouseward == "") {
      setWwValidation("is-invalid");
    } else {
      setWwValidation("");
    }
    if (wManualLatitude == "" || !isValidLatitude(wManualLatitude)) {
      setWLatitudeValidation("is-invalid");
    } else {
      setWLatitudeValidation("");
    }
    if (wManualLongitude == "" || !isValidLongitude(wManualLongitude)) {
      setWLongitudeValidation("is-invalid");
    } else {
      setWLongitudeValidation("");
    }

    // if (warehousepostcode == '') {
    //   setWPostcodeValidation('is-invalid')
    // } else {
    //   setWPostcodeValidation('')
    // }
    if (shoGmDetails) {
      if (warehouseGmFirstname == "") {
        setGmFirstnameValidation("is-invalid");
      } else {
        setGmFirstnameValidation("");
      }
      if (warehouseGmLastname == "") {
        setGmLastnameValidation("is-invalid");
      } else {
        setGmLastnameValidation("");
      }
      if (warehousephoneNumber == "") {
        setPhoneNumberValidation("is-invalid");
      } else {
        setPhoneNumberValidation("");
      }
      if (warehouseEmail == "") {
        setEmailValidation("is-invalid");
      } else {
        setEmailValidation("");
      }
    }

    // api validation front-end side. [ SAME IN ALL INSERT FUNCTIONS ]
    if (warehouseName !== "") {
      if (warehouseType !== "") {
        if (regionSelectValidation) {
          if (parentSelectValidation) {
            if (parentwarehouse || regionwarehouse !== '') {
              if (wardwarehouse !== '') {
                if (warehouseaddress !== '') {
                  if (warehouseward !== '') {
                    if (wManualLatitude !== "" && isValidLatitude(wManualLatitude)) {
                      if (wManualLongitude !== "" && isValidLongitude(wManualLongitude)) {
                        {
                          if (warehouseGmFirstname !== '') {
                            if (warehouseGmLastname !== '') {
                              if (warehousephoneNumber !== '') {
                                if (warehouseEmail !== '') {
                                  if (validateEmail(warehouseEmail)) {
                                    // add Headquarter
                                    setDisableBtn(true)
                                    setIsLoading('Please Wait..')

                                    Headquarter()
                                  }
                                }
                              }
                            }
                          } else {
                            setDisableBtn(true)
                            setIsLoading('Please Wait..')
                            // add Distribution center
                            Distribution()
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const Distribution = () => {
    setMessageTitle(warehouseName);
    // add Distribution
    let wards: any = [];
    wardwarehouse.forEach((element: any) => {
      wards.push(element.value);
    });
    WareHouseCreate(
      warehouseName,
      warehouseType,
      parentwarehouse,
      warehouseaddress,
      warehousepostcode,
      regionwarehouse,
      wards,
      wManualLatitude,
      wManualLongitude,
      warehouseward
    ).then((res: any) => {
      setDisableBtn(false)
      setIsLoading('Submit')
      if (res.success) {
        clearWareHouse()

        WareHouseSingle(res.warehouseId).then((warehouseRes: any) => {
          setIsAlertSuccess(true)
          $('#ea_modal_close_add_warehouse').trigger('click') // modal close
          if (warehouseRes.warehouse_billing.length > 0) {
            navigate('/warehouse/DC/singlewarehousename/billing-info')
          } else {
            navigate('/warehouse/DC/singlewarehousename/overview', {
              state: {
                billingRequired: true
              }
            })
          }
        })
      }
      if (res.errorField == "name") {
        setErrorMessage(
          "Warehouse Name is already exist in this system! Please try again with unique name."
        );
      }

      if (res.failed) {
        // setIsAlertFailed(true)
      }
      setTimeout(() => {
        setIsAlertSuccess(false);
        setIsAlertFailed(false);
      }, 2500);

      setBtn("Submit");
    });
  };

  const Headquarter = () => {
    // add Headquarter
    let wards: any = [];
    wardwarehouse.forEach((element: any) => {
      wards.push(element.value);
    });
    setMessageTitle(warehouseName);
    setBtn("Please Wait..");
    WareHouseCreate(
      warehouseName,
      warehouseType,
      parentwarehouse,
      warehouseaddress,
      warehousepostcode,
      regionwarehouse,
      wards,
      wManualLatitude,
      wManualLongitude,
      warehouseward,
      warehouseGmFirstname,
      warehouseGmLastname,
      warehouseEmail,
      warehousephoneNumber,
      null
    ).then((res: any) => {
      setDisableBtn(false);
      setIsLoading("Submit");
      if (res.success) {
        clearWareHouse()
        $('#ea_modal_close_add_warehouse').trigger('click') // modal close
        // setIsAlertSuccess(true)
        navigate('/warehouse/DC/singlewarehousename/overview', {
          state: {
            billingRequired: true
          }
        })
      }

      setTimeout(() => {
        setIsAlertSuccess(false);
        setIsAlertFailed(false);
      }, 2500);

      if (res.errorField == "name") {
        // setShowErrorMessage(true)
        setErrorMessage(
          "Warehouse Name is already exist in this system! Please try again with unique name."
        );
      }

      if (res.errorField == "email") {
        // setShowErrorMessage(true)
        setErrorMessage(
          "email is already exist in this system! Please try again with unique email."
        );
      }
      if (res.errorField == "lat_long") {
        setErrorMessage(
          "Oops, we could not generate the Latitude & Longitude of your given address! Please provide a valid address and try again."
        );
      }
      if (res.failed) {
        // setIsAlertFailed(true
      }
      setBtn("Submit");
    });
  };

  const clearWareHouse = () => {
    // clear add modal state
    setErrorMessage('')
    setWarehouseName('')
    setWarehouseType('DistributionCenter')
    setparentWarehouse('')
    setregionWarehouse('')
    setwardWarehouse('')
    setWAddress('')
    setWPostcode('')
    setWManualLatitude("");
    setWManualLongitude("");
    setWward('')
    setGmFirstname('')
    setGmLastname('')
    setPhoneNumber('')
    setEmail('')
    setShowGmDetails(false)
    setWarehouseNameValidation('')
    setWarehouseTypeValidation('')
    setparentWarehouseValidation('')
    setregionWarehouseValidation('')
    setwardWarehouseValidation('')
    setWwValidation('')
    setWAddressValidation('')
    setWPostcodeValidation('')
    setWLatitudeValidation("");
    setWLongitudeValidation("");
    setGmFirstnameValidation('')
    setGmLastnameValidation('')
    setPhoneNumberValidation('')
    setEmailValidation('')
    setParentSelectValidation(false)
    setHeadquarterChecked(false)
    setValidationEmail(false)
    setChekValue(true)
    setBtn('Submit')
    setScrollToParent(false)
  }

  const wardselect = (e: any) => {
    getParentList();
    setwardWarehouse("");
    setregionWarehouse(e.value);
    if (e.value) {
      getRequest(
        `${apiBaseURL()}master/district_ward_filter/list/?region__id=${e.value}&ordering=name` +
        limit,
        true
      ).then((res) => {
        let data = res.results;
        let districtName = "";
        let wards: any = [];
        data.forEach((element: any) => {
          let disName = [{ label: "-", value: "-" }];
          districtName = element.name;
          let nameFormating: any = element.ward.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
          });
          let removeBlankSpace = false;
          nameFormating.forEach((dist: any) => {
            removeBlankSpace = true;
            disName.push({ label: dist.name, value: dist.id });
            wards.push(dist);
          });
          if (removeBlankSpace) {
            disName.shift();
          }
          optionData.push({
            label: districtName,
            options: disName
          });
        });
        optGroup = optionData;
        setWard(optGroup);
        let nameFormating: any = wards.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        });
        // setWardModal(nameFormating)
      });

      getRequest(
        `${apiBaseURL()}master/district_ward/list/?region__id=${e.value}&ordering=name` + limit,
        true
      ).then((res) => {
        let data = res.results;
        let wards: any = [];
        if (data.length !== 0) {
          data.forEach((element: any) => {
            element.ward.forEach((res: any) => {
              wards.push({
                label: res.name,
                value: res.id
              });
            });
          });
        }
        setWardModal(wards);
      });
    }
  };

  const getBoundaries = (id: any) => {
    // get district_ward list
    let totalDist: any = [];
    WareHouseSingle(id).then((res: any) => {
      let regionID = res.region.id;
      getRequest(
        `${apiBaseURL()}master/district_ward_filter/list/?region__id=${regionID}` + limit,
        true
      ).then((res) => {
        let data = res.results;
        let districtName = "";
        let wards: any = [];
        data.forEach((element: any) => {
          let disName = [{ label: "-", value: "-" }];
          districtName = element.name;
          let removeBlankSpace = false;
          let nameFormating: any = element.ward.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
          });
          nameFormating.forEach((dist: any) => {
            removeBlankSpace = true;
            disName.push({ label: dist.name, value: dist.id });
            wards.push(dist);
            totalDist.push(dist);
          });
          if (removeBlankSpace) {
            disName.shift();
          }
          optionData.push({
            label: districtName,
            options: disName
          });
        });
        optGroup = optionData;
        setWard(optGroup);
        let nameFormating: any = wards.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        });
        // setWardModal(nameFormating)
        // format data for react-select dropdown.
      });

      let wardsId = res.wards;

      getRequest(
        `${apiBaseURL()}master/district_ward/list/?region__id=${regionID}&ordering=name` + limit,
        true
      ).then((res) => {
        let data = res.results;
        let wards: any = [];
        if (data.length !== 0) {
          data.forEach((element: any) => {
            element.ward.forEach((res: any) => {
              // if (wardsId.includes(res.id)) {
              wards.push({
                label: res.name,
                value: res.id
              });
              // }
            });
          });
        }
        setWardModal(wards);
      });
    });
  };

  const [parentOffset, setParentOffset] = useState("");
  const [parentWarehouse, setParentWarehouse] = useState([]);
  const [searchParentWarehouse, setSearchParentWarehouse] = useState("");
  const [parentValue, setParentValue] = useState("");

  const [headquarterOffset, setHeadquarterOffset] = useState("");
  const [headquarter, setHeadquarter] = useState([]);
  const [searchHeadquarter, setSearchHeadquarter] = useState("");
  const [headquarterValue, setHeadquarterValue] = useState("");

  const [wardsValue, setWardsValue] = useState("");

  useEffect(() => {
    setParentOffset("");
  }, [searchParentWarehouse]);

  useEffect(() => {
    setHeadquarterOffset("");
  }, [searchHeadquarter]);

  const fetchParentWarehouse = (search: any) => {
    let url = `warehouse/list?ordering=name&warehouse_type=Headquarter`;
    return fetchAsyncDropdown(
      parentOffset,
      setParentOffset,
      parentWarehouse,
      setParentWarehouse,
      url,
      search,
      setSearchParentWarehouse
    );
  };

  const fetchHeadquarter = (search: any) => {
    let url = `master/region_warehouse/list/?ordering=name`;
    return fetchAsyncDropdown(
      headquarterOffset,
      setHeadquarterOffset,
      headquarter,
      setHeadquarter,
      url,
      search,
      setSearchHeadquarter
    );
  };

  return (
    <>
      <EATitle title="Warehouse & DC" />
      <div>
        <PageTitle breadcrumbs={[masterHeaderTitle]}>
          {intl.formatMessage({ id: "Warehouse & DC" })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow("success")}
          show={isAlertSuccess}
          // message='success'
          message={FormatMessage("PROMOTIONAL.DISCOUNT.ADD", messageTitle)}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow("failed")}
          show={isAlertFailed}
          message="failed"
        />

        <div className={`card ${className}`}>
          <div className="card-header border-0 px-7">
            <div className="card-toolbar row g-0 w-100">
              <CustomSearchBar onChange={(e: any) => searchList(e)} />
              {permission.write && (
                <div className="col-12 col-md-auto ms-auto mt-3 mt-md-0">
                  <EaButton
                    onClick={wardselect}
                    isModal={true}
                    dataTarget="#ea_modal_warehouse"
                    btnName="Add Warehouse"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="card-body py-4">
            {listLoadder ? (
              <IsLoader />
            ) : (
              <TableWarehouse
                showSingleWH={showSingleWH}
                getLength={getLength}
                tableList={getList}
                getPropsEdit={getProps}
              />
            )}
          </div>
        </div>
        <div className="table-info py-5">
          <div className="row g-0">
            <div className="col">
              {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
                } of ${getList.length == 0 ? 0 : count} entries`}
            </div>
            <div className="col">
              <CustomPagination
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
                count={getList.length == 0 ? 0 : count}
                selected={selected}
              />
            </div>
          </div>
        </div>
      </div>

      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        id="ea_modal_warehouse"
        modalTitle="Add Warehouse"
        cancel={clearWareHouse}
        closeAuto="ea_modal_close_add_warehouse"
        onSubmit={() => addFunction()}
      >
        <div className="mb-4">
          <label className="form-label">
            Warehouse Name<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="warehouseName"
            value={warehouseName}
            onChange={(e) => {
              setWarehouseName(capitalizeFirstLetter(e.target.value));
              const validation = ValidationHandler(e.target.value, "is-invalid");
              setWarehouseNameValidation(validation);
            }}
            className={clsx("form-control", warehouseNameValidation)}
            placeholder="Type here..."
          />
        </div>
        <div className="mb-4">
          <label className="form-label">
            Warehouse Type<span className="text-danger">*</span>
          </label>
          <div className="row">
            <div className="col-auto">
              <div className="form-check form-check-custom form-check-solid form-check-sm">
                <input
                  className="form-check-input"
                  name="whType"
                  type="radio"
                  id="headquarter"
                  onClick={() => {
                    setHeadquarterChecked(true);
                    setWarehouseType("Headquarter");
                    setParentSelectValidation(true);
                    setRegionSelectValidation(true);
                    setparentWarehouse("");
                    setChekValue(false);
                    setShowGmDetails(true);
                    setParentValue("");
                    // setWard([])
                    setwardWarehouse([]);
                    setWardsValue("");
                    setScrollToParent(true);
                  }}
                />
                <label className="form-check-label" htmlFor="headquarter">
                  Headquarter
                </label>
              </div>
            </div>
            <div className="col-auto">
              <div className="form-check form-check-custom form-check-solid form-check-sm">
                <input
                  className="form-check-input"
                  name="whType"
                  type="radio"
                  onClick={() => {
                    setHeadquarterChecked(false);
                    setWarehouseType("DistributionCenter");
                    setRegionSelectValidation(true);
                    setChekValue(true);
                    setParentSelectValidation(true);
                    setregionWarehouse("");
                    setShowGmDetails(false);
                    setHeadquarterValue("");
                    // setWard([])
                    setwardWarehouse([]);
                    setWardsValue("");
                  }}
                  checked={chekValue}
                  id="distribution-center"
                />
                <label className="form-check-label" htmlFor="distribution-center">
                  Distribution Center
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mb-4"
          style={{
            display: headquarterChecked ? "none" : "block"
          }}
        >
          <label className="form-label" id="parentValue">
            Parent Warehouse<span className="text-danger">*</span>
          </label>
          <AsyncPaginate

            loadOptions={fetchParentWarehouse}
            isSearchable
            className={clsx("react-select-container w-100%", parentwarehouseValidation)}
            classNamePrefix="react-select"
            placeholder="Select"
            value={parentValue}
            onChange={(e: any) => {
              setParentValue(e);
              setWardsValue("");
              const validation = ValidationHandler(e.value, "is-invalid");
              setparentWarehouseValidation(validation);
              setparentWarehouse(e.value);
              getBoundaries(e.value);
              setParentSelectValidation(true);
              setwardWarehouse("");
              setWward("");
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#0c78a4"
              }
            })}
          />
        </div>
        <div
          className="mb-4"
          style={{
            display: headquarterChecked ? "block" : "none"
          }}
        >
          <label className="form-label" id="headquarterValue">
            Headquarter Boundary<span className="text-danger">*</span>
          </label>
          <AsyncPaginate

            loadOptions={fetchHeadquarter}
            isSearchable
            placeholder="Select"
            className={clsx("react-select-container w-100%", regionwarehouseValidation)}
            classNamePrefix="react-select"
            value={headquarterValue}
            onChange={(e: any) => {
              setWardsValue("");
              setWward("");
              setHeadquarterValue(e);
              wardselect(e);
              const validation = ValidationHandler(e.value, "is-invalid");
              setregionWarehouseValidation(validation);
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#0c78a4"
              }
            })}
          />
        </div>
        <div className="mb-4">
          <label className="form-label" id="wardwarehouse">
            Distribution Boundaries<span className="text-danger">*</span>
          </label>
          <Select
            options={getWard}
            placeholder="Select"
            onChange={(e: any) => {
              let filterData = e.filter((val: any) => {
                if (val.label !== "-") {
                  return val;
                }
              });
              setwardWarehouse(filterData);
              let event = e;
              if (event.length === 0) {
                event = "";
              }
              const validation = ValidationHandler(event, "is-invalid");
              setwardWarehouseValidation(validation);
            }}
            value={wardwarehouse}
            isMulti
            className={clsx("react-select-container", wardwarehouseValidation)}
            classNamePrefix="react-select"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#0c78a4"
              }
            })}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">
            Street Address<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="warehouseaddress"
            placeholder="Type here..."
            value={warehouseaddress}
            onChange={(e) => {
              setWAddress(e.target.value);
              const validation = ValidationHandler(e.target.value, "is-invalid");
              setWAddressValidation(validation);
            }}
            className={clsx("form-control", warehouseaddressValidation)}
          />
        </div>
        <div className="row">
          <div className="col-sm-6 mb-4">
            <label className="form-label" id="wardsValue">
              Ward<span className="text-danger">*</span>
            </label>
            <Select
              options={getWardModal}
              placeholder="Select Ward"
              onChange={(e: any) => {
                setWardsValue(e);
                setWward(e.value);
                const validation = ValidationHandler(e.value, "is-invalid");
                setWwValidation(validation);
              }}
              value={wardsValue}
              className={clsx("react-select-container", warehousewardValidation)}
              classNamePrefix="react-select"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#0c78a4"
                }
              })}
            />
            {/* <select
              value={warehouseward}
              onChange={(e) => {
                setWward(e.target.value)
                const validation = ValidationHandler(e.target.value, "is-invalid")
                setWwValidation(validation)
              }}
              className={clsx('form-select font-14', warehousewardValidation)}
              aria-label='Select example'
            >
              <option hidden>Select Ward</option>
              {getWardModal.map((item: any) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </select> */}
          </div>

          <div className="col-sm-6 mb-4">
            <label className="form-label">Postcode</label>
            <input
              type="text"
              value={warehousepostcode}
              maxLength={8}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setWPostcode(e.target.value);
                  const validation = ValidationHandler(e.target.value, "is-invalid");
                  setWPostcodeValidation(validation);
                }
              }}
              className={clsx("form-control")}
              placeholder="Type here..."
            />
          </div>
          <div className="col-sm-6 mb-4">
            <label className="form-label">Latitude<span className="text-danger">*</span></label>
            <input
              type="text"
              value={wManualLatitude}
              onChange={(e) => {
                setWManualLatitude(e.target.value);
                const validation = ValidationHandler(e.target.value, "is-invalid");
                setWLatitudeValidation(validation);
              }}
              className={clsx("form-control", wlatitudeValidation)}
              placeholder="Type here..."
            />
          </div>
          <div className="col-sm-6 mb-4">
            <label className="form-label">Longitude<span className="text-danger">*</span></label>
            <input
              type="text"
              value={wManualLongitude}
              onChange={(e) => {
                setWManualLongitude(e.target.value);
                const validation = ValidationHandler(e.target.value, "is-invalid");
                setWLongitudeValidation(validation);
              }}
              className={clsx("form-control", wlongitudeValidation)}
              placeholder="Type here..."
            />
          </div>
          <div className="col-sm-12 mb-4">
            <a href="javascript:void(0)" onClick={goToMap} className={clsx(isEmpty(wManualLatitude) || isEmpty(wManualLongitude) && 'disabled')}>Preview on Map</a>
          </div>
          <div
            className="col-sm-6 mb-4"
            style={{
              display: shoGmDetails ? "block" : "none"
            }}
          >
            <label className="form-label">
              BM's First Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="warehouseGmFirstname"
              value={warehouseGmFirstname}
              onChange={(e) => {
                setGmFirstname(e.target.value);
                const validation = ValidationHandler(e.target.value, "is-invalid");
                setGmFirstnameValidation(validation);
              }}
              className={clsx("form-control", warehouseGmFirstnameValidation)}
              placeholder="Type here..."
            />
          </div>

          <div
            className="col-sm-6 mb-4"
            style={{
              display: shoGmDetails ? "block" : "none"
            }}
          >
            <label className="form-label">
              Last Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="warehouseGmLastname"
              value={warehouseGmLastname}
              onChange={(e) => {
                setGmLastname(e.target.value);
                const validation = ValidationHandler(e.target.value, "is-invalid");
                setGmLastnameValidation(validation);
              }}
              className={clsx("form-control", warehouseGmLastnameValidation)}
              placeholder="Type here..."
            />
          </div>
          <div
            id="warehousephoneNumber"
            className="col-sm-12 mb-4"
            style={{
              display: shoGmDetails ? "block" : "none"
            }}
          >
            <MobileNumberInput
              validation={warehousephoneNumberValidation}
              setValidation={setPhoneNumberValidation}
              numberValue={warehousephoneNumber}
              setNumberValue={setPhoneNumber}
            />

            {/* <label className='form-label'>Phone Number</label>
            <input
              type='text'
              placeholder='Type here...'
              value={warehousephoneNumber}
              onChange={(e) => {
                const re = /^[0-9\b]+$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  setPhoneNumber(e.target.value)
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  setPhoneNumberValidation(validation)
                }
              }}
              maxLength={9}
              className={clsx('form-control', warehousephoneNumberValidation)}
            /> */}
          </div>
          <div
            className="col-sm-12 mb-4"
            style={{
              display: shoGmDetails ? "block" : "none"
            }}
          >
            <label className="form-label">
              Email<span className="text-danger">*</span>
            </label>
            <input
              id="warehouseEmail"
              type="text"
              value={warehouseEmail}
              onChange={(e) => {
                setEmail(e.target.value);
                const validation = ValidationHandler(e.target.value, "is-invalid");
                setEmailValidation(validation);
              }}
              className={clsx("form-control", warehouseemailValidation)}
              placeholder="Type here..."
              onBlur={() => {
                if (validateEmail(warehouseEmail)) {
                  setValidationEmail(false);
                } else {
                  setValidationEmail(true);
                }
              }}
            />
            {validationEmail && <p className="text-danger">Wrong email format</p>}
          </div>
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  );
};

export default WareHouseDc;
