import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  isEmpty,
  KTSVG,
  numberFormat,
  retrieveFilter,
  saveFilter,
} from '../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import tenantConfiguration from '../../../../TenantVariables'
import {AsyncPaginate} from 'react-select-async-paginate'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {filters} from '../../../../_eaFruitsDms/utils/FilterConstants'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'
import {useNavigate} from 'react-router'

type Props = {
  className: string
}
const CreditIssueReports: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const navigate = useNavigate()

    const currentWarehouseId = localStorage.getItem('w_id')
    const warehouseName: any = localStorage.getItem('warehouse_name')

    let creditIsuueFiltered = retrieveFilter(filters.wm_credit_issue_report);

    const initialState = {
        warehouse: creditIsuueFiltered?.warehouse ||  {label: warehouseName, value: currentWarehouseId},
        search: creditIsuueFiltered?.search || '',
        selected: creditIsuueFiltered?.selected || '',
        currentOffset: creditIsuueFiltered?.currentOffset || '',
        buttonDisable: true
      }

    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Credit Issues Report"
    }
    const [warehouses, setWarehouses] = useState<any>([])
    const [warehouseOffSet, setWarehouseOffSet] = useState<any>('')
    const [searchSelect, setSearchSelect] = useState<string | undefined>('')
    const [limit, setLimit] = useState(tenantConfiguration.limit)
    const [displayLoader, setDisplayLoader] = useState<boolean>(false)
    const [creditIssue, setCreditIssue] = useState<any>([])
    const [count, setCount] = useState('1')
    const [currentOffset, setCurrentOffset] = useState<number>(0)
    const [selected, setSelected] = useState(creditIsuueFiltered?.selected || 0)
    const [search, setSearch] = useState<any>(creditIsuueFiltered?.search || '')
    const [warehouseId, setWarehouseId] = useState<any>(creditIsuueFiltered?.warehouse || {label: warehouseName, value: currentWarehouseId})
    const [filterStates, setFilterStates] = useState<any>(initialState);
    const [buttonDisable, setButtonDisable] = useState<boolean>(creditIsuueFiltered?.buttonDisable || false)

    const debounceSearch = useDebounce(search, 500)

    useEffect(() => {
        setWarehouseOffSet('')
      }, [searchSelect])

    useEffect(() => {
        fetchCreditIssue()
    }, [currentOffset, warehouseId , debounceSearch])

    useEffect(() => {
        localStorage.removeItem('cId')
    },[])

    const fetchCreditIssue = async () =>{
        setDisplayLoader(true)
        const API = `${apiBaseURL()}reports/credit-issues?limit=${limit}&ordering=-updated_date&warehouse_id=${isEmpty(warehouseId.value) ? "" : warehouseId.value}&search=${debounceSearch}&offset=${currentOffset}`
        const response = await getRequest(API, true)
        setCreditIssue(response.results)
        setCount(response.count)
        setDisplayLoader(false)

        let updatedState: any = { ...filterStates }
        updatedState['selected'] = selected
        updatedState['currentOffset'] = currentOffset
        updatedState['buttonDisable'] = buttonDisable

        setFilterStates(updatedState)
        saveFilter(filters.wm_credit_issue_report , filterStates)
        if(warehouseId.value == currentWarehouseId &&  search == '' ){
            setButtonDisable(false)
        }
    }

    const fetchWarehouses = async (search?: any) => {
        if (search != '') {
          setSearchSelect(search)
        }

        let options: any = []
        const warehouseResponse = await getRequest(
          `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=${currentWarehouseId}`,
          true
        )

        let hasMore: boolean = false

        if (warehouseResponse.next !== null) {
          const queryParams = new URLSearchParams(warehouseResponse.next)
          let newOffset: any = queryParams.get('offset')
          setWarehouseOffSet(newOffset)
          hasMore = true
        }

        // if (warehouses.length == 0) {
        //   options.push({
        //     label: 'All Warehouses',
        //     value: '',
        //   })
        // }

        if (warehouseResponse.results.length > 0) {
          warehouseResponse.results.map((option: any) => {
            options.push({
              label: option.name,
              value: option.id,
            })
          })
        }

        if (warehouses.length > 0) {
          setWarehouses(warehouses.concat(options))
        } else {
          setWarehouses(options)
        }

        return {
          options: options,
          hasMore: hasMore,
        }
    }

    const handleFilterChange = (e : any , name: string) => {
        const inputName = e.target ? e.target.name : name
        const inputValue = e.target ? e.target.value : e.value

        setCurrentOffset(0)
        setSelected(0)
        setButtonDisable(true)

        let updatedState: any = { ...filterStates }
        switch (inputName){
          case 'warehouse' :
          updatedState['warehouse'] = e
          setWarehouseId(e)
            break
          case 'search' :
          updatedState['search'] = inputValue
          setSearch(inputValue)
            break
        }
        setFilterStates(updatedState)
      }

    const handleChangeReset = () =>{
        setSearch('')
        setWarehouseId({label: warehouseName, value: currentWarehouseId})
        setCurrentOffset(0)
        setSelected(0)
        let updatedState: any = { ...filterStates }
        updatedState['warehouse'] = ''
        updatedState['search'] = ''
        updatedState['selected'] = ''
        updatedState['currentOffset'] = ''
        setFilterStates(updatedState)
        setButtonDisable(false)
      }

    return (
        <>
            <EATitle title='Credit Issues Report' />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Credit Issues Report' })}</PageTitle>
            {/* <div className={`card ${className} mb-5`}>
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    <h3 className='card-title align-items-start flex-column my-0'>
                        <span className='card-label fw-bold font-17'>Credit Issues</span>
                    </h3>
                    <div className='card-toolbar d-flex g-3 my-0'>
                        <AsyncPaginate
                            loadOptions={fetchWarehouse}
                            isSearchable
                            placeholder="All Warehouses "
                            className='react-select-container my-1 w-160px'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                        <span className="v-seperator mx-3"></span>
                        <ul className='nav'>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_1'
                                >
                                    Month
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_2'
                                >
                                    Year
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    Chart Area
                </div>
            </div> */}
            <div>
                <div className='card'>
                {displayLoader && <IsLoader/> }
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar  onChange={handleFilterChange} value={search} isDisable={displayLoader}/>
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <AsyncPaginate
                                    loadOptions={fetchWarehouses}
                                    isDisabled={displayLoader}
                                    isSearchable
                                    className='react-select-container my-1 me-3 w-160px'
                                    classNamePrefix='react-select'
                                    // placeholder='All Warehouses'
                                    value={warehouseId}
                                    onChange={(e: any) => handleFilterChange(e, 'warehouse')}
                                    theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                    })}
                                />
                                {/* <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2020',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar my-1 me-3 w-auto min-w-215px" />
                                </DateRangePicker> */}
                                {/* <label className="form-label mb-0"><button type="button" className="btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 me-3 py-1 d-flex align-items-center">
                                    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                    Export</button>
                                </label> */}
                              <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            {creditIssue.length == 0 ? <NoRecords/> :
                            <>
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-140px'>Customer Name</th>
                                        <th className='min-w-140px'>Warehouse Name</th>
                                        <th className='min-w-120px'>Sales Reps.</th>
                                        <th className='min-w-120px'>Sales Supervisor</th>
                                        <th className='min-w-80px'>Credit Issues</th>
                                        <th className='min-w-80px py-2'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {creditIssue.map((issue : any) => {
                                        return(
                                            <tr>
                                            <td>{issue && issue.customer_name ? issue.customer_name : '-'}</td>
                                            <td>{issue && issue.warehouse_name ? issue.warehouse_name : '-'}</td>
                                            <td>{issue && issue.requested_by ? issue.requested_by : '-'}</td>
                                            <td>{issue && issue.ss_name  ? issue.ss_name  : '-'}</td>
                                            <td>{issue && issue.total_approved_amount ? `${tenantConfiguration.currencyCode}` + ' ' + `${numberFormat(issue.total_approved_amount).displayFormat}`: '-'}</td>
                                            <td>
                                                <a
                                                    onClick={() => {
                                                        localStorage.setItem('cId', issue.customer_id)
                                                        navigate('/customers/singlecustomer/credit-issue')

                                                    }}
                                                    className='btn py-0 ps-1 pe-0 btn-active-color-primary btn-sm'>
                                                    <KTSVG path='/media/icons/duotune/arrows/arr007.svg' className='ic mr-0' />
                                                </a>
                                            </td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            </>}
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* end::Body */}
                </div>
                    <CustomPaginate
                        data={creditIssue}
                        selected={selected}
                        setSelected={setSelected}
                        setCurrentOffset={setCurrentOffset}
                        totalRecords={count}
                        limit={limit}
                        isDisable={displayLoader}
                        saveFilterName={filters.wm_credit_issue_report}
                        filterStates={filterStates}
                    />
            </div>
        </>
    )
}

export default CreditIssueReports
