import React from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import Select from "react-select"
// import Select from 'react-select/dist/declarations/src/Select'
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const paymentoptions = [
  {value: 'credit', label: 'Credit'},
  {value: 'debite', label: 'Debite'},
  {value: 'cash', label: 'Cash'},
  {value: 'upi', label: 'Upi'},
]

const PastOrders = (props: any) => {
  const teamMembersTitle = {
    title: 'Team members',
    subTitle: props.fullname,
  }
  return (
    <>
      <PageTitle breadcrumbs={[teamMembersTitle]}>{props.fullname}</PageTitle>
      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <DateRangePicker
                initialSettings={{
                  singleDatePicker: true,
                  drops: 'auto',
                  showDropdowns: true,
                  cancelClass: 'btn-secondary',
                }}
              >
                <input
                  type='text'
                  className='form-control calendar w-auto my-1 me-3'
                  placeholder='Select'
                />
              </DateRangePicker>
              <Select
                options={paymentoptions}
                isSearchable
                placeholder='All Payment Status'
                className='react-select-container'
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='text-muted fw-500'>
                  <th className='min-w-150px'>Order ID</th>
                  <th className='min-w-140px'>Order Date</th>
                  <th className='min-w-120px'>Invoice Amt.</th>
                  <th className='min-w-120px'>Customer Name</th>
                  <th className='min-w-120px'>Payment By</th>
                  <th className='min-w-120px'>Payment Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td>#66304</td>
                  <td>30 May 2022</td>
                  <td>TZS 285</td>
                  <td>Dusya Sigachyova</td>
                  <td>Cash</td>
                  <td>Paid</td>
                </tr>
                <tr>
                  <td>#66304</td>
                  <td>30 May 2022</td>
                  <td>TZS 285</td>
                  <td>Dusya Sigachyova</td>
                  <td>Payment Gateway</td>
                  <td>Paid</td>
                </tr>
                <tr>
                  <td>#66304</td>
                  <td>30 May 2022</td>
                  <td>TZS 285</td>
                  <td>Dusya Sigachyova</td>
                  <td>Cash</td>
                  <td className='text-danger'>Pending</td>
                </tr>
                <tr>
                  <td>#66304</td>
                  <td>30 May 2022</td>
                  <td>TZS 285</td>
                  <td>Dusya Sigachyova</td>
                  <td>Payment Gateway</td>
                  <td>Paid</td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>Showing 1 to 10 of 50 entries</div>
          <div className='col'>
            <CustomPagination />
          </div>
        </div>
      </div>
    </>
  )
}

export default PastOrders
