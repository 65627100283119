import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  addDiscountRejection,
  discountRejectionDelete,
  discountRejectionList,
  discountRejectionUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import { checkPermissions, defaultPermission, KTSVG } from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"

type Props = {
  className: string
}
const DiscountRejection: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Discount Rejection Options',
  }

  const [getList, setGetList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [ValidationEdit, setValidationEdit] = useState<any>('')
  const [Validation, setValidation] = useState<any>('')
  const [discountRejection, setDiscountRejection] = useState<any>('')
  const [discountRejectionEdit, setDiscountRejectionEdit] = useState<any>('')
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [DeleteId, setDeleteId] = useState<any>('')
  const [EditId, setEditId] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [errorMessage, setErrorMessage] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  useEffect(() => {
    fetchPermission()
    CategoriesList()
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_rejection_options")
    setPermission(res)
   }

  const CategoriesList = () => {
    setListLoader(true)
    discountRejectionList('10', '0', '', 'DiscountRejection', 'name').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      discountRejectionList('10', newOffset, search, 'DiscountRejection', 'name').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      discountRejectionList('10', newOffset, search, 'DiscountRejection', 'name').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      discountRejectionList('10', newOffset, search, 'DiscountRejection', 'name').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }
  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)
      discountRejectionList('10', '0', e.target.value, 'DiscountRejection', 'name').then((res) => {
        setGetList(res.data)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
        setListLoader(false)
      })
    }, 500)

    setTimer(newTimer)
  }

  // Develop BY: Kriti
  // Description: Add Discount Rejection function which is called on Eamodel's submit event

  const AddFunction = () => {
    if (discountRejection === '') {
      setValidation('is-invalid')
    } else {
      setValidation('')
    }
    if (discountRejection !== '') {
      AddFunctionApi()
    }
  }
  const clearFunctionAdd = () => {
    setValidation('')
    setDiscountRejection('')
    setErrorMessage('')
  }

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    addDiscountRejection(discountRejection, 'DiscountRejection').then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunctionAdd()
      setListLoader(true)
        discountRejectionList('10', '0', '', 'DiscountRejection', 'name').then((res) => {
          setGetList(res.data)

          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
        $('#closeAddDiscountEaModal').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'non_field_errors') {
        setErrorMessage(
          'Your added Discount Rejection Option name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  // Develop BY: Kriti
  // Description: Edit Discount Rejection function which is called on Eamodel's submit event

  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setDiscountRejectionEdit(item.option)
    setEditId(item.id)
    setModalLoader(false)
  }
  const EditFunction = () => {
    if (discountRejectionEdit === '') {
      setValidationEdit('is-invalid')
    } else {
      setValidationEdit('')
    }
    if (discountRejectionEdit !== '') {
      EditFunctionApi()
    }
  }
  const clearFunctionEdit = () => {
    setValidationEdit('')
    setDiscountRejectionEdit('')
    setErrorMessage('')
    setModalLoader(false)
    $('#tableId')
      .find('input[type=number]')
      .each(function () {
        var defaultVal = $(this).data('default')
        $(this).val(defaultVal)
      })
  }

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    discountRejectionUpdate(discountRejectionEdit, 'DiscountRejection', EditId).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
      setListLoader(true)
        discountRejectionList('10', '0', search, 'DiscountRejection', 'name').then((res) => {
          setGetList(res.data)

          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setListLoader(false)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
        })
        $('#closeEditDiscountModal').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'non_field_errors') {
        setErrorMessage(
          'Your added Discount Rejection Option name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  const onDelete = (item: any) => {
    setDeleteId(item.id)

    // api call
    discountRejectionDelete(DeleteId)
    discountRejectionList('10', '0', search, 'DiscountRejection', 'name')
  }
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <EATitle title='Discount Rejection Options' />
      <div>
        <PageTitle breadcrumbs={[masterHeaderTitle]}>
          {intl.formatMessage({ id: 'Discount Rejection Options' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? 'Your discount rejection  has been added successfully.'
              : 'Your discount rejection  has been edited successfully.'
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <div className='col-12 col-md-auto mt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className='form-control form-search'
                  onChange={searchList}
                />
              </div>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    btnName='Add Discount Rejection Options'
                    dataTarget='#ea_modal_create_discountrejection'
                    onClick={() => {
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
              {/* <TableDiscountRejection /> */}
              <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='min-w-100px py-2'>Discount Rejection Options</th>
                        <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getList.map((item: any) => {
                        return (
                          <tr>
                            <td>{item.option}</td>

                            <td className='text-end'>
                              {permission.write && (
                                <a
                                  href='javascript:void(0)'
                                  data-bs-toggle='modal'
                                  data-bs-target='#ea_modal_edit_editcustomerrejection'
                                  className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                  onClick={(event) => {
                                    onChangeAdd(item)
                                    setMessageHandler('successEdit')
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='ic mr-0'
                                  />
                                </a>
                              )}
                              {/* {permission.delete && (
                                <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                onClick={(event) => {
                                  onDelete(item)
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='ic mr-0'
                                />
                              </a>
                              )} */}
                            </td>
                          </tr>
                        )
                      })}
                      <tr>
                        <td>Other</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
                } of ${getList.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
                count={getList.length == 0 ? 0 : count}
                selected={selected}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <AddDiscountRejection /> */}

      <EaModal
        id='ea_modal_create_discountrejection'
        modalTitle='Add Discount Rejection Options'
        onSubmit={AddFunction}
        closeAuto='closeAddDiscountEaModal'
        cancel={clearFunctionAdd}
        actionBtnName={isLoadingAdd}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Discount Rejection Options<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', Validation)}
            placeholder='Type here...'
            value={discountRejection}
            onChange={(e) => {
              setDiscountRejection(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidation(validation)
            }}
          />
        </div>

        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>

      <EaModal
        id='ea_modal_edit_editcustomerrejection'
        modalTitle='Edit Discount Rejection Options'
        onSubmit={EditFunction}
        closeAuto='closeEditDiscountModal'
        cancel={clearFunctionEdit}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Discount Rejection Options<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', ValidationEdit)}
            placeholder='Type here...'
            value={discountRejectionEdit}
            onChange={(e) => {
              setDiscountRejectionEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidationEdit(validation)
            }}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}

export default DiscountRejection
