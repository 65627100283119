import clsx from "clsx";
import "cropperjs/dist/cropper.css";
import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import Cropper from "react-cropper";
import { KTSVG, toAbsoluteUrl } from "../../../../_eaFruitsDms/helpers";

const defaultSrc = toAbsoluteUrl("/media/icons/placeholder_img_crop.jpg");

export const Imagecropper = (props: any) => {
    const [image, setImage] = useState(defaultSrc);
    const [cropper, setCropper] = useState<any>();
    const [show, setShow] = useState(false);
    const [isLoadin, setLoading] = useState("Save");
    const [sizeValidation, setSizeValidation] = useState<string>(props.text)
    const handleShow = () => setShow(true);
    const handleHide = () => setShow(false);

    let files: any = ""
    const onChange = (e: any) => {
        var allowedExtensions =
            /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if (allowedExtensions.exec(e.target.value)) {
            if (props.imageSize !== undefined) {
                let size = e.target.files[0].size / 1024 / 1024
                if (size <= 5.0) {
                    handleShow();

                    e.preventDefault();

                    if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                    } else if (e.target) {
                        files = e.target.files;
                    }
                    const reader = new FileReader();
                    reader.onloadend = function () {

                    }
                    reader.onload = () => {
                        setImage(reader.result as any);
                    };
                    reader.readAsDataURL(files[0]);
                    props.onImageChange(e)
                    e.target.value = ''
                    setSizeValidation("")
                } else {
                    setSizeValidation("is-invalid")
                }
            } else {
                handleShow();

                e.preventDefault();

                if (e.dataTransfer) {
                    files = e.dataTransfer.files;
                } else if (e.target) {
                    files = e.target.files;
                }
                const reader = new FileReader();
                reader.onloadend = function () {

                }
                reader.onload = () => {
                    setImage(reader.result as any);
                };
                reader.readAsDataURL(files[0]);
                props.onImageChange(e)
                e.target.value = ''
            }
        }
    };

    const getCropData = () => {
        handleHide();
        if (typeof cropper !== "undefined") {
            props.setCrop(cropper.getCroppedCanvas().toDataURL());
            let url = cropper.getCroppedCanvas().toDataURL()
            handleUpload(url)
        } else {

        }
    };

    const dataUrlToFileUsingFetch = async (
        url: string,
        fileName: string,
        mimeType: string
    ) => {
        let data: any = ''
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();

        data = new File([buffer], fileName, { type: mimeType });
        return data;
    };

    const handleUpload = async (url: string) => {
        dataUrlToFileUsingFetch(url, "tes.png", "image/png").then((res) => {
            props.getImage(res)
        })
    }

    return (
        <>
            <div>
                <div className="d-flex align-items-center file-group">
                    <div className={clsx(
                        'file-icon symbol symbol-100px me-3', props.imageRequire
                    )} >
                        <span className='symbol-label bg-transparent'>
                            <img
                                style={{ width: "100%", objectFit: 'cover', objectPosition: 'center' }}
                                src={
                                    props.cropData ? props.cropData : defaultSrc
                                }
                                alt="cropped"
                                className="h-100px w-100px rounded text-dark"
                            />
                        </span>
                    </div>
                    <div className="position-relative form-file">
                        <label className="form-label mb-0" htmlFor='group-icon-crop' >
                            <button type='button' className='btn btn-bg-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1'>
                                <span className="svg-icon svg-icon-1">
                                    <KTSVG path="/media/icons/duotune/files/fil022.svg" className="ic svg-icon-muted me-1" />
                                </span>Upload Image
                            </button>
                        </label>
                        <input
                            type="file"
                            className="file-input opacity-0 position-absolute h-auto"
                            placeholder="Type here..."
                            id='group-icon-crop'
                            onChange={onChange}
                            accept="image/*"
                        />
                        <div className={clsx(
                            `font-13 mt-2 mt-2 ${sizeValidation === 'is-invalid'? 'text-danger' : 'text-muted'}`, props.imageSize,
                        )}>
                            {
                                props.text ? props.text : 'Max. File Size: 5MB'
                            }
                        </div>
                    </div>
                </div>
                {props.imageRequire && <span className="font-13 text-danger" >This field is required.</span>}
            </div>
            <Modal show={show} centered id='modal_img_crop' >
                <Modal.Header closeButton onClick={handleHide} >
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Cropper
                        style={{ height: "100%", width: "100%" }}
                        zoomTo={0}
                        aspectRatio={1 / 1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={200}
                        minCropBoxWidth={200}
                        background={false}
                        responsive={false}
                        autoCropArea={1}
                        scalable={false}
                        // checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                            setCropper(instance)
                        }}
                        guides={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={getCropData}>
                        {isLoadin}
                    </button>
                    <button className="btn btn-secondary" onClick={handleHide}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Imagecropper;
