import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {
  apiBaseURL,
  dateFormat,
  fetchStaticAsyncDropdown,
  isEmpty,
  numberFormatWithCode,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import tenantConfiguration from '../../../TenantVariables'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'
import clsx from 'clsx'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {requestStatus} from './status-const'
import {AsyncPaginate} from 'react-select-async-paginate'

type Props = {
  className: string
}
const DiscountRequestList: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const priceHeaderTitle = {
    title: 'Discount Request ',
    subTitle: 'Price & Discounts',
  }
  const navigate = useNavigate()

  let discountCappingFiltered: any = retrieveFilter(filters.wm_discount_requesting)

  const initialStatus = {
    value: '',
    label: 'All Status',
  }

  const initialState = {
    selected: discountCappingFiltered?.selected || '',
    search: discountCappingFiltered?.search || '',
    currentOffSet: discountCappingFiltered?.currentOffSet || '',
    buttonDisable: true,
    status: discountCappingFiltered?.status || initialStatus,
    discountType: discountCappingFiltered?.discountType || 'action-required',
  }

  const [discountRequestType, setDiscountRequestType] = useState(
    discountCappingFiltered?.discountType || 'action-required'
  )
  const [currentOffset, setCurrentOffset] = useState<any>(
    discountCappingFiltered?.currentOffSet || '0'
  )
  const [selected, setSelected] = useState<number>(discountCappingFiltered?.selected || 0)
  const [discountRequests, setDiscountRequests] = useState<any>([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>(discountCappingFiltered?.search || '')
  const [count, setCount] = useState<string>('1')
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    discountCappingFiltered?.buttonDisable || false
  )
  const [status, setStatus] = useState(discountCappingFiltered?.status || initialStatus)
  const [isHoreca, setIsHoreca] = useState(false)

  const debounceSearch = useDebounce(searchByName, 500)
  const location = useLocation()

  let isActionRequired = location && location.search.includes('isActionRequired') ? '1' : '0'

  useEffect(() => {
    fetchDiscountRequests().then()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    if (isActionRequired && isActionRequired == '1') {
      updatedState['discountType'] = 'action-required'
    }

    setTimeout(() => {
      localStorage.removeItem('is-approval-action-required')
    }, 300)

    setFilterStates(updatedState)
    saveFilter(filters.wm_discount_requesting, filterStates)

    if (searchByName !== '' || discountRequestType !== 'action-required' || status.value !== '') {
      setButtonDisable(true)
    }
  }, [debounceSearch, currentOffset, discountRequestType, status, isHoreca])

  useEffect(() => {
    if (isActionRequired && isActionRequired == '1') {
      setDiscountRequestType('action-required')
    }
  }, [isActionRequired])

  const fetchIssueStatuses = (search?: string) => {
    return fetchStaticAsyncDropdown(requestStatus, search)
  }

  const fetchDiscountRequests = async () => {
    setDisplayLoader(true)

    const response = await getRequest(
      `${apiBaseURL()}delivery/additional-discount-list?limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&status=${
        discountRequestType === 'action-required' ? 'ss_approved' : status.value
      }&is_horeca_customer=${
        location.pathname.includes('/price/discount-request') && discountRequestType === 'all'
          ? isHoreca === true
            ? true
            : ''
          : ''
      }`,
      true
    )

    if (response.results) {
      const requests = response.results

      requests.map((request: any, index: number) => {
        if (isEmpty(request.amount)) {
          requests[index].amount = request.requested_amount
        }
      })

      setDiscountRequests(requests)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const orderDetail = (requestId: number) => {
    saveFilter(filters.wm_discount_requesting, filterStates)

    navigate('/price/discount-request/check-order-detail', {
      state: {
        request_id: requestId,
      },
    })
  }

  const filterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)

    setFilterStates({...filterStates, [inputName]: inputValue})

    switch (inputName) {
      case 'search':
        setSearchByName(inputValue)
        break
      case 'status':
        setStatus(inputValue)
        break
    }
  }

  const handleChangeReset = () => {
    setSearchByName('')
    setCurrentOffset(0)
    setSelected(0)
    setStatus(initialStatus)
    setDiscountRequestType('action-required')
    let updatedState: any = {...filterStates}
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title='Discount Request' />
      <div>
        <PageTitle breadcrumbs={[priceHeaderTitle]}>
          {intl.formatMessage({id: 'Discount Request'})}
        </PageTitle>
        <div className={`card ${className}`}>
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={filterChange}
              value={searchByName}
              isDisable={displayLoader}
            />

            <div className='col-auto ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                {discountRequestType === 'all' && (
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-2'>
                    <input
                      className='form-check-input widget-13-check'
                      name='isHoreca'
                      checked={isHoreca}
                      onChange={(e: any) => {
                        setIsHoreca(!isHoreca)
                      }}
                      type='checkbox'
                      disabled={false}
                      value=''
                      id='isHoreca'
                    />
                    <label className='form-check-label' htmlFor='isHoreca'>
                      HORECA Only
                    </label>
                  </div>
                )}

                <div className='btn-group me-3' role='group'>
                  <button
                    type='button'
                    className={clsx(
                      'btn btn-outline btn-outline-primary border-secondary text-dark' +
                        ' text-hover-white text-active-white',
                      discountRequestType === 'action-required' && !displayLoader && 'active'
                    )}
                    disabled={displayLoader}
                    onClick={() => {
                      setFilterStates({...filterStates, ['discountType']: 'action-required'})
                      setDiscountRequestType('action-required')
                    }}
                  >
                    Approval Action Requires
                  </button>
                  <button
                    type='button'
                    className={clsx(
                      'btn btn-outline btn-outline-primary border-secondary' +
                        ' text-dark text-hover-white text-active-white',
                      discountRequestType === 'all' && !displayLoader && 'active'
                    )}
                    disabled={displayLoader}
                    onClick={() => {
                      setFilterStates({...filterStates, ['discountType']: 'all'})
                      setDiscountRequestType('all')
                    }}
                  >
                    All
                  </button>
                </div>
                {discountRequestType !== 'action-required' && (
                  <AsyncPaginate
                    loadOptions={fetchIssueStatuses}
                    isSearchable
                    isDisabled={displayLoader}
                    placeholder='All Status'
                    className='react-select-container my-1 me-3 w-140px'
                    classNamePrefix='react-select'
                    name='sales'
                    onChange={(e: any) => filterChange(e, 'status')}
                    value={status}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
              {displayLoader ? (
                <FetchingRecords />
              ) : discountRequests.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-1'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-150px'>Sales Order ID</th>
                      <th className='min-w-140px'>SR Name</th>
                      <th className='min-w-120px'>Customer Name</th>
                      <th className='min-w-120px'>Invoice Amount</th>
                      <th className='min-w-120px'>Discount Amount</th>
                      <th className='min-w-120px'>Date</th>
                      <th className='min-w-120px'>Status</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {discountRequests.map((discountRequest: any) => {
                      return (
                        <tr
                          onClick={() => orderDetail(discountRequest.id)}
                          className='cursor-pointer'
                        >
                          <td>
                            {isEmpty(discountRequest.pre_order_brand_group_id)
                              ? '-'
                              : `#${discountRequest.pre_order_brand_group_id}`}
                          </td>
                          <td>
                            {isEmpty(discountRequest.sr_name) ? '-' : discountRequest.sr_name}
                          </td>
                          <td>
                            {isEmpty(discountRequest.customer_details) ||
                            discountRequest.customer_details.length === 0
                              ? '-'
                              : `${discountRequest.customer_details[0].customer_name}, ${discountRequest.customer_details[0].customer_street_name}`}
                          </td>
                          <td>
                            {isEmpty(discountRequest.invoice_amount)
                              ? '-'
                              : numberFormatWithCode(discountRequest.invoice_amount).displayFormat}
                          </td>
                          <td>
                            {!isEmpty(discountRequest.amount)
                              ? numberFormatWithCode(discountRequest.amount).displayFormat
                              : '-'}
                          </td>
                          <td>
                            {isEmpty(discountRequest.created_at) ? (
                              '-'
                            ) : (
                              <>
                                {dateFormat(discountRequest.created_at).displayDate}
                                <p className='font-13 text-muted mb-0'>
                                  {dateFormat(discountRequest.created_at, 'LT').displayDate}
                                </p>
                              </>
                            )}
                          </td>
                          <td>
                            {isEmpty(discountRequest.status) ? (
                              '-'
                            ) : discountRequest.status.toLowerCase() === 'pending' ? (
                              <span className='badge badge-light-warning text-dark'>Pending</span>
                            ) : discountRequest.status.toLowerCase() === 'approved' ? (
                              <span className='badge badge-light-success text-dark'>Approved</span>
                            ) : discountRequest.status.toLowerCase() === 'cancelled' ? (
                              <span className='badge  badge-secondary text-dark'>Cancelled</span>
                            ) : discountRequest.status.toLowerCase() === 'ss_approved' ? (
                              <span className='badge badge-light-success text-dark'>
                                SS Approved
                              </span>
                            ) : (
                              <span className='badge badge-light-danger text-dark'>Rejected</span>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={discountRequests}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.wm_discount_requesting}
          filterStates={filterStates}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default DiscountRequestList
