import React, { useEffect, useState } from "react"
import { Toast, ToastHeader } from "react-bootstrap"

function Toasts(props: any) {
  const [showToast, setShowToast] = useState(props.showToast)

  useEffect(() => {
    if (props.showToast) {
      setShowToast(true)
    } else {
      setShowToast(false)
    }
  }, [props.showToast])

  return (
    <>
      <div className='position-fixed bottom-0 end-0 p-3' style={{zIndex: '1111'}}>
        <Toast
          // bg='light-primary'
          bg={props.bgClass}
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={5000}
          autohide
          className='noheadtoast'
        >
          <ToastHeader>
            <div className='me-3 text-primary'>{props.message}</div>
          </ToastHeader>
        </Toast>
        {/* <Toast
                    bg='light-success'
                    show={showB} onClose={toggleShowB} delay={1000000} autohide className='noheadtoast mt-3'>
                    <ToastHeader>
                        <div className='me-3 text-success'>
                            Woohoo, you're reading this text in a Toast!
                        </div>
                    </ToastHeader>
                </Toast>
                <Toast
                    bg='light-danger'
                    show={showC} onClose={toggleShowC} delay={1000000} autohide className='noheadtoast mt-3'>
                    <ToastHeader>
                        <div className='me-3 text-danger'>
                            Woohoo, you're reading this text in a Toast!
                        </div>
                    </ToastHeader>
                </Toast>
                <Toast
                    bg='light-warning'
                    show={showD} onClose={toggleShowD} delay={1000000} autohide className='noheadtoast mt-3'>
                    <ToastHeader>
                        <div className='me-3 text-dark'>
                            Woohoo, you're reading this text in a Toast!
                        </div>
                    </ToastHeader>
                </Toast> */}
      </div>
    </>
  )
}
export default Toasts
