export const activitiesEnum = [
  {label: 'Gatepass Exit Check', value: 'gate_pass_exit_check'},
  {label: 'Gatepass Entry Check', value: 'gate_pass_entry_check'},
  {label: 'Make a Sale Location Check', value: 'make_a_sale_location_check'},
  {label: 'Credit Collection Location Check', value: 'credit_collection_location_check'},
  {label: 'Make a Sale', value: 'make_a_sale'},
  {label: 'Start Delivery', value: 'start_delivery'},
  {label: 'Credit Request Send', value: 'credit_request_send'},
  {label: 'Credit Request Approved', value: 'credit_request_approved'},
  {label: 'Credit Request Rejected', value: 'credit_request_rejected'},
  {label: 'Discount Request Send', value: 'discount_request_send'},
  {label: 'Discount Request Approved', value: 'discount_request_approved'},
  {label: 'Discount Request Rejected', value: 'discount_request_rejected'},
  {label: 'Order Quantity Request Send', value: 'order_qty_request_send'},
  {label: 'Order Quantity Request Approved', value: 'order_qty_request_approved'},
  {label: 'Order Quantity Request Rejected', value: 'order_qty_request_rejected'},
  {label: 'Order Limit Request Send', value: 'order_limit_request_send'},
  {label: 'Order Limit Request Approved', value: 'order_limit_request_approved'},
  {label: 'Order Limit Request Rejected', value: 'order_limit_request_rejected'},
  {label: 'Payment Received', value: 'payment_received'},
  {label: 'Signature', value: 'signature'},
  {label: 'Credit Collection', value: 'credit_collection'},
  {label: 'Cash Deposit', value: 'cash_deposit'},
  {label: 'Feedback Collection', value: 'feedback_collection'},
  {label: 'Survey Administered', value: 'survey_administered'},
  {label: 'Customer Creation', value: 'customer_creation'},
]

export const getActivityEnumName = (type: string) => {
  return activitiesEnum.find((i) => i.value === type)?.label || '-'
}
