import moment from 'moment'
import {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../TenantVariables'
import {getRequest} from '../../https/apiCall'
import {apiBaseURL, isEmpty} from '../CommonHelpers'

interface Props {
  street: any
  handleFilterChange: any
  isDisabled: boolean
  className?: string
  allStreet?: boolean
  ward_id?: string
}

export const StreetFilter = ({
  street,
  handleFilterChange,
  isDisabled,
  className,
  allStreet = true,
  ward_id,
}: Props) => {
  const [streets, setStreets] = useState<any>([])
  const [offset, setOffset] = useState<number>(0)
  const [reset, setReset] = useState<any>(moment())

  const fetchStreets = async (search?: any) => {
    if (search !== '') {
      setOffset(0)
    }

    let options: any = []

    const response = await getRequest(
      `${apiBaseURL()}master/street/list?ordering=name&search=${search}&limit=${
        tenantConfiguration.limit
      }&offset=${offset}&ward__id=${isEmpty(ward_id) ? '' : ward_id}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setOffset(newOffset)
      hasMore = true
    }

    if (allStreet && streets.length === 0) {
      options.push({
        label: 'All Streets',
        value: '',
      })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (streets.length > 0) {
      setStreets(streets.concat(options))
    } else {
      setStreets(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  useEffect(() => {
    setReset(moment())
    setOffset(0)
    setStreets([])
  }, [ward_id])

  return (
    <>
      <AsyncPaginate
        key={reset}
        loadOptions={fetchStreets}
        isDisabled={isDisabled}
        isSearchable
        className={`react-select-container ${className ? className : 'my-1 me-3 w-190px'}`}
        classNamePrefix='react-select'
        placeholder='All Streets'
        value={street}
        onChange={(e: any) => handleFilterChange(e, 'street')}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#0c78a4',
          },
        })}
      />
    </>
  )
}
