import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import { streetCreate, WardCreate, WardList, WardUpdate } from "../../../../_eaFruitsDms/apiFunctions/master/master";
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation
} from "../../../../_eaFruitsDms/helpers";
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import { getToken } from "../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage";
import TableWard from "./component/tableWard";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";

type Props = {
  className: string
  props?: any
}

const Ward: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Wards',
  }

  // all states
  const [getList, setGetList] = useState<any>([])
  const [id, setId] = useState('')
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [count, setCount] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [getLength, setLength] = useState('1')
  const [getRegionsList, setRegionsList] = useState<any>([])
  const [getDistrictsList, setDistrictsList] = useState<any>([])
  const [DistrictValue, setSdistrict] = useState<any>({ label: '', value: '' })
  const [regionsValue, setSregion] = useState<any>({ label: '', value: '' })
  const [rModal, setRModal] = useState('')
  const [districtModalValue, setDModal] = useState<any>('')
  const [districtModalValueEdit, setDModalEdit] = useState<any>('')
  const [districtName, setDistrictName] = useState('')
  const [regionName, setRegionName] = useState('')
  const [wardName, setWardName] = useState('')
  const [kmlFile, setKmlFile] = useState('')
  const [kmlFileName, setKmlName] = useState('Browse')
  const [wardNameEdit, setWardNameEdit] = useState('')
  const [kmlFileEdit, setKmlFileEdit] = useState<any>('')
  const [kmlFileNameEdit, setKmlNameEdit] = useState('Browse')
  const [wardValidation, setWardValidation] = useState('')
  const [districtValidation, setDsitrictValidation] = useState('')
  const [kmlValidation, setKmlValidation] = useState('')
  const [wardValidationEdit, setWardValidationEdit] = useState('')
  const [districtValidationEdit, setDsitrictValidationEdit] = useState('')
  const [kmlValidationEdit, setKmlValidationEdit] = useState('')
  const [regionValidation, setRegionValidation] = useState('')
  const [districtData, setDistrictData] = useState<any>([])
  const [listLoadder, setListLoader] = useState<any>(true)
  const [editDis, setDis] = useState(false)
  const [newValue, setNewValue] = useState<any>('')
  const [sKml, setSKml] = useState('')
  const [sKmlLink, setSKmlLink] = useState('#')
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [searchSelect, setSearchSelect] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  // Authorization token
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  useEffect(() => {
    fetchPermission()
    setListLoader(true)
   
    WardList('10', '0', '', '', '').then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })
    getDistricts()
    getRegions()
    // fetchRegions()
    // fetchDistrict()
  }, [])
  useEffect(() => {
    // setBankOffset('')
    setRegionOffset('')
    setGroupOffset('')
    setRoleOffset('')
  }, [searchSelect])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_distribution_boundaries")
    setPermission(res)
   }

  // default list for react-select
  let optGroup = [
    {
      label: '',
      options: [{ label: '', value: '' }],
    },
  ]

  let optionData: any = []
  const apiLimit = '&limit=10000'
  const getDistricts = async () => {
    //request method for api implementation :- last change by kriti
    getRequest(`${apiBaseURL()}master/district/list/?ordering=name`, true).then((res) => {
      setDistrictsList(res.results)
    })

    let options: any = []

    const districtApi = `${apiBaseURL()}master/district/list/?ordering=name` + apiLimit

    const regionResponse = await getRequest(districtApi, true)

    if (regionResponse.results.length > 0) {
      regionResponse.results.forEach((item: any) => {
        options.push({ label: item.name, value: item.id })
      })
      setDistrictData(options)
    }
  }

  const getRegions = async () => {
    // get regions list with name ordering
    //request method for api implementation :- last change by kriti
    await getRequest(`${apiBaseURL()}master/region/list/?ordering=name`, true).then((res) =>
      setRegionsList(res.results)
    )
  }

  const getProps = async (val: any) => {
    setMessageHandler('successEdit')
    setId(val.id)

    // get selected value
    //request method for api implementation :- last change by kriti
    setModalLoader(true)
    await getRequest(`${apiBaseURL()}master/wards/${val.id}/`, true).then((res) => {
      // for kml
      if (res.kml_file !== null) {
        var filename = res.kml_file.substring(res.kml_file.lastIndexOf('/') + 1)
        setSKml(filename)
        setSKmlLink(res.kml_file)
      } else {
        setSKml('')
        setSKmlLink('#')
      }

      setWardNameEdit(res.name)
      setDModalEdit(res.district)
      setModalLoader(false)
      let districtValue: any = ''
      let selectedDistrictValue: any = []
      // districtData.forEach((element: any) => {
      //   districtValue = element.options.filter((val: any) => {
      //     return val.value === res.data.district
      //   })
      //   districtValue.forEach((element: any) => {
      //     selectedDistrictValue.push(element)
      //   })
      // })
      setNewValue(selectedDistrictValue)
    })
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    setListLoader(true)
      WardList(
        '10',
        newOffset,
        DistrictValue.label === 'All Districts' ? '' : DistrictValue.label,
        regionsValue.label === 'All Regions' ? '' : regionsValue.label,
        search
      ).then((res) => {
        setGetList(res.data)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setCount(res.count)
        setListLoader(false)
        setNext(res.next)
        setPrev(res.prev)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    setListLoader(true)
      WardList(
        '10',
        newOffset,
        DistrictValue.label === 'All Districts' ? '' : DistrictValue.label,
        regionsValue.label === 'All Regions' ? '' : regionsValue.label,
        search
      ).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      WardList(
        '10',
        newOffset,
        DistrictValue.label === 'All Districts' ? '' : DistrictValue.label,
        regionsValue.label === 'All Regions' ? '' : regionsValue.label,
        search
      ).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
    setListLoader(true)
      WardList(
        '10',
        '0',
        DistrictValue.label === 'All Districts' ? '' : DistrictValue.label,
        regionsValue.label === 'All Regions' ? '' : regionsValue.label,
        e.target.value
      ).then((res) => {
        setGetList(res.data)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setCount(res.count)
        setListLoader(false)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
      })
    }, 500)
    setTimer(newTimer)
  }
  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const changeDistrict = (val: any) => {
    setSdistrict(val)
    setListLoader(true)
    WardList(
      '10',
      '0',
      val.label === 'All Districts' ? '' : val.label,
      regionsValue.label === 'All Regions' ? '' : regionsValue.label,
      search
    ).then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
      setListLoader(false)
    })
  }

  const changeRegion = (val: any) => {
    setSregion(val)
    setListLoader(true)
    WardList(
      '10',
      '0',
      DistrictValue.label === 'All Districts' ? '' : DistrictValue.label,
      val.label === 'All Regions' ? '' : val.label,
      search
    ).then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setListLoader(false)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
    })
  }

  const clearFunctionWard = () => {
    // clear add modal state
    setErrorMessage('')
    setWardValidation('')
    setDsitrictValidation('')
    setRegionValidation('')
    setKmlValidation('')
    setGroupOffset('')
    setWardName('')
    setDistrictName('')
    setRegionName('')
    setKmlFile('')
    setKmlName('Browse')
    setDModal('')
    setRModal('')
  }

  const clearFunctionWardEdit = () => {
    // clear edit modal state
    setErrorMessage('')
    setWardValidationEdit('')
    setDsitrictValidationEdit('')
    setKmlValidationEdit('')
    setDis(false)
    setWardNameEdit('')
    setGroupOffset('')
    setKmlFileEdit('')
    setKmlNameEdit('Browse')
    setDModalEdit('')
  }

  const addFunctionWard = () => {
    let navigateField = {
      wardName: wardName,
      districtModalValue: districtModalValue,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // ui validation [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    if (wardName == '') {
      setWardValidation('is-invalid')
    } else {
      setWardValidation('')
    }
    if (districtModalValue == '') {
      setDsitrictValidation('is-invalid')
    } else {
      setDsitrictValidation('')
    }

    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    if (wardName !== '') {
      if (districtModalValue !== '') {
        setDisableBtn(true)
        setIsLoading('Please Wait..')
        WardCreate(wardName, districtModalValue, kmlFile).then((res) => {
          setDisableBtn(false)
          setIsLoading('Submit')
          if (res.success) {
            clearFunctionWard()
            setDistrictList([])
            setRegionList([])
            setSregion({ label: '', value: '' })
            setSdistrict({ label: '', value: '' })
            setListLoader(true)
            WardList('10', '0', '', '', '').then((res) => {
              setGetList(res.data)
              if (res.data.length == 0) {
                setLength('2')
              } else {
                setLength('1')
              }
              setCount(res.count)
              setNext(res.next)
              setPrev(res.prev)
              setSelected(0)
              setListLoader(false)
            })
            setTimeout(() => {
              setIsAlertSuccess(false)
              setIsAlertFailed(false)
            }, 2000)
            $('#ea_modal_close_add_Wards').trigger('click') // modal close
            setIsAlertSuccess(true)
          }
          if (res.failed) {
            // setIsAlertFailed(true)
          }
          if (res.errorField == 'name') {
            setErrorMessage(
              'Your added ward name already exists in this system! Please try again with a unique name.'
            )
          }
        })
      }
    }
  }

  const EditFunctionWard = () => {
    let navigateField = {
      wardNameEdit: wardNameEdit,
      districtModalValueEdit: districtModalValueEdit,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (wardNameEdit == '') {
      setWardValidationEdit('is-invalid')
    } else {
      setWardValidationEdit('')
    }
    if (districtModalValueEdit == '') {
      setDsitrictValidationEdit('is-invalid')
    } else {
      setDsitrictValidationEdit('')
    }

    if (wardNameEdit !== '') {
      if (districtModalValueEdit !== '') {
        let districtModalValue = ''
        if (districtModalValueEdit.value) {
          districtModalValue = districtModalValueEdit.value
        } else {
          districtModalValue = districtModalValueEdit
        }
        setDisableBtn(true)
        setIsLoading('Please Wait..')
        WardUpdate(id, wardNameEdit, districtModalValue, kmlFileEdit).then((res) => {
          setDisableBtn(false)
          setIsLoading('Submit')
          if (res.success) {
            clearFunctionWardEdit()
            setListLoader(true)
            WardList(
              '10',
              '0',
              DistrictValue.label === 'All Districts' ? '' : DistrictValue.label,
              regionsValue.label === 'All Regions' ? '' : regionsValue.label,
              search
            ).then((res) => {
              setGetList(res.data)
              if (res.data.length == 0) {
                setLength('2')
              } else {
                setLength('1')
              }
              setCount(res.count)
              setListLoader(false)
              setNext(res.next)
              setPrev(res.prev)
              setSelected(0)
            })
            setTimeout(() => {
              setIsAlertSuccess(false)
              setIsAlertFailed(false)
            }, 2000)
            $('#ea_modal_close_Edit_Wards').trigger('click') // modal close
            setIsAlertSuccess(true)
          }
          if (res.failed) {
            // setIsAlertFailed(true)
          }
          if (res.errorField == 'name') {
            setErrorMessage(
              'Your added ward name already exists in this system! Please try again with a unique name.'
            )
          }
        })
      }
    }
  }

  const selectKmlFileDistrictEdit = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlNameEdit(e.target.files[0].name)
      setKmlFileEdit(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const selectKmlFileDistrict = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlName(e.target.files[0].name)
      setKmlFile(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const [kmlFileNameKml, setkmlFileNameKml] = useState('Browse')
  const [KmlFile, setKMLFile] = useState('')
  const [distId, setDistId] = useState('')
  const [streetValidation, setSvalidation] = useState('')
  const [streetName, setStreetName] = useState('')
  const [selectWard, setSelectWard] = useState('')

  const clearFunctionKML = () => {
    // clear KML modal state
    setKMLFile('')
    setkmlFileNameKml('Browse')
    setKmlValidationEdit('')
  }

  const kmlFunction = () => {
    if (KmlFile == '') {
      setKmlValidationEdit('is-invalid')
    } else {
      setKmlValidationEdit('')
    }

    if (KmlFile !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')

      WardUpdate(id, wardNameEdit, distId, KmlFile).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunctionKML()
          clearFunctionWardEdit()
          setListLoader(true)
          WardList('10', '0', '', '', '').then((res) => {
            setGetList(res.data)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)
            setListLoader(false)
          })
          setIsAlertSuccess(true)
        }
        if (res.failed) {
          setIsAlertFailed(true)
        }
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        $('#ea_modal_close_kml_region').trigger('click') // modal close
      })
    }
  }

  const selectKmlFileKml = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setkmlFileNameKml(e.target.files[0].name)
      setKMLFile(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const getKmlProps = (val: any) => {
    setMessageHandler('successKml')
    setWardNameEdit(val.name)
    setId(val.id)
    getRequest(`${apiBaseURL()}master/wards/${val.id}/`, true).then((res) => {
      setDistId(res.district)
    })
  }

  const getStreetProps = (val: any) => {
    setMessageHandler('successStreet')
    setSelectWard(val.id)
  }

  const clearFunctionStreet = () => {
    // clear add modal state
    setStreetName('')
    setDModal('')
    setSvalidation('')
    setKmlFile('')
    setKmlName('Browse')
    setDsitrictValidation('')
    setErrorMessage('')
  }

  const selectKmlFile = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlName(e.target.files[0].name)
      setKmlFile(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const addFunctionStreet = () => {
    // ui validation [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    if (streetName == '') {
      setSvalidation('is-invalid')
    } else {
      setSvalidation('')
    }

    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    if (streetName.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      streetCreate(streetName, selectWard, '', kmlFile).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunctionStreet()
          setListLoader(true)
          WardList('10', '0', '', '', '').then((res) => {
            setGetList(res.data)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)
            setListLoader(false)
          })
          setTimeout(() => {
            setIsAlertSuccess(false)
            setIsAlertFailed(false)
          }, 2000)
          $('#ea_modal_close_add_Street').trigger('click') // modal close
          setIsAlertSuccess(true)
        }
        if (res.failed) {
          // setIsAlertFailed(true)
        }
        if (res.errorField == 'name') {
          setErrorMessage(
            'Your added street name already exists in this system! Please try again with a unique name.'
          )
        }
      })
    }
  }

  const [districtList, setDistrictList] = useState<any>([])
  const [regionList, setRegionList] = useState<any>([])

  const [limit, setLimit] = useState<number>(10)
  const [roleOffset, setRoleOffset] = useState<any>('')

  const [Regionlimit, setRegionLimit] = useState<number>(10)
  const [RegionOffset, setRegionOffset] = useState<any>('')

  const [grouplimit, setGroupLimit] = useState<number>(10)
  const [groupOffset, setGroupOffset] = useState<any>('')

  const fetchDistrict = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const districtApi = `${apiBaseURL()}master/district/list/?ordering=name&search=${search}&limit=${limit}&offset=${roleOffset}`

    const regionResponse = await getRequest(districtApi, true)

    let hasMore: boolean = false

    if (regionResponse.next !== null) {
      const queryParams = new URLSearchParams(regionResponse.next)
      let newOffset: any = queryParams.get('offset')
      setRoleOffset(newOffset)
      hasMore = true
    }

    if (districtList.length == 0) {
      options.push({
        label: 'All Districts',
        value: '',
      })
    }

    if (regionResponse.results.length > 0) {
      regionResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (districtList.length > 0) {
      setDistrictList(districtList.concat(options))
    } else {
      setDistrictList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchGroupDistrict = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const districtApi = `${apiBaseURL()}master/region_district_ward/list/?ordering=name&search=${search}&limit=${grouplimit}&offset=${groupOffset}`

    const regionResponse = await getRequest(districtApi, true)

    let hasMore: boolean = false

    if (regionResponse.next !== null) {
      const queryParams = new URLSearchParams(regionResponse.next)
      let newOffset: any = queryParams.get('offset')
      setGroupOffset(newOffset)
      hasMore = true
    }

    if (districtData.length == 0) {
      // options.push({
      //     label: "All Districts",
      //     value: ""
      // })
    }

    if (regionResponse.results.length > 0) {
      let region = ''
      regionResponse.results.map((option: any) => {
        let disName = [{ label: '-', value: '-' }]
        region = option.name
        let nameFormating: any = option.district.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name)
        })
        let removeBlankSpace = false
        nameFormating.forEach((dist: any) => {
          removeBlankSpace = true
          disName.push({ label: dist.name, value: dist.id })
        })
        if (removeBlankSpace) {
          disName.shift()
        }
        options.push({
          label: region,
          options: disName,
        })

        // options.push({
        //     label: region,
        //     options: disName
        // })
      })
    }

    if (districtData.length > 0) {
      // setDistrictData(districtData.concat(options))
    } else {
      // setDistrictData(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchGroupDistrictEdit = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const districtApi = `${apiBaseURL()}master/region_district_ward/list/?ordering=name&search=${search}&limit=${grouplimit}&offset=${groupOffset}`

    const regionResponse = await getRequest(districtApi, true)

    let hasMore: boolean = false

    if (regionResponse.next !== null) {
      const queryParams = new URLSearchParams(regionResponse.next)
      let newOffset: any = queryParams.get('offset')
      setGroupOffset(newOffset)
      hasMore = true
    }

    if (districtData.length == 0) {
      // options.push({
      //     label: "All Districts",
      //     value: ""
      // })
    }

    if (regionResponse.results.length > 0) {
      let region = ''
      regionResponse.results.map((option: any) => {
        let disName = [{ label: '-', value: '-' }]
        region = option.name
        let nameFormating: any = option.district.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name)
        })
        let removeBlankSpace = false
        nameFormating.forEach((dist: any) => {
          removeBlankSpace = true
          disName.push({ label: dist.name, value: dist.id })
        })
        if (removeBlankSpace) {
          disName.shift()
        }
        options.push({
          label: region,
          options: disName,
        })

        // options.push({
        //     label: region,
        //     options: disName
        // })
      })
    }

    if (districtData.length > 0) {
      // setDistrictData(districtData.concat(options))
    } else {
      // setDistrictData(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchRegions = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const regionApi = `${apiBaseURL()}master/region/list?ordering=name&search=${search}&limit=${Regionlimit}&offset=${RegionOffset}`

    const regionResponse = await getRequest(regionApi, true)

    let hasMore: boolean = false

    if (regionResponse.next !== null) {
      const queryParams = new URLSearchParams(regionResponse.next)
      let newOffset: any = queryParams.get('offset')
      setRegionOffset(newOffset)
      hasMore = true
    }

    if (regionList.length == 0) {
      options.push({
        label: 'All Regions',
        value: '',
      })
    }

    if (regionResponse.results.length > 0) {
      regionResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (regionList.length > 0) {
      setRegionList(regionList.concat(options))
    } else {
      setRegionList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  return (
    <>
      <EATitle title='Ward' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>{intl.formatMessage({ id: 'Wards' })}</PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('WARD.ADD')
            : messageHandler === 'successEdit'
              ? FormatMessage('WARD.UPDATE')
              : messageHandler === 'successKml'
                ? FormatMessage('KML.ADD')
                : FormatMessage('STREET.ADD')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar d-flex g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => searchList(e)} />
            {/* <DropdownDistricts getDistrictsList={getDistrictsList} changeDistrict={changeDistrict} /> */}
            {/* <DropdownRegion getRegionsList={getRegionsList} changeRegion={changeRegion} /> */}
            <form
              onSubmit={(e) => e.preventDefault()}
              autoComplete='off'
              className='ms-auto d-flex'
            >
              <AsyncPaginate
                    
                loadOptions={fetchDistrict}
                isSearchable
                className={`react-select-container w-120px me-3`}
                classNamePrefix='react-select'
                name='districts'
                placeholder='All Districts'
                value={districtList.filter((option: any) => option.value === DistrictValue.value)}
                onChange={changeDistrict}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              <AsyncPaginate
                    
                loadOptions={fetchRegions}
                isSearchable
                className={`react-select-container w-120px me-3`}
                classNamePrefix='react-select'
                name='region'
                placeholder='All Regions'
                value={regionList.filter((option: any) => option.value === regionsValue.value)}
                onChange={changeRegion}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </form>

            {permission.write && (
              <div className='col-md-auto'>
                {/* <EaButton isModal={false} btnName='Add Ward' ></EaButton> */}
                <EaButton
                  onClick={() => {
                    setMessageHandler('successAdd')
                  }}
                  isModal={true}
                  dataTarget='#ea_modal_create_ward'
                  btnName='Add Ward'
                />
              </div>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0 ? (
              <NoRecords />
            ) : (
              <TableWard
                showDelete={permission.delete}
                showWrite={permission.write}
                getLength={getLength}
                tableList={getList}
                getPropsEdit={getProps}
                getKmlProps={getKmlProps}
                getStreetProps={getStreetProps}
              />
            )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
              } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>

      {/* add ward */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        id='ea_modal_create_ward'
        modalTitle='Add Ward'
        cancel={clearFunctionWard}
        closeAuto='ea_modal_close_add_Wards'
        onSubmit={() => addFunctionWard()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Ward Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='wardName'
            className={clsx('form-control', wardValidation)}
            placeholder='Type here...'
            value={wardName}
            onChange={(e) => {
              setWardName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setWardValidation(validation)
            }}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            District Name<span className="text-danger">*</span>
          </label>

          <AsyncPaginate
                    
            loadOptions={fetchGroupDistrict}
            isSearchable
            className={`react-select-container w-100 ms-md-auto ${districtValidation}`}
            classNamePrefix='react-select'
            name='role'
            id='districtModalValue'
            placeholder='Select'
            // value={districtModalValue}
            value={districtData.filter((option: any) => option.value === districtModalValue)}
            onChange={(e: any) => {
              console.log('e == ', e)

              setDModal(e.value)
              let event = e
              if (event.length === 0) {
                event = ''
              }
              const validation = ValidationHandler(event, 'is-invalid')
              setDsitrictValidation(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />

          {/* <Select
                        options={districtData}
                        onChange={(e: any) => {
                            setDModal(e)
                            let event = e
                            if (event.length === 0) {
                                event = ""
                            }
                            const validation = ValidationHandler(event, "is-invalid")
                            setDsitrictValidation(validation)
                        }}
                        value={districtModalValue.value !== "-" ? districtModalValue : setDModal('')}
                        className={
                            clsx('react-select-container', districtValidation)
                        }
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#0c78a4',
                            },
                        })}
                    /> */}
        </div>
        <div className=''>
          <label className='form-label'>KML File</label>
          <label htmlFor='kml-file' className={clsx('form-control file-input', kmlValidation)}>
            <span className='text-muted'>{kmlFileName}</span>
          </label>
          <input type='file' id='kml-file' onChange={selectKmlFileDistrict} />
        </div>

        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* edit ward */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        id='ea_modal_Edit_ward'
        modalTitle='Edit Ward'
        cancel={clearFunctionWardEdit}
        closeAuto='ea_modal_close_Edit_Wards'
        onSubmit={() => EditFunctionWard()}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Ward Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='wardNameEdit'
            className={clsx('form-control', wardValidationEdit)}
            placeholder='Type here...'
            value={wardNameEdit}
            onChange={(e) => {
              setWardNameEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setWardValidationEdit(validation)
            }}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='districtValidationEdit'>
            District Name<span className="text-danger">*</span>
          </label>

          <AsyncPaginate
                    
            loadOptions={fetchGroupDistrictEdit}
            isSearchable
            className={`react-select-container w-100 ms-md-auto ${districtValidationEdit}`}
            classNamePrefix='react-select'
            name='role'
            placeholder='Select'
            // value={districtModalValueEdit}
            value={districtData.filter((option: any) => option.value === districtModalValueEdit)}
            onChange={(e: any) => {
              setDModalEdit(e.value)
              setDis(true)

              let event = e
              if (event.length === 0) {
                event = ''
              }
              const validation = ValidationHandler(event, 'is-invalid')
              setDsitrictValidationEdit(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />

          {/* <Select
                        options={districtData}
                        onChange={(e: any) => {
                            setDModalEdit(e)
                            setDis(true)

                            let event = e
                            if (event.length === 0) {
                                event = ""
                            }
                            const validation = ValidationHandler(event, "is-invalid")
                            setDsitrictValidationEdit(validation)
                        }}
                        value={editDis === true ? districtModalValueEdit : newValue}
                        className={
                            clsx('react-select-container', districtValidationEdit)
                        }
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#0c78a4',
                            },
                        })}
                    /> */}
        </div>
        <div className='position-relative'>
          <div className='d-flex justify-content-between'>
            <label className='form-label me-2'>KML File</label>
            <a
              href={sKmlLink}
              className='text-hover-primary text-decoration-underline text-hover-decoration-underline text-truncate'
              target='_blank'
            >
              {sKml}
            </a>
          </div>
          <label
            htmlFor='kml-file-edit'
            className={clsx('form-control file-input', kmlValidationEdit)}
          >
            <span className='text-muted'>{kmlFileNameEdit}</span>
          </label>
          <input type='file' id='kml-file-edit' onChange={selectKmlFileDistrictEdit} />
        </div>

        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* add kml */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionKML}
        id='ea_modal_create_kmlfile'
        modalTitle='Add KML File'
        closeAuto='ea_modal_close_kml_region'
        onSubmit={() => kmlFunction()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            KML File<span className="text-danger">*</span>
          </label>
          <label htmlFor='kml-file' className={clsx('form-control file-input', kmlValidationEdit)}>
            <span className='text-muted'>{kmlFileNameKml}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            //
            onChange={selectKmlFileKml}
            id='kml-file'
          />
        </div>
      </EaModal>

      {/* add street */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        id='ea_modal_create_street'
        modalTitle='Add Street'
        cancel={clearFunctionStreet}
        closeAuto='ea_modal_close_add_Street'
        onSubmit={() => addFunctionStreet()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Street Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', streetValidation)}
            placeholder='Type here...'
            value={streetName}
            onChange={(e) => {
              setStreetName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setSvalidation(validation)
            }}
          />
        </div>
        <div className=''>
          <label className='form-label'>KML File</label>
          <label htmlFor='kml-file' className='form-control file-input'>
            <span className='text-muted'>{kmlFileName}</span>
          </label>
          <input
            type='file'
            id='kml-file'
            //
            onChange={selectKmlFile}
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  )
}

export default Ward
