import { apiBaseURL } from "../../../helpers"
import { postRequest } from "../../../https/apiCall"
import {
	FORGOT_PASSWORD_EAFRUITS_FAIL,
	FORGOT_PASSWORD_EAFRUITS_IS_LOADING,
	FORGOT_PASSWORD_EAFRUITS_SUCCESS
} from "../../types/types"

export const ForgotPasswordEa = (email: string, reset_url: string, resetForm: any) => async (dispatch: any) => {
    dispatch({
        type: FORGOT_PASSWORD_EAFRUITS_IS_LOADING
    })
    postRequest(`${apiBaseURL()}auth/reset-password`, {
        email: email,
        reset_url: reset_url,
    }).then((res) => {
        if (res.data.error) {
            dispatch({
                type: FORGOT_PASSWORD_EAFRUITS_FAIL,
                payload: "The entered email address is not register with user account"
            })
            return
        }

        dispatch({
            type: FORGOT_PASSWORD_EAFRUITS_SUCCESS,
            payload: `Reset-Password url sent to ${email}`
        })
        resetForm()
    })
}

export const resetForgotValues = () => (dispatch: any) => {
    dispatch({
        type: FORGOT_PASSWORD_EAFRUITS_FAIL,
        payload: ''
    })
}
