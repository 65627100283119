import React, {useEffect, useState} from 'react'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {apiBaseURL, dateFormat, isEmpty, KTSVG} from '../../../../_eaFruitsDms/helpers'
import tenantConfiguration from '../../../../TenantVariables'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import OrderQuantityProducts from './components/orderQuantityProduct'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'

function OrderQuantityRequestDetail() {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()

  const customerHeaderTitle = {
    title:
      location.pathname !== '/reports/order-quantity-requests/quantity-request-detail'
        ? 'Customers'
        : 'Reports',
  }

  const {state}: any = location

  const [productRequest, setProductsRequest] = useState<any>([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [customer, setCustomer] = useState<any>([])
  const [orderedProducts, setOrderedProducts] = useState<any>([])

  useEffect(() => {
    fetchOrderQuantityDetails().then()
  }, [])

  const fetchOrderQuantityDetails = async () => {
    setDisplayLoader(true)
    const response = await getRequest(
      `${apiBaseURL()}preorder/sales-quantity-requests/${state.order_id}`,
      true
    )

    if (response) {
      setCustomer(response)
      let rows = [...productRequest]
      response.delivery_product_order.length > 0 &&
        response.delivery_product_order.map((item: any) => {
          const price = !isEmpty(item.delivery_date_discount_price)
            ? item.delivery_date_discount_price
            : isEmpty(item.delivery_date_price)
            ? 0
            : item.delivery_date_price

          let appliedPrice =
            parseInt(item.product_setting_qty) === 0
              ? item.discounted_price === 0
                ? item.product_price
                : item.discounted_price
              : parseInt(item.approved_qty) > parseInt(item.product_setting_qty) ||
                parseInt(item.approved_qty) === parseInt(item.product_setting_qty)
              ? item.wholesaler_discounted_price === 0
                ? item.wholesaler_price
                : item.wholesaler_discounted_price
              : item.discounted_price === 0
              ? item.product_price
              : item.discounted_price

          rows.push({
            product_sku: item.product_sku.id,
            requested_quantity: item.requested_qty,
            is_crossed_max_qty: item.is_crossed_max_qty,
            approved_qty: item.approved_qty,
            price: price,
            // total_price: item.requested_qty * price,
            total_price: item.requested_qty * appliedPrice,
            validation_for_qty: '',
            max_qty: '',
            product_setting_qty: item.product_setting_qty,
            wholesaler_discounted_price: item.wholesaler_discounted_price,
            wholesaler_price: item.wholesaler_price,
            discounted_price: item.discounted_price,
            product_price: item.product_price,
          })
        })

      setProductsRequest(rows)
      setOrderedProducts(response.delivery_product_order)
    }
    setDisplayLoader(false)
  }

  const backURL = () => {
    let URL = '/customers/order-quantity-requests'

    if (location.pathname === '/reports/order-quantity-requests/quantity-request-detail') {
      URL = '/reports/order-quantity-requests'
    } else {
      URL = '/customers/order-quantity-requests'
    }

    return URL
  }

  const goToOrderDetailPage = (orderId: number) => {
    localStorage.setItem('oId', orderId.toString())
    localStorage.setItem('redirection_back', location.pathname)
    navigate('/reports/order-quantity-requests/order-detail')
  }

  return (
    <>
      {/* <EATitle title='Order Quantity Request' />
      <PageTitle breadcrumbs={[customerHeaderTitle]} backNavigate={backURL}>
        {intl.formatMessage({id: 'Order Quantity Request'})}
      </PageTitle> */}
      <EATitle
        title={
          location.pathname !== '/reports/order-quantity-requests/quantity-request-detail'
            ? 'Order Quantity Request'
            : 'Sales Quantity Control Report'
        }
      />
      <PageTitle breadcrumbs={[customerHeaderTitle]} backNavigate={backURL}>
        {intl.formatMessage({
          id:
            location.pathname !== '/reports/order-quantity-requests/quantity-request-detail'
              ? 'Order Quantity Request'
              : 'Sales Quantity Control Report',
        })}
      </PageTitle>
      {/* Header */}
      {displayLoader ? (
        <FetchingRecords />
      ) : (
        <>
          <div className='card mb-4 mb-xl-5'>
            <div className='card-body pt-5 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-1'>
                        <span className='text-dark font-20 fw-bold me-1'>
                          Order No #
                          {customer && isEmpty(customer.order_no) ? '-' : customer.order_no}
                        </span>
                      </div>
                      <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                        <span className='d-flex align-items-center me-6 mb-2'>
                          <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                          {customer && isEmpty(customer.requested_at) ? (
                            '-'
                          ) : (
                            <>
                              {dateFormat(customer.requested_at).displayDate} ,{' '}
                              {dateFormat(customer.requested_at, 'LT').displayDate}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-wrap flex-sm-nowrap'>
                  <div className='flex-grow-1'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap'>
                      <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center mb-1'>
                          {customer && customer.status && (
                            <>
                              {customer.status === 'approved' ? (
                                <span className='badge badge-light-success text-dark'>
                                  Approved
                                </span>
                              ) : customer.status === 'pending' ? (
                                <span className='badge badge-light-danger text-dark'>Pending</span>
                              ) : customer.status === 'rejected' ? (
                                <span className='badge badge-light-info text-dark'>Rejected</span>
                              ) : customer.status === 'cancelled' ? (
                                <span className='badge badge-light-dark text-dark'>Cancelled</span>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                          <button
                            className='btn btn-primary ms-3'
                            onClick={() => goToOrderDetailPage(customer.order_id)}
                          >
                            <i className='fa fa-info-circle' aria-hidden='true'></i>Order Detail
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body py-5'>
              <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'> Customer Details</h3>
              <div className='row'>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Order ID</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && isEmpty(customer.order_no) ? '-' : `#${customer.order_no}`}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {isEmpty(customer.customer_name) ? '-' : customer.customer_name}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Mobile Number</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && isEmpty(customer.contact_no) ? (
                        '-'
                      ) : (
                        <>
                          {tenantConfiguration.baseCountryCode} {customer.contact_no}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>SR Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && isEmpty(customer.requested_by_name)
                        ? '-'
                        : customer.requested_by_name}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>SR Mobile Number</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && isEmpty(customer.sr_phone_no) ? (
                        '-'
                      ) : (
                        <>
                          {tenantConfiguration.baseCountryCode} {customer.sr_phone_no}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved By</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && isEmpty(customer.action_by_name) ? '-' : customer.action_by_name}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>SM Mobile Number</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && isEmpty(customer.sm_phone_no) ? (
                        '-'
                      ) : (
                        <>
                          {tenantConfiguration.baseCountryCode} {customer.sm_phone_no}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Comments</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && isEmpty(customer.reason) ? '-' : customer.reason}
                    </span>
                  </div>
                </div>
              </div>
              <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'> Request Details</h3>
              <OrderQuantityProducts
                orderedProducts={orderedProducts}
                productRequest={productRequest}
                setProductsRequest={setProductsRequest}
                orderId={state.order_id}
                orderStatus={customer.status}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default OrderQuantityRequestDetail
