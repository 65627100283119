import "lightgallery/css/lg-thumbnail.css"
import { useEffect, useState } from "react"
import { toAbsoluteUrl } from "../../../../_eaFruitsDms/helpers"
import LightBoxCustom from "./LightBoxCustom"

const LightBoxDynamic = ({ addressTitle, lightboxImages, addressType, fetchAddressDetails }: any) => {
	const [images, setImages] = useState<any>([])

	useEffect(() => {
		if (lightboxImages) {
			setImages(lightboxImages)
		}
	}, [lightboxImages])

	return (
		<>
			<div className="symbol symbol-60px symbol-1by1 me-4 " data-bs-toggle="modal"
				data-bs-target={`#exampleModalToggle_${addressType}`}>
				<div
					className="symbol-label symbol-hover symbol-default"
					style={{ backgroundImage: `url(${(images.length > 0 && images[0].thumb !== null) ? images[0].thumb : toAbsoluteUrl("/media/stock/600x400/img-20.jpg")})` }}
				>
					<div className="text-center text-white position-relative ">
						<div className="font-20 fw-bold">{images && images.length}</div>
						<span className="font-14">
							Photos
						</span>
					</div>
				</div>
			</div>
			{images.length !== 0 && <LightBoxCustom fetchAddressDetails={fetchAddressDetails} addressTitle={addressTitle} imageData={images} addressType={addressType} />}
		</>
	)
}

export default LightBoxDynamic
