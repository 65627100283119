/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {getUserType} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import ExpiredProducts from './expiredProducts'
import ExpiredProductsAM from './expiredProductsAM'
import ExpiredProductsWM from './expiredProdutsWM'
// import { OO, superAdmin, w_manager } from '../../../_eaFruitsDms/localstorage/setitemStore';

const superAdmin = localStorage.getItem('superAdmin')
const w_manager = localStorage.getItem('w_manager')
const OO = localStorage.getItem('OO')

const ExpiredProductsWrapper: FC = () => {
  const intl = useIntl()
  const headerTitle = {
    title: 'ProductSku',
  }
  return (
    <>
      {/* {localStorage.getItem('user_type') === "SA" && <ProductSku className='super-admin-productsku' />} */}
      {localStorage.getItem('user_type') === 'OO' && <ExpiredProducts className='oo-report' />}
      {localStorage.getItem('user_type') === 'AM' && <ExpiredProductsAM className='oo-report' />}
      {(localStorage.getItem('user_type') === 'WM' ||
        localStorage.getItem('user_type') === 'SA' ||
        localStorage.getItem('user_type') === 'TS') && <ExpiredProductsWM className='oo-report' />}
    </>
  )
}

export {ExpiredProductsWrapper}
