import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import IsLoader from '../../../../../_eaFruitsDms/layout/components/loader/loader'
import tenantConfiguration from '../../../../../TenantVariables'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {
  apiBaseURL,
  dateFormat,
  fetchStaticAsyncDropdown,
  GLOBAL_DATE_FORMAT,
  isAdmin,
  isEmpty,
  numberFormatWithCode,
} from '../../../../../_eaFruitsDms/helpers'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {useNavigate} from 'react-router-dom'
import useDebounce from '../../../../../_eaFruitsDms/helpers/components/useDebounce'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {AsyncPaginate} from 'react-select-async-paginate'
import {paymentStatus, paymentStatusOptions} from '../../../salesInvoices/invoiceConstant'

const SalesOrder = ({showAddOrderButton, warehouse, selectedCustomer, orderCustomer}: any) => {
  const navigate: any = useNavigate()

  const customerId: any = localStorage.getItem('cId') || ''
  const warehouseId: any = isAdmin() ? localStorage.getItem('wId') || '' : ''
  const [salesOrder, setSalesOrder] = useState<any>([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [currentOffSet, setCurrentOffSet] = useState<number>(0)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [orderDate, setOrderDate] = useState<any>({
    start_date: dateFormat(moment().startOf('month')).apiDate,
    end_date: dateFormat(moment().endOf('month')).apiDate,
  })
  const [salesPaymentStatus, setSalesPaymentStatus] = useState<string>('')

  const debounce = useDebounce(searchByName, 500)

  useEffect(() => {
    localStorage.removeItem('oId')
  }, [])

  useEffect(() => {
    if (!isEmpty(customerId || !isEmpty(warehouseId))) {
      fetchSalesOrder().then()
    }
  }, [debounce, currentOffSet, orderDate, salesPaymentStatus])

  const fetchSalesOrder = async () => {
    setDisplayLoader(true)

    const response = await getRequest(
      `${apiBaseURL()}payment/sales-orders?search=${debounce}&limit=${limit}&offset=${currentOffSet}&customer=${customerId}&start_date=${
        orderDate.start_date
      }&end_date=${orderDate.end_date}&order_payment_status=${salesPaymentStatus}&warehouse_id=${
        isEmpty(warehouseId) ? '' : warehouseId
      }`,
      true
    )

    if (response.results) {
      setSalesOrder(response.results)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const filterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'search':
        setSearchByName(inputValue)
        break
      case 'status':
        setSalesPaymentStatus(inputValue)
        break
    }
  }

  const fetchPaymentStatuses = (search?: string) => {
    return fetchStaticAsyncDropdown(paymentStatusOptions, search)
  }

  return (
    <>
      <div className='card'>
        {/* begin::Header */}
        {displayLoader && <IsLoader />}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={filterChange} />
          <div className='ms-md-auto'>
            <div className='card-toolbar d-flex my-0'>
              <DateRangePicker
                onCallback={(start, end, label) => {
                  setOrderDate({
                    start_date: dateFormat(start._d).apiDate,
                    end_date: dateFormat(end._d).apiDate,
                  })
                }}
                initialSettings={{
                  startDate: moment().startOf('month'),
                  endDate: moment().endOf('month'),
                  alwaysShowCalendars: true,
                  cancelClass: 'btn-secondary',
                  drops: 'auto',
                  locale: {
                    format: GLOBAL_DATE_FORMAT,
                  },
                  ranges: {
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [
                      moment().subtract(1, 'month').startOf('month'),
                      moment().subtract(1, 'month').endOf('month'),
                    ],
                  },
                }}
              >
                <input type='text' className='form-control calendar w-auto me-3' />
              </DateRangePicker>

              <AsyncPaginate
                loadOptions={fetchPaymentStatuses}
                isSearchable
                placeholder='All Payment Status'
                className='react-select-container my-1 w-170px'
                classNamePrefix='react-select'
                name='status'
                onChange={(e: any) => filterChange(e, 'status')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              {showAddOrderButton && (
                <button
                  className='btn btn-primary ms-3'
                  disabled={displayLoader}
                  onClick={() =>
                    navigate('/orders/manage-order', {
                      state: {
                        customer: selectedCustomer,
                        warehouse: warehouse,
                        orderCustomer: orderCustomer,
                      },
                    })
                  }
                >
                  Add Order
                </button>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {salesOrder.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-90px'>Invoice No.</th>
                    <th className='min-w-100px w-100px'>Order No.</th>
                    <th className='min-w-120px'>Delivery Date & Time</th>
                    <th className='min-w-160px'>Customer Name</th>
                    <th className='w-150px min-w-150px'>SR Name</th>
                    <th className='min-w-120px'>Payable Amt.</th>
                    <th className='min-w-120px'>Received Amt.</th>
                    <th className='min-w-120px'>Due Amt.</th>
                    <th className='min-w-100px'>Payment Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {salesOrder.map((invoice: any) => {
                    return (
                      <tr
                        className='cursor-pointer'
                        onClick={() => {
                          if (isAdmin()) {
                            navigate('order-details', {
                              state: {invoiceId: invoice.id},
                            })
                          } else {
                            navigate('/customers/singleCustomer/sales-order/order-details', {
                              state: {invoiceId: invoice.id},
                            })
                          }
                        }}
                      >
                        <td>{invoice.invoice_no ? '#' + invoice.invoice_no : '-'}</td>
                        <td>#{invoice.order_no}</td>
                        <td>
                          {dateFormat(invoice.delivery_date_time).displayDate}
                          <div>{dateFormat(invoice.delivery_date_time, 'LT').displayDate}</div>
                        </td>
                        <td>
                          {isEmpty(invoice.customer_name)
                            ? ''
                            : `${invoice.customer_name} ${
                                !isEmpty(invoice.customer_street) &&
                                '(' + invoice.customer_street + ')'
                              }`}
                        </td>
                        <td>{isEmpty(invoice.sr_name) ? '-' : invoice.sr_name}</td>
                        <td>{numberFormatWithCode(invoice.total_payable).displayFormat}</td>
                        <td>{numberFormatWithCode(invoice.total_received_amount).displayFormat}</td>
                        <td>
                          {numberFormatWithCode(invoice.total_outstanding_amount).displayFormat}
                        </td>
                        <td>
                          {isEmpty(invoice.order_payment_status)
                            ? '-'
                            : paymentStatus[invoice.order_payment_status]}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CustomPaginate
        data={salesOrder}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffSet}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default SalesOrder
