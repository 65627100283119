import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {Props} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../TenantVariables'
import {
  apiBaseURL,
  dateFormat,
  isEmpty,
  staticSearch,
  toAbsoluteUrl,
} from '../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'

const DormantCustomerReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Dormant Customer Report',
    subTitle: 'Dormant Customer Report',
  }

  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [reports, setReports] = useState<any>([])
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [warehouseId, setWarehouseId] = useState<any>({label: 'All Warehouses', value: ''})
  const [userType, setUserType] = useState({label: 'All Type', value: ''})

  const debounceSearch = useDebounce(searchByName, 500)
  const navigate = useNavigate()

  useEffect(() => {
    fetchingReports()
  }, [currentOffset, debounceSearch, warehouseId, userType])

  useEffect(() => {
    localStorage.removeItem('dormant-selected-wid')
    localStorage.removeItem('dormant-selected-type')
  }, [])

  const fetchingReports = async () => {
    setDisplayLoader(true)

    const url = `${apiBaseURL()}reports/dormant-customers?search=${searchByName}&warehouse_id=${
      warehouseId.value
    }&limit=${limit}&offset=${currentOffset}&ordering=-last_ordered_date&created_by_type=${
      userType.value
    }`

    const response = await getRequest(url, true)

    if (!isEmpty(response.results)) {
      setReports(response.results)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const userTypes: any = [
    {
      label: 'All Types',
      value: '',
    },
    {
      label: 'SR',
      value: 'sr',
    },
    {
      label: 'SS',
      value: 'ss',
    },
    {
      label: 'TS',
      value: 'ts',
    },
    {
      label: 'WM',
      value: 'wm',
    },
  ]

  const fetchUserTypes = async (search?: any) => {
    let options = userTypes

    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e
    setCurrentOffset(0)
    setSelected(0)
    switch (inputName) {
      case 'warehouse':
        setWarehouseId(e)
        localStorage.setItem('dormant-selected-wid', e.value)
        break

      case 'type':
        setUserType(e)
        localStorage.setItem('dormant-selected-type', e.value)
        break

      default:
        setSearchByName(inputValue)
        break
    }
  }

  const goToOverview = (cId: any, type: any) => {
    localStorage.setItem('cId', cId)
    localStorage.setItem('type', type)
    navigate('/customers/singlecustomer/overview')
  }

  return (
    <>
      <EATitle title='Dormant Customer Report' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id: 'Dormant Customer Report',
        })}
      </PageTitle>
      <div className={`card ${className}`}>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <WarehouseFilter
                warehouse={warehouseId}
                handleFilterChange={(e: any) => handleFilterChange(e, 'warehouse')}
                isDisabled={displayLoader}
              />
              <AsyncPaginate
                loadOptions={fetchUserTypes}
                className='react-select-container my-1 me-3 w-120px'
                classNamePrefix='react-select'
                placeholder='Created By'
                onChange={(e: any) => handleFilterChange(e, 'type')}
                value={userType}
                isDisabled={displayLoader}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className='card-body pt-0 pb-3'>
          <div className='table-responsive'>
            {displayLoader ? (
              <FetchingRecords />
            ) : reports.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='w-30px'>ID</th>
                    <th className='w-100px'>Name</th>
                    <th className='w-100px'>Category</th>
                    <th className='w-100px'>Warehouse Name</th>
                    <th className='w-100px'>Type</th>
                    <th className='w-170px'>Contact Info</th>
                    <th className='w-170px'>Created by</th>
                    <th className='w-170px'>Since When</th>
                    <th className='w-160px'>Last Ordered Date</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {reports.map((reports: any) => {
                    return (
                      <tr
                        onClick={() => goToOverview(reports.id, reports.type)}
                        className='cursor-pointer'
                      >
                        <td>#{reports.id}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <span
                              className='rounded-circle pro-icon me-2'
                              data-bs-toggle='tooltip'
                              title='Mark Larson'
                            >
                              {reports.logo_thumbnail === null ? (
                                <img
                                  src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                                  alt=''
                                  className='rounded-circle pro-icon'
                                />
                              ) : (
                                <img
                                  src={reports.logo_thumbnail}
                                  alt=''
                                  className='rounded-circle pro-icon'
                                />
                              )}
                            </span>
                            <div className='media-body'>
                              <p className='m-0 xs-font'>{reports.business_name}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          {
                            <div>
                              <div className='text-muted font-13 mb-1 font-medium text-capitalize'>
                                {isEmpty(reports.nature_of_business)
                                  ? '-'
                                  : reports.nature_of_business}
                              </div>
                              {isEmpty(reports.category_name) ? '-' : reports.category_name}
                            </div>
                          }
                        </td>
                        <td>{isEmpty(reports.warehouse_name) ? '-' : reports.warehouse_name}</td>
                        <td className='text-capitalize'>
                          {isEmpty(reports.type) ? '-' : reports.type}
                        </td>
                        <td>
                          {isEmpty(reports.contact_no)
                            ? '-'
                            : `${tenantConfiguration.baseCountryCode} ${reports.contact_no}`}
                        </td>
                        <td>
                          {isEmpty(reports.reg_by)
                            ? '-'
                            : `${
                                isEmpty(reports.reg_by) || isEmpty(reports.reg_by.type)
                                  ? ''
                                  : `${reports.reg_by.first_name} ${
                                      reports.reg_by.last_name
                                    } (${reports.reg_by.type.toUpperCase()})`
                              }`}
                        </td>
                        <td>
                          {isEmpty(reports.since_when)
                            ? '-'
                            : `${
                                reports.since_when > 0
                                  ? `${reports.since_when} Days`
                                  : `${reports.since_when} Day`
                              }`}
                        </td>
                        <td>
                          {isEmpty(reports.last_ordered_date)
                            ? '-'
                            : dateFormat(reports.last_ordered_date).displayDate}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <CustomPaginate
        data={reports}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default DormantCustomerReport
