import ReactECharts from 'echarts-for-react'

const EaChartPie = (props: any) => {
  // Develop BY: Kashyap
  // Description: pie chart component
  let option = {
    animation: props.isAnimationDisable ? false : true,
    title: {
      text: props.item.title && props.item.title,
      subtext: '',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      bottom: 0,
      left: 'center',
    },
    series: [
      {
        selectedMode: props.item.selectedMode ? 'single' : false,
        name: props.item.formName && props.item.formName,
        type: 'pie',
        radius: '50%',
        bottom: props.item.bottom ? props.item.bottom : 0,
        data: props.item.data,
        tooltip: props.tooltip && {
          triggerOn: 'none',
          formatter: function (params: any) {
            return props.tooltip(params)
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  }

  return (
    <ReactECharts
      option={option}
      style={{height: '500px'}}
      onEvents={{
        click: (i: any) => {
          props.onClick && props.onClick(i)
        },
      }}
    />
  )
}

const EaChartPieNested = (props: any) => {
  // Develop BY: Kashyap
  // Description: pie chart nested component

  let option = {
    animation: props.isAnimationDisable ? false : true,
    title: {
      text: props.item.title && props.item.title,
      subtext: '',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      bottom: 0,
      left: 'center',
    },
    series: [
      {
        selectedMode: props.item.selectedMode ? 'single' : false,
        name: props.item.formName && props.item.formName,
        type: 'pie',
        radius: [0, '30%'],
        data: props.item.data,
        tooltip: props.tooltip && {
          triggerOn: 'none',
          formatter: function (params: any) {
            return props.tooltip(params)
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
      {
        name: props.item.formName && props.item.formName,
        type: 'pie',
        radius: ['60%', '70%'],
        labelLine: {
          length: 30,
        },
        tooltip: props.tooltipNested && {
          triggerOn: 'none',
          formatter: function (params: any) {
            return props.tooltipNested(params)
          },
        },
        data: props.nestedData,
      },
    ],
  }

  return (
    <ReactECharts
      option={option}
      style={{height: '500px'}}
      onEvents={{
        click: (i: any) => {
          props.onClick && props.onClick(i)
        },
      }}
    />
  )
}

const EaChartBar = (props: any) => {
  // Develop BY: Kashyap
  // Description: bar chart component
  const {xAxisData, seriesData, startZoom, endZoom, series, legend, rotate} = props

  // ex : xAxisData => [2014,2015,2016]
  // ex : seriesData => [ { value: 500, itemStyle: {color: '#a900c1'} } ] note itemStyle is only for bar color. NOT Require.

  var app: any = {}

  var option

  const posList = [
    'left',
    'right',
    'top',
    'bottom',
    'inside',
    'insideTop',
    'insideLeft',
    'insideRight',
    'insideBottom',
    'insideTopLeft',
    'insideTopRight',
    'insideBottomLeft',
    'insideBottomRight',
  ]
  app.configParameters = {
    rotate: {
      min: -90,
      max: 90,
    },
    align: {
      options: {
        left: 'left',
        center: 'center',
        right: 'right',
      },
    },
    verticalAlign: {
      options: {
        top: 'top',
        middle: 'middle',
        bottom: 'bottom',
      },
    },
    position: {
      options: posList.reduce(function (map: any, pos) {
        map[pos] = pos
        return map
      }, {}),
    },
    distance: {
      min: 0,
      max: 100,
    },
  }
  app.config = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    onChange: function () {
      const labelOption = {
        rotate: app.config.rotate,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        position: app.config.position,
        distance: app.config.distance,
      }
      // myChart.setOption({
      //     series: [
      //         {
      //             label: labelOption
      //         },
      //         {
      //             label: labelOption
      //         },
      //         {
      //             label: labelOption
      //         },
      //         {
      //             label: labelOption
      //         }
      //     ]
      // });
    },
  }
  const labelOption = {
    show: true,
    position: app.config.position,
    distance: app.config.distance,
    align: app.config.align,
    verticalAlign: app.config.verticalAlign,
    rotate: app.config.rotate,
    formatter: '{c}  {name|{a}}',
    fontSize: 10,
    rich: {
      name: {},
    },
  }
  option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: legend && {data: legend},
    // legend: {
    //     data: [
    //         {
    //           name: 'Forest',
    //             itemStyle: { color: 'red' }
    //         },
    //         {
    //             name: 'Steppe',
    //             itemStyle: { color: 'blue' }
    //         }]
    // },

    // toolbox: {
    //     show: true,
    //     orient: 'vertical',
    //     left: 'right',
    //     top: 'center',
    //     feature: {
    //         mark: { show: true },
    //         dataView: { show: true, readOnly: false },
    //         magicType: { show: true, type: ['line', 'bar', 'stack'] },
    //         restore: { show: true },
    //         saveAsImage: { show: true }
    //     }
    // },
    xAxis: [
      {
        type: 'category',
        // axisTick: { show: false },
        axisLabel: {
          textStyle: {
            fontSize: 10,
          },
          interval: 0,
          rotate: rotate ? rotate : 30,
        },

        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: series
      ? series
      : [
          {
            name: '',
            type: 'bar',
            width: '100%',
            // label: labelOption,
            emphasis: {
              focus: 'series',
            },
            data: seriesData || [],
          },
        ],
    dataZoom: [
      {
        type: 'inside',
        start: startZoom ? startZoom : 0,
        end: endZoom ? endZoom : 15,
      },
      {
        show: true,
        type: 'slider',
        top: '90%',
        start: startZoom ? startZoom : 0,
        end: endZoom ? endZoom : 15,
      },
    ],
  }

  return <ReactECharts option={option} style={{height: '400px'}} />
}

const EaLineChart = (props: any) => {
  const {xAxisData, seriesData, maxTemperature, minTemperature, name, formatter, maxYaxis} = props

  let option = {
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {},
    toolbox: {},
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData ? xAxisData : [],
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: formatter ? formatter : '{value} °C',
      },
      max: maxYaxis && maxYaxis,
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        show: true,
        type: 'slider',
        top: '90%',
        start: 0,
        end: 100,
      },
    ],
    series: seriesData,
  }

  return <ReactECharts option={option} style={{height: '400px'}} />
}

export {EaChartPie, EaChartBar, EaLineChart, EaChartPieNested}
