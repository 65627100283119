/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { KTSVG, toAbsoluteUrl } from "../../../../../_eaFruitsDms/helpers"

const SalesSupervisorHeader: React.FC = () => {
  const location = useLocation()
  return (
    <div className='card mb-4 mb-xl-5'>
      <div className='card-body pt-5 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='me-3 me-md-5 mb-3'>
            <div className='symbol symbol-40px symbol-lg-60px symbol-fixed'>
              <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='User' />
            </div>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap'>
              <div className='d-flex flex-column mb-2 mb-sm-0'>
                <div className='d-flex align-items-center mb-1'>
                  <span className='text-dark font-20 fw-bold me-1'>
                    Connor Lawson
                  </span>
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props} className='tooltip-dark'>
                        Active since 20 Sep 2022
                      </Tooltip>
                    )}
                    placement='top'
                  >
                    <span className="py-1 ms-2 btn btn-sm bg-light-success">Active</span>

                  </OverlayTrigger>
                </div>
                <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                  <span
                    className='d-flex align-items-center me-6 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='me-1'
                    />
                    Sales Superviser
                  </span>
                  <span
                    className='d-flex align-items-center me-5 mb-2 '
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='me-1'
                    />
                    Kinondoni
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <a href="#" className='btn btn-primary font-13'>Login As GM</a>
          </div>
        </div>
        <div className='d-flex overflow-auto h-40px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-0 ` +
                  (location.pathname === '/team-members/sales-supervisor/overview' && 'active')
                }
                to='/team-members/sales-supervisor/overview'
              >
                Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-0 ` +
                  (location.pathname === '/team-members/sales-supervisor/customers' && 'active')
                }
                to='/team-members/sales-supervisor/customers'
              >
                Customers
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-0 ` +
                  (location.pathname === '/team-members/sales-supervisor/sales-reps' && 'active')
                }
                to='/team-members/sales-supervisor/sales-reps'
              >
                Sales Reps.
              </Link>
            </li>
            {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-0 ` +
                  (location.pathname === '/team-members/sales-representative/past-orders' && 'active')
                }
                to='/team-members/sales-representative/past-orders'
              >
                Past Orders
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-0 ` +
                  (location.pathname === '/team-members/sales-representative/targets' && 'active')
                }
                to='/team-members/sales-representative/targets'
              >
                Targets
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-0 ` +
                  (location.pathname === '/team-members/sales-representative/payment-issue' && 'active')
                }
                to='/team-members/sales-representative/payment-issue'
              >
                Payment Issues
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export { SalesSupervisorHeader }
