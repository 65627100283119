import React from 'react'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {dateFormat, isEmpty} from '../../../../../_eaFruitsDms/helpers'
import {getActivityEnumName} from '../../../../../_eaFruitsDms/helpers/activitiesEnum'

interface Props {
  data: any
  loading: boolean
}

const Activities = ({data, loading}: Props) => {
  return (
    <>
      {loading ? (
        <FetchingRecords />
      ) : data && data.length === 0 ? (
        <NoRecords />
      ) : (
        data.map((item: any, index: any) => {
          return (
            <div className='d-flex' key={index}>
              {/* Timeline column */}

              <div className='drawer-box d-flex flex-column align-items-center me-3'>
                <div
                  className='drawer-circle bg-primary fw-bold p-1'
                  style={{
                    width: 15,
                    height: 17,
                  }}
                />

                <div
                  className='drawer-line'
                  style={{
                    borderStyle: 'dashed',
                    backgroundColor: 'transparent',
                    width: 1,
                    borderWidth: 1,
                  }}
                ></div>
              </div>

              {/* Card content column */}
              <div className='flex-grow-1 cursor-pointer mb-5'>
                <div>
                  {isEmpty(item?.date_time) ? '-' : dateFormat(item?.date_time, 'LT').displayDate}
                </div>
                <div className='fw-bolder'>
                  {isEmpty(item?.type) ? '-' : getActivityEnumName(item?.type)}
                </div>
              </div>
            </div>
          )
        })
      )}
    </>
  )
}

export default Activities
