import clsx from "clsx"
import $ from "jquery"
import React, { useState } from "react"
import * as Yup from "yup"
import { changePassword } from "../../../apiFunctions/userMaster/changePassword"
import { KTSVG } from "../../../helpers"
import ErrorHandler from "../errorHandler/errorHandler"
import EaModal from "../modal/eaModal"

const loginSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password should be minimum 8 characters long')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Password must be at least 8 characters long, and must contain characters (a-z), digits (0-9), and special characters (e.g. ~!@#$)")
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})


const ChangePassword = () => {
    const initialRow: any = {
        currentPassWord: false,
        newPassWord: false,
        confirmPassWord: false,
      }

    const [password, setPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [cnfPassword, setCnfPassword] = useState('');

    const [password_validation, setPassword_validation] = useState('');
    const [newpassword_validation, setNewPassword_validation] = useState('');
    const [cnfPassword_validation, setCnfPassword_validation] = useState('');

    const [confirmPsdCheck, setconfirmPsdCheck] = useState(false);
    const [newPsdCheck, setnewPsdCheck] = useState(false);

    const [validation_confirmPsdCheck, setconfirmPsdCheckValid] = useState(false);
    const [validation_newPsdCheck, setnewPsdCheckValid] = useState(false);

    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [success, setSuccess] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCnfPassword, setShowCnfPassword] = useState(false);

    const clearFun = () => {
        setPassword('')
        setNewPassword('')
        setCnfPassword('')

        setPassword_validation('')
        setNewPassword_validation('')
        setCnfPassword_validation('')

        setconfirmPsdCheck(false)
        setnewPsdCheck(false)
        setconfirmPsdCheckValid(false)
        setnewPsdCheckValid(false)

        setShowPassword(false)
        setShowNewPassword(false)
        setShowCnfPassword(false)

        setErrorMessage('')
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    const ResetPasswordEa = async () => {

        if (password.trim() == "") { setPassword_validation('is-invalid') } else { setPassword_validation('') }
        if (newpassword.trim() == "") { setNewPassword_validation('is-invalid') } else { setNewPassword_validation('') }
        if (cnfPassword.trim() == "") { setCnfPassword_validation('is-invalid') } else { setCnfPassword_validation('') }

        if (newpassword !== cnfPassword) {
            setErrorMessage("New Password and Confirm must be same.")
        }
        
        // setErrorMessage('')
        if (password.trim() !== "") {
            if (newpassword.trim() !== "") {
                if (cnfPassword.trim() !== "") {
                    if (newPsdCheck && confirmPsdCheck) {
                        if(newpassword == cnfPassword){
                            changePassword(
                                password, newpassword, cnfPassword
                            )
                                .then((res) => {
    
                                    if (res.success) {
                                        clearFun()
                                        setSuccess(true)
                                        setTimeout(() => {
                                            setSuccess(false)
                                            $('#close_ea_change_password').trigger('click');
                                        }, 1000);
                                    }
                                    setErrorMessage(res.err)
    
                                    if (res.errorField !== "") {
                                        if (res.errorField === "new_password / confirm_password") {
                                            setErrorMessage("New Password and Confirm must be same.")
                                        } 
                                        else if(res.errorField === "new_password") {
                                            setErrorMessage("Please choose a password that is different from your last 2 passwords!")
                                        }
                                        else if(res.errorField === "current_password") {
                                            setErrorMessage("Current password is incorrect.")
                                        }
                                        else {
                                            setErrorMessage(res.errorField)
                                        }
                                    }
                                })
                        }
                       
                    }
                }
            }
        }
    }

    const handleTogglePassword = (name : any) => {

        if(name === "currentPassword"){
            setShowPassword(!showPassword);
        }
        if(name === "newPassword"){
            setShowNewPassword(!showNewPassword);
        }
        if(name === "confirmPassword"){
            setShowCnfPassword(!showCnfPassword);
        } 
      };

    return (
        <EaModal
            closeAuto={'close_ea_change_password'}
            onSubmit={ResetPasswordEa}
            modalTitle='Change Password'
            id='ea_modal_change_password'
            cancel={clearFun}
        >
            <div className="mb-4">
                <label className="form-label">Current Password</label>
                <div className="d-flex position-relative">
                    <input
                        autoComplete='off'
                        type={showPassword ? 'text' : 'password'}
                        className={clsx(
                            'form-control psw-input', password_validation
                        )}
                        placeholder="Type here..."
                        value={password}
                        name="currentPassword"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <a onClick={() => handleTogglePassword("currentPassword")} className= "btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm">
                        {showPassword 
                        ? <><KTSVG path='/media/icons/ic_eye_off.svg' className="ic ms-n4 position-absolute psw-toggle" /></> 
                        : <><KTSVG path='/media/icons/ic_eye_on.svg' className="ic ms-n4 position-absolute psw-toggle" /></>
                        }  
                    </a>
                </div>
            </div>

            <div className="mb-4">
                <label className="form-label">New Password</label>
                <div className="d-flex position-relative">
                    <input
                        autoComplete='off'
                        type={showNewPassword ? 'text' : 'password'}  
                        className={clsx(
                            'form-control psw-input', newpassword_validation
                        )}
                        onBlur={() => {
                            if (passwordRegex.test(newpassword)) {

                                setnewPsdCheckValid(false)
                                setnewPsdCheck(true)
                            } else {

                                setnewPsdCheckValid(true)
                                setnewPsdCheck(false)
                            }
                        }}
                        placeholder="Type here..."
                        value={newpassword}
                        name="newPassword"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <a onClick={() => handleTogglePassword("newPassword")} className= "btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm">
                        {showNewPassword 
                        ? <><KTSVG path='/media/icons/ic_eye_off.svg' className="ic ms-n4 position-absolute psw-toggle" /></> 
                        : <><KTSVG path='/media/icons/ic_eye_on.svg' className="ic ms-n4 position-absolute psw-toggle" /></>
                        }  
                    </a>
                </div>
                {validation_newPsdCheck && <span className='text-danger'>Password must be at least 8 characters long, and must contain characters (a-z), digits (0-9), and special characters (e.g. ~!@#$)</span>}
            </div>

            <div>
                <label className="form-label">Confirm Password</label>
                <div className="d-flex position-relative">
                    <input
                        autoComplete='off'
                        type={showCnfPassword ? 'text' : 'password'} 
                        name='confirmPassword'
                        className={clsx(
                            'form-control psw-input', cnfPassword_validation
                        )}
                        placeholder="Type here..."
                        onBlur={() => {
                            if (passwordRegex.test(cnfPassword)) {

                                setconfirmPsdCheckValid(false)
                                setconfirmPsdCheck(true)
                            } else {

                                setconfirmPsdCheckValid(true)
                                setconfirmPsdCheck(false)
                            }
                        }}
                        value={cnfPassword}
                        onChange={(e) => setCnfPassword(e.target.value)}
                    />
                    <a onClick={() => handleTogglePassword("confirmPassword")} className= "btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm">
                        {showCnfPassword 
                        ? <><KTSVG path='/media/icons/ic_eye_off.svg' className="ic ms-n4 position-absolute psw-toggle" /></> 
                        : <><KTSVG path='/media/icons/ic_eye_on.svg' className="ic ms-n4 position-absolute psw-toggle" /></>
                        }  
                    </a>
                </div>               
                {validation_confirmPsdCheck && <span className='text-danger'>Password must be at least 8 characters long, and must contain characters (a-z), digits (0-9), and special characters (e.g. ~!@#$)</span>}

            </div>

            <div className='mt-3'>
                <ErrorHandler errorMessage={errorMessage} />
            </div>
            {/* {errorMessage.non_field_errors ?
                <div>
                    <p className='text-danger mt-5' >{errorMessage.non_field_errors}</p>
                </div> : <p className='text-danger mt-5' >{errorMessage}</p>} */}

            {success && <span className='text-primary' >password changed successfully</span>}
        </EaModal>
    )
}

export default ChangePassword
