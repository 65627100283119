import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import EATitle from '../../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../../_eaFruitsDms/layout/core'
import {
  KTSVG,
  apiBaseURL,
  checkEmpty,
  checkUserType,
  dateFormat,
  isEmpty,
  numberFormatWithCode,
  toAbsoluteUrl,
} from '../../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import HorecaModal from './horecaModal'
import FetchingRecords from '../../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {EaAlertSuccess} from '../../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import usePermission from '../../../../../../_eaFruitsDms/helpers/components/usePermission'

const WMHorecaContractDetail: React.FC = () => {
  const intl = useIntl()
  const id = localStorage.getItem('horeca-id') || ''
  const customersHeaderTitle = {
    title: 'Customers',
    subTitle: 'HORECA Contracts',
  }

  const permission = usePermission('customer_horeca_contracts')

  const initialState = {
    id: 0,
    customer_id: 0,
    customer_name: '-',
    start_date: '-',
    end_date: '-',
    credit_amount_cap: 0,
    credit_cycle: 0,
    file_name: '/',
    customer_contract_skus: [],
    status: '',
  }

  const [horeca, setHoreca] = useState(initialState)
  const [show, setShow] = useState(false)
  const [horecaId, setHorecaId] = useState(id || '')
  const [fetchingRecords, setFetching] = useState(true)
  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')

  const fetchRecords = async () => {
    setFetching(true)
    const url = apiBaseURL() + 'customers/contracts/' + id
    const response = await getRequest(url, true)
    let updateResponse: any = {...horeca}

    if (response && response.id) {
      Object.entries(response).map((object: any) => {
        if (!isEmpty(object[1])) {
          updateResponse[object[0]] = object[1]
        }
      })
    }

    setHoreca(updateResponse)
    setFetching(false)
  }

  useEffect(() => {
    if (!isEmpty(id)) {
      fetchRecords()
    }
  }, [id])

  return (
    <>
      <EATitle title='HORECA Contracts' />
      <PageTitle breadcrumbs={[customersHeaderTitle]}>
        {intl.formatMessage({id: 'HORECA Contracts'})}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      {fetchingRecords ? (
        <FetchingRecords />
      ) : (
        <>
          <div className='card mb-5'>
            <div className='card-body py-5'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center'>
                  <h1 className='font-20 fw-700 mb-0'>HORECA Contracts</h1>
                  {isEmpty(horeca.status) ? (
                    ''
                  ) : (
                    <>
                      {horeca.status === 'expired' ? (
                        <span className='badge badge-secondary ms-4 text-dark'>Expired</span>
                      ) : horeca.status === 'scheduled' ? (
                        <span className='badge badge-light-warning ms-4 text-dark'>Scheduled</span>
                      ) : (
                        <span className='badge badge-light-success ms-4 text-dark'>Active</span>
                      )}
                    </>
                  )}
                </div>
                {checkUserType(['wm']) &&
                  ['scheduled', 'active'].includes(horeca.status) &&
                  permission.write && (
                    <button
                      type='button'
                      onClick={() => {
                        setShow(true)
                        setHorecaId(id)
                      }}
                      className='btn btn-secondary'
                    >
                      Edit
                    </button>
                  )}
              </div>
            </div>
          </div>
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>
                <h2 className='font-17 fw-700'>Contract Details</h2>
              </div>
            </div>
            <div className='card-body py-5'>
              <div className='row'>
                <div className='col-md-6 col-lg-3'>
                  <div className='font-14 text-muted mb-2'>Contract Duration</div>
                  <div className='font-14'>
                    {horeca.start_date === '-' ? (
                      '-'
                    ) : (
                      <>
                        {dateFormat(horeca.start_date).displayDate} —{' '}
                        {dateFormat(horeca.end_date).displayDate}
                      </>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3'>
                  <div className='font-14 text-muted mb-2'>Credit Capping</div>
                  <div className='font-14'>
                    {numberFormatWithCode(horeca.credit_amount_cap).displayFormat}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3'>
                  <div className='font-14 text-muted mb-2'>Duration Days</div>
                  <div className='font-14'>{horeca.credit_cycle}</div>
                </div>
                <div className='col-md-6 col-lg-3'>
                  <div className='font-14 text-muted mb-2'>Contract Document</div>
                  <div className='font-14 d-flex justify-content-between'>
                    <a href={horeca.file_name} target='_blank' download={horeca.file_name}>
                      {horeca.file_name.substring(horeca.file_name.lastIndexOf('/') + 1)}
                      {/* <KTSVG
                        path='/media/icons/duotune/files/fil021.svg'
                        className='svg-icon-muted ic'
                      /> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>
                <h2 className='font-17 fw-700'>Contract Pricing</h2>
              </div>
            </div>
            <div className='card-body py-5'>
              <div className='table-responsive'>
                {horeca.customer_contract_skus.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='gs-0 gy-3 table table-row-bordered'>
                    <thead>
                      <tr>
                        <th style={{width: '30%'}}>Product SKU</th>
                        <th>Pricing</th>
                      </tr>
                    </thead>
                    <tbody>
                      {horeca.customer_contract_skus.length > 0 &&
                        horeca.customer_contract_skus.map((item: any) => {
                          return (
                            <tr>
                              <td>{item.product_name}</td>
                              <td>{numberFormatWithCode(item.price).displayFormat}</td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <HorecaModal
        show={show}
        setShow={setShow}
        onPageStateUpdate={fetchRecords}
        id={horecaId}
        setId={setHorecaId}
        setIsAlert={setIsAlert}
        setMessage={setMessage}
      />
    </>
  )
}

export default WMHorecaContractDetail
