/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation } from "react-router-dom";

const OdooHeader = (props: any) => {
  const location = useLocation()
  return (
    <>
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex overflow-auto h-40px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/odoo-configuration/odoo-warehouse' && 'active')
                  }
                  to='/odoo-configuration/odoo-warehouse'
                >
                  Warehouses Mapping
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/odoo-configuration/odoo-product-group' && 'active')
                  }
                  to={`/odoo-configuration/odoo-product-group`}
                >
                  Product Groups Mapping
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/odoo-configuration/odoo-product-sku' && 'active')
                  }
                  to={`/odoo-configuration/odoo-product-sku`}
                >
                  Products Mapping
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/odoo-configuration/odoo-customer-category' && 'active')
                  }
                  to={`/odoo-configuration/odoo-customer-category`}
                >
                  Customer Categories Mapping
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export {OdooHeader}
