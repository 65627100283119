import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  isAdmin,
} from '../../../../_eaFruitsDms/helpers'
import moment from 'moment'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import {AsyncPaginate} from 'react-select-async-paginate'
import {patchRequest, postRequest} from '../../../../_eaFruitsDms/https/apiCall'
import clsx from 'clsx'
import $ from 'jquery'
import Messages from './messages'

const SupportRequestHeader = (props: any) => {
  const navigate = useNavigate()
  const supportRequestId = localStorage.getItem('supportRequestId')

  const initialAssignedValidation: any = {
    option: '',
    comments: '',
  }

  const initialAssignedReason = {
    option: {label: 'Select', value: null},
    comments: '',
  }

  const initialReplyValidation: any = {
    support_request: '',
    message: '',
  }
  const initialReply: any = {
    support_request: supportRequestId,
    message: '',
    sent_type: 'telesales',
  }

  const initialCloseValidation: any = {
    comment: '',
  }
  const initialClose: any = {
    comment: '',
  }

  const requestDetails = props.requestDetails
  const location = useLocation()
  const requestType = localStorage.getItem('supportRequestType')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [reasonOffSet, setReasonOffSet] = useState<any>('')
  const [reasonOption, setReasonOption] = useState<any>([])
  const [reasonValidation, setReasonValidation] = useState<any>(initialAssignedValidation)
  const [assignedReason, setAssigedReason] = useState(initialAssignedReason)
  const [sendReplyValidation, setSendReplyValidation] = useState<any>(initialReplyValidation)
  const [sendReply, setSendReply] = useState<any>(initialReply)
  const [closeRequestValidation, setCloseRequestValidation] = useState<any>(initialCloseValidation)
  const [closeRequest, setCloseRequest] = useState<any>(initialClose)
  const [disableBtn, setDisableBtn] = useState(false)
  const [isLoading, setIsLoading] = useState('Submit')
  const [disableReplyBtn, setDisableReplyBtn] = useState(false)
  const [isReplyLoading, setIsReplyLoading] = useState('Submit')
  const [disableCloseBtn, setDisableCloseBtn] = useState(false)
  const [isCloseLoading, setIsCloseLoading] = useState('Submit')

  const fetchRequestReason = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const response = await fetchAsyncDropdown(
      reasonOffSet,
      setReasonOffSet,
      reasonOption,
      setReasonOption,
      `master/options-master/?type=support_request_change_reason`,
      search,
      setSearchSelect,
      'option'
    )
    response.options.push({
      label: 'Other',
      value: '',
    })
    return response
  }

  const handleChangeReason = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setReasonValidation({...reasonValidation, [inputName]: ''})
    let updatedassignedReason: any = {...assignedReason}

    switch (inputName) {
      case 'option':
        updatedassignedReason['option'] = e
        // updatedassignedReason['comments'] = ''
        break
      case 'comments':
        updatedassignedReason['comments'] = inputValue
        break
    }
    setAssigedReason(updatedassignedReason)
  }

  // assigned function for assigned status
  const assignedValidate = () => {
    let validations = {...reasonValidation}
    let isValid = true

    if (assignedReason.option.value == null) {
      validations.option = 'is-invalid'
      isValid = false
    }
    // if(!assignedReason.option.value && assignedReason.comments == ''){
    //     validations.comments = 'is-invalid'
    //     isValid = false
    // }
    if (assignedReason.comments == '') {
      validations.comments = 'is-invalid'
      isValid = false
    }
    setReasonValidation(validations)
    return isValid
  }

  const assignedToMeClearFunction = () => {
    setAssigedReason(initialAssignedReason)
    setReasonValidation(initialAssignedValidation)
    setDisableBtn(false)
    setIsLoading('Submit')
  }

  const assignedToMeSubmit = async () => {
    const isValid = assignedValidate()
    if (isValid) {
      setDisableBtn(true)
      setIsLoading('Please Wait...')
      const res = await patchRequest(
        `${apiBaseURL()}support_request/support-request-assign/${supportRequestId}`,
        {
          assign_reason_option: assignedReason.option.value,
          assign_reason_note: assignedReason.comments,
        },
        true
      )
      if (res.status == 200) {
        setDisableBtn(false)
        setIsLoading('Submit')
        $('#closeAssigendModal').trigger('click')
        setTimeout(() => {
          assignedToMeClearFunction()
        }, 200)
        localStorage.removeItem('supportRequestId')
        navigate('/complaint/my-assigned')
      }
    }
  }

  // Send Reply Modal Require functions
  const sendReplyValidate = () => {
    let validations = {...sendReplyValidation}
    let isValid = true

    if (sendReply.message == '') {
      validations.message = 'is-invalid'
      isValid = false
    }

    setSendReplyValidation(validations)
    return isValid
  }

  const sendReplyClearFunction = () => {
    setSendReply(initialReply)
    setSendReplyValidation(initialReplyValidation)
    setDisableReplyBtn(false)
    setIsReplyLoading('Submit')
  }

  const sendReplySubmit = async () => {
    const replyMessageInfo = {...sendReply}

    const isValid = sendReplyValidate()
    if (isValid) {
      setDisableReplyBtn(true)
      setIsReplyLoading('Please Wait...')
      const res = await postRequest(
        `${apiBaseURL()}support_request/message`,
        replyMessageInfo,
        true
      )
      if (res.status == 201) {
        setDisableReplyBtn(false)
        setIsReplyLoading('Submit')
        $('#closeSendReplyModal').trigger('click')
        setTimeout(() => {
          assignedToMeClearFunction()
        }, 201)
        props.onAction()
      }
    }
  }

  // assigned function for open status
  const assignedTo = async () => {
    await patchRequest(
      `${apiBaseURL()}support_request/support-request-assign/${supportRequestId}`,
      {status: 'assigned'},
      true
    ).then((res: any) => {
      if (res.status == 200) {
        navigate('/complaint/my-assigned')
      }
    })
  }

  //  Closed Modal Require functions
  const closedRequestValidate = () => {
    let validations = {...closeRequestValidation}
    let isValid = true

    if (closeRequest.comment == '') {
      validations.comment = 'is-invalid'
      isValid = false
    }

    setCloseRequestValidation(validations)
    return isValid
  }

  const closedRequestClearFunction = () => {
    setDisableCloseBtn(false)
    setIsCloseLoading('Submit')
    setCloseRequest(initialClose)
    setCloseRequestValidation(initialCloseValidation)
  }

  const closedRequest = async () => {
    const isValid = closedRequestValidate()
    if (isValid) {
      setDisableCloseBtn(true)
      setIsCloseLoading('Please Wait...')
      const res = await patchRequest(
        `${apiBaseURL()}support_request/support-request-assign/${supportRequestId}`,
        {
          status: 'closed',
          closing_note: closeRequest.comment,
        },
        true
      )
      if (res.status == 200) {
        setDisableCloseBtn(false)
        setIsCloseLoading('Submit')
        $('#closeSupportRequestClosed').trigger('click')
        setTimeout(() => {
          closedRequestClearFunction()
        }, 200)
        navigate('/complaint/my-closed')
      }
    }
  }

  return (
    <>
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      #{requestDetails.support_number} {requestDetails.title}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                    <span className='d-flex align-items-center me-6 mb-2'>
                      <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                      {dateFormat(requestDetails.created_at).displayDate} ,{' '}
                      {dateFormat(requestDetails.created_at, 'LT').displayDate}
                    </span>
                  </div>
                </div>
                {checkUserType('ts') && (
                  <>
                    {requestType === 'new-message' &&
                      location.pathname === '/complaint/new-message/complaint-detail/overview' &&
                      ['assigned'].includes(requestDetails.status) && (
                        <div>
                          <button
                            className='btn btn-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#ea_modal_assigntome'
                          >
                            Assign to Me
                          </button>
                        </div>
                      )}
                    {requestType === 'new-message' &&
                      location.pathname === '/complaint/new-message/complaint-detail/overview' &&
                      requestDetails.status == 'open' && (
                        <div>
                          <button className='btn btn-primary' onClick={assignedTo}>
                            Assign to Me
                          </button>
                        </div>
                      )}
                    {requestType !== 'my-closed' &&
                      location.pathname !== `/complaint/${requestType}/complaint-detail/overview` &&
                      requestDetails.status === 'assigned' && (
                        <div>
                          <button
                            className='btn btn-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#ea_modal_send_reply'
                          >
                            Send Reply
                          </button>
                        </div>
                      )}
                    {requestType === 'my-assigned' &&
                      location.pathname === '/complaint/my-assigned/complaint-detail/overview' && (
                        <div>
                          <button
                            className='btn btn-danger'
                            data-bs-toggle='modal'
                            data-bs-target='#ea_modal_close_request'
                          >
                            Close
                          </button>
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
          {isAdmin() ? (
            <>
              <div className='d-flex overflow-auto h-40px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === `/tele-sales/complaint-title/overview` && 'active')
                      }
                      to='overview'
                    >
                      Overview
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === `/tele-sales/complaint-title/messages` && 'active')
                      }
                      to='messages'
                    >
                      Messages
                    </Link>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <div className='d-flex overflow-auto h-40px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname ===
                        `/complaint/${requestType}/complaint-detail/overview` && 'active')
                    }
                    to='overview'
                  >
                    Overview
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname ===
                        `/complaint/${requestType}/complaint-detail/messages` && 'active')
                    }
                    to='messages'
                  >
                    Messages
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <EaModal
        id='ea_modal_assigntome'
        modalTitle='Reason'
        onSubmit={assignedToMeSubmit}
        cancel={assignedToMeClearFunction}
        closeAuto='closeAssigendModal'
        btnDisabled={disableBtn}
        actionBtnName={isLoading}
      >
        <div className='mb-4'>
          <label className='form-label'>Reason</label>
          <AsyncPaginate
            loadOptions={fetchRequestReason}
            isSearchable
            placeholder='Select'
            className={clsx('react-select-container w-100', reasonValidation.option)}
            classNamePrefix='react-select'
            value={assignedReason.option}
            onChange={(e: any) => handleChangeReason(e, 'option')}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        {/* {assignedReason.option.value == '' && ( */}
        <>
          <label className='form-label'>Comments</label>
          <textarea
            className={clsx('form-control', reasonValidation.comments)}
            rows={4}
            placeholder='Type here…'
            value={assignedReason.comments}
            name='comments'
            onChange={(e: any) => handleChangeReason(e, 'comments')}
          ></textarea>
        </>
        {/* )} */}
      </EaModal>
      <EaModal
        id='ea_modal_send_reply'
        modalTitle='Send Reply to Complaint'
        onSubmit={sendReplySubmit}
        cancel={sendReplyClearFunction}
        closeAuto='closeSendReplyModal'
        btnDisabled={disableReplyBtn}
        actionBtnName={isReplyLoading}
      >
        <div>
          <label className='form-label'>Reply</label>
          <textarea
            className={clsx('form-control', sendReplyValidation.message)}
            placeholder='Type here…'
            rows={6}
            value={sendReply.message}
            name='message'
            onChange={(e: any) => {
              setSendReply({...sendReply, ['message']: e.target.value})
              setSendReplyValidation({...sendReplyValidation, ['message']: ''})
            }}
          ></textarea>
        </div>
      </EaModal>
      <EaModal
        id='ea_modal_close_request'
        modalTitle='Close Complaint'
        onSubmit={closedRequest}
        cancel={closedRequestClearFunction}
        closeAuto='closeSupportRequestClosed'
        btnDisabled={disableCloseBtn}
        actionBtnName={isCloseLoading}
      >
        <div>
          <label className='form-label'>Comments</label>
          <textarea
            className={clsx('form-control', closeRequestValidation.comment)}
            placeholder='Type here…'
            rows={6}
            value={closeRequest.comment}
            name='comment'
            onChange={(e: any) => {
              setCloseRequest({...sendReply, ['comment']: e.target.value})
              setCloseRequestValidation({...closeRequestValidation, ['comment']: ''})
            }}
          ></textarea>
        </div>
      </EaModal>
    </>
  )
}

export default SupportRequestHeader
