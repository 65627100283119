import React from "react"
import { useIntl } from "react-intl"
import Select from "react-select"
// import Select from 'react-select/dist/declarations/src/Select'
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const paymentoptions = [
    { value: 'settle', label: 'Settle' },
    { value: 'pending', label: 'Pending' },
    { value: 'approve', label: 'Approve' },
]

const PaymentIssue: React.FC = () => {
    const intl = useIntl()
    const teamHeaderTitle = {
        title: "Team members",
        subTitle: "Connor Lawson"
    }
    return (
        <>
            <PageTitle breadcrumbs={[teamHeaderTitle]} >{intl.formatMessage({ id: 'Connor Lawson' })}</PageTitle>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className="ms-auto">
                        <div className='card-toolbar my-0'>
                            <Select
                                options={paymentoptions}
                                isSearchable
                                placeholder="All Status"
                                className='react-select-container w-115px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-150px'>Date</th>
                                    <th className='min-w-140px'>Total Receivable</th>
                                    <th className='min-w-120px'>Total Received</th>
                                    <th className='min-w-120px'>Unsettled Amount</th>
                                    <th className='min-w-120px text-end'>Status</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>
                                    <td>07 Apr 2022</td>
                                    <td>TZS 90953</td>
                                    <td>TZS 0.00</td>
                                    <td>TZS 90953</td>
                                    <td className='text-end'>
                                        <span className='badge badge-light-success text-dark'>Settle</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>07 Apr 2022</td>
                                    <td>TZS 90953</td>
                                    <td>TZS 0.00</td>
                                    <td>TZS 90953</td>
                                    <td className='text-end'>
                                        <span className='badge badge-light-success text-dark'>Settle</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>07 Apr 2022</td>
                                    <td>TZS 90953</td>
                                    <td>TZS 0.00</td>
                                    <td>TZS 90953</td>
                                    <td className='text-end'>
                                        <span className='badge badge-light-warning text-dark'>Pending</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>07 Apr 2022</td>
                                    <td>TZS 90953</td>
                                    <td>TZS 0.00</td>
                                    <td>TZS 90953</td>
                                    <td className='text-end'>
                                        <span className='badge badge-light-warning text-dark'>Pending</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>07 Apr 2022</td>
                                    <td>TZS 90953</td>
                                    <td>TZS 0.00</td>
                                    <td>TZS 90953</td>
                                    <td className='text-end'>
                                        <span className='badge badge-light-success text-dark'>Settle</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>07 Apr 2022</td>
                                    <td>TZS 90953</td>
                                    <td>TZS 0.00</td>
                                    <td>TZS 90953</td>
                                    <td className='text-end'>
                                        <span className='badge badge-light-success text-dark'>Settle</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>07 Apr 2022</td>
                                    <td>TZS 90953</td>
                                    <td>TZS 0.00</td>
                                    <td>TZS 90953</td>
                                    <td className='text-end'>
                                        <span className='badge badge-light-success text-dark'>Settle</span>
                                    </td>
                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentIssue
