import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AMAsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/payment-issues'
        icon='/media/icons/duotune/finance/fin002.svg'
        title='Payment Issues'
      />
      <AsideMenuItem
        to='/credit-issues'
        icon='/media/icons/duotune/files/fil005.svg'
        title='Credit Issues'
      />
      <AsideMenuItem
        to='/sales-invoices'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
        title='Sales Invoices'
      />
      <AsideMenuItem
        to='/all-complaints'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Complaints'
      />
      {/* <AsideMenuItem
                to='/credit-notes'
                icon='/media/icons/duotune/general/gen005.svg'
                title='Credit Notes'
            />*/}
      <AsideMenuItem
        to='/targets'
        icon='/media/icons/duotune/abstract/abs014.svg'
        title='Targets'
      />
      <AsideMenuItemWithSub
        to='/reports'
        title='Reports'
        icon='/media/icons/duotune/graphs/gra006.svg'
      >
        <AsideMenuItem
          to='/reports/sales-invoice-report'
          title='Sales Invoice Report'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/credit-collection-report'
          title='Credit Collection Report'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/pay-later-today-report'
          title='Pay Later Today Report'
          hasBullet={true}
        />
        {/*  <AsideMenuItem
                    to='/reports/reconciliation-report'
                    title='Reconciliation Report'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/reports/credit-usage-report'
                    title='Credit Usage Report'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/reports/brandwise-sales'
                    title='Brandwise Sales'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/reports/discount-usage-report'
                    title='Discount Usage Report'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/reports/expired-products'
                    title='Expired Products Report'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/reports/returned-products'
                    title='Retured Items Report'
                    hasBullet={true}
                />*/}
      </AsideMenuItemWithSub>
    </>
  )
}
