import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
    apiBaseURL,
    checkEmpty,
    dateFormat,
    fetchAsyncDropdown,
    getLoginWarehouse,
    isEmpty,
    numberFormat,
} from '../../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import { PageTitle } from '../../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../../TenantVariables'
import useDebounce from '../../../../../_eaFruitsDms/helpers/components/useDebounce'
import { getRequest } from '../../../../../_eaFruitsDms/https/apiCall'
import { WarehouseFilter } from '../../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import { CustomDatePicker } from '../../../../../_eaFruitsDms/helpers/components/CustomDatePicker'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import ShowBrandToolTip from '../../../../../_eaFruitsDms/helpers/components/BrandToolTip'
import { AsyncPaginate } from 'react-select-async-paginate'

const GatePassRejectionReport = () => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Gatepass Request Rejection Report"
    }

    let todayDate = dateFormat(moment());

    const [fetchingData, setFetchingData] = useState<boolean>(false);
    const [gatePassRejection, setGatePassRejection] = useState([]);
    const [count, setCount] = useState(0);
    const [selected, setSelected] = useState(0);
    const [currentOffSet, setCurrentOffSet] = useState(0);
    const [search, setSearch] = useState("");
    const [warehouse, setWarehouse] = useState({ label: 'All Warehouses', value: '' });
    const [limit, setLimit] = useState(tenantConfiguration.limit);
    const [date, setDate] = useState<any>({
        startDate: todayDate,
        endDate: todayDate
    });
    const [offset, setOffset] = useState(0)
    const [list, setList] = useState([])
    const [dropDownSearch, setDropDownSearch] = useState('')
    const [srUser, setSrUser] = useState<any>('')

    const debounceSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchReportData().then()
    }, [debounceSearch, currentOffSet, warehouse, date, srUser]);

    const fetchReportData = async () => {
        setFetchingData(true)
        const response = await getRequest(
            `${apiBaseURL()}reports/rejection/gatepass?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&warehouse_id=${checkEmpty(warehouse.value, "")}&start_date=${date.startDate.apiDate}&end_date=${date.endDate.apiDate}&sr_user=${srUser !== "" ? srUser.value : ''}`,
            true
        )

        if (response.results) {
            const reportData: any = []
            response.results.map((report: any) => {
                let status = ''

                if (report.status === 'sg_rejected_at_exit_check') status = 'SG Rejected At Exit Check'
                if (report.status === 'sg_reported_at_entry_check') status = 'SG Reported At Entry Check'
                if (report.status === 'ss_rejected') status = 'SS Rejected'

                return reportData.push({
                    id: report.id,
                    created_date: isEmpty(report.date_time) ? "-" : dateFormat(report.date_time).displayDate,
                    created_time: isEmpty(report.date_time) ? "-" : dateFormat(report.date_time, "LT").displayDate,
                    gatepass_number: isEmpty(report.gatepass_number) ? "-" : `#${report.gatepass_number}`,
                    sr_vehicle_number: isEmpty(report.sr_vehicle_number) ? "-" : report.sr_vehicle_number,
                    sr_name: report.sr_user && isEmpty(report.sr_user.first_name) ? "-" : report.sr_user.first_name + " " + report.sr_user.last_name,
                    rejected_date: isEmpty(report.rejected_at) ? "-" : dateFormat(report.rejected_at).displayDate,
                    rejected_time: isEmpty(report.rejected_at) ? "-" : dateFormat(report.rejected_at, "LT").displayDate,
                    response_by: report.processd_by && isEmpty(report.processd_by.first_name) ? "-" : report.processd_by.first_name + " " + report.processd_by.last_name,
                    status: isEmpty(status) ? "-" : status,
                    reason: report.display_reason,
                    brands: report.products_brand && report.products_brand.length > 0 ? report.products_brand : [],
                    type: report.processd_by && isEmpty(report.processd_by.type) ? "-" : report.processd_by.type.toUpperCase(),
                })
            })
            setCount(response.count)
            setGatePassRejection(reportData)
        }
        setFetchingData(false)
    };

    const handleFilterChange = (e: any, name?: string) => {
        const inputName: string = e.target ? e.target.name : name

        switch (inputName) {
            case 'warehouse':
                setWarehouse(e)
                break
            case 'sales':
                setSrUser(e)
                break
            case 'search':
                setSearch(e.target.value)
                break
        }
    }

    const fetchSalesReps = async (search: any) => {
        if (search != "") {
            setDropDownSearch(search)
            setOffset(0)
        }

        let limit = tenantConfiguration.limit  // default limit

        let options: any = []

        let warehouseFilter = '&warehouse_id=' + checkEmpty(warehouse.value, "")

        let concatUrl = `${apiBaseURL()}auth/users/team/list?type=sr&ordering=first_name&search=${search}&limit=${limit}&offset=${offset}` + warehouseFilter

        const response = await getRequest(concatUrl, true)

        let hasMore: boolean = false

        if (response.results) {
            if (response.next !== null) {
                const queryParams = new URLSearchParams(response.next)
                let newOffset: any = queryParams.get("offset")
                setOffset(newOffset)
                hasMore = true
            }

            if (list.length === 0) {
                options.push({
                    label: "All Sales Reps", // these
                    value: "",
                })
            }

            if (response.results.length > 0) {
                response.results.map((option: any) => {
                    options.push({
                        label: option.first_name + " " + option.last_name,
                        value: option.id,
                    })
                })
            }

            if (list.length > 0) {
                setList(list.concat(options))
            } else {
                setList(options)
            }
        }

        return {
            options: options,
            hasMore: hasMore,
        }
    }

    return (
        <>
            <EATitle title='Gatepass Request Rejection Report' />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Gatepass Request Rejection Report' })}</PageTitle>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar onChange={handleFilterChange} value={search} isDisable={fetchingData} />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <WarehouseFilter
                                    warehouse={warehouse}
                                    handleFilterChange={handleFilterChange}
                                    isDisabled={fetchingData}
                                />
                                <AsyncPaginate
                                    loadOptions={fetchSalesReps}
                                    isSearchable
                                    placeholder="All Sales Reps"
                                    className='react-select-container my-1 me-3 w-150px'
                                    classNamePrefix="react-select"
                                    name='sales'
                                    onChange={(e) => {
                                        handleFilterChange(e, 'sales')
                                    }}
                                    value={srUser}
                                    isDisabled={fetchingData}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <CustomDatePicker
                                    date={date}
                                    setDate={setDate}
                                    displayLoader={fetchingData}
                                />
                                {/*<button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 fw-600 d-flex align-items-center">*/}
                                {/*    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />*/}
                                {/*    Export*/}
                                {/*</button>*/}
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {
                                fetchingData ? <FetchingRecords /> :
                                    gatePassRejection.length === 0 ? <NoRecords />
                                        :
                                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                            {/* begin::Table head */}
                                            <thead>
                                                <tr className='text-muted fw-500'>
                                                    <th className='min-w-110px py-2'>Date & Time</th>
                                                    <th className='min-w-140px py-2'>Gatepass Id</th>
                                                    <th className='min-w-140px py-2'>Vehicle Number</th>
                                                    <th className='min-w-160px py-2'>Brand</th>
                                                    <th className='min-w-120px py-2'>SR Name</th>
                                                    <th className='min-w-120px py-2'>Rejected At</th>
                                                    <th className='min-w-150px py-2'>Response by</th>
                                                    <th className='min-w-170px py-2'>Gatepass Status</th>
                                                    <th className='min-w-150px py-2'>Reason</th>
                                                </tr>
                                            </thead>
                                            {/* end::Table head */}
                                            {/* begin::Table body */}
                                            <tbody>
                                                {
                                                    gatePassRejection.map((requestReport: any) => {
                                                        return <tr>
                                                            <td>
                                                                {requestReport.created_date}
                                                                <p className='font-13 text-muted mb-0'>{requestReport.created_time}</p>
                                                            </td>
                                                            <td>
                                                                {requestReport.gatepass_number}
                                                            </td>
                                                            <td>
                                                                {requestReport.sr_vehicle_number}
                                                            </td>
                                                            <td>
                                                                <ShowBrandToolTip brands={requestReport.brands} />
                                                            </td>
                                                            <td>
                                                                {requestReport.sr_name}
                                                            </td>
                                                            <td>
                                                                {requestReport.rejected_date}
                                                                <p className='font-13 text-muted mb-0'>{requestReport.rejected_time}</p>
                                                            </td>
                                                            <td>
                                                                {`${requestReport.response_by} (${requestReport.type})`}
                                                            </td>
                                                            <td>
                                                                {requestReport.status}
                                                            </td>
                                                            <td>
                                                                {requestReport.reason}
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                            {/* end::Table body */}
                                        </table>
                            }
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <CustomPaginate
                    data={gatePassRejection}
                    selected={selected}
                    setSelected={setSelected}
                    setCurrentOffset={setCurrentOffSet}
                    totalRecords={count}
                    limit={limit}
                    isDisabled={fetchingData}
                />
            </div>
        </>
    )
}

export default GatePassRejectionReport
