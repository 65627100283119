import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {apiBaseURL, checkEmpty, isEmpty, numberFormat} from '../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageLink, PageTitle} from '../../../../_eaFruitsDms/layout/core'
import OrderDrawer from './components/drawer'
import RouteDetails from './components/routeDetails'
import DropPointSection from './components/dropPointSection'

const SrTimeTrackingDetails = () => {
  const location: any = useLocation()
  const navigate = useNavigate()

  const header: Array<PageLink> = [
    {
      title: 'SRs Daily Routes',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  const [isDisplayLoader, setIsDisplayLoader] = useState<boolean>(false)
  const [details, setDetails] = useState<any>(null)
  const [type, setType] = useState('system_generated_seq')
  const [gatePassType, setGatePassType] = useState('withoutGatePass')
  const [orderId, setOrderId] = useState('')
  const [srId, setSrId] = useState('')

  useEffect(() => {
    if (isEmpty(location?.state?.itemId)) {
      navigate(-1)
      return
    }

    fetchRouteDetails().then()
  }, [location])

  const fetchRouteDetails = async () => {
    setIsDisplayLoader(true)

    const response = await getRequest(
      `${apiBaseURL()}routes/sr-daily-route-report/${location?.state?.itemId}?date=${
        location?.state?.routeDate
      }`,
      true
    )

    const responseDetails = await getRequest(
      `${apiBaseURL()}routes/mobile/manage/${location?.state?.itemId}/detail?date=${
        location?.state?.routeDate
      }`,
      true
    )

    if (responseDetails && responseDetails?.result?.default_sr_user_id) {
      setSrId(
        isEmpty(responseDetails?.result?.default_sr_user_id)
          ? ''
          : responseDetails?.result?.default_sr_user_id
      )
    }

    if (response && response?.id) {
      setDetails(response)
    }

    setIsDisplayLoader(false)
  }

  return (
    <>
      <EATitle title='SRs Daily Routes' />

      <PageTitle breadcrumbs={header}>
        {isEmpty(location?.state?.routeName) ? '-' : location?.state?.routeName}
      </PageTitle>

      <>
        <div className='card mb-4 mb-xl-5'>
          <div className='card-header border px-7 pt-5 pb-0'>
            <div className='d-flex flex-column mb-2 mb-sm-0'>
              <div className='d-flex align-items-center mb-1'>
                <span className='text-dark font-20 fw-bold me-1'>Route Details</span>
              </div>
            </div>
          </div>
          <div className='card-body py-3 mt-4'>
            {isDisplayLoader ? (
              <FetchingRecords />
            ) : isEmpty(details) ? (
              <NoRecords />
            ) : (
              <div className='row'>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Route No</label>
                  <div>
                    <h6 className='fw-bold'>
                      {isEmpty(details?.route_no) ? '-' : `#${details?.route_no}`}
                    </h6>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Route Name</label>
                  <div>
                    <h6 className='fw-bold'>{checkEmpty(details?.name)}</h6>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Sales Team Name</label>
                  <div>
                    <h6 className='fw-bold'>{checkEmpty(details?.sales_team_name)}</h6>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Warehouse Name</label>
                  <div>
                    <h6 className='fw-bold'>{checkEmpty(details?.warehouse_name)}</h6>
                  </div>
                </div>

                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Total Customers</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {isEmpty(details?.customer_count)
                        ? '-'
                        : numberFormat(details?.customer_count).displayFormat}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Total Pre Orders</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {isEmpty(details?.total_pre_orders)
                        ? '-'
                        : numberFormat(details?.total_pre_orders).displayFormat}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>
                    Total Customer Preferred Delivery
                  </label>
                  <div>
                    <span className='font-14 text-dark'>
                      {isEmpty(details?.total_customer_preferred_delivery)
                        ? '-'
                        : numberFormat(details?.total_customer_preferred_delivery).displayFormat}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Total Credit Issues</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {isEmpty(details?.total_credit_issues)
                        ? '-'
                        : numberFormat(details?.total_credit_issues).displayFormat}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card mb-4 pt-3 ps-3'>
          <div className='d-flex overflow-auto h-40px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
              <li
                className='nav-item'
                onClick={() => {
                  setGatePassType('withoutGatePass')
                  setType('system_generated_seq')
                }}
              >
                <div
                  className={`nav-link cursor-pointer text-active-primary me-0 ${
                    gatePassType === 'withoutGatePass' ? 'active' : ''
                  }`}
                >
                  Drop Points
                </div>
              </li>
              {/* <li
                  className='nav-item'
                  onClick={() => {
                    setGatePassType('gatePass')
                    setType('system')
                  }}
                >
                  <div
                    className={`nav-link cursor-pointer text-active-primary me-0 ${
                      gatePassType === 'gatePass' ? 'active' : ''
                    }`}
                  >
                    Gatepass #001
                  </div>
                </li> */}
            </ul>
          </div>
        </div>

        <div className='bg-white pt-3 ps-3 border-top-0 border-start-0 border-end-0 border border-secondary'>
          <div className='d-flex overflow-auto h-40px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
              <li className='nav-item' onClick={() => setType('system_generated_seq')}>
                <div
                  className={`nav-link cursor-pointer text-active-primary me-0 ${
                    type === 'system_generated_seq' ? 'active' : ''
                  }`}
                >
                  System Suggested
                </div>
              </li>
              {
                <li className='nav-item' onClick={() => setType('followed_seq')}>
                  <div
                    className={`nav-link cursor-pointer text-active-primary me-0 ${
                      type === 'followed_seq' ? 'active' : ''
                    }`}
                  >
                    SR Followed
                  </div>
                </li>
              }
              <li className='nav-item' onClick={() => setType('route')}>
                <div
                  className={`nav-link cursor-pointer text-active-primary me-0 ${
                    type === 'route' ? 'active' : ''
                  }`}
                >
                  Route Details
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className='bg-white p-5'>
          {['system_generated_seq', 'followed_seq'].includes(type) && (
            <DropPointSection setOrderId={setOrderId} type={type} srId={srId} />
          )}

          {type === 'route' && <RouteDetails />}
        </div>

        <OrderDrawer orderId={orderId} />
      </>
    </>
  )
}

export default SrTimeTrackingDetails
