import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import tenantConfiguration from '../../../../TenantVariables'
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  fetchAsyncDropdownNew,
  fetchStaticAsyncDropdown,
  globalDateFormate,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {AsyncPaginate} from 'react-select-async-paginate'

const OrderLimitRequestReport = () => {
  const intl = useIntl()
  const customersHeaderTitle = {
    title: 'Customers',
    subTitle: 'Order Limit Requests Report',
  }

  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState(tenantConfiguration.limit)
  const [warehouse, setWarehouse] = useState<any>({label: 'All Warehouse', value: ''})
  const [fetchingRecords, setFetchingRecords] = useState(false)
  const [orderLimits, setOrderQuantity] = useState([])
  const [count, setCount] = useState<string>('1')
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  })
  const [srOffSet, setSrOffSet] = useState<any>('')
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [sr, setSr] = useState<any>({label: 'All Sales Reps', value: ''})
  const [customer, setCustomer] = useState<any>({label: 'All Customer', value: ''})
  const [status, setStatus] = useState<any>({label: 'All Status', value: ''})
  const [srReset, setSrReset] = useState<any>(new Date())
  const [customerReset, setCustomerReset] = useState<any>(moment())
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')

  const userType: any = localStorage.getItem('user_type') || ''
  const debounceSearch = useDebounce(search, 500)

  const fetchOrderLimitRequest = async () => {
    setFetchingRecords(true)

    const url = `${apiBaseURL()}reports/order-limit-request?search=${debounceSearch}&warehouse_id=${
      warehouse.value
    }&limit=${limit}&offset=${currentOffset}&customer_id=${customer.value}&sr_id=${
      sr.value
    }&status=${status.value}&requested_at_duration_after=${
      apiDate.startDate
    }&requested_at_duration_before=${apiDate.endDate}`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setOrderQuantity(response.results)
      setCount(response.count)
    }

    setFetchingRecords(false)
  }

  const handleFilterChange = (e: any) => {
    setCurrentOffset(0)
    setSelected(0)
    setWarehouse(e)

    setDropDownSearch('')
    setList([])
    setOffset(0)
    setCustomerReset(moment())
    setCustomer({label: 'All Customer', value: ''})

    setSearchSelect('')
    setSalesRepsOption([])
    setSrOffSet('')
    setSrReset(new Date())
    setSr({label: 'All Sales Reps', value: ''})
  }

  const fetchSrs = async (search?: string) => {
    let wId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id') || ''

    if (!isEmpty(warehouse)) {
      wId = typeof warehouse === 'object' ? warehouse.value : warehouse
    }
    if (!isEmpty(warehouse.value)) {
      wId = warehouse.value
    }

    return await fetchAsyncDropdownNew(
      srOffSet,
      setSrOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `auth/users/team/list?ordering=first_name&warehouse__id=${isEmpty(wId) ? '' : wId}&type=sr`,
      search,
      setSearchSelect,
      'first_name',
      'last_name',
      true,
      'All Sales Reps'
    )
  }

  const fetchCustomer = (search: string) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `customers/drop-down?ordering=business_name&warehouse_id=${warehouse.value}`,
      search,
      setDropDownSearch,
      'business_name',
      true,
      'All Customer'
    )
  }

  const fetchStatus = (search: string) => {
    const status = [
      {
        label: 'All Status',
        value: '',
      },
      {
        label: 'Approved',
        value: 'approved',
      },
      {
        label: 'Cancelled',
        value: 'cancelled',
      },
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Rejected',
        value: 'rejected',
      },
    ]

    return fetchStaticAsyncDropdown(status, search)
  }

  useEffect(() => {
    fetchOrderLimitRequest()
  }, [currentOffset, warehouse, customer, sr, status, apiDate, debounceSearch])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
            disabled={fetchingRecords}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Order Limit Requests Report' />
      <PageTitle breadcrumbs={[customersHeaderTitle]}>
        {intl.formatMessage({id: 'Order Limit Requests Report'})}
      </PageTitle>

      <div className={`card`}>
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              isDisable={fetchingRecords}
            />
            <div className='col-auto ms-auto'>
              <form
                className=' card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setApiDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={apiDate}
                  startDate={apiDate.startDate}
                  endDate={apiDate.endDate}
                />

                <WarehouseFilter
                  warehouse={warehouse}
                  handleFilterChange={handleFilterChange}
                  isDisabled={fetchingRecords}
                />

                <AsyncPaginate
                  key={customerReset}
                  loadOptions={fetchCustomer}
                  isSearchable
                  placeholder='Select'
                  className={`react-select-container me-3 w-150px`}
                  onChange={(e) => {
                    setCustomer(e)
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={customer}
                  isDisabled={fetchingRecords}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  key={srReset}
                  loadOptions={fetchSrs}
                  className='react-select-container me-3 w-150px'
                  classNamePrefix='react-select'
                  placeholder='All Sales Reps'
                  value={sr}
                  isDisabled={fetchingRecords}
                  onChange={(e: any) => {
                    setSr(e)
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  placeholder='Select'
                  className={`react-select-container w-150px`}
                  onChange={(e) => {
                    setStatus(e)
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={status}
                  isDisabled={fetchingRecords}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </form>
            </div>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {fetchingRecords ? (
              <FetchingRecords />
            ) : orderLimits.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-80px'>Request No</th>
                    <th className='min-w-100px'>Request Date</th>
                    <th className='min-w-150px'>Customer Name</th>
                    <th className='min-w-150px'>Request by</th>
                    <th className='min-w-120px'>Response by</th>
                    <th className='min-w-120px'>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {orderLimits.length > 0 &&
                    orderLimits.map((item: any) => {
                      return (
                        <tr>
                          <td className='py-3'>{isEmpty(item.ref_no) ? '-' : `#${item.ref_no}`}</td>
                          <td className='py-3'>
                            {isEmpty(item.created_at) ? (
                              '-'
                            ) : (
                              <>
                                {dateFormat(item.created_at).displayDate}
                                <div>{dateFormat(item.created_at, 'LT').displayDate}</div>
                              </>
                            )}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.customer_name) ? '-' : item.customer_name}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.requested_by) ? '-' : item.requested_by}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.action_by) || isEmpty(item.action_by_type)
                              ? '-'
                              : `${item.action_by} (${item.action_by_type.toUpperCase()})`}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.status) ? (
                              '-'
                            ) : (
                              <span>
                                {item.status === 'pending' && (
                                  <span className='badge badge-secondary text-dark'>Pending</span>
                                )}

                                {item.status === 'approved' && (
                                  <span className='badge badge-light-success text-dark'>
                                    Approved
                                  </span>
                                )}

                                {item.status === 'cancelled' && (
                                  <span className='badge badge-secondary text-dark'>Cancelled</span>
                                )}

                                {item.status === 'rejected' && (
                                  <span className='badge badge-light-danger text-dark'>
                                    Rejected
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <CustomPaginate
        data={orderLimits}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={fetchingRecords}
        saveFilterName={'feedbackReport'}
        filterStates={''}
      />
    </>
  )
}

export default OrderLimitRequestReport
