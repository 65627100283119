import { useEffect, useState } from "react"

const useDebounce = <T extends unknown>(value: T, delay: number, setOffset?: any): T => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (setOffset)
				setOffset('')
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
};

export default useDebounce;
