import { apiBaseURL } from "../../helpers"
import { getRequest, patchRequest, postRequest, putRequest } from "../../https/apiCall"

export const RolesPermissions = async () => {
    const res: any = await getRequest(`${apiBaseURL()}auth/roles-features`, true)
    return res
}

export const roleCreate = async (
    role: any,
    screen_name: any,
    short_description?: any,
    user_type ?: any
) => {
    const notify = {
        success: false,
        failed: false,
        errorField: ""
    }
    let body = {
        name: role,
        screen_name: screen_name,
        short_description: short_description,
        user_type: user_type
    }

    const res =
    user_type === 'sa'
      ? await postRequest(`${apiBaseURL()}auth/role`, body, true)
      : await postRequest(`${apiBaseURL()}auth/role?user_type=${user_type}`, body, true)
            if (res.status === 201 ) {
                notify.success = true
            }
            if (res.statusText === "Bad Request") {
                notify.failed = true
                notify.errorField = res.data.error.field
            }
    return notify;
}

export const roleEdit = async (
    id: any,
    role_name: any,
    user_type: any,
    short_description?: any,
) => {
    const notify = {
        success: false,
        failed: false,
        errorField: ""
    }
    let body = {
        name: role_name,
        short_description: short_description,
        user_type: user_type
    }
    const res =
    user_type === 'sa'
      ? await patchRequest(`${apiBaseURL()}auth/role/${id}`, body, true)
      : await patchRequest(`${apiBaseURL()}auth/role/${id}?user_type=${user_type}`, body, true)
            if (res.status === 200) {
                notify.success = true
            }
            if (res.statusText === "Bad Request") {
                notify.failed = true
                notify.errorField = res.data.error.field
            }
    return notify;
}

export const RolesSingle = async (id?: any) => {
    let data = ""
    await getRequest(`${apiBaseURL()}auth/role/${id}`, true)
        .then((res) => {
            data = res
        })
    return data;
}

export const wmRolesSingle = async (id?: any) => {
    let data = ""
    await getRequest(`${apiBaseURL()}auth/role/${id}?user_type=wm`, true)
        .then((res) => {
            data = res
        })
    return data;
}

export const rolePermissionEditRead = async (
    id: any,
    screen_name: any,
    read: any,
    roles: any,
    features: any,
) => {
    const notify = {
        success: false,
        failed: false
    }
    let body = {
        screen_name: screen_name,
        read: read,
        roles: roles,
        features: features
    }
    await putRequest(`${apiBaseURL()}auth/application/role-permission/${id}/`, body, true)
        .then((res) => {
            if (res.status === 200) {
                notify.success = true
            }
            if (res.statusText === "Bad Request") {
                notify.failed = true
            }
        })
    return notify;
}

export const rolePermissionEditWrite = async (
    id: any,
    screen_name: any,

    write: any,

    roles: any,
    features: any,
) => {
    const notify = {
        success: false,
        failed: false
    }

    let body = {
        screen_name: screen_name,
        write: write,
        roles: roles,
        features: features
    }
    await putRequest(`${apiBaseURL()}auth/application/role-permission/${id}/`, body, true)
        .then((res) => {
            if (res.status === 200) {
                notify.success = true
            }
            if (res.statusText === "Bad Request") {
                notify.failed = true
            }
        })
    return notify;
}

export const rolePermissionEditDelete = async (
    id: any,
    screen_name: any,
    Rdelete: any,
    roles: any,
    features: any,
) => {
    const notify = {
        success: false,
        failed: false
    }
    let body = {
        screen_name: screen_name,
        delete: Rdelete,
        roles: roles,
        features: features
    }
    await putRequest(`${apiBaseURL()}auth/application/role-permission/${id}/`, body, true)
        .then((res) => {
            if (res.status === 200) {
                notify.success = true
            }
            if (res.statusText === "Bad Request") {
                notify.failed = true
            }
        })
    return notify;
}

// read , write , del which is used in tableRolesPermission.tsx updated by kriti
export const permissionEditRead = async (
    read: any,
    id: any,    
) => {
    const notify = {
        success: false,
        failed: false
    }
    let body = {
        value: read,
    }
    console.log("body" , body);
    
    await patchRequest(`${apiBaseURL()}auth/role-feature-permission/${id}`, body, true)
        .then((res) => {
            if (res.status === 200) {
                notify.success = true
            }
            if (res.statusText === "Bad Request") {
                notify.failed = true
            }
        })
    return notify;
}

export const permissionEditWrite = async (
    write: any,
    id: any,    
) => {
    const notify = {
        success: false,
        failed: false
    }
    let body = {
        value: write,
    }
    console.log("body" , body);
    
    await patchRequest(`${apiBaseURL()}auth/role-feature-permission/${id}`, body, true)
        .then((res) => {
            if (res.status === 200) {
                notify.success = true
            }
            if (res.statusText === "Bad Request") {
                notify.failed = true
            }
        })
    return notify;
}

export const permissionEditDelete = async (
    del: any,
    id: any,    
) => {
    const notify = {
        success: false,
        failed: false
    }
    let body = {
        value: del,
    }
    console.log("body" , body);
    
    await patchRequest(`${apiBaseURL()}auth/role-feature-permission/${id}`, body, true)
        .then((res) => {
            if (res.status === 200) {
                notify.success = true
            }
            if (res.statusText === "Bad Request") {
                notify.failed = true
            }
        })
    return notify;
}

