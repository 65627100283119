import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { MenuComponent } from "../assets/ts/components"
import { AsideDefault } from "./components/aside/AsideDefault"
import { Content } from "./components/Content"
import ChangePassword from "./components/header/changePassword"
import HeaderWrapper from "./components/header/HeaderWrapper"
import UpdateStockModal from "./components/header/updateStock"
import { Toolbar } from "./components/toolbar/Toolbar"
import { PageDataProvider } from "./core"

const MasterLayout = () => {
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
        </div>
      </div>
      <ChangePassword />
      <UpdateStockModal />

      {/* <Main /> */}
    </PageDataProvider>
  )
}

export { MasterLayout }
