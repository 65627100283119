import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import {useLocation, useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdownNew,
  fetchStaticAsyncDropdown,
  globalDateFormate,
  isAdmin,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
  removeFilter,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import tenantConfiguration from '../../../TenantVariables'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import moment from 'moment'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'
import DateRangePicker from 'react-bootstrap-daterangepicker'

const statusoptions = [
  {value: 'settled', label: 'Settled'},
  {value: 'unsettled', label: 'Unsettled'},
]

const PaymentIssues = (props: any) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const {state}: any = location

  const paymentIssuesTitle = {
    title:
      isAdmin() && location.pathname === '/payment-issues'
        ? 'Payment Issues Report'
        : 'Payment Issues',
    subTitle:
      isAdmin() && location.pathname === '/payment-issues'
        ? 'Payment Issues Report'
        : 'Payment Issues',
  }

  let paymentFiltered = retrieveFilter(filters.am_payment_issues)
  const userType = localStorage.getItem('user_type') || ''

  let currentWarehouseId = !['sa', 'ts'].includes(userType.toLowerCase())
    ? localStorage.getItem('w_id')
    : ''

  if (location.pathname === '/warehouse/DC/singlewarehousename/payment-issues') {
    currentWarehouseId = isEmpty(currentWarehouseId)
      ? localStorage.getItem('wId')
      : currentWarehouseId
  }
  const currentWarehouseName = !['sa', 'ts'].includes(userType.toLowerCase())
    ? localStorage.getItem('warehouse_name')
    : 'All Warehouses'
  const defaultWarehouse = {label: currentWarehouseName, value: currentWarehouseId}

  const initialState = {
    sales: paymentFiltered?.sales || {label: 'All Sales Reps', value: ''},
    warehouse: paymentFiltered?.warehouse || defaultWarehouse,
    status: paymentFiltered?.status || '',
    date: paymentFiltered?.date || '',
    search: paymentFiltered?.search || '',
    selected: paymentFiltered?.selected || '',
    currentOffset: paymentFiltered?.currentOffset || '',
    buttonDisable: true,
  }
  const [salesRepsOffSet, setSalesRepsOffSet] = useState<any>('')
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [paymentIssue, setPaymentIssue] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(paymentFiltered?.selected || 0)
  const [listLoader, setListLoader] = useState<any>(false)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [currentOffset, setCurrentOffset] = useState<any>(paymentFiltered?.currentOffset || '0')
  const [selectedSales, setSelectedSales] = useState<any>(
    paymentFiltered?.sales || {label: 'All Sales Reps', value: ''}
  )
  const [apiDate, setApiDate] = useState<any>({
    startDate: paymentFiltered?.date?.startDate || moment().format('YYYY-MM-DD'),
    endDate: paymentFiltered?.date?.endDate || moment().format('YYYY-MM-DD'),
  })
  const [selectedStatus, setSelectedStatus] = useState<any>(paymentFiltered?.status || 'unsettled')
  const [search, setSearch] = useState<any>(paymentFiltered?.search || '')
  const [warehouseOffSet, setWarehouseOffSet] = useState('')
  const [metaData, setMetaData] = useState<any>([])
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouse, setWarehouse] = useState<any>(defaultWarehouse || paymentFiltered?.warehouse)
  const [srReset, setSrReset] = useState<any>(moment())
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [isDateChange, setDateChange] = useState(
    paymentFiltered?.date?.startDate || paymentFiltered?.status === 'settled' ? true : false
  )
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    paymentFiltered?.buttonDisable || false
  )

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    fetchPaymentIssue()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffset'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.am_payment_issues, filterStates)

    localStorage.setItem('payment-issue-warehouse', warehouse.value)
    localStorage.setItem('payment-issue-start-date', isDateChange ? apiDate.startDate : '')
    localStorage.setItem('payment-issue-end-date', isDateChange ? apiDate.endDate : '')
    localStorage.setItem('payment-issue-sr-value', selectedSales.value)
    localStorage.setItem('payment-issue-sr-name', selectedSales.label)
    localStorage.setItem('payment-issue-status', selectedStatus)

    if (
      selectedSales.value == '' &&
      selectedStatus == 'unsettled' &&
      search == '' &&
      warehouse.value == currentWarehouseId &&
      apiDate.startDate == moment().format('YYYY-MM-DD').toString() &&
      apiDate.endDate == moment().format('YYYY-MM-DD').toString() &&
      isDateChange
    ) {
      setButtonDisable(false)
    }
  }, [
    currentOffset,
    selectedSales,
    selectedStatus,
    debounceSearch,
    warehouse,
    apiDate,
    isDateChange,
  ])

  useEffect(() => {
    setWarehouseOffSet('')
  }, [searchSelect])

  useEffect(() => {
    // fetchWarehouses('').then(() => {
    //   setWarehouseOffSet('')
    // })

    if (localStorage.getItem('SettlementMessage')) {
      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertSuccess(false)
        localStorage.removeItem('SettlementMessage')
      }, 2500)
    }
  }, [])

  useEffect(() => {
    localStorage.removeItem('reportBackNavigate')
  }, [])

  const fetchPaymentIssue = async () => {
    setListLoader(true)

    let dateValue = ''

    if (
      location.pathname === '/reports/payment-issues' ||
      location.pathname === '/payment-issues'
    ) {
      dateValue = `&date_after=${isDateChange ? apiDate.startDate : ''}&date_before=${
        isDateChange ? apiDate.endDate : ''
      }`
    }

    const API = `${apiBaseURL()}payment/issues?limit=${limit}&ordering=-updated_date&warehouse_id=${
      warehouse.value
    }&search=${debounceSearch}&offset=${currentOffset}&status=${selectedStatus}&sr_user_id=${
      isEmpty(selectedSales.value) ? '' : selectedSales.value
    }${dateValue}`
    const response = await getRequest(API, true)
    setPaymentIssue(response.results)
    setMetaData(response.meta)
    setCount(response.count)
    setListLoader(false)
  }

  const fetchSalesReps = (search?: any) => {
    return fetchAsyncDropdownNew(
      salesRepsOffSet,
      setSalesRepsOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `auth/users/drop-down?warehouse_id=${warehouse.value}`,
      search,
      setSearchSelect,
      'sr_user',
      '',
      true,
      'All Sales Reps'
    )
  }

  const fetchStatus = (search?: string) => {
    return fetchStaticAsyncDropdown(statusoptions, search)
  }

  const handleFilterChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)

    let updatedState: any = {...filterStates}

    switch (inputName) {
      case 'sales':
        updatedState['sales'] = e
        setSelectedSales(e)
        break
      case 'warehouse':
        updatedState['warehouse'] = e
        updatedState['sales'] = ''
        setWarehouse(e)
        setSelectedSales({label: 'All Sales Reps', value: ''})
        setSrReset(moment())
        setSalesRepsOffSet('')
        setSalesRepsOption([])
        break
      case 'status':
        updatedState['status'] = inputValue
        setSelectedStatus(inputValue)

        if (e.value === 'unsettled') {
          updatedState['date'] = ''

          setApiDate({
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
          })

          setDateChange(false)
        } else {
          updatedState['date'] = {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
          }

          setApiDate({
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
          })

          setDateChange(true)
        }

        break
      case 'date':
        updatedState['date'] = e
        break
      case 'search':
        updatedState['search'] = inputValue
        setSearch(inputValue)
        break
    }
    setFilterStates(updatedState)
  }
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=${currentWarehouseId}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0 && checkUserType(['sa'])) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleChangeReset = () => {
    setDateChange(false)
    removeFilter(filters.am_payment_issues)
    setSearch('')
    setSelectedSales({label: 'All Sales Reps', value: ''})
    setSelectedStatus('unsettled')
    setWarehouse(defaultWarehouse)
    setCurrentOffset(0)
    setSelected(0)
    // setFilterStates(initialState)
    setApiDate({
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    })
    let updatedState: any = {...filterStates}
    updatedState['status'] = 'unsettled'
    updatedState['warehouse'] = ''
    updatedState['sales'] = ''
    updatedState['date'] = ''
    updatedState['search'] = ''
    updatedState['selected'] = ''
    updatedState['currentOffset'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)

    localStorage.removeItem('payment-issue-warehouse')
    localStorage.removeItem('payment-issue-start-date')
    localStorage.removeItem('payment-issue-end-date')
    localStorage.removeItem('payment-issue-sr-value')
    localStorage.removeItem('payment-issue-sr-name')
    localStorage.removeItem('payment-issue-status')
  }

  const goToDetailPage = async (issue: any) => {
    localStorage.setItem('paymentIssueId', issue.id)
    saveFilter(filters.am_payment_issues, filterStates)

    if (isAdmin()) {
      if (location.pathname === '/reports/payment-issues') {
        localStorage.setItem('reportBackNavigate', 'payment-issue-report')
        navigate('payment-issue-detail/transactions')
      } else {
        navigate('/payment-issues/payment-issue-detail/transactions')
      }
    } else {
      navigate('payment-issue-detail/transactions')
    }
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: isDateChange && moment(startDate).format(globalDateFormate()),
            endDate: isDateChange && moment(endDate).format(globalDateFormate()),
            autoUpdateInput: isDateChange,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={isDateChange ? value : 'Select Date'}
            placeholder='Select Date'
            disabled={listLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle
        title={
          isAdmin() && location.pathname === '/payment-issues'
            ? 'Payment Issues Report'
            : 'Payment Issues'
        }
      />
      <PageTitle breadcrumbs={[paymentIssuesTitle]}>
        {intl.formatMessage({
          id:
            isAdmin() && location.pathname === '/payment-issues'
              ? 'Payment Issues Report'
              : 'Payment Issues',
        })}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={FormatMessage('PAYMENT_SETTLEMENT')}
      />
      {listLoader && <IsLoader />}
      <>
        <div className='row g-3 g-xl-5'>
          {location.pathname.includes('payment-issues') && checkUserType(['am', 'wm']) && (
            <div className='col-sm-6 col-md-6 col-lg col-xl'>
              <EaStatisticsWidget
                className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                color='success'
                title={
                  (metaData && metaData.received_payment_issue_count
                    ? numberFormat(metaData.received_payment_issue_count).displayFormat
                    : 0) +
                  ' ' +
                  'Payment Issues'
                }
                path='/'
                description='Settled Payment Issues Count'
              />
            </div>
          )}
          <div className='col-sm-6 col-md-6 col-lg col-xl'>
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='dark'
              title={
                (metaData && metaData.payment_issue_count
                  ? numberFormat(metaData.payment_issue_count).displayFormat
                  : 0) +
                ' ' +
                'Payment Issues'
              }
              path='/'
              description='Unsettled Payment Issues Count'
            />
          </div>

          {location.pathname.includes('payment-issues') && checkUserType(['am', 'wm']) && (
            <>
              <div className='col-sm-6 col-md-6 col-lg col-xl'>
                <EaStatisticsWidget
                  className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                  color='info'
                  title={
                    metaData && metaData.receivable_payment_issues_amount
                      ? numberFormatWithCode(metaData.receivable_payment_issues_amount)
                          .displayFormat
                      : numberFormatWithCode(0).displayFormat
                  }
                  path='/'
                  description='Total Receivable Payment Issues Amount'
                />
              </div>
              <div className='col-sm-6 col-md-6 col-lg col-xl'>
                <EaStatisticsWidget
                  className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                  color='success'
                  title={
                    metaData && metaData.received_payment_issue_amount
                      ? numberFormatWithCode(metaData.received_payment_issue_amount).displayFormat
                      : numberFormatWithCode(0).displayFormat
                  }
                  path='/'
                  description='Total Settled Payment Issue Amount'
                />
              </div>
            </>
          )}

          <div className='col-sm-6 col-md-6 col-lg col-xl'>
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='warning'
              title={
                `${tenantConfiguration.currencyCode}` +
                ' ' +
                `${
                  numberFormat(
                    metaData && metaData.unsettled_payment_issue_amount
                      ? metaData.unsettled_payment_issue_amount
                      : 0
                  ).displayFormat
                }`
              }
              description='Unsettled Payment Issues Amount'
              path='/'
            />
          </div>
        </div>
        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={handleFilterChange} value={search} isDisable={listLoader} />
            <div className='ms-auto'>
              <div className='card-toolbar my-0'>
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setApiDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })

                    setDateChange(true)
                    const newDate = {
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    }
                    handleFilterChange(newDate, 'date')
                  }}
                  value={apiDate}
                  startDate={apiDate.startDate}
                  endDate={apiDate.endDate}
                />

                {isDateChange && selectedStatus === 'unsettled' && (
                  <div
                    className='me-3 cursor-pointer'
                    onClick={() => {
                      let updatedState: any = {...filterStates}
                      updatedState['date'] = ''
                      setFilterStates(updatedState)

                      setApiDate({
                        startDate: moment().format('YYYY-MM-DD'),
                        endDate: moment().format('YYYY-MM-DD'),
                      })
                      setDateChange(false)
                    }}
                  >
                    <KTSVG path={'/media/icons/duotune/abstract/abs012.svg'} className='ic me-0' />
                  </div>
                )}

                {checkUserType(['wm', 'sa']) &&
                  location.pathname !== '/warehouse/DC/singlewarehousename/payment-issues' && (
                    <AsyncPaginate
                      loadOptions={fetchWarehouses}
                      onChange={(e) => handleFilterChange(e, 'warehouse')}
                      isSearchable
                      isDisabled={listLoader}
                      value={warehouse}
                      className='react-select-container my-1 me-3 w-160px'
                      classNamePrefix='react-select'
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  )}
                <AsyncPaginate
                  key={srReset}
                  loadOptions={fetchSalesReps}
                  isSearchable
                  isDisabled={listLoader}
                  placeholder='All Sales Reps'
                  className='react-select-container my-1 me-3 w-140px'
                  classNamePrefix='react-select'
                  name='sales'
                  value={selectedSales || ''}
                  onChange={(e: any) => handleFilterChange(e, 'sales')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  isDisabled={listLoader}
                  placeholder='All Status'
                  className='react-select-container my-1 me-3 w-110px'
                  classNamePrefix='react-select'
                  name='status'
                  value={statusoptions.find((option: any) => option.value == selectedStatus)}
                  onChange={(e: any) => {
                    handleFilterChange(e, 'status')
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {paymentIssue.length == 0 ? (
                <NoRecords />
              ) : (
                <>
                  <div
                    className='table-responsive expandable'
                    style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
                    id='expandTablePayment'
                  >
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='text-muted fw-500'>
                          <th className='min-w-150px'>SR Name</th>
                          <th className='min-w-150px'>Date</th>
                          <th className='min-w-140px'>Total Receivable</th>
                          <th className='min-w-120px'>Total Received</th>
                          <th className='min-w-120px'>Unsettled Amount</th>
                          <th className=''>Status</th>
                          <th className='min-w-20px'></th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {paymentIssue.map((issue: any) => {
                          return (
                            <>
                              <tr className='cursor-pointer' onClick={() => goToDetailPage(issue)}>
                                <td>{issue && issue.sr_user}</td>
                                <td>
                                  {issue && issue.date ? dateFormat(issue.date).displayDate : '-'}
                                </td>
                                <td>
                                  {issue && issue.receivable_amount
                                    ? `${tenantConfiguration.currencyCode}` +
                                      ' ' +
                                      `${numberFormat(issue.receivable_amount).displayFormat}`
                                    : '-'}
                                </td>
                                <td>
                                  {issue && issue.received_amount
                                    ? `${tenantConfiguration.currencyCode}` +
                                      ' ' +
                                      `${numberFormat(issue.received_amount).displayFormat}`
                                    : '-'}
                                </td>
                                <td>
                                  {issue && issue.diff_amount
                                    ? `${tenantConfiguration.currencyCode}` +
                                      ' ' +
                                      `${numberFormat(issue.diff_amount).displayFormat}`
                                    : '-'}
                                </td>
                                {issue.status == 'unsettled' ? (
                                  <>
                                    <td>
                                      <span className='badge badge-light-danger text-dark'>
                                        Unsettled
                                      </span>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <span className='badge badge-light-success text-dark'>
                                        Settled
                                      </span>
                                    </td>
                                  </>
                                )}
                                <td
                                  onClick={(e) => e.stopPropagation()}
                                  data-bs-toggle='collapse'
                                  data-bs-target={`.row-collapse${issue.id}`}
                                  aria-controls={`.rowCollapse${issue.id}`}
                                  className='cursor-pointer'
                                ></td>
                              </tr>
                              <tr className='expandable-row'>
                                <td className='reset-expansion-style' colSpan={11}>
                                  <div
                                    className={`row-expansion-style collapse row-collapse${issue.id} collapsible`}
                                    id={`rowCollapse${issue.id}`}
                                    data-bs-parent='#expandTablePayment'
                                  >
                                    <div className='p-2'>
                                      {!issue.transaction_details ||
                                      issue.transaction_details.length === 0 ? (
                                        <NoRecords />
                                      ) : (
                                        <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                          <thead>
                                            <tr className='text-muted fw-500'>
                                              <th className='min-w-120px py-2'>Payment Type</th>
                                              <th className='min-w-120px py-2'>Reference number</th>
                                              <th className='min-w-120px py-2'>Amount</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {issue?.transaction_details &&
                                              issue?.transaction_details.length > 0 &&
                                              issue?.transaction_details.map((item: any) => {
                                                return (
                                                  <tr className='cursor-pointer'>
                                                    <td>
                                                      {isEmpty(item.payment_mode)
                                                        ? '-'
                                                        : item.payment_mode}
                                                    </td>
                                                    <td>
                                                      {isEmpty(item.ref_no) ? '-' : item.ref_no}
                                                    </td>
                                                    <td>
                                                      {isEmpty(item.receivable_amount)
                                                        ? '-'
                                                        : numberFormat(item.receivable_amount)
                                                            .displayFormat}
                                                    </td>
                                                  </tr>
                                                )
                                              })}
                                          </tbody>
                                        </table>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                </>
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={paymentIssue}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={listLoader}
          saveFilterName={filters.am_payment_issues}
          filterStates={filterStates}
        />
      </>
    </>
  )
}

export default PaymentIssues
