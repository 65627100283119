import moment from "moment"
import React from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"

const TSCreditIssues = () => {
    return (
        <>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>

                    <div>
                        <input type="text" className='form-control form-search' placeholder='Search' />
                    </div>
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: '01/01/2020',
                                        endDate: '01/12/2020',
                                        alwaysShowCalendars: true,
                                        cancelClass: 'btn-secondary',
                                        drops: "auto",
                                        locale: {
                                            format: 'DD/MM/YYYY'
                                        },
                                        ranges: {
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                        },
                                    }}
                            >
                                <input type="text" className="form-control calendar" />
                            </DateRangePicker>
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-140px'>Invoice ID</th>
                                    <th className='min-w-140px'>Customer Name</th>
                                    <th className='min-w-120px'>Credit Issue Date</th>
                                    <th className='min-w-120px'>Resolved On</th>
                                    <th className='min-w-120px'>Sales Representative</th>
                                    <th className='min-w-120px'>Credit Issue Amt.</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>
                                    <td>
                                        #84419
                                    </td>
                                    <td>
                                        Augusta Day
                                    </td>
                                    <td>
                                        08 Apr 2022
                                    </td>
                                    <td>
                                        12 Jul 2022
                                    </td>
                                    <td>
                                        Yamaha Toshinobu
                                    </td>
                                    <td>
                                        TZS 893
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        #81045
                                    </td>
                                    <td>
                                        Stanley Blake
                                    </td>
                                    <td>
                                        04 Aug 2022
                                    </td>
                                    <td>
                                        —
                                    </td>
                                    <td>
                                        Nguyễn Nguyên Khang
                                    </td>
                                    <td>
                                        TZS 910
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        #84419
                                    </td>
                                    <td>
                                        Augusta Day
                                    </td>
                                    <td>
                                        08 Apr 2022
                                    </td>
                                    <td>
                                        12 Jul 2022
                                    </td>
                                    <td>
                                        Yamaha Toshinobu
                                    </td>
                                    <td>
                                        TZS 893
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        #81045
                                    </td>
                                    <td>
                                        Stanley Blake
                                    </td>
                                    <td>
                                        04 Aug 2022
                                    </td>
                                    <td>
                                        —
                                    </td>
                                    <td>
                                        Nguyễn Nguyên Khang
                                    </td>
                                    <td>
                                        TZS 910
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        #84419
                                    </td>
                                    <td>
                                        Augusta Day
                                    </td>
                                    <td>
                                        08 Apr 2022
                                    </td>
                                    <td>
                                        12 Jul 2022
                                    </td>
                                    <td>
                                        Yamaha Toshinobu
                                    </td>
                                    <td>
                                        TZS 893
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        #81045
                                    </td>
                                    <td>
                                        Stanley Blake
                                    </td>
                                    <td>
                                        04 Aug 2022
                                    </td>
                                    <td>
                                        —
                                    </td>
                                    <td>
                                        Nguyễn Nguyên Khang
                                    </td>
                                    <td>
                                        TZS 910
                                    </td>
                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className='table-info py-5'>
                <div className='row g-0'>
                    <div className='col'>
                        Showing 1 to 10 of entries
                    </div>
                    <div className='col'>
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TSCreditIssues
