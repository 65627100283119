import React from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import Messages from './components/messages'
import NewMessageHeader from './components/newMessageHeader'
import Overview from './components/overview'

const NewMessgaesDetail: React.FC = () => {
  const intl = useIntl()
  const supportReqTitle = {
    title: 'Complaints',
    subTitle: 'New Messages',
  }

  return (
    <>
      <PageTitle breadcrumbs={[supportReqTitle]}>
        {intl.formatMessage({id: 'New Messages'})}
      </PageTitle>
      <NewMessageHeader />
      <Routes>
        <Route path='overview' element={<Overview />} />
        <Route path='messages' element={<Messages />} />
        <Route index element={<Navigate to='overview' />} />
      </Routes>
    </>
  )
}

export default NewMessgaesDetail
