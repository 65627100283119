import {
  apiBaseURL,
  checkEmpty,
  isEmpty,
  toAbsoluteUrl,
} from '../../../../../../_eaFruitsDms/helpers'
import React, {useEffect, useState} from 'react'
import NoRecords from '../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import IsLoader from '../../../../../../_eaFruitsDms/layout/components/loader/loader'
import {getRequest, putRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import tenantConfiguration from '../../../../../../TenantVariables'
import {Modal} from 'react-bootstrap'
import Imagecropper from '../../../../brands/components/imageCroper'
import MobileNumberInput from '../../../../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput'
import FormatMessage from '../../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {EaAlertSuccess} from '../../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import {useLocation, useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import EATitle from '../../../../../../_eaFruitsDms/layout/components/title/title'

const BillingInfo = (props: any) => {
  const wId = localStorage.getItem('wId')
  const navigate = useNavigate()
  const location = useLocation()
  const state: any = location.state

  const initBillingInfo = {
    id: '',
    business_name: '',
    full_address: '',
    primary_contact: '',
    secondary_contact: '',
    support_contact: '',
    website_url: '',
    warehouse: wId,
    business_logo: '',
  }
  const initBillingInfoValidation = {
    business_name: '',
    full_address: '',
    primary_contact: '',
    support_contact: '',
    secondary_contact: '',
    website_url: '',
    business_logo: '',
  }
  const initialImage = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const isAdded = localStorage.getItem('billing-add')

  const [billingInfo, setBillingInfo] = useState<any>(initBillingInfo)
  const [billingInfoDetail, setBillingInfoDetail] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showBillingInfo, setShowBillingInfo] = useState(false)
  const [taxLabels, setTaxLabels] = useState<any>([])
  const [taxInfo, setTaxInfo] = useState<any>([])
  const [taxLabelLoading, setTaxLabelLoading] = useState<boolean>(false)
  const [billingButtonText, setBillingButtonText] = useState<string>('Submit')
  const [cropData, setCropData] = useState(initialImage)
  const [billingButtonDisabled, setBillingButtonDisabled] = useState<boolean>(false)
  const [imgSizeInvalid, setImgSizeInvalid] = useState('')
  const [billingInfoValidation, setBillingInfoValidation] = useState(initBillingInfoValidation)
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [messageHandler, setMessageHandler] = useState('')

  useEffect(() => {
    console.log('billingInfo================', billingInfo)
  }, [billingInfo])

  useEffect(() => {
    fetchBillingInfo().then()
    fetchTaxLabels().then()

    if (isAdded) {
      setMessageHandler('add')
      localStorage.removeItem('billing-add')
      setIsAlertSuccess(true)

      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)
    }
  }, [state])

  const handleCloseBillingInfo = () => {
    setBillingInfoDetail(initBillingInfo)
    setTaxInfo([])
    setBillingButtonText('Submit')
    setCropData(initialImage)
    setShowBillingInfo(false)
  }

  const fetchTaxLabels = async () => {
    setTaxLabelLoading(true)
    const response = await getRequest(`${apiBaseURL()}master/tax-labels`, true)
    setTaxLabelLoading(false)

    if (response) {
      setTaxLabels(response.results)
    }
  }

  const fetchBillingInfo = async () => {
    setIsLoading(true)
    const res = await getRequest(`${apiBaseURL()}warehouse/warehouse-billing/${wId}`, true)

    setIsLoading(false)
    if (!res.error) {
      setBillingInfo(res)
      setCropData(res.business_logo)

      const taxes: any = []

      if (res.warehouse_billing_info_details.length > 0) {
        res.warehouse_billing_info_details.map((tax: any) => {
          taxes.push({
            tax_label_id: tax.tax_label_info,
            tax_number: tax.tax_label_number,
            tax_id: tax.id,
          })
        })
      }

      setTaxInfo(taxes)
      if (isEmpty(res.secondary_contact)) {
        res.secondary_contact = ''
      }
      setBillingInfoDetail(res)
    }
  }

  const onImageChange = (e: any) => {
    if (e.target.files[0]) {
      const [file] = e.target.files

      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setBillingInfoDetail({...billingInfoDetail, ['business_logo']: e.target.files[0]})
      }
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        let objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  const getImage = (val: any) => {
    setBillingInfo({...billingInfo, ['business_logo']: val})
  }

  const billingHandleChange = (e: any) => {
    const inputValue = e.target.value

    const updatedBillingInfo = {...billingInfoDetail}
    const re = /^[0-9\b]+$/

    setBillingInfoValidation({...billingInfoValidation, [e.target.name]: ''})
    setBillingButtonDisabled(false)

    switch (e.target.name) {
      case 'business_name':
        updatedBillingInfo.business_name = inputValue
        break
      case 'full_address':
        updatedBillingInfo.full_address = inputValue
        break
      case 'support_contact':
        if (inputValue !== '' && !re.test(inputValue)) {
          setBillingInfoValidation({...billingInfoValidation, ['support_contact']: 'is-invalid'})
          setBillingButtonDisabled(true)
        } else {
          setBillingInfoValidation({...billingInfoValidation, ['support_contact']: ''})
          setBillingButtonDisabled(false)
          updatedBillingInfo.support_contact = inputValue
        }
        break
      case 'primary_contact':
        if (inputValue !== '' && !re.test(inputValue)) {
          setBillingInfoValidation({...billingInfoValidation, ['primary_contact']: 'is-invalid'})
          setBillingButtonDisabled(true)
        } else {
          setBillingInfoValidation({...billingInfoValidation, ['primary_contact']: ''})
          setBillingButtonDisabled(false)
          updatedBillingInfo.primary_contact = inputValue
        }
        break
      case 'secondary_contact':
        if (inputValue !== '' && !re.test(inputValue)) {
          setBillingInfoValidation({...billingInfoValidation, ['secondary_contact']: 'is-invalid'})
          setBillingButtonDisabled(true)
        } else {
          setBillingInfoValidation({...billingInfoValidation, ['secondary_contact']: ''})
          setBillingButtonDisabled(false)
          updatedBillingInfo.secondary_contact = inputValue
        }
        break
      case 'website_url':
        let urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/[\w.-]*)*\/?$/i
        const validURL = urlPattern.test(inputValue)
        if (!validURL) {
          setBillingInfoValidation({...billingInfoValidation, ['website_url']: 'is-invalid'})
          setBillingButtonDisabled(true)
        } else {
          setBillingInfoValidation({...billingInfoValidation, ['website_url']: ''})
          setBillingButtonDisabled(false)
        }
        updatedBillingInfo.website_url = inputValue
        break
    }

    setBillingInfoDetail(updatedBillingInfo)
  }

  const handleTaxChange = (e: any, id: number, index: number) => {
    let updatedTaxInfo: any = [...taxInfo]
    const value = e.target.value

    if (updatedTaxInfo[index]) {
      updatedTaxInfo[index]['tax_label_id'] = id
      updatedTaxInfo[index]['tax_number'] = value
    } else {
      updatedTaxInfo.push({
        tax_label_id: id,
        tax_number: value,
      })
    }

    setTaxInfo(updatedTaxInfo)
  }

  const isValdBillingEntries = () => {
    let isValid: boolean = true

    const updatedValidation = {...billingInfoValidation}

    if (isEmpty(billingInfoDetail.business_name)) {
      isValid = false
      updatedValidation['business_name'] = 'is-invalid'
    }

    if (isEmpty(billingInfoDetail.full_address)) {
      isValid = false
      updatedValidation['full_address'] = 'is-invalid'
    }

    if (isEmpty(billingInfoDetail.primary_contact)) {
      isValid = false
      updatedValidation['primary_contact'] = 'is-invalid'
    }

    if (isEmpty(billingInfoDetail.support_contact)) {
      isValid = false
      updatedValidation['support_contact'] = 'is-invalid'
    }

    if (isEmpty(billingInfoDetail.website_url)) {
      isValid = false
      updatedValidation['website_url'] = 'is-invalid'
    }

    if (isEmpty(billingInfoDetail.business_logo)) {
      isValid = false
      setImgSizeInvalid('is-invalid')
      updatedValidation['business_logo'] = 'is-invalid'
    }

    setBillingInfoValidation(updatedValidation)

    setBillingButtonDisabled(true)

    if (isValid) {
      setBillingButtonDisabled(false)
    }

    return isValid
  }

  const saveBillingInfo = async () => {
    if (isValdBillingEntries()) {
      const billingFormData: any = new FormData()

      setBillingButtonText('Please wait...')
      const billingId = billingInfoDetail.id

      delete billingInfoDetail.id
      delete billingInfoDetail.warehouse_billing_info_details

      if (typeof billingInfoDetail.business_logo === 'string') {
        delete billingInfoDetail.business_logo
      }

      Object.entries(billingInfoDetail).map((object: any) => {
        billingFormData.append(object[0], object[1])
      })

      if (taxInfo.length > 0) {
        taxInfo.map((tax: any, index: number) => {
          billingFormData.append(`warehouse_billing_info_details[${index}]id`, tax.tax_id)
          billingFormData.append(
            `warehouse_billing_info_details[${index}]tax_label_info`,
            typeof tax.tax_label_id === 'object' ? tax.tax_label_id.id : tax.tax_label_id
          )
          billingFormData.append(
            `warehouse_billing_info_details[${index}]tax_label_number`,
            isEmpty(tax.tax_number) ? '' : tax.tax_number
          )
          billingFormData.append(`warehouse_billing_info_details[${index}]warehouse`, wId) //TODO: Himalaya need this key as he said that he can not able to retrieve from warehouse key
        })
      }

      await putRequest(
        `${apiBaseURL()}warehouse/warehouse-billing/${billingId}`,
        billingFormData,
        true
      )
      await fetchBillingInfo()

      handleCloseBillingInfo()

      setMessageHandler('edit')
      setIsAlertSuccess(true)

      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)
    }
  }

  const updateBilling = async () => {
    await fetchTaxLabels()
    await fetchBillingInfo()
    setShowBillingInfo(true)
  }

  const isAlertSHow = (val: any) => {
    if (val === 'success') {
      setIsAlertSuccess(false)
    }
  }

  return (
    <>
      <EATitle title='Billing Info' />
      <div className='card mb-5 mb-xl-10'>
        {isLoading && <IsLoader />}
        <div className='card-body py-5'>
          <EaAlertSuccess
            onClick={() => isAlertSHow('success')}
            show={isAlertSuccess && messageHandler !== ''}
            message={
              messageHandler === 'add'
                ? FormatMessage('BILLING_ADDED')
                : FormatMessage('BILLING_UPDATED')
            }
          />
          {isEmpty(billingInfo.business_name) ? (
            <NoRecords />
          ) : (
            <>
              <div className='mb-6'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px me-5'>
                    <img
                      src={billingInfo.business_logo}
                      onError={({currentTarget}) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = toAbsoluteUrl('/media/avatars/300-23.jpg')
                      }}
                      alt=''
                    />
                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <label className='font-14 text-muted mb-2'>Registered Business Name</label>
                    <div className='font-14 text-dark'>
                      {checkEmpty(billingInfo.business_name, '-')}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='font-14 text-muted mb-2'>Business Full Address</label>
                <div className='font-14 text-dark'>{checkEmpty(billingInfo.full_address, '-')}</div>
              </div>
              <div className='row'>
                <div className='col-md-6 col-lg-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Primary Contact No.</label>
                  <div className='font-14 text-dark'>
                    {tenantConfiguration.baseCountryCode}{' '}
                    {checkEmpty(billingInfo.primary_contact, '-')}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Secondary Contact No.</label>
                  <div className='font-14 text-dark'>
                    {!isEmpty(billingInfo.secondary_contact)
                      ? tenantConfiguration.baseCountryCode + ' ' + billingInfo.secondary_contact
                      : '-'}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 col-lg-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Support Contact No.</label>
                  <div className='font-14 text-dark'>
                    {tenantConfiguration.baseCountryCode}{' '}
                    {checkEmpty(billingInfo.support_contact, '-')}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Website Link</label>
                  <div className='font-14 text-dark'>
                    {/*<a href="" target="_blank"}>{billingInfo.website_url}</a>*/}
                    {billingInfo.website_url !== '' ? (
                      <a href={billingInfo.website_url} rel='noreferrer' target='_blank'>
                        {billingInfo.website_url}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>
              <h3 className='border-bottom font-14 fw-500 pb-2'>Taxation Number(s)</h3>
              <div className='row'>
                {taxLabels.length === 0 ? (
                  <NoRecords />
                ) : (
                  taxLabels.map((tax: any, index: number) => {
                    const findIndex: number =
                      billingInfo &&
                      billingInfo.warehouse_billing_info_details &&
                      billingInfo.warehouse_billing_info_details
                        ? billingInfo.warehouse_billing_info_details.findIndex(
                            (option: any) => option.tax_label_info.id === tax.id
                          )
                        : -1

                    return (
                      <div className='col-md-6 col-lg-3 mb-6'>
                        <label className='font-14 text-muted mb-2'>
                          {findIndex > -1
                            ? billingInfo.warehouse_billing_info_details[
                                findIndex
                              ].tax_label_info.name.toUpperCase()
                            : '-'}
                        </label>
                        <div className='font-14 text-dark'>
                          {findIndex > -1
                            ? checkEmpty(
                                billingInfo.warehouse_billing_info_details[findIndex]
                                  .tax_label_number,
                                '-'
                              )
                            : '-'}
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
              <div className='row'>
                <div className='col-12'>
                  <button className='btn btn-primary' onClick={updateBilling}>
                    Update Billing Info
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Update billing info */}
      <Modal show={showBillingInfo} onHide={handleCloseBillingInfo} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Billing Info</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          <div className='mb-6'>
            <Imagecropper
              text='Max. File Size: 5MB & 500px × 500px'
              isUpload={true}
              title='business_logo'
              getImage={getImage}
              onImageChange={onImageChange}
              imageSize={imgSizeInvalid}
              cropData={cropData}
              setCrop={setCropData}
            />
          </div>
          <div className='mb-6'>
            <label className='form-label'>
              Registered Business Name<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              value={billingInfoDetail && billingInfoDetail.business_name}
              className={clsx('form-control', billingInfoValidation.business_name)}
              name='business_name'
              onChange={billingHandleChange}
              placeholder='Type here...'
            />
          </div>
          <div className='mb-6'>
            <label className='form-label'>
              Warehouse Address<span className='text-danger'>*</span>
            </label>
            <textarea
              className='form-control'
              rows={3}
              onChange={billingHandleChange}
              placeholder='Type here…'
              name='full_address'
              value={billingInfoDetail && billingInfoDetail.full_address}
            ></textarea>
          </div>
          <div className='mb-6'>
            <MobileNumberInput
              label='Primary Contact No.'
              validation={billingInfoValidation.primary_contact}
              onChange={billingHandleChange}
              placeholder='Enter primary contact'
              numberValue={billingInfoDetail && billingInfoDetail.primary_contact}
              name='primary_contact'
            />
          </div>
          <div className='mb-6'>
            <MobileNumberInput
              label='Secondary Contact No.'
              isOptional={true}
              validation={billingInfoValidation.secondary_contact}
              onChange={billingHandleChange}
              placeholder='Enter secondary contact'
              numberValue={billingInfoDetail && billingInfoDetail.secondary_contact}
              name='secondary_contact'
            />
          </div>
          <div className='mb-6'>
            <MobileNumberInput
              label='Support Contact No.'
              validation={billingInfoValidation.support_contact}
              onChange={billingHandleChange}
              placeholder='Enter support contact'
              numberValue={billingInfoDetail && billingInfoDetail.support_contact}
              name='support_contact'
            />
          </div>
          <div className='mb-6'>
            <label className='form-label'>
              Website Link<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              value={billingInfoDetail && billingInfoDetail.website_url}
              name='website_url'
              className='form-control'
              onChange={billingHandleChange}
              placeholder='Type here...'
            />
          </div>
          <div className='mb-6'>
            <div className='border-bottom form-label mb-2 font-bold'>Taxation Number(s)</div>
            {taxLabelLoading && <IsLoader />}
            {taxLabels.length === 0 ? (
              <NoRecords />
            ) : (
              taxLabels.map((taxLabel: any, index: number) => {
                return (
                  <div className='mb-6'>
                    <label className='form-label'>{taxLabel.name}</label>
                    <input
                      type='text'
                      name='tax'
                      className='form-control'
                      placeholder='Type here...'
                      onChange={(e: any) => handleTaxChange(e, taxLabel.id, index)}
                      value={taxInfo[index] && taxInfo[index][`tax_number`]}
                    />
                  </div>
                )
              })
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-light' onClick={handleCloseBillingInfo}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={saveBillingInfo}
            disabled={billingButtonText !== 'Submit' || billingButtonDisabled}
          >
            {billingButtonText}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BillingInfo
