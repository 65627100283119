import React from "react"
import { useIntl } from "react-intl"
import { KTSVG } from "../../../../../_eaFruitsDms/helpers"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

type Props = {
    className?: string
}
const CustomerReturnDetail: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const WMHeaderTitle = {
        title: "Customers",
        subTitle: "Returns"
    }
    return (
        <>
            <PageTitle breadcrumbs={[WMHeaderTitle]} >{intl.formatMessage({ id: 'Customers' })}</PageTitle>

            <div className='card mb-4 mb-xl-5'>
                <div className='card-body pt-5 pb-0'>
                    <div className='d-flex flex-wrap flex-sm-nowrap'>
                        <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between align-items-start flex-wrap'>
                                <div className='d-flex flex-column mb-2 mb-sm-0'>
                                    <div className='mb-1'>
                                        <span className='text-dark font-20 fw-bold me-1'>
                                            #84454
                                        </span>
                                    </div>
                                    <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                                        <span className='d-flex align-items-center me-6 mb-2'>
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen014.svg'
                                                className='me-1'
                                            />
                                            29 Mar 2022
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card mb-5 mb-xl-10' >
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <div className="card-title my-0">
                        <span className='fw-bold font-17 mb-1'>
                            Credit Note Details
                        </span>

                    </div>
                </div>
                <div className='card-body pt-2 mt-1 pb-5'>
                    <div className='row'>
                        <div className="col-sm-6 col-xl-3 mb-6">
                            <label className='font-14 text-muted mb-2'>Credit Note ID</label>
                            <div>
                                <span className='font-14 text-dark'>#84454</span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3 mb-6">
                            <label className='font-14 text-muted mb-2'>Created On</label>
                            <div>
                                <span className='font-14 text-dark'>30 May 2022</span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3 mb-6">
                            <label className='font-14 text-muted mb-2'>SR Name</label>
                            <div>
                                <span className='font-14 text-dark'>Aryn Jacobssen</span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-12 col-xl-3 mb-6">
                            <label className='font-14 text-muted mb-2'>Credit Note Amount</label>
                            <div>
                                <span className='font-14 text-dark'>TZS 1000.00</span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3 mb-6">
                            <label className='font-14 text-muted mb-2'>Returned Items</label>
                            <div>
                                <span className='font-14 text-dark'>2 Items</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card mb-5 mb-xl-10' >
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <div className="card-title my-0">
                        <span className='fw-bold font-17 mb-1'>
                            Items Returned
                        </span>

                    </div>
                </div>
                <div className='card-body pt-2 mt-1 pb-5'>
                    <div className="row">
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-100px py-2'>Product SKU</th>
                                        <th className='min-w-100px py-2'>Price/Unit</th>
                                        <th className='min-w-100px py-2'>Qty.</th>
                                        <th className='min-w-120px py-2'>Total Amt.</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <td>Onja Rice 5kg</td>
                                        <td>TZS 25.00</td>
                                        <td>10</td>
                                        <td>TZS 250.00</td>
                                    </tr>
                                    <tr>
                                        <td>Onja Rice 5kg</td>
                                        <td>TZS 25.00</td>
                                        <td>10</td>
                                        <td>TZS 250.00</td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                                <tfoot className='border-bottom'>
                                    <tr className='bg-light2 font-medium border-top border-bottom' >
                                        <td colSpan={3} className='text-center'>Total Payable</td>
                                        <td>TZS 1350.00</td>
                                    </tr>
                                </tfoot>
                            </table>

                            {/* end::Table */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerReturnDetail
