import React, {useEffect, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {apiBaseURL, KTSVG} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {
  OrderingArrowDown,
  OrderingArrowUp,
} from '../../../_eaFruitsDms/layout/components/orderingArrow/orderingArrow'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import SkuThumbnail from '../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {categoryStyle} from '../../../_eaFruitsDms/helpers/components/categoryStyle'
import tenantConfiguration from '../../../TenantVariables'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'

type Props = {
  className: string
}
const WMProductSkuCatalog: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Product SKUs',
  }
  const initialOrderColumn = {
    ProductSKU: true,
    Brand: true,
    Category: true,
    ProductGroup: true,
    Variant: true,
    Packaging: true,
  }

  const [getList, setGetList] = useState<any>([]) // getdata
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [brands, setBrands] = useState<any>([])
  const [categories, setCategories] = useState<any>([])
  const [warehouses, setWarehouses] = useState<any>([])
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [selected, setSelected] = useState(0)
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [brandValue, setBrandValue] = useState<any>('')
  const [categoryValue, setCategoryValue] = useState<any>('')
  const [groupsValue, setGroupsValue] = useState<any>('')
  const [productGroups, setProductGroups] = useState<any>([])
  const [warehouseId, setWarehouseId] = useState(localStorage.getItem('w_id'))
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [brandOffSet, setBrandOffSet] = useState('')
  const [warehouseOffSet, setWarehouseOffSet] = useState('')
  const [categoryOffSet, setCategoryOffSet] = useState('')
  const [groupOffSet, setGroupOffSet] = useState('')
  const [searchSelect, setSearchSelect] = useState('')
  const [orderingColumn, setOrderingColumn] = useState(initialOrderColumn)
  const [isOrdering, setIsOrdering] = useState(false)
  const [ordering, setOrdering] = useState('')
  const [orderColumnKey, setOrderColumnKey] = useState('')

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    getProducts().then()
  }, [currentOffset, warehouseId, brandValue, categoryValue, groupsValue, debounceSearch, ordering])

  useEffect(() => {
    fetchWarehouses('').then(() => {
      setWarehouseOffSet('')
    })
  }, [])

  useEffect(() => {
    setBrandOffSet('')
    setWarehouseOffSet('')
    setCategoryOffSet('')
    setGroupOffSet('')
  }, [searchSelect])

  const getProducts = async () => {
    let url = `?ordering=${ordering}&limit=${limit}&warehouse_id=${warehouseId}&offset=${currentOffset}&search=${debounceSearch}&brand_id__id=${brandValue}&category_id__id=${categoryValue}&variants__group_name__id=${groupsValue}&remove_product_stocking=true`
    setListLoader(true)
    const res = await getRequest(`${apiBaseURL()}inventory/product-SKUs` + url, true)

    setGetList(res.results)
    // setGetSize(res.data)
    setCount(res.count)
    setNext(res.next)
    setPrev(res.prev)
    if (res.results.length == 0) {
      setLength('2')
    } else {
      setLength('1')
    }
    setListLoader(false)
  }

  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?parent_warehouse__id=${localStorage.getItem(
        'w_id'
      )}&search=${search}&ordering=name&limit=${limit}&offset=${warehouseOffSet}&is_parent=True`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }
    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchBrands = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    const brandResponse = await getRequest(
      `${apiBaseURL()}master/product/brand?ordering=name&search=${search}&limit=${limit}&offset=${brandOffSet}`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (brandResponse.next !== null) {
      const queryParams = new URLSearchParams(brandResponse.next)
      let newOffset: any = queryParams.get('offset')
      setBrandOffSet(newOffset)
      hasMore = true
    }

    if (brands.length == 0 || !brands.filter((option: any) => option.value === '')) {
      options.push({
        label: 'All Brands',
        value: '',
      })
    }

    if (brandResponse.results.length > 0) {
      brandResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (brands.length > 0) {
      setBrands(brands.concat(options))
    } else {
      setBrands(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchProductGroups = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    const groupResponse = await getRequest(
      `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${limit}&offset=${groupOffSet}`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (groupResponse.next !== null) {
      const queryParams = new URLSearchParams(groupResponse.next)
      let newOffset: any = queryParams.get('offset')
      setGroupOffSet(newOffset)
      hasMore = true
    }

    if (productGroups.length == 0) {
      options.push({
        label: 'All Groups',
        value: '',
      })
    }

    if (groupResponse.results.length > 0) {
      groupResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (productGroups.length > 0) {
      setProductGroups(productGroups.concat(options))
    } else {
      setProductGroups(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategories = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (categoriesResponse.next !== null) {
      const queryParams = new URLSearchParams(categoriesResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffSet(newOffset)
      hasMore = true
    }

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    if (categoriesResponse.results.length > 0) {
      categoriesResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          color: '#6d6e6f',
          bg: '#f7f7f7',
          transform: 'uppercase',
          boxSizing: 'borderBox',
          weight: '500',
          cursor: 'default',
          size: '.75rem !important',
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const searchList = (e: any) => {
    // clearTimeout(timer)
    // const newTimer = setTimeout(() => {
    setSearch(e.target.value)
    // }, 500)
    // setTimer(newTimer)
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const filterChangeHandler = (e: any, name: string) => {
    setCurrentOffset(0)
    setSelected(0)
    switch (name) {
      case 'warehouse':
        setWarehouseId(e.value)
        break
      case 'brand':
        setBrandValue(e.value)
        break
      case 'category':
        setCategoryValue(e.value)
        break
      case 'product_group':
        setGroupsValue(e.value)
        break
    }
  }

  const handleOrdering = (key?: any) => {
    setOrderColumnKey(key)
    setIsOrdering(true)
    // setListLoader(true)
    if (key === 'ProductSKU') {
      setOrderingColumn({...initialOrderColumn, ProductSKU: !orderingColumn.ProductSKU})
    }

    if (key === 'Brand') {
      setOrderingColumn({...initialOrderColumn, Brand: !orderingColumn.Brand})
    }

    if (key === 'Category') {
      setOrderingColumn({...initialOrderColumn, Category: !orderingColumn.Category})
    }

    if (key === 'ProductGroup') {
      setOrderingColumn({...initialOrderColumn, ProductGroup: !orderingColumn.ProductGroup})
    }

    if (key === 'Variant') {
      setOrderingColumn({...initialOrderColumn, Variant: !orderingColumn.Variant})
    }

    if (key === 'Packaging') {
      setOrderingColumn({...initialOrderColumn, Packaging: !orderingColumn.Packaging})
    }
  }

  useEffect(() => {
    if (orderColumnKey === 'ProductSKU') {
      if (orderingColumn.ProductSKU) {
        setOrdering('name')
      } else {
        setOrdering('-name')
      }
    }

    if (orderColumnKey === 'Brand') {
      if (orderingColumn.Brand) {
        setOrdering('brand_id__name')
      } else {
        setOrdering('-brand_id__name')
      }
    }

    if (orderColumnKey === 'Category') {
      if (orderingColumn.Category) {
        setOrdering('category_id__name')
      } else {
        setOrdering('-category_id__name')
      }
    }

    if (orderColumnKey === 'ProductGroup') {
      if (orderingColumn.ProductGroup) {
        setOrdering('variants__group_name__name')
      } else {
        setOrdering('-variants__group_name__name')
      }
    }

    if (orderColumnKey === 'Variant') {
      if (orderingColumn.Variant) {
        setOrdering('variants__name')
      } else {
        setOrdering('-variants__name')
      }
    }

    if (orderColumnKey === 'Packaging') {
      if (orderingColumn.Packaging) {
        setOrdering('material__name')
      } else {
        setOrdering('-material__name')
      }
    }
  }, [orderingColumn, orderColumnKey])

  return (
    <>
      <EATitle title='Product Catalog' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({id: 'Product Catalog'})}
      </PageTitle>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={searchList} />
            <div className='col-auto ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <AsyncPaginate
                  loadOptions={fetchBrands}
                  onChange={(e) => filterChangeHandler(e, 'brand')}
                  isSearchable
                  placeholder='All Brands'
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCategories}
                  onChange={(e) => filterChangeHandler(e, 'category')}
                  styles={categoryStyle}
                  isSearchable
                  placeholder='All Categories'
                  className='react-select-container my-1 me-3 w-140px'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchProductGroups}
                  onChange={(e) => filterChangeHandler(e, 'product_group')}
                  isSearchable
                  placeholder='All Groups'
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </form>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {listLoadder ? (
              <IsLoader />
            ) : (
              <>
                {getLength !== '1' ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered align-middle gs-0 gy-2'>
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='min-w-180px max-w-180px py-2'>
                          Product SKU
                          <span onClick={() => handleOrdering('ProductSKU')}>
                            {orderingColumn.ProductSKU ? (
                              <OrderingArrowUp />
                            ) : (
                              <OrderingArrowDown />
                            )}
                          </span>
                        </th>
                        <th className='min-w-100px py-2'>
                          Brand
                          <span onClick={() => handleOrdering('Brand')}>
                            {orderingColumn.Brand ? <OrderingArrowUp /> : <OrderingArrowDown />}
                          </span>
                        </th>
                        <th className='min-w-120px py-2'>
                          Category
                          <span onClick={() => handleOrdering('Category')}>
                            {orderingColumn.Category ? <OrderingArrowUp /> : <OrderingArrowDown />}
                          </span>
                        </th>
                        <th className='min-w-150px py-2'>
                          Product Group
                          <span onClick={() => handleOrdering('ProductGroup')}>
                            {orderingColumn.ProductGroup ? (
                              <OrderingArrowUp />
                            ) : (
                              <OrderingArrowDown />
                            )}
                          </span>
                        </th>
                        <th className='min-w-100px py-2'>Size</th>
                        <th className='min-w-100px py-2'>
                          Packaging
                          <span onClick={() => handleOrdering('Packaging')}>
                            {orderingColumn.Packaging ? <OrderingArrowUp /> : <OrderingArrowDown />}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getList.map((item: any) => {
                        let approxWeight = 0

                        if (item.variants && item.variants.approx_weight > 0) {
                          approxWeight = item.variants.approx_weight / 1000
                        }
                        return (
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                <SkuThumbnail src={item.product_thumbnail} skuName={item.name} />
                                {item.additional_notes !== '' && (
                                  <OverlayTrigger
                                    delay={{hide: 450, show: 300}}
                                    overlay={(props) => (
                                      <Tooltip {...props} className='tooltip-dark'>
                                        {item.additional_notes}
                                      </Tooltip>
                                    )}
                                    placement='top'
                                  >
                                    <span className='ms-2 cursor-pointer'>
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen045.svg'
                                        className='ic text-primary mr-0'
                                      />
                                    </span>
                                  </OverlayTrigger>
                                )}
                              </div>
                            </td>
                            <td>{item.brand_id}</td>
                            <td>
                              <div>
                                <div className='text-muted font-13 mb-1 font-medium'>
                                  {item.parent_category}
                                </div>
                                {item.category_id}
                              </div>
                            </td>
                            <td>{item.group_name}</td>
                            <td>
                              {approxWeight > 0
                                ? approxWeight + ' Kgs'
                                : (item.measurement_unit.toLowerCase() === 'gram'
                                    ? item.size / 1000
                                    : item.size) + ' Kgs'}
                            </td>
                            <td>{item.material.name}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <CustomPaginate
        data={getList}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default WMProductSkuCatalog
