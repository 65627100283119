// app route file

import {FC} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {App} from '../App'
import {AuthPage, Logout} from '../modules/auth'
import {PrivateRoutes} from './privateRoutes'
import {connect} from 'react-redux'
import {isEmpty} from '../../_eaFruitsDms/helpers'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = (props: any) => {
  const {
    userDetails: {token},
  } = props

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='logout' element={<Logout />} />
          {!isEmpty(token) ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={'/dashboard'} />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.login,
  }
}

export default connect(mapStateToProps, null)(AppRoutes)
