import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import moment from 'moment'
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AsyncPaginate} from 'react-select-async-paginate'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import {useLocation, useNavigate} from 'react-router-dom'
import {getRequest, putRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  apiBaseURL,
  calculateDays,
  checkEmpty,
  dateFormat,
  dateTimeFormat,
  fetchAsyncDropdown,
  firstLetterCapital,
  formatTags,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
} from '../../../../_eaFruitsDms/helpers'
import tenantConfiguration from '../../../../TenantVariables'
import DatePicker from '../../../../_eaFruitsDms/layout/components/datePicker/datePicker'
import clsx from 'clsx'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'

type Props = {
  className: string
}

const CreditRequestDetail: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const customerHeaderTitle = {
    title: 'Customers',
  }
  const location: any = useLocation()
  const navigate = useNavigate()
  const {state}: any = location

  if (isEmpty(state) || isEmpty(state.request_id)) {
    navigate('/customers/credit-requests')
  }

  const currentDateObj = moment().toDate()

  let initialReason = {
    reason: null,
    reason_note: '',
  }

  let initialApproveValidation = {
    requested_amount: '',
    reason: '',
    reason_note: '',
  }

  const [rejection, setRejection] = useState(false)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [creditRequest, setCreditRequest] = useState<any>(null)
  const [approveCreditRequest, setApproveCreditRequest] = useState(false)
  const [approveButtonDisable, setApproveButtonDisable] = useState<boolean>(false)
  const [approveCreditButtonDisable, setApproveCreditButtonDisable] = useState<boolean>(false)
  const [maxCreditDays, setMaxCreditDays] = useState<number>(0)
  const [requestAmount, setRequestAmount] = useState<any>(0)
  const [requestDueDate, setRequestDueDate] = useState<any>(dateFormat(moment()))
  const [maxDate, setMaxDate] = useState<any>(moment())
  const [searchSelect, setSearchSelect] = useState('')
  const [reasonOffSet, setReasonOffSet] = useState<any>('')
  const [reasons, setReasons] = useState<any>([])
  const [selectedReason, setSelectedReason] = useState<any>(initialReason)
  const [approveValidation, setApproveValidation] = useState<any>(initialApproveValidation)
  const [rejectButtonDisable, setRejectButtonDisable] = useState<boolean>(false)
  const [fetchingSrCreditHistory, setFetchingSrCreditHistory] = useState(false)
  const [srCreditHistory, setSrCreditHistory] = useState({
    total_credit_request_of_sr: 0,
    approved_credits_of_sr: 0,
    approved_credits_ratio: 0,
  })
  const [tab, setTab] = useState('open')
  const [customerHistory, setCustomerHistory] = useState([])
  const [openCreditIssues, setOpenCreditIssues] = useState({open_credit_amount: 0})
  const [fetchingCustomerHistory, setFetchingCustomerHistory] = useState(false)
  const [customerId, setCustomerId] = useState('')

  useEffect(() => {
    fetchCreditRequestDetails().then()
  }, [])

  useEffect(() => {
    !isEmpty(customerId) && fetchCustomerHistory()
  }, [customerId, tab])

  useEffect(() => {
    setReasonOffSet('')
  }, [searchSelect])

  const fetchCreditRequestDetails = async () => {
    setDisplayLoader(true)

    const response = await getRequest(`${apiBaseURL()}credit/requests/${state.request_id}`, true)
    setDisplayLoader(false)

    if (response.result) {
      setRequestAmount(response.result.requested_amount)
      setCreditRequest(response.result)
      response.result.customer && setCustomerId(response.result.customer.id)
      setSrCreditHistory(response.meta)
    } else {
      navigate('/customers/credit-requests')
    }
  }

  const fetchCustomerHistory = async () => {
    setFetchingCustomerHistory(true)
    const url = `${apiBaseURL()}credit/requests/customer-history/${customerId}?issue_status=${tab}`
    const response = await getRequest(url, true)

    if (response.result) {
      setCustomerHistory(response.result)
      setOpenCreditIssues(response.meta)
    }
    setFetchingCustomerHistory(false)
  }

  const handleShowApproveCreditRequest = async () => {
    setApproveButtonDisable(true)
    const response = await getRequest(
      `${apiBaseURL()}customers/${creditRequest.customer.id}/credit-details`,
      true
    )

    if (response.result) {
      setMaxCreditDays(response.result.max_credit_due_days)
      setMaxDate(creditRequest.due_date)
      setRequestDueDate(dateFormat(creditRequest.due_date))
      setApproveCreditRequest(true)
    }
    setApproveButtonDisable(false)
  }
  const handleCloseApproveCreditRequest = () => {
    clearState()
    setApproveCreditRequest(false)
  }

  const handleShowRejection = () => setRejection(true)
  const handleCloseRejection = () => {
    clearState()
    setRejection(false)
  }

  const clearState = () => {
    setReasonOffSet('')
    setReasons([])
    setSelectedReason(initialReason)
    setApproveValidation(initialApproveValidation)
  }

  const handleAmountChange = (e: any) => {
    const {value} = e.target
    if (value > creditRequest.requested_amount) {
      setApproveValidation({...approveValidation, ['requested_amount']: 'is-invalid'})
    } else {
      setApproveValidation({...approveValidation, ['requested_amount']: ''})
    }
    setRequestAmount(value)
  }

  const fetchCreditRequestUpdateReasons = async (search?: any) => {
    let response = await fetchAsyncDropdown(
      reasonOffSet,
      setReasonOffSet,
      reasons,
      setReasons,
      `master/options-master/?type=credit_request_update_reason`,
      search,
      setSearchSelect,
      'option'
    )

    response.options.push({
      label: 'Other',
      value: '',
    })

    return response
  }

  const fetchCreditRequestRejectionReason = async (search?: any) => {
    let response = await fetchAsyncDropdown(
      reasonOffSet,
      setReasonOffSet,
      reasons,
      setReasons,
      `master/options-master/?type=credit_request_rejection_reason`,
      search,
      setSearchSelect,
      'option'
    )

    response.options.push({
      label: 'Other',
      value: '',
    })

    return response
  }

  const handleReasonChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    setApproveValidation({...approveValidation, [inputName]: ''})
    setSelectedReason({...selectedReason, [inputName]: inputValue})
  }

  const approveRequest = async () => {
    let isValid: boolean = true
    const updatedValidation = {...approveValidation}

    if (isEmpty(requestAmount)) {
      isValid = false
      updatedValidation.requested_amount = 'is-invalid'
    } else {
      if (requestAmount > creditRequest.requested_amount) {
        isValid = false
        updatedValidation.requested_amount = 'is-invalid'
      }
    }

    if (
      creditRequest.requested_amount > requestAmount ||
      creditRequest.due_date !== requestDueDate.apiDate
    ) {
      if (selectedReason.reason === null) {
        isValid = false
        updatedValidation.reason = 'is-invalid'
      } else {
        if (selectedReason.reason === '' && isEmpty(selectedReason.reason_note)) {
          isValid = false
          updatedValidation.reason_note = 'is-invalid'
        }
      }
    }
    setApproveValidation(updatedValidation)

    if (isValid) {
      const requestData = {
        status: 'approved',
        approved_amount: requestAmount,
        approved_due_date: requestDueDate.apiDate,
        partial_approval_reason_option: isEmpty(selectedReason.reason)
          ? null
          : selectedReason.reason,
        partial_approval_reason_note: isEmpty(selectedReason.reason_note)
          ? null
          : selectedReason.reason_note,
      }
      setApproveCreditButtonDisable(true)
      await putRequest(
        `${apiBaseURL()}credit/requests/${creditRequest.id}/approval`,
        requestData,
        true
      )
      setApproveCreditButtonDisable(false)

      clearState()
      handleCloseApproveCreditRequest()
      navigate('/customers/credit-requests')
    }
  }

  const rejectRequest = async () => {
    let isValid: boolean = true
    const updatedValidation = {...approveValidation}
    console.table(selectedReason)
    if (selectedReason.reason === null) {
      isValid = false
      updatedValidation.reason = 'is-invalid'
    } else {
      if (selectedReason.reason === '' && isEmpty(selectedReason.reason_note)) {
        isValid = false
        updatedValidation.reason_note = 'is-invalid'
      }
    }
    setApproveValidation(updatedValidation)

    if (isValid) {
      const requestData = {
        status: 'rejected',
        rejection_option: isEmpty(selectedReason.reason) ? null : selectedReason.reason,
        rejection_note: isEmpty(selectedReason.reason_note) ? null : selectedReason.reason_note,
      }

      setRejectButtonDisable(true)
      await putRequest(
        `${apiBaseURL()}credit/requests/${creditRequest.id}/approval`,
        requestData,
        true
      )
      setRejectButtonDisable(false)

      clearState()
      handleCloseRejection()
      navigate('/customers/credit-requests')
    }
  }

  return (
    <>
      <EATitle title='Credit Request Details' />
      <PageTitle breadcrumbs={[customerHeaderTitle]}>
        {intl.formatMessage({id: 'Credit Requests'})}
      </PageTitle>
      {/* credit request detail */}
      <div className='card'>
        {displayLoader && <IsLoader />}
        <div className='card-body pt-4 pt-md-5'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='border-bottom d-flex justify-content-between align-item-center pb-4 mb-4'>
                <h3 className='font-14 fw-500'>Credit Requests Details</h3>
                <>
                  {creditRequest && (
                    <>
                      <td className='text-end'>
                        {creditRequest.pay_later_today_created_at ? (
                          <span className='badge bg-light-success text-dark cursor-pointer'>
                            Auto Approved
                          </span>
                        ) : (
                          <>
                            {creditRequest.status === 'pending' ? (
                              <>
                                <span className='badge bg-secondary text-dark cursor-pointer'>
                                  Pending
                                </span>
                              </>
                            ) : ['approved', 'completed'].includes(creditRequest.status) ? (
                              <span className='badge bg-light-success text-dark cursor-pointer'>
                                Approved
                              </span>
                            ) : creditRequest.status === 'rejected' ? (
                              <span className='badge bg-light-danger text-dark cursor-pointer'>
                                Rejected
                              </span>
                            ) : creditRequest.status === 'cancelled' ? (
                              <span className='badge bg-secondary text-dark cursor-pointer'>
                                Cancelled
                              </span>
                            ) : (
                              '-'
                            )}
                          </>
                        )}
                      </td>
                    </>
                  )}
                </>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Requested Credit Amount</label>
                  <div className='font-17 text-dark font-bold'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.requested_amount)
                      ? '-'
                      : tenantConfiguration.currencyCode +
                        ' ' +
                        numberFormat(creditRequest.requested_amount).displayFormat}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved Credit Amount</label>
                  <div className='font-17 text-dark font-bold'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.approved_amount)
                      ? '-'
                      : tenantConfiguration.currencyCode +
                        ' ' +
                        numberFormat(creditRequest.approved_amount).displayFormat}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Due Date</label>
                  <div className='font-17 text-dark font-bold'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : `${dateFormat(creditRequest.due_date).displayDate} (${calculateDays(
                          dateFormat(
                            creditRequest.pay_later_today_created_at
                              ? creditRequest.pay_later_today_created_at
                              : creditRequest.created_at
                          ).apiDate,
                          creditRequest.due_date
                        )})`}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved Due Date</label>
                  <div className='font-17 text-dark font-bold'>
                    {isEmpty(creditRequest) || isEmpty(creditRequest.approved_due_date)
                      ? '-'
                      : `${
                          dateFormat(creditRequest.approved_due_date).displayDate
                        } (${calculateDays(
                          dateFormat(
                            creditRequest.pay_later_today_created_at
                              ? creditRequest.pay_later_today_created_at
                              : creditRequest.created_at
                          ).apiDate,
                          creditRequest.approved_due_date
                        )})`}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-3'>
                  <label className='font-14 text-muted mb-2'>Request Date & Time</label>
                  <div className='font-14 text-dark'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.created_at)
                      ? '-'
                      : dateTimeFormat(creditRequest.created_at)}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Requested By</label>
                  <div className='font-14 text-dark'>
                    {isEmpty(creditRequest) ? '-' : checkEmpty(creditRequest.sr_name, '-')}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>SR Contact No.</label>
                  <div className='font-14 text-dark'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.sr_contact_no)
                      ? '-'
                      : tenantConfiguration.baseCountryCode + ' ' + creditRequest.sr_contact_no}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Comment</label>
                  <div className='font-14 text-dark'>
                    {isEmpty(creditRequest) ? '-' : checkEmpty(creditRequest.description, '-')}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved At</label>
                  <div className='font-14 text-dark'>
                    {creditRequest &&
                    (creditRequest.status === 'cancelled' ||
                      creditRequest.status === 'rejected') ? (
                      '-'
                    ) : (
                      <>
                        {creditRequest && !isEmpty(creditRequest.action_at)
                          ? dateTimeFormat(creditRequest.action_at)
                          : '-'}
                      </>
                    )}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved by</label>
                  <div className='font-14 text-dark'>
                    {creditRequest &&
                    (creditRequest.status === 'cancelled' ||
                      creditRequest.status === 'rejected') ? (
                      '-'
                    ) : (
                      <>
                        {creditRequest && !isEmpty(creditRequest.action_by_name) ? (
                          <>
                            {creditRequest.action_by_name} ({creditRequest.user_type})
                          </>
                        ) : (
                          '-'
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Reason</label>
                  <div className='font-14 text-dark'>
                    {isEmpty(creditRequest) ? '-' : checkEmpty(creditRequest.rejection_note, '-')}
                  </div>
                </div>
              </div>

              <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 mt-4'>Customer Details</h3>
              <div className='row'>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer ID</label>
                  <div className='font-14 text-dark'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.customer)
                      ? '-'
                      : isEmpty(creditRequest.customer.id)
                      ? '-'
                      : `#${creditRequest.customer.id}`}
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Mobile Number</label>
                  <div className='font-14 text-dark'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.customer)
                      ? '-'
                      : isEmpty(creditRequest.customer.contact_no)
                      ? '-'
                      : `${tenantConfiguration.baseCountryCode} ${creditRequest.customer.contact_no}`}
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Name</label>
                  <div className='font-14 text-dark'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.customer)
                      ? '-'
                      : isEmpty(creditRequest.customer.business_name)
                      ? '-'
                      : `${creditRequest.customer.business_name}`}
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 mb-6'>
                  <label className='font-14 text-muted mb-2'>Address</label>
                  <div className='font-14 text-dark'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.customer)
                      ? '-'
                      : isEmpty(creditRequest.customer.address)
                      ? '-'
                      : `${creditRequest.customer.address}`}
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Category/Sub-category</label>
                  <div className='font-14 text-dark text-capitalize'>
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.customer)
                      ? '-'
                      : isEmpty(creditRequest.customer.nature_of_business)
                      ? '-'
                      : `${creditRequest.customer.nature_of_business}`}
                    /
                    {isEmpty(creditRequest)
                      ? '-'
                      : isEmpty(creditRequest.customer)
                      ? '-'
                      : isEmpty(creditRequest.customer.category)
                      ? '-'
                      : `${creditRequest.customer.category}`}
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 mb-0'>
                  <label className='font-14 text-muted mb-2'>Customer Tags</label>
                  <div className='font-14 text-dark'>
                    {creditRequest &&
                    creditRequest.customer &&
                    creditRequest.customer.tags.length > 0 ? (
                      <div className='d-flex align-items-center'>
                        {creditRequest.customer.tags.length === 1 ? (
                          <span className='ms-2 btn btn-sm bg-light-primary py-1 cursor-default'>
                            {creditRequest.customer.tags[0].tag_name}
                          </span>
                        ) : (
                          <OverlayTrigger
                            delay={{hide: 450, show: 300}}
                            overlay={(props) => (
                              <Tooltip {...props} className='tooltip-dark'>
                                <span>{formatTags(creditRequest.customer.tags)}</span>
                              </Tooltip>
                            )}
                            placement='top'
                          >
                            <span className='ms-2 btn btn-sm bg-light-primary py-1 cursor-default'>
                              {creditRequest.customer.tags[0].tag_name}{' '}
                              {creditRequest.customer.tags.length > 1 &&
                                `& ${creditRequest.customer.tags.length - 1} More`}
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='card-body pt-2 mt-1 pb-5'>
                <div className='border-bottom d-flex justify-content-between align-item-center pb-4 mb-4'>
                  <h3 className='font-14 fw-500'>SR Credit Requests History</h3>
                </div>
                {fetchingSrCreditHistory ? (
                  <FetchingRecords />
                ) : (
                  <div className='row'>
                    <div className='col-sm-4 col-md-4 mb-4'>
                      <label className='font-14 text-muted mb-2'>Total Requests</label>
                      <div className='font-14 text-dark'>
                        {isEmpty(srCreditHistory.total_credit_request_of_sr)
                          ? '-'
                          : numberFormat(srCreditHistory.total_credit_request_of_sr).displayFormat}
                      </div>
                    </div>
                    <div className='col-sm-4 col-md-4 mb-4'>
                      <label className='font-14 text-muted mb-2'>Approved Requests</label>
                      <div className='font-14 text-dark'>
                        {isEmpty(srCreditHistory.approved_credits_of_sr)
                          ? '-'
                          : numberFormat(srCreditHistory.approved_credits_of_sr).displayFormat}
                      </div>
                    </div>
                    <div className='col-sm-4 col-md-4 mb-4'>
                      <label className='font-14 text-muted mb-2'>Approval Ratio</label>
                      <div className='font-14 text-dark'>
                        {isEmpty(srCreditHistory.approved_credits_ratio)
                          ? '-'
                          : `${
                              numberFormat(srCreditHistory.approved_credits_ratio, true)
                                .displayFormat
                            } %`}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/*  */}
              <div className='card-body pt-2 mt-1'>
                <div className='d-flex justify-content-between align-item-center border-bottom pb-4 mt-4'>
                  <h3 className='font-14 fw-500'>Credit History</h3>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='d-flex p-3'>
                      <div
                        onClick={() => setTab('open')}
                        className={`${
                          tab === 'open'
                            ? 'text-primary fw-500 border-bottom border-primary pb-1 border-3'
                            : 'text-muted'
                        }  cursor-pointer me-4`}
                      >
                        Open
                      </div>
                      <div
                        onClick={() => setTab('overdue')}
                        className={`${
                          tab === 'overdue'
                            ? 'text-primary fw-500 border-bottom border-primary pb-1 border-3'
                            : 'text-muted'
                        }  cursor-pointer me-4`}
                      >
                        Overdue
                      </div>
                      <div
                        onClick={() => setTab('on_time')}
                        className={`${
                          tab === 'on_time'
                            ? 'text-primary fw-500 border-bottom border-primary pb-1 border-3'
                            : 'text-muted'
                        }  cursor-pointer me-4`}
                      >
                        On Time
                      </div>
                      <div
                        onClick={() => setTab('delayed')}
                        className={`${
                          tab === 'delayed'
                            ? 'text-primary fw-500 border-bottom border-primary pb-1 border-3'
                            : 'text-muted'
                        }  cursor-pointer me-4`}
                      >
                        Delayed
                      </div>
                    </div>
                  </div>
                  {['open', 'overdue'].includes(tab) && (
                    <div className='col-md-12'>
                      <div className='bg-light p-4'>
                        <div className={`fw-500 cursor-pointer text-primary mb-2`}>
                          {firstLetterCapital(tab)} Credit Issues
                        </div>
                        <b>
                          {isEmpty(openCreditIssues.open_credit_amount)
                            ? '-'
                            : numberFormatWithCode(openCreditIssues.open_credit_amount)
                                .displayFormat}
                        </b>
                      </div>
                    </div>
                  )}
                  <div className='col-md-12 mt-2'>
                    {fetchingCustomerHistory ? (
                      <FetchingRecords />
                    ) : customerHistory.length === 0 ? (
                      <NoRecords />
                    ) : (
                      <div className='table-responsive h-500px'>
                        <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                          <thead>
                            <tr className='text-muted fw-500'>
                              <th className='min-w-150px'>Order No</th>
                              <th className='min-w-120px'>Due Date</th>
                              {['open', 'overdue'].includes(tab) ? (
                                <th className='min-w-140px'>Amount Due</th>
                              ) : (
                                <th className='min-w-140px'>Received Date</th>
                              )}
                              <th className='min-w-120px'>SR Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customerHistory.length > 0 &&
                              customerHistory.map((item: any) => {
                                return (
                                  <tr className='cursor-pointer'>
                                    <td className='py-3'>
                                      {isEmpty(item.order_no) ? '-' : `#${item.order_no}`}
                                      <div>
                                        {isEmpty(item.delivery_date)
                                          ? '-'
                                          : dateFormat(item.delivery_date).displayDate}
                                      </div>
                                    </td>
                                    <td>
                                      {isEmpty(item.due_date)
                                        ? '-'
                                        : dateFormat(item.due_date).displayDate}
                                    </td>
                                    {['open', 'overdue'].includes(tab) ? (
                                      <td>
                                        {isEmpty(item.due_amount)
                                          ? '-'
                                          : numberFormatWithCode(item.due_amount).displayFormat}
                                      </td>
                                    ) : (
                                      <td>
                                        {isEmpty(item.received_date)
                                          ? '-'
                                          : dateFormat(item.received_date).displayDate}
                                      </td>
                                    )}

                                    <td>{isEmpty(item.sr_name) ? '-' : item.sr_name}</td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {creditRequest && creditRequest.status === 'pending' && (
          <div className='card-footer py-4'>
            <button className='btn btn-secondary me-2 ' type='button' onClick={handleShowRejection}>
              Reject
            </button>
            <button
              className='btn btn-primary '
              type='button'
              disabled={approveButtonDisable}
              onClick={handleShowApproveCreditRequest}
            >
              {approveButtonDisable ? 'Please Wait...' : 'Approve'}
            </button>
          </div>
        )}
      </div>

      {/* Approve */}

      <Modal show={approveCreditRequest} onHide={handleCloseApproveCreditRequest} centered>
        <Modal.Header closeButton>
          <Modal.Title>Approve Credit Request</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          <div className='mb-4'>
            <label className='form-label'>Credit Request Amount</label>
            <div
              className={clsx(
                'input-group mb-1',
                !isEmpty(approveValidation.requested_amount) && 'is-invalid-grp'
              )}
            >
              <span className='input-group-text bg-light2 border-start-0 px-3 border-end border-light ms-0 w-50px'>
                {`${tenantConfiguration.currencyCode}`}
              </span>
              <input
                type='number'
                placeholder='0'
                className={clsx('form-control min-w-70px', approveValidation.requested_amount)}
                onChange={handleAmountChange}
                value={requestAmount}
              />
            </div>
          </div>
          <div className='mb-4'>
            <div className='d-flex justify-content-between'>
              <label className='form-label'>Credit Duration</label>
              <label className='form-label text-primary'>
                {maxCreditDays > 0 ? maxCreditDays + ' Days' : '0 Day'}
              </label>
            </div>
            <DatePicker
              onCallback={(dateView: any, dateApi: any) => {
                setRequestDueDate(dateFormat(dateApi))
              }}
              onCancel={() => {}}
              id='datePicker'
              name='expiry_date'
              onApply={() => {}}
              direction='auto'
              startDate={currentDateObj}
              minDate={moment().add(1, 'days')}
              maxDate={moment().add(maxCreditDays, 'days')}
              dateValue={requestDueDate.displayDate}
            />
          </div>
          {creditRequest &&
            (creditRequest.requested_amount > requestAmount ||
              creditRequest.due_date !== requestDueDate.apiDate) && (
              <>
                <div className='mb-4'>
                  <label className='form-label'>Reason to Update Credit Request</label>
                  <AsyncPaginate
                    loadOptions={fetchCreditRequestUpdateReasons}
                    className={clsx('react-select-container w-100', approveValidation.reason)}
                    classNamePrefix='react-select'
                    onChange={(e: any) => handleReasonChange(e, 'reason')}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
                {selectedReason.reason === '' && (
                  <div className='mb-0'>
                    <label className='form-label'>Comments</label>
                    <textarea
                      className={clsx('form-control', approveValidation.reason_note)}
                      rows={4}
                      onChange={(e: any) => handleReasonChange(e)}
                      name='reason_note'
                      placeholder='Type here…'
                    ></textarea>
                  </div>
                )}
              </>
            )}
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-light' onClick={handleCloseApproveCreditRequest}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            disabled={approveCreditButtonDisable}
            onClick={approveRequest}
          >
            {approveCreditButtonDisable ? 'Please Wait...' : 'Approve Credit Request'}
          </button>
        </Modal.Footer>
      </Modal>

      {/* <CreditRequestRejectionModal className="" /> */}
      <Modal show={rejection} onHide={handleCloseRejection} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          <div className='mb-4'>
            <label className='form-label'>Rejection Reason</label>
            <AsyncPaginate
              loadOptions={fetchCreditRequestRejectionReason}
              className={clsx('react-select-container w-100', approveValidation.reason)}
              classNamePrefix='react-select'
              onChange={(e: any) => handleReasonChange(e, 'reason')}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
          {selectedReason.reason === '' && (
            <div className='mb-4'>
              <label className='form-label'>Comments</label>
              <textarea
                className={clsx('form-control', approveValidation.reason_note)}
                rows={4}
                name='reason_note'
                onChange={handleReasonChange}
                placeholder='Type here…'
              ></textarea>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-light' onClick={handleCloseRejection}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            disabled={rejectButtonDisable}
            onClick={rejectRequest}
          >
            {rejectButtonDisable ? 'Pleas Wait...' : 'Submit'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreditRequestDetail
