import React, {useState} from 'react'
import {reSendEmail} from '../../../../../../_eaFruitsDms/helpers'
import {EaAlertSuccess} from '../../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import tenantConfiguration from '../../../../../../TenantVariables'
import EATitle from '../../../../../../_eaFruitsDms/layout/components/title/title'

const WarehouseOverview = (props: any) => {
  const {Warehouse_Type, Warehouse_Address, General_Manager, Phone_Number, Email, Verified_Status} =
    props.WarehouseOverView

  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState('Resend Verification Email')

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  const handleValidate = () => {
    if (isLoading === 'Please Wait...') {
      return false
    }
    setIsLoading('Please Wait...')
    reSendEmail(Email).then((res: any) => {
      if (res.status == 200) setIsLoading('Resend Verification Email')
      setIsAlertSuccess(true)

      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)
    })
  }

  return (
    <>
      <EATitle title='Overview' />
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={`Verification email has been resent to ${Email}.`}
      />
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-5'>
          <div className='row mb-6'>
            <label className='col-lg-12 font-14 text-muted'>Warehouse Type</label>
            <div className='col-lg-12'>
              <span className='font-14 text-dark'>
                {Warehouse_Type == 'DistributionCenter' ? 'Distribution Center' : Warehouse_Type}
              </span>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-12 font-14 text-muted'>Street Address</label>
            <div className='col-lg-12'>
              <span className='font-14 text-dark'>{Warehouse_Address}</span>
            </div>
          </div>
          {Warehouse_Type !== 'DistributionCenter' && (
            <>
              <div className='row mb-6'>
                <label className='col-lg-12 font-14 text-muted'>Branch Manager</label>
                <div className='col-lg-12'>
                  <span className='font-14 text-dark'>{General_Manager}</span>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-12 font-14 text-muted'>Mobile Number</label>
                <div className='col-lg-12'>
                  <span className='font-14 text-dark'>
                    {Phone_Number && tenantConfiguration.baseCountryCode} {Phone_Number}
                  </span>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-12 font-14 text-muted'>Email</label>
                <div className='col-lg-12'>
                  <span className='font-14 text-dark'>{Email}</span>
                  {!Verified_Status && (
                    <button
                      className='btn btn-link ms-3 p-0 '
                      type='button'
                      onClick={handleValidate}
                    >
                      {isLoading}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default WarehouseOverview
