import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  CategoriesCreate,
  CategoriesDelete,
  CategoriesList,
  CategoriesSingle,
  CategoriesUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation
} from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler"
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import { getToken } from "../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage"
import TableCategory from "./components/tableCategory"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"

type Props = {
  className: string
}
const ProductCategoriesMaster: React.FC<Props> = ({ className }) => {
  const intl = useIntl()

  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Product Categories',
  }

  // all states
  const [catName, setCatName] = useState('')
  const [parentcatName, setParentCatName] = useState('')
  const [catNameEdit, setCatNameEdit] = useState('')
  const [parentcatNameEdit, parentsetCatNameEdit] = useState('')
  const [catNameDelete, setCatNameDelete] = useState('')
  const [id, setId] = useState('')
  const [getList, setGetList] = useState<any>([])
  const [getParentCategoryList, setParentCategory] = useState<any>([])
  const [getParentCategoryListModal, setParentCategoryModal] = useState<any>([])
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [validation, setValidation] = useState('')
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [getLength, setLength] = useState('1')
  const [showWrite, setShowWrite] = useState<any>(false)
  const [showDelete, setShowDelete] = useState<any>(false)
  const [listLoadder, setListLoader] = useState<any>(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  // Authorization token
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  useEffect(() => {
    fetchPermission()

    setListLoader(true)
    CategoriesList('10', '0').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_products")
    setPermission(res)
   }

  //request method for api implementation :- last change by kriti
  const categoryApi = () => {
    getRequest(`${apiBaseURL()}master/product/category?ordering=name`, true).then((res) => {
      setParentCategory(res.results)
      setParentCategoryModal(res.results)
    })
  }
  const getProps = (val: any) => {
    setMessageHandler('successEdit')
    setModalLoader(true)
    categoryApi()
    setCatNameEdit(val.name)
    setId(val.id)
    CategoriesSingle(val.id).then((res: any) => {
      setCatNameEdit(res.name)
      parentsetCatNameEdit(res.parent_category)
      setModalLoader(false)
    })
  }

  const getPropsDelete = (val: any) => {
    setId(val.id)
    setCatNameDelete(val.name)
  }

  const updateFunction = () => {
    let navigateField = {
      catNameEdit: catNameEdit,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (catNameEdit.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')

      CategoriesUpdate(catNameEdit, parentcatNameEdit, id).then((notify) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (notify.success) {
          clearFunctionEdit()
          setListLoader(true)
          CategoriesList('10', '0', search).then((res) => {
            setGetList(res.data)
            setSelected(0)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setListLoader(false)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
          })
          $('#ea_close_update_product_category').trigger('click') // modal close

          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
          setIsAlertSuccess(true)
        }

        if (notify.errorField == 'name') {
          setErrorMessage(
            'Your added category name already exists in this system! Please try again with a unique category name.'
          )
        }
        if (notify.failed) {
          // setIsAlertFailed(true)
        }
      })
    }
  }

  const addFunction = () => {
    let navigateField = {
      catName: catName,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // api validation front-end side
    if (catName.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      CategoriesCreate(catName, parentcatName).then((notify) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (notify.success) {
          clearFunction()
          setListLoader(true)
          CategoriesList('10', '0', search).then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)
            setListLoader(false)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
          })
          $('#ea_close_create_product_category').trigger('click') // modal close
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
          setIsAlertSuccess(true)
        }
        if (notify.failed) {
          // setIsAlertFailed(true)
        }

        if (notify.errorField == 'name') {
          setErrorMessage(
            'Your added category name already exists in this system! Please try again with a unique category name.'
          )
        }
      })
    } else {
      setValidation('is-invalid')
    }
  }

  const clearFunction = () => {
    // clear add modal state
    setParentCatName('')
    setCatName('')
    setValidation('')
    setErrorMessage('')
  }

  const clearFunctionEdit = () => {
    // clear edit modal state
    setErrorMessage('')
    setCatNameEdit('')
  }

  const deleteFuntion = () => {
    CategoriesDelete(id).then((notify) => {
      if (notify.success) {
        setListLoader(true)
        CategoriesList('10', currentOffset, search).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setListLoader(false)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
        })
        setIsAlertSuccess(true)
      }
      if (notify.failed) {
        setIsAlertFailed(true)
      }
      $('#ea_close_delete_product_category').trigger('click') // modal close
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)
    })
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)
      CategoriesList('10', '0', e.target.value).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
        setListLoader(false)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
      })
    }, 500)
    setTimer(newTimer)
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      CategoriesList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      CategoriesList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      CategoriesList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  return (
    <>
      <EATitle title='Product-Categories' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({ id: 'Product Categories' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('PRODUCT.CATEGORIES.ADD')
            : FormatMessage('PRODUCT.CATEGORIES.UPDATE')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => searchList(e)} id='searcList' />
            {permission.write && (
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                <EaButton
                  onClick={() => {
                    categoryApi()
                    setMessageHandler('successAdd')
                  }}
                  isModal={true}
                  btnName='Add Category'
                  dataTarget='#ea_modal_create_product_category'
                />
              </div>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0 ? (
              <NoRecords />
            ) : (
              <TableCategory
                showDelete={permission.delete}
                showWrite={permission.write}
                getLength={getLength}
                tableList={getList}
                getPropsEdit={getProps}
                getPropsDelete={getPropsDelete}
              />
            )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
              } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>

      {/* add categories */}
      <EaModal
        cancel={clearFunction}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        onSubmit={() => addFunction()}
        closeAuto={'ea_close_create_product_category'}
        id='ea_modal_create_product_category'
        modalTitle='Add Product Category'
      >
        <div className='mb-3'>
          <label className='form-label'>
            Category Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='catName'
            className={clsx('form-control', validation)}
            value={catName}
            onChange={(e) => {
              setCatName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidation(validation)
            }}
            placeholder='Type here...'
          />
        </div>
        <div className=''>
          <label className='form-label'>Parent Category (If Available)</label>
          <select
            value={parentcatName}
            className='form-select font-14'
            aria-label='Select example'
            onChange={(e) => {
              setParentCatName(e.target.value)
            }}
          >
            <option hidden>Select</option>
            {getParentCategoryListModal.map(
              (item: any) =>
                item.parent_category == null && (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
            )}
          </select>
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* edit categories */}
      <EaModal
        cancel={clearFunctionEdit}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        onSubmit={() => updateFunction()}
        closeAuto={'ea_close_update_product_category'}
        id='ea_modal_edit_product_category'
        modalTitle='Edit Product Category'
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Category Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='catNameEdit'
            value={catNameEdit}
            onChange={(e) => {
              setCatNameEdit(capitalizeFirstLetter(e.target.value))
            }}
            className={clsx('form-control', { 'is-invalid': catNameEdit.trim() == '' })}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>Parent Category (If Available)</label>
          <select
            value={parentcatNameEdit == null ? '' : parentcatNameEdit}
            className='form-select font-14'
            aria-label='Select example'
            onChange={(e) => {
              parentsetCatNameEdit(e.target.value)
            }}
          >
            <option hidden>Select</option>
            {getParentCategoryList.map(
              (item: any) =>
                item.parent_category == null && (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
            )}
          </select>
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* delete categories */}
      <EaModal
        modalTitle='Delete Product Category'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{catNameDelete}?</span>
          </p>
        }
        onSubmit={deleteFuntion}
        id={'deleteProductCategories'}
        closeAuto='ea_close_delete_product_category'
        actionBtnName='Yes, Proceed'
      />
    </>
  )
}

export default ProductCategoriesMaster
