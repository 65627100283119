import SunEditor from "suneditor-react"
import "suneditor/dist/css/suneditor.min.css"

const CustomSunEditor = ({data, handleChange, fieldName, validationTriggered, message}: any) => {
	let formats: any = [
		["undo", "redo"],
		["list"],
		["bold", "underline", "italic", "strike", "subscript", "superscript"],
		["align", "formatBlock", "lineHeight"],
		["image"],
	]

	return (
		<>
			<SunEditor
				setOptions={ {
					height: "200",
					buttonList: formats,
				} }
				onChange={ (e: any) => handleChange(e, fieldName) }
				onPaste={ (e: any, cleanData) => {
					cleanData = cleanData.toString()
					cleanData = cleanData.replace(/style="(.*?)"/gm, "")
					e.preventDefault()
					return cleanData
				} }
				setContents={ data }
			/>
			{
				validationTriggered && <span className="font-13 text-danger">{ message }</span>
			}
		</>
	)
}

export default CustomSunEditor
