import {SA_DASHBOARD_WAREHOUSE, SA_DASHBOARD_MONTH} from '../../types/types'

export const setWarehouseValue = (val: any) => (dispatch: any) => {
  dispatch({
    type: SA_DASHBOARD_WAREHOUSE,
    payload: val,
  })
}

export const setMonthValue = (val: any) => (dispatch: any) => {
  dispatch({
    type: SA_DASHBOARD_MONTH,
    payload: val,
  })
}
