import {useLocation, useNavigate} from 'react-router-dom'

export const StockInTabs = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <div className={`card mb-4`}>
        <ul className='nav nav-tabs ml-3'>
          <li className='nav-item'>
            <a
              className={`nav-link ${
                location.pathname === '/fresh-product-stock-in' ? 'active' : ''
              }`}
              href='JAVASCRIPT:void(0)'
              onClick={() => navigate('/fresh-product-stock-in')}
            >
              Fresh Stock-In (Other than Banana Group)
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link ${
                location.pathname === '/banana-group-stock-in' ? 'active' : ''
              }`}
              href='JAVASCRIPT:void(0)'
              onClick={() => navigate('/banana-group-stock-in')}
            >
              Banana Group Stock-In
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}
