import React from 'react'
import EATitle from './title/title'
import {PageTitle} from '../core'
import {useIntl} from 'react-intl'

interface Props {
  pageTitle: string
  headerTitle: string
  headerSubTitle: string
}

const HeaderTitle = ({pageTitle, headerTitle, headerSubTitle}: Props) => {
  const intl = useIntl()

  const header = {
    title: headerSubTitle,
  }

  return (
    <>
      <EATitle title={pageTitle} />

      <PageTitle breadcrumbs={[header]}>
        {intl.formatMessage({
          id: headerTitle,
        })}
      </PageTitle>
    </>
  )
}

export default HeaderTitle
