import $ from "jquery"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { apiBaseURL, KTSVG } from "../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { deleteRequest } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import {
	OrderingArrowDown,
	OrderingArrowUp
} from "../../../../_eaFruitsDms/layout/components/orderingArrow/orderingArrow"

type Props = {
  surveyForms: any
  fetchSurveyForms: any
  limit: number
  currentOffset: number
  searchByName: string,
  SurveyFormName: any,
  FormPermissions: any,
  handleOrdering: any,
  showWrite: any,
  showDelete: any,
  showWriteSurvey: any,
  showDeleteSurvey: any,
}
const TableSurveyForms: React.FC<Props> = ({
  surveyForms,
  fetchSurveyForms,
  limit,
  currentOffset,
  searchByName,
  SurveyFormName,
  FormPermissions,
  handleOrdering,
  showWrite,
  showDelete,
  showWriteSurvey,
  showDeleteSurvey
}) => {
  const navigate = useNavigate()

  const [isLoadingDelete, setIsLoadingDelete] = useState<string>('Yes, Proceed')
  const [formId, setFormId] = useState<number>()
  const [formName, setFormName] = useState('')
  const [isAlertSuccess, setIsAlertSuccess] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)
  const [messageTitle, setMessageTitle] = useState<string>('')

  const gotoEditComponent = (id: number) => {
    navigate('/survey-forms/survey-feedback', { state: { id: id } })
  }

  const setFormIdName = (formId: number, formName: string) => {
    setFormId(formId)
    setFormName(formName)
  }
  const removeSurveyForm = async () => {
    setIsLoadingDelete('Please Wait...')
    const deleteSurveyQuestionAPI = `${apiBaseURL()}surveyForms/form/${formId}`

    const res = await deleteRequest(deleteSurveyQuestionAPI, true)

    if (res && res.status == 204) {
      setIsLoadingDelete('Yes, Proceed')
      $('#delete_survey_form').trigger('click')

      setIsAlertSuccess(true)
      setMessageTitle(formName)

      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)

      await fetchSurveyForms(limit, currentOffset, searchByName)
    }
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const navigateToDetailPage = (item: any) => {
    let detail = [item.id, item.survey_form_name, item.create_date, item.conduct_to]
    localStorage.setItem('surveyFormSingle', JSON.stringify(detail))
    navigate('/survey-forms/survey-feedback-name')
  }

  return (
    <>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={FormatMessage('SURVEY_FORM.DELETE', messageTitle)}
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      {surveyForms.length === 0 ? (
        <NoRecords />
      ) : (
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2 table-hover'>
          {/* begin::Table head */}
          <thead>
            <tr className='text-muted fw-500'>
              <th className='min-w-150px'>Survey Form Name
                <span onClick={() => handleOrdering("SurveyFormName")}>
                  {SurveyFormName ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              <th className='min-w-140px'>Conducted To</th>
              <th className='min-w-120px'>Form Permissions
                <span onClick={() => handleOrdering("FormPermissions")}>
                  {FormPermissions ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              <th className='min-w-120px'>Status</th>
              <th className='min-w-80px action-th py-2'>
                  {showWrite  && "Actions"}
              </th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {surveyForms.map((form: any) => {
              return (
                <tr className='cursor-pointer'>
                  <td onClick={() => navigateToDetailPage(form)} className=''>{form.survey_form_name}</td>
                  <td onClick={() => navigateToDetailPage(form)} className=''>{form.conduct_to == 0 ? "-" : form.conduct_to + " " + "Customers"}</td>
                  <td onClick={() => navigateToDetailPage(form)}>
                    <p className='m-0 xs-font'>{form.access_to}</p>
                  </td>
                  <td onClick={() => navigateToDetailPage(form)}>
                    <span className='badge badge-light-success text-dark'>
                      {form.is_active ? 'Active' : form.is_deleted ? 'Deleted' : 'InActive'}
                    </span>
                  </td>
                  <td className='text-end'>
                      {showWrite && <>
                        {
                          form.conduct_to == 0 &&
                          <a
                            href='javascript:void(0)'
                            onClick={() => {
                              gotoEditComponent(form.id)
                            }}
                            className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                          </a>
                        }
                      </>}
                    {/* {showDeleteSurvey && <>
                      {showDelete && <a
                        href='javascript:void(0)'
                        data-bs-toggle='modal'
                        data-bs-target='#deleteSurveyForm'
                        onClick={() => {
                          setFormIdName(form.id, form.survey_form_name)
                        }}
                        className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                      >
                        <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                      </a>}
                    </>} */}
                  </td>
                </tr>
              )
            })}
          </tbody>
          {/* end::Table body */}
        </table>
      )}
      <EaModal
        modalTitle='Delete Survey Form'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{formName}?</span>
          </p>
        }
        closeAuto='delete_survey_form'
        id={'deleteSurveyForm'}
        actionBtnName={isLoadingDelete}
        onSubmit={removeSurveyForm}
      />
    </>
  )
}

export default TableSurveyForms
