import clsx from "clsx";
import $ from "jquery";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { apiBaseURL, focusVatidateField, isModalValidation, staticSearch } from "../../../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage";
import { getRequest, postRequest, putRequest } from "../../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import CustomSunEditor from "../../../../../_eaFruitsDms/layout/components/sunEditor/CustomSunEditor";
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title";
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core";
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader";

const cmsOpenings: Array<PageLink> = [
  {
    title: 'CMS',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Career',
    path: '',
    isSeparator: false,
    isActive: false,
  },
]

export const JobTypes: any = [
  {
    label: 'FullTime',
    value: 'FullTime',
  },
  {
    label: 'PartTime',
    value: 'PartTime',
  },
]
const AddCurrentOpenings = () => {
  const navigate = useNavigate()
  const openingsId = localStorage.getItem('openingsId')

  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'CMS',
  }

  const initialOpenings = {
    title: '',
    subtitle: '',
    jobdiscription: '',
    location: '',
    jobtype: '',
    internship: '',
    is_active: 'true',
  }
  const initialOpeningsValidation = {
    title: '',
    subtitle: '',
    jobtype: '',
    internship: '',
    jobdiscription: '',
    location: '',
    is_active: '',
  }

  const [openings, setOpenings] = useState<any>(initialOpenings)
  const [limit, setLimit] = useState<number>(10)
  const [openingsValidation, setOpeningsValidation] = useState<any>(initialOpeningsValidation)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [currentOpeningsInital, setCurrentOpeningsInitial] = useState<any>(initialOpenings)
  const [isButtonLoader, setIsButtonLoader] = useState<any>('Submit')
  const [accessSts, setAccessSts] = useState<any>(true)
  const [description, setDescription] = useState('')
  const [descriptionValidation, setDescriptionValidation] = useState('')
  const [isResonseLoading, setIsResponseLoading] = useState<any>('')

  useEffect(() => {
    fetchCurrentOpenings().then()
  }, [])

  useEffect(() => {
    setOpenings(currentOpeningsInital)
  }, [currentOpeningsInital])

  const fetchCurrentOpenings = async () => {
    setOpenings(initialOpenings)
    if (openingsId != null) {
      setIsResponseLoading(true)
      let openingsResponse = await getRequest(
        `${apiBaseURL()}career/CurrentOpenings/${openingsId}/`,
        true
      )
      if (openingsResponse) {
        let openingsDetail: any = { ...currentOpeningsInital }

        Object.entries(openingsResponse).map((object: any) => {
          if (object[0] in openingsDetail) {
            return (openingsDetail[object[0]] = object[1])
          }
        })

        setAccessSts(openingsResponse.is_active)
        setCurrentOpeningsInitial(openingsDetail)
        setIsResponseLoading(false)
      }
    }
  }

  const fetchJobTypes = async (search?: any) => {
    let options = JobTypes
    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options,
      hasMore: false,
    }
  }

  const handleChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setOpeningsValidation({ ...openingsValidation, [inputName]: '' })
    let updatedOpenings: any = { ...openings }

    switch (inputName) {
      case 'title':
        updatedOpenings[inputName] = inputValue
        break
      case 'subtitle':
        updatedOpenings[inputName] = inputValue
        break
      case 'jobdiscription':
        updatedOpenings[inputName] = inputValue
        break
      case 'internship':
        updatedOpenings[inputName] = e.target.checked
        break
      case 'location':
        updatedOpenings[inputName] = inputValue
        break
      case 'jobtype':
        updatedOpenings[inputName] = inputValue
        break
    }
    setOpenings(updatedOpenings)
  }

  const handleDescription = (e: any) => {
    setDescriptionValidation('')
    setDescription(e)
  }

  const validateForm = () => {
    const validation = { ...openings }
    let isValid = true

    let navigateField = {
      title: openings.title,
      subtitle: openings.subtitle,
      description: openings.description,
      location: openings.location,
      jobtype: openings.jobtype,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (openings.title === '') {
      validation.title = 'is-invalid'
      isValid = false
    }
    if (openings.subtitle === '') {
      validation.subtitle = 'is-invalid'
      isValid = false
    }
    if (description === '' || description === '<p><br></p>') {
      setDescriptionValidation('is-invalid')
      isValid = false
    }
    if (openings.jobtype === '') {
      validation.jobtype = 'is-invalid'
      isValid = false
    }
    if (openings.location === '') {
      validation.location = 'is-invalid'
      isValid = false
    }

    setOpeningsValidation(validation)
    return isValid
  }

  const addOpenings = () => {
    const isValid = validateForm()
    if (isValid) {
      setIsButtonLoader('Please Wait...')
      let openingsData = new FormData()
      const openingsInfo: any = { ...openings }
      delete openingsInfo.date_view

      openingsInfo.jobdiscription = description

      Object.entries(openingsInfo).map((object: any) => {
        return openingsData.append(object[0], object[1])
      })

      if (openingsId != null) {
        putRequest(`${apiBaseURL()}career/CurrentOpenings/${openingsId}/`, openingsData, true).then(
          (res) => {
            handleResponse(res, 'Edit')
          }
        )
      } else {
        postRequest(`${apiBaseURL()}career/CurrentOpenings/create`, openingsData, true).then(
          (res) => {
            handleResponse(res, 'Add')
          }
        )
      }
    }
  }

  const fetchRedirect = () => {
    navigate('/cms/career/current-openings')
  }

  const handleResponse = (res: any, type: string) => {
    setIsButtonLoader('Submit')
    if (res.status === 201 || res.status === 200 || res.status === 204) {
      setIsAlertSuccess(true)
      setOpenings(initialOpenings)
      localStorage.setItem('type', type)
      localStorage.removeItem('openingsId')
      fetchRedirect()
    } else {
      setIsAlertFailed(true)
    }
    setTimeout(() => {
      setIsAlertFailed(false)
      setIsAlertSuccess(false)
    }, 1500)
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <div>
        {/* <EATitle title='Success Openings - ' /> */}
        <EATitle title='Current Openings - ' />

        {openingsId == null ? (
          <>
            <PageTitle breadcrumbs={cmsOpenings} backNavigate='/cms/career/current-openings'>
              {intl.formatMessage({ id: 'Add Current Openings' })}
            </PageTitle>
          </>
        ) : (
          <PageTitle breadcrumbs={cmsOpenings} backNavigate='/cms/career/current-openings'>
            {intl.formatMessage({ id: 'Edit Current Openings' })}
          </PageTitle>
        )}

        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={FormatMessage('success')}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        {isResonseLoading !== '' && isResonseLoading == true && <IsLoader />}
        <div className='card' >
          {/* begin::Header */}
          <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
            {/* begin::Title */}
            <h3 className='card-title align-items-start flex-column my-0'>
              {openingsId == null ? (
                <>
                  <span className='card-label fw-bold font-17'>Add Current Openings</span>
                </>
              ) : (
                <span className='card-label fw-bold font-17'>Edit Current Openings</span>
              )}
            </h3>
            {/* end::Title */}
          </div>

          {/* <div className='card-body pt-4'> */}
          <form onSubmit={(e) => e.preventDefault()} autoComplete='off' className='card-body pt-4'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='mb-4'>
                  <label className='form-label'>
                    Opening Title<span className="text-danger">*</span>
                  </label>
                  <input
                    type='text'
                    id='title'
                    className={clsx('form-control', openingsValidation.title)}
                    name='title'
                    value={openings.title}
                    placeholder='Type here...'
                    onChange={(e: any) => handleChange(e, 'title')}
                  />
                </div>
                <div className='row'>
                  <div className='mb-4'>
                    <label className='form-label'>
                      Sub Title<span className="text-danger">*</span>
                    </label>
                    <input
                      id='subtitle'
                      className={clsx('form-control', openingsValidation.subtitle)}
                      placeholder='Type here…'
                      value={openings.subtitle}
                      name='subtitle'
                      onChange={(e: any) => handleChange(e, 'subtitle')}
                    ></input>
                  </div>
                  <div className='mb-4'>
                    <label className='form-label' id='jobdiscription'>
                      Job Description <span className="text-danger">*</span>
                    </label>
                    <CustomSunEditor
                      data={openings.jobdiscription}
                      handleChange={handleDescription}
                      fieldName='jobdiscription'
                      validationTriggered={descriptionValidation}
                      message='Job Description Field is required.'
                    />
                  </div>
                  {/* <div className='mb-4'> */}
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <label className='form-check-label me-3'>Publish Opening</label>
                    <input
                      checked={openings.is_active}
                      id='accessStatus'
                      onChange={(e) => {
                        let checkedEdit = $('#accessStatus').is(':checked')
                        if (checkedEdit == true) {
                          setAccessSts(true)
                          setOpenings({ ...openings, ['is_active']: true })
                        } else {
                          setAccessSts(false)
                          setOpenings({ ...openings, ['is_active']: false })
                        }
                      }}
                      value={openings.is_active}
                      className='form-check-input h-30px w-50px'
                      type='checkbox'
                      name='layout-builder[layout][header][fixed][desktop]'
                      defaultChecked={true}
                    />
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='mb-4'>
                    <label className='form-label'>
                      Location<span className="text-danger">*</span>
                    </label>
                    <input
                      type='text'
                      id='location'
                      className={clsx('form-control', openingsValidation.location)}
                      name='location'
                      value={openings.location}
                      placeholder='Type here...'
                      onChange={(e: any) => handleChange(e, 'location')}
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='form-label' id='jobtype'>
                      Job Type<span className="text-danger">*</span>
                    </label>
                    <AsyncPaginate
                    
                      loadOptions={fetchJobTypes}
                      isSearchable
                      placeholder='Select'
                      name='jobtype'
                      className={clsx('react-select-container w-100', openingsValidation.jobtype)}
                      classNamePrefix='react-select'
                      value={JobTypes.find((option: any) => option.value == openings.jobtype) || ''}
                      onChange={(e: any) => handleChange(e, 'jobtype')}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className={clsx(
                          'form-check-input widget-13-check mx-2',
                          openingsValidation.internship
                        )}
                        name='internship'
                        checked={openings.internship}
                        type='checkbox'
                        onChange={(e: any) => handleChange(e, 'internship')}
                        value=''
                        id='internship'
                      />
                      <label className='form-check-label' htmlFor='internship'>
                        Internship
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className='card-footer py-2'>
            <button
              type='button'
              className='btn btn-secondary me-3'
              onClick={() => {
                fetchRedirect()
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              disabled={isButtonLoader !== 'Submit'}
              onClick={() => {
                addOpenings()
              }}
            >
              {isButtonLoader}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddCurrentOpenings
