import clsx from "clsx"
import React from "react"
import { toAbsoluteUrl } from "../../../../_eaFruitsDms/helpers"

const AddBusinessDocuments = ({ customer, handleChange, customerValidation }: any) => {

    return <>
        {/* business vwrification documents */}
        <h3 className='font-14 fw-500 border-bottom pb-4 mb-2 pt-5'>Business Verification Documents (Optional)</h3>

        <div className="d-flex align-items-sm-center border-bottom px-0 py-3">
            <div className='symbol symbol-60px symbol-1by1 me-4'>
                <div
                    className='symbol-label'
                    style={{ backgroundImage: `url(${toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')})` }}
                ></div>
            </div>
            <div className="d-flex flex-row-fluid flex-wrap align-items-center row g-3">
                <div className="col-md-4">
                    <p className='text-gray-800 fw-bold font-17 d-block mb-0'>
                        Business License
                    </p>
                    <p className='mb-0 pt-1'>No. {customer.business_license ? customer.business_license : "-"}</p>
                </div>
                <div className="col-md-4">
                    <div className="mb-4 position-relative">
                        <label className="form-label me-2">Attach Business Licence</label>
                        <label htmlFor='business-lic' className={
                            clsx('form-control file-input text-truncate pe-6'
                                , customerValidation.business_license_file
                            )
                        }>
                            <span
                                className='text-muted'>{customer.business_license_file ? customer.business_license_file.name : "Browse"}</span>
                        </label>
                        <input
                            type="file" id='business-lic' accept="application/pdf, image/*"
                            name="business_license_file"
                            onChange={handleChange}
                            className={clsx('form-control')}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-4">
                        <label className="form-label">Business Licence Number</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            name="business_license"
                            className={clsx('form-control'
                                , customerValidation.business_license
                            )
                            }
                            value={customer.business_license}
                            placeholder="XXXXXXXXXX"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="d-flex align-items-sm-center border-bottom px-0 py-3">
            <div className='symbol symbol-60px symbol-1by1 me-4'>
                <div
                    className='symbol-label'
                    style={{ backgroundImage: `url(${toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')})` }}
                ></div>
            </div>
            <div className="d-flex flex-row-fluid flex-wrap align-items-center row g-3">
                <div className="col-md-4">
                    <p className='text-gray-800 fw-bold font-17 d-block mb-0'>
                        Registration Certificate
                    </p>
                    <p className='mb-0 pt-1'>No. {customer.reg_certificate ? customer.reg_certificate : "-"}</p>
                </div>
                <div className="col-md-4">
                    <div className="mb-4 position-relative">
                        <label className="form-label me-2">Attach Registration Certificate</label>
                        <label htmlFor='business-lic' className={
                            clsx('form-control file-input text-truncate pe-6'
                                , customerValidation.reg_certificate_file
                            )
                        }>
                            <span
                                className='text-muted'>{customer.reg_certificate_file ? customer.reg_certificate_file.name : "Browse"}</span>
                        </label>
                        <input
                            type="file" id='business-lic' accept="application/pdf, image/*"
                            name="reg_certificate_file"
                            onChange={handleChange}
                            className={clsx('form-control')}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-4">
                        <label className="form-label">Registration Certificate Number</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            name="reg_certificate"
                            className={clsx('form-control'
                                , customerValidation.reg_certificate
                            )
                            }
                            value={customer.reg_certificate}
                            placeholder="XXXXXXXXXX"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="d-flex align-items-sm-center border-bottom px-0 py-3">
            <div className='symbol symbol-60px symbol-1by1 me-4'>
                <div
                    className='symbol-label'
                    style={{ backgroundImage: `url(${toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')})` }}
                ></div>
            </div>
            <div className="d-flex flex-row-fluid flex-wrap align-items-center row g-3">
                <div className="col-md-4">
                    <p className='text-gray-800 fw-bold font-17 d-block mb-0'>
                        National ID
                    </p>
                    <p className='mb-0 pt-1'>No. {customer.national_id ? customer.national_id : "-"}</p>
                </div>
                <div className="col-md-4">
                    <div className="mb-4 position-relative">
                        <label className="form-label me-2">Attach National ID</label>
                        <label htmlFor='business-lic' className={
                            clsx('form-control file-input text-truncate pe-6'
                                , customerValidation.national_id_file
                            )
                        }>
                            <span
                                className='text-muted'>{customer.national_id_file ? customer.national_id_file.name : "Browse"}</span>
                        </label>
                        <input
                            type="file" id='business-lic' accept="application/pdf, image/*"
                            name="national_id_file"
                            className={clsx('form-control')}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-4">
                        <label className="form-label">National ID Number</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            name="national_id"
                            className={clsx('form-control'
                                , customerValidation.national_id
                            )
                            }
                            value={customer.national_id}
                            placeholder="XXXXXXXXXX"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AddBusinessDocuments
