import React from 'react'
import {connect} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {KTSVG, shortName} from '../../../../_eaFruitsDms/helpers'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {
  OrderingArrowDown,
  OrderingArrowUp,
} from '../../../../_eaFruitsDms/layout/components/orderingArrow/orderingArrow'
import {addUser} from '../../../../_eaFruitsDms/redux/action/teleSalesUser/teleSalesUser'
import tenantConfiguration from '../../../../TenantVariables'

type SaleUser = {
  teleSaleUsers: any
  fetchDetail: any
  removeDetail: any
  addUser: any
  handleOrdering: any
  Name: any
  Email: any
  showWrite: any
  showDelete: any
}

const TableTeleSales: React.FC<SaleUser> = ({
  teleSaleUsers,
  fetchDetail,
  removeDetail,
  addUser,
  handleOrdering,
  Name,
  Email,
  showDelete,
  showWrite,
}) => {
  const navigate = useNavigate()

  const navigateToTele = (user: any) => {
    addUser(user)
    navigate('/tele-sales/tele-sales-name/overview')
    localStorage.setItem('teleUserId', user.id)
    localStorage.setItem('teleSalesName', user.first_name + ' ' + user.last_name)
  }

  return (
    <>
      {teleSaleUsers.length == 0 ? (
        <NoRecords />
      ) : (
        <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
          {/* begin::Table head */}
          <thead>
            <tr className='text-muted fw-500'>
              <th className='min-w-150px'>
                Name
                <span onClick={() => handleOrdering('Name')}>
                  {Name ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              <th className='min-w-140px'>
                Email
                <span onClick={() => handleOrdering('Email')}>
                  {Email ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              <th className='min-w-120px'>Mobile Number</th>
              <th className='min-w-120px'>Rating</th>
              <th className='min-w-120px'>Status</th>
              <th className='min-w-80px action-th py-2'>
                {showWrite || showDelete ? 'Actions' : ''}
              </th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {teleSaleUsers.map((user: any) => {
              return (
                <tr className='cursor-pointer'>
                  <td onClick={() => navigateToTele(user)}>
                    <div className='d-flex align-items-center'>
                      <span className='rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10'>
                        {shortName(user.first_name + ' ' + user.last_name)}
                      </span>
                      <div className='media-body'>
                        <p className='m-0 xs-font'>{user.first_name + ' ' + user.last_name}</p>
                      </div>
                    </div>
                  </td>
                  <td onClick={() => navigateToTele(user)}>
                    <span className='text-dark d-inline-block mb-1'>{user.email}</span>
                  </td>
                  <td onClick={() => navigateToTele(user)}>
                    <span className='text-dark d-inline-block mb-1'>
                      {user.phone_no && tenantConfiguration.baseCountryCode} {user.phone_no}
                    </span>
                  </td>
                  <td className='text-dark'>
                    {/*<KTSVG path="/media/icons/duotune/general/gen029.svg"*/}
                    {/*       className="ic text-warning me-1"/>*/}
                    {/*4.2*/}-
                  </td>
                  <td onClick={() => navigateToTele(user)}>
                    {user.is_active ? (
                      <span className='badge badge-light-success text-dark'>Active</span>
                    ) : (
                      <span className='badge badge-light text-dark'>Inactive</span>
                    )}
                  </td>
                  <td className='text-end'>
                    {showWrite && (
                      <a
                        href='#'
                        data-bs-toggle='modal'
                        data-bs-target='#ea_modal_create_telesales_user'
                        onClick={() => fetchDetail(user.id)}
                        className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                      </a>
                    )}
                    {/* {showDelete && <a href='#deleteProductSKU'
                                                data-bs-toggle='modal'
                                                data-bs-target='#deleteUser'
                                                onClick={() => removeDetail(user)}
                                                className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>} */}
                  </td>
                </tr>
              )
            })}
          </tbody>
          {/* end::Table body */}
        </table>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUser: (val: any) => {
      dispatch(addUser(val))
    },
  }
}

export default connect(null, mapDispatchToProps)(TableTeleSales)
