import React from 'react'
import ThreeDotsLoader from './threeDotsLoader'

const IsLoader = () => {
  return (
    <div
      className='text-center mb-5'
      style={{
        justifyContent: 'center',
        display: 'flex',
        position: 'absolute',
        left: 0,
        right: 0,
        top: '65px',
        bottom: 0,
        width: '100% !important',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.7)',
        height: '100% !important',
        zIndex: 99,
      }}
    >
      <ThreeDotsLoader />
    </div>
    // <div id="splash-screen1" className="splash-screen-loader">
    //     {/* <!-- <img src="%PUBLIC_URL%/media/logos/Spin-1s-200px.gif" alt="EAfruits logo" class="" /> --> */}
    //     <img src="media/logos/logolight2.png" alt="EAfruits logo" className="h-55px" />
    //     <span className='mb-5'>Loading ...</span>
    // </div>
  )
}

export default IsLoader
