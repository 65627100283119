import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {Props} from 'react-select'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import tenantConfiguration from '../../../TenantVariables'
import {
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdownNew,
  fetchStaticAsyncDropdown,
  isAdmin,
  isEmpty,
  isWarehouseManager,
  numberFormat,
  numberFormatWithCode,
} from '../../../_eaFruitsDms/helpers'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {AsyncPaginate} from 'react-select-async-paginate'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {issueStatus} from './credit-issue-constants'
import IssueStatus from './components/issueStatus'
import moment from 'moment/moment'

const CreditIssues: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const headerTitle = {
    title:
      isAdmin() && location.pathname === '/reports/credit-issues'
        ? 'Credit Issues Report'
        : 'Credit Issues',
    subTitle:
      isAdmin() && location.pathname === '/reports/credit-issues'
        ? 'Credit Issues Report'
        : 'Credit Issues',
  }

  const userType = localStorage.getItem('user_type') || ''
  let currentWarehouseId = !['sa', 'ts'].includes(userType.toLowerCase())
    ? localStorage.getItem('w_id')
    : ''

  if (isAdmin()) {
    currentWarehouseId = localStorage.getItem('wId') ? localStorage.getItem('wId') : ''
  }

  const [creditIssueCount, setCreditIssueCount] = useState<any>({
    open_ci_count: 0,
    open_ci_amount: 0,
    overdue_ci_count: 0,
    overdue_ci_amount: 0,
  })
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [creditIssues, setCreditIssues] = useState<any>([])
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [customers, setCustomers] = useState<any>([])
  const [customerReset, setCustomerReset] = useState<any>(moment())
  const [customerOffSet, setCustomerOffSet] = useState<string>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [salesRepsOffSet, setSalesRepsOffSet] = useState<any>('')
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouseOffSet, setWarehouseOffSet] = useState<any>('')
  const [warehouseId, setWarehouseId] = useState<any>({label: 'All Warehouses', value: ''})
  const [customer, setCustomer] = useState<any>({
    label: 'All Customers',
    value: '',
  })
  const [sr, setSr] = useState<any>({
    label: 'All Sales Reps',
    value: '',
  })
  const [status, setStatus] = useState<any>({
    label: 'All Status',
    value: '',
  })
  const [isHoreca, setIsHoreca] = useState(false)

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    localStorage.removeItem('is-horeca')
    localStorage.removeItem('selected-search')
    localStorage.removeItem('selected-status')
    localStorage.removeItem('selected-sr')
    localStorage.removeItem('selected-customer')
    localStorage.removeItem('selected-customer-name')
  }, [])

  useEffect(() => {
    fetchCreditIssues().then()
  }, [currentOffset, customer, sr, debounceSearch, status, warehouseId, isHoreca])

  const fetchCreditIssues = async () => {
    setDisplayLoader(true)
    let customerId = customer.value

    if (
      (isAdmin() || isWarehouseManager() || checkUserType(['ts'])) &&
      location.pathname === '/customers/singlecustomer/credit-issue'
    ) {
      customerId = localStorage.getItem('cId')
    }

    let filteredWarehouse = warehouseId.value

    if (isEmpty(filteredWarehouse)) {
      if (
        ['/warehouse/DC/singlewarehousename/credit-issues'].includes(location.pathname) ||
        checkUserType(['am'])
      ) {
        filteredWarehouse = currentWarehouseId
      }
    }

    const statusValue = status.value === 'open' ? 'open,overdue' : status.value

    const creditResponse = await getRequest(
      `${apiBaseURL()}credit/issues?meta=true&search=${debounceSearch}&warehouse_id=${
        !isEmpty(filteredWarehouse) ? filteredWarehouse : ''
      }&customer_id=${customerId}&sr_id=${
        sr.value
      }&issue_status=${statusValue}&limit=${limit}&offset=${currentOffset}&ordering=-id&is_horeca_customer=${
        location.pathname.includes('/reports/credit-issues') ? (isHoreca === true ? true : '') : ''
      }`,
      true
    )

    if (!isEmpty(creditResponse.results)) {
      setCreditIssues(creditResponse.results)
      setCount(creditResponse.count)

      if (!isEmpty(creditResponse.meta)) {
        setCreditIssueCount({
          open_ci_count: creditResponse.meta.open_ci_count,
          open_ci_amount: creditResponse.meta.open_ci_amount,
          overdue_ci_count: creditResponse.meta.overdue_ci_count,
          overdue_ci_amount: creditResponse.meta.overdue_ci_amount,
        })
      }
    }

    setDisplayLoader(false)
  }

  const fetchCustomers = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
      setCustomerOffSet('')
    }

    let url = ''
    if (location.pathname.includes('/credit-issues') && checkUserType(['sa'])) {
      url = `&warehouse_id=`
    } else {
      url = `&warehouse_id=${isEmpty(currentWarehouseId) ? '' : currentWarehouseId}`
    }

    const customerResponse = await getRequest(
      `${apiBaseURL()}customers/drop-down?ordering=business_name&on_boarding_status=Approved&search=${search}&limit=${limit}&offset=${customerOffSet}` +
        url,
      true
    )

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if (customerResponse.next !== null) {
      const queryParams = new URLSearchParams(customerResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCustomerOffSet(newOffset)
      hasMore = true
    }

    if (customers.length === 0) {
      options.push({
        label: 'All Customers',
        value: '',
      })
    }

    if (customerResponse.results.length > 0) {
      customerResponse.results.map((option: any) => {
        options.push({
          label: option.business_name + ', ' + option.street,
          value: option.id,
        })
        optionData.push(option)
      })
    }

    if (customers.length > 0) {
      setCustomers(customers.concat(optionData))
    } else {
      setCustomers(optionData)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
      setWarehouseOffSet('')
    }

    let options: any = []

    let url = ''
    if (location.pathname.includes('/credit-issues') && checkUserType(['sa'])) {
      url = `&parent_warehouse__id=`
    } else {
      url = `&parent_warehouse__id=${currentWarehouseId}`
    }

    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&is_parent=True` +
        url,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSalesReps = async (search?: any) => {
    const wId =
      isAdmin() &&
      (location.pathname === '/customers/singlecustomer/credit-issue' ||
        location.pathname.includes('/credit-issues'))
        ? ''
        : isEmpty(currentWarehouseId)
        ? ''
        : currentWarehouseId

    return await fetchAsyncDropdownNew(
      salesRepsOffSet,
      setSalesRepsOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `auth/users/team/list?ordering=first_name&warehouse__id=${wId}&type=sr`,
      search,
      setSearchSelect,
      'first_name',
      'last_name',
      true,
      'All Sales Reps'
    )
  }

  const fetchIssueStatuses = (search?: string) => {
    return fetchStaticAsyncDropdown(issueStatus, search)
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e
    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'warehouse':
        localStorage.setItem('selected-wid', inputValue?.value ?? '')
        setWarehouseId(e)
        setCustomerOffSet('')
        setCustomers([])
        setCustomerReset(moment())
        setSalesRepsOption([])
        break
      case 'customer':
        localStorage.setItem('selected-customer', inputValue.value)
        localStorage.setItem('selected-customer-name', e.label)
        setCustomer(inputValue)
        break
      case 'sales':
        setSr(inputValue)
        localStorage.setItem('selected-sr', inputValue.value)
        break
      case 'status':
        const status = inputValue.value === 'open' ? 'open,overdue' : inputValue.value
        localStorage.setItem('selected-status', status)
        setStatus(inputValue)
        break
      default:
        localStorage.setItem('selected-search', inputValue)
        setSearchByName(inputValue)
        break
    }
  }

  const goToDetailPage = async (creditIssueId: number) => {
    setDisplayLoader(true)
    const response = await getRequest(`${apiBaseURL()}credit/issues/${creditIssueId}`, true)

    if (response && response.result) {
      if (isAdmin()) {
        if (location.pathname === '/customers/singlecustomer/credit-issue') {
          navigate('/credit-issue/credit-issues-detail', {
            state: {credit_issue: response.result, creditIssueId: creditIssueId},
          })
        } else if (location.pathname === '/reports/credit-issues') {
          navigate('/reports/credit-issues/credit-issues-detail', {
            state: {credit_issue: response.result, reports: 'report', creditIssueId: creditIssueId},
          })
        } else {
          navigate('/credit-issues/credit-issues-detail', {
            state: {credit_issue: response.result, creditIssueId: creditIssueId},
          })
        }
      } else {
        if (location.pathname === '/customers/singlecustomer/credit-issue') {
          navigate('/credit-issue/credit-issues-detail', {
            state: {credit_issue: response.result, creditIssueId: creditIssueId},
          })
        } else {
          navigate('credit-issues-detail', {
            state: {credit_issue: response.result, creditIssueId: creditIssueId},
          })
        }
      }
    }

    setDisplayLoader(false)
  }

  useEffect(() => {
    localStorage.setItem('selected-wid', '')
  }, [])

  return (
    <>
      <EATitle
        title={
          isAdmin() && location.pathname === '/reports/credit-issues'
            ? 'Credit Issues Report'
            : 'Credit Issues'
        }
      />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id:
            isAdmin() && location.pathname === '/reports/credit-issues'
              ? 'Credit Issues Report'
              : 'Credit Issues',
        })}
      </PageTitle>
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={`${numberFormat(creditIssueCount.open_ci_count).displayFormat} Credit Issues`}
            path='/warehouse/DC'
            description='Open Credit Issues Count'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='info'
            title={`${numberFormatWithCode(creditIssueCount.open_ci_amount).displayFormat}`}
            description='Open Credit Issues Amount'
            path='/products'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='dark'
            title={`${numberFormat(creditIssueCount.overdue_ci_count).displayFormat} Credit Issues`}
            description='Overdue Credit Issues Count'
            path='/customers'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={`${numberFormatWithCode(creditIssueCount.overdue_ci_amount).displayFormat}`}
            description='Overdue Credit Issues Amount'
            path='/reports/sales-report'
          />
        </div>
      </div>
      <div className={`card ${className}`}>
        {displayLoader && <IsLoader />}
        {/* {displayLoader && <IsLoader />} */}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <div className='form-check form-check-sm form-check-custom form-check-solid me-2'>
                <input
                  className='form-check-input widget-13-check'
                  name='isHoreca'
                  checked={isHoreca}
                  onChange={(e: any) => {
                    setIsHoreca(!isHoreca)
                    localStorage.setItem('is-horeca', !isHoreca ? 'true' : 'false')
                    handleFilterChange(e, 'isHoreca')
                  }}
                  type='checkbox'
                  disabled={false}
                  value=''
                  id='isHoreca'
                />
                <label className='form-check-label' htmlFor='isHoreca'>
                  HORECA Only
                </label>
              </div>

              {['sa', 'wm'].includes(userType.toLowerCase()) &&
                ['/credit-issues', '/reports/credit-issues'].includes(location.pathname) && (
                  <AsyncPaginate
                    loadOptions={fetchWarehouses}
                    isDisabled={displayLoader}
                    isSearchable
                    className='react-select-container my-1 me-3 w-160px'
                    classNamePrefix='react-select'
                    placeholder='All Warehouses'
                    // value={warehouses.find((option: any) => option.value === warehouseId)}
                    value={warehouseId}
                    onChange={(e: any) => handleFilterChange(e, 'warehouse')}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
              {(!isAdmin() || !isWarehouseManager()) &&
                !['/customers/singlecustomer/credit-issue'].includes(location.pathname) && (
                  <AsyncPaginate
                    key={customerReset}
                    loadOptions={fetchCustomers}
                    isDisabled={displayLoader}
                    isSearchable
                    className='react-select-container w-150px me-3'
                    classNamePrefix='react-select'
                    menuPosition='fixed'
                    name='customer'
                    value={customer}
                    onChange={(e: any) => handleFilterChange(e, 'customer')}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
              <AsyncPaginate
                loadOptions={fetchSalesReps}
                isSearchable
                isDisabled={displayLoader}
                placeholder='All Sales Reps'
                className='react-select-container my-1 me-3 w-140px'
                classNamePrefix='react-select'
                name='sales'
                onChange={(e: any) => handleFilterChange(e, 'sales')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchIssueStatuses}
                isSearchable
                isDisabled={displayLoader}
                placeholder='All Status'
                className='react-select-container my-1 me-3 w-140px'
                classNamePrefix='react-select'
                name='sales'
                onChange={(e: any) => handleFilterChange(e, 'status')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          <div className='table-responsive'>
            {creditIssues.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-100px w-100px'>Order No</th>
                    <th className='min-w-120px'>Order Amount</th>
                    <th className='w-120px min-w-120px'>Delivery Date</th>
                    <th className='min-w-160px'>Customer Name</th>
                    <th className='w-150px min-w-150px'>SR Name</th>
                    <th className='min-w-150px'>Due Amount</th>
                    <th className='min-w-150px'>Due Date</th>
                    <th className='min-w-100px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {creditIssues.map((creditIssue: any) => {
                    return (
                      <tr onClick={() => goToDetailPage(creditIssue.id)} className='cursor-pointer'>
                        <td>{isEmpty(creditIssue.order_no) ? '-' : `#${creditIssue.order_no}`}</td>
                        <td>
                          {isEmpty(creditIssue.total_amount)
                            ? '-'
                            : numberFormatWithCode(creditIssue.total_amount).displayFormat}
                        </td>
                        <td>
                          {isEmpty(creditIssue.delivery_date)
                            ? '-'
                            : dateFormat(creditIssue.delivery_date).displayDate}
                        </td>
                        <td>
                          {isEmpty(creditIssue.customer_name) ? '-' : creditIssue.customer_name}
                        </td>
                        <td>{isEmpty(creditIssue.sr_name) ? '-' : creditIssue.sr_name}</td>
                        <td>
                          {isEmpty(creditIssue.due_amount)
                            ? '-'
                            : numberFormatWithCode(creditIssue.due_amount).displayFormat}
                        </td>
                        <td>
                          {isEmpty(creditIssue.due_date)
                            ? '-'
                            : dateFormat(creditIssue.due_date).displayDate}
                        </td>
                        <td>
                          <IssueStatus
                            status={creditIssue.issue_status}
                            dueDate={creditIssue.due_date}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
            )}
          </div>
        </div>
      </div>
      <CustomPaginate
        data={creditIssues}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default CreditIssues
