import clsx from 'clsx'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  GLOBAL_DATE_FORMAT,
  globalDateFormate,
  KTSVG,
} from '../../../../_eaFruitsDms/helpers'
import {categoryStyle} from '../../../../_eaFruitsDms/helpers/components/categoryStyle'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'

type Props = {
  className: string
}
type modalType = {
  startDateApi: string
  startDateView: string
  endDateApi: string
  endDateView: string
}

const ManualStockChangeReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Manual Stock Change Report',
  }
  const currentWarehouseId: any = localStorage.getItem('w_id')

  const [searchSelect, setSearchSelect] = useState('')
  const [categoryOffset, setCategoryOffset] = useState<any>('')
  const [categoryOption, setCategoryOption] = useState<any>([])
  const [brandOffset, setBrandOffset] = useState<any>('')
  const [brandOption, setBrandOption] = useState<any>([])
  const [productOffSet, setProductOffSet] = useState<any>('')
  const [productSkus, setProductSkus] = useState<any>([])
  const [limit, setLimit] = useState<number>(10)
  const [reasonOffset, setReasonOffset] = useState<any>('')
  const [reasonOption, setReasonOption] = useState<any>([])
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(0)
  const [selectedSku, setSelectedSku] = useState<any>('')
  const [selectedCategory, setSelectedCategory] = useState<any>('')
  const [selectedBrand, setSelectedBrand] = useState<any>('')
  const [selectedReason, setSelectedReason] = useState<any>('')
  const [searchByName, setSearchByName] = useState<string>('')
  const [product, setProduct] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')

  const [dateRange, setDateRange] = useState<modalType>({
    startDateApi: '',
    startDateView: 'Select',
    endDateApi: '',
    endDateView: '',
  })
  const [initialSettings, setInitialSettings] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    autoUpdateInput: false,
    cancelClass: 'btn-secondary',
    drops: 'down',
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    },
    alwaysShowCalendars: true,
  })
  const [dateHandler, setDateHandler] = useState({
    firstDate: [moment().startOf('month').format('YYYY-MM-DD')],
    lastDate: [moment().endOf('month').format('YYYY-MM-DD')],
  })

  const debounceSearch = useDebounce(searchByName, 500)

  const modalValueHandler = (e: any, checkBox: any) => {
    setDateRange((preValue: any) => ({
      ...preValue,
      startDateApi: e.startDateApi,
      endDateApi: e.endDateApi,
      startDateView: e.startDateView,
      endDateView: e.endDateView,
    }))

    setDateHandler({firstDate: e.startDateApi, lastDate: e.endDateApi})
  }

  useEffect(() => {
    setTimeout(() => fetchExpiredProductList().then(), timeoutSeconds)
  }, [
    currentOffset,
    count,
    product.length,
    dateHandler.firstDate,
    dateHandler.lastDate,
    selectedBrand,
    selectedCategory,
    selectedReason,
    selectedSku,
    debounceSearch,
  ])

  const fetchExpiredProductList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}inventory/update_stock_list/?warehouse_id__id=${currentWarehouseId}&ordering=-change_date&search=${debounceSearch}&offset=${currentOffset}&product_id__brand_id__id=${selectedBrand}&category_id=${selectedCategory}&reason=${selectedReason}&product_id=${selectedSku}&start_date=${
      dateHandler.firstDate
    }&end_date=${dateHandler.lastDate}`
    const response = await getRequest(API, true)
    setProduct(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }

  const CustomDateRangePicker = () => {
    return (
      <DateRangePicker
        initialSettings={initialSettings}
        onEvent={(e) => {
          e.preventDefault()
        }}
        onCallback={(start, end, label) => {
          setInitialSettings({
            startDate: start._d,
            endDate: end._d,
            cancelClass: 'btn-secondary',
            drops: 'down',
            autoUpdateInput: false,
            locale: {
              format: GLOBAL_DATE_FORMAT,
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
            alwaysShowCalendars: true,
          })

          let newStartDate = dateFormat(start._d)
          let newEndDate = dateFormat(end._d)
          let startDate = start._d
          let endDate = end._d

          let dateObj = {
            startDateView: newStartDate.displayDate,
            endDateView: newEndDate.displayDate,

            startDateApi: newStartDate.apiDate,
            endDateApi: newEndDate.apiDate,
          }
          modalValueHandler(dateObj, '')
        }}
        onCancel={(event: any, picker: any) => {
          setDateRange((preValue: any) => ({
            ...preValue,
            startDateView: 'Select',
          }))
        }}
      >
        <input
          id='datePickerFocus'
          type='text'
          onFocus={() => {
            // $('.daterangepicker').css('display', 'block')
          }}
          className='form-control calendar'
          //   className={clsx('form-control calendar', validation.startDateView)}
          readOnly
          value={
            dateRange.startDateView === 'Select'
              ? [moment().startOf('month').format(globalDateFormate())] +
                '-' +
                [[moment().endOf('month').format(globalDateFormate())]]
              : dateRange.startDateView + ' - ' + dateRange.endDateView
          }
        />
      </DateRangePicker>
    )
  }
  const fetchBrands = async (search?: any) => {
    return await fetchAsyncDropdown(
      brandOffset,
      setBrandOffset,
      brandOption,
      setBrandOption,
      `master/product/brand?`,
      search,
      setSearchSelect,
      '',
      true,
      'All Brands'
    )
  }
  const fetchCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffset}`,
      true
    )

    let hasMore: boolean = false

    if (categoriesResponse.next !== null) {
      const queryParams = new URLSearchParams(categoriesResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffset(newOffset)
      hasMore = true
    }

    if (categoryOption.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    if (categoriesResponse.results.length > 0) {
      categoriesResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          color: '#6d6e6f',
          bg: '#f7f7f7',
          transform: 'uppercase',
          boxSizing: 'borderBox',
          weight: '500',
          cursor: 'default',
          size: '.75rem !important',
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }

    if (categoryOption.length > 0) {
      setCategoryOption(categoryOption.concat(options))
    } else {
      setCategoryOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchProductsku = async (search?: any) => {
    return await fetchAsyncDropdown(
      productOffSet,
      setProductOffSet,
      productSkus,
      setProductSkus,
      `inventory/product-SKUs/?warehouse_id=${currentWarehouseId}&ordering=name`,
      search,
      setSearchSelect,
      '',
      true,
      'All SKU'
    )
  }
  const featchReason = async (search?: any) => {
    const response = await fetchAsyncDropdown(
      reasonOffset,
      setReasonOffset,
      reasonOption,
      setReasonOption,
      `master/options-master/?type=Manual_Stock_Update`,
      search,
      setSearchSelect,
      'option',
      true,
      'All Reason'
    )
    response.options.push({
      label: 'Other',
      value: 'other',
    })
    return response
  }
  const handleFilterOptionChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'brand':
        setTimeoutSeconds(0)
        setSelectedBrand(inputValue)
        break
      case 'category':
        setTimeoutSeconds(0)
        setSelectedCategory(inputValue)
        break
      case 'productSku':
        setTimeoutSeconds(0)
        setSelectedSku(inputValue)
        break
      case 'reason':
        setTimeoutSeconds(0)
        setSelectedReason(inputValue)
        break
      case 'search':
        setTimeoutSeconds(500)
        setSearchByName(inputValue)
        break
    }
  }
  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }
  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }
  return (
    <>
      <EATitle title='Manual Stock Change Report -' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Manual Stock Change Report'})}
      </PageTitle>
      <div className='card'>
        {listLoadder && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={(e: any) => handleFilterOptionChange(e, 'search')} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0 d-flex me-3'>
                <CustomDateRangePicker />
              </div>
              <AsyncPaginate
                loadOptions={fetchBrands}
                isSearchable
                placeholder='All Brands'
                className={clsx('react-select-container my-1 me-3 w-120px', '')}
                classNamePrefix='react-select'
                name='brand'
                onChange={(e: any) => handleFilterOptionChange(e, 'brand')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchCategory}
                isSearchable
                placeholder='All Categories'
                className={clsx('react-select-container my-1 me-3 w-135px', '')}
                classNamePrefix='react-select'
                styles={categoryStyle}
                name='category'
                onChange={(e: any) => handleFilterOptionChange(e, 'category')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchProductsku}
                isSearchable
                placeholder='All SKU'
                className={clsx('react-select-container my-1 me-3 w-100px', '')}
                classNamePrefix='react-select'
                name='productSku'
                onChange={(e: any) => handleFilterOptionChange(e, 'productSku')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={featchReason}
                isSearchable
                placeholder='All Reason'
                className={clsx('react-select-container my-1 me-3 w-120px', '')}
                classNamePrefix='react-select'
                name='reason'
                onChange={(e: any) => handleFilterOptionChange(e, 'reason')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <button
                type='button'
                className='btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center'
              >
                <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
                Export
              </button>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {product.length == 0 ? (
              <NoRecords />
            ) : (
              <>
                <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                  <thead>
                    <tr className=' text-muted fw-500'>
                      <th className='min-w-110px py-2'>Batch Number</th>
                      <th className='min-w-110px py-2'>Product SKU</th>
                      <th className='min-w-100px py-2'>Brand</th>
                      <th className='min-w-120px py-2'>Category</th>
                      <th className='min-w-100px py-2'>Size</th>
                      <th className='min-w-100px py-2'>Change</th>
                      <th className='min-w-150px py-2'>Reason</th>
                      <th className='min-w-100px py-2'>Change By</th>
                      <th className='min-w-100px py-2'>Change Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.map((item: any) => {
                      let approxWeight = 0

                      if (item.product_id && item.product_id.variants.approx_weight > 0) {
                        approxWeight = item.product_id.variants.approx_weight / 1000
                      }

                      return (
                        <tr>
                          <td>{item.batch_number}</td>
                          <td>{item.product_id && item.product_id.name}</td>
                          <td>{item.product_id && item.product_id.brand_id}</td>
                          <div>
                            <div className='text-muted font-13 mb-1 font-medium'>
                              {item.product_id && item.product_id.category_id.parent_category.name}
                            </div>
                            {item.product_id && item.product_id.category_id.name}
                          </div>
                          <td>
                            {' '}
                            {approxWeight > 0
                              ? approxWeight + ' Kgs'
                              : (item.product_id.measurement_unit.toLowerCase() === 'gram'
                                  ? item.product_id.size / 1000
                                  : item.product_id.size) + ' Kgs'}
                          </td>

                          <td>
                            {item.qty_difference && item.qty_difference <= 0
                              ? item.qty_difference
                              : '+' + item.qty_difference}
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <span>
                                {item.reason && item.reason.option
                                  ? item.reason.option
                                  : item.reason_note}
                              </span>
                              {/* {item.reason_note ? (
                                    <>
                                      {' '}
                                      <OverlayTrigger
                                        delay={{hide: 300, show: 300}}
                                        overlay={(props) => (
                                          <Tooltip {...props} className='tooltip-dark long-tooltip'>
                                            {item.reason_note}
                                          </Tooltip>
                                        )}
                                        placement='top'
                                      >
                                        <a className='ms-2 text-hover-primary cursor-pointer'>
                                          <KTSVG
                                            path='/media/icons/duotune/communication/com003.svg'
                                            className='ic text-dark mr-0'
                                          />
                                        </a>
                                      </OverlayTrigger>
                                    </>
                                  ) : (
                                    ''
                                  )} */}
                            </div>
                          </td>
                          <td>
                            {item.action_by.first_name} {item.action_by.last_name}
                          </td>
                          <td>
                            {item.change_date ? dateFormat(item.change_date).displayDate : '-'}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${product.length == 0 ? 0 : selected * 10 + 1} to ${
              product.length == 0 ? 0 : product.length < 10 ? count : (selected + 1) * 10
            } of ${product.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              count={product.length == 0 ? 0 : count}
              selected={selected}
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ManualStockChangeReport
