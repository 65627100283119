export const getLoginKey = localStorage.getItem('eafruitsLogin') // done - not used anywhere
export const getEmail = () => localStorage.getItem('email') // done
export const getUserId = () => localStorage.getItem('user_id') // done
export const getW_Id: any = localStorage.getItem('w_id') // done
export const getRoleId = localStorage.getItem('roleId') // done
export const getToken = localStorage.getItem('token') // done
export const getUserType = localStorage.getItem('user_type') // done
export const getroleId = () => localStorage.getItem('roleId') // done
export const getRoleName = localStorage.getItem('roleName') // done
export const getTimeZone = localStorage.getItem('timeZone') // done
export const userLoginDetails = () => localStorage.getItem('userDetails') // done
export const cId = localStorage.getItem('cId') // done
