import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  dateIsToday,
  isEmpty,
  KTSVG,
  numberFormat,
  numberFormatWithCode,
} from '../../../../_eaFruitsDms/helpers'
import {StaticFilter} from '../../../../_eaFruitsDms/helpers/components/StaticFilter'
import {getRequest, patchRequest} from '../../../../_eaFruitsDms/https/apiCall'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import tenantConfiguration from '../../../../TenantVariables'

const CashDeposits = () => {
  const paymentIssueId = localStorage.getItem('paymentIssueId')
  const userType = localStorage.getItem('user_type') || ''

  const [cashDeposits, setCashDeposits] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(true)
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(0)
  const [metaData, setMetaData] = useState<any>([])
  const [showVerifyRef, setShowVerifyRef] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [refNumber, setRefNumber] = useState('')
  const [refNumberValid, setRefNumberValid] = useState('')
  const [refImg, setRefImg] = useState('')
  const [verificationStatus, setVerificationStatus] = useState('')
  const [details, setDetails] = useState<any>([])
  const [fetchingDetails, setFetchingDetails] = useState<any>([])
  const [creditIssueId, setCreditIssueId] = useState('')
  const [currentCreditIssueId, setCurrentCreditIssueId] = useState('')
  const [error, setError] = useState('')
  const [status, setStatus] = useState({label: 'All Status', value: ''})

  useEffect(() => {
    setTimeout(() => fetchTransactions().then(), timeoutSeconds)
  }, [status])

  const fetchTransactions = async () => {
    setIsLoading(true)

    const url = `${apiBaseURL()}payment/issues/${paymentIssueId}/cash-deposits?ordering=-updated_date&status=${
      isEmpty(status?.value) ? '' : status?.value
    }`

    const response = await getRequest(url, true)

    if (!isEmpty(response.results)) {
      setCashDeposits(response.results)
    }

    setMetaData(response.meta)
    setIsLoading(false)
  }

  const handleFilterChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e

    switch (inputName) {
      case 'status':
        setTimeoutSeconds(0)
        setStatus(inputValue)
        break
    }
  }

  const clearVerifyRefModal = () => {
    setShowVerifyRef(false)
    setRefNumber('')
    setRefNumberValid('')
    setRefImg('')
    setVerificationStatus('')
    setError('')
  }

  const fetchRefDetails = async (id: string) => {
    if (id === currentCreditIssueId) {
      return false
    }

    setCurrentCreditIssueId(id)
    setFetchingDetails(true)

    const url = `${apiBaseURL()}payment/issues/${paymentIssueId}/cash-deposits/${id}`

    const response = await getRequest(url, true)

    if (response?.results) {
      setDetails(response?.results)
    } else {
      setDetails([])
    }

    setFetchingDetails(false)
  }

  const onVerify = async () => {
    if (isEmpty(refNumber?.trim())) {
      setRefNumberValid('is-invalid')

      return false
    }

    setIsVerifying(true)

    const url = `${apiBaseURL()}payment/issues/${paymentIssueId}/cash-deposits/${creditIssueId}/verify`

    const body = {
      ref_no: refNumber?.trim(),
    }

    const response = await patchRequest(url, body, true)

    setIsVerifying(false)

    if (response?.data?.error?.field === 'ref_no_not_match') {
      setError('Ref No does not match with Cash Deposit!')

      return false
    }

    if (response?.data?.error?.field === 'already_verified') {
      setError('Ref no of this credit issue has been already verified!')

      return false
    }

    if (response?.status === 200) {
      clearVerifyRefModal()
      fetchTransactions()
    }
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <div className='ms-auto'>
            <div className='card-toolbar my-0'>
              <StaticFilter
                option={[
                  {
                    label: 'All Status',
                    value: '',
                  },
                  {
                    label: 'Verified',
                    value: 'verified',
                  },
                  {
                    label: 'Not Verified',
                    value: 'pending',
                  },
                ]}
                handleFilterChange={(e: any) => handleFilterChange(e, 'status')}
                isDisabled={isLoading}
                value={status}
              />
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div
            className='table-responsive expandable'
            style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
            id='expandTable'
          >
            {isLoading ? (
              <FetchingRecords />
            ) : (
              <>
                {cashDeposits.length == 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-120px'>Receivable</th>
                        <th className='min-w-120px'>Deposited</th>
                        <th className='min-w-120px'>Balance</th>
                        <th className='min-w-220px'>Payment Mode</th>
                        <th className='min-w-100px'>Ref. No.</th>
                        <th className='min-w-100px'>Verify Ref. No.</th>
                        <th className='min-w-100px'>Action</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {cashDeposits.map((item: any) => {
                        return (
                          <>
                            <tr>
                              <td>
                                {!isEmpty(item?.receivable_amount)
                                  ? `${tenantConfiguration.currencyCode}` +
                                    ' ' +
                                    `${numberFormat(item?.receivable_amount).displayFormat}`
                                  : '-'}
                              </td>
                              <td>
                                {isEmpty(item?.received_amount)
                                  ? '-'
                                  : numberFormatWithCode(item?.received_amount ?? 0).displayFormat}
                              </td>
                              <td
                                className={clsx(
                                  isEmpty(item?.credit_request_id)
                                    ? ''
                                    : 'text-decoration-line-through'
                                )}
                              >
                                {!isEmpty(item?.diff_amount)
                                  ? `${tenantConfiguration.currencyCode}` +
                                    ' ' +
                                    `${numberFormat(item?.diff_amount).displayFormat}`
                                  : '-'}
                              </td>
                              <td>{item && item?.payment_mode ? item.payment_mode : '-'}</td>
                              <td>{item && item?.ref_no ? item.ref_no : '-'}</td>
                              <td>
                                {isEmpty(item?.ref_no_verification_status) ? (
                                  '-'
                                ) : item?.ref_no_verification_status === 'pending' ? (
                                  <span className='text-danger'>Not Verified</span>
                                ) : (
                                  <span className='text-success'>Verified</span>
                                )}
                              </td>
                              <td>
                                <div
                                  onClick={(e) => {
                                    setRefImg(item?.payment_ref_img)
                                    setVerificationStatus(item?.ref_no_verification_status ?? '')
                                    setShowVerifyRef(true)
                                    setCreditIssueId(item?.id)
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/communication/com008.svg'
                                    className='me-1 cursor-pointer'
                                  />
                                </div>
                              </td>

                              <td
                                onClick={() => {
                                  fetchRefDetails(item?.id ?? '')
                                }}
                                data-bs-toggle='collapse'
                                data-bs-target={`.row-collapse${item?.id}`}
                                aria-controls={`.rowCollapse${item?.id}`}
                                className='p-4 cursor-pointer'
                              ></td>
                            </tr>

                            <tr className='expandable-row'>
                              <td className='reset-expansion-style' colSpan={9}>
                                <div
                                  className={`row-expansion-style collapse row-collapse${item?.id} collapsible`}
                                  id={`rowCollapse${item?.id}`}
                                  data-bs-parent='#expandTable'
                                >
                                  {fetchingDetails ? (
                                    <FetchingRecords />
                                  ) : details?.length === 0 ? (
                                    <NoRecords />
                                  ) : (
                                    <div>
                                      <table className='table table-bordered gs-4 mb-0'>
                                        <thead>
                                          <tr className='text-muted fw-500 bg-light2'>
                                            <th className='w-200px py-2'>ID</th>
                                            <th className='w-250px py-2'>Customer Name</th>
                                            <th className='w-250px py-2'>Receivable</th>
                                            <th className='w-200px py-2'>Deposited</th>
                                            <th className='min-w-120px py-2'>Balance</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {details.map((i: any) => {
                                            return (
                                              <tr>
                                                <td>{checkEmpty(i?.description)}</td>
                                                <td>
                                                  {isEmpty(i?.customer_name) ||
                                                  isEmpty(i?.customer_street)
                                                    ? '-'
                                                    : `${i?.customer_name},${i?.customer_street}`}
                                                </td>
                                                <td>
                                                  {isEmpty(i?.receivable_amount)
                                                    ? '-'
                                                    : numberFormatWithCode(
                                                        i?.receivable_amount ?? 0
                                                      ).displayFormat}
                                                </td>
                                                <td>
                                                  {isEmpty(i?.received_amount)
                                                    ? '-'
                                                    : numberFormatWithCode(i?.received_amount ?? 0)
                                                        .displayFormat}
                                                </td>
                                                <td>
                                                  {isEmpty(i?.diff_amount)
                                                    ? '-'
                                                    : numberFormatWithCode(i?.diff_amount)
                                                        .displayFormat}
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      {!metaData || isEmpty(metaData.sr_user_name) ? '-' : metaData.sr_user_name}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                    <span className='d-flex align-items-center me-6 mb-2'>
                      <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                      {!metaData || isEmpty(metaData.date)
                        ? '-'
                        : dateFormat(metaData.date).displayDate}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='ms-auto'>
              {metaData &&
              metaData.unsettled_cash_amount === 0 &&
              metaData.unsettled_pay_later === 0 ? (
                <>
                  <span className='badge badge-light-success text-dark cursor-pointer'>
                    Settled
                  </span>
                </>
              ) : (
                <>
                  <div>
                    <span className='text-danger font-medium mx-2'>
                      {`${
                        numberFormatWithCode(
                          metaData && !isEmpty(metaData.unsettled_cash_amount)
                            ? metaData.unsettled_cash_amount
                            : 0
                        ).displayFormat
                      } `}
                      Unsettled Cash
                    </span>
                    {metaData &&
                      metaData.unsettled_cash_amount > 0 &&
                      !['wm', 'sa'].includes(userType.toLowerCase()) && (
                        <button
                          className='btn btn-primary cursor-pointer'
                          data-bs-target='#ea_modal_payment_settlement'
                          data-bs-toggle='modal'
                        >
                          Settle Cash
                        </button>
                      )}
                  </div>
                  {metaData &&
                    metaData.unsettled_pay_later > 0 &&
                    !isEmpty(metaData.date) &&
                    dateIsToday(metaData.date) && (
                      <div className='mt-4'>
                        <span className='text-danger font-medium mx-2'>
                          {metaData &&
                            metaData.unsettled_pay_later &&
                            `${tenantConfiguration.currencyCode} ${
                              numberFormat(metaData.unsettled_pay_later).displayFormat
                            } `}
                          Unsettled Pay Later Today
                        </span>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Verify Ref No */}
      <Modal show={showVerifyRef} centered onHide={() => clearVerifyRefModal()} backdrop='static'>
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Payment Receipt</h2>
          </Modal.Title>
          <div
            onClick={() => {
              clearVerifyRefModal()
            }}
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            // data-bs-dismiss='modal'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className=''>
              <div className='w-full d-flex justify-content-center'>
                {isEmpty(refImg) ? <NoRecords /> : <img src={refImg} alt='refImg' width={170} />}
              </div>
            </div>
          </>
        </Modal.Body>

        {verificationStatus === 'pending' && (
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <input
                type='text'
                className={`form-control ${refNumberValid}`}
                placeholder='Enter ref no.'
                value={refNumber}
                disabled={isVerifying}
                onChange={(e) => {
                  setRefNumber(e.target.value)
                  setRefNumberValid('')
                  setError('')
                }}
              />

              <button
                className='btn btn-secondary ms-4'
                type='button'
                disabled={isVerifying}
                onClick={() => {
                  onVerify()
                }}
              >
                {isVerifying ? 'Please Wait' : 'Verify'}
              </button>
            </div>

            <div className='my-3 w-100 d-flex justify-content-center'>
              <ErrorHandler errorMessage={error} />
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

export default CashDeposits
