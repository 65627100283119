import React, { useState } from "react"

const CustomSearchBar = ({ onChange, id, newSearch, setSelected, setSearch, setResponse, value, isDisable}: any) => {

    const [timer, setTimer] = useState<any>(null);

    if (newSearch) {

        const onChange = (e: any) => {
            clearTimeout(timer)
            const newTimer = setTimeout(() => {
                setResponse((preValue: any) => ({
                    ...preValue, search: e.target.value, selected: 0
                }))
                // setSelected(0)
                // setSearch(e.target.value)
            }, 500)
            setTimer(newTimer)
        }

        return (
            <form className='col-12 col-md-auto mt-0' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                <input type="text" placeholder='Search' name="search" className='form-control form-search' onChange={onChange} value={value} disabled={isDisable}/>
            </form>
        )
    } else {
        return (
            <form className='col-12 col-md-auto mt-0' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                <input type="text" placeholder='Search' name="search" className='form-control form-search' onChange={onChange} value={value} disabled={isDisable} />
            </form>
        )
    }

}

export default CustomSearchBar
