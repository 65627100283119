import React, { useEffect } from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../AssetHelpers"

type Props = {
  className?: string
  path: string
  svgClassName?: string
}

const KTSVG: React.FC<Props> = ({ className = '', path, svgClassName = '' }) => {
  useEffect(() => {
    window.addEventListener('storage', () => {
      window.addEventListener("click", function () {
        if (localStorage.getItem("eafruitsLogin") === null) {
          window.location.reload();
        }
      });
    })
  }, [])


  return (
    <span className={`svg-icon ${className}`}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  )
}

export { KTSVG }
