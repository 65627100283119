/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { Link, useLocation } from "react-router-dom"
import { KTSVG } from "../../../../../_eaFruitsDms/helpers"
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core"

let shoeEditBtn = true
const WarehouseHeader = (props: any) => {
  const location = useLocation()
  if (location.pathname === '/warehouse/DC/singlewarehousename/credit-issues') {
    shoeEditBtn = false
  } else {
    shoeEditBtn = true
  }
  if (location.pathname === '/warehouse/DC/singlewarehousename/vehicles') {
    shoeEditBtn = false
  }

  const [showWrite, setShowWrite] = useState<any>(false)
  const [showDelete, setShowDelete] = useState<any>(false)

  const intl = useIntl()
  const warehouseBreadCrumbs: Array<PageLink> = [
    {
      title: 'Warehouse',
      path: '/warehouse/DC',
      isSeparator: false,
      isActive: false,
    },

  ]
  const { region, warehouseName = "Warehouse Name", warehouseType } = props.header

  return (
    <>
      <PageTitle
        breadcrumbs={warehouseBreadCrumbs}
        backNavigate='/warehouses'
      >
        {warehouseName}
      </PageTitle>
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      {warehouseName}
                    </span>

                  </div>
                  {props.header !== "" &&
                    <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                      <span
                        className='d-flex align-items-center me-6 mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen045.svg'
                          className='me-1'
                        />
                        {warehouseType === "DistributionCenter" ? "Distribution Center" : warehouseType}
                      </span>
                      {warehouseType === "Headquarter" &&
                        <span
                          className='d-flex align-items-center me-5 mb-2 '
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen018.svg'
                            className='me-1'
                          />
                          {region}
                          {/* {props.WarehouseRegion} */}
                        </span>}
                    </div>}
                </div>
              </div>
            </div>

          </div>

          <div className='d-flex overflow-auto h-40px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
              {/* {showOverview && */}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouses/overview' && 'active')
                  }
                  to='/warehouses/overview'
                >
                  Overview
                </Link>
              </li>
              {/* } */}


              {/* {showArea && */}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouses/area-coverage' && 'active')
                  }
                  to={`/warehouses/area-coverage`}
                >
                  Area Coverage
                </Link>
              </li>
              {/* } */}

              {/* {showTeam && */}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouses/team' && 'active')
                  }
                  to='/warehouses/team'
                >
                  Team
                </Link>
              </li>
              {/* } */}

              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouses/sales-order' && 'active')
                  }
                  to='/warehouses/sales-order'
                >
                  Sales Order
                </Link>
              </li> */}



              {/* {showStock && */}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouses/stock' && 'active')
                  }
                  to='/warehouses/stock'
                >
                  Stock
                </Link>
              </li>
              {/* } */}

              {/*{showCustomers &&*/}
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouses/customers' && 'active')
                  }
                  to='/warehouses/customers'
                >
                  Customers
                </Link>
              </li> */}
              {/* } */}

              {/*{showCredit &&*/}
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouses/credit-issues' && 'active')
                  }
                  to='/warehouses/credit-issues'
                >
                  Credit Issues
                </Link>
              </li> */}
              {/* } */}

            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export { WarehouseHeader }
