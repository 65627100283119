import { apiBaseURL } from "../../helpers"
import { deleteRequest, getRequest, patchRequest, postRequest } from "../../https/apiCall"
import { getToken } from "../../localStorage/getItem/getLocalStorage"

const header = {
  headers: {
    Authorization: `Bearer ${getToken}`,
  },
}

export const UserCreate = async (
  full_name: any,
  last_name: any,
  gender: any,
  email: any,
  date_of_birth: any,
  is_active: any,
  phone_no: any,
  role: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    first_name: full_name,
    last_name: last_name,
    gender: gender,
    email: email,
    warehouse: null,
    type: 'sa',
    supervisor: null,
    date_of_birth: date_of_birth,
    is_active: is_active,
    phone_no: phone_no,
    role: role,
    ward: [],
  }
  await postRequest(`${apiBaseURL()}auth/users/create`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

//request method for api implementation :- last change by kriti
export const UserUpdate = async (
  full_name: any,
  last_name: any,
  gender: any,
  email: any,
  date_of_birth: any,
  is_active: any,
  phone_no: any,
  role: any,
  id: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  const data = {
    first_name: full_name,
    last_name: last_name,
    gender: gender,
    email: email,
    role: role,
    date_of_birth: date_of_birth,
    is_active: is_active,
    phone_no: phone_no,
  }

  await patchRequest(`${apiBaseURL()}auth/users/update/${id}/`, data, true)
    .then((res) => {
      notify.success = true // res
    })
    .catch((err) => {
      notify.failed = true // catch
      notify.errorField = err.response.data.error.field // catch
      console.log('UserUpdate Error ==>>', err.response.data)
    })
  return notify
}

export const getUsers = async (limit: any, offset: any, search: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }

  await getRequest(
    `${apiBaseURL()}auth/users/super-admin?&limit=${limit}&offset=${offset}&ordering=-created_at&search=${search}`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const getUserSingle = async (id: any) => {
  let data = ''

  await getRequest(`${apiBaseURL()}auth/users/${id}/`, true).then((res) => {
    data = res
  })
  return data
}

export const UserDelete = async (id: any) => {
  const notify = {
    success: false,
    failed: false,
  }

  await deleteRequest(`${apiBaseURL()}auth/users/delete/${id}/`, true).then((res) => {
    if (res.status === 204) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}
