import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import BusinessAddress from './components/businessAddress'
import BusinessDetails from './components/businessDetails'
import MobileNumber from './components/mobileNumber'
import Overview from './components/overview'
import VerificationHeader from './components/verificationHeader'
import {getCustomerDetails} from '../CustomerConst'
import {
  apiBaseURL,
  checkUserType,
  isEmpty,
  verificationPending,
} from '../../../../../_eaFruitsDms/helpers'
import WMHorecaContractDetail from '../horecaContract/components/horecaContractDetail'
import WmOverview from '../../WMcustomers/components/overview'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'

const VerificationRequest: React.FC = () => {
  const intl = useIntl()
  const customersHeaderTitle = {
    title: 'Customers',
    subTitle: 'Customer Name',
  }
  const [customer, setCustomer] = useState<any>(null)
  const [customerSa, setCustomerSa] = useState<any>(null)
  const customerId = localStorage.getItem('cId')

  useEffect(() => {
    fetchCustomer(customerId).then()
  }, [])

  const fetchCustomer = async (customerId: any) => {
    const customerResponse = await getCustomerDetails(customerId)
    if (customerResponse) {
      const verificationStatus = verificationPending(customerResponse)
      customerResponse.pending = verificationStatus.pending
      customerResponse.verification_type = verificationStatus.type
      setCustomer(customerResponse)

      const contract_id =
        customerResponse && customerResponse.contract_id && customerResponse.contract_id

      if (!isEmpty(contract_id)) {
        localStorage.setItem('horeca-id', contract_id)
      } else {
        localStorage.setItem('horeca-id', '')
      }
    }
  }

  const fetchCustomerDetails = async () => {
    const customerResponse = await getRequest(`${apiBaseURL()}customers/${customerId}`, true)

    if (customerResponse) {
      const verificationStatus = verificationPending(customerResponse)
      customerResponse.pending = verificationStatus.pending
      customerResponse.verification_type = verificationStatus.type
      setCustomerSa(customerResponse)
    }
  }

  useEffect(() => {
    if (checkUserType('sa')) {
      fetchCustomerDetails()
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[customersHeaderTitle]}>
        {intl.formatMessage({id: 'Customer Name'})}
      </PageTitle>
      {/* <CustomerNameHeader /> */}
      <VerificationHeader customer={customer} />
      <Routes>
        {checkUserType(['wm', 'ts']) ? (
          <Route path='overview' element={<Overview customer={customer} />} />
        ) : (
          <Route
            path='overview'
            element={
              <WmOverview customer={customerSa} fetchCustomerDetails={fetchCustomerDetails} />
            }
          />
        )}
        {/*<Route path='overview' element={<Overview customer={customer} fetchCustomerDetails={fetchCustomerDetails}/>} />*/}
        <Route path='mobile-number' element={<MobileNumber />} />
        <Route path='business-details' element={<BusinessDetails />} />
        <Route path='business-address' element={<BusinessAddress />} />
        <Route path='horeca-contract-detail' element={<WMHorecaContractDetail />} />
        <Route index element={<Navigate to='overview' />} />
      </Routes>
    </>
  )
}

export default VerificationRequest
