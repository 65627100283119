import React from "react"
import BusinessDocuments from "../../components/BusinessDocuments"

const BusinessDetails = () => {
    const customerId = localStorage.getItem('cId')

    return (
        <>
            <div className='card mb-5 mb-xl-10' >
                <div className='card-body py-5'>
                    <BusinessDocuments
                        customerId={customerId}
                    />
                </div>
            </div>
        </>
    )
}

export default BusinessDetails
