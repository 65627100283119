import {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../TenantVariables'
import {getRequest} from '../../https/apiCall'
import {apiBaseURL, isEmpty} from '../CommonHelpers'
import moment from 'moment'

interface Props {
  route: any
  handleFilterChange: any
  isDisabled: boolean
  className?: string
  allRoute?: boolean
  warehouse?: any
  sales_team_id?: any
  salesTeamName?: boolean
}

export const RouteFilter = ({
  route,
  handleFilterChange,
  isDisabled,
  className,
  allRoute = true,
  warehouse,
  sales_team_id,
  salesTeamName,
}: Props) => {
  const [routes, setRoutes] = useState<any>([])
  const [offset, setOffset] = useState<number>(0)
  const [reset, setReset] = useState<any>(moment())

  useEffect(() => {
    if (!isEmpty(warehouse)) {
      setReset(moment())
      setOffset(0)
      setRoutes([])
    }

    if (!isEmpty(sales_team_id)) {
      setReset(moment())
      setOffset(0)
      setRoutes([])
    }
  }, [warehouse, sales_team_id])

  const fetchRoutes = async (search?: any) => {
    if (search !== '') {
      setOffset(0)
    }

    let options: any = []

    const response = await getRequest(
      `${apiBaseURL()}routes/drop-down?ordering=name&search=${search}&limit=${
        tenantConfiguration.limit
      }&offset=${offset}&warehouse_id=${isEmpty(warehouse) ? '' : warehouse}&sales_team_id=${
        isEmpty(sales_team_id) ? '' : sales_team_id
      }`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setOffset(newOffset)
      hasMore = true
    }

    if (allRoute && routes.length === 0) {
      options.push({
        label: 'All Routes',
        value: '',
      })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        if (salesTeamName) {
          let concatName = option?.name ?? ''

          if (!isEmpty(option?.sales_team_name)) {
            concatName = (
              <span>
                {option.name} - <b>{option?.sales_team_name}</b>
              </span>
            )
          }

          options.push({
            label: concatName,
            value: option.id,
          })
        } else {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
    }

    if (routes.length > 0) {
      setRoutes(routes.concat(options))
    } else {
      setRoutes(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  return (
    <>
      <AsyncPaginate
        key={reset}
        loadOptions={fetchRoutes}
        isDisabled={isDisabled}
        isSearchable
        className={`react-select-container ${className ? className : 'my-1 me-3 w-160px'}`}
        classNamePrefix='react-select'
        placeholder='All Routes'
        value={route}
        onChange={(e: any) => handleFilterChange(e, 'route')}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#0c78a4',
          },
        })}
      />
    </>
  )
}
