import {isEmpty} from 'lodash'
import {SA_DASHBOARD_WAREHOUSE, SA_DASHBOARD_MONTH} from '../../types/types'

let parseData = !isEmpty(localStorage.getItem('sa_dashboard_month'))
  ? JSON.parse(localStorage.getItem('sa_dashboard_month') || '')
  : ''

let isMonth = isEmpty(parseData) ? 'today' : parseData.value

let parseWarehouseData = !isEmpty(localStorage.getItem('sa_dashboard_warehouse'))
  ? JSON.parse(localStorage.getItem('sa_dashboard_warehouse') || '')
  : ''

let warehouse = isEmpty(parseWarehouseData) ? '' : parseWarehouseData.value

const initialState: any = {
  warehouseId: warehouse,
  month: isMonth,
}

export const SaDashboardFilters = (state = initialState, action: any) => {
  switch (action.type) {
    case SA_DASHBOARD_WAREHOUSE:
      return {
        ...state,
        warehouseId: action.payload,
      }

    case SA_DASHBOARD_MONTH:
      return {
        ...state,
        month: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}
