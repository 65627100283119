import React from "react"
import { useIntl } from "react-intl"
import Select from "react-select"
import { toAbsoluteUrl } from "../../../../_eaFruitsDms/helpers"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import TableVehicalReport from './components/tableVehicalReport'

const vehicalheadquartersoption = [
    { value: 'allheadquarter', label: 'All Headquarter' },
    { value: 'headquarter1', label: 'Headquarter1' },
    { value: 'headquarter2', label: 'Headquarter2' },
    { value: 'headquarter3', label: 'Headquarter3' },
]

const vehicalcenteroption = [
    { value: 'alldistributioncenters', label: 'All Distribution Centers' },
    { value: 'centers1', label: 'Centers1' },
    { value: 'centers2', label: 'Centers2' },
    { value: 'centers3', label: 'Centers3' },
]

const warehouseoptions = [
    { value: 'all', label: 'All Warehouses' },
    { value: 'Warehouse1', label: 'Warehouse 1' },
    { value: 'Warehouse2', label: 'Warehouse 2' },
    { value: 'Warehouse3', label: 'Warehouse 3' },
]
const vehicletypeoptions = [
    { value: 'all', label: 'All Type/Model' },
    { value: 'Warehouse1', label: 'Active' },
    { value: 'Warehouse2', label: 'Inactive' }
]


type Props = {
    className: string
}
const VehicalReport: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = [{
        title: "Reports"
    }]
    return (
        <>
            <EATitle title='Vehical Report' />
            <PageTitle breadcrumbs={reportHeaderTitle} >{intl.formatMessage({ id: 'Vehicle Report' })}</PageTitle>
            <div className={`card ${className} mb-5`}>
                {/* begin::Header */}
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    {/* begin::Title */}
                    <h3 className='card-title align-items-start flex-column my-0'>
                        <span className='card-label fw-bold font-17'>Vehicle Report</span>
                        <p className="font-13 text-muted mb-0">Headquarter Wise
                        </p>
                    </h3>
                    {/* end::Title */}
                    {/* begin::Toolbar */}
                    <div className='card-toolbar d-flex g-3 my-0'>
                        <Select
                            options={warehouseoptions}
                            isSearchable
                            placeholder="All Warehouses "
                            className='react-select-container my-1 w-160px'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                    </div>
                    {/* end::Toolbar */}
                </div>
                <div className="card-body">
                    Chart Area
                </div>
            </div>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <Select
                                    options={warehouseoptions}
                                    isSearchable
                                    placeholder="All Warehouses "
                                    className='react-select-container my-1 me-3 w-160px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <Select
                                    options={vehicletypeoptions}
                                    isSearchable
                                    placeholder="All Type/Model"
                                    className='react-select-container my-1 w-150px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <TableVehicalReport />
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            Showing 1 to 10 of 50 entries
                        </div>
                        <div className="col">
                            <CustomPagination />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Addwarehouse /> */}
        </>
    )
}

export default VehicalReport
