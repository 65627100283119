import clsx from 'clsx'
import React from 'react'
import {useIntl} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../../../_eaFruitsDms/layout/core'
import CustomerHeader from '../../../components/customerHeader'
import {isEmpty} from '../../../../../../_eaFruitsDms/helpers'

interface VerificationHeaderProps {
  customer: any
}

const VerificationHeader: React.FC<VerificationHeaderProps> = ({customer}: any) => {
  const intl = useIntl()
  const location = useLocation()
  const adminHeaderTitle = [
    {
      title: 'Customers',
      path: '/customers',
    },
  ]

  const isHoreca = !isEmpty(customer) && !isEmpty(customer.contract_id) && customer.contract_id

  return (
    <>
      <div>
        <PageTitle breadcrumbs={adminHeaderTitle} backNavigate='/customers/verification-requests'>
          {intl.formatMessage({id: 'Verification Requests'})}
        </PageTitle>
        <div className='card mb-4 mb-xl-5'>
          <div className='card-body pt-5 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <CustomerHeader customer={customer} />
            </div>
            <div className='d-flex overflow-auto h-40px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/customers/verification-requests/overview' &&
                        'active')
                    }
                    to='/customers/verification-requests/overview'
                  >
                    Overview
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={clsx(
                      'nav-link text-active-primary me-0',
                      customer && customer.contact_is_verified == false ? 'text-danger' : '',
                      location.pathname === '/customers/verification-requests/mobile-number' &&
                        'active'
                    )}
                    to='/customers/verification-requests/mobile-number'
                  >
                    Mobile Number
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={clsx(
                      'nav-link text-active-primary me-0',
                      customer && customer.verification_statuses.business_doc_verified !== true
                        ? 'text-danger'
                        : '',
                      location.pathname === '/customers/verification-requests/business-details' &&
                        'active'
                    )}
                    to='/customers/verification-requests/business-details'
                  >
                    Business Details
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={clsx(
                      'nav-link text-active-primary me-0',
                      customer && customer.business_address_verification == false
                        ? 'text-danger'
                        : '',
                      location.pathname === '/customers/verification-requests/business-address' &&
                        'active'
                    )}
                    to='/customers/verification-requests/business-address'
                  >
                    Business Address
                  </Link>
                </li>
                {isHoreca && (
                  <li className='nav-item'>
                    <Link
                      className={clsx(
                        'nav-link text-active-primary me-0',
                        customer && customer.business_address_verification == false
                          ? 'text-danger'
                          : '',
                        location.pathname ===
                          '/customers/verification-requests/horeca-contract-detail' && 'active'
                      )}
                      to='/customers/verification-requests/horeca-contract-detail'
                    >
                      HORECA Contracts
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerificationHeader
