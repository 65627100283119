import {useEffect, useState} from 'react'
import {checkPermissions, defaultPermission} from '../CommonHelpers'

const usePermission = (permissionName: string) => {
  const [permission, setPermission] = useState(defaultPermission)

  useEffect(() => {
    fetchPermission()
  }, [])

  const fetchPermission = async () => {
    let res = await checkPermissions(permissionName)
    setPermission(res)
  }

  return permission
}

export default usePermission
