import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { apiBaseURL, isModalValidation, KTSVG } from "../../../../../_eaFruitsDms/helpers"
import ShowingEntries from "../../../../../_eaFruitsDms/helpers/components/ShowingEntries"
import { getRequest, patchRequest, postRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import { EaAlertSuccess } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../../_eaFruitsDms/layout/components/modal/eaModal"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const CmsTeam = () => {

    const initialPageState = {
        search: '', limit: 10, offset: '', list: [], next: '', count: '', previous: '', isLoading: true, currentOffset: '', selected: 0
    }
    const initialValue = { title: '', short_description: '' }
    const [pageState, setPageState] = useState(initialPageState)
    const [validation, setValidation] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [id, setId] = useState('');
    const [disableBtn, setDisable] = useState(false)
    const [isLoading, setIsLoading] = useState("Submit")
    const [requestHandler, setRequest] = useState("Add")
    const [name, setName] = useState("")
    const [teamMember, setTeam] = useState(initialValue)
    const [errorMessage, setErrorMessage] = useState('')

    const getCmsTeamList = async () => {
        // Develop BY: Kashyap 
        // Description: get List Details
        const url = apiBaseURL() + "ourteam/teams/list?" + `ordering=-updated_date&limit=${pageState.limit}&offset=${pageState.currentOffset}&search=${pageState.search}`;
        const response = await getRequest(url, true);
        setPageState({ ...pageState, list: response.results, next: response.next, count: response.count, previous: response.previous, isLoading: response ? false : true })
    }

    useEffect(() => {
        getCmsTeamList()
    }, [pageState.currentOffset, pageState.search])


    const cancleFunction = () => {
        setTeam(initialValue)
        setErrorMessage('')
        setIsLoading("Submit")
        setDisable(false)
        setValidation(false)
    }

    const modalHandler = (event: any, key: any) => {
        // Develop BY: Kashyap 
        // Description: handling modal value when user add details
        key === "input" && setTeam({ ...teamMember, [event.target.name]: event.target.value })
        if (key === "edit") {
            setRequest("Edit")
            setId(event.id)
            setTeam({ title: event.title, short_description: event.short_description })
        }
    }

    const onSubmit = async () => {
        setValidation(true)
        if (isModalValidation(teamMember).length === 0) {
            setName(teamMember.title)
            setDisable(true)
            setIsLoading("Please Wait...")
            let url = apiBaseURL() + 'ourteam/teams/create'
            let editUrl = apiBaseURL() + 'ourteam/teams/update/' + id + "/"

            // Develop BY: Kashyap 
            // Description: make the requestHandler for post and patch request with same code.
            const response = requestHandler === "Add" ? await postRequest(url, teamMember, true) : await patchRequest(editUrl, teamMember, true)
            let successArray = [201, 200]
            let failArray = [400, 500]
            if (response.data.error) {
                if (response.data.error.field === "title") {
                    setErrorMessage('Your added team name already exists in this system! Please try again with a unique team name.')
                }
            }
            successArray.includes(response.status) && responseSuccess()
            failArray.includes(response.status) && responseFail()
        }
    }


    const responseSuccess = () => {
        getCmsTeamList()
        setShowAlert(true)
        setIsLoading("Submit")
        setDisable(false)
    }

    const responseFail = () => {
        setIsLoading("Submit")
        setDisable(false)
    }
    return (
        <>
            <EATitle title="Teams - " />
            <PageTitle breadcrumbs={[{ title: 'CMS - Our Team' }]} >{"Teams"}</PageTitle>
            <EaAlertSuccess newProps={true} showAlert={showAlert} setShowAlert={setShowAlert} message={
                requestHandler === 'Add' ? 'CMS.TEAM.ADD' :
                    requestHandler === 'Edit' && 'CMS.TEAM.UPDATE'
            } name={name} />

            <div className={`card`}>
                {pageState.isLoading && <IsLoader />}
                <div className='card-header border-0 px-7'>
                    <div className='card-toolbar row g-0 w-100'>
                        <CustomSearchBar newSearch={true} setResponse={setPageState} />
                        <div className="col-12 col-md-auto ms-auto mt-3 mt-md-0">
                            <EaButton onClick={() => setRequest("Add")} isModal={true} dataTarget='#ea_modal_create_blogauthor' btnName='Add Team' />
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    {pageState.list.length === 0 ? <NoRecords /> : <TeamTable list={pageState.list} getPropsEdit={(item: any) => modalHandler(item, "edit")} />}
                </div>
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        <ShowingEntries response={pageState} />
                    </div>
                    <div className="col">
                        <CustomPagination newProp={true}
                            pageState={pageState}
                            setPageState={setPageState} />
                    </div>
                </div>
            </div>

            { /* add group */}
            <EaModal
                actionBtnName={isLoading}
                btnDisabled={disableBtn}
                cancel={cancleFunction}
                onSubmit={onSubmit}
                id='ea_modal_create_blogauthor'
                modalTitle={`${requestHandler} Team`}
            >
                <div className="mb-3">
                    <label className="form-label">Team Name</label>
                    <input
                        type="text"
                        className={clsx(
                            'form-control', validation && teamMember.title === "" ? "is-invalid" : ""
                        )}
                        name='title'
                        onChange={(e) => modalHandler(e, 'input')}
                        value={teamMember.title}
                        placeholder="Type here..."
                    />
                </div>
                <div className='mb-4'>
                    {errorMessage && <ErrorHandler errorMessage={errorMessage} />}
                </div>
                <div className='mb-4'>
                    <label className='form-label'>Small Description</label>
                    <textarea
                        className={`form-control ${validation && teamMember.short_description === "" ? "is-invalid" : ""} `}
                        rows={3}
                        placeholder='Type here…'
                        name='short_description'
                        onChange={(e) => modalHandler(e, 'input')}
                        value={teamMember.short_description}
                    ></textarea>
                </div>

            </EaModal>

        </>
    )
}

export default CmsTeam


const TeamTable = (props: any) => {
    return (
        <div className='table-responsive'>
            <table className='table table-row-bordered align-middle gs-0 gy-2'>
                <thead>
                    <tr className=' text-muted fw-500'>
                        <th className='min-w-120px py-2'>Team Name</th>
                        <th className='min-w-80px action-th py-2'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.list.length !== 0 && props.list.map((item: any) => (
                            <tr>
                                <td>{item.title}</td>
                                <td className='text-end'>
                                    <a
                                        onClick={() => props.getPropsEdit(item)}
                                        href='#ea_modal_create_blogauthor'
                                        data-bs-toggle='modal'
                                        data-bs-target='#ea_modal_create_blogauthor'
                                        className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                                    >
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                    </a>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
