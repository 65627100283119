import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  DistictList,
  DistrictCreate,
  DistrictSingle,
  DistrictUpdate,
  WardCreate
} from "../../../../_eaFruitsDms/apiFunctions/master/master";
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation
} from "../../../../_eaFruitsDms/helpers";
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import { getToken } from "../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage";
import TableDistrict from "./component/tableDistrict";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";

type Props = {
  className: string
}

const District: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Districts',
  }

  // all states
  const [modalRegion, setModalRegion] = useState('')
  const [modalRegionEdit, setModalRegionEdit] = useState('')
  const [getList, setGetList] = useState<any>([])
  const [id, setId] = useState('')
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [count, setCount] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [getLength, setLength] = useState('1')
  const [getRegionDrop, setRegion] = useState<any>([])
  const [kmlFileNameDistrict, setKmlFileNameDistrict] = useState('Browse')
  const [kmlFileNameDistrictEdit, setKmlFileNameDistrictEdit] = useState('Browse')
  const [kmlFileDistrict, setKmlFileDistrict] = useState('')
  const [kmlFileDistrictEdit, setKmlFileDistrictEdit] = useState('')
  const [districtName, setDistrictName] = useState('')
  const [districtNameEdit, setDistrictNameEdit] = useState('')
  const [districtValidation, setdistrictValidation] = useState('')
  const [districtValidationEdit, setdistrictValidationEdit] = useState('')
  const [regionValidation, setRegionValidation] = useState('')
  const [regionValidationEdit, setRegionValidationEdit] = useState('')
  const [regionGet, setRegionGet] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [skml, setSKml] = useState('')
  const [skmlLink, setSKmlLink] = useState('#')
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [messageTitle, setMessageTitle] = useState('')
  const [regionDrodown, setRegionDropDown] = useState<any>('')

  const [limit, setLimit] = useState<number>(10)
  const [regionOffset, setRegionOffset] = useState<any>('')
  const [regionList, setRegionList] = useState<any>([])
  const [regionFilter, setRegionFilter] = useState<any>([])
  const [regionId, setRegionId] = useState('')
  const [searchSelect, setSearchSelect] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  // Authorization token.
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  useEffect(() => {
    fetchPermission()
    setListLoader(true)
    DistictList('10', '0', '', '').then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })
    getRegion()
  }, [])

  useEffect(() => {
    setRegionOffset('')
  }, [searchSelect])

  const apiLimit = '&limit=1000'

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_distribution_boundaries")
    setPermission(res)
   }

  // get region with name ordering.

  // request method for api implementation :- last change by kriti

  const getRegion = () => {
    getRequest(`${apiBaseURL()}master/region/list?ordering=name` + apiLimit, true).then(
      (res: any) => {
        let data = res.results
        let region: any = []
        data.forEach((item: any) => {
          region.push({ label: item.name, value: item.id })
        })
        setRegionList(region)
      }
    )
  }

  const getKmlProps = (val: any) => {
    setMessageHandler('successKml')
    setId(val.id)
    setDistrictNameEdit(val.name)
    DistrictSingle(val.id).then((res: any) => {
      setModalRegionEdit(res.region)
    })
  }

  const getProps = (val: any) => {
    getRegion()
    setModalLoader(true)
    setMessageHandler('successEdit')
    setId(val.id)
    DistrictSingle(val.id).then((res: any) => {
      setDistrictNameEdit(res.name)
      setModalRegionEdit(res.region)
      if (res.kml_file !== null) {
        var filename = res.kml_file.substring(res.kml_file.lastIndexOf('/') + 1)
        setSKml(filename)
        setSKmlLink(res.kml_file)
      } else {
        setSKml('')
        setSKmlLink('#')
      }
      setModalLoader(false)
    })
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      DistictList('10', newOffset, search, regionId).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      DistictList('10', newOffset, search, regionId).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      DistictList('10', newOffset, search, regionId).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    setListLoader(true)
    const newTimer = setTimeout(() => {
      DistictList('10', '0', e.target.value, regionId).then((res) => {
        setGetList(res.data)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setListLoader(false)
        setCount(res.count)
        setNext(res.next)
        setSelected(0)
        setPrev(res.prev)
      })
    }, 500)
    setTimer(newTimer)
  }

  const clearFunctionDistrict = () => {
    setRegionValidation('')
    setErrorMessage('')
    setKmlFileNameDistrict('Browse')
    setKmlFileDistrict('')
    setModalRegion('')
    setDistrictName('')
    setdistrictValidation('')
    setRegionOffset('')
  }

  const clearFunctionDistrictEdit = () => {
    setKmlFileNameDistrictEdit('Browse')
    setKmlFileDistrictEdit('')
    setErrorMessage('')
    setDistrictNameEdit('')
    setRegionOffset('')
    setdistrictValidationEdit('')
  }

  const addFunctionDistrict = () => {
    let navigateField = {
      districtName: districtName,
      modalRegion: modalRegion,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // ui validation [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (districtName.trim() == '') {
      setdistrictValidation('is-invalid')
    } else {
      setdistrictValidation('')
    }
    if (modalRegion == '') {
      setRegionValidation('is-invalid')
    } else {
      setRegionValidation('')
    }

    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (districtName.trim() !== '') {
      if (modalRegion !== '') {
        setDisableBtn(true)
        setIsLoading('Please Wait..')
        DistrictCreate(districtName, modalRegion, kmlFileDistrict).then((res) => {
          setDisableBtn(false)
          setRegionId('')
          setRegionDropDown('')
          setIsLoading('Submit')
          if (res.success) {
            clearFunctionDistrict()
            setListLoader(true)
            DistictList('10', '0', '', '').then((res) => {
              setGetList(res.data)
              if (res.data.length == 0) {
                setLength('2')
              } else {
                setLength('1')
              }
              setCount(res.count)
              setNext(res.next)
              setPrev(res.prev)
              setSelected(0)
              setListLoader(false)
            })
            setTimeout(() => {
              setIsAlertSuccess(false)
              setIsAlertFailed(false)
            }, 2000)
            $('#ea_modal_close_add_District').trigger('click') // modal close
            setIsAlertSuccess(true)
          }
          if (res.failed) {
            // setIsAlertFailed(true)
          }
          if (res.errorField == 'name') {
            setErrorMessage(
              'Your added district name already exists in this system! Please try again with a unique name.'
            )
          }
        })
      }
    }
  }

  const EditFunctionDistrict = () => {
    let navigateField = {
      districtNameEdit: districtNameEdit,
      modalRegionEdit: modalRegionEdit,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])
    if (districtNameEdit.trim() == '') {
      setdistrictValidationEdit('is-invalid')
    } else {
      setdistrictValidationEdit('')
    }
    if (modalRegionEdit == '') {
      setRegionValidationEdit('is-invalid')
    } else {
      setRegionValidationEdit('')
    }

    if (districtNameEdit.trim() !== '') {
      if (modalRegionEdit !== '') {
        setDisableBtn(true)
        setIsLoading('Please Wait..')
        DistrictUpdate(id, districtNameEdit, modalRegionEdit, kmlFileDistrictEdit).then((res) => {
          setDisableBtn(false)
          setIsLoading('Submit')
          if (res.success) {
            clearFunctionDistrictEdit()
            setListLoader(true)
            DistictList('10', '', search, regionId).then((res) => {
              setGetList(res.data)
              if (res.data.length == 0) {
                setLength('2')
              } else {
                setLength('1')
              }
              setListLoader(false)
              setCount(res.count)
              setNext(res.next)
              setPrev(res.prev)
              setSelected(0)
            })
            setTimeout(() => {
              setIsAlertSuccess(false)
              setIsAlertFailed(false)
            }, 2000)
            $('#ea_modal_close_Edit_District').trigger('click') // modal close
            setIsAlertSuccess(true)
          }
          if (res.failed) {
            // setIsAlertFailed(true)
          }

          if (res.errorField == 'name') {
            setErrorMessage(
              'Your added district name already exists in this system! Please try again with a unique name.'
            )
          }
        })
      }
    }
  }

  const selectKmlFileDistrict = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlFileNameDistrict(e.target.files[0].name)
      setKmlFileDistrict(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const selectKmlFileDistrictEdit = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlFileNameDistrictEdit(e.target.files[0].name)
      setKmlFileDistrictEdit(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  // success message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const [KmlFile, setKMLFile] = useState('')
  const [kmlFileNameKml, setkmlFileNameKml] = useState('Browse')
  const [kmlValidationEdit, setKmlValidationEdit] = useState('')

  // ward
  const [wardValidation, setWardValidation] = useState('')
  const [wardName, setWardName] = useState('')
  const [kmlValidation, setKmlValidation] = useState('')
  const [kmlFileName, setKmlName] = useState('Browse')
  const [districtId, setDistrictId] = useState('')

  const getWardProps = (val: any) => {
    setMessageHandler('successWard')
    setDistrictId(val.id)
  }

  const clearFunctionKML = () => {
    // clear KML modal state
    setKMLFile('')
    setkmlFileNameKml('Browse')
    setKmlValidationEdit('')
  }

  const kmlFunction = () => {
    if (KmlFile == '') {
      setKmlValidationEdit('is-invalid')
    } else {
      setKmlValidationEdit('')
    }

    if (KmlFile !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')

      DistrictUpdate(id, districtNameEdit, modalRegionEdit, KmlFile).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunctionKML()
          clearFunctionDistrictEdit()
          setListLoader(true)
          DistictList('10', '0', '', '').then((res) => {
            setGetList(res.data)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)
            setListLoader(false)
          })
          setIsAlertSuccess(true)
        }
        if (res.failed) {
          setIsAlertFailed(true)
        }
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        $('#ea_modal_close_kml_region').trigger('click') // modal close
      })
    }
  }

  const selectKmlFileKml = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setkmlFileNameKml(e.target.files[0].name)
      setKMLFile(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const clearFunctionWard = () => {
    setKMLFile('')
    setWardName('')
    setWardValidation('')
    setRegionOffset('')
    setErrorMessage('')
    // clear add modal state
  }
  const selectKmlFileWard = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlName(e.target.files[0].name)
      setKMLFile(e.target.files[0])

      // }
      e.target.value = ''
    }
  }

  const addFunctionWard = () => {
    // ui validation [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    if (wardName === '') {
      setWardValidation('is-invalid')
    } else {
      setWardValidation('')
    }

    if (wardName !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      WardCreate(wardName, districtId, KmlFile).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunctionWard()
          setListLoader(true)
          DistictList('10', '0', '', '').then((res) => {
            setGetList(res.data)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setListLoader(false)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)
          })
          setTimeout(() => {
            setIsAlertSuccess(false)
            setIsAlertFailed(false)
          }, 2000)
          $('#ea_modal_close_add_Wards').trigger('click') // modal close
          setIsAlertSuccess(true)
        }
        if (res.failed) {
          // setIsAlertFailed(true)
        }
        if (res.errorField == 'name') {
          setErrorMessage(
            'Your added ward name already exists in this system! Please try again with a unique name.'
          )
        }
      })
    }
  }

  const fetchRegions = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const regionApi = `${apiBaseURL()}master/region/list?ordering=name&search=${search}&limit=${limit}&offset=${regionOffset}`

    const regionResponse = await getRequest(regionApi, true)

    let hasMore: boolean = false

    if (regionResponse.next !== null) {
      const queryParams = new URLSearchParams(regionResponse.next)
      let newOffset: any = queryParams.get('offset')
      setRegionOffset(newOffset)
      hasMore = true
    }

    if (regionList.length === 0) {
      // options.push({
      //   label: 'All Regions',
      //   value: '',
      // })
    }

    if (regionResponse.results.length > 0) {
      regionResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (regionList.length > 0) {
      setRegionList(regionList.concat(options))
    } else {
      setRegionList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchRegionsFilter = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const regionApi = `${apiBaseURL()}master/region/list?ordering=name&search=${search}&limit=${limit}&offset=${regionOffset}`

    const regionResponse = await getRequest(regionApi, true)

    let hasMore: boolean = false

    if (regionResponse.next !== null) {
      const queryParams = new URLSearchParams(regionResponse.next)
      let newOffset: any = queryParams.get('offset')
      setRegionOffset(newOffset)
      hasMore = true
    }

    if (regionFilter.length === 0) {
      options.push({
        label: 'All Regions',
        value: '',
      })
    }

    if (regionResponse.results.length > 0) {
      regionResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (regionFilter.length > 0) {
      setRegionFilter(regionFilter.concat(options))
    } else {
      setRegionFilter(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchRegionsEdit = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const regionApi = `${apiBaseURL()}master/region/list?ordering=name&search=${search}&limit=${limit}&offset=${regionOffset}`

    const regionResponse = await getRequest(regionApi, true)

    let hasMore: boolean = false

    if (regionResponse.next !== null) {
      const queryParams = new URLSearchParams(regionResponse.next)
      let newOffset: any = queryParams.get('offset')
      setRegionOffset(newOffset)
      hasMore = true
    }

    if (regionList.length == 0) {
      // options.push({
      //   label: 'All Regions',
      //   value: '',
      // })
    }

    if (regionResponse.results.length > 0) {
      regionResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (regionList.length > 0) {
      // setRegionList(regionList.concat(options))
    } else {
      // setRegionList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleRegionChange = (e: any, key?: any) => {
    setRegionId(e.value)
    setListLoader(true)
    setTimer(null)
    setRegionDropDown(e)

    DistictList('10', '0', search, e.value).then((res) => {
      setGetList(res.data)
      setListLoader(false)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
    })
  }

  return (
    <>
      <EATitle title='District' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({ id: 'Districts' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('DISTRICT.ADD')
            : messageHandler === 'successEdit'
              ? FormatMessage('DISTRICT.UPDATE')
              : messageHandler === 'successKml'
                ? FormatMessage('KML.ADD')
                : FormatMessage('WARD.ADD')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar d-flex g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => searchList(e)} />
            {/* <Dropdown getRegionDrop={getRegionDrop} regionFilter={regionFilter} /> */}
            <form onSubmit={(e) => e.preventDefault()} autoComplete='off' className='ms-auto'>
              <AsyncPaginate
                loadOptions={fetchRegionsFilter}
                isSearchable
                className='react-select-container me-3 w-120px'
                classNamePrefix='react-select'
                name='region'
                placeholder='All Regions'
                value={regionDrodown}
                onChange={handleRegionChange}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </form>
            {/* <EaButton isModal={false} btnName='Add District' ></EaButton> */}
            {permission.write && (
              <EaButton
                isModal={true}
                dataTarget='#ea_modal_create_district'
                btnName='Add District'
                onClick={() => {
                  setMessageHandler('successAdd')
                }}
              />
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0 ? (
              <NoRecords />
            ) : (
              <TableDistrict
                showDelete={permission.delete}
                showWrite={permission.write}
                getKmlProps={getKmlProps}
                getLength={getLength}
                tableList={getList}
                getPropsEdit={getProps}
                getWardProps={getWardProps}
              />
            )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
              } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>

      {/* add district */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionDistrict}
        id='ea_modal_create_district'
        modalTitle='Add District'
        closeAuto='ea_modal_close_add_District'
        onSubmit={() => addFunctionDistrict()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            District Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='districtName'
            className={clsx('form-control', districtValidation)}
            placeholder='Type here...'
            value={districtName}
            onChange={(e) => {
              setDistrictName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setdistrictValidation(validation)
            }}
          />
        </div>

        <div className='mb-4'>
          <label className='form-label' id='modalRegion'>
            Region Name<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchRegions}
            placeholder='Select'
            isSearchable
            className={`react-select-container ${regionValidation}`}
            classNamePrefix='react-select'
            name='role'
            value={regionList.filter((option: any) => option.value === modalRegion)}
            onChange={(e) => {
              setModalRegion(e.value)
              setRegionValidation('')
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>

        <div className=''>
          <label className='form-label'>KML File</label>
          <label htmlFor='kml-file' className={clsx('form-control file-input')}>
            <span className='text-muted'>{kmlFileNameDistrict}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            onChange={selectKmlFileDistrict}
            id='kml-file'
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* edit district */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionDistrictEdit}
        id='ea_modal_Edit_district'
        modalTitle='Edit District'
        closeAuto='ea_modal_close_Edit_District'
        onSubmit={() => EditFunctionDistrict()}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            District Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='districtNameEdit'
            className={clsx('form-control', districtValidationEdit)}
            placeholder='Type here...'
            value={districtNameEdit}
            onChange={(e) => {
              setDistrictNameEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setdistrictValidationEdit(validation)
            }}
          />
        </div>

        <div className='mb-4'>
          <label className='form-label' id='modalRegionEdit'>
            Region Name<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchRegionsEdit}
            isSearchable
            className='react-select-container'
            classNamePrefix='react-select'
            name='role'
            value={regionList.filter((option: any) => option.value === modalRegionEdit)}
            onChange={(e) => {
              setRegionValidationEdit('')
              setModalRegionEdit(e.value)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>

        <div className='position-relative'>
          <div className='d-flex justify-content-between'>
            <label className='form-label me-2'>KML File</label>
            <a
              href={skmlLink}
              className='text-hover-primary text-decoration-underline text-hover-decoration-underline text-truncate'
              target='_blank'
            >
              {skml}
            </a>
          </div>
          <label htmlFor='kml-file-edit' className={clsx('form-control file-input')}>
            <span className='text-muted'>{kmlFileNameDistrictEdit}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            onChange={selectKmlFileDistrictEdit}
            id='kml-file-edit'
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* add kml */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionKML}
        id='ea_modal_create_kmlfile'
        modalTitle='Add KML File'
        closeAuto='ea_modal_close_kml_region'
        onSubmit={() => kmlFunction()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            KML File<span className="text-danger">*</span>
          </label>
          <label htmlFor='kml-file' className={clsx('form-control file-input', kmlValidationEdit)}>
            <span className='text-muted'>{kmlFileNameKml}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            //
            onChange={selectKmlFileKml}
            id='kml-file'
          />
        </div>
      </EaModal>

      {/* add ward */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        id='ea_modal_create_ward'
        modalTitle='Add Ward'
        cancel={clearFunctionWard}
        closeAuto='ea_modal_close_add_Wards'
        onSubmit={() => addFunctionWard()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Ward Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', wardValidation)}
            placeholder='Type here...'
            value={wardName}
            onChange={(e) => {
              setWardName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setWardValidation(validation)
            }}
          />
        </div>
        <div className=''>
          <label className='form-label'>KML File</label>
          <label htmlFor='kml-file' className={clsx('form-control file-input', kmlValidation)}>
            <span className='text-muted'>{kmlFileName}</span>
          </label>
          <input type='file' id='kml-file' onChange={selectKmlFileWard} />
        </div>

        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  )
}

export default District
