import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function TSAsideMenuMain() {
  const intl = useIntl()
  const loc = useLocation()

  useEffect(() => {
    if (localStorage.getItem('eafruitsLogin') === null) {
      window.location.reload()
    }
  }, [loc])

  const splashScreen = document.getElementById('splash-screen')
  useEffect(() => {
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }, [])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem to='/orders' icon='/media/icons/duotune/ecommerce/ecm002.svg' title='Orders' />
      <AsideMenuItem
        to='/all-complaints'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Complaints'
      />
      {/* <AsideMenuItemWithSub
        to='/complaint'
        title='Complaints'
        icon='/media/icons/duotune/communication/com013.svg'
      >
        <AsideMenuItem to='/complaint/new-message' title='New Messages' hasBullet={true} />
        <AsideMenuItem to='/complaint/my-assigned' title='My Assigned' hasBullet={true} />
        <AsideMenuItem to='/complaint/my-closed' title='My Closed' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      <AsideMenuItem
        to='/warehouses'
        icon='/media/icons/duotune/maps/map008.svg'
        title='Warehouses'
      />
      {/*<AsideMenuItem*/}
      {/*  to='/customers'*/}
      {/*  title='Customers'*/}
      {/*  icon='/media/icons/duotune/communication/com013.svg'*/}
      {/*  hasBullet={false}*/}
      {/*/>*/}
      <AsideMenuItemWithSub
        to='/customers'
        title='Customers'
        icon='/media/icons/duotune/communication/com013.svg'
      >
        <AsideMenuItem to='/customers' title='All Customers' hasBullet={true} />
        <AsideMenuItem
          to='/customers/verification-requests'
          title='Verification Requests'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/customers/suspended-customers'
          title='Suspended Customers'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/customers/order-quantity-requests'
          title='Order Quantity Request'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/customers/special-order-limit'
          title='Special Order Limits'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/customers/order-limit-access-request'
          title='Order Limit Access Requests'
          hasBullet={true}
        />
        <AsideMenuItem to='/customers/customer-lead' title='Customer Lead' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/current-stock-and-pricing'
        icon='/media/icons/duotune/general/gen001.svg'
        title='Current Stock & Pricing'
      />
      <AsideMenuItem
        to='/promotional-discount'
        icon='/media/icons/duotune/general/gen001.svg'
        title='Promotional Discount'
      />
      <AsideMenuItem
        to='/all-call-notes'
        icon='/media/icons/duotune/general/gen001.svg'
        title='Call Notes'
      />
      <AsideMenuItem
        to='/survey-forms'
        title='Survey Forms'
        icon='/media/icons/duotune/files/fil024.svg'
      />
      <AsideMenuItemWithSub
        to='/reports'
        title='Reports'
        icon='/media/icons/duotune/graphs/gra006.svg'
      >
        <AsideMenuItem to='/reports/order-report' title='Pre Order Report' hasBullet={true} />
        {/* <AsideMenuItem to='/reports/complaint-report' title='Complaints Report' hasBullet={true} /> */}
        {/*<AsideMenuItem to='/reports/survey-feedback-report' title='Surveys Feedback' hasBullet={true} />
        <AsideMenuItem to='/reports/sales-order-feedback-report' title='Sales Orders Feedback' hasBullet={true} />
        <AsideMenuItem to='/reports/pre-orders-report' title='Pre-Orders' hasBullet={true} />
        <AsideMenuItem to='/reports/new-customers' title='New Customers' hasBullet={true} /> */}
        <AsideMenuItem
          to='/reports/issues-from-sg'
          title='Issues Reported by SG'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/expired-products'
          title='Unsold Expired Products'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/no-ordering-customer-report'
          title='Customer No-Ordering Report'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/dormant-customer-report'
          title='Dormant Customer Report'
          hasBullet={true}
        />
        <AsideMenuItem to='/reports/complaint-report' title='Complaints Report' hasBullet={true} />
        <AsideMenuItem
          to='/reports/order-limit-request-report'
          title='Order Limit Request Report'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/product-skus-report'
          title='Product wise Sales'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/credit-collection-report'
          title='Credit Collection Report'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/order-feedback-report'
          title='Orders Feedback'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/pay-later-today-report'
          title='Pay Later Today Report'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/order-quantity-requests'
          title='Sales Quantity Control Report'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/vehicle-breakdown-report'
          title='Vehicle Breakdown Report'
          hasBullet={true}
        />
        <AsideMenuItemWithSub
          to='/reports/rejection-report'
          title='Rejection Reports'
          hasBullet={true}
        >
          {/*<AsideMenuItem*/}
          {/*  to='/reports/rejection-report/customer-on-boarding'*/}
          {/*  title='Customer On-Boarding'*/}
          {/*  hasBullet={true}*/}
          {/*/>*/}
          <AsideMenuItem
            to='/reports/rejection-report/load-out-requests'
            title='Load-Out Requests'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/reports/rejection-report/order-discount'
            title='Order Discount'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/reports/rejection-report/credit-request'
            title='Credit Request'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/reports/rejection-report/gate-pass-rejection'
            title='Gate Pass Rejection'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
    </>
  )
}
