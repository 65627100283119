import React from 'react'
import {checkEmpty, firstLetterCapital, isEmpty} from '../../../../_eaFruitsDms/helpers'

const Overview = (props: any) => {
  const {records, fetchingRecords} = props

  return (
    <>
      {!fetchingRecords && records && (
        <div className='card p-5'>
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <div className='mb-1'>Complaint ID</div>
              <div>{isEmpty(records.ref_no) ? '' : `#${records.ref_no}`}</div>
            </div>
            <div className='col-md-3 mb-3'>
              <div className='mb-1'>Customer Name</div>
              <div>
                {isEmpty(records.customer) || isEmpty(records.customer.business_name)
                  ? '-'
                  : records.customer.business_name}
              </div>
            </div>
            <div className='col-md-3 mb-3'>
              <div className='mb-1'>Complaint Type</div>
              <div>{isEmpty(records.type_name) ? '-' : records.type_name}</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <div className='mb-1'>Customer Address</div>
              <div>{records.customer && checkEmpty(records.customer.address)}</div>
            </div>
            <div className='col-md-3 mb-3'>
              <div className='mb-1'>Assignee</div>
              <div>{checkEmpty(records.assign_to_name)}</div>
            </div>
            <div className='col-md-3 mb-3'>
              <div className='mb-1'>Priority</div>
              <div>{isEmpty(records.priority) ? '-' : firstLetterCapital(records.priority)}</div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-3 mb-3'>
              <div className='mb-1'>Mapped Warehouse</div>
              <div>{checkEmpty(records.warehouse_name)}</div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Overview
