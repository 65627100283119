import clsx from "clsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { GLOBAL_DATE_FORMAT } from "../../../helpers";

const DatePicker = (props: any) => {

  return (
    <DateRangePicker
      onCallback={(start, end, label) => {
        let date = start._d
        let formatDate = moment(date).format("DD-MM-YYYY")
        let formatDateApi = moment(date).format("YYYY-MM-DD")
        props.onCallback(formatDate, formatDateApi)
      }}
      onApply={(e) => {
        props.onApply()
      }}
      onCancel={() => {
        props.onCancel()
      }}
      initialSettings={
        {
          isClearable: true,
          autoUpdateInput: false,
          minDate: props.minDate && props.minDate,
          maxDate: props.maxDate && props.maxDate,
          startDate: props.startDate ? props.startDate : '01/01/2020',
          endDate: '01/12/2022',
          selectedDate: '26/02/1999',
          alwaysShowCalendars: true,
          cancelClass: 'btn-secondary',
          singleDatePicker: true,
          locale: {
            format: GLOBAL_DATE_FORMAT
          },
          drops: (props.direction) ? props.direction : 'up',
          showDropdowns: true,
        }}
    >
      <input id={props.id} type="text" readOnly value={props.dateValue} className={clsx('form-control calendar', props.validation)} />
    </DateRangePicker>
  )
}

export default DatePicker
