import {isEmpty, toAbsoluteUrl} from '../../../helpers'

// example default image url
// https://eaf-dms-api.yecor.com/upload/eaf-dms-api/default.jpg

const SkuThumbnail = (props: any) => {
  const {skuName, src} = props
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-24px me-2'>
          <span className='symbol-label'>
            {isEmpty(src) ? (
              <img
                src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                className='h-40 rounded-1'
                alt=''
                style={{width: '100%', height: '100%'}}
              />
            ) : (
              <img
                src={src}
                className=' rounded-1'
                alt=''
                style={{width: '100%', height: '100%'}}
              />
            )}
          </span>
        </div>
        {skuName}
      </div>
    </>
  )
}

export default SkuThumbnail
