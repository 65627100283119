export const paymentStatus: any = {
  "fully_paid" : "Fully Paid",
  "partially_paid" : "Partially Paid",
  "not_yet_paid" : "Not Yet Paid",
}

export const paymentStatusOptions: any = [
  {
    label: "All Payment Status",
    value: ""
  },
  {
    label: "Fully Paid",
    value: "fully_paid"
  },
  {
    label: "Partially Paid",
    value: "partially_paid"
  },
  {
    label: "Not Yet Paid",
    value: "not_yet_paid"
  },
]
