import clsx from "clsx"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
// import { requestPassword } from '../core/_requests'
import { connect } from "react-redux"
import { useNavigate } from "react-router"
import * as Yup from "yup"
import { removeBlur } from "../../../../_eaFruitsDms/helpers"
import { ForgotPasswordEa, resetForgotValues } from "../../../../_eaFruitsDms/redux/action/forgotPassword/forgotAction"
import tenantConfiguration from "../../../../TenantVariables"

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

function ForgotPassword(props: any) {
  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      props.resetForgotValues()
    }
  }, [])


  // const url = window.location.href;
  const url = tenantConfiguration.forgotPasswordBaseUrl;

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { resetForm }: any) => {
      props.forgtoEaFruitsProp(values.email, url, resetForm)
      removeBlur()
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Title */}
        <div className='text-start mb-6'>
          <h1 className='text-dark font-17 font-bold mb-1'>Forgot Password ?</h1>
          {/* begin::Link */}
          <div className='text-muted fw-500 '>Enter your email to reset your password.</div>
          {/* end::Link */}
        </div>
        {/* end::Title */}

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-7 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-7 bg-light-info p-4 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-7'>
          <label className='form-label text-dark'>Email</label>
          <input
            type='email'
            placeholder='Email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-sm-sflex row g-0 justify-content-between pb-lg-0 flex-md-row-reverse'>
          <div className='ps-md-2 col-sm-6 mb-3 mb-md-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-primary fw-500 w-100'
              disabled={props.isLoading}
            >
              {/* <span className='indicator-label'>Submit</span> */}
              {props.isLoading ? <span className='indicator-label'>Please wait...</span>
                : <span className='indicator-label'>Submit</span>}
              {/* {props.isLoading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )} */}
            </button>
          </div>
          {/* <Link to='/auth/login' className='pe-md-2 col-sm-6'> */}
          <div className='ps-md-2 col-sm-6 mb-3 mb-md-0'>

            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-secondary fw-500 w-100'
              disabled={props.isLoading}
              onClick={() => {
                if (!props.isLoading) {
                  navigate('/auth/login')
                }
              }}
            >
              Cancel
            </button>
          </div>
          {/* </Link> */}
        </div>
        {/* end::Form group */}
      </form>
      {/* {props.errorMessage && <div className='text-center mt-2 text-danger' >{props.errorMessage}</div>} */}
      {props.errorMessage && <div className='text-center mt-2 text-danger' >{props.errorMessage}</div>}
      {props.forgotResponse && <div className='text-center mt-2 text-primary' >{props.forgotResponse}
        <span style={{
          cursor: 'pointer'
        }} className='font-bold text-decoration-underline' onClick={() => navigate('/auth/login')}> Back To Login.</span>
      </div>}
    </>
  )
}


const mapStateToProps = (state: any) => {
  return {
    isLoading: state.forgot.isLoading,
    errorMessage: state.forgot.errorMessage,
    forgotResponse: state.forgot.forgotResponse,
    tets: state.forgot.tets,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    forgtoEaFruitsProp: (email: string, url: string, resetForm: any) => {
      dispatch(ForgotPasswordEa(email, url, resetForm))
    },
    resetForgotValues: () => {
      dispatch(resetForgotValues())
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
