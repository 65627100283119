import clsx from "clsx"
import React, { useEffect } from "react"
import { useLocation } from "react-router"
import { DrawerComponent } from "../../assets/ts/components"
import { useLayout } from "../core"

const Content: React.FC = ({ children }) => {
  const { classes } = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
      {children}
    </div>
  )
}

export { Content }
