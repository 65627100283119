/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import RejectionReport from './rejectionReport'

type Props = {
  className: string
}
const RejectionReportWrapper: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Rejection Report',
  }

  return (
    <>
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Rejection Report'})}
      </PageTitle>
      {/*{localStorage.getItem('user_type') === "SA" && */}
      <RejectionReport className='rejection-report-sa' />
      {/*}*/}
      {/*{localStorage.getItem('user_type') === "WM" && <RejectionReportWM className='rejection-report' />}*/}
    </>
  )
}

export {RejectionReportWrapper}
