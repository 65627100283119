import { KTSVG } from "../../../../../_eaFruitsDms/helpers"
import EaButton from "../../../../../_eaFruitsDms/layout/components/button/eaButton"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"

const TableDistrict = (props: any) => {
    return (
        <>
          <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                    <thead>
                        <tr className=' text-muted fw-500'>
                            <th className='min-w-150px max-w-200px'>District Name</th>
                            <th className='min-w-150px'>Region Name</th>
                            <th className='min-w-150px'>Wards</th>
                            <th className='min-w-150px'>KML File</th>
                            {props.showWrite || props.showDelete ? <th className='min-w-150px text-end'>Action</th> : <th className='min-w-150px text-end'></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {props.tableList.map((item: any) => (
                            <tr key={item.id}>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.region}
                                </td>
                                <td>
                                    {item.wards}
                                </td>
                                <td>
                                    {item.kml_file == null ? "Not Updated" : "Updated"}
                                </td>
                                <td className='text-end'>
                                    {props.showWrite &&
                                        <>
                                            {item.kml_file == null ?
                                                <EaButton
                                                    onClick={() => props.getKmlProps(item)}
                                                    isModal={true} dataTarget="#ea_modal_create_kmlfile" btnName="Add KML File" className='btn btn-primary btn-sm me-4 font-13' /> : null}

                                            <EaButton
                                                isModal={true}
                                                onClick={() => props.getWardProps(item)}
                                                dataTarget="#ea_modal_create_ward"
                                                btnName='Add Ward' className='btn btn-primary btn-sm me-4 font-13' />
                                        </>}
                                    {props.showWrite &&
                                        <a href='#'
                                            onClick={() => props.getPropsEdit(item)}
                                            className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-2'
                                            data-bs-toggle='modal'
                                            data-bs-target='#ea_modal_Edit_district'
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </a>}
                                    {/* <a href='#' className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                    </a> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </>
    )
}

export default TableDistrict
