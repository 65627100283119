import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {Navigate, Route, Routes} from 'react-router-dom'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import SupportRequestHeader from './components/supportRequestHeader'
import Messages from './components/messages'
import Overview from './components/overview'
import {useEffect, useState} from 'react'
import {apiBaseURL} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'

type Props = {
  className?: string
}
const SupportRequestDetail = (props: any) => {
  const supportRequestTitle = {
    title: 'Complaint',
    subTitle: 'Complaint',
  }
  const requestType = localStorage.getItem('supportRequestType')
  const supportRequestId = localStorage.getItem('supportRequestId')

  const [backNavigate, setBackNavigate] = useState<any>('')
  const [requestDetails, setRequestDetails] = useState<any>('')
  const [pageTitle, setPageTitle] = useState<any>('')
  const [reloadMessages, setReloadMessages] = useState(false)

  useEffect(() => {
    if (requestType == 'new-message') {
      setBackNavigate('/complaint/new-message')
      setPageTitle('New Messages')
    } else if (requestType == 'my-assigned') {
      setBackNavigate('/complaint/my-assigned')
      setPageTitle('My Assigned')
    } else {
      setBackNavigate('/complaint/my-closed')
      setPageTitle('My Closed')
    }
  }, [])

  useEffect(() => {
    supportRequest()
  }, [])

  useEffect(() => {
    setReloadMessages(false)
  }, [reloadMessages])

  const handleAction = () => {
    setReloadMessages(true)
  }

  const supportRequest = async () => {
    const api = `${apiBaseURL()}support_request/web/${supportRequestId}`
    const requestResponce = await getRequest(api, true)
    setRequestDetails(requestResponce)
  }

  return (
    <>
      <EATitle title='Complaint - ' />
      <PageTitle breadcrumbs={[supportRequestTitle]} backNavigate={backNavigate}>
        {pageTitle}
      </PageTitle>
      <SupportRequestHeader requestDetails={requestDetails} onAction={handleAction} />
      <Routes>
        <Route path='messages' element={<Messages reload={reloadMessages} />} />
        <Route path='overview' element={<Overview requestDetails={requestDetails} />} />
        <Route index element={<Navigate to='/new-message/complaint-detail/messages' />} />
        <Route index element={<Navigate to='/my-assigned/complaint-detail/messages' />} />
        <Route index element={<Navigate to='/my-closed/complaint-detail/messages' />} />
      </Routes>
    </>
  )
}

export default SupportRequestDetail
