import DocumentSection from "./DocumentSection"

const BusinessDocuments = ({ customerId, businessDetailData, fetchDocumentDetails }: any) => {
    return <>
        {/* business vwrification documents */}
        <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'>
            Business Verification Documents
        </h3>
        <div className="row">
            <div className='col-lg-12'>
                <DocumentSection
                    documentTitle={'Business License'}
                    documentType={'business_license'}
                    customerId={customerId}
                />

                <DocumentSection
                    documentTitle={'Registration Certificate'}
                    documentType={'reg_certificate'}
                    customerId={customerId}
                />

                <DocumentSection
                    documentTitle={'National Id'}
                    documentType={'national_id'}
                    customerId={customerId}
                />
            </div>
        </div>
    </>
}
export default BusinessDocuments
