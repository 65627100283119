import React from "react"
import { useIntl } from "react-intl"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const Targets: React.FC = () => {
    const intl = useIntl()
    const teamHeaderTitle = {
        title: "Team members",
        subTitle: "Connor Lawson"
    }
    return (
        <>
            <PageTitle breadcrumbs={[teamHeaderTitle]} >{intl.formatMessage({ id: 'Connor Lawson' })}</PageTitle>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>

                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default Targets
