import React from "react"
import { useIntl } from "react-intl"
import { Navigate, Route, Routes } from "react-router-dom"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import Attendance from "./components/attendance"
import Customers from "./components/customers"
import Overview from "./components/overview"
import PastOrders from "./components/pastOrders"
import PaymentIssue from "./components/paymentIssue"
import { SalesRepresentativeHeader } from "./components/salesRepresentativeHeader"
import Targets from "./components/targets"

type Props = {
    className?: string
}
const SalesRepresentative: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const teamMembersTitle = {
        title: "Team members",
        subTitle: "Allen Watts"
    }
    return (
        <>

            <PageTitle breadcrumbs={[teamMembersTitle]} >{intl.formatMessage({ id: 'Allen Watts' })}</PageTitle>
            <div className={`card ${className}`}>
            </div>
            <SalesRepresentativeHeader />
            <Routes>
                <Route path='overview' element={<Overview />} />
                <Route path='customers' element={<Customers />} />
                <Route path='attendance' element={<Attendance />} />
                <Route path='past-orders' element={<PastOrders />} />
                <Route path='targets' element={<Targets />} />
                <Route path='payment-issue' element={<PaymentIssue />} />
                <Route index element={<Navigate to="/team-members/sales-representative/overview" />} />
            </Routes>
        </>
    )
}

export default SalesRepresentative
