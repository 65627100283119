import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {
  apiBaseURL,
  calculateDays,
  dateFormat,
  fetchStaticAsyncDropdown,
  isEmpty,
  KTSVG,
  numberFormat,
} from '../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {AsyncPaginate} from 'react-select-async-paginate'
import CreditRequestRejectionModal from './creditRequestRejectionModal'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../../TenantVariables'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {creditRequestStatus} from './CustomerConst'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'

type Props = {
  className: string
}

const CreditRequest: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  const currentWarehouseId = localStorage.getItem('w_id')
  const intl = useIntl()
  const customerHeaderTitle = {
    title: 'Customers',
  }
  const initialFilter = {
    status: '',
    warehouse: '',
    customer: '',
    sr: '',
  }

  const [creditRequests, setCreditRequests] = useState<any>([])
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouseOffSet, setWarehouseOffSet] = useState<number>(0)
  const [customers, setCustomers] = useState<any>([])
  const [customerOffSet, setCustomerOffSet] = useState<number>(0)
  const [srOffSet, setSrOffSet] = useState(0)
  const [filter, setFilter] = useState<any>(initialFilter)
  const [searchByName, setSearchByName] = useState<string>('')

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    setWarehouseOffSet(0)
    setCustomerOffSet(0)
    setSrOffSet(0)
  }, [searchSelect])

  useEffect(() => {
    fetchCreditRequests().then()
  }, [debounceSearch, filter, currentOffset])

  const fetchCreditRequests = async () => {
    setDisplayLoader(true)
    const response = await getRequest(
      `${apiBaseURL()}credit/requests?limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&status=${
        filter.status
      }&sr_id=${filter.sr}&customer_id=${filter.customer}&warehouse_id=${filter.warehouse}`,
      true
    )
    setDisplayLoader(false)

    if (response.results) {
      setCreditRequests(response.results)
      setCount(response.count)
    }
  }
  const fetchWarehouses = async (search?: any) => {
    let offset = warehouseOffSet

    if (search !== '') {
      setSearchSelect(search)
      offset = 0
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${offset}&parent_warehouse__id=${currentWarehouseId}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchOpenRequest = async (search?: any) => {
    return fetchStaticAsyncDropdown(creditRequestStatus, search)
  }

  const fetchCustomer = async (search?: any) => {
    let offset = customerOffSet
    if (search !== '') {
      offset = 0
      setSearchSelect(search)
    }

    const customerResponse = await getRequest(
      `${apiBaseURL()}customers/drop-down?ordering=business_name&warehouse_id=${currentWarehouseId}&on_boarding_status=Approved&search=${search}&limit=${limit}&offset=${offset}`,
      true
    )

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if (customerResponse.next !== null) {
      const queryParams = new URLSearchParams(customerResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCustomerOffSet(newOffset)
      hasMore = true
    }

    if (customers.length === 0) {
      options.push({
        label: 'All Customers',
        value: '',
      })
    }

    if (customerResponse.results.length > 0) {
      customerResponse.results.map((option: any) => {
        options.push({
          label: option.business_name + ', ' + option.street,
          value: option.id,
        })
        optionData.push(option)
      })
    }

    if (customers.length > 0) {
      setCustomers(customers.concat(optionData))
    } else {
      setCustomers(optionData)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSRs = async (search?: any) => {
    const options: any = []
    let hasMore: boolean = false
    let offset = srOffSet

    if (search !== '') {
      offset = 0
      setSearchSelect(search)
    }

    const response: any = await getRequest(
      `${apiBaseURL()}auth/users/team/list?search=${search}&type=sr&limit=${limit}&offset=${offset}&ordering=first_name`,
      true
    )

    if (!response.results) {
      hasMore = false
    } else {
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next)
        let newOffset: any = queryParams.get('offset')
        setSrOffSet(newOffset)
        hasMore = true
      }

      if (options.length === 0) {
        options.push({
          label: 'All Users',
          value: '',
        })
      }

      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label:
              option.first_name + ' ' + option.last_name + ' (' + option.type.toUpperCase() + ')',
            value: option.id,
          })
        })
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const goToDetailPage = (requestId: any) => {
    navigate('/customers/credit-requests/credit-request-detail', {
      state: {
        request_id: requestId,
      },
    })
  }

  const filterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: string = e.target ? e.target.value : e.value

    if (inputName === 'search') {
      setSearchByName(inputValue)
    } else {
      setFilter({...filter, [inputName]: inputValue})
    }
  }

  return (
    <>
      <div>
        <EATitle title='Credit Requests' />
        <PageTitle breadcrumbs={[customerHeaderTitle]}>
          {intl.formatMessage({id: 'Credit Requests'})}
        </PageTitle>
        <div className='card'>
          {displayLoader && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={filterChange} />
            <div className='ms-md-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}

              <form className='card-toolbar d-flex my-0'>
                <AsyncPaginate
                  loadOptions={fetchOpenRequest}
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  onChange={(e: any) => filterChange(e, 'status')}
                  placeholder='All Requests'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchWarehouses}
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  onChange={(e: any) => filterChange(e, 'warehouse')}
                  placeholder='All Warehouses'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCustomer}
                  className='react-select-container my-1 me-3 w-175px'
                  classNamePrefix='react-select'
                  placeholder='All Customer'
                  onChange={(e: any) => filterChange(e, 'customer')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchSRs}
                  className='react-select-container my-1 w-175px'
                  onChange={(e: any) => filterChange(e, 'sr')}
                  classNamePrefix='react-select'
                  placeholder='All SR'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </form>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {creditRequests.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-110px'>Date</th>
                      <th className='min-w-120px'>Sales Order ID</th>
                      <th className='min-w-140px'>SR Name</th>
                      <th className='min-w-140px'>Customer Name</th>
                      <th className='min-w-130px'>Invoice Amount</th>
                      <th className='min-w-140px'>Credit Requested</th>
                      <th className='min-w-170px'>Requested Due Date</th>
                      <th className='min-w-100px action-th py-2 text-end'>Status</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {creditRequests.map((creditRequest: any) => {
                      return (
                        <tr>
                          <td>
                            {dateFormat(creditRequest.created_at).displayDate}
                            <p className='font-13 text-muted mb-0'>
                              {dateFormat(creditRequest.created_at, 'LT').displayDate}
                            </p>
                          </td>
                          <td>#{creditRequest.order_no}</td>
                          <td>{creditRequest.sr_name}</td>
                          <td>{creditRequest.customer_name}</td>
                          <td>
                            {tenantConfiguration.currencyCode}{' '}
                            {numberFormat(creditRequest.total_amount).displayFormat}
                          </td>
                          <td>
                            {tenantConfiguration.currencyCode}{' '}
                            {numberFormat(creditRequest.requested_amount).displayFormat}
                          </td>
                          <td>
                            {dateFormat(creditRequest.due_date).displayDate}{' '}
                            {`(${calculateDays(
                              creditRequest.pay_later_today_created_at
                                ? dateFormat(creditRequest.pay_later_today_created_at).apiDate
                                : dateFormat(creditRequest.created_at).apiDate,
                              creditRequest.due_date
                            )})`}
                          </td>
                          <td className='text-end'>
                            {creditRequest.pay_later_today_created_at ? (
                              <span
                                onClick={() => goToDetailPage(creditRequest.id)}
                                className='badge bg-light-success text-dark cursor-pointer'
                              >
                                Auto Approved
                              </span>
                            ) : (
                              <>
                                {creditRequest.status === 'pending' ? (
                                  <>
                                    <span
                                      onClick={() => goToDetailPage(creditRequest.id)}
                                      className='badge bg-secondary text-dark cursor-pointer'
                                    >
                                      Pending
                                    </span>
                                  </>
                                ) : ['approved', 'completed'].includes(creditRequest.status) ? (
                                  <span
                                    onClick={() => goToDetailPage(creditRequest.id)}
                                    className='badge bg-light-success text-dark cursor-pointer'
                                  >
                                    Approved
                                  </span>
                                ) : creditRequest.status === 'rejected' ? (
                                  <span
                                    onClick={() => goToDetailPage(creditRequest.id)}
                                    className='badge bg-light-danger text-dark cursor-pointer'
                                  >
                                    Rejected
                                  </span>
                                ) : creditRequest.status === 'cancelled' ? (
                                  <span
                                    onClick={() => goToDetailPage(creditRequest.id)}
                                    className='badge bg-secondary text-dark cursor-pointer'
                                  >
                                    Cancelled
                                  </span>
                                ) : (
                                  '-'
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={creditRequests}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
        />
      </div>

      <CreditRequestRejectionModal className='' />
    </>
  )
}

export default CreditRequest
