import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {KTSVG} from '../../../../../_eaFruitsDms/helpers'

let shoeEditBtn = true
const MyAssignedHeader: React.FC = () => {
  const location = useLocation()

  return (
    <div className='card mb-4 mb-xl-5'>
      <div className='card-body pt-5 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-1'>
                  <span className='text-dark font-20 fw-bold me-1'>
                    #314234 Support Request Title
                  </span>
                </div>
                <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                  <span className='d-flex align-items-center me-6 mb-2'>
                    <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                    29 Mar 2022, 05:32PM
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-40px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-0 ` +
                  (location.pathname === '/complaints/my-assigned/overview' && 'active')
                }
                to='/complaints/my-assigned/overview'
              >
                Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-0 ` +
                  (location.pathname === '/complaints/my-assigned/messages' && 'active')
                }
                to='/complaints/my-assigned/messages'
              >
                Messages
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MyAssignedHeader
