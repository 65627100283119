import clsx from "clsx";
import React from "react";
import tenantConfiguration from "../../../../TenantVariables";
import ValidationHandler from "../../../helpers/components/validationHandler";

const MobileNumberInput = (props: any) => {
    const { validation, setValidation, numberValue, setNumberValue, name, onChange, label, contactNoVerify, placeholder, isOptional } = props
    const prefix = tenantConfiguration.baseCountryCode
    return (
        <>
            <label className='form-label'>{label ? label : "Mobile Number"}{!isOptional && <span
              className="text-danger">*</span>}</label>
            <div
                className={clsx('input-group',
                    validation === 'is-invalid' ? 'is-invalid-grp' : ''
                )}
            >
                <span className='input-group-text bg-white border-end-0'>{prefix}</span>
                <input
                    type='text'
                    className={clsx('form-control border-start-0 ps-0 border-focus-light', validation)}
                    maxLength={9}
                    minLength={9}
                    disabled={contactNoVerify}
                    placeholder={placeholder ? placeholder : 'Enter mobile number'}
                    value={numberValue}
                    name={name}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e)
                        } else {
                            const re = /^[0-9\b]+$/
                            if (e.target.value === '' || re.test(e.target.value)) {
                                setNumberValue(e.target.value)
                                const validation = ValidationHandler(e.target.value, 'is-invalid')
                                setValidation(validation)
                            }
                        }
                    }}
                />
            </div>
        </>
    )
}

export default MobileNumberInput
