import {
  SUMMARY_REPORT_REPORT_WAREHOUSE,
  SUMMARY_REPORT_REPORT_START_DATE,
  SUMMARY_REPORT_REPORT_END_DATE,
  SUMMARY_REPORT_REPORT_DATE,
} from '../../types/types'

export const setWarehouseValue = (val: any) => (dispatch: any) => {
  dispatch({
    type: SUMMARY_REPORT_REPORT_WAREHOUSE,
    payload: val,
  })
}

export const setStartDate = (val: any) => (dispatch: any) => {
  dispatch({
    type: SUMMARY_REPORT_REPORT_START_DATE,
    payload: val,
  })
}

export const setEndDate = (val: any) => (dispatch: any) => {
  dispatch({
    type: SUMMARY_REPORT_REPORT_END_DATE,
    payload: val,
  })
}

export const setDate = (val: any) => (dispatch: any) => {
  dispatch({
    type: SUMMARY_REPORT_REPORT_DATE,
    payload: val,
  })
}
