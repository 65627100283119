import moment from 'moment'
import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../_eaFruitsDms/helpers'
import CustomPagination from '../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'

const MyClosed = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Complaints',
    subTitle: 'My Closed',
  }

  // function MyClosed() {

  return (
    <>
      <EATitle title='My Closed - ' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'My Closed'})}
      </PageTitle>

      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar />
          <div className='ms-md-auto mt-2 mt-md-0'>
            <div className='card-toolbar d-flex my-0'>
              <DateRangePicker
                initialSettings={{
                  startDate: '01/01/2020',
                  endDate: '01/12/2020',
                  alwaysShowCalendars: true,
                  cancelClass: 'btn-secondary',
                  drops: 'auto',
                  locale: {
                    format: 'DD/MM/YYYY',
                  },
                  ranges: {
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [
                      moment().subtract(1, 'month').startOf('month'),
                      moment().subtract(1, 'month').endOf('month'),
                    ],
                  },
                }}
              >
                <input type='text' className='form-control calendar w-auto' />
              </DateRangePicker>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
              {/* begin::Table head */}
              <thead>
                <tr className='text-muted fw-500'>
                  <th className='min-w-100px py-2'>Complaint ID</th>
                  <th className='min-w-120px py-2'>Complaint Title</th>
                  <th className='min-w-120px py-2'>Customer Name</th>
                  <th className='min-w-120px py-2'>Assigned To</th>
                  <th className='min-w-120px py-2'>Rating</th>
                  <th className='min-w-120px py-2'>Created On</th>
                  <th className='min-w-120px py-2'>Resolved On</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr className='cursor-pointer'>
                  <td>#314234</td>
                  <td>Complaint Title</td>
                  <td>Victor Adkins</td>
                  <td>Thomas George</td>
                  <td>
                    <KTSVG
                      path='/media/icons/duotune/general/gen029.svg'
                      className='ic text-warning me-1'
                    />
                    4.2
                  </td>
                  <td>
                    01 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    29 Apr 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                </tr>
                <tr className='cursor-pointer'>
                  <td>#214561</td>
                  <td>Complaint Title</td>
                  <td>Madge Rodriguez</td>
                  <td>Thomas George</td>
                  <td>
                    <KTSVG
                      path='/media/icons/duotune/general/gen029.svg'
                      className='ic text-warning me-1'
                    />
                    3.8
                  </td>
                  <td>
                    13 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    20 Feb 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                </tr>
                <tr className='cursor-pointer'>
                  <td>#186541</td>
                  <td>Complaint Title</td>
                  <td>Francisco Grant</td>
                  <td>William Wise</td>
                  <td>
                    <KTSVG
                      path='/media/icons/duotune/general/gen029.svg'
                      className='ic text-warning me-1'
                    />
                    4.8
                  </td>
                  <td>
                    04 Oct 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    28 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                </tr>
                <tr className='cursor-pointer'>
                  <td>#314234</td>
                  <td>Complaint Title</td>
                  <td>Victor Adkins</td>
                  <td>Thomas George</td>
                  <td>
                    <KTSVG
                      path='/media/icons/duotune/general/gen029.svg'
                      className='ic text-warning me-1'
                    />
                    4.2
                  </td>
                  <td>
                    01 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    29 Apr 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                </tr>
                <tr className='cursor-pointer'>
                  <td>#214561</td>
                  <td>Complaint Title</td>
                  <td>Madge Rodriguez</td>
                  <td>Thomas George</td>
                  <td>
                    <KTSVG
                      path='/media/icons/duotune/general/gen029.svg'
                      className='ic text-warning me-1'
                    />
                    3.8
                  </td>
                  <td>
                    13 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    20 Feb 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                </tr>
                <tr className='cursor-pointer'>
                  <td>#186541</td>
                  <td>Complaint Title</td>
                  <td>Francisco Grant</td>
                  <td>William Wise</td>
                  <td>
                    <KTSVG
                      path='/media/icons/duotune/general/gen029.svg'
                      className='ic text-warning me-1'
                    />
                    4.8
                  </td>
                  <td>
                    04 Oct 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    28 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>Showing 1 to 10 of 50 entries</div>
          <div className='col'>
            <CustomPagination />
          </div>
        </div>
      </div>
    </>
  )
}

export default MyClosed
