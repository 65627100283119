import clsx from 'clsx'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {Rating} from 'react-simple-star-rating'
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  GLOBAL_DATE_FORMAT,
  isEmpty,
  KTSVG,
} from '../../../../../_eaFruitsDms/helpers'
import DatePickerCustom from '../../../../../_eaFruitsDms/helpers/datePicker'
import {getRequest, postRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import ErrorHandler from '../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../../../_eaFruitsDms/layout/components/loader/loader'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'

const AddSurveyForm = () => {
  const navigate = useNavigate()
  const location: any = useLocation()

  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'Survey Forms',
    subTitle: 'Survey Forms',
  }

  const initialQuestions = {
    id: 0,
    survey_form_name: '',
    access_to: '',
    questions: [
      {
        id: 0,
        question_title: '',
        question_type: '',
        placeholder: '',
        use_for_analytic: true,
        option_text: '',
        question_options: 'Option 1',
      },
    ],
  }

  const initialForm = {
    answers: [],
    submited_at: '', //2022-10-27T08:54:43.635Z
    surveyform_id: 0,
    customer_id: 0,
  }

  const initialValidation = [
    {
      answerOfQue: '',
      questionId: 162,
      questionTitle: '',
      questionType: '',
    },
  ]

  const errMessage = 'This field is required.'
  // Optinal callback functions
  const onPointerEnter = () => {
    //console.log('Enter')
  }
  const onPointerLeave = () => {
    //console.log('Leave')
  }
  const onPointerMove = (value: number, index: number) => {
    //console.log(value, index)
  }

  const customIcons = [
    {
      icon: <KTSVG path='/media/icons/duotune/general/gen029.svg' svgClassName='star-svg' />,
    },
    {
      icon: <KTSVG path='/media/icons/duotune/general/gen029.svg' svgClassName='star-svg' />,
    },
    {
      icon: <KTSVG path='/media/icons/duotune/general/gen029.svg' svgClassName='star-svg' />,
    },
    {
      icon: <KTSVG path='/media/icons/duotune/general/gen029.svg' svgClassName='star-svg' />,
    },
    {
      icon: <KTSVG path='/media/icons/duotune/general/gen029.svg' svgClassName='star-svg' />,
    },
  ]
  const initialValidationValue = {
    customerName: '',
    dateTime: '',
  }
  let defaultDate = dateFormat(moment(), GLOBAL_DATE_FORMAT + ' hh:mm a')
  const [surveyFormDetail, setSurveyFormDetail] = useState<any>(null)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [pageTitle, setPageTitle] = useState<string>('Survey Feedback Form')
  const [feedBackId, setFeedBackId] = useState('')
  const [disableSurveyCreatedDate, setDisableCreateDate] = useState(defaultDate.displayDate)
  const [submitEvent, setSubmitEvent] = useState(false)
  const [customerOffset, setCustomerOffset] = useState(0)
  const [customerList, setCustomerList] = useState([])
  const [customerId, setCustomerId] = useState([])
  const [search, setSearch] = useState([])
  const [customerDetails, setCustomerDetails] = useState({phoneNumber: '-', warehouse: '-'})
  const [surveyInformationQuestion, setSurveyInformation] = useState(initialQuestions)
  const [submitForm, setSubmitForm] = useState(initialForm)
  const [validationQue, setValidationQue] = useState(initialValidation)
  const [multipalAnswer, setMultipalAnswer] = useState<any>([])
  const [selcetedMultipalAnswer, setSelectedMultipalAnswer] = useState<any>([])
  const [validation, setValidation] = useState(initialValidationValue)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsLoading] = useState('Submit')
  const [index, setIndex] = useState([])
  const [answerState, setAnswerState] = useState<any>('')
  const [errorMessage, setErrorMessage] = useState('')

  const getSurveyInformationQuestions = async () => {
    const url = apiBaseURL() + 'surveyForms/form/' + feedBackId
    const response = await getRequest(url, true)
    let questionState: any = []
    let indexs: any = []
    if (response.questions.length !== 0) {
      response.questions.forEach((item: any, index: any) => {
        let question = {}
        if (item.question_type === 'multiple_choice') {
          question = {
            questionId: item.id,
            questionTitle: item.question_title,
            questionType: item.question_type,
            // answerOfQue: "fill"
            answerOfQue: '',
          }
        } else {
          question = {
            questionId: item.id,
            questionTitle: item.question_title,
            questionType: item.question_type,
            answerOfQue: '',
          }
          indexs.push(index)
        }
        questionState.push(question)
      })
    }

    setValidationQue(questionState)
    setSurveyInformation(response)
    setIndex(indexs)
  }

  const fetchcustomers = async (search?: any) => {
    const id = parseInt(feedBackId)

    return fetchAsyncDropdown(
      customerOffset,
      setCustomerOffset,
      customerList,
      setCustomerList,
      'customers/drop-down?ordering=business_name&survey_form_id=' + id,
      search,
      setSearch,
      'business_name'
    )
  }

  const handleChange = async (e: any) => {
    setCustomerId(e.value)
    formHandler('CustomerName', e.value, '')
    const url = apiBaseURL() + 'customers/' + e.value
    const response = await getRequest(url, true)
    setCustomerDetails({
      ...customerDetails,
      phoneNumber: response.contact_no,
      warehouse: response.warehouse_name ? response.warehouse_name : '-',
    })
  }

  const cancelHandler = () => {
    navigate('/survey-forms')
  }
  const [dateValue, setDateValue] = useState('Select')
  const [dateApi, setDateApi] = useState('')
  const [initialSettings, setInitialSettings] = useState({
    alwaysShowCalendars: true,
    singleDatePicker: true,
    timePicker: true,
    cancelClass: 'btn-secondary',
    maxDate: defaultDate.displayDate,
    // startDate: disableSurveyCreatedDate,
    startDate: moment(),
    drops: 'auto',
    autoUpdateInput: false,
    locale: {
      format: GLOBAL_DATE_FORMAT + ' hh:mm a',
    },
  })

  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        initialSettings={initialSettings}
        onCallback={(start: any, end: any, label: any) => {
          let newDate = dateFormat(start._d, GLOBAL_DATE_FORMAT + ' hh:mm a')
          setDateValue(newDate.displayDate)
          setDateApi(newDate.apiDate)
          setInitialSettings({...initialSettings, startDate: start._d})
          let newApiDate = dateFormat(start._d, 'YYYY-MM-DD hh:mm')
          formHandler('D&T', newApiDate.displayDate, '')
        }}
        dateValue={dateValue}
        dateValidation={validation.dateTime}
      />
    )
  }
  const initialQuestionValue = {
    answer: '',
    questions_id: '',
  }

  const formHandler = (key: string, value: any, queIndex: any) => {
    setErrorMessage('')
    if (key === 'CustomerName') {
      setSubmitForm({...submitForm, customer_id: value})
      setValidation({...validation, customerName: ''})
    }

    if (key === 'D&T') {
      setSubmitForm({...submitForm, submited_at: value})
      setValidation({...validation, dateTime: ''})
    }

    // new implement here :
    let answerInitialValue: any = index

    if (key === 'textBox') {
      answerInitialValue[queIndex] = value
    }
    if (key === 'textArea') {
      answerInitialValue[queIndex] = value
    }
    if (key === 'radio') {
      answerInitialValue[queIndex] = value
    }
    if (key === 'rating') {
      answerInitialValue[queIndex] = value
    }
    if (key === 'checkbox') {
      if (value.event.checked) {
        setMultipalAnswer([
          ...multipalAnswer,
          {
            answer: value.answer,
            questions_id: value.questions_id,
            option_id: value.option_id,
          },
        ])
      } else {
        const filterAns = multipalAnswer.filter((item: any) => {
          return item.option_id !== value.option_id
        })
        setMultipalAnswer(filterAns)
      }
    }

    setAnswerState(answerInitialValue)
  }
  const submitSurveyFeedBackForm = () => {
    // surveyInformationQuestion.questions.map((item: any, queIndex: any) => {
    //   let title: any = []
    //   item && title.push(item.question_title)

    //   let navigateField = {
    //     customer_name: submitForm.customer_id !== 0 ? submitForm.customer_id : '',
    //     date: submitForm.submited_at,
    //     [title]: submitEvent && validationQue[queIndex].answerOfQue === '' ? '' : 'Parent',
    //   }
    //   console.log('navigateField', navigateField)

    //   isModalValidation(navigateField).length !== 0 &&
    //     focusVatidateField(isModalValidation(navigateField)[0])
    // })

    setSubmitEvent(true)
    if (submitForm.customer_id === 0) {
      setValidation((pre) => ({...pre, customerName: 'is-invalid'}))
      setErrorMessage(
        'Please make sure you have filled out all the fields marked with an asterisk (*)'
      )
    } else {
      setValidation((pre) => ({...pre, customerName: ''}))
    }
    if (submitForm.submited_at === '') {
      setValidation((pre) => ({...pre, dateTime: 'is-invalid'}))
      setErrorMessage(
        'Please make sure you have filled out all the fields marked with an asterisk (*)'
      )
    } else {
      setValidation((pre) => ({...pre, dateTime: ''}))
    }

    let validaiton: any = [0]

    if (validationQue.length !== 0) {
      validaiton = []
      validationQue.forEach((item, index) => {
        if (item.answerOfQue === '') {
          validaiton.push(index)
          setErrorMessage(
            'Please make sure you have filled out all the fields marked with an asterisk (*)'
          )
        }
      })
    }

    let multipleChoice = ''

    surveyInformationQuestion.questions.forEach((element, index) => {
      if (element.question_type === 'multiple_choice') {
        multipleChoice = 'multiple_choice'
      }
    })

    if (multipleChoice === 'multiple_choice') {
      if (selcetedMultipalAnswer.length === 0) {
        validaiton.push(1)
      }
    }

    if (submitForm.customer_id !== 0 && submitForm.submited_at !== '') {
      if (validaiton.length === 0) {
        // new submit code here
        setIsSubmitting(true)
        let answer: any = []

        answerState.length !== 0 &&
          answerState.forEach((item: any) => {
            item.answer && answer.push(item)
          })

        multipalAnswer.length !== 0 &&
          multipalAnswer.forEach((item: any) => {
            answer.push(item)
          })

        submitForm.answers = answer

        postRequest(apiBaseURL() + 'surveyForms/answer/create', submitForm, true).then((res) => {
          if (res.status === 201 || res.status === 200) {
            responseHandler()
          } else {
            window.scrollTo(0, 0)
            setIsAlertFailed(true)
          }

          setTimeout(() => {
            setIsAlertFailed(false)
          }, 1500)
        })
      }
    }
  }

  const responseHandler = () => {
    localStorage.setItem('successAddTsSurveyForm', 'success')
    cancelHandler()
    setIsSubmitting(false)
  }

  const isAlertSHow = (val: any) => {
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  useEffect(() => {
    const res = Object.values(
      multipalAnswer.reduce((a: any, {answer, questions_id}: any) => {
        a[questions_id] = a[questions_id] || {questions_id, answer: new Set()}
        a[questions_id].answer.add(answer)
        return a
      }, {})
    ).map(({answer, questions_id}: any) => ({answer: [...answer].join(','), questions_id}))

    setSelectedMultipalAnswer(res)
  }, [multipalAnswer])

  useEffect(() => {
    if (feedBackId !== '') {
      getSurveyInformationQuestions()
      let id = parseInt(feedBackId)
      setSubmitForm({...submitForm, surveyform_id: id})
    }
  }, [feedBackId])

  useEffect(() => {
    if (location.state.feedBackId) {
      setFeedBackId(location.state.feedBackId)
    }
    if (location.state.surveyFormCreatedDate) {
      let newDate = dateFormat(
        location.state.surveyFormCreatedDate,
        GLOBAL_DATE_FORMAT + ' hh:mm a'
      )
      console.log('newDate == ', newDate.displayDate)
      setDisableCreateDate(newDate.displayDate)
      setInitialSettings({...initialSettings, maxDate: newDate.displayDate})
    }
  }, [])

  useEffect(() => {
    setCustomerOffset(0)
  }, [search])

  return (
    <>
      <div>
        <PageTitle breadcrumbs={[adminHeaderTitle]} backNavigate='/survey-forms'>
          {isEmpty(surveyInformationQuestion.survey_form_name)
            ? '-'
            : surveyInformationQuestion.survey_form_name}
        </PageTitle>
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed to submit Survey Feedback Form'
        />
        <div className='card'>
          {displayLoader && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
            {/* begin::Title */}
            <h3 className='card-title align-items-start flex-column my-0'>
              <span className='card-label fw-bold font-17'>{pageTitle}</span>
            </h3>
            {/* end::Title */}
          </div>
          {/* <div className='card-body pt-4'> */}
          <form className='card-body pt-4' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'>Customer Information</h3>
            <div className='row'>
              <div className='col-md-5'>
                <div className='mb-4' id='customer_name'>
                  <label className='form-label'>
                    Customer Name<span className='text-danger'>*</span>
                  </label>
                  <AsyncPaginate
                    loadOptions={fetchcustomers}
                    placeholder='Select'
                    isSearchable
                    className={`react-select-container ${validation.customerName}`}
                    classNamePrefix='react-select'
                    value={customerList.find((option: any) => option.value === customerId)}
                    //  name='access_to'
                    onChange={(e: any) => handleChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
                <div className='mb-4'>
                  <label className='form-label'>Customer Phone Number</label>
                  <input
                    type='text'
                    name='surve_form_name'
                    className={clsx('form-control')}
                    value={customerDetails.phoneNumber}
                    defaultValue='(749) 728-2651'
                    placeholder='Type here...'
                    disabled
                    // onChange={handleChange}
                  />
                </div>
                <div className='mb-4'>
                  <label className='form-label'>Mapped Warehouse</label>
                  <input
                    type='text'
                    name='surve_form_name'
                    className={clsx('form-control')}
                    value={customerDetails.warehouse}
                    defaultValue='Warehouse 1'
                    placeholder='Type here...'
                    disabled
                    // onChange={handleChange}
                  />
                </div>
                <div className='mb-4'>
                  <label className='form-label' id='date'>
                    Date & Time<span className='text-danger'>*</span>
                  </label>
                  <CustomDatePicker />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-7'>
                <div className='border-top mt-1'></div>
              </div>
            </div>
            <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-4'>Survey Information</h3>
            {surveyInformationQuestion.survey_form_name !== '' &&
              surveyInformationQuestion.questions.map((item, queIndex) => {
                let questions_id = item.id
                // console.log('question_item', item)

                if (item.question_type === 'multiple_choice') {
                  let result: any = item.question_options
                  return (
                    <div className='mb-4'>
                      <label className='form-label'>
                        {item.question_title}
                        <span className='text-danger'>*</span>
                      </label>
                      <div className='d-flex flex-wrap'>
                        {result.length !== 0 &&
                          result.map((item: any, index: any) => {
                            return (
                              <div
                                className='form-check form-check-sm form-check-custom form-check-solid my-1 me-4'
                                id='checkbox'
                              >
                                <input
                                  className='form-check-input widget-13-check me-2'
                                  type='checkbox'
                                  onChange={(e) => {
                                    let que = {
                                      event: e.target,
                                      answer: e.target.value,
                                      questions_id: questions_id,
                                      option_id: item.id,
                                    }
                                    formHandler('checkbox', que, queIndex)
                                    validationQue[queIndex].answerOfQue = 'fill'
                                  }}
                                  value={item.option_text}
                                  id={item.id}
                                />
                                <label className='text-dark' htmlFor={item.id}>
                                  {item.option_text}
                                  <span className='text-danger'></span>
                                </label>
                              </div>
                            )
                          })}
                      </div>
                      {/* {submitEvent && multipalAnswer.length === 0 && <ErrorHandler errorMessage={errMessage} />} */}
                      {submitEvent && validationQue[queIndex].answerOfQue === '' && (
                        <ErrorHandler errorMessage={errMessage} />
                      )}
                    </div>
                  )
                }

                if (item.question_type === 'text_box') {
                  return (
                    <div className='mb-4'>
                      <label className='form-label' id={item.question_title}>
                        {item.question_title}
                        <span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        name='surve_form_name'
                        className={clsx(
                          'form-control',
                          submitEvent && validationQue[queIndex].answerOfQue === ''
                            ? 'is-invalid'
                            : ''
                        )}
                        onChange={(e) => {
                          let que = {
                            answer: e.target.value,
                            questions_id: questions_id,
                          }
                          formHandler('textBox', que, queIndex)
                          validationQue[queIndex].answerOfQue = e.target.value
                        }}
                        value={surveyFormDetail && surveyFormDetail.surve_form_name}
                        placeholder={item.placeholder}
                      />
                    </div>
                  )
                }

                if (item.question_type === 'text_area') {
                  return (
                    <div className='mb-4'>
                      <label className='form-label' id={item.question_title}>
                        {item.question_title}
                      </label>
                      <span className='text-danger'>*</span>
                      <textarea
                        className={`form-control pe-10 ${
                          submitEvent && validationQue[queIndex].answerOfQue === ''
                            ? 'is-invalid'
                            : ''
                        }`}
                        rows={3}
                        id='question_title'
                        onChange={(e) => {
                          let que = {
                            answer: e.target.value,
                            questions_id: questions_id,
                          }
                          formHandler('textArea', que, queIndex)
                          validationQue[queIndex].answerOfQue = e.target.value
                        }}
                        placeholder={item.placeholder}
                      ></textarea>
                    </div>
                  )
                }

                if (item.question_type === 'single_choice') {
                  let result: any = item.question_options
                  return (
                    <div className='mb-4'>
                      <label className='form-label' id={item.question_title}>
                        {item.question_title}
                      </label>
                      <span className='text-danger'>*</span>
                      <div className='d-flex flex-wrap'>
                        {result.length !== 0 &&
                          result.map((item: any, index: any) => {
                            return (
                              <div className='form-check form-check-sm form-check-custom form-check-check form-check-solid my-1 me-4'>
                                <input
                                  className='form-check-input widget-13-check me-2'
                                  type='radio'
                                  name={`q5_opt_${queIndex}`}
                                  value={item.option_text}
                                  id={item.id}
                                  onChange={(e) => {
                                    let que = {
                                      answer: e.target.value,
                                      questions_id: questions_id,
                                      option_id: item.id,
                                    }
                                    formHandler('radio', que, queIndex)
                                    validationQue[queIndex].answerOfQue = e.target.value
                                  }}
                                />
                                <label className='text-dark' htmlFor={item.id}>
                                  {item.option_text}
                                  <span className='text-danger'></span>
                                </label>
                              </div>
                            )
                          })}
                      </div>
                      {submitEvent && validationQue[queIndex].answerOfQue === '' && (
                        <ErrorHandler errorMessage={errMessage} />
                      )}
                    </div>
                  )
                }

                if (item.question_type === 'star_rating') {
                  return (
                    <div>
                      <label className='form-label' id={item.question_title}>
                        {/* <label className='form-label' id='rating'> */}
                        {item.question_title}
                      </label>
                      <span className='text-danger'>*</span>
                      <div>
                        <Rating
                          onClick={(rate) => {
                            let que = {
                              answer: rate,
                              questions_id: questions_id,
                            }
                            formHandler('rating', que, queIndex)
                            validationQue[queIndex].answerOfQue = rate.toString()
                          }}
                          size={24}
                          transition
                          allowFraction
                          className='react-simple-star-rating'
                          customIcons={customIcons}
                          onPointerEnter={onPointerEnter}
                          onPointerLeave={onPointerLeave}
                          onPointerMove={onPointerMove}
                          SVGclassName='star-svg'
                          fillColor='#f9c527'
                          emptyColor='#ebebeb'
                        />
                      </div>
                      {submitEvent && validationQue[queIndex].answerOfQue === '' && (
                        <ErrorHandler errorMessage={errMessage} />
                      )}
                    </div>
                  )
                }
              })}
            <div className='mb-3 mt-4'>
              <ErrorHandler errorMessage={errorMessage} />
            </div>
            {/* </div> */}
          </form>

          <div className='card-footer py-2'>
            <button
              type='button'
              disabled={isSubmitting}
              className='btn btn-secondary me-3'
              onClick={cancelHandler}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              disabled={isSubmitting}
              onClick={submitSurveyFeedBackForm}
            >
              {isSubmitting ? 'Please wait...' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddSurveyForm
