import { apiBaseURL } from "../../helpers"
import { getRequest } from "../../https/apiCall"

export const dailyStockInOutReport = async (
  search?: any,
  ordering?: any,
  stock_type?: any,
  stock_entry_type?: any,
  stock_id?: any,
  stock_date?: any,
  transfer_status?: any,
  action_by__id?: any,
  sender_warehouse_id?: any,
  receiver_warehouse_id?: any,
  sales_representative?: any,
  limit?: any,
  offset?: any
) => {
  const data = {
    data: [],
    count: '',
    next: '',
    prev: '',
  }
  let url = `?search=${search}&ordering=${ordering}&stock_type=${stock_type}&stock_entry_type=${stock_entry_type}&stock_id=${stock_id}&stock_date=${stock_date}&transfer_status=${transfer_status}&action_by__id=${action_by__id}&sender_warehouse_id=${sender_warehouse_id}&receiver_warehouse_id=${receiver_warehouse_id}&sales_representative=${sales_representative}&limit=${limit}&offset=${offset}`
  await getRequest(`${apiBaseURL()}inventory/view_stock_in_out` + url, true).then(
    (res) => {
      data.count = res.count
      data.next = res.next
      data.prev = res.previous
      data.data = res.results
    }
  )
  return data
}

export const OODropDownList = async (
  role__id?: any,
  warehouse__id?: any,
  search?: any,
  limit?: any,
  offset?: any
) => {
  let url = `?role__id=${role__id}&warehouse__id=${warehouse__id}&search=${search}&limit=${limit}&offset=${offset}`
  const res = await getRequest(`${apiBaseURL()}auth/users/team/list` + url, true)

  return {
    data: res,
  }
}
