import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_eaFruitsDms/helpers'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'

const Messages = () => {
  const intl = useIntl()
  const supportReqTitle = {
    title: 'Complaints',
    subTitle: 'My Assigned',
  }
  return (
    <>
      <PageTitle breadcrumbs={[supportReqTitle]}>
        {intl.formatMessage({id: 'My Assigned'})}
      </PageTitle>
      <div className='card mb-5 mb-xl-6'>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <div className='d-flex'>
            <span className='rounded-circle bg-light-warning pro-icon me-3 d-inline-block text-dark font-10'>
              TS
            </span>
            <div className='media-body'>
              <p className='m-0 mb-1 xs-font fw-500'>Tele-Sales Person</p>
              <p className='m-0 xs-font fw-500'>
                <span className=' text-muted'>Replied to</span>{' '}
                <span className='text-primary'>Elizabeth Francis</span>
              </p>
            </div>
          </div>
          <div className='ms-md-auto mt-2 mt-md-0'>
            <div className='card-toolbar d-flex my-0'>
              <p className=' fw-500 text-muted'>01 Jun 2022 12:20PM</p>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col-md-10'>
              <p className='text-muted mb-1'>Hi</p>
              <p className='text-muted'>
                Lorem ipsum dolor sit amet. Et cumque unde eum distinctio repellendus aut quia
                tempora quo dolores facere. Eum velit nisi quo unde eaque et excepturi minus in
                dignissimos atque sit nostrum amet ut atque dolorem.
              </p>
              <p className='text-muted mb-1'>01. Go to my Order</p>
              <p className='text-muted mb-1'>
                02. Click on the edit details icon for a order which you’d like update.
              </p>
              <p className='text-muted'>03. Go to my Order</p>
              <p className='text-muted'>Thanks!</p>
              <div className='pt-3'>
                <label
                  htmlFor='attachFile'
                  className='btn btn-with-icon btn-icon-dark fw-600 py-1 lnh-36px px-0'
                >
                  <KTSVG path='/media/icons/duotune/communication/com008.svg' className='ic me-4' />
                </label>
                <input
                  className='form-control invisible position-absolute'
                  type='file'
                  accept='application/pdf, image/*, application/msword'
                  id='attachFile'
                />
                <KTSVG path='/media/icons/duotune/art/art001.svg' className='ic' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-7'>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <div className='d-flex align-items-center'>
            <span className='rounded-circle bg-light-warning pro-icon me-3 d-inline-block text-dark font-10'>
              EF
            </span>
            <div className='media-body'>
              <p className='m-0 mb-1 xs-font fw-500'>
                <span className='text-primary'>Elizabeth Francis </span>
                <span className=' text-muted'>Replied this conversation</span>
              </p>
              <p className='m-0 xs-font fw-500'>
                <span className=' text-muted'>
                  Survey response for default Survey to Tele-Sales Person
                </span>{' '}
              </p>
            </div>
          </div>
          <div className='ms-md-auto mt-2 mt-md-0'>
            <div className='card-toolbar d-flex my-0'>
              <p className=' fw-500 text-muted'>20 May 2022 08:15AM</p>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col-md-10'>
              <p className='text-muted mb-1'>Hi</p>
              <p className='text-muted mb-1'>
                Lorem ipsum dolor sit amet. Et cumque unde eum distinctio repellendus aut quia
                tempora quo dolores facere. Eum velit nisi quo unde eaque et excepturi minus in
                dignissimos atque sit nostrum amet ut atque dolorem.
              </p>
              <p className='text-muted'>Thanks!</p>
            </div>
            <div className='col-md-2 d-flex align-items-end flex-column'>
              <label
                htmlFor='attachFile'
                className='btn btn-with-icon btn-icon-dark fw-600 py-1 lnh-36px px-0'
              >
                <KTSVG path='/media/icons/duotune/communication/com008.svg' className='ic' />
              </label>
              <input
                className='form-control invisible position-absolute'
                type='file'
                accept='application/pdf, image/*, application/msword'
                id='attachFile'
              />
              <KTSVG path='/media/ic_dots_red.svg' className='ic notification_dot' />
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-6'>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <div className='d-flex'>
            <span className='rounded-circle bg-light-warning pro-icon me-3 d-inline-block text-dark font-10'>
              TS
            </span>
            <div className='media-body'>
              <p className='m-0 mb-1 xs-font fw-500'>Tele-Sales Person</p>
              <p className='m-0 xs-font fw-500'>
                <span className=' text-muted'>Replied to</span>{' '}
                <span className='text-primary'>Elizabeth Francis</span>
              </p>
            </div>
          </div>
          <div className='ms-md-auto mt-2 mt-md-0'>
            <div className='card-toolbar d-flex my-0'>
              <p className=' fw-500 text-muted'>12 May 2022 05:32PM</p>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col-md-10'>
              <p className='text-muted mb-1'>Hi</p>
              <p className='text-muted'>
                Lorem ipsum dolor sit amet. Et cumque unde eum distinctio repellendus aut quia
                tempora quo dolores facere. Eum velit nisi quo unde eaque et excepturi minus in
                dignissimos atque sit nostrum amet ut atque dolorem.
              </p>
              <p className='text-muted mb-1'>01. Go to my Order</p>
              <p className='text-muted mb-1'>
                02. Click on the edit details icon for a order which you’d like update.
              </p>
              <p className='text-muted'>03. Go to my Order</p>
              <p className='text-muted'>Thanks!</p>
            </div>
            <div className='col-md-2 d-flex align-items-end flex-column'>
              <label
                htmlFor='attachFile'
                className='btn btn-with-icon btn-icon-dark fw-600 py-1 lnh-36px px-0'
              >
                <KTSVG path='/media/icons/duotune/communication/com008.svg' className='ic' />
              </label>
              <input
                className='form-control invisible position-absolute'
                type='file'
                accept='application/pdf, image/*, application/msword'
                id='attachFile'
              />
              <KTSVG path='/media/ic_dots_red.svg' className='ic notification_dot' />
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-7'>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <div className='d-flex align-items-center'>
            <span className='rounded-circle bg-light-warning pro-icon me-3 d-inline-block text-dark font-10'>
              EF
            </span>
            <div className='media-body'>
              <p className='m-0 mb-1 xs-font fw-500'>
                <span className='text-primary'>Elizabeth Francis </span>
                <span className=' text-muted'>reported in Issue</span>
              </p>
            </div>
          </div>
          <div className='ms-md-auto mt-2 mt-md-0'>
            <div className='card-toolbar d-flex my-0'>
              <p className=' fw-500 text-muted'>01 May 2022 05:32PM</p>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col-md-10'>
              <p className='text-muted mb-1'>Hi</p>
              <p className='text-muted mb-1'>
                Lorem ipsum dolor sit amet. Et cumque unde eum distinctio repellendus aut quia
                tempora quo dolores facere. Eum velit nisi quo unde eaque et excepturi minus in
                dignissimos atque sit nostrum amet ut atque dolorem.
              </p>
              <p className='text-muted'>Thanks!</p>
            </div>
            <div className='col-md-2 d-flex align-items-end flex-column'>
              <label
                htmlFor='attachFile'
                className='btn btn-with-icon btn-icon-dark fw-600 py-1 lnh-36px px-0'
              >
                <KTSVG path='/media/icons/duotune/communication/com008.svg' className='ic' />
              </label>
              <input
                className='form-control invisible position-absolute'
                type='file'
                accept='application/pdf, image/*, application/msword'
                id='attachFile'
              />
              <KTSVG path='/media/ic_dots_red.svg' className='ic notification_dot' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Messages
