import { initializeApp } from 'firebase/app'
import { getMessaging, getToken as token, onMessage } from 'firebase/messaging'

// eafruits
const firebaseConfig = {
    apiKey: 'AIzaSyBqcokxzyIZxk7Iiwhs5P3eb7SvZhaVvFw',
    authDomain: 'ea-fruits-dms-firebase.firebaseapp.com',
    projectId: 'ea-fruits-dms-firebase',
    storageBucket: 'ea-fruits-dms-firebase.appspot.com',
    messagingSenderId: '472879923643',
    appId: '1:472879923643:web:a9fcb32b66cf4f6a25849e',
    measurementId: 'G-4HVTTZCYP2',
}

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound?: any) => {
    let currentToken = "";

    try {
        currentToken = await token(messaging, { vapidKey: publicKey })
        if (currentToken) {
            setTokenFound(true);
        } else {
            setTokenFound(false);
        }
    } catch (error) {
        console.log("An error occurred while retrieving token. ", error);
    }

    return currentToken;
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
