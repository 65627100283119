import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  KTSVG,
  toAbsoluteUrl
} from "../../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../../_eaFruitsDms/layout/components/button/eaButton"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce"

type Props = {
  className: string
}

const FarmersSuccessStories = ({ className }: any) => {
  const navigate = useNavigate()

  const intl = useIntl()
  const [farmersStoryList, setFarmersStoryList] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(10)
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [permission, setPermission] = useState(defaultPermission)
  const debounceSearch = useDebounce(search, 500);

  const cmsAuthor: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Success Stories',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    setTimeout(() => fetchFarmersStory().then(), timer)
  }, [currentOffset, debounceSearch, count, farmersStoryList.length])

  useEffect(() => {
    localStorage.removeItem('storyId')
    if (localStorage.getItem('type')) {
      setMessageHandler(`success${localStorage.getItem('type')}`)

      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)

      localStorage.removeItem('type')
    }
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("cms")
    setPermission(res)
  }

  const fetchFarmersStory = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}successstories/get?usertype=Farmer&limit=${limit}&ordering=-updated_at&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    setFarmersStoryList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChangeSearch = (e: any) => {
    let inputValue = e.target ? e.target.value : e.value
    setSearch(inputValue)
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Farmers Success Stories - ' />
      <div>
        <PageTitle breadcrumbs={cmsAuthor}>{intl.formatMessage({ id: 'Farmers' })}</PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('CMS_FARMER_STORY.ADD')
              : messageHandler === 'successEdit'
                ? FormatMessage('CMS_FARMER_STORY.UPDATE')
                : messageHandler === 'successDelete' && FormatMessage('CMS_FARMER_STORY.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} />
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && <EaButton
                  btnName='Add Farmers Sucess Story'
                  onClick={() => {
                    navigate('add-stories')
                    localStorage.setItem('StoryType', 'Farmer')
                  }}
                />}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {farmersStoryList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-180px py-2'>Name</th>
                          <th className='min-w-120px py-2'>Story Title</th>
                          <th className='min-w-120px py-2'>Sub Title</th>
                          <th className='min-w-120px py-2'>Status</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {farmersStoryList.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-28px me-2'>
                                    <span className='symbol-label'>
                                      {item.profile_thumbnail === null ? (
                                        <img
                                          alt='group image'
                                          className='h-35px rounded-1'
                                          src={defaultSrc}
                                        />
                                      ) : (
                                        <img
                                          src={item.profile_thumbnail}
                                          className='h-40 rounded-1'
                                          alt=''
                                          style={{
                                            height: '100%',
                                            width: '100%',
                                          }}
                                        />
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span>
                                      {item.firstname} {item.lastname}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>{item.story_title}</td>
                              <td>{item.subtitle}</td>
                              <td>
                                {item.is_active == true ? (
                                  <>
                                    <span className='badge badge-light-success my-2 text-dark'>
                                      Active
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    <span className='badge badge-light-danger my-2 text-dark'>
                                      Inactive
                                    </span>
                                  </>
                                )}
                              </td>
                              <td className='text-end'>
                                {permission.write && <a
                                  href='javascript:void(0)'
                                  className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                  onClick={(event) => {
                                    navigate('add-stories')
                                    localStorage.setItem('StoryType', 'Farmer')
                                    localStorage.setItem('storyId', item.id)
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='ic mr-0'
                                  />
                                </a>}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${farmersStoryList.length == 0 ? 0 : selected * 10 + 1} to ${farmersStoryList.length == 0
                ? 0
                : farmersStoryList.length < 10
                  ? count
                  : (selected + 1) * 10
                } of ${farmersStoryList.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                count={farmersStoryList.length == 0 ? 0 : count}
                selected={selected}
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FarmersSuccessStories
