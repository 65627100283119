import {
	FORGOT_PASSWORD_EAFRUITS_FAIL,
	FORGOT_PASSWORD_EAFRUITS_IS_LOADING,
	FORGOT_PASSWORD_EAFRUITS_SUCCESS
} from "../../types/types"

type state = {
    forgotResponse?: string,
    isLoading?: boolean | string
    errorMessage?: string
}

const initialState: state = {
    forgotResponse: '',
    isLoading: false,
    errorMessage: '',
}

export const ForgotReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FORGOT_PASSWORD_EAFRUITS_SUCCESS:
            return {
                forgotResponse: action.payload,
                isLoading: false,
            }
        case FORGOT_PASSWORD_EAFRUITS_IS_LOADING:
            return {
                isLoading: true,
            }

        case FORGOT_PASSWORD_EAFRUITS_FAIL:
            return {
                isLoading: false,
                errorMessage: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
