import React from 'react'
import { useIntl } from 'react-intl'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import Select from 'react-select'
import { Helmet } from 'react-helmet'
import { EaStatisticsWidget } from '../../../../_eaFruitsDms/partials/widgets'

const sroptions = [
    { value: 'allsalesresp', label: 'All Sales Reps' },
    { value: 'sr1', label: 'Dusya Sigachyova' },
    { value: 'sr2', label: 'Thomas Kukabango' },
    { value: 'sr3', label: 'Neeshaan El Pasha' },
]


const warehouseoptions = [
    { value: 'all', label: 'All Warehouses' },
    { value: 'Warehouse1', label: 'Warehouse 1' },
    { value: 'Warehouse2', label: 'Warehouse 2' },
    { value: 'Warehouse3', label: 'Warehouse 3' },
]

type Props = {
    className: string
}
const DiscountUsageReports: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Discount Usage Report"
    }
    return (
        <>
            <Helmet>
                <title>Discount Usage Report - EA Fruits DMS</title>
            </Helmet>
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Discount Usage Report' })}</PageTitle>
            {/* begin::Row statistics*/}
            <div className='row g-3 g-xl-5'>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='success'
                        title='38 Invoices'
                        path='/'
                        description='Total Discount Given Invoice Count'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='info'
                        title='TZS 1,382,290.00'
                        description='Total Sales Amount'
                        path='/'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='danger'
                        title='TZS 234,000.00'
                        description='Total Discount Given Amount'
                        path='/'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='warning'
                        title='3%'
                        description='Average Discount In Percentage'
                        path='/'
                    />
                </div>

            </div>
            {/* end::Row statistics*/}

            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: '01/01/2020',
                                        endDate: '01/12/2020',
                                        alwaysShowCalendars: true,
                                        cancelClass: 'btn-secondary',
                                        drops: "auto",
                                        locale: {
                                            format: 'DD/MM/YYYY'
                                        },
                                        ranges: {
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                        },
                                    }}
                            >
                                <input type="text" className="form-control calendar my-1 me-3 w-auto min-w-215px" />
                            </DateRangePicker>
                            <Select
                                options={sroptions}
                                isSearchable
                                placeholder="All Sales Reps"
                                className='react-select-container my-1 me-3 w-140px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <Select
                                options={sroptions}
                                isSearchable
                                placeholder="All Discount Types"
                                className='react-select-container my-1 w-170px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-100px'>Date</th>
                                    <th className='min-w-120px'>Discount Amt.</th>
                                    <th className='min-w-130px'>Invoice Amount</th>
                                    <th className='min-w-120px'>in Percentage</th>
                                    <th className='min-w-120px'>Type</th>
                                    <th className='min-w-140px'>Customer Name</th>
                                    <th className='min-w-150px'>SR Name</th>
                                    <th className='min-w-140px'>Approver</th>
                                    <th className='min-w-120px'>Invoice No.</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>
                                    <td>28 Oct 2022</td>
                                    <td>TZS 300</td>
                                    <td>TZS 30000</td>
                                    <td>1%</td>
                                    <td>Promotional</td>
                                    <td>Angel Schwartz</td>
                                    <td>Phoebe Hammond</td>
                                    <td>Blanche Marshall</td>
                                    <td>#28176</td>
                                </tr>
                                <tr>
                                    <td>28 Oct 2022</td>
                                    <td>TZS 300</td>
                                    <td>TZS 30000</td>
                                    <td>1%</td>
                                    <td>Promotional</td>
                                    <td>Angel Schwartz</td>
                                    <td>Phoebe Hammond</td>
                                    <td>Blanche Marshall</td>
                                    <td>#28176</td>
                                </tr>
                                <tr>
                                    <td>28 Oct 2022</td>
                                    <td>TZS 300</td>
                                    <td>TZS 30000</td>
                                    <td>1%</td>
                                    <td>Promotional</td>
                                    <td>Angel Schwartz</td>
                                    <td>Phoebe Hammond</td>
                                    <td>Blanche Marshall</td>
                                    <td>#28176</td>
                                </tr>
                                <tr>
                                    <td>28 Oct 2022</td>
                                    <td>TZS 300</td>
                                    <td>TZS 30000</td>
                                    <td>1%</td>
                                    <td>Promotional</td>
                                    <td>Angel Schwartz</td>
                                    <td>Phoebe Hammond</td>
                                    <td>Blanche Marshall</td>
                                    <td>#28176</td>
                                </tr>
                                <tr>
                                    <td>28 Oct 2022</td>
                                    <td>TZS 300</td>
                                    <td>TZS 30000</td>
                                    <td>1%</td>
                                    <td>Promotional</td>
                                    <td>Angel Schwartz</td>
                                    <td>Phoebe Hammond</td>
                                    <td>Blanche Marshall</td>
                                    <td>#28176</td>
                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
            {/* <Addwarehouse /> */}
        </>
    )
}

export default DiscountUsageReports