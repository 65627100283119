import React from "react"
import { useIntl } from "react-intl"
import { Navigate, Route, Routes } from "react-router-dom"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import Customers from "./components/customers"
import Overview from "./components/overview"
import SalesReps from "./components/salesReps"
import { SalesSupervisorHeader } from "./components/salesSupervisorHeader"

type Props = {
    className?: string
}
const SalesSupervisor: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const teamMembersTitle = {
        title: "Team members",
        subTitle: "Allen Watts"
    }
    return (
        <>

            <PageTitle breadcrumbs={[teamMembersTitle]} >{intl.formatMessage({ id: 'Allen Watts' })}</PageTitle>
            <div className={`card ${className}`}>
            </div>
            <SalesSupervisorHeader />
            <Routes>
                <Route path='overview' element={<Overview />} />
                <Route path='customers' element={<Customers />} />
                <Route path='sales-reps' element={<SalesReps />} />
                {/* <Route path='past-orders' element={<PastOrders />} />
                <Route path='targets' element={<Targets />} />
                <Route path='payment-issue' element={<PaymentIssue />} /> */}
                <Route index element={<Navigate to="/team-members/sales-supervisor/overview" />} />
            </Routes>
        </>
    )
}

export default SalesSupervisor
