export const filters: any = {
  wm_team_members: 'wm-team-members',
  wm_all_customers: 'wm-all-customers',
  wm_manage_price: 'wm-manage-price',
  wm_discount_requesting: 'wm-discount-requesting',
  wm_promotional_discount: 'wm-promotional-discount',
  wm_discount_capping: 'wm-discount-capping',
  wm_low_stock: 'wm-low-stock',
  wm_bank: 'wm-bank',
  wm_wallet: 'wm-wallet',
  wm_customer_report: 'wm-customer-report',
  ts_all_customers: 'ts-all-customers',
  ts_promotional_discount: 'ts-promotional-discount',
  ts_survey: 'ts-survey',
  ts_survey_feedback: 'ts-survey-feedback',
  ts_current_stock: 'ts-current-stock',
  ts_verification_customer: 'ts-verification-customer',
  ts_suspended_customers: 'ts-suspended-customers',
  ts_orders: 'ts-orders',
  am_payment_issues: 'am-payment-issues',
  am_sales_invoice: 'am-sales-invoice',
  ts_support_request_newmessages: 'ts-support-request-newmessages',
  ts_support_request_my_assigned: 'ts-support-request-my-assigned',
  ts_support_request_my_closed: 'ts-support-request-my-closed',
  reason_master_support_request: 'reason-master-support-request',
  wm_credit_issue_report: 'wm-credit-issue-report',
  sa_telesales_salesorderfeedback: 'sa-telesales-salesorderfeedback',
  sa_telesales_supportrequest_message: 'sa-telesales-supportrequest-message',
  sa_customer_on_boarding_rejection_report: 'sa-customer-on-boarding-rejection-report',
  sa_pay_later_today_report: 'sa-pay-later-today-report',
}
