import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  fetchAsyncDropdown,
  fetchStaticAsyncDropdown,
  isEmpty,
} from '../../../../../_eaFruitsDms/helpers'
import {patchRequest, postRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import EaButton from '../../../../../_eaFruitsDms/layout/components/button/eaButton'
import EaModal from '../../../../../_eaFruitsDms/layout/components/modal/eaModal'
import {cId} from '../../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {
  intialSupportRequest,
  intialSupportRequestValidation,
  rejection,
  rejectionValidation,
  supportRequestType,
} from '../CustomerConst'
import CustomerHeader from '../../components/customerHeader'

let shoeEditBtn = true

const CustomerNameHeader = ({customer}: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const userType = localStorage.getItem('user_type') || ''
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [rejectReasonOffSet, setRejectReasonOffSet] = useState<any>('')
  const [rejectReasonOption, setRejectReasonOption] = useState<any>([])
  const [customerRejection, setCustomerRejection] = useState(rejection)
  const [customerRejectionValidation, setCustomerRejectionValidation] =
    useState<any>(rejectionValidation)
  const [grantAccessReasonOffSet, setGrantAccessReasonOffSet] = useState<any>('')
  const [grantAccessReasonOption, setGrantAccessReasonOption] = useState<any>([])
  const [customerGrantAccess, setCustomerGrantAccess] = useState(rejection)
  const [customerGrantAccessValidation, setCustomerGrantAccessValidation] =
    useState<any>(rejectionValidation)
  const [permission, setPermission] = useState(defaultPermission)
  const [permissionGrantAccess, setPermissionGrantAccess] = useState(defaultPermission)
  const customerType = localStorage.getItem('customerPermission')
  const [supportRequest, setSupportRequest] = useState(intialSupportRequest)
  const [supportRequestValidation, setSupportRequestValidation] = useState<any>(
    intialSupportRequestValidation
  )
  const [disableBtn, setDisableBtn] = useState(false)
  const [isLoading, setIsLoading] = useState('Submit')

  useEffect(() => {
    // allCustomer
    // suspendedCustomer

    checkPermissions('customer').then((res: any) => {
      setPermission(res)
    })
    checkPermissions('suspended_customer').then((res: any) => {
      setPermissionGrantAccess(res)
    })
  }, [])

  useEffect(() => {
    setRejectReasonOffSet('')
  }, [searchSelect])

  const fetchSuspensionReason = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const response = await fetchAsyncDropdown(
      rejectReasonOffSet,
      setRejectReasonOffSet,
      rejectReasonOption,
      setRejectReasonOption,
      `master/options-master/?type=Customer_Suspension_Reasons`,
      search,
      setSearchSelect,
      'option'
    )
    response.options.push({
      label: 'Other',
      value: '',
    })
    return response
  }

  const handleChangeReason = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setCustomerRejectionValidation({...customerRejectionValidation, [inputName]: ''})

    switch (inputName) {
      case 'option':
        setCustomerRejection({...customerRejection, ['option']: inputValue})
        break
      case 'comments':
        setCustomerRejection({...customerRejection, ['comments']: inputValue})
        break
    }
  }

  const fetchGrantAccessReason = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const response = await fetchAsyncDropdown(
      grantAccessReasonOffSet,
      setGrantAccessReasonOffSet,
      grantAccessReasonOption,
      setGrantAccessReasonOption,
      `master/options-master/?type=CustomerGrantAccess`,
      search,
      setSearchSelect,
      'option'
    )
    response.options.push({
      label: 'Other',
      value: '',
    })
    return response
  }

  const handleChangeGrandAccessReason = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setCustomerGrantAccessValidation({...customerGrantAccessValidation, [inputName]: ''})

    switch (inputName) {
      case 'option':
        setCustomerGrantAccess({...customerGrantAccess, ['option']: inputValue})
        break
      case 'comments':
        setCustomerGrantAccess({...customerGrantAccess, ['comments']: inputValue})
        break
    }
  }

  const grantCustomerAccess = async () => {
    const customerId = localStorage.getItem('cId')
    await patchRequest(
      `${apiBaseURL()}customers/${customerId}/suspend-or-grant`,
      {
        status: 'approved',
        reason_option: customerGrantAccess.option,
        reason_note: customerGrantAccess.comments,
      },
      true
    )

    $('#closeGrantAccessReasonModal').trigger('click')
    setTimeout(() => {
      clearFunction()
    }, 200)
    navigate('/customers/suspended-customers')
  }

  const clearFunction = () => {
    setCustomerRejection(rejection)
    setCustomerRejectionValidation(rejectionValidation)
    setRejectReasonOffSet('')
    setRejectReasonOption([])
    setCustomerGrantAccess(rejection)
    setCustomerGrantAccessValidation(rejectionValidation)
    setGrantAccessReasonOffSet('')
    setGrantAccessReasonOption([])
  }

  const revokeCustomerAccess = async () => {
    const customerId = localStorage.getItem('cId')
    await patchRequest(
      `${apiBaseURL()}customers/${customerId}/suspend-or-grant`,
      {
        status: 'suspended',
        reason_option: customerRejection.option,
        reason_note: customerRejection.comments,
      },
      true
    )

    $('#closeRejectionReasonModal').trigger('click')
    setTimeout(() => {
      clearFunction()
    }, 200)
    localStorage.removeItem('cId')
    navigate('/customers')
  }

  // Create Complaint
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [requestType, setRequestType] = useState<any>({label: 'Select', value: ''})

  const fetchRequestType = (search?: string) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      'master/options-master/?type=complaint_request_type&ordering=-updated_date',
      search,
      setDropDownSearch,
      'option',
      false
    )
  }

  const handleChangeSupportRequest = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setSupportRequestValidation({...supportRequestValidation, [inputName]: ''})

    switch (inputName) {
      case 'requestType':
        setRequestType(e)
        setSupportRequest({...supportRequest, ['requestType']: inputValue})
        break
      case 'title':
        setSupportRequest({...supportRequest, ['title']: inputValue})
        break
      case 'message':
        setSupportRequest({...supportRequest, ['message']: inputValue})
        break
    }
  }

  const validateSupportRequest = () => {
    let validations = {...supportRequestValidation}
    let isValid = true

    if (supportRequest.requestType === '') {
      validations.requestType = 'is-invalid'
      isValid = false
    }
    if (supportRequest.title === '') {
      validations.title = 'is-invalid'
      isValid = false
    }
    if (supportRequest.message === '') {
      validations.message = 'is-invalid'
      isValid = false
    }

    setSupportRequestValidation(validations)
    return isValid
  }

  const createCustomerRequest = async () => {
    const customerId = localStorage.getItem('cId')
    const isValid = validateSupportRequest()

    if (isValid) {
      setDisableBtn(true)
      setIsLoading('Please Wait...')

      const res = await postRequest(
        `${apiBaseURL()}support_request/create`,
        {
          customer: customerId,
          request_type: supportRequest.requestType,
          title: supportRequest.title,
          message: supportRequest.message,
        },
        true
      )
      if (res.status == 201) {
        $('#closeSupportRequestModal').trigger('click')
        setDisableBtn(false)
        setIsLoading('Submit')
        setTimeout(() => {
          clearSupportRequest()
          navigate('/complaint/new-message')
        }, 200)
      }
    }
  }

  const clearSupportRequest = () => {
    setSupportRequest(intialSupportRequest)
    setSupportRequestValidation(intialSupportRequestValidation)
    setDisableBtn(false)
    setIsLoading('Submit')
    setOffset(0)
    setList([])
    setDropDownSearch('')
    setRequestType({label: 'Select', value: ''})
  }

  return (
    <>
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <CustomerHeader customer={customer} />
                <div className='ms-auto'>
                  {userType.toLowerCase() !== 'sa' &&
                  customer &&
                  customer.status !== 'suspended' ? (
                    <>
                      {((permission.write && userType.toLowerCase() === 'wm') ||
                        userType.toLowerCase() === 'ts') && (
                        <button
                          className='btn btn-light me-2'
                          onClick={() => {
                            navigate('../../customers/manage-customer')
                          }}
                        >
                          Edit
                        </button>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {['wm', 'ts'].includes(userType.toLowerCase()) ? (
                    customer && customer.status === 'suspended' ? (
                      <>
                        {((permissionGrantAccess.write && userType.toLowerCase() === 'wm') ||
                          userType.toLowerCase() === 'ts') && (
                          <EaButton
                            isModal={true}
                            dataTarget='#ea_modal_grant_access'
                            btnName='Grant Access'
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {((permission.write && userType.toLowerCase() === 'wm') ||
                          userType.toLowerCase() === 'ts') && (
                          <EaButton
                            isModal={true}
                            dataTarget='#ea_modal_revoke_customer_access'
                            btnName='Revoke Access'
                          ></EaButton>
                        )}
                      </>
                    )
                  ) : (
                    <></>
                  )}
                  {/* {userType.toLowerCase() == 'ts' &&
                    location.pathname == '/customers/singlecustomer/complaints-request' && (
                      <EaButton
                        isModal={true}
                        dataTarget='#ea_modal_create_request'
                        className='btn-primary ms-2'
                        btnName='Create Complaint'
                      />
                    )} */}
                </div>
              </div>
            </div>
          </div>
          {customerType === 'allCustomer' ? (
            <>
              <div className='d-flex overflow-auto h-40px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/overview' && 'active')
                      }
                      to='/customers/singlecustomer/overview'
                    >
                      Overview
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/business-details' &&
                          'active')
                      }
                      to='/customers/singlecustomer/business-details'
                    >
                      Business Details
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/business-address' &&
                          'active')
                      }
                      to='/customers/singlecustomer/business-address'
                    >
                      Business Address
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/sales-order' && 'active')
                      }
                      to='/customers/singlecustomer/sales-order'
                    >
                      Sales Order
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/credit-issue' && 'active')
                      }
                      to='/customers/singlecustomer/credit-issue'
                    >
                      Credit Issue
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/preferences' && 'active')
                      }
                      to='/customers/singlecustomer/preferences'
                    >
                      Preferences
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/soko-point' && 'active')
                      }
                      to='/customers/singlecustomer/soko-point'
                    >
                      SokoPoints
                    </Link>
                  </li>
                  {customer &&
                    !isEmpty(customer.category_name) &&
                    customer.category_name === 'Horeca' && (
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-0 ` +
                            (location.pathname === '/customers/singlecustomer/horeca-contracts' &&
                              'active')
                          }
                          to='/customers/singlecustomer/horeca-contracts'
                        >
                          HORECA Contracts
                        </Link>
                      </li>
                    )}
                  {/*<li className='nav-item'>*/}
                  {/*    <Link*/}
                  {/*        className={*/}
                  {/*            `nav-link text-active-primary me-0 ` +*/}
                  {/*            (location.pathname === '/customers/singlecustomer/return' && 'active')*/}
                  {/*        }*/}
                  {/*        to='/customers/singlecustomer/return'*/}
                  {/*    >*/}
                  {/*        Return*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                  {/*<li className='nav-item'>*/}
                  {/*    <Link*/}
                  {/*        className={*/}
                  {/*            `nav-link text-active-primary me-0 ` +*/}
                  {/*            (location.pathname === '/customers/singlecustomer/credit-issue' && 'active')*/}
                  {/*        }*/}
                  {/*        to='/customers/singlecustomer/credit-issue'*/}
                  {/*    >*/}
                  {/*        Credit Issue*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/complaints-request' &&
                          'active')
                      }
                      to='/customers/singlecustomer/complaints-request'
                    >
                      Complaint
                    </Link>
                  </li>

                  {/*<li className='nav-item'>*/}
                  {/*    <Link*/}
                  {/*        className={*/}
                  {/*            `nav-link text-active-primary me-0 ` +*/}
                  {/*            (location.pathname === '/customers/singlecustomer/survey-feedback' && 'active')*/}
                  {/*        }*/}
                  {/*        to='/customers/singlecustomer/survey-feedback'*/}
                  {/*    >*/}
                  {/*        Survey Feedback*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/call-notes' && 'active')
                      }
                      to='/customers/singlecustomer/call-notes'
                    >
                      Call Notes
                    </Link>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <>
              <div className='d-flex overflow-auto h-40px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        ([
                          '/customers/suspended-customers/overview',
                          '/customers/singlecustomer/overview',
                        ].includes(location.pathname) && 'active')
                      }
                      to='/customers/suspended-customers/overview'
                    >
                      Overview
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/suspended-customers/business-details' &&
                          'active')
                      }
                      to='/customers/suspended-customers/business-details'
                    >
                      Business Details
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/suspended-customers/business-address' &&
                          'active')
                      }
                      to='/customers/suspended-customers/business-address'
                    >
                      Business Address
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/sales-order' && 'active')
                      }
                      to='/customers/singlecustomer/sales-order'
                    >
                      Sales Order
                    </Link>
                  </li>
                  {/*<li className='nav-item'>*/}
                  {/*    <Link*/}
                  {/*        className={*/}
                  {/*            `nav-link text-active-primary me-0 ` +*/}
                  {/*            (location.pathname === '/customers/singlecustomer/return' && 'active')*/}
                  {/*        }*/}
                  {/*        to='/customers/singlecustomer/return'*/}
                  {/*    >*/}
                  {/*        Return*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/credit-issue' && 'active')
                      }
                      to='/customers/singlecustomer/credit-issue'
                    >
                      Credit Issue
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname ===
                          '/customers/suspended-customers/complaints-request' && 'active')
                      }
                      to='/customers/suspended-customers/complaints-request'
                    >
                      Complaint
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/preferences' && 'active')
                      }
                      to='/customers/singlecustomer/preferences'
                    >
                      Preferences
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === '/customers/singlecustomer/soko-point' && 'active')
                      }
                      to='/customers/singlecustomer/soko-point'
                    >
                      SokoPoints
                    </Link>
                  </li>
                  {customer &&
                    !isEmpty(customer.category_name) &&
                    customer.category_name === 'Horeca' && (
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-0 ` +
                            (location.pathname === '/customers/singlecustomer/horeca-contracts' &&
                              'active')
                          }
                          to='/customers/singlecustomer/horeca-contracts'
                        >
                          HORECA Contracts
                        </Link>
                      </li>
                    )}
                  {/*<li className='nav-item'>*/}
                  {/*    <Link*/}
                  {/*        className={*/}
                  {/*            `nav-link text-active-primary me-0 ` +*/}
                  {/*            (location.pathname === '/customers/singlecustomer/survey-feedback' && 'active')*/}
                  {/*        }*/}
                  {/*        to='/customers/singlecustomer/survey-feedback'*/}
                  {/*    >*/}
                  {/*        Survey Feedback*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                  {/*<li className='nav-item'>*/}
                  {/*    <Link*/}
                  {/*        className={*/}
                  {/*            `nav-link text-active-primary me-0 ` +*/}
                  {/*            (location.pathname === '/customers/singlecustomer/call-notes' && 'active')*/}
                  {/*        }*/}
                  {/*        to='/customers/singlecustomer/call-notes'*/}
                  {/*    >*/}
                  {/*        Call Notes*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
      {/* delete Customer */}
      {/* <EaModal
                modalTitle='Delete Customer'
                children={
                    <p className='mb-0'>Are you sure you want to delete <span className='fw-bold'>Marc Marsh?</span></p>
                }
                id={'delete_wm_customer'}
                actionBtnName='Yes, Proceed'
            /> */}
      {/* revoke access */}
      <EaModal
        modalTitle='Revoke Customer Access'
        onSubmit={revokeCustomerAccess}
        cancel={clearFunction}
        closeAuto='closeRejectionReasonModal'
        children={
          <>
            <p>Are you sure, you want to revoke this customer access?</p>
            <div className='mb-4'>
              <label className='form-label'>Customer Suspension Reason</label>
              <AsyncPaginate
                loadOptions={fetchSuspensionReason}
                isSearchable
                placeholder='Select'
                className={clsx('react-select-container w-100', '')}
                classNamePrefix='react-select'
                onChange={(e: any) => handleChangeReason(e, 'option')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            {customerRejection.option == '' && (
              <>
                <label className='form-label'>Comments</label>
                <textarea
                  className='form-control'
                  rows={4}
                  placeholder='Type here…'
                  value={customerRejection.comments}
                  name='comments'
                  onChange={(e: any) => handleChangeReason(e, 'comments')}
                ></textarea>
              </>
            )}
          </>
        }
        id={'ea_modal_revoke_customer_access'}
        actionBtnName='Yes, Proceed'
      />

      {/* grant access */}
      <EaModal
        modalTitle='Grant Customer Access'
        onSubmit={grantCustomerAccess}
        cancel={clearFunction}
        closeAuto='closeGrantAccessReasonModal'
        children={
          <>
            <p>Are you sure, you want to grant this customer access?</p>
            <div className='mb-4'>
              <label className='form-label'>Customer Grant Access Reason</label>
              <AsyncPaginate
                loadOptions={fetchGrantAccessReason}
                isSearchable
                placeholder='Select'
                className={clsx('react-select-container w-100', '')}
                classNamePrefix='react-select'
                onChange={(e: any) => handleChangeGrandAccessReason(e, 'option')}
                value={
                  customerGrantAccess.option != null &&
                  grantAccessReasonOption.find(
                    (option: any) => option.value === customerGrantAccess
                  )
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            <div>
              {customerGrantAccess.option == '' && (
                <>
                  <label className='form-label'>Comments</label>
                  <textarea
                    className='form-control'
                    rows={4}
                    placeholder='Type here…'
                    value={customerGrantAccess.comments}
                    name='comments'
                    onChange={(e: any) => handleChangeGrandAccessReason(e, 'comments')}
                  ></textarea>
                </>
              )}
            </div>
          </>
        }
        id={'ea_modal_grant_access'}
        actionBtnName='Yes, Proceed'
      />
      {/* Create Complaint from TS on behalf of the Customer*/}
      <EaModal
        id='ea_modal_create_request'
        modalTitle='Create Complaint'
        onSubmit={createCustomerRequest}
        cancel={clearSupportRequest}
        closeAuto='closeSupportRequestModal'
        btnDisabled={disableBtn}
        actionBtnName={isLoading}
      >
        <div>
          <div className='mb-4'>
            <label className='form-label'>Complaint Category</label>
            <AsyncPaginate
              loadOptions={fetchRequestType}
              isSearchable
              placeholder='Select'
              className={clsx('react-select-container w-100', supportRequestValidation.requestType)}
              classNamePrefix='react-select'
              onChange={(e: any) => handleChangeSupportRequest(e, 'requestType')}
              value={requestType}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
          <div className='mb-4'>
            <label className='form-label'>Title</label>
            <input
              className={clsx('form-control', supportRequestValidation.title)}
              placeholder='Type here…'
              value={supportRequest.title}
              name='title'
              onChange={(e: any) => handleChangeSupportRequest(e, 'title')}
            ></input>
          </div>
          <label className='form-label'>Message</label>
          <textarea
            className={clsx('form-control', supportRequestValidation.message)}
            placeholder='Type here…'
            rows={6}
            value={supportRequest.message}
            name='message'
            onChange={(e: any) => handleChangeSupportRequest(e, 'message')}
          ></textarea>
        </div>
      </EaModal>
    </>
  )
}

export default CustomerNameHeader
