import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {VariantsSingle} from '../../../_eaFruitsDms/apiFunctions/master/master'
import {
  AddSkus,
  EditSkus,
  productSkuDelete,
  SkuList,
  SkuListSeacrch,
  SkuSingle,
} from '../../../_eaFruitsDms/apiFunctions/productSkus/productSkus'
import {
  apiBaseURL,
  categoryOptions,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
  toAbsoluteUrl,
} from '../../../_eaFruitsDms/helpers'
import capitalizeFirstLetter from '../../../_eaFruitsDms/helpers/components/CapitalLetter'
import {categoryStyle} from '../../../_eaFruitsDms/helpers/components/categoryStyle'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import ValidationHandler from '../../../_eaFruitsDms/helpers/components/validationHandler'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import EaDropDown from '../../../_eaFruitsDms/layout/components/dropdown/dropdown'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import Pagination from '../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getToken} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import Imagecropper from '../brands/components/imageCroper'
import TableProductSku from './components/tableProductSku'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'

type Props = {
  className: string
}

const ProductSku: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Product SKU ',
  }

  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [validSkuName, setValidSkuName] = useState(false)
  const [getBrand, setGetBrand] = useState<any>([])
  const [brands, setBrands] = useState<any>([])
  const [brandId, setBrandId] = useState<number>(0)
  const [brandOffSet, setBrandOffSet] = useState<any>('')
  const [categories, setCategories] = useState<any>([])
  const [categoryId, setCategoryId] = useState<number>(0)
  const [categoryOffSet, setCategoryOffSet] = useState<any>('')
  const [groupOffSet, setGroupOffSet] = useState<any>('')
  const [packageOffSet, setpackageOffSet] = useState<any>('')
  const [parentCateOffSet, setParentCateOffSet] = useState<any>('')
  const [cateOffSet, setCateOffSet] = useState<any>('')
  const [limit, setLimit] = useState<number>(10)
  const [getProduct, setGetProdcut] = useState<any>([])
  const [getProductVariant, setGetProdcutVariant] = useState<any>([])
  const [getProductVariantModal, setGetProdcutVariantModal] = useState<any>([])
  const [getProductMaterial, setGetProdcutMaterial] = useState<any>([])
  const [getProductCategory, setGetProdcutCategory] = useState<any>([])
  const [getProductGrops, setProductGrops] = useState<any>([])
  const [productGroupResponse, setProductGroupResponse] = useState<any>([])
  const [selectedProductGroup, setSelectedProductGroup] = useState<string>('')
  const [getProductGropsList, setProductGropsList] = useState<any>([])
  const [getGetSize, setGetSize] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  // const [getMeasurement, setMeasurement] = useState<any>(["Kilogram", "Crate", "Gram", "Punnet"]);
  const [getSingleProductCategoryEdit, setSingleProdcutCategoryEdit] = useState<any>('')
  const [validSkuNameEdit, setValidSkuNameEdit] = useState(false)
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [count, setCount] = useState('')
  const [getProductGropModal, setgetProductGroupModal] = useState<any>([])
  const [getProductFromVarEdit, setgetProductFromVarEdit] = useState<any>([])
  const [skuName, setSkuName] = useState('')
  const [selcetedBrand, setSelcetedBrand] = useState('')
  const [productCategory, setProductCategory] = useState('')
  const [measurementUnit, setMeasurementunit] = useState('')
  const [selcetedcategory, setCategory] = useState('')
  const [selcetedproductGroup, setProductGroup] = useState('')
  const [selcetedproductvariant, setProductvariant] = useState('')
  const [selcetedadditionalNotes, setAdditionalNotes] = useState('')
  const [selcetedpackagingMaterial, setPackagingMaterial] = useState('')
  const [selcetedvat, setVat] = useState('')
  const [selcetedfixSize, setFixSize] = useState(false)
  const [selcetedmeasurementUnit, setMeasurementUnit] = useState('')
  const [selcetedSku, setSku] = useState('')
  const [selcetedstandardShelf, setstandardShelf] = useState('')
  const [selcetednearExpiry, setNearExpiry] = useState('')
  const [skuNameEdit, setSkuNameEdit] = useState('')
  const [selcetedBrandEdit, setSelcetedBrandEdit] = useState('')
  const [productCategoryEdit, setProductCategoryEdit] = useState('')
  const [selcetedcategoryEdit, setCategoryEdit] = useState('')
  const [selcetedproductGroupEdit, setProductGroupEdit] = useState('')
  const [selcetedproductvariantEdit, setProductvariantEdit] = useState('')
  const [selcetedadditionalNotesEdit, setAdditionalNotesEdit] = useState('')
  const [selcetedpackagingMaterialEdit, setPackagingMaterialEdit] = useState('')
  const [selcetedvatEdit, setVatEdit] = useState('')
  const [selcetedfixSizeEdit, setFixSizeEdit] = useState(false)
  const [selcetedmeasurementUnitEdit, setMeasurementUnitEdit] = useState('')
  const [selcetedSkuEdit, setSkuEdit] = useState('')
  const [selcetedstandardShelfEdit, setstandardShelfEdit] = useState('')
  const [selcetednearExpiryEdit, setNearExpiryEdit] = useState('')
  const [validationSkuName, validationSetSkuName] = useState('')
  const [validationSelcetedBrand, validationSetSelcetedBrand] = useState('')
  const [validationProductCategory, validationSetProductCategory] = useState('')
  const [validationSelcetedcategory, validationSetCategory] = useState('')
  const [validationSelcetedproductGroup, validationSetProductGroup] = useState('')
  const [validationSelcetedproductvariant, validationSetProductvariant] = useState('')
  const [validationSelcetedadditionalNotes, validationSetAdditionalNotes] = useState('')
  const [validationSelcetedpackagingMaterial, validationSetPackagingMaterial] = useState('')
  const [validationSelcetedvat, validationSetVat] = useState('')
  const [validationSelcetedfixSize, validationSetFixSize] = useState('')
  const [validationSelcetedmeasurementUnit, validationSetMeasurementUnit] = useState('')
  const [validationSelcetedSku, validationSetSku] = useState('')
  const [validationSelcetedstandardShelf, validationSetstandardShelf] = useState('')
  const [validationSelcetednearExpiry, validationSetNearExpiry] = useState('')
  const [imageValidationSize, setImageValidationSize] = useState<boolean>(false)
  const [validationImage, setValidationImage] = useState('')
  const [validationSkuNameEdit, validationSetSkuNameEdit] = useState('')
  const [validationSelcetedBrand_Edit, validationSetSelcetedBrandEdit] = useState('')
  const [validationProductCategory_Edit, validationSetProductCategoryEdit] = useState('')
  const [validationSelcetedcategoryEdit, validationSetCategoryEdit] = useState('')
  const [validationSelcetedproductGroupEdit, validationSetProductGroupEdit] = useState('')
  const [validationSelcetedproductvariantEdit, validationSetProductvariantEdit] = useState('')
  const [validationSelcetedadditionalNotesEdit, validationSetAdditionalNotesEdit] = useState('')
  const [validationSelcetedpackagingMaterialEdit, validationSetPackagingMaterialEdit] = useState('')
  const [validationSelcetedvatEdit, validationSetVatEdit] = useState('')
  const [validationSelcetedfixSizeEdit, validationSetFixSizeEdit] = useState('')
  const [validationSelcetedmeasurementUnitEdit, validationSetMeasurementUnitEdit] = useState('')
  const [validationSelcetedSkuEdit, validationSetSkuEdit] = useState('')
  const [validationSelcetedstandardShelfEdit, validationSetstandardShelfEdit] = useState('')
  const [validationSelcetednearExpiryEdit, validationSetNearExpiryEdit] = useState('')
  const [imageValidationEdit, setImageValidationEdit] = useState<boolean>(true)
  const [imageValidationSizeEdit, setImageValidationSizeEdit] = useState<boolean>(true)
  const [skuUniq, setSkuUniq] = useState<boolean>(true)
  const [skuUniqEdit, setSkuUniqEdit] = useState<boolean>(true)
  const [validationImageEdit, setValidationImageEdit] = useState('')
  const [imageSize, setImageSize] = useState('')
  const [img, setImg] = useState<any>('')
  const [imageSizeEdit, setImageSizeEdit] = useState('')
  const [imgEdit, setImgEdit] = useState<any>('')
  const [prodcutImage, setProductImage] = useState('')
  const [prodcutImageEdit, setProductImageEdit] = useState('') // for send img to api
  const [getList, setGetList] = useState<any>([]) // getdata
  const [filterBrand, setFilterBrand] = useState({label: '', value: ''})
  const [filterCategory, setFilterCategory] = useState({label: '', value: ''})
  const [filterGroups, setFilterGroups] = useState({label: '', value: ''})
  const [filterSize, setFilterSize] = useState('')
  const [filterPackaging, setFilterPackaging] = useState({value: '', label: ''})
  const [filterSearch, setFilterSearch] = useState('')
  const [filterGroup, setFilterGroup] = useState('')
  const [id, setId] = useState('')
  const [prodcutNameDelete, setProductNameDelete] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [getParentCategory, setParentCategory] = useState<any>([])
  const [categoryList, setCategoryList] = useState<any>([])
  const [getChildCategory, setChildCategory] = useState<any>([])
  const [getProductVariantModalAdd, setProductVariantModalAdd] = useState<any>([])
  const [getProductVariantModalEdit, setProductVariantModalEdit] = useState<any>([])
  const [getParentCategoryEdit, setParentCategoryEdit] = useState<any>([])
  const [getProductGropModalEdit, setProductGropModalEdit] = useState<any>([])
  const [getParentCategoryEditModal, setParentCategoryEditModal] = useState<any>([])
  const [parentCategoryId, setParentCategoryId] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(false)
  const [fixSize, setSkuFixSize] = useState<any>(false)

  const [getProductMaterialList, setProductMaterialList] = useState<any>([])

  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [cropData, setCropData] = useState(defaultSrc)
  const [cropDataEdit, setCropDataEdit] = useState(defaultSrc)

  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [messageTitle, setMessageTitle] = useState('')
  const [modalLoader, setModalLoader] = useState<any>(false)

  const initialOrderColumn = {
    ProductSKU: true,
    Brand: true,
    Category: true,
    ProductGroup: true,
    Variant: true,
    Packaging: true,
  }
  const [orderingColumn, setOrderingColumn] = useState(initialOrderColumn)
  const [isOrdering, setIsOrdering] = useState(false)
  const [ordering, setOrdering] = useState('')
  const [orderColumnKey, setOrderColumnKey] = useState('')
  const [permission, setPermission] = useState(defaultPermission)

  const apiLimit = '&limit=1000'

  // Authorization token
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  useEffect(() => {
    fetchPermission()
    setListLoader(true)
    SkuList(
      '10',
      '0',
      search,
      ordering,
      filterBrand.value,
      filterCategory.value,
      filterSize,
      filterPackaging.value,
      filterGroups.value
    ).then((res) => {
      setGetList(res.data)
      setGetSize(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
    getDropDownValues()
  }, [])

  useEffect(() => {
    setBrandOffSet('')
    setCategoryOffSet('')
    setGroupOffSet('')
    setpackageOffSet('')
    setParentCateOffSet('')
    setCateOffSet('')
  }, [searchSelect])

  const fetchPermission = async () => {
    let res = await checkPermissions('product_SKUs')
    setPermission(res)
  }

  const clearFunction = () => {
    // clear add modal state
    setBrandOffSet('')
    setMeasurementunit('')
    setCropData(defaultSrc)
    setSkuName('')
    setValidSkuName(false)
    setSelcetedBrand('')
    setProductCategory('')
    setCategory('')
    setProductGroup('')
    setProductvariant('')
    setAdditionalNotes('')
    setProductVariantModalAdd([])
    setPackagingMaterial('')
    setVat('0.00')
    setFixSize(false)
    setMeasurementUnit('')
    setSku('')
    setstandardShelf('')
    setNearExpiry('')
    setImg('')
    setProductImage('')
    validationSetSkuName('')
    validationSetSelcetedBrand('')
    validationSetProductCategory('')
    validationSetProductGroup('')
    validationSetProductvariant('')
    validationSetPackagingMaterial('')
    validationSetVat('')
    validationSetFixSize('')
    validationSetMeasurementUnit('')
    validationSetSku('')
    validationSetstandardShelf('')
    validationSetNearExpiry('')
    setValidationImage('')
    setImageSize('')
  }

  const clearFunctionEdit = () => {
    // clear edit modal state
    setMeasurementunit('')
    setValidSkuNameEdit(false)
    setSkuNameEdit('')
    setSelcetedBrandEdit('')
    setProductCategoryEdit('')
    setCategoryEdit('')
    setProductGroupEdit('')
    setProductvariantEdit('')
    setAdditionalNotesEdit('')
    setPackagingMaterialEdit('')
    setProductVariantModalEdit([])
    setVatEdit('0')
    setFixSizeEdit(false)
    setMeasurementUnitEdit('')
    setSkuEdit('')
    setstandardShelfEdit('')
    setNearExpiryEdit('')
    setImgEdit('')
    setProductImageEdit('')
    validationSetSkuNameEdit('')
    validationSetSelcetedBrandEdit('')
    validationSetProductCategoryEdit('')
    validationSetProductGroupEdit('')
    validationSetProductvariantEdit('')
    validationSetPackagingMaterialEdit('')
    validationSetVatEdit('')
    validationSetFixSizeEdit('')
    validationSetMeasurementUnitEdit('')
    validationSetSkuEdit('')
    validationSetstandardShelfEdit('')
    validationSetNearExpiryEdit('')
    setValidationImageEdit('')
    setImageSizeEdit('')
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      SkuList(
        '10',
        newOffset,
        search,
        ordering,
        filterBrand.value,
        filterCategory.value,
        filterSize,
        filterPackaging.value,
        filterGroups.value
      ).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      SkuList(
        '10',
        newOffset,
        search,
        ordering,
        filterBrand.value,
        filterCategory.value,
        filterSize,
        filterPackaging.value,
        filterGroups.value
      ).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const addSkus = () => {
    let navigateField = {
      skuName: skuName,
      selcetedBrand: selcetedBrand,
      selcetedproductGroup: selcetedproductGroup,
      selcetedproductvariant: selcetedproductvariant,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // ui validation [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (skuName.trim() == '') {
      validationSetSkuName('is-invalid')
    } else {
      validationSetSkuName('')
    }
    if (selcetedBrand == '') {
      validationSetSelcetedBrand('is-invalid')
    } else {
      validationSetSelcetedBrand('')
    }
    if (productCategory == '') {
      validationSetProductCategory('is-invalid')
    } else {
      validationSetProductCategory('')
    }
    if (selcetedproductGroup == '') {
      validationSetProductGroup('is-invalid')
    } else {
      validationSetProductGroup('')
    }
    if (selcetedproductvariant == '') {
      validationSetProductvariant('is-invalid')
    } else {
      validationSetProductvariant('')
    }
    if (selcetedpackagingMaterial == '') {
      validationSetPackagingMaterial('is-invalid')
    } else {
      validationSetPackagingMaterial('')
    }
    if (selcetedmeasurementUnit == '') {
      validationSetMeasurementUnit('is-invalid')
    } else {
      validationSetMeasurementUnit('')
    }
    if (selcetedstandardShelf == '') {
      validationSetstandardShelf('is-invalid')
    } else {
      validationSetstandardShelf('')
    }
    if (selcetednearExpiry == '') {
      validationSetNearExpiry('is-invalid')
    } else {
      validationSetNearExpiry('')
    }

    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (skuName !== '') {
      if (!skuUniq) {
        if (selcetedBrand !== '') {
          // if (productCategory !== '') {
          if (selcetedproductGroup !== '') {
            if (selcetedproductvariant !== '') {
              // if (selcetedpackagingMaterial !== '') {
              // if (selcetedstandardShelf !== '') {
              // if (selcetednearExpiry !== '') {
              if (prodcutImage !== '') {
                if (imageValidationSize) {
                  setDisableBtn(true)
                  setIsLoading('Please Wait..')
                  AddSkus(
                    skuName,
                    selcetedBrand,
                    // productCategory,
                    selcetedproductvariant,
                    // selcetednearExpiry,
                    selcetedadditionalNotes,
                    // selcetedpackagingMaterial,
                    // selcetedvat,
                    selcetedfixSize,
                    // selcetedmeasurementUnit,
                    selcetedSku,
                    // selcetedstandardShelf,
                    prodcutImage
                  ).then((res) => {
                    setDisableBtn(false)
                    setIsLoading('Submit')
                    if (res.success) {
                      setIsAlertSuccess(true)
                      setListLoader(true)
                      SkuList(
                        '10',
                        '0',
                        search,
                        ordering,
                        filterBrand.value,
                        filterCategory.value,
                        filterSize,
                        filterPackaging.value,
                        filterGroups.value
                      ).then((res) => {
                        setGetList(res.data)
                        setCount(res.count)
                        setNext(res.next)
                        setPrev(res.prev)
                        setSelected(0)
                        setLength('1')
                        setListLoader(false)
                      })
                      clearFunction()
                    }
                    if (res.failed) {
                      setIsAlertFailed(true)
                    }
                    $('#ea_close_create_product_skus').trigger('click') // modal close
                    setTimeout(() => {
                      setIsAlertFailed(false)
                      setIsAlertSuccess(false)
                    }, 1500)
                  })
                } else {
                  setImageSize('text-danger')
                }
              } else {
                setDisableBtn(true)
                setIsLoading('Please Wait..')
                AddSkus(
                  skuName,
                  selcetedBrand,
                  // productCategory,
                  selcetedproductvariant,
                  // selcetednearExpiry,
                  selcetedadditionalNotes,
                  // selcetedpackagingMaterial,
                  // selcetedvat,
                  selcetedfixSize,
                  // selcetedmeasurementUnit,
                  selcetedSku,
                  // selcetedstandardShelf,
                  prodcutImage
                ).then((res) => {
                  setDisableBtn(false)
                  setIsLoading('Submit')
                  if (res.success) {
                    setIsAlertSuccess(true)
                    setListLoader(true)
                    SkuList(
                      '10',
                      '0',
                      search,
                      ordering,
                      filterBrand.value,
                      filterCategory.value,
                      filterSize,
                      filterPackaging.value,
                      filterGroups.value
                    ).then((res) => {
                      setGetList(res.data)
                      setCount(res.count)
                      setNext(res.next)
                      setPrev(res.prev)
                      setLength('1')
                      setListLoader(false)
                    })
                    clearFunction()
                  }
                  if (res.failed) {
                    setIsAlertFailed(true)
                  }
                  $('#ea_close_create_product_skus').trigger('click') // modal close
                  setTimeout(() => {
                    setIsAlertFailed(false)
                    setIsAlertSuccess(false)
                  }, 1500)
                })
              }
            }
          }
        }
      }
    }
  }
  //       }
  //     }
  //   }
  // }

  const getProps = (val: any) => {
    setMeasurementunit(val.measurement_unit)
    setBrandOffSet('')
    setCategoryOffSet('')
    setGroupOffSet('')
    setpackageOffSet('')
    setParentCateOffSet('')
    setCateOffSet('')

    setMessageHandler('successEdit')
    setId(val.id)
    setParentCategoryId(val.parent_category_id)
    setProductGroupEdit(val.group_name)
    setModalLoader(true)
    // get single sku details
    SkuSingle(val.id).then((res: any) => {
      setSelectedProductGroup(res.measurement_unit ? res.measurement_unit : '-')
      setSkuNameEdit(res.name)
      setSelcetedBrandEdit(res.brand_id)
      setProductCategoryEdit(res.category_id)
      setProductGroupEdit(res.group_name)
      setProductvariantEdit(res.variants)
      setAdditionalNotesEdit(res.additional_notes)
      setPackagingMaterialEdit(res.material)
      setVatEdit(res.product_vat)
      setFixSizeEdit(res.fix_size)
      setMeasurementUnitEdit(res.measurement_unit)
      if (res.fix_size) {
        setSkuEdit(res.size)
      } else {
        setSkuEdit('1')
      }
      setstandardShelfEdit(res.shelf_life)
      setNearExpiryEdit(res.near_expiry)
      setImgEdit(res.product_img)
      if (res.product_img == null) {
        setCropDataEdit(defaultSrc)
      } else {
        setCropDataEdit(res.product_img)
      }

      // get selected variant details
      VariantsSingle(res.variants).then((res: any) => {
        setProductGroupEdit(res.group_name)
        setgetProductFromVarEdit([res])
      })
      setModalLoader(false)
      //request method for api implementation :- last change by kriti
      // getRequest(`${apiBaseURL()}master/product/category/${res.category_id}`, true).then((res) => {
      //   setSingleProdcutCategoryEdit(res)
      //   setCategoryEdit(res.parent_category)
      // })

      //request method for api implementation :- last change by kriti
      // getRequest(
      //   `${apiBaseURL()}master/product/category?orderning=name&parent_category__id=${val.parent_category_id
      //   }`,
      //   true
      // ).then((res) => {
      //   let data = res.results
      //   let category: any = []
      //   // data.forEach((element: any) => {
      //   //   category.push({ label: element.name, value: element.id })
      //   // })

      //   setParentCategoryEditModal(category)
      //   // setParentCategoryEditModal(res.data.results)
      // })

      //request method for api implementation :- last change by kriti
      getRequest(
        `${apiBaseURL()}master/product/variant?ordering=name&group_name__id=${val.group_id}` +
          apiLimit,
        true
      ).then((response) => {
        let options: any = []
        if (response.results.length > 0) {
          response.results.map((option: any) => {
            if (option.parent_category !== null) {
              options.push({
                label: option.name,
                value: option.id,
              })
            }
          })
          setProductVariantModalEdit(options)
        }
        // setProductVariantModalEdit(res.data.results)
      })
    })
  }

  const editSkus = () => {
    let navigateField = {
      skuNameEdit: skuNameEdit,
      selcetedBrandEdit: selcetedBrandEdit,
      selcetedproductGroupEdit: selcetedproductGroupEdit,
      selcetedproductvariantEdit: selcetedproductvariantEdit,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (skuNameEdit == '') {
      validationSetSkuNameEdit('is-invalid')
    } else {
      validationSetSkuNameEdit('')
    }
    if (selcetedBrandEdit == '') {
      validationSetSelcetedBrandEdit('is-invalid')
    } else {
      validationSetSelcetedBrandEdit('')
    }
    if (productCategoryEdit == '') {
      validationSetProductCategoryEdit('is-invalid')
    } else {
      validationSetProductCategoryEdit('')
    }
    if (selcetedproductvariantEdit == '') {
      validationSetProductvariantEdit('is-invalid')
    } else {
      validationSetProductvariantEdit('')
    }
    if (selcetedpackagingMaterialEdit == '') {
      validationSetPackagingMaterialEdit('is-invalid')
    } else {
      validationSetPackagingMaterialEdit('')
    }
    if (selcetedmeasurementUnitEdit == '') {
      validationSetMeasurementUnitEdit('is-invalid')
    } else {
      validationSetMeasurementUnitEdit('')
    }
    if (selcetedstandardShelfEdit == '') {
      validationSetstandardShelfEdit('is-invalid')
    } else {
      validationSetstandardShelfEdit('')
    }
    if (selcetednearExpiryEdit == '') {
      validationSetNearExpiryEdit('is-invalid')
    } else {
      validationSetNearExpiryEdit('')
    }

    if (skuNameEdit.trim() !== '') {
      if (skuUniqEdit) {
        if (selcetedBrandEdit !== '') {
          //if (productCategoryEdit !== '') {
          if (selcetedproductvariantEdit !== '') {
            // if (selcetedpackagingMaterialEdit !== '') {
            // if (selcetedmeasurementUnitEdit !== '') {
            // if (selcetedstandardShelfEdit !== '') {
            // if (selcetednearExpiryEdit !== '') {
            if (imageValidationSizeEdit) {
              setDisableBtn(true)
              setIsLoading('Please Wait..')
              setImageSizeEdit('')
              EditSkus(
                skuNameEdit,
                selcetedBrandEdit,
                // productCategoryEdit,
                selcetedproductvariantEdit,
                // selcetednearExpiryEdit,
                selcetedadditionalNotesEdit,
                // selcetedpackagingMaterialEdit,
                // selcetedvatEdit,
                selcetedfixSizeEdit,
                // selcetedmeasurementUnitEdit,
                selcetedSkuEdit,
                // selcetedstandardShelfEdit,
                prodcutImageEdit,
                id
              ).then((res) => {
                setDisableBtn(false)
                setIsLoading('Submit')
                if (res.success) {
                  setListLoader(true)
                  setIsAlertSuccess(true)
                  SkuList(
                    '10',
                    '0',
                    search,
                    ordering,
                    filterBrand.value,
                    filterCategory.value,
                    filterSize,
                    filterPackaging.value,
                    filterGroups.value
                  ).then((res) => {
                    setGetList(res.data)
                    setCount(res.count)
                    setNext(res.next)
                    setPrev(res.prev)
                    setSelected(0)
                    setListLoader(false)
                    if (res.data.length == 0) {
                      setLength('2')
                    } else {
                      setLength('1')
                    }
                  })
                  clearFunctionEdit()
                }
                if (res.failed) {
                  setIsAlertFailed(true)
                }
                $('#ea_close_Edit_product_skus').trigger('click') // modal close
                setTimeout(() => {
                  setIsAlertFailed(false)
                  setIsAlertSuccess(false)
                }, 1500)
              })
            } else {
              setImageSizeEdit('text-danger')
            }
          }
        }
      }
    }
  }
  //       }
  //     }
  //   }
  // }
  // }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      SkuList(
        '10',
        newOffset,
        search,
        ordering,
        filterBrand.value,
        filterCategory.value,
        filterSize,
        filterPackaging.value,
        filterGroups.value
      ).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files

      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImg(URL.createObjectURL(file))
        setProductImage(e.target.files[0])
      }
      let img: any = ''

      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setImageSize('text-danger')
            setImageValidationSize(false)
          } else {
            setImageValidationSize(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  const onImageChangeEdit = (e: any) => {
    setImageSizeEdit('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImgEdit(URL.createObjectURL(file))
        setProductImageEdit(e.target.files[0])
      }
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setImageSizeEdit('text-danger')
            setImageValidationSizeEdit(false)
          } else {
            setImageValidationSizeEdit(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  // chekc uniq sku name
  //request method for api implementation :- last change by kriti
  const checkSkus = async () => {
    if (skuName !== '') {
      await getRequest(`${apiBaseURL()}master/product/sku_check?search=${skuName}`, true).then(
        (res) => {
          if (res.message == 'True') {
            setValidSkuName(true)
            setSkuUniq(true)
          } else {
            setSkuUniq(false)
            setValidSkuName(false)
          }
        }
      )
    }
  }
  //request method for api implementation :- last change by kriti
  // check uniq edit sku
  const checkSkusEdit = async () => {
    if (skuNameEdit !== '') {
      // axios
      //   .get(`${apiBaseURL()}master/product/sku_check?search=${skuNameEdit}&id=${id}`, header)
      await getRequest(
        `${apiBaseURL()}master/product/sku_check?search=${skuNameEdit}&id=${id}`,
        true
      ).then((res) => {
        if (res.message == 'True') {
          setValidSkuNameEdit(true)
          setSkuUniqEdit(false)
        } else {
          setSkuUniqEdit(true)
          setValidSkuNameEdit(false)
        }
      })
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    setFilterSearch(e.target.value)
    setListLoader(true)
    const newTimer = setTimeout(() => {
      SkuListSeacrch(
        '10',
        '0',
        filterBrand.label,
        filterCategory.label,
        filterSize,
        filterPackaging.label,
        e.target.value,
        filterGroups.label
      ).then((res) => {
        setGetList(res.data)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
        setListLoader(false)
      })
    }, 500)
    setTimer(newTimer)
  }

  const fetchBrands = async (key?: any, search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const brandResponse = await getRequest(
      `${apiBaseURL()}master/product/brand?ordering=name&search=${search}&limit=${limit}&offset=${brandOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (brandResponse.next !== null) {
      const queryParams = new URLSearchParams(brandResponse.next)
      let newOffset: any = queryParams.get('offset')
      setBrandOffSet(newOffset)
      hasMore = true
    }

    if (brands.length == 0) {
      if (key !== 'modal') {
        options.push({
          label: 'All Brands',
          value: '',
        })
      }
    }

    if (brandResponse.results.length > 0) {
      brandResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (brands.length > 0) {
      setBrands(brands.concat(options))
    } else {
      setBrands(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategories = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (categoriesResponse.next !== null) {
      const queryParams = new URLSearchParams(categoriesResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffSet(newOffset)
      hasMore = true
    }

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    if (categoriesResponse.results.length > 0) {
      categoriesResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          ...categoryOptions,
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchGroups = async (key?: any, search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${limit}&offset=${groupOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setGroupOffSet(newOffset)
      hasMore = true
    }

    if (getProductGrops.length == 0) {
      if (key !== 'modal') {
        options.push({
          label: 'All Groups',
          value: '',
        })
      }
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (getProductGrops.length > 0) {
      setProductGrops(getProductGrops.concat(options))
      setProductGroupResponse(productGroupResponse.concat(response.results))
    } else {
      setProductGrops(options)
      setProductGroupResponse(response.results)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchPackaging = async (key?: any, search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/material?ordering=name&search=${search}&limit=${limit}&offset=${packageOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setpackageOffSet(newOffset)
      hasMore = true
    }

    if (getProductMaterial.length == 0) {
      if (key !== 'modal') {
        options.push({
          label: 'All Packaging',
          value: '',
        })
      }
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (getProductMaterial.length > 0) {
      setGetProdcutMaterial(getProductMaterial.concat(options))
    } else {
      setGetProdcutMaterial(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchParentCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/category?ordering=name&search=${search}&limit=${limit}&offset=${parentCateOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setParentCateOffSet(newOffset)
      hasMore = true
    }

    if (getParentCategory.length == 0) {
      // options.push({
      //     label: "All Packaging",
      //     value: ""
      // })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        if (option.parent_category == null) {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
    }

    if (getParentCategory.length > 0) {
      setParentCategory(getParentCategory.concat(options))
    } else {
      setParentCategory(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategory = async (id?: any, search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    // const res = await getRequest(`${apiBaseURL()}master/product/category?orderning=name&parent_category__id=${id}`, true)

    const response = await getRequest(
      `${apiBaseURL()}master/product/category?orderning=name&search=${search}&parent_category__id=${id}&limit=${limit}&offset=${cateOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setCateOffSet(newOffset)
      hasMore = true
    }

    if (getChildCategory.length == 0) {
      // options.push({
      //     label: "All Packaging",
      //     value: ""
      // })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        if (option.parent_category !== null) {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
      // setChildCategory(options)
    }

    if (getChildCategory.length > 0) {
      // setChildCategory(getChildCategory.concat(options))
    } else {
      // setChildCategory(options)
    }

    console.log('options == ', options)

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const [packageMaterial, setPackageMaterial] = useState([])
  const getDropDownValues = () => {
    setBrandOffSet('')
    setCategoryOffSet('')
    setGroupOffSet('')
    setpackageOffSet('')
    setParentCateOffSet('')
    setCateOffSet('')
    setMessageHandler('successAdd')
    // get brand list
    //request method for api implementation :- last change by kriti
    getRequest(`${apiBaseURL()}master/product/brand?ordering=name` + apiLimit, true).then((res) => {
      let data = res.results
      let brand: any = []
      data.forEach((element: any) => {
        brand.push({value: element.id, label: element.name})
      })
      setGetBrand(brand)
    })

    // get category list
    //request method for api implementation :- last change by kriti
    getRequest(`${apiBaseURL()}master/product/category?ordering=name` + apiLimit, true).then(
      (res) => {
        // setParentCategory(res.data.results)
        setGetProdcutCategory(res.results)
        setParentCategoryEdit(res.results)
        // setChildCategory(res.results)

        let data = res.results
        let category: any = []
        data.forEach((item: any) => {
          category.push({label: item.name, value: item.id})
        })
        setCategoryList(category)
      }
    )

    // get group list
    //request method for api implementation :- last change by kriti
    getRequest(`${apiBaseURL()}master/product/group?ordering=name` + apiLimit, true).then((res) => {
      setGetProdcut(res.results)
      setgetProductGroupModal(res.results)
      setProductGropModalEdit(res.results)
      // setProductGrops(res.data.results)

      let data = res.results
      let group: any = []
      data.forEach((item: any) => {
        group.push({label: item.name, value: item.id})
      })
      setProductGropsList(group)
    })

    // get variants list
    //request method for api implementation :- last change by kriti
    getRequest(`${apiBaseURL()}master/product/variant?ordering=name` + apiLimit, true).then(
      (res) => {
        setGetProdcutVariant(res.results)
        setGetProdcutVariantModal(res.results)
        // setProductVariantModalAdd(res.data.results)
      }
    )

    // get material list
    //request method for api implementation :- last change by kriti
    getRequest(`${apiBaseURL()}master/product/material?ordering=name` + apiLimit, true).then(
      (res) => {
        setPackageMaterial(res.results)

        let data = res.results
        let material: any = []
        data.forEach((item: any) => {
          material.push({label: item.name, value: item.id})
        })
        setProductMaterialList(material)
        // setProductMaterialList
      }
    )
  }

  const getCategoryFromParent = async (val: any) => {
    let id = val.value
    setCategory(val.value)
    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/category?orderning=name&parent_category__id=${id}` + apiLimit,
      true
    )
    // setChildCategory(res.results)

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        if (option.parent_category !== null) {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
      setChildCategory(options)
    }
  }

  const getCategoryFromParentEdit = (val: any) => {
    let id = val.value
    setCategoryEdit(val.value)
    //request method for api implementation :- last change by kriti
    getRequest(
      `${apiBaseURL()}master/product/category?orderning=name&parent_category__id=${id}`,
      true
    ).then((res) => {
      let data = res.results
      let category: any = []
      data.forEach((element: any) => {
        category.push({label: element.name, value: element.id})
      })

      setParentCategoryEditModal(category)
    })
  }

  const filterSearchBrand = (val: any) => {
    if (val.label === 'All Brands') {
      setFilterBrand({label: '', value: ''})
    } else {
      setFilterBrand(val)
    }
    setListLoader(true)
    SkuListSeacrch(
      '10',
      '0',
      val.label === 'All Brands' ? '' : val.value,
      filterCategory.value,
      filterSize,
      filterPackaging.value,
      filterSearch,
      filterGroups.value
    ).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
      setListLoader(false)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
    })
  }

  const filterSearchCategory = (val: any) => {
    if (val.label === 'All Categories') {
      setFilterCategory({label: '', value: ''})
    } else {
      setFilterCategory(val)
    }
    setListLoader(true)
    SkuListSeacrch(
      '10',
      '0',
      filterBrand.value,
      val.label === 'All Categories' ? '' : val.value,
      filterSize,
      filterPackaging.value,
      filterSearch,
      filterGroups.value
    ).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
      setListLoader(false)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
    })
  }

  const filterSearchPackaging = (val: any) => {
    if (val.label === 'All Packaging') {
      setFilterPackaging({value: '', label: ''})
    } else {
      setFilterPackaging(val)
    }
    setListLoader(true)
    SkuListSeacrch(
      '10',
      '0',
      filterBrand.value,
      filterCategory.value,
      filterSize,
      val.label === 'All Packaging' ? '' : val.value,
      filterSearch,
      filterGroups.value
    ).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
      setListLoader(true)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
    })
  }

  const filterSearchGroups = (val: any) => {
    if (val.label === 'All Groups') {
      setFilterGroups({label: '', value: ''})
    } else {
      setFilterGroups(val)
    }
    setListLoader(true)
    SkuListSeacrch(
      '10',
      '0',
      filterBrand.value,
      filterCategory.value,
      filterSize,
      filterPackaging.value,
      filterSearch,
      val.label === 'All Groups' ? '' : val.value
    ).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
      setListLoader(false)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
    })
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const getPropsDelete = (val: any) => {
    setMessageHandler('successDelete')
    setId(val.id)
    setProductNameDelete(val.name)
  }

  const deleteFuntion = () => {
    productSkuDelete(id).then((notify) => {
      if (notify.success) {
        SkuList(
          '10',
          '0',
          search,
          ordering,
          filterBrand.value,
          filterCategory.value,
          filterSize,
          filterPackaging.value,
          filterGroups.value
        ).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
        })
        setIsAlertSuccess(true)
      }
      if (notify.failed) {
        setIsAlertFailed(true)
      }
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)
      $('#delete_Product_SKU').trigger('click')
    })
  }

  //request method for api implementation :- last change by kriti
  const selcetedproductGroupToVar = (e: any) => {
    setProductGroup(e.value)
    getRequest(
      `${apiBaseURL()}master/product/variant?ordering=name&group_name__id=${e.value}` + apiLimit,
      true
    ).then((response) => {
      let options: any = []
      if (response.results) {
        response.results.map((option: any) => {
          if (option.parent_category !== null) {
            options.push({
              label: option.name,
              value: option.id,
            })
          }
        })
        setProductVariantModalAdd(options)
      }
      // setProductVariantModalAdd(res.data.results)
    })
  }

  //request method for api implementation :- last change by kriti
  const selcetedproductGroupToVarEdit = (e: any) => {
    setProductGroupEdit(e.value)
    getRequest(
      `${apiBaseURL()}master/product/variant?ordering=name&group_name__id=${e.value}` + apiLimit,
      true
    ).then((response) => {
      let options: any = []
      if (response.results) {
        response.results.map((option: any) => {
          if (option.parent_category !== null) {
            options.push({
              label: option.name,
              value: option.id,
            })
          }
        })
        setProductVariantModalEdit(options)
      }
      // setProductVariantModalEdit(res.data.results)
    })
  }

  const getImage = (val: any) => {
    setProductImage(val)
  }
  const getImageEdit = (val: any) => {
    setProductImageEdit(val)
  }

  const handleBrandChange = (e: any) => {
    setBrandId(e.value)
    setTimer(null)
  }

  const handleCategoryChange = (e: any) => {
    setCategoryId(e.value)
    setTimer(null)
  }

  const getProductGroup = async (id: any) => {
    const response = await getRequest(apiBaseURL() + 'master/product/group/' + id + '/', true)
    setMeasurementunit(response.measurement_unit)
  }

  const handleOrdering = (key?: any) => {
    setOrderColumnKey(key)
    setIsOrdering(true)
    // setListLoader(true)
    if (key === 'ProductSKU') {
      setOrderingColumn({...initialOrderColumn, ProductSKU: !orderingColumn.ProductSKU})
    }

    if (key === 'Brand') {
      setOrderingColumn({...initialOrderColumn, Brand: !orderingColumn.Brand})
    }

    if (key === 'Category') {
      setOrderingColumn({...initialOrderColumn, Category: !orderingColumn.Category})
    }

    if (key === 'ProductGroup') {
      setOrderingColumn({...initialOrderColumn, ProductGroup: !orderingColumn.ProductGroup})
    }

    if (key === 'Variant') {
      setOrderingColumn({...initialOrderColumn, Variant: !orderingColumn.Variant})
    }

    if (key === 'Packaging') {
      setOrderingColumn({...initialOrderColumn, Packaging: !orderingColumn.Packaging})
    }
  }

  useEffect(() => {
    if (orderColumnKey === 'ProductSKU') {
      if (orderingColumn.ProductSKU) {
        setOrdering('name')
      } else {
        setOrdering('-name')
      }
    }

    if (orderColumnKey === 'Brand') {
      if (orderingColumn.Brand) {
        setOrdering('brand_id__name')
      } else {
        setOrdering('-brand_id__name')
      }
    }

    if (orderColumnKey === 'Category') {
      if (orderingColumn.Category) {
        setOrdering('category_id__name')
      } else {
        setOrdering('-category_id__name')
      }
    }

    if (orderColumnKey === 'ProductGroup') {
      if (orderingColumn.ProductGroup) {
        setOrdering('variants__group_name__name')
      } else {
        setOrdering('-variants__group_name__name')
      }
    }

    if (orderColumnKey === 'Variant') {
      if (orderingColumn.Variant) {
        setOrdering('variants__name')
      } else {
        setOrdering('-variants__name')
      }
    }

    if (orderColumnKey === 'Packaging') {
      if (orderingColumn.Packaging) {
        setOrdering('material__name')
      } else {
        setOrdering('-material__name')
      }
    }
  }, [orderingColumn, orderColumnKey])

  useEffect(() => {
    setListLoader(true)
    SkuList(
      '10',
      currentOffset,
      search,
      ordering,
      filterBrand.value,
      filterCategory.value,
      filterSize,
      filterPackaging.value,
      filterGroups.value
    ).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
      // setListLoader(false)
    })
  }, [ordering])

  return (
    <>
      <EATitle title='Product SKUs' />
      <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({id: 'Product SKU'})}</PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('PRODUCT.ADD', messageTitle)
            : messageHandler === 'successEdit'
            ? FormatMessage('PRODUCT.UPDATE', messageTitle)
            : messageHandler === 'successDelete' && FormatMessage('PRODUCT.DELETE', messageTitle)
        }
      />

      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar id='searcListProdcut' onChange={searchList} />
            <div className='col-auto ms-auto'>
              {/* <div className=' card-toolbar d-flex my-0'> */}
              <form
                className=' card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                {/* filter dropdown */}
                <AsyncPaginate
                  loadOptions={(search) => fetchBrands('', search)}
                  isSearchable
                  className='react-select-container me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Brands'
                  name='brand'
                  value={brands.filter((option: any) => option.value === filterBrand.value)}
                  onChange={filterSearchBrand}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  // loadOptions={(search) => fetchCategories('', search)}
                  loadOptions={fetchCategories}
                  isSearchable
                  className='react-select-container w-140px me-3'
                  classNamePrefix='react-select'
                  placeholder='All Categories'
                  styles={categoryStyle}
                  name='categories'
                  value={categories.filter((option: any) => option.value === filterCategory.value)}
                  onChange={filterSearchCategory}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={(search) => fetchGroups('', search)}
                  isSearchable
                  className='react-select-container w-120px me-3'
                  classNamePrefix='react-select'
                  placeholder='All Groups'
                  name='brand'
                  value={getProductGrops.filter(
                    (option: any) => option.value === filterGroups.value
                  )}
                  onChange={filterSearchGroups}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={(search) => fetchPackaging('', search)}
                  isSearchable
                  className='react-select-container w-130px me-3'
                  classNamePrefix='react-select'
                  placeholder='All Packaging'
                  name='brand'
                  value={getProductMaterial.filter(
                    (option: any) => option.value === filterPackaging.value
                  )}
                  onChange={filterSearchPackaging}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_sku'
                    btnName='Add SKU'
                    onClick={getDropDownValues}
                    className='btn btn-primary'
                  />
                )}
                {/* <EaButton isModal={true} dataTarget='#ea_modal_import_sku' btnName='Import SKUs' />  */}
              </form>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0 ? (
              <NoRecords />
            ) : (
              <TableProductSku
                showDelete={permission.delete}
                showWrite={permission.write}
                getLength={getLength}
                tableList={getList}
                getPropsDelete={getPropsDelete}
                getPropsEdit={getProps}
                orderingColumn={orderingColumn}
                handleOrdering={handleOrdering}
              />
            )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${
              getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
            } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <Pagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>

      {/* =============================================================================================== */}
      {/* add sku */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        closeAuto={'ea_close_create_product_skus'}
        modalTitle='Add Product SKU'
        id='ea_modal_create_sku'
        onSubmit={addSkus}
        cancel={clearFunction}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Product SKU Name<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='skuName'
            className={clsx('form-control', validationSkuName)}
            placeholder='Type here...'
            value={skuName}
            onChange={(e) => {
              setSkuName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              validationSetSkuName(validation)
            }}
            onBlur={checkSkus}
          />
          {validSkuName && (
            <div className='text-danger'>
              The product name alredy exists. Please enter unique product SKU name.
            </div>
          )}
        </div>
        <div className='mb-4'>
          <label className='form-label' id='selcetedBrand'>
            Brand<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={(search) => fetchBrands('modal', search)}
            isSearchable
            className={`react-select-container ${validationSelcetedBrand}`}
            classNamePrefix='react-select'
            placeholder='All Brands'
            name='brand'
            value={brands.filter((option: any) => option.value === selcetedBrand)}
            onChange={(e) => {
              setSelcetedBrand(e.value)
              const validation = ValidationHandler(e.value, 'is-invalid')
              validationSetSelcetedBrand(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='row'>
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Parent Category</label>
            <AsyncPaginate

              loadOptions={fetchParentCategory}
              isSearchable
              className={`react-select-container ${validationSelcetedcategory}`}
              classNamePrefix='react-select'
              placeholder='Select'
              name='product'
              value={getParentCategory.filter((option: any) => option.value === selcetedcategory)}
              onChange={(e) => {
                getCategoryFromParent(e)
                setChildCategory([])
                // fetchCategory(e.value)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div> */}
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Category</label>
            <EaDropDown
              className={`react-select-container  ${validationProductCategory}`}
              list={getChildCategory}
              placeholder='Select'
              onChange={(e: any) => {
                setProductCategory(e.value)
                const validation = ValidationHandler(e.value, 'is-invalid')
                validationSetProductCategory(validation)
              }}
              value={getChildCategory.filter((option: any) => option.value === productCategory)}
            />
          </div> */}
          <div className='col-sm-6 mb-4'>
            <label className='form-label' id='selcetedproductGroup'>
              Product Group<span className='text-danger'>*</span>
            </label>
            <AsyncPaginate
              loadOptions={(search) => fetchGroups('modal', search)}
              isSearchable
              className={`react-select-container ${validationSelcetedproductGroup}`}
              classNamePrefix='react-select'
              placeholder='Select'
              name='product'
              value={getProductGrops.filter((option: any) => option.value === selcetedproductGroup)}
              onChange={(e) => {
                getProductGroup(e.value)
                // console.log(e);
                // master/product/group/4/

                productGroupResponse.map((option: any) => {
                  if (option.id === e.value) {
                    console.log('option================', option)
                    setSelectedProductGroup(option.measurement_unit)
                    setSku('')
                    setFixSize(false)
                    return false
                  }
                })
                selcetedproductGroupToVar(e)
                const validation = ValidationHandler(e.value, 'is-invalid')
                validationSetProductGroup(validation)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label' id='selcetedproductvariant'>
              Product Variant<span className='text-danger'>*</span>
            </label>
            <EaDropDown
              className={`react-select-container ${validationSelcetedproductvariant}`}
              list={getProductVariantModalAdd}
              placeholder='Select'
              onChange={(e: any) => {
                setProductvariant(e.value)
                const validation = ValidationHandler(e.value, 'is-invalid')
                validationSetProductvariant(validation)
              }}
              value={getProductVariantModalAdd.filter(
                (option: any) => option.value === selcetedproductvariant
              )}
            />
          </div>
          <div className='mb-4'>
            <label className='form-label'>Additional Notes (Optional)</label>
            <input
              type='text'
              className={clsx('form-control', validationSelcetedadditionalNotes)}
              placeholder='Type here...'
              value={selcetedadditionalNotes}
              onChange={(e) => {
                setAdditionalNotes(capitalizeFirstLetter(e.target.value))
              }}
            />
          </div>
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Packaging Material</label>
            <AsyncPaginate

              loadOptions={(search) => fetchPackaging('modal', search)}
              isSearchable
              className='react-select-container me-3'
              classNamePrefix='react-select'
              placeholder='Select'
              name='brand'
              value={getProductMaterial.filter(
                (option: any) => option.value === selcetedpackagingMaterial
              )}
              onChange={(e) => {
                setPackagingMaterial(e.value)
                const validation = ValidationHandler(e.value, 'is-invalid')
                validationSetPackagingMaterial(validation)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div> */}
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>VAT (in %)</label>
            <input
              type='text'
              className={clsx('form-control', validationSelcetedvat)}
              placeholder='0.00'
              value={selcetedvat}
              onChange={(e) => {
                const re = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  setVat(e.target.value)
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  validationSetVat(validation)
                }
              }}
            />
          </div> */}

          {measurementUnit !== '' &&
            (measurementUnit.toLowerCase() === 'kilogram' ||
              measurementUnit.toLowerCase() === 'gram') && (
              <div className='col-sm-12 mb-4'>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className={clsx('form-check-input', validationSelcetedfixSize)}
                    type='checkbox'
                    checked={selcetedfixSize}
                    onChange={(e) => {
                      let checked = $('#fixed-size').is(':checked')
                      if (checked == true) {
                        setFixSize(true)
                      } else {
                        setFixSize(false)
                      }
                    }}
                    id='fixed-size'
                    data-kt-check='true'
                    data-kt-check-target='.widget-13-check'
                  />
                  <label className='form-check-label' htmlFor='fixed-size'>
                    Available in fixed size
                  </label>
                </div>
              </div>
            )}
          {selcetedfixSize && (
            <div className='col-sm-12 mb-4'>
              <label className='form-label'>SKU Size</label>
              <div className='input-group'>
                <input
                  type='text'
                  className={clsx('form-control', validationSelcetedSku)}
                  placeholder='0'
                  maxLength={5}
                  value={selcetedSku}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/
                    if (e.target.value === '' || re.test(e.target.value)) {
                      if (e.target.value !== '0') {
                        setSku(e.target.value)
                        const validation = ValidationHandler(e.target.value, 'is-invalid')
                        validationSetSku(validation)
                      }
                    }
                  }}
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-100px'>
                  In {selectedProductGroup || '-'}
                </span>
              </div>
            </div>
          )}

          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Standard Shelf Life</label>
            <input
              type='text'
              className={clsx('form-control', validationSelcetedstandardShelf)}
              placeholder='x Days'
              maxLength={5}
              value={selcetedstandardShelf}
              onChange={(e) => {
                const re = /^[0-9\b]+$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  setstandardShelf(e.target.value)
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  validationSetstandardShelf(validation)
                }
              }}
            />
          </div> */}
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Near Expiry</label>
            <input
              type='text'
              className={clsx('form-control', validationSelcetednearExpiry)}
              placeholder='x Days'
              value={selcetednearExpiry}
              maxLength={5}
              onChange={(e) => {
                const re = /^[0-9\b]+$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  setNearExpiry(e.target.value)
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  validationSetNearExpiry(validation)
                }
              }}
            />
          </div> */}
        </div>

        <Imagecropper
          title='Product SKU Image'
          getImage={getImage}
          onImageChange={onImageChange}
          imageRequire={validationImage}
          imageSize={imageSize}
          cropData={cropData}
          setCrop={setCropData}
        />
      </EaModal>

      {/* =edit================== */}
      {/* edit skus */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        closeAuto={'ea_close_Edit_product_skus'}
        modalTitle='Edit Product SKU'
        id='ea_modal_Edit_sku'
        onSubmit={editSkus}
        cancel={clearFunctionEdit}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Product SKU Name<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='skuNameEdit'
            className={clsx('form-control', validationSkuNameEdit)}
            placeholder='Type here...'
            value={skuNameEdit}
            onChange={(e) => {
              setSkuNameEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              validationSetSkuNameEdit(validation)
            }}
            onBlur={checkSkusEdit}
          />
          {validSkuNameEdit && (
            <div className='text-danger'>
              The product name alredy exists. Please enter unique product SKU name.
            </div>
          )}
        </div>
        <div className='mb-4'>
          <label className='form-label' id='selcetedBrandEdit'>
            Brand<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={(search) => fetchBrands('modal', search)}
            isSearchable
            className={`react-select-container ${validationSelcetedBrand_Edit}`}
            classNamePrefix='react-select'
            menuPosition='fixed'
            placeholder='Select'
            name='brand'
            value={getBrand.filter((option: any) => option.value === selcetedBrandEdit)}
            onChange={(e) => {
              setSelcetedBrandEdit(e.value)
              const validation = ValidationHandler(e.value, 'is-invalid')
              validationSetSelcetedBrandEdit(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='row'>
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Parent Category</label>
            <AsyncPaginate

              loadOptions={fetchParentCategory}
              isSearchable
              className={`react-select-container ${validationSelcetedcategoryEdit}`}
              classNamePrefix='react-select'
              menuPosition='fixed'
              placeholder='Select'
              name='product'
              value={categoryList.filter((option: any) => option.value === selcetedcategoryEdit)}
              onChange={(e) => {
                getCategoryFromParentEdit(e)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div> */}
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Category</label>
            <EaDropDown
              className={`react-select-container  ${validationProductCategory_Edit}`}
              list={getParentCategoryEditModal}
              placeholder='Select'
              onChange={(e: any) => {
                setProductCategoryEdit(e.value)
                const validation = ValidationHandler(e.value, 'is-invalid')
                validationSetProductCategoryEdit(validation)
              }}
              value={categoryList.filter((option: any) => option.value === productCategoryEdit)}
            />
          </div> */}
          <div className='col-sm-6 mb-4'>
            <label className='form-label' id='selcetedproductGroupEdit'>
              Product Group<span className='text-danger'>*</span>
            </label>
            <AsyncPaginate
              loadOptions={(search) => fetchGroups('modal', search)}
              isSearchable
              className={`react-select-container ${validationSelcetedproductGroupEdit}`}
              classNamePrefix='react-select'
              menuPosition='fixed'
              placeholder='Select'
              name='product'
              value={getProductGropsList.filter(
                (option: any) => option.value === selcetedproductGroupEdit
              )}
              onChange={(e) => {
                getProductGroup(e.value)
                productGroupResponse.map((option: any) => {
                  if (option.id === e.value) {
                    setSelectedProductGroup(option.measurement_unit)
                    setFixSizeEdit(false)
                    setSkuEdit('')
                    return false
                  }
                })
                selcetedproductGroupToVarEdit(e)
                const validation = ValidationHandler(e.value, 'is-invalid')
                validationSetProductGroupEdit(validation)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label' id='selcetedproductvariantEdit'>
              Product Variant<span className='text-danger'>*</span>
            </label>
            <EaDropDown
              className={`react-select-container ${validationSelcetedproductvariantEdit}`}
              list={getProductVariantModalEdit}
              placeholder='Select'
              onChange={(e: any) => {
                setProductvariantEdit(e.value)
                const validation = ValidationHandler(e.value, 'is-invalid')
                validationSetProductvariantEdit(validation)
              }}
              value={getProductVariantModalEdit.filter(
                (option: any) => option.value === selcetedproductvariantEdit
              )}
            />
          </div>
          <div className='mb-4'>
            <label className='form-label'>Additional Notes (Optional)</label>
            <input
              type='text'
              className={clsx('form-control', validationSelcetedadditionalNotesEdit)}
              placeholder='Type here...'
              value={selcetedadditionalNotesEdit}
              onChange={(e) => {
                setAdditionalNotesEdit(e.target.value)
              }}
            />
          </div>
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Packaging Material</label>
            <AsyncPaginate

              loadOptions={(search) => fetchPackaging('modal', search)}
              isSearchable
              className={`react-select-container me-3  ${validationSelcetedpackagingMaterialEdit}`}
              classNamePrefix='react-select'
              placeholder='Select'
              name='brand'
              value={getProductMaterialList.filter(
                (option: any) => option.value === selcetedpackagingMaterialEdit
              )}
              onChange={(e) => {
                setPackagingMaterialEdit(e.value)
                const validation = ValidationHandler(e.value, 'is-invalid')
                validationSetPackagingMaterialEdit(validation)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div> */}
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>VAT (in %)</label>
            <input
              type='text'
              className={clsx('form-control', validationSelcetedvatEdit)}
              placeholder='0.00'
              value={selcetedvatEdit}
              onChange={(e) => {
                const re = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  setVatEdit(e.target.value)
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  validationSetVatEdit(validation)
                }
              }}
            />
          </div> */}
          {measurementUnit !== '' &&
            (measurementUnit.toLowerCase() === 'kilogram' ||
              measurementUnit.toLowerCase() === 'gram') && (
              <div className='col-sm-12 mb-4'>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className={clsx('form-check-input', validationSelcetedfixSizeEdit)}
                    type='checkbox'
                    checked={selcetedfixSizeEdit}
                    onChange={(e) => {
                      let checkedEdit = $('#fixed-size-Edit').is(':checked')
                      if (checkedEdit == true) {
                        setFixSizeEdit(true)
                      } else {
                        setFixSizeEdit(false)
                      }
                    }}
                    id='fixed-size-Edit'
                    data-kt-check='true'
                    data-kt-check-target='.widget-13-check'
                  />
                  <label className='form-check-label' htmlFor='fixed-size-Edit'>
                    Available in fixed size
                  </label>
                </div>
              </div>
            )}
          {selcetedfixSizeEdit && (
            <div className='col-sm-12 mb-4'>
              <label className='form-label'>SKU Size</label>
              <div className='input-group'>
                <input
                  type='text'
                  className={clsx('form-control', validationSelcetedSkuEdit)}
                  placeholder='0'
                  value={selcetedSkuEdit}
                  maxLength={5}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/
                    if (e.target.value === '' || re.test(e.target.value)) {
                      if (e.target.value !== '0') {
                        setSkuEdit(e.target.value)
                        const validation = ValidationHandler(e.target.value, 'is-invalid')
                        validationSetSkuEdit(validation)
                      }
                    }
                  }}
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-100px'>
                  In {selectedProductGroup ? selectedProductGroup : '-'}
                </span>
              </div>
            </div>
          )}

          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Standard Shelf Life</label>
            <input
              type='text'
              className={clsx('form-control', validationSelcetedstandardShelfEdit)}
              placeholder='x Days'
              value={selcetedstandardShelfEdit}
              maxLength={5}
              onChange={(e) => {
                const re = /^[0-9\b]+$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  setstandardShelfEdit(e.target.value)
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  validationSetstandardShelfEdit(validation)
                }
              }}
            />
          </div> */}
          {/* <div className='col-sm-6 mb-4'>
            <label className='form-label'>Near Expiry</label>
            <input
              type='text'
              className={clsx('form-control', validationSelcetednearExpiryEdit)}
              maxLength={5}
              placeholder='x Days'
              value={selcetednearExpiryEdit}
              onChange={(e) => {
                const re = /^[0-9\b]+$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  setNearExpiryEdit(e.target.value)
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  validationSetNearExpiryEdit(validation)
                }
              }}
            />
          </div> */}
        </div>
        <Imagecropper
          title='Product SKU Image'
          getImage={getImageEdit}
          onImageChange={onImageChangeEdit}
          imageRequire={validationImageEdit}
          imageSize={imageSizeEdit}
          cropData={cropDataEdit}
          setCrop={setCropDataEdit}
        />
      </EaModal>

      {/* delete sku */}
      <EaModal
        modalTitle='Delete Product SKU'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{prodcutNameDelete}?</span>
          </p>
        }
        closeAuto='delete_Product_SKU'
        id={'deleteProductSKU'}
        actionBtnName='Yes, Proceed'
        onSubmit={deleteFuntion}
      />

      {/* bluk work pending */}
      <EaModal
        modalTitle='Product SKU Bluk Upload'
        children={
          <>
            <div
              style={{backgroundColor: 'rgba(247,247,247,0.5)'}}
              className='rounded cursor-pointer bg-hover-light-primary my-2 h-150px position-relative form-file border border-light border-dashed text-center py-4 px-4 d-flex align-items-center justify-content-center'
            >
              <div>
                <label className='form-label mb-0' htmlFor='group-icon'>
                  <div className='btn-bg-light'>
                    <span className='svg-icon svg-icon-1'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil022.svg'
                        className='ic text-dark me-1 svg-icon-2hx'
                      />
                    </span>
                    <div className='mt-3 text-muted'>Upload Product SKUs Excel File</div>
                  </div>
                </label>
                <input
                  type='file'
                  className='file-input opacity-0 position-absolute h-auto cursor-pointer'
                  placeholder='Type here...'
                  id='group-icon'
                />
              </div>
            </div>
            <div className='mt-7 mb-2 text-center'>
              <a href='#!' className='text-primary'>
                Download Sample Excel File
              </a>
            </div>
          </>
        }
        id={'ea_modal_import_sku'}
      />
    </>
  )
}

export default ProductSku
