import React from "react";
import { apiBaseURL, downloadFile, KTSVG } from "../../../../_eaFruitsDms/helpers";
import { deleteRequest } from "../../../../_eaFruitsDms/https/apiCall";

const Documents = ({
  documents,
  setDocuments,
  handleChange,
  removeDocument,
  isUpdate,
  isOverView,
  customerId,
  showWrite,
}: any) => {
  const user_type = localStorage.getItem('type')
  const cId = localStorage.getItem('cId')
  const contactVerify = localStorage.getItem('contact_is_verified')

  const deleteDocument = async (documentId?: number) => {
    if (isUpdate || isOverView) {
      await deleteRequest(`${apiBaseURL()}customers/${cId}/other-documents/${documentId}`, true)
    }
  }

  const removeDocumentFormArray = (index: number) => {
    if (!isOverView) {
      removeDocument(index)
    } else {
      let uploadedDocument: any = [...documents]

      uploadedDocument.splice(index, 1)

      setDocuments(uploadedDocument)
    }
  }

  // const saveDocument = async (e: any) => {
  //   if (customerId != 0) {
  //     const document = handleFile(e)
  //     if (document != '') {
  //       let customerDocument = new FormData()
  //       customerDocument.append(`document[${documents.length}]customer_document`, document)
  //
  //       await putRequest(`${apiBaseURL()}customers/${customerId}`, customerDocument, true)
  //
  //       let uploadedDocument: any = [...documents]
  //
  //       uploadedDocument.push(document)
  //
  //       setDocuments(uploadedDocument)
  //     }
  //   }
  // }

  const handleFile = (e: any) => {
    const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.gif|\.doc|\.xlsx)$/i

    if (allowedExtensions.exec(e.target.value)) {
      if (e.dataTransfer) {
        return e.dataTransfer.files[0]
      } else if (e.target) {
        return e.target.files[0]
      }
    }

    return ''
  }

  return (
    <>
      <h3 className='font-14 fw-500 border-bottom pb-4 mb-0 pt-3'>Other Documents (Optional)</h3>
      <div className='row'>
        <div className='col-lg-9 col-xl-9 col-xxl-8'>
          {documents.length === 0 ? "-" :
            documents.map((file: any, index: number) => {
              return (
                <>
                  <div className='d-flex justify-content-between align-items-center border-bottom'>
                    <p className='my-2 py-1'>
                      {file.name
                        ? file.name
                        : file.document.substring(
                          file.document.lastIndexOf('/') + 1
                        )}
                    </p>
                    <div className="min-w-80px">
                      <a
                        href='JAVASCRIPT:void(0)'
                        className='btn py-0 pe-0 btn-hover-color-primary btn-sm'
                        onClick={() => downloadFile(file.document)}
                      >
                        <KTSVG path='/media/icons/duotune/files/fil021.svg' className='ic mr-0' />
                      </a>
                      {!user_type ? (
                        <>
                          {isUpdate || isOverView ? (
                            <a
                              href='JAVASCRIPT:void(0)'
                              className='btn py-0 ps-5 pe-0 btn-hover-color-primary btn-sm'
                              onClick={() => {
                                deleteDocument(file.id)
                                removeDocumentFormArray(index)
                              }}
                            >
                              {showWrite &&
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='ic mr-0'
                                />}
                            </a>
                          ) : (
                            <a
                              href='JAVASCRIPT:void(0)'
                              className='btn py-0 ps-5 pe-0 btn-hover-color-primary btn-sm'
                              onClick={() => removeDocument(index)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr011.svg'
                                className='ic mr-0'
                              />
                            </a>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              )
            })}
          {/*{!user_type && showWrite ? (*/}
          {/*  <>*/}
          {/*    <div className='d-flex justify-content-between align-items-center border-bottom position-relative'>*/}
          {/*      <label htmlFor='attachFile' className='text-primary fw-500 cursor-pointer'>*/}
          {/*        Attach New File*/}
          {/*      </label>*/}
          {/*      <label*/}
          {/*        htmlFor='attachFile'*/}
          {/*        className='btn btn-with-icon btn-icon-dark fw-600 py-1 lnh-36px px-0'*/}
          {/*      >*/}
          {/*        <KTSVG*/}
          {/*          path='/media/icons/duotune/communication/com008.svg'*/}
          {/*          className='svg-icon-muted ic me-0'*/}
          {/*        />*/}
          {/*        /!* <span*/}
          {/*                                      className='text-muted'>{customer.document ? customer.document.name : "Attach"}</span> *!/*/}
          {/*      </label>*/}
          {/*      <input*/}
          {/*        className='form-control invisible position-absolute'*/}
          {/*        type='file'*/}
          {/*        accept='application/pdf, image/*, application/msword'*/}
          {/*        id='attachFile'*/}
          {/*        disabled={isOverView && contactVerify === 'false'}*/}
          {/*        name='document'*/}
          {/*        onChange={(e) => {*/}
          {/*          isOverView ? saveDocument(e) : handleChange(e, 'document')*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*) : (*/}
          {/*  <></>*/}
          {/*)}*/}
        </div>
      </div>
    </>
  )
}

export default Documents
