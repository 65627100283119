import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const ShowBrandToolTip = ({ brands }: any) => {
    if (brands && brands.length > 0) {
        if (brands.length > 1) {
            return <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => (
                    <Tooltip {...props} className="tooltip-dark">
                        {brands.map((item: any, index: any) => {
                            return (
                                <>
                                    <span>
                                        {index ? "," : ""} {item.name}{" "}
                                    </span>
                                </>
                            );
                        })}
                    </Tooltip>
                )}
                placement="top"
            >
                <span className="ms-2 cursor-pointer">
                    {brands[0].name}
                    {` & ${brands.length} more`}
                </span>
            </OverlayTrigger>
        } else {
            return (
                <span className="ms-2 cursor-pointer">
                    {brands[0].name}
                </span>
            )
        }
    } else {
        return <></>
    }
}

export default ShowBrandToolTip