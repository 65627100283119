import moment from 'moment'
import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import tenantConfiguration from '../../../TenantVariables'
import {
  apiBaseURL,
  dateFormat,
  getQuarter,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
  staticSearch,
} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {
  EaChartBar,
  EaChartPie,
  EaChartPieNested,
} from '../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import {AsyncPaginate} from 'react-select-async-paginate'
import usePermission from '../../../_eaFruitsDms/helpers/components/usePermission'

const SMDashboard = (props: any) => {
  const {warehouseId, month} = props
  const initialType = {label: 'Sales', value: 'sales'}
  const targetPermission = usePermission('targets')
  const productWiseReportPermission = usePermission('product_wise_sales_report')

  // for bar chart
  let app = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
  }

  // for bar chart
  const labelOption = (name: any) => {
    return {
      show: true,
      position: app.position,
      distance: app.distance,
      align: app.align,
      verticalAlign: app.verticalAlign,
      rotate: app.rotate,
      // formatter: '{c}  {name|{a}}',
      // formatter: function (params: any) {
      //   return numberFormatWithCode(params.data).displayFormat
      // },
      formatter: function (params: any) {
        // return numberFormatWithCode(params.data).displayFormat
        return name === 'New Customers'
          ? params.data
          : numberFormatWithCode(params.data).displayFormat
      },
      fontSize: 10,
      rich: {
        name: {},
      },
    }
  }

  const [widget, setWidget] = useState({
    total_team_size: 0,
    total_warehouses: 0,
    total_skus: 0,
    total_product_groups: 0,
    total_customers: 0,
    total_categories: 0,
    total_sales_amount: 0,
    total_open_payment_issues_amount: 0,
    total_open_payment_issues: 0,
    total_expired_products: 0,
    total_open_credit_issues_amount: 0,
    total_open_credit_issues_due_percentage: 0,
    total_approved_discount_amount: 0,
    total_approved_discount_percentage: 0,
    achieved_targets: 0,
    total_targets: 0,
    cashy_sales_amount: 0,
    credit_sales_amount: 0,
  })

  const [brandPieChart, setBrandPieChart] = useState({
    data: [],
    title: '',
    bottom: '',
    selectedMode: true,
  })
  const [customerCategoryPieChart, setCustomerCategoryPieChart] = useState({
    data: [],
    title: '',
    bottom: '5%',
    selectedMode: false,
  })
  const [nestedData, setNestedData] = useState([])
  const [brandsChartLoading, setBrandsChartLoading] = useState(true)
  const [customerLoading, setCustomerLoading] = useState(true)
  const [reset, setReset] = useState<any>(moment())
  const [srType, setSrType] = useState<any>(initialType)
  const [srLoading, setSrLoading] = useState(true)
  const [topSr, setTopSr] = useState<any>([])
  const [isHoreca, setIsHoreca] = useState(false)
  const [customerType, setCustomerType] = useState<any>(initialType)
  const [topCustomer, setTopCustomer] = useState<any>([])
  const [barChart, setBarChart] = useState({})
  const [isChartLoading, setChartLoading] = useState(true)
  const [barCharEmpty, setBarChartEmpty] = useState<any>([])

  const filter = `warehouse_id=${warehouseId}&timeline=${isEmpty(month) ? 'today' : month}`

  const fetchWidgetsCounts = async () => {
    const url = apiBaseURL() + 'reports/sa-dashboard/card-counts?' + filter
    const response = await getRequest(url, true)
    if (response && response.result) {
      setWidget(response.result)
    }
  }

  const fetchBrandPieChart = async () => {
    setBrandsChartLoading(true)

    const url = apiBaseURL() + 'reports/sa-dashboard/brands?' + filter
    const response = await getRequest(url, true)
    let brandChart: any = {...brandPieChart}
    let mergeBrandChart: Object[] = []

    if (response && response.result) {
      if (response.result.length === 0) {
        setNestedData([])
      }

      response.result.forEach((item: any, index: number) => {
        mergeBrandChart.push({
          name: item.brand_name,
          value: parseFloat(item.sales_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          brand_id: item.brand_id,
          selected: index === 0 ? true : false,
          total_payable_amount: parseFloat(item.total_payable_amount)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    brandChart.data = mergeBrandChart
    setBrandPieChart(brandChart)

    if (response.result.length >= 1) {
      fetchProductGroupsPieChart(response.result[0].brand_id)
    }

    setBrandsChartLoading(false)
  }

  const fetchProductGroupsPieChart = async (brandId: string) => {
    const url = apiBaseURL() + `reports/sa-dashboard/brands/${brandId}/product-groups?` + filter
    const response = await getRequest(url, true)
    let mergeChart: any = []

    if (response && response.result) {
      response.result.forEach((item: any) => {
        mergeChart.push({
          name: item.product_group_name,
          value: parseFloat(item.sales_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          product_group_id: item.product_group_id,
          total_payable_amount: parseFloat(item.total_payable_amount)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    setNestedData(mergeChart)
  }

  const fetchCustomerCategory = async () => {
    setCustomerLoading(true)
    const url = apiBaseURL() + `reports/sm-dashboard/customer-category-sale?` + filter
    const response = await getRequest(url, true)
    let chart: any = {...customerCategoryPieChart}
    let mergeChart: Object[] = []

    if (response && response.result) {
      response.result.forEach((item: any, index: number) => {
        mergeChart.push({
          name: item.customer_type,
          value: parseFloat(item.total_sales_amount)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    chart.data = mergeChart
    setCustomerCategoryPieChart(chart)
    setCustomerLoading(false)
  }

  const fetchBusinessOverviewChart = async () => {
    setChartLoading(true)

    const url = apiBaseURL() + `reports/sa-dashboard/business-overview?` + filter
    const response = await getRequest(url, true)

    if (response && response.result) handleChartResponse(response.result)

    setChartLoading(false)
  }

  const handleChartResponse = (response: any) => {
    let initialChart: any = {}
    let xAxisData: string[] = []
    let seriesData: any = []
    let legend: any = []
    let type: any = []
    let key = 'label'
    let lastQuarter = getQuarter().currentQuarter - 1
    let year: any = []

    if (response.length > 0) {
      setBarChartEmpty([1])
    } else {
      setBarChartEmpty([])
    }

    if (response.length > 0) {
      response[0].chart_data.forEach((item: any) => {
        if (item[key] !== 'Stock-in') {
          legend.push(item[key])
          type.push({name: item[key]})
        }
      })

      response.forEach((item: any) => {
        if (item[key] !== 'Stock-in') {
          year.push(getQuarter(item.range, true).concatMonths)

          xAxisData.push(
            isEmpty(warehouseId)
              ? !isEmpty(item.warehouse_name)
                ? item.warehouse_name
                : '-'
              : item.timeline_type + ' ' + item.range
          )
        }
      })

      type.forEach((i: any) => {
        let data: any = []
        response.forEach((item: any) => {
          item.chart_data.length > 0 &&
            item.chart_data.forEach((item: any, index: any) => {
              if (item[key] !== 'Stock-in') {
                if (i.name === item[key]) {
                  data.push(
                    parseFloat(item.value)
                      .toFixed(2)
                      .replace(/\.?0+$/, '')
                  )
                }
              }
            })
        })

        seriesData.push({
          name: i.name,
          type: 'bar',
          width: '100%',
          label: labelOption(i.name),
          tooltip: {
            valueFormatter: function (params: any) {
              return i.name === 'New Customers'
                ? params
                : numberFormatWithCode(params).displayFormat
            },
          },
          emphasis: {
            focus: 'series',
          },
          data: data,
        })
      })
    }

    switch (month) {
      case 'this_quarter':
        initialChart.xAxisData = getQuarter().quarterMonths
        initialChart.rotate = 1
        break

      case 'last_quarter':
        initialChart.xAxisData = getQuarter(lastQuarter).quarterMonths
        initialChart.rotate = 1
        break

      case 'this_year':
        initialChart.xAxisData = year
        initialChart.rotate = 1
        break

      case 'last_year':
        initialChart.xAxisData = year
        initialChart.rotate = 1
        break

      default:
        initialChart.xAxisData = xAxisData
        initialChart.rotate = 30
        break
    }

    if (isEmpty(warehouseId)) {
      initialChart.xAxisData = xAxisData
      initialChart.rotate = 30
    }

    initialChart.series = seriesData
    initialChart.legend = legend
    initialChart.startZoom = 0
    initialChart.endZoom = 100

    setBarChart(initialChart)
  }

  const fetchCustomerType = async (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        value: 'sales',
        label: 'Sales',
      },
      {
        value: 'credit_requests',
        label: 'Credit Requests',
      },
      {
        value: 'overdue_credit_issues',
        label: 'Overdue Credit Issues',
      },
      {
        value: 'discount_requests',
        label: 'Discount Requests',
      },
      {
        value: 'pay_later_usage',
        label: 'Pay Later Usage',
      },
      {
        value: 'pay_later_to_credit_issues',
        label: 'Pay Later To Credit Issues',
      },
    ]

    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchTopSr = async () => {
    setSrLoading(true)
    const url =
      apiBaseURL() + 'reports/sa-dashboard/sr-performance?' + filter + `&records=${srType.value}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      setTopSr(response.result)
    }
    setSrLoading(false)
  }

  const fetchTopCustomer = async () => {
    setCustomerLoading(true)
    const url =
      apiBaseURL() +
      'reports/sa-dashboard/customers-performance?' +
      filter +
      `&records=${customerType.value}&is_horeca_customer=${isHoreca === true ? true : ''}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      setTopCustomer(response.result)
    }
    setCustomerLoading(false)
  }

  const updateSelectedBrands = (brands: any) => {
    const id = brands.data.brand_id
    let chart: any = {...brandPieChart}
    let mergeChart: Object[] = []

    chart.data.forEach((item: any, index: number) => {
      mergeChart.push({
        name: item.name,
        value: item.value,
        brand_id: item.brand_id,
        selected: item.brand_id === id ? true : false,
        total_payable_amount: item.total_payable_amount,
      })
    })

    chart.data = mergeChart
    setBrandPieChart(chart)
    fetchProductGroupsPieChart(id)
  }

  const fetchSrType = async (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        value: 'sales',
        label: 'Sales',
      },
      {
        value: 'payment_issues',
        label: 'Payment Issues',
      },
      {
        value: 'discount_requests',
        label: 'Discount Requests',
      },
      {
        value: 'unsold_returns',
        label: 'Unsold Returns',
      },
      {
        value: 'gatepass_issues',
        label: 'Gatepass Issues',
      },
      {
        value: 'pay_later_usage',
        label: 'Pay Later Usage',
      },
      {
        value: 'pay_later_to_credit_issues',
        label: 'Pay Later To Credit Issues',
      },
    ]

    if (isEmpty(month) || month === 'today') {
      defaultOptions.push({
        value: 'pay_later_balances',
        label: 'Pay Later Balances',
      })
    }

    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  useEffect(() => {
    const srToday = ['pay_later_balances']

    setReset(moment())

    if (month !== 'today') {
      if (srToday.includes(srType.value)) {
        setSrType(initialType)
      }
    }
  }, [filter])

  useEffect(() => {
    fetchWidgetsCounts()
    fetchBrandPieChart()
    fetchCustomerCategory()
    fetchBusinessOverviewChart()
  }, [filter])

  useEffect(() => {
    fetchTopSr()
  }, [filter, srType])

  useEffect(() => {
    fetchTopCustomer()
  }, [filter, customerType, isHoreca])

  const toolTip = (item: any) => {
    return `<div>
        <p>
          <b>${item.data.name}</b>
        </p>
        <div>
          <b>${numberFormatWithCode(item.data.total_payable_amount).displayFormat}</b>
        </div>
      </div>`
  }

  const toolTipCategory = (item: any) => {
    return `<div>
        <p>
          <b>${item.data.name}</b>
        </p>
        <div>
          <b>${numberFormatWithCode(item.data.value).displayFormat}</b>
        </div>
      </div>`
  }

  return (
    <>
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          {targetPermission.read || targetPermission.write ? (
            <a href='/targets' target='_blank'>
              <EaStatisticsWidget
                className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                color='info'
                title={`${numberFormat(widget.achieved_targets).displayFormat} / ${
                  numberFormat(widget.total_targets).displayFormat
                }`}
                description={'Target Achieved'}
                label='Current Month'
                labelWidth='w-110px'
              />
            </a>
          ) : (
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='info'
              title={`${numberFormat(widget.achieved_targets).displayFormat} / ${
                numberFormat(widget.total_targets).displayFormat
              }`}
              description={'Target Achieved'}
              label='Current Month'
              labelWidth='w-110px'
            />
          )}
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          {productWiseReportPermission.read || productWiseReportPermission.write ? (
            <a href='/reports/product-skus-report' target='_blank'>
              <EaStatisticsWidget
                className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                color='info'
                title={widget.total_skus.toString()}
                description={`Total SKUs in ${
                  numberFormat(widget.total_product_groups).displayFormat
                } Product Groups`}
                label='Lifetime'
              />
            </a>
          ) : (
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='info'
              title={widget.total_skus.toString()}
              description={`Total SKUs in ${
                numberFormat(widget.total_product_groups).displayFormat
              } Product Groups`}
              label='Lifetime'
            />
          )}
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={widget.total_customers.toString()}
            description={`Total Customers in ${
              numberFormat(widget.total_categories).displayFormat
            } Categories`}
            label='Lifetime'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={numberFormatWithCode(widget.total_open_credit_issues_amount).displayFormat}
            description={`Open Credit Issues ${
              numberFormat(widget.total_open_credit_issues_due_percentage, true).displayFormat
            }% Overdue`}
            label='Current'
          />
        </div>
      </div>
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='info'
            title={`Total Sales ${numberFormatWithCode(widget.total_sales_amount).displayFormat}`}
            description=''
            titleDescription={
              <div className='mt-3'>
                <div className={`card-title text-dark font-19 mb-0 d-block mb-3`}>
                  Casey Sales {numberFormatWithCode(widget.cashy_sales_amount).displayFormat}
                </div>

                <div className={`card-title text-dark font-19 mb-0 d-block`}>
                  Credit Sales {numberFormatWithCode(widget.credit_sales_amount).displayFormat}
                </div>
              </div>
            }
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={numberFormatWithCode(widget.total_approved_discount_amount).displayFormat}
            description={`Total Approved Discounts ${
              numberFormat(widget.total_approved_discount_percentage, true).displayFormat
            }% of Total Sales`}
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='dark'
            title={numberFormatWithCode(widget.total_open_payment_issues_amount).displayFormat}
            description={`Total Payment Issues ${
              numberFormat(widget.total_open_payment_issues).displayFormat
            }`}
          />
        </div>
      </div>

      {/* business overview */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Business Overview</span>
              </h3>
            </div>
            <div className='border-0 py-3 py-md-2 px-5 px-md-7'>
              {isChartLoading ? (
                <FetchingRecords />
              ) : barCharEmpty.length === 0 ? (
                <NoRecords />
              ) : (
                <EaChartBar {...barChart} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* brands and product overview */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>
                  Brands and Customers Categories Overview
                </span>
                <span className='text-muted font-14'>
                  Based on Sales amount in {tenantConfiguration.currencyCode}
                </span>
              </h3>
            </div>

            <div className='card-body'>
              {brandsChartLoading || customerLoading ? (
                <FetchingRecords />
              ) : brandPieChart.data.length === 0 && customerCategoryPieChart.data.length === 0 ? (
                <NoRecords />
              ) : (
                <div className='row'>
                  <div className='col-md-6'>
                    {brandPieChart.data.length === 0 ? (
                      <NoRecords />
                    ) : (
                      <EaChartPieNested
                        item={brandPieChart}
                        nestedData={nestedData}
                        tooltip={toolTip}
                        tooltipNested={toolTip}
                        onClick={(item: any) => {
                          if (item.data.brand_id) {
                            updateSelectedBrands(item)
                          }
                        }}
                        isAnimationDisable={true}
                      />
                    )}
                  </div>

                  <div className='col-md-6'>
                    {customerCategoryPieChart.data.length === 0 ? (
                      <NoRecords />
                    ) : (
                      <EaChartPie
                        tooltip={toolTipCategory}
                        item={customerCategoryPieChart}
                        isAnimationDisable={true}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        {/* Top 5 Sales Representatives */}
        <div className='col-xl-6'>
          <div className={`card card-xl-stretch mb-5`}>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <div>
                <h3 className='card-title align-items-start flex-column my-0 mt-3'>
                  <span className='card-label fw-bold font-17'>Top 5 Sales Representatives</span>
                </h3>
              </div>
              <div>
                <AsyncPaginate
                  loadOptions={fetchSrType}
                  isSearchable
                  key={reset}
                  className='react-select-container my-1 me-3 w-180px'
                  classNamePrefix='react-select'
                  placeholder='Sales'
                  onChange={setSrType}
                  value={srType}
                  isDisabled={srLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
            <div className='card-body py-0'>
              <div className='table-responsive'>
                {srLoading ? (
                  <FetchingRecords />
                ) : topSr.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered'>
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='py-2'>SR Name</th>
                        <th className='min-w-70px py-2'>
                          {srType && srType.value === 'gatepass_issues' ? 'Count' : 'Amount'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topSr.length > 0 &&
                        topSr.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <p className='font-14 mb-1'>{item.sr}</p>
                                <p className='font-13 mb-0  text-muted'>
                                  {!isEmpty(item.warehouse_name) ? item.warehouse_name : '-'}
                                </p>
                              </td>
                              <td>
                                {srType && srType.value === 'gatepass_issues'
                                  ? numberFormat(item.value).displayFormat
                                  : numberFormatWithCode(item.value).displayFormat}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Top 5 Customer */}
        <div className='col-xl-6'>
          <div className={`card card-xl-stretch mb-5`}>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <div>
                <h3 className='card-title align-items-start flex-column my-0 mt-3'>
                  <span className='card-label fw-bold font-17'>Top 5 Customers</span>
                </h3>
              </div>
              <div className='d-flex'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-2'>
                  <input
                    className='form-check-input widget-13-check'
                    name='isHoreca'
                    checked={isHoreca}
                    onChange={(e: any) => {
                      setIsHoreca(!isHoreca)
                    }}
                    type='checkbox'
                    disabled={false}
                    value=''
                    id='isHoreca'
                  />
                  <label className='form-check-label' htmlFor='isHoreca'>
                    HORECA Only
                  </label>
                </div>
                <AsyncPaginate
                  loadOptions={fetchCustomerType}
                  isSearchable
                  key={reset}
                  className='react-select-container my-1 me-3 w-180px'
                  classNamePrefix='react-select'
                  placeholder='Sales'
                  onChange={setCustomerType}
                  value={customerType}
                  isDisabled={customerLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
            <div className='card-body py-0'>
              <div className='table-responsive'>
                {customerLoading ? (
                  <FetchingRecords />
                ) : topCustomer.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered'>
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='py-2'>Customer Name</th>
                        <th className='min-w-70px py-2'>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topCustomer.length > 0 &&
                        topCustomer.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <p className='font-14 mb-1'>{item.customer}</p>
                                <p className='font-13 mb-0  text-muted'>
                                  {!isEmpty(item.warehouse_name) ? item.warehouse_name : '-'}
                                </p>
                              </td>
                              <td>{numberFormatWithCode(item.value).displayFormat}</td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    warehouseId: state.saDashboard.warehouseId,
    month: state.saDashboard.month,
  }
}

export default connect(mapStateToProps, null)(SMDashboard)
