import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {apiBaseURL, staticSearch} from '../../../../_eaFruitsDms/helpers/CommonHelpers'
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import SurveyFeedBackRow from './surveyFeedBackRow'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'

type surveyFormData = {
  survey_form_name: string
  access_to: string
  questions: string
}

const SurveyFeedback = () => {
  const navigate = useNavigate()
  const location: any = useLocation()

  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'Survey Forms',
    subTitle: 'Survey Forms',
  }

  const initialQuestionRow = {
    id: '',
    index: 0,
    question_title: '',
    validationQuestion: '',
    questionType: '',
    validationQuestionType: '',
    placeholder: 'Type here...',
    // useForAnalytics: false,
    // value: '',
    use_for_analytic: false,
    optionRequired: false,
    useForAnalyticsDisplay: false,
  }

  const initialFormData: surveyFormData = {
    survey_form_name: '',
    access_to: '',
    questions: '',
  }

  const [surveyQuestionsRows, setSurveyQuestionsRows] = useState<any>([initialQuestionRow])
  const [surveyFormDetail, setSurveyFormDetail] = useState<any>(null)
  const [surveyQuestionData, setSurveyQuestionData] = useState<surveyFormData>(initialFormData)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [validationForm, setValidationForm] = useState<string>('')
  const [validationPermission, setValidationPermission] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [pageTitle, setPageTitle] = useState<string>('Create Survey Feedback Form')
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (location.state) {
      setPageTitle('Update Survey Feedback Form')
      apiCall().then()
    }
  }, [])

  let defaultOptions: any = [
    {
      value: 'Tele Sales',
      label: 'Tele Sales',
    },
    {
      value: 'Customers',
      label: 'Customers',
    },
    {
      value: 'Sales Reps',
      label: 'Sales Reps',
    },
    {
      value: 'Marketing Officer',
      label: 'Marketing Officer',
    },
  ]

  const formPermissionOptions: any = (search: any) => {
    let options: any = []
    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }
  const questionTypeOptions: any = (search: any) => {
    let options: any = []

    let defaultOptionsQuestionType: any = [
      {
        value: 'text_box',
        label: 'Textbox',
      },
      {
        value: 'text_area',
        label: 'Textarea',
      },
      {
        value: 'single_choice',
        label: 'Single Choice',
      },
      {
        value: 'multiple_choice',
        label: 'Multiple Choice',
      },
      {
        value: 'star_rating',
        label: 'Star Rating',
      },
    ]

    if (search) {
      options = staticSearch(defaultOptionsQuestionType, search)
    } else {
      options = defaultOptionsQuestionType
    }

    return {
      options: options,
      hasMore: false,
    }
  }
  const apiCall = async () => {
    const surveyQuestionFormDetailAPI = `${apiBaseURL()}surveyForms/form/${location.state.id}`
    setDisplayLoader(true)

    const res = await getRequest(surveyQuestionFormDetailAPI, true)

    const options: any = []

    if (res) {
      defaultOptions.map((permission: any) => {
        if (res.access_to.includes(permission.value)) {
          options.push(permission)
        }
      })
    }
    res['options'] = options

    if (res.questions) {
      if (res.questions.length !== 0) {
        let updatedForm: any = []

        res.questions.map((option: any) => {
          if (['single_choice', 'multiple_choice', 'star_rating'].includes(option.question_type)) {
            option['useForAnalyticsDisplay'] = true
          }
          updatedForm.push(option)
        })

        setSurveyQuestionsRows(updatedForm)
      }
    }

    const defaultFormData: any = {
      survey_form_name: res.survey_form_name,
      access_to: res.access_to,
    }

    setDisplayLoader(false)
    setSurveyQuestionData(defaultFormData)
    setSurveyFormDetail(res)
  }

  const addRow = (currentIndex: number) => {
    const updatedRows = [...surveyQuestionsRows]

    if (updatedRows[currentIndex].question_title === '') {
      updatedRows[currentIndex]['validationQuestion'] = 'is-invalid'
    } else {
      initialQuestionRow.index = surveyQuestionsRows.length

      updatedRows.push(initialQuestionRow)
    }
    setSurveyQuestionsRows(updatedRows)
  }

  const removeRow = (index: number, questionId: any) => {
    if (questionId !== '') {
      setDisplayLoader(true)
      const url = `${apiBaseURL()}surveyForms/question/${questionId}`
      deleteRequest(url, true)
        .then((response) => {
          if (response) {
            apiCall().then()
          }
        })
        .catch(() => setDisplayLoader(false))
    }

    if (surveyQuestionsRows.length > 0) {
      const updatedRows = [...surveyQuestionsRows]

      let newRows: any = []

      updatedRows.forEach((item: any, indexNew: any) => {
        if (indexNew !== index) {
          newRows.push(item)
        }
      })

      setSurveyQuestionsRows(newRows)
    }
  }

  // updated by : Kashyap Trivedi
  // remove code old
  // const removeRow = (index: number) => {
  //   if (surveyQuestionsRows.length > 0) {
  //     let indexToRemove = surveyQuestionsRows.findIndex((x: any) => x.index == index)

  //     if (indexToRemove > -1) {
  //       let updatedRows: any = []

  //       surveyQuestionsRows.forEach((row: any, index: any) => {
  //         if (index !== indexToRemove) {
  //           if (index > indexToRemove) {
  //             row.index = row.index - 1
  //           }
  //           updatedRows.push(row)
  //         }
  //       })
  //       setSurveyQuestionsRows(updatedRows)
  //     }
  //   }
  // }

  const handleChange = (e: any) => {
    if (e.target) {
      if (e.target.name === 'survey_form_name') {
        setApiErrorMessage('')
      }
      setSurveyQuestionData({...surveyQuestionData, [e.target.name]: e.target.value})
      setSurveyFormDetail({...surveyFormDetail, [e.target.name]: e.target.value})
      setValidationForm('')
    } else {
      let selectedAccess = ''
      e.map((value: any, index: number) => {
        selectedAccess += index === 0 ? value.value : ',' + value.value
        return selectedAccess
      })

      if (surveyFormDetail && surveyFormDetail.options) {
        surveyFormDetail.options = e
      }
      setSurveyFormDetail(surveyFormDetail)
      setSurveyQuestionData({...surveyQuestionData, access_to: selectedAccess})
      setSurveyFormDetail({...surveyFormDetail, access_to: selectedAccess})
      setValidationPermission('')
    }
    setErrorMessage('')
  }

  const handleChangeRow = (e: any, index: number) => {
    const rows = [...surveyQuestionsRows]
    setErrorMessage('')
    const inputName = e.target ? e.target.name : 'questionType'
    const inputValue = e.target ? e.target.value : e.value

    if (e.value) {
      if (
        inputValue === 'single_choice' ||
        inputValue === 'multiple_choice' ||
        inputValue === 'star_rating'
      ) {
        rows[index].useForAnalyticsDisplay = true
      } else {
        rows[index].useForAnalyticsDisplay = false
      }
    }

    if (inputName === 'use_for_analytic') {
      rows[index]['use_for_analytic'] = e.target.checked
      // rows[index].useForAnalyticsDisplay = true
    } else {
      rows[index][inputName] = inputValue
    }

    if (inputName === 'questionType') {
      rows[index]['question_type'] = inputValue
      rows[index]['placeholder'] = 'Type here...'
      rows[index].requireOption = false
      rows[index].option_text = ''

      if (inputValue === 'single_choice' || inputValue === 'multiple_choice') {
        rows[index]['placeholder'] = 'Select all appropriate answers'

        if (inputValue === 'single_choice') {
          rows[index]['placeholder'] = 'Select one appropriate answer'
        }

        rows[index].requireOption = true
      }

      if (inputValue === 'star_rating') {
        rows[index]['placeholder'] = '1 Star : Bad | 5 Stars : Excellent'
      }
    }

    if (inputName === 'question_title') {
      if (inputValue.trim() !== '') {
        rows[index].question_title = inputValue
        rows[index].validationQuestion = ''
      } else {
        rows[index].validationQuestion = 'is-invalid'
      }
    }

    setSurveyQuestionsRows(rows)
  }

  const submitQuestionForm = () => {
    if (surveyQuestionsRows.length === 0) {
      alert("You've to add some survey questions.!")
      return false
    }

    let isValid: boolean = true
    const updatedQuestion: any = [...surveyQuestionsRows]
    surveyQuestionsRows.map((row: any, index: number) => {
      updatedQuestion[index]['question_title_id'] = `question_title_${index}`
      updatedQuestion[index]['questionType_id'] = `questionType_${index}`

      let question_title = `question_title_${index}`
      let questionType = `questionType_${index}`

      // let navigateField = {
      //   name: surveyQuestionData.survey_form_name,
      //   access_to: surveyQuestionData.access_to,
      //   [question_title]: updatedQuestion[index]['question_title'],
      //   [questionType]: updatedQuestion[index]['questionType'],
      // }

      // isModalValidation(navigateField).length !== 0 &&
      //   focusVatidateField(isModalValidation(navigateField)[0])

      updatedQuestion[index]['validationQuestion'] = ''
      updatedQuestion[index]['validationQuestionType'] = ''

      if (updatedQuestion[index]['question_title'] == '') {
        updatedQuestion[index]['validationQuestion'] = 'is-invalid'
        setErrorMessage(
          'Please make sure you have filled out all the fields marked with an asterisk (*)'
        )
        isValid = false
      } else {
        updatedQuestion[index]['validationQuestion'] = ''
      }

      if (updatedQuestion[index]['questionType'] == '') {
        updatedQuestion[index]['validationQuestionType'] = 'is-invalid'
        setErrorMessage(
          'Please make sure you have filled out all the fields marked with an asterisk (*)'
        )
        isValid = false
      }
    })

    setValidationForm('')
    if (surveyQuestionData.survey_form_name === '') {
      setValidationForm('is-invalid')
      setErrorMessage(
        'Please make sure you have filled out all the fields marked with an asterisk (*)'
      )
      isValid = false
    }

    setValidationPermission('')
    if (surveyQuestionData.access_to === '') {
      setValidationPermission('is-invalid')
      setErrorMessage(
        'Please make sure you have filled out all the fields marked with an asterisk (*)'
      )
      isValid = false
    }

    if (!isValid) {
      setSurveyQuestionsRows(updatedQuestion)
      return false
    }

    const questions: any = []
    surveyQuestionsRows.map((row: any, index: number) => {
      questions.push({
        question_title: row.question_title,
        question_type: row.questionType ? row.questionType : row.question_type,
        required: true,
        ...(row.option_text !== '' && {option_text: row.option_text}),
        placeholder: row.placeholder,
        use_for_analytic: row.use_for_analytic,
        order: index + 1,
      })
    })

    const finalSurveyData: any = {...surveyQuestionData}

    // finalSurveyData.questions = JSON.stringify(questions)
    finalSurveyData.questions = questions

    setDisplayLoader(true)
    setIsSubmitting(true)
    if (location.state) {
      const submitSurveyQuestionAPI = `${apiBaseURL()}surveyForms/form/${location.state.id}`

      putRequest(submitSurveyQuestionAPI, finalSurveyData, true).then((response: any) => {
        setDisplayLoader(false)
        setIsSubmitting(false)
        if (response.data && response.data.error) {
          navigate('/survey-forms', {
            state: {
              formName: surveyQuestionData.survey_form_name,
              type: 'errorInUpdate',
              status: false,
            },
          })
        }
        navigate('/survey-forms', {
          state: {
            formName: surveyQuestionData.survey_form_name,
            type: 'successEdit',
            status: true,
          },
        })
      })
    } else {
      const submitSurveyQuestionAPI = `${apiBaseURL()}surveyForms/form/create`

      postRequest(submitSurveyQuestionAPI, finalSurveyData, true).then((response: any) => {
        setDisplayLoader(false)
        setIsSubmitting(false)

        if (response.data && response.data.error) {
          if (response.data.error.code == 412) {
            setApiErrorMessage(
              'Your added survey form name already exist in this system! Please try again with a unique name.'
            )
          } else {
            navigate('/survey-forms', {
              state: {
                formName: surveyQuestionData.survey_form_name,
                type: 'errorInAdd',
                status: false,
              },
            })
          }
        } else {
          navigate('/survey-forms', {
            state: {
              formName: surveyQuestionData.survey_form_name,
              type: 'successAdd',
              status: true,
            },
          })
        }
      })
    }
  }

  const cancelHandler = () => {
    navigate('/survey-forms')
  }

  return (
    <>
      <div>
        <PageTitle breadcrumbs={[adminHeaderTitle]} backNavigate='/survey-forms'>
          {intl.formatMessage({id: 'Survey Forms'})}
        </PageTitle>

        <div className='card'>
          {displayLoader && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
            {/* begin::Title */}
            <h3 className='card-title align-items-start flex-column my-0'>
              <span className='card-label fw-bold font-17'>{pageTitle}</span>
            </h3>
            {/* end::Title */}
          </div>
          {/* <div > */}
          <form className='card-body pt-1' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <div className='row'>
              <div className='col-md-5 mb-4'>
                <label className='form-label'>
                  Survey Feedback Form Name<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  id='name'
                  name='survey_form_name'
                  className={clsx(
                    'form-control',
                    apiErrorMessage != '' ? 'is-invalid' : validationForm
                  )}
                  value={surveyFormDetail && surveyFormDetail.survey_form_name}
                  placeholder='Type here...'
                  onChange={handleChange}
                />
                {apiErrorMessage && <p className='text-danger'>{apiErrorMessage}</p>}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-5 mb-4'>
                <label className='form-label' id='access_to'>
                  Form Permissions<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  loadOptions={formPermissionOptions}
                  isSearchable
                  placeholder='Select'
                  isMulti
                  className={clsx('react-select-container', validationPermission)}
                  classNamePrefix='react-select'
                  value={surveyFormDetail && surveyFormDetail.options}
                  name='access_to'
                  onChange={(e: any) => handleChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
            {surveyQuestionsRows.length > 0 &&
              surveyQuestionsRows.map((row: any, index: number): any => {
                if (row) {
                  return (
                    <SurveyFeedBackRow
                      surveyQuestionsRows={surveyQuestionsRows}
                      questionTypeOptions={questionTypeOptions}
                      index={index}
                      isLastRow={surveyQuestionsRows.length === index + 1}
                      addRow={addRow}
                      removeRow={removeRow}
                      handleChange={handleChangeRow}
                      id={row.id ? row.id : ''}
                    />
                  )
                }
              })}
            <div className='mb-3 mt-4'>
              <ErrorHandler errorMessage={errorMessage} />
            </div>
            <div className='pt-4 border-top'>
              <button
                type='button'
                disabled={isSubmitting}
                className='btn btn-secondary me-3'
                onClick={cancelHandler}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary'
                disabled={isSubmitting}
                onClick={submitQuestionForm}
              >
                {isSubmitting ? 'Please wail...' : 'Submit'}
              </button>
            </div>
          </form>
          {/* </div> */}
        </div>
      </div>
    </>
  )
}

export default SurveyFeedback
