import { useEffect } from "react"
import {
    permissionEditDelete,
	permissionEditRead,
	permissionEditWrite,
	rolePermissionEditDelete,
	rolePermissionEditRead,
	rolePermissionEditWrite
} from "../../../../../_eaFruitsDms/apiFunctions/rolesAndPermission/RoleAndPermission"
import { KTSVG } from "../../../../../_eaFruitsDms/helpers"

const TableWarehouseRole = (props: any) => {
    const { roleList, featureList, getProps , showWrite } = props

    const checkedPermissionRead = (checkedRead: any, rolesIdRead: any) => {
        permissionEditRead(checkedRead, rolesIdRead)
    }

    const checkedPermissionWrite = (checkedWrite: any, rolesIdWrite: any) => {
        permissionEditWrite(checkedWrite, rolesIdWrite)
    }             
    
    const checkedPermissionDelete = (checkedDelete: any, rolesIdDelete: any) => {
        permissionEditDelete(checkedDelete, rolesIdDelete)
    } 

    return (
        <>
            <table className='table table-row-bordered align-middle gs-0 gy-2'>
                <thead>
                    <tr className=' text-muted fw-500'>
                        <th className='min-w-250px py-2'>Feature Access</th>
                        {
                            roleList.map((item: any) => (
                                <th key={item.id} className='min-w-190px py-2'>{item.name}
                                {showWrite && 
                                    <span className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                    <a
                                        href='#'
                                        data-bs-toggle='modal'
                                        data-bs-target='#ea_modal_warehouserole'
                                        onClick={() => getProps(item)}
                                    >
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                    </a>
                                </span>
                                } 
                            </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        featureList.map((main_item: any) => {
                            let featureId = main_item.feature_id
                            return (
                                <tr key={featureId}>
                                    <td>{main_item.display_name}</td>
                                    {
                                        main_item.roles.map((roles_item: any) => {
                                            let rolesIdRead = ""
                                            let rolesIdWrite = ""
                                            let rolesIdDelete = ""
                                            let read = false
                                            let write = false
                                            let del = false

                                     roles_item.role_feature_permissions.length > 0 && roles_item.role_feature_permissions.map((item : any) =>{
                                           if(item.permission_type === "read"){
                                                read  = item.value
                                                rolesIdRead = item.role_feature_permissions_id
                                            }
                                           if(item.permission_type === "write"){
                                                write  = item.value
                                                rolesIdWrite = item.role_feature_permissions_id
                                            }
                                            if(item.permission_type === "delete"){
                                                del  = item.value
                                                rolesIdDelete = item.role_feature_permissions_id
                                            }
                                        })

                                        let checkedRead: any;
                                        let checkedWrite: any;
                                        let checkedDelete: any;
                                        let checkboxUniqId = featureId + "_" + rolesIdRead 
  
                                            return (
                                                <td key={roles_item.id}>
                                                    <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                        <input className='form-check-input widget-13-check me-1' type='checkbox' id={checkboxUniqId + "_read"}
                                                            disabled={props.showWrite  ? false : true}
                                                            checked={checkedRead}
                                                            defaultChecked={read}
                                                            onChange={(e) => {
                                                                checkedPermissionRead(e.target.checked, rolesIdRead)
                                                                checkedRead = e.target.checked
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor={checkboxUniqId + "_read"}>Read</label>
                                                    </div>

                                                    <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                        <input className='form-check-input widget-13-check me-1' type='checkbox'
                                                           disabled={!main_item.feature_name.includes("impersonation") && props.showWrite ? false : true}
                                                           id={checkboxUniqId + "_write"}
                                                            checked={checkedWrite}
                                                            defaultChecked={write}
                                                            onChange={(e) => {
                                                                checkedPermissionWrite(e.target.checked, rolesIdWrite)
                                                                checkedWrite = e.target.checked
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor={checkboxUniqId + "_write"}>Write</label>
                                                    </div>
                                                    <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                        <input className='form-check-input widget-13-check me-1' type='checkbox'
                                                            disabled={!main_item.feature_name.includes("impersonation") && props.showWrite ? false : true}
                                                            id={checkboxUniqId + "_delete"}
                                                            checked={checkedDelete}
                                                            defaultChecked={del}
                                                            onChange={(e) => {
                                                                checkedPermissionDelete(e.target.checked, rolesIdDelete)
                                                                checkedDelete = e.target.checked
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor={checkboxUniqId + "_delete"}>Delete</label>
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default TableWarehouseRole
