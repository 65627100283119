/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getUserType} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'

import VehicalReport from './vehicalReport'
import WMVehicalReport from './vehicalReportWM'
import LOVehicalReport from './vehicalReportLO'

const superAdmin = localStorage.getItem('superAdmin')
const w_manager = localStorage.getItem('w_manager')
const OO = localStorage.getItem('OO')

type Props = {
  className: string
}
const VehicleReportWrapper: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Vehicle Usage Report',
  }
  return (
    <>
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({id: 'Vehicle Usage Report'})}
      </PageTitle>
      <WMVehicalReport className='wm-vehicle' />
    </>
  )
}

export {VehicleReportWrapper}
