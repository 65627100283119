import React from "react"
import { useNavigate } from "react-router-dom"
import { KTSVG } from "../../../../../_eaFruitsDms/helpers"
import EaButton from "../../../../../_eaFruitsDms/layout/components/button/eaButton"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"

const TableRegions = (props: any) => {
    const navigate = useNavigate();
    return (
        <>
           <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-1'>
                    <thead>
                        <tr className=' text-muted fw-500'>
                            <th className='min-w-150px'>Region Name</th>
                            <th className='min-w-150px'>Districts</th>
                            <th className='min-w-150px'>KML Fileeee</th>
                            {props.showWrite || props.showDelete ? <th className='min-w-150px text-end'>Action</th> : <th className='min-w-150px text-end'></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {props.tableList.map((item: any) => (
                            <tr key={item.id} >
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.district}
                                </td>
                                <td>
                                    {item.kml_file === null ? "Not Updated" : "Updated"}
                                </td>
                                <td className='text-end'>
                                    {props.showWrite &&
                                        <>
                                            {item.kml_file === null ?
                                                <EaButton isModal={true}
                                                    onClick={() => props.getKmlProps(item)}
                                                    dataTarget="#ea_modal_kml_region"
                                                    btnName='Add KML File' className='btn btn-primary btn-sm me-4 font-13' /> : null}
                                            <button
                                                onClick={() => props.getDistrictProps(item)}
                                                data-bs-toggle='modal'
                                                data-bs-target='#ea_modal_District_region'
                                                className="btn btn-primary btn-sm me-4 font-13" >Add District</button>
                                        </>}
                                    {props.showWrite &&
                                        <span
                                            onClick={() => navigate("/master/distribution-boundaries/regionname", {
                                                state: item
                                            })}
                                            className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-2'
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </span>}
                                    {/* <a href='#' className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                    </a> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </>
    )
}

export default TableRegions
