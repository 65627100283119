// admin -> /customers TODO: For our reference do not remove this comment

import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiBaseURL,
  displayAddedBy,
  formatVerificationType,
  KTSVG,
  staticSearch,
  toAbsoluteUrl,
  verificationPending
} from "../../../_eaFruitsDms/helpers";
import useDebounce from "../../../_eaFruitsDms/helpers/components/useDebounce";
import { getRequest } from "../../../_eaFruitsDms/https/apiCall";
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader";
import EaModal from "../../../_eaFruitsDms/layout/components/modal/eaModal";
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import {
  OrderingArrowDown,
  OrderingArrowUp
} from "../../../_eaFruitsDms/layout/components/orderingArrow/orderingArrow";
import CustomSearchBar from "../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../_eaFruitsDms/layout/core";
import tenantConfiguration from "../../../TenantVariables";
import { categoryStyle } from "../../../_eaFruitsDms/helpers/components/categoryStyle";
import { fetchUserTypes } from "./WMcustomers/CustomerConst";
import CustomPaginate from "../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import moment from "moment/moment";

const Regionoption = [
  { value: 'all', label: 'All Status' },
  { value: 'ongoing', label: 'Ongoing' },
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'Past', label: 'Past' },
]

type Props = {
  className: string
}

const CustomerPage: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'Customers',
    subTitle: 'Customers',
  }

  const initialOrderKeys = {
    Id: true ,
    Name: true,
    StreetName: true,
    Category: true,
    Type: true,
    CreditRange: true,
  }

  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [customers, setCustomers] = useState<any>([])
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [warehouseId, setWarehouseId] = useState<any>('')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [categoryId, setCategoryId] = useState<any>('')
  const [typeId, setTypeId] = useState<any>('')
  const [searchByName, setSearchByName] = useState<string>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [warehouseOffSet, setWarehouseOffSet] = useState<number>(0)
  const [categories, setCategories] = useState<any>([])
  const [warehouses, setWarehouses] = useState<any>([])
  const [ordering, setOrdering] = useState('')
  const [orderColumnKey, setOrderColumnKey] = useState('')
  const [orderKey, setOrderKey] = useState(initialOrderKeys)
  const [userOffset, setUserOffset] = useState('');
  const [userType, setUserType] = useState<any>('')
  const [userId, setUserId] = useState<any>('')
  const [userReset, setUserReset] = useState<any>(moment());
  const [users, setUsers] = useState<any>([])

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchCustomers().then()
  }, [
    currentOffset,
    debounceSearch,
    warehouseId,
    categoryId,
    typeId,
    ordering,
    userType,
    userId
  ])

  useEffect(() => {
    setUserOffset('')
  }, [searchSelect])

  const fetchCustomers = async () => {
    setDisplayLoader(true)

    const customersResponse = await getRequest(
      `${apiBaseURL()}customers?limit=${limit}&offset=${currentOffset}&ordering=${ordering}&search=${debounceSearch}&warehouse_id=${warehouseId}&category_ids=${categoryId}&type=${typeId.toLowerCase()}&reg_by_type=${userType}&reg_by_id=${userId !== '' ? userId.value : ''}`,
      true
    )
    let customerList: any = []
    if (customersResponse.results && customersResponse.results.length > 0) {
      customersResponse.results.map((customer: any) => {
        const verificationStatus = verificationPending(customer)
        customer.pending = verificationStatus.pending
        customer.verification_type = verificationStatus.type

        let customerInfo: any = { ...customer }
        customerList.push(customerInfo)
      })
    }
    setCustomers(customerList)
    setCount(customersResponse.count)
    setNext(customersResponse.next)
    setPrev(customersResponse.previous)
    setDisplayLoader(false)
  }

  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const customerTypes: any = [
    {
      value: '',
      label: 'All Types',
    },
    {
      label: 'Wholesaler',
      value: 'Wholesaler',
    },
    {
      label: 'Retailer',
      value: 'Retailer',
    },
  ]

  const fetchCustomerTypes = async (search?: any) => {
    let options = customerTypes

    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchUsers = async (search?: any) => {
    //auth/users/team/list?offset=0&warehouse__id=6&type=sr
    const options: any = []
    let hasMore: boolean = false
    
    if (userType !== "") {
      if (search !== "") {
        setSearchSelect(search)
      }

      const wId: any = (!['ts'].includes(userType)) ? warehouseId : ''

      const response: any = await getRequest(`${apiBaseURL()}auth/users/team/list?search=${searchSelect}&type=${userType}&limit=${limit}&offset=${userOffset}&ordering=first_name&warehouse__id=${wId}`, true)

      if (!response.results) {
        hasMore = false
      } else {
        if (response.next !== null) {
          const queryParams = new URLSearchParams(response.next)
          let newOffset: any = queryParams.get('offset')
          setUserOffset(newOffset)
          hasMore = true
        }

        if (users.length === 0) {
          options.push({
            label: 'All Users',
            value: '',
          })
        }

        if (response.results.length > 0) {
          response.results.map((option: any) => {
            options.push({
              label: option.first_name + " " + option.last_name + " (" + option.type.toUpperCase() + ")",
              value: option.id,
            })
          })
        }

        if (users.length > 0) {
          setUsers(users.concat(options))
        } else {
          setUsers(options)
        }
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchCategories = async (search?: any) => {
    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/customer-category-parent/list/`,
      true
    )

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    const categoryKeys = Object.keys(categoriesResponse)

    categoryKeys.forEach((value) => {
      let optionId: string = ''

      options.push({
        label: value,
        value: optionId,
        color: '#6d6e6f',
        bg: '#f7f7f7',
        transform: 'uppercase',
        boxSizing: 'borderBox',
        weight: '500',
        cursor: 'default',
        size: '.75rem !important',
      })

      categoriesResponse[value].map((option: any) => {
        if (optionId === '') {
          optionId += option.id
        } else {
          optionId += ',' + option.id
        }
        options.push({
          label: option.category,
          value: option.id,
        })
        return options
      })

      let index = options.findIndex((x: any) => x.label === value)
      options[index].value = optionId
    })

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options: options,
      hasMore: false,
    }
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'warehouse':
        setWarehouseId(inputValue)
        setUserReset(moment())
        setUserOffset('')
        break
      case 'category':
        setCategoryId(inputValue)
        break
      case 'type':
        setTypeId(inputValue)
        setUserReset(moment())
        break
      case 'user-type':
        setUserType(inputValue)
        setUserOffset('')
        setUserReset(moment())
        setUserId('')
        break
      case 'users':
        setUserId(e)
        break
      default:
        setCurrentOffset(0)
        setSearchByName(inputValue)
    }
  }

  const goToOverview = (cId: any, type: any) => {
    localStorage.setItem('cId', cId)
    localStorage.setItem('type', type)
    navigate('singlecustomer/overview')
  }

  const handleOrdering = (key: any) => {
    setOrderColumnKey(key)
    if (key === "Id") {
      setOrderKey({ ...initialOrderKeys, Id: !orderKey.Id })
    }
    if (key === 'Name') {
      setOrderKey({ ...initialOrderKeys, Name: !orderKey.Name })
    }
    if (key === 'StreetName') {
      setOrderKey({ ...initialOrderKeys, StreetName: !orderKey.StreetName })
    }
    if (key === 'Category') {
      setOrderKey({ ...initialOrderKeys, Category: !orderKey.Category })
    }
    if (key === 'Type') {
      setOrderKey({ ...initialOrderKeys, Type: !orderKey.Type })
    }
    if (key === 'CreditRange') {
      setOrderKey({ ...initialOrderKeys, CreditRange: !orderKey.CreditRange })
    }
  }

  useEffect(() => {
    if (orderColumnKey === "Id") {
      if (orderKey.Id) {
        setOrdering('id')
      } else {
        setOrdering('-id')
      }
    }

    if (orderColumnKey === 'Name') {
      if (orderKey.Name) {
        setOrdering('business_name')
      } else {
        setOrdering('-business_name')
      }
    }

    if (orderColumnKey === 'StreetName') {
      if (orderKey.StreetName) {
        setOrdering('street')
      } else {
        setOrdering('-street')
      }
    }

    if (orderColumnKey === 'Category') {
      if (orderKey.Category) {
        setOrdering('category')
      } else {
        setOrdering('-category')
      }
    }

    if (orderColumnKey === "CreditRange") {
      if (orderKey.CreditRange) {
        setOrdering('credit_range')
      } else {
        setOrdering('-credit_range')
      }
    }

    if (orderColumnKey === 'Type') {
      if (orderKey.Type) {
        setOrdering('type')
      } else {
        setOrdering('-type')
      }
    }
  }, [orderColumnKey, orderKey])

  return (
    <>
      <EATitle title='Customers' />
      <div>
        <PageTitle breadcrumbs={[adminHeaderTitle]}>
          {intl.formatMessage({ id: 'Customers' })}
        </PageTitle>
        <div className='card'>
          {displayLoader && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={handleChange} />
            <div className='ms-md-auto'>
              {/* <div className='card-toolbar d-flex my-0' > */}
              <form className='card-toolbar d-flex my-0' onSubmit={(e) => e.preventDefault()}>
                <AsyncPaginate
                  loadOptions={fetchWarehouses}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  placeholder='All Warehouses'
                  value={warehouses.find((option: any) => option.value === warehouseId)}
                  onChange={(e: any) => handleChange(e, 'warehouse')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCategories}
                  isSearchable
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  placeholder='All Categories'
                  styles={categoryStyle}
                  name='categories'
                  value={categories.filter((option: any) => option.value === categoryId)}
                  onChange={(e: any) => handleChange(e, 'category')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCustomerTypes}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Types'
                  // value={types.find((option: any) => option.value === typeId)}
                  onChange={(e: any) => handleChange(e, 'type')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchUserTypes}
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='Added By ...'
                  // value={types.find((option: any) => option.value === typeId)}
                  onChange={(e: any) => handleChange(e, 'user-type')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {
                  userType !== "" && <AsyncPaginate
                    key={userReset}
                    loadOptions={fetchUsers}
                    className='react-select-container my-1 me-3 w-200px'
                    classNamePrefix='react-select'
                    placeholder='All Users'
                    onChange={(e: any) => handleChange(e, 'users')}
                    value={userId || ''}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                }
              </form>
              {/* </div> */}
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {customers.length == 0 ? (
                <>
                  <NoRecords />
                </>
              ) : (
                <>
                  <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='text-muted fw-500'>
                      <th className='min-w-60px'>ID
                       <span onClick={() => handleOrdering("Id")}>
                         {orderKey.Id ? <OrderingArrowUp /> : <OrderingArrowDown />}
                      </span>
                        </th>
                        <th className='min-w-160px'>
                          Name
                          <span onClick={() => handleOrdering('Name')}>
                            {orderKey.Name ? <OrderingArrowUp /> : <OrderingArrowDown />}
                          </span>
                        </th>
                        <th className='min-w-130px'>
                          Street Name
                          <span onClick={() => handleOrdering('StreetName')}>
                            {orderKey.StreetName ? <OrderingArrowUp /> : <OrderingArrowDown />}
                          </span>
                        </th>
                        <th className='min-w-170px'>Contact Info</th>
                        <th className='min-w-120px'>
                          Category
                          <span onClick={() => handleOrdering('Category')}>
                            {orderKey.Category ? <OrderingArrowUp /> : <OrderingArrowDown />}
                          </span>
                        </th>
                        <th className='min-w-120px'>
                          Type
                          <span onClick={() => handleOrdering('Type')}>
                            {orderKey.Type ? <OrderingArrowUp /> : <OrderingArrowDown />}
                          </span>
                        </th>
                        <th className='min-w-150px'>Warehouse Name</th>
                        <th className='min-w-160px'>Added By</th>
                        <th className='min-w-100px'>Verification</th>
                        <th className='min-w-140px'>
                          Credit Range
                          <span onClick={() => handleOrdering('CreditRange')}>
                            {orderKey.CreditRange ? <OrderingArrowUp /> : <OrderingArrowDown />}
                          </span>
                        </th>
                        <th className='min-w-120px'>Total Sales</th>
                        <th className='min-w-120px'>Credit Issue</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {customers.map((customer: any) => {
                        return (
                          <>
                            <tr
                              onClick={() => goToOverview(customer.id, 'SA')}
                              className='cursor-pointer'
                            >
                               <td>
                              <div className='media-body'>
                                <p className='m-0 xs-font'>#{customer.id} </p>
                              </div>
                            </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <span
                                    className='rounded-circle pro-icon me-2'
                                    data-bs-toggle='tooltip'
                                    title={customer.business_name}
                                  >
                                    {customer.logo_thumbnail === null ? (
                                      <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} alt='' className="rounded-circle pro-icon" />
                                    ) : (
                                      <img src={customer.logo_thumbnail} alt='' className="rounded-circle pro-icon" />
                                    )}
                                  </span>
                                  <div className='media-body'>
                                    <p className='m-0 xs-font'>{customer.business_name}</p>
                                  </div>
                                </div>
                              </td>
                              <td>{customer.street}</td>
                              <td>
                                <div>
                                  <div className='mb-1'>
                                    {tenantConfiguration.baseCountryCode} {customer.contact_no}
                                  </div>
                                  {![null, ''].includes(customer.email) && (
                                    <div className='mb-1'>{customer.email ? customer.email : ''}</div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="text-capitalize">
                                  <div className='text-muted font-13 font-medium'>
                                    {customer.nature_of_business
                                      ? customer.nature_of_business
                                      : '-'}
                                  </div>
                                  {customer.category_name
                                    ? customer.category_name
                                    : '-'}
                                </div>
                              </td>
                              <td className="text-capitalize">{customer.type}</td>
                              <td>
                                {' '}
                                {customer.warehouse_name
                                  ? customer.warehouse_name
                                  : '-'}
                              </td>
                              <td>{displayAddedBy(customer.reg_by)}</td>
                              <td>
                                {customer && customer.pending == 0 ? (
                                  <>
                                    <span className='btn py-0 ps-1 pe-0 btn-sm me-3'>
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr012.svg'
                                        className='ic mr-0'
                                      />
                                    </span>
                                  </>
                                ) : (
                                  customer.verification_type.length > 0 && (
                                    <div className='d-flex align-items-center'>
                                      <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => (
                                          <Tooltip {...props} className='tooltip-dark'>
                                            <span>{formatVerificationType(customer.verification_type)}</span>
                                          </Tooltip>
                                        )}
                                        placement='top'
                                      >
                                        <span className='cursor-pointer text-decoration-underline'>
                                          {customer.pending} Pending
                                        </span>
                                      </OverlayTrigger>
                                    </div>
                                  )
                                )}
                              </td>
                              <td>{customer && customer.credit_range ? customer.credit_range : '-'}</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </>
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate 
          data={customers}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
        />
      </div>
      <EaModal id='ea_modal_create_customer' modalTitle='Add Customer'>
        {/* <Form> */}
        <div className='row'>
          <div className='col-sm-6 mb-4'>
            <label className='form-label'>Business Name</label>
            <input type='text' className='form-control' placeholder='Type here...' />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label'>Contact Person</label>
            <input type='text' className='form-control' placeholder='Type here...' />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label'>Phone Number</label>
            <input type='text' className='form-control' placeholder='Type here...' />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label'>Email</label>
            <input type='text' className='form-control' placeholder='Type here...' />
          </div>
        </div>
        <div className='mb-4'>
          <label className='form-label'>Street Address</label>
          <input type='text' className='form-control' placeholder='Type here...' />
        </div>
        <div className='row'>
          <div className='col-sm-6 mb-4'>
            <label className='form-label'>Region</label>
            <Select
              options={Regionoption}
              isSearchable
              className='react-select-container mb-4'
              classNamePrefix='react-select'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
        </div>

        {/* </Form> */}
      </EaModal>
    </>
  )
}

export default CustomerPage
