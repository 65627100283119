import clsx from "clsx"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { apiBaseURL, focusVatidateField, isModalValidation, toAbsoluteUrl, } from "../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { getRequest, patchRequest } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import CustomSunEditor from "../../../../_eaFruitsDms/layout/components/sunEditor/CustomSunEditor"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../_eaFruitsDms/layout/core"
import Imagecropper from "./imageCropper"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"

const cmsProducts: Array<PageLink> = [
  {
    title: 'CMS',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
]

const UpdateProducts = () => {
  const navigate = useNavigate()
  const productsId = localStorage.getItem('productsId')

  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'CMS',
  }

  const initialProducts = {
    product_name: '',
    description: '',
    cover_image: '',
  }
  const initialProductsValidation = {
    product_name: '',
    description: '',
    cover_image: '',
  }

  const [products, setProducts] = useState<any>(initialProducts)
  const [imageValidation, setImageValidation] = useState<boolean>(false)
  const [img, setImg] = useState<any>('')
  const [imageSize, setImageSize] = useState('')
  const [imageRequire, setImageRequire] = useState('')
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [cropData, setCropData] = useState(defaultSrc)
  const [imgSizeInvalid, setimgSizeInvalid] = useState('')
  const [limit, setLimit] = useState<number>(10)
  const [productsValidation, setProductsValidation] = useState<any>(initialProductsValidation)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [productsDetailInitial, setProductsDetailInitial] = useState<any>(initialProducts)
  const [imageRequireMessage, setImageRequireMessage] = useState('')
  const [isButtonLoader, setIsButtonLoader] = useState<any>('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [description, setDescription] = useState('')
  const [descriptionValidation, setDescriptionValidation] = useState('')
  const [isResonseLoading, setIsResponseLoading] = useState<any>('')

  useEffect(() => {
    fetchProductsDetail().then()
  }, [])

  useEffect(() => {
    setProducts(productsDetailInitial)
  }, [productsDetailInitial])

  // Develop BY: Kriti
  // Description: fetchDetail from get ID

  const fetchProductsDetail = async () => {
    setProducts(initialProducts)
    if (productsId != null) {
      setIsResponseLoading(true)
      let productsResponse = await getRequest(`${apiBaseURL()}cms/product/${productsId}/`, true)
      if (productsResponse) {
        let productsDetail: any = { ...productsDetailInitial }

        Object.entries(productsResponse).map((object: any) => {
          if (object[0] in productsDetail) {
            return (productsDetail[object[0]] = object[1])
          }
        })
        if (productsResponse.cover_image != null) {
          setCropData(productsResponse.cover_image)
        } else {
          setCropData(defaultSrc)
        }
        setProductsDetailInitial(productsDetail)
        setIsResponseLoading(false)
      }
    }
  }

  // Develop BY: Kriti
  // Description: Selected Image Details
  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.Jfif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImg(URL.createObjectURL(file))
        setProducts({ ...products, ['cover_image']: e.target.files[0] })
      }
      let img: any = ''
      if (e.target.files[0]) {
        setImageRequire('')
        setImageRequireMessage('')
        img = new Image()
        let objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setimgSizeInvalid('text-danger')
            setImageValidation(false)
            setImageRequireMessage('')
          } else {
            setimgSizeInvalid('')
            setImageValidation(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }
  const getImage = (val: any) => {
    setProducts({ ...products, ['cover_image']: val })
  }

  // Develop BY: Kriti
  // Description: function to obtain the target value

  const handleChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setProductsValidation({ ...productsValidation, [inputName]: '' })
    let updatedProducts: any = { ...products }

    switch (inputName) {
      case 'product_name':
        updatedProducts[inputName] = inputValue
        break
      case 'description':
        updatedProducts[inputName] = inputValue
        break
    }
    setProducts(updatedProducts)
  }

  const handleDescription = (e: any) => {
    setDescriptionValidation('')
    setDescription(e)
  }

  // Develop BY: Kriti
  // Description: function to verify the fileld

  const validateForm = () => {
    const validation = { ...products }
    let isValid = true

    let navigateField = {
      product_name: products.product_name,
      description: description,
      cover_image: products.cover_image,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (products.product_name === '') {
      validation.product_name = 'is-invalid'
      isValid = false
    }
    if (description === '' || description === '<p><br></p>') {
      setDescriptionValidation('is-invalid')
      isValid = false
    }

    if (products.cover_image === '' || imgSizeInvalid == 'text-danger') {
      setImageRequire('file-icon-inValid')
      if (imgSizeInvalid != 'text-danger') {
        setImageRequireMessage('file-icon-inValid')
      }
      validation.cover_image = 'is-invalid'
      isValid = false
    }
    setProductsValidation(validation)
    return isValid
  }

  // Develop BY: Kriti
  // Description: function to call post/put api
  const addProducts = () => {
    const isValid = validateForm()
    if (isValid) {
      setIsButtonLoader('Please Wait...')
      let productsData = new FormData()
      const productsInfo: any = { ...products }

      productsInfo.description = description

      Object.entries(productsInfo).map((object: any) => {
        if (object[0] == 'cover_image') {
          if (object[1] != null && typeof object[1] != 'string') {
            return productsData.append(object[0], object[1])
          }
        } else {
          return productsData.append(object[0], object[1])
        }
      })

      patchRequest(`${apiBaseURL()}cms/product/${productsId}/`, productsData, true).then((res) => {
        setIsButtonLoader('Submit')
        if (res.status === 201 || res.status === 200 || res.status === 204) {
          setIsAlertSuccess(true)
          setProducts(initialProducts)
          localStorage.setItem('type', 'edit')
          localStorage.removeItem('productsId')
          navigate('/cms/products')
        } else {
          setErrorMessage('Please enter valid url.')
        }
        setTimeout(() => {
          setIsAlertFailed(false)
          setIsAlertSuccess(false)
        }, 1500)
      })
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <div>
        <EATitle title='Edit Products for Website - ' />
        <PageTitle breadcrumbs={cmsProducts} backNavigate='/cms/products'>
          {intl.formatMessage({
            id: 'Edit Products for Website',
          })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={FormatMessage('success')}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className='card'>
        {isResonseLoading !== '' && isResonseLoading == true && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
            {/* begin::Title */}
            <h3 className='card-title align-items-start flex-column my-0'>
              <span className='card-label fw-bold font-17'>Edit Products for Website</span>
            </h3>
            {/* end::Title */}
          </div>
          <div className='card-body pt-4'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='mb-4'>
                  <label className='form-label'>
                    Product Group Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type='text'
                    id='product_name'
                    className={clsx('form-control', productsValidation.product_name)}
                    name='product_name'
                    value={products.product_name}
                    placeholder='Type here...'
                    onChange={(e: any) => handleChange(e, 'product_name')}
                  />
                </div>
                <div className='row'>
                  <div className='mb-4'>
                    <label className='form-label' id='description'>
                      Description<span className="text-danger">*</span>
                    </label>
                    <CustomSunEditor
                      data={products.description}
                      handleChange={handleDescription}
                      fieldName='description'
                      validationTriggered={descriptionValidation}
                      message='Description Field is required.'
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div id='cover_image'>
                  <Imagecropper
                    getImage={getImage}
                    onImageChange={onImageChange}
                    imageRequire={imageRequire}
                    imageRequireMessage={imageRequireMessage}
                    imageSize={imgSizeInvalid}
                    cropData={cropData}
                    setCrop={setCropData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer py-2'>
            <button
              type='button'
              className='btn btn-secondary me-3'
              onClick={() => {
                navigate('/cms/products')
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              disabled={isButtonLoader !== 'Submit'}
              onClick={() => {
                addProducts()
              }}
            >
              {isButtonLoader}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateProducts
