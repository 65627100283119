import React from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import { categoryStyle } from "../../../../_eaFruitsDms/helpers/components/categoryStyle";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

type Props = {
    className: string
}
const fetchBrands = async (search: any) => {
    let options: any = []
    options.push(
        {
            label: 'All Brand',
            value: 'all',
        },
        {
            label: 'Onja',
            value: 'onja',
        },
        { value: 'Golden-Banana', label: 'Golden Banana' })
    return {
        options: options,
        hasMore: false,
    }
}

const fetchCategories = async (search: any) => {
    let options: any = []
    options.push(
        {
            label: 'All Categories',
            value: '',
        },
        {
            label: "Grains",
            options: [
                { label: "Rices", value: "rices" },
                { label: "Beans", value: "beans" }
            ]
        },
        {
            label: "Fruits",
            options: [
                { label: "Fresh Produce", value: "Fruits" },
                { label: "Fresh Produce 2", value: "Fruits" }
            ]
        },)
    return {
        options: options,
        hasMore: false,
    }

}
const fetchProductsGroups = async (search?: any) => {
    let options: any = []
    options.push(
        {
            label: 'All Group',
            value: 'all',
        },
        {
            label: 'Rice',
            value: 'Rice',
        })
    return {
        options: options,
        hasMore: false,
    }
}
const StockTransactions: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Stock Transactions"
    }

    return (
        <>
            <EATitle title='Stock Transactions' />
            <PageTitle breadcrumbs={[reportHeaderTitle]}>{intl.formatMessage({ id: 'Stock Transactions' })}</PageTitle>
            <div className='card'>
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className='ms-auto'>
                        <div className='card-toolbar d-flex my-0'>
                            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0 d-flex me-3'>
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2020',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar" />
                                </DateRangePicker>
                            </div>
                            <AsyncPaginate
                    
                                loadOptions={fetchBrands}
                                isSearchable
                                className='react-select-container my-1 me-3 w-115px'
                                classNamePrefix='react-select'
                                placeholder='All Brands'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <AsyncPaginate
                    
                                loadOptions={fetchCategories}
                                isSearchable
                                className='react-select-container my-1 me-3 w-160px'
                                classNamePrefix='react-select'
                                placeholder='All Categories'
                                styles={categoryStyle}
                                name='categories'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <AsyncPaginate
                    
                                loadOptions={fetchProductsGroups}
                                isSearchable
                                className='react-select-container my-1 w-130px'
                                classNamePrefix='react-select'
                                placeholder='All Groups'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />

                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                            <thead>
                                <tr className=' text-muted fw-500'>
                                    <th className='min-w-110px py-2'>Batch Number</th>
                                    <th className='min-w-110px py-2'>Product SKU</th>
                                    <th className='min-w-100px py-2'>Brand</th>
                                    <th className='min-w-120px py-2'>Category</th>
                                    <th className='min-w-100px py-2'>Size</th>
                                    <th className='min-w-180px py-2'>Type</th>
                                    <th className='min-w-100px py-2'>Qty.</th>
                                    <th className='min-w-120px py-2'>Performed By</th>
                                    <th className='min-w-100px py-2'>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>#4808</td>
                                    <td>Onja Rice 1kg</td>
                                    <td>Onja</td>
                                    <td>
                                        <div>
                                            <div className='text-muted font-13 mb-1 font-medium'>
                                                Grains
                                            </div>
                                            Rice
                                        </div>
                                    </td>
                                    <td>
                                        1kg
                                    </td>
                                    <td>Warehouse Transfer Stock-In</td>
                                    <td>621</td>
                                    <td>Don Barker</td>
                                    <td>11 Jul 2022</td>
                                </tr>
                                <tr>
                                    <td>#4808</td>
                                    <td>Onja Rice 1kg</td>
                                    <td>Onja</td>
                                    <td>
                                        <div>
                                            <div className='text-muted font-13 mb-1 font-medium'>
                                                Grains
                                            </div>
                                            Rice
                                        </div>
                                    </td>
                                    <td>
                                        1kg
                                    </td>
                                    <td>Stock-In</td>
                                    <td>621</td>
                                    <td>Don Barker</td>
                                    <td>11 Jul 2022</td>
                                </tr>
                                <tr>
                                    <td>#4808</td>
                                    <td>Onja Rice 1kg</td>
                                    <td>Onja</td>
                                    <td>
                                        <div>
                                            <div className='text-muted font-13 mb-1 font-medium'>
                                                Grains
                                            </div>
                                            Rice
                                        </div>
                                    </td>
                                    <td>
                                        1kg
                                    </td>
                                    <td>Fresh Stock-In</td>
                                    <td>621</td>
                                    <td>Don Barker</td>
                                    <td>11 Jul 2022</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        {/* <ShowingEntries /> */}
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default StockTransactions
