import React from "react"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"

const CallNotes = () => {
    return (
        <>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-150px py-2'>Date & Time</th>
                                    <th className='min-w-150px py-2'>Customer Name</th>
                                    <th className='min-w-150px py-2'>Notes</th>
                                    <th className='min-w-150px py-2'>Warehouse Name</th>
                                    <th className='min-w-120px py-2'>Flag</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>

                                    <td>
                                        29 Mar 2022
                                        <p className='font-13 text-muted mb-0'>05:32PM</p>
                                    </td>
                                    <td>
                                        Brian Wolfe
                                    </td>
                                    <td>
                                        Lorem ipsum may be used as a placeholder
                                        before final copy is available
                                    </td>
                                    <td>
                                        Warehouse 1
                                    </td>
                                    <td>
                                        Garrett Mullins
                                    </td>
                                </tr>
                                <tr>

                                    <td>
                                        29 Mar 2022
                                        <p className='font-13 text-muted mb-0'>05:32PM</p>
                                    </td>
                                    <td>
                                        Brian Wolfe
                                    </td>
                                    <td>
                                        Lorem ipsum may be used as a placeholder
                                        before final copy is available
                                    </td>
                                    <td>
                                        Warehouse 1
                                    </td>
                                    <td>
                                        Garrett Mullins
                                    </td>
                                </tr>
                                <tr>

                                    <td>
                                        29 Mar 2022
                                        <p className='font-13 text-muted mb-0'>05:32PM</p>
                                    </td>
                                    <td>
                                        Brian Wolfe
                                    </td>
                                    <td>
                                        Lorem ipsum may be used as a placeholder
                                        before final copy is available
                                    </td>
                                    <td>
                                        Warehouse 1
                                    </td>
                                    <td>
                                        Garrett Mullins
                                    </td>
                                </tr>

                            </tbody>
                            {/* end::Table body */}
                        </table>

                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CallNotes
