import React, {useEffect, useState} from 'react'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {
  GLOBAL_DATE_FORMAT,
  KTSVG,
  apiBaseURL,
  dateFormat,
  fetchStaticAsyncDropdown,
  globalDateFormate,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import tenantConfiguration from '../../../TenantVariables'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import {AsyncPaginate} from 'react-select-async-paginate'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

const SupportRequestMyClosed = () => {
  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'Complaints',
    subTitle: 'Complaints',
  }

  let supportRequestFiltered = retrieveFilter(filters.ts_support_request_my_closed)
  let userId = localStorage.getItem('user_id')
  let monthStart = dateFormat(moment().startOf('month'))
  let monthEnd = dateFormat(moment().endOf('month'))

  const initialState = {
    startDate: supportRequestFiltered?.startDate || monthStart,
    endDate: supportRequestFiltered?.endDate || monthEnd,
    search: supportRequestFiltered?.search || '',
    selected: supportRequestFiltered?.selected || '',
    currentOffSet: supportRequestFiltered?.currentOffset || '',
    buttonDisable: true,
  }

  const initialDateFilter = {
    startDate: supportRequestFiltered?.startDate || monthStart,
    endDate: supportRequestFiltered?.endDate || monthEnd,
  }
  const [myClosed, setMyClosed] = useState([])
  const [count, setCount] = useState('')
  const [search, setSearch] = useState(supportRequestFiltered?.search || '')
  const [selected, setSelected] = useState<number>(supportRequestFiltered?.selected || 0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [currentOffset, setCurrentOffset] = useState<number>(
    supportRequestFiltered?.currentOffset || '0'
  )
  const [isLoading, setIsLoading] = useState(true)
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    supportRequestFiltered?.buttonDisable || false
  )
  const [requestDate, setRequestDate] = useState<any>(initialDateFilter)
  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    localStorage.removeItem('supportRequestType')
  }, [])

  useEffect(() => {
    myClosedRequest()
  }, [currentOffset, debounceSearch, requestDate])

  const myClosedRequest = async () => {
    setIsLoading(true)
    const url = `${apiBaseURL()}support_request/web?limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&status=closed&assign_to=${userId}&start_date=${
      requestDate.startDate.apiDate
    }&end_date=${requestDate.endDate.apiDate}`
    const response = await getRequest(url, true)
    setMyClosed(response.results)
    setCount(response.count)

    if (response) {
      setIsLoading(false)
    }

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable
    setFilterStates(updatedState)

    saveFilter(filters.ts_support_request_my_closed, filterStates)

    if (
      search == '' &&
      requestDate.startDate.apiDate == monthStart.apiDate &&
      requestDate.endDate.apiDate == monthEnd.apiDate
    ) {
      setButtonDisable(false)
    }
  }

  const CustomDatePickerComponent = () => {
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            setButtonDisable(true)
            const callBackDate = {
              startDate: dateFormat(start._d),
              endDate: dateFormat(end._d),
            }
            let updatedState: any = {...filterStates}
            updatedState['startDate'] = callBackDate.startDate
            updatedState['endDate'] = callBackDate.endDate
            setFilterStates(updatedState)
            setRequestDate(callBackDate)
          }}
          initialSettings={{
            startDate: moment(requestDate.startDate.apiDate).toDate(),
            endDate: moment(requestDate.endDate.apiDate).toDate(),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={requestDate}
            disabled={isLoading}
          />
        </DateRangePicker>
      </>
    )
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputValue = e.target ? e.target.value : e.value
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    setSearch(inputValue)
    setFilterStates({...filterStates, ['search']: inputValue})
  }

  const handleChangeReset = () => {
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)

    let updatedDateState: any = {...requestDate}
    updatedDateState['startDate'] = monthStart
    updatedDateState['endDate'] = monthEnd
    setRequestDate(updatedDateState)

    let updatedState: any = {...filterStates}
    updatedState['status'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    updatedState['startDate'] = ''
    updatedState['endDate'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title='My Closed' />
      <div>
        <PageTitle breadcrumbs={[adminHeaderTitle]}>
          {intl.formatMessage({id: 'My Closed'})}
        </PageTitle>
        <div className='card'>
          {isLoading && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={handleFilterChange} value={search} />
            <div className='ms-auto'>
              <div className='card-toolbar my-0'>
                <CustomDatePickerComponent />
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
              </div>
            </div>
          </div>
          <div className='card-body py-3'>
            {myClosed.length === 0 ? <NoRecords /> : <TsSupportRequestTable list={myClosed} />}
          </div>
        </div>
        <CustomPaginate
          data={myClosed}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={isLoading}
          saveFilterName={filters.ts_support_request_my_closed}
          filterStates={filterStates}
        />
      </div>
    </>
  )
}
export default SupportRequestMyClosed

export const TsSupportRequestTable = (props: any) => {
  const navigate = useNavigate()

  return (
    <div className='table-responsive'>
      <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2 table-hover'>
        <thead>
          <tr className='text-muted fw-500'>
            <th className='min-w-70px'>Complaint ID</th>
            <th className='min-w-150px'>Complaint Tilte</th>
            <th className='min-w-140px'>Customer Name</th>
            <th className='min-w-140px'>Assigned To</th>
            <th className='min-w-140px'>Rating</th>
            <th className='min-w-120px'>Created on</th>
            <th className='min-w-120px'>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {props.list.map((item: any) => {
            let id = item.id
            return (
              <tr
                onClick={() => {
                  navigate('complaint-detail/overview')
                  localStorage.setItem('supportRequestType', 'my-closed')
                  localStorage.setItem('supportRequestId', item.id)
                }}
                className='cursor-pointer'
              >
                <td>#{item.support_number}</td>
                <td>{item.title}</td>
                <td>{item.customer.business_name}</td>
                <td>
                  {item.assign_to == null
                    ? '-'
                    : item.assign_to.first_name + item.assign_to.last_name}
                </td>
                <td>
                  {item.rating == null ? (
                    '-'
                  ) : (
                    <>
                      <KTSVG
                        path='/media/icons/duotune/general/gen029.svg'
                        className='ic text-warning me-1'
                      />{' '}
                      {item.rating}
                    </>
                  )}
                </td>
                <td>
                  {item.created_at && item.created_at !== '' ? (
                    <>
                      {dateFormat(item.created_at).displayDate}
                      <p className='font-13 text-muted mb-0'>
                        {dateFormat(item.created_at, 'LT').displayDate}
                      </p>
                    </>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {item.updated_at && item.updated_at !== '' ? (
                    <>
                      {dateFormat(item.updated_at).displayDate}
                      <p className='font-13 text-muted mb-0'>
                        {dateFormat(item.updated_at, 'LT').displayDate}
                      </p>
                    </>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
