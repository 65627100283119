import React, {useEffect, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  checkUserType,
  fetchAsyncDropdown,
  isEmpty,
  KTSVG,
  measurementLabel,
  numberFormat,
} from '../../../../_eaFruitsDms/helpers'
import ShowingEntries from '../../../../_eaFruitsDms/helpers/components/ShowingEntries'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {categoryStyle} from '../../../../_eaFruitsDms/helpers/components/categoryStyle'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'

type Props = {
  className: string
}
const LowStockReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const location: any = useLocation()

  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Low-Stock Report',
  }

  const initialPageState = {
    search: '',
    limit: 10,
    offset: '',
    list: [],
    next: '',
    count: '',
    previous: '',
    isLoading: true,
    currentOffset: '',
    selected: 0,
  }

  const selectedWarehouse = isEmpty(localStorage.getItem('sa_dashboard_warehouse'))
    ? ''
    : JSON.parse(localStorage.getItem('sa_dashboard_warehouse') || '')

  const [pageState, setPageState] = useState(initialPageState)
  const [categories, setCategories] = useState([])
  const [warehouse, setWarehouse] = useState<any>(
    !checkUserType(['wm', 'sa'])
      ? {label: 'All Warehouse', value: ''}
      : !isEmpty(location) && !isEmpty(location.state) && !isEmpty(location.state.lowStock)
      ? selectedWarehouse
      : {label: 'All Warehouse', value: ''}
  )
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [categoryOffSet, setCategoryOffSet] = useState<any>('')
  const [limit] = useState<number>(10)
  const [brands, setBrands] = useState([])
  const [searchSelectBrand, setSearchSelectBrand] = useState<string>('')
  const [brandOffSet, setBrandOffSet] = useState<any>('')
  const [group, setGroup] = useState([])
  const [searchSelectGroup, setSearchSelectGroup] = useState<string>('')
  const [groupOffSet, setGroupOffSet] = useState<any>('')
  const [brandName, setBrandName] = useState<any>({label: 'All Brands', value: ''})
  const [groupName, setGroupName] = useState<any>({label: 'All Groups', value: ''})
  const [categoryName, setCategoryName] = useState<any>({label: 'All Categories', value: ''})

  const getList = async () => {
    // Develop BY: Kashyap
    // Description: get List Details
    setPageState({
      ...pageState,
      isLoading: true,
    })
    const warehouseId = !checkUserType(['wm', 'sa'])
      ? localStorage.getItem('w_id')
      : isEmpty(warehouse.value)
      ? ''
      : warehouse.value

    const url =
      apiBaseURL() +
      `inventory/lowstock-report/?warehouse_id=${isEmpty(warehouseId) ? '' : warehouseId}` +
      `&ordering=product_name&limit=${pageState.limit}&offset=${pageState.currentOffset}&search=${pageState.search}&brand_id=${brandName.value}&category_id=${categoryName.value}&group_id=${groupName.value}`

    const response = await getRequest(url, true)

    if (response.results) {
      setPageState({
        ...pageState,
        list: response.results,
        next: response.next,
        count: response.count,
        previous: response.previous,
        isLoading: response ? false : true,
      })
    }
  }

  const fetchCategories = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (categoriesResponse.next !== null) {
      const queryParams = new URLSearchParams(categoriesResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffSet(newOffset)
      hasMore = true
    }

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    if (categoriesResponse.results.length > 0) {
      categoriesResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          color: '#6d6e6f',
          bg: '#f7f7f7',
          transform: 'uppercase',
          boxSizing: 'borderBox',
          weight: '500',
          cursor: 'default',
          size: '.75rem !important',
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchBrands = (search: any) => {
    return fetchAsyncDropdown(
      brandOffSet,
      setBrandOffSet,
      brands,
      setBrands,
      'master/product/brand?ordering=name',
      search,
      setSearchSelectBrand,
      false,
      true,
      'All Brands'
    )
  }

  const fetchGroup = (search: any) => {
    return fetchAsyncDropdown(
      groupOffSet,
      setGroupOffSet,
      group,
      setGroup,
      'master/product/group?ordering=name',
      search,
      setSearchSelectGroup,
      false,
      true,
      'All Groups'
    )
  }

  useEffect(() => {
    getList()
  }, [pageState.currentOffset, pageState.search, brandName, groupName, categoryName, warehouse])

  useEffect(() => {
    setGroupOffSet('')
    setCategoryOffSet('')
    setBrandOffSet('')
  }, [searchSelectGroup, searchSelectBrand, searchSelect, warehouse])

  return (
    <>
      <EATitle title='Low-Stock Report' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Low-Stock Report'})}
      </PageTitle>
      <div className='card'>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar newSearch={true} setResponse={setPageState} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              {checkUserType(['sa', 'wm']) && (
                <WarehouseFilter
                  warehouse={warehouse}
                  handleFilterChange={(e: any) => setWarehouse(e)}
                  isDisabled={pageState.isLoading}
                />
              )}
              <AsyncPaginate
                loadOptions={fetchBrands}
                isSearchable
                className='react-select-container my-1 me-3 w-160px'
                classNamePrefix='react-select'
                placeholder='All Brands'
                value={brandName}
                onChange={(e) => setBrandName(e)}
                isDisabled={pageState.isLoading}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchCategories}
                isSearchable
                className='react-select-container my-1 me-3 w-160px'
                classNamePrefix='react-select'
                placeholder='All Categories'
                styles={categoryStyle}
                isDisabled={pageState.isLoading}
                name='categories'
                value={categoryName}
                onChange={(e) => setCategoryName(e)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchGroup}
                isSearchable
                className='react-select-container my-1 w-160px'
                classNamePrefix='react-select'
                value={groupName}
                onChange={(e) => setGroupName(e)}
                placeholder='All Group'
                isDisabled={pageState.isLoading}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>
        {pageState.isLoading ? (
          <FetchingRecords />
        ) : pageState.list.length === 0 ? (
          <NoRecords />
        ) : !checkUserType(['sa', 'wm']) ? (
          <ReportTable list={pageState.list} />
        ) : (
          <ReportTableWmSa list={pageState.list} />
        )}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            <ShowingEntries response={pageState} />
          </div>
          <div className='col'>
            <CustomPagination newProp={true} pageState={pageState} setPageState={setPageState} />
          </div>
        </div>
      </div>
    </>
  )
}

export default LowStockReport

const ReportTable = (props: any) => {
  return (
    <div className='card-body py-3'>
      {/* begin::Table container */}
      <div className='table-responsive'>
        <table className='table table-hover table-row-bordered align-middle gs-0 gy-3 mb-0'>
          <thead>
            <tr className=' text-muted fw-500'>
              <th className='min-w-110px py-2'>Product SKU</th>
              <th className='min-w-100px py-2'>Brand</th>
              <th className='min-w-120px py-2'>Category</th>
              <th className='min-w-130px py-2'>Product Group</th>
              <th className='min-w-100px py-2'>Variant</th>
              <th className='min-w-100px py-2'>Size</th>
              <th className='min-w-100px py-2'>Low Stock at</th>
              <th className='min-w-100px py-2'>Current Stock</th>
            </tr>
          </thead>
          <tbody>
            {props.list.length !== 0 &&
              props.list.map((item: any) => (
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <span>{checkEmpty(item.product_name)}</span>
                      {item.additional_notes && (
                        <OverlayTrigger
                          delay={{hide: 450, show: 300}}
                          overlay={(props) => (
                            <Tooltip {...props} className='tooltip-dark'>
                              {item.additional_notes}
                            </Tooltip>
                          )}
                          placement='top'
                        >
                          <span className='ms-2 cursor-pointer'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen045.svg'
                              className='ic text-primary mr-0'
                            />
                          </span>
                        </OverlayTrigger>
                      )}
                    </div>
                  </td>
                  <td>{checkEmpty(item.brand_name)}</td>
                  <div>
                    <div className='text-muted font-13 mb-1 font-medium'>
                      {checkEmpty(item.parent_category_name)}
                    </div>
                    {checkEmpty(item.category_name)}
                  </div>
                  <td>{checkEmpty(item.group_name)}</td>
                  <td>{checkEmpty(item.variant_name)}</td>
                  <td>
                    {!isEmpty(item.size) ? (
                      <> {item.size + ' ' + measurementLabel(item.measurement_unit)} </>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {isEmpty(item.alert_stock_in) ? (
                      '-'
                    ) : (
                      <>
                        {numberFormat(item.alert_stock_in).displayFormat}{' '}
                        {measurementLabel(
                          item.fix_size
                            ? item.material_name
                            : item.approx_weight > 0
                            ? item.material_name
                            : item.measurement_unit
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {isEmpty(item.current_stock_in) ? (
                      '-'
                    ) : (
                      <>
                        {numberFormat(item.current_stock_in).displayFormat}{' '}
                        {measurementLabel(
                          item.fix_size
                            ? item.material_name
                            : item.approx_weight > 0
                            ? item.material_name
                            : item.measurement_unit
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* end::Table container */}
    </div>
  )
}

const ReportTableWmSa = (props: any) => {
  return (
    <div className='card-body py-3'>
      {/* begin::Table container */}
      <div className='table-responsive'>
        <table className='table table-hover table-row-bordered align-middle gs-0 gy-3 mb-0'>
          <thead>
            <tr className=' text-muted fw-500'>
              <th className='min-w-110px py-2'>Product SKU</th>
              <th className='min-w-150px py-2'>Warehouse Name</th>
              <th className='min-w-100px py-2'>Brand</th>
              <th className='min-w-120px py-2'>Category</th>
              <th className='min-w-130px py-2'>Product Group</th>
              <th className='min-w-100px py-2'>Variant</th>
              <th className='min-w-100px py-2'>Size</th>
              <th className='min-w-130px py-2'>Low Stock at</th>
              <th className='min-w-100px py-2'>Current Stock</th>
            </tr>
          </thead>
          <tbody>
            {props.list.length !== 0 &&
              props.list.map((item: any) => (
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <span>{checkEmpty(item.product_name)}</span>
                      {item.additional_notes && (
                        <OverlayTrigger
                          delay={{hide: 450, show: 300}}
                          overlay={(props) => (
                            <Tooltip {...props} className='tooltip-dark'>
                              {item.additional_notes}
                            </Tooltip>
                          )}
                          placement='top'
                        >
                          <span className='ms-2 cursor-pointer'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen045.svg'
                              className='ic text-primary mr-0'
                            />
                          </span>
                        </OverlayTrigger>
                      )}
                    </div>
                  </td>
                  <td>{checkEmpty(item.warehouse_name)}</td>
                  <td>{checkEmpty(item.brand_name)}</td>
                  <div>
                    <div className='text-muted font-13 mb-1 font-medium'>
                      {checkEmpty(item.parent_category_name)}
                    </div>
                    {checkEmpty(item.category_name)}
                  </div>
                  <td>{checkEmpty(item.group_name)}</td>
                  <td>{checkEmpty(item.variant_name)}</td>
                  <td>
                    {!isEmpty(item.size) ? (
                      <> {item.size + ' ' + measurementLabel(item.measurement_unit)} </>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {isEmpty(item.alert_stock_in) ? (
                      '-'
                    ) : (
                      <>
                        {numberFormat(item.alert_stock_in).displayFormat}{' '}
                        {measurementLabel(
                          item.fix_size
                            ? item.material_name
                            : item.approx_weight > 0
                            ? item.material_name
                            : item.measurement_unit
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {isEmpty(item.current_stock_in) ? (
                      '-'
                    ) : (
                      <>
                        {numberFormat(item.current_stock_in).displayFormat}{' '}
                        {measurementLabel(
                          item.fix_size
                            ? item.material_name
                            : item.approx_weight > 0
                            ? item.material_name
                            : item.measurement_unit
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* end::Table container */}
    </div>
  )
}
