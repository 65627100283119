import React, {useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  isEmpty,
  KTSVG,
  measurementLabel,
} from '../../../_eaFruitsDms/helpers'
import {getRequest, postRequest} from '../../../_eaFruitsDms/https/apiCall'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {EaAlertDanger} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import moment from 'moment'
import {StockInTabs} from '../../../_eaFruitsDms/helpers/components/stockInTabs'
import {useNavigate} from 'react-router-dom'

let tableRowIndex: number = 0
const BananaStockIn = () => {
  const headerTitle = {
    title: 'Stock-In',
    subTitle: 'Banana Group Stock-In',
  }
  const navigate = useNavigate()

  const currentWarehouseId = localStorage.getItem('w_id')

  const initialRow: any = {
    index: 0,
    product_id: '',
    container: '',
    batch: '',
    offload_days: '',
    quantity: '',
    inward_quantity: '0',
    validation_for_product: '',
    validation_for_container: '',
    validation_for_batch: '',
    validation_for_offload_days: '',
    validation_for_quantity: '',
    validation_for_quantity_exceed: '',
    validation_for_min_temperature: '',
    validation_for_max_temperature: '',
    validation_for_min_humidity: '',
    validation_for_max_humidity: '',
    quantity_label: '',
    min_temperature: '',
    max_temperature: '',
    min_humidity: '',
    max_humidity: '',
  }

  const [bananaStockInRows, setBananaStockInRows] = useState<any>([initialRow])
  const [searchSelect, setSearchSelect] = useState('')
  const [productOffSet, setProductOffSet] = useState<any>('')
  const [productSkus, setProductSkus] = useState<any>([])
  const [productReset, setProductReset] = useState<any>(moment())
  const [containerReset, setContainerReset] = useState<any>(moment())
  const [containers, setContainers] = useState<any>([])
  const [limit] = useState<number>(10)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)
  const [isDataUpdate, setIsDataUpdate] = useState<boolean>(false)

  /**
   * Fetches product SKUs based on search criteria and updates state accordingly.
   *
   * @param {any} search - optional search criteria
   * @return {object} object containing options and a flag indicating if there are more results
   */
  const fetchProductSku = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
      setProductOffSet('')
    }
    const productResponse = await getRequest(
      `${apiBaseURL()}inventory/product-SKUs/?warehouse_id=${currentWarehouseId}&ordering=name&search=${search}&limit=${limit}&offset=${productOffSet}&remove_product_stocking=true&&only_ripening_product=true`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (productResponse.next !== null) {
      const queryParams = new URLSearchParams(productResponse.next)
      let newOffset: any = queryParams.get('offset')
      setProductOffSet(newOffset)
      hasMore = true
    }

    if (productResponse.results.length > 0) {
      productResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          ...option,
        })
      })
    }

    if (productSkus.length > 0) {
      setProductSkus(productSkus.concat(options))
    } else {
      setProductSkus(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  /**
   * Fetches containers based on the search criteria.
   *
   * @param {any} search - the search criteria
   * @return {object} an object containing the options and hasMore flag
   */
  const fetchContainers = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }
    const containerResponse = await getRequest(
      `${apiBaseURL()}weather-boxes/available-containers-list?warehouse_id=${currentWarehouseId}&status=empty`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (containerResponse.length > 0) {
      containerResponse.map((option: any) => {
        options.push({
          label: option.container_name,
          value: option.id,
          ...option,
        })
      })
    }

    if (containers.length > 0) {
      setContainers(containers.concat(options))
    } else {
      setContainers(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  /**
   * Handles the change event for the input fields.
   *
   * @param {any} e - the event object
   * @param {any} index - the index of the element being changed
   * @param {string} name - (optional) the name of the element being changed
   * @return {void}
   */
  const handleChange = (e: any, index: any, name?: string) => {
    let rows = [...bananaStockInRows]
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'product':
        rows[index]['product_id'] = inputValue
        rows[index]['batch'] = ''
        rows[index]['quantity'] = ''
        rows[index]['inward_quantity'] = 0
        rows[index]['validation_for_product'] = ''
        rows[index]['quantity_label'] = measurementLabel(
          e.fix_size
            ? e.material.name
            : e.variants && e.variants.approx_weight > 0
            ? e.material.name
            : e.measurement_unit
        )
        break
      case 'container':
        const findIndex = bananaStockInRows.findIndex(
          (option: any) => option.container_id === inputValue
        )
        rows[index]['container'] = e.label
        let minTemperature,
          maxTemperature,
          minimumHumidity,
          maximumHumidity = 0

        if (findIndex > -1) {
          minTemperature = checkEmpty(rows[findIndex]['min_temperature'], '', true)
          maxTemperature = checkEmpty(rows[findIndex]['max_temperature'], '', true)
          minimumHumidity = checkEmpty(rows[findIndex]['min_humidity'], '', true)
          maximumHumidity = checkEmpty(rows[findIndex]['max_humidity'], '', true)
        } else {
          minTemperature = checkEmpty(e.associate_weather_min_temp, '', true)
          maxTemperature = checkEmpty(e.associate_weather_max_temp, '', true)
          minimumHumidity = checkEmpty(e.associate_weather_min_humidity, '', true)
          maximumHumidity = checkEmpty(e.associate_weather_max_humidity, '', true)
        }

        if (!isEmpty(minimumHumidity, true)) {
          rows[index]['validation_for_min_humidity'] = ''
        }

        if (!isEmpty(maximumHumidity, true)) {
          rows[index]['validation_for_max_humidity'] = ''
        }

        if (!isEmpty(minTemperature, true)) {
          rows[index]['validation_for_min_temperature'] = ''
        }

        if (!isEmpty(maxTemperature, true)) {
          rows[index]['validation_for_max_temperature'] = ''
        }

        rows[index]['min_temperature'] = checkEmpty(minTemperature, '', true)
        rows[index]['max_temperature'] = checkEmpty(maxTemperature, '', true)
        rows[index]['min_humidity'] = checkEmpty(minimumHumidity, '', true)
        rows[index]['max_humidity'] = checkEmpty(maximumHumidity, '', true)

        rows[index]['container_id'] = inputValue
        rows[index]['validation_for_container'] = ''
        rows[index]['validation_for_quantity_exceed'] = ''

        break
      case 'batch':
        if (inputValue.length > 1 && !inputValue.includes('#')) {
          return false
        }

        if (inputValue.length == 1 && !inputValue.includes('#')) {
          inputValue = '#' + inputValue
        }

        rows[index]['batch'] = inputValue
        rows[index]['validation_for_batch'] = ''

        if (isEmpty(inputValue.trim())) {
          rows[index]['validation_for_batch'] = 'is-invalid'
        }

        break
      case 'offload_days':
        inputValue = inputValue.replace(/[^0-9]/g, '')

        rows[index]['offload_days'] = inputValue
        rows[index]['validation_for_offload_days'] = ''

        if (isEmpty(inputValue.trim())) {
          rows[index]['validation_for_offload_days'] = ''
        }
        break
      case 'quantity':
        if (inputValue.length == 1 && inputValue == 0) {
          return false
        }
        inputValue = inputValue.replace(/[^0-9]/g, '')

        rows[index]['quantity'] = inputValue
        rows[index]['validation_for_quantity'] = ''

        if (isEmpty(inputValue.trim())) {
          rows[index]['validation_for_quantity'] = 'is-invalid'
        }
        if (inputValue > 0) {
          const container: any = containers.find(
            (option: any) => option.id === rows[index]['container_id']
          )

          // counting for Crates(Available) start
          let count = 0
          let rowCountQuantity = 0

          const containerId = isEmpty(rows[index].container_id)
            ? 0
            : parseInt(rows[index].container_id)

          rows.forEach((i, ind) => {
            if (parseInt(i.container_id) === containerId) {
              count += parseInt(i.quantity)
              if (ind !== index) {
                rowCountQuantity += parseInt(i.quantity)
              }
            }
          })

          if (container.available_qty < count) {
            rows[index]['validation_for_quantity_exceed'] = `Capacity ${
              container.available_qty - rowCountQuantity
            } Crates(Available)`
          } else {
            rows.forEach((i, ind) => {
              if (parseInt(i.container_id) === containerId) {
                rows[ind]['validation_for_quantity_exceed'] = ''
              }
            })
          }
          // counting for Crates(Available) end

          let product = productSkus.find((option: any) => option.id === rows[index]['product_id'])

          if (product.length > 0) product = product[0]

          if (product.fix_size) {
            if (product.measurement_unit.toLowerCase() === 'gram') {
              rows[index]['inward_quantity'] = inputValue * (product.size / 1000)
            } else {
              rows[index]['inward_quantity'] = inputValue * product.size
            }
          } else {
            if (product.variants && product.variants.approx_weight > 0) {
              rows[index]['inward_quantity'] = (inputValue * product.variants.approx_weight) / 1000
            } else {
              rows[index]['inward_quantity'] = inputValue
            }
          }
        }
        break
      case 'min_temperature':
      case 'max_temperature':
      case 'min_humidity':
      case 'max_humidity':
        if (inputValue.length === 1 && inputValue === '.') {
          return false
        }

        if (inputValue.length > 1) {
          inputValue = inputValue.replace(/(\d)-/g, '$1')
        }

        if (['min_temperature', 'max_temperature'].includes(inputName)) {
          if ((inputValue.split('-').length-1) > 1) {
            return false
          }
        } else {
          inputValue = inputValue.replace('-')
        }

        inputValue = inputValue.replace(/[^0-9.-]|(\.[0-9]*)\./g, '$1')

        rows[index][`validation_for_${inputName}`] = ''
        rows[index][inputName] = inputValue
        if (isEmpty(inputValue.trim())) {
          rows[index][`validation_for_${inputName}`] = 'is-invalid'
        }

        rows.map((row, ind) => {
          if (row.container_id === rows[index][`container_id`]) {
            rows[ind][inputName] = inputValue
          }
        })

        // Validation checks for min_temperature < max_temperature and min_humidity < max_humidity
        const minTemp = parseFloat(rows[index]['min_temperature'])
        const maxTemp = parseFloat(rows[index]['max_temperature'])
        const minHumidity = parseFloat(rows[index]['min_humidity'])
        const maxHumidity = parseFloat(rows[index]['max_humidity'])

        if (!isNaN(minTemp) && !isNaN(maxTemp) && minTemp > maxTemp) {
          // Set validation for min_temperature and max_temperature
          rows[index]['validation_for_min_temperature'] = 'is-invalid'
          rows[index]['validation_for_max_temperature'] = 'is-invalid'
        } else {
          rows[index]['validation_for_min_temperature'] = ''
          rows[index]['validation_for_max_temperature'] = ''
        }

        if (!isNaN(minHumidity) && !isNaN(maxHumidity) && minHumidity > maxHumidity) {
          // Set validation for min_humidity and max_humidity
          rows[index]['validation_for_min_humidity'] = 'is-invalid'
          rows[index]['validation_for_max_humidity'] = 'is-invalid'
        } else {
          rows[index]['validation_for_min_humidity'] = ''
          rows[index]['validation_for_max_humidity'] = ''
        }
        break
    }

    setIsDataUpdate(true)
    setBananaStockInRows(rows)
  }

  /**
   * Validate the stock of bananas in the object and update the validation status of each row.
   *
   * @return {boolean} The overall validation status of the banana stock in the object.
   */

  const validateBananaStockInObj = () => {
    const rows = [...bananaStockInRows]

    let isValid: boolean = true

    rows.map((row: any, index: number) => {
      if (isEmpty(row.product_id)) {
        rows[index]['validation_for_product'] = 'is-invalid'
        isValid = false
      }

      if (isEmpty(row.container_id)) {
        rows[index]['validation_for_container'] = 'is-invalid'
        isValid = false
      }

      if (isEmpty(row.batch)) {
        rows[index]['validation_for_batch'] = 'is-invalid'
        isValid = false
      }

      if (isEmpty(row.offload_days, true)) {
        rows[index]['validation_for_offload_days'] = 'is-invalid'
        isValid = false
      }

      if (isEmpty(row.quantity, true)) {
        rows[index]['validation_for_quantity'] = 'is-invalid'
        isValid = false
      } else {
        let container: any = containers.find(
          (option: any) => option.id === rows[index]['container_id']
        )

        // counting for Crates(Available) start

        let count = 0
        let rowCountQuantity = 0

        const containerId = isEmpty(rows[index].container_id)
          ? 0
          : parseInt(rows[index].container_id)

        rows.forEach((i, ind) => {
          if (parseInt(i.container_id) === containerId) {
            count += parseInt(i.quantity)

            if (ind !== index) {
              rowCountQuantity += parseInt(i.quantity)
            }
          }
        })

        if (container.available_qty < count) {
          rows[index]['validation_for_quantity_exceed'] = `Capacity ${
            container.available_qty - rowCountQuantity
          } Crates(Available)`

          isValid = false
        } else {
          rows.forEach((i, ind) => {
            if (parseInt(i.container_id) === containerId) {
              rows[ind]['validation_for_quantity_exceed'] = ''
            }
          })
        }
      }

      if (isEmpty(row.min_temperature)) {
        isValid = false
        rows[index]['validation_for_min_temperature'] = 'is-invalid'
      } else {
        if (parseFloat(row.min_temperature) > parseFloat(row.max_temperature)) {
          isValid = false
          rows[index]['validation_for_min_temperature'] = 'is-invalid'
          rows[index]['validation_for_max_temperature'] = 'is-invalid'
        }
      }

      if (isEmpty(row.max_temperature)) {
        isValid = false
        rows[index]['validation_for_max_temperature'] = 'is-invalid'
      } else {
        if (parseFloat(row.min_temperature) > parseFloat(row.max_temperature)) {
          isValid = false
          rows[index]['validation_for_min_temperature'] = 'is-invalid'
          rows[index]['validation_for_max_temperature'] = 'is-invalid'
        }
      }

      if (isEmpty(row.min_humidity)) {
        isValid = false
        rows[index]['validation_for_min_humidity'] = 'is-invalid'
      } else {
        if (parseFloat(row.min_humidity) > parseFloat(row.max_humidity)) {
          isValid = false
          rows[index]['validation_for_min_humidity'] = 'is-invalid'
          rows[index]['validation_for_max_humidity'] = 'is-invalid'
        }
      }

      if (isEmpty(row.max_humidity)) {
        isValid = false
        rows[index]['validation_for_max_humidity'] = 'is-invalid'
      } else {
        if (parseFloat(row.min_humidity) > parseFloat(row.max_humidity)) {
          isValid = false
          rows[index]['validation_for_min_humidity'] = 'is-invalid'
          rows[index]['validation_for_max_humidity'] = 'is-invalid'
        }
      }

      return isValid
    })

    setBananaStockInRows(rows)

    return isValid
  }

  /**
   * This function adds a new stock in a row after validating the stock.
   *
   * @return {void}
   */
  const addNewStockInRow = () => {
    const isValid = validateBananaStockInObj()
    if (isValid) {
      tableRowIndex++

      const updatedRows = [...bananaStockInRows]
      initialRow.index = tableRowIndex
      updatedRows[tableRowIndex] = initialRow
      setBananaStockInRows(updatedRows)
      setProductOffSet('')
    }
  }

  /**
   * Deletes a row from the banana stock table.
   *
   * @param {number} index - the index of the row to be deleted
   * @return {any}
   */

  const deleteRow = (index: number): any => {
    if (bananaStockInRows.length === 1) {
      alert('You can not remove this row because you need to add at least one stock item.!')
      return false;
    }

    setProductOffSet('')
    let indexToRemove = bananaStockInRows.findIndex((x: any) => x.index === index)

    if (indexToRemove > -1) {
      let updatedRows: any = []

      bananaStockInRows.forEach((row: any, index: any) => {
        if (index !== indexToRemove) {
          if (index > indexToRemove) {
            row.index = row.index - 1
          }
          updatedRows.push(row)
        }
      })

      setBananaStockInRows(updatedRows)
      tableRowIndex--
    }
  }

  /**
   * Perform a stock-in operation for bananas.
   *
   * @return {Promise<void>} Promise that resolves when the stock-in operation is completed
   */
  const bananaStockIn = async () => {
    const isValid = validateBananaStockInObj()

    if (isValid) {
      setIsSubmit(true)

      const bananaStockInObj: any = {
        stock_entries: [],
      }

      const containers: any = []

      bananaStockInRows.map((row: any, index: number) => {
        containers.push(row.container)

        bananaStockInObj.stock_entries.push({
          product: row.product_id,
          container: row.container_id,
          batch_number: row.batch,
          offload_days: row.offload_days,
          qty: row.quantity,
          associate_weather_min_temp: row.min_temperature,
          associate_weather_max_temp: row.max_temperature,
          associate_weather_min_humidity: row.min_humidity,
          associate_weather_max_humidity: row.max_humidity,
        })
      })

      const response = await postRequest(
        `${apiBaseURL()}inventory/ripening-product-create`,
        bananaStockInObj,
        true
      )

      handleResponse(response, containers)
    }
  }

  /**
   * Handles the response from the API request.
   *
   * @param {any} res - the response object from the API
   * @return {void}
   */
  const handleResponse = (res: any, containers: any) => {
    setIsSubmit(false)
    if (res.status === 201 || res.status === 200 || res.status === 204) {
      resetStates()
      if (!isEmpty(containers)) {
        containers = Array.from(new Set(containers))
        containers = containers.join(', ')

        localStorage.setItem('stock_in_message', `Bananas has been stock in successfully for the ${containers}`)
        navigate('/containers')
      }
    } else {
      setIsAlertFailed(true)
    }

    setTimeout(() => {
      setIsAlertFailed(false)
    }, 1500)
  }

  /**
   * Check the value and hide corresponding alert messages.
   *
   * @param {any} val - the value to check
   * @return {void}
   */
  const isAlertShow = (val: any) => {
    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }

  /**
   * This function cancels stock in.
   *
   * @return {void}
   */
  const cancelStockIn = () => {
    if (!isDataUpdate) {
      navigate('/')
    }
    resetStates()
  }

  /**
   * Resets the states by setting product reset, container reset, and banana stock in rows.
   */
  const resetStates = () => {
    setProductReset(moment())
    setContainerReset(moment())
    setProductOffSet('')
    setBananaStockInRows([initialRow])
    setIsDataUpdate(false)
  }

  return (
    <>
      <StockInTabs />
      <EATitle title='Banana Group Stock-In' />
      <PageTitle breadcrumbs={[headerTitle]}>Banana Group Stock-In</PageTitle>

      <div className='card p-5'>
        <h3 className='mb-5'>Banana Group Stock-In</h3>
        <EaAlertDanger
          onClick={() => isAlertShow('failed')}
          show={isAlertFailed}
          message='Something went wrong. Please try again.!'
        />
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th className='min-w-160px'>Product SKU</th>
                <th className='min-w-160px'>Container</th>
                <th className='min-w-160px'>Batch No.</th>
                <th className='min-w-160px'>Offload In Days</th>
                <th className='min-w-160px'>Quantity</th>
                <th className='min-w-160px'>Inward Quantity</th>
                <th className='min-w-160px'>Min. Temperature</th>
                <th className='min-w-160px'>Max. Temperature</th>
                <th className='min-w-160px'>Min. Humidity</th>
                <th className='min-w-160px'>Max. Humidity</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {bananaStockInRows.map((bananaStockInRow: any, index: number) => {
                return (
                  <tr key={index.toString()}>
                    <td>
                      <AsyncPaginate
                        key={productReset}
                        loadOptions={fetchProductSku}
                        placeholder='Select'
                        isSearchable
                        className={`react-select-container ${bananaStockInRow.validation_for_product}`}
                        classNamePrefix='react-select'
                        menuPosition='fixed'
                        onChange={(e) => {
                          handleChange(e, index, 'product')
                        }}
                        name='product_sku_id'
                        value={
                          productSkus.find(
                            (option: any) => option.value === bananaStockInRow.product_id
                          ) || ''
                        }
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#0c78a4',
                          },
                        })}
                      />
                    </td>
                    <td>
                      <AsyncPaginate
                        key={containerReset}
                        loadOptions={fetchContainers}
                        placeholder='Select'
                        isSearchable
                        className={`react-select-container ${bananaStockInRow.validation_for_container}`}
                        classNamePrefix='react-select'
                        menuPosition='fixed'
                        onChange={(e) => {
                          handleChange(e, index, 'container')
                        }}
                        name='container_id'
                        value={
                          containers.find(
                            (option: any) => option.id === bananaStockInRow.container_id
                          ) || ''
                        }
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#0c78a4',
                          },
                        })}
                      />
                    </td>
                    <td>
                      <input
                        type='text'
                        placeholder='Type here...'
                        name='batch'
                        value={bananaStockInRow.batch}
                        onChange={(e: any) => handleChange(e, index)}
                        className={`form-control ${bananaStockInRow.validation_for_batch}`}
                      />
                    </td>
                    <td>
                      <div
                        className='input-group'
                        style={{
                          borderColor: !isEmpty(bananaStockInRow.validation_for_offload_days)
                            ? 'Red'
                            : '',
                        }}
                      >
                        <input
                          type='text'
                          placeholder='0'
                          name='offload_days'
                          className={`form-control`}
                          value={bananaStockInRow.offload_days}
                          onChange={(e: any) => handleChange(e, index)}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                          Days
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className='input-group'
                        style={{
                          borderColor: !isEmpty(bananaStockInRow.validation_for_quantity)
                            ? 'Red'
                            : '',
                        }}
                      >
                        <input
                          type='text'
                          placeholder='0'
                          name='quantity'
                          className={`form-control`}
                          disabled={
                            isEmpty(bananaStockInRow.product_id) ||
                            isEmpty(bananaStockInRow.container_id)
                          }
                          value={bananaStockInRow.quantity}
                          onChange={(e: any) => handleChange(e, index)}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                          {!isEmpty(bananaStockInRow.quantity_label) &&
                            bananaStockInRow.quantity_label}
                        </span>
                      </div>
                      {!isEmpty(bananaStockInRow.validation_for_quantity_exceed) && (
                        <span className='font-13 text-danger mt-1'>
                          {bananaStockInRow.validation_for_quantity_exceed}
                        </span>
                      )}
                    </td>

                    <td>
                      <div className='input-group'>
                        <input
                          type='text'
                          placeholder='0'
                          name='inward_quantity'
                          className={`form-control`}
                          disabled
                          value={bananaStockInRow.inward_quantity}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                          Kgs
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className='input-group'
                        style={{
                          borderColor: !isEmpty(bananaStockInRow.validation_for_min_temperature)
                            ? 'Red'
                            : '',
                        }}
                      >
                        <input
                          type='text'
                          placeholder='0'
                          name='min_temperature'
                          value={bananaStockInRow.min_temperature}
                          onChange={(e: any) => handleChange(e, index)}
                          className={`form-control`}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                          ℃
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className='input-group'
                        style={{
                          borderColor: !isEmpty(bananaStockInRow.validation_for_max_temperature)
                            ? 'Red'
                            : '',
                        }}
                      >
                        <input
                          type='text'
                          placeholder='0'
                          name='max_temperature'
                          value={bananaStockInRow.max_temperature}
                          onChange={(e: any) => handleChange(e, index)}
                          className={`form-control`}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                          ℃
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className='input-group'
                        style={{
                          borderColor: !isEmpty(bananaStockInRow.validation_for_min_humidity)
                            ? 'Red'
                            : '',
                        }}
                      >
                        <input
                          type='text'
                          placeholder='0'
                          name='min_humidity'
                          value={bananaStockInRow.min_humidity}
                          onChange={(e: any) => handleChange(e, index)}
                          className={`form-control`}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                          g/m3
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className='input-group'
                        style={{
                          borderColor: !isEmpty(bananaStockInRow.validation_for_max_humidity)
                            ? 'Red'
                            : '',
                        }}
                      >
                        <input
                          type='text'
                          placeholder='0'
                          name='max_humidity'
                          value={bananaStockInRow.max_humidity}
                          onChange={(e: any) => handleChange(e, index)}
                          className={`form-control`}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                          g/m3
                        </span>
                      </div>
                    </td>
                    <td>
                      <button
                        type='button'
                        className='position-relative btn ms-auto p-0'
                        id={index.toString()}
                        onClick={() => {
                          deleteRow(index)
                        }}
                      >
                        <span className='svg-icon-dark'>
                          <KTSVG
                            path='/media/icons/duotune/abstract/abs012.svg'
                            className='ic me-0'
                          />
                        </span>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="ms-4 mb-2">
            <button
              type='button'
              className='position-relative btn ms-auto p-0 ms-3'
              onClick={addNewStockInRow}
            >
              <span className='svg-icon-dark'>
                <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='ic me-0 mt-1' />
              </span>
            </button>
          </div>
        </div>

        <div>
          <div className='card-footer py-2 border-top text-center justify-content-center'>
            <button type='button' className='btn btn-secondary me-3' onClick={cancelStockIn}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={bananaStockIn} disabled={isSubmit}>
              {isSubmit ? 'Please Wait...' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BananaStockIn
