import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Route, Routes} from 'react-router-dom'
import {apiBaseURL} from '../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import TSAreaCoverage from './components/areaCoverage'
import TSCreditIssues from './components/creditIssues'
import TSCustomers from './components/customers'
import TSSalesOrder from './components/sales'
import TSStock from './components/stock'
import TSTeam from './components/team'
import {WarehouseHeader} from './components/warehouseHeader'
import TSWarehouseOverview from './components/warehouseOverview'

const TSSingleWarehouse: React.FC = () => {
  const intl = useIntl()
  const customersHeaderTitle = {
    title: 'Warehouses',
    subTitle: 'Warehouse Name',
  }

  // Develop BY: Kashyap
  // Description: get single warehouse id
  let ID = localStorage.getItem('wId')
  const [id, setID] = useState(ID)

  const serverKmlUrl = 'https://eaf-dms-api.yecor.com'

  const [header, setHeader] = useState('')
  const [overView, setOverView] = useState('')
  const [areaCoverage, setAreaCoverage] = useState([])
  const [mapDetails, setMapDetails] = useState<any>('')
  const [type, setType] = useState('')
  const [KMLfiles, setKMLfiles] = useState<any>([])
  const [WarehouseArea, setWarehouseArea] = useState<any>('')

  useEffect(() => {
    getSingleWarehouse()
  }, [])

  const getKmlUrl = (kmlFullUrl: any, kmlBaseUrl: any) => {
    let kmlUrl = null
    if (kmlFullUrl) {
      if (kmlFullUrl.includes('http')) {
        kmlUrl = kmlFullUrl
      } else {
        kmlUrl = kmlBaseUrl + kmlFullUrl
      }
    }
    return kmlUrl
  }

  const getSingleWarehouse = async () => {
    // Develop BY: Kashyap
    // Description: get table list and dropdown list
    const url = `${apiBaseURL()}warehouse/`
    const response = await getRequest(url + id, true)

    let warehouseHeader: any = {
      warehouseName: response.name,
      warehouseType: response.warehouse_type,
      region: response.region !== null ? response.region.name : '',
    }

    setType(response.warehouse_type)

    let warehouseOverview: any = {
      warehouseType: response.warehouse_type,
      warehouseAddress: response.address,
      generalManager: response.warehouse_manager !== null ? response.warehouse_manager : '',
    }

    let warehouseAreaCoverage: any = {
      warehouseType: response.warehouse_type,
      warehouseAddress: response.address,
      generalManager: response.warehouse_manager !== null ? response.warehouse_manager : '',
    }
    let mapDetails: any = {
      kml_file: response.region !== null && response.region.kml_file,
      latitude: response.manual_latitude,
      longitude: response.manual_longitude,
    }

    setMapDetails(mapDetails)

    setOverView(warehouseOverview)
    setHeader(warehouseHeader)

    let totalWards = response.wards
    let masterData = response.region !== null ? response.region.district : []

    if (response.warehouse_type === 'Headquarter') {
      let WardsList: any = []
      let kmlFiles: any = []

      masterData.forEach((element: any) => {
        let obj: any = {}
        obj = element.name
        let obj2: any = []
        // kmlfiles
        let disKml: any = {}
        let wardKml: any = []
        disKml = element.kml_file !== null ? element.kml_file : ''
        element.ward.forEach((element: any) => {
          if (totalWards.includes(element.id)) {
            obj2.push(element.name)
            wardKml.push(
              element.kml_file !== null ? getKmlUrl(element.kml_file, element.base_url) : ''
            )
          }
        })
        let obj3 = {
          dist: obj,
          wards: obj2,
        }
        let distWardKmlFiles = {
          distKml: disKml,
          wardsKml: wardKml,
        }
        WardsList.push(obj3)
        kmlFiles.push(distWardKmlFiles)
      })
      // state
      let filterKmlFiles: any = []
      response.region !== null && filterKmlFiles.push(response.region.kml_file)
      kmlFiles.length !== 0 &&
        kmlFiles.forEach((element: any) => {
          // element.distKml !== "" && filterKmlFiles.push(serverKmlUrl + element.distKml)
          element.wardsKml.forEach((elementDis: any) => {
            // getKmlUrl(elementDis , "baseUrl")
            // elementDis !== "" && filterKmlFiles.push(serverKmlUrl + elementDis)
            elementDis !== '' && filterKmlFiles.push(elementDis)
          })
        })
      setKMLfiles(filterKmlFiles)
      setWarehouseArea({
        Warehouse_Boundary_Region: response.region !== null ? response.region.name : '-',
      })
      setAreaCoverage(WardsList)
    } else {
      let parentWarehouseId = response.parent_warehouse
      getSingleWarehouseFromParent(totalWards, parentWarehouseId)
    }
  }

  const getSingleWarehouseFromParent = async (wards: any, parentWarehouseId: any) => {
    const url = `${apiBaseURL()}warehouse/`

    const response = await getRequest(url + parentWarehouseId, true)

    let totalWards = wards
    let masterData = response.region !== null ? response.region.district : []
    let WardsList: any = []
    let kmlFiles: any = []

    masterData.forEach((element: any) => {
      let obj: any = {}
      obj = element.name
      let obj2: any = []
      // kmlfiles
      let disKml: any = {}
      let wardKml: any = []
      disKml = element.kml_file !== null ? element.kml_file : ''
      element.ward.forEach((element: any) => {
        if (totalWards.includes(element.id)) {
          obj2.push(element.name)
          wardKml.push(
            element.kml_file !== null ? getKmlUrl(element.kml_file, element.base_url) : ''
          )
        }
      })
      let obj3 = {
        dist: obj,
        wards: obj2,
      }
      let distWardKmlFiles = {
        distKml: disKml,
        wardsKml: wardKml,
      }
      WardsList.push(obj3)
      kmlFiles.push(distWardKmlFiles)
    })
    let filterKmlFiles: any = []
    response.region !== null && filterKmlFiles.push(response.region.kml_file)
    kmlFiles.length !== 0 &&
      kmlFiles.forEach((element: any) => {
        // element.distKml !== "" && filterKmlFiles.push(serverKmlUrl + element.distKml)
        element.wardsKml.forEach((elementDis: any) => {
          // getKmlUrl(elementDis , "baseUrl")
          // elementDis !== "" && filterKmlFiles.push(serverKmlUrl + elementDis)
          elementDis !== '' && filterKmlFiles.push(elementDis)
        })
      })
    setKMLfiles(filterKmlFiles)
    setWarehouseArea({
      Warehouse_Boundary_Region: response.region !== null ? response.region.name : '-',
    })
    setAreaCoverage(WardsList)
  }

  return (
    <>
      <PageTitle breadcrumbs={[customersHeaderTitle]}>
        {intl.formatMessage({id: 'Warehouse Name'})}
      </PageTitle>
      <WarehouseHeader header={header} />
      {/*    <Routes>
                <Route path='overview' element={<Overview />} />
                <Route path='sales-order' element={<SalesOrder />} />
                <Route path='return' element={<Return />} />
                <Route path='credit-issue' element={<CreditIssue />} />
                <Route path='order-feedback' element={<OrderFeedback />} />
                <Route path='complaint' element={<SupportRequest />} />
                <Route path='call-notes' element={<CallNotes />} />
                <Route index element={<Navigate to="overview" />} />
            </Routes>*/}
      <Routes>
        <Route
          path='overview'
          element={<TSWarehouseOverview overView={overView} className='test' />}
        />
        <Route
          path='area-coverage'
          element={
            <TSAreaCoverage
              warehouseType={type}
              mapDetails={mapDetails}
              getWardsDist={areaCoverage}
              KMLfiles={KMLfiles}
              WarehouseArea={WarehouseArea}
            />
          }
        />
        <Route path='team' element={<TSTeam />} />
        <Route path='sales-order' element={<TSSalesOrder />} />
        <Route path='stock' element={<TSStock />} />
        <Route path='credit-issues' element={<TSCreditIssues />} />
        <Route path='customers' element={<TSCustomers />} />
        <Route index element={<Navigate to='warehouses/overview' />} />
      </Routes>
    </>
  )
}

export default TSSingleWarehouse
