import {Navigate, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {useIntl} from 'react-intl'
import OdooWarehouse from './components/odooWarehouse'
import OdooProductGroup from './components/odooProductGroup'
import {OdooHeader} from './components/odooHeader'
import OdooCustomerCategory from './components/odooCustomerCategory'
import OdooProductSkus from './components/odooProductSkus'

const OddoConfigure = () => {
  const intl = useIntl()
  const oddoHeaderTitle = {
    title: 'Master - Odoo Config',
  }

  return (
    <>
      <PageTitle breadcrumbs={[oddoHeaderTitle]}>
        {intl.formatMessage({id: 'DMS to Odoo Mapping Config'})}
      </PageTitle>
      <OdooHeader />
      <Routes>
        <Route path='odoo-warehouse' element={<OdooWarehouse />} />
        <Route path='odoo-product-group' element={<OdooProductGroup />} />
        <Route path='odoo-customer-category' element={<OdooCustomerCategory />} />
        <Route path='odoo-product-sku' element={<OdooProductSkus />} />
        <Route index element={<Navigate to='odoo-configuration/odoo-warehouse' />} />
      </Routes>
    </>
  )
}
export default OddoConfigure
