import React, {useEffect, useState} from 'react'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../TenantVariables'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {useIntl} from 'react-intl'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {
  KTSVG,
  apiBaseURL,
  checkEmpty,
  checkUserType,
  dateFormat,
  dateTimeFormat,
  fetchAsyncDropdown,
  globalDateFormate,
  globalDateTimeFormate,
  isEmpty,
} from '../../../_eaFruitsDms/helpers'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {Modal} from 'react-bootstrap'
import {getRequest, patchRequest, postRequest} from '../../../_eaFruitsDms/https/apiCall'
import {AsyncPaginate} from 'react-select-async-paginate'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

const CallNotes = ({customerId, selectedCustomer, customerStatus}: any) => {
  const intl = useIntl()

  const [records, setRecords] = useState<any>([])
  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [fetchingRecords, setFetchingRecords] = useState(true)
  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [search, setSearch] = useState('')
  const [notes, setNotes] = useState('')
  const [notesValid, setNotesValid] = useState('')
  const [customerValid, setCustomerValid] = useState('')
  const [submit, setSubmit] = useState('Submit')
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [customer, setCustomer] = useState({label: 'Select', value: ''})
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [id, setId] = useState('')
  const [modalApiDate, setModalApiDate] = useState<any>({
    startDate: moment().format('YYYY-MM-DD hh:mm:ss A'),
  })

  const debounceSearch = useDebounce(search, 500)

  const fetchRecords = async () => {
    setFetchingRecords(true)

    const url = `${apiBaseURL()}customers/call-note/list?search=${debounceSearch}&limit=${limit}&offset=${currentOffset}${
      isEmpty(customerId) ? '' : `&customer_id=${customerId}`
    }`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setRecords(response.results)
      setCount(response.count)
    }

    setFetchingRecords(false)
  }

  const isModalClear = () => {
    setId('')
    setShow(false)
    setSubmit('Submit')
    setNotesValid('')
    setCustomerValid('')
    setNotes('')
    setCustomer({label: 'Select', value: ''})
    setModalApiDate({
      startDate: moment().format('YYYY-MM-DD hh:mm:ss A'),
    })
    setOffset(0)
    setList([])
  }

  const checkValid = () => {
    let valid = true

    if (isEmpty(customer.value)) {
      valid = false
      setCustomerValid('is-invalid')
    }

    if (isEmpty(notes.trim())) {
      valid = false
      setNotesValid('is-invalid')
    }

    return valid
  }

  const onSubmit = async () => {
    const isValid = checkValid()

    if (isValid) {
      setSubmit('Please Wait')

      const body = {
        customer: customer.value,
        notes: notes,
        received_at: moment(modalApiDate.startDate).utc(),
      }

      if (isEmpty(id)) {
        const url = `${apiBaseURL()}customers/call-note/create`
        const response = await postRequest(url, body, true)

        if (response.status === 201) {
          isModalClear()
          fetchRecords()
          setIsAlert(true)
          setMessage('Your call note created successfully.')
          setTimeout(() => {
            setIsAlert(false)
          }, 2000)
        }
      } else {
        const url = `${apiBaseURL()}customers/call-note/update/${id}`
        const body = {
          customer: customer.value,
          notes: notes,
          received_at: moment(modalApiDate.startDate).utc(),
        }

        const response = await patchRequest(url, body, true)

        if (response.status === 200) {
          isModalClear()
          fetchRecords()
          setIsAlert(true)
          setMessage('Your call note updated successfully.')
          setTimeout(() => {
            setIsAlert(false)
          }, 2000)
        }
      }
    }
  }

  const pinCallNotes = async (id: string, is_pin: boolean) => {
    const url = `${apiBaseURL()}customers/call-note/update/${id}`
    const body = {
      is_pin: !is_pin,
    }
    const response = await patchRequest(url, body, true)

    if (response.status === 200) {
      fetchRecords()
    }
  }

  const fetchCustomer = (search: string) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `customers/drop-down?ordering=business_name`,
      search,
      setDropDownSearch,
      'business_name',
      false
    )
  }

  const onEdit = async (items: any) => {
    const url = `${apiBaseURL()}customers/call-note/detail/${items.id}`
    const response = await getRequest(url, true)

    setId(items.id)

    if (response.result) {
      const {customer, notes, received_at} = response.result
      setCustomer({label: customer.business_name, value: customer.id})
      setNotes(notes)
      setModalApiDate({startDate: dateTimeFormat(received_at)})
      setShow(true)
    }
  }

  useEffect(() => {
    if (!isEmpty(selectedCustomer?.value)) {
      setCustomer(selectedCustomer)
    }
  }, [selectedCustomer, show])

  useEffect(() => {
    fetchRecords()
  }, [debounceSearch, currentOffset])

  return (
    <>
      <EATitle title='Call Notes' />
      <PageTitle
        breadcrumbs={[
          {
            title: 'Call Notes',
            subTitle: 'Call Notes',
          },
        ]}
      >
        {intl.formatMessage({id: 'Call Notes'})}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      <>
        <div className={`card`}>
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar
                onChange={(e: any) => {
                  setSearch(e.target.value)
                  setCurrentOffset(0)
                  setSelected(0)
                }}
                isDisable={fetchingRecords}
              />
              <div className='col-auto ms-auto'>
                <form
                  className=' card-toolbar d-flex my-0'
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete='off'
                >
                  {customerStatus &&
                  customerStatus === 'approved' &&
                  !checkUserType(['sa', 'wm']) ? (
                    <button className='btn btn-primary ms-3' onClick={() => setShow(true)}>
                      Add Call Note
                    </button>
                  ) : (
                    checkUserType(['ts']) &&
                    !customerStatus && (
                      <button className='btn btn-primary ms-3' onClick={() => setShow(true)}>
                        Add Call Note
                      </button>
                    )
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <>
                {fetchingRecords ? (
                  <FetchingRecords />
                ) : records.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='w-50px py-2'></th>
                        <th className='w-140px py-2'>Date & Time</th>
                        <th className='w-140px py-2'>Customer Name</th>
                        <th className='w-160px py-2'>Notes</th>
                        <th className='w-140px py-2'>Warehouse Name</th>
                        <th className='w-140px py-2'>Created by</th>
                        {isEmpty(customerId) && <th className='w-140px py-2'>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {records.map((item: any) => {
                        return (
                          <>
                            <tr>
                              <td className={isEmpty(customerId) ? '' : 'text-center'}>
                                <div
                                  onClick={() => {
                                    isEmpty(customerId) && pinCallNotes(item.id, item.is_pin)
                                  }}
                                >
                                  {item.is_pin ? (
                                    <i className='bi bi-pin-angle-fill text-primary cursor-pointer font-20'></i>
                                  ) : (
                                    <i className='bi bi-pin-angle cursor-pointer font-20'></i>
                                  )}
                                </div>
                              </td>
                              <td>
                                {isEmpty(item.received_at) ? '-' : dateTimeFormat(item.received_at)}
                              </td>
                              <td>
                                {isEmpty(item.customer) || isEmpty(item.customer.business_name)
                                  ? ''
                                  : item.customer.business_name}
                              </td>
                              <td>{checkEmpty(item.notes)}</td>
                              <td>{checkEmpty(item.warehouse_name)}</td>
                              <td>
                                {isEmpty(item.created_by_name)
                                  ? '-'
                                  : isEmpty(item.created_by_type)
                                  ? ''
                                  : `${
                                      item.created_by_name
                                    } (${item.created_by_type.toUpperCase()})`}
                              </td>
                              {isEmpty(customerId) && (
                                <td>
                                  <div
                                    onClick={() => {
                                      onEdit(item)
                                    }}
                                    className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm cursor-pointer me-2'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </div>
                                </td>
                              )}
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                )}
              </>
            </div>
          </div>
        </div>
        <CustomPaginate
          data={records}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={fetchingRecords}
          saveFilterName={''}
          filterStates={''}
        />
      </>

      <>
        <Modal
          show={show}
          onHide={isModalClear}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
          className='call-notes-modal'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>{isEmpty(id) ? 'Add' : 'Edit'} Call Note</h2>
            </Modal.Title>
            <div className='btn p-1 mh-24 btn-active-light-primary ms-2' onClick={isModalClear}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-sm-12 mb-4'>
                <label className='form-label'>
                  Customer Name<span className='text-danger'>*</span>
                </label>

                <AsyncPaginate
                  loadOptions={fetchCustomer}
                  isSearchable
                  placeholder='Select'
                  className={`react-select-container my-1 ${customerValid}`}
                  onChange={(e: any) => {
                    setCustomer(e)
                    setCustomerValid('')
                  }}
                  value={customer}
                  isDisabled={!isEmpty(id) || !isEmpty(selectedCustomer?.value)}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>
                  Notes<span className='text-danger'>*</span>
                </label>

                <textarea
                  id='notes'
                  className={`form-control ${notesValid}`}
                  rows={4}
                  placeholder='Type here…'
                  name='notes'
                  value={notes}
                  onChange={(e: any) => {
                    setNotes(e.target.value)
                    setNotesValid('')
                  }}
                ></textarea>
              </div>

              <div className='mb-4'>
                <label className='form-label' id='roleModal'>
                  Date & Time
                </label>
                <DateRangePicker
                  onCallback={(start, end, label) => {
                    const callBakeDate = {
                      startDate: start._d,
                    }

                    setModalApiDate(callBakeDate)
                  }}
                  initialSettings={{
                    startDate: moment(modalApiDate.startDate).format(globalDateTimeFormate()),
                    autoUpdateInput: true,
                    alwaysShowCalendars: true,
                    cancelClass: 'btn-secondary',
                    drops: 'auto',
                    parentEl: '.call-notes-modal',
                    maxDate: moment(),
                    locale: {
                      format: globalDateTimeFormate(),
                    },
                    timePicker: true,
                    singleDatePicker: true,
                  }}
                >
                  <input
                    type='text'
                    className='form-control calendar my-1'
                    defaultValue={modalApiDate}
                    disabled={!isEmpty(id)}
                  />
                </DateRangePicker>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={isModalClear}
                disabled={submit !== 'Submit'}
              >
                Cancel
              </button>
              <button className='btn btn-primary' onClick={onSubmit} disabled={submit !== 'Submit'}>
                {submit}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export default CallNotes
