import {AsyncPaginate} from 'react-select-async-paginate'
import React, {useState} from 'react'
import {getRequest} from '../../https/apiCall'
import {apiBaseURL, checkUserType} from '../CommonHelpers'
import tenantConfiguration from '../../../TenantVariables'

export const WarehouseFilter = ({
  warehouse,
  handleFilterChange,
  isDisabled,
  className,
  allWarehouse = true,
}: any) => {
  const currentWarehouseId = checkUserType(['sa', 'ts']) ? '' : localStorage.getItem('w_id')

  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouseOffSet, setWarehouseOffSet] = useState<number>(0)
  const [searchSelect, setSearchSelect] = useState<string>('')

  const fetchWarehouses = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
      setWarehouseOffSet(0)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${
        tenantConfiguration.limit
      }&offset=${warehouseOffSet}&parent_warehouse__id=${currentWarehouseId}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (allWarehouse && warehouses.length === 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  return (
    <>
      <AsyncPaginate
        loadOptions={fetchWarehouses}
        isDisabled={isDisabled}
        isSearchable
        className={`react-select-container ${className ? className : 'my-1 me-3 w-160px'}`}
        classNamePrefix='react-select'
        placeholder='All Warehouses'
        value={warehouse}
        onChange={(e: any) => handleFilterChange(e, 'warehouse')}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#0c78a4',
          },
        })}
      />
    </>
  )
}
