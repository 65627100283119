import React from "react"
import { useIntl } from "react-intl"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const Overview: React.FC = () => {
    const intl = useIntl()
    const teamHeaderTitle = {
        title: "Team members",
        subTitle: "Connor Lawson"
    }
    return (
        <>
            <PageTitle breadcrumbs={[teamHeaderTitle]} >{intl.formatMessage({ id: 'Connor Lawson' })}</PageTitle>
            <div className='card mb-5 mb-xl-10' >
                <div className='card-body py-4'>
                    <div className="row">
                        <div className="col-md-7">
                            <div className='row mb-6'>
                                <label className='col-md-6 font-14 text-muted'>Full Name</label>

                                <div className='col-md-6'>
                                    <span className='font-14 text-dark'>Martijn Dragonjer</span>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-md-6 font-14 text-muted'>Phone Number</label>
                                <div className='col-md-6 fv-row'>
                                    <a href='#' className='font-14 text-dark'>(813) 691-1517</a>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-md-6 font-14 text-muted'>Email</label>
                                <div className='col-md-6 fv-row'>
                                    <a href='#' className='font-14 text-dark'>
                                        eli@zeapcic.org
                                    </a>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-md-6 font-14 text-muted'>
                                    Gender
                                </label>
                                <div className='col-md-6 fv-row'>
                                    <span className='font-14 text-dark'>
                                        Male
                                    </span>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-md-6 font-14 text-muted'>Date of Birth</label>
                                <div className='col-md-6'>
                                    <span className='font-14 text-dark'>
                                        11 July 1993
                                    </span>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-md-6 font-14 text-muted'>Warehouse</label>
                                <div className='col-md-6'>
                                    <span className='font-14 text-dark'>
                                        Kinondoni
                                    </span>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-md-6 font-14 text-muted'>Position</label>
                                <div className='col-md-6'>
                                    <span className='font-14 text-dark'>
                                        Sales Supervisor
                                    </span>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-md-6 font-14 text-muted'>Mapped Wards & Brands</label>
                                <div className='col-md-6'>
                                    <div className='font-14 text-dark'>Ward 1, Ward 2 — Brand 1</div>
                                    <div className='font-14 text-dark'>Ward 3 — Brand 2</div>
                                    <div className='font-14 text-dark'>Ward 4 — Brand 3, Brand 4</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <p className='ps-5'>Map Goes to here...</p>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Overview
