import moment from 'moment'
import React from 'react'
import {connect} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {GLOBAL_DATE_FORMAT, KTSVG, isEmpty, numberFormat} from '../../../../../_eaFruitsDms/helpers'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {wareHouseId} from '../../../../../_eaFruitsDms/redux/action/warehoues/warehoues'

const TableWarehouse = (props: any) => {
  const navigate = useNavigate()

  // All roles permission's
  const {
    showOverview,
    showArea,
    showTeam,
    showSales,
    showTargets,
    showStock,
    showCustomers,
    showCredit,
    showVehicles,
  } = props.showSingleWH

  const SingleWareHouse = (item: any) => {
    if (
      showOverview ||
      showArea ||
      showTeam ||
      showSales ||
      showTargets ||
      showStock ||
      showCustomers ||
      showCredit ||
      showVehicles
    ) {
      props.wareHouseSaveId(item.id)
      localStorage.setItem('wId', item.id)
      if (showOverview) {
        navigate('/warehouse/DC/singlewarehousename/overview')
        localStorage.setItem('singleWarehousePath', '/warehouse/DC/singlewarehousename/overview')
      } else {
        if (showArea) {
          navigate('/warehouse/DC/singlewarehousename/area-coverage')
          localStorage.setItem(
            'singleWarehousePath',
            '/warehouse/DC/singlewarehousename/area-coverage'
          )
        } else {
          if (showTeam) {
            navigate('/warehouse/DC/singlewarehousename/team')
            localStorage.setItem('singleWarehousePath', '/warehouse/DC/singlewarehousename/team')
          } else {
            if (showSales) {
              navigate('/warehouse/DC/singlewarehousename/sales')
              localStorage.setItem('singleWarehousePath', '/warehouse/DC/singlewarehousename/sales')
            } else {
              if (showTargets) {
                navigate('/warehouse/DC/singlewarehousename/targets')
                localStorage.setItem(
                  'singleWarehousePath',
                  '/warehouse/DC/singlewarehousename/targets'
                )
              } else {
                if (showStock) {
                  navigate('/warehouse/DC/singlewarehousename/stock')
                  localStorage.setItem(
                    'singleWarehousePath',
                    '/warehouse/DC/singlewarehousename/stock'
                  )
                } else {
                  if (showCustomers) {
                    navigate('/warehouse/DC/singlewarehousename/warehouse-customers')
                    localStorage.setItem(
                      'singleWarehousePath',
                      '/warehouse/DC/singlewarehousename/warehouse-customers'
                    )
                  } else {
                    if (showCredit) {
                      navigate('/warehouse/DC/singlewarehousename/credit-issues')
                      localStorage.setItem(
                        'singleWarehousePath',
                        '/warehouse/DC/singlewarehousename/credit-issues'
                      )
                    } else {
                      if (showVehicles) {
                        navigate('/warehouse/DC/singlewarehousename/vehicles')
                        localStorage.setItem(
                          'singleWarehousePath',
                          '/warehouse/DC/singlewarehousename/vehicles'
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <>
      {props.getLength !== '1' ? (
        <NoRecords />
      ) : (
        <ul className='list-unstyled ps-0 wh-list'>
          {props.tableList.map((item: any) => {
            let fullName = '—'
            if (item.warehouse_manager.length !== 0) {
              fullName =
                item.warehouse_manager[0].first_name + ' ' + item.warehouse_manager[0].last_name
            }
            return (
              <li
                key={item.id}
                className='boxrow border-bottom mb-4 mb-md-5 pb-1 text-hover-bg-light'
                onClick={() => {
                  SingleWareHouse(item)
                }}
              >
                <div className='row mb-3'>
                  <div className='mb-2 mb-md-0 col-12 col-md-auto pe-md-5'>
                    <h3 className='font-20 font-bold mb-0'>{item.name}</h3>
                  </div>
                  {item.warehouse_type !== 'DistributionCenter' ? (
                    <div className='col-auto align-self-center ps-md-0 pe-md-5'>
                      <div className='d-flex align-items-center'>
                        <KTSVG path='/media/icons/duotune/general/gen018.svg' className='me-1' />
                        {item.region.name}
                      </div>
                    </div>
                  ) : null}
                  <div className='col-auto align-self-center ps-md-0 pe-md-5'>
                    <div className='d-flex align-items-center'>
                      <KTSVG path='/media/icons/duotune/ecommerce/ecm009.svg' className='me-1' />
                      {item.warehouse_type === 'DistributionCenter'
                        ? 'Distribution Center'
                        : item.warehouse_type}
                    </div>
                  </div>
                  {/* <div className="mt-3 mt-sm-0 col-12 col-sm-auto align-self-center ps-md-0 ms-sm-auto">
                                        <span>
                                            <KTSVG path="/media/icons/duotune/general/gen052.svg" className='' />
                                        </span>
                                    </div> */}
                </div>
                <div className='row'>
                  <div className='col-xl-2 col-lg-3 col-md-3 col-4 mb-4'>
                    <div className='font-13 font-semibold text-muted mb-1'>Parent Warehouse</div>
                    <div className='font-13 text-dark'>
                      {' '}
                      {item.parent_warehouse === null ? '—' : item.parent_warehouse_name}{' '}
                    </div>
                  </div>
                  <div className='col-xl-2 col-lg-3 col-md-3 col-4 mb-4'>
                    <div className='font-13 font-semibold text-muted mb-1'>Active Since</div>
                    <div className='font-13 text-dark'>
                      {moment(item.start_date).format(GLOBAL_DATE_FORMAT)}
                    </div>
                  </div>
                  {item.warehouse_type !== 'DistributionCenter' && (
                    <div className='col-xl-2 col-lg-3 col-md-3 col-4 mb-4'>
                      <div className='font-13 font-semibold text-muted mb-1'>Branch Manager</div>
                      <div className='font-13 text-dark'>{fullName}</div>
                    </div>
                  )}
                  <div className='col-xl-2 col-lg-3 col-md-3 col-4 mb-4'>
                    <div className='font-13 font-semibold text-muted mb-1'>Team Size</div>
                    <div className='font-13 text-dark'>
                      {isEmpty(item.team_size) ? '-' : numberFormat(item.team_size).displayFormat}
                    </div>
                  </div>
                  <div className='col-xl-2 col-lg-3 col-md-3 col-4 mb-4'>
                    <div className='font-13 font-semibold text-muted mb-1'>Customers</div>
                    <div className='font-13 text-dark'>
                      {isEmpty(item.customer_count)
                        ? '-'
                        : numberFormat(item.customer_count).displayFormat}
                    </div>
                  </div>
                  <div className='col-xl-2 col-lg-3 col-md-3 col-4 mb-4'>
                    <div className='font-13 font-semibold text-muted mb-1'>Vehicles</div>
                    <div className='font-13 text-dark'>
                      {isEmpty(item.vehicle_count)
                        ? '-'
                        : numberFormat(item.vehicle_count).displayFormat}
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    wareHouseSaveId: (val: any) => {
      dispatch(wareHouseId(val))
    },
  }
}

export default connect(null, mapDispatchToProps)(TableWarehouse)

{
  /* <span className='badge badge-light-success text-dark badge-sm'>Active</span> */
}
