import moment from "moment";
import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { AsyncPaginate } from "react-select-async-paginate";
import { KTSVG } from "../../../../../_eaFruitsDms/helpers";
import EaButton from "../../../../../_eaFruitsDms/layout/components/button/eaButton";
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination";

const fetchRoles = async () => {
    let options: any = []
    let hasMore: boolean = false
    return {
        options: [{ value: 'option1', label: 'All Feedback Pending' }, { value: 'option2', label: 'All Feedback Approve' },],
        hasMore: false,
    }
}

const TSSalesOrder: React.FC = () => {
    return (
        <>

            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <div>
                        <input type="text" className='form-control form-search' placeholder='Search' />
                    </div>
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <div className='me-3'>
                                <AsyncPaginate
                    
                                    loadOptions={fetchRoles}
                                    isSearchable
                                    className='react-select-container w-190px'
                                    classNamePrefix="react-select"
                                    menuPosition="fixed"
                                    name="role"
                                    placeholder='All Feedback Pending'
                                    // value={roles.filter((option: any) => option.value === roleId)}
                                    // onChange={handleRoleChange}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                            </div>
                            <div>
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2020',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar" />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-150px'>Invoice ID</th>
                                    <th className='min-w-140px'>Order Date</th>
                                    <th className='min-w-120px'>Sales Representative</th>
                                    <th className='min-w-120px'>Invoice Amount</th>
                                    <th className='min-w-120px'>Order Feedback</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>
                                    <td>
                                        #84419
                                    </td>
                                    <td>
                                        08 Apr 2022
                                    </td>
                                    <td>
                                        Yamaha Toshinobu
                                    </td>
                                    <td>
                                        TZS 75000
                                    </td>
                                    <td>
                                        <EaButton isModal={false} btnName='Add Order Feedback'></EaButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        #84419
                                    </td>
                                    <td>
                                        08 Apr 2022
                                    </td>
                                    <td>
                                        Yamaha Toshinobu
                                    </td>
                                    <td>
                                        TZS 75000
                                    </td>
                                    <td>
                                        <EaButton isModal={false} btnName='Add Order Feedback'></EaButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        #84419
                                    </td>
                                    <td>
                                        08 Apr 2022
                                    </td>
                                    <td>
                                        Yamaha Toshinobu
                                    </td>
                                    <td>
                                        TZS 75000
                                    </td>
                                    <td>
                                        <EaButton isModal={false} btnName='Add Order Feedback'></EaButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        #84419
                                    </td>
                                    <td>
                                        08 Apr 2022
                                    </td>
                                    <td>
                                        Yamaha Toshinobu
                                    </td>
                                    <td>
                                        TZS 75000
                                    </td>
                                    <td>
                                        <KTSVG path="/media/icons/duotune/general/gen029.svg" className="ic text-warning me-1" />
                                        4.2
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        #84419
                                    </td>
                                    <td>
                                        08 Apr 2022
                                    </td>
                                    <td>
                                        Yamaha Toshinobu
                                    </td>
                                    <td>
                                        TZS 75000
                                    </td>
                                    <td>
                                        <KTSVG path="/media/icons/duotune/general/gen029.svg" className="ic text-warning me-1" />
                                        3.8
                                    </td>
                                </tr>


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className='table-info py-5'>
                <div className='row g-0'>
                    <div className='col'>
                        Showing 1 to 10 of entries
                    </div>
                    <div className='col'>
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TSSalesOrder
