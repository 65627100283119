import moment from 'moment'
import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Props } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import { EaStatisticsWidget } from '../../../../_eaFruitsDms/partials/widgets'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { KTSVG } from '../../../../_eaFruitsDms/helpers'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'

const fetchStatus = async (search: any) => {
    let options: any = []
    options.push(
        { label: "N/A", value: "na" },
        { label: "Paid", value: "paid" },
    )
    return {
        options: options,
        hasMore: false,
    }
}

const fetchAchieved = async (search: any) => {
    let options: any = []
    options.push(
        { label: "All Achieved", value: "allachieved" },
        { label: "option1", value: "option1" },
        { label: "option2", value: "option2" },
    )
    return {
        options: options,
        hasMore: false,
    }
}

const TargetReport: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const headerTitle = {
        title: 'Targets',
        subTitle: 'Targets',
    }

    return (
        <>
            <EATitle title='Targets' />
            <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({ id: 'Targets' })}</PageTitle>
            <div className='row g-3 g-xl-5'>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='success'
                        title='TZS 10,000,000.00'
                        path='/'
                        description='Total Incentive Payable'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='info'
                        title='TZS 9,998,000.00'
                        description='Total Incentive Paid'
                        path='/'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='dark'
                        title='TZS 10,000.00'
                        description='Incentive Payable This Month'
                        path='/'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='warning'
                        title='TZS 8,000.00'
                        description='Incentive Paid This Month'
                        path='/'
                    />
                </div>
            </div>
            <div className={`card ${className}`}>
                {/* {displayLoader && <IsLoader />} */}
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: '01/01/2020',
                                        endDate: '01/12/2020',
                                        alwaysShowCalendars: true,
                                        cancelClass: 'btn-secondary',
                                        drops: "auto",
                                        locale: {
                                            format: 'DD/MM/YYYY'
                                        },
                                        ranges: {
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                        },
                                    }}
                            >
                                <input type="text" className="form-control calendar my-1 me-3 w-auto" />
                            </DateRangePicker>
                            <AsyncPaginate
                                loadOptions={fetchAchieved}
                                isSearchable
                                placeholder="All Achieved"
                                className='react-select-container my-1 w-130px me-3'
                                classNamePrefix='react-select'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <AsyncPaginate
                                loadOptions={fetchStatus}
                                isSearchable
                                placeholder="All Paid"
                                className='react-select-container my-1 w-100px me-3'
                                classNamePrefix='react-select'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 fw-600 d-flex align-items-center">
                                <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                Export</button>
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive expandable' style={{ 'transition': '.5s linear all', 'borderCollapse': 'collapse' }} id="expandTable">
                        <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-120px w-120px'>SR Name</th>
                                    <th className='w-160px min-w-160px'>Target</th>
                                    <th className='w-130px min-w-130px'>Target Revenue</th>
                                    <th className='w-100px min-w-100px'>Commission</th>
                                    <th className='w-120px min-w-120px'>Target Division</th>
                                    <th className='w-140px min-w-140px'>Incentive Payable</th>
                                    <th className='w-120px min-w-120px text-end'>Status</th>
                                    <th className='w-60px text-end'><span className='invisible'>hidn</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    data-bs-toggle="collapse"
                                    data-bs-target=".row-collapse1"
                                    aria-controls="rowCollapse1"
                                    className='cursor-pointer'
                                >
                                    <td>
                                        Emily Cruz
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            <span> 1 Product Group</span>
                                            <OverlayTrigger
                                                delay={{ hide: 450, show: 300 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className='tooltip-dark'>
                                                        text
                                                    </Tooltip>
                                                )}
                                                placement='top'
                                            >
                                                <span className='ms-2'>
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen045.svg'
                                                        className='ic text-primary mr-0'
                                                    />
                                                </span>

                                            </OverlayTrigger>
                                        </div>
                                    </td>
                                    <td>
                                        TZS 10,000,000
                                    </td>
                                    <td>
                                        2.00 %
                                    </td>
                                    <td>
                                        <span className='text-success'>80%</span>
                                    </td>
                                    <td>
                                        <span className='text-success'>TZS 201</span>
                                    </td>
                                    <td className='text-end'>
                                        <span className='badge badge-light-warning text-dark'>Pending</span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr className='expandable-row'>
                                    <td className="reset-expansion-style" colSpan={9}>
                                        <div className='row-expansion-style collapse row-collapse1 collapsible' id="rowCollapse1" data-bs-parent="#expandTable">
                                            <div>
                                                <table className='table table-bordered gs-4 mb-0'>
                                                    <thead>
                                                        <tr className='text-muted fw-500 bg-light2'>
                                                            <th className='w-200px py-2'>KPI</th>
                                                            <th className='w-200px py-2'>Target</th>
                                                            <th className='w-200px min-w-120px py-2'>Achieved</th>
                                                            <th className='w-200px min-w-120px py-2'>Incentive Amt.</th>
                                                            <th className='w-200px min-w-120px py-2 text-end pe-0'>
                                                                <button className='btn p-0 '>
                                                                    <KTSVG path='/media/icons/duotune/files/fil021.svg' className='ic mr-0' />
                                                                </button>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Revenue (80%)</td>
                                                            <td><span className='text-danger'>8,000,000</span></td>
                                                            <td><span className='text-danger'>7,800,000</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>New Customers (2%)</td>
                                                            <td><span className='text-danger'>20 Customers</span></td>
                                                            <td><span className='text-danger'>14 Customers</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Return Rate (2.25%)</td>
                                                            <td><span className='text-danger'>10%</span></td>
                                                            <td><span className='text-danger'>12%</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Succesful Calls (2.25%)</td>
                                                            <td>110 Calls</td>
                                                            <td>141 Calls</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>No Credit (2.25%)</td>
                                                            <td>10%</td>
                                                            <td>9.8%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pre-Orders (2.25%)</td>
                                                            <td>120 Pre-Orders</td>
                                                            <td>122 Pre-Orders</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Un Cred (2.25%)</td>
                                                            <td><span className='text-danger'>10%</span></td>
                                                            <td><span className='text-danger'>11.2%</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Un Disc (2.25%)</td>
                                                            <td>10%</td>
                                                            <td>9.2%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Discounts (2.25%)</td>
                                                            <td>10%</td>
                                                            <td>8%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Attendance (2.25%)</td>
                                                            <td>85%</td>
                                                            <td>97%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td><span className='fw-500'>Total</span></td>
                                                            <td><span className='fw-500'>TZS 600.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {/* end first row expand */}
                                <tr
                                    data-bs-toggle="collapse"
                                    data-bs-target=".row-collapse2"
                                    aria-controls="rowCollapse2"
                                    className='cursor-pointer'
                                >
                                    <td>
                                        Emily Cruz
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            <span> 1 Product Group</span>
                                            <OverlayTrigger
                                                delay={{ hide: 450, show: 300 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className='tooltip-dark'>
                                                        text
                                                    </Tooltip>
                                                )}
                                                placement='top'
                                            >
                                                <span className='ms-2'>
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen045.svg'
                                                        className='ic text-primary mr-0'
                                                    />
                                                </span>

                                            </OverlayTrigger>
                                        </div>
                                    </td>
                                    <td>
                                        TZS 10,000,000
                                    </td>
                                    <td>
                                        2.00 %
                                    </td>
                                    <td>
                                        <span className='text-success'>80%</span>
                                    </td>
                                    <td>
                                        <span className='text-success'>TZS 201</span>
                                    </td>
                                    <td className='text-end'>
                                        <span className='badge badge-secondary text-dark'>N/A</span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr className='expandable-row'>
                                    <td className="reset-expansion-style" colSpan={9}>
                                        <div className='row-expansion-style collapse row-collapse2 collapsible' id="rowCollapse2" data-bs-parent="#expandTable">
                                            <div>
                                                <table className='table table-bordered gs-4 mb-0'>
                                                    <thead>
                                                        <tr className='text-muted fw-500 bg-light2'>
                                                            <th className='w-200px py-2'>KPIs (Weightage)</th>
                                                            <th className='w-200px py-2'>Target</th>
                                                            <th className='w-200px min-w-120px py-2'>Achieved</th>
                                                            <th className='w-200px min-w-120px py-2'>Incentive Amt.</th>
                                                            <th className='w-200px min-w-120px py-2 text-end pe-0'>
                                                                <button className='btn p-0 '>
                                                                    <KTSVG path='/media/icons/duotune/files/fil021.svg' className='ic mr-0' />
                                                                </button>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Revenue (80%)</td>
                                                            <td><span className='text-danger'>8,000,000</span></td>
                                                            <td><span className='text-danger'>7,800,000</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>New Customers (2%)</td>
                                                            <td><span className='text-danger'>20 Customers</span></td>
                                                            <td><span className='text-danger'>14 Customers</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Return Rate (2.25%)</td>
                                                            <td><span className='text-danger'>10%</span></td>
                                                            <td><span className='text-danger'>12%</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Succesful Calls (2.25%)</td>
                                                            <td>110 Calls</td>
                                                            <td>141 Calls</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>No Credit (2.25%)</td>
                                                            <td>10%</td>
                                                            <td>9.8%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pre-Orders (2.25%)</td>
                                                            <td>120 Pre-Orders</td>
                                                            <td>122 Pre-Orders</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Un Cred (2.25%)</td>
                                                            <td><span className='text-danger'>10%</span></td>
                                                            <td><span className='text-danger'>11.2%</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Un Disc (2.25%)</td>
                                                            <td>10%</td>
                                                            <td>9.2%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Discounts (2.25%)</td>
                                                            <td>10%</td>
                                                            <td>8%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Attendance (2.25%)</td>
                                                            <td>85%</td>
                                                            <td>97%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td><span className='fw-500'>Total</span></td>
                                                            <td><span className='fw-500'>TZS 600.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {/* end second row expand */}
                                <tr
                                    data-bs-toggle="collapse"
                                    data-bs-target=".row-collapse3"
                                    aria-controls="rowCollapse3"
                                    className='cursor-pointer'
                                >
                                    <td>
                                        Emily Cruz
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            <span> 1 Product Group</span>
                                            <OverlayTrigger
                                                delay={{ hide: 450, show: 300 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props} className='tooltip-dark'>
                                                        text
                                                    </Tooltip>
                                                )}
                                                placement='top'
                                            >
                                                <span className='ms-2'>
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen045.svg'
                                                        className='ic text-primary mr-0'
                                                    />
                                                </span>

                                            </OverlayTrigger>
                                        </div>
                                    </td>
                                    <td>
                                        TZS 10,000,000
                                    </td>
                                    <td>
                                        2.00 %
                                    </td>
                                    <td>
                                        <span className='text-success'>80%</span>
                                    </td>
                                    <td>
                                        <span className='text-success'>TZS 201</span>
                                    </td>
                                    <td className='text-end'>
                                        <span className='badge badge-light-success text-dark'>Paid</span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr className='expandable-row'>
                                    <td className="reset-expansion-style" colSpan={9}>
                                        <div className='row-expansion-style collapse row-collapse3 collapsible' id="rowCollapse3" data-bs-parent="#expandTable">
                                            <div>
                                                <table className='table table-bordered gs-4 mb-0'>
                                                    <thead>
                                                        <tr className='text-muted fw-500 bg-light2'>
                                                            <th className='w-200px py-2'>KPIs (Weightage)</th>
                                                            <th className='w-200px py-2'>Target</th>
                                                            <th className='w-200px min-w-120px py-2'>Achieved</th>
                                                            <th className='w-200px min-w-120px py-2'>Incentive Amt.</th>
                                                            <th className='w-200px min-w-120px py-2 text-end pe-0'>
                                                                <button className='btn p-0 '>
                                                                    <KTSVG path='/media/icons/duotune/files/fil021.svg' className='ic mr-0' />
                                                                </button>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Revenue (80%)</td>
                                                            <td><span className='text-danger'>8,000,000</span></td>
                                                            <td><span className='text-danger'>7,800,000</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>New Customers (2%)</td>
                                                            <td><span className='text-danger'>20 Customers</span></td>
                                                            <td><span className='text-danger'>14 Customers</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Return Rate (2.25%)</td>
                                                            <td><span className='text-danger'>10%</span></td>
                                                            <td><span className='text-danger'>12%</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Succesful Calls (2.25%)</td>
                                                            <td>110 Calls</td>
                                                            <td>141 Calls</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>No Credit (2.25%)</td>
                                                            <td>10%</td>
                                                            <td>9.8%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pre-Orders (2.25%)</td>
                                                            <td>120 Pre-Orders</td>
                                                            <td>122 Pre-Orders</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Un Cred (2.25%)</td>
                                                            <td><span className='text-danger'>10%</span></td>
                                                            <td><span className='text-danger'>11.2%</span></td>
                                                            <td><span className='text-danger'>TZS 0.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Un Disc (2.25%)</td>
                                                            <td>10%</td>
                                                            <td>9.2%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Discounts (2.25%)</td>
                                                            <td>10%</td>
                                                            <td>8%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Attendance (2.25%)</td>
                                                            <td>85%</td>
                                                            <td>97%</td>
                                                            <td>TZS 100.00</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td><span className='fw-500'>Total</span></td>
                                                            <td><span className='fw-500'>TZS 600.00</span></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {/* end third row expand */}
                            </tbody>
                        </table>

                    </div>
                    {/* end::Table container */}
                </div>

            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TargetReport