import {Placement} from 'react-bootstrap/esm/types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {isEmpty} from '../../../helpers'

interface Props {
  message: string
  placement: Placement
  item: any
}

const TooltipText = (props: Props) => {
  const {message, placement, item} = props

  return (
    <>
      {!isEmpty(message) && (
        <div className='me-3'>
          <OverlayTrigger
            delay={{hide: 450, show: 300}}
            overlay={(props) => (
              <Tooltip {...props} className='tooltip-dark'>
                <span>{message}</span>
              </Tooltip>
            )}
            placement={placement ? placement : 'left'}
          >
            <span className='cursor-pointer'>{item}</span>
          </OverlayTrigger>
        </div>
      )}
    </>
  )
}

export default TooltipText
