import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {dailyStockInOutReport} from '../../../../_eaFruitsDms/apiFunctions/reports/dailyStockInReport'
import {apiBaseURL} from '../../../../_eaFruitsDms/helpers'
import {
  dateFormat,
  GLOBAL_DATE_FORMAT,
  globalDateFormate,
  staticSearch,
} from '../../../../_eaFruitsDms/helpers/CommonHelpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getroleId, getW_Id} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {stockInOutType} from '../../customers/WMcustomers/CustomerConst'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'

type Props = {
  className: string
}
const DailyStockOutReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Daily Stock-Out Report',
  }

  const [getDailyStockInList, setDailyStockInList] = useState<any>([])
  const [count, setCount] = useState<any>('')
  const [next, setNext] = useState<any>('')
  const [prev, setPrev] = useState<any>('')
  const [selected, setSelected] = useState(0)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [getLength, setLength] = useState<any>('1')
  const [getOOsList, setOOsList] = useState<any>([])
  const [timer, setTimer] = useState<any>(null)
  const [getIndusialOOs, setIndusialOOs] = useState<any>('')
  const [startDate, setDate] = useState<any>(moment(Date.now()).format('YYYY-MM-DD'))
  const [listLoadder, setListLoader] = useState<any>(true)
  const [stockType, setStockType] = useState('')
  let todayDate = moment(Date.now()).format('YYYY-MM-DD')
  const [stockOutOffset, setStockOutOffset] = useState<any>('')
  const [limit, setLimit] = useState<number>(10)
  const [OperationOfficerOption, setOperationOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [search, setSearch] = useState('')

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    setStockOutOffset('')
  }, [searchSelect])

  useEffect(() => {
    setListLoader(true)
    dailyStockInOutReport(
      debounceSearch,
      '',
      stockType,
      'Out',
      '',
      startDate,
      '',
      getIndusialOOs,
      localStorage.getItem('w_id'),
      '',
      '',
      limit,
      currentOffset
    ).then((res) => {
      setDailyStockInList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
    // getDropDownValues()
  }, [getIndusialOOs, stockType, startDate, debounceSearch, currentOffset])

  const handlechangeStockType = (e: any) => {
    setStockType(e.value)
  }

  const handlechangeOperationOfficer = (e: any) => {
    setIndusialOOs(e.value)
  }

  // Develop BY: Kriti
  // Description: fetch stock out type drop-down's options which is called on AsyncPaginate

  const fetchStockOutType = async (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        value: '',
        label: 'All Stock Out Types',
      },
      {
        value: 'LoadOutRequest',
        label: 'Load Out Request',
      },
      {
        value: 'WarehouseTransfer',
        label: 'Warehouse Transfer',
      },
    ]
    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  // Develop BY: Kriti
  // Description: fetch operational officer drop-down's options which is called on AsyncPaginate

  const fetchOperationOfficer = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []

    const STOCK_OUT_API = `${apiBaseURL()}auth/users/team/list?role__id=${getroleId()}&warehouse__id=${localStorage.getItem(
      'w_id'
    )}&limit=${limit}&search=${search}&offset=${stockOutOffset}`
    const Response = await getRequest(STOCK_OUT_API, true)
    let hasMore: boolean = false

    if (Response.next !== null) {
      const queryParams = new URLSearchParams(Response.next)
      let newOffset: any = queryParams.get('offset')
      setStockOutOffset(newOffset)
      hasMore = true
    }

    if (OperationOfficerOption.length === 0) {
      options.push({
        label: 'From All OOs',
        value: '',
      })
    }

    if (Response.results.length > 0) {
      Response.results.map((option: any) => {
        options.push({
          label: option.first_name + ' ' + option.last_name,
          value: option.id,
        })
      })
    }
    if (OperationOfficerOption.length > 0) {
      setOperationOption(OperationOfficerOption.concat(options))
    } else {
      setOperationOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const searchList = (e: any) => {
    setSearch(e.target.value)
  }

  return (
    <>
      <EATitle title='Daily Stock-Out Report' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Daily Stock-Out Report'})}
      </PageTitle>
      <div className='card'>
        {listLoadder && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          {/* <CustomSearchBar /> */}
          <div className='col-12 col-md-auto mt-0'>
            <input
              type='text'
              placeholder='Search'
              className='form-control form-search'
              onChange={searchList}
            />
          </div>
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <DateRangePicker
                onCallback={(dateView: any, dateApi: any) => {
                  setDate(moment(dateView._d).format('YYYY-MM-DD'))
                }}
                initialSettings={{
                  startDate: {startDate},
                  // endDate: {endDate},
                  alwaysShowCalendars: true,
                  maxDate: moment(Date.now()),
                  cancelClass: 'btn-secondary',
                  singleDatePicker: true,
                  drops: 'auto',
                  locale: {
                    format: GLOBAL_DATE_FORMAT,
                  },
                }}
              >
                <input type='text' className='form-control calendar my-1 me-3 w-140px' />
              </DateRangePicker>
              <AsyncPaginate
                loadOptions={fetchStockOutType}
                isSearchable
                className='react-select-container my-1 me-3 w-180px'
                classNamePrefix='react-select'
                placeholder='All Stock Out Types'
                onChange={handlechangeStockType}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchOperationOfficer}
                isSearchable
                className='react-select-container my-1 w-150px'
                classNamePrefix='react-select'
                placeholder='From All OOs'
                value={OperationOfficerOption.filter(
                  (option: any) => option.value === getIndusialOOs
                )}
                onChange={handlechangeOperationOfficer}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div
            className='table-responsive expandable'
            style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
            id='expandTable'
          >
            {getDailyStockInList.length == 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                <thead>
                  <tr className=' text-muted fw-500'>
                    <th className='min-w-100px py-2'>ID</th>
                    <th className='min-w-100px py-2'>Stock-Out Date & Time</th>
                    <th className='min-w-100px py-2'>Type</th>
                    <th>Request From</th>
                    <th className='min-w-100px py-2'>OO Name</th>
                  </tr>
                </thead>{' '}
                <tbody>
                  {getDailyStockInList.length > 0 &&
                    getDailyStockInList.map((item: any) => {
                      return (
                        <>
                          <tr
                            data-bs-toggle='collapse'
                            data-bs-target={`.row-collapse${item.id}`}
                            aria-controls={`rowCollapse${item.id}`}
                            className='cursor-pointer'
                          >
                            <td>
                              {' '}
                              {item.stock_id.includes('#') ? item.stock_id : '#' + item.stock_id}
                            </td>

                            <td>
                              {item.length !== 0 ? (
                                <>
                                  {dateFormat(item.stock_date).displayDate}{' '}
                                  <p className='font-13 text-muted mb-0'>
                                    {dateFormat(item.stock_date, 'LT').displayDate}
                                  </p>
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {item.stock_type.length !== 0 ? (
                                <>{stockInOutType(item.stock_type)}</>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {item.stock_type === 'WarehouseTransfer'
                                ? item.receiver_warehouse_id && item.receiver_warehouse_id.name
                                : item.sales_representative
                                ? item.sales_representative.first_name +
                                  ' ' +
                                  item.sales_representative.last_name
                                : '-'}
                            </td>
                            <td>
                              {item.action_by.first_name} {item.action_by.last_name}
                            </td>
                          </tr>
                          <tr className='expandable-row'>
                            <td className='reset-expansion-style' colSpan={9}>
                              <div
                                className={`row-expansion-style collapse row-collapse${item.id} collapsible`}
                                id={`rowCollapse${item.id}`}
                                data-bs-parent='#expandTable'
                              >
                                <div>
                                  <table className='table table-bordered gs-4 mb-0'>
                                    <thead>
                                      <tr className='text-muted fw-500 bg-light2'>
                                        <th className='w-200px py-2'>Product SKU</th>
                                        <th className='w-120px py-2'>Batch Number</th>
                                        <th className='min-w-120px w-120px py-2'>Qty.</th>
                                        <th className='min-w-120px w-120px py-2'>Expiry Date</th>
                                        {/* <th className='min-w-120px py-2'>Added Date</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.stock_entries.map((data: any) => {
                                        return (
                                          <tr>
                                            <td>{data.product_id && data.product_id.name}</td>
                                            <td>
                                              {' '}
                                              {data.batch_number.includes('#')
                                                ? data.batch_number
                                                : '#' + data.batch_number}{' '}
                                            </td>
                                            <td>{data.qty}</td>
                                            <td>
                                              {' '}
                                              {item.length !== 0
                                                ? moment(data.expiry_date).format(
                                                    globalDateFormate()
                                                  )
                                                : '-'}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {/* end first row expand */}

                          {/* end first row expand */}
                        </>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getDailyStockInList.length == 0 ? 0 : selected * 10 + 1} to ${
              getDailyStockInList.length == 0
                ? 0
                : getDailyStockInList.length < 10
                ? count
                : (selected + 1) * 10
            } of ${getDailyStockInList.length == 0 ? 0 : count} entries`}
          </div>{' '}
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getDailyStockInList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DailyStockOutReport
