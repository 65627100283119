import { useEffect, useState } from "react"
import { GLOBAL_DATE_FORMAT, KTSVG, apiBaseURL, dateFormat, retrieveFilter, saveFilter } from "../../../../../_eaFruitsDms/helpers"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import tenantConfiguration from "../../../../../TenantVariables"
import { filters } from "../../../../../_eaFruitsDms/utils/FilterConstants"
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce"
import CustomPaginate from "../../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import moment from "moment"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import ResetButton from "../../../../../_eaFruitsDms/layout/components/button/resetButton"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"

const SalesOrderFeedback = () => {
    let ordersFiltered: any = retrieveFilter(filters.sa_telesales_salesorderfeedback)
    const teleUserId = localStorage.getItem("teleUserId")

    const initialState = {
        search: ordersFiltered?.search || '',
        selected: ordersFiltered?.selected || '',
        currentOffSet: ordersFiltered?.currentOffSet || '',
        buttonDisable: true,
      }
    
    const [limit, setLimit] = useState(tenantConfiguration.limit)
    const [currentOffSet, setCurrentOffSet] = useState<any>(ordersFiltered?.currentOffSet || '0')
    const [preOrders, setPreOrders] = useState<any>([])
    const [count, setCount] = useState<string>('1')
    const [selected, setSelected] = useState<number>(ordersFiltered?.selected || 0)
    const [searchByName, setSearchByName] = useState<string>(ordersFiltered?.search || '')
    const [displayLoader, setDisplayLoader] = useState<boolean>(false)
    const [filterStates, setFilterStates] = useState<any>(initialState)
      const [buttonDisable, setButtonDisable] = useState<boolean>(
        ordersFiltered?.buttonDisable || false
      )
    const debounceSearch = useDebounce(searchByName, 500)

    useEffect(() => {
        fetchSalesOrderFeedback().then()
      }, [currentOffSet, debounceSearch])

    const fetchSalesOrderFeedback: any = async () => {
        let salesOrderListAPI = `${apiBaseURL()}preorder/pre_order/list?limit=${limit}&offset=${currentOffSet}&ordering=&search=${debounceSearch}&created_by=${teleUserId}`
    
        setDisplayLoader(true)
    
        const preOrderResponse = await getRequest(salesOrderListAPI, true)
    
        setPreOrders(preOrderResponse.results)
        setCount(preOrderResponse.count)
        setDisplayLoader(false)
        
        let updatedState: any = {...filterStates}
        updatedState['selected'] = selected
        updatedState['currentOffSet'] = currentOffSet
        updatedState['buttonDisable'] = buttonDisable
    
        setFilterStates(updatedState)
        saveFilter(filters.sa_telesales_salesorderfeedback, filterStates)
    
        if (searchByName == '') {
          setButtonDisable(false)
        }
      }

      const filterChangeHandler = (e: any) => {
        setCurrentOffSet('')
        setSelected(0)
        setButtonDisable(true)
        setSearchByName(e.target.value)
        setFilterStates({...filterStates, ['search']: e.target.value})
      }

      const handleChangeReset = () => {
        setSearchByName('')
        setCurrentOffSet(0)
        setSelected(0)
      
        let updatedState: any = {...filterStates}
        updatedState['search'] = ''
        updatedState['selected'] = ''
        updatedState['currentOffSet'] = ''
        updatedState['buttonDisable'] = ''
        setFilterStates(updatedState)
        setButtonDisable(false)
      }

    return (
        <>
            <div className='card'>
            {displayLoader && <IsLoader />}
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar value={searchByName} isDisable={displayLoader}  onChange={filterChangeHandler}/>
                    <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        {preOrders.length == 0 ? <NoRecords/> : 
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='text-muted fw-500'>
                                <th className='min-w-150px py-2'>Date & Time</th>
                                <th className='min-w-100px py-2'>Order ID</th>
                                <th className='min-w-150px py-2'>Customer Name</th>
                                <th className='min-w-150px py-2'>Warehouse Name</th>
                                <th className='min-w-150px py-2'>Rating</th>
                                <th className='min-w-120px py-2'>Reviews</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {preOrders.map((preOrder :  any) =>{
                               return(
                                <tr>
                                <td>{dateFormat(preOrder.created_at).displayDate}
                                    <p className='font-13 text-muted mb-0'>{dateFormat(preOrder.created_at, 'LT').displayDate}</p>
                                </td>
                                <td>
                                    {'#' + preOrder.id}
                                </td>
                                <td>
                                    {preOrder.customer.business_name ? preOrder.customer.business_name : '-'}
                                </td>
                                <td>
                                    {preOrder.warehouse.length > 0 && preOrder.warehouse[0].name ? preOrder.warehouse[0].name : '-' }
                                </td>
                                <td>
                                {preOrder.feedback.length > 0 && preOrder.feedback[0].rating ? 
                                 <><KTSVG path="/media/icons/duotune/general/gen029.svg" className="ic text-warning me-1" />
                                    {preOrder.feedback.length > 0 && preOrder.feedback[0].rating}</>
                                : '-' }</td> 
                                <td>
                                   {preOrder.feedback.length > 0 && preOrder.feedback[0].review ? preOrder.feedback[0].review : '-'}
                                </td>
                            </tr>
                               )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                        }         
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <CustomPaginate
                data={preOrders}
                selected={selected}
                setSelected={setSelected}
                setCurrentOffset={setCurrentOffSet}
                totalRecords={count}
                limit={limit}
                saveFilterName={filters.sa_telesales_salesorderfeedback}
                filterStates={filterStates}
                isDisabled={displayLoader}
            />  
        </>
    )
}

export default SalesOrderFeedback
