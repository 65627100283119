import React from 'react'
import { useIntl } from 'react-intl'
import CustomPagination from '../../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../../_eaFruitsDms/layout/core'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import Select from 'react-select'
import { Helmet } from 'react-helmet'
import { EaStatisticsWidget } from '../../../../../_eaFruitsDms/partials/widgets'
import { useNavigate } from 'react-router-dom'

const sroptions = [
    { value: 'allsalesresp', label: 'All Sales Reps' },
    { value: 'sr1', label: 'Dusya Sigachyova' },
    { value: 'sr2', label: 'Thomas Kukabango' },
    { value: 'sr3', label: 'Neeshaan El Pasha' },
]


const warehouseoptions = [
    { value: 'all', label: 'All Warehouses' },
    { value: 'Warehouse1', label: 'Warehouse 1' },
    { value: 'Warehouse2', label: 'Warehouse 2' },
    { value: 'Warehouse3', label: 'Warehouse 3' },
]

type Props = {
    className: string
}
const ReportsBifurcation: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Mobile Wallet Receivable"
    }
    return (
        <>
            <Helmet>
                <title>Mobile Wallet Receivable - EA Fruits DMS</title>
            </Helmet>
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Mobile Wallet Receivable' })}</PageTitle>

            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 table-hover'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-100px'>Order ID</th>
                                    <th className='min-w-100px'>Order Date</th>
                                    <th className='min-w-120px'>Discount Amt.</th>
                                    <th className='min-w-150px'>SR Name</th>
                                    <th className='min-w-120px'>Payment By</th>
                                    <th className='min-w-140px'>Invoice Amt.</th>
                                    <th className='min-w-150px'>Receivable by Mobile Wallet</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr onClick={() => navigate('/sales-invoices/sales-invoice-detail')} className='cursor-pointer'>
                                    <td>#66304</td>
                                    <td>28 Oct 2022</td>
                                    <td>Ekene Obasey</td>
                                    <td>Dusya Sigachyova</td>
                                    <td>Wallet Name</td>
                                    <td>TZS 583</td>
                                    <td>TZS 564</td>
                                </tr>
                                <tr>
                                    <td>#66304</td>
                                    <td>28 Oct 2022</td>
                                    <td>Ekene Obasey</td>
                                    <td>Dusya Sigachyova</td>
                                    <td>Wallet Name</td>
                                    <td>TZS 583</td>
                                    <td>TZS 564</td>
                                </tr>
                                <tr>
                                    <td>#66304</td>
                                    <td>28 Oct 2022</td>
                                    <td>Ekene Obasey</td>
                                    <td>Dusya Sigachyova</td>
                                    <td>Wallet Name</td>
                                    <td>TZS 583</td>
                                    <td>TZS 564</td>
                                </tr>
                            </tbody>
                            {/* end::Table body */}

                            <tfoot className='border-bottom'>
                                <tr className='bg-light2 font-medium border-top border-bottom' >
                                    <td colSpan={5} className='text-center'>Total Receivable by Mobile Wallet</td>
                                    <td>TZS 14000.00</td>
                                    <td>TZS 10000.00</td>
                                </tr>
                            </tfoot>
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
            {/* <Addwarehouse /> */}
        </>
    )
}

export default ReportsBifurcation