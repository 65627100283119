import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  formDataConverter,
  globalDateFormate,
  isEmpty,
} from '../../../../../../_eaFruitsDms/helpers'
import {AsyncPaginate} from 'react-select-async-paginate'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import clsx from 'clsx'
import ErrorHandler from '../../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import {getRequest, patchRequest, postRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {useLocation} from 'react-router-dom'

const HorecaModal = (props: any) => {
  const location: any = useLocation()
  const {
    show,
    setShow,
    onPageStateUpdate,
    id,
    setId,
    setIsAlert,
    setMessage,
    customerDetails,
    isCopyContract,
    setCopyContract,
  } = props

  const initialSku = {
    product_id: '',
    product: '',
    price: '',
  }

  const initialState: any = {
    customer_id: '',
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().add(7, 'days').format('YYYY-MM-DD'),
    credit_amount_cap: '',
    credit_cycle: '',
    customer_contract_skus: [initialSku],
    file_name: '',
  }

  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [offsetSku, setOffsetSku] = useState(0)
  const [resetSku, setResetSku] = useState<any>(moment())
  const [listSku, setListSku] = useState([])
  const [dropDownSearchSku, setDropDownSearchSku] = useState('')
  const [isSkuError, setSkuError] = useState('')
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
  })
  const [customer, setCustomer] = useState<any>('')
  const [horeca, setHoreca] = useState(initialState)
  const [validation, setValidation] = useState(initialState)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isCustomerDisable, setIsDisable] = useState(false)
  const [error, setError] = useState('')
  const [disableFields, setDisableFields] = useState(false)
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (
      location.pathname.includes('customers/singlecustomer/') ||
      location.pathname.includes('customers/verification-requests/horeca-contract-detail')
    ) {
      if (!isEmpty(customerDetails)) {
        setHoreca({...horeca, customer_id: customerDetails.id})
        setCustomer({label: customerDetails.business_name, value: customerDetails.id})
      }

      setIsDisable(true)
      return
    }

    if (!isEmpty(location.state) && !isEmpty(location.state.disableCustomerDropDown)) {
      setIsDisable(true)
      return
    }

    setIsDisable(false)
  }, [location, customerDetails])

  useEffect(() => {
    if (!isEmpty(id) && !isEmpty(status)) {
      if (status === 'active') {
        setDisableFields(true)
        return
      }
    }
    setDisableFields(false)
  }, [id, status])

  const warehouseId = !checkUserType(['sa', 'ts']) ? localStorage.getItem('w_id') : ''

  const handleClose = () => setShow(false)

  const fetchCustomer = (search: string) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `customers/drop-down?ordering=business_name&category=horeca&warehouse_id=${warehouseId}`,
      search,
      setDropDownSearch,
      'business_name',
      false
    )
  }

  const fetchProductSku = async (search: string) => {
    let response = await fetchAsyncDropdown(
      offsetSku,
      setOffsetSku,
      listSku,
      setListSku,
      `master/product/view?`,
      search,
      setDropDownSearchSku,
      'name',
      false
    )

    let update = {...horeca}
    let selectedSkus: any = []
    let customOptions: any = []

    update.customer_contract_skus.forEach((item: any) => {
      selectedSkus.push(item.product_id)
    })

    response.options.forEach((options: any) => {
      if (!selectedSkus.includes(options.value)) {
        customOptions.push({...options})
      }
    })

    response.options = customOptions

    return response
  }

  const onChangeHandler = (e: any, name: string, index: number, isProduct?: any) => {
    let updateHoreca: any = {...horeca}
    let updateValidation: any = {...validation}

    switch (name) {
      case 'customer_id':
        setCustomer(e)
        updateHoreca.customer_id = e.value
        updateValidation.customer_id = e.value === '' ? 'is-invalid' : ''
        break

      case 'date':
        updateHoreca.start_date = e.startDate
        updateHoreca.end_date = e.endDate
        break

      case 'credit_amount_cap':
        updateHoreca.credit_amount_cap = e.target.value
        updateValidation.credit_amount_cap = e.target.value === '' ? 'is-invalid' : ''
        break

      case 'credit_cycle':
        updateHoreca.credit_cycle = e.target.value
        updateValidation.credit_cycle = e.target.value === '' ? 'is-invalid' : ''
        break

      case 'file_name':
        const allowedExtensions = /(\.pdf)$/i

        if (allowedExtensions.exec(e.target.value)) {
          updateHoreca.file_name = e.target.files[0]
        }
        updateValidation.file_name = e.target.value === '' ? 'is-invalid' : ''
        break

      case 'customer_contract_skus':
        let products: any = [...updateHoreca.customer_contract_skus]
        let productsValidation: any = [...updateValidation.customer_contract_skus]

        products[index] = {
          product_id: e.value ? e.value : products[index].product_id,
          product: e.value ? e : products[index].product,
          price: e.target ? e.target.value : products[index].price,
        }

        productsValidation[index] = {
          product_id: e.value === '' ? 'is-invalid' : '',
          product: e.value === '' ? 'is-invalid' : '',
          ...(isProduct && {price: products[index].price === '' ? 'is-invalid' : ''}),
        }

        updateHoreca.customer_contract_skus = products
        updateValidation.customer_contract_skus = productsValidation

        break
    }

    setHoreca(updateHoreca)
    setValidation(updateValidation)
  }

  const CustomDateRangePicker = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar'
            defaultValue={value}
            disabled={
              !isEmpty(isCopyContract) && isCopyContract === 'copyContract' ? false : disableFields
            }
          />
        </DateRangePicker>
      </>
    )
  }

  const addRow = (index: number) => {
    let isAdded = true
    let updateHoreca: any = {...horeca}
    let updateValidation = {...validation}

    updateHoreca.customer_contract_skus.forEach((item: any) => {
      if (isEmpty(item.product_id) || isEmpty(item.price)) {
        isAdded = false
        setSkuError('Please select the Product SKU & Price above first!.')
        setTimeout(() => {
          setSkuError('')
        }, 2500)
      }
    })

    if (isAdded) {
      updateHoreca.customer_contract_skus = [...updateHoreca.customer_contract_skus, initialSku]
      updateValidation.customer_contract_skus = [
        ...updateValidation.customer_contract_skus,
        initialSku,
      ]

      setHoreca(updateHoreca)
      setValidation(updateValidation)
    }
  }

  const removeRow = (index: number) => {
    let updateHoreca: any = {...horeca}
    let updateValidation = {...validation}
    let productSku: any = []

    updateHoreca.customer_contract_skus.forEach((item: any, itemIndex: any) => {
      if (itemIndex !== index) {
        productSku.push({...item})
      }
    })

    updateHoreca.customer_contract_skus = productSku
    updateValidation.customer_contract_skus = productSku

    setHoreca(updateHoreca)
    setValidation(updateValidation)
  }

  const onSubmit = async () => {
    if (checkValue()) {
      setIsSubmit(true)
      const url =
        apiBaseURL() +
        `customers/contracts/${
          isEmpty(id) || (!isEmpty(isCopyContract) && isCopyContract === 'copyContract')
            ? 'create'
            : `update/${id}`
        }`

      let body = formDataConverter(horeca, [
        'customer_contract_skus',
        `${typeof horeca.file_name === 'string' ? 'file_name' : ''}`,
      ])

      horeca.customer_contract_skus.forEach((item: any, index: number) => {
        body.append(`customer_contract_skus[${index}]product_id`, item.product_id)
        body.append(`customer_contract_skus[${index}]price`, item.price)
      })

      if (isEmpty(id) || (!isEmpty(isCopyContract) && isCopyContract === 'copyContract')) {
        const response = await postRequest(url, body, true)
        if (response.status === 201) {
          onSuccess()

          if (!isEmpty(isCopyContract) && isCopyContract === 'copyContract') {
            setMessage('Your HORECA Contracts has been copied successfully.')
          } else {
            setMessage('Your HORECA Contracts has been added successfully.')
          }
        } else {
          if (
            !isEmpty(response.data) &&
            !isEmpty(response.data.error.field) &&
            response.data.error.error_message
          ) {
            setError(response.data.error.error_message)
          }
        }
        setIsSubmit(false)
      } else {
        // if (!isEmpty(status) && status === 'active') {
        //   body.delete('file_name')
        //   body.delete('start_date')
        //   body.delete('end_date')
        //   body.delete('customer_id')
        // }

        const response = await patchRequest(url, body, true)

        if (response.status !== 500) {
          if (response.status === 200) {
            onSuccess()
            setMessage('Your HORECA Contracts has been updated successfully.')
          } else {
            if (
              !isEmpty(response) &&
              !isEmpty(response.data) &&
              !isEmpty(response.data.error.field) &&
              response.data.error.error_message
            ) {
              setError(response.data.error.error_message)
            }
          }
        }
        setIsSubmit(false)
      }
    }
  }

  const onSuccess = () => {
    isModalClear()
    onPageStateUpdate()
    setIsAlert(true)
    setTimeout(() => {
      setIsAlert(false)
    }, 2000)
  }

  const isModalClear = () => {
    handleClose()
    setList([])
    setListSku([])
    setDropDownSearch('')
    setDropDownSearchSku('')
    setOffsetSku(0)
    setOffset(0)
    setResetSku(moment())
    setError('')
    setApiDate({
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
    })

    if (location.pathname.includes('customers/singlecustomer/')) {
      let updateHoreca = {...initialState}
      updateHoreca['customer_id'] = horeca.customer_id
      setHoreca(updateHoreca)
    } else {
      setCustomer('')
      setHoreca(initialState)
    }

    setValidation(initialState)
    setId('')
    setIsModalLoading(false)

    if (!isEmpty(setCopyContract)) {
      setCopyContract('')
    }
  }

  const fetchModalValue = async () => {
    setIsModalLoading(true)
    const url = apiBaseURL() + 'customers/contracts/' + id
    const response = await getRequest(url, true)
    if (response && response.id) {
      setStatus(response.status)

      let updateHoreca: any = {...horeca}
      let updateValidation: any = {...validation}

      updateHoreca.customer_id = response.customer_id
      updateHoreca.start_date = response.start_date
      updateHoreca.end_date = response.end_date
      updateHoreca.credit_amount_cap = response.credit_amount_cap
      updateHoreca.credit_amount_cap = response.credit_amount_cap
      updateHoreca.credit_cycle = response.credit_cycle
      updateHoreca.file_name = response.file_name

      let productSku: any = []

      response.customer_contract_skus.length > 0 &&
        response.customer_contract_skus.forEach((item: any) => {
          productSku.push({
            product_id: item.product_id,
            product: {label: item.product_name, value: item.product_id},
            price: item.price,
          })
        })

      updateHoreca.customer_contract_skus = productSku
      updateValidation.customer_contract_skus = productSku
      setHoreca(updateHoreca)
      setValidation(updateValidation)
      setApiDate({
        startDate: response.start_date,
        endDate: response.end_date,
      })
      setCustomer({label: response.customer_name, value: response.customer_id})
    }
    setIsModalLoading(false)
  }

  const checkValue = () => {
    let isValid = true
    let updateValidation = {...validation}
    let skuValue: any = []

    for (const key in updateValidation) {
      if (key !== 'customer_contract_skus') {
        if (isEmpty(horeca[key])) {
          isValid = false
          updateValidation[key] = 'is-invalid'
        }
      } else {
        horeca[key].forEach((item: any, index: number) => {
          skuValue.push({
            product_id: isEmpty(item.product_id) ? 'is-invalid' : '',
            product: isEmpty(item.product) ? 'is-invalid' : '',
            price: isEmpty(item.price) ? 'is-invalid' : '',
          })
        })
      }
    }

    horeca.customer_contract_skus.map((item: any, index: number) => {
      if (isEmpty(item.product_id) || isEmpty(item.price)) {
        isValid = false
      }
    })

    updateValidation.customer_contract_skus = skuValue
    setValidation(updateValidation)

    return isValid
  }

  useEffect(() => {
    if (id && !isEmpty(id)) {
      fetchModalValue()
    }
  }, [id])

  return (
    <Modal
      show={show}
      onHide={isModalClear}
      centered
      backdrop='static'
      dialogClassName='h-auto mw-570px'
    >
      <Modal.Header>
        <Modal.Title>
          <h2 className='modal-title fw-bold'>
            {!isEmpty(isCopyContract) && isCopyContract === 'copyContract'
              ? 'Copy'
              : isEmpty(id)
              ? 'Add'
              : 'Edit'}{' '}
            Contract
          </h2>
        </Modal.Title>
        <div className='btn p-1 mh-24 btn-active-light-primary ms-2' onClick={isModalClear}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
        </div>
      </Modal.Header>
      <Modal.Body>
        {isModalLoading ? (
          <FetchingRecords />
        ) : (
          <div className='row'>
            <div className='col-sm-12 mb-4'>
              <label className='form-label'>
                HORECA Customer<span className='text-danger'>*</span>
              </label>
              <AsyncPaginate
                loadOptions={fetchCustomer}
                isSearchable
                placeholder='Select'
                className={`react-select-container my-1 w-100 ${validation.customer_id}`}
                onChange={(e) => onChangeHandler(e, 'customer_id', 0)}
                value={customer}
                isDisabled={
                  disableFields ||
                  isCustomerDisable ||
                  (!isEmpty(isCopyContract) && isCopyContract === 'copyContract')
                }
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            <div className='col-sm-12 mb-4'>
              <label className='form-label'>
                Select Date
                {/* <span className='text-danger'>*</span> */}
              </label>
              <CustomDateRangePicker
                onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                  setApiDate({
                    startDate: callBakeDate.startDate,
                    endDate: callBakeDate.endDate,
                  })

                  const date = {
                    startDate: callBakeDate.startDate,
                    endDate: callBakeDate.endDate,
                  }

                  onChangeHandler(date, 'date', 0)
                }}
                value={apiDate}
                startDate={apiDate.startDate}
                endDate={apiDate.endDate}
              />
            </div>
            <div className='col-sm-6 mb-4'>
              <label className='form-label'>
                Credit Capping<span className='text-danger'>*</span>
              </label>
              <div className='input-group mb-1'>
                <span className='input-group-text bg-light2 border-start-0 px-3 border-end border-light ms-0 w-50px'>
                  TZS
                </span>
                <input
                  type='number'
                  placeholder='0'
                  className={`form-control min-w-70px ${validation.credit_amount_cap}`}
                  onChange={(e) => {
                    if (e.target.value !== '0') {
                      onChangeHandler(e, 'credit_amount_cap', 0)
                    }
                  }}
                  value={horeca.credit_amount_cap}
                />
              </div>
            </div>
            <div className='col-sm-6 mb-4'>
              <label className='form-label'>
                Duration Days<span className='text-danger'>*</span>
              </label>
              <div className='d-flex align-items-center'>
                <div className='input-group me-1'>
                  <input
                    type='text'
                    placeholder='0'
                    name='quantity'
                    className={`form-control min-w-70px ${validation.credit_cycle}`}
                    value={horeca.credit_cycle}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/
                      if (e.target.value === '' || re.test(e.target.value)) {
                        if (e.target.value !== '0') {
                          onChangeHandler(e, 'credit_cycle', 0)
                        }
                      }
                    }}
                  />
                  <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                    Days
                  </span>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='mb-4 position-relative'>
                <label className='form-label me-2'>Upload Contract Document</label>
                <label
                  htmlFor='business-lic'
                  className={clsx(
                    'form-control file-input text-truncate pe-6',
                    validation.file_name
                  )}
                >
                  <span className='text-muted'>
                    {horeca.file_name && horeca.file_name.name ? horeca.file_name.name : 'Browse'}
                  </span>
                </label>
                <input
                  type='file'
                  id='business-lic'
                  accept='application/pdf'
                  name='file_name'
                  disabled={
                    !isEmpty(isCopyContract) && isCopyContract === 'copyContract'
                      ? false
                      : disableFields
                  }
                  onChange={(e) => onChangeHandler(e, 'file_name', 0)}
                  className={clsx('form-control', validation.file_name)}
                />

                {isCopyContract !== 'copyContract' &&
                  !isEmpty(horeca.file_name) &&
                  typeof horeca.file_name === 'string' && (
                    <a href={horeca.file_name} target='_blank'>
                      {horeca.file_name.substring(horeca.file_name.lastIndexOf('/') + 1)}
                    </a>
                  )}
              </div>
            </div>
            <div className='col-sm-12'>
              <label className='form-label mb-0' style={{fontWeight: 600}}>
                {' '}
                Add SKU{' '}
              </label>
              <hr className='mb-0 bg-light opacity-100' />
              <div className='col-12'>
                <table className='table gs-0 gy-3 mb-0'>
                  <thead>
                    <tr>
                      <th className='ps-0 text-muted' style={{width: '58%'}}>
                        Product SKU
                      </th>
                      <th className='ps-0 max-w-140px text-muted' style={{width: '35%'}}>
                        Price
                      </th>
                      <th style={{width: '7%'}}>
                        <span className='invisible'>Hidden</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {horeca.customer_contract_skus &&
                      horeca.customer_contract_skus.length > 0 &&
                      horeca.customer_contract_skus.map((item: any, index: number) => {
                        return (
                          <tr>
                            <td className='ps-0'>
                              <AsyncPaginate
                                loadOptions={fetchProductSku}
                                isSearchable
                                key={resetSku}
                                placeholder='Select'
                                value={item.product}
                                onBlur={() => {
                                  setOffsetSku(0)
                                  setResetSku(moment())
                                }}
                                className={`react-select-container  ${validation.customer_contract_skus[index].product_id}`}
                                onChange={(e) =>
                                  onChangeHandler(e, 'customer_contract_skus', index)
                                }
                                classNamePrefix='react-select'
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                  },
                                })}
                              />
                            </td>
                            <td className='ps-0'>
                              <div className='input-group max-w-140px'>
                                <span className='input-group-text bg-light2 border-start-0 px-3 border-end border-light ms-0 w-50px'>
                                  TZS
                                </span>
                                <input
                                  type='number'
                                  placeholder='0'
                                  className={`form-control min-w-70px ${validation.customer_contract_skus[index].price}`}
                                  value={item.price}
                                  onChange={(e) =>
                                    onChangeHandler(e, 'customer_contract_skus', index, 'isProduct')
                                  }
                                />
                              </div>
                            </td>
                            <td className='align-middle'>
                              {horeca.customer_contract_skus.length === 1 ||
                              horeca.customer_contract_skus.length - 1 === index ? (
                                <div onClick={() => addRow(index)}>
                                  <KTSVG
                                    path={'/media/icons/duotune/abstract/abs011.svg'}
                                    className='ic me-0'
                                  />
                                </div>
                              ) : (
                                <div onClick={() => removeRow(index)}>
                                  <KTSVG
                                    path={'/media/icons/duotune/abstract/abs012.svg'}
                                    className='ic me-0'
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
                <ErrorHandler errorMessage={isSkuError} />
              </div>
            </div>

            <div className='mb-3'>
              <ErrorHandler errorMessage={error} />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='w-100 d-flex justify-content-center'>
          <button className='btn btn-secondary me-3' onClick={isModalClear} disabled={isSubmit}>
            Cancel
          </button>
          <button className='btn btn-primary' onClick={onSubmit} disabled={isSubmit}>
            {isSubmit ? 'Please Wait' : 'Submit'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default HorecaModal
