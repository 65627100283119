import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import {useEffect, useState} from 'react'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {
  apiBaseURL,
  checkRoleName,
  dateFormat,
  globalDateFormate,
  isEmpty,
  numberFormatWithCode,
} from '../../../_eaFruitsDms/helpers'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {EaChartBar} from '../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import DatePicker from 'react-date-picker'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {financeManager} from '../../../_eaFruitsDms/utils/roleNames'

const AMDashboard = () => {
  // for bar chart
  let app = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
  }

  // for bar chart
  const labelOption = {
    show: true,
    position: app.position,
    distance: app.distance,
    align: app.align,
    verticalAlign: app.verticalAlign,
    rotate: app.rotate,
    // formatter: '{c}  {name|{a}}',
    formatter: function (params: any) {
      return numberFormatWithCode(params.data).displayFormat
    },
    fontSize: 10,
    rich: {
      name: {},
    },
  }

  const [fetchingSummary, setFetchingSummary] = useState(true)
  const [fetchingCashyCreditChart, setFetchingCashyCreditChart] = useState(true)
  const [fetchingDiscountOrderChart, setFetchingDiscountOrderChart] = useState(true)
  const [apiDate, setApiDate] = useState(moment().format('YYYY-MM-DD'))
  const [fetchingTargetIncentiveChart, setFetchingTargetIncentiveChart] = useState(true)
  const [warehouse, setWarehouse] = useState({label: 'All Warehouse', value: ''})
  const [metaCount, setMetaCount] = useState({
    open_credit_amount: 0,
    total_sales_amount: 0,
    unsettled_payment_amount: 0,
  })

  const [summary, setSummary] = useState({
    collection_summary: {
      cash: 0,
      mobile_wallet: 0,
      bank_transfer: 0,
      cheque: 0,
      total_collection: 0,
    },
    payment_summary: {
      cashy_sales: 0,
      pay_later_today_sales: {
        total: 0,
        received: 0,
        due: 0,
      },
      credit_sales: 0,
      past_credit_payment: 0,
      total_business: 0,
      total_collection: 0,
    },
  })

  const [cashyCreditChart, setCashyCreditChart] = useState({
    xAxisData: [],
    rotate: '',
    series: '',
    legend: '',
    startZoom: '',
    endZoom: '',
  })

  const [discountOrderChart, setDiscountOrderChart] = useState({
    xAxisData: [],
    rotate: '',
    series: '',
    legend: '',
    startZoom: '',
    endZoom: '',
  })

  const [targetIncentiveChart, setTargetIncentiveChart] = useState({
    xAxisData: [],
    rotate: '',
    series: '',
    legend: '',
    startZoom: '',
    endZoom: '',
  })

  // credit vs cashy
  const [isFromCalenderOpen, setIsFromCalenderOpen] = useState<boolean>(false)
  const [isToCalenderOpen, setIsToCalenderOpen] = useState<boolean>(false)
  const [fromMonth, setFromMonth] = useState<any>(moment().toDate())
  const [toMonth, setToMonth] = useState<any>(moment().toDate())

  // Discount Order
  const [isFromCalenderOpenDiscount, setIsFromCalenderOpenDiscount] = useState<boolean>(false)
  const [isToCalenderOpenDiscount, setIsToCalenderOpenDiscount] = useState<boolean>(false)
  const [fromMonthDiscount, setFromMonthDiscount] = useState<any>(moment().toDate())
  const [toMonthDiscount, setToMonthDiscount] = useState<any>(moment().toDate())

  // Target Incentive
  const [isFromCalenderOpenTarget, setIsFromCalenderOpenTarget] = useState<boolean>(false)
  const [isToCalenderOpenTarget, setIsToCalenderOpenTarget] = useState<boolean>(false)
  const [fromMonthTarget, setFromMonthTarget] = useState<any>(
    moment().subtract(1, 'months').toDate()
  )
  const [toMonthTarget, setToMonthTarget] = useState<any>(moment().subtract(1, 'months').toDate())

  const fetchSummary = async () => {
    setFetchingSummary(true)

    const url = `${apiBaseURL()}reports/am-dashboard/payment-summary/${apiDate}/?warehouse_id=${
      warehouse.value
    }`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setSummary(response.results)
    }

    setFetchingSummary(false)
  }

  const fetchCreditCashyChart = async () => {
    setFetchingCashyCreditChart(true)

    const url = `${apiBaseURL()}reports/am-dashboard/cashy-credit-chart/${
      dateFormat(fromMonth, 'MM-yyyy', true).displayDate
    }/${dateFormat(toMonth, 'MM-yyyy', true).displayDate}?warehouse_id=${warehouse.value}`

    const response = await getRequest(url, true)

    if (response && response.result) handleChartResponse(response.result, setCashyCreditChart)

    setFetchingCashyCreditChart(false)
  }

  const fetchTargetIncentiveChart = async () => {
    setFetchingTargetIncentiveChart(true)

    const url = `${apiBaseURL()}reports/am-dashboard/target-incentives-chart/${
      dateFormat(fromMonthTarget, 'MM-yyyy', true).displayDate
    }/${dateFormat(toMonthTarget, 'MM-yyyy', true).displayDate}?warehouse_id=${warehouse.value}`

    const response = await getRequest(url, true)

    if (response && response.result) handleChartResponse(response.result, setTargetIncentiveChart)

    setFetchingTargetIncentiveChart(false)
  }

  const fetchDiscountOrderChart = async () => {
    setFetchingDiscountOrderChart(true)

    const url = `${apiBaseURL()}reports/am-dashboard/discount-order-promotional-chart/${
      dateFormat(fromMonthDiscount, 'MM-yyyy', true).displayDate
    }/${dateFormat(toMonthDiscount, 'MM-yyyy', true).displayDate}?warehouse_id=${warehouse.value}`

    const response = await getRequest(url, true)

    if (response && response.result) handleChartResponse(response.result, setDiscountOrderChart)

    setFetchingDiscountOrderChart(false)
  }

  const handleChartResponse = (response: any, setChartData: any) => {
    let initialChart: any = {}
    let xAxisData: string[] = []
    let seriesData: any = []
    let legend: any = []
    let type: any = []
    let key = 'label'

    if (response.length > 0) {
      response[0].chart_data.forEach((item: any) => {
        legend.push(item[key])
        type.push({name: item[key]})
      })

      response.forEach((item: any) => {
        xAxisData.push(item.month)
      })

      type.forEach((i: any) => {
        let data: any = []
        response.forEach((item: any) => {
          item.chart_data.length > 0 &&
            item.chart_data.forEach((item: any, index: any) => {
              if (i.name === item[key]) {
                data.push(
                  parseFloat(item.value)
                    .toFixed(2)
                    .replace(/\.?0+$/, '')
                )
              }
            })
        })

        seriesData.push({
          name: i.name,
          type: 'bar',
          width: '100%',
          label: labelOption,
          tooltip: {
            valueFormatter: function (params: any) {
              return numberFormatWithCode(params).displayFormat
            },
          },
          emphasis: {
            focus: 'series',
          },
          data: data,
        })
      })
    }

    initialChart.xAxisData = xAxisData
    initialChart.rotate = 30
    initialChart.series = seriesData
    initialChart.legend = legend
    initialChart.startZoom = 0
    initialChart.endZoom = 100

    setChartData(initialChart)
  }

  const fetchMetaCount = async () => {
    const url = `${apiBaseURL()}reports/am-dashboard/meta-counts?warehouse_id=${warehouse.value}`

    const response = await getRequest(url, true)

    if (response && response.meta) {
      setMetaCount(response.meta)
    }
  }

  const handleFilterChange = (e: any) => {
    setWarehouse(e)
  }

  useEffect(() => {
    fetchMetaCount()
  }, [warehouse])

  useEffect(() => {
    fetchCreditCashyChart()
  }, [fromMonth, toMonth, warehouse])

  useEffect(() => {
    fetchDiscountOrderChart()
  }, [fromMonthDiscount, toMonthDiscount, warehouse])

  useEffect(() => {
    fetchTargetIncentiveChart()
  }, [fromMonthTarget, toMonthTarget, warehouse])

  useEffect(() => {
    fetchSummary()
  }, [apiDate, warehouse])

  useEffect(() => {
    if (fromMonth != '') {
      setIsFromCalenderOpen(false)
    }
  }, [fromMonth])

  useEffect(() => {
    if (toMonth != '') {
      setIsToCalenderOpen(false)
    }
  }, [toMonth])

  useEffect(() => {
    if (fromMonthDiscount != '') {
      setIsFromCalenderOpenDiscount(false)
    }
  }, [fromMonthDiscount])

  useEffect(() => {
    if (toMonthDiscount != '') {
      setIsToCalenderOpenDiscount(false)
    }
  }, [toMonthDiscount])

  useEffect(() => {
    if (fromMonthTarget != '') {
      setIsFromCalenderOpenTarget(false)
    }
  }, [fromMonthTarget])

  useEffect(() => {
    if (toMonthTarget != '') {
      setIsToCalenderOpenTarget(false)
    }
  }, [toMonthTarget])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            singleDatePicker: true,
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto bg-white'
            defaultValue={value}
          />
        </DateRangePicker>
      </>
    )
  }

  const amDashboardFilterId = document.getElementById('am-dashboard-filter')

  return (
    <>
      {checkRoleName(financeManager) &&
        amDashboardFilterId &&
        ReactDOM.render(
          <WarehouseFilter
            warehouse={warehouse}
            handleFilterChange={handleFilterChange}
            isDisabled={false}
          />,
          document.getElementById('am-dashboard-filter')
        )}

      {/* begin::Row statistics*/}
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={
              numberFormatWithCode(
                isEmpty(metaCount.unsettled_payment_amount) ? 0 : metaCount.unsettled_payment_amount
              ).displayFormat
            }
            path='/'
            description='Open Payment Issues'
            label='Lifetime'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='danger'
            title={
              numberFormatWithCode(
                isEmpty(metaCount.open_credit_amount) ? 0 : metaCount.open_credit_amount
              ).displayFormat
            }
            path='/'
            description='Open Credit Issues'
            label='Lifetime'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='primary'
            title={
              numberFormatWithCode(
                isEmpty(metaCount.total_sales_amount) ? 0 : metaCount.total_sales_amount
              ).displayFormat
            }
            path='/'
            description='Total Sales Amount'
            label='Lifetime'
          />
        </div>
      </div>
      {/* end::Row statistics*/}

      {/* begin::Row */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='app-container d-flex flex-stack'>
            <h3 className='card-title align-items-start flex-column mt-0 mb-4 pb-1'>
              <span className='card-label fw-bold font-20 '>Daily Summary</span>
            </h3>
            <div className='d-flex align-items-center gap-2 gap-lg-3 mb-4 pb-1'>
              <CustomDatePickerComponent
                onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                  setApiDate(callBakeDate.startDate)
                }}
                value={apiDate}
                startDate={apiDate}
              />
            </div>
          </div>
          {fetchingSummary ? (
            <div className='card mb-5 pt-5'>
              <FetchingRecords />
            </div>
          ) : (
            <div className='row'>
              {/* Business summery start */}
              <div className='col-sm-6 col-md-6 col-lg col-xl'>
                <div className='card card-xl-stretch mb-5'>
                  <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    <h3 className='card-title align-items-start flex-column my-0'>
                      <span className='card-label fw-bold font-17 mb-1'>Payment Summary</span>
                    </h3>
                  </div>
                  <div className='card-body pt-0 pb-4'>
                    <div className='table-responsive'>
                      <table className='table table-row-bordered align-middle gs-0 gy-2'>
                        <tbody>
                          <tr>
                            <td>Cashy Sales</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.payment_summary.cashy_sales)
                                    ? 0
                                    : summary.payment_summary.cashy_sales
                                ).displayFormat
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Pay Later Today</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.payment_summary.pay_later_today_sales.total)
                                    ? 0
                                    : summary.payment_summary.pay_later_today_sales.total
                                ).displayFormat
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className='ps-5'>Received</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.payment_summary.pay_later_today_sales.received)
                                    ? 0
                                    : summary.payment_summary.pay_later_today_sales.received
                                ).displayFormat
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className='ps-5'>Due</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.payment_summary.pay_later_today_sales.due)
                                    ? 0
                                    : summary.payment_summary.pay_later_today_sales.due
                                ).displayFormat
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Credit Sales</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.payment_summary.credit_sales)
                                    ? 0
                                    : summary.payment_summary.credit_sales
                                ).displayFormat
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Past Credit Payment</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.payment_summary.past_credit_payment)
                                    ? 0
                                    : summary.payment_summary.past_credit_payment
                                ).displayFormat
                              }
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className='bg-light2'>
                            <td className='fw-500'>Total Business</td>
                            <td className='text-end fw-500'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.payment_summary.total_business)
                                    ? 0
                                    : summary.payment_summary.total_business
                                ).displayFormat
                              }
                            </td>
                          </tr>
                          <tr className='bg-light2'>
                            <td className='fw-500'>Total Collection</td>
                            <td className='text-end fw-500'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.payment_summary.total_collection)
                                    ? 0
                                    : summary.payment_summary.total_collection
                                ).displayFormat
                              }
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* Business summery end */}
              {/* collection summery start */}
              <div className='col-sm-6 col-md-6 col-lg col-xl'>
                <div className='card card-xl-stretch mb-5'>
                  <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    <h3 className='card-title align-items-start flex-column my-0'>
                      <span className='card-label fw-bold font-17 mb-1'>Collection Summary</span>
                    </h3>
                  </div>
                  <div className='card-body pt-0 pb-4'>
                    <div className='table-responsive'>
                      <table className='table table-row-bordered align-middle gs-0 gy-2'>
                        <tbody>
                          <tr>
                            <td>Cash</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.collection_summary.cash)
                                    ? 0
                                    : summary.collection_summary.cash
                                ).displayFormat
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Mobile Wallet</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.collection_summary.mobile_wallet)
                                    ? 0
                                    : summary.collection_summary.mobile_wallet
                                ).displayFormat
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Cheque</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.collection_summary.cheque)
                                    ? 0
                                    : summary.collection_summary.cheque
                                ).displayFormat
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Bank Transfer</td>
                            <td className='text-end'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.collection_summary.bank_transfer)
                                    ? 0
                                    : summary.collection_summary.bank_transfer
                                ).displayFormat
                              }
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className='bg-light2'>
                            <td className='fw-500'>Total Collection</td>
                            <td className='text-end fw-500'>
                              {
                                numberFormatWithCode(
                                  isEmpty(summary.collection_summary.total_collection)
                                    ? 0
                                    : summary.collection_summary.total_collection
                                ).displayFormat
                              }
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* collection summery end */}
            </div>
          )}
        </div>
      </div>
      {/* end::Row */}

      {/* start::Row */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            {/* begin::Header */}
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              {/* begin::Title */}
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Credit vs Cashy Sales</span>
              </h3>
              {/* end::Title */}

              {/* begin::Toolbar */}
              <div className='card-toolbar d-flex g-3 my-0'>
                <ul className='nav'>
                  <li className='nav-item me-3'>
                    <label className='font-bold mb-1 form-label'>From Month:</label>
                    <DatePicker
                      value={fromMonth}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isFromCalenderOpen}
                      maxDate={moment().toDate()}
                      onClickMonth={(e: any) => {
                        setIsFromCalenderOpen(true)
                        setFromMonth(e)
                        setToMonth(e)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                  <li>
                    <label className='font-bold mb-1 form-label'>To Month:</label>
                    <DatePicker
                      value={toMonth}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isToCalenderOpen}
                      minDate={fromMonth}
                      maxDate={moment().toDate()}
                      onClickMonth={(e: any) => {
                        setToMonth(e)
                        setIsToCalenderOpen(true)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                </ul>
              </div>
              {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
              {fetchingCashyCreditChart ? (
                <FetchingRecords />
              ) : cashyCreditChart.xAxisData.length === 0 ? (
                <NoRecords />
              ) : (
                <EaChartBar {...cashyCreditChart} />
              )}
            </div>
            {/* end::Body */}
          </div>
        </div>
      </div>
      {/* end::Row */}
      {/* start::Row */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            {/* begin::Header */}
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              {/* begin::Title */}
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Discount Usage</span>
              </h3>
              {/* end::Title */}
              {/* begin::Toolbar */}
              <div className='card-toolbar d-flex g-3 my-0'>
                <ul className='nav'>
                  <li className='nav-item me-3'>
                    <label className='font-bold mb-1 form-label'>From Month:</label>
                    <DatePicker
                      value={fromMonthDiscount}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isFromCalenderOpenDiscount}
                      maxDate={moment().toDate()}
                      onClickMonth={(e: any) => {
                        setIsFromCalenderOpenDiscount(true)
                        setFromMonthDiscount(e)
                        setToMonthDiscount(e)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                  <li>
                    <label className='font-bold mb-1 form-label'>To Month:</label>
                    <DatePicker
                      value={toMonthDiscount}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isToCalenderOpenDiscount}
                      minDate={fromMonthDiscount}
                      maxDate={moment().toDate()}
                      onClickMonth={(e: any) => {
                        setToMonthDiscount(e)
                        setIsToCalenderOpenDiscount(true)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                </ul>
              </div>
              {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
              {fetchingDiscountOrderChart ? (
                <FetchingRecords />
              ) : discountOrderChart.xAxisData.length === 0 ? (
                <NoRecords />
              ) : (
                <EaChartBar {...discountOrderChart} />
              )}
            </div>
            {/* end::Body */}
          </div>
        </div>
      </div>
      {/* end::Row */}
      {/* start::Row */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            {/* begin::Header */}
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              {/* begin::Title */}
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Target Incentives Paid</span>
              </h3>
              {/* end::Title */}
              {/* begin::Toolbar */}
              <div className='card-toolbar d-flex g-3 my-0'>
                <ul className='nav'>
                  <li className='nav-item me-3'>
                    <label className='font-bold mb-1 form-label'>From Month:</label>
                    <DatePicker
                      value={fromMonthTarget}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isFromCalenderOpenTarget}
                      maxDate={moment().toDate()}
                      onClickMonth={(e: any) => {
                        setIsFromCalenderOpenTarget(true)
                        setFromMonthTarget(e)
                        setToMonthTarget(e)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                  <li>
                    <label className='font-bold mb-1 form-label'>To Month:</label>
                    <DatePicker
                      value={toMonthTarget}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isToCalenderOpenTarget}
                      minDate={fromMonthTarget}
                      onClickMonth={(e: any) => {
                        setToMonthTarget(e)
                        setIsToCalenderOpenTarget(true)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                </ul>
              </div>
              {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
              {fetchingTargetIncentiveChart ? (
                <FetchingRecords />
              ) : targetIncentiveChart.xAxisData.length === 0 ? (
                <NoRecords />
              ) : (
                <EaChartBar {...targetIncentiveChart} />
              )}
            </div>
            {/* end::Body */}
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default AMDashboard
