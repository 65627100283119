import { PROFILE } from "../../types/types"

const initialState: any = {
    profile: '1'
}

export const manageProfile = (state = initialState, action: any) => {
    switch (action.type) {
        case PROFILE:
            return {
                profile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
