/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {isEmpty} from '../../../helpers'

type Props = {
  className: string
  color: string
  title: string
  description: string
  path?: string
  label?: string
  titleDescription?: any
  labelWidth?: any
}

const EaStatisticsWidget: React.FC<Props> = ({
  className,
  color,
  title,
  description,
  path,
  label,
  titleDescription,
  labelWidth,
}) => {
  return (
    <div className={`card bg-light-${color} ${className}`}>
      <div className='card-body p-5'>
        <a className={`card-title font-bold text-dark font-20 mb-0 d-block`}>{title}</a>
        {titleDescription}
        <div className='pt-1 mb-0'>
          <span className='fw-500 text-dark font-14'>{description}</span>
        </div>
      </div>

      {!isEmpty(label) && (
        <div
          className={`text-light ${
            labelWidth ? labelWidth : 'w-70px'
          } position-absolute mt-5 py-1 rounded-start text-center fw-600 top-0 end-0`}
          style={{
            backgroundColor: '#6c757d',
          }}
        >
          {label}
        </div>
      )}
    </div>
  )
}

export {EaStatisticsWidget}
