import moment from 'moment'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../TenantVariables'
import {
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  fetchAsyncDropdownNew,
  fetchStaticAsyncDropdown,
  firstLetterCapital,
  globalDateFormate,
  globalDateTimeFormate,
  isEmpty,
  KTSVG,
} from '../../../_eaFruitsDms/helpers'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest, postRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {useNavigate} from 'react-router-dom'

const Complaints = ({warehouse, selectedCustomer, isViewOnly, customerStatus}: any) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const initialComplaints = {
    title: '',
    customer: {label: 'Select', value: ''},
    type: {label: 'Select', value: ''},
    priority: {label: 'Select', value: ''},
    role: {label: 'Select', value: ''},
    assign: {label: 'Select', value: ''},
    userType: {label: 'Select', value: ''},
    warehouse: {label: 'Select', value: ''},
    dateTime: moment().format('YYYY-MM-DD hh:mm:ss A'),
    isValidTitle: false,
    isValidCustomer: false,
    isValidType: false,
    isValidPriority: false,
    isValidRole: false,
    isValidAssign: false,
    isValidUserType: false,
    isValidWarehouse: false,
  }

  useEffect(() => {
    localStorage.removeItem('support_request_warehouse')
    localStorage.removeItem('support_request_start_date')
    localStorage.removeItem('support_request_end_date')
  }, [])

  const [updateComplaint, setUpdateComplaint] = useState(initialComplaints)
  const [complaints, setComplaints] = useState([])
  const [showModal, handleModal] = useState(false)
  const [alert, setIsAlert] = useState(false)
  const [submit, setSubmit] = useState('Submit')
  const [message, setMessage] = useState('')
  const [fetchingRecords, setFetchingRecords] = useState(false)
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  })
  const [modalApiDate, setModalApiDate] = useState<any>({
    startDate: moment().format(globalDateTimeFormate()),
  })
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [searchByName, setSearchByName] = useState<string>('')
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [assignOffset, setAssignOffset] = useState(0)
  const [assignList, setAssignList] = useState([])
  const [offsetType, setOffsetType] = useState(0)
  const [listType, setListType] = useState([])
  const [offsetCustomer, setOffsetCustomer] = useState(0)
  const [listCustomer, setListCustomer] = useState([])
  const [customer, setCustomer] = useState({label: 'All Customer', value: ''})
  const [status, setStatus] = useState({label: 'All Status', value: ''})
  const [rolesReset, setRolesReset] = useState<any>(moment())
  const [assignReset, setAssignReset] = useState<any>(moment())
  const [customerReset, setCustomerReset] = useState<any>(moment())
  const [roleOffset, setRoleOffset] = useState('')
  const [roleList, setRoleList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [assignSearch, setAssignSearch] = useState('')
  const [search, setDropDownSearchType] = useState('')
  const [searchCustomer, setDropDownSearchCustomer] = useState('')
  const [roleSearch, setRoleSearch] = useState('')
  const [warehouseOffset, setWarehouseOffset] = useState('')
  const [warehouseList, setWarehouseList] = useState([])
  const [warehouseSearch, setWarehouseSearch] = useState('')

  const [limit] = useState(tenantConfiguration.limit)

  const debounceSearch = useDebounce(searchByName, 500)

  const fetchComplaints = async () => {
    setFetchingRecords(true)
    let customerId = customer.value

    if (isViewOnly) {
      customerId = localStorage.getItem('cId') || ''
    }

    let url = `${apiBaseURL()}complaint_request/list-complaint?search=${debounceSearch}&limit=${limit}&offset=${currentOffset}&customer=${customerId}&status=${
      status.value
    }&date_duration_after=${apiDate.startDate}&date_duration_before=${apiDate.endDate}`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setComplaints(response.results)
      setCount(response.count)
    }

    setFetchingRecords(false)
  }

  const fetchCustomer = (search: string) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `customers/drop-down?ordering=business_name`,
      search,
      setDropDownSearch,
      'business_name',
      true,
      'All Customers'
    )
  }

  const fetchStatus = (search?: string) => {
    const status = [
      {
        label: 'All Status',
        value: '',
      },
      {
        label: 'Open',
        value: 'open',
      },
      {
        label: 'In Progress',
        value: 'in_process',
      },
      {
        label: 'Closed',
        value: 'closed',
      },
      {
        label: 'Resolved',
        value: 'resolved',
      },
    ]

    return fetchStaticAsyncDropdown(status, search)
  }

  const fetchComplaintType = (search?: string) => {
    return fetchAsyncDropdown(
      offsetType,
      setOffsetType,
      listType,
      setListType,
      'master/options-master/?type=complaint_request_type&ordering=-updated_date',
      search,
      setDropDownSearchType,
      'option',
      false
    )
  }

  const fetchUserType = (search?: string) => {
    const status = [
      {value: 'am', label: 'Account Manager'},
      {value: 'lo', label: 'Logistics Officer'},
      {value: 'mo', label: 'Marketing Officer'},
      {value: 'oo', label: 'Operations Officer'},
      {value: 'ss', label: 'Sales Supervisor'},
      {value: 'sr', label: 'Sales Representatives'},
      {value: 'sg', label: 'Security Gatekeeper'},
      {value: 'ts', label: 'Tele Sales'},
      {value: 'wm', label: 'Warehouse Manager'},
    ]

    return fetchStaticAsyncDropdown(status, search)
  }

  const fetchPriority = (search?: string) => {
    const status = [
      {value: 'high', label: 'High'},
      {value: 'medium', label: 'Medium'},
      {value: 'low', label: 'Low'},
    ]

    return fetchStaticAsyncDropdown(status, search)
  }

  const fetchCustomerModal = (search: string) => {
    return fetchAsyncDropdown(
      offsetCustomer,
      setOffsetCustomer,
      listCustomer,
      setListCustomer,
      `customers/drop-down?ordering=business_name&warehouse_id=${updateComplaint.warehouse.value}`,
      search,
      setDropDownSearchCustomer,
      'business_name',
      false
    )
  }

  const fetchRolesModal = (search?: any) => {
    const userType = updateComplaint.userType.value

    return fetchAsyncDropdown(
      roleOffset,
      setRoleOffset,
      roleList,
      setRoleList,
      `auth/application/role/list/?ordering=name&user_type__in=${userType}`,
      search,
      setRoleSearch,
      false,
      false
    )
  }

  const fetchAssignee = (search?: any) => {
    const userType = updateComplaint.userType.value
    const roleId = updateComplaint.role.value

    return fetchAsyncDropdownNew(
      assignOffset,
      setAssignOffset,
      assignList,
      setAssignList,
      `complaint_request/assign-users?type=${userType}&role=${roleId}&warehouse_id=${updateComplaint.warehouse.value}`,
      search,
      setAssignSearch,
      'first_name',
      'last_name',
      false
    )
  }

  const fetchWarehouse = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffset,
      setWarehouseOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?is_parent=True&ordering=name`,
      search,
      setWarehouseSearch,
      false,
      false
    )
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e

    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'status':
        localStorage.setItem('status', inputValue.value)
        setStatus(e)
        break

      case 'customer':
        localStorage.setItem('customer', inputValue.value)
        setCustomer(e)
        break

      default:
        localStorage.setItem('search', inputValue)
        setSearchByName(inputValue)
        break
    }
  }

  const clear = () => {
    handleModal(false)
    setUpdateComplaint(initialComplaints)
    setModalApiDate({startDate: moment().format(globalDateTimeFormate())})
    setWarehouseOffset('')
    setWarehouseList([])
  }

  const checkValidation = () => {
    let valid = true
    let updateAddComplaints = {...updateComplaint}

    if (isEmpty(updateAddComplaints.title.trim())) {
      updateAddComplaints.isValidTitle = true
      valid = false
    }

    if (isEmpty(updateAddComplaints.type.value.toString())) {
      updateAddComplaints.isValidType = true
      valid = false
    }

    if (isEmpty(updateAddComplaints.warehouse.value.toString())) {
      updateAddComplaints.isValidWarehouse = true
      valid = false
    }

    if (isEmpty(updateAddComplaints.customer.value.toString())) {
      updateAddComplaints.isValidCustomer = true
      valid = false
    }

    if (isEmpty(updateAddComplaints.userType.value.toString())) {
      updateAddComplaints.isValidUserType = true
      valid = false
    }

    if (
      updateAddComplaints.userType?.value !== 'ts' &&
      isEmpty(updateAddComplaints.role.value.toString())
    ) {
      updateAddComplaints.isValidRole = true
      valid = false
    }

    if (isEmpty(updateAddComplaints.assign.value.toString())) {
      updateAddComplaints.isValidAssign = true
      valid = false
    }

    if (isEmpty(updateAddComplaints.priority.value.toString())) {
      updateAddComplaints.isValidPriority = true
      valid = false
    }

    setUpdateComplaint(updateAddComplaints)

    return valid
  }

  useEffect(() => {
    if (!isEmpty(warehouse?.value) && selectedCustomer)
      setUpdateComplaint({
        ...updateComplaint,
        customer: selectedCustomer,
        warehouse: warehouse,
      })
  }, [selectedCustomer, warehouse, showModal])

  const onSubmit = async () => {
    const isValid = checkValidation()

    if (isValid) {
      setSubmit('Please Wait')

      const url = `${apiBaseURL()}complaint_request/create-complaint`

      const body = {
        customer: updateComplaint.customer.value,
        type: updateComplaint.type.value,
        title: updateComplaint.title,
        priority: updateComplaint.priority.value,
        assign_to: updateComplaint.assign.value,
        assign_user_type: updateComplaint.userType.value,
        assign_at: moment(updateComplaint.dateTime).utc(),
      }

      const response = await postRequest(url, body, true)

      if (response.status === 201) {
        clear()
        setMessage('Your complaints added successfully.')
        setIsAlert(true)
        fetchComplaints()
        setTimeout(() => {
          setIsAlert(false)
        }, 2000)
      }

      setSubmit('Submit')
    }
  }

  const handleChange = (e: any, inputName?: string) => {
    const value = e.target ? e.target.value : e
    const name = e.target ? e.target.name : inputName

    let validationKey = `isValid${firstLetterCapital(name)}`
    let updateAddComplaints: any = {...updateComplaint}

    if (name === 'userType') {
      updateAddComplaints['role'] = {label: 'Select', value: ''}

      setRolesReset(moment())
      setRoleList([])
      setRoleOffset('0')
    }

    if (name === 'role') {
      updateAddComplaints['assign'] = {label: 'Select', value: ''}

      setAssignReset(moment())
      setAssignList([])
      setAssignOffset(0)
    }

    if (name === 'warehouse') {
      updateAddComplaints['assign'] = {label: 'Select', value: ''}
      updateAddComplaints['customer'] = {label: 'Select', value: ''}

      setAssignReset(moment())
      setCustomerReset(moment())
      setListCustomer([])
      setOffsetCustomer(0)
      setAssignList([])
      setAssignOffset(0)
    }

    updateAddComplaints[name] = value
    updateAddComplaints[validationKey] = false

    setUpdateComplaint(updateAddComplaints)
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }

            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            localStorage.setItem('support_request_start_date', callBakeDate.startDate)
            localStorage.setItem('support_request_end_date', callBakeDate.endDate)

            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-220px'
            defaultValue={value}
            disabled={fetchingRecords}
          />
        </DateRangePicker>
      </>
    )
  }

  const navigateTo = (id: string) => {
    if (!isViewOnly) {
      localStorage.setItem('complaints-detail-id', id)
      navigate('/all-complaints-details/overview')
    }
  }

  const checkInValid = (isInvalid: boolean) => {
    return isInvalid ? 'is-invalid' : ''
  }

  useEffect(() => {
    fetchComplaints()
  }, [apiDate, status, debounceSearch, customer, currentOffset])

  return (
    <>
      <EATitle title='Complaints' />
      <PageTitle
        breadcrumbs={[
          {
            title: 'Complaints',
            subTitle: 'Complaints',
          },
        ]}
      >
        {intl.formatMessage({id: 'Complaints'})}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      <div className='card'>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar isDisable={fetchingRecords} onChange={handleFilterChange} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <AsyncPaginate
                loadOptions={fetchStatus}
                isSearchable
                placeholder='All Status'
                className='react-select-container my-1 me-2 w-150px'
                onChange={(e) => {
                  handleFilterChange(e, 'status')
                }}
                value={status}
                isDisabled={fetchingRecords}
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              {!isViewOnly && (
                <AsyncPaginate
                  loadOptions={fetchCustomer}
                  isSearchable
                  placeholder='All Customer'
                  className='react-select-container my-1 me-2 w-170px'
                  onChange={(e) => {
                    handleFilterChange(e, 'customer')
                  }}
                  value={customer}
                  isDisabled={fetchingRecords}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              )}
              <CustomDatePickerComponent
                onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                  setApiDate({
                    startDate: callBakeDate.startDate,
                    endDate: callBakeDate.endDate,
                  })
                  setCurrentOffset(0)
                  setSelected(0)
                }}
                value={apiDate}
                startDate={apiDate.startDate}
                endDate={apiDate.endDate}
              />

              {isViewOnly && customerStatus === 'approved' && !checkUserType(['sa', 'wm']) ? (
                <button
                  className='btn btn-primary'
                  type='button'
                  onClick={() => {
                    handleModal(true)
                  }}
                >
                  Add Complaint
                </button>
              ) : (
                checkUserType(['ts']) &&
                !customerStatus && (
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      handleModal(true)
                    }}
                  >
                    Add Complaint
                  </button>
                )
              )}
            </div>
          </div>
        </div>
        <div className='card-body pt-0 pb-3'>
          <div className='table-responsive'>
            {fetchingRecords ? (
              <FetchingRecords />
            ) : complaints.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='w-100px'>Complaint ID</th>
                    <th className='w-150px'>Complaint Title</th>
                    <th className='w-100px'>Complaint Type</th>
                    <th className='w-100px'>Customer Name</th>
                    {/* <th className='w-100px'>SR Name</th> */}
                    <th className='w-100px'>Assignee</th>
                    <th className='w-100px'>Created At</th>
                    <th className='w-100px'>Priority</th>
                    <th className='w-100px'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {complaints.length > 0 &&
                    complaints.map((items: any) => {
                      let ref_no = isEmpty(items.ref_no) ? '-' : `#${items.ref_no}`
                      let title = isEmpty(items.title) ? '-' : items.title
                      let type_name = isEmpty(items.type_name) ? '-' : items.type_name
                      let customer_name =
                        isEmpty(items.customer) && isEmpty(items.customer.business_name)
                          ? '-'
                          : items.customer.business_name
                      let sr_name =
                        isEmpty(items.customer) && isEmpty(items.customer.sr_name)
                          ? '-'
                          : items.customer.sr_name
                      let assign_to_name = isEmpty(items.assign_to_name)
                        ? '-'
                        : items.assign_to_name
                      let assign_at = isEmpty(items.assign_at) ? (
                        '-'
                      ) : (
                        <div>
                          {dateFormat(items.assign_at).displayDate}{' '}
                          <div>{dateFormat(items.assign_at, 'LT').displayDate}</div>
                        </div>
                      )
                      let priority = isEmpty(items.priority)
                        ? '-'
                        : firstLetterCapital(items.priority)
                      let status = isEmpty(items.status) ? (
                        '-'
                      ) : (
                        <>
                          {items.status === 'open' && (
                            <span className='badge badge-light-warning text-dark'>Open</span>
                          )}

                          {items.status === 'resolved' && (
                            <span className='badge badge-light-primary text-dark'>Resolved</span>
                          )}

                          {items.status === 'closed' && (
                            <span className='badge badge-light-success text-dark'>Closed</span>
                          )}

                          {items.status === 'in_process' && (
                            <span className='badge badge-light-danger text-dark'>In Progress</span>
                          )}
                        </>
                      )

                      return (
                        <tr className='cursor-pointer' onClick={() => navigateTo(items.id)}>
                          <td>{ref_no}</td>
                          <td>{title}</td>
                          <td>{type_name}</td>
                          <td>{customer_name}</td>
                          {/* <td>{sr_name}</td> */}
                          <td>{assign_to_name}</td>
                          <td>{assign_at}</td>
                          <td>{priority}</td>
                          <td>{status}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <>
        <Modal
          show={showModal}
          onHide={clear}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
          className='complaint-modal'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>Add Complaint</h2>
            </Modal.Title>
            <div className='btn p-1 mh-24 btn-active-light-primary ms-2' onClick={clear}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-sm-12 mb-4'>
                <label className='form-label'>
                  Complaint Title<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  placeholder='Type here'
                  className={`form-control ${checkInValid(updateComplaint.isValidTitle)}`}
                  value={updateComplaint.title}
                  name='title'
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
              </div>
              <div className='col-sm-12 mb-4'>
                <label className='form-label'>
                  Complaint Type<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  loadOptions={fetchComplaintType}
                  isSearchable
                  placeholder='Select'
                  className={`react-select-container my-1 me-2 ${checkInValid(
                    updateComplaint.isValidType
                  )}`}
                  onChange={(e) => {
                    handleChange(e, 'type')
                  }}
                  value={updateComplaint.type}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              <div className='col-sm-12 mb-4'>
                <label className='form-label'>
                  Warehouse Name<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  loadOptions={fetchWarehouse}
                  onChange={(e: any) => {
                    handleChange(e, 'warehouse')
                  }}
                  isSearchable
                  placeholder='Select'
                  value={updateComplaint.warehouse}
                  className={`react-select-container ${checkInValid(
                    updateComplaint.isValidWarehouse
                  )}`}
                  isDisabled={!isEmpty(warehouse?.value) && selectedCustomer}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              <div className='col-sm-12 mb-4'>
                <label className='form-label'>
                  Customer Name<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  key={customerReset}
                  loadOptions={fetchCustomerModal}
                  isSearchable
                  placeholder='Select'
                  className={`react-select-container my-1 me-2 ${checkInValid(
                    updateComplaint.isValidCustomer
                  )}`}
                  onChange={(e) => {
                    handleChange(e, 'customer')
                  }}
                  isDisabled={
                    isEmpty(updateComplaint.warehouse.value) ||
                    (!isEmpty(warehouse?.value) && selectedCustomer)
                  }
                  value={updateComplaint.customer}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              <div className='mb-4'>
                <label className='form-label' id='userType'>
                  User Type<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  loadOptions={fetchUserType}
                  isSearchable
                  placeholder='Select'
                  className={`react-select-container ${checkInValid(
                    updateComplaint.isValidUserType
                  )}`}
                  onChange={(e) => {
                    handleChange(e, 'userType')
                  }}
                  value={updateComplaint.userType}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              {updateComplaint.userType.value !== 'ts' && (
                <div className='mb-4'>
                  <label className='form-label' id='roleModal'>
                    Role<span className='text-danger'>*</span>
                  </label>
                  <AsyncPaginate
                    key={rolesReset}
                    loadOptions={fetchRolesModal}
                    onChange={(e) => {
                      handleChange(e, 'role')
                    }}
                    value={updateComplaint.role}
                    isSearchable
                    isDisabled={isEmpty(updateComplaint.userType.value)}
                    placeholder='Select'
                    className={`react-select-container ${checkInValid(
                      updateComplaint.isValidRole
                    )}`}
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
              )}
              <div className='mb-4'>
                <label className='form-label' id='roleModal'>
                  Assignee<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  key={assignReset}
                  loadOptions={fetchAssignee}
                  onChange={(e: any) => {
                    handleChange(e, 'assign')
                  }}
                  isSearchable
                  placeholder='Select'
                  value={updateComplaint.assign}
                  isDisabled={
                    updateComplaint.userType.value === 'ts'
                      ? false
                      : updateComplaint.role.value === ''
                  }
                  className={`react-select-container ${checkInValid(
                    updateComplaint.isValidAssign
                  )}`}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              <div className='mb-4'>
                <label className='form-label' id='roleModal'>
                  Priority<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  loadOptions={fetchPriority}
                  onChange={(e: any) => {
                    handleChange(e, 'priority')
                  }}
                  isSearchable
                  placeholder='Select'
                  value={updateComplaint.priority}
                  className={`react-select-container ${checkInValid(
                    updateComplaint.isValidPriority
                  )}`}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              <div className='mb-4'>
                <label className='form-label' id='roleModal'>
                  Date & Time
                </label>
                <DateRangePicker
                  onCallback={(start, end, label) => {
                    const callBakeDate = {
                      startDate: start._d,
                    }

                    setModalApiDate(callBakeDate)
                    handleChange(start._d, 'dateTime')
                  }}
                  initialSettings={{
                    startDate: moment(modalApiDate.startDate).format(globalDateTimeFormate()),
                    autoUpdateInput: true,
                    alwaysShowCalendars: true,
                    cancelClass: 'btn-secondary',
                    drops: 'auto',
                    parentEl: '.complaint-modal',
                    maxDate: moment(),
                    locale: {
                      format: globalDateTimeFormate(),
                    },
                    timePicker: true,
                    singleDatePicker: true,
                  }}
                >
                  <input
                    type='text'
                    className='form-control calendar my-1'
                    defaultValue={modalApiDate}
                  />
                </DateRangePicker>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={clear}
                disabled={submit !== 'Submit'}
              >
                Cancel
              </button>
              <button className='btn btn-primary' onClick={onSubmit} disabled={submit !== 'Submit'}>
                {submit}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>

      <CustomPaginate
        data={complaints}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default Complaints
