import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  DistrictCreate,
  RegionCreate,
  RegionList,
  RegionSingle,
  RegionUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import {
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation
} from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import TableRegions from "./component/tableRegions"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"

type Props = {
  className: string
}

const Regions: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Regions',
  }

  // all states
  const [kmlFile, setKmlFile] = useState('')
  const [kmlFileDistrict, setKmlFileDistrict] = useState('')
  const [kmlFileNameDistrict, setKmlFileNameDistrict] = useState('Browse')
  const [kmlFileName, setKmlFileName] = useState('Browse')
  const [KmlFile, setKMLFile] = useState('')
  const [regionName, setRegionName] = useState('')
  const [districtName, setDistrictName] = useState('')
  const [kmlFileEdit, setKmlFileEdit] = useState('')
  const [kmlFileNameEdit, setKmlFileNameEdit] = useState('Browse')
  const [kmlFileNameKml, setkmlFileNameKml] = useState('Browse')
  const [regionNameEdit, setRegionNameEdit] = useState('')
  const [getList, setGetList] = useState<any>([])
  const [id, setId] = useState('')
  const [regionId, setregionId] = useState('')
  const [getLength, setLength] = useState('1')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [count, setCount] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [addNameKml, setAddNameKml] = useState('')
  const [regionvalidation, setRegionValidation] = useState('')
  const [districtValidation, setDistrictValidation] = useState('')
  const [regionValidationEdit, setRegionValidationEdit] = useState('')
  const [kmlValidationEdit, setKmlValidationEdit] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [permission, setPermission] = useState(defaultPermission)

  useEffect(() => {
    fetchPermission()
    setListLoader(true)
    RegionList('10', '0').then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_distribution_boundaries")
    setPermission(res)
   }

  const addFunction = () => {
    let navigateField = {
      regionName: regionName,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // ui validation [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (regionName.trim() == '') {
      setRegionValidation('is-invalid')
    } else {
      setRegionValidation('')
    }

    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (regionName.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')

      RegionCreate(regionName, kmlFile).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunction()
          setListLoader(true)
          RegionList('10', '0').then((res) => {
            setGetList(res.data)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setCount(res.count)
            setNext(res.next)
            setSelected(0)
            setPrev(res.prev)
            setListLoader(false)
          })
          setIsAlertSuccess(true)

          setTimeout(() => {
            setIsAlertSuccess(false)
            setIsAlertFailed(false)
          }, 2000)
          $('#ea_modal_close_add_region').trigger('click') // modal close
        }
        if (res.failed) {
          // setIsAlertFailed(true)
        }
        if (res.errorField == 'name') {
          setErrorMessage(
            'Your added region name already exists in this system! Please try again with a unique name.'
          )
        }
      })
    }
  }

  const addFunctionDistrict = () => {
    if (districtName.trim() == '') {
      setDistrictValidation('is-invalid')
    } else {
      setDistrictValidation('')
    }

    if (districtName.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      DistrictCreate(districtName, regionId, kmlFileDistrict).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')

        if (res.success) {
          clearFunctionDistrict()
          setListLoader(true)
          RegionList('10', '0').then((res) => {
            setGetList(res.data)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setCount(res.count)
            setNext(res.next)
            setSelected(0)
            setPrev(res.prev)
            setListLoader(false)
          })
          setIsAlertSuccess(true)
          $('#ea_modal_close_add_District').trigger('click') // modal close
        }
        if (res.failed) {
          // setIsAlertFailed(true)
        }
        if (res.errorField == 'name') {
          setErrorMessage(
            'Your added district name already exists in this system! Please try again with a unique name.'
          )
        }
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
      })
    }
  }

  const editFunction = () => {
    let navigateField = {
      regionNameEdit: regionNameEdit,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (regionNameEdit.trim() == '') {
      setRegionValidationEdit('is-invalid')
    } else {
      setRegionValidationEdit('')
    }

    if (regionNameEdit.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      RegionUpdate(id, regionNameEdit, kmlFileEdit).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunctionEdit()
          setListLoader(true)
          RegionList('10', '0').then((res) => {
            setGetList(res.data)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setCount(res.count)
            setNext(res.next)
            setSelected(0)
            setPrev(res.prev)
            setListLoader(false)
          })
          setIsAlertSuccess(true)
        }
        if (res.failed) {
          setIsAlertFailed(true)
        }
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        $('#ea_modal_close_edit_region').trigger('click') // modal close
      })
    }
  }

  const kmlFunction = () => {
    if (KmlFile == '') {
      setKmlValidationEdit('is-invalid')
    } else {
      setKmlValidationEdit('')
    }

    if (KmlFile !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')

      RegionUpdate(id, addNameKml, KmlFile).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunctionKML()
          setListLoader(true)
          RegionList('10', '0').then((res) => {
            setGetList(res.data)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)
            setListLoader(false)
          })
          setIsAlertSuccess(true)
        }
        if (res.failed) {
          setIsAlertFailed(true)
        }
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        $('#ea_modal_close_kml_region').trigger('click') // modal close
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      RegionList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      RegionList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      RegionList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)
      RegionList('10', '0', e.target.value).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
        setListLoader(false)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
      })
    }, 500)
    setTimer(newTimer)
  }

  const clearFunction = () => {
    // clear add modal state
    setErrorMessage('')
    setKmlFile('')
    setKmlFileName('Browse')
    setRegionName('')
    setRegionValidation('')
  }

  const clearFunctionDistrict = () => {
    // clear add Dist. modal state
    setErrorMessage('')
    setKmlFileNameDistrict('Browse')
    setKmlFileDistrict('')
    setDistrictName('')
    setDistrictValidation('')
  }

  const clearFunctionEdit = () => {
    // clear edit modal state
    setKmlFileEdit('')
    setKmlFileNameEdit('Browse')
    setRegionNameEdit('')
    setRegionValidationEdit('')
  }

  const clearFunctionKML = () => {
    // clear KML modal state
    setKMLFile('')
    setkmlFileNameKml('Browse')
    setKmlValidationEdit('')
  }

  const getProps = (val: any) => {
    setId(val.id)

    // get single region
    RegionSingle(val.id).then((res: any) => {
      setRegionNameEdit(res.name)
      setKmlFileEdit(res.kml_file)
    })
  }

  const getDeleteProps = () => {
    // delete
  }

  const getKmlProps = (val: any) => {
    setMessageHandler('successKml')
    setId(val.id)
    setAddNameKml(val.name)
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const selectKmlFile = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setKmlFileName(e.target.files[0].name)
        setKmlFile(e.target.files[0])
      }
      e.target.value = ''
    }
  }

  const selectKmlFileDistrict = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setKmlFileNameDistrict(e.target.files[0].name)
        setKmlFileDistrict(e.target.files[0])
      }
      e.target.value = ''
    }
  }

  const getDistrictProps = (val: any) => {
    setMessageHandler('successDAdd')
    setregionId(val.id)
  }

  const selectKmlFileKml = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setkmlFileNameKml(e.target.files[0].name)
      setKMLFile(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const selectKmlFileEdit = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setKmlFileNameEdit(e.target.files[0].name)
        setKmlFileEdit(e.target.files[0])
      }
      e.target.value = ''
    }
  }

  return (
    <>
      <EATitle title='Regions' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>{intl.formatMessage({ id: 'Regions' })}</PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('REGION.ADD')
            : messageHandler === 'successDAdd'
              ? FormatMessage('DISTRICT.ADD')
              : FormatMessage('KML.ADD')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => searchList(e)} />
            {permission.write && (
              <div className='col-md-auto ms-0 ms-md-3 mt-2 mt-md-0 ms-md-auto'>
                <EaButton
                  isModal={true}
                  dataTarget='#ea_modal_add_region'
                  btnName='Add Region'
                  onClick={() => setMessageHandler('successAdd')}
                />
              </div>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0 ? (
              <NoRecords />
            ) : (
              <TableRegions
                showDelete={permission.delete}
                showWrite={permission.write}
                getDistrictProps={getDistrictProps}
                getKmlProps={getKmlProps}
                getLength={getLength}
                tableList={getList}
                getPropsEdit={getProps}
                getPropsDelete={getDeleteProps}
              />
            )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
              } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>

      {/* add region */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunction}
        id='ea_modal_add_region'
        modalTitle='Add Region'
        closeAuto='ea_modal_close_add_region'
        onSubmit={() => addFunction()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Region Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='regionName'
            className={clsx('form-control', regionvalidation)}
            placeholder='Type here...'
            value={regionName}
            onChange={(e) => {
              setRegionName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setRegionValidation(validation)
            }}
          />
        </div>
        <div className=''>
          <label className='form-label'>KML File</label>
          <label htmlFor='kml-file' className={clsx('form-control file-input')}>
            <span className='text-muted'>{kmlFileName}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            onChange={selectKmlFile}
            id='kml-file'
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* edit region */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionEdit}
        id='ea_modal_edit_region'
        modalTitle='Edit Region'
        closeAuto='ea_modal_close_edit_region'
        onSubmit={() => editFunction()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Region Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='regionNameEdit'
            className={clsx('form-control', regionValidationEdit)}
            placeholder='Type here...'
            value={regionNameEdit}
            onChange={(e) => setRegionNameEdit(e.target.value)}
          />
        </div>
        <div className=''>
          <label className='form-label'>KML File</label>
          <label htmlFor='kml-file' className='form-control file-input'>
            <span className='text-muted'>{kmlFileNameEdit}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            onChange={selectKmlFileEdit}
            id='kml-file'
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* add KML */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionKML}
        id='ea_modal_kml_region'
        modalTitle='Add KML File'
        closeAuto='ea_modal_close_kml_region'
        onSubmit={() => kmlFunction()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            KML File<span className="text-danger">*</span>
          </label>
          <label htmlFor='kml-file' className={clsx('form-control file-input', kmlValidationEdit)}>
            <span className='text-muted'>{kmlFileNameKml}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            //
            onChange={selectKmlFileKml}
            id='kml-file'
          />
        </div>
      </EaModal>

      {/* new districvt modal */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionDistrict}
        id='ea_modal_District_region'
        modalTitle='Add District'
        closeAuto='ea_modal_close_add_District'
        onSubmit={() => addFunctionDistrict()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            District Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', districtValidation)}
            placeholder='Type here...'
            value={districtName}
            onChange={(e) => {
              setDistrictName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setDistrictValidation(validation)
            }}
          />
        </div>

        <div className=''>
          <label className='form-label'>KML File</label>
          <label htmlFor='kml-file' className={clsx('form-control file-input')}>
            <span className='text-muted'>{kmlFileNameDistrict}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            onChange={selectKmlFileDistrict}
            id='kml-file'
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  )
}

export default Regions
