import { WAREHOUSE_ID } from "../../types/types"

const initialState: any = {
    warehouseId: '',
}


export const warehouseReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case WAREHOUSE_ID:
            return {
                warehouseId: action.payload,
            }

        default:
            return {
                ...state
            }
    }
}
