import { useEffect } from "react"
import { Alert } from "react-bootstrap"
import { eaCloseModal, KTSVG } from "../../../helpers"
import FormatMessage from "../../../helpers/components/FormateMessage"

interface success {
    show: any, onClick: any, message: any, newProps?: any
}

export const EaAlertSuccess = (props: any) => {
    const { newProps, showAlert, message, name, setShowAlert } = props

    useEffect(() => {
        if (showAlert) {
            eaCloseModal()
            setTimeout(() => {
                setShowAlert(false)
            }, 1500);
        }
    }, [showAlert])

    if (newProps) {
        const handleAlert = () => {
            setShowAlert(false)
        }

        return (
            <Alert show={showAlert} className='alert bg-light-success border-0 d-flex flex-sm-row py-3 px-5 align-items-center mb-5'>
                <div className="text-dark pe-2">
                    {name ? FormatMessage(message, name) : FormatMessage(message)}
                </div>
                <button type="button" className="position-relative btn ms-auto p-0" onClick={handleAlert} >
                    <span className="svg-icon-dark">
                        <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="ic me-0" />
                    </span>
                </button>
            </Alert>
        )
    } else {
        const { show, onClick, message, newProps } = props
        return (
            <Alert show={show} className='alert bg-light-success border-0 d-flex flex-sm-row py-3 px-5 align-items-center mb-5'>
                <div className="text-dark pe-2">
                    {message}
                </div>
                <button type="button" className="position-relative btn ms-auto p-0" onClick={onClick} >
                    <span className="svg-icon-dark">
                        <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="ic me-0" />
                    </span>
                </button>
            </Alert>
        )
    }
}

export const EaAlertDanger = (props: any) => {

    const { show, onClick, message, showAlert, setShowAlert, newProps, name } = props

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false)
            }, 1500);
        }
    }, [showAlert])

    if (newProps) {
        const handleAlert = () => {
            setShowAlert(false)
        }

        return (
            <Alert show={showAlert} className='alert bg-light-danger border-0 d-flex flex-sm-row py-3 px-5 align-items-center mb-5'>
                <div className="text-dark pe-2">
                    {name ? FormatMessage(message, name) : FormatMessage(message)}
                </div>
                <button type="button" className="position-relative btn ms-auto p-0" onClick={handleAlert} >
                    <span className="svg-icon-dark">
                        <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="ic me-0" />
                    </span>
                </button>
            </Alert>
        )

    } else {
        return (
            <Alert show={show} className='alert bg-light-danger border-0 d-flex flex-sm-row py-3 px-5 align-items-center mb-5'>
                <div className="text-dark pe-2">
                    {message}
                </div>
                <button type="button" className="position-relative btn ms-auto p-0" onClick={onClick} >
                    <span className="svg-icon-dark">
                        <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="ic me-0" />
                    </span>
                </button>
            </Alert>
        )
    }
}
