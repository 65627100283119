import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {KTSVG, toAbsoluteUrl} from '../../../../_eaFruitsDms/helpers'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {
  OrderingArrowDown,
  OrderingArrowUp,
} from '../../../../_eaFruitsDms/layout/components/orderingArrow/orderingArrow'

const TableProductSku = (props: any) => {
  const {ProductSKU, Brand, Category, ProductGroup, Variant, Packaging} = props.orderingColumn
  return (
    <>
      {props.getLength !== '1' ? (
        <NoRecords />
      ) : (
        <table className='table table-row-bordered align-middle gs-0 gy-2'>
          <thead>
            <tr className=' text-muted fw-500'>
              {/* <th className='w-25px py-2'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                />
                            </div>
                        </th> */}
              <th className='min-w-100px py-2'>
                Product SKU
                <span onClick={() => props.handleOrdering('ProductSKU')}>
                  {ProductSKU ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              <th className='min-w-100px py-2'>
                Brand
                <span onClick={() => props.handleOrdering('Brand')}>
                  {Brand ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              <th className='min-w-120px py-2'>
                Category
                <span onClick={() => props.handleOrdering('Category')}>
                  {Category ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              <th className='min-w-140px py-2'>
                Product Group
                <span onClick={() => props.handleOrdering('ProductGroup')}>
                  {ProductGroup ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              <th className='min-w-100px py-2'>
                Variant
                <span onClick={() => props.handleOrdering('Variant')}>
                  {Variant ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              <th className='min-w-100px py-2'>Size</th>
              <th className='min-w-130px py-2'>
                Packaging
                <span onClick={() => props.handleOrdering('Packaging')}>
                  {Packaging ? <OrderingArrowUp /> : <OrderingArrowDown />}
                </span>
              </th>
              {props.showWrite || props.showDelete ? (
                <th className='min-w-80px action-th py-2'>Actions</th>
              ) : (
                <th className='min-w-80px action-th py-2'></th>
              )}
            </tr>
          </thead>
          <tbody>
            {props.tableList.map((item: any) => (
              <tr>
                {/* <td>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                </div>
                            </td> */}
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-24px me-2'>
                      <span className='symbol-label'>
                        {item.product_thumbnail !== null ? (
                          <img
                            src={item.product_thumbnail}
                            className='h-40 rounded-1'
                            alt=''
                            style={{width: '100%', height: '100%'}}
                          />
                        ) : (
                          <img
                            alt=''
                            className='h-35px rounded-1'
                            src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                          />
                        )}
                      </span>
                    </div>
                    <span>{item.name}</span>
                    {item.additional_notes && (
                      <OverlayTrigger
                        delay={{hide: 450, show: 300}}
                        overlay={(props) => (
                          <Tooltip {...props} className='tooltip-dark'>
                            {item.additional_notes}
                          </Tooltip>
                        )}
                        placement='bottom'
                      >
                        <span className='ms-2 cursor-pointer'>
                          <KTSVG path='/media/icons/duotune/general/gen045.svg' className='mr-0' />
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                </td>
                <td>{item.brand_id}</td>
                <td>{item.category_name}</td>
                <td>{item.group_name}</td>
                <td>{item.variants.name}</td>
                <td>
                  {item.fix_size
                    ? item.measurement_unit.toLowerCase() === 'gram'
                      ? item.size / 1000
                      : item.size
                    : item.variants.approx_weight > 0
                    ? item.variants.approx_weight / 1000
                    : 1}{' '}
                  {item.measurement_unit.toLowerCase() === 'gram' ? 'Grams' : 'Kgs'}
                </td>
                <td>{item.material}</td>
                <td className='text-end'>
                  {props.showWrite && (
                    <a
                      href='#'
                      data-bs-toggle='modal'
                      data-bs-target='#ea_modal_Edit_sku'
                      onClick={() => props.getPropsEdit(item)}
                      className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                    </a>
                  )}
                  {/* {props.showDelete &&
                                        <a href='#deleteProductSKU'
                                            data-bs-toggle='modal'
                                            data-bs-target='#deleteProductSKU'
                                            onClick={() => props.getPropsDelete(item)}
                                            className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                        </a>} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  )
}

export default TableProductSku
