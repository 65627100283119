import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {AsyncPaginate} from 'react-select-async-paginate'
import {EaStatisticsWidget} from '../../../../_eaFruitsDms/partials/widgets'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdownNew,
  fetchStaticAsyncDropdown,
  formatTags,
  globalDateFormate,
  isAdmin,
  isEmpty,
  measurementLabel,
  numberFormat,
} from '../../../../_eaFruitsDms/helpers'
import tenantConfiguration from '../../../../TenantVariables'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {gatePassStatus, requestStatus} from './gatepass-constant'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  className: string
}
const GatePassReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Gatepass Report',
  }
  const currentWarehouseId = isAdmin() ? '' : localStorage.getItem('w_id')

  const [warehouses, setWarehouses] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [currentOffSet, setCurrentOffSet] = useState<any>('0')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [displayDetailsLoader, setDisplayDetailsLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [warehouseOffSet, setWarehouseOffSet] = useState<number>(0)
  const [srOffSet, setSrOffSet] = useState<number>(0)
  const [sr, setSr] = useState<any>({
    label: 'All Sales Reps',
    value: '',
  })
  const [entry, setEntry] = useState<any>({
    label: 'All Entry',
    value: '',
  })
  const [srReset, setSrReset] = useState<any>(moment())
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [filteredDate, setFilteredDate] = useState<any>({
    startDate: dateFormat(moment()).apiDate,
    endDate: dateFormat(moment()).apiDate,
  })
  const [warehouseId, setWarehouseId] = useState<any>({
    label: 'All Warehouses',
    value: '',
  })
  const [gatePassList, setGatePassList] = useState<any>([])
  const [gatePassBrands, setGatePassBrands] = useState<any>([])
  const [metaCount, setMetaCount] = useState<any>({
    total_late: 0,
    total_return: 0,
  })
  const [currentGatePassId, setCurrentGatePassId] = useState<number>(0)

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchGatePassReportList().then()

    let selectedWarehouseId = isEmpty(warehouseId.value) ? '' : warehouseId.value

    if (checkUserType('oo')) {
      selectedWarehouseId = currentWarehouseId
    }

    localStorage.setItem('Gatepass-start-date', filteredDate.startDate)
    localStorage.setItem('Gatepass-end-date', filteredDate.endDate)
    localStorage.setItem('Gatepass-warehouse', selectedWarehouseId)
    localStorage.setItem('Gatepass-sr-value', sr.value)
    localStorage.setItem('Gatepass-sr-name', sr.label)
    localStorage.setItem('Gatepass-entry-value', entry.value)
  }, [debounceSearch, warehouseId, sr, selected, currentOffSet, filteredDate, entry])

  useEffect(() => {
    setWarehouseOffSet(0)
    setSrOffSet(0)
  }, [searchSelect])

  const fetchGatePassReportList = async () => {
    setDisplayLoader(true)

    const startDate = filteredDate.startDate
    const endDate = filteredDate.endDate

    let selectedWarehouseId = isEmpty(warehouseId.value) ? '' : warehouseId.value

    if (checkUserType('oo')) {
      selectedWarehouseId = currentWarehouseId
    }

    const response = await getRequest(
      `${apiBaseURL()}reports/gate-pass?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&warehouse_id=${selectedWarehouseId}&sr_id=${
        isEmpty(sr.value) ? '' : sr.value
      }&date_duration_after=${startDate}&date_duration_before=${endDate}&is_auto_handled=${
        entry.value
      }`,
      true
    )

    const reportData: any = []

    if (response.results) {
      if (response.meta) {
        setMetaCount({
          total_late: response.meta.total_late,
          total_return: response.meta.total_return,
        })
      }

      setCount(response.count)

      response.results.map((data: any) => {
        return reportData.push({
          id: data.id,
          gate_pass_id: data.gatepass_number,
          sr_name: data.sr_name,
          vehicle_no: data.vehicle_no,
          brands: data.product_brands.length > 0 ? data.product_brands : [],
          display_date: isEmpty(data.date_time) ? '-' : dateFormat(data.date_time).displayDate,
          vehicle_is_for_horeca: data.vehicle_is_for_horeca,
          departure_time: isEmpty(data.departure_date_time)
            ? '-'
            : dateFormat(data.departure_date_time, 'LT').displayDate,
          return_time: isEmpty(data.return_date_time)
            ? '-'
            : dateFormat(data.return_date_time, 'LT').displayDate,
          departure_status: isEmpty(data.departure_status)
            ? '-'
            : gatePassStatus[data.departure_status],
          request_status: isEmpty(data.status) ? '-' : requestStatus[data.status],
          is_auto_handled: isEmpty(data.is_auto_handled)
            ? '-'
            : data.is_auto_handled
            ? 'System'
            : 'SR',
          text_class: isEmpty(data.departure_status)
            ? ''
            : data.departure_status === 'late'
            ? 'text-danger'
            : '',
        })
      })
    }
    setGatePassList(reportData)
    setDisplayLoader(false)
  }

  const fetchGatePassDetail = async (gatePassId: number) => {
    if (gatePassId !== currentGatePassId) {
      setCurrentGatePassId(gatePassId)
      setDisplayDetailsLoader(true)
      setGatePassBrands([])
      const response = await getRequest(
        `${apiBaseURL()}reports/gate-pass/${gatePassId}/brands`,
        true
      )

      if (response.results && response.results.length > 0) {
        const brands: any = []

        response.results.map((brand: any) => {
          const label = measurementLabel(
            brand.fix_size
              ? brand.material_name
              : brand.approx_weight > 0
              ? brand.material_name
              : brand.measurement_unit
          )

          return brands.push({
            product_id: brand.product_id,
            product_name: brand.product_name,
            brand_name: brand.product_brand_name,
            loaded_stock: brand.approved_qty + ' ' + label,
            returned_qty: brand.returned_qty + ' ' + label,
          })
        })
        setGatePassBrands(brands)
      }
      setDisplayDetailsLoader(false)
    }
  }

  const fetchWarehouses = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=${currentWarehouseId}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSrs = async (search?: string) => {
    let selectedWarehouseId = isEmpty(warehouseId.value) ? '' : warehouseId.value

    if (checkUserType('oo')) {
      selectedWarehouseId = currentWarehouseId
    }

    return await fetchAsyncDropdownNew(
      srOffSet,
      setSrOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `reports/users/drop-down?ordering=first_name&warehouse_id=${selectedWarehouseId}&type=sr`,
      search,
      setSearchSelect,
      'full_name',
      '',
      true,
      'All Sales Reps'
    )
  }

  const fetchEntry = (search?: string) => {
    const status = [
      {label: 'All Entry', value: ''},
      {label: 'SR', value: 'False'},
      {label: 'System', value: 'True'},
    ]

    return fetchStaticAsyncDropdown(status, search)
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-1 w-auto'
            defaultValue={value}
            disabled={displayLoader || displayDetailsLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name

    switch (inputName) {
      case 'warehouse':
        setWarehouseId(e)
        setSalesRepsOption([])
        setSrReset(moment())
        break
      case 'entry':
        setEntry(e)
        break
      case 'search':
        setSearchByName(e.target.value)
        break
      case 'sr':
        setSr(e)
        break
    }
  }

  return (
    <>
      <EATitle title='Gatepass Report' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Gatepass Report'})}
      </PageTitle>
      <div>
        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={handleFilterChange}
              isDisable={displayLoader || displayDetailsLoader}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                {checkUserType(['sa', 'wm']) && (
                  <AsyncPaginate
                    loadOptions={fetchWarehouses}
                    isDisabled={displayLoader || displayDetailsLoader}
                    isSearchable
                    className='react-select-container my-1 me-3 w-160px'
                    classNamePrefix='react-select'
                    placeholder='All Warehouses'
                    value={warehouseId}
                    onChange={(e: any) => handleFilterChange(e, 'warehouse')}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
                <AsyncPaginate
                  key={srReset}
                  loadOptions={fetchSrs}
                  className='react-select-container my-1 me-3 w-140px'
                  classNamePrefix='react-select'
                  isDisabled={displayLoader || displayDetailsLoader}
                  placeholder='All Sales Reps'
                  onChange={(e: any) => handleFilterChange(e, 'sr')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchEntry}
                  className='react-select-container my-1 me-3 w-140px'
                  classNamePrefix='react-select'
                  isDisabled={displayLoader || displayDetailsLoader}
                  placeholder='All Entry'
                  onChange={(e: any) => handleFilterChange(e, 'entry')}
                  value={entry}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setFilteredDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                  }}
                  value={filteredDate}
                  startDate={filteredDate.startDate}
                  endDate={filteredDate.endDate}
                />
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            <div className='row g-3 g-xl-5'>
              <div className='col-sm-6 col-md-6 col-lg col-xl'>
                <EaStatisticsWidget
                  className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                  color='success'
                  title={numberFormat(metaCount.total_late).displayFormat}
                  description='Total Late'
                />
              </div>
              <div className='col-sm-6 col-md-6 col-lg col-xl'>
                <EaStatisticsWidget
                  className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                  color='info'
                  title={numberFormat(metaCount.total_return).displayFormat}
                  description='Total Return Qty'
                />
              </div>
            </div>
            {/* begin::Table container */}
            <div
              className='table-responsive expandable'
              style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
              id='expandTable'
            >
              {displayLoader ? (
                <FetchingRecords />
              ) : gatePassList.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-100px'>Gatepass ID</th>
                      <th className='min-w-120px w-120px'>SR Name</th>
                      <th className='min-w-120px'>Truck No.</th>
                      <th className='min-w-120px'>Brand</th>
                      <th className='w-150px min-w-150px'>Date</th>
                      <th className='w-150px min-w-150px'>Gatepass Status</th>
                      <th className='w-150px min-w-150px'>Entry Check By</th>
                      <th className='w-150px min-w-150px'>Departure Time</th>
                      <th className='w-100px min-w-150px'>Return Time</th>
                      <th className='min-w-100px'>Departure Status</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {gatePassList.map((gatePass: any) => {
                      const brandsLength = gatePass.brands.length
                      return (
                        <>
                          <tr
                            data-bs-toggle='collapse'
                            data-bs-target={`.row-collapse${gatePass.id}`}
                            aria-controls={`.rowCollapse${gatePass.id}`}
                            className='cursor-pointer'
                            onClick={() => fetchGatePassDetail(gatePass.id)}
                          >
                            <td>{gatePass.gate_pass_id}</td>
                            <td>{gatePass.sr_name}</td>
                            <td>{gatePass.vehicle_no}</td>
                            <td>
                              {gatePass.vehicle_is_for_horeca ? (
                                'All Brands'
                              ) : brandsLength === 0 ? (
                                '-'
                              ) : brandsLength === 1 ? (
                                gatePass.brands[0].name
                              ) : (
                                <OverlayTrigger
                                  delay={{hide: 450, show: 300}}
                                  overlay={(props) => (
                                    <Tooltip {...props} className='tooltip-dark'>
                                      <span>{formatTags(gatePass.brands, 'name')}</span>
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <span className='py-1 cursor-default'>
                                    {gatePass.brands[0].name}{' '}
                                    {brandsLength > 1 && `& ${brandsLength - 1} More`}
                                  </span>
                                </OverlayTrigger>
                              )}
                            </td>
                            <td>{gatePass.display_date}</td>
                            <td>{gatePass.request_status}</td>
                            <td
                              className={gatePass.is_auto_handled === 'System' ? 'text-danger' : ''}
                            >
                              {gatePass.is_auto_handled}
                            </td>
                            <td className={gatePass.text_class}>{gatePass.departure_time}</td>
                            <td>{gatePass.return_time}</td>
                            <td className={gatePass.text_class}>{gatePass.departure_status}</td>
                          </tr>
                          <tr className='expandable-row'>
                            <td className='reset-expansion-style' colSpan={11}>
                              <div
                                className={`row-expansion-style collapse row-collapse${gatePass.id} collapsible`}
                                id={`rowCollapse${gatePass.id}`}
                                data-bs-parent='#expandTable'
                              >
                                <div>
                                  {displayDetailsLoader ? (
                                    <FetchingRecords />
                                  ) : gatePassBrands.length === 0 ? (
                                    <NoRecords />
                                  ) : (
                                    <table className='table table-bordered gs-4 mb-0'>
                                      <thead>
                                        <tr className='text-muted fw-500 bg-light2'>
                                          <th className='w-200px py-2'>Brand</th>
                                          <th className='w-250px py-2'>Product</th>
                                          <th className='w-200px py-2'>Loaded Stock</th>
                                          <th className='min-w-120px py-2'>Returned Stock</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {gatePassBrands.map((brand: any) => {
                                          return (
                                            <tr>
                                              <td>{brand.brand_name}</td>
                                              <td>{brand.product_name}</td>
                                              <td>{brand.loaded_stock}</td>
                                              <td>{brand.returned_qty}</td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={gatePassList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffSet}
          totalRecords={count}
          limit={limit}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default GatePassReport
