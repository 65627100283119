import React from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import Messages from './components/messages'
import MyAssignedHeader from './components/myAssignedHeader'
import Overview from './components/overview'

const MyAssignedDetail: React.FC = () => {
  const intl = useIntl()
  const supportReqTitle = {
    title: 'Complaints',
    subTitle: 'My Assigned',
  }

  return (
    <>
      <PageTitle breadcrumbs={[supportReqTitle]}>
        {intl.formatMessage({id: 'My Assigned'})}
      </PageTitle>
      <MyAssignedHeader />
      <Routes>
        <Route path='overview' element={<Overview />} />
        <Route path='messages' element={<Messages />} />
        <Route index element={<Navigate to='overview' />} />
      </Routes>
    </>
  )
}

export default MyAssignedDetail
