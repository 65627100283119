import { apiBaseURL } from "../../helpers"
import { deleteRequest, getRequest, postRequest, putRequest } from "../../https/apiCall"
import { getToken } from "../../localStorage/getItem/getLocalStorage"

const header = {
  headers: {
    Authorization: `Bearer ${getToken}`,
  },
}

// product/create
export const AddSkus = async (
  name: any,
  brand_id: any,
  // category_id: any,
  variants: any,
  // near_expiry: any,
  additional_notes: any,
  // material: any,
  // product_vat: any,
  fix_size: any,
  // measurement_unit: any,
  size: any,
  // shelf_life: any,
  product_img: any
) => {
  const notify = {
    success: false,
    failed: false,
  }

  let form_data = new FormData()
  form_data.append('name', name)
  form_data.append('brand_id', brand_id)
  // form_data.append('category_id', category_id)
  form_data.append('variants', variants)
  // form_data.append('near_expiry', near_expiry)
  form_data.append('additional_notes', additional_notes)
  // form_data.append('material', material)
  // form_data.append('product_vat', product_vat)
  form_data.append('fix_size', fix_size)
  // form_data.append('measurement_unit', measurement_unit)
  form_data.append('size', size)

  // form_data.append('shelf_life', shelf_life)
  if (product_img.name) {
    form_data.append('product_img', product_img, product_img.name)
  }
  //  else {
  //     form_data.append('brand_image', product_img);
  // }

  await postRequest(`${apiBaseURL()}master/product/create`, form_data, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}

// product/edit
export const EditSkus = async (
  name: any,
  brand_id: any,
  // category_id: any,
  variants: any,
  // near_expiry: any,
  additional_notes: any,
  // material: any,
  // product_vat: any,
  fix_size: any,
  // measurement_unit: any,
  size: any,
  // shelf_life: any,
  product_img: any,
  id: any
) => {
  const notify = {
    success: false,
    failed: false,
  }

  let form_data = new FormData()
  form_data.append('name', name)
  form_data.append('brand_id', brand_id)
  // form_data.append('category_id', category_id)
  form_data.append('variants', variants)
  // form_data.append('near_expiry', near_expiry)
  form_data.append('additional_notes', additional_notes)
  // form_data.append('material', material)
  // if (product_vat == '') {
  //   form_data.append('product_vat', '0')
  // } else {
  //   form_data.append('product_vat', product_vat)
  // }
  form_data.append('fix_size', fix_size)
  // form_data.append('measurement_unit', measurement_unit)
  if (size === '') {
    form_data.append('size', '1')
  } else {
    form_data.append('size', size)
  }
  // form_data.append('shelf_life', shelf_life)
  if (product_img.name) {
    form_data.append('product_img', product_img, product_img.name)
  }
  await putRequest(`${apiBaseURL()}master/product/update/${id}/`, form_data, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}

//request method for api implementation :- last change by kriti
export const SkuList = async (
  limit: any,
  offset: any,
  search?: any,
  ordering?: any,
  brand?: any,
  category?: any,
  size?: any,
  material?: any,
  group_name?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
    length: '',
  }

  let filterUrl = `&brand_id__id=${brand}&category_id__id=${category}&size=${size}&material__id=${material}&variants__group_name__id=${group_name}`

  await getRequest(
    `${apiBaseURL()}master/product/view?limit=${limit}&ordering=${ordering}&offset=${offset}${filterUrl}${search ? `&search=${search}` : ''
    }`,
    true
  )
    .then((res) => {
      data.count = res.count
      data.next = res.next
      data.prev = res.previous
      data.data = res.results
      data.length = res.results.length
    })
    .catch((err) => {
      console.log('SkuList Error ==>>', err.response.data)
    })
  return data
}

//request method for api implementation :- last change by kriti
export const SkuSingle = async (id: any) => {
  let data = ''

  await getRequest(`${apiBaseURL()}master/product/update/${id}/`, true)
    .then((res) => {
      data = res
    })
    .catch((err) => {
      console.log('SkuSingle Error ==>>', err.response.data)
    })
  return data
}

export const SkuListSeacrch = async (
  limit: any,
  offset: any,
  brand?: any,
  category?: any,
  size?: any,
  material?: any,
  search?: any,
  group_name?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  await getRequest(
    `${apiBaseURL()}master/product/view?limit=${limit}&offset=${offset}&brand_id__id=${brand}&category_id__id=${category}&size=${size}&material__id=${material}&search=${search}&variants__group_name__id=${group_name}`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const productSkuDelete = async (id: string) => {
  const notify = {
    success: false,
    failed: false,
  }
  await deleteRequest(`${apiBaseURL()}master/product/update/${id}/`, true).then((res) => {
    if (res.status === 204) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}
