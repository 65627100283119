import React from 'react'
import { useIntl } from 'react-intl'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
type Props = {
    className: string
}
const CreditCappingUpdate: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Credit Capping Updates"
    }
    return (
        <>
            <EATitle title="Credit Capping Updates - " />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Credit Capping Updates' })}</PageTitle>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2022',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar my-1 w-auto min-w-215px" />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-150px py-2'>Date & Time</th>
                                        <th className='min-w-150px py-2'>Customer Name</th>
                                        <th className='min-w-150px py-2'>Previous Capping</th>
                                        <th className='min-w-200px py-2'>New Capping</th>
                                        <th className='min-w-150px py-2'>Changed By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            06 Jun 2022
                                            <p className='font-13 text-muted mb-0'>07:24AM</p>
                                        </td>
                                        <td>
                                            Mark Payne
                                        </td>
                                        <td>
                                            TZS 40381
                                        </td>
                                        <td>
                                            TZS 49461
                                        </td>
                                        <td>
                                            Ola Sims
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            06 Jun 2022
                                            <p className='font-13 text-muted mb-0'>07:24AM</p>
                                        </td>
                                        <td>
                                            Willie Sanders
                                        </td>
                                        <td>
                                            TZS 40381
                                        </td>
                                        <td>
                                            TZS 49461
                                        </td>
                                        <td>
                                            Ola Sims
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            06 Jun 2022
                                            <p className='font-13 text-muted mb-0'>07:24AM</p>
                                        </td>
                                        <td>
                                            Mark Payne
                                        </td>
                                        <td>
                                            TZS 40381
                                        </td>
                                        <td>
                                            TZS 49461
                                        </td>
                                        <td>
                                            Ola Sims
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            06 Jun 2022
                                            <p className='font-13 text-muted mb-0'>07:24AM</p>
                                        </td>
                                        <td>
                                            Willie Sanders
                                        </td>
                                        <td>
                                            TZS 40381
                                        </td>
                                        <td>
                                            TZS 49461
                                        </td>
                                        <td>
                                            Ola Sims
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            06 Jun 2022
                                            <p className='font-13 text-muted mb-0'>07:24AM</p>
                                        </td>
                                        <td>
                                            Mark Payne
                                        </td>
                                        <td>
                                            TZS 40381
                                        </td>
                                        <td>
                                            TZS 49461
                                        </td>
                                        <td>
                                            Ola Sims
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            06 Jun 2022
                                            <p className='font-13 text-muted mb-0'>07:24AM</p>
                                        </td>
                                        <td>
                                            Willie Sanders
                                        </td>
                                        <td>
                                            TZS 40381
                                        </td>
                                        <td>
                                            TZS 49461
                                        </td>
                                        <td>
                                            Ola Sims
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                {/* end:: card */}
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            Showing 1 to 10 of 50 entries
                        </div>
                        <div className="col">
                            <CustomPagination />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreditCappingUpdate
