import React from "react";
import { KTSVG } from "../../../../../../_eaFruitsDms/helpers";
import AddCustomer from "../../addCustomer";

const Overview = ({customer}: any) => {
    return (
        <>
          {
            customer && customer.status.toLocaleString() !== 'approved' &&  <div className='bg-light-warning d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>
                <span className="svg-icon-dark">
                    <KTSVG path="/media/icons/duotune/general/gen045.svg" className="ic me-3" />
                </span>

              <div className="text-dark pe-2">
                  As you are doing approval of the customer on-boarding, please go through below configured details,
                  and if require update them before approving customer. In case you would like to Reject this request,
                  then you may do it by using button given at bottom of the page!
                </div>
            </div>
          }

            <AddCustomer overview={true} />
        </>
    )
}

export default Overview
