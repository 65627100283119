import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  fetchAsyncDropdown,
  fetchStaticAsyncDropdown,
  getFirstWarehouse,
  isEmpty,
  KTSVG,
  numberFormat,
  numberFormatWithCode,
} from '../../../../_eaFruitsDms/helpers'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest, postRequest, putRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import moment from 'moment'

const RewardOffers = () => {
  const initialState = {
    title: '',
    description: '',
    brand_id: {label: 'Select', value: ''},
    product_sku_id: {
      label: 'Select',
      value: '',
      wholesale_price: '',
      retail_price: '',
    },
    warehouse_id: {label: 'Select', value: ''},
    qty: '',
    supplier_id: {label: 'Select', value: ''},
    required_sokopoints: '',
    status: true,
    title_error: '',
    brand_id_error: '',
    product_sku_id_error: '',
    warehouse_id_error: '',
    qty_error: '',
    supplier_id_error: '',
    required_sokopoints_error: '',
    status_error: '',
  }

  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [message, setMessage] = useState('')
  const [rewardsOffers, setRewardsOffers] = useState([])
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [show, setShow] = useState(false)
  const [currentOffSet, setCurrentOffSet] = useState<any>('0')
  const [displayLoader, setDisplayLoader] = useState<boolean>(true)
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [sokoAmount, setSokoAmount] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [warehouse, setWarehouse] = useState({value: '', label: 'Select'})
  const [warehouseInitial, setWarehouseInitial] = useState({value: '', label: 'Select'})
  const [warehouseModal, setWarehouseModal] = useState({value: 'Select', label: ''})
  const [status, setStatus] = useState<any>({
    label: 'All Status',
    value: '',
  })
  const [brand, setBrand] = useState({
    label: 'All Brand',
    value: '',
  })
  const [product, setProduct] = useState({
    label: 'All Product',
    value: '',
  })
  const [brandsOffSet, setBrandsOffSet] = useState<any>('')
  const [brandsOption, setBrandsOption] = useState<any>([])
  const [productsOffSet, setProductsOffSet] = useState<any>('')
  const [productsOption, setProductsOption] = useState<any>([])
  const [supplierOffSet, setSupplierOffSet] = useState<any>('')
  const [supplierOption, setSupplierOption] = useState<any>([])
  const [reward, setReward] = useState(initialState)
  const [id, setId] = useState('')
  const [submit, setSubmit] = useState('Submit')
  const [brandsFilterOffSet, setBrandsFilterOffSet] = useState<any>('')
  const [brandsFilterOption, setBrandsFilterOption] = useState<any>([])
  const [productsFilterOffSet, setProductsFilterOffSet] = useState<any>('')
  const [productsFilterOption, setProductsFilterOption] = useState<any>([])
  const [type, setType] = useState('retailer')
  const [supplierId, setSupplierId] = useState('')
  const [resetProductSku, setResetProductSku] = useState<any>(moment())
  const [resetProductSkuFilter, setResetProductSkuFilter] = useState<any>(moment())

  const debounceSearch = useDebounce(searchByName, 500)

  const fetchRewardOffers = async () => {
    setDisplayLoader(true)

    const url = `${apiBaseURL()}master/sokopoint-rewards-master/list?limit=${limit}&offset=${currentOffSet}&warehouse_id=${
      isEmpty(warehouse?.value) ? '' : warehouse?.value
    }&search=${debounceSearch}&status=${isEmpty(status?.value) ? '' : status?.value}&brand_id=${
      isEmpty(brand?.value) ? '' : brand?.value
    }&product_sku_id=${isEmpty(product?.value) ? '' : product?.value}&customer_type=${type}`

    const response = await getRequest(url, true)

    if (response?.results) {
      setRewardsOffers(response.results)
      setCount(response?.count ?? 0)
    }

    setDisplayLoader(false)
  }

  const fetchRewardOffersDetails = async () => {
    setIsModalLoading(true)

    const url = `${apiBaseURL()}master/sokopoint-rewards/${id}`

    const response = await getRequest(url, true)

    if (response?.result) {
      let updateReward = {...reward}

      const {
        title,
        description,
        brand_id,
        brand_name,
        product_sku_id,
        product_sku_name,
        qty,
        supplier_id,
        supplier_name,
        required_sokopoints,
        status,
        warehouse_name,
        warehouse_id,
        wholesale_price,
        retail_price,
      } = response?.result

      updateReward.title = isEmpty(title) ? '' : title
      updateReward.description = isEmpty(description) ? '' : description
      updateReward.brand_id =
        isEmpty(brand_id) || isEmpty(brand_name)
          ? {label: 'Select', value: ''}
          : {label: brand_name, value: brand_id}
      updateReward.product_sku_id =
        isEmpty(product_sku_id) || isEmpty(product_sku_name)
          ? {label: 'Select', value: '', retail_price: '', wholesale_price: ''}
          : {
              label: product_sku_name,
              value: product_sku_id,
              retail_price: retail_price,
              wholesale_price: wholesale_price,
            }
      updateReward.supplier_id =
        isEmpty(supplier_id) || isEmpty(supplier_name)
          ? {label: 'Select', value: ''}
          : {label: supplier_name, value: supplier_id}
      updateReward.qty = isEmpty(qty) ? '' : qty
      updateReward.required_sokopoints = isEmpty(required_sokopoints) ? '' : required_sokopoints
      updateReward.status = isEmpty(status) ? false : status == 'inactive' ? false : true
      updateReward.warehouse_id =
        isEmpty(warehouse_id) || isEmpty(warehouse_name)
          ? {label: 'Select', value: ''}
          : {label: warehouse_name, value: warehouse_id}

      setReward(updateReward)
    }

    setIsModalLoading(false)
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name

    setCurrentOffSet('')
    setSelected(0)

    switch (inputName) {
      case 'status':
        setStatus(e)
        break

      case 'warehouse':
        setWarehouse(e)
        setResetProductSkuFilter(moment())
        setProductsFilterOffSet('')
        setProductsFilterOption([])
        break

      case 'brand':
        setBrand(e)
        setResetProductSkuFilter(moment())
        setProductsFilterOffSet('')
        setProductsFilterOption([])
        setProduct({
          label: 'All Product',
          value: '',
        })

        break

      case 'product':
        setProduct(e)
        break

      case 'search':
        setSearchByName(e.target.value)
        break
    }
  }

  const getFirstWh = async () => {
    const url = `${apiBaseURL()}warehouse/list?ordering=name`
    const response: any = await getFirstWarehouse(url)

    if (response && response.value) {
      setWarehouse(response)
      setWarehouseModal(response)
      setWarehouseInitial(response)
    }
  }

  const fetchBrands = async (search?: string) => {
    return await fetchAsyncDropdown(
      brandsOffSet,
      setBrandsOffSet,
      brandsOption,
      setBrandsOption,
      `master/product/brandSKU?ordering=name`,
      search,
      setSearchSelect,
      'name',
      false
    )
  }

  const fetchBrandsFilter = async (search?: string) => {
    return await fetchAsyncDropdown(
      brandsFilterOffSet,
      setBrandsFilterOffSet,
      brandsFilterOption,
      setBrandsFilterOption,
      `master/product/brandSKU?ordering=name`,
      search,
      setSearchSelect,
      'name',
      true,
      'All Brand'
    )
  }

  const fetchProductFilter = async (search?: string) => {
    return await fetchAsyncDropdown(
      productsFilterOffSet,
      setProductsFilterOffSet,
      productsFilterOption,
      setProductsFilterOption,
      `inventory/product-SKUs/?warehouse_id=${warehouse?.value}&ordering=name&only_set_priced=true&brand_id__id=${brand?.value}`,
      search,
      setSearchSelect,
      'name',
      true,
      'All Product'
    )
  }

  const fetchStatus = async (search?: string) => {
    const status = [
      {label: 'All Status', value: ''},
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'},
    ]

    return await fetchStaticAsyncDropdown(status, search)
  }

  const fetchProduct = async (search: any) => {
    if (search != '') {
      setSearchSelect(search)
      setProductsOffSet('')
    }

    let limit = tenantConfiguration.limit // default limit

    let options: any = []

    let concatUrl = `${apiBaseURL()}inventory/product-SKUs/?warehouse_id=${
      reward.warehouse_id?.value
    }&ordering=name&only_set_priced=true&brand_id__id=${
      reward?.brand_id?.value
    }&search=${search}&limit=${limit}&offset=${productsOffSet}`

    const response = await getRequest(concatUrl, true)

    let hasMore: boolean = false

    if (response.results) {
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next)
        let newOffset: any = queryParams.get('offset')
        setProductsOffSet(newOffset)
        hasMore = true
      }

      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: option.name,
            value: option.id,
            wholesale_price: option?.price?.length > 0 ? option.price[0].wholesale_price : '',
            retail_price: option?.price?.length > 0 ? option?.price[0].retail_price : '',
          })
        })
      }

      if (productsOption.length > 0) {
        setProductsOption(productsOption.concat(options))
      } else {
        setProductsOption(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSokoAmount = async () => {
    const url = `${apiBaseURL()}master/sokopoint-spending/list?warehouse_id=${
      reward.warehouse_id?.value
    }&customer_type=${type}&brand_id=${reward?.brand_id?.value}`

    const response = await getRequest(url, true)

    if (response?.result && response?.result?.length > 0) {
      if (response?.result?.[0]?.soko_amount) {
        setSokoAmount(response?.result[0].soko_amount)
      }
    }
  }

  useEffect(() => {
    if (
      !isEmpty(type) &&
      !isEmpty(reward?.brand_id?.value) &&
      !isEmpty(reward.warehouse_id?.value)
    ) {
      fetchSokoAmount()
    }
  }, [reward.brand_id?.value, reward.warehouse_id?.value, type])

  const fetchSupplier = async (search?: string) => {
    const response = await fetchAsyncDropdown(
      supplierOffSet,
      setSupplierOffSet,
      supplierOption,
      setSupplierOption,
      `master/sokopoint-supplier/list?ordering=name`,
      search,
      setSearchSelect,
      'name',
      false
    )

    if (response && response.options?.length > 0) {
      const data = response.options[0]

      if (!isEmpty(data?.value)) setSupplierId(data.value)
    }
  }

  const onChange = (e: any, name?: string) => {
    const inputName = e?.target ? e?.target?.name : name
    const value = e?.target ? e?.target?.value : e
    const re = /^[0-9\b]+$/

    let updatedReward: any = {...reward}

    switch (inputName) {
      case 'title':
        updatedReward[inputName] = value
        updatedReward[`${inputName}_error`] = ''
        break

      case 'warehouse_id':
        setResetProductSku(moment())
        setProductsOffSet('')
        setProductsOption([])

        updatedReward[inputName] = value
        updatedReward[`${inputName}_error`] = ''

        updatedReward[`product_sku_id_error`] = ''

        updatedReward['product_sku_id'] = {
          label: 'Select',
          value: '',
          wholesale_price: '',
          retail_price: '',
        }

        break

      case 'brand_id':
        setResetProductSku(moment())
        setProductsOffSet('')
        setProductsOption([])

        updatedReward[inputName] = value
        updatedReward[`${inputName}_error`] = ''

        updatedReward['product_sku_id'] = {
          label: 'Select',
          value: '',
          wholesale_price: '',
          retail_price: '',
        }

        updatedReward[`product_sku_id_error`] = ''

        break

      case 'product_sku_id':
        updatedReward[inputName] = value
        updatedReward[`${inputName}_error`] = ''
        break

      case 'qty':
        if (e.target.value === '' || re.test(e.target.value)) {
          if (e.target.value !== '0') {
            updatedReward[inputName] = value
            updatedReward[`${inputName}_error`] = ''
          }
        }
        break

      case 'required_sokopoints':
        if (e.target.value === '' || re.test(e.target.value)) {
          if (e.target.value !== '0') {
            updatedReward[inputName] = value
            updatedReward[`${inputName}_error`] = ''
          }
        }
        break

      case 'description':
        updatedReward[inputName] = value
        updatedReward[`${inputName}_error`] = ''
        break
    }

    setReward(updatedReward)
  }

  const checkValid = () => {
    let valid = true
    let updatedReward = {...reward}

    if (isEmpty(updatedReward?.title?.trim())) {
      updatedReward.title_error = 'is-invalid'
      valid = false
    }

    if (isEmpty(updatedReward?.brand_id?.value)) {
      updatedReward.brand_id_error = 'is-invalid'
      valid = false
    }

    if (isEmpty(updatedReward?.product_sku_id?.value)) {
      updatedReward.product_sku_id_error = 'is-invalid'
      valid = false
    }

    if (isEmpty(updatedReward?.warehouse_id?.value)) {
      updatedReward.warehouse_id_error = 'is-invalid'
      valid = false
    }

    if (isEmpty(updatedReward?.qty)) {
      updatedReward.qty_error = 'is-invalid'
      valid = false
    }

    if (isEmpty(updatedReward?.required_sokopoints)) {
      updatedReward.required_sokopoints_error = 'is-invalid'
      valid = false
    }

    setReward(updatedReward)

    return valid
  }

  const onSubmit = async () => {
    const valid = checkValid()

    if (valid) {
      setSubmit('Please Wait')

      let url = `${apiBaseURL()}master/sokopoint-rewards/create`

      if (!isEmpty(id)) {
        url = `${apiBaseURL()}master/sokopoint-rewards/edit/${id}`
      }

      const body = {
        title: reward?.title?.trim() ?? '',
        description: reward?.description?.trim() ?? '',
        brand_id: reward?.brand_id?.value ?? '',
        product_sku_id: reward?.product_sku_id?.value,
        qty: reward?.qty,
        supplier_id: supplierId,
        warehouse_id: reward?.warehouse_id?.value,
        required_sokopoints: reward?.required_sokopoints,
        status: reward?.status ? 'active' : 'inactive',
        customer_type: type,
      }

      const response = isEmpty(id)
        ? await postRequest(url, body, true)
        : await putRequest(url, body, true)

      if ([200, 201].includes(response?.status)) {
        isModalClear()
        fetchRewardOffers()

        if (isEmpty(id)) {
          setMessage('Reward offer added successfully')
        } else {
          setMessage('Reward offer updated successfully')
        }

        setIsAlertSuccess(true)

        setTimeout(() => {
          setIsAlertSuccess(false)
        }, 2000)
      }

      setSubmit('Submit')
    }
  }

  const isModalClear = () => {
    setShow(false)
    setReward(initialState)
    setId('')
    setBrandsOffSet('')
    setBrandsOption([])
    setProductsOffSet('')
    setProductsOption([])
    setSupplierOffSet('')
    setSupplierOption([])
    setWarehouseModal(warehouseInitial)
    setSokoAmount(0)
  }

  const updateStatus = async (status: boolean, id: string) => {
    const url = `${apiBaseURL()}master/sokopoint-rewards/status/${id}`

    const body = {
      status: status ? 'active' : 'inactive',
    }

    await putRequest(url, body, true)
  }

  const countPrice = () => {
    let price =
      type === 'retailer'
        ? isEmpty(reward?.product_sku_id?.retail_price)
          ? 0
          : parseInt(reward?.product_sku_id?.retail_price)
        : isEmpty(reward?.product_sku_id?.wholesale_price)
        ? 0
        : parseInt(reward?.product_sku_id?.wholesale_price)

    if (!isEmpty(reward.qty) && !isEmpty(reward?.product_sku_id?.value)) {
      const total = price * parseInt(reward.qty)

      return numberFormatWithCode(total).displayFormat
    }

    return numberFormatWithCode(0).displayFormat
  }

  const countSokoAmount = () => {
    let price = parseInt(`${sokoAmount}`)

    if (!isEmpty(reward.required_sokopoints) && !isEmpty(reward?.brand_id?.value)) {
      const total = price * parseInt(reward.required_sokopoints)

      return numberFormatWithCode(total).displayFormat
    }

    return numberFormatWithCode(0).displayFormat
  }

  useEffect(() => {
    !isEmpty(warehouse.value) && fetchRewardOffers()
  }, [currentOffSet, debounceSearch, brand, product, status, type, warehouse])

  useEffect(() => {
    if (!isEmpty(id)) fetchRewardOffersDetails()
  }, [id])

  useEffect(() => {
    getFirstWh()
  }, [])

  useEffect(() => {
    fetchSupplier()
  }, [])

  return (
    <>
      <EATitle title='Reward Offers ' />
      <PageTitle
        breadcrumbs={[
          {
            title: 'Masters',
            subTitle: 'Reward Offers',
          },
        ]}
      >
        Reward Offers
      </PageTitle>
      <EaAlertSuccess
        onClick={() => setIsAlertSuccess(false)}
        show={isAlertSuccess}
        message={message}
      />

      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-1 pb-0'>
          <div className='d-flex overflow-auto h-40px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap cursor-pointer'>
              <li
                className='nav-item'
                onClick={() => {
                  setType('retailer')
                }}
              >
                <div
                  className={`nav-link text-active-primary me-0 ${
                    type === 'retailer' ? 'active' : ''
                  }`}
                >
                  Retail Customer
                </div>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  setType('wholesaler')
                }}
              >
                <div
                  className={`nav-link text-active-primary me-0 ${
                    type === 'wholesaler' ? 'active' : ''
                  }`}
                >
                  Wholesale Customer
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div className='card'>
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={handleFilterChange} isDisable={displayLoader} />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                <WarehouseFilter
                  className='w-200px me-3'
                  warehouse={warehouse}
                  handleFilterChange={(e: any) => handleFilterChange(e, 'warehouse')}
                  allWarehouse={false}
                  isDisabled={displayLoader}
                />

                <AsyncPaginate
                  loadOptions={fetchBrandsFilter}
                  isSearchable
                  placeholder='All Brand'
                  className={`react-select-container w-170px me-3`}
                  onChange={(e) => {
                    handleFilterChange(e, 'brand')
                  }}
                  value={brand}
                  isDisabled={displayLoader}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  key={resetProductSkuFilter}
                  loadOptions={fetchProductFilter}
                  isSearchable
                  placeholder='All Product'
                  className={`react-select-container w-170px me-3`}
                  onChange={(e) => {
                    handleFilterChange(e, 'product')
                  }}
                  value={product}
                  isDisabled={displayLoader || isEmpty(warehouse?.value) || isEmpty(brand?.value)}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  placeholder='All Status'
                  className={`react-select-container w-120px me-3`}
                  onChange={(e) => {
                    handleFilterChange(e, 'status')
                  }}
                  value={status}
                  isDisabled={displayLoader}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <button className='btn btn-primary ms-3' onClick={() => setShow(true)}>
                  Add Reward
                </button>
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              {displayLoader ? (
                <FetchingRecords />
              ) : rewardsOffers.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-100px'>Reward Title</th>
                      <th className='min-w-100px'>Brand</th>
                      <th className='min-w-100px'>Product Sku</th>
                      <th className=''>Qty</th>
                      <th className=''>Supplier</th>
                      <th className=''>SokoPoint</th>
                      <th className=''>Status</th>
                      <th className=''>Action</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {rewardsOffers &&
                      rewardsOffers?.map((i: any) => {
                        return (
                          <>
                            <tr>
                              <td>{checkEmpty(i?.title)}</td>
                              <td>{checkEmpty(i?.brand_name)}</td>
                              <td>{checkEmpty(i?.product_sku_name)}</td>
                              <td>{isEmpty(i?.qty) ? '-' : numberFormat(i?.qty).displayFormat}</td>
                              <td>{checkEmpty(i?.supplier_name)}</td>
                              <td>
                                {isEmpty(i?.required_sokopoints)
                                  ? '-'
                                  : numberFormat(i?.required_sokopoints).displayFormat}
                              </td>
                              <td>
                                {isEmpty(i?.status) ? (
                                  '-'
                                ) : (
                                  <>
                                    {/* {i?.status === 'inactive' ? (
                                      <>
                                        <span className='badge badge-light-danger text-dark'>
                                          Inactive
                                        </span>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {i?.status === 'active' ? (
                                      <>
                                        <span className='badge badge-light-success text-dark'>
                                          Active
                                        </span>
                                      </>
                                    ) : (
                                      ''
                                    )} */}

                                    <div className='col-sm-12 mb-4'>
                                      <div className='form-check form-switch form-check-custom form-check-solid'>
                                        <input
                                          onChange={(e) => {
                                            updateStatus(e.target.checked, i.id)
                                          }}
                                          value={i?.status === 'active' ? '1' : '0'}
                                          className='form-check-input h-30px w-50px'
                                          type='checkbox'
                                          name='status'
                                          defaultChecked={i?.status === 'active'}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </td>
                              <td>
                                {' '}
                                <div
                                  onClick={() => {
                                    setShow(true)
                                    setId(i?.id ?? '')
                                  }}
                                  className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm cursor-pointer me-2'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='ic mr-0'
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <CustomPaginate
          data={rewardsOffers}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffSet}
          totalRecords={count}
          limit={limit}
          isDisabled={displayLoader}
        />
      </div>

      {/* modal */}
      <Modal
        show={show}
        onHide={isModalClear}
        centered
        backdrop='static'
        dialogClassName='h-auto mw-570px'
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>
              {isEmpty(id) ? 'Add' : 'Edit'}{' '}
              {type === 'retailer' ? 'Retail Customer Reward' : 'Wholesale Customer Reward'}
            </h2>
          </Modal.Title>
          <div className='btn p-1 mh-24 btn-active-light-primary ms-2' onClick={isModalClear}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            {isEmpty(supplierId) || isModalLoading || isEmpty(warehouseModal?.value) ? (
              <FetchingRecords />
            ) : (
              <div className='row'>
                <div className='col-sm-12 mb-4'>
                  <label className='form-label' htmlFor=''>
                    Reward Title<span className='text-danger'>*</span>
                  </label>
                  <div>
                    <input
                      type='text'
                      onChange={(e) => {
                        onChange(e)
                      }}
                      placeholder='Type here..'
                      value={reward.title}
                      name='title'
                      className={`form-control ${reward.title_error}`}
                    />
                  </div>
                </div>

                <div className='col-sm-12 mb-4'>
                  <label className='form-label'>
                    Warehouse<span className='text-danger'>*</span>
                  </label>
                  <WarehouseFilter
                    className={`w-full ${reward.warehouse_id_error}`}
                    warehouse={reward.warehouse_id}
                    handleFilterChange={(e: any) => {
                      onChange(e, 'warehouse_id')
                    }}
                    allWarehouse={false}
                    isDisabled={false}
                  />
                </div>

                <div className='col-sm-12 mb-4'>
                  <label className='form-label'>
                    Brand<span className='text-danger'>*</span>
                  </label>
                  <AsyncPaginate
                    loadOptions={fetchBrands}
                    isSearchable
                    placeholder='Select'
                    className={`react-select-container ${reward.brand_id_error}`}
                    onChange={(e) => {
                      onChange(e, 'brand_id')
                    }}
                    value={reward.brand_id}
                    isDisabled={false}
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>

                <div className='col-sm-6 mb-4'>
                  <label className='form-label'>
                    Product Sku<span className='text-danger'>*</span>
                  </label>
                  <AsyncPaginate
                    key={resetProductSku}
                    loadOptions={fetchProduct}
                    isSearchable
                    placeholder='Select'
                    className={`react-select-container ${reward.product_sku_id_error}`}
                    onChange={(e) => {
                      onChange(e, 'product_sku_id')
                    }}
                    value={reward.product_sku_id}
                    isDisabled={
                      isEmpty(reward.warehouse_id?.value) || isEmpty(reward?.brand_id?.value)
                    }
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>

                <div className='col-sm-6 mb-4'>
                  <label className='form-label' htmlFor=''>
                    Single SKu Price
                  </label>
                  <div>
                    <input
                      type='text'
                      disabled
                      placeholder='0'
                      value={
                        type === 'retailer'
                          ? isEmpty(reward?.product_sku_id?.retail_price)
                            ? numberFormatWithCode(0).displayFormat
                            : numberFormatWithCode(reward?.product_sku_id?.retail_price)
                                .displayFormat
                          : isEmpty(reward?.product_sku_id?.wholesale_price)
                          ? numberFormatWithCode(0).displayFormat
                          : numberFormatWithCode(reward?.product_sku_id?.wholesale_price)
                              .displayFormat
                      }
                      name='required_soko_amount'
                      className={`form-control`}
                    />
                  </div>
                </div>

                <div className='col-sm-6 mb-4'>
                  <label className='form-label' htmlFor=''>
                    Qty<span className='text-danger'>*</span>
                  </label>
                  <div>
                    <input
                      type='text'
                      onChange={(e) => {
                        onChange(e, 'qty')
                      }}
                      placeholder='Type here..'
                      name='qty'
                      value={reward.qty}
                      autoComplete='off'
                      className={`form-control ${reward.qty_error}`}
                    />
                  </div>
                </div>

                <div className='col-sm-6 mb-4'>
                  <label className='form-label' htmlFor=''>
                    Total Current Payable
                  </label>
                  <div>
                    <input
                      type='text'
                      disabled
                      placeholder='0'
                      value={countPrice()}
                      name=''
                      className={`form-control`}
                    />
                  </div>
                </div>

                <div className='col-sm-6 mb-4'>
                  <label className='form-label' htmlFor=''>
                    SokoPoints<span className='text-danger'>*</span>
                  </label>
                  <div>
                    <input
                      type='text'
                      onChange={(e) => {
                        onChange(e, 'required_sokopoints')
                      }}
                      placeholder='Type here..'
                      value={reward.required_sokopoints}
                      name='required_sokopoints'
                      className={`form-control ${reward.required_sokopoints_error}`}
                    />
                  </div>
                </div>

                <div className='col-sm-6 mb-4'>
                  <label className='form-label' htmlFor=''>
                    SokoAmount
                  </label>
                  <div>
                    <input
                      type='text'
                      disabled
                      placeholder='0'
                      value={countSokoAmount()}
                      name='required_soko_amount'
                      className={`form-control`}
                    />
                  </div>
                </div>

                <div className='col-sm-12 mb-4'>
                  <label className='form-label' htmlFor=''>
                    Short Description
                  </label>
                  <div>
                    <textarea
                      onChange={(e) => {
                        onChange(e, 'description')
                      }}
                      placeholder='Type here..'
                      value={reward.description}
                      name='description'
                      rows={5}
                      className={`form-control`}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn btn-secondary me-3'
              onClick={isModalClear}
              disabled={
                isEmpty(supplierId) ||
                isModalLoading ||
                isEmpty(warehouseModal?.value) ||
                submit !== 'Submit'
              }
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                onSubmit()
              }}
              disabled={
                isEmpty(supplierId) ||
                isModalLoading ||
                isEmpty(warehouseModal?.value) ||
                submit !== 'Submit'
              }
            >
              {submit}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RewardOffers
