import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { apiBaseURL, dateFormat, fetchAsyncDropdown } from "../../../../../../_eaFruitsDms/helpers";
import { getRequest } from "../../../../../../_eaFruitsDms/https/apiCall";
import IsLoader from "../../../../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../../../_eaFruitsDms/layout/components/searchbar/searchbar";

const SurveyName: React.FC = () => {

    const location: any = useLocation();
    const navigate = useNavigate();

    const limit = "10"
    const [feedBackId, setFeedBackId] = useState('')
    const [list, setList] = useState([])
    const [search, setSearch] = useState('')
    const [count, setCount] = useState('');
    const [next, setNext] = useState('');
    const [prev, setPrev] = useState('');
    const [timer, setTimer] = useState<any>(null);
    const [selected, setSelected] = useState(0);
    const [currentOffset, setCurrentOffset] = useState<any>('');
    const [isLoading, setIsLoading] = useState(true);
    const [warehouseId, setWarehouseId] = useState('')

    const numberClick = (val: any, index: any) => {
        if (val) {
            setSelected(val - 1)
            let newOffset = index * 10;
            setCurrentOffset(newOffset)
        }
    }

    const prevPage = () => {
        if (prev !== null) {
            const queryParams = new URLSearchParams(prev);
            const newOffset = queryParams.get('offset');
            setCurrentOffset(newOffset)
            setSelected(selected - 1)
        }
    }

    const nextPage = () => {
        if (next !== null) {
            const queryParams = new URLSearchParams(next);
            const newOffset = queryParams.get('offset');
            setCurrentOffset(newOffset)
            setSelected(selected + 1)
        }
    }

    const searchList = (e: any) => {
        clearTimeout(timer)
        const newTimer = setTimeout(() => {
            setSelected(0)
            setSearch(e.target.value)
        }, 500)
        setTimer(newTimer)
    }

    useEffect(() => {
        if (location.state) {
            setFeedBackId(location.state.surveyFeedBackId)
        } else {
            navigate("/tele-sales")
        }
    }, [])

    useEffect(() => {
        if (feedBackId !== "") {
            getSurveyFeedBackList()
        }
    }, [feedBackId, currentOffset, search, warehouseId])

    const getSurveyFeedBackList = () => {
        const teleUserId = localStorage.getItem('teleUserId')
        const url = `${apiBaseURL()}surveyForms/answer/list?limit=${limit}&offset=${currentOffset}&search=${search}&surveyform_id=`
        const warehouseUrl = "&warehouse_id=" + warehouseId
        const answerFilter = "&feedback_by__id=" + teleUserId

        getRequest(url + feedBackId + warehouseUrl + answerFilter, true).then((response) => {
            setNext(response.next)
            setList(response.results)
            setCount(response.count)
            setPrev(response.previous)
            if (response) {
                setIsLoading(false)
            }
        })
    }

    const [offset, setOffset] = useState(0)
    const [warehouseList, setWarehouseList] = useState([])
    const [dropDownSearch, setDropDownSearch] = useState('')

    useEffect(() => {
        setOffset(0)
    }, [dropDownSearch])

    const fetchWarehouse = async (search: string) => {
        return fetchAsyncDropdown(offset, setOffset, warehouseList, setWarehouseList, "warehouse/list?ordering=name", search, setDropDownSearch, false, true, "All Warehouse")
    }

    const onChangeWarehouse = (e: any) => {
        setWarehouseId(e.value)
    }

    return (
        <>
            <div className='card'>
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar onChange={searchList} />
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <AsyncPaginate
                    
                                loadOptions={fetchWarehouse}
                                isSearchable
                                placeholder="All Warehouses"
                                className='react-select-container my-1 me-3 w-160px'
                                classNamePrefix="react-select"
                                onChange={onChangeWarehouse}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>

                <div className='card-body py-3'>
                    {isLoading ? <IsLoader /> : <>
                        {list.length === 0 ? <NoRecords /> : <TableSurveyFeedBack list={list} />}
                    </>}
                </div>
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        {`Showing ${list.length == 0 ? 0 : selected * 10 + 1} to ${list.length == 0 ? 0 : list.length < 10 ? count : (selected + 1) * 10} of ${list.length == 0 ? 0 : count} entries`}
                    </div>
                    <div className="col">
                        <CustomPagination numberClick={numberClick} prev={prevPage} next={nextPage} count={list.length == 0 ? 0 : count} selected={selected} />
                    </div>
                </div>
            </div>
            {/* <button onClick={() => navigate("/tele-sales/survey-name")} >Navigate to survey name 1</button> */}
        </>
    )
}

export default SurveyName


export const TableSurveyFeedBack = (props: any) => {
    const navigate = useNavigate();
    return (
        <div className='table-responsive'>
            <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-1'>
                <thead>
                    <tr className='text-muted fw-500'><th className='min-w-100px py-2'>Date</th>
                        <th className='min-w-120px py-2'>Customer Name</th>
                        <th className='min-w-120px py-2'>Warehouse Name</th>
                        <th className='min-w-120px py-2'>Flag</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.list.map((item: any) => (
                            <tr onClick={() =>
                                navigate("/tele-sales/survey-name", {
                                    state: {
                                        answerId: item.id
                                    }
                                })
                            } className='cursor-pointer'>
                                <td>
                                    {dateFormat(item.submited_at).displayDate}
                                    <p className='font-13 text-muted mb-0'>{dateFormat(item.submited_at, "LT").displayDate}</p>
                                </td>
                                <td>{item.customer_id.business_name}</td>
                                <td>{item.customer_id.warehouse.length !== 0 ? item.customer_id.warehouse[0].name : "-"}</td>
                                <td>-</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
