import { StylesConfig } from "react-select"

export const categoryStyle: StylesConfig<any> = {
    option: (styles, { data }) => {
        return {
            ...styles,
            color: data.color,
            background: data.bg,
            fontWeight: data.weight,
            textTransform: data.transform,
            cursor: data.cursor,
            fontSize: data.size,
            ':hover': {
                ...styles[':hover'],
                backgroundColor: '#DEEBFF',
            },
            ':focus': {
                ...styles[':focus'],
                backgroundColor: '#DEEBFF',
            },
            ':active': {
                ...styles[':active'],
                backgroundColor: '#DEEBFF',
                color: '#fff',
            },
        }
    },
}
