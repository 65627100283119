import React from "react"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const Targets = (props: any) => {
  const teamMembersTitle = {
    title: 'Team members',
    subTitle: props.fullname,
  }
  return (
    <>
      <PageTitle breadcrumbs={[teamMembersTitle]}>{props.fullname}</PageTitle>

      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar />
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'></div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default Targets
