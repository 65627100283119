import React from "react";
import { googleApi } from "../../../../_eaFruitsDms/helpers";
import CustomerMap from "../../../../_eaFruitsDms/layout/components/eaMap/customerMap";

const CustomerLocation = () => {
//	const location:any = useLocation()
	const location:any = localStorage.getItem('location')
	const {customerLocation, photoLocation} = JSON.parse(location)

	return <>
	  <CustomerMap
		  googleMapURL={googleApi}
		  loadingElement={<div style={{ height: `100%` }} />}
		  containerElement={<div style={{ height: `100%` }} />}
		  mapElement={<div style={{ height: `100%` }} />}
		  center={{ lat: parseFloat(photoLocation.latitude), lng: parseFloat(photoLocation.longitude) }}
		  customerLocation={customerLocation}
		  photoLocation={photoLocation}
		  isMarkerShown
	  />
  </>
}

export default CustomerLocation
