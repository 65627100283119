import React from "react"
import { useIntl } from "react-intl"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const SalesReps: React.FC = () => {
    const intl = useIntl()
    const teamHeaderTitle = {
        title: "Team members",
        subTitle: "Connor Lawson"
    }
    return (
        <>
            <PageTitle breadcrumbs={[teamHeaderTitle]} >{intl.formatMessage({ id: 'Connor Lawson' })}</PageTitle>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    {/* <div className="ms-md-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <select
                                className='form-select form-select-white my-1 me-3 w-auto'
                            >
                                <option value='all'>June 2022</option>
                                <option value='active'>June 2021</option>
                                <option value='inactive'>June 2020</option>
                            </select>
                        </div>
                    </div> */}
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-150px'>SR Name</th>
                                    <th className='min-w-140px'>Streets</th>
                                    <th className='min-w-120px'>Mapped Customers</th>
                                    <th className='min-w-120px'>Payment Issues</th>

                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">BM</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Bobby McCarthy</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>3 Streets</td>
                                    <td>53</td>
                                    <td>TZS 127</td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">MM</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Millie Moss</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>2 Streets</td>
                                    <td>56</td>
                                    <td>TZS 50</td>

                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesReps
