import React, { useState } from "react"
import DatePicker from "react-date-picker"
// import Calendar from 'react-calendar'
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const Customers = (props: any) => {
  const teamMembersTitle = {
    title: 'Team members',
    subTitle: props.fullname,
  }
  const [value, onChange] = useState(new Date())
  // const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <PageTitle breadcrumbs={[teamMembersTitle]}>{props.fullname}</PageTitle>
      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar />
          <div className='ms-md-auto'>
            <div className='card-toolbar d-flex my-0'>
              <DatePicker
                className='calendar react-datepicker'
                clearIcon={null}
                calendarIcon={null}
                onChange={onChange}
                //onChange={(e: any) => onChange(e.target.value)}
                value={value}
                defaultView={'year'}
                format={'MM-yyyy'}
                monthAriaLabel={'Month'}
                monthPlaceholder='MM'
                yearAriaLabel={'Year'}
                yearPlaceholder={'YYYY'}
                // maxDetail={"month"}
                view={'year'}
                // onClickMonth = {(value, event) => alert('Clicked month: ', value)}
              />

              {/* <DatePicker
                                className='calendar react-datepicker'
                                clearIcon={null}
                                calendarIcon={null}
                                onChange={(date: any) => setStartDate(date)}
                                // value={value}
                                value={startDate}
                                defaultView={'month'}
                                format={'MM-yyyy'}

                                // monthAriaLabel={"Month"}
                                // monthPlaceholder="MM"
                                // yearAriaLabel={"Year"}
                                // yearPlaceholder={"YYYY"}
                                view={"year"}
                            /> */}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
              {/* begin::Table head */}
              <thead>
                <tr className='text-muted fw-500'>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-120px'>Category</th>
                  <th className='min-w-120px'>Type</th>
                  <th className='min-w-120px'>Address</th>
                  <th className='min-w-120px'>Credit Range</th>
                  <th className='min-w-120px'>Credit Issue</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <span className='rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10'>
                        AS
                      </span>
                      <div className='media-body'>
                        <p className='m-0 xs-font'>Artem Sazonov</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='text-muted font-13 mb-1 font-medium'>Informal</div>
                      HORECA
                    </div>
                  </td>
                  <td>Wholesaler</td>
                  <td>1281 Ramu Place</td>
                  <td>Safe</td>
                  <td>TZS 127</td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <span className='rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10'>
                        AS
                      </span>
                      <div className='media-body'>
                        <p className='m-0 xs-font'>Artem Sazonov</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='text-muted font-13 mb-1 font-medium'>Informal</div>
                      HORECA
                    </div>
                  </td>
                  <td>Wholesaler</td>
                  <td>1281 Ramu Place</td>
                  <td>Safe</td>
                  <td>TZS 127</td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <span className='rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10'>
                        AS
                      </span>
                      <div className='media-body'>
                        <p className='m-0 xs-font'>Artem Sazonov</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className='text-muted font-13 mb-1 font-medium'>Informal</div>
                      HORECA
                    </div>
                  </td>
                  <td>Wholesaler</td>
                  <td>1281 Ramu Place</td>
                  <td>Safe</td>
                  <td>TZS 127</td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>Showing 1 to 10 of 50 entries</div>
          <div className='col'>
            <CustomPagination />
          </div>
        </div>
      </div>
    </>
  )
}

export default Customers
