import React, { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { apiBaseURL, dateFormat } from "../../../../../_eaFruitsDms/helpers"
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import SurveyName from "./surveyFeedbackName/surveyFeedbackDetail"

const SurveyFeedback = () => {

    const SurveyFeed = () => {
        const limit = "10"
        const [list, setList] = useState([])
        const [search, setSearch] = useState('')
        const [count, setCount] = useState('');
        const [next, setNext] = useState('');
        const [prev, setPrev] = useState('');
        const [timer, setTimer] = useState<any>(null);
        const [selected, setSelected] = useState(0);
        const [currentOffset, setCurrentOffset] = useState<any>('');
        const [isLoading, setIsLoading] = useState(true);

        const teleUserId = localStorage.getItem('teleUserId')

        const getSurveyFeedBack = async () => {
            const url = `${apiBaseURL()}surveyForms/form/telesales/${teleUserId}?limit=${limit}&offset=${currentOffset}&search=${search}`
            const response = await getRequest(url, true);
            setNext(response.next)
            setList(response.results)
            setCount(response.count)
            setPrev(response.previous)
            if (response) {
                setIsLoading(false)
            }
        }

        const numberClick = (val: any, index: any) => {
            if (val) {
                setSelected(val - 1)
                let newOffset = index * 10;
                setCurrentOffset(newOffset)
            }
        }

        const prevPage = () => {
            if (prev !== null) {
                const queryParams = new URLSearchParams(prev);
                const newOffset = queryParams.get('offset');
                setCurrentOffset(newOffset)
                setSelected(selected - 1)
            }
        }

        const nextPage = () => {
            if (next !== null) {
                const queryParams = new URLSearchParams(next);
                const newOffset = queryParams.get('offset');
                setCurrentOffset(newOffset)
                setSelected(selected + 1)
            }
        }

        const searchList = (e: any) => {
            clearTimeout(timer)
            const newTimer = setTimeout(() => {
                setSelected(0)
                setSearch(e.target.value)
            }, 500)
            setTimer(newTimer)
        }

        useEffect(() => {
            getSurveyFeedBack()
        }, [currentOffset, search])

        return (
            <>
                <div className='card'>
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar onChange={searchList} />
                    </div>
                    <div className='card-body py-3'>
                        {isLoading ? <IsLoader /> : <>
                            {list.length === 0 ? <NoRecords /> : <TableSurveyFeedBack list={list} />}
                        </>}
                    </div>
                </div>
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            {`Showing ${list.length == 0 ? 0 : selected * 10 + 1} to ${list.length == 0 ? 0 : list.length < 10 ? count : (selected + 1) * 10} of ${list.length == 0 ? 0 : count} entries`}
                        </div>
                        <div className="col">
                            <CustomPagination numberClick={numberClick} prev={prevPage} next={nextPage} count={list.length == 0 ? 0 : count} selected={selected} />
                        </div>
                    </div>
                </div>
            </>
        )
    }



    return (
        <Routes>
            <Route path='survey-feedback-name' element={<SurveyName />} />
            <Route index element={<SurveyFeed />} />
        </Routes>
    )
}

export default SurveyFeedback


export const TableSurveyFeedBack = (props: any) => {
    const navigate = useNavigate();

    const navigateToSurvey = (id: any) => {
        navigate('/tele-sales/tele-sales-name/survey-feedback/survey-feedback-name', {
            state: {
                surveyFeedBackId: id
            }
        })
    }

    return (
        <div className='table-responsive'>
            <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-1'>
                <thead>
                    <tr className='text-muted fw-500'>
                        <th className='min-w-120px py-2'>Survey Name</th>
                        <th className='min-w-100px py-2'>Created Date</th>
                        <th className='min-w-120px py-2'>Conducted To</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.list.map((item: any) => {
                            return (
                                <tr onClick={() => {
                                    navigateToSurvey(item.id)
                                    localStorage.setItem("feedBackName", item.survey_form_name)
                                }} className='cursor-pointer'>
                                    <td>
                                        {item.survey_form_name}
                                    </td>
                                    <td>
                                        {dateFormat(item.create_date).displayDate}
                                        <p className='font-13 text-muted mb-0'>{dateFormat(item.create_date, "LT").displayDate}</p>
                                    </td>
                                    <td>
                                        {item.conducted_to ? item.conducted_to + " Customers" : "-"}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
