
export const  achievedStatus: any= [
  {label: "All Target", value: ""},
  {label: "Target Achieved", value: "true"},
  {label: "Target Not Achieved", value: "false"}
]

export const  paidStatus: any= [
  {label: "All Incentive", value: ""},
  {label: "Incentive Pending", value: "incentive_pending"},
  {label: "Incentive Paid", value: "incentive_paid"},
  {label: "Incentive Skipped", value: "incentive_skipped"},
]
