import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {isEmpty} from '../../../helpers'

const InfoIcon = (props: any) => {
  const {message, placement} = props

  return (
    <>
      {!isEmpty(message) && (
        <div className='me-3'>
          <OverlayTrigger
            delay={{hide: 450, show: 300}}
            overlay={(props) => (
              <Tooltip {...props} className='tooltip-dark'>
                <span>{message}</span>
              </Tooltip>
            )}
            placement={placement ? placement : 'left'}
          >
            <span className='ms-2 cursor-pointer'>
              <i className='fa fa-info-circle' aria-hidden='true'></i>
            </span>
          </OverlayTrigger>
        </div>
      )}
    </>
  )
}

export default InfoIcon
