import React, {useState} from 'react'
import {isEmpty, numberFormat, numberFormatWithCode} from '../../../../_eaFruitsDms/helpers'
import tenantConfiguration from '../../../../TenantVariables'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'

const Overview = (props: any) => {
  const {business, collection} = props
  const [listLoadder, setListLoader] = useState<any>(false)

  return (
    <div className='row'>
      {listLoadder ? (
        <IsLoader />
      ) : (
        <>
          {/* Business summery start */}
          <div className='col-sm-6 col-md-6 col-lg col-xl'>
            <div className='card card-xl-stretch mb-5'>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                <h3 className='card-title align-items-start flex-column my-0'>
                  <span className='card-label fw-bold font-17 mb-1'>Payment Summary</span>
                </h3>
              </div>
              <div className='card-body pt-0 pb-4'>
                <div className='table-responsive'>
                  <table className='table table-row-bordered align-middle gs-0 gy-2'>
                    <tbody>
                      <tr>
                        <td>Cashy Sales</td>
                        <td className='text-end'>
                          {business && business.cashy_sales
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${numberFormat(business.cashy_sales).displayFormat}`
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Pay Later Today</td>
                        <td className='text-end'>
                          {!business ||
                          isEmpty(business.pay_later_today_sales) ||
                          isEmpty(business.pay_later_today_sales.total)
                            ? '-'
                            : numberFormatWithCode(business.pay_later_today_sales.total)
                                .displayFormat}
                        </td>
                      </tr>
                      <tr>
                        <td className='ps-5'>Received</td>
                        <td className='text-end'>
                          {!business ||
                          isEmpty(business.pay_later_today_sales) ||
                          isEmpty(business.pay_later_today_sales.received)
                            ? '-'
                            : numberFormatWithCode(business.pay_later_today_sales.received)
                                .displayFormat}
                        </td>
                      </tr>
                      <tr>
                        <td className='ps-5'>Due</td>
                        <td className='text-end'>
                          {!business ||
                          isEmpty(business.pay_later_today_sales) ||
                          isEmpty(business.pay_later_today_sales.due)
                            ? '-'
                            : numberFormatWithCode(business.pay_later_today_sales.due)
                                .displayFormat}
                        </td>
                      </tr>
                      <tr>
                        <td>Credit Sales</td>
                        <td className='text-end'>
                          {
                            numberFormatWithCode(
                              isEmpty(business.credit_sales) ? 0 : business.credit_sales
                            ).displayFormat
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Past Credit Payment</td>
                        <td className='text-end'>
                          {business && !isEmpty(business.past_credit_payment)
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${numberFormat(business.past_credit_payment).displayFormat}`
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className='bg-light2'>
                        <td className='fw-500'>Total Business</td>
                        <td className='text-end fw-500'>
                          {business && business.total_business
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${numberFormat(business.total_business).displayFormat}`
                            : '-'}
                        </td>
                      </tr>
                      <tr className='bg-light2'>
                        <td className='fw-500'>Total Collection</td>
                        <td className='text-end fw-500'>
                          {!business || isEmpty(business.total_collection)
                            ? '-'
                            : numberFormatWithCode(business.total_collection).displayFormat}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* Business summery end */}
          {/* collection summery start */}
          <div className='col-sm-6 col-md-6 col-lg col-xl'>
            <div className='card card-xl-stretch mb-5'>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                <h3 className='card-title align-items-start flex-column my-0'>
                  <span className='card-label fw-bold font-17 mb-1'>Collection Summary</span>
                </h3>
              </div>
              <div className='card-body pt-0 pb-4'>
                <div className='table-responsive'>
                  <table className='table table-row-bordered align-middle gs-0 gy-2'>
                    <tbody>
                      <tr>
                        <td>Cash</td>
                        <td className='text-end'>
                          {collection && collection.cash
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${numberFormat(collection.cash).displayFormat}`
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Mobile Wallet</td>
                        <td className='text-end'>
                          {collection && collection.mobile_wallet
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${numberFormat(collection.mobile_wallet).displayFormat}`
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Cheque</td>
                        <td className='text-end'>
                          {collection && collection.cheque
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${numberFormat(collection.cheque).displayFormat}`
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Bank Transfer</td>
                        <td className='text-end'>
                          {collection && collection.bank_transfer
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${numberFormat(collection.bank_transfer).displayFormat}`
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className='bg-light2'>
                        <td className='fw-500'>Total Collection</td>
                        <td className='text-end fw-500'>
                          {collection && collection.total_collection
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${numberFormat(collection.total_collection).displayFormat}`
                            : '-'}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* collection summery end */}
        </>
      )}
    </div>
  )
}

export default Overview
