import React, {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import {apiBaseURL, toAbsoluteUrl} from '../../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'

const Vehicles = () => {
  const warehouseId = localStorage.getItem('wId')

  const [vehicleModals, setVehicleModals] = useState<any>([])
  const [modals, setModals] = useState<any>([])
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [limit, setLimit] = useState<number>(10)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [vehicleModalOffSet, setVehicleModalOffSet] = useState<any>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [searchByName, setSearchByName] = useState<string>('')
  const [timer, setTimer] = useState<any>(null)
  const [filterModal, setFilterModal] = useState('')

  useEffect(() => {
    fetchStockItems().then()
  }, [currentOffset, searchByName, filterModal])

  const fetchStockItems = async () => {
    setTimeout(async () => {
      setDisplayLoader(true)
      const vehicleModalResponse = await getRequest(
        `${apiBaseURL()}vehicle/view/?warehouse_id=${warehouseId}&limit=${limit}&offset=${currentOffset}&model__id=${filterModal}&search=${searchByName}`,
        true
      )
      setVehicleModals(vehicleModalResponse.results)
      setCount(vehicleModalResponse.count)
      setNext(vehicleModalResponse.next)
      setPrev(vehicleModalResponse.previous)
      setDisplayLoader(false)
    }, timer)
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * limit
      setCurrentOffset(newOffset)
    }
  }

  const fetchVehicleModals = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    const vehicleModalResponse = await getRequest(
      `${apiBaseURL()}master/vechile-model/?ordering=vechile_model&limit=${limit}&offset=${vehicleModalOffSet}&search=${searchSelect}`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (vehicleModalResponse.next !== null) {
      const queryParams = new URLSearchParams(vehicleModalResponse.next)
      let newOffset: any = queryParams.get('offset')
      setVehicleModalOffSet(newOffset)
      hasMore = true
    }

    if (modals.length == 0) {
      options.push({
        label: 'All Models',
        value: '',
      })
    }

    if (vehicleModalResponse.results.length > 0) {
      vehicleModalResponse.results.map((option: any) => {
        options.push({
          label: option.vechile_model,
          value: option.id,
        })
      })
    }

    if (modals.length > 0) {
      setModals(modals.concat(options))
    } else {
      setModals(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleFilterChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setTimer(0)

    switch (inputName) {
      case 'modal':
        setFilterModal(inputValue)
        break
      default:
        setTimer(1000)
        setSearchByName(inputValue)
    }
  }

  return (
    <>
      {/* begin::Row */}
      {/*<div className='row mt-3 mt-md-0'>*/}
      {/*    <div className='col-12 mt-md-0'>*/}
      {/*        <div className='card card-xl-stretch mb-5'>*/}
      {/*            /!* begin::Header *!/*/}
      {/*            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>*/}
      {/*                /!* begin::Title *!/*/}
      {/*                <h3 className='card-title align-items-start flex-column my-0'>*/}
      {/*                    <span className='card-label fw-bold font-17 mb-1'>Vehicle Usage Overview</span>*/}
      {/*                </h3>*/}
      {/*                /!* end::Title *!/*/}

      {/*                /!* begin::Toolbar *!/*/}
      {/*                <div className='card-toolbar d-flex g-3 my-0'>*/}
      {/*                    <DateRangePicker*/}
      {/*                        initialSettings={*/}
      {/*                            {*/}
      {/*                                startDate: '01/01/2020',*/}
      {/*                                endDate: '01/12/2020',*/}
      {/*                                alwaysShowCalendars: true,*/}
      {/*                                cancelClass: 'btn-secondary',*/}
      {/*                                drops: "auto",*/}
      {/*                                locale: {*/}
      {/*                                    format: 'DD/MM/YYYY'*/}
      {/*                                },*/}
      {/*                                ranges: {*/}
      {/*                                    'Today': [moment(), moment()],*/}
      {/*                                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],*/}
      {/*                                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],*/}
      {/*                                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],*/}
      {/*                                    'This Month': [moment().startOf('month'), moment().endOf('month')],*/}
      {/*                                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]*/}
      {/*                                },*/}
      {/*                            }}*/}
      {/*                    >*/}
      {/*                        <input type="text" className="form-control calendar" />*/}
      {/*                    </DateRangePicker>*/}
      {/*                </div>*/}
      {/*                /!* end::Toolbar *!/*/}
      {/*            </div>*/}
      {/*            /!* end::Header *!/*/}

      {/*            /!* begin::Body *!/*/}
      {/*            <div className='card-body'>*/}
      {/*                /!* begin::Chart *!/*/}
      {/*                Chart Area*/}
      {/*                /!* end::Chart *!/*/}
      {/*            </div>*/}
      {/*            /!* end::Body *!/*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <AsyncPaginate
                loadOptions={fetchVehicleModals}
                isSearchable
                className='react-select-container me-3 w-150px'
                classNamePrefix='react-select'
                placeholder='All Models'
                name='brand'
                onChange={(e: any) => handleFilterChange(e, 'modal')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {displayLoader && <IsLoader />}
          {/* begin::Table container */}
          <div className='table-responsive'>
            {vehicleModals.length === 0 ? (
              <NoRecords />
            ) : (
              <>
                {/* begin::Table */}
                <table className='table table-row-bordered align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className=' text-muted fw-500'>
                      <th className='min-w-100px w-180px py-2'>Vehicle Number</th>
                      <th className='min-w-150px w-150px py-2'>Vehicle Type</th>
                      <th className='min-w-100px w-150px py-2'>Make/Model/Year</th>
                      <th className='min-w-100px w-150px py-2'>Wards</th>
                      <th className='min-w-100px py-2'>Brands</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {vehicleModals.map((vehicleModal: any) => {
                      return (
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-24px me-2'>
                                <span className='symbol-label'>
                                  {
                                    vehicleModal.vehicle_images.length == 0 ? (
                                      <img
                                        alt=''
                                        className='img-fluid rounded-1'
                                        src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                                      />
                                    ) : (
                                      vehicleModal.vehicle_images.map((item: any, index: any) => {
                                        if (item.is_cover) {
                                          return (
                                            <img
                                              alt=''
                                              className='img-fluid rounded-1'
                                              src={item.vehicle_thumbnail}
                                            />
                                          )
                                        }
                                      })
                                    )
                                    //
                                    // <img src={vehicleModal.vehicle_images[0].vehicle_thumbnail}
                                    //      className='h-10 rounded-1' alt="group image" style={{height: "inherit"}}/>
                                  }
                                </span>
                              </div>
                              <span>{vehicleModal.number}</span>
                            </div>
                          </td>
                          <td>{vehicleModal.type.vechile_type}</td>
                          <td>{`${vehicleModal.make.vechile_make}/${vehicleModal.model.vechile_model}/${vehicleModal.year}`}</td>
                          <td>
                            {vehicleModal.wards[0].name}
                            {vehicleModal.wards.length > 1
                              ? ' & ' + (vehicleModal.wards.length - 1) + ' more'
                              : ''}
                          </td>
                          <td>
                            {vehicleModal && vehicleModal.is_for_horeca
                              ? 'All Brands'
                              : vehicleModal.brand_name.name}
                          </td>
                          {/*<td>{vehicleModal.capacity} kg</td>*/}
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${vehicleModals.length == 0 ? 0 : selected * limit + 1} to ${
              vehicleModals.length == 0
                ? 0
                : vehicleModals.length < 10
                ? count
                : (selected + 1) * 10
            } of ${vehicleModals.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              prev={prevPage}
              next={nextPage}
              count={count}
              selected={selected}
              numberClick={numberClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Vehicles
