import React from 'react'
import {KTSVG, numberFormat, toAbsoluteUrl} from '../../../../../_eaFruitsDms/helpers'
import Tooltip from 'react-bootstrap/Tooltip'
import {OverlayTrigger} from 'react-bootstrap'
import formateMessage from '../../../../../_eaFruitsDms/helpers/components/FormateMessage'

const TableProduct = (props: any) => {
  return (
    <>
      <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
        <thead>
          <tr className=' text-muted fw-500'>
            {/* <th className='w-25px py-2'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                />
                            </div>
                        </th> */}
            <th className='min-w-100px py-2'>Group Name</th>
            <th className='min-w-100px py-2'>Category</th>
            <th className='min-w-100px py-2'>Measurement unit</th>
            <th className='min-w-100px py-2'>VAT</th>
            <th className='min-w-150px py-2'>
              Sales Quantity Control
              <OverlayTrigger
                delay={{hide: 450, show: 300}}
                overlay={(props) => (
                  <Tooltip {...props} className='tooltip-dark'>
                    {formateMessage('SALES_QUANTITY_CONTROL_DESC')}
                  </Tooltip>
                )}
                placement='top'
              >
                <span className='cursor-pointer text-decoration-underline'>
                  <a href='#' className='ms-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='ic text-primary mr-0'
                    />
                  </a>
                </span>
              </OverlayTrigger>
            </th>
            {props.showWrite || props.showDelete ? (
              <th className='min-w-80px action-th py-2'>Actions</th>
            ) : (
              <th className='min-w-80px action-th py-2'></th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.tableList.map((item: any) => (
            <tr key={item.id}>
              {/* <td>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                </div>
                            </td> */}
              <td>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-40px me-2'>
                    <span className='symbol-label'>
                      {item.group_thumbnail == null ? (
                        <img
                          alt='group image'
                          className='h-35px rounded-1'
                          src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                        />
                      ) : (
                        <img
                          src={item.group_thumbnail}
                          className='h-40 rounded-1'
                          alt=''
                          style={{width: '100%', height: '100%'}}
                        />
                      )}
                    </span>
                  </div>
                  <span>{item.name}</span>
                </div>
              </td>
              <td>
                {item.category !== null ? (
                  <>
                    {item.category.parent_category !== null ? (
                      <div>
                        <div className='text-muted font-13 mb-1 font-medium'>
                          {item.category.parent_category.name}
                        </div>
                        {item.category.name}
                      </div>
                    ) : (
                      item.category.name
                    )}
                  </>
                ) : (
                  '-'
                )}
              </td>
              <td>{item.measurement_unit}</td>
              <td>{item.product_vat}</td>
              <td>
                {item.is_max_qty_limit_active ? (
                  <>
                    <div>
                      {item.sales_max_size_limit === 0
                        ? 0
                        : numberFormat(item.sales_max_size_limit).displayFormat}{' '}
                      Grams{' '}
                      {item.sales_max_size_limit > 0 &&
                        `(${
                          item.sales_max_size_limit === 0
                            ? 0
                            : numberFormat(item.sales_max_size_limit / 1000).displayFormat
                        } 
                      Kgs)`}
                    </div>
                  </>
                ) : (
                  <div className='text-muted font-13 mb-1 font-medium'>Disabled</div>
                )}
              </td>
              <td className='text-end'>
                {props.showWrite && (
                  <a
                    onClick={() => props.getPropsEdit(item)}
                    href='#'
                    data-bs-toggle='modal'
                    data-bs-target='#ea_modal_edit_product_group'
                    className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                  </a>
                )}
                {/* <a
                                    onClick={() => props.getPropsDelete(item)}
                                    data-bs-toggle='modal'
                                    data-bs-target='#deleteProductGroup'
                                    href='#' className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                    <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                </a> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default TableProduct
