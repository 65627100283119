import React, {useEffect, useState} from 'react'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {
  checkRoleName,
  fetchAsyncDropdown,
  isEmpty,
  staticSearch,
} from '../../../_eaFruitsDms/helpers'
import {AsyncPaginate} from 'react-select-async-paginate'
import {connect} from 'react-redux'
import {
  setMonthValue,
  setWarehouseValue,
} from '../../../_eaFruitsDms/redux/action/saDashboardFilters/saDashboardFilters'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import {operationsOfficer} from '../../../_eaFruitsDms/utils/roleNames'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'

const SaDashboardFilter = (props: any) => {
  const selectedWarehouse = isEmpty(localStorage.getItem('sa_dashboard_warehouse'))
    ? ''
    : JSON.parse(localStorage.getItem('sa_dashboard_warehouse') || '')

  const selectedMonth =
    !isEmpty(localStorage.getItem('sa_dashboard_month')) &&
    JSON.parse(localStorage.getItem('sa_dashboard_month') || '')

  const [warehouse, setWarehouse] = useState<any>(selectedWarehouse)
  const [month, setMonth] = useState<any>(selectedMonth)
  const [buttonDisable, setButtonDisable] = useState<any>(false)

  const handleFilterChange = (e: any) => {
    setWarehouse(e)
    props.setWarehouseValue(e.value)
    localStorage.setItem('sa_dashboard_warehouse', JSON.stringify(e))

    if (isEmpty(e.value) && (isEmpty(month) || month.value === 'today')) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }

  const fetchMonthFilter = async (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        value: 'today',
        label: 'Today',
      },
      {
        value: 'yesterday',
        label: 'Yesterday',
      },
      {
        value: 'this_week',
        label: 'This Week',
      },
      {
        value: 'last_week',
        label: 'Last Week',
      },
      {
        value: 'this_month',
        label: 'This Month',
      },
      {
        value: 'last_month',
        label: 'Last Month',
      },
      {
        value: 'this_quarter',
        label: 'This Quarter',
      },
      {
        value: 'last_quarter',
        label: 'Last Quarter',
      },
      {
        value: 'this_year',
        label: 'This Year',
      },
      {
        value: 'last_year',
        label: 'Last Year',
      },
    ]
    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const handleChangeReset = () => {
    if (!isEmpty(selectedMonth)) {
      localStorage.removeItem('sa_dashboard_month')
      props.setMonthValue('')
      setMonth('')
    }

    if (!isEmpty(selectedWarehouse)) {
      localStorage.removeItem('sa_dashboard_warehouse')
      props.setWarehouseValue('')
      setWarehouse('')
    }

    setButtonDisable(false)
  }

  useEffect(() => {
    if (selectedMonth && !isEmpty(selectedMonth) && selectedMonth.value !== 'today') {
      //    props.setMonthValue(selectedMonth.value)
      setButtonDisable(true)
    }

    if (selectedWarehouse && !isEmpty(selectedWarehouse) && !isEmpty(selectedWarehouse.value)) {
      //      props.setWarehouseValue(selectedWarehouse.value)
      setButtonDisable(true)
    }
  }, [])

  const [warehouseOffset, setWarehouseOffset] = useState('')
  const [warehouseList, setWarehouseList] = useState([])
  const [search, setWarehouseSearch] = useState('')

  const fetchWarehouse = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffset,
      setWarehouseOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?parent_warehouse__id=${localStorage.getItem(
        'w_id'
      )}&is_parent=True&ordering=name`,
      search,
      setWarehouseSearch,
      false,
      false
    )
  }

  return (
    <div className='d-flex'>
      {!checkRoleName([operationsOfficer]) && (
        <WarehouseFilter
          warehouse={warehouse}
          handleFilterChange={handleFilterChange}
          isDisabled={false}
        />
      )}

      <AsyncPaginate
        loadOptions={fetchMonthFilter}
        isSearchable
        className='react-select-container my-1 me-3 w-180px'
        classNamePrefix='react-select'
        placeholder='Today'
        onChange={(e: any) => {
          setMonth(e)
          props.setMonthValue(e.value)
          localStorage.setItem('sa_dashboard_month', JSON.stringify(e))

          if (e.value === 'today' && (isEmpty(warehouse) || isEmpty(warehouse.value))) {
            setButtonDisable(false)
          } else {
            setButtonDisable(true)
          }
        }}
        value={month}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#0c78a4',
          },
        })}
      />
      <div className='mt-2'>
        <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    warehouseId: state.saDashboard.warehouseId,
    month: state.saDashboard.month,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setWarehouseValue: (value: any) => {
      dispatch(setWarehouseValue(value))
    },
    setMonthValue: (value: any) => {
      dispatch(setMonthValue(value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaDashboardFilter)
