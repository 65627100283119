import {
    RESET_PASSWORD_EAFRUITS_FAIL,
    RESET_PASSWORD_EAFRUITS_IS_LOADING,
    RESET_PASSWORD_EAFRUITS_SUCCESS,
    RESET_PASSWORD_EAFRUITS_UNIQUE_PASSWORD,
} from "../../types/types"

type state = {
    resetResponse?: string,
    isLoading?: boolean | string
    errorMessage?: string | boolean
    uniquePassword: string
}

const initialState: state = {
    resetResponse: '',
    isLoading: false,
    errorMessage: '',
    uniquePassword: ''
}

export const ResetPasswordReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case RESET_PASSWORD_EAFRUITS_IS_LOADING:
            return {
                isLoading: true,
            }
        case RESET_PASSWORD_EAFRUITS_SUCCESS:
            return {
                resetResponse: action.payload,
                isLoading: false,
            }
        case RESET_PASSWORD_EAFRUITS_FAIL:
            return {
                errorMessage: action.payload,
                isLoading: false,
            }
         case RESET_PASSWORD_EAFRUITS_UNIQUE_PASSWORD:
             return {
                 uniquePassword: action.payload,
                 isLoading: false,
             }
                
        default:
            return {
                ...state
            }
    }
}

