import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Select from 'react-select'
import moment from 'moment'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../../TenantVariables'
import {
  apiBaseURL,
  calculateDays,
  dateFormat,
  fetchAsyncDropdown,
  getLoginWarehouse,
  globalDateFormate,
  numberFormatWithCode,
} from '../../../../_eaFruitsDms/helpers'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'

const statusOptions = [
  {value: '', label: 'All Status'},
  {value: 'approved', label: 'Approved'},
  {value: 'rejected', label: 'Rejected'},
]

type Props = {
  className: string
}

const CreditRequestReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Credit Request Report',
  }

  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [creditRequest, setCreditRequest] = useState([])
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [search, setSearch] = useState('')
  const [isListLoading, setIsListLoading] = useState(false)
  const [warehouseValueReport, setWarehouseValueReport] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')

  const currentWarehouseId: any = localStorage.getItem('w_id')
  const debounceSearch = useDebounce(search, 500)

  // fetch chart Warehouse Dropdown
  const fetchWarehouse = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `warehouse/list?parent_warehouse__id=${currentWarehouseId}&ordering=name`,
      search,
      setDropDownSearch,
      'name',
      true,
      'All Warehouses'
    )
  }

  const fetchList = async () => {
    setIsListLoading(true)
    let warehouseFilter = `&warehouse_id=${
      warehouseValueReport !== '' && warehouseValueReport.value !== ''
        ? warehouseValueReport.value
        : ''
    }`
    const param =
      `limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&date_duration_after=${
        apiDate.startDate
      }&date_duration_before=${apiDate.endDate}&status=${status !== '' ? status.value : ''}` +
      warehouseFilter
    const url = apiBaseURL() + 'reports/credit-request?' + param
    const response = await getRequest(url, true)

    if (response) {
      setCreditRequest(response.results)
      setCount(response.count)
    }
    setIsListLoading(false)
  }

  useEffect(() => {
    fetchList()
  }, [debounceSearch, currentOffset, apiDate, warehouseValueReport, status])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
            disabled={isListLoading}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Credit Request Report - ' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Credit Request Report'})}
      </PageTitle>
      <div>
        <div className='card'>
          {isListLoading && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              value={search}
              isDisable={isListLoading}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setApiDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={apiDate}
                  startDate={apiDate.startDate}
                  endDate={apiDate.endDate}
                />
                <AsyncPaginate
                  loadOptions={fetchWarehouse}
                  isSearchable
                  placeholder='All Warehouses'
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  name='select_team'
                  onChange={(e) => {
                    setWarehouseValueReport(e)
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={warehouseValueReport}
                  isDisabled={isListLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <Select
                  options={statusOptions}
                  isSearchable
                  placeholder='All Status'
                  className='react-select-container my-1 me-3 w-110px'
                  classNamePrefix='react-select'
                  onChange={(e) => {
                    setStatus(e)
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={status}
                  isDisabled={isListLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {creditRequest.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-100px py-2'>Order No.</th>
                      <th className='min-w-170px py-2'>Request Date & Time</th>
                      <th className='min-w-150px py-2'>Requested Amount</th>
                      <th className='min-w-150px py-2'>Approved Amount</th>
                      <th className='min-w-170px py-2'>Due Date</th>
                      <th className='min-w-150px py-2'>Customer Name</th>
                      <th className='min-w-150px py-2'>Requested By SR</th>
                      <th className='min-w-150px py-2'>Responded By</th>
                      <th className='min-w-150px py-2'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditRequest.length > 0 &&
                      creditRequest.map((item: any) => {
                        return (
                          <tr>
                            <td>
                              {item.order_no && item.order_no !== '' ? `#${item.order_no}` : '-'}
                            </td>
                            <td>
                              {item.pay_later_today_created_at ? (
                                <>
                                  {dateFormat(item.pay_later_today_created_at).displayDate}
                                  <p className='font-13 text-muted mb-0'>
                                    {dateFormat(item.pay_later_today_created_at, 'LT').displayDate}
                                  </p>
                                </>
                              ) : (
                                <>
                                  {dateFormat(item.created_at).displayDate}
                                  <p className='font-13 text-muted mb-0'>
                                    {dateFormat(item.created_at, 'LT').displayDate}
                                  </p>
                                </>
                              )}
                            </td>
                            <td>
                              {item.requested_amount && item.requested_amount !== ''
                                ? numberFormatWithCode(item.requested_amount).displayFormat
                                : '-'}
                            </td>
                            <td>{numberFormatWithCode(item.amount).displayFormat}</td>
                            <td>
                              {item.due_date && item.due_date !== '' ? (
                                <>
                                  {`${dateFormat(item.due_date).displayDate} (${calculateDays(
                                    item.pay_later_today_created_at
                                      ? dateFormat(item.pay_later_today_created_at).apiDate
                                      : dateFormat(item.created_at).apiDate,
                                    item.due_date
                                  )})`}
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {item.customer_name && item.customer_name !== ''
                                ? item.customer_name
                                : '-'}
                            </td>
                            <td>
                              {item.requested_by_name && item.requested_by_name !== ''
                                ? item.requested_by_name
                                : '-'}
                            </td>
                            <td>
                              {item.action_by_name && item.action_by_name !== ''
                                ? `${item.action_by_name}  (${item.action_by_type})`
                                : '-'}
                            </td>
                            <td>
                              {item.status && item.status !== '' ? (
                                <>
                                  {item.pay_later_today_created_at ? (
                                    <span className='badge badge-light-success text-dark'>
                                      Auto Approved
                                    </span>
                                  ) : (
                                    <>
                                      {item.status === 'approved' && (
                                        <span className='badge badge-light-success text-dark'>
                                          Approved
                                        </span>
                                      )}
                                      {item.status === 'rejected' && (
                                        <span className='badge badge-light-danger text-dark'>
                                          Rejected
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <CustomPaginate
          data={creditRequest}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={isListLoading}
          saveFilterName={'feedbackReport'}
          filterStates={''}
        />
      </div>
    </>
  )
}

export default CreditRequestReport
