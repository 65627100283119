import React from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import { useIntl } from "react-intl"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const Attendance: React.FC = () => {
    const intl = useIntl()
    const teamHeaderTitle = {
        title: "Team members",
        subTitle: "Connor Lawson"
    }
    return (
        <>
            <PageTitle breadcrumbs={[teamHeaderTitle]} >{intl.formatMessage({ id: 'Connor Lawson' })}</PageTitle>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className="ms-md-auto">
                        <div className='card-toolbar my-0'>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        singleDatePicker: true,
                                        drops: "auto",
                                        showDropdowns: true,
                                        cancelClass: 'btn-secondary',
                                    }
                                }
                            >
                                <input
                                    type="text"
                                    className="form-control calendar"
                                    placeholder="Select"
                                />
                            </DateRangePicker>
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-150px'>Date</th>
                                    <th className='min-w-140px'>Clock-In</th>
                                    <th className='min-w-120px'>Clock-Out</th>

                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>
                                    <td>22 May 2022</td>
                                    <td>01:41AM</td>
                                    <td>01:55PM</td>

                                </tr>
                                <tr className='table-red'>
                                    <td>15 Nov 2022</td>
                                    <td>12:38AM</td>
                                    <td>06:41PM</td>
                                </tr>
                                <tr>
                                    <td>22 May 2022</td>
                                    <td>01:41AM</td>
                                    <td>01:55PM</td>

                                </tr>
                                <tr>
                                    <td>15 Nov 2022</td>
                                    <td>12:38AM</td>
                                    <td>06:41PM</td>
                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Attendance
