import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {dateFormat, GLOBAL_DATE_FORMAT} from '../CommonHelpers'

interface Props {
  onCallback: any
  startDate: any
  singleDatePicker: boolean
  displayLoader: boolean
  ranges?: boolean
  showDropdowns?: boolean
  endDate?: any
  value?: any
  maxDate?: any
  minDate?: any
  parentEl?: string
  drops?: string
}

export const CustomDatePickerComponent = (props: Props) => {
  const {
    onCallback,
    startDate,
    endDate,
    value,
    parentEl,
    drops,
    maxDate,
    minDate,
    singleDatePicker,
    showDropdowns = false,
    displayLoader,
    ranges,
  } = props

  //   value state
  //   const [filteredDate, setFilteredDate] = useState<any>({
  //     startDate: dateFormat(moment()).apiDate,
  //     endDate: dateFormat(moment()).apiDate,
  //   })

  //     example
  //     <div className='me-3 w-250px'>
  //         <CustomDatePickerComponent
  //           onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
  //             setFilteredDate({
  //               startDate: callBakeDate.startDate,
  //               endDate: callBakeDate.endDate,
  //             })
  //           }}
  //           value={filteredDate}
  //           startDate={filteredDate.startDate}
  //           endDate={filteredDate.endDate}
  //           displayLoader={displayLoader}
  //           drops='down'
  //           singleDatePicker={false}
  //           ranges
  //         />
  //     </div>

  return (
    <>
      <DateRangePicker
        onCallback={(start, end, label) => {
          const callBakeDate = {
            startDate: dateFormat(start._d).apiDate,
            endDate: dateFormat(end._d).apiDate,
          }
          const callBakeDateDisplay = {
            startDate: dateFormat(start._d).displayDate,
            endDate: dateFormat(end._d).displayDate,
          }
          onCallback(callBakeDate, callBakeDateDisplay)
        }}
        initialSettings={{
          startDate: moment(startDate).format(GLOBAL_DATE_FORMAT),
          ...(endDate && {endDate: moment(endDate).format(GLOBAL_DATE_FORMAT)}),
          autoUpdateInput: true,
          alwaysShowCalendars: true,
          cancelClass: 'btn-secondary',
          ...(parentEl && {parentEl: `.${parentEl}`}),
          drops: drops || 'up',
          ...(maxDate && {maxDate: maxDate}),
          ...(minDate && {minDate: minDate}),
          singleDatePicker: singleDatePicker,
          showDropdowns: showDropdowns,
          locale: {
            format: GLOBAL_DATE_FORMAT,
          },
          ...(ranges &&
            !singleDatePicker && {
              ranges: {
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [
                  moment().subtract(1, 'month').startOf('month'),
                  moment().subtract(1, 'month').endOf('month'),
                ],
              },
            }),
        }}
      >
        <input
          type='text'
          className='form-control calendar'
          defaultValue={value}
          disabled={displayLoader}
          readOnly
        />
      </DateRangePicker>
    </>
  )
}
