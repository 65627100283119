import React from "react";
import { calculateDays, dateFormat } from "../../../../_eaFruitsDms/helpers";
import moment from "moment/moment";

const IssueStatus = ({status, dueDate}: any) => {
  return <>
    {
      status === 'open' ?
        <span className="badge badge-light-warning text-dark">Open</span>
        : status === 'overdue' ?
          <span className="badge badge-light-danger text-dark text-capitalize">Overdue ({calculateDays(dueDate, dateFormat(moment()).apiDate)})</span>
        :
          <span className="badge badge-light-success text-dark">Closed</span>
    }
  </>
}

export default IssueStatus
