import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  addVehicleBreakdown,
  vehicleBreakdownList,
  vehicleBreakdownUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import { checkPermissions, defaultPermission, KTSVG } from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"

type Props = {
  className: string
}
const VehicleBreakdown: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Masters',
    subTitle: 'Vehicle Breakdown Options',
  }

  const [getList, setGetList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [vehicleBreakDown, setVehicleBreakDown] = useState<any>('')
  const [vehicleBreakDownEdit, setVehicleBreakDownEdit] = useState<any>('')
  const [validationEdit, setValidationEdit] = useState<any>('')
  const [validation, setValidation] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [EditId, setEditId] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    VehicalList()
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_vehicle_breakdown_options")
    setPermission(res)
   }

  const VehicalList = () => {
      setListLoader(true)
    vehicleBreakdownList('10', '0', '', 'VehicleBreakdown', '').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      vehicleBreakdownList('10', newOffset, search, 'VehicleBreakdown').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      vehicleBreakdownList('10', newOffset, search, 'VehicleBreakdown').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      vehicleBreakdownList('10', newOffset, search, 'VehicleBreakdown').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)
      vehicleBreakdownList('10', '0', e.target.value, 'VehicleBreakdown').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
        setListLoader(false)
      })
    }, 500)

    setTimer(newTimer)
  }

  // AddFunction
  const clearFunction = () => {
    setVehicleBreakDown('')
    setValidation('')
    setErrorMessage('')
  }

  // Develop BY: Kriti
  // Description: Add Vehicle Break-Down function which is called on Eamodel's submit event

  const AddFunction = () => {
    if (vehicleBreakDown === '') {
      setValidation('is-invalid')
    } else {
      setValidation('')
      AddFunctionApi()
    }
  }

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    addVehicleBreakdown(vehicleBreakDown, 'VehicleBreakdown').then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunction()
      setListLoader(true)
        vehicleBreakdownList('10', '0', '', 'VehicleBreakdown').then((res) => {
          setGetList(res.data)

          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false) 
        })
        $('#closeAddEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'non_field_errors') {
        setErrorMessage(
          'Your added Vehicle Breakdown Options already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  // edit function

  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setVehicleBreakDownEdit(item.option)
    setEditId(item.id)
    setModalLoader(false)
  }

  // Develop BY: Kriti
  // Description: Edit Vehicle Break-Down function which is called on Eamodel's submit event

  const EditFunction = () => {
    if (vehicleBreakDownEdit === '') {
      setValidationEdit('is-invalid')
    } else {
      setValidationEdit('')
      EditFunctionApi()
    }
  }

  const clearFunctionEdit = () => {
    setValidationEdit('')
    setVehicleBreakDownEdit('')
    setErrorMessage('')
  }

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    vehicleBreakdownUpdate(vehicleBreakDownEdit, 'VehicleBreakdown', EditId).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
      setListLoader(true)
        vehicleBreakdownList('10', '0', search, 'VehicleBreakdown').then((res) => {
          setGetList(res.data)

          setCount(res.count)
          setNext(res.next)
          setListLoader(false)  
          setPrev(res.prev)
          setSelected(0)
        })
        $('#closeEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'non_field_errors') {
        setErrorMessage(
          'Your added  Vehicle Breakdown Options already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <EATitle title='Vehicle Breakdown Options' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({ id: 'Vehicle Breakdown Options' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? 'Your Vehicle Breakdown has been added successfully.'
            : 'Your Vehicle Breakdown has been edited  successfully.'
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />
      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            {/* <CustomSearchBar /> */}
            <div className='col-12 col-md-auto mt-0'>
              <input
                type='text'
                placeholder='Search'
                className='form-control form-search'
                onChange={searchList}
              />
            </div>
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
              {permission.write && (
                <EaButton
                  isModal={true}
                  btnName='Add Vehicle Breakdown Options'
                  dataTarget='#ea_modal_create_vehiclebreakdown'
                  onClick={() => {
                    setMessageHandler('successAdd')
                  }}
                />
              )}
            </div>
            {/* <AddVehicleBreakdown /> */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-4'>
          <div className='table-responsive'>
            {/* <TableVehicleBreakdown /> */}
            <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className=' text-muted fw-500'>
                      <th className='min-w-100px py-2'>Vehicle Breakdown Options</th>
                      <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}

                  <tbody>
                    {getList.map((item: any) => {
                      return (
                        <tr>
                          <td>{item.option}</td>

                          <td className='text-end'>
                            {permission.write && (
                              <a
                                href='javascript:void(0)'
                                data-bs-toggle='modal'
                                data-bs-target='#ea_modal_edit_vehiclebreakdown'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                onClick={(event) => {
                                  onChangeAdd(item)
                                  setMessageHandler('successEdit')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='ic mr-0'
                                />
                              </a>
                            )}
                            {/* <a
                          href='javascript:void(0)'
                          className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr011.svg'
                            className='ic mr-0'
                          />
                        </a> */}
                          </td>
                        </tr>
                      )
                    })}
                    <tr>
                      <td>Other</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
              } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>
      <EaModal
        id='ea_modal_create_vehiclebreakdown'
        modalTitle='Add Vehicle Breakdown Options'
        onSubmit={AddFunction}
        closeAuto='closeAddEaModalBrand'
        cancel={clearFunction}
        actionBtnName={isLoadingAdd}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Vehicle Breakdown Options<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validation)}
            onChange={(e) => setVehicleBreakDown(capitalizeFirstLetter(e.target.value))}
            value={vehicleBreakDown}
            placeholder='Type here...'
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
      <EaModal
        id='ea_modal_edit_vehiclebreakdown'
        modalTitle='Edit Vehicle Breakdown Options'
        onSubmit={EditFunction}
        closeAuto='closeEditEaModalBrand'
        cancel={clearFunctionEdit}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Vehicle Breakdown Options<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validationEdit)}
            placeholder='Type here...'
            value={vehicleBreakDownEdit}
            onChange={(e) => setVehicleBreakDownEdit(capitalizeFirstLetter(e.target.value))}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}

export default VehicleBreakdown
