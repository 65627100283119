import React from 'react'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../../_eaFruitsDms/helpers'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import { EaStatisticsWidget } from '../../../../_eaFruitsDms/partials/widgets'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import Select from 'react-select'
import { Helmet } from 'react-helmet'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useNavigate } from 'react-router-dom'


const fetchSR = async (search: any) => {
    let options: any = []
    options.push(
        { label: "All Sales Reps", value: "allsalesresp" },
        { label: "sr1", value: "Dusya Sigachyova" },
        { label: "sr2", value: "Thomas Kukabango" },)
    return {
        options: options,
        hasMore: false,
    }
}


type Props = {
    className: string
}
const ReconciliationReportAM: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Reconciliation Report"
    }
    return (
        <>
            <Helmet title='Reconciliation Report' />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Reconciliation Report' })}</PageTitle>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: '01/01/2020',
                                        endDate: '01/12/2022',
                                        alwaysShowCalendars: true,
                                        cancelClass: 'btn-secondary',
                                        singleDatePicker: true,
                                        drops: "auto",
                                        locale: {
                                            format: 'DD/MM/YYYY'
                                        },
                                    }}
                            >
                                <input type="text" className="form-control calendar my-1 me-3 w-140px" />
                            </DateRangePicker>
                            <AsyncPaginate
                                loadOptions={fetchSR}
                                isSearchable
                                placeholder="All Sales Reps"
                                className='react-select-container my-1 w-140px me-3'
                                classNamePrefix='react-select'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <button className='btn btn-primary'>
                                Export
                            </button>
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <table className='table table-row-bordered align-middle gx-0 gy-3 mb-0'>
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='' style={{ width: '30%' }}>Description</th>
                                    <th className='text-end' style={{ width: '20%' }}>Receivable</th>
                                    <th className='min-w-130px text-end' style={{ width: '25%' }}>Received</th>
                                    <th className='min-w-100px text-end' style={{ width: '25%' }}>Over/Short</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* cash row start */}
                                <tr className='fw-500 bg-light2'>
                                    <td colSpan={4} className='py-0'>
                                        <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                                            <th className='min-w-110px' style={{ width: '30%' }}>Cash</th>
                                            <th className='min-w-100px text-end cursor-pointer text-decoration-underline text-hover-primary'
                                                style={{ width: '20%' }}
                                                onClick={() => navigate('reports-bifurcation')}
                                            >
                                                TZS 2000.00
                                            </th>
                                            <th className='min-w-130px text-end' style={{ width: '25%' }}>TZS 1500.00</th>
                                            <th className='min-w-100px text-end text-danger' style={{ width: '25%' }}>- TZS 500.00</th>
                                        </table>
                                    </td>
                                </tr>
                                {/* cash row end */}
                                {/* mobile wallet row start */}
                                <tr>
                                    <td colSpan={4} className='py-0'>
                                        <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                                            <tr className='fw-500 bg-light2'>
                                                <th className='min-w-110px' style={{ width: '30%' }}>Mobile Wallet</th>
                                                <th className='min-w-100px text-end cursor-pointer text-decoration-underline text-hover-primary' style={{ width: '20%' }}>
                                                    TZS 10000.00
                                                </th>
                                                <th className='min-w-130px text-end' style={{ width: '25%' }}>TZS 10000.00</th>
                                                <th className='min-w-100px text-end' style={{ width: '25%' }}>TZS 0.00</th>
                                            </tr>
                                            <tr>
                                                <td className='min-w-110px'>Mobile Wallet Name</td>
                                                <td className='text-end cursor-pointer text-decoration-underline text-hover-primary'>
                                                    TZS 4000.00
                                                </td>
                                                <td className='text-end'>TZS 4000.00</td>
                                                <td className='text-end'>TZS 0.00</td>
                                            </tr>
                                            <tr>
                                                <td className='min-w-110px'>Mobile Wallet Name</td>
                                                <td className='text-end cursor-pointer text-decoration-underline text-hover-primary'>
                                                    TZS 4000.00
                                                </td>
                                                <td className='text-end'>TZS 4000.00</td>
                                                <td className='text-end'>TZS 0.00</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                {/* mobile wallet row end */}
                                {/* Cheque row start */}
                                <tr className='fw-500 bg-light2'>
                                    <td colSpan={4} className='py-0'>
                                        <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                                            <th className='min-w-110px' style={{ width: '30%' }}>Cheque</th>
                                            <th className='min-w-100px text-end cursor-pointer text-decoration-underline text-hover-primary'
                                                style={{ width: '20%' }}
                                                onClick={() => navigate('reports-bifurcation')}
                                            >
                                                TZS 2000.00
                                            </th>
                                            <th className='min-w-130px text-end' style={{ width: '25%' }}>TZS 2000.00</th>
                                            <th className='min-w-100px text-end' style={{ width: '25%' }}>TZS 0.00</th>
                                        </table>
                                    </td>
                                </tr>
                                {/* Cheque row end */}
                                {/* Bank Transfer row start */}
                                <tr>
                                    <td colSpan={4} className='py-0'>
                                        <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                                            <tr className='fw-500 bg-light2'>
                                                <th className='min-w-110px' style={{ width: '30%' }}>Bank Transfer</th>
                                                <th className='min-w-100px text-end cursor-pointer text-decoration-underline text-hover-primary' style={{ width: '20%' }}>
                                                    TZS 10000.00
                                                </th>
                                                <th className='min-w-130px text-end' style={{ width: '25%' }}>TZS 10000.00</th>
                                                <th className='min-w-100px text-end' style={{ width: '25%' }}>TZS 0.00</th>
                                            </tr>
                                            <tr>
                                                <td className='min-w-110px'>Bank Name</td>
                                                <td className='text-end cursor-pointer text-decoration-underline text-hover-primary'>
                                                    TZS 4000.00
                                                </td>
                                                <td className='text-end'>TZS 4000.00</td>
                                                <td className='text-end'>TZS 0.00</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                {/* Bank Transfer row end */}
                                {/* Auto Credit Note row start */}
                                <tr>
                                    <td colSpan={4} className='py-0'>
                                        <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                                            <tr className='fw-500 bg-light2'>
                                                <th className='min-w-110px' style={{ width: '30%' }}>Auto Credit Note</th>
                                                <th className='min-w-100px text-end cursor-pointer text-decoration-underline text-hover-primary' style={{ width: '20%' }}>
                                                    TZS 10000.00
                                                </th>
                                                <th className='min-w-130px text-end' style={{ width: '25%' }}>TZS 10000.00</th>
                                                <th className='min-w-100px text-end' style={{ width: '25%' }}>TZS 0.00</th>
                                            </tr>
                                            <tr>
                                                <td className='min-w-110px'>Expired SKUs</td>
                                                <td className='text-end cursor-pointer text-decoration-underline text-hover-primary'>
                                                    TZS 4000.00
                                                </td>
                                                <td className='text-end'>TZS 4000.00</td>
                                                <td className='text-end'>TZS 0.00</td>
                                            </tr>
                                            <tr>
                                                <td className='min-w-110px'>Damage SKUs</td>
                                                <td className='text-end cursor-pointer text-decoration-underline text-hover-primary'>
                                                    TZS 4000.00
                                                </td>
                                                <td className='text-end'>TZS 4000.00</td>
                                                <td className='text-end'>TZS 0.00</td>
                                            </tr>
                                            <tr>
                                                <td className='min-w-110px'>Missing SKUs</td>
                                                <td className='text-end cursor-pointer text-decoration-underline text-hover-primary'>
                                                    TZS 4000.00
                                                </td>
                                                <td className='text-end'>TZS 4000.00</td>
                                                <td className='text-end'>TZS 0.00</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                {/* Auto Credit Note row end */}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={4} className='py-0'>
                                        <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                                            <tr className='fw-500 bg-light2'>
                                                <th className='min-w-110px' style={{ width: '30%' }}>Total Collection</th>
                                                <th className='min-w-100px text-end cursor-pointer text-decoration-underline text-hover-primary' style={{ width: '20%' }}>
                                                    TZS 14000.00
                                                </th>
                                                <th className='min-w-130px text-end' style={{ width: '25%' }}>TZS 13500.00</th>
                                                <th className='min-w-100px text-end text-danger' style={{ width: '25%' }}>- TZS 500.00</th>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReconciliationReportAM