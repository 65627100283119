import "lightgallery/scss/lg-zoom.scss"
import "lightgallery/scss/lightgallery.scss"
import React from "react"
import AddressSection from "../../../components/AddressSection"

const BusinessAddress = ({customer}: any) => {
    return (
        <>
            <div className='card mb-5 mb-xl-10' >
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    {/* begin::Title */}
                    <h3 className='card-title align-items-start flex-column my-0'>
                        <span className='card-label fw-bold font-17'>Business Address Verification</span>
                    </h3>
                    {/* end::Title */}
                </div>
                <div className="card-body border-top pt-4 pt-md-5">
                    <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'> Verification Documents</h3>
                    <AddressSection
                        addressType="store_front_photos"
                        addressTitle="Store Front Photo"
                        statusKey="store_front_verification_status"
                        type="store_front"
                        customer={customer}
                    />

                    <AddressSection
                        addressType="in_store_photos"
                        addressTitle="In Store Photo"
                        statusKey="in_store_verification_status"
                        type="in_store"
                        customer={customer}
                    />

                    <AddressSection
                        addressType="other_store_photos"
                        addressTitle="Other Photo"
                        statusKey="other_verification_status"
                        type="other"
                        customer={customer}
                    />
                </div>
            </div>
        </>
    )
}

export default BusinessAddress
