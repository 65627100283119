import React from "react"
import { useNavigate } from "react-router-dom"
import { dateFormat, KTSVG } from "../../../../../_eaFruitsDms/helpers"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"

const TsWarehouseTable = (props: any) => {
    const navigate = useNavigate()

    const { list } = props;

    return (
        <>
            {list.length === 0 ? <NoRecords /> :
                <ul className="list-unstyled ps-0 wh-list">
                    {
                        list.map((item: any) => {
                            const { name, } = item

                            let fullName = "—"
                            if (item.warehouse_manager.length !== 0) {
                                fullName = item.warehouse_manager[0].first_name + " " + item.warehouse_manager[0].last_name
                            }
                            let newDate = dateFormat(item.start_date)
                            return (
                                <li key={item.id} className='boxrow border-bottom mb-4 mb-md-5 pb-1 text-hover-bg-light cursor-pointer'
                                    onClick={() => {
                                        localStorage.setItem("wId", item.id)
                                        navigate('/warehouses/overview')
                                    }}
                                >
                                    <div className="row mb-3">
                                        <div className="mb-2 mb-md-0 col-12 col-md-auto pe-md-5">
                                            <h3 className='font-20 font-bold mb-0'>{item.name}</h3>
                                        </div>
                                        {item.warehouse_type !== "DistributionCenter" ?
                                            <div className="col-auto align-self-center ps-md-0 pe-md-5">
                                                <div className="d-flex align-items-center">
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen018.svg'
                                                        className='me-1'
                                                    />
                                                    {item.region.name}
                                                </div>
                                            </div> : null}
                                        <div className="col-auto align-self-center ps-md-0 pe-md-5">
                                            <div className="d-flex align-items-center">
                                                <KTSVG path="/media/icons/duotune/ecommerce/ecm009.svg" className='me-1' />
                                                {item.warehouse_type === "DistributionCenter" ? "Distribution Center" : item.warehouse_type}
                                            </div>
                                        </div>
                                        {/* <div className="mt-3 mt-sm-0 col-12 col-sm-auto align-self-center ps-md-0 ms-sm-auto">
                                        <span>
                                            <KTSVG path="/media/icons/duotune/general/gen052.svg" className='' />
                                        </span>
                                    </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-4 mb-4">
                                            <div className='font-13 font-semibold text-muted mb-1'>Parent Warehouse</div>
                                            <div className='font-13 text-dark'> {item.parent_warehouse === null ? "—" : item.parent_warehouse_name} </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-4 mb-4">
                                            <div className='font-13 font-semibold text-muted mb-1'>Active Since</div>
                                            <div className='font-13 text-dark'>{newDate.displayDate}</div>
                                        </div>
                                        {item.warehouse_type !== "DistributionCenter" &&
                                            <div className="col-xl-2 col-lg-3 col-md-3 col-4 mb-4">
                                                <div className='font-13 font-semibold text-muted mb-1'>Branch Manager</div>
                                                <div className='font-13 text-dark'>{fullName}</div>
                                            </div>}
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-4 mb-4">
                                            <div className='font-13 font-semibold text-muted mb-1'>Team Size</div>
                                            <div className='font-13 text-dark'>
                                                {item.team_size} Members
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-4 mb-4">
                                            <div className='font-13 font-semibold text-muted mb-1'>Customers</div>
                                            <div className='font-13 text-dark'>
                                                {item.customer_size}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-4 mb-4">
                                            <div className='font-13 font-semibold text-muted mb-1'>Vehicles</div>
                                            <div className='font-13 text-dark'>
                                                {item.vehicle_size} Vehicles
                                                {/* — */}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>}
        </>
    )
}

export default TsWarehouseTable
