import { ADD_USER } from "../../types/types"

const initialState: any = null


export const TeleSalesUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_USER:
            return {
                user: action.payload,
            }
        default:
            return {
                ...state
            }
    }
}
