import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  isEmpty,
  numberFormatWithCode,
} from '../../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../../TenantVariables'
import useDebounce from '../../../../../_eaFruitsDms/helpers/components/useDebounce'
import {WarehouseFilter} from '../../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {CustomDatePicker} from '../../../../../_eaFruitsDms/helpers/components/CustomDatePicker'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'

type Props = {
  className: string
}
const CreditRequest: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Credit Request"
    }

    let todayDate = dateFormat(moment());

    const [fetchingData, setFetchingData] = useState<boolean>(false);
    const [creditRequestReport, setCreditRequestReport] = useState([]);
    const [count, setCount] = useState(0);
    const [selected, setSelected] = useState(0);
    const [currentOffSet, setCurrentOffSet] = useState(0);
    const [search, setSearch] = useState("");
    const [warehouse, setWarehouse] = useState({label: 'All Warehouses', value: ''});
    const [limit, setLimit] = useState(tenantConfiguration.limit);
    const [date, setDate] = useState<any>({
        startDate: todayDate,
        endDate: todayDate
    });

    const debounceSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchReportData().then()
    }, [debounceSearch, currentOffSet, warehouse, date]);


    const fetchReportData = async () => {
        setFetchingData(true)
        const response = await getRequest(
          `${apiBaseURL()}reports/rejection/credit-request?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&warehouse_id=${checkEmpty(warehouse.value, "")}&rejection_date_duration_after=${date.startDate.apiDate}&rejection_date_duration_before=${date.endDate.apiDate}`,
          true
        )

        if (response.results) {
            const reportData: any = []
            response.results.map((report: any) => {
                return reportData.push({
                    id: report.id,
                    order_no: isEmpty(report.order_no) ? "-" : `#${report.order_no}`,
                    created_date: isEmpty(report.created_at) ? "-" : dateFormat(report.created_at).displayDate,
                    created_time: isEmpty(report.created_at) ? "-" : dateFormat(report.created_at, "LT").displayDate,
                    request_by: isEmpty(report.requested_by_name) ? "-" : `${report.requested_by_name} (${isEmpty(report.requested_by_type) ? "-" : report.requested_by_type.toUpperCase()})`,
                    response_by: isEmpty(report.action_by_name) ? "-" : `${report.action_by_name} (${isEmpty(report.action_by_type) ? "-" : report.action_by_type.toUpperCase()})`,
                    action_date: isEmpty(report.action_at) ? "-" : dateFormat(report.action_at).displayDate,
                    action_time: isEmpty(report.action_at) ? "-" : dateFormat(report.action_at, "LT").displayDate,
                    customer_name: isEmpty(report.customer_name) ? "-" : report.customer_name,
                    customer_street: isEmpty(report.customer_street) ? "-" : report.customer_street,
                    credit_requested: isEmpty(report.requested_amount)? "-" : numberFormatWithCode(report.requested_amount).displayFormat,
                    reason: isEmpty(report.rejection_reason_option) ? isEmpty(report.rejection_note) ? "-" : report.rejection_note : report.rejection_reason_option
                })
            })
            setCount(response.count)
            setCreditRequestReport(reportData)
        }
        setFetchingData(false)
    };

    const handleFilterChange = (e: any, name?: string) => {
        const inputName:string = e.target ? e.target.name : name

        switch (inputName) {
            case 'warehouse':
                setWarehouse(e)
                break
            case 'search':
                setSearch(e.target.value)
                break
        }
    }

    return <>
        <EATitle title='Credit Request' />
        <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Credit Request' })}</PageTitle>
        <div>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar onChange={handleFilterChange}/>
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <WarehouseFilter
                              warehouse={warehouse}
                              handleFilterChange={handleFilterChange}
                              isDisabled={fetchingData}
                            />

                            <CustomDatePicker
                              date={date}
                              setDate={setDate}
                              displayLoader={fetchingData}
                            />

                            {/*<label className="form-label mb-0"><button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 fw-600 d-flex align-items-center">*/}
                            {/*    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />*/}
                            {/*    Export</button>*/}
                            {/*</label>*/}
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {
                            fetchingData ? <FetchingRecords /> :
                              creditRequestReport.length === 0 ? <NoRecords />
                                :
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='w-100px py-2'>Date & Time</th>
                                        <th className='min-w-110px py-2'>Order No.</th>
                                        <th className='w-150px py-2'>Request by</th>
                                        <th className='w-150px py-2'>Response by</th>
                                        <th className='w-150px py-2'>Date & Time</th>
                                        <th className='w-150px py-2'>Request for</th>
                                        <th className='w-150px py-2'>Credit Requested</th>
                                        <th className='w-250px py-2'>Rejection Comments</th>
                                    </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                    {
                                        creditRequestReport.map((requestReport: any) => {
                                            return <tr>
                                                <td>
                                                    {requestReport.created_date}
                                                    <p className='font-13 text-muted mb-0'>{requestReport.created_time}</p>
                                                </td>
                                                <td>
                                                    {requestReport.order_no}
                                                </td>
                                                <td>
                                                    {requestReport.request_by}
                                                </td>
                                                <td>
                                                    {requestReport.response_by}
                                                </td>
                                                <td>
                                                    {requestReport.action_date}
                                                    <p className='font-13 text-muted mb-0'>{requestReport.action_time}</p>
                                                </td>
                                                <td>
                                                    {requestReport.customer_name}{isEmpty(requestReport.customer_street) ? "" : `, ${requestReport.customer_street.toUpperCase()}`}
                                                </td>
                                                <td>
                                                    {requestReport.credit_requested}
                                                </td>
                                                <td>
                                                    {requestReport.reason}
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                        }
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <CustomPaginate
              data={creditRequestReport}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffSet}
              totalRecords={count}
              limit={limit}
              isDisabled={fetchingData}
            />
        </div>
    </>
}

export default CreditRequest
