import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {
  apiBaseURL,
  checkEmpty,
  focusVatidateField,
  getFirstWarehouse,
  goToTop,
  isEmpty,
  KTSVG,
} from '../../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {getRequest, patchRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'

const SokoPoints = () => {
  const [type, setType] = useState('retailer')
  const [loader, setLoader] = useState(true)
  const [sokoPoints, setSokoPoints] = useState([])
  const [warehouse, setWarehouse] = useState({value: '', label: ''})
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isSubmit, setIsSubmit] = useState('Submit')
  const [isSet, setIsSet] = useState(false)
  const [show, setShow] = useState(false)
  const [submit, setSubmit] = useState('Submit')
  const [message, setMessage] = useState('')

  const getFirstWh = async () => {
    const url = `${apiBaseURL()}warehouse/list?ordering=name`
    const response: any = await getFirstWarehouse(url)

    if (response && response.value) {
      setWarehouse(response)
    }
  }

  const fetchEarningSpendingValues = async () => {
    setLoader(true)

    const url = `${apiBaseURL()}master/sokopoint-values/list?warehouse_id=${
      warehouse?.value ?? ''
    }&customer_type=${type}&ordering=name`
    const response = await getRequest(url, true)

    if (response?.result) {
      let sokoPoints: any = []

      response?.result?.map((i: any, index: number) => {
        sokoPoints.push({
          id: i.id,
          brand_name: isEmpty(i?.brand_name) ? '-' : i.brand_name,
          warehouse_id: i?.warehouse_id ?? '',
          warehouse_name: i?.warehouse_name ?? '',
          purchase_amount:
            isEmpty(i?.purchase_amount) || i?.purchase_amount == 0 ? null : i?.purchase_amount,
          sokopoint_earning:
            isEmpty(i?.sokopoint_earning) || i?.sokopoint_earning == 0 ? 1 : i?.sokopoint_earning,
          sokopoint_spending:
            isEmpty(i?.sokopoint_spending) || i?.sokopoint_spending == 0
              ? 1
              : i?.sokopoint_spending,
          point_validity:
            isEmpty(i?.point_validity) || i?.point_validity == 0 ? null : i?.point_validity,
          discount_amount:
            isEmpty(i?.discount_amount) || i?.discount_amount == 0 ? null : i?.discount_amount,
          customer_type: i?.customer_type ?? '',
        })
      })

      setSokoPoints(sokoPoints)
      setIsSet(response?.meta.is_set)
    }

    setLoader(false)
  }

  const onInputChange = (e: any, index: any) => {
    let updatedSokoPoints: any = [...sokoPoints]
    let value = e.target.value
    let name = e.target.name

    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      updatedSokoPoints[index][name] = value

      updatedSokoPoints[index][`purchase_amount_error`] = ''
      updatedSokoPoints[index][`discount_amount_error`] = ''
      updatedSokoPoints[index][`point_validity_error`] = ''
    }

    setSokoPoints(updatedSokoPoints)
  }

  const checkValidation = () => {
    let valid = true

    let updatedSokoPoints: any = [...sokoPoints]

    updatedSokoPoints?.map((i: any, index: number) => {
      if (
        !isEmpty(i?.purchase_amount) ||
        !isEmpty(i?.discount_amount) ||
        !isEmpty(i?.point_validity)
      ) {
        if (isEmpty(i?.purchase_amount)) {
          updatedSokoPoints[index]['purchase_amount_error'] = 'is-invalid-grp'
          valid = false
          focusVatidateField(i?.id)
        }

        if (isEmpty(i?.discount_amount)) {
          updatedSokoPoints[index]['discount_amount_error'] = 'is-invalid-grp'
          valid = false
          focusVatidateField(i?.id)
        }

        if (isEmpty(i?.point_validity)) {
          updatedSokoPoints[index]['point_validity_error'] = 'is-invalid-grp'
          valid = false
          focusVatidateField(i?.id)
        }
      }

      setSokoPoints(updatedSokoPoints)
    })

    return valid
  }

  const onSubmit = async () => {
    const valid = checkValidation()

    if (valid) {
      setIsSubmit('Please Wait')

      const url = `${apiBaseURL()}master/sokopoint-values/update`
      const body: any = []

      sokoPoints.map((item: any) => {
        if (
          !isEmpty(item.purchase_amount) &&
          !isEmpty(item.point_validity) &&
          !isEmpty(item.discount_amount)
        ) {
          body.push({
            warehouse_id: warehouse?.value ?? '',
            brand_id: item.id,
            customer_type: type,
            purchase_amount: Number(item?.purchase_amount),
            sokopoint_earning:
              isEmpty(item.sokopoint_earning) || item?.sokopoint_earning == 0
                ? 1
                : Number(item?.sokopoint_earning),

            point_validity: Number(item.point_validity),
            sokopoint_spending:
              isEmpty(item.sokopoint_spending) || item?.sokopoint_spending == 0
                ? 1
                : Number(item?.sokopoint_spending),
            discount_amount: Number(item.discount_amount),
          })
        }
      })

      const response = await patchRequest(url, {sokopoint_adding: body}, true)

      if (response) {
        setMessage('Earning & Spending Values configuration updated successfully.')
        setIsAlertSuccess(true)
        setIsSubmit('Submit')
        fetchEarningSpendingValues()
        goToTop()
        setTimeout(() => {
          setIsAlertSuccess(false)
        }, 2000)
      }
    }
  }

  const onUnset = async () => {
    setSubmit('Please Wait')

    const url = `${apiBaseURL()}master/sokopoint-values/update`
    const body: any = []

    sokoPoints.map((item: any) => {
      body.push({
        warehouse_id: warehouse?.value ?? '',
        brand_id: item.id,
        customer_type: type,
        purchase_amount: 0,
        sokopoint_earning: 0,
        point_validity: 0,
        sokopoint_spending: 0,
        discount_amount: 0,
      })
    })

    const response = await patchRequest(url, {sokopoint_adding: body}, true)

    if (response) {
      onHide()
      setIsAlertSuccess(true)
      setSubmit('Submit')
      setMessage('Earning & Spending Values configuration unset successfully.')
      fetchEarningSpendingValues()
      goToTop()
      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 2000)
    }
  }

  const onHide = () => {
    setShow(false)
  }

  useEffect(() => {
    getFirstWh()
  }, [])

  useEffect(() => {
    !isEmpty(warehouse.value) && fetchEarningSpendingValues()
  }, [type, warehouse])

  return (
    <>
      <EATitle title='Earning & Spending Values ' />
      <PageTitle
        breadcrumbs={[
          {
            title: 'Masters',
            subTitle: 'Earning & Spending Values',
          },
        ]}
      >
        Earning & Spending Values
      </PageTitle>

      <EaAlertSuccess
        onClick={() => setIsAlertSuccess(false)}
        show={IsAlertSuccess}
        message={message}
      />

      <>
        <div className='card mb-4 mb-xl-5'>
          <div className='card-body pt-1 pb-0'>
            <div className='d-flex overflow-auto h-40px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap cursor-pointer'>
                <li
                  className='nav-item'
                  onClick={() => {
                    setType('retailer')
                  }}
                >
                  <div
                    className={`nav-link text-active-primary me-0 ${
                      type === 'retailer' ? 'active' : ''
                    }`}
                  >
                    Retail Customer
                  </div>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    setType('wholesaler')
                  }}
                >
                  <div
                    className={`nav-link text-active-primary me-0 ${
                      type === 'wholesaler' ? 'active' : ''
                    }`}
                  >
                    Wholesale Customer
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='card'>
          <div className='card-header border-0 px-7 pt-3'>
            <div className='card-toolbar row g-0 w-100'>
              {!loader && !isSet && (
                <div className='bg-light-warning d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>
                  <span className='svg-icon-dark'>
                    <KTSVG path='/media/icons/duotune/general/gen045.svg' className='ic me-3' />
                  </span>
                  <div className='text-dark pe-2'>
                    Please configure the SokoPoints' Values and Validity for the selected Warehouse!
                  </div>
                </div>
              )}

              <div className='col-sm-6 mb-4'>
                <label className='text-muted mb-2'>Select Warehouse</label>
                <WarehouseFilter
                  className='w-250px'
                  warehouse={warehouse}
                  handleFilterChange={(e: any) => setWarehouse(e)}
                  allWarehouse={false}
                  isDisabled={loader}
                />
              </div>

              {isSet && (
                <div className='col-sm-6 text-end'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => {
                      setShow(true)
                    }}
                  >
                    Unset Earning & Spending Values
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className='card-body py-1'>
            <div>
              <label className='text-muted mb-2'>Earning & Spending Values</label>
            </div>

            {loader ? (
              <FetchingRecords />
            ) : sokoPoints.length === 0 ? (
              <NoRecords />
            ) : (
              <div className='table-responsive'>
                <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                  <thead>
                    <tr className=' text-muted fw-500'>
                      <th className='min-w-100px py-2' style={{width: '25%'}}>
                        Brand
                      </th>
                      <th className='min-w-100px py-2' style={{width: '25%'}}>
                        Purchase Amount
                      </th>
                      <th className='min-w-100px py-2' style={{width: '15%'}}>
                        SokoPoints
                      </th>
                      <th className='min-w-100px py-2' style={{width: '25%'}}>
                        SokoAmount
                      </th>
                      <th className='min-w-100px py-2' style={{width: '25%'}}>
                        Point Validity
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {sokoPoints?.map((i: any, index: number) => {
                      return (
                        <tr key={i?.id} id={i.id}>
                          <td className='py-4'>{checkEmpty(i?.brand_name)}</td>
                          <td className='py-4'>
                            <div className={` ${i?.purchase_amount_error} input-group mb-1`}>
                              <span className='input-group-text bg-light2 border-start-0 px-3 border-end border-light ms-0 w-50px'>
                                {tenantConfiguration.currencyCode}
                              </span>
                              <input
                                type='text'
                                onChange={(e) => onInputChange(e, index)}
                                placeholder='0'
                                className={`form-control min-w-70px`}
                                value={isEmpty(i?.purchase_amount) ? '' : i?.purchase_amount}
                                name='purchase_amount'
                              />
                            </div>
                          </td>
                          <td>{isEmpty(i?.sokopoint_earning) ? 1 : i?.sokopoint_earning}</td>
                          <td className='py-4'>
                            <div className={` ${i?.discount_amount_error} input-group mb-1`}>
                              <span className='input-group-text bg-light2 border-start-0 px-3 border-end border-light ms-0 w-50px'>
                                {tenantConfiguration.currencyCode}
                              </span>
                              <input
                                type='text'
                                onChange={(e) => onInputChange(e, index)}
                                placeholder='0'
                                className={`form-control min-w-70px`}
                                value={isEmpty(i?.discount_amount) ? '' : i?.discount_amount}
                                name='discount_amount'
                              />
                            </div>
                          </td>
                          <td className='py-4'>
                            <div className={` ${i?.point_validity_error} input-group mb-1 w-200px`}>
                              <input
                                type='text'
                                onChange={(e) => onInputChange(e, index)}
                                placeholder='0'
                                className={`form-control min-w-70px `}
                                value={isEmpty(i?.point_validity) ? '' : i?.point_validity}
                                name='point_validity'
                              />

                              <span className='input-group-text bg-light2 border-start-0 px-3 border-end border-light ms-0 w-50px'>
                                Days
                              </span>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className='card-footer'>
            <button
              type='button'
              disabled={loader || isSubmit !== 'Submit'}
              className='btn btn-primary'
              onClick={onSubmit}
            >
              {isSubmit}
            </button>
          </div>
        </div>
      </>

      <Modal
        show={show}
        onHide={onHide}
        centered
        backdrop='static'
        dialogClassName='h-auto mw-570px'
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Unset Earning & Spending Values</h2>
          </Modal.Title>
          <div className='btn p-1 mh-24 btn-active-light-primary ms-2' onClick={onHide}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='card'>
            <p className='mt-2'>
              Are you sure you want to unset all Earning & Spending Values for{' '}
              <b>{warehouse?.label}</b>?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn btn-secondary me-3'
              onClick={onHide}
              disabled={submit !== 'Submit'}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                onUnset()
              }}
              disabled={submit !== 'Submit'}
            >
              {submit}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SokoPoints
