import React, {useEffect, useState} from 'react'
import {getRequest, postRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {apiBaseURL, focusVatidateField, goToTop, isEmpty} from '../../../../../_eaFruitsDms/helpers'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import clsx from 'clsx'
import {EaAlertSuccess} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FormatMessage from '../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'

const OdooProductSkus = () => {
  const [products, setProducts] = useState<any>([])
  const [fetchingRecords, setFetchingRecords] = useState<boolean>(false)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isSubmit, setIsSubmit] = useState('Submit')

  useEffect(() => {
    fetchingDetails().then()
  }, [])

  const fetchingDetails = async () => {
    setFetchingRecords(true)
    const response = await getRequest(`${apiBaseURL()}odoo-mapping/product-sku`, true)

    if (!isEmpty(response) && !isEmpty(response.result)) {
      setProducts(response.result)
    }

    setFetchingRecords(false)
  }

  const handleChange = (e: any, index: number) => {
    const inputValue = e.target.value
    const inputName = e.target.name

    setProducts((prevWarehouses: any) => {
      const updatedWarehouseDetail = [...prevWarehouses]

      updatedWarehouseDetail[index] = {
        ...updatedWarehouseDetail[index],
        [inputName]: inputValue,
        [`${inputName}_valid`]: '',
      }

      return updatedWarehouseDetail
    })
  }

  const checkProductInput = (e: any, index: any) => {
    let checkProducts: any = [...products]

    if (
      isEmpty(checkProducts[index].product_id) &&
      isEmpty(checkProducts[index].product_name) &&
      isEmpty(checkProducts[index].product_description)
    ) {
      checkProducts[index]['product_id_valid'] = ''
      checkProducts[index]['product_name_valid'] = ''
      checkProducts[index]['product_description_valid'] = ''
    }

    setProducts(checkProducts)
  }

  const onProductSubmit = async () => {
    let valid = []

    let checkProducts: any = [...products]

    checkProducts.forEach((item: any, index: any) => {
      if (
        !isEmpty(checkProducts[index].product_id) ||
        !isEmpty(checkProducts[index].product_name) ||
        !isEmpty(checkProducts[index].product_description)
      ) {
        if (isEmpty(item.product_id)) {
          valid.push(index)
          checkProducts[index]['product_id_valid'] = 'is-invalid'
          focusVatidateField(index)
        } else {
          checkProducts[index]['product_id_valid'] = ''
        }

        if (isEmpty(item.product_name)) {
          valid.push(index)
          checkProducts[index]['product_name_valid'] = 'is-invalid'
          focusVatidateField(index)
        } else {
          checkProducts[index]['product_name_valid'] = ''
        }

        if (isEmpty(item.product_description)) {
          valid.push(index)
          checkProducts[index]['product_description_valid'] = 'is-invalid'
          focusVatidateField(index)
        } else {
          checkProducts[index]['product_description_valid'] = ''
        }
      } else {
        checkProducts[index]['product_id_valid'] = ''
        checkProducts[index]['product_name_valid'] = ''
        checkProducts[index]['product_description_valid'] = ''
      }
    })

    setProducts(checkProducts)

    if (valid.length === 0) {
      setIsSubmit('Please Wait')
      let url = apiBaseURL() + 'odoo-mapping/product-sku/edit'
      let productPayload: any = []

      products.forEach((item: any, index: any) => {
        if (
          !isEmpty(checkProducts[index].product_id) ||
          !isEmpty(checkProducts[index].product_name) ||
          !isEmpty(checkProducts[index].product_description)
        ) {
          productPayload.push({
            product_sku: item.id,
            product_id: item.product_id,
            product_name: item.product_name,
            product_description: item.product_description,
          })
        }
      })

      const response = await postRequest(url, {mapping_product_sku: productPayload}, true)

      if (response) {
        setIsAlertSuccess(true)
        setIsSubmit('Submit')
        goToTop()
        setTimeout(() => {
          setIsAlertSuccess(false)
        }, 2000)
      }
    }
  }

  return (
    <>
      <EATitle title='DMS to Odoo Mapping Config - ' />
      <EaAlertSuccess
        onClick={() => setIsAlertSuccess(false)}
        show={IsAlertSuccess}
        message={FormatMessage('Product Sku configuration updated successfully.')}
      />
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-5'>
          {fetchingRecords ? (
            <FetchingRecords />
          ) : products.length === 0 ? (
            <NoRecords />
          ) : (
            <>
              <div className='table-responsive'>
                <table className='table align-baseline table-row-bordered table-row-gray-100 gs-0 gy-3'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='w-180px'>Product SKUs In DMS</th>
                      <th className='min-w-150px'>Product Details In Odoo</th>
                    </tr>
                  </thead>
                  <tr className='align-top bg-light2'>
                    <th className='min-w-150px'></th>
                    <th className='min-w-150px'>
                      <b className='font-14 text-muted'>
                        Product ID
                        <span className='text-danger'>*</span>
                      </b>
                    </th>
                    <th className='min-w-150px'>
                      <b className='font-14 text-muted'>
                        Product Title
                        <span className='text-danger'>*</span>
                      </b>
                    </th>
                    <th className='min-w-150px'>
                      <b className='font-14 text-muted'>
                        Product Description
                        <span className='text-danger'>*</span>
                      </b>
                    </th>
                  </tr>
                  <tbody>
                    {products.map((item: any, index: any) => {
                      return (
                        <>
                          <tr className='align-top' id={index}>
                            <td>
                              <div className='font-14 text-muted'>
                                {item.name}
                                <span className='text-danger'>*</span>
                              </div>
                            </td>
                            <td>
                              <input
                                type='text'
                                className={clsx('form-control', item.product_id_valid)}
                                onChange={(e: any) => handleChange(e, index)}
                                onBlur={(e: any) => checkProductInput(e, index)}
                                placeholder='Type here..'
                                value={isEmpty(item.product_id) ? '' : item.product_id}
                                name='product_id'
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className={clsx('form-control', item.product_name_valid)}
                                onChange={(e: any) => handleChange(e, index)}
                                placeholder='Type here..'
                                value={isEmpty(item.product_name) ? '' : item.product_name}
                                onBlur={(e: any) => checkProductInput(e, index)}
                                name='product_name'
                              />
                            </td>
                            <td>
                              <textarea
                                rows={3}
                                className={clsx('form-control', item.product_description_valid)}
                                onChange={(e: any) => handleChange(e, index)}
                                onBlur={(e: any) => checkProductInput(e, index)}
                                placeholder='Type here..'
                                value={
                                  isEmpty(item.product_description) ? '' : item.product_description
                                }
                                name='product_description'
                              />
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        {products.length > 0 && !fetchingRecords && (
          <div className='card-footer'>
            <button
              disabled={isSubmit !== 'Submit' ? true : false}
              className='btn btn-primary'
              onClick={onProductSubmit}
            >
              {isSubmit}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default OdooProductSkus
