import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {apiBaseURL} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import EaDropDown from '../../../_eaFruitsDms/layout/components/dropdown/dropdown'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import CustomPagination from '../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import VehicleTableLo from './component/vehicleTableLO'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'

type Props = {
  className: string
}
const year = [
  {value: '1990', label: '1990'},
  {value: '1991', label: '1991'},
  {value: '1992', label: '1992'},
  {value: '1993', label: '1993'},
  {value: '1994', label: '1994'},
  {value: '1995', label: '1995'},
  {value: '1996', label: '1996'},
  {value: '1997', label: '1997'},
  {value: '1998', label: '1998'},
  {value: '1999', label: '1999'},
  {value: '2000', label: '2000'},
  {value: '2001', label: '2001'},
  {value: '2002', label: '2002'},
  {value: '2003', label: '2003'},
  {value: '2004', label: '2004'},
  {value: '2005', label: '2005'},
  {value: '2006', label: '2006'},
  {value: '2007', label: '2007'},
  {value: '2008', label: '2008'},
  {value: '2009', label: '2009'},
  {value: '2010', label: '2010'},
  {value: '2011', label: '2011'},
  {value: '2012', label: '2012'},
  {value: '2013', label: '2013'},
  {value: '2014', label: '2014'},
  {value: '2015', label: '2015'},
  {value: '2016', label: '2016'},
  {value: '2017', label: '2017'},
  {value: '2018', label: '2018'},
  {value: '2019', label: '2019'},
  {value: '2020', label: '2020'},
  {value: '2021', label: '2021'},
  {value: '2022', label: '2022'},
]

const WMVehicleManagement: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const customerHeaderTitle = {
    title: 'Vehicle Overview',
    subTitle: '',
  }

  const limit = '&limit=1000'

  const [getList, setGetList] = useState<any>([]) // getdata
  const [getListDropDown, setGetListDropDown] = useState<any>([]) // getdata
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [selected, setSelected] = useState(0)
  const [currentOffset, setCurrentOffset] = useState<any>('')

  const [dropDownList, setDropDownList] = useState({
    vehicleType: [],
    make: [],
    brands: [],
    wards: [],
    status: [
      {value: '', label: 'All Status'},
      {value: 'Available', label: 'Available'},
      {value: 'Breakdown', label: 'Breakdown'},
      {value: 'InService', label: 'In Service'},
    ],
    model: [],
    warehouse: [],
  })
  const [dropDownValue, setDropDownValue] = useState({
    vehicleType: [{value: '', label: 'All Vehicle'}],
    make: [{value: '', label: 'All Make'}],
    brands: [{value: '', label: 'All Brands'}],
    wards: [{value: '', label: 'All Wards'}],
    warehouse: [{value: localStorage.getItem('w_id'), label: 'All Warehouse'}],
    status: [{value: '', label: 'All Status'}],
  })

  const getVehicleList = (
    limit: any,
    offset: any,
    search: any,
    vehicleType: any,
    make: any,
    ward: any,
    brand: any,
    status: any,
    warehouseId: any
  ) => {
    setListLoader(true)
    const url = `${apiBaseURL()}vehicle/view?&limit=${limit}&offset=${offset}&search=${search}&type__id=${vehicleType}&make__id=${make}&wards__id=${ward}&brand_name__id=${brand}&status=${status}&warehouse_id=${warehouseId}`
    // &warehouse_id=${localStorage.getItem('w_id')}
    getRequest(url, true).then((res) => {
      setGetList(res.results)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.previous)
      setListLoader(false)
    })
  }

  useEffect(() => {
    // Develop BY: Kashyap
    // Description: get table list and dropdown list
    getVehicleList(
      '10',
      '0',
      '',
      dropDownValue.vehicleType[0].value,
      dropDownValue.make[0].value,
      dropDownValue.wards[0].value,
      dropDownValue.brands[0].value,
      dropDownValue.status[0].value,
      dropDownValue.warehouse[0].value
    )
    getModalValue()
  }, [])

  const [dropDownModalList, setDropDownModalList] = useState({
    vehicleType: [],
    make: [],
    brands: [],
    wards: [],
    status: [
      {value: 'Available', label: 'Available'},
      {value: 'Breakdown', label: 'Breakdown'},
      {value: 'InService', label: 'In Service'},
    ],
    model: [],
  })

  const getModalValue = () => {
    // Develop BY: Kashyap
    // Description: dropdown list function

    getRequest(`${apiBaseURL()}master/vechile-type/?ordering=vechile_type` + limit, true).then(
      (res) => {
        let vehicleType: any = [{value: '', label: 'All Vehicle'}]
        let vehicleTypeModal: any = []
        res.results.forEach((element: any) => {
          vehicleType.push({value: element.id, label: element.vechile_type})
          vehicleTypeModal.push({value: element.id, label: element.vechile_type})
        })
        setDropDownList((preValue: any) => ({...preValue, vehicleType: vehicleType}))
        setDropDownModalList((preValue: any) => ({...preValue, vehicleType: vehicleTypeModal}))
      }
    )

    getRequest(`${apiBaseURL()}master/vechile-make/?ordering=vechile_make` + limit, true).then(
      (res) => {
        let vehicleMake: any = [{value: '', label: 'All Make'}]
        let vehicleMakeModal: any = []
        res.results.forEach((element: any) => {
          vehicleMake.push({value: element.id, label: element.vechile_make})
          vehicleMakeModal.push({value: element.id, label: element.vechile_make})
        })
        setDropDownList((preValue: any) => ({...preValue, make: vehicleMake}))
        setDropDownModalList((preValue: any) => ({...preValue, make: vehicleMakeModal}))
      }
    )

    getRequest(`${apiBaseURL()}master/product/brand?ordering=name` + limit, true).then((res) => {
      let brands: any = [{value: '', label: 'All Brands'}]
      let brandsModal: any = []
      res.results.forEach((element: any) => {
        brands.push({value: element.id, label: element.name})
        brandsModal.push({value: element.id, label: element.name})
      })
      setDropDownList((preValue: any) => ({...preValue, brands: brands}))
      setDropDownModalList((preValue: any) => ({...preValue, brands: brandsModal}))
    })

    getRequest(`${apiBaseURL()}master/vechile-model?ordering=name` + limit, true).then((res) => {
      let modal: any = [{value: '', label: 'All Modal'}]
      let modalModal: any = []
      res.results.forEach((element: any) => {
        modal.push({value: element.id, label: element.vechile_model})
        modalModal.push({value: element.id, label: element.vechile_model})
      })
      setDropDownList((preValue: any) => ({...preValue, model: modal}))
      setDropDownModalList((preValue: any) => ({...preValue, model: modalModal}))
    })

    const warehouseUrl = `${apiBaseURL()}warehouse/list?parent_warehouse__id=${localStorage.getItem(
      'w_id'
    )}&ordering=name&is_parent=True`

    getRequest(warehouseUrl + limit, true).then((res) => {
      let modal: any = []
      let modalModal: any = []
      let parentWarehouse: any = []
      res.results.forEach((element: any) => {
        modal.push({value: element.id, label: element.name})
        modalModal.push({value: element.id, label: element.name})
        if (element.id == localStorage.getItem('w_id')) {
          parentWarehouse.push({value: element.id, label: element.name})
        }
      })

      setDropDownValue((preValue) => ({...preValue, warehouse: parentWarehouse}))
      setDropDownList((preValue: any) => ({...preValue, warehouse: modal}))
      setDropDownModalList((preValue: any) => ({...preValue, warehouse: modalModal}))
    })
    getWards(localStorage.getItem('w_id'))
  }

  const getWards = (warehouseId: any) => {
    getRequest(
      `${apiBaseURL()}master/wards/list/?ordering=name` + limit + '&warehouse_id=' + warehouseId,
      true
    ).then((ward) => {
      let getLoWards: any = [{value: '', label: 'All Wards'}]
      let getLoWardsModal: any = []
      ward.results.forEach((element: any) => {
        getLoWards.push({value: element.id, label: element.name})
        getLoWardsModal.push({value: element.id, label: element.name})
      })
      setDropDownList((preValue: any) => ({...preValue, wards: getLoWards}))
      setDropDownModalList((preValue: any) => ({...preValue, wards: getLoWardsModal}))
    })
  }

  const filterVehicleType = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, vehicleType: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      e.value,
      dropDownValue.make[0].value,
      dropDownValue.wards[0].value,
      dropDownValue.brands[0].value,
      dropDownValue.status[0].value,
      dropDownValue.warehouse[0].value
    )
  }
  const filterWarehouse = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, wards: [{value: '', label: 'All Wards'}]}))
    getWards(e.value)
    setDropDownValue((preValue) => ({...preValue, warehouse: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      dropDownValue.vehicleType[0].value,
      dropDownValue.make[0].value,
      '',
      dropDownValue.brands[0].value,
      dropDownValue.status[0].value,
      e.value
    )
  }
  const filterMake = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, make: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      dropDownValue.vehicleType[0].value,
      e.value,
      dropDownValue.wards[0].value,
      dropDownValue.brands[0].value,
      dropDownValue.status[0].value,
      dropDownValue.warehouse[0].value
    )
  }
  const filterBrands = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, brands: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      dropDownValue.vehicleType[0].value,
      dropDownValue.make[0].value,
      dropDownValue.wards[0].value,
      e.value,
      dropDownValue.status[0].value,
      dropDownValue.warehouse[0].value
    )
  }
  const filterWards = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, wards: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      dropDownValue.vehicleType[0].value,
      dropDownValue.make[0].value,
      e.value,
      dropDownValue.brands[0].value,
      dropDownValue.status[0].value,
      dropDownValue.warehouse[0].value
    )
  }
  const filterStatus = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, status: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      dropDownValue.vehicleType[0].value,
      dropDownValue.make[0].value,
      dropDownValue.wards[0].value,
      dropDownValue.brands[0].value,
      e.value,
      dropDownValue.warehouse[0].value
    )
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      getVehicleList(
        '10',
        '',
        e.target.value,
        dropDownValue.vehicleType[0].value,
        dropDownValue.make[0].value,
        dropDownValue.wards[0].value,
        dropDownValue.brands[0].value,
        dropDownValue.status[0].value,
        dropDownValue.warehouse[0].value
      )
      setSelected(0)
    }, 500)
    setTimer(newTimer)
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      getVehicleList(
        '10',
        newOffset,
        search,
        dropDownValue.vehicleType[0].value,
        dropDownValue.make[0].value,
        dropDownValue.wards[0].value,
        dropDownValue.brands[0].value,
        dropDownValue.status[0].value,
        dropDownValue.warehouse[0].value
      )
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      getVehicleList(
        '10',
        newOffset,
        search,
        dropDownValue.vehicleType[0].value,
        dropDownValue.make[0].value,
        dropDownValue.wards[0].value,
        dropDownValue.brands[0].value,
        dropDownValue.status[0].value,
        dropDownValue.warehouse[0].value
      )
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      getVehicleList(
        '10',
        newOffset,
        search,
        dropDownValue.vehicleType[0].value,
        dropDownValue.make[0].value,
        dropDownValue.wards[0].value,
        dropDownValue.brands[0].value,
        dropDownValue.status[0].value,
        dropDownValue.warehouse[0].value
      )
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[customerHeaderTitle]}>
        {intl.formatMessage({id: 'Vehicle Overview'})}
      </PageTitle>
      <EATitle title='Vehicle Overview - ' />
      <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar id='searcListProdcut' onChange={searchList} />
            <div className='col-auto ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <EaDropDown
                  list={dropDownList.warehouse}
                  placeholder='All Warehouse'
                  onChange={filterWarehouse}
                  value={dropDownValue.warehouse}
                />
                <EaDropDown
                  list={dropDownList.vehicleType}
                  placeholder='All Vehicle Types'
                  onChange={filterVehicleType}
                  value={dropDownValue.vehicleType}
                />
                <EaDropDown
                  className='my-1 me-3 w-130px'
                  list={dropDownList.make}
                  placeholder='All Make'
                  onChange={filterMake}
                  value={dropDownValue.make}
                />
                <EaDropDown
                  className='my-1 me-3 w-120px'
                  list={dropDownList.brands}
                  placeholder='All Brands'
                  onChange={filterBrands}
                  value={dropDownValue.brands}
                />
                <EaDropDown
                  className='my-1 me-3 w-110px'
                  list={dropDownList.wards}
                  placeholder='All Wards'
                  onChange={filterWards}
                  value={dropDownValue.wards}
                />
                <EaDropDown
                  className='my-1 me-3 w-115px'
                  list={dropDownList.status}
                  placeholder='All Status'
                  onChange={filterStatus}
                  value={dropDownValue.status}
                />
                {/* </div> */}
              </form>
            </div>
          </div>
        </div>
        {getList.length === 0 ? <NoRecords /> : <VehicleTableLo list={getList} showIcon={false} />}
      </div>

      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${
              getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
            } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default WMVehicleManagement
