import moment from 'moment'
import {
  SUPPORT_REQUEST_REPORT_DATE,
  SUPPORT_REQUEST_REPORT_END_DATE,
  SUPPORT_REQUEST_REPORT_START_DATE,
  SUPPORT_REQUEST_REPORT_WAREHOUSE,
} from '../../types/types'
import {isEmpty} from '../../../helpers'

const selectedWarehouse = isEmpty(localStorage.getItem('support_request_warehouse'))
  ? ''
  : JSON.parse(localStorage.getItem('support_request_warehouse') || '')

const startDate = !isEmpty(localStorage.getItem('support_request_start_date'))
  ? localStorage.getItem('support_request_start_date')
  : moment().startOf('month').format('YYYY-MM-DD')

const endDate = !isEmpty(localStorage.getItem('support_request_end_date'))
  ? localStorage.getItem('support_request_end_date')
  : moment().endOf('month').format('YYYY-MM-DD')

const initialState: any = {
  warehouseId: selectedWarehouse?.value ?? '',
  startDate: startDate,
  endDate: endDate,
  date: {
    startDate: startDate,
    endDate: endDate,
  },
}

export const SupportRequestReportFilter = (state = initialState, action: any) => {
  switch (action.type) {
    case SUPPORT_REQUEST_REPORT_WAREHOUSE:
      return {
        ...state,
        warehouseId: action.payload,
      }

    case SUPPORT_REQUEST_REPORT_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      }

    case SUPPORT_REQUEST_REPORT_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      }

    case SUPPORT_REQUEST_REPORT_DATE:
      return {
        ...state,
        date: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}
