import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {checkPermissions} from '../../../helpers'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import SideBarLoader from '../fetchingReccords/sideBarLoader'

export function AsideMenuMain() {
  const intl = useIntl()
  const loc = useLocation()

  const [isLoading, setIsLoading] = useState(true)

  const [showDashboard, setDashboard] = useState(false)
  const [showCustomer, setCustomer] = useState(false)
  const [showTeleSales, setTeleSales] = useState(false)
  const [showBrands, setBrands] = useState(false)
  const [showProductSkus, setProductSkus] = useState(false)
  const [showSurveyForms, setSurveyForms] = useState(false)
  const [showTargets, setShowTargets] = useState<boolean>(false)
  const [showWarehouseRoles, setWareHouseRoles] = useState(false)
  const [showWarehouseDC, setWareHouseDC] = useState(false)
  const [showMasterDB, setMasterDB] = useState(false)
  const [showMasterPM, setMasterPM] = useState(false)
  const [showMasterCM, setMasterCM] = useState(false)
  const [showMasterRM, setMasterRM] = useState(false)
  const [showMasterVB, setMasterVB] = useState(false)
  const [showReportPskus, setReportPskus] = useState(false)
  const [showReportSre, setReportSre] = useState(false)
  const [showReportCIR, setReportCIR] = useState(false)
  const [showReportTR, setReportTR] = useState(false)
  const [showInvoiceReport, setInvoiceReport] = useState(false)
  const [showReportVR, setReportVR] = useState(false)
  const [showReportCR, setReportCR] = useState(false)
  const [showReportRej, setReportRej] = useState(false)
  const [showSuperAdminUA, setSuperAdminUA] = useState(false)
  const [showSuperAdminRP, setSuperAdminRP] = useState(false)
  const [showCms, setCms] = useState(false)
  const [showMasterPay, setMasterPay] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('eafruitsLogin') === null) {
      window.location.reload()
    }
  }, [loc])

  const getPermission = async () => {
    setIsLoading(true)

    let res: any

    res = await checkPermissions('customers')

    if (res && (res.read || res.write)) {
      setCustomer(true)
    }

    res = await checkPermissions('telesales')

    if (res && (res.read || res.write)) {
      setTeleSales(true)
    }

    res = await checkPermissions('brands')

    if (res && (res.read || res.write)) {
      setBrands(true)
    }

    res = await checkPermissions('product_SKUs')

    if (res && (res.read || res.write)) {
      setProductSkus(true)
    }

    res = await checkPermissions('survey_forms')

    if (res && (res.read || res.write)) {
      setSurveyForms(true)
    }

    res = await checkPermissions('sa_targets')

    if (res && (res.read || res.write)) {
      setShowTargets(true)
    }

    res = await checkPermissions('warehouse_roles')

    if (res && (res.read || res.write)) {
      setWareHouseRoles(true)
    }

    res = await checkPermissions('warehouse_and_dc')

    if (res && (res.read || res.write)) {
      setWareHouseDC(true)
    }

    res = await checkPermissions('masters_distribution_boundaries')

    if (res && (res.read || res.write)) {
      setMasterDB(true)
    }

    res = await checkPermissions('masters_products')

    if (res && (res.read || res.write)) {
      setMasterPM(true)
    }

    res = await checkPermissions('masters_customers')

    if (res && (res.read || res.write)) {
      setMasterCM(true)
    }

    res = await checkPermissions('masters_rejection_options')

    if (res && (res.read || res.write)) {
      setMasterRM(true)
    }

    res = await checkPermissions('masters_vehicle_breakdown_options')

    if (res && (res.read || res.write)) {
      setMasterVB(true)
    }

    res = await checkPermissions('report_product_SKUs')

    if (res && (res.read || res.write)) {
      setReportPskus(true)
    }

    res = await checkPermissions('Report_sales')

    if (res && (res.read || res.write)) {
      setReportCIR(true)
    }

    res = await checkPermissions('report_credit_issues')

    if (res && (res.read || res.write)) {
      setReportSre(true)
    }

    res = await checkPermissions('report_team')

    if (res && (res.read || res.write)) {
      setReportTR(true)
    }

    res = await checkPermissions('sales_invoice_report')

    if (res && (res.read || res.write)) {
      setInvoiceReport(true)
    }

    res = await checkPermissions('report_vehicles')

    if (res && (res.read || res.write)) {
      setReportVR(true)
    }

    res = await checkPermissions('Report > Comparisons').then((res) => {})
    if (res && (res.read || res.write)) {
      setReportCR(true)
    }

    res = await checkPermissions('report_rejections')

    if (res && (res.read || res.write)) {
      setReportRej(true)
    }

    res = await checkPermissions('super_admin_user_accounts')

    if (res && (res.read || res.write)) {
      setSuperAdminUA(true)
    }

    res = await checkPermissions('super_admin_roles_and_permissions')

    if (res && (res.read || res.write)) {
      setSuperAdminRP(true)
    }

    res = await checkPermissions('cms')

    if (res && (res.read || res.write)) {
      setCms(true)
    }

    res = await checkPermissions('masters_payment')

    if (res && (res.read || res.write)) {
      setMasterPay(true)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    getPermission()
  }, [])

  return (
    <>
      {isLoading ? (
        <SideBarLoader />
      ) : (
        <>
          <AsideMenuItem
            to='/dashboard'
            icon='/media/icons/duotune/general/gen001.svg'
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            fontIcon='bi-app-indicator'
          />

          {showWarehouseDC || showWarehouseRoles ? (
            <AsideMenuItemWithSub
              to='/warehouse'
              title='Warehouse'
              icon='/media/icons/duotune/maps/map008.svg'
            >
              {showWarehouseDC && (
                <AsideMenuItem to='/warehouse/DC' title='Warehouses & DC' hasBullet={true} />
              )}

              {showWarehouseRoles && (
                <AsideMenuItem to='/warehouse/roles' title='Warehouse Roles' hasBullet={true} />
              )}
            </AsideMenuItemWithSub>
          ) : null}

          {showCustomer ? (
            <AsideMenuItemWithSub
              to='/customers'
              title='Customers'
              icon='/media/icons/duotune/communication/com013.svg'
            >
              {showCustomer && (
                <AsideMenuItem to='/customers' title='All Customers' hasBullet={true} />
              )}
              <AsideMenuItem
                to='/customers/verification-requests'
                title='Verification Requests'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/customers/horeca-contracts'
                title='HORECA Customers'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/customers/suspended-customers'
                title='Suspended Customers'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/customers/special-order-limit'
                title='Special Order Limits'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/customers/order-limit-access-request'
                title='Order Limit Access Requests'
                hasBullet={true}
              />
              <AsideMenuItem to='/customers/customer-lead' title='Customer Lead' hasBullet={true} />
            </AsideMenuItemWithSub>
          ) : null}

          {showTeleSales && (
            <AsideMenuItem
              to='/tele-sales'
              icon='/media/icons/duotune/general/gen032.svg'
              title='Tele-Sales'
            />
          )}

          {showBrands && (
            <AsideMenuItem
              to='/brands'
              icon='/media/icons/duotune/abstract/abs014.svg'
              title='Brands'
            />
          )}

          {showProductSkus && (
            <AsideMenuItem
              to='/products'
              icon='/media/icons/duotune/general/gen017.svg'
              title='Product SKUs'
            />
          )}

          {showSurveyForms && (
            <AsideMenuItem
              to='/survey-forms'
              title='Survey Forms'
              icon='/media/icons/duotune/files/fil024.svg'
            />
          )}

          {showTargets && (
            <AsideMenuItem
              to='/targets'
              icon='/media/icons/duotune/abstract/abs014.svg'
              title='Targets'
            />
          )}

          {showReportPskus ||
          showReportSre ||
          showReportCIR ||
          showReportTR ||
          showReportVR ||
          showReportCR ||
          showInvoiceReport ||
          showReportRej ? (
            <AsideMenuItemWithSub
              to='/reports'
              title='Reports'
              icon='/media/icons/duotune/graphs/gra006.svg'
            >
              <AsideMenuItem to='/reports/order-report' title='Pre Order Report' hasBullet={true} />
              {/* {showReportPskus &&
            <AsideMenuItem to='/reports/product-skus-report' title='Product wise Sales' hasBullet={true} />}

          {showReportSre &&
            <AsideMenuItem to='/reports/sales-report' title='Sales Report' hasBullet={true} />}

          {showReportCIR &&
            <AsideMenuItem to='/reports/credit-issues-report' title='Credit Issues Report' hasBullet={true} />} */}
              <AsideMenuItem
                to='/reports/gatepass-report'
                title='Gatepass Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/route-usage-report'
                title='Route Usage Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/serving-time-report'
                title='Serving Time Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/idle-time-report'
                title='Idle Time Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/low-stock-report'
                title='Low-Stock Report'
                hasBullet={true}
              />
              {showInvoiceReport && (
                <AsideMenuItem
                  to='/reports/sales-invoice-report'
                  hasBullet={true}
                  title='Sales Invoice Report'
                />
              )}
              <AsideMenuItem to='/reports/summary-report' hasBullet={true} title='Summary Report' />
              <AsideMenuItem
                to='/reports/credit-collection-report'
                title='Credit Collection Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/payment-issues'
                title='Payment Issues Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/credit-issues'
                title='Credit Issues Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/route-jump-report'
                title='Route Jump Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/route-based-price-summary-report'
                title='Route Specific Price Summary Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/sr-daily-routes-report'
                title='SRs Daily Routes'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/temperature-humidity-report'
                title='Temperature & Humidity Report Readings'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/temperature-humidity-alert-report'
                title='Temperature & Humidity Alerts'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/pay-later-today-report'
                title='Pay Later Today Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/order-quantity-requests'
                title='Sales Quantity Control Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/issues-from-sg'
                title='Issues Reported by SG'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/expired-products'
                title='Unsold Expired Products'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/customer-report'
                title='Customer Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/attendance-report'
                title='Attendance Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/dormant-customer-report'
                title='Dormant Customer Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/no-ordering-customer-report'
                title='Customer No-Ordering Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/order-limit-request-report'
                title='Order Limit Request Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/product-skus-report'
                title='Product wise Sales'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/order-feedback-report'
                title='Orders Feedback'
                hasBullet={true}
              />
              {showReportTR && (
                <>
                  <AsideMenuItem to='/reports/team-report' title='Team Report' hasBullet={true} />
                </>
              )}
              {/*
          {showReportVR &&
            <AsideMenuItem to='/reports/vehicle-usage-report' title='Vehicle Report' hasBullet={true} />}

          {showReportCR &&
            <AsideMenuItem to='/reports/comparison-report' title='Comparison Report' hasBullet={true} />}
*/}
              {showReportRej && (
                <AsideMenuItemWithSub
                  to='/reports/rejection-report'
                  title='Rejection Reports'
                  hasBullet={true}
                >
                  {/*<AsideMenuItem to='/reports/rejection-report/customer-on-boarding' title='Customer On-Boarding' hasBullet={true} />*/}
                  <AsideMenuItem
                    to='/reports/rejection-report/load-out-requests'
                    title='Load-Out Requests'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/reports/rejection-report/order-discount'
                    title='Order Discount'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/reports/rejection-report/credit-request'
                    title='Credit Request'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/reports/rejection-report/gate-pass-rejection'
                    title='Gate Pass Rejection'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>
              )}
              <AsideMenuItem
                to='/reports/complaint-report'
                title='Complaints Report'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/reports/vehicle-breakdown-report'
                title='Vehicle Breakdown Report'
                hasBullet={true}
              />
            </AsideMenuItemWithSub>
          ) : null}

          {showSuperAdminUA || showSuperAdminRP ? (
            <AsideMenuItemWithSub
              to='/superadmin'
              title='Super Admin Users'
              icon='/media/icons/duotune/communication/com014.svg'
            >
              {showSuperAdminUA && (
                <AsideMenuItem
                  to='/superadmin/user-accounts'
                  title='User Accounts'
                  hasBullet={true}
                />
              )}
              {showSuperAdminRP && (
                <AsideMenuItem
                  to='/superadmin/roles'
                  title='Roles & Permissions'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          ) : null}

          {showMasterDB || showMasterPM || showMasterCM || showMasterRM || showMasterVB ? (
            <AsideMenuItemWithSub
              to='/master'
              title='Masters'
              icon='/media/icons/duotune/abstract/abs027.svg'
            >
              <AsideMenuItem
                to='/odoo-configuration/odoo-warehouse'
                title='DMS to Odoo Mapping Config'
                hasBullet={true}
              />
              <AsideMenuItem to='/master/sales-team' title='Sales Team' hasBullet={true} />
              <AsideMenuItem
                to='/master/incentive-components'
                title='Incentive Components'
                hasBullet={true}
              />
              {/*<AsideMenuItem*/}
              {/*  to='/master/weather-box-configuration'*/}
              {/*  title='Weather Box Configuration'*/}
              {/*  hasBullet={true}*/}
              {/*/>*/}
              {showMasterDB && (
                <AsideMenuItemWithSub
                  to='/distribution-boundaries'
                  title='Distribution Boundaries'
                  hasBullet={true}
                >
                  <AsideMenuItem
                    to='/master/distribution-boundaries/regions'
                    title='Regions'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/distribution-boundaries/district'
                    title='Districts'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/distribution-boundaries/ward'
                    title='Wards'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/distribution-boundaries/street'
                    title='Streets'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>
              )}

              {showMasterPM && (
                <AsideMenuItemWithSub
                  to='/product-masters'
                  title='Product Masters'
                  hasBullet={true}
                >
                  <AsideMenuItem
                    to='/master/product-masters/product-categories'
                    title='Categories'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/product-masters/product-groups'
                    title='Groups'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/product-masters/package-material'
                    title='Packaging'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/product-masters/product-variants'
                    title='Variants'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>
              )}

              <AsideMenuItemWithSub to='/soko-points' title='SokoPoints' hasBullet={true}>
                <AsideMenuItem
                  to='/master/soko-points/earning-spending-values'
                  title='Earning & Spending'
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/master/soko-points/reward-offers'
                  title='Reward Offers'
                  hasBullet={true}
                />
              </AsideMenuItemWithSub>

              {showMasterCM && (
                <AsideMenuItemWithSub
                  to='/customer-masters'
                  title='Customer Masters'
                  hasBullet={true}
                >
                  <AsideMenuItem
                    to='/master/customer-masters/customer-categories'
                    title='Categories'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/customer-masters/customer-tags'
                    title='Tags'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/customer-masters/credit-score'
                    title='Credit Score'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>
              )}

              {showMasterRM && (
                <AsideMenuItemWithSub to='/reason-masters' title='Reason Master' hasBullet={true}>
                  <AsideMenuItem
                    to='/master/reason-masters/address-reason'
                    title='Address Reasons'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/customer-rejection'
                    title='Customer Verification'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/credit-rejection'
                    title='Credit'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/credit-request-update'
                    title='Credit Request Update'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/credit-request-reject'
                    title='Credit Request Reject'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/manual-stock-update'
                    title='Manual Stock Update'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/discount-rejection'
                    title='Discount'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/stockin-reason'
                    title='Stock-in Reasons'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/order-limit-reason'
                    title='Order Limit Reasons'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/order-rejection-reason'
                    title='Order Rejection Reasons'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/revoke-access-reason'
                    title='Customer Revoke Access'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/customer-lead-rejection-reason'
                    title='Customer Lead Rejection Reason'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/grant-access-reason'
                    title='Customer Grant Access'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/business-detail-reason'
                    title='Business Detail Rejection'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/load-out-rejection-reason'
                    title='Load-out Request Rejection'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/load-out-approval-reason'
                    title='Load-out Request Partial Approval'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/discount-request-rejection'
                    title='Discount Request Rejection'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/discount-request-amount-update'
                    title='Discount Request Amount Update'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/load-out-cancel-reason'
                    title='Load-out Request Cancel'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/gatepass-reason'
                    title='Gatepass Rejection'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/case-settlement-reason'
                    title='Case Settlement Reason'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/vehicle-to-vehicle-reason'
                    title='Vehicle To Vehicle Transfer Reason'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/vehicle-to-vehicle-partially-reason'
                    title='Vehicle To Vehicle Partially Stock Receive Reason'
                    hasBullet={true}
                  />
                  {/*  */}
                  <AsideMenuItem
                    to='/master/reason-masters/route-request-reason'
                    title='Route Request Reason'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/weather-box-alert-log-reason'
                    title='Weather Box Alert Log Reason'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/target-flag-reason'
                    title='Target Flag Reason'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/reason-masters/order-quantity-reason'
                    title='Order Quantity Request Rejection Reason'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/reason-masters/complaint-reason'
                    title='Complaint Reason'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>
              )}
              {showMasterVB && (
                <AsideMenuItemWithSub to='/vehicle-masters' title='Vehicle Master' hasBullet={true}>
                  <AsideMenuItem
                    to='/master/vehicle-masters/vehicle-types'
                    title='Vehicle Types'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/vehicle-masters/vehicle-make'
                    title='Vehicle Make'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/vehicle-masters/vehicle-model'
                    title='Vehicle Model'
                    hasBullet={true}
                  />

                  <AsideMenuItem
                    to='/master/vehicle-masters/vehicle-breakdown'
                    title='Vehicle Breakdown'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>
              )}
              {showMasterPay && (
                <AsideMenuItemWithSub to='/payment-masters' title='Payment Master' hasBullet={true}>
                  <AsideMenuItem
                    to='/master/payment-masters/banks'
                    title='Banks'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/master/payment-masters/wallets'
                    title='Wallets'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>
              )}

              <AsideMenuItem
                to='/master/complaint-type'
                title='Complaint Categories'
                hasBullet={true}
              />

              {/* <AsideMenuItem to='/master/payment-master' title='Payment Master' hasBullet={true} /> */}

              {/* {showMasterVB &&
            <AsideMenuItem to='/master/vehicle-breakdown' title='Vehicle Breakdown'
              // icon='/media/icons/duotune/abstract/abs027.svg'
              hasBullet={true}
            />} */}
            </AsideMenuItemWithSub>
          ) : null}

          {showCms && (
            <AsideMenuItemWithSub
              to='/cms'
              title='CMS'
              icon='/media/icons/duotune/abstract/abs027.svg'
            >
              <AsideMenuItemWithSub to='/blogs' title='Blogs' hasBullet={true}>
                <AsideMenuItem to='/cms/blogs/categories' title='Categories' hasBullet={true} />
                <AsideMenuItem to='/cms/blogs/authors' title='Authors' hasBullet={true} />
                <AsideMenuItem to='/cms/blogs/posts' title='Posts' hasBullet={true} />
              </AsideMenuItemWithSub>

              {/* <AsideMenuItemWithSub to='/our-team' title='Our Team' hasBullet={true}>
          <AsideMenuItem to='/cms/our-team/teams' title='Teams' hasBullet={true} />
          <AsideMenuItem to='/cms/our-team/team-member' title='Team Members' hasBullet={true} />
        </AsideMenuItemWithSub> */}

              <AsideMenuItemWithSub to='/career' title='Career' hasBullet={true}>
                <AsideMenuItem
                  to='/cms/career/current-openings'
                  title=' Current Openings'
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/cms/career/application'
                  title='Job Applications'
                  hasBullet={true}
                />
              </AsideMenuItemWithSub>

              <AsideMenuItemWithSub to='/scuccess-stories' title='Success Stories' hasBullet={true}>
                <AsideMenuItem
                  to='/cms/scuccess-stories/farmers'
                  title='Farmers'
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/cms/scuccess-stories/vendors'
                  title='Customers'
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/cms/scuccess-stories/employees'
                  title='Employees'
                  hasBullet={true}
                />
              </AsideMenuItemWithSub>

              <AsideMenuItem to='/cms/testimonials' title='Testimonials' hasBullet={true} />

              <AsideMenuItemWithSub to='/inquiries ' title='Inquiries' hasBullet={true}>
                <AsideMenuItem to='/cms/inquiries/contact' title='Contact Us' hasBullet={true} />
                <AsideMenuItem
                  to='/cms/inquiries/investors'
                  title='Investors & Partners'
                  hasBullet={true}
                />
              </AsideMenuItemWithSub>
              <AsideMenuItem to='/cms/products' title='Products for Website' hasBullet={true} />
            </AsideMenuItemWithSub>
          )}
        </>
      )}
    </>
  )
}
