import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkEmpty,
  isEmpty,
  measurementLabel,
  numberFormat,
  numberFormatWithCode,
} from '../../../_eaFruitsDms/helpers'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {connect} from 'react-redux'

const SummaryReport = (props: any) => {
  const {warehouseId, date} = props
  const intl = useIntl()
  const headerTitle = {
    title: 'Summary Report',
    subTitle: 'Summary Report',
  }

  const initialSummary = {
    total_cashy_sales_amount: 0,
    total_orders: 0,
    total_sales: 0,
    total_credit_amount: 0,
    total_rejected_orders: 0,
    total_no_of_open_credits_records: 0,
    total_open_credit_amount: 0,
    total_no_of_overdue_credits_records: 0,
    total_no_of_overdue_credits_amount: 0,
    total_collected_credits_records: 0,
    total_collected_credits_amount: 0,
    total_pay_later_payments_records: 0,
    total_pay_later_payments_amount: 0,
    total_pay_later_payments_recovered_payments_records: 0,
    total_pay_later_payments_recovered_amount: 0,
    total_pay_later_payments_to_carry_forwarded_payments_records: 0,
    total_pay_later_payments_carry_forwarded_amount: 0,
    loadout_stock_approved_qty: 0,
    loadout_stock_returned_qty: 0,
    loadout_return_rate_percent: '0.00',
    new_verified_customers: 0,
    total_registered_customers: 0,
    order_did_by_customers: 0,
    credit_used_by_customers: 0,
    pay_later_payments_by_customers: 0,
    top_three_sr_sale: [],
    last_three_sr_sales: [],
  }

  const [summary, setSummary] = useState(initialSummary)
  const [sales, setSales] = useState([])
  const [returns, setReturns] = useState([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [isSalesLoading, setSalesLoading] = useState<boolean>(false)
  const [isReturnsLoading, setReturnsLoading] = useState<boolean>(false)
  const [salesProduct, setSalesProduct] = useState([])
  const [isSalesProductLoading, setSalesProductLoading] = useState<boolean>(false)

  useEffect(() => {
    fetchRecords().then()
    fetchSales().then()
    fetchReturns().then()
    fetchSalesProductGroup().then()
  }, [date, warehouseId])

  const fetchRecords = async () => {
    setDisplayLoader(true)

    const response = await getRequest(
      `${apiBaseURL()}reports/summary-report/?warehouse_id=${warehouseId}&start_date=${
        date.startDate
      }&end_date=${date.endDate}`,
      true
    )

    if (response) {
      setSummary(response)
    }

    setDisplayLoader(false)
  }

  const fetchSalesProductGroup = async () => {
    setSalesProductLoading(true)

    const response = await getRequest(
      `${apiBaseURL()}reports/summary-report/sales-product-group-overview?warehouse_id=${warehouseId}&start_date=${
        date.startDate
      }&end_date=${date.endDate}`,
      true
    )

    if (response?.result) {
      setSalesProduct(response?.result ?? [])
    }

    setSalesProductLoading(false)
  }

  const fetchSales = async () => {
    setSalesLoading(true)

    const response = await getRequest(
      `${apiBaseURL()}reports/summary-report/sales-product-sku-overview?warehouse_id=${warehouseId}&start_date=${
        date.startDate
      }&end_date=${date.endDate}`,
      true
    )

    if (response && response.result) {
      setSales(response.result)
    }

    setSalesLoading(false)
  }

  const fetchReturns = async () => {
    setReturnsLoading(true)

    const response = await getRequest(
      `${apiBaseURL()}reports/summary-report/return-loadout-product-sku-overview?warehouse_id=${warehouseId}&start_date=${
        date.startDate
      }&end_date=${date.endDate}`,
      true
    )

    if (response && response.result) {
      setReturns(response.result)
    }

    setReturnsLoading(false)
  }

  return (
    <>
      <EATitle title='Summary Report - ' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({id: 'Summary Report'})}
      </PageTitle>
      {displayLoader ? (
        <div className='card'>
          <FetchingRecords />
        </div>
      ) : (
        <>
          <div className='card mb-5'>
            <div className='card-header border-0 py-2 px-7 align-items-center'>
              <h3 className='fw-bolder'>Sales</h3>
            </div>

            <div className='py-2 px-7'>
              <div className='row g-3 g-xl-5'>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='success'
                    title={`${numberFormat(summary.total_orders).displayFormat}`}
                    description='No. of Orders'
                    path='/'
                  />
                </div>
                {/* <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='danger'
                    title={`${numberFormat(summary.total_rejected_orders).displayFormat}`}
                    description='No. of Rejected Orders'
                    path='/'
                  />
                </div> */}
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='warning'
                    title={numberFormatWithCode(summary.total_sales).displayFormat}
                    path='/'
                    description='Cashy Sales Amount'
                  />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='primary'
                    title={numberFormatWithCode(summary.total_credit_amount).displayFormat}
                    description='Credit Sales Amount'
                    path='/'
                  />
                </div>
              </div>
            </div>

            <div className='my-3 card-body'>
              <div className='row'>
                <div className='col-6 border border-left-0 border-top-0 border-bottom-0 border-secondary'>
                  <h5 className='mb-2 text-dark fw-bolder'>Top 3 SRs</h5>
                  {summary.top_three_sr_sale.length === 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                      <thead>
                        <tr className='text-muted fw-500'>
                          <th className='min-w-120px w-120px'>SR Name</th>
                          <th className='w-160px min-w-160px'>Sales Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {summary.top_three_sr_sale &&
                          summary.top_three_sr_sale.length > 0 &&
                          summary.top_three_sr_sale.map((item: any) => {
                            return (
                              <tr>
                                <td>{checkEmpty(item.sr_name)}</td>
                                <td>
                                  {isEmpty(item.salse_amount)
                                    ? '-'
                                    : numberFormatWithCode(item.salse_amount).displayFormat}
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  )}
                </div>

                <div className='col-6'>
                  <h5 className='mb-2 text-dark fw-bolder'>Last 3 SRs</h5>

                  {summary.last_three_sr_sales.length === 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                      <thead>
                        <tr className='text-muted fw-500'>
                          <th className='min-w-120px w-120px'>SR Name</th>
                          <th className='w-160px min-w-160px'>Sales Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {summary.last_three_sr_sales &&
                          summary.last_three_sr_sales.length > 0 &&
                          summary.last_three_sr_sales.map((item: any) => {
                            return (
                              <tr>
                                <td>{checkEmpty(item.sr_name)}</td>
                                <td>
                                  {isEmpty(item.salse_amount)
                                    ? '-'
                                    : numberFormatWithCode(item.salse_amount).displayFormat}
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>

            <div className='card-body py-3'>
              <div className='mb-3'>
                {isSalesLoading ? (
                  <FetchingRecords />
                ) : sales.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-120px w-120px'>Product SKU</th>
                        <th className='w-160px min-w-160px'>Product Group</th>
                        <th className='w-160px min-w-160px'>Brand</th>
                        <th className='w-160px min-w-160px'>Sales Quantity</th>
                        <th className='w-160px min-w-160px'>Sales Amt.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sales.map((item: any) => {
                        return (
                          <>
                            <tr>
                              <td>{checkEmpty(item.product_sku_name)}</td>
                              <td>{checkEmpty(item.group_name)}</td>
                              <td>{checkEmpty(item.brand_name)}</td>
                              <td>
                                {!isEmpty(item.sales_qty)
                                  ? `${numberFormat(item.sales_qty).displayFormat} ${checkEmpty(
                                      measurementLabel(
                                        item.fix_size
                                          ? item.material_name
                                          : item.approx_weight > 0
                                          ? item.material_name
                                          : item.measurement_unit
                                      ),
                                      ''
                                    )}`
                                  : '-'}
                              </td>
                              <td>
                                {isEmpty(item.sales_amount)
                                  ? '-'
                                  : numberFormatWithCode(item.sales_amount).displayFormat}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            <div className='card-body py-3'>
              <div className='border-0 py-4 align-items-center'>
                <h3 className='fw-bolder'>Discount & Credit Summary</h3>
              </div>

              <div className='mb-3'>
                {isSalesProductLoading ? (
                  <FetchingRecords />
                ) : sales.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-120px w-120px'>Product Group</th>
                        <th className='w-160px min-w-160px'>Total Credit Amount</th>
                        <th className='w-160px min-w-160px'>Total Discounted Amount</th>
                        <th className='w-160px min-w-160px'>Total Sales Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesProduct.map((item: any) => {
                        return (
                          <>
                            <tr>
                              <td>{checkEmpty(item.group_name)}</td>
                              <td>
                                {isEmpty(item.credit_amount)
                                  ? '-'
                                  : numberFormatWithCode(item.credit_amount).displayFormat}
                              </td>
                              <td>
                                {isEmpty(item.discount_amount)
                                  ? '-'
                                  : numberFormatWithCode(item.discount_amount).displayFormat}
                              </td>
                              <td>
                                {isEmpty(item.sales_amount)
                                  ? '-'
                                  : numberFormatWithCode(item.sales_amount).displayFormat}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          <div className='card mb-5'>
            <div className='card-header border-0 py-2 px-7 align-items-center'>
              <h3 className='fw-bolder'>Returns</h3>
            </div>

            <div className='py-2 px-7'>
              <div className='row g-3 g-xl-5'>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='success'
                    title={`${numberFormat(summary.loadout_stock_approved_qty).displayFormat}`}
                    description='Loaded Out Stock Qty'
                    path='/'
                  />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='warning'
                    title={numberFormatWithCode(summary.loadout_stock_returned_qty).displayFormat}
                    path='/'
                    description='Returned Stock Qty'
                  />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='primary'
                    title={`Return Rate ${
                      numberFormat(summary.loadout_return_rate_percent, true).displayFormat
                    } %`}
                    description=''
                    path='/'
                  />
                </div>
              </div>
            </div>

            <div className='card-body py-3'>
              <div>
                {isReturnsLoading ? (
                  <FetchingRecords />
                ) : returns.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-120px w-120px'>Product SKU</th>
                        <th className='w-160px min-w-160px'>Product Group</th>
                        <th className='w-160px min-w-160px'>Brand</th>
                        <th className='w-160px min-w-160px'>Loaded Out Stock Qty</th>
                        <th className='w-160px min-w-160px'>Returned Stock Qty</th>
                        <th className='w-160px min-w-160px'>Return Rate (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {returns.map((item: any) => {
                        return (
                          <>
                            <tr>
                              <td>{checkEmpty(item.product_sku_name)}</td>
                              <td>{checkEmpty(item.group_name)}</td>
                              <td>{checkEmpty(item.brand_name)}</td>
                              <td>
                                {!isEmpty(item.loaded_out_stock_qty)
                                  ? `${
                                      numberFormat(item.loaded_out_stock_qty).displayFormat
                                    } ${checkEmpty(
                                      measurementLabel(
                                        item.fix_size
                                          ? item.material_name
                                          : item.approx_weight > 0
                                          ? item.material_name
                                          : item.measurement_unit
                                      ),
                                      ''
                                    )}`
                                  : '-'}
                              </td>
                              <td>
                                {!isEmpty(item.returned_stock_qty)
                                  ? `${
                                      numberFormat(item.returned_stock_qty).displayFormat
                                    } ${checkEmpty(
                                      measurementLabel(
                                        item.fix_size
                                          ? item.material_name
                                          : item.approx_weight > 0
                                          ? item.material_name
                                          : item.measurement_unit
                                      ),
                                      ''
                                    )}`
                                  : '-'}
                              </td>

                              <td>
                                {isEmpty(item.loadout_return_rate_percent) ? (
                                  '-'
                                ) : (
                                  <>
                                    {
                                      numberFormat(item.loadout_return_rate_percent, true)
                                        .displayFormat
                                    }
                                    {'%'}
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          <div className='card mb-5'>
            <div className='card-header border border-left-0 border-top-0 border-right-0 border-secondary py-2 px-7 align-items-center'>
              <h3 className='fw-bolder'>Customers</h3>
            </div>

            <div className='p-3 mt-3'>
              <div className='row px-3'>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>New Customers</p>
                  <div>
                    {checkEmpty(numberFormat(summary.new_verified_customers).displayFormat)}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>Total Registered Customers</p>
                  <div>
                    {checkEmpty(numberFormat(summary.total_registered_customers).displayFormat)}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>No. of Customers did Orders</p>
                  <div>
                    {checkEmpty(numberFormat(summary.order_did_by_customers).displayFormat)}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>No. of Customers Used Pay Later</p>
                  <div>
                    {checkEmpty(
                      numberFormat(summary.pay_later_payments_by_customers).displayFormat
                    )}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>No. of Customers Used Credit</p>
                  <div>
                    {checkEmpty(numberFormat(summary.credit_used_by_customers).displayFormat)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-5'>
            <div className='card-header border border-left-0 border-top-0 border-right-0 border-secondary py-2 px-7 align-items-center'>
              <h3 className='fw-bolder'>Credits</h3>
            </div>

            <div className='p-3 mt-3'>
              <div className='row px-3'>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>No. of Open Credits</p>
                  <div>
                    {checkEmpty(
                      numberFormat(summary.total_no_of_open_credits_records).displayFormat
                    )}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>No. of Overdue Credits</p>
                  <div>
                    {checkEmpty(
                      numberFormat(summary.total_no_of_overdue_credits_records).displayFormat
                    )}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>No. of Credit Collections</p>
                  <div>
                    {checkEmpty(
                      numberFormat(summary.total_collected_credits_records).displayFormat
                    )}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>Open Credit Amount</p>
                  <div>
                    {isEmpty(summary.total_open_credit_amount)
                      ? '-'
                      : numberFormatWithCode(summary.total_open_credit_amount).displayFormat}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>Overdue Credit Amount</p>
                  <div>
                    {isEmpty(summary.total_no_of_overdue_credits_amount)
                      ? '-'
                      : numberFormatWithCode(summary.total_no_of_overdue_credits_amount)
                          .displayFormat}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>Credit Collection Amount</p>
                  <div>
                    {isEmpty(summary.total_collected_credits_amount)
                      ? '-'
                      : numberFormatWithCode(summary.total_collected_credits_amount).displayFormat}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-5'>
            <div className='card-header border border-left-0 border-top-0 border-right-0 border-secondary py-2 px-7 align-items-center'>
              <h3 className='fw-bolder'>Pay Later Today</h3>
            </div>

            <div className='p-3 mt-3'>
              <div className='row px-3'>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>No. of Pay Later Orders</p>
                  <div>
                    {checkEmpty(
                      numberFormat(summary.total_pay_later_payments_records).displayFormat
                    )}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>No. of Recovered Payments</p>
                  <div>
                    {checkEmpty(
                      numberFormat(summary.total_pay_later_payments_recovered_payments_records)
                        .displayFormat
                    )}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>No. of Carry Forwarded Payments</p>
                  <div>
                    {checkEmpty(
                      numberFormat(
                        summary.total_pay_later_payments_to_carry_forwarded_payments_records
                      ).displayFormat
                    )}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>Pay Later Amount</p>
                  <div>
                    {isEmpty(summary.total_pay_later_payments_amount)
                      ? '-'
                      : numberFormatWithCode(summary.total_pay_later_payments_amount).displayFormat}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>Recovered Amount</p>
                  <div>
                    {isEmpty(summary.total_pay_later_payments_recovered_amount)
                      ? '-'
                      : numberFormatWithCode(summary.total_pay_later_payments_recovered_amount)
                          .displayFormat}
                  </div>
                </div>
                <div className='col-4 p-3 mb-3'>
                  <p className='text-muted'>Carry Forwarded Amount</p>
                  <div>
                    {isEmpty(summary.total_pay_later_payments_carry_forwarded_amount)
                      ? '-'
                      : numberFormatWithCode(
                          summary.total_pay_later_payments_carry_forwarded_amount
                        ).displayFormat}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    warehouseId: state.summary.warehouseId,
    date: state.summary.date,
  }
}

export default connect(mapStateToProps, null)(SummaryReport)
