import { FC } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { toAbsoluteUrl } from "../../../helpers"
import EaFruitsLogout from "../../../redux/action/logout/logoutAction"

const HeaderUserMenu: FC = (props: any) => {
  const img = process.env.PUBLIC_URL + "/media/avatars/img_user.png"
  const isImpersonationLogin = localStorage.getItem('isImpersonationLogin')
  const { image, fullName, type } = props.profile

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg text-dark menu-gray-900 font-13 pt-4 w-200px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-2 pt-1 pb-3'>
            <div className='symbol symbol-36px me-5'>
              {image == null ? (
                <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Eafruits' />
              ) : (
                <img alt='' src={image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = `${img}`
                  }} />
              )}
            </div>
            <div className='d-flex flex-column'>
              <div className='fw-bolder text-break d-flex align-items-center font-13 fw-500'>{fullName}</div>
              <span className='text-muted font-13'>
                {type == 'SA' && 'Super Admin'}
                {type == 'WM' && 'Branch Manager'}
                {type == 'OO' && 'Operation Officer'}
                {type == 'LO' && 'Logistics Officer'}
                {type == 'TS' && 'Tele Sale'}
              </span>
            </div>
          </div>
        </div>
        <div className='separator my-0'></div>
        <div className='menu-item'>
          <Link to={'/profile/myprofile'} className='menu-link px-5 rounded-0'>
            My Account
          </Link>
        </div>
        <div className='separator my-0'></div>

        {/* change password and signout option is hidden while ImpersonationLogin is active. */}
        {isImpersonationLogin !== 'true' && <>
          <div className='menu-item'>
            <a
              href='#'
              data-bs-toggle={'modal'}
              data-bs-target={'#ea_modal_change_password'}
              className='menu-link px-5 rounded-0'
            >
              Change Password
            </a>
          </div>
          <div className='separator my-0'></div>
          <div className='menu-item'>
            <a onClick={EaFruitsLogout} className='menu-link px-5 rounded-0'>
              Sign Out
            </a>
          </div>
        </>}

      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    loginDetails: state.login.loginResponse,
    profile: state.profile.profile,
  }
}

export default connect(mapStateToProps, null)(HeaderUserMenu)
