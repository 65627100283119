import React from "react"
import { googleApi, mapZoom } from "../../../../../_eaFruitsDms/helpers"
import MapWithAKmlLayer from "../../../../../_eaFruitsDms/layout/components/eaMap/eaMap"

const TSAreaCoverage = (props: any) => {
    const { kml_file, latitude, longitude, } = props.mapDetails
    const { Warehouse_Boundary_Region } = props.WarehouseArea

    return (
        <>
            <div className='card mb-5 mb-xl-10' >
                <div className='card-body py-5'>
                    <div className="row">
                        <div className="col-md-7">
                            {props.warehouseType === "DistributionCenter" ? null :
                                <div className='row mb-6'>
                                    <div className='col-lg-12 font-14 text-muted'>Warehouse Boundary by Region</div >
                                    <div className='col-lg-12'>
                                        <span className='font-14 text-dark'>
                                            {Warehouse_Boundary_Region}
                                        </span>
                                    </div>
                                </div>}
                            <div className='row mb-6'>
                                <div className='col-lg-12 font-14 text-muted'>Distribution Boundary by Districts &gt; Wards</div>
                                <div className='col-lg-12'>
                                    <span className='font-14 text-dark'>
                                        {/* {Warehouse_Boundary_Districts == 0 ? "—" : Warehouse_Boundary_Districts + " " + ">" + " " + props.s_ward.name} */}

                                        {
                                            props.getWardsDist.map((item: any) => {
                                                let name = item.dist;
                                                let wards = item.wards
                                                return (
                                                    <>
                                                        {wards.length !== 0 ?
                                                            <div>{name} {">"}
                                                                {
                                                                    wards.map((item: any, index: any) => (
                                                                        <span> {index ? "," : ""} {item}</span>
                                                                    ))
                                                                }
                                                            </div> : null}
                                                    </>
                                                )
                                            })
                                        }
                                    </span>
                                </div>
                            </div>
                            {/* <div className='row mb-6'>
                                <div className='col-lg-12'>
                                    <a href="#" className='btn btn-primary btn-sm'>Update Area</a>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-5">
                            {
                                latitude !== "" &&
                                <MapWithAKmlLayer
                                    googleMapURL={googleApi}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    url={kml_file}
                                    KMLfiles={props.KMLfiles}
                                    zoom={mapZoom}
                                    center={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                                    isMarkerShown
                                    warehouseType={props.warehouseType}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TSAreaCoverage
