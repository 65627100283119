import React from "react"
import tenantConfiguration from "../../../../../TenantVariables"

const TSWarehouseOverview = (props: any) => {
  const {warehouseType, warehouseAddress, generalManager} = props.overView

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-5'>
          <div className='row mb-6'>
            <label className='col-lg-12 font-14 text-muted'>Warehouse Type</label>
            <div className='col-lg-12'>
              <span className='font-14 text-dark'>{warehouseType}</span>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-12 font-14 text-muted'>Street Address</label>
            <div className='col-lg-12'>
              <span className='font-14 text-dark'>{warehouseAddress}</span>
            </div>
          </div>
          {warehouseType === 'Headquarter' && (
            <>
              <div className='row mb-6'>
                <label className='col-lg-12 font-14 text-muted'>Branch Manager</label>
                <div className='col-lg-12'>
                  <span className='font-14 text-dark'>
                    {generalManager.first_name + ' ' + generalManager.last_name}
                  </span>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-12 font-14 text-muted'>Mobile Number</label>
                <div className='col-lg-12'>
                  <span className='font-14 text-dark'>
                    {generalManager.phone_no && tenantConfiguration.baseCountryCode}{' '}
                    {generalManager.phone_no}
                  </span>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-12 font-14 text-muted'>Email</label>
                <div className='col-lg-12'>
                  <span className='font-14 text-dark'>{generalManager.email}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default TSWarehouseOverview
