import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  fetchAsyncDropdown,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
  toAbsoluteUrl,
} from '../../../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {getRequest, patchRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {PageTitle} from '../../../../../../_eaFruitsDms/layout/core'
import {AsyncPaginate} from 'react-select-async-paginate'
import clsx from 'clsx'
import usePermission from '../../../../../../_eaFruitsDms/helpers/components/usePermission'
import InfoIcon from '../../../../../../_eaFruitsDms/layout/components/header/infoIcon'

type Props = {
  className?: string
}

const OrderLimitAccessDetails: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const location = useLocation()
  const {state}: any = location
  const WMHeaderTitle = {
    title: 'Order Limit Access Requests',
    subTitle: isEmpty(state) || isEmpty(state.id) ? '-' : `#${state.id}`,
  }
  const navigate = useNavigate()
  const warehouseId = localStorage.getItem('w_id')
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const permission = usePermission('order_limit_access_requests')

  const [orderLimit, setOrderLimit] = useState<any>(null)
  const [showModal, setShowModal] = useState(false)
  const [fetchingModal, setFetchingModal] = useState(false)
  const [submit, setSubmit] = useState('Submit')
  const [orderLimitId, setOrderLimitId] = useState<any>('')
  const [isFetching, setIsFetching] = useState(true)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [reason, setReason] = useState({label: 'Select', value: ''})
  const [rejectReasonOffSet, setRejectReasonOffSet] = useState<any>('')
  const [rejectReasonOption, setRejectReasonOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [reasonNote, setReasonNote] = useState('')
  const [validation, setValidation] = useState({reason: '', reasonNote: ''})
  const [isDisable, setDisable] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (isEmpty(state) || isEmpty(state.id)) {
      navigate('/credit-issues')
    }

    if (!isEmpty(state) && !isEmpty(state.id)) {
      state.id && setOrderLimitId(state.id)
    } else {
      navigate('/credit-issues')
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(orderLimitId)) {
      fetchOrderLimitDetails()
    }
  }, [orderLimitId])

  const fetchOrderLimitDetails = async () => {
    setIsFetching(true)
    const response = await getRequest(
      `${apiBaseURL()}preorder/order-limit-requests/${orderLimitId}`,
      true
    )

    if (response) {
      setOrderLimit(response)
    }

    setIsFetching(false)
  }

  const submitReason = async (type: string) => {
    const url = `${apiBaseURL()}preorder/order-limit-requests/${orderLimitId}/update`

    if (type === 'approve') {
      setDisable(true)

      const body = {
        status: 'approved',
        reason: null,
        reason_note: null,
      }
      await patchRequest(url, body, true)
      setMessage('Order limit request is approved')
      localStorage.setItem('limitStatus', 'true')
      localStorage.setItem('requestStatus', 'approved')
      navigate('/customers/order-limit-access-request')
      setDisable(false)
    }

    if (type === 'reject') {
      setShowModal(true)
    }

    if (type === 'rejectSubmitReason') {
      let updateValidation = {...validation}
      let valid = true

      if (isEmpty(reason.value)) {
        updateValidation.reason = 'is-invalid'
        valid = false
      }

      if (reason.value === '-' && isEmpty(reasonNote)) {
        updateValidation.reasonNote = 'is-invalid'
        valid = false
      }

      setValidation(updateValidation)

      if (valid) {
        const body = {
          status: 'rejected',
          reason: reason.value === '-' ? null : reason.value,
          reason_note: reason.value === '-' ? reasonNote : null,
        }

        setSubmit('Please Wait')
        await patchRequest(url, body, true)
        localStorage.setItem('limitStatus', 'true')
        localStorage.setItem('requestStatus', 'rejected')
        navigate('/customers/order-limit-access-request')
        setSubmit('Submit')
        isClear()
      }
    }
  }

  const isClear = () => {
    setShowModal(false)
    setReason({label: 'Select', value: ''})
    setReasonNote('')
    setValidation({reason: '', reasonNote: ''})
  }

  const fetchReason = async (search: any) => {
    const response = await fetchAsyncDropdown(
      rejectReasonOffSet,
      setRejectReasonOffSet,
      rejectReasonOption,
      setRejectReasonOption,
      `master/options-master/?type=order_limit_request_reason`,
      search,
      setSearchSelect,
      'option'
    )

    response.options.push({
      label: 'Other',
      value: '-',
    })

    return response
  }

  return (
    <>
      <PageTitle breadcrumbs={[WMHeaderTitle]}>
        {intl.formatMessage({id: orderLimit ? `#${orderLimit.ref_no}` : '-'})}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => setIsAlertSuccess(false)}
        show={IsAlertSuccess}
        message={message}
      />

      {isFetching ? (
        <FetchingRecords />
      ) : (
        <>
          <div className='card mb-5 mb-xl-5'>
            <div className='card-header border-0 py-2 px-7 align-items-center'>
              <div className='card-title my-0'>
                <span className='fw-bold font-17 mb-1 me-5'>
                  Order Limit Access Requests Details
                </span>
                <span className='font-14 text-dark'>
                  {orderLimit && isEmpty(orderLimit.status) ? (
                    '-'
                  ) : (
                    <>
                      <span>
                        {orderLimit.status === 'pending' && (
                          <span className='cursor-pointer badge badge-secondary text-dark'>
                            Pending
                          </span>
                        )}

                        {orderLimit.status === 'approved' && (
                          <span className='cursor-pointer badge badge-light-success text-dark'>
                            Approved
                          </span>
                        )}

                        {orderLimit.status === 'cancelled' && (
                          <span className='cursor-pointer badge badge-secondary text-dark'>
                            Cancelled
                          </span>
                        )}

                        {orderLimit.status === 'rejected' && (
                          <span className='cursor-pointer badge badge-light-danger text-dark'>
                            Rejected
                          </span>
                        )}
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
            <div className='card-body pt-2 mt-1 pb-5'>
              <div className='row'>
                <div className='col-sm-12 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Request Id</label>
                  <div>
                    <span className='font-14'>
                      {orderLimit && !isEmpty(orderLimit.ref_no) ? `#${orderLimit.ref_no}` : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {orderLimit && !isEmpty(orderLimit.customer_name)
                        ? orderLimit.customer_name
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Request by</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {orderLimit && !isEmpty(orderLimit.requested_by_name)
                        ? orderLimit.requested_by_name
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Response by</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {orderLimit &&
                      !isEmpty(orderLimit.action_by_name) &&
                      !isEmpty(orderLimit.action_by_type)
                        ? `${
                            orderLimit.action_by_name
                          } (${orderLimit.action_by_type.toUpperCase()})`
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>
                    <div className='d-flex'>
                      <div>Order Limits </div>
                      <InfoIcon
                        placement='top'
                        message='This is default or specially granted "Per Day Order Limit" for the Customer account.'
                      />
                    </div>
                  </label>
                  <div>
                    <span className='font-14 text-dark'>
                      {orderLimit && isEmpty(orderLimit.order_limit)
                        ? '-'
                        : numberFormat(orderLimit.order_limit).displayFormat}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>
                    {' '}
                    <div className='d-flex'>
                      <div>Total Orders </div>
                      <InfoIcon
                        placement='top'
                        message='This is already "Placed Orders Count" for the Day, for the Customer account.'
                      />
                    </div>
                  </label>
                  <div>
                    <span className='font-14 text-dark'>
                      {orderLimit && isEmpty(orderLimit.total_order)
                        ? '-'
                        : numberFormat(orderLimit.total_order).displayFormat}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Reject Reason</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {orderLimit && isEmpty(orderLimit.reason)
                        ? isEmpty(orderLimit.reason_note)
                          ? '-'
                          : orderLimit.reason_note
                        : isEmpty(orderLimit.reason)
                        ? '-'
                        : orderLimit.reason}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {orderLimit && orderLimit.orders && orderLimit.orders.length > 0 && (
              <div className='mb-5 mb-xl-5'>
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                  <div className='card-title my-0'>
                    <span className='fw-bold font-17 mb-1'>Order History</span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card-body pt-2 mt-1 pb-5'>
                      <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                        <thead>
                          <tr className='text-muted fw-500'>
                            <th className='min-w-80px'>Order ID</th>
                            <th className='min-w-80px'>Product Count</th>
                            <th className='min-w-100px'>Payable Amount</th>
                            <th className='min-w-150px'>Received Amount</th>
                            <th className='min-w-150px'>Due Amount</th>
                            <th className='min-w-120px'>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {orderLimit &&
                            orderLimit.orders &&
                            orderLimit.orders.map((item: any) => {
                              return (
                                <tr>
                                  <td>{isEmpty(item.order_no) ? '-' : `#${item.order_no}`}</td>
                                  <td>
                                    {item.product_count
                                      ? numberFormat(item.product_count).displayFormat
                                      : '-'}
                                  </td>
                                  <td>
                                    {item.total_payable
                                      ? numberFormatWithCode(item.total_payable).displayFormat
                                      : '-'}
                                  </td>
                                  <td>
                                    {item.total_received_amount
                                      ? numberFormatWithCode(item.total_received_amount)
                                          .displayFormat
                                      : '-'}
                                  </td>
                                  <td>
                                    {item.outstanding_amount
                                      ? numberFormatWithCode(item.outstanding_amount).displayFormat
                                      : '-'}
                                  </td>
                                  <td>
                                    {isEmpty(item.status) ? (
                                      '-'
                                    ) : (
                                      <>
                                        {item.status === 'start_delivery' && (
                                          <span className='cursor-pointer badge badge-light-warning text-dark'>
                                            Delivery In Process
                                          </span>
                                        )}

                                        {item.status === 'delivered' && (
                                          <span className='cursor-pointer badge badge-light-success text-dark'>
                                            Delivered
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {permission.write &&
              orderLimit &&
              !isEmpty(orderLimit.status) &&
              ['pending'].includes(orderLimit.status) &&
              checkUserType(['wm']) && (
                <div className='d-flex m-5 justify-content-center'>
                  <button
                    type='button'
                    onClick={() => submitReason('reject')}
                    className='btn btn-secondary me-3'
                    disabled={isDisable}
                  >
                    Reject
                  </button>
                  <button
                    type='button'
                    onClick={() => submitReason('approve')}
                    className='btn btn-primary'
                    disabled={isDisable}
                  >
                    {isDisable ? 'Please Wait' : 'Approve'}
                  </button>
                </div>
              )}
          </div>
        </>
      )}

      <Modal
        show={showModal}
        onHide={() => {
          isClear()
        }}
        centered
        backdrop='static'
        dialogClassName='h-auto mw-570px'
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Add Reject Reason</h2>
          </Modal.Title>
          <div
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            onClick={() => {
              isClear()
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          {fetchingModal ? (
            <FetchingRecords />
          ) : (
            <div className='row'>
              <div className='col-md-12 mb-3'>
                <label htmlFor='' className='form-label'>
                  Reason
                </label>
                <AsyncPaginate
                  loadOptions={fetchReason}
                  onChange={(e: any) => {
                    setReason(e)
                    setValidation({...validation, reason: ''})
                  }}
                  isSearchable
                  placeholder='Select'
                  isDisabled={false}
                  value={reason}
                  className={`react-select-container ${validation.reason}`}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>

              {reason.label === 'Other' && (
                <div className='col-md-12'>
                  <label className='form-label'>Reason Note</label>
                  <textarea
                    className={clsx('form-control', validation.reasonNote)}
                    rows={4}
                    name='reasonNote'
                    value={reasonNote}
                    onChange={(e: any) => {
                      setReasonNote(e.target.value)
                      setValidation({...validation, reasonNote: ''})
                    }}
                  ></textarea>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn btn-secondary me-3'
              onClick={() => {
                isClear()
              }}
              disabled={submit !== 'Submit'}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                submitReason('rejectSubmitReason')
              }}
              disabled={submit !== 'Submit'}
            >
              {submit}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default OrderLimitAccessDetails
