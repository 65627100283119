import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  KTSVG,
  staticSearch
} from "../../../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage";
import { getRequest, postRequest, putRequest } from "../../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader";
import EaModal from "../../../../../_eaFruitsDms/layout/components/modal/eaModal";
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import CustomSunEditor from "../../../../../_eaFruitsDms/layout/components/sunEditor/CustomSunEditor";
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title";
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core";
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce";

type Props = {
  className: string
}
export const JobTypes: any = [
  {
    label: 'FullTime',
    value: 'FullTime',
  },
  {
    label: 'PartTime',
    value: 'PartTime',
  },
]
const CurrentOpenings: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const initialRow = {
    title: '',
    subtitle: '',
    location: '',
    jobtype: '',
    jobdiscription: '',
    internship: '',
    is_active: '',
  }
  const initialValidation = {
    title: '',
    subtitle: '',
    location: '',
    jobtype: '',
    jobdiscription: '',
    internship: '',
    is_active: '',
  }

  const [currentOpeningsList, setCurrentOpeningsList] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(10)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('')
  const [currentOpenings, setCurrentOpenings] = useState<any>(initialRow)
  const [currentOpeningsValidation, setCurrentOpeningsValidation] = useState<any>(initialValidation)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState('Submit')
  const [disableBtn, setDisable] = useState(false)
  const [accessSts, setAccessSts] = useState<any>(true)
  const [description, setDescription] = useState('')
  const [descriptionValidation, setDescriptionValidation] = useState('')
  const [permission, setPermission] = useState(defaultPermission)

  const debounceSearch = useDebounce(search, 500);

  const cmsOpenings: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Career',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    setTimeout(() => fetchCurrentOpeningsList().then(), timer)
  }, [currentOffset, debounceSearch, count, currentOpeningsList.length])

  useEffect(() => {
    localStorage.removeItem('openingsId')
    if (localStorage.getItem('type')) {
      setMessageHandler(`success${localStorage.getItem('type')}`)

      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)

      localStorage.removeItem('type')
    }
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("cms")
    setPermission(res)
  }

  const fetchCurrentOpeningsList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}career/CurrentOpenings/get?&limit=${limit}&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    setCurrentOpeningsList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }
  const fetchJobTypes = async (search?: any) => {
    let options = JobTypes

    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options,
      hasMore: false,
    }
  }
  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChangeSearch = (e: any) => {
    let inputValue = e.target ? e.target.value : e.value
    setSearch(inputValue)
  }

  const singledata = async (item: any) => {
    setId(item.id)
    const API = `${apiBaseURL()}career/CurrentOpenings/${item.id}/`
    const response = await getRequest(API, true)

    const formDetail = response
    let updatedData: any = { ...currentOpenings }
    updatedData['title'] = formDetail.title
    updatedData['subtitle'] = formDetail.subtitle
    updatedData['location'] = formDetail.location
    updatedData['jobtype'] = formDetail.jobtype
    updatedData['jobdiscription'] = formDetail.jobdiscription
    updatedData['internship'] = formDetail.internship
    updatedData['is_active'] = formDetail.is_active
    setCurrentOpenings(updatedData)
  }

  const clearFunctionAdd = () => {
    setCurrentOpenings(initialRow)
    setCurrentOpeningsValidation('')
    setErrorMessage('')
    setDescriptionValidation('')
    setDescription('')
    setIsLoading('Submit')
    setDisable(false)
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value

    setCurrentOpeningsValidation({ ...currentOpeningsValidation, [inputName]: '' })

    switch (inputName) {
      case 'title':
        setCurrentOpenings({ ...currentOpenings, ['title']: inputValue })
        break
      case 'subtitle':
        setCurrentOpenings({ ...currentOpenings, ['subtitle']: inputValue })
        break
      case 'location':
        setCurrentOpenings({ ...currentOpenings, ['location']: inputValue })
        break
      case 'jobtype':
        setCurrentOpenings({ ...currentOpenings, ['jobtype']: inputValue })
        break
      case 'jobdiscription':
        setCurrentOpenings({ ...currentOpenings, ['jobdiscription']: inputValue })
        break
      case 'internship':
        setCurrentOpenings({ ...currentOpenings, ['internship']: e.target.checked })
        break
    }
  }

  const validateForm = () => {
    let validations = { ...currentOpeningsValidation }
    let isValid = true
    if (currentOpenings.title.trim() == '') {
      validations.title = 'is-invalid'

      isValid = false
    }
    if (currentOpenings.subtitle.trim() == '') {
      validations.subtitle = 'is-invalid'
      isValid = false
    }
    if (currentOpenings.location.trim() == '') {
      validations.location = 'is-invalid'
      isValid = false
    }
    if (currentOpenings.jobdiscription.trim() == '') {
      validations.jobdiscription = 'is-invalid'
      isValid = false
    }
    // if (currentOpenings.jobtype.trim() == '') {
    //   validations.jobtype = 'is-invalid'
    //   isValid = false
    // }
    if (description === '' || description === '<p><br></p>') {
      setDescriptionValidation('is-invalid')
      isValid = false
    }
    setCurrentOpeningsValidation(validations)
    return isValid
  }

  const apiCall = async () => {
    let OpeningsData = new FormData()
    const currentOpeningsInfo = { ...currentOpenings }

    currentOpeningsInfo.jobdiscription = description

    Object.entries(currentOpeningsInfo).map((object: any) => {
      if (object[0] == 'profile_picture') {
        if (object[1] != null && typeof object[1] != 'string') {
          return OpeningsData.append(object[0], object[1])
        }
      } else {
        return OpeningsData.append(object[0], object[1])
      }
    })
    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }

    if (requestHandler === 'add') {
      setDisable(true)
      setIsLoading('Please Wait...')
      postRequest(`${apiBaseURL()}career/CurrentOpenings/create`, OpeningsData, true).then(
        (response: any) => {
          if (response.status === 201) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            setIsLoading('Submit')
            setDisable(false)
            fetchCurrentOpeningsList()
            $('#closeEaModalBlogsCategory').trigger('click')
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
          }
          if (notify.failed) {
            if (notify.errorField == 'name') {
              setErrorMessage(
                'Your added data is already exist in this system! Please try again with unique data.'
              )
            }
          }
        }
      )
    } else {
      setDisable(true)
      setIsLoading('Please Wait...')
      putRequest(`${apiBaseURL()}career/CurrentOpenings/${id}/`, OpeningsData, true).then(
        (response: any) => {
          if (response.status === 200) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            setIsLoading('Submit')
            setDisable(false)
            fetchCurrentOpeningsList()
            $('#closeEaModalBlogsCategory').trigger('click')
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
          }
          if (notify.failed) {
            if (notify.errorField == 'name') {
              setErrorMessage(
                'Your added data is already exist in this system! Please try again with unique data.'
              )
            }
            // setIsAlertFailed(true)
          }
        }
      )
    }
  }

  const submitFunction = () => {
    const isValid = validateForm()
    if (isValid) {
      apiCall()
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const handleDescription = (e: any) => {
    setDescriptionValidation('')
    setDescription(e)
  }
  return (
    <>
      <EATitle title='Current Openings - ' />
      <div>
        <PageTitle breadcrumbs={cmsOpenings}>
          {intl.formatMessage({ id: 'Current Openings' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('CMS_CAREER_CURRENT_OPENINGS.ADD')
              : messageHandler === 'successEdit'
                ? FormatMessage('CMS_CAREER_CURRENT_OPENINGS.UPDATE')
                : messageHandler === 'successDelete' && FormatMessage('CMS_BLOG_AUTHORS.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} />
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    // isModal={true}
                    // dataTarget='#ea_modal_create_currentOpeningss'
                    btnName='Add Current Openings'
                    // onClick={() => {
                    //   setRequest('add')
                    //   setMessageHandler('successAdd')
                    // }}
                    onClick={() => {
                      navigate('add-openings')
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {currentOpeningsList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px py-2'>Opening Title</th>
                          <th className='min-w-100px py-2'>Sub Title</th>
                          <th className='min-w-100px py-2'>Location</th>
                          <th className='min-w-100px py-2'>Job Type</th>
                          <th className='min-w-100px py-2'>Status</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {currentOpeningsList.map((item: any) => {
                          return (
                            <tr>
                              <td>{item.title}</td>
                              <td>{item.subtitle}</td>
                              <td>{item.location}</td>
                              <td>{item.jobtype == 'FullTime' ? 'Full Time' : 'Part Time'}</td>
                              <td>
                                {item.is_active == true ? (
                                  <>
                                    <span className='badge badge-light-success text-dark'>
                                      Active
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    <span className='badge badge-light-danger text-dark'>
                                      Inactive
                                    </span>
                                  </>
                                )}
                              </td>
                              <td className='text-end'>
                                {permission.write && (
                                  <a
                                    href='javascript:void(0)'
                                    className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                    // data-bs-toggle='modal'
                                    // data-bs-target='#ea_modal_create_currentOpeningss'
                                    // onClick={(event) => {
                                    //   singledata(item)
                                    //   setRequest('edit')
                                    //   setMessageHandler('successEdit')
                                    // }}
                                    onClick={() => {
                                      navigate('add-openings')
                                      localStorage.setItem('openingsId', item.id)
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
            </div>
          </div>
        </div>
        <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${currentOpeningsList.length == 0 ? 0 : selected * 10 + 1} to ${currentOpeningsList.length == 0
                ? 0
                : currentOpeningsList.length < 10
                  ? count
                  : (selected + 1) * 10
                } of ${currentOpeningsList.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                count={currentOpeningsList.length == 0 ? 0 : count}
                selected={selected}
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
              />
            </div>
          </div>
        </div>
      </div>
      <EaModal
        id='ea_modal_create_currentOpeningss'
        modalTitle={requestHandler === 'add' ? 'Add Current Openings' : 'Update Current Openings'}
        onSubmit={submitFunction}
        cancel={clearFunctionAdd}
        actionBtnName={isLoading}
        closeAuto='closeEaModalBlogsCategory'
        btnDisabled={disableBtn}
      >
        <div className='mb-4'>
          <label className='form-label'>Opening Title</label>
          <input
            type='text'
            className={clsx('form-control', currentOpeningsValidation.title)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e, 'title')}
            name='title'
            value={currentOpenings && currentOpenings.title}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>Sub Title</label>
          <input
            type='text'
            className={clsx('form-control', currentOpeningsValidation.subtitle)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e, 'subtitle')}
            name='subtitle'
            value={currentOpenings && currentOpenings.subtitle}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>Location</label>
          <input
            type='text'
            className={clsx('form-control', currentOpeningsValidation.location)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e, 'location')}
            name='location'
            value={currentOpenings && currentOpenings.location}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'> Job Description</label>
          <CustomSunEditor
            data={currentOpenings.jobdiscription}
            handleChange={handleDescription}
            fieldName='story_description'
            validationTriggered={descriptionValidation}
            message='Job Description Field is required.'
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'> Job Type</label>
          <AsyncPaginate
                    
            loadOptions={fetchJobTypes}
            isSearchable
            name='jobtype'
            placeholder='Select'
            className={clsx('react-select-container w-100', currentOpeningsValidation.jobtype)}
            classNamePrefix='react-select'
            value={JobTypes.find((option: any) => option.value == currentOpenings.jobtype) || ''}
            onChange={(e: any) => handleChange(e, 'jobtype')}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className={clsx(
                'form-check-input widget-13-check',
                currentOpeningsValidation.internship
              )}
              name='internship'
              checked={currentOpenings.internship}
              type='checkbox'
              onChange={(e: any) => handleChange(e, 'internship')}
              value=''
            />
            <label className='form-check-label'>Interndhip</label>
          </div>
        </div>

        <div className='mb-4'>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <label className='form-check-label me-3'>Access Status</label>
            <input
              checked={currentOpenings.is_active}
              id='accessStatus'
              onChange={(e) => {
                let checkedEdit = $('#accessStatus').is(':checked')
                if (checkedEdit == true) {
                  setAccessSts(true)
                  setCurrentOpenings({ ...currentOpenings, ['is_active']: true })
                } else {
                  setAccessSts(false)
                  setCurrentOpenings({ ...currentOpenings, ['is_active']: false })
                }
              }}
              value={currentOpenings && currentOpenings.is_active}
              className='form-check-input h-30px w-50px'
              type='checkbox'
              name='layout-builder[layout][header][fixed][desktop]'
              defaultChecked={true}
            />
          </div>
        </div>

        <div className='mb-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  )
}

export default CurrentOpenings
