import moment from 'moment'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkEmpty,
  checkUserType,
  dateTimeFormat,
  getFirstWarehouse,
  getLoginWarehouse,
  isAdmin,
  isEmpty,
  KTSVG,
  numberFormatWithCode,
} from '../../../../_eaFruitsDms/helpers'
import {RouteFilter} from '../../../../_eaFruitsDms/helpers/components/RouteFilter'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {blobXlsRequest, getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import TooltipText from '../../../../_eaFruitsDms/layout/components/header/TooltipText'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import SkuThumbnail from '../../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'

const RouteBasedPriceSummaryReport = () => {
  const intl = useIntl()

  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Route Specific Price Summary Report',
  }

  const [currentOffSet, setCurrentOffSet] = useState<any>('0')
  const [displayLoader, setDisplayLoader] = useState<boolean>(true)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [warehouse, setWarehouse] = useState<any>({label: 'All Warehouse', value: ''})
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState<boolean>(false)
  const [routeSpecific, setRouteSpecific] = useState([])
  const [route, setRoute] = useState<any>({
    label: 'Select',
    value: '',
  })

  const debounceSearch = useDebounce(searchByName, 500)

  const fetchFirstWarehouse = async () => {
    const url = apiBaseURL() + `warehouse/list?limit=2&ordering=name`

    if (checkUserType(['sa'])) {
      const response = await getFirstWarehouse(url)

      if (response) {
        setWarehouse(response)
      }
    } else if (checkUserType(['wm'])) {
      setWarehouse(getLoginWarehouse())
    }
  }

  const fetchFirstRoute = async () => {
    const url = `${apiBaseURL()}routes/drop-down?ordering=name&limit=2&warehouse_id=${
      warehouse?.value
    }`

    const response = await getRequest(url, true)

    if (response?.results?.length > 0) {
      const data = response?.results[0]
      let concatName = data?.name ?? ''

      if (!isEmpty(data?.sales_team_name)) {
        concatName = (
          <span>
            {data.name} - <b>{data?.sales_team_name}</b>
          </span>
        )
      }

      setRoute({
        label: concatName,
        value: data?.id ?? '',
      })
    } else {
      setRouteSpecific([])
      setDisplayLoader(false)
    }
  }

  const fetchReportList = async () => {
    setDisplayLoader(true)

    let routeID = isEmpty(route.value) ? '' : route.value

    const response = await getRequest(
      `${apiBaseURL()}reports/route-specific-report?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&route_id=${routeID}&warehouse_id=${
        isEmpty(warehouse?.value) ? '' : warehouse?.value
      }`,
      true
    )

    if (response?.results) {
      setRouteSpecific(response?.results)
      setCount(response?.count)
    }

    setDisplayLoader(false)
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name

    setCurrentOffSet('')
    setSelected(0)

    switch (inputName) {
      case 'route':
        setRoute(e)
        break

      case 'warehouse':
        setWarehouse(e)

        isAdmin() &&
          setRoute({
            label: 'Select',
            value: '',
          })
        break

      case 'search':
        setSearchByName(e.target.value)
        break
    }
  }

  const downloadExcl = async () => {
    setDownloadButtonDisabled(true)

    const routeID = isEmpty(route.value) ? '' : route.value

    // Convert the JSX to a string
    const stringTest = route?.label?.props.children.reduce((acc: any, child: any) => {
      if (typeof child === 'string') {
        return acc + child
      } else if (typeof child === 'object' && child.props && child.props.children) {
        return acc + child.props.children
      }
      return acc
    }, '')

    // Remove all tags using a regular expression
    const cleanText = stringTest.replace(/<[^>]*>/g, '')

    await blobXlsRequest(
      `${apiBaseURL()}reports/route-specific-excel?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&route_id=${routeID}&warehouse_id=${
        isEmpty(warehouse?.value) ? '' : warehouse?.value
      }`,
      `Route Specific Price Summary Report - ${checkEmpty(warehouse?.label, '-')} - ${checkEmpty(
        cleanText,
        '-'
      )} #${dateTimeFormat(moment())}`,
      true,
      setDownloadButtonDisabled
    )
  }

  useEffect(() => {
    fetchFirstWarehouse()
  }, [])

  useEffect(() => {
    if (!isEmpty(warehouse?.value)) fetchFirstRoute()
  }, [warehouse])

  useEffect(() => {
    if (!isEmpty(warehouse?.value) && !isEmpty(route?.value)) {
      fetchReportList().then()
    }
  }, [debounceSearch, route, selected, currentOffSet, warehouse])

  return (
    <>
      <EATitle title='Route Specific Price Summary Report' />

      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Route Specific Price Summary Report'})}
      </PageTitle>

      <div>
        <div className='card'>
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={handleFilterChange} isDisable={displayLoader} />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                {isAdmin() && (
                  <WarehouseFilter
                    warehouse={warehouse}
                    handleFilterChange={handleFilterChange}
                    isDisabled={false}
                    allWarehouse={false}
                    className='my-1 me-3 w-200px'
                  />
                )}

                <RouteFilter
                  route={route}
                  isDisabled={
                    (isAdmin() && isEmpty(warehouse?.value)) || displayLoader ? true : displayLoader
                  }
                  handleFilterChange={handleFilterChange}
                  warehouse={isEmpty(warehouse?.value) ? '' : warehouse?.value}
                  allRoute={false}
                  className='my-1 me-3 w-220px'
                  salesTeamName={true}
                />

                <button
                  type='button'
                  className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center ml-4'
                  disabled={downloadButtonDisabled || isEmpty(route?.value)}
                  onClick={downloadExcl}
                >
                  <KTSVG
                    path='/media/icons/duotune/files/fil021.svg'
                    className='svg-icon-muted ic'
                  />
                  {downloadButtonDisabled ? 'Please Wait...' : 'Export'}
                </button>
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              {displayLoader ? (
                <FetchingRecords />
              ) : routeSpecific.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='w-200px min-w-200px'>Product SKU</th>
                      {/* <th className='w-180px min-w-180px'>Warehouse Name</th>
                      <th className='w-200px min-w-200px'>Route Name</th> */}
                      <th className='w-180px min-w-180px'>
                        <TooltipText
                          item={
                            <>
                              Wholesale Price <span className='fw-bolder'>(MP)</span>
                            </>
                          }
                          message='Warehouse Price'
                          placement='top'
                        />
                      </th>
                      <th className='w-160px min-w-160px'>
                        <TooltipText
                          item={
                            <>
                              Retail Price <span className='fw-bolder'>(MP)</span>
                            </>
                          }
                          message='Warehouse Price'
                          placement='top'
                        />
                      </th>
                      <th className='w-180px min-w-180px'>
                        <TooltipText
                          item={
                            <>
                              Wholesale Price <span className='fw-bolder'>(RP)</span>
                            </>
                          }
                          message='Route Price'
                          placement='top'
                        />
                      </th>
                      <th className='w-160px min-w-160px'>
                        <TooltipText
                          item={
                            <>
                              Retail Price <span className='fw-bolder'>(RP)</span>
                            </>
                          }
                          message='Route Price'
                          placement='top'
                        />
                      </th>
                      <th className='w-200px min-w-200px'>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routeSpecific.map((item: any) => {
                      return (
                        <>
                          <tr key={item.id}>
                            <td>
                              <SkuThumbnail
                                src={checkEmpty(item.product_thumbnail)}
                                skuName={checkEmpty(item.name)}
                              />
                            </td>
                            {/* <td>{checkEmpty(item.warehouse_name)}</td>
                            <td>{checkEmpty(item.route_name)}</td> */}
                            <td>
                              {isEmpty(item.warehouse_wholesale_price)
                                ? '-'
                                : numberFormatWithCode(item.warehouse_wholesale_price)
                                    .displayFormat}
                            </td>
                            <td>
                              {isEmpty(item.warehouse_retail_price)
                                ? '-'
                                : numberFormatWithCode(item.warehouse_retail_price).displayFormat}
                            </td>
                            <td>
                              {isEmpty(item.route_wholesale_price)
                                ? '-'
                                : numberFormatWithCode(item.route_wholesale_price).displayFormat}
                            </td>
                            <td>
                              {isEmpty(item.route_retail_price)
                                ? '-'
                                : numberFormatWithCode(item.route_retail_price).displayFormat}
                            </td>
                            <td
                              className='text-truncate'
                              style={{
                                maxWidth: 300,
                              }}
                            >
                              {checkEmpty(item.reason_note)}
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <CustomPaginate
          data={routeSpecific}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffSet}
          totalRecords={count}
          limit={limit}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default RouteBasedPriceSummaryReport
