import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_eaFruitsDms/helpers'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'

const Overview = () => {
  const intl = useIntl()
  const supportReqTitle = {
    title: 'Complaints',
    subTitle: 'My Assigned',
  }
  return (
    <>
      <PageTitle breadcrumbs={[supportReqTitle]}>
        {intl.formatMessage({id: 'My Assigned'})}
      </PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-5'>
          <div className='row'>
            <div className='col-sm-4 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Complaint ID</label>
              <div>
                <span className='font-14 text-dark'>#314234</span>
              </div>
            </div>
            <div className='col-sm-4 col-xl-5 mb-6'>
              <label className='font-14 text-muted mb-2'>Customer Name</label>
              <div>
                <span className='font-14 text-dark'>Nathaniel Paul</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Customer Address</label>
              <div>
                <span className='font-14 text-dark'>755G+PRW, Dar es Salaam, Tanzania</span>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>SR Name</label>
              <div>
                <span className='font-14 text-dark'>Mike Mendoza</span>
              </div>
            </div>
            <div className='col-sm-4 col-xl-5 mb-6'>
              <label className='font-14 text-muted mb-2'>Rating</label>
              <div>
                <span className='d-flex align-items-center me-5'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen003.svg'
                    className='text-warning me-1'
                  />
                  4.2
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Mapped Warehouse</label>
              <div>
                <span className='font-14 text-dark'>Distribution Center 1</span>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Mapped SR</label>
              <div>
                <span className='font-14 text-dark'>Warehouse 1</span>
              </div>
            </div>
            <div className='col-sm-4 col-xl-5 mb-6'>
              <label className='font-14 text-muted mb-2'>Review</label>
              <div>
                <span className='font-14 text-dark'>
                  Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of
                  a document{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Overview
