import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
// import { KTSVG, toAbsoluteUrl } from '../../../../../../_eaFruitsDms/helpers'
import { useLocation, useNavigate } from "react-router-dom"
import { DistrictCreate, RegionUpdate } from "../../../../../_eaFruitsDms/apiFunctions/master/master"
import { apiBaseURL, KTSVG } from "../../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage"
import ValidationHandler from "../../../../../_eaFruitsDms/helpers/components/validationHandler"
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import ErrorHandler from "../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import EaModal from "../../../../../_eaFruitsDms/layout/components/modal/eaModal"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import { getToken } from "../../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage"

type Props = {
  className: string
}

const RegionName: React.FC<Props> = ({ className }) => {
  // Authorization token
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  const navigate = useNavigate()
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Regions',
  }

  // all states
  const location: any = useLocation()
  const [districtList, setList] = useState<any>([])
  const [kmlFileNameDistrict, setKmlFileNameDistrict] = useState('Browse')
  const [kmlFileDistrict, setKmlFileDistrict] = useState('')
  const [districtName, setDistrictName] = useState('')
  const [districtValidation, setDistrictValidation] = useState('')
  const [regionId, setregionId] = useState('') // set_regionId
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [sKml, setSKml] = useState('')
  const [sKmlLink, setSKmlLink] = useState('#')
  const [kmlFileNameEdit, setKmlFileNameEdit] = useState('Browse')
  const [kmlFileEdit, setKmlFileEdit] = useState('')
  const [regionNameEdit, setRegionNameEdit] = useState('')
  const [regionValidationEdit, setRegionValidationEdit] = useState('')
  const [regionHeaderName, setRegionHeaderName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')

  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [messageTitle, setMessageTitle] = useState('')
  const [modalLoader, setModalLoader] = useState<any>(false)

  useEffect(() => {
    if (location.state === null) {
      navigate('/master/distribution-boundaries/regions')
    }
    getDistrict()
    RegionDetails()
  }, [])

  // get district list
  //request method for api implementation :- last change by kriti
  const getDistrict = async () => {
    await getRequest(
      `${apiBaseURL()}master/district/list?&region__id=${location.state.id}`,
      true
    ).then((res) => {
      setList(res.results)
    })
  }

  // get regions list
  //request method for api implementation :- last change by kriti
  const RegionDetails = async () => {
    setModalLoader(true)
    setMessageHandler('successAdd')
    setregionId(location.state.id)
    await getRequest(`${apiBaseURL()}master/region/${location.state.id}/`, true).then((res) => {
      setRegionHeaderName(res.name)
      setRegionNameEdit(res.name)
      if (res.kml_file !== null) {
        var filename = res.kml_file.substring(res.kml_file.lastIndexOf('/') + 1)
        setSKml(filename)
        setSKmlLink(res.kml_file)
      }
      setModalLoader(false)
    })
  }

  const clearFunctionDistrict = () => {
    // clear function
    setKmlFileNameDistrict('Browse')
    setKmlFileDistrict('')
    setDistrictName('')
    setDistrictValidation('')
  }

  const addFunctionDistrict = () => {
    if (districtName.trim() == '') {
      setDistrictValidation('is-invalid')
    } else {
      setDistrictValidation('')
    }

    if (districtName.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')

      DistrictCreate(districtName, regionId, kmlFileDistrict).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunctionDistrict()
          getDistrict()
          setIsAlertSuccess(true)
        }
        if (res.failed) {
          setIsAlertFailed(true)
        }
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        $('#ea_modal_close_add_District').trigger('click') // modal close
      })
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const selectKmlFileDistrict = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlFileNameDistrict(e.target.files[0].name)
      setKmlFileDistrict(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const setDistrictItem = () => {
    setMessageHandler('successEdit')
    if (location.state !== null) {
      setregionId(location.state.id)
    }
  }

  const clearFunctionEdit = () => {
    setKmlFileEdit('')
    setKmlFileNameEdit('Browse')
    setRegionNameEdit('')
    setErrorMessage('')
    setRegionValidationEdit('')
  }

  const selectKmlFileEdit = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlFileNameEdit(e.target.files[0].name)
      setKmlFileEdit(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const editFunction = () => {
    if (regionNameEdit.trim() == '') {
      setRegionValidationEdit('is-invalid')
    } else {
      setRegionValidationEdit('')
    }

    if (regionNameEdit.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      RegionUpdate(regionId, regionNameEdit, kmlFileEdit).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunctionEdit()
          RegionDetails()
          setIsAlertSuccess(true)
          setTimeout(() => {
            setIsAlertSuccess(false)
            setIsAlertFailed(false)
          }, 2000)
          $('#ea_modal_close_edit_region').trigger('click') // modal close
        }
        if (res.failed) {
          // setIsAlertFailed(true)
        }
        if (res.errorField == 'name') {
          setErrorMessage(
            'Your added region name already exists in this system! Please try again with a unique name.'
          )
        }
      })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[masterHeaderTitle]} backNavigate='/master/distribution-boundaries/regions'>{intl.formatMessage({ id: 'Regions' })}</PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('REGION.UPDATE')
            : FormatMessage('DISTRICT.ADD')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      {location.state === null ? '-' : regionHeaderName}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                    <span className='d-flex align-items-center me-6 mb-2'>
                      <KTSVG path='/media/icons/duotune/general/gen045.svg' className='me-1' />
                      Headquarter
                    </span>
                    <span className='d-flex align-items-center me-5 mb-2 '>
                      <KTSVG path='/media/icons/duotune/general/gen018.svg' className='me-1' />
                      Dar es Salaam
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span
                data-bs-toggle='modal'
                data-bs-target='#ea_modal_edit_region'
                onClick={RegionDetails}
                className='btn btn-secondary font-13 mx-3'
              >
                Edit
              </span>
              <span
                data-bs-toggle='modal'
                data-bs-target='#ea_modal_District_region'
                className='btn btn-primary font-13'
                onClick={setDistrictItem}
              >
                Add District
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='card'>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='ps-5'>District Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {districtList.map((item: any) => (
                      <tr key={item.id}>
                        <td className='ps-5'>{item.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-md-6'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='ps-5'>Region on Map</th>
                  </tr>
                </thead>
              </table>
              <p className='ps-5'>Map Goes to here...</p>
            </div>
          </div>
        </div>
      </div>

      {/* add district */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionDistrict}
        id='ea_modal_District_region'
        modalTitle='Add District'
        closeAuto='ea_modal_close_add_District'
        onSubmit={() => addFunctionDistrict()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            District Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', districtValidation)}
            placeholder='Type here...'
            value={districtName}
            onChange={(e) => {
              setDistrictName(e.target.value)
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setDistrictValidation(validation)
            }}
          />
        </div>
        <div className=''>
          <label className='form-label'>KML File</label>
          <label htmlFor='kml-file' className={clsx('form-control file-input')}>
            <span className='text-muted'>{kmlFileNameDistrict}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            onChange={selectKmlFileDistrict}
            id='kml-file'
          />
        </div>
      </EaModal>

      {/* updateModal */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionEdit}
        id='ea_modal_edit_region'
        modalTitle='Edit Region'
        closeAuto='ea_modal_close_edit_region'
        onSubmit={() => editFunction()}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Region Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', regionValidationEdit)}
            placeholder='Type here...'
            value={regionNameEdit}
            onChange={(e) => {
              setRegionNameEdit(e.target.value)
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setRegionValidationEdit(validation)
            }}
          />
        </div>
        <div className='position-relative'>
          <div className='d-flex justify-content-between'>
            <label className='form-label me-2'>KML File</label>
            <a
              href={sKmlLink}
              className='text-hover-primary text-decoration-underline text-hover-decoration-underline text-truncate'
              target='_blank'
            >
              {sKml}
            </a>
          </div>
          <label htmlFor='kml-file' className='form-control file-input'>
            <span className='text-muted'>{kmlFileNameEdit}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            onChange={selectKmlFileEdit}
            id='kml-file'
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  )
}

export default RegionName
