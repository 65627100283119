import moment from "moment"
import React from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import { useIntl } from "react-intl"
import Select from "react-select"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"

const brandoptions = [
    { value: 'allbrands', label: 'All Brands' },
    { value: 'brand1', label: 'Brand1' },
    { value: 'brand2', label: 'Brand2' },
    { value: 'brand3', label: 'Brand3' },
]

const categoryoptions = [
    {
        label: "Grains",
        options: [
            { label: "Rices", value: "rices" },
            { label: "Beans", value: "beans" }
        ]
    },
    {
        label: "Fruits",
        options: [
            { label: "Fresh Produce", value: "Fruits" },
            { label: "Fresh Produce 2", value: "Fruits" }
        ]
    },
];

const sizeoptions = [
    { value: 'allsize', label: 'All Sizes' },
    { value: '1kg', label: '1kg' },
    { value: '5kg', label: '5KG' },
    { value: '20kg', label: '20KG' },
]

const allcustomers = [
    { value: 'allcustomers', label: 'All Customers' },
    { value: 'customer1', label: 'Customer1' },
    { value: 'customer2', label: 'Customer2' },
    { value: 'customer3', label: 'Customer3' },
]

const allsrs = [
    { value: 'allsrs', label: 'All SRs' },
    { value: 'other', label: 'other' },
]

type Props = {
    className: string
}
const ReturnedProducts: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Retured Items Report"
    }
    return (
        <>
            <EATitle title='Returned Products' />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Retured Items Report' })}</PageTitle>
            <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            {/* begin::Title */}
                            <div>
                                <h3 className="card-title align-items-start flex-column my-0">
                                    <span className="card-label fw-bold font-17">Retured Items Overview</span>
                                </h3>
                                <p className="font-13 text-muted mb-0">Based on Returned Products Count
                                </p>
                            </div>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: '01/01/2020',
                                        endDate: '01/12/2022',
                                        alwaysShowCalendars: true,
                                        cancelClass: 'btn-secondary',
                                        singleDatePicker: true,
                                        drops: "auto",
                                        locale: {
                                            format: 'DD/MM/YYYY'
                                        },
                                    }}
                            >
                                <input type="text" className="form-control align-self-center calendar w-140px" />
                            </DateRangePicker>
                        </div>
                        <div className='card-body'>
                            Chart Area
                        </div>
                    </div>
                </div>
            </div>
            <div className={`card ${className} mb-5`}>
                {/* begin::Header */}
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    {/* begin::Title */}
                    <div><h3 className="card-title align-items-start flex-column my-0">
                        <span className="card-label fw-bold font-17">Retured Items Overview</span>
                    </h3>
                        <p className="font-13 text-muted mb-0">Based on Returned Products Count
                        </p>
                    </div>

                    {/* end::Title */}

                    {/* begin::Toolbar */}
                    <div className='card-toolbar d-flex g-3 my-0'>
                        <ul className='nav'>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_1'
                                >
                                    Brands
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_2'
                                >
                                    Parent Categories
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_3'
                                >
                                    Product Groups
                                </a>
                            </li>
                        </ul>
                        <span className="v-seperator mx-3"></span>
                        <ul className='nav'>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_1'
                                >
                                    Month
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_2'
                                >
                                    Year
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* end::Toolbar */}
                </div>
                <div className="card-body">
                    Chart Area
                </div>
            </div>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2022',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar my-1 me-3 w-auto min-w-210px" />
                                </DateRangePicker>
                                <Select
                                    options={brandoptions}
                                    isSearchable
                                    placeholder="All Brands"
                                    className='react-select-container my-1 me-3 w-120px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <Select
                                    options={categoryoptions}
                                    isSearchable
                                    placeholder="All Categories"
                                    className='react-select-container my-1 me-3 w-150px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <Select
                                    options={sizeoptions}
                                    isSearchable
                                    placeholder="All Sizes"
                                    className='react-select-container my-1 me-3 w-100px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <Select
                                    options={allcustomers}
                                    isSearchable
                                    placeholder="All Customers"
                                    className='react-select-container my-1 me-3 w-140px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <Select
                                    options={allsrs}
                                    isSearchable
                                    placeholder="All SRs"
                                    className='react-select-container my-1 w-90px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive expandable' style={{ 'transition': '.5s linear all', 'borderCollapse': 'collapse' }} id="expandTable">
                            <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                                <thead>
                                    <tr className=' text-muted fw-500'>
                                        <th className='min-w-110px py-2'>Product SKU</th>
                                        <th className='min-w-100px py-2'>Brand</th>
                                        <th className='min-w-120px py-2'>Category</th>
                                        <th className='min-w-130px py-2'>Product Group</th>
                                        <th className='min-w-100px py-2'>Variant</th>
                                        <th className='min-w-100px py-2'>Size</th>
                                        <th className='min-w-100px py-2'>Returned Qty.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        data-bs-toggle="collapse"
                                        data-bs-target=".row-collapse1"
                                        aria-controls="rowCollapse1"
                                        className='cursor-pointer'
                                    >
                                        <td>Onja Rice 1kg</td>
                                        <td>Onja</td>
                                        <td>
                                            <div>
                                                <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                                Rice
                                            </div>
                                        </td>
                                        <td>Rice</td>
                                        <td>Standard</td>
                                        <td>1kg</td>
                                        <td>601</td>
                                    </tr>
                                    <tr className='expandable-row'>
                                        <td className="reset-expansion-style" colSpan={11}>
                                            <div className='row-expansion-style collapse row-collapse1 collapsible' id="rowCollapse1" data-bs-parent="#expandTable">
                                                <div>
                                                    <table className='table table-bordered gs-4 mb-0'>
                                                        <thead>
                                                            <tr className='text-muted fw-500 bg-light2'>
                                                                <th className='w-200px py-2'>Return Date</th>
                                                                <th className='w-200px py-2'>Customer Name</th>
                                                                <th className='w-200px py-2'>SR Name</th>
                                                                <th className='min-w-120px py-2'>Returned Qty.</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>02 May 2022</td>
                                                                <td>Alta Foster</td>
                                                                <td>Edna Byrd</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td>02 May 2022</td>
                                                                <td>Alta Foster</td>
                                                                <td>Edna Byrd</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td>02 May 2022</td>
                                                                <td>Alta Foster</td>
                                                                <td>Edna Byrd</td>
                                                                <td>8</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    {/* end first row expand */}
                                    <tr
                                        data-bs-toggle="collapse"
                                        data-bs-target=".row-collapse2"
                                        aria-controls="rowCollapse2"
                                        className='cursor-pointer'
                                    >
                                        <td>Onja Rice 5kg</td>
                                        <td>Onja</td>
                                        <td>
                                            <div>
                                                <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                                Rice
                                            </div>
                                        </td>
                                        <td>Rice</td>
                                        <td>Standard</td>
                                        <td>5kg</td>
                                        <td>601</td>
                                    </tr>
                                    <tr className='expandable-row'>
                                        <td className="reset-expansion-style" colSpan={11}>
                                            <div className='row-expansion-style collapse row-collapse2 collapsible' id="rowCollapse2" data-bs-parent="#expandTable">
                                                <div>
                                                    <table className='table table-bordered gs-4 mb-0'>
                                                        <thead>
                                                            <tr className='text-muted fw-500 bg-light2'>
                                                                <th className='w-200px py-2'>Return Date</th>
                                                                <th className='w-200px py-2'>Customer Name</th>
                                                                <th className='w-200px py-2'>SR Name</th>
                                                                <th className='min-w-120px py-2'>Returned Qty.</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>02 May 2022</td>
                                                                <td>Alta Foster</td>
                                                                <td>Edna Byrd</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td>02 May 2022</td>
                                                                <td>Alta Foster</td>
                                                                <td>Edna Byrd</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td>02 May 2022</td>
                                                                <td>Alta Foster</td>
                                                                <td>Edna Byrd</td>
                                                                <td>8</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    {/* end first row expand */}
                                    <tr>
                                        <td>Onja Rice 20kg</td>
                                        <td>Onja</td>
                                        <td>
                                            <div>
                                                <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                                Rice
                                            </div>
                                        </td>
                                        <td>Rice</td>
                                        <td>Standard</td>
                                        <td>20kg</td>
                                        <td>601</td>
                                    </tr>
                                    <tr>
                                        <td>Onja Rice 1kg</td>
                                        <td>Onja</td>
                                        <td>
                                            <div>
                                                <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                                Rice
                                            </div>
                                        </td>
                                        <td>Rice</td>
                                        <td>Standard</td>
                                        <td>1kg</td>
                                        <td>601</td>
                                    </tr>
                                    <tr>
                                        <td>Onja Rice 25kg</td>
                                        <td>Onja</td>
                                        <td>
                                            <div>
                                                <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                                Rice
                                            </div>
                                        </td>
                                        <td>Rice</td>
                                        <td>Standard</td>
                                        <td>25kg</td>
                                        <td>601</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
            </div>
            {/* <Addwarehouse /> */}
        </>
    )
}

export default ReturnedProducts
