// reset-password-confirm

import { apiBaseURL } from "../../../helpers";
import { postRequest } from "../../../https/apiCall";
import {
  RESET_PASSWORD_EAFRUITS_FAIL,
  RESET_PASSWORD_EAFRUITS_IS_LOADING,
  RESET_PASSWORD_EAFRUITS_SUCCESS,
  RESET_PASSWORD_EAFRUITS_UNIQUE_PASSWORD
} from "../../types/types";

export const ResetPasswordEa =
  (email: string, password: string, confirm_password: string, token: string) =>
  async (dispatch: any) => {
    dispatch({
      type: RESET_PASSWORD_EAFRUITS_IS_LOADING,
    })
    // await axios({
    //     method: 'post',
    //     url: `${apiBaseURL()}auth/reset-password-confirm`,
    //     headers: {
    //         "content-type": "application/json",
    //     },
    //     data: {
    //         email: email,
    //         password: password,
    //         confirm_password: confirm_password,
    //         token: token,
    //     }
    // })

    const data = {
      email: email,
      password: password,
      confirm_password: confirm_password,
      token: token,
    }

    await postRequest(`${apiBaseURL()}auth/reset-password-confirm`, data)
      .then((res) => {
         if (res.statusText === "Bad Request") {
           if (res.data && res.data.error.field == 'password') {
             dispatch({
               type: RESET_PASSWORD_EAFRUITS_UNIQUE_PASSWORD,
               payload: 'Please choose a password that is different from your last 2 passwords!',
             })
           }
       }
      //  500 error handler
       else if(res.statusText === "Internal Server Error"){
            dispatch({
              type: RESET_PASSWORD_EAFRUITS_FAIL,
              payload: 'Something Went Wrong. Please Try Again',
            })
       }
       else {
             dispatch({
                 type: RESET_PASSWORD_EAFRUITS_SUCCESS,
                 payload: 'Your password has been changed successful',
             })
         }
      })
      .catch((err) => {
        // dispatch({
        //   type: RESET_PASSWORD_EAFRUITS_FAIL,
        //   payload: 'Something Went Wrong. Please Try Again',
        // })
      })
  }
