import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { AsyncPaginate } from "react-select-async-paginate";
import { apiBaseURL, dateFormat, KTSVG, measurementLabel } from "../../../_eaFruitsDms/helpers";
import { getRequest, postRequest } from "../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../_eaFruitsDms/layout/components/alert/eaAlert";
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import { PageTitle } from "../../../_eaFruitsDms/layout/core";
import tenantConfiguration from "../../../TenantVariables";

type Props = {
  className: string
}

let tableRowIndex: number = 0

const WTransferStockOut: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Stock-Out',
    subTitle: 'Warehouse Transfer Stock-Out',
  }

  const initialRow: any = {
    index: 0,
    product_id: '',
    product_id_obj: '',
    batch_number: '',
    product_sku: null,
    product_sku_response: null,
    product_batches: [],
    quantity_label: '',
    validation_for_product: '',
    validation_for_batch: '',
    expiry_date: '',
    available_quantity: '',
    inward_available_quantity: '',
    qty: '',
    quantity: '',
    inward_qty: '',
  }

  const navigate = useNavigate()

  const APIBaseURL = tenantConfiguration.apiBaseUrl
  const [productSkus, setProductSkus] = useState<any>([])
  const [productSkuResponse, setProductSkuResponse] = useState<any>([])
  const [productSkuBatches, setProductSkuBatches] = useState<any>([])
  const [sendWarehouse, setSendWarehouse] = useState<any>([])
  const [salesRepresentatives, setSalesRepresentatives] = useState<any>([])
  const [warehouseId, setWarehouseId] = useState<any>(localStorage.getItem('w_id'))
  const [sendWarehouseId, setSendWarehouseId] = useState<any>(0)
  const [salesRepresentativeId, setSalesRepresentativeId] = useState<any>(0)
  const [note, setNote] = useState<string>('')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [warehouseStockOutRows, setWarehouseStockOutRows] = useState<any>([initialRow])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [IsAlertSuccess, setIsAlertSuccess] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [warehouseOffSet, setWarehouseOffSet] = useState<any>('')
  const [salesRepresentOffSet, setSalesRepresentOffSet] = useState<any>('')
  const [productOffSet, setProductOffSet] = useState<any>('')
  const [limit, setLimit] = useState<number>(10)
  const [searchSelect, setSearchSelect] = useState('')
  const [batchReset, setBatchReset] = useState<any>(moment());

  useEffect(() => {
    setWarehouseOffSet('')
    setSalesRepresentOffSet('')
  }, [searchSelect])

  const productSKUAPI = async () => {
    let productResponse = await getRequest(
      `${APIBaseURL}inventory/product-SKUs?ordering=name&trade_only=true&zero_stock=True&remove_product_stocking=true&warehouse_id=${warehouseId}&limit=${limit}&offset=${productOffSet}`,
      true
    )

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if (productResponse.next !== null) {
      const queryParams = new URLSearchParams(productResponse.next)
      let newOffset: any = queryParams.get('offset')
      setProductOffSet(newOffset)
      hasMore = true
    }

    if (productResponse.results.length > 0) {
      productResponse.results.map((option: any) => {
        optionData.push(option)
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (productSkus.length > 0) {
      setProductSkus(productSkus.concat(options))
      setProductSkuResponse(productSkuResponse.concat(optionData))
    } else {
      setProductSkus(options)
      setProductSkuResponse(optionData)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchBatches = async (index: number) => {
    const stockRows: any = [...warehouseStockOutRows]
    const wId = localStorage.getItem('w_id')

    const batchResponse = await getRequest(
      `${apiBaseURL()}inventory/product-SKUs/${stockRows[index].product_id}/?warehouse_id=${wId}`,
      true
    )

    let options: any = []
    let optionData: any = []

    if (batchResponse.product_stocking.length > 0) {
      const rows = [...warehouseStockOutRows]

      batchResponse.product_stocking.map((option: any) => {
        const batch = rows.filter((row: any) => option.batch_number === row['batch_number'])

        if (batch.length == 0) {
          optionData.push(option)
          options.push({
            label: option.batch_number,
            value: option.batch_number,
          })
        }
      })
    }

    setProductSkuBatches(optionData)

    return {
      options: options,
      hasMore: false,
    }
  }

  const salesRepresentativesAPI = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    const salesRepresentativeResponse = await getRequest(
      `${APIBaseURL}auth/users/team/list?warehouse_id=${warehouseId}&search=${search}&type=SR&limit=${limit}&offset=${salesRepresentOffSet}`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (salesRepresentativeResponse.next !== null) {
      const queryParams = new URLSearchParams(salesRepresentativeResponse.next)
      let newOffset: any = queryParams.get('offset')
      setSalesRepresentOffSet(newOffset)
      hasMore = true
    }

    if (salesRepresentativeResponse.results.length > 0) {
      salesRepresentativeResponse.results.map((option: any) => {
        options.push({
          label: option.first_name + ' ' + option.last_name,
          value: option.id,
        })
      })
    }

    if (sendWarehouse.length > 0) {
      setSalesRepresentatives(sendWarehouse.concat(options))
    } else {
      setSalesRepresentatives(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const warehouseAPI = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    const warehouseResponse: any = await getRequest(
      `${APIBaseURL}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        if (option.id != warehouseId) {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
    }

    if (sendWarehouse.length > 0) {
      setSendWarehouse(sendWarehouse.concat(options))
    } else {
      setSendWarehouse(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  // Add New Table Row
  const addNewRow = () => {
    tableRowIndex++

    const updatedRows = [...warehouseStockOutRows]
    initialRow.index = tableRowIndex
    setBatchReset(moment())
    setCurrentIndex(tableRowIndex)
    updatedRows[tableRowIndex] = initialRow
    setWarehouseStockOutRows(updatedRows)
  }

  // Remove Table row if rows are count is more than 1
  const deleteRow = (index: number): any => {
    if (warehouseStockOutRows.length > 0) {
      let updatedRows: any = [...warehouseStockOutRows]
      updatedRows.splice(index, 1)

      tableRowIndex--
      updatedRows.forEach((row: any, index: any) => {
        row.index = index
      })
      setWarehouseStockOutRows(updatedRows)
    }
  }

  const handleChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    if (inputName === 'warehouse') {
      setSendWarehouseId(inputValue)
    }

    if (inputName === 'salesRepresentative') {
      setSalesRepresentativeId(inputValue)
    }

    if (inputName === 'note') {
      setNote(inputValue)
    }
  }

  const handleChangeRow = (e: any, index: any, name?: string) => {
    let rows = [...warehouseStockOutRows]

    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'product':
        rows[index]['product_id'] = inputValue
        rows[index]['product_id_obj'] = e
        let productRes = productSkuResponse.filter(
          (productSku: any) => productSku.id === inputValue
        )
        setBatchReset(moment())
        setCurrentIndex(index)
        if (productRes.length > 0) {
          productRes = productRes[0]

          rows[index]['validation_for_product'] = ''
          rows[index]['product_sku_response'] = productRes
          rows[index]['batch_number'] = ''
          rows[index]['batch_obj'] = ''
          rows[index]['expiry_date'] = ''
          rows[index]['qty'] = 0
          rows[index]['quantity'] = 0
          rows[index]['inward_available_quantity'] = 0
          rows[index]['inward_qty'] = 0

          const product = rows[index]['product_sku_response']

          rows[index]['quantity_label'] = measurementLabel(
            product.fix_size
              ? product.material.name
              : product.variants && product.variants.approx_weight > 0
                ? product.material.name
                : product.measurement_unit
          )
        }
        break
      case 'batch_number':
        rows[index]['batch_number'] = inputValue
        rows[index]['validation_for_batch'] = inputValue
        rows[index]['batch_obj'] = {
          label: inputValue,
          value: inputValue,
        }
        const productStock = productSkuBatches.filter(
          (option: any) => option.batch_number == inputValue
        )

        const product = rows[index]['product_sku_response']

        rows[index]['quantity'] = productStock[0].units
        rows[index]['quantity_label'] = measurementLabel(
          product.fix_size
            ? product.material.name
            : product.variants && product.variants.approx_weight > 0
              ? product.material.name
              : product.measurement_unit
        )

        if (product.fix_size) {
          rows[index]['inward_available_quantity'] = productStock[0].units * product.size

          if (product.measurement_unit.toLowerCase() === 'gram') {
            rows[index]['inward_available_quantity'] =
              rows[index]['inward_available_quantity'] / 1000
          }
        } else {
          rows[index]['inward_available_quantity'] =
            product.variants && product.variants.approx_weight > 0
              ? (productStock[0].units * product.variants.approx_weight) / 1000
              : productStock[0].units
        }

        rows[index]['expiry_date'] = productStock[0].expiry_date
        break
      case 'expiry_date':
        rows[index]['expiry_date'] = moment(inputValue)
        break
      case 'qty':
        rows[index]['validation_for_qty'] = ''

        if (rows[index]['quantity'] < inputValue) {
          setErrorMessage(
            'You can not transfer ' +
            inputValue +
            ' ' +
            rows[index]['quantity_label'] +
            ' because there is only ' +
            rows[index]['quantity'] +
            ' ' +
            rows[index]['quantity_label'] +
            ' available in warehouse'
          )
          setIsAlertFailed(true)

          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 2000)
          return false
        } else {
          inputValue = inputValue.replace(/\D/g, '')
          rows[index]['qty'] = inputValue

          let productRes = rows[index]['product_sku_response']

          if (productRes) {
            if (productRes.fix_size) {
              rows[index]['inward_qty'] = inputValue * productRes.size
              if (productRes.measurement_unit.toLowerCase() === 'gram') {
                rows[index]['inward_qty'] = rows[index]['inward_qty'] / 1000
              }
            } else {
              rows[index]['inward_qty'] = inputValue

              if (productRes.variants && productRes.variants.approx_weight > 0) {
                rows[index]['inward_qty'] = (inputValue * productRes.variants.approx_weight) / 1000
              }
            }
          }
        }
        break
    }
    setWarehouseStockOutRows(rows)
  }

  const cancel = () => {
    setWarehouseStockOutRows([initialRow])
    setSendWarehouseId(0)
    setSalesRepresentativeId(0)
    setProductSkus([])
    setProductOffSet('')
  }

  const warehouseStockOutSubmitHandler = async () => {
    if (warehouseStockOutRows.length === 0) {
      alert("You've to add stock items.!")
      return false
    }
    let isValid: boolean = true

    const updatedWarehouseStockOutRows: any = [...warehouseStockOutRows]

    warehouseStockOutRows.map((row: any, index: number) => {
      if (row['product_id'] === '') {
        isValid = false
        updatedWarehouseStockOutRows[index].validation_for_product = 'is-invalid'
      } else {
        updatedWarehouseStockOutRows[index].validation_for_product = ''
      }

      if (row['batch_number'] === '') {
        isValid = false
        updatedWarehouseStockOutRows[index].validation_for_batch = 'is-invalid'
      } else {
        updatedWarehouseStockOutRows[index].validation_for_batch = ''
      }

      if (row['qty'] === '' || row['qty'] == 0) {
        isValid = false
        updatedWarehouseStockOutRows[index].validation_for_qty = 'is-invalid'
      } else {
        updatedWarehouseStockOutRows[index].validation_for_qty = ''
      }

      setWarehouseStockOutRows(updatedWarehouseStockOutRows)
    })

    if (isValid) {
      const warehouseStockOutSubmitData: any = {
        stock_entries: warehouseStockOutRows,
        sender_warehouse_id: warehouseId,
        stock_type: 'WarehouseTransfer',
        stock_entry_type: 'Out',
        receiver_warehouse_id: sendWarehouseId,
        action_by: localStorage.getItem('user_id'),
      }

      const bulkStockInAPIUrl = `${apiBaseURL()}inventory/bulk_stock_in_out/`
      setDisplayLoader(true)
      setIsSubmitting(true)
      const response = await postRequest(bulkStockInAPIUrl, warehouseStockOutSubmitData, true)

      if (response.status == 500) {
        setIsAlertFailed(true)
      } else {
        setIsAlertSuccess(true)
      }

      setDisplayLoader(false)
      setIsSubmitting(false)
      setWarehouseStockOutRows(initialRow)
      setSendWarehouseId(0)
      setSalesRepresentativeId(0)
      setNote('')

      navigate('/reports/daily-stock-out-report')
    }
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({ id: 'Warehouse Transfer Stock-Out' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        message='success'
      />
      <EaAlertDanger
        onClick={() => isAlertSHow('failed')}
        show={isAlertFailed}
        message={errorMessage != '' ? errorMessage : 'failed'}
      />

      <div className={`card mb-6 ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          {/* begin::Title */}
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Warehouse Transfer Stock-Out</span>
          </h3>
          {/* end::Title */}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          {displayLoader && <IsLoader />}
          <div className='row mb-4'>
            <div className='col-md-6'>
              <label className='form-label'>
                Send to Warehouse<span className="text-danger">*</span>
              </label>
              <AsyncPaginate
                    
                loadOptions={warehouseAPI}
                placeholder='Select'
                isSearchable
                className='react-select-container w-100'
                classNamePrefix='react-select'
                menuPosition='fixed'
                name='warehouse'
                value={sendWarehouse.find((data: any) => data.value == sendWarehouseId) || ''}
                onChange={(e) => handleChange(e, 'warehouse')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>

            <div className='col-md-6'>
              <label className='form-label'>Notes / Reason</label>
              <input
                type='text'
                className='form-control'
                value={note}
                name='note'
                onChange={handleChange}
                placeholder='Enter the notes or reason to transfer the stock'
              />
            </div>
          </div>
          {/* begin::Table container */}
          {sendWarehouseId === 0 ? (
            <NoRecords />
          ) : (
            <>
              <div className='table-responsive border-top'>
                {/* begin::Table */}
                <table className='table table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='w-200px'>
                        Product SKU<span className="text-danger">*</span>
                      </th>
                      <th className='w-180px'>
                        Batch<span className="text-danger">*</span>
                      </th>
                      <th>Expiry Date</th>
                      <th>Available Qty.</th>
                      <th>
                        Transfer Qty.<span className="text-danger">*</span>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {warehouseStockOutRows.length > 0 &&
                      warehouseStockOutRows.map((row: any, index: number) => {
                        let newDate: any =
                          warehouseStockOutRows[index].expiry_date !== ''
                            ? dateFormat(warehouseStockOutRows[index].expiry_date)
                            : ''
                        if (row) {
                          return (
                            <>
                              <tr>
                                <td>
                                  <AsyncPaginate
                    
                                    loadOptions={productSKUAPI}
                                    isSearchable
                                    placeholder='Select'
                                    isOptionDisabled={(option: any) => option.isDisable}
                                    className={clsx(
                                      'react-select-container' + ' w-100',
                                      row.validation_for_product
                                    )}
                                    classNamePrefix='react-select'
                                    menuPosition='fixed'
                                    name='product'
                                    value={warehouseStockOutRows[index].product_id_obj || ''}
                                    onChange={(e) => {
                                      setProductOffSet('')
                                      handleChangeRow(e, index, 'product')
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <AsyncPaginate
                                    key={batchReset}
                                    loadOptions={() => fetchBatches(index)}
                                    isSearchable
                                    placeholder='Select'
                                    isOptionDisabled={(option: any) => option.isDisable}
                                    className={clsx(
                                      'react-select-container' + ' w-100',
                                      row.validation_for_batch
                                    )}
                                    classNamePrefix='react-select'
                                    menuPosition='fixed'
                                    name='batches'
                                    value={warehouseStockOutRows[index].batch_obj || ''}
                                    onChange={(e) => handleChangeRow(e, index, 'batch_number')}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    className='form-control'
                                    value={newDate !== '' ? newDate.displayDate : ''}
                                    disabled
                                  />
                                </td>
                                <td className='align-baseline'>
                                  <div className='input-group mb-1'>
                                    <input
                                      type='text'
                                      className='form-control'
                                      value={warehouseStockOutRows[index].quantity}
                                      disabled
                                    />
                                    <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                                      {warehouseStockOutRows[index].quantity_label}
                                    </span>
                                  </div>
                                  <span className='font-13 text-muted mt-1'>
                                    {warehouseStockOutRows[index].inward_available_quantity +
                                      ' Kgs'}
                                  </span>
                                </td>
                                <td className='align-baseline'>
                                  <div
                                    className='input-group mb-1'
                                    style={{
                                      borderColor:
                                        warehouseStockOutRows[index].validation_for_qty ===
                                          'is-invalid'
                                          ? 'Red'
                                          : '',
                                    }}
                                  >
                                    <input
                                      type='number'
                                      name='qty'
                                      className={clsx(
                                        'form-control min-w-70px',
                                        row.validation_for_qty
                                      )}
                                      placeholder='0'
                                      onChange={(e: any) => handleChangeRow(e, index)}
                                      value={
                                        warehouseStockOutRows[index].qty > 0 &&
                                        warehouseStockOutRows[index].qty
                                      }
                                    />
                                    <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                                      {warehouseStockOutRows[index].quantity_label}
                                    </span>
                                  </div>
                                  <span className='font-13 text-muted'>
                                    {warehouseStockOutRows[index].inward_qty + '  Kgs'}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    type='button'
                                    onClick={() => deleteRow(index)}
                                    className='position-relative btn ms-auto p-0'
                                  >
                                    <span className='svg-icon-dark'>
                                      <KTSVG
                                        path='/media/icons/duotune/abstract/abs012.svg'
                                        className='ic me-0'
                                      />
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            </>
                          )
                        }
                      })}
                    <button
                      type='button'
                      className='position-relative btn ms-auto p-0'
                      onClick={addNewRow}
                    >
                      <span className='svg-icon-dark'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs011.svg'
                          className='ic me-0'
                        />
                      </span>
                    </button>
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
              <div
                className='pt-4 border-top text-center'
                style={sendWarehouseId === 0 ? { opacity: 0.5, pointerEvents: 'none' } : {}}
              >
                <button
                  type='button'
                  className='btn btn-secondary me-3'
                  disabled={isSubmitting}
                  onClick={cancel}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-primary'
                  disabled={isSubmitting}
                  onClick={warehouseStockOutSubmitHandler}
                >
                  {isSubmitting ? 'Please wail...' : 'Submit'}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default WTransferStockOut
