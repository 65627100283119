import React from "react"

const OrderingArrowUp = () => {
    return (
        <i className="ms-1 bi bi-caret-up-fill cursor-pointer"></i>
    )
}

const OrderingArrowDown = () => {
    return (
        <i className="ms-1 bi bi-caret-down-fill cursor-pointer"></i>
    )
}

export { OrderingArrowUp, OrderingArrowDown }
