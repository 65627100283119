import React, {useEffect, useState} from 'react'
import {
  apiBaseURL,
  checkEmpty,
  dateTimeFormat,
  isEmpty,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../../TenantVariables'

const Message = (props: any) => {
  const img = `${process.env.PUBLIC_URL}/media/icons/duotune/files/fil003.svg`

  const [complaintsId] = useState(localStorage.getItem('complaints-detail-id') || '')
  const [message, setMessage] = useState([])
  const [fetchingRecords, setFetchingRecords] = useState(true)
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState(tenantConfiguration.limit)

  const fetchMessages = async () => {
    setFetchingRecords(true)

    const url = `${apiBaseURL()}complaint_request/list-complaint-comment/${complaintsId}?limit=${limit}&offset=${currentOffset}`
    const response = await getRequest(url, true)

    if (response.results) {
      setMessage(response.results)
      setFetchingRecords(false)
      setCount(response.count)
    }
  }

  useEffect(() => {
    !isEmpty(complaintsId) && fetchMessages()
  }, [complaintsId, currentOffset])

  useEffect(() => {
    if (props.alert === true) {
      fetchMessages()
    }
  }, [props.alert])

  return (
    <>
      {props.loading || fetchingRecords ? (
        <FetchingRecords />
      ) : message.length === 0 ? (
        <div className='card p-5'>
          <NoRecords />
        </div>
      ) : (
        <>
          {message.length > 0 &&
            message.map((items: any) => {
              return (
                <div className='card p-5 mb-3'>
                  <div className='row'>
                    <div className='col-md-12 p-3'>
                      <div className='d-flex align-items-center'>
                        {isEmpty(items.comment_user_photo) ? (
                          <img
                            src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                            alt=''
                            className='rounded-circle me-3 w-60px'
                          />
                        ) : (
                          <img
                            src={items.comment_user_photo}
                            alt=''
                            className='rounded-circle me-3 w-60px'
                          />
                        )}
                        <div>
                          <h5>
                            {checkEmpty(items.comment_user_name)}{' '}
                            {!isEmpty(items.comment_user_type) &&
                              `(${items.comment_user_type.toUpperCase()})`}
                          </h5>
                          <div className='font-weight-normal'>
                            {isEmpty(items.created_at) ? '-' : dateTimeFormat(items.created_at)}
                          </div>
                        </div>
                      </div>
                      <div className='mt-5'>{checkEmpty(items.message)}</div>

                      <div className='mt-2 row'>
                        {items.message_documents.length > 0 && (
                          <div className='font-semibold my-2'>
                            {items.message_documents.length} Attachments
                          </div>
                        )}

                        {!isEmpty(items.message_documents) &&
                          items.message_documents.length > 0 &&
                          items.message_documents.map((i: any) => {
                            return (
                              <>
                                {!isEmpty(i.document_name) && (
                                  <>
                                    <div className='border me-3 col-md-4 mb-2'>
                                      <a href={i.document_name} target='_blank' rel='noreferrer'>
                                        <div className='py-3 px-1 d-flex align-items-center'>
                                          <img
                                            src={i.document_name}
                                            alt=''
                                            height={50}
                                            width={50}
                                            className='me-4'
                                            onError={({currentTarget}) => {
                                              currentTarget.onerror = null // prevents looping
                                              currentTarget.src = `${img}`
                                            }}
                                          />

                                          <div className='font-semibold w-80'>
                                            {i.document_name &&
                                              i.document_name.substring(
                                                i.document_name.lastIndexOf('/') + 1
                                              )}
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  </>
                                )}
                              </>
                            )
                          })}
                      </div>

                      {/* <div className='mt-2 text-primary font-semibold'>
                        Posted By {checkEmpty(items.comment_user_name)}{' '}
                        {!isEmpty(items.comment_user_type) &&
                          `(${items.comment_user_type.toUpperCase()})`}
                      </div> */}
                    </div>
                  </div>
                </div>
              )
            })}

          <CustomPaginate
            data={message}
            selected={selected}
            setSelected={setSelected}
            setCurrentOffset={setCurrentOffset}
            totalRecords={count}
            limit={limit}
          />
        </>
      )}
    </>
  )
}

export default Message
