export const requestStatus = [
  {
    value: '',
    label: 'All Status',
  },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
  {
    value: 'ss_approved',
    label: 'SS Approved',
  },
]
