import React from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import { useLocation } from "react-router-dom"

let shoeEditBtn = true
const AddOrderFeedback: React.FC = () => {
    const location = useLocation()

    return (
        <>
            <div className='card mb-4 mb-xl-5'>
                <div className='card-body py-5'>
                    <span className='text-dark font-20 fw-bold me-1'>
                        #84419 Invoice ID
                    </span>
                    <span className='d-flex align-items-center text-muted font-14'>
                        Customer Name
                    </span>
                </div>
            </div>
            <div className='card mb-4 mb-xl-5'>
                <div className='card-body py-5'>
                    <div className='row'>
                        <div className='col-lg-5 mb-4'>
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <label className='form-label'>Date</label>
                                    <DateRangePicker
                                        initialSettings={{
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2020',
                                            alwaysShowCalendars: true,
                                            singleDatePicker: true,
                                            cancelClass: 'btn-secondary',
                                            drops: 'auto',
                                            locale: {
                                                format: 'DD/MM/YYYY',
                                            },
                                        }}
                                    >
                                        <input type='text' className='form-control calendar' />
                                    </DateRangePicker>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <label className='form-label'>Time</label>
                                    <DateRangePicker
                                        initialSettings={{
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2020',
                                            alwaysShowCalendars: true,
                                            singleDatePicker: true,
                                            cancelClass: 'btn-secondary',
                                            drops: 'auto',
                                            locale: {
                                                format: 'DD/MM/YYYY',
                                            },
                                        }}
                                    >
                                        <input type='text' className='form-control calendar' />
                                    </DateRangePicker>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 mb-4">
                            <label className='form-label'>Note</label>
                            <textarea
                                className='form-control'
                                rows={4}
                                placeholder='Type here…'
                            >
                            </textarea>
                        </div>
                        <div className="col-md-7 mb-4">
                            <label className='form-label'>Rating</label>
                            <div className='rating'>
                                <div className='rating-label me-2 checked'>
                                    <i className='bi bi-star-fill fs-5'></i>
                                </div>
                                <div className='rating-label me-2 checked'>
                                    <i className='bi bi-star-fill fs-5'></i>
                                </div>
                                <div className='rating-label me-2 checked'>
                                    <i className='bi bi-star-fill fs-5'></i>
                                </div>
                                <div className='rating-label me-2'>
                                    <i className='bi bi-star-fill fs-5'></i>
                                </div>
                                <div className='rating-label me-2'>
                                    <i className='bi bi-star-fill fs-5'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer py-2">
                    <button
                        type='button'
                        className='btn btn-secondary me-3'
                    >
                        Cancel
                    </button>
                    <button
                        className='btn btn-primary'
                    >
                        Submit
                    </button>
                </div>
            </div>

        </>
    )
}

export default AddOrderFeedback
