import moment from "moment"
import React from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import { useIntl } from "react-intl"
import Select from "react-select"
import { KTSVG, toAbsoluteUrl } from "../../../../_eaFruitsDms/helpers"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"

const vehicletypes = [
    { value: 'allvehicletypes', label: 'All Vehicle Types' },
    { value: 'type1', label: 'Type1' },
    { value: 'type2', label: 'Type2' },
    { value: 'type3', label: 'Type3' },
]

const allmake = [
    { value: 'allmake', label: 'All Make' },
    { value: 'tata', label: 'Tata' },
    { value: 'suzuki', label: 'Suzuki' },
    { value: 'bajaj', label: 'Bajaj' },
]

const allwards = [
    { value: 'allwards', label: 'All Wards' },
    { value: 'bangata', label: 'Bangata' },
    { value: 'kisongo', label: 'Kisongo' },
    { value: 'mussa', label: 'Mussa' },
]

type Props = {
    className: string
}
const VehicleUsageReport: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Vehicle Usage Report",
        subTitle: ""
    }
    return (
        <>
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Vehicle Usage Report' })}</PageTitle>
            <div className={`card ${className} mb-5`}>
                {/* begin::Header */}
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    {/* begin::Title */}

                    <h3 className='card-title align-items-start flex-column my-0'>
                        <span className='card-label fw-bold font-17'>Overview</span>
                    </h3>

                    {/* end::Title */}

                    {/* begin::Toolbar */}
                    <div className='card-toolbar d-flex g-3 my-0'>
                        <DateRangePicker
                            initialSettings={
                                {
                                    startDate: '01/01/2020',
                                    endDate: '01/12/2022',
                                    alwaysShowCalendars: true,
                                    cancelClass: 'btn-secondary',
                                    drops: "auto",
                                    locale: {
                                        format: 'DD/MM/YYYY'
                                    },
                                    ranges: {
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    },
                                }}
                        >
                            <input type="text" className="form-control calendar my-1 w-auto min-w-215px" />
                        </DateRangePicker>
                    </div>
                    {/* end::Toolbar */}
                </div>
                <div className="card-body">
                    Chart Area
                </div>
            </div>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <Select
                                    options={vehicletypes}
                                    isSearchable
                                    placeholder="All Vehicle Types"
                                    className='react-select-container my-1 me-3 w-170px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <Select
                                    options={allmake}
                                    isSearchable
                                    placeholder="All Make"
                                    className='react-select-container my-1 me-3 w-110px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <Select
                                    options={allwards}
                                    isSearchable
                                    placeholder="All Wards"
                                    className='react-select-container my-1 me-3 w-110px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2022',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar my-1 me-3 w-auto min-w-215px" />
                                </DateRangePicker>
                                <label className="form-label mb-0"><button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">
                                    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                    Export</button>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-150px'>Vehicle Number</th>
                                        <th className='min-w-140px'>Vehicle Type</th>
                                        <th className='min-w-120px'>Make/Model/Year</th>
                                        <th className='min-w-120px'>Wards</th>
                                        <th className='min-w-80px py-2'>Capacity</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="me-3">
                                                    <span className='symbol-label'>

                                                        <img
                                                            alt='group image'
                                                            className='h-30px rounded-1'
                                                            src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                                                        />

                                                    </span>
                                                </div>
                                                <span>T 173 DSA</span>
                                            </div>
                                        </td>
                                        <td>—</td>
                                        <td>Tata/ACE/2020</td>
                                        <td>Bangata</td>
                                        <td>2000 kg</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="me-3">
                                                    <span className='symbol-label'>

                                                        <img
                                                            alt='group image'
                                                            className='h-30px rounded-1'
                                                            src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                                                        />

                                                    </span>
                                                </div>
                                                <span>T 681 DSD</span>
                                            </div>
                                        </td>
                                        <td>—</td>
                                        <td>Tata/ACE/—</td>
                                        <td>Bwawani & 1 more</td>
                                        <td>1800 kg</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="me-3">
                                                    <span className='symbol-label'>

                                                        <img
                                                            alt='group image'
                                                            className='h-30px rounded-1'
                                                            src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                                                        />

                                                    </span>
                                                </div>
                                                <span>T 540 DSD</span>
                                            </div>
                                        </td>
                                        <td>—</td>
                                        <td>Suzuki/Carry/—</td>
                                        <td>Kisongo</td>
                                        <td>1000 kg</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="me-3">
                                                    <span className='symbol-label'>

                                                        <img
                                                            alt='group image'
                                                            className='h-30px rounded-1'
                                                            src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                                                        />

                                                    </span>
                                                </div>
                                                <span>T 654 DSA</span>
                                            </div>
                                        </td>
                                        <td>—</td>
                                        <td>Bajaj/Bajaj/—</td>
                                        <td>Mateves & 2 more</td>
                                        <td>1500 kg</td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>

                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            Showing 1 to 10 of 50 entries
                        </div>
                        <div className="col">
                            <CustomPagination />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VehicleUsageReport
