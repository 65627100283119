import $ from 'jquery'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  brandGetUrl,
  discountBrand,
  discountProduct,
  productGetUrl,
} from '../../../_eaFruitsDms/apiFunctions/master/apiList'
import {promotionalDiscountPostUrl} from '../../../_eaFruitsDms/apiFunctions/priceDiscount/apiListPrice'
import {
  apiBaseURL,
  globalDateFormate,
  KTSVG,
  retrieveFilter,
  saveFilter,
  staticSearch,
  toAbsoluteUrl,
} from '../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import {deleteRequest, getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../_eaFruitsDms/layout/components/pagination/pagination'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../TenantVariables'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'

const brandoptions = [
  {value: 'all', label: 'All Brands'},
  {value: 'Onja', label: 'Onja'},
]

const productOptions = [
  {value: 'all', label: 'All Products'},
  {value: 'Golden-Banana', label: 'Golden Banana'},
]

type Props = {
  className: string
}
type modalType = {
  discountOn: string
  dropDownValue: object[]
  discount: string
  discountTitle: string
  image: any
  startDateApi: string
  startDateView: string
  endDateApi: string
  endDateView: string
}
const TSPromotionalDiscount: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const priceHeaderTitle = {
    title: 'Promotional Discount',
    subTitle: 'Promotional Discount',
  }
  let promotionalFiltered: any = retrieveFilter(filters.ts_promotional_discount)

  const initialState = {
    warehouse: promotionalFiltered?.warehouse || {value: '', label: 'All Warehouse'},
    brand: promotionalFiltered?.brand || {value: '', label: 'All Brands'},
    product: promotionalFiltered?.product || {value: '', label: 'All Products'},
    status: promotionalFiltered?.status || {value: '', label: 'All Status'},
    selected: promotionalFiltered?.selected || '',
    search: promotionalFiltered?.search || '',
    currentOffSet: promotionalFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  const [getList, setGetList] = useState<any>([]) // getdata
  const [getListDropDown, setGetListDropDown] = useState<any>([]) // getdata
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>(promotionalFiltered?.search || '')
  const [currentOffset, setCurrentOffset] = useState<any>(promotionalFiltered?.currentOffSet || '0')
  const [selected, setSelected] = useState<number>(promotionalFiltered?.selected || 0)
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('add')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [messageTitle, setMessageTitle] = useState('')

  const [listDropDown, setListDropdown] = useState({
    brands: [],
    products: [],
  })

  const defaultSrc = toAbsoluteUrl('/media/icons/img_crop_banner.png')

  const [cropData, setCropData] = useState(defaultSrc)

  const [discount, setDiscount] = useState<modalType>({
    discountOn: 'BRAND',
    dropDownValue: [],
    discount: '',
    discountTitle: '',
    image: '',
    startDateApi: '',
    startDateView: 'Select',
    endDateApi: '',
    endDateView: '',
  })

  const [validation, setValidation] = useState<any>({
    dropDownValue: '',
    discount: '',
    discountTitle: '',
    image: '',
    imageSize: '',
    startDateView: '',
  })

  const [errorMessage, setErrorMessage] = useState('')
  const [disableFeature, setDisableFeature] = useState(true)
  const [status, setStatus] = useState<any>(
    promotionalFiltered?.status || {value: '', label: 'All Status'}
  )
  const [brand, setBrand] = useState<any>(
    promotionalFiltered?.brand || {value: '', label: 'All Brands'}
  )
  const [product, setProduct] = useState<any>(
    promotionalFiltered?.product || {value: '', label: 'All Products'}
  )
  const [warehouse, setWarehouse] = useState<any>(
    promotionalFiltered?.warehouse || {value: '', label: 'All Warehouse'}
  )
  const [brandOffset, setBrandOffset] = useState<any>('')
  const [warehouseOffset, setWarehouseOffset] = useState<any>('')
  const [brandOption, setBrandOption] = useState<any>([])
  const [warehouseOption, setWarehouseOption] = useState<any>([])
  const [productOffset, setProductOffset] = useState<any>('')
  const [roles, setRoles] = useState<any>([])
  const [addBrandOffset, setAddBrandOffset] = useState<any>('')
  const [addBrand, setAddBrand] = useState<any>([])
  const [addProductOffset, setAddProductOffset] = useState<any>('')
  const [addProduct, setAddProduct] = useState<any>([])
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchSelect, setSearchSelect] = useState('')
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    promotionalFiltered?.buttonDisable || false
  )

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    // Develop BY: Kashyap
    // Description: get table list and dropdown list

    getListFromApi(
      limit,
      currentOffset,
      debounceSearch,
      status.value,
      brand.value,
      product.value,
      warehouse.value
    )

    getRequest(brandGetUrl + '?ordering=name' + limit, true).then((res) => {
      let obj: any = [{value: '', label: 'All Brands'}]
      res.results.forEach((item: any) => {
        obj.push({value: item.id, label: item.name})
      })
      setListDropdown((preValue: any) => ({
        ...preValue,
        brands: obj,
      }))
    })

    getRequest(productGetUrl + '?ordering=name' + limit, true).then((res) => {
      let obj: any = [{value: '', label: 'All Products'}]
      res.results.forEach((item: any) => {
        obj.push({value: item.id, label: item.name})
      })
      setListDropdown((preValue: any) => ({
        ...preValue,
        products: obj,
      }))
    })

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.ts_promotional_discount, filterStates)
    if (
      warehouse.value == '' &&
      brand.value == '' &&
      search == '' &&
      product.value == '' &&
      (status.value == '' || status.value == 'all')
    ) {
      setButtonDisable(false)
    }
  }, [currentOffset, brand, product, warehouse, status, debounceSearch])

  useEffect(() => {
    // Develop BY: Kashyap
    // Description: when user search in dropdown i have clear offset of dropdown

    setAddBrandOffset('')
    setAddProductOffset('')
    setBrandOffset('')
    setProductOffset('')
    setWarehouseOffset('')
  }, [searchSelect])

  const fetchAddBrand = async (search?: any, offset?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let hasMore: boolean = false
    let options: any = []
    let startDate: any = discount.startDateApi
    let endDate: any = discount.endDateApi

    let brandOffset = offset
    if (offset === '1') {
      brandOffset = ''
    } else {
      brandOffset = offset
    }

    // addBrandOffset
    let url = `&start_date=${startDate}&end_date=${endDate}&ordering=name&search=${search}&limit=${limit}&offset=${brandOffset}`
    const roleResponse = await getRequest(discountBrand + url, true)
    if (roleResponse.next !== null && offset !== '1') {
      const queryParams = new URLSearchParams(roleResponse.next)
      let newOffset: any = queryParams.get('offset')
      setAddBrandOffset(newOffset)
      hasMore = true
    }
    if (roleResponse.results.length > 0) {
      roleResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (addBrand.length > 0) {
      setAddBrand(addBrand.concat(options))
    } else {
      setAddBrand(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchAddProduct = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let hasMore: boolean = false
    let options: any = []
    let startDate: any = discount.startDateApi
    let endDate: any = discount.endDateApi

    let url = `&start_date=${startDate}&end_date=${endDate}&ordering=name&search=${search}&limit=${limit}&offset=${addProductOffset}`

    const roleResponse = await getRequest(discountProduct + url, true)
    if (roleResponse.next !== null) {
      const queryParams = new URLSearchParams(roleResponse.next)
      let newOffset: any = queryParams.get('offset')
      setAddProductOffset(newOffset)
      hasMore = true
    }
    if (roleResponse.results.length > 0) {
      roleResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (addProduct.length > 0) {
      setAddProduct(addProduct.concat(options))
    } else {
      setAddProduct(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchBrand = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const BRAND_API = `?ordering=name&search=${search}&limit=${limit}&offset=${brandOffset}`
    const brandResponse = await getRequest(brandGetUrl + BRAND_API, true)
    let hasMore: boolean = false

    if (brandResponse.next !== null) {
      const queryParams = new URLSearchParams(brandResponse.next)
      let newOffset: any = queryParams.get('offset')
      setBrandOffset(newOffset)
      hasMore = true
    }
    if (brandOption.length == 0) {
      options.push({
        label: 'All Brand',
        value: '',
      })
    }
    if (brandResponse.results.length > 0) {
      brandResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }
    if (brandOption.length > 0) {
      setBrandOption(brandOption.concat(options))
    } else {
      setBrandOption(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchWarehouse = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const WAREHOUSE_API = `?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffset}`
    const response = await getRequest(apiBaseURL() + 'warehouse/list' + WAREHOUSE_API, true)
    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffset(newOffset)
      hasMore = true
    }
    if (warehouseOption.length == 0) {
      options.push({
        label: 'All Warehouse',
        value: '',
      })
    }
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }
    if (warehouseOption.length > 0) {
      setWarehouseOption(warehouseOption.concat(options))
    } else {
      setWarehouseOption(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const extendedLoadOptions = useCallback(
    async (search, offset) => {
      if (discount.discountOn == 'BRAND') {
        return await fetchAddBrand(search, offset)
      } else {
        return await fetchAddProduct(search)
      }
    },
    [
      discount.discountOn,
      addBrandOffset,
      addProductOffset,
      discount.startDateApi,
      discount.endDateApi,
    ]
  )

  const fetchProducts = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []

    const PRODUCT_API = `?ordering=name&search=${search}&limit=${limit}&offset=${productOffset}`
    const productResponse = await getRequest(productGetUrl + PRODUCT_API, true)

    let hasMore: boolean = false

    if (productResponse.next !== null) {
      const queryParams = new URLSearchParams(productResponse.next)
      let newOffset: any = queryParams.get('offset')
      setProductOffset(newOffset)
      hasMore = true
    }

    if (roles.length == 0) {
      options.push({
        label: 'All Products',
        value: '',
      })
    }

    if (productResponse.results.length > 0) {
      productResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (roles.length > 0) {
      setRoles(roles.concat(options))
    } else {
      setRoles(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchStatus = async (search?: any) => {
    let option: any = []
    let array = [
      {
        value: 'all',
        label: 'All Status',
      },
      {
        value: 'Ongoing',
        label: 'Ongoing',
      },
      {
        value: 'Upcoming',
        label: 'Scheduled',
      },
      {
        value: 'Past',
        label: 'Past',
      },
    ]

    const searchList = staticSearch(array, search)

    if (search !== '') {
      option = searchList
    } else {
      option = array
    }

    return {
      options: option,
      hasMore: false,
    }
  }

  const getListFromApi = (
    limit: any,
    offset: any,
    search: any,
    status: any,
    brands: any,
    products: any,
    warehouse: any
  ) => {
    setListLoader(true)
    let url = `?limit=${limit}&offset=${offset}&search=${search}&status=${status}&product_id__id=${products}&brand_id__id=${brands}&warehouse_id=${warehouse}`

    getRequest(`${apiBaseURL()}pricing/TS/discount_view` + url, true).then((res: any) => {
      setGetList(res.results)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.previous)
      if (res.results.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
  }

  const searchList = (e: any) => {
    setSearch(e.target.value)
    setButtonDisable(true)
    setFilterStates({...filterStates, ['search']: e.target.value})
  }

  // const numberClick = (val: any, index: any) => {
  //     if (val) {
  //         setSelected(val - 1)
  //         let newOffset = index * 10
  //         setCurrentOffset(newOffset)
  //         getListFromApi('10', newOffset, search, status.value, brand.value, product.value, warehouse.value)
  //     }
  // }

  // const prevPage = () => {
  //     if (prev !== null) {
  //         const queryParams = new URLSearchParams(prev)
  //         const newOffset = queryParams.get('offset')
  //         setCurrentOffset(newOffset)
  //         setSelected(selected - 1)
  //         getListFromApi('10', newOffset, search, status.value, brand.value, product.value, warehouse.value)
  //     }
  // }

  // const nextPage = () => {
  //     if (next !== null) {
  //         const queryParams = new URLSearchParams(next)
  //         const newOffset = queryParams.get('offset')
  //         setCurrentOffset(newOffset)
  //         setSelected(selected + 1)
  //         getListFromApi('10', newOffset, search, status.value, brand.value, product.value, warehouse.value)
  //     }
  // }

  useEffect(() => {
    if (discount.startDateView !== 'Select') {
      setDisableFeature(false)

      extendedLoadOptions('', '1')
      setBrandOffset('')
      setProductOffset('')
    }
  }, [discount.startDateView])

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const [initialDateRangePickerValue, setDateRangePickerValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    alwaysShowCalendars: false,
    cancelClass: 'btn-secondary',
    drops: 'up',
    locale: {
      format: 'DD/MM/YYYY',
    },
  })

  // const deleteFunction = () => {
  //     let promotionalDiscountDeleteUrl = promotionalDiscountPostUrl + id
  //     deleteRequest(promotionalDiscountDeleteUrl, true).then((res) => {
  //         setIsAlertSuccess(true)
  //         $('#closeDeleteEaModalDiscount').trigger('click')
  //         getListFromApi('10', currentOffset, search, status.value, brand.value, product.value, warehouse.value)
  //         setTimeout(() => {
  //             setIsAlertFailed(false)
  //             setIsAlertSuccess(false)
  //         }, 2000)
  //     })
  // }

  const filterStatus = (value: any) => {
    setStatus(value)
    setButtonDisable(true)
    setFilterStates({...filterStates, ['status']: value})
  }

  // const filter = () => { }

  const filterBrands = (value: any) => {
    setBrand(value)
    setButtonDisable(true)
    setFilterStates({...filterStates, ['brand']: value})
  }
  const filterProducts = (value: any) => {
    setProduct(value)
    setButtonDisable(true)
    setFilterStates({...filterStates, ['product']: value})
  }

  const filterWarehouse = (value: any) => {
    setWarehouse(value)
    setButtonDisable(true)
    setFilterStates({...filterStates, ['warehouse']: value})
  }

  const handleChangeReset = () => {
    setSearch('')
    setWarehouse({label: 'All Warehouses', value: ''})
    setBrand({label: 'All Brands', value: ''})
    setProduct({label: 'All Products', value: ''})
    setStatus({label: 'All Status', value: ''})
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = {...filterStates}
    updatedState['warehouseId'] = ''
    updatedState['categoryId'] = ''
    updatedState['typeId'] = ''
    updatedState['userId'] = ''
    updatedState['userType'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title='Promotional Discount' />
      <div>
        <PageTitle breadcrumbs={[priceHeaderTitle]}>
          {intl.formatMessage({id: 'Promotional Discount'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('PROMOTIONAL.DISCOUNT.ADD', messageTitle)
              : FormatMessage('PROMOTIONAL.DISCOUNT.UPDATE', messageTitle)
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message={FormatMessage('test')}
        />

        <div className={`card ${className}`}>
          {listLoadder && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <div>
              <input
                type='text'
                className='form-control form-search'
                placeholder='Search'
                onChange={searchList}
                disabled={listLoadder}
                value={search}
              />
            </div>
            <div className='ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                {/* <EaDropDown list={listDropDown.brands} placeholder="All Brands" onChange={filterBrands} value={brand} /> */}
                <AsyncPaginate
                  loadOptions={fetchWarehouse}
                  isSearchable
                  placeholder='All Warehouse'
                  className='react-select-container w-140px me-3'
                  classNamePrefix='react-select'
                  value={warehouse}
                  isDisabled={listLoadder}
                  onChange={filterWarehouse}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchBrand}
                  isSearchable
                  placeholder='All Brands'
                  className='react-select-container w-120px me-3'
                  classNamePrefix='react-select'
                  name='role'
                  value={brand}
                  isDisabled={listLoadder}
                  onChange={filterBrands}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchProducts}
                  isSearchable
                  placeholder='All Products'
                  className='react-select-container w-130px me-3'
                  classNamePrefix='react-select'
                  value={product}
                  isDisabled={listLoadder}
                  onChange={filterProducts}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  placeholder='All Status'
                  className='react-select-container w-115px me-3'
                  classNamePrefix='react-select'
                  value={status}
                  isDisabled={listLoadder}
                  onChange={filterStatus}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
              </form>
            </div>
          </div>
          <div className='card-body py-4 '>
            <div className='table-responsive'>
              {getList.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-180px'>Discount Title</th>
                      <th className='min-w-140px'>Discount (%)</th>
                      <th className='min-w-120px'>Discount On</th>
                      <th className='min-w-190px'>Duration</th>
                      <th className='min-w-190px'>Warehouse</th>
                      <th className='min-w-130px'>
                        Total Claimed
                        <br />
                        (No. of Orders)
                      </th>
                      {/* <th className='min-w-120px'>Impressions</th>
                                                    <th className='min-w-120px'>Clicks</th>
                                                    <th className='min-w-120px'>Usage</th> */}
                      <th className='min-w-100px'>Status</th>
                      {/* <th className='min-w-80px action-th py-2'>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {getList.map((item: any) => {
                      let masterArray: any = []
                      if (item.discount_on === 'Brand') {
                        item.brand_id.forEach((element: any) => {
                          masterArray.push(element)
                        })
                      }

                      if (item.discount_on === 'ProductSKU') {
                        item.product_id.forEach((element: any) => {
                          masterArray.push(element)
                        })
                      }

                      // status
                      let currentDate = moment().format('YYYY-MM-DD')
                      let tomorrowDate = moment().add(1, 'days').format('YYYY-MM-DD')
                      let masterDate = []

                      // const date = new Date('2022-10-18');
                      const date = new Date()
                      const start = new Date(item.start_date)
                      const end = new Date(item.end_date)
                      let onGoing = false
                      let shedule = false
                      let past = false
                      if (date >= start && date <= end) {
                        onGoing = true
                      } else if (date < start) {
                        shedule = true
                      } else {
                        past = true
                      }

                      return (
                        <tr>
                          <td>{item.discount_title}</td>
                          <td>{item.discount}%</td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <span>
                                {item.brand_id.length !== 0 && item.brand_id.length + ' ' + 'Brand'}{' '}
                                {item.product_id.length !== 0 &&
                                  item.product_id.length + ' ' + 'SKUs'}{' '}
                              </span>
                              <OverlayTrigger
                                delay={{hide: 450, show: 300}}
                                overlay={(props) => (
                                  <Tooltip {...props} className='tooltip-dark'>
                                    {masterArray.map((item: any, index: any) => {
                                      return (
                                        <>
                                          <span>
                                            {index ? ',' : ''} {item.name}{' '}
                                          </span>
                                        </>
                                      )
                                    })}
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <span className='ms-2 cursor-pointer'>
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen045.svg'
                                    className='ic text-primary mr-0'
                                  />
                                </span>
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td>
                            {moment(item.start_date).format(globalDateFormate())} —{' '}
                            {moment(item.end_date).format(globalDateFormate())}
                          </td>
                          {/* <td> TZS {item.total_claimed}<br />(20 Orders) -</td>*/}
                          <td>{item.warehouse_name}</td>
                          {/* <td>TZS 86652 (20 Orders)</td> */}
                          <td>-</td>
                          <td>
                            {onGoing && (
                              <span className='badge badge-light-success text-dark'>Ongoing</span>
                            )}
                            {shedule && (
                              <span className='badge badge-light-primary text-dark'>Scheduled</span>
                            )}
                            {past && <span className='badge badge-light text-dark'>Past</span>}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <CustomPaginate
          data={getList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.ts_promotional_discount}
          filterStates={filterStates}
          isDisabled={listLoadder}
        />
      </div>
    </>
  )
}

export default TSPromotionalDiscount
