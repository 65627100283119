import {checkEmpty, dateFormat, isEmpty} from '../../../../_eaFruitsDms/helpers'

export const customerLeadColumns = (navigate: any, permissionOverview: any) => {
  const navigateTo = (id: string, cId?: string) => {
    if (navigate && cId && (permissionOverview?.write || permissionOverview?.read)) {
      localStorage.setItem('customerPermission', 'allCustomer')
      localStorage.setItem('cId', cId || '')
      navigate('/customers/singlecustomer/overview')
    } else {
      navigate &&
        navigate('/customer-lead-detail', {
          state: {
            id: id,
          },
        })
    }
  }

  return [
    {
      label: 'Lead Created On',
      format: (val: any) =>
        isEmpty(val?.created_at) ? '-' : dateFormat(val?.created_at).displayDate,
      onClick: (val: any) => {
        navigateTo(val?.id, val?.status === 'registered' ? val?.customer_id : '')
      },
    },
    {
      label: 'Business Name',
      format: (val: any) => checkEmpty(val?.business_name),
      onClick: (val: any) => {
        navigateTo(val?.id, val?.status === 'registered' ? val?.customer_id : '')
      },
    },
    {
      label: 'Address',
      format: (val: any) => <div className='text-truncate w-180px'>{checkEmpty(val?.address)}</div>,
      onClick: (val: any) => {
        navigateTo(val?.id, val?.status === 'registered' ? val?.customer_id : '')
      },
    },
    {
      label: 'District',
      format: (val: any) => checkEmpty(val?.district),
      onClick: (val: any) => {
        navigateTo(val?.id, val?.status === 'registered' ? val?.customer_id : '')
      },
    },
    {
      label: 'Ward',
      format: (val: any) => checkEmpty(val?.ward),
      onClick: (val: any) => {
        navigateTo(val?.id, val?.status === 'registered' ? val?.customer_id : '')
      },
    },
    {
      label: 'Street',
      format: (val: any) => checkEmpty(val?.street),
      onClick: (val: any) => {
        navigateTo(val?.id, val?.status === 'registered' ? val?.customer_id : '')
      },
    },
    {
      label: 'Lead Created By',
      format: (val: any) => checkEmpty(val?.created_by_name),
      onClick: (val: any) => {
        navigateTo(val?.id, val?.status === 'registered' ? val?.customer_id : '')
      },
    },
    {
      label: 'Registered By',
      format: (val: any) => checkEmpty(val?.action_by_name),
      onClick: (val: any) => {
        navigateTo(val?.id, val?.status === 'registered' ? val?.customer_id : '')
      },
    },
    {
      label: 'Status',
      format: (val: any) => {
        if (isEmpty(val?.status)) {
          return '-'
        } else if (val?.status === 'lead') {
          return <span className='badge badge-light-primary text-dark'>Lead</span>
        } else if (val?.status === 'invalid') {
          return <span className='badge badge-light-danger text-dark'>Invalid</span>
        } else if (val?.status === 'registered') {
          return <span className='badge badge-light-success text-dark'>Registered</span>
        } else if (val?.status === 'pending') {
          return <span className='badge badge-light-warning text-dark'>Pending</span>
        }
      },
      onClick: (val: any) => {
        navigateTo(val?.id, val?.status === 'registered' ? val?.customer_id : '')
      },
    },
  ]
}
