import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  KTSVG,
  apiBaseURL,
  checkEmpty,
  dateTimeFormat,
  fetchAsyncDropdown,
  isEmpty,
  numberFormat,
} from '../../../_eaFruitsDms/helpers'
import {getRequest, patchRequest, putRequest} from '../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import moment from 'moment'
import toast from 'react-hot-toast'
import usePermission from '../../../_eaFruitsDms/helpers/components/usePermission'

const RouteRequestDetails = () => {
  const intl = useIntl()

  const headerTitle = {
    title: 'Route Request Details',
    subTitle: 'Route Request Details',
  }

  const initialRoute = {
    idle_time: '',
    avg_serving_time: '',
    name: '',
    sales_supervisor_name: '',
    streets: [],
    sales_team: null,
    created_by_type: '',
    created_by_name: '',
  }

  const initialValidation = {
    idle_time: '',
    avg_serving_time: '',
    name: '',
    sales_supervisor_name: '',
    streets: '',
    sales_team: '',
  }

  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
  const [routeDetailsData, setRouteDetailsData] = useState<any>(initialRoute)
  const [isDetailsLoading, setDetailsLoading] = useState(false)
  const [modal, setShowModal] = useState(false)
  const [searchSelect, setSearchSelect] = useState('')
  const [reasonOffset, setReasonOffset] = useState<any>('')
  const [reasonOption, setReasonOption] = useState<any>([])
  const [manualStockReason, setManualStockReason] = useState<any>('')
  const [reasonNote, setReasonNote] = useState<any>('')
  const [isSubmit, setIsSubmit] = useState(false)
  const [routeDetails, setRouteDetails] = useState<any>(initialRoute)
  const [validation, setValidation] = useState<any>(initialValidation)
  const [resetSalesTeam, setResetSalesTeam] = useState<any>(moment())
  const [resetStreetsTeam, setResetStreetsTeam] = useState<any>(moment())
  const [showAlert, setShowAlert] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState('Submit')
  const [uniqError, setUniqError] = useState<any>({})
  const [showRouteModal, setShowRouteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [streetsOffset, setStreetsOffset] = useState(0)
  const [streetsList, setStreetsList] = useState([])
  const [streetsDropDownSearch, setStreetsDropDownSearch] = useState('')

  const permission = usePermission('route_management')

  const showError = () => {
    const error = uniqError

    if (error && error.route_name) {
      return (
        <p className='text-danger'>
          Route should be paired with unique Streets to avoid duplicate combinations.{' '}
          {error?.streets.map((item: any, index: any) => {
            return (
              <>
                <b>
                  {index ? ',' : ''} {item.street_name}{' '}
                </b>
              </>
            )
          })}{' '}
          are already assigned
        </p>
      )
    }

    return ''
  }

  const navigate = useNavigate()
  const {state}: any = useLocation()

  const routeId = state?.routeId ?? null

  const fetchRouteDetails = async () => {
    setDetailsLoading(true)

    const url = `${apiBaseURL()}routes/requests/${routeId}`

    const response = await getRequest(url, true)

    setRouteDetailsData({
      idle_time: response?.avg_ideal_time ?? '',
      avg_serving_time: response?.avg_serving_time ?? '',
      ref_no: response?.ref_no ?? '',
      name: response?.name ?? '',
      sales_team_name: response?.sales_team_name ?? '',
      sales_supervisor_name: response?.sales_supervisor_name ?? '',
      created_by_name: response?.created_by_name ?? '',
      created_by_type: response?.created_by_type ?? '',
      updated_by_name: response?.updated_by_name ?? '',
      updated_by_type: response?.updated_by_type ?? '',
      created_at: response?.created_at ?? '',
      rejection_option_name: response?.rejection_option_name ?? '',
      rejection_note: response?.rejection_note ?? '',
      status: response?.status ?? '',
      streets: isEmpty(response?.streets) ? [] : response.streets,
      sales_team: isEmpty(response?.sales_team_id)
        ? null
        : {
            value: response?.sales_team_id,
            label: response?.sales_team_name,
          },
    })

    setRouteDetails({
      idle_time: response?.avg_ideal_time ?? '',
      avg_serving_time: response?.avg_serving_time ?? '',
      name: response?.name ?? '',
      sales_supervisor_name: response?.sales_supervisor_name ?? '',
      created_by_name: response?.created_by_name ?? '',
      created_by_type: response?.created_by_type ?? '',
      streets: isEmpty(response?.streets)
        ? []
        : response.streets.map((i: any) => ({
            value: i.street_id,
            label: i.street_name,
          })),
      sales_team: isEmpty(response?.sales_team_id)
        ? null
        : {
            value: response?.sales_team_id,
            label: response?.sales_team_name,
          },
    })

    setDetailsLoading(false)
  }

  const updateStatus = async (type: string) => {
    setIsRequestLoading(true)

    const body = {
      status: type,
      rejection_option:
        !isEmpty(manualStockReason) && manualStockReason?.label !== 'Other'
          ? manualStockReason?.value
          : null,
      rejection_note:
        !isEmpty(manualStockReason) && manualStockReason?.label === 'Other' ? reasonNote : null,
    }

    const response = await putRequest(
      `${apiBaseURL()}routes/requests/${routeId}/update`,
      body,
      true
    )

    if (response.status === 200) {
      navigate('/route/route-requests')
    } else {
      if (response && response.data && response.data.error) {
        handleError(response)
      }
    }

    setIsRequestLoading(false)
  }

  const handleError = (response: any) => {
    let errorMessage = ''
    if (response.data.error.field === 'already_approved_rejected_or_cancelled') {
      errorMessage = 'This route request has been already approved, rejected or cancelled!'
    }

    if (response.data.error.field === 'invalid_status') {
      errorMessage = 'Status should be approved, rejected or cancelled!'
    }

    if (response.data.error.field === 'rejection_option_or_note_required') {
      errorMessage =
        'Rejection option or rejection_note one field is required while reject the route request!'
    }

    errorMessage !== '' &&
      toast.error(errorMessage, {
        position: 'top-center',
      })
  }

  const rejectReason = () => {
    setIsSubmit(true)

    let valid = true

    if (isEmpty(manualStockReason)) {
      valid = false
    }

    if (manualStockReason?.label === 'Other' && reasonNote.trim() === '') {
      valid = false
    }

    if (valid) {
      updateStatus('rejected')
    }
  }

  const fetchSalesTeamModal = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      'master/sales-team?ordering=name',
      search,
      setDropDownSearch,
      'name'
    )
  }

  const fetchStreets = async (search: any) => {
    return fetchAsyncDropdown(
      streetsOffset,
      setStreetsOffset,
      streetsList,
      setStreetsList,
      `routes/street/drop-down?sales_team_id=${routeDetails?.sales_team?.value}`,
      search,
      setStreetsDropDownSearch,
      'name'
    )
  }

  const clear = () => {
    setShowModal(false)
    setReasonOffset('')
    setReasonOption([])
    setSearchSelect('')
    setReasonNote('')
    setManualStockReason('')
    setIsSubmit(false)
  }

  const fetchReason = async (search?: any) => {
    const response = await fetchAsyncDropdown(
      reasonOffset,
      setReasonOffset,
      reasonOption,
      setReasonOption,
      `master/options-master/?type=route_request_reason`,
      search,
      setSearchSelect,
      'option'
    )

    response.options.push({
      label: 'Other',
      value: null,
    })

    return response
  }

  const checkValid = () => {
    let valid = true
    let details = {...routeDetails}
    let validationHandler = {...validation}

    if (isEmpty(details.name)) {
      valid = false
      validationHandler.name = 'is-invalid'
    }

    if (details.streets.length === 0) {
      valid = false
      validationHandler.streets = 'is-invalid'
    }

    if (isEmpty(details.sales_team)) {
      valid = false
      validationHandler.sales_team = 'is-invalid'
    }

    if (isEmpty(details.idle_time)) {
      valid = false
      validationHandler.idle_time = 'is-invalid'
    }

    if (isEmpty(details.avg_serving_time)) {
      valid = false
      validationHandler.avg_serving_time = 'is-invalid'
    }

    setValidation(validationHandler)

    return valid
  }

  const updateRouteDetails = async () => {
    let valid = checkValid()

    if (valid) {
      setIsModalLoading('Please Wait...')

      const url = `${apiBaseURL()}routes/requests/${routeId}/update`

      const body = {
        name: routeDetails?.name ?? null,
        sales_team: routeDetails?.sales_team?.value ?? null,
        streets: routeDetails.streets ? routeDetails.streets.map((i: any) => i.value) : [],
        ideal_time: routeDetails?.idle_time ?? null,
        avg_serving_time: routeDetails?.avg_serving_time ?? null,
        status: 'approved',
      }

      const response = await patchRequest(url, body, true)

      let successArray = [201, 200]
      let failArray = [400, 500]

      successArray.includes(response.status) && responseSuccess()
      failArray.includes(response.status) && responseFail(response)
    }
  }

  const responseSuccess = () => {
    clearRouteModal()
    setShowAlert(true)
    fetchRouteDetails()
    setIsModalLoading('Submit')
  }

  const clearRouteModal = () => {
    setValidation(initialValidation)
    setRouteDetails(initialRoute)
    setShowRouteModal(false)
  }

  const responseFail = (response: any) => {
    setIsModalLoading('Submit')

    if (response && response.data && response.data.error) {
      if (response.data.error.field === 'combination_already_exists') {
        setUniqError(response?.data?.error?.data ?? [])
      }

      handleError(response)
    }
  }

  useEffect(() => {
    fetchRouteDetails()
  }, [routeId])

  return (
    <>
      <EATitle title='Route Request Details' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id: 'Route Request Details',
        })}
      </PageTitle>

      {isDetailsLoading ? (
        <div className='card'>
          <FetchingRecords />
        </div>
      ) : (
        <>
          <div className='card p-5'>
            <div className='d-flex'>
              <div className='w-100'>
                <h3 className='font-bold'>
                  {checkEmpty(routeDetailsData.name)} #{checkEmpty(routeDetailsData.ref_no)}
                </h3>
                <div className='d-flex'>
                  <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                  <div className='mt-1'>
                    {isEmpty(routeDetailsData.created_at)
                      ? '-'
                      : dateTimeFormat(routeDetailsData.created_at)}
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <div>
                  {routeDetailsData.status === 'approved' && (
                    <span className='badge badge-light-success text-dark'>Approved</span>
                  )}

                  {routeDetailsData.status === 'rejected' && (
                    <span className='badge badge-light-danger text-dark'>Rejected</span>
                  )}

                  {routeDetailsData.status === 'pending' && (
                    <span className='p-2 badge badge-light-warning text-dark'>Pending</span>
                  )}

                  {routeDetailsData.status === 'cancelled' && (
                    <span className='badge badge-light-dark text-dark'>Cancelled</span>
                  )}
                </div>

                {['pending'].includes(routeDetailsData.status) && permission.write && (
                  <button
                    onClick={() => {
                      fetchRouteDetails()
                      setShowRouteModal(true)
                    }}
                    type='button'
                    className='btn btn-secondary font-13 ms-5 w-100px'
                  >
                    Edit Request
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className='card mt-5 p-5'>
            <div className='font-bold fs-6 '>Route Details</div>
            <hr className='opacity-100 bg-light' />

            <div className='row'>
              <div className='col-md-3'>
                <div className='text-muted'>Route Name</div>
                <div>{checkEmpty(routeDetailsData.name)}</div>
              </div>
              <div className='col-md-3'>
                <div className='text-muted'>Requested by</div>
                <div>
                  {isEmpty(routeDetailsData.created_by_name) ? (
                    '-'
                  ) : (
                    <>
                      {checkEmpty(routeDetailsData.created_by_name)}{' '}
                      {`(${routeDetailsData.created_by_type.toUpperCase()})`}
                    </>
                  )}
                </div>
              </div>
              <div className='col-md-3'>
                <div className='text-muted'>Approved By</div>
                <div>
                  {routeDetailsData.status === 'cancelled' ? (
                    '-'
                  ) : (
                    <>
                      {isEmpty(routeDetailsData.updated_by_name) ? (
                        '-'
                      ) : (
                        <>
                          {checkEmpty(routeDetailsData.updated_by_name)}{' '}
                          {`(${routeDetailsData.updated_by_type.toUpperCase()})`}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className={'col-md-3'}>
                <div className='text-muted'>Sales Team</div>
                <div>{checkEmpty(routeDetailsData.sales_team_name)}</div>
              </div>

              <div className={'col-md-3'}>
                <div className='text-muted'>Avg. Serving Time</div>
                <div>
                  {isEmpty(routeDetailsData.avg_serving_time)
                    ? '-'
                    : `${numberFormat(routeDetailsData.avg_serving_time).displayFormat} ${
                        routeDetailsData.avg_serving_time > 0 ? 'Mins' : 'Min'
                      }`}
                </div>
              </div>

              <div className={'col-md-3'}>
                <div className='text-muted'>Avg. Idle Time</div>
                <div>
                  {isEmpty(routeDetailsData.idle_time)
                    ? '-'
                    : `${numberFormat(routeDetailsData.idle_time).displayFormat} ${
                        routeDetailsData.idle_time > 0 ? 'Mins' : 'Min'
                      }`}
                </div>
              </div>
            </div>

            {routeDetailsData.status === 'rejected' && (
              <div className='row mt-5'>
                <div className='col-md-3'>
                  <div className='text-muted'>Reject Reason</div>
                  <div>
                    {!isEmpty(routeDetailsData.rejection_option_name)
                      ? routeDetailsData.rejection_option_name
                      : isEmpty(routeDetailsData.rejection_note)
                      ? '-'
                      : routeDetailsData.rejection_note}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='p-5 card'>
            <div className='font-bold fs-6 '>Street Details</div>
            <hr className='opacity-100 bg-light' />

            <div className='col-md-6'>
              {routeDetailsData.streets && routeDetailsData.streets.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                  <thead>
                    <tr>
                      <th>Streets Name</th>
                      <th>Total Customer</th>
                    </tr>
                  </thead>

                  <tbody>
                    {routeDetailsData.streets &&
                      routeDetailsData.streets.length > 0 &&
                      routeDetailsData.streets.map((i: any) => {
                        return (
                          <tr>
                            <td>{checkEmpty(i.street_name)}</td>
                            <td>{checkEmpty(i.customer_count)}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              )}
            </div>

            {routeDetailsData.status === 'pending' && permission.write && (
              <div className='border-top mt-5 pt-5'>
                <button
                  className='btn-light btn me-3'
                  type='button'
                  disabled={isRequestLoading}
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  Reject
                </button>
                <button
                  className='btn-primary btn'
                  type='button'
                  disabled={isRequestLoading}
                  onClick={() => {
                    updateStatus('approved')
                  }}
                >
                  {isRequestLoading ? 'Please Wait' : 'Approve'}
                </button>
              </div>
            )}
          </div>
        </>
      )}

      <>
        <Modal
          show={modal}
          onHide={() => {
            clear()
          }}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
          className='call-notes-modal'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>Add Rejected Reason</h2>
            </Modal.Title>
            <div
              className='btn p-1 mh-24 btn-active-light-primary ms-2'
              onClick={() => {
                clear()
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className='mb-4'>
                <label className='form-label' id='manualStockReason'>
                  Reason<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  loadOptions={fetchReason}
                  placeholder='Select'
                  isSearchable
                  className={clsx(
                    'react-select-container w-100',
                    isSubmit && manualStockReason === '' ? 'is-invalid' : ''
                  )}
                  classNamePrefix='react-select'
                  menuPosition='fixed'
                  value={manualStockReason}
                  onChange={(e: any) => {
                    setManualStockReason(e)
                  }}
                  name='reason'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              {manualStockReason?.label === 'Other' && (
                <div>
                  <label className='form-label'>
                    Notes<span className='text-danger'>*</span>
                  </label>
                  <textarea
                    id='reason_note'
                    className={clsx(
                      'form-control',
                      isSubmit && reasonNote === '' ? 'is-invalid' : ''
                    )}
                    rows={4}
                    placeholder='Type here…'
                    name='reason_note'
                    value={reasonNote}
                    onChange={(e: any) => {
                      setReasonNote(e.target.value)
                    }}
                  ></textarea>
                </div>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={() => {
                  clear()
                }}
                disabled={isRequestLoading}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary'
                onClick={() => {
                  rejectReason()
                }}
                disabled={isRequestLoading}
              >
                {isRequestLoading ? 'Please Wait' : 'Submit'}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>

      <>
        <Modal
          show={showRouteModal}
          onHide={() => {
            setShowRouteModal(false)
            clearRouteModal()
          }}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
          className='call-notes-modal'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>Edit Route Request</h2>
            </Modal.Title>
            <div
              className='btn p-1 mh-24 btn-active-light-primary ms-2'
              onClick={() => {
                clearRouteModal()
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            {isDetailsLoading ? (
              <FetchingRecords />
            ) : (
              <>
                <div className=''>
                  <label htmlFor=''>Route Name</label>
                  <div className='p-1'>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setRouteDetails({...routeDetails, name: e.target.value})
                        setValidation({...routeDetails, name: ''})
                      }}
                      placeholder='Enter Route Name'
                      value={routeDetails.name}
                      className={clsx('form-control', validation.name)}
                    />
                  </div>
                </div>

                <div className='mt-4'>
                  <label htmlFor=''>Sales Supervisor</label>
                  <div className='p-1'>
                    <input
                      type='text'
                      placeholder='Sales Supervisor'
                      value={`${checkEmpty(routeDetails.created_by_name)}`}
                      disabled
                      className={clsx('form-control')}
                    />
                  </div>
                </div>

                <div className='mt-4'>
                  <label htmlFor=''>Sales Team</label>
                  <div className='p-1'>
                    <AsyncPaginate
                      key={resetSalesTeam}
                      loadOptions={fetchSalesTeamModal}
                      isSearchable
                      placeholder='Select'
                      value={routeDetails.sales_team}
                      onChange={(e) => {
                        setRouteDetails({...routeDetails, streets: [], sales_team: e})
                        setValidation({...routeDetails, sales_team: ''})
                      }}
                      onBlur={() => {
                        setResetSalesTeam(moment())
                        setOffset(0)
                        setList([])
                        setDropDownSearch('')
                      }}
                      className={clsx('react-select-container', validation.sales_team)}
                      classNamePrefix='react-select'
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                </div>

                <div className='mt-4'>
                  <label htmlFor=''>Streets</label>
                  <div className='p-1'>
                    <AsyncPaginate
                      key={resetStreetsTeam}
                      loadOptions={fetchStreets}
                      isSearchable
                      placeholder='Select'
                      isMulti
                      value={routeDetails.streets}
                      onChange={(e) => {
                        setRouteDetails({...routeDetails, streets: e})
                        setValidation({...routeDetails, streets: ''})
                      }}
                      onBlur={() => {
                        setResetStreetsTeam(moment())
                        setStreetsOffset(0)
                        setStreetsList([])
                        setStreetsDropDownSearch('')
                      }}
                      isDisabled={isEmpty(routeDetails?.sales_team?.value)}
                      className={clsx('react-select-container', validation.streets)}
                      classNamePrefix='react-select'
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                </div>

                <div className='mt-4'>
                  <label htmlFor=''>Avg. Serving Time</label>
                  <div className='p-1'>
                    <div
                      className={clsx('input-group  p-0')}
                      style={{
                        borderWidth: validation.avg_serving_time === 'is-invalid' ? 1 : '',
                        borderColor: validation.avg_serving_time === 'is-invalid' ? 'red' : '',
                        borderStyle: validation.avg_serving_time === 'is-invalid' ? 'solid' : '',
                      }}
                    >
                      <input
                        type='text'
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/
                          if (e.target.value === '' || re.test(e.target.value)) {
                            setRouteDetails({...routeDetails, avg_serving_time: e.target.value})
                            setValidation({...routeDetails, avg_serving_time: ''})
                          }
                        }}
                        placeholder='Enter Idle Time'
                        className={clsx('form-control border-start-0', validation.avg_serving_time)}
                        value={routeDetails.avg_serving_time}
                      />
                      <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                        Mins
                      </span>
                    </div>
                  </div>
                </div>

                <div className='mt-4'>
                  <label htmlFor=''>Avg. Idle Time</label>
                  <div className='p-1'>
                    <div
                      className={clsx('input-group  p-0')}
                      style={{
                        borderWidth: validation.idle_time === 'is-invalid' ? 1 : '',
                        borderColor: validation.idle_time === 'is-invalid' ? 'red' : '',
                        borderStyle: validation.idle_time === 'is-invalid' ? 'solid' : '',
                      }}
                    >
                      <input
                        type='text'
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/
                          if (e.target.value === '' || re.test(e.target.value)) {
                            setRouteDetails({...routeDetails, idle_time: e.target.value})
                            setValidation({...routeDetails, idle_time: ''})
                          }
                        }}
                        placeholder='Enter Idle Time'
                        className={clsx('form-control border-start-0', validation.idle_time)}
                        value={routeDetails.idle_time}
                      />
                      <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                        Mins
                      </span>
                    </div>
                  </div>
                </div>

                <div className='mt-3'>{showError()}</div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={() => {
                  clearRouteModal()
                }}
                disabled={isModalLoading !== 'Submit'}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary'
                onClick={() => {
                  updateRouteDetails()
                }}
                disabled={isModalLoading !== 'Submit'}
              >
                {isModalLoading === 'Submit' ? 'Save & Approve' : 'Please Wait...'}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export default RouteRequestDetails
