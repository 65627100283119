import clsx from 'clsx'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  dateFormat,
  globalDateFormate,
  goToTop,
  isEmpty,
  KTSVG,
  staticSearch,
} from '../../../_eaFruitsDms/helpers'
import {getRequest, patchRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import EaModalFooterIgnore from '../../../_eaFruitsDms/layout/components/modal/withoutFooterModal'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../TenantVariables'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'

type Props = {
  className: string
}
const LoadOutRequests: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Stock-Out',
    subTitle: 'Load-Out Requests',
  }
  const navigate = useNavigate()

  const [loadOutRequest, setLoadOutRequest] = useState<any>([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [srsOffSet, setSrsOffSet] = useState<any>('')
  const [srsOption, setSrsOptions] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [statusId, setStatusId] = useState('')
  const [requestTypeId, setRequestTypeId] = useState('')
  const [srsId, setSrsID] = useState('')
  //to do = kriti will improve code quality for pagination
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [searchByName, setSearchByName] = useState<string>('')
  const [preOrders, setPreOrders] = useState<any>([])
  const [requestedId, setRequestedId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [dateType, setDateType] = useState('today')
  const [requestCount, setRequestCount] = useState<any>({
    today: 0,
    tomorrow: 0,
    previous: 0,
  })

  useEffect(() => {
    fetchLoadOutRequest().then()
  }, [currentOffset, searchByName, statusId, srsId, requestTypeId, dateType])

  useEffect(() => {
    setSrsOffSet('')
  }, [searchSelect])

  useEffect(() => {
    fetchPreOrder().then()
  }, [requestedId])

  const fetchPreOrder: any = async () => {
    let PRE_ORDER_API = `${apiBaseURL()}preorder/get_connected_preorder/?request_id=${requestedId}`
    const preOrderResponse = await getRequest(PRE_ORDER_API, true)
    setPreOrders(preOrderResponse)
  }
  const fetchLoadOutRequest: any = async () => {
    let loadRequestListAPI = `${apiBaseURL()}preorder/oo-load-out-request/?limit=${limit}&offset=${currentOffset}&ordering=&date_type=${dateType}`

    if (searchByName !== '') {
      loadRequestListAPI += `&search=${searchByName}`
    }
    if (statusId !== '') {
      loadRequestListAPI += `&oo_status=${statusId}`
    }
    if (requestTypeId !== '') {
      loadRequestListAPI += `&request_type=${requestTypeId}`
    }
    if (srsId !== '') {
      loadRequestListAPI += `&sr_id=${srsId}`
    }

    setDisplayLoader(true)

    const loadOutResponse = await getRequest(loadRequestListAPI, true)

    setDisplayLoader(false)

    if (loadOutResponse.results) {
      setLoadOutRequest(loadOutResponse.results)
      setCount(loadOutResponse.count)
      setNext(loadOutResponse.next)
      setPrev(loadOutResponse.previous)

      if (loadOutResponse.meta) {
        setRequestCount({
          ...requestCount,
          ['today']: loadOutResponse.meta.today_count,
          ['tomorrow']: loadOutResponse.meta.tomorrow_count,
          ['previous']: loadOutResponse.meta.previous_count,
        })
      }
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }
  const fetchStatus: any = (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        label: 'All Status',
        value: '',
      },
      {
        label: 'Process',
        value: 'Process',
      },
      {
        label: 'Processing',
        value: 'Processing',
      },
      {
        label: 'Processed',
        value: 'Processed',
      },
    ]
    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchRequestType: any = (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        label: 'All Request types',
        value: '',
      },
      {
        label: 'With Pre-Order(s)',
        value: 'WithPreorder',
      },
      {
        label: 'Without Pre-Order',
        value: 'WithoutPreorder',
      },
    ]
    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const featchSrs = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const LOAD_OUT_API = `${apiBaseURL()}preorder/oo-load-out-request/get_oo_sr/?search=${search}&limit=${limit}&offset=${srsOffSet}`
    const response = await getRequest(LOAD_OUT_API, true)
    let hasMore: boolean = false
    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setSrsOffSet(newOffset)
      hasMore = true
    }
    if (srsOption.length === 0) {
      options.push({
        label: 'From All SR',
        value: '',
      })
    }
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name + ' ' + option.last,
          value: option.id,
        })
      })
    }
    if (srsOption.length > 0) {
      setSrsOptions(srsOption.concat(options))
    } else {
      setSrsOptions(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const filterChangeHandler = (e: any, name: string) => {
    setSelected(0)
    setCurrentOffset(0)

    switch (name) {
      case 'status':
        setStatusId(e.value)
        break
      case 'requestType':
        setRequestTypeId(e.value)
        break
      case 'srs':
        setSrsID(e.value)
        break
      case 'search':
        setSearchByName(e.target.value)
        break
    }
  }

  const navigateToPreOrderDetail = (e: any) => {
    localStorage.setItem('oId', e.id)
    navigate('/orders/order-detail')
  }

  const processOrder = async (requestId: number) => {
    const orderData = {
      request_status: 'InProgress',
      oo_status: 'Processing',
    }
    setDisplayLoader(true)
    const res = await patchRequest(
      `${apiBaseURL()}preorder/update_load_out_request/${requestId}/`,
      orderData,
      true
    )
    setDisplayLoader(false)

    if (res && !isEmpty(res.status) && res.status === 400) {
      setErrorMessage('This order already processing by some one else.!')
      goToTop()
      setIsAlertFailed(true)

      setTimeout(() => {
        setIsAlertFailed(false)
      }, 3500)
    } else {
    }
    navigateToLoadOutRequestSR(requestId)
  }

  const navigateToLoadOutRequestSR = (requestId: number, request?: any) => {
    if (request) {
      if (request.oo_status.toLowerCase() === 'processing') {
        const userId: string = localStorage.getItem('user_id') || ''
        if (request.processed_by && request.processed_by.id === parseInt(userId)) {
          navigate('/load-out-requests-from-SR', {
            state: {
              requestId: requestId,
              buttonDisabled: false,
            },
          })
        } else {
          setErrorMessage('This order already processing by some one else.!')
          goToTop()
          setIsAlertFailed(true)

          setTimeout(() => {
            setIsAlertFailed(false)
          }, 3500)
        }
      } else {
        navigate('/load-out-requests-from-SR', {
          state: {
            requestId: requestId,
            buttonDisabled: true,
          },
        })
      }
    } else {
      navigate('/load-out-requests-from-SR', {
        state: {
          requestId: requestId,
          buttonDisabled: false,
        },
      })
    }
  }

  // success/failed message function
  const isAlertShow = (val: any) => {
    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <EATitle title='Load-Out Requests' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({id: 'Load-Out Requests'})}
      </PageTitle>
      <EaAlertDanger
        onClick={() => isAlertShow('failed')}
        show={isAlertFailed}
        message={errorMessage}
      />
      {/* <div className="alert bg-light-warning d-flex flex-sm-row py-3 px-5 align-items-center mb-5">
			 <div className="text-dark pe-2">
			 Warning
			 </div>
			 <button type="button" className="position-relative btn ms-auto p-0" data-bs-dismiss="alert">
			 <span className="svg-icon-dark">
			 <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="ic me-0" />
			 </span>
			 </button>
			 </div> */}
      {/*<div className='bg-light-warning  d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>*/}
      {/*  <span className="svg-icon-dark">*/}
      {/*    <KTSVG path="/media/icons/duotune/general/gen007.svg" className="ic me-3" />*/}
      {/*  </span>*/}
      {/*  <div className="text-dark pe-2">*/}
      {/*    There are some pre-orders for which the SRs are yet to send loadout requests.*/}
      {/*  </div>*/}
      {/*  <a href="#ea_modal_send_reminder"*/}
      {/*    data-bs-toggle='modal'*/}
      {/*    data-bs-target='#ea_modal_send_reminder'*/}
      {/*    className='text-dark text-hover-decoration-underline text-hover-dark ms-auto fw-500'>Send Reminder*/}
      {/*    <KTSVG path="/media/icons/duotune/arrows/arr071.svg" className="ic" />*/}
      {/*  </a>*/}
      {/*</div>*/}
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          {displayLoader && <IsLoader />}
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => filterChangeHandler(e, 'search')} />
            <div className='col-auto ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                <div className='btn-group me-3' role='group'>
                  <button
                    type='button'
                    className={clsx(
                      'btn btn-outline btn-outline-primary border-secondary text-dark' +
                        ' text-hover-white text-active-white',
                      dateType === 'today' && 'active'
                    )}
                    onClick={() => setDateType('today')}
                  >
                    Today&nbsp;({requestCount.today})
                  </button>
                  <button
                    type='button'
                    className={clsx(
                      'btn btn-outline btn-outline-primary border-secondary' +
                        ' text-dark text-hover-white text-active-white',
                      dateType === 'tomorrow' && 'active'
                    )}
                    onClick={() => setDateType('tomorrow')}
                  >
                    Tomorrow&nbsp;({requestCount.tomorrow})
                  </button>
                  <button
                    type='button'
                    className={clsx(
                      'btn btn-outline btn-outline-primary border-secondary' +
                        ' text-dark text-hover-white text-active-white',
                      dateType === 'previous' && 'active'
                    )}
                    onClick={() => setDateType('previous')}
                  >
                    Previous&nbsp;({requestCount.previous})
                  </button>
                </div>
                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  onChange={(e) => filterChangeHandler(e, 'status')}
                  className='react-select-container my-1 me-3 w-115px'
                  placeholder='All Status'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchRequestType}
                  isSearchable
                  onChange={(e) => filterChangeHandler(e, 'requestType')}
                  className='react-select-container my-1 me-3 w-160px'
                  placeholder='All Request types'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={featchSrs}
                  isSearchable
                  placeholder='From All SRs'
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  name='srs'
                  onChange={(e: any) => filterChangeHandler(e, 'srs')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          {/* begin::Table container */}
          {loadOutRequest.length == 0 ? (
            <NoRecords />
          ) : (
            <>
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-gray-100 table-row-bordered align-middle gs-0 gy-2 mt-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='w-100px min-w-100px'>Request ID</th>
                      <th className='w-120px min-w-120px'>Date & Time</th>
                      <th className='min-w-150px'>Request Type</th>
                      <th className='min-w-120px'>From</th>
                      <th className='min-w-150px'>Processed By</th>
                      <th className='min-w-170px text-center'>Connected Pre-orders</th>
                      <th className='min-w-120px text-end'>Action</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {loadOutRequest.map((item: any) => {
                      const loadOutDate =
                        item.load_out_request_date + ' ' + item.load_out_request_time

                      return (
                        <>
                          <tr
                          // onClick={navigateToLoadOutRequestSR}
                          //	className="cursor-pointer"
                          >
                            <td>
                              {item.request_id.includes('#')
                                ? item.request_id
                                : '#' + item.request_id}
                            </td>
                            <td>
                              {dateFormat(loadOutDate).displayDate}{' '}
                              <p className='font-13 text-muted mb-0'>
                                {dateFormat(loadOutDate, 'LT').displayDate}
                              </p>
                            </td>
                            <td>
                              {item && item.request_type === 'WithPreorder'
                                ? 'With Pre-Order(s)'
                                : 'Without Pre-Order'}
                            </td>
                            <td>{item && item.request_sr}</td>
                            <td>{item && item.processed_by ? item.processed_by.name : '-'}</td>
                            <td className='text-center'>
                              {item && item.request_type === 'WithPreorder' ? (
                                <EaButton
                                  className='btn btn-secondary'
                                  isModal={true}
                                  onClick={() => setRequestedId(item.request_id)}
                                  btnName={item && item.pre_order.length}
                                  dataTarget='#preorder_request_order_modal'
                                ></EaButton>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className='text-end'>
                              {item.oo_status.toLowerCase() === 'process' && (
                                <button
                                  className='btn btn-primary'
                                  disabled={dateType === 'previous'}
                                  onClick={() => processOrder(item.id)}
                                >
                                  Process
                                </button>
                              )}
                              {item.oo_status.toLowerCase() === 'processing' && (
                                <button
                                  className='badge badge-light-warning text-dark border-0'
                                  disabled={dateType === 'previous'}
                                  onClick={() => navigateToLoadOutRequestSR(item.id, item)}
                                >
                                  Processing
                                </button>
                              )}
                              {item.oo_status.toLowerCase() === 'processed' && (
                                <button
                                  className='badge badge-light text-dark border-0'
                                  disabled={dateType === 'previous'}
                                  onClick={() => navigateToLoadOutRequestSR(item.id, item)}
                                >
                                  Processed
                                </button>
                              )}
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
            </>
          )}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CustomPaginate
        data={loadOutRequest}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />

      {/* preorder request modal */}

      <EaModalFooterIgnore
        id='preorder_request_order_modal'
        modalTitle='Pre-orders in Loadout Request'
        closeAuto='closeEaModalPreOrder'
        requestedId={requestedId}
      >
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-hover table-row-gray-100 table-row-bordered align-middle gs-0 gy-2 mt-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='text-muted fw-500'>
                <th className='min-w-80px'>Order ID</th>
                <th className='min-w-100px'>Order Date</th>
                <th className='min-w-120px'>Customer Name</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {preOrders.map((item: any) => {
                return (
                  <>
                    <tr className='cursor-pointer'>
                      <td>{item.order_id.includes('#') ? item.order_id : '#' + item.order_id}</td>
                      <td>
                        <span>
                          {moment(
                            item.order_date.concat(item.order_date),
                            'YYYY-MM-DD-h:m:s'
                          ).format(globalDateFormate())}
                        </span>
                      </td>
                      <td>{item && item.customer_name}</td>
                      <td
                        className=' text-end'
                        id='closeEaModalPreOrder'
                        data-bs-dismiss='modal'
                        onClick={() => navigateToPreOrderDetail(item)}
                      >
                        <span className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                          <KTSVG path='/media/icons/duotune/arrows/arr007.svg' />
                        </span>
                      </td>
                    </tr>
                  </>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </EaModalFooterIgnore>

      {/* Send reminder */}
      <EaModal
        modalTitle='Reminder Sent'
        id={'ea_modal_send_reminder'}
        actionBtnName='Okay'
        cancelBtnDisabled={true}
        children={
          <>
            <p>
              Reminder sent to the respective SRs to send the loadout requests for the pre-orders
              successfully.
            </p>
          </>
        }
      />
    </>
  )
}

export default LoadOutRequests
