import React from 'react'
import {useNavigate} from 'react-router-dom'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import Select from 'react-select'
import {useIntl} from 'react-intl'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'

const NewMessages = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'New Messages',
  }

  return (
    <>
      <EATitle title='New Messages - ' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'New Messages'})}
      </PageTitle>

      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <DateRangePicker
                initialSettings={{
                  startDate: '01/01/2020',
                  endDate: '01/12/2020',
                  alwaysShowCalendars: true,
                  cancelClass: 'btn-secondary',
                  drops: 'auto',
                  locale: {
                    format: 'DD/MM/YYYY',
                  },
                  ranges: {
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [
                      moment().subtract(1, 'month').startOf('month'),
                      moment().subtract(1, 'month').endOf('month'),
                    ],
                  },
                }}
              >
                <input type='text' className='form-control calendar w-auto' />
              </DateRangePicker>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
              {/* begin::Table head */}
              <thead>
                <tr className='text-muted fw-500'>
                  <th className='min-w-100px py-2'>Complaint ID</th>
                  <th className='min-w-120px py-2'>Support Request Title</th>
                  <th className='min-w-120px py-2'>Customer Name</th>
                  <th className='min-w-120px py-2'>Created On</th>
                  <th className='min-w-120px py-2'>Last Updated</th>
                  <th className='min-w-120px py-2'>Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr className='cursor-pointer' onClick={() => navigate('overview')}>
                  <td>#10242</td>
                  <td>Support Request Title</td>
                  <td>Darika Samak</td>
                  <td>
                    01 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    01 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    <span className='badge badge-light-warning text-dark'>Closed</span>
                  </td>
                </tr>
                <tr className='cursor-pointer'>
                  <td>#10242</td>
                  <td>Support Request Title</td>
                  <td>Darika Samak</td>
                  <td>
                    01 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    01 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    <span className='badge badge-light-success text-dark'>Open</span>
                  </td>
                </tr>
                <tr className='cursor-pointer'>
                  <td>#10242</td>
                  <td>Support Request Title</td>
                  <td>Darika Samak</td>
                  <td>
                    01 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    01 May 2022
                    <p className='font-13 text-muted mb-0'>05:32PM</p>
                  </td>
                  <td>
                    <span className='badge badge-light-primary text-dark'>Assigned</span>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>

            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>Showing 1 to 10 of 50 entries</div>
          <div className='col'>
            <CustomPagination />
          </div>
        </div>
      </div>
    </>
  )
}

export default NewMessages
