import React, {useEffect, useState} from 'react'
import {getToken, onMessageListener} from '../firebase/firebaseInit'
import {apiBaseURL} from '../helpers'
import {postRequest} from '../https/apiCall'

const Notifications = (props: any) => {
  const [isTokenFound, setTokenFound] = useState(false)

  // To load once
  useEffect(() => {
    let data

    async function tokenFunc() {
      data = await getToken(setTokenFound)
      if (data) {
        const deviceId = localStorage.getItem('device_id')
        const fcmToken = localStorage.getItem('device_token')

        if (deviceId != null && fcmToken != data) {
          const updateFcmTokeData = {
            device_id: deviceId,
            device_token: data,
          }

          await postRequest(`${apiBaseURL()}auth/update_fcm_token`, updateFcmTokeData, true)
        }

        localStorage.setItem('device_token', data)
      }
      return data
    }

    tokenFunc()

    onMessageListener()
      .then((payload: any) => {
        console.log('foreground notification received====== ')
      })
      .catch((err) => console.log('failed: ', err))
  }, [setTokenFound])

  return <></>
}

Notifications.propTypes = {};

export default Notifications;
