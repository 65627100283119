import clsx from 'clsx'
import * as React from 'react'
import {useEffect, useState} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  dateFormat,
  GLOBAL_DATE_FORMAT,
  isEmpty,
  KTSVG,
  measurementLabel,
  numberFormat,
} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import SkuThumbnail from '../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../TenantVariables'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {categoryStyle} from '../../../_eaFruitsDms/helpers/components/categoryStyle'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'

type Props = {
  className: string
}

const CurrentStock: React.FC<Props> = ({className}) => {
  const API_BASE_URL = tenantConfiguration.apiBaseUrl
  const currentWarehouseId: any = localStorage.getItem('w_id')
  const expiry_report: any = localStorage.getItem('expiry_report')

  const intl = useIntl()

  const headerTitle = {
    title: 'Current Stock',
    subTitle: 'Current Stock',
  }

  const [currentStock, setCurrentStock] = useState<any>([])
  const [isDisplayLoader, setIsDisplayLoader] = useState<boolean>(false)
  const [selectedBrand, setSelectedBrand] = useState<number>(0)
  const [selectedCategory, setSelectedCategory] = useState<number>(0)
  const [searchByName, setSearchByName] = useState<string>('')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [timer, setTimer] = useState<any>(null)
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [selectedLowStockProduct, setSelectedLowStockProduct] = useState<string>('')
  const [brandOffset, setBrandOffset] = useState<any>('')
  const [brandOption, setBrandOption] = useState<any>([])
  const [categoryOffset, setCategoryOffset] = useState<any>('')
  const [categoryOption, setCategoryOption] = useState<any>([])
  const [productsOffset, setProductsOffset] = useState<any>('')
  const [searchSelect, setSearchSelect] = useState('')
  const [groupOffSet, setGroupOffSet] = useState<any>('')
  const [groupOption, setGroupOption] = useState<any>([])
  const [selectedGroupId, setSelectedGroupId] = useState<any>('')
  const [preOrder, setPreOrder] = useState<any>('')
  const [isExpanded, setIsExpanded] = useState<any>('parentExpanded')
  const [skuName, setSkuName] = useState<any>('All Stocked SKUs')
  const [isAlertSuccess, setIsAlertSuccess] = useState<boolean>(false)
  const [message, setMessage] = useState("");

  useEffect(() => {
    const offloadMessage = localStorage.getItem('offload_message') || ""
    if (!isEmpty(offloadMessage)) {
      setMessage(offloadMessage)
      setIsAlertSuccess(true)

      localStorage.removeItem('offload_message')

      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 1500)
    }
  }, []);

  useEffect(() => {
    setBrandOffset('')
    setCategoryOffset('')
    setProductsOffset('')
    setGroupOffSet('')
  }, [searchSelect])

  useEffect(() => {
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      fetchCurrentStock(
        limit,
        currentOffset,
        currentWarehouseId,
        selectedBrand,
        selectedCategory,
        selectedLowStockProduct,
        searchByName
      ).then(() => {})
    }, timeoutSeconds)
    setTimer(newTimer)
  }, [
    selectedBrand,
    selectedCategory,
    selectedLowStockProduct,
    selectedGroupId,
    searchByName,
    preOrder,
    currentOffset,
  ])

  const fetchCurrentStock = async (
    limit: number,
    offset: any,
    w_id: number,
    selectedBrand: number,
    selectedCategory: number,
    selectedLowStockProduct: string,
    searchByName: string
  ) => {
    setIsDisplayLoader(true)

    let currentStockAPI = `${API_BASE_URL}inventory/product-SKUs/?warehouse_id=${w_id}&ordering=name&expired_units=True&trade_only=True&limit=${limit}&offset=${offset}&warehouse_id=${w_id}&variants__group_name__id=${selectedGroupId}&exclude_pre=${preOrder}&include_zero=True`

    if (selectedBrand != 0) {
      currentStockAPI += `&brand_id__id=${selectedBrand}`
    }

    if (selectedCategory != 0) {
      currentStockAPI += `&category_id__id=${selectedCategory}`
    }

    if (expiry_report === 'true') {
      currentStockAPI += '&expired_stock=true'
      setSkuName('Near Expiry SKUs')
    } else {
      currentStockAPI += `&${selectedLowStockProduct}`
    }

    // if (selectedLowStockProduct != '') {
    //   currentStockAPI += `&${selectedLowStockProduct}`
    // }

    if (searchByName != '') {
      currentStockAPI += `&search=${searchByName}`
    }

    const currentStockResponse = await getRequest(currentStockAPI, true)

    setCurrentStock(currentStockResponse.results)
    setCount(currentStockResponse.count)
    setNext(currentStockResponse.next)
    setPrev(currentStockResponse.previous)
    setIsDisplayLoader(false)
    localStorage.removeItem('expiry_report')
  }

  const fetchBrand = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []

    const BRAND_API = `${apiBaseURL()}master/product/brand?ordering=name&search=${search}&limit=${limit}&offset=${brandOffset}`

    const brandResponse = await getRequest(BRAND_API, true)

    let hasMore: boolean = false

    if (brandResponse.next !== null) {
      const queryParams = new URLSearchParams(brandResponse.next)
      let newOffset: any = queryParams.get('offset')
      setBrandOffset(newOffset)
      hasMore = true
    }

    if (brandOption.length == 0) {
      options.push({
        label: 'All Brands',
        value: 0,
      })
    }

    if (brandResponse.results.length > 0) {
      brandResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (brandOption.length > 0) {
      setBrandOption(brandOption.concat(options))
    } else {
      setBrandOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffset}`,
      true
    )

    let hasMore: boolean = false

    if (categoriesResponse.next !== null) {
      const queryParams = new URLSearchParams(categoriesResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffset(newOffset)
      hasMore = true
    }

    if (categoryOption.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    if (categoriesResponse.results.length > 0) {
      categoriesResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          color: '#6d6e6f',
          bg: '#f7f7f7',
          transform: 'uppercase',
          boxSizing: 'borderBox',
          weight: '500',
          cursor: 'default',
          size: '.75rem !important',
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }

    if (categoryOption.length > 0) {
      setCategoryOption(categoryOption.concat(options))
    } else {
      setCategoryOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchGroups = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${limit}&offset=${groupOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setGroupOffSet(newOffset)
      hasMore = true
    }
    if (groupOption.length == 0) {
      options.push({
        label: 'All Groups',
        value: '',
      })
    }
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (groupOption.length > 0) {
      setGroupOption(groupOption.concat(options))
    } else {
      setGroupOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleFilterOptionChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'brand':
        setTimeoutSeconds(0)
        setSelectedBrand(inputValue)
        break
      case 'category':
        setTimeoutSeconds(0)
        setSelectedCategory(inputValue)
        break
      case 'group':
        setTimeoutSeconds(0)
        setSelectedGroupId(inputValue)
        break
      case 'stock_type':
        setTimeoutSeconds(0)
        setSelectedLowStockProduct(inputValue)
        localStorage.removeItem('expiry_report')
        break
      case 'pre_order':
        setTimeoutSeconds(0)
        if (e.target.checked == false) {
          setPreOrder('')
          setIsExpanded('true')
        } else {
          setPreOrder(e.target.checked)
          setIsExpanded('')
        }
        break
      case 'search':
        setTimeoutSeconds(500)
        setSearchByName(inputValue)
        break
    }
  }

  const prevPage = async () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      await fetchCurrentStock(
        limit,
        newOffset,
        currentWarehouseId,
        selectedBrand,
        selectedCategory,
        selectedLowStockProduct,
        searchByName
      )
    }
  }

  const nextPage = async () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      await fetchCurrentStock(
        limit,
        newOffset,
        currentWarehouseId,
        selectedBrand,
        selectedCategory,
        selectedLowStockProduct,
        searchByName
      )
    }
  }

  const numberClick = async (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      await fetchCurrentStock(
        limit,
        newOffset,
        currentWarehouseId,
        selectedBrand,
        selectedCategory,
        selectedLowStockProduct,
        searchByName
      )
    }
  }

  const fetchLowStockProduct = () => {
    const options = [
      {
        label: 'All Stocked SKUs',
        value: '',
      },
      {
        label: 'Low Stock SKUs Only',
        value: 'low=True',
      },
      {
        label: 'Near Expiry SKUs Only',
        value: 'expired_stock=true',
      },
    ]

    return {
      options,
      hasMore: false,
    }
  }

  const isAlertShow = (val: any) => {
    if (val === 'success') {
      setIsAlertSuccess(false)
    }
  }

  return (
    <>
      <EATitle title='Current Stock' />
      <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({id: 'Current Stock'})}</PageTitle>
      {
        !isEmpty(message) && <EaAlertSuccess
          onClick={() => isAlertShow('success')}
          show={isAlertSuccess}
          message={message}
        />
      }

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={handleFilterOptionChange} />
            <div className='col-auto ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
                className='card-toolbar d-flex my-0'
              >
                <div className='form-check form-check-sm form-check-custom form-check-solid cursor'>
                  <input
                    className='form-check-input widget-13-check mx-2'
                    name='pre_order'
                    onChange={(e: any) => handleFilterOptionChange(e, 'pre_order')}
                    type='checkbox'
                    value=''
                    id='Pre-Orders'
                  />
                  <label htmlFor='Pre-Orders' className='form-label my-1 me-3'>
                    Include Pre-Orders
                  </label>
                  <OverlayTrigger
                    delay={{hide: 450, show: 300}}
                    overlay={(props) => (
                      <Tooltip {...props} className='tooltip-dark'>
                        Current Stock(minus) Today's Tomorrow's Pre-orders releated Holded Stock
                      </Tooltip>
                    )}
                    placement='top'
                  >
                    <span className='cursor-pointer me-3'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='ic text-primary me-0'
                      />
                    </span>
                  </OverlayTrigger>
                </div>

                <AsyncPaginate
                  loadOptions={fetchBrand}
                  isSearchable
                  className='react-select-container my-1 me-3 w-115px'
                  classNamePrefix='react-select'
                  name='role'
                  placeholder='All Brands'
                  onChange={(e: any) => handleFilterOptionChange(e, 'brand')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  loadOptions={fetchCategory}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  placeholder='All Categories'
                  styles={categoryStyle}
                  onChange={(e: any) => handleFilterOptionChange(e, 'category')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  loadOptions={fetchGroups}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  value={groupOption.filter((option: any) => option.value == selectedGroupId)}
                  onChange={(e: any) => handleFilterOptionChange(e, 'group')}
                  placeholder='All Group'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchLowStockProduct}
                  isSearchable
                  className='react-select-container my-1 me-3 w-170px'
                  classNamePrefix='react-select'
                  placeholder={skuName}
                  name='stock_type'
                  onChange={(e: any) => handleFilterOptionChange(e, 'stock_type')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                {/*<button*/}
                {/*  type='button'*/}
                {/*  onClick={exportToExcel}*/}
                {/*  className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center'*/}
                {/*>*/}
                {/*  <KTSVG*/}
                {/*    path='/media/icons/duotune/files/fil021.svg'*/}
                {/*    className='svg-icon-muted ic'*/}
                {/*  />*/}
                {/*  Export*/}
                {/*</button>*/}
                {/* </div> */}
              </form>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {isDisplayLoader && <IsLoader />}
          {/* begin::Table container */}
          <div
            className='table-responsive expandable'
            style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
            id='expandTable'
          >
            {currentStock.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                <thead>
                  <tr className=' text-muted fw-500'>
                    <th className='min-w-220px py-2'>Product SKU</th>
                    <th className='min-w-100px py-2'>Brand</th>
                    <th className='min-w-120px py-2'>Category</th>
                    <th className='min-w-150px py-2'>Product Group</th>
                    <th className='min-w-100px py-2'>Variant</th>
                    <th className='min-w-100px py-2'>Size</th>
                    <th className='min-w-120px py-2'>Current Stock</th>
                    <th className='min-w-120px py-2'>Expired Qty.</th>
                    <th className='min-w-100px py-2'>Near Expiry</th>
                    <th className='min-w-100px py-2'>Batches</th>
                    <th className='text-end min-w-30px'></th>
                  </tr>
                </thead>
                <tbody>
                  {currentStock.map((stock: any) => {
                    const quantityLabel: string = measurementLabel(
                      stock.fix_size
                        ? stock.material.name
                        : stock.variants && stock.variants.approx_weight > 0
                        ? stock.material.name
                        : stock.measurement_unit
                    )

                    let approxWeight = 0

                    if (stock.variants && stock.variants.approx_weight > 0) {
                      approxWeight = stock.variants.approx_weight / 1000
                    }

                    return (
                      <>
                        <tr
                          data-bs-toggle={`${isExpanded ? `collapse` : ``}`}
                          data-bs-target={`${isExpanded ? `.row-collapse${stock.id}` : ``}`}
                          aria-controls={`${isExpanded ? `rowCollapse${stock.id}` : ``}`}
                          className={`${isExpanded ? `cursor-pointer` : `not-expanded`}`}
                          aria-expanded={`${isExpanded ? false : true}`}
                          style={{backgroundColor: `${stock.total_qty == 0 && '#F7F7F7'}`}}
                        >
                          <td>
                            <div className='d-flex align-items-center'>
                              <SkuThumbnail src={stock.product_thumbnail} skuName={stock.name} />
                              {stock.additional_notes != '' ? (
                                <OverlayTrigger
                                  delay={{hide: 450, show: 300}}
                                  overlay={(props) => (
                                    <Tooltip {...props} className='tooltip-dark'>
                                      {stock.additional_notes}
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <a href='#' className='ms-2'>
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen045.svg'
                                      className='ic text-primary mr-0'
                                    />
                                  </a>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                          <td>{stock.brand_id}</td>
                          <td>
                            <div>
                              <div className='text-muted font-13 mb-1 font-medium'>
                                {stock.parent_category}
                              </div>
                              {stock.category_id}
                            </div>
                          </td>
                          <td>{stock.group_name}</td>
                          <td>{stock.variants.name}</td>
                          <td>
                            {approxWeight > 0
                              ? approxWeight + ' Kgs'
                              : (stock.measurement_unit.toLowerCase() === 'gram'
                                  ? stock.size / 1000
                                  : stock.size) + ' Kgs'}
                          </td>
                          <td>
                            {`${numberFormat(stock.total_qty).displayFormat}`} {quantityLabel}
                            {stock.low_stock ? (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr057.svg'
                                className='ic ms-1'
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            {`${numberFormat(stock.expired_qty).displayFormat}` +
                              ' ' +
                              quantityLabel}
                          </td>
                          <td>
                            <div>{stock.near_expiry + ' ' + quantityLabel}</div>
                          </td>
                          <td>{stock.batches}</td>
                          <td className='text-end'>
                            {/* <button
                            //   type='button'
                            //   className='mt-n6 position-relative btn bg-white p-0 accordion-button w-auto ms-auto collapsed'
                            //   data-bs-toggle='collapse'
                            //   data-bs-target={'.row-collapse' + stock.id}
                            //   aria-controls={'.rowCollapse' + stock.id}
                            ></button> */}
                          </td>
                        </tr>
                        <tr className='expandable-row'>
                          <td className='reset-expansion-style' colSpan={11}>
                            <div
                              className={`${
                                isExpanded
                                  ? `row-expansion-style collapse collapsible row-collapse${stock.id}`
                                  : `row-expansion-style collapse collapsible row-collapse`
                              }`}
                              id={`rowCollapse${stock.id}`}
                              data-bs-parent='#expandTable'
                            >
                              <div>
                                {stock.product_stocking.length === 0 ? (
                                  <NoRecords />
                                ) : (
                                  <table className='table table-bordered gs-4 mb-0'>
                                    <thead>
                                      <tr className='text-muted fw-500 bg-light2'>
                                        <th className='w-120px py-2'>Batch Number</th>
                                        <th className='w-130px py-2'>Qty.</th>
                                        <th>Expiry Date</th>
                                        <th>Last Updated On</th>
                                        <th>Last Updated By</th>
                                        {/*<th>Added Date</th>*/}
                                        {/*<th>Added By</th>*/}
                                        {/*<th colSpan={3}>Type</th>*/}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {stock.product_stocking.map((productStock: any) => {
                                        return (
                                          <tr className=''>
                                            {/*<tr className='bg-light-danger'>*/}
                                            <td>
                                              {productStock.batch_number.includes('#')
                                                ? productStock.batch_number
                                                : '#' + productStock.batch_number}
                                            </td>
                                            <td>
                                              {`${numberFormat(productStock.units).displayFormat}` +
                                                ' ' +
                                                quantityLabel}
                                            </td>
                                            <td>
                                              <span
                                                className={clsx(
                                                  productStock.is_expiry && 'text-danger'
                                                )}
                                              >
                                                {dateFormat(productStock.expiry_date).displayDate}
                                              </span>
                                            </td>
                                            <td>
                                              <span>
                                                {productStock.action_by
                                                  ? dateFormat(
                                                      productStock.updated_date,
                                                      GLOBAL_DATE_FORMAT + ' LT'
                                                    ).displayDate
                                                  : '-'}
                                              </span>
                                            </td>
                                            <td>
                                              <span>
                                                {productStock.action_by
                                                  ? productStock.action_by.first_name +
                                                    ' ' +
                                                    productStock.action_by.last_name
                                                  : '-'}
                                              </span>
                                            </td>

                                            {/*<td>-</td>*/}
                                            {/*<td>-</td>*/}
                                            {/*<td>-*/}
                                            {/*    /!*<KTSVG*!/*/}
                                            {/*    /!*    path='/media/icons/duotune/communication/com003.svg'*!/*/}
                                            {/*    /!*    className='ic mr-0 ms-2'/>*!/*/}
                                            {/*</td>*/}
                                            {/*<td className='text-end'>*/}
                                            {/*    <a href='#!'*/}
                                            {/*        className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'*/}
                                            {/*        data-bs-toggle='modal'*/}
                                            {/*        data-bs-target='#ea_modal_update_stock'*/}
                                            {/*    >*/}
                                            {/*        <KTSVG*/}
                                            {/*            path='/media/icons/duotune/art/art005.svg'*/}
                                            {/*            className='ic mr-0' />*/}
                                            {/*    </a>*/}
                                            {/*</td>*/}
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                  {/* end first row expand */}
                </tbody>
              </table>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CustomPaginate
        data={currentStock}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
      {/* <EaModal id='ea_modal_update_stock' modalTitle='Update Stock'>
        <div className='mb-4'>
          <label className='form-label'>Product SKU</label>
          <input
            type='text'
            className='form-control'
            placeholder='Onja Rice 5kg'
            defaultValue={'Onja Rice 5kg'}
          />
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label'>Batch Number</label>
              <input
                type='text'
                className='form-control'
                placeholder='342342'
                defaultValue={'342342'}
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label'>Expiry Date</label>
              <DateRangePicker
                initialSettings={{
                  startDate: '01/01/2020',
                  endDate: '01/12/2020',
                  alwaysShowCalendars: true,
                  singleDatePicker: true,
                  cancelClass: 'btn-secondary',
                  drops: 'auto',
                  locale: {
                    format: 'DD/MM/YYYY',
                  },
                }}
              >
                <input type='text' className='form-control calendar' />
              </DateRangePicker>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label'>System Stock</label>
              <input
                type='text'
                className='form-control'
                placeholder='450 Bags'
                defaultValue={'450 Bags'}
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label'>Actual Stock</label>
              <div className='input-group mb-1'>
                <input
                  type='number'
                  placeholder='450'
                  className='form-control min-w-70px'
                  defaultValue={'450'}
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                  Bags
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label className='form-label'>Notes/Reason</label>
          <input
            type='text'
            className='form-control'
            placeholder='Type here...'
            defaultValue={'Type here...'}
          />
        </div>
      </EaModal> */}
    </>
  )
}

export default CurrentStock
