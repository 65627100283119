import React from 'react'

const CustomPagination = (props: any) => {
  const {pageState, setPageState, limit = 10, count, selected, prev, next, numberClick} = props
  const totalCount = Math.ceil((pageState?.count || count) / limit)
  const maxVisibleButtons = 5 // Increased number of visible buttons

  const generatePageNumbers = () => {
    const currentPage = (pageState?.selected ?? selected) + 1 // Convert zero-indexed to one-indexed
    const pages = []

    if (totalCount <= maxVisibleButtons) {
      // Show all pages if they fit within maxVisibleButtons
      for (let i = 1; i <= totalCount; i++) {
        pages.push(i)
      }
    } else {
      // Show a mix of pages and ellipses
      const halfRange = Math.floor(maxVisibleButtons / 2)
      const startPage = Math.max(2, currentPage - halfRange)
      const endPage = Math.min(totalCount - 1, currentPage + halfRange)

      pages.push(1) // Always include the first page
      if (startPage > 2) pages.push('...') // Ellipsis before the start range

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i)
      }

      if (endPage < totalCount - 1) pages.push('...') // Ellipsis after the end range
      pages.push(totalCount) // Always include the last page
    }

    return pages
  }

  const handlePageClick = (page: any) => {
    if (page === '...') return

    const newOffset = (page - 1) * limit
    if (pageState) {
      setPageState((prevState: any) => ({
        ...prevState,
        selected: page - 1,
        currentOffset: newOffset,
      }))
    } else {
      numberClick(page, page - 1)
    }
  }

  const handlePrevious = () => {
    if ((pageState?.selected ?? selected) > 0) {
      const newSelected = (pageState?.selected ?? selected) - 1
      handlePageClick(newSelected + 1) // Convert zero-indexed to one-indexed
    } else if (prev) {
      prev()
    }
  }

  const handleNext = () => {
    if ((pageState?.selected ?? selected) < totalCount - 1) {
      const newSelected = (pageState?.selected ?? selected) + 1
      handlePageClick(newSelected + 1) // Convert zero-indexed to one-indexed
    } else if (next) {
      next()
    }
  }

  const pageNumbers = generatePageNumbers()

  return (
    <ul className='pagination justify-content-md-end custom-pagination'>
      <li
        className={`page-item previous ${
          (pageState?.selected ?? selected) === 0 ? 'disabled' : ''
        }`}
        onClick={handlePrevious}
      >
        <a className='page-link cursor-pointer'>
          <i className='previous'></i>
        </a>
      </li>
      {pageNumbers.map((item, index) => (
        <li
          key={index}
          className={`page-item ${item === (pageState?.selected ?? selected) + 1 ? 'active' : ''}`}
          onClick={() => handlePageClick(item)}
        >
          <a
            style={{
              color: item === (pageState?.selected ?? selected) + 1 ? 'white' : 'black',
            }}
            className='page-link cursor-pointer'
          >
            {item}
          </a>
        </li>
      ))}
      <li
        className={`page-item next ${
          (pageState?.selected ?? selected) === totalCount - 1 ? 'disabled' : ''
        }`}
        onClick={handleNext}
      >
        <a className='page-link cursor-pointer'>
          <i className='next'></i>
        </a>
      </li>
    </ul>
  )
}

export default CustomPagination
