import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
} from "../../../../../_eaFruitsDms/helpers"
import CustomColorPicker from "../../../../../_eaFruitsDms/helpers/components/CustomColorPicker"
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { deleteRequest, getRequest, postRequest, putRequest, } from "../../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../../_eaFruitsDms/layout/components/modal/eaModal"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce"

type Props = {
  className: string
}
const BlogCategory: React.FC<Props> = ({ className }) => {
  const intl = useIntl()

  const masterHeaderTitle = {
    title: 'CMS',
    subTitle: 'Categories',
  }
  const initialRow: any = {
    title: '',
    colour_code: '',
    short_description: '',
  }
  const initialCustomerValidation: any = {
    title: '',
    short_description: '',
  }

  const [categoryList, setCategoryList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [DeleteId, setDeleteId] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(10)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('')
  const [category, setCategory] = useState<any>(initialRow)
  const [categoryValidation, setCategoryValidation] = useState<any>(initialCustomerValidation)
  const [isButtonLoadder, setIsButtonLoader] = useState<any>('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState('Submit')
  const [colorHex, setColorHex] = useState<any>('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  const debounceSearch = useDebounce(search, 500);

  const cmsAuthor: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Blog',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    setTimeout(() => blogsCategoryList().then(), timer)
  }, [currentOffset, debounceSearch])

  const fetchPermission = async () =>{
    let res = await checkPermissions("cms")
    setPermission(res)
   }

  const blogsCategoryList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}blog/categories/list?&limit=${limit}&ordering=-updated_date&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    setCategoryList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChangeSearch = (e: any) => {
    let inputValue = e.target ? e.target.value : e.value
    setSearch(inputValue)
  }

  const singledata = async (item: any) => {
    setModalLoader(true)
    setId(item.id)
    const API = `${apiBaseURL()}blog/categories/get/${item.id}`
    const response = await getRequest(API, true)

    const formDetail = response
    // const formDetail = item
    let updatedData: any = { ...category }
    updatedData['title'] = formDetail.title

    updatedData['short_description'] = formDetail.short_description

    if (!['', null, 'null'].includes(formDetail.colour_code)) {
      setColorHex(formDetail.colour_code)
    }

    setCategory(updatedData)
    setModalLoader(false)
  }

  const deleteItem = async (item: any) => {
    setDeleteId(item.id)

    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }

    await deleteRequest(`${apiBaseURL()}blog/categories/delete/${DeleteId}/`, true).then((res) => {
      if (res.status === 204) {
        notify.success = true
      }

      if (res.statusText === 'Bad Request') {
        notify.failed = true
        notify.errorField = res.data.error.field
      }
    })

    if (notify.success) {
      await blogsCategoryList()

      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 2000)
      setIsAlertSuccess(true)
    }

    if (notify.failed) {
      // setIsAlertFailed(true)
    }
  }

  const clearFunctionAdd = () => {
    setCategory(initialRow)
    setCategoryValidation('')
    setErrorMessage('')
    setIsLoading('')
    setColorHex('')
    setModalLoader(false)
  }

  const handleChange = (e: any) => {
    const inputName = e.target ? e.target.name : 'name'
    let inputValue = e.target ? e.target.value : e.value

    setCategoryValidation({ ...categoryValidation, [inputName]: '' })

    switch (inputName) {
      case 'title':
        setCategory({ ...category, ['title']: inputValue })
        break
      case 'short_description':
        setCategory({ ...category, ['short_description']: inputValue })
        break
    }
  }

  const validateForm = () => {
    let navigateField = {
      title: category.title,
      colorHex: colorHex,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    let validations = { ...categoryValidation }
    let isValid = true

    if (category.title.trim() === '') {
      validations.title = 'is-invalid'
      isValid = false
    }

    if (colorHex.trim() === '') {
      validations.color_hex = 'is-invalid'
      isValid = false
    }

    setCategoryValidation(validations)
    return isValid
  }

  const apiCall = async () => {
    const categoryInfo = { ...category }
    categoryInfo['colour_code'] = colorHex

    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }

    if (requestHandler === 'add') {
      setIsLoading('Please Wait...')
      postRequest(`${apiBaseURL()}blog/categories/create`, categoryInfo, true).then(
        (response: any) => {
          setIsButtonLoader('Submit')
          if (response.status === 201) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            setIsLoading('Submit')
            blogsCategoryList()
            $('#closeEaModalBlogsCategory').trigger('click')
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
          }
          if (notify.failed) {
            if (notify.errorField == 'title') {
              setErrorMessage(
                'Your added category is already exist in this system! Please try again with unique data.'
              )
            }
            // setIsAlertFailed(true)
          }
        }
      )
    } else {
      setIsLoading('Please Wait...')
      putRequest(`${apiBaseURL()}blog/categories/update/${id}/`, categoryInfo, true).then(
        (response: any) => {
          setIsButtonLoader('Submit')
          if (response.status === 200) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            setIsLoading('Submit')
            blogsCategoryList()
            $('#closeEaModalBlogsCategory').trigger('click')
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
          }
          if (notify.failed) {
            if (notify.errorField == 'title') {
              setErrorMessage(
                'Your added data is already exist in this system! Please try again with unique data.'
              )
            }
            // setIsAlertFailed(true)
          }
        }
      )
    }
  }

  const submitFunction = async () => {
    const isValid = validateForm()
    if (isValid) {
      setIsButtonLoader('Please Wait...')
      apiCall().then((r) => setIsButtonLoader('Submit'))
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Categories - ' />
      <div>
        <PageTitle breadcrumbs={cmsAuthor}>{intl.formatMessage({ id: 'Categories' })}</PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('CMS_BLOG_CATEGORIES.ADD')
              : messageHandler === 'successEdit'
                ? FormatMessage('CMS_BLOG_CATEGORIES.UPDATE')
                : messageHandler === 'successDelete' && FormatMessage('CMS_BLOG_CATEGORIES.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} />
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_categorys'
                    btnName='Add Category'
                    onClick={() => {
                      setRequest('add')
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {categoryList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px py-2'>Category Name</th>
                          <th className='min-w-100px py-2'>Category Color Hex</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {categoryList.map((item: any) => {
                          return (
                            <tr>
                              <td>{item.title}</td>
                              <td>
                                {['', null, 'null'].includes(item.colour_code) ? (
                                  '-'
                                ) : (
                                  <p
                                    style={{
                                      backgroundColor: item.colour_code,
                                      width: '25px',
                                      height: '25px',
                                    }}
                                  ></p>
                                )}
                              </td>
                              <td className='text-end'>
                                {permission.write && (
                                  <a
                                    href='javascript:void(0)'
                                    className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                    // onClick={singledata(item)}
                                    data-bs-toggle='modal'
                                    data-bs-target='#ea_modal_create_categorys'
                                    onClick={(event) => {
                                      singledata(item)
                                      setRequest('edit')
                                      setMessageHandler('successEdit')
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )}
                                {/* <a
                                  href='javascript:void(0)'
                                  className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                  onClick={(event) => {
                                    deleteItem(item)
                                    setMessageHandler('successDelete')
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr011.svg'
                                    className='ic mr-0'
                                  />
                                </a> */}
                              </td>
                            </tr>
                          )
                        })}

                        {/* {(currentOffset == '0' || currentOffset == null) && (
                          <tr>
                            <td>Other</td>
                            
                            <td className='text-end'></td>
                          </tr>
                        )} */}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${categoryList.length == 0 ? 0 : selected * 10 + 1} to ${categoryList.length == 0
                  ? 0
                  : categoryList.length < 10
                    ? count
                    : (selected + 1) * 10
                } of ${categoryList.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                count={categoryList.length == 0 ? 0 : count}
                selected={selected}
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <AddStockInReason getId={id} requestData={requestHandler} data={data} /> */}
      <EaModal
        id='ea_modal_create_categorys'
        modalTitle={requestHandler === 'add' ? 'Add Category' : 'Update Category'}
        onSubmit={submitFunction}
        cancel={clearFunctionAdd}
        btnDisabled={isButtonLoadder === 'Submit' ? false : true}
        closeAuto='closeEaModalBlogsCategory'
        actionBtnName={isLoading}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Category Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='title'
            className={clsx('form-control', categoryValidation.title)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e)}
            name='title'
            value={category && category.title}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='colorHex'>
            Category Color Hex<span className="text-danger">*</span>
          </label>
          <CustomColorPicker
            colorHex={colorHex}
            setColorHex={setColorHex}
            validationFired={categoryValidation.color_hex}
            categoryValidation={categoryValidation}
            setCategoryValidation={setCategoryValidation}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>Small Description</label>
          <textarea
            className={clsx('form-control')}
            rows={4}
            placeholder='Type here…'
            name='short_description'
            onChange={(e) => handleChange(e)}
            value={category && category.short_description}
          ></textarea>
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}

export default BlogCategory
