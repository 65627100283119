// login types
export const LOGIN_EAFRUITS_SUCCESS = 'LOGIN_EAFRUITS_SUCCESS'
export const LOGIN_EAFRUITS_IS_LOADING = 'LOGIN_EAFRUITS_IS_LOADING'
export const LOGIN_EAFRUITS_FAILS = 'LOGIN_EAFRUITS_FAILS'

// forgot Password
export const FORGOT_PASSWORD_EAFRUITS_SUCCESS = 'FORGOT_PASSWORD_EAFRUITS_SUCCESS'
export const FORGOT_PASSWORD_EAFRUITS_IS_LOADING = 'FORGOT_PASSWORD_EAFRUITS_IS_LOADING'
export const FORGOT_PASSWORD_EAFRUITS_FAIL = 'FORGOT_PASSWORD_EAFRUITS_FAIL'
export const RESET_FORGOT_VALUES = 'RESET_FORGOT_VALUES'

// reset Password
export const RESET_PASSWORD_EAFRUITS_SUCCESS = 'RESET_PASSWORD_EAFRUITS_SUCCESS'
export const RESET_PASSWORD_EAFRUITS_IS_LOADING = 'RESET_PASSWORD_EAFRUITS_IS_LOADING'
export const RESET_PASSWORD_EAFRUITS_FAIL = 'RESET_PASSWORD_EAFRUITS_FAIL'
export const RESET_PASSWORD_EAFRUITS_UNIQUE_PASSWORD = 'RESET_PASSWORD_EAFRUITS_UNIQUE_PASSWORD'

// ===>> master page :
// creadit scrore types
export const CREDIT_SCORE_CREATE_SUCCESS = 'CREDIT_SCORE_CREATE_SUCCESS'
export const CREDIT_SCORE_CREATE_FAIL = 'CREDIT_SCORE_CREATE_FAILS'
export const CREDIT_SCORE_GET_SINGLE_DATA = 'CREDIT_SCORE_GET_SINGLE_DATA'
export const CREDIT_SCORE_ON_CHANGE = 'CREDIT_SCORE_ON_CHANGE'

export const CREDIT_SCORE_LIST_SUCCESS = 'CREDIT_SCORE_LIST_SUCCESS'
export const CREDIT_SCORE_LIST_FAILS = 'CREDIT_SCORE_LIST_FAILS'

export const CREDIT_SCORE_SINGLE_LIST_SUCCESS = 'CREDIT_SCORE_SINGLE_LIST_SUCCESS'
export const CREDIT_SCORE_SINGLE_LIST_FAIL = 'CREDIT_SCORE_SINGLE_LIST_FAIL'

export const CREDIT_SCORE_UPDATE_SUCCESS = 'CREDIT_SCORE_UPDATE_SUCCESS'
export const CREDIT_SCORE_UPDATE_FAIL = 'CREDIT_SCORE_UPDATE_FAILS'

// customer-category types
export const CUSTOMER_CATEGORY_CREATE_SUCCESS = 'CUSTOMER_CATEGORY_CREATE_SUCCESS'
export const CUSTOMER_CATEGORY_CREATE_FAIL = 'CUSTOMER_CATEGORY_CREATE_FAIL'

export const CUSTOMER_CATEGORY_LIST_SUCCESS = 'CUSTOMER_CATEGORY_LIST_SUCCESS'
export const CUSTOMER_CATEGORY_LIST_FAIL = 'CUSTOMER_CATEGORY_LIST_FAIL'

export const CUSTOMER_CATEGORY_SINGLE_SUCCESS = 'CUSTOMER_CATEGORY_SINGLE_SUCCESS'
export const CUSTOMER_CATEGORY_SINGLE_FAIL = 'CUSTOMER_CATEGORY_SINGLE_FAIL'

export const CUSTOMER_CATEGORY_UPDATE_SUCCESS = 'CUSTOMER_CATEGORY_UPDATE_SUCCESS'
export const CUSTOMER_CATEGORY_UPDATE_FAIL = 'CUSTOMER_CATEGORY_UPDATE_FAIL'

export const CUSTOMER_CATEGORY_DELETE_SUCCESS = 'CUSTOMER_CATEGORY_DELETE_SUCCESS'
export const CUSTOMER_CATEGORY_DELETE_FAIL = 'CUSTOMER_CATEGORY_DELETE_FAIL'

//  warehouse ID
export const WAREHOUSE_ID = 'WAREHOUSE_ID'

// teleSalesUser
export const ADD_USER = 'ADD_USER'

// profile
export const PROFILE = 'PROFILE'

// saDashboardFilters
export const SA_DASHBOARD_WAREHOUSE = 'SA_DASHBOARD_WAREHOUSE'
export const SA_DASHBOARD_MONTH = 'SA_DASHBOARD_MONTH'

// summaryFilters
export const SUMMARY_WAREHOUSE = 'SUMMARY_WAREHOUSE'
export const SUMMARY_MONTH = 'SUMMARY_MONTH'

// support request report filter
export const SUPPORT_REQUEST_REPORT_WAREHOUSE = 'SUPPORT_REQUEST_REPORT_WAREHOUSE'
export const SUPPORT_REQUEST_REPORT_START_DATE = 'SUPPORT_REQUEST_REPORT_START_DATE'
export const SUPPORT_REQUEST_REPORT_END_DATE = 'SUPPORT_REQUEST_REPORT_END_DATE'
export const SUPPORT_REQUEST_REPORT_DATE = 'SUPPORT_REQUEST_REPORT_DATE'

// summary report filter
export const SUMMARY_REPORT_REPORT_WAREHOUSE = 'SUPPORT_REQUEST_REPORT_WAREHOUSE'
export const SUMMARY_REPORT_REPORT_START_DATE = 'SUPPORT_REQUEST_REPORT_START_DATE'
export const SUMMARY_REPORT_REPORT_END_DATE = 'SUPPORT_REQUEST_REPORT_END_DATE'
export const SUMMARY_REPORT_REPORT_DATE = 'SUPPORT_REQUEST_REPORT_DATE'
