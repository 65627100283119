import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { CustomerMarker } from "./CustomerMarker";
import { PhotoMarker } from "./PhotoMarker";
import { customerMapZoom } from "../../../helpers";

const CustomerMap = withScriptjs(withGoogleMap((props: any) => (
    <GoogleMap
        defaultZoom={15}
        defaultCenter={props.center}
        center={props.center}
        zoom={customerMapZoom}
    >
        {
            (props.customerLocation && props.customerLocation.latitude && props.customerLocation.longitude) && <CustomerMarker customerLocation={props.customerLocation} />
        }

        {
            (props.photoLocation && props.photoLocation.latitude && props.photoLocation.longitude) && <PhotoMarker photoLocation={props.photoLocation}/>
        }
    </GoogleMap>
)))

export default CustomerMap
