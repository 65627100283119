import { apiBaseURL } from "../../helpers"
import { deleteRequest, getRequest, postRequest, putRequest } from "../../https/apiCall"

export const BrandsList = async (limit: any, offset: any, val?: any, ordering?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }

  await getRequest(
    `${apiBaseURL()}master/product/brandSKU?limit=${limit}&offset=${offset}&search=${val}&ordering=${ordering}`,
    true
  ).then((res: any) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const BrandCreate = async (
  name: string,
  brand_tin_number: string,
  brand_vrn_number: string,
  brand_story: string,
  brand_image: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let form_data = new FormData()
  form_data.append('name', name)
  form_data.append('brand_tin_number', brand_tin_number)
  form_data.append('brand_vrn_number', brand_vrn_number)
  form_data.append('brand_story', brand_story)
  if (brand_image.name) {
    form_data.append('brand_image', brand_image, brand_image.name)
  }
  //  else {
  //     form_data.append('brand_image', brand_image);
  // }

  await postRequest(`${apiBaseURL()}master/product/brand`, form_data, true).then((res) => {
    console.log('res === ', res)
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const BrandUpdate = async (
  name: string,
  brand_tin_number: string,
  brand_vrn_number: string,
  brand_image: any,
  brand_story: any,
  id: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let form_data = new FormData()
  form_data.append('name', name)
  form_data.append('brand_tin_number', brand_tin_number)
  form_data.append('brand_vrn_number', brand_vrn_number)
  form_data.append('brand_story', brand_story)
  if (brand_image.name) {
    form_data.append('brand_image', brand_image, brand_image.name)
  }
  //  else {
  //     form_data.append('brand_image', brand_image);
  // }

  await putRequest(`${apiBaseURL()}master/product/brand/${id}/`, form_data, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    // error handler
    if (res.status === 400) {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const BrandDelete = async (id: any) => {
  const notify = {
    success: false,
    failed: false,
  }
  await deleteRequest(`${apiBaseURL()}master/product/brand/${id}`, true).then((res) => {
    if (res.status === 204) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}

export const BrandSingle = async (id: any) => {
  let data = ''

  await getRequest(`${apiBaseURL()}master/product/brand/${id}/`, true)
    .then((res) => {
      data = res
    })
    .catch((err) => {})
  return data
}
