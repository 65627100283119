import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {AsyncPaginate} from 'react-select-async-paginate'

type Props = {
  className: string
}
const fetchRejectionReason = async (search?: any) => {
    let options: any = [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 3', value: 'option3' },
    ]
    let hasMore: boolean = false
    options.push({
        label: 'Option 2',
        value: 'option2',
    })
    return {
        options: options,
        hasMore: hasMore,
    }
}

const CreditRequestRejectionModal: React.FC<Props> = () => {
    const handleShowRejection = () => setRejection(true)
    const [rejection, setRejection] = useState(false)
    const handleCloseRejection = () => setRejection(false)
    return (
        <>
            <Modal show={rejection} onHide={handleCloseRejection} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-md-12'>
                    <div className="mb-4">
                        <label className="form-label">Rejection Reason</label>
                        <AsyncPaginate

                            loadOptions={fetchRejectionReason}
                            className='react-select-container me-3 w-100'
                            classNamePrefix='react-select'
                            placeholder='All Users'
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />

                    </div>
                    <div className="mb-4">
                        <label className="form-label">Comments</label>
                        <textarea
                            className='form-control'
                            rows={4}
                            placeholder='Type here…'
                        ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-footer justify-content-center'>
                    <button type='button' className='btn btn-light' onClick={handleCloseRejection}>
                        Cancel
                    </button>
                    <button type='button' className='btn btn-primary' >
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default CreditRequestRejectionModal
