import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {AsyncPaginate} from 'react-select-async-paginate'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { useNavigate } from 'react-router-dom'
const TSHorecaContract: React.FC = () => {
  const intl = useIntl()
  const customersHeaderTitle = {
    title: 'Customers',
    subTitle: 'HORECA Contracts',
  }
  const fetchStatus = async (search?: any) => {
    let options: any = [
      {label: 'Option 1', value: 'option1'},
      {label: 'Option 3', value: 'option3'},
    ]
    let hasMore: boolean = false
    options.push({
      label: 'All Status',
      value: '',
    })
    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const navigate = useNavigate()
  return (
    <>
      <EATitle title='HORECA Contracts' />
      <PageTitle breadcrumbs={[customersHeaderTitle]}>
        {intl.formatMessage({id: 'HORECA Contracts'})}
      </PageTitle>
      <div className={`card`}>
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar />
            <div className='col-auto ms-auto'>
              {/* <div className=' card-toolbar d-flex my-0'> */}
              <form
                className=' card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                {/* filter dropdown */}

                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  placeholder='All Status'
                  className='react-select-container w-115px'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <button
                  className='btn btn-primary ms-3'
                  onClick={handleShow}
                >
                  Add Contract
                  </button>

                {/* <EaButton isModal={true} dataTarget='#ea_modal_import_sku' btnName='Import SKUs' />  */}
              </form>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
              {/* begin::Table head */}
              <thead>
                <tr className='text-muted fw-500'>
                  <th className='min-w-120px py-2'>Contract Duration</th>
                  <th className='min-w-120px py-2'>Credit Cap</th>
                  <th className='min-w-120px py-2'>Credit Duration</th>
                  <th className='min-w-120px py-2'>Added By</th>
                  <th className='min-w-120px py-2'>Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr onClick={() => navigate('horeca-contract-detail')} className='cursor-pointer'>
                  <td>2 Feb 2023 — 2 Feb 2024</td>
                  <td>TZS 100,000.00</td>
                  <td>5 Days</td>
                  <td>Jube Bowman (WM)</td>
                  <td>
                    <span className='badge badge-light-success text-dark'>Active</span>
                  </td>
                </tr>
                <tr onClick={() => navigate('horeca-contract-detail')} className='cursor-pointer'>
                  <td>21 Aug 2022 — 22 Aug 2023</td>
                  <td>TZS 140,000.00</td>
                  <td>10 Days</td>
                  <td>Nout Golstein (SM)</td>
                  <td>
                    <span className='badge badge-light-dark text-dark'>Expired</span>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
        </div>
      </div>
      
    </>
  )
}

export default TSHorecaContract
