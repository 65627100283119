export const issueStatus = [
  {
    label: "All Status",
    value: ""
  },
  {
    label: "Open",
    value: "open"
  },
  {
    label: "Overdue",
    value: "overdue"
  },
  {
    label: "Closed",
    value: "completed"
  },
]
