import {useEffect, useState} from 'react'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  isEmpty,
  KTSVG,
  numberFormat,
  numberFormatWithCode,
} from '../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import './OrderDrawer.css' // Add this line to import custom CSS
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import Activities from './activities'

interface Props {
  orderId: string
}

const OrderDrawer = ({orderId}: Props) => {
  let number = 0

  const [openCurrentItem, setOpenCurrentItem] = useState<any>(null)
  const [details, setDetails] = useState<any>(null)
  const [activities, setActivities] = useState([])
  const [loafingActivities, setLoadingActivities] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState(true)

  useEffect(() => {
    if (!isEmpty(orderId)) fetchOrderDetails()
  }, [orderId])

  const fetchOrderDetails = async () => {
    setOpenCurrentItem(null)
    setLoadingDetails(true)
    setDetails(null)

    const url = `${apiBaseURL()}delivery/customer-check-list/${orderId}`

    const response = await getRequest(url, true)

    if (response?.result) setDetails(response?.result)

    setLoadingDetails(false)
  }

  const fetchActivities = async (type: 'credit_issue' | 'order', id: string) => {
    setLoadingActivities(true)
    setActivities([])

    const customerRouteId = orderId

    const url = `${apiBaseURL()}routes/customer-routes/${customerRouteId}/${id}/activities?record_type=${type}`

    const response = await getRequest(url, true)

    if (response?.results) setActivities(response?.results)

    setLoadingActivities(false)
  }

  return (
    <>
      <div
        id='kt_drawer_chat'
        data-kt-drawer='true'
        data-kt-drawer-name='chat'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'250px', 'lg': '510px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_chat_toggle'
        data-kt-drawer-close='#kt_drawer_chat_close'
        className='p-0'
      >
        <div className='card w-100 drawer-on-grey bg-white' id='kt_drawer_chat_messenger'>
          <div className='card-header pe-5 bg-white' id='kt_drawer_chat_messenger_header'>
            <div className='card-title d-flex justify-content-between'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <div className='fs-6 fw-bold text-gray-900 text-hover-primary lh-1'>
                  {!loadingDetails && checkEmpty(details?.customer?.name)}
                </div>
                <div className='font-14'>
                  {!loadingDetails && (
                    <>
                      {checkEmpty(details?.customer?.street)}, {checkEmpty(details?.customer?.ward)}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className='card-toolbar'>
              <div
                className='btn btn-sm btn-icon btn-active-light-primary'
                id='kt_drawer_chat_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </div>
            </div>
          </div>

          <div className='p-5 w-70'>
            {loadingDetails ? (
              <FetchingRecords />
            ) : isEmpty(details) ||
              (details?.orders?.length === 0 &&
                details?.credit_issues?.length === 0 &&
                details?.surveys?.length === 0) ? (
              <div className='mt-5'>
                <NoRecords />
              </div>
            ) : (
              <>
                {Object.entries(details).map(([key]) => {
                  // 1. orders & Feedback
                  if (key === 'orders' && details['orders'].length > 0) {
                    return details['orders'].map((item: any, index: any) => {
                      number = number + 1
                      return (
                        <>
                          <div className='d-flex' key={`orders_${index}`}>
                            <div className='drawer-box d-flex flex-column align-items-center me-3'>
                              <div className='drawer-line'></div>

                              {item?.order_status === 'completed' ? (
                                <div className='drawer-circle bg-success fw-bold p-1'>
                                  <div>
                                    <i className='bi bi-check text-white font-24'></i>
                                  </div>
                                </div>
                              ) : (
                                <div className='drawer-circle bg-warning fw-bold p-1'>
                                  <div>{number}</div>
                                </div>
                              )}

                              <div className='drawer-line'></div>
                            </div>

                            {/* Card content column */}
                            <div className='border border-secondary rounded p-3 flex-grow-1  mb-3 mt-3'>
                              <div className='d-flex justify-content-between align-content-center'>
                                <div>
                                  <div>
                                    {isEmpty(item?.activity_time)
                                      ? ''
                                      : dateFormat(item?.activity_time, 'LT').displayDate}
                                  </div>
                                  <h5 className='fw-bold'>
                                    {isEmpty(item?.order_no) ? '-' : `Order #${item?.order_no}`}
                                  </h5>
                                </div>

                                <div
                                  onClick={() => {
                                    if (
                                      isEmpty(openCurrentItem) ||
                                      openCurrentItem !== `orders_${index}`
                                    ) {
                                      setOpenCurrentItem(`orders_${index}`)
                                      fetchActivities('order', item?.id ?? '')
                                    } else {
                                      setOpenCurrentItem(null)
                                    }
                                  }}
                                >
                                  <KTSVG
                                    path={`/media/icons/duotune/arrows/${
                                      isEmpty(openCurrentItem) ||
                                      openCurrentItem !== `orders_${index}`
                                        ? 'ic_down.svg'
                                        : 'ic_up.svg'
                                    }`}
                                    className='svg-icon-2 cursor-pointer'
                                  />
                                </div>
                              </div>

                              <>
                                <hr className='m-0 mb-2 mt-1' />

                                <div className='d-flex justify-content-between'>
                                  <div className='text-primary fw-bold'>Products</div>
                                  <div className='text-primary fw-bold'>Amount</div>
                                </div>

                                <div key={1} className='d-flex justify-content-between'>
                                  <div>
                                    {isEmpty(item?.total_product_count)
                                      ? '-'
                                      : numberFormat(item?.total_product_count).displayFormat}{' '}
                                    products
                                  </div>
                                  <div>
                                    {isEmpty(item?.amount)
                                      ? '-'
                                      : numberFormatWithCode(item?.amount).displayFormat}
                                  </div>
                                </div>
                              </>

                              {openCurrentItem == `orders_${index}` && (
                                <>
                                  <hr className='m-0 mb-3 mt-3' />

                                  <Activities data={activities} loading={loafingActivities} />
                                </>
                              )}
                            </div>
                          </div>

                          {item?.is_feedback_given && (
                            <div className='d-flex'>
                              <div className='drawer-box d-flex flex-column align-items-center me-3'>
                                {/* {item?.status === 'completed' ? (
                                  <div className='drawer-circle bg-success fw-bold p-1'>
                                    <div>
                                      <i className='bi bi-check text-white font-24'></i>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='drawer-circle bg-warning fw-bold p-1'>
                                    <div>{number}</div>
                                  </div>
                                )} */}

                                <div className='drawer-line'></div>
                              </div>

                              <div className='border border-secondary rounded p-3 flex-grow-1  mb-3 mt-3'>
                                <div className='d-flex justify-content-between align-content-center'>
                                  <div>
                                    <div>
                                      {isEmpty(item?.feedback_activity_time)
                                        ? ''
                                        : dateFormat(item?.feedback_activity_time, 'LT')
                                            .displayDate}
                                    </div>
                                    <h5 className='fw-bold'>
                                      {isEmpty(item?.order_no)
                                        ? '-'
                                        : `Order #${item?.order_no} Feedback`}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )
                    })
                  }

                  // 2. Credit Issues
                  if (key === 'credit_issues' && details['credit_issues'].length > 0) {
                    return details['credit_issues'].map((item: any, index: any) => {
                      number = number + 1
                      return (
                        <div className='d-flex' key={`credit_issues_${index}`}>
                          <div className='drawer-box d-flex flex-column align-items-center me-3'>
                            <div className='drawer-line'></div>

                            {item?.status === 'completed' ? (
                              <div className='drawer-circle bg-success fw-bold p-1'>
                                <div>
                                  <i className='bi bi-check text-white font-24'></i>
                                </div>
                              </div>
                            ) : (
                              <div className='drawer-circle bg-warning fw-bold p-1'>
                                <div>{number}</div>
                              </div>
                            )}

                            <div className='drawer-line'></div>
                          </div>

                          {/* Card content column */}
                          <div className='border border-secondary rounded p-3 flex-grow-1  mb-3 mt-3'>
                            <div className='d-flex justify-content-between align-content-center'>
                              <div>
                                <div>
                                  {isEmpty(item?.activity_time)
                                    ? ''
                                    : dateFormat(item?.activity_time, 'LT').displayDate}
                                </div>
                                <h5 className='fw-bold'>
                                  {isEmpty(item?.id) ? '-' : `Credit Issue #${item?.id}`}
                                </h5>
                              </div>

                              <div
                                onClick={() => {
                                  if (
                                    isEmpty(openCurrentItem) ||
                                    openCurrentItem !== `credit_issues_${index}`
                                  ) {
                                    setOpenCurrentItem(`credit_issues_${index}`)
                                    fetchActivities('credit_issue', item?.id ?? '')
                                  } else {
                                    setOpenCurrentItem(null)
                                  }
                                }}
                              >
                                <KTSVG
                                  path={`/media/icons/duotune/arrows/${
                                    isEmpty(openCurrentItem) ||
                                    openCurrentItem !== `credit_issues_${index}`
                                      ? 'ic_down.svg'
                                      : 'ic_up.svg'
                                  }`}
                                  className='svg-icon-2 cursor-pointer'
                                />
                              </div>
                            </div>

                            <>
                              <hr className='m-0 mb-2 mt-1' />

                              <div className='d-flex justify-content-between'>
                                <div className='text-primary fw-bold'>Total Amount</div>
                                <div className='text-primary fw-bold'>Cash Received</div>
                              </div>

                              <div key={1} className='d-flex justify-content-between'>
                                <div>
                                  {isEmpty(item?.due_amount)
                                    ? '-'
                                    : numberFormatWithCode(item?.due_amount).displayFormat}{' '}
                                </div>
                                <div>
                                  {isEmpty(item?.approved_amount)
                                    ? '-'
                                    : numberFormatWithCode(item?.approved_amount).displayFormat}
                                </div>
                              </div>
                            </>

                            {openCurrentItem == `credit_issues_${index}` && (
                              <>
                                <hr className='m-0 mb-3 mt-3' />

                                <Activities data={activities} loading={loafingActivities} />
                              </>
                            )}
                          </div>
                        </div>
                      )
                    })
                  }

                  // 2. Survey Form
                  if (key === 'surveys' && details['surveys'].length > 0) {
                    return details['surveys'].map((item: any, index: any) => {
                      number = number + 1
                      return (
                        <div className='d-flex' key={`${index}_surveys`}>
                          <div className='drawer-box d-flex flex-column align-items-center me-3'>
                            <div className='drawer-line'></div>

                            <div className='drawer-circle bg-warning fw-bold p-1'>
                              <div>{number}</div>
                            </div>
                            <div className='drawer-line'></div>
                          </div>

                          {/* Card content column */}
                          <div className='border border-secondary rounded p-3 flex-grow-1  mb-3 mt-3'>
                            <div className='d-flex justify-content-between align-content-center'>
                              <div>
                                <h5 className='fw-bold'>{checkEmpty(item?.name)}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderDrawer
