import React, {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  dateFormat,
  isEmpty,
  KTSVG,
  measurementLabel,
} from '../../../_eaFruitsDms/helpers'
import {getRequest, patchRequest} from '../../../_eaFruitsDms/https/apiCall'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import moment from 'moment'
import {useLocation, useNavigate} from 'react-router-dom'
import DatePicker from '../../../_eaFruitsDms/layout/components/datePicker/datePicker'
import {EaAlertDanger} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'

let tableRowIndex: number = 0
/**
 * A function for handling the offloading of bananas from a container.
 *
 * @param {any} search - optional search criteria
 * @return {object} object containing options and a flag indicating if there are more results
 */
const BananaOffLoad = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {state}: any = location
  const containerName = state?.container_name || ''
  const containerId = state?.container_id || ''

  const headerTitle = {
    title: `Banana Off-Load From ${containerName}`,
    subTitle: `Banana Off-Load From ${containerName}`,
  }

  const currentWarehouseId = localStorage.getItem('w_id')
  const currentDate = moment()
  const formattedCurrentDate = dateFormat(currentDate)

  const initialRow = {
    index: 0,
    product_id: '',
    validation_for_product: '',
    batch_id: '',
    validation_for_batch: '',
    quantity: '',
    validation_for_quantity: '',
    validation_for_quantity_exceed: '',
    quantity_label: '',
    inward_quantity: '',
    expiry_date: formattedCurrentDate.apiDate,
  }

  const [searchSelect, setSearchSelect] = useState<string>('')
  const [bananaOffLoadRows, setBananaOffLoadRows] = useState<any>([initialRow])
  const [productSkus, setProductSkus] = useState<any>([])
  const [selectedProduct, setSelectedProduct] = useState()
  const [productReset, setProductReset] = useState<any>(moment())
  const [batches, setBatches] = useState<any>([])
  const [batchReset, setBatchReset] = useState<any>(moment())
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)

  useEffect(() => {
    if (isEmpty(containerName) || isEmpty(containerId)) {
      navigate('/containers')
    }
  }, [])

  /**
   * Fetches product SKUs based on search criteria and updates state accordingly.
   *
   * @param {any} search - optional search criteria
   * @return {object} object containing options and a flag indicating if there are more results
   */
  const fetchProductSku = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }
    const productResponse = await getRequest(
      `${apiBaseURL()}inventory/ripening-product-list/${containerId}`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (productResponse.length > 0) {
      productResponse.map((option: any) => {
        options.push({
          label: option.product_name,
          value: option.product_id,
          ...option,
        })
      })
    }

    if (productSkus.length > 0) {
      setProductSkus(productSkus.concat(options))
    } else {
      setProductSkus(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  /**
   * Fetches batches based on the given index and search criteria.
   *
   * @param {number} index - The index of the batch
   * @param {any} search - Optional search criteria
   * @return {object} An object with options array and hasMore boolean
   */
  const fetchBatches = async (index: number, search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }
    const batchResponse = await getRequest(
      `${apiBaseURL()}inventory/ripening-batch-list/${containerId}/${
        bananaOffLoadRows[index].product_id
      }`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (batchResponse.length > 0) {
      batchResponse.map((option: any) => {
        const findBatchIndex = bananaOffLoadRows.findIndex(
          (row: any) =>
            row.product_id === bananaOffLoadRows[index].product_id && row.batch_id === option.id
        )

        if (findBatchIndex === -1) {
          options.push({
            label: option.batch_number,
            value: option.id,
            ...option,
          })
        }
      })
    }

    if (batches.length > 0) {
      setBatches(batches.concat(options))
    } else {
      setBatches(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  /**
   * Handles the change event for the input fields.
   *
   * @param {any} e - the event object
   * @param {any} index - the index of the element being changed
   * @param {string} name - (optional) the name of the element being changed
   * @return {void}
   */
  const handleChange = (e: any, index: any, name?: string) => {
    let rows = [...bananaOffLoadRows]
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'product':
        // setBatchReset(moment())
        setBatchReset(moment())
        setSelectedProduct(e)
        rows[index]['product_id'] = inputValue
        rows[index]['batch_id'] = ''
        rows[index]['quantity'] = ''
        rows[index]['inward_quantity'] = 0
        rows[index]['validation_for_product'] = ''
        rows[index]['quantity_label'] = measurementLabel(
          e.fix_size ? e.material_name : e.approx_weight > 0 ? e.material_name : e.measurement_unit
        )
        break
      case 'batch':
        rows[index]['batch_id'] = inputValue
        rows[index]['validation_for_batch'] = ''
        rows[index]['quantity'] = e.qty

        if (e.qty > 0) {
          rows[index]['validation_for_quantity'] = ''
        }

        rows[index]['inward_quantity'] = calculateInwardQuantity(index, e.qty)
        break
      case 'quantity':
        inputValue = inputValue.replace(/[^0-9]/g, '')

        rows[index]['quantity'] = inputValue
        rows[index]['validation_for_quantity'] = ''
        rows[index]['validation_for_quantity_exceed'] = ''
        rows[index]['validation_for_quantity'] = ''

        if (isEmpty(inputValue.trim())) {
          rows[index]['validation_for_quantity'] = 'is-invalid'
        } else {
          const batch = batches.find((option: any) => option.id === rows[index]['batch_id'])

          if (!isEmpty(batch) && batch.qty < parseInt(inputValue)) {
            rows[index][
              'validation_for_quantity_exceed'
            ] = `Only ${batch.qty} Crates Available for Offloading`
          }
        }
        rows[index]['inward_quantity'] = calculateInwardQuantity(index, inputValue)
        break
    }

    setBananaOffLoadRows(rows)
  }

  /**
   * Calculate inward quantity based on the product's properties.
   *
   * @param {number} index - the index of the product
   * @param {number} quantity - the quantity of the product
   * @return {number} calculated inward quantity
   */
  const calculateInwardQuantity = (index: number, quantity: number) => {
    let product = productSkus.find(
      (option: any) => option.value === bananaOffLoadRows[index]['product_id']
    )

    if (product.length > 0) product = product[0]

    if (product.fix_size) {
      if (product.measurement_unit.toLowerCase() === 'gram') {
        return quantity * (product.size / 1000)
      } else {
        return quantity * product.size
      }
    } else {
      if (product.variants && product.variants.approx_weight > 0) {
        return (quantity * product.variants.approx_weight) / 1000
      } else {
        return quantity
      }
    }
  }

  /**
   * Validates the banana offload object and updates the validation status of each row.
   *
   * @return {boolean} the validation status of the banana offload object
   */
  const validateBananaOffloadObj = () => {
    let valid = true
    const rows = [...bananaOffLoadRows]

    bananaOffLoadRows.map((row: any, index: number) => {
      if (isEmpty(row.product_id)) {
        valid = false
        rows[index]['validation_for_product'] = 'is-invalid'
      }

      if (isEmpty(row.batch_id)) {
        valid = false
        rows[index]['validation_for_batch'] = 'is-invalid'
      }

      if (isEmpty(row.quantity, true)) {
        valid = false
        rows[index]['validation_for_quantity'] = 'is-invalid'
      } else {
        const batch = batches.find((option: any) => option.id === rows[index]['batch_id'])

        if (!isEmpty(batch) && batch.qty < parseInt(row.quantity)) {
          valid = false
          rows[index][
            'validation_for_quantity_exceed'
          ] = `Only ${batch.qty} Crates Available for Offloading`
        }
      }
    })

    setBananaOffLoadRows(rows)
    return valid
  }

  /**
   * Function to add a new offload row if the banana offload object is valid.
   *
   * @return {void}
   */
  const addNewOffloadRow = () => {
    const isValid = validateBananaOffloadObj()

    if (isValid) {
      tableRowIndex++

      const updatedRows = [...bananaOffLoadRows]
      initialRow.index = tableRowIndex
      updatedRows[tableRowIndex] = initialRow
      setBananaOffLoadRows(updatedRows)
    }
  }

  /**
   * Deletes a row from the banana offload rows.
   *
   * @param {number} index - The index of the row to be deleted
   * @return {any}
   */
  const deleteRow = (index: number): any => {
    if (bananaOffLoadRows.length === 1) {
      alert('You can not remove this row because you need to add at least one offload item.!')
      return false;
    }

    if (bananaOffLoadRows.length > 0) {
      let updatedRows: any = [...bananaOffLoadRows]
      updatedRows.splice(index, 1)

      tableRowIndex--
      updatedRows.forEach((row: any, index: any) => {
        row.index = index
      })
      setBananaOffLoadRows(updatedRows)
    }
  }

  /**
   * A function to offload banana stock entries.
   *
   * @return {Promise<void>} no return value
   */
  const bananaOffloading = async () => {
    const isValid = validateBananaOffloadObj()

    if (isValid) {
      setIsSubmit(true)

      const bananaOffloadRequestObj: any = {
        stock_entries: [],
        warehouse_id: currentWarehouseId,
      }

      bananaOffLoadRows.map((row: any, index: number) => {
        return bananaOffloadRequestObj.stock_entries.push({
          ripening_stock_id: row.batch_id,
          qty: row.quantity,
          expiry_date: row.expiry_date,
        })
      })

      const response = await patchRequest(
        `${apiBaseURL()}inventory/ripening-stock-off-load`,
        bananaOffloadRequestObj,
        true
      )

      handleResponse(response)
    }
  }

  /**
   * Handle the response from the API call.
   *
   * @param {any} res - the response object from the API call
   * @return {void}
   */
  const handleResponse = (res: any) => {
    setIsSubmit(false)
    if (res.status === 201 || res.status === 200 || res.status === 204) {
      resetStates()
      localStorage.setItem('offload_message', `Banana Off-Load Successful from ${containerName}`)
      navigate('/current-stock')
    } else {
      setIsAlertFailed(true)
    }

    setTimeout(() => {
      setIsAlertFailed(false)
    }, 1500)
  }

  /**
   * This function cancels stock in.
   *
   * @return {void}
   */
  const cancelOffloading = () => {
    resetStates()
    navigate('/containers')
  }

  const isAlertShow = (val: any) => {
    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }

  /**
   * Resets the states by setting product reset, container reset, and banana stock in rows.
   */
  const resetStates = () => {
    setProductReset(moment())
    setBatchReset(moment())
    setBananaOffLoadRows([initialRow])
  }

  return (
    <>
      <EATitle title='Banana Off-Load' />
      <PageTitle breadcrumbs={[headerTitle]}>Banana Off-Load</PageTitle>

      <div className='card p-5'>
        <h3 className='mb-5'>Banana Off-Load for {containerName}</h3>
        <EaAlertDanger
          onClick={() => isAlertShow('failed')}
          show={isAlertFailed}
          message='Something went wrong. Please try again.!'
        />
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th className='min-w-160px'>Product SKU</th>
                <th className='min-w-160px'>Batch No.</th>
                <th className='min-w-160px'>Expiry Date</th>
                <th className='min-w-160px'>Quantity</th>
                <th className='min-w-160px'>Inward Quantity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bananaOffLoadRows.map((row: any, index: any) => {
                let dateView = dateFormat(!isEmpty(row) ? row.expiry_date : moment())

                return (
                  <tr key={index.toString()}>
                    <td>
                      <AsyncPaginate
                        key={productReset}
                        loadOptions={fetchProductSku}
                        placeholder='Select'
                        isSearchable
                        className={`react-select-container ${row.validation_for_product}`}
                        classNamePrefix='react-select'
                        menuPosition='fixed'
                        onChange={(e) => {
                          handleChange(e, index, 'product')
                        }}
                        value={
                          productSkus.find((option: any) => option.value === row.product_id) || ''
                        }
                        name='product_sku_id'
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#0c78a4',
                          },
                        })}
                      />
                    </td>
                    <td>
                      <AsyncPaginate
                        key={batchReset}
                        loadOptions={(search) => fetchBatches(index, search)}
                        placeholder='Select'
                        isSearchable
                        className={`react-select-container ${row.validation_for_batch}`}
                        classNamePrefix='react-select'
                        menuPosition='fixed'
                        isDisabled={isEmpty(row.product_id)}
                        value={batches.find((option: any) => option.value === row.batch_id) || ''}
                        onChange={(e) => {
                          handleChange(e, index, 'batch')
                        }}
                        name='batch_id'
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#0c78a4',
                          },
                        })}
                      />
                    </td>
                    <td>
                      <DatePicker
                        onCallback={(dateView: any, dateApi: any) => {
                          let rows = [...bananaOffLoadRows]
                          rows[index].expiry_date = dateApi
                          setBananaOffLoadRows(rows)
                        }}
                        onCancel={() => {}}
                        id='datePicker'
                        name='expiry_date'
                        onApply={() => {}}
                        direction='down'
                        startDate={currentDate}
                        minDate={currentDate}
                        dateValue={dateView.displayDate}
                      />
                    </td>
                    <td>
                      <div
                        className='input-group'
                        style={{
                          borderColor: !isEmpty(row.validation_for_quantity) ? 'Red' : '',
                        }}
                      >
                        <input
                          type='text'
                          placeholder='0'
                          name='quantity'
                          disabled={isEmpty(row.batch_id)}
                          value={row.quantity}
                          onChange={(e) => handleChange(e, index)}
                          className={`form-control`}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                          {row.quantity_label}
                        </span>
                      </div>
                      {!isEmpty(row.validation_for_quantity_exceed) && (
                        <span className='font-13 text-danger mt-1'>
                          {row.validation_for_quantity_exceed}
                        </span>
                      )}
                    </td>
                    <td>
                      <div className='input-group'>
                        <input
                          type='text'
                          placeholder='0'
                          disabled
                          name='inward_quantity'
                          value={row.inward_quantity}
                          className={`form-control`}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                          Kgs
                        </span>
                      </div>
                    </td>
                    <td>
                      <button
                        type='button'
                        className='position-relative btn ms-auto p-0'
                        onClick={() => deleteRow(index)}
                      >
                        <span className='svg-icon-dark'>
                          <KTSVG
                            path='/media/icons/duotune/abstract/abs012.svg'
                            className='ic me-0 mt-1'
                          />
                        </span>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className='ms-4 mb-2'>
            <button
              type='button'
              className='position-relative btn ms-auto p-0'
              onClick={addNewOffloadRow}
            >
              <span className='svg-icon-dark'>
                <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='ic me-0 mt-1' />
              </span>
            </button>
          </div>
        </div>

        <div>
          <div className='card-footer py-2 border-top text-center justify-content-center'>
            <button type='button' className='btn btn-secondary me-3' onClick={cancelOffloading}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={bananaOffloading} disabled={isSubmit}>
              {isSubmit ? 'Please Wait...' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BananaOffLoad
