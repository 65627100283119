import React from 'react'
import {useIntl} from 'react-intl'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {AsyncPaginate} from 'react-select-async-paginate'
import {EaStatisticsWidget} from '../../../../_eaFruitsDms/partials/widgets'

const fetchWarehouses = async (search?: any) => {
  let options: any = [
    {label: 'Option 1', value: 'option1'},
    {label: 'Option 3', value: 'option3'},
  ]
  let hasMore: boolean = false
  options.push({
    label: 'Measurement Unit',
    value: '',
  })
  return {
    options: options,
    hasMore: hasMore,
  }
}
const fetchSrs = async (search?: any) => {
    let options: any = [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 3', value: 'option3' },
    ]
    let hasMore: boolean = false
    options.push({
        label: 'Sr1',
        value: '',
    })
    return {
        options: options,
        hasMore: hasMore,
    }
}
type Props = {
    className: string
}
const RealTimeSalesReport: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Daily Sales Report - Real Time"
    }
    return (
        <>
            <EATitle title='Daily Sales Report - Real Time' />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Daily Sales Report - Real Time' })}</PageTitle>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <AsyncPaginate
                                    loadOptions={fetchWarehouses}
                                    
                                    isSearchable
                                    className='react-select-container my-1 me-3 w-160px'
                                    classNamePrefix='react-select'
                                    placeholder='All Warehouses'
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <AsyncPaginate
                                    loadOptions={fetchSrs}
                                    
                                    className='react-select-container my-1 me-3 w-140px'
                                    classNamePrefix='react-select'
                                    placeholder='All Sales Reps'
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        <div className='row g-3 g-xl-5'>
                            <div className='col-sm-6 col-md-6 col-lg col-xl'>
                                <EaStatisticsWidget
                                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                                    color='success'
                                    title="395"
                                    //  path='/warehouse/DC'
                                    description='Pending Stock'
                                />
                            </div>
                            <div className='col-sm-6 col-md-6 col-lg col-xl'>
                                <EaStatisticsWidget
                                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                                    color='info'
                                    title="TZS 10,000,000.00"
                                    description='Total Sales'
                                //   path='/products'
                                />
                            </div>
                            <div className='col-sm-6 col-md-6 col-lg col-xl'>
                                <EaStatisticsWidget
                                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                                    color='dark'
                                    title="TZS 200,000.00"
                                    description='Total Discount'
                                //  path='/customers'
                                />
                            </div>
                            <div className='col-sm-6 col-md-6 col-lg col-xl'>
                                <EaStatisticsWidget
                                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                                    color='warning'
                                    title="TZS 1,000,000.00"
                                    description='Total Credits'
                                //  path='/reports/sales-report'
                                />
                            </div>
                        </div>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-90px'>Invoice No.</th>
                                        <th className='min-w-100px w-100px'>Order ID</th>
                                        {/* <th className='min-w-120px'>Timestamp</th> */}
                                        <th className='min-w-150px'>Order Date & Time</th>
                                        <th className='min-w-120px'>Customer Name</th>
                                        <th className='w-150px min-w-150px'>Invoice Amt.</th>
                                        <th className='w-150px min-w-150px'>SR Name</th>
                                        <th className='w-100px min-w-150px'>Payment Details</th>
                                        {/* <th className='min-w-100px'>Status</th> */}
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr className="cursor-pointer">
                                        <td>#66304</td>
                                        <td>#8340</td>
                                        <td>30 May 2022 <span className="ms-1">01:16AM</span></td>
                                        <td>Jordan Ntolo</td>
                                        <td>TZS 285</td>
                                        <td>Dusya Sigachyova</td>
                                        <td>Cash</td>
                                    </tr>
                                    <tr className="cursor-pointer">
                                        <td>#66304</td>
                                        <td>#8340</td>
                                        <td>30 May 2022 <span className="ms-1">01:16AM</span></td>
                                        <td>Jordan Ntolo</td>
                                        <td>TZS 285</td>
                                        <td>Ren Xue</td>
                                        <td>
                                            Bank Transfer <br />
                                            #name#<br />
                                            #234829342389
                                        </td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>

                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            Showing 1 to 10 of 50 entries
                        </div>
                        <div className="col">
                            <CustomPagination />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Addwarehouse /> */}
        </>
    )
}

export default RealTimeSalesReport
