import React, {useEffect, useState} from 'react'
import {KTSVG, numberFormat, toAbsoluteUrl} from '../../../../_eaFruitsDms/helpers'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {isEmpty} from 'lodash'

const VehicleTableLo = (props: any) => {
  return (
    <div className='card-body py-3'>
      <div className='table-responsive'>
        <table className='table table-row-bordered align-middle gs-0 gy-2 table-hover'>
          <thead>
            <tr className=' text-muted fw-500'>
              <th className='min-w-100px py-2'>Vehicle Number</th>
              <th className='min-w-100px py-2'>Vehicle Type</th>
              <th className='min-w-100px py-2'>Make/Model/Year</th>
              <th className='min-w-180px py-2'>Wards</th>
              <th className='min-w-100px py-2'>Brand</th>
              <th className='min-w-100px py-2'>Capacity</th>
              <th className='min-w-100px py-2'>Status</th>
              {props.showIcon && <th className='min-w-80px action-th py-2'>Action</th>}
            </tr>
          </thead>
          <tbody>
            {props.list.map((item: any) => {
              return (
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-24px me-2'>
                        <span className='symbol-label'>
                          {item.vehicle_images.length === 0 ? (
                            <img
                              alt=''
                              className='img-fluid rounded-1'
                              src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                            />
                          ) : (
                            <>
                              {item.vehicle_images.map((item: any, index: any) => {
                                if (item.is_cover) {
                                  return (
                                    <img
                                      alt=''
                                      className='img-fluid rounded-1'
                                      src={item.vehicle_thumbnail}
                                    />
                                  )
                                }
                              })}
                            </>
                          )}
                        </span>
                      </div>
                      <span>{item.number}</span>
                    </div>
                  </td>
                  <td>{item.type && item.type.vechile_type}</td>
                  <td>
                    {item.make && item.make.vechile_make}/{item.model && item.model.vechile_model}/
                    {item.year}
                  </td>
                  <td>
                    {/* {
                                                item.wards.length !== 0 &&
                                                item.wards.map((wards: any, index: any) => (
                                                    <>{wards.name} {`& ${item.wards.length - 1} more`} </>
                                                ))
                                            } */}
                    {item.wards.length !== 0 && item.wards[0].name}{' '}
                    {item.wards[1] && `& ${item.wards.length - 1} more`}
                  </td>
                  <td>{item.is_for_horeca ? 'All Brands' : item.brand_name.name}</td>
                  <td>{`${numberFormat(item.capacity).displayFormat}`} KG</td>
                  <td>
                    <a
                      href='#ea_modal_vehicle_status'
                      data-bs-toggle='modal'
                      data-bs-target='#ea_modal_vehicle_status'
                      onClick={() => props.getProps(item, 'edit')}
                    >
                      {item.status === 'Available' && (
                        <span className='badge bg-light-success text-dark'>Available</span>
                      )}
                      {item.status === 'Breakdown' && (
                        <span className='badge bg-light-primary text-dark'>Breakdown</span>
                      )}
                      {item.status === 'InService' && (
                        <span className='badge bg-secondary text-dark'>In Service</span>
                      )}
                    </a>
                  </td>
                  {props.showIcon && (
                    <td className='text-end'>
                      <a
                        href='#ea_modal_edit_vehicle'
                        data-bs-toggle='modal'
                        data-bs-target='#ea_modal_create_vehicle'
                        className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                        onClick={() => props.getProps(item, 'edit')}
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                      </a>
                      {/* <a
                          href='#ea_modal_delete_vehicle'
                          data-bs-toggle='modal'
                          data-bs-target='#ea_modal_delete_vehicle'
                          className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'
                          onClick={() => props.getProps(item, 'delete')}
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr011.svg'
                            className='ic mr-0'
                          />
                        </a> */}
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default VehicleTableLo
