import React from "react"

const ValidationHandler = (value: any, text: any) => {

    let textLine = ""

    if (value !== "") {
        textLine = ""
    } else {
        textLine = text
    }

    return textLine
}

export default ValidationHandler
