import clsx from "clsx"
import "cropperjs/dist/cropper.css"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import Cropper from "react-cropper"
import { KTSVG, toAbsoluteUrl } from "../../../helpers"

const defaultSrc = toAbsoluteUrl("/media/icons/placeholder_img_crop.jpg");
const defaultSrcbanner = toAbsoluteUrl("/media/icons/img_crop_banner.png");
type Props = {
    size?: string;
    text?: string;
    isUpload?: boolean
    isBanner?: boolean
}
export const Banner = (props: any) => {
    const { size, text, isUpload, isBanner, children } = props
    // const [image, setImage] = useState(defaultSrc);
    const [cropData, setCropData] = useState(defaultSrc);
    const [banner, setBanner] = useState(defaultSrcbanner);
    const [cropDataBanner, setCropDataBanner] = useState(defaultSrcbanner);
    // const [cropper, setCropper] = useState<any>();
    // const [show, setShow] = useState(false);
    // const handleShow = () => setShow(true);
    // const handleHide = () => setShow(false);
    // const onChange = (e: any) => {
    //     handleShow();
    //     e.preventDefault();
    //     let files;
    //     if (e.dataTransfer) {
    //         files = e.dataTransfer.files;
    //     } else if (e.target) {
    //         files = e.target.files;
    //     }
    //     const reader = new FileReader();
    //     reader.onloadend = function () {
    //         console.log('RESULT', reader.result)
    //     }
    //     reader.onload = () => {
    //         setImage(reader.result as any);
    //         setBanner(reader.result as any);
    //     };
    //     reader.readAsDataURL(files[0]);

    // };

    // const getCropData = () => {
    //     handleHide();
    //     if (typeof cropper !== "undefined") {
    //         setCropData(cropper.getCroppedCanvas().toDataURL());
    //     }
    // };

    const getCropDataBanner = () => {
        handleHide();
        if (typeof cropper !== "undefined") {
            setCropDataBanner(cropper.getCroppedCanvas().toDataURL());
        }
    };


    const [image, setImage] = useState(defaultSrc);
    const [cropper, setCropper] = useState<any>();
    const [show, setShow] = useState(false);
    const [isLoadin, setLoading] = useState("Save");

    const handleShow = () => {
        setShow(true)
        if (props.id !== "") {
            // $(`#${props.id}`).blur();
            // $(`#${props.id}`).data("DateTimePicker").hide()
        }
    };
    const handleHide = () => {
        props.onCancle && props.onCancle()
        setShow(false);
    }

    let files: any = ""
    const onChange = (e: any) => {
        var allowedExtensions =
            /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if (allowedExtensions.exec(e.target.value)) {
            handleShow();

            e.preventDefault();

            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }
            const reader = new FileReader();
            reader.onloadend = function () {

            }
            reader.onload = () => {
                setImage(reader.result as any);
            };
            reader.readAsDataURL(files[0]);
            props.onImageChange(e)
            e.target.value = ''
        }
    };

    const getCropData = () => {
        handleHide();
        if (typeof cropper !== "undefined") {
            props.setCrop(cropper.getCroppedCanvas().toDataURL());
            let url = cropper.getCroppedCanvas().toDataURL()
            handleUpload(url)
        } else {

        }
    };

    const dataUrlToFileUsingFetch = async (
        url: string,
        fileName: string,
        mimeType: string
    ) => {
        let data: any = ''
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();

        data = new File([buffer], fileName, { type: mimeType });
        return data;
    };

    const handleUpload = async (url: string) => {
        dataUrlToFileUsingFetch(url, "test.png", "image/png").then((res) => {
            props.getImage(res, url)
        })
    }

    return (
        <>
            <div>
                <div className={isBanner ? `d-block flex-column align-items-center file-group position-relative` : 'd-flex align-items-center file-group'}>
                    {
                        isBanner ? (
                            <>
                                <div className={
                                    clsx(props.imageRequire ? 'file-icon w-100 text-center pro-banner border border-danger rounded' : 'file-icon w-100 text-center pro-banner border-0')
                                } >
                                    <span className='symbol-label bg-transparent'>
                                        <img style={{ width: "100%", objectPosition: 'center', height: 'auto' }} src={props.cropData} alt="cropped" className="rounded text-dark" />
                                    </span>
                                </div>

                            </>
                        ) : (
                            <>
                                <div className={clsx('file-icon symbol symbol-100px me-3', props.imageRequire)}>
                                    <span className='symbol-label bg-transparent'>
                                        <img style={{ width: "100%", objectFit: 'cover', objectPosition: 'center' }} src={props.cropData} alt="cropped" className="h-100px w-100px rounded text-dark" />
                                    </span>
                                </div>
                            </>
                        )
                    }

                    <div className="position-relative form-file">
                        {
                            isUpload ? (
                                <>
                                    <label className="form-label mb-0" htmlFor='group-icon'>
                                        <button type='button' className='btn btn-bg-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1'>
                                            <span className="svg-icon svg-icon-1">
                                                <KTSVG path="/media/icons/duotune/files/fil022.svg" className="ic svg-icon-muted me-1" />
                                            </span>Upload Image
                                        </button>
                                    </label>
                                </>
                            ) : ''
                        }

                        <input
                            type="file"
                            className="file-input opacity-0 position-absolute h-auto"
                            placeholder="Type here..." id='group-icon'
                            onChange={onChange}
                            accept="image/*"
                        />
                        {props.imageRequire && <span className="font-13 text-danger" >This field is required.</span>}
                        <div className={clsx(props.imageSize ? 'font-13 text-danger mt-2' : 'font-13 text-muted mt-2 ')} >
                            <span>{text}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} centered id='modal_img_crop' >
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {isBanner ? (
                        <>
                            <Cropper
                                style={{ height: "100%", width: "100%" }}
                                zoomTo={0}
                                // aspectRatio={props.aspectRatio ? props.aspectRatio :2 / 1}
                                aspectRatio={props.aspectRatio ? props.aspectRatio : 2 / 1}
                                preview=".img-preview"
                                src={image}
                                viewMode={1}
                                minCropBoxHeight={props.minCropBoxHeight ? props.minCropBoxHeight : 300}
                                minCropBoxWidth={props.minCropBoxWidth ? props.minCropBoxWidth : 600}
                                background={false}
                                responsive={false}
                                autoCropArea={1}
                                scalable={false}
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                                guides={true}
                            />
                        </>) :
                        (
                            <>
                                <Cropper
                                    style={{ height: "100%", width: "100%" }}
                                    zoomTo={0}
                                    aspectRatio={1 / 1}
                                    preview=".img-preview"
                                    src={image}
                                    viewMode={1}
                                    minCropBoxHeight={200}
                                    minCropBoxWidth={200}
                                    background={false}
                                    responsive={false}
                                    autoCropArea={1}
                                    scalable={false}
                                    onInitialized={(instance) => {
                                        setCropper(instance);
                                    }}
                                    guides={true}
                                />
                            </>)}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button className="btn btn-secondary" onClick={handleHide}>
                        Cancel
                    </button>
                    {
                        isBanner ? (
                            <button className="btn btn-primary" onClick={getCropData}>
                                Submit
                            </button>)
                            : (
                                <button className="btn btn-primary" onClick={getCropData}>
                                    Submit
                                </button>
                            )
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Banner;
