import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
} from '../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {getRequest, patchRequest, postRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageLink, PageTitle} from '../../../../_eaFruitsDms/layout/core'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'

const SupportRequestTypeReason = () => {
  const intl = useIntl()

  const initialRow: any = {
    option: '',
    type: 'complaint_request_type',
  }

  const initialReasonValidation: any = {
    option: '',
  }

  const [masterReason, setMasterReason] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [fetchingRecords, setFetchingRecords] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(10)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('')
  const [reason, setReason] = useState<any>(initialRow)
  const [reasonValidation, setReasonValidation] = useState<any>(initialReasonValidation)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState('Submit')
  const [disableBtn, setDisable] = useState(false)
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  const debounceSearch = useDebounce(search, 500)

  const reasonMaster: Array<PageLink> = [
    {
      title: 'Masters',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    setTimeout(() => fetchReasonMaster().then(), timer)
  }, [currentOffset, debounceSearch, count, masterReason.length])

  const fetchPermission = async () => {
    let res = await checkPermissions('masters_rejection_options')
    setPermission(res)
  }

  const fetchReasonMaster = async () => {
    setFetchingRecords(true)
    const API = `${apiBaseURL()}master/options-master/?type=complaint_request_type&limit=${limit}&ordering=-updated_date&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    if (response && response.results) {
      response.results.push({
        id: '',
        option: 'Other',
      })
      setMasterReason(response.results)
      setCount(response.count)
      setNext(response.next)
      setPrev(response.previous)
      setFetchingRecords(false)
    }
  }

  const handleChangeSearch = (e: any) => {
    let inputValue = e.target ? e.target.value : e.value
    setSelected(0)
    setCurrentOffset(0)
    setSearch(inputValue)
  }

  const fetchSingleReasonMaster = async (item: any) => {
    setModalLoader(true)
    setId(item.id)
    const API = `${apiBaseURL()}master/options-master/${item.id}/`
    const response = await getRequest(API, true)
    setReason({...reason, ['option']: response.option})
    setModalLoader(false)
  }

  const clearFunctionAdd = () => {
    setReason(initialRow)
    setReasonValidation('')
    setErrorMessage('')
    setIsLoading('Submit')
    setDisable(false)
    setModalLoader(false)
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value
    setReasonValidation({...reasonValidation, [inputName]: ''})
    setReason({...reason, ['option']: inputValue})
  }

  const validateForm = () => {
    let navigateField = {
      option: reason.option,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    let validations = {...reasonValidation}
    let isValid = true
    if (reason.option == '') {
      validations.option = 'is-invalid'
      isValid = false
    }
    setReasonValidation(validations)
    return isValid
  }

  const submitFunction = async () => {
    const isValid = validateForm()
    if (isValid) {
      const reasonInfo = {...reason}
      setDisable(true)
      setIsLoading('Please Wait...')
      if (requestHandler === 'add') {
        postRequest(`${apiBaseURL()}master/options-master/`, reasonInfo, true).then(
          (response: any) => {
            handleResponse(response)
          }
        )
      } else {
        patchRequest(`${apiBaseURL()}master/options-master/${id}/`, reasonInfo, true).then(
          (response: any) => {
            handleResponse(response)
          }
        )
      }
    }
  }

  const handleResponse = (response: any) => {
    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }
    if (response.status === 200 || response.status === 201) {
      notify.success = true
    }
    if (response.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = response.data.error.field
    }
    if (notify.success) {
      setIsLoading('Submit')
      setDisable(false)
      fetchReasonMaster()
      $('#closeEaModalLORRejection').trigger('click')
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 2000)
      setIsAlertSuccess(true)
    }
    if (notify.failed) {
      setIsLoading('Submit')
      setDisable(false)
      if (notify.errorField == 'non_field_errors') {
        setErrorMessage(
          'Your added data is already exist in this system! Please try again with unique data.'
        )
      }
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <EATitle title='Complaint Categories - ' />
      <div>
        <PageTitle breadcrumbs={reasonMaster}>
          {intl.formatMessage({id: 'Complaint Categories'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('Your Complaint Categories has been added successfully.')
              : messageHandler === 'successEdit'
              ? FormatMessage('Your Complaint Categories has been updated successfully.')
              : messageHandler === 'successDelete' &&
                FormatMessage('Your Complaint Categories has been added successfully.')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className='card'>
          {fetchingRecords && <IsLoader />}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} />
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_reasons'
                    btnName='Add Complaint Categories'
                    onClick={() => {
                      setRequest('add')
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
              {masterReason.length == 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className=' text-muted fw-500'>
                      <th className='min-w-100px py-2'>Complaint Categories</th>
                      <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {masterReason.map((item: any) => {
                      return (
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div>
                                <span>{item.option}</span>
                              </div>
                            </div>
                          </td>
                          <td className='text-end'>
                            {permission.write && item.id !== '' && (
                              <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                data-bs-toggle='modal'
                                data-bs-target='#ea_modal_create_reasons'
                                onClick={(event) => {
                                  fetchSingleReasonMaster(item)
                                  setRequest('edit')
                                  setMessageHandler('successEdit')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='ic mr-0'
                                />
                              </a>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={reasonMaster}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisabled={fetchingRecords}
        />

        {/* <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${masterReason.length == 0 ? 0 : selected * 10 + 1} to ${
                masterReason.length == 0
                  ? 0
                  : masterReason.length < 10
                  ? count
                  : (selected + 1) * 10
              } of ${masterReason.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                count={masterReason.length == 0 ? 0 : count}
                selected={selected}
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
              />
            </div>
          </div>
        </div> */}
      </div>
      <EaModal
        id='ea_modal_create_reasons'
        modalTitle={
          requestHandler === 'add' ? 'Add Complaint Categories' : 'Update Complaint Categories'
        }
        btnDisabled={disableBtn}
        closeAuto='closeEaModalLORRejection'
        onSubmit={submitFunction}
        cancel={clearFunctionAdd}
        actionBtnName={isLoading}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Complaint Categories<span style={{color: '#FF0000'}}>*</span>
          </label>
          <input
            type='text'
            id='option'
            className={clsx('form-control', reasonValidation.option)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e, 'option')}
            name='option'
            value={reason && reason.option}
          />
        </div>
        <div className='mb-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  )
}

export default SupportRequestTypeReason
