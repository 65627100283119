import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  isEmpty,
  numberFormat,
} from '../../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../../TenantVariables'
import useDebounce from '../../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {WarehouseFilter} from '../../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {CustomDatePicker} from '../../../../../_eaFruitsDms/helpers/components/CustomDatePicker'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'

type Props = {
  className: string
}
const LoadOutRequests: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Load-Out Requests"
    }

    let todayDate = dateFormat(moment());

    const [fetchingData, setFetchingData] = useState<boolean>(false);
    const [loadOutRequestReport, setLoadOutRequestReport] = useState([]);
    const [count, setCount] = useState(0);
    const [selected, setSelected] = useState(0);
    const [currentOffSet, setCurrentOffSet] = useState(0);
    const [search, setSearch] = useState("");
    const [warehouse, setWarehouse] = useState({label: 'All Warehouses', value: ''});
    const [limit, setLimit] = useState(tenantConfiguration.limit);
    const [date, setDate] = useState<any>({
        startDate: todayDate,
        endDate: todayDate
    });

    const debounceSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchReportData().then()
    }, [debounceSearch, currentOffSet, warehouse, date]);


    const fetchReportData = async () => {
        setFetchingData(true)
        const response = await getRequest(
          `${apiBaseURL()}reports/rejection/load-out-request?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&warehouse_id=${checkEmpty(warehouse.value, "")}&rejection_date_duration_after=${date.startDate.apiDate}&rejection_date_duration_before=${date.endDate.apiDate}`,
          true
        )

        if (response.results) {
            const reportData: any = []
            response.results.map((report: any) => {
                let displayReason = "-"

                if (!isEmpty(report.rejection_reason_option)) {
                    displayReason = report.rejection_reason_option
                } else if (!isEmpty(report.loadout_reason_note)) {
                    displayReason = report.loadout_reason_note
                } else if (!isEmpty(report.partial_approve_reason)) {
                    displayReason = report.partial_approve_reason
                } else if (!isEmpty(report.partial_approve_reason_note)) {
                    displayReason = report.partial_approve_reason_note
                }

                return reportData.push({
                    id: report.id,
                    request_id: isEmpty(report.request_id) ? "-" : `#${report.request_id}`,
                    created_date: isEmpty(report.created_at) ? "-" : dateFormat(report.created_at).displayDate,
                    created_time: isEmpty(report.created_at) ? "-" : dateFormat(report.created_at, "LT").displayDate,
                    requested_by: isEmpty(report.request_from_name) ? "-" : `${report.request_from_name} (${isEmpty(report.request_from_type) ? "-" : report.request_from_type.toUpperCase()})`,
                    response_by: isEmpty(report.processed_by_name) ? "-" : `${report.processed_by_name} (${isEmpty(report.processed_by_type) ? "-" : report.processed_by_type.toUpperCase()})`,
                    action_date: isEmpty(report.updated_at) ? "-" : dateFormat(report.updated_at).displayDate,
                    action_time: isEmpty(report.updated_at) ? "-" : dateFormat(report.updated_at, "LT").displayDate,
                    requested_qty: isEmpty(report.requested_qty)? "-" : numberFormat(report.requested_qty).displayFormat,
                    rejected_qty: isEmpty(report.rejected_qty)? "-" : numberFormat(report.rejected_qty).displayFormat,
                    reason: displayReason
                })
            })
            setCount(response.count)
            setLoadOutRequestReport(reportData)
        }
        setFetchingData(false)
    };

    const handleFilterChange = (e: any, name?: string) => {
        const inputName:string = e.target ? e.target.name : name

        switch (inputName) {
            case 'warehouse':
                setWarehouse(e)
                break
            case 'search':
                setSearch(e.target.value)
                break
        }
    }

    return (
        <>
            <EATitle title='Load-Out Requests' />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Load-Out Requests' })}</PageTitle>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar onChange={handleFilterChange}/>
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <WarehouseFilter
                                  warehouse={warehouse}
                                  handleFilterChange={handleFilterChange}
                                  isDisabled={fetchingData}
                                />

                                <CustomDatePicker
                                  date={date}
                                  setDate={setDate}
                                  displayLoader={fetchingData}
                                />
                                {/*<button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 fw-600 d-flex align-items-center">*/}
                                {/*    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />*/}
                                {/*    Export*/}
                                {/*</button>*/}
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {
                                fetchingData ? <FetchingRecords /> :
                                  loadOutRequestReport.length === 0 ? <NoRecords />
                                    :
                                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                        {/* begin::Table head */}
                                        <thead>
                                        <tr className='text-muted fw-500'>
                                            <th className='min-w-110px py-2'>Date & Time</th>
                                            <th className='min-w-110px py-2'>Request Id</th>
                                            <th className='min-w-100px py-2'>Request by</th>
                                            <th className='min-w-110px py-2'>Date & Time</th>
                                            <th className='min-w-120px py-2'>Response by</th>
                                            <th className='min-w-120px py-2'>Requested Qty.</th>
                                            <th className='min-w-120px py-2'>Rejected Qty.</th>
                                            <th className='min-w-100px py-2'>Rejection Comments</th>
                                        </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                        {
                                            loadOutRequestReport.map((requestReport: any) => {
                                                return <tr>
                                                    <td>
                                                        {requestReport.created_date}
                                                        <p className='font-13 text-muted mb-0'>{requestReport.created_time}</p>
                                                    </td>
                                                    <td>
                                                        {requestReport.request_id}
                                                    </td>
                                                    <td>
                                                        {requestReport.requested_by}
                                                    </td>
                                                    <td>
                                                        {requestReport.action_date}
                                                        <p className='font-13 text-muted mb-0'>{requestReport.action_time}</p>
                                                    </td>
                                                    <td>
                                                        {requestReport.response_by}
                                                    </td>
                                                    <td>
                                                        {requestReport.requested_qty}
                                                    </td>
                                                    <td>
                                                        {requestReport.rejected_qty}
                                                    </td>
                                                    <td>
                                                        {requestReport.reason}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                            }
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <CustomPaginate
                  data={loadOutRequestReport}
                  selected={selected}
                  setSelected={setSelected}
                  setCurrentOffset={setCurrentOffSet}
                  totalRecords={count}
                  limit={limit}
                  isDisabled={fetchingData}
                />
            </div>
        </>
    )
}

export default LoadOutRequests
