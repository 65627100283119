import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  addBankAccounts,
  bankAccountsList,
  bankAccountsUpdate,
} from '../../../_eaFruitsDms/apiFunctions/paymentAccounts/paymentAccounts'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import capitalizeFirstLetter from '../../../_eaFruitsDms/helpers/components/CapitalLetter'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageLink, PageTitle} from '../../../_eaFruitsDms/layout/core'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../TenantVariables'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'

type Props = {
  className: string
}
const BankAccounts: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const paymentBreadCrumbs: Array<PageLink> = [
    {
      title: 'Payment Accounts',
      path: 'payment-accounts/bank-accounts',
      isSeparator: false,
      isActive: false,
    },
  ]

  let bankFiltered : any = retrieveFilter(filters.wm_bank);

    const initialState = {
      selected: bankFiltered?.selected || '',
      search: bankFiltered?.search || '',
      currentOffSet: bankFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [bankAccountList, setBankAccountList] = useState<any>([])
  const [bankOption, setBankOption] = useState<any>([])
  const [bankOptionList, setBankOptionList] = useState<any>([])
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>(bankFiltered?.search || '')
  const [currentOffset, setCurrentOffset] = useState<any>(bankFiltered?.currentOffSet || '0');
  const [selected, setSelected] = useState<number>(bankFiltered?.selected || 0)
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [accountNumber, setAccountNumber] = useState<any>('')
  const [branch, setBranch] = useState<any>('')
  const [ifscCode, setIfscCode] = useState<any>('')
  const [accountNumberEdit, setAccountNumberEdit] = useState<any>('')
  const [branchEdit, setBranchEdit] = useState<any>('')
  const [ifscCodeEdit, setIfscCodeEdit] = useState<any>('')
  const [validationBankName, setValidationBankName] = useState<any>('')
  const [validationAccountNumber, setValidationAccountNumber] = useState<any>('')
  const [validationBranch, setValidationBranch] = useState<any>('')
  const [validationIfscCode, setValidationifscCode] = useState<any>('')
  const [validationBankNameEdit, setValidationBankNameEdit] = useState<any>('')
  const [validationAccountNumberEdit, setValidationAccountNumberEdit] = useState<any>('')
  const [validationBranchEdit, setValidationBranchEdit] = useState<any>('')
  const [validationIfscCodeEdit, setValidationifscCodeEdit] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [EditId, setEditId] = useState<any>('')
  const [DeleteId, setDeleteId] = useState<any>('')
  const [deleteName, setDeleteName] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [singleBankName, setSingleBankName] = useState<any>('')
  const [singleBankNameEdit, setSingleBankNameEdit] = useState<any>('')
  const [accessSts, setAccessSts] = useState<any>(true)
  const [accessStsEdit, setAccessStsEdit] = useState<any>(true)
  const [bankOffset, setBankOffset] = useState<any>('')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchSelect, setSearchSelect] = useState('')
  const [deleteBankName, setDeleteBankName] = useState<any>('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(bankFiltered?.buttonDisable || false)
  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    fetchPermission()
    BankAccount()
  }, [currentOffset , debounceSearch])

  useEffect(() => {
    setBankOffset('')
  }, [searchSelect])

  const fetchPermission = async () =>{
    let res = await checkPermissions("payment_account")
    setPermission(res)
   }

   useEffect(() =>{
    getRequest(`${apiBaseURL()}master/payment-bank/?limit=1000`, true).then((res) => {
      let bankName: any = []
      res.results.forEach((element: any) => {
        // allBankNameList.push(element)
        bankName.push({ value: element.id, label: element.bank_name })
      })
      setBankOptionList(bankName)
    })
   },[])

  const BankAccount = () => {
    setListLoader(true)
    bankAccountsList(limit, currentOffset, debounceSearch).then((res) => {
      setBankAccountList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.wm_bank , filterStates)

    if(search == ''){
        setButtonDisable(false)
    }
  }
  // const BankNameDropDown = () => {
  //   // let bankName: any = [{value: '', label: 'Select...'}]
  //   let bankName: any = []
  //   // let allBankNameList: any = []
  //   axios.get(`${apiBaseURL()}master/payment-bank/`, header).then((res) => {
  //     let data = res.data
  //     data.forEach((element: any) => {
  //       // allBankNameList.push(element)
  //       bankName.push({value: element.id, label: element.bank_name})
  //     })

  const fetchBankName = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []

    const BANK_API = `${apiBaseURL()}master/payment-bank/?limit=${limit}&offset=${bankOffset}&search=${search}&ordering=bank_name`
    const bankResponse = await getRequest(BANK_API, true)
    let hasMore: boolean = false

    if (bankResponse.next !== null) {
      const queryParams = new URLSearchParams(bankResponse.next)
      let newOffset: any = queryParams.get('offset')
      setBankOffset(newOffset)
      hasMore = true
    }
    if (bankResponse.results.length > 0) {
      bankResponse.results.map((option: any) => {
        options.push({
          label: option.bank_name,
          value: option.id,
        })
      })
    }
    if (bankOption.length > 0) {
      setBankOption(bankOption.concat(options))
    } else {
      setBankOption(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handlechangeBankName = (e: any) => {
    setSingleBankName(e.value)
    setValidationBankName('')
  }
  const handlechangeEditBankName = (e: any) => {
    setSingleBankNameEdit(e.value)
    setValidationBankNameEdit('')
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      bankAccountsList(limit, newOffset, search).then((res) => {
        setBankAccountList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      // setCurrentOffset(newOffset)
      setSelected(selected - 1)
      bankAccountsList(limit, newOffset, search).then((res) => {
        setBankAccountList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      // setCurrentOffset(newOffset)
      // setSelected(selected + 1)
      bankAccountsList(limit, newOffset, search).then((res) => {
        setBankAccountList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
      })
    }
  }

  const searchList = (e: any) => {
    setSearch(e.target.value)
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  //  Develop BY: Kriti
  //  Description: Add Bank Account function

  const AddFunction = () => {
    let navigateField = {
      singleBankName: singleBankName,
      accountNumber: accountNumber,
      branch: branch,
      ifscCode: ifscCode,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (singleBankName === '') {
      setValidationBankName('is-invalid')
    } else {
      setValidationBankName('')
    }
    if (accountNumber === '') {
      setValidationAccountNumber('is-invalid')
    } else {
      setValidationAccountNumber('')
    }
    if (branch === '') {
      setValidationBranch('is-invalid')
    } else {
      setValidationBranch('')
    }
    if (ifscCode === '') {
      setValidationifscCode('is-invalid')
    } else {
      setValidationifscCode('')
    }
    if (singleBankName !== '') {
      if (accountNumber !== '') {
        if (branch !== '') {
          if (ifscCode !== '') {
            AddFunctionApi()
          }
        }
      }
    }
  }

  const clearFunction = () => {
    setAccountNumber('')
    setBranch('')
    setIfscCode('')
    setSingleBankName('')
    setValidationBankName('')
    setValidationAccountNumber('')
    setValidationBranch('')
    setValidationifscCode('')
    setErrorMessage('')
    setBankOffset('')
    setAccessSts('true')
  }

  //  Develop BY: Kriti
  //  Description: Add Bank Account API Call function

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    addBankAccounts(singleBankName, accountNumber, branch, ifscCode, accessSts).then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunction()
        setListLoader(true)
        bankAccountsList(limit, '0', '').then((res) => {
          setBankAccountList(res.data)

          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
        $('#closeAddEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'account_number') {
        setErrorMessage(
          'Your added data is already exist in this system! Please try again with unique data.'
        )
      }
      setIsloading('Submit')
    })
  }

  // edit function
  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setEditId(item.id)
    setSingleBankNameEdit(item.paymentBank.id)
    setAccountNumberEdit(item.account_number)
    setBranchEdit(item.branch)
    setIfscCodeEdit(item.ifsccode)
    setAccessStsEdit(item.is_active)
    setModalLoader(false)
  }

  //  Develop BY: Kriti
  //  Description: Edit Bank Account function

  const EditFunction = () => {
    let navigateField = {
      singleBankNameEdit: singleBankNameEdit,
      accountNumberEdit: accountNumberEdit,
      branchEdit: branchEdit,
      ifscCodeEdit: ifscCodeEdit,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (singleBankNameEdit === '') {
      setValidationBankNameEdit('is-invalid')
    } else {
      setValidationBankNameEdit('')
    }
    if (accountNumberEdit === '') {
      setValidationAccountNumberEdit('is-invalid')
    } else {
      setValidationAccountNumberEdit('')
    }
    if (branchEdit === '') {
      setValidationBranchEdit('is-invalid')
    } else {
      setValidationBranchEdit('')
    }
    if (ifscCodeEdit === '') {
      setValidationifscCodeEdit('is-invalid')
    } else {
      setValidationifscCodeEdit('')
    }
    if (singleBankNameEdit !== '') {
      if (accountNumberEdit !== '') {
        if (branchEdit !== '') {
          if (ifscCodeEdit !== '') {
            EditFunctionApi()
          }
        }
      }
    }
  }

  const clearFunctionEdit = () => {
    setSingleBankNameEdit('')
    setAccountNumberEdit('')
    setBranchEdit('')
    setIfscCodeEdit('')
    setAccessStsEdit('')
    setErrorMessage('')
    setValidationBankNameEdit('')
    setValidationAccountNumberEdit('')
    setValidationBranchEdit('')
    setValidationifscCodeEdit('')
    setBankOffset('')
    setModalLoader(false)
  }

  //  Develop BY: Kriti
  //  Description: Edit Bank Account API function

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    bankAccountsUpdate(
      singleBankNameEdit,
      accountNumberEdit,
      branchEdit,
      ifscCodeEdit,
      accessStsEdit,
      EditId
    ).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
        setListLoader(true)
        bankAccountsList(limit, '0', debounceSearch).then((res) => {
          setBankAccountList(res.data)
          setListLoader(false)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
        })
        $('#closeEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'account_number') {
        setErrorMessage(
          'Your added data is already exist in this system! Please try again with unique data.'
        )
      }
      setIsloading('Submit')
    })
  }

  // const onDelete = (item: any) => {
  //   setDeleteId(item.id)
  //   setDeleteName(item.account_number)
  //   setDeleteBankName(item.paymentBank.bank_name)
  // }
  // const deleteApi = () => {
  //   bankAccountsDelete(DeleteId).then((notify) => {
  //     if (notify.success) {
  //       bankAccountsList(limit, currentOffset, search).then((res) => {
  //         setBankAccountList(res.data)
  //         setCount(res.count)
  //         setNext(res.next)
  //         setPrev(res.prev)
  //       })
  //       setIsAlertSuccess(true)
  //     }
  //     if (notify.failed) {
  //       setIsAlertFailed(true)
  //     }
  //     $('#closeDeleteEaModalBrand').trigger('click') // modal close
  //     setTimeout(() => {
  //       setIsAlertSuccess(false)
  //       setIsAlertFailed(false)
  //     }, 2000)
  //   })
  // }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Bank Accounts' />
      <div>
        <PageTitle breadcrumbs={paymentBreadCrumbs}>
          {intl.formatMessage({ id: 'Bank Accounts' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? 'Your bank account has been added succeefully.'
              : messageHandler === 'successEdit'
                ? 'Your bank account has been updated succeefully.'
                : messageHandler === 'successDelete' &&
                'Your bank account has been deleted succeefully.'
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              {/* <CustomSearchBar /> */}
              <div className='col-12 col-md-auto mt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className='form-control form-search'
                  onChange={searchList}
                  value={search}
                  disabled={listLoadder}
                />
              </div>
              <div className='col-auto ms-md-auto'>
                <form className="card-toolbar d-flex my-0" onSubmit={(e: any) => e.preventDefault()}>
                   {permission.write &&
                    <div className="me-3">
                      <EaButton
                      isModal={true}
                      dataTarget='#ea_modal_create_bank_account'
                      btnName='Add Bank Account'
                      onClick={() => {
                        setMessageHandler('successAdd')
                      }}
                    />
               </div>}
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
                </form>
                 </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            <>
                  {bankAccountList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-1'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='w-150px py-2'>Bank Name</th>
                          <th className='min-w-100px py-2'>Branch</th>
                          <th className='min-w-100px py-2'>Account Number</th>
                          <th className='min-w-100px py-2'>IFSC Code</th>
                          <th className='min-w-100px'>Status</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && "Actions"}</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {bankAccountList.map((item: any) => {
                          return (
                            <tr>
                              <td>{item.paymentBank.bank_name}</td>
                              <td>{item.branch}</td>
                              <td>{item.account_number}</td>
                              <td>{item.ifsccode}</td>
                              {/* <td>
                          <span className='badge badge-light-success text-dark'>Active</span>
                        </td> */}
                              <td>
                                {item.is_active ? (
                                  <span className='badge badge-light-success text-dark'>
                                    Active
                                  </span>
                                ) : (
                                  <span className='badge badge-secondary text-dark'>Inactive</span>
                                )}
                              </td>
                              <td className='text-end'>
                                {permission.write &&
                                <span
                                data-bs-target='#ea_modal_edit_bank_account'
                                data-bs-toggle='modal'
                                onClick={(event) => {
                                  onChangeAdd(item)
                                  setMessageHandler('successEdit')
                                }}
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='ic mr-0'
                                />
                              </span>}
                                {/* {permission.delete &&
                                <span
                                data-bs-target='#ea_modal_delete_bank_account'
                                data-bs-toggle='modal'
                                onClick={(event) => {
                                  onDelete(item)
                                  setMessageHandler('successDelete')
                                }}
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='ic mr-0'
                                />
                              </span>} */}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
                </>
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={bankAccountList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.wm_bank}
          filterStates={filterStates}
          isDisabled={listLoadder}
        />
      </div>
      <EaModal
        id='ea_modal_create_bank_account'
        modalTitle='Add Bank Account'
        onSubmit={AddFunction}
        closeAuto='closeAddEaModalBrand'
        cancel={clearFunction}
        actionBtnName={isLoadingAdd}
      >
        <div className='mb-4'>
          <label className='form-label' id='singleBankName'>
            Bank Name<span className="text-danger">*</span>
          </label>
          <AsyncPaginate

            loadOptions={fetchBankName}
            isSearchable
            placeholder='Select'
            className={clsx('react-select-container w-100', validationBankName)}
            classNamePrefix='react-select'
            name='bankAdd'
            value={bankOption.filter((option: any) => option.value === singleBankName)}
            onChange={handlechangeBankName}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Account Number<span className="text-danger">*</span>
          </label>
          <input
            type='number'
            id='accountNumber'
            className={clsx('form-control', validationAccountNumber)}
            placeholder='Type here...'
            onChange={(e) => {
              setAccountNumber(capitalizeFirstLetter(e.target.value))
              setValidationAccountNumber('')
            }}
            value={accountNumber}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Branch<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='branch'
            className={clsx('form-control', validationBranch)}
            placeholder='Type here...'
            onChange={(e) => {
              setBranch(capitalizeFirstLetter(e.target.value))
              setValidationBranch('')
            }}
            value={branch}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            IFSC Code<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='ifscCode'
            className={clsx('form-control', validationIfscCode)}
            placeholder='Type here...'
            onChange={(e) => {
              setIfscCode(capitalizeFirstLetter(e.target.value))
              setValidationifscCode('')
            }}
            value={ifscCode}
          />
        </div>
        <div>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <label className='form-check-label me-3'>Access Status</label>
            <input
              checked={accessSts}
              id='accessStatus'
              onChange={(e) => {
                let checkedEdit = $('#accessStatus').is(':checked')
                if (checkedEdit == true) {
                  setAccessSts(true)
                } else {
                  setAccessSts(false)
                }
              }}
              value={accessSts}
              className='form-check-input h-30px w-50px'
              type='checkbox'
              name='layout-builder[layout][header][fixed][desktop]'
              defaultChecked={true}
            />
            {/* <input className='form-check-input' type='checkbox' value='' checked /> */}
          </div>
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>

      <EaModal
        id='ea_modal_edit_bank_account'
        modalTitle='Edit Bank Account'
        onSubmit={EditFunction}
        closeAuto='closeEditEaModalBrand'
        cancel={clearFunctionEdit}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label' id='singleBankNameEdit'>
            Bank Name<span className="text-danger">*</span>
          </label>
          <AsyncPaginate

            loadOptions={fetchBankName}
            placeholder='Select'
            isSearchable
            className={clsx('react-select-container w-100', validationBankNameEdit)}
            classNamePrefix='react-select'
            name='bankEdit'
            value={bankOptionList.filter((option: any) => option.value === singleBankNameEdit)}
            onChange={handlechangeEditBankName}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Account Number<span className="text-danger">*</span>
          </label>
          <input
            type='number'
            id='accountNumberEdit'
            className={clsx('form-control', validationAccountNumberEdit)}
            placeholder='Type here...'
            onChange={(e) => {
              setAccountNumberEdit(capitalizeFirstLetter(e.target.value))
              setValidationAccountNumberEdit('')
            }}
            value={accountNumberEdit}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Branch<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='branchEdit'
            className={clsx('form-control', validationBranchEdit)}
            placeholder='Type here...'
            onChange={(e) => {
              setBranchEdit(capitalizeFirstLetter(e.target.value))
              setValidationBranchEdit('')
            }}
            value={branchEdit}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            IFSC Code<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='ifscCodeEdit'
            className={clsx('form-control', validationIfscCodeEdit)}
            placeholder='Type here...'
            onChange={(e) => {
              setIfscCodeEdit(capitalizeFirstLetter(e.target.value))
              setValidationifscCodeEdit('')
            }}
            value={ifscCodeEdit}
          />
        </div>
        <div>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <label className='form-check-label me-3'>Access Status</label>
            <input
              checked={accessStsEdit}
              id='accessStatusEdit'
              onChange={(e) => {
                let checkedEdit = $('#accessStatusEdit').is(':checked')
                if (checkedEdit == true) {
                  setAccessStsEdit(true)
                } else {
                  setAccessStsEdit(false)
                }
              }}
              value={accessStsEdit}
              className='form-check-input h-30px w-50px'
              type='checkbox'
              name='layout-builder[layout][header][fixed][desktop]'
              defaultChecked={true}
            />
            {/* <input className='form-check-input' type='checkbox' value='' checked /> */}
          </div>
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
      {/* delete Bank Account*/}
      <EaModal
        modalTitle='Delete Bank Account'
        closeAuto='closeDeleteEaModalBrand'
        children={
          <p className='mb-0'>
            Are you sure you want to delete this {deleteBankName} account number{' '}
            <span className='fw-bold'>{deleteName}?</span>
          </p>
        }
        id={'ea_modal_delete_bank_account'}
        actionBtnName='Yes, Proceed'
      // onSubmit={deleteApi}
      />
    </>
  )
}

export default BankAccounts
