import "@formatjs/intl-relativetimeformat/locale-data/en"
import "@formatjs/intl-relativetimeformat/polyfill"
import { FC } from "react"
import { IntlProvider } from "react-intl"
import { useLang } from "./Eafruits18n"

import enMessages from "./messages/en.json"

const allMessages = {
  en: enMessages,
}

const I18nProvider: FC = ({ children }) => {
  const locale = useLang()
  const messages = allMessages[locale]
  const errorHandling = ({ error }: any) => {
    // error handling, cosole will be clear
  }
  return (
    <IntlProvider onError={errorHandling} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
