import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {AsyncPaginate} from 'react-select-async-paginate'
import clsx from 'clsx'
import {getRequest, patchRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  apiBaseURL,
  dateTimeFormat,
  fetchAsyncDropdown,
  isEmpty,
  numberFormatWithCode,
} from '../../../../_eaFruitsDms/helpers'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import tenantConfiguration from '../../../../TenantVariables'
import {Modal} from 'react-bootstrap'
import toast from 'react-hot-toast'

type Props = {
  className?: string
}
const CheckOrderDetail: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const priceHeaderTitle = {
    title: 'Discount Request ',
    subTitle: 'Price & Discounts',
  }
  let initialReason = {
    reason: null,
    reason_note: '',
  }

  let initialApproveValidation = {
    requested_amount: '',
    reason: '',
    reason_note: '',
  }
  const navigate = useNavigate()
  const location = useLocation()
  const {state}: any = location

  const [rejection, setRejection] = useState(false)
  const [discountDetails, setDiscountDetails] = useState<any>(null)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [reasonOffSet, setReasonOffSet] = useState<any>('')
  const [reasons, setReasons] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [selectedReason, setSelectedReason] = useState<any>(initialReason)
  const [approveValidation, setApproveValidation] = useState<any>(initialApproveValidation)
  const [rejectButtonDisable, setRejectButtonDisable] = useState<boolean>(false)
  const [approveButtonDisable, setApproveButtonDisable] = useState<boolean>(false)
  const [requestAmount, setRequestAmount] = useState<any>(0)
  const [approveDiscountRequest, setApproveDiscountRequest] = useState(false)
  const [partialApprovedReason, setPartialApprovedReason] = useState<any>('')
  const [partialApprovedReasonNote, setPartialApprovedReasonNote] = useState<any>('')

  useEffect(() => {
    if (state) {
      fetchDiscountRequestDetails(state.request_id).then()
    }
  }, [])

  const fetchDiscountRequestDetails = async (requestId: number) => {
    setDisplayLoader(true)
    const res = await getRequest(
      `${apiBaseURL()}delivery/additional-discount-retrieve/${requestId}`,
      true
    )

    if (res) {
      if (res.status === 'pending') {
        setRequestAmount(res.requested_amount)
      } else {
        setRequestAmount(res.amount)
      }

      if (res.rejection_reason || res.reject_reason) {
        setPartialApprovedReason({
          label: res.rejection_reason,
          value: res.reject_reason,
        })
      }

      if (!isEmpty(res.other_reason)) {
        setPartialApprovedReason({
          label: 'Other',
          value: '',
        })
        setPartialApprovedReasonNote(res.other_reason)
        setSelectedReason({...selectedReason, ['reason']: ''})
      }

      if (isEmpty(res.amount)) {
        res.amount = res.requested_amount
      }

      // if (details.due)
      setDiscountDetails(res)
    }
    setDisplayLoader(false)
  }
  const handleAmountChange = (e: any) => {
    const {value} = e.target
    if (value > discountDetails.requested_amount) {
      setApproveValidation({...approveValidation, ['requested_amount']: 'is-invalid'})
    } else {
      setApproveValidation({...approveValidation, ['requested_amount']: ''})
    }
    setRequestAmount(value)
  }

  const handleCloseApproveDiscountRequest = () => {
    clearState()
    setApproveDiscountRequest(false)
  }

  const handleCloseRejection = () => {
    clearState()
    setRejection(false)
  }

  const clearState = () => {
    setReasonOffSet('')
    setReasons([])
    setSelectedReason(initialReason)
    if (!isEmpty(discountDetails.other_reason)) {
      setPartialApprovedReasonNote(discountDetails.other_reason)
      setSelectedReason({...selectedReason, ['reason']: ''})
    }
    setRequestAmount(discountDetails.amount)
    setPartialApprovedReason('')
    setApproveValidation(initialApproveValidation)
  }

  const fetchCreditRequestUpdateReasons = async (search?: any) => {
    let response = await fetchAsyncDropdown(
      reasonOffSet,
      setReasonOffSet,
      reasons,
      setReasons,
      `master/options-master/?type=discount_request_amount_update`,
      search,
      setSearchSelect,
      'option'
    )

    response.options.push({
      label: 'Other',
      value: '',
    })

    return response
  }

  const handleShowRejection = () => setRejection(true)

  const fetchCreditRequestRejectionReason = async (search?: any) => {
    let response = await fetchAsyncDropdown(
      reasonOffSet,
      setReasonOffSet,
      reasons,
      setReasons,
      `master/options-master/?type=discount_request_rejection`,
      search,
      setSearchSelect,
      'option'
    )

    response.options.push({
      label: 'Other',
      value: '',
    })

    return response
  }

  const handleReasonChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    if (name === 'reason') {
      setPartialApprovedReason(e)
    } else {
      setPartialApprovedReasonNote(inputValue)
    }
    setApproveValidation({...approveValidation, [inputName]: ''})
    setSelectedReason({...selectedReason, [inputName]: inputValue})
  }

  const approveRequest = async () => {
    let isValid: boolean = true
    const updatedValidation = {...approveValidation}

    if (isEmpty(requestAmount)) {
      isValid = false
      updatedValidation.requested_amount = 'is-invalid'
    } else {
      if (requestAmount > discountDetails.requested_amount) {
        isValid = false
        updatedValidation.requested_amount = 'is-invalid'
      }
    }

    if (discountDetails.requested_amount > requestAmount) {
      if (selectedReason.reason === null) {
        isValid = false
        updatedValidation.reason = 'is-invalid'
      } else {
        if (selectedReason.reason === '' && isEmpty(selectedReason.reason_note)) {
          isValid = false
          updatedValidation.reason_note = 'is-invalid'
        }
      }
    }
    setApproveValidation(updatedValidation)

    if (isValid) {
      const requestData = {
        status: 'approved',
        amount: requestAmount,
        reject_reason: isEmpty(selectedReason.reason) ? null : selectedReason.reason,
        other_reason: isEmpty(selectedReason.reason_note) ? null : selectedReason.reason_note,
      }

      setApproveButtonDisable(true)
      const response = await patchRequest(
        `${apiBaseURL()}delivery/additional-discount-reject/${discountDetails.id}`,
        requestData,
        true
      )

      if (response?.data?.error?.field === 'not_editable_order') {
        cancelOrder('approve')
      } else {
        clearState()
        handleCloseApproveDiscountRequest()
        navigate('/price/discount-request')

        setApproveButtonDisable(false)
      }
    }
  }

  const cancelOrder = async (status: string) => {
    await patchRequest(
      `${apiBaseURL()}delivery/additional-discount-reject/${discountDetails.id}`,
      {status: 'cancelled'},
      true
    )

    clearState()
    handleCloseApproveDiscountRequest()
    navigate('/price/discount-request')

    setApproveButtonDisable(false)

    setRejectButtonDisable(false)

    clearState()
    handleCloseRejection()

    toast.error(
      <div>
        <h4>This order has already been completed. you cannot {status} the discount request.</h4>
      </div>,
      {
        position: 'top-center',
        duration: 5000,
        style: {
          background: '#FFBB70',
          maxWidth: 700,
          marginTop: 40,
        },
      }
    )
  }

  const rejectRequest = async () => {
    let isValid: boolean = true
    const updatedValidation = {...approveValidation}

    if (selectedReason.reason === null) {
      isValid = false
      updatedValidation.reason = 'is-invalid'
    } else {
      if (selectedReason.reason === '' && isEmpty(selectedReason.reason_note)) {
        isValid = false
        updatedValidation.reason_note = 'is-invalid'
      }
    }
    setApproveValidation(updatedValidation)

    if (isValid) {
      const requestData = {
        status: 'rejected',
        reject_reason: isEmpty(selectedReason.reason) ? null : selectedReason.reason,
        other_reason: isEmpty(selectedReason.reason_note) ? null : selectedReason.reason_note,
      }

      setRejectButtonDisable(true)
      const response = await patchRequest(
        `${apiBaseURL()}delivery/additional-discount-reject/${discountDetails.id}`,
        requestData,
        true
      )

      if (response?.data?.error?.field === 'not_editable_order') {
        cancelOrder('reject')
      } else {
        setRejectButtonDisable(false)

        clearState()
        handleCloseRejection()
        navigate('/price/discount-request')
      }
    }
  }

  const goToOrderDetailPage = (orderId: number) => {
    localStorage.setItem('oId', orderId.toString())
    localStorage.setItem('redirection_back', location.pathname)
    navigate('/reports/order-quantity-requests/order-detail')
  }

  return (
    <>
      <PageTitle breadcrumbs={[priceHeaderTitle]} backNavigate='/price/discount-request'>
        {intl.formatMessage({id: 'Discount Request'})}
      </PageTitle>
      <div className='card mb-5 mb-xl-10 pt-3'>
        {displayLoader && <IsLoader />}
        <div className='card-body pt-2 mt-1 pb-5'>
          <div className='d-flex justify-content-end mb-5 position-absolute w-100 pe-10'>
            {isEmpty(discountDetails) || isEmpty(discountDetails.status) ? (
              ''
            ) : discountDetails.status.toLowerCase() === 'pending' ? (
              <span className='badge badge-light-warning text-dark'>Pending</span>
            ) : discountDetails.status.toLowerCase() === 'approved' ? (
              <span className='badge badge-light-success text-dark'>Approved</span>
            ) : discountDetails.status.toLowerCase() === 'cancelled' ? (
              <span className='badge  badge-secondary text-dark'>Cancelled</span>
            ) : discountDetails.status.toLowerCase() === 'ss_approved' ? (
              <span className='badge badge-light-success text-dark'>SS Approved</span>
            ) : (
              <span className='badge badge-light-danger text-dark'>Rejected</span>
            )}
            <button
              className='btn btn-primary ms-3'
              onClick={() => goToOrderDetailPage(discountDetails.order)}
            >
              <i className='fa fa-info-circle' aria-hidden='true'></i>Order Detail
            </button>
          </div>
          <div className='row'>
            <div className='col-sm-6 col-xl-4 mb-6'>
              <label className='font-14 text-muted mb-2'>Request Date & Time</label>
              <div>
                <span className='font-14 text-dark'>
                  {!discountDetails || isEmpty(discountDetails.created_at)
                    ? '-'
                    : dateTimeFormat(discountDetails.created_at)}
                </span>
              </div>
            </div>
            <div className='col-sm-6 col-xl-4 mb-6'>
              <label className='font-14 text-muted mb-2'>SR Name</label>
              <div>
                <span className='font-14 text-dark'>
                  {!discountDetails || isEmpty(discountDetails.sr_name)
                    ? '-'
                    : discountDetails.sr_name}
                </span>
              </div>
            </div>
            <div className='col-sm-6 col-xl-4 mb-6'>
              <label className='font-14 text-muted mb-2'>Customer Name</label>
              <div>
                <span className='font-14 text-dark'>
                  {!discountDetails ||
                  isEmpty(discountDetails.customer_details) ||
                  discountDetails.customer_details.length === 0 ||
                  isEmpty(discountDetails.customer_details[0].customer_name)
                    ? '-'
                    : discountDetails.customer_details[0].customer_name}
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-xl-6 mb-6'>
              <label className='font-14 text-muted mb-2'>Address</label>
              <div>
                <span className='font-14 text-dark'>
                  {!discountDetails ||
                  isEmpty(discountDetails.customer_address) ||
                  isEmpty(discountDetails.customer_address[0].address)
                    ? '-'
                    : discountDetails.customer_address[0].address}
                </span>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-6 col-xl-4 mb-6'>
              <label className='font-14 text-muted mb-2'>Order ID</label>
              <div>
                <span className='font-14 text-dark'>
                  {!discountDetails || isEmpty(discountDetails.pre_order_brand_group_id)
                    ? '-'
                    : `#${discountDetails.pre_order_brand_group_id}`}
                </span>
              </div>
            </div>
            <div className='col-sm-6 col-xl-4 mb-6'>
              <label className='font-14 text-muted mb-2'>Invoice Amount</label>
              <div>
                <span className='font-14 text-dark'>
                  {!discountDetails || isEmpty(discountDetails.invoice_amount)
                    ? '-'
                    : numberFormatWithCode(discountDetails.invoice_amount).displayFormat}
                </span>
              </div>
            </div>
            <div className='col-sm-6 col-xl-4 mb-6'>
              <label className='font-14 text-muted mb-2'>Requested Amount</label>
              <div>
                <span className='font-14 text-dark'>
                  {!discountDetails || isEmpty(discountDetails.amount)
                    ? '-'
                    : numberFormatWithCode(discountDetails.requested_amount).displayFormat}
                </span>
              </div>
            </div>
            {discountDetails && discountDetails.status.toLowerCase() !== 'pending' && (
              <>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved Amount</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!discountDetails ||
                      isEmpty(discountDetails.amount) ||
                      !['approved', 'ss_approved'].includes(discountDetails.status)
                        ? '-'
                        : numberFormatWithCode(discountDetails.amount).displayFormat}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Action By</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!discountDetails || isEmpty(discountDetails.action_by_name)
                        ? '-'
                        : `${
                            discountDetails.action_by_name
                          } (${discountDetails.action_by_type.toUpperCase()})`}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Action At</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!discountDetails || isEmpty(discountDetails.action_at)
                        ? '-'
                        : dateTimeFormat(discountDetails.action_at)}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='row'>
            <div className='col-sm-12 col-xl-4 mb-6'>
              <label className='font-14 text-muted mb-2'>Comment</label>
              <div>
                <span className='font-14 text-dark'>
                  {!discountDetails || isEmpty(discountDetails.comment)
                    ? '-'
                    : discountDetails.comment}
                </span>
              </div>
            </div>
            {discountDetails &&
              ['rejected', 'ss_approved', 'approved'].includes(
                discountDetails.status.toLowerCase()
              ) &&
              (!isEmpty(discountDetails.rejection_reason) ||
                !isEmpty(discountDetails.other_reason)) && (
                <div className='col-sm-12 col-xl-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Reason</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!discountDetails || isEmpty(discountDetails.rejection_reason)
                        ? isEmpty(discountDetails.other_reason)
                          ? '-'
                          : discountDetails.other_reason
                        : discountDetails.rejection_reason}
                    </span>
                  </div>
                </div>
              )}
          </div>
        </div>
        {discountDetails &&
          ['ss_approved', 'pending'].includes(discountDetails.status.toLowerCase()) && (
            <div className='card-footer py-4'>
              <button
                className='btn btn-secondary me-3'
                type='button'
                onClick={handleShowRejection}
              >
                Reject
              </button>
              <button
                className='btn btn-primary '
                type='button'
                disabled={approveButtonDisable}
                onClick={() => setApproveDiscountRequest(true)}
              >
                {approveButtonDisable ? 'Please Wait...' : 'Approve'}
              </button>
            </div>
          )}
      </div>

      <Modal show={rejection} onHide={handleCloseRejection} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reject Discount Request</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          <div className='mb-4'>
            <label className='form-label'>Rejection Reason</label>
            <AsyncPaginate
              loadOptions={fetchCreditRequestRejectionReason}
              className={clsx('react-select-container w-100', approveValidation.reason)}
              classNamePrefix='react-select'
              onChange={(e: any) => handleReasonChange(e, 'reason')}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
          {selectedReason.reason === '' && (
            <div className='mb-4'>
              <label className='form-label'>Comments</label>
              <textarea
                className={clsx('form-control', approveValidation.reason_note)}
                rows={4}
                name='reason_note'
                onChange={handleReasonChange}
                placeholder='Type here…'
              ></textarea>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-light' onClick={handleCloseRejection}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            disabled={rejectButtonDisable}
            onClick={rejectRequest}
          >
            {rejectButtonDisable ? 'Pleas Wait...' : 'Submit'}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={approveDiscountRequest} onHide={handleCloseApproveDiscountRequest} centered>
        <Modal.Header closeButton>
          <Modal.Title>Discount Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          <div className='row'>
            <div className='col mb-4'>
              <label className='form-label'>Requested Amount</label>
              <input
                type='text'
                className='form-control'
                value={`${tenantConfiguration.currencyCode} ${
                  discountDetails && discountDetails.requested_amount
                }`}
                disabled
              />
            </div>
            <div className='col mb-4'>
              <label className='form-label'>Approved Discount</label>
              <div
                className={clsx(
                  'input-group mb-1',
                  !isEmpty(approveValidation.requested_amount) && 'is-invalid-grp'
                )}
              >
                <span className='input-group-text bg-light2 border-start-0 px-3 border-end border-light ms-0 w-50px'>
                  {`${tenantConfiguration.currencyCode}`}
                </span>
                <input
                  type='number'
                  placeholder='0'
                  className={clsx('form-control min-w-70px', approveValidation.requested_amount)}
                  onChange={handleAmountChange}
                  value={requestAmount}
                />
              </div>
            </div>
          </div>
          {discountDetails && discountDetails.requested_amount > requestAmount && (
            <>
              <div className='row'>
                <div className='col mb-4'>
                  <label className='form-label'>Reason to Update Discount Request</label>
                  <AsyncPaginate
                    loadOptions={fetchCreditRequestUpdateReasons}
                    className={clsx('react-select-container w-100', approveValidation.reason)}
                    classNamePrefix='react-select'
                    placeholder='Select'
                    onChange={(e: any) => handleReasonChange(e, 'reason')}
                    value={partialApprovedReason}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
              </div>
              {selectedReason.reason === '' && (
                <div className='row'>
                  <div className='col mb-0'>
                    <label className='form-label'>Comments</label>
                    <textarea
                      className={clsx('form-control', approveValidation.reason_note)}
                      rows={4}
                      onChange={(e: any) => handleReasonChange(e)}
                      name='reason_note'
                      placeholder='Type here…'
                      value={partialApprovedReasonNote}
                    ></textarea>
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button
            type='button'
            className='btn btn-light'
            onClick={handleCloseApproveDiscountRequest}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            disabled={approveButtonDisable}
            onClick={approveRequest}
          >
            {approveButtonDisable ? 'Please Wait...' : 'Approve Discount Request'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CheckOrderDetail
