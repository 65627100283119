import {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../../../TenantVariables'
import {
  apiBaseURL,
  firstLetterCapital,
  isEmpty,
  staticSearch,
} from '../../../../../_eaFruitsDms/helpers'
import {getRequest, putRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import {EaAlertSuccess} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'

const Preferences = ({setPreference}: any) => {
  const customerId = localStorage.getItem('cId')

  const isUi = !setPreference

  let options = [
    {
      label: (
        <div className='d-flex justify-content-between'>
          <div>00:00 - 24:00</div>
          <div className='fw-bold'>Anytime</div>
        </div>
      ),
      value: '1',
      startTime: '00:00:00',
      endTime: '23:59:59',
    },
    {
      label: (
        <div className='d-flex justify-content-between'>
          <div>08:00 - 11:00</div>
          <div className='fw-bold'>Morning</div>
        </div>
      ),
      value: '2',
      startTime: '08:00:00',
      endTime: '11:00:00',
    },
    {
      label: (
        <div className='d-flex justify-content-between'>
          <div>11:00 - 15:00</div>
          <div className='fw-bold'>Afternoon</div>
        </div>
      ),
      value: '3',
      startTime: '11:00:00',
      endTime: '15:00:00',
    },
    {
      label: (
        <div className='d-flex justify-content-between'>
          <div>15:00 - 18:00</div>
          <div className='fw-bold'>Evening</div>
        </div>
      ),
      value: '4',
      startTime: '15:00:00',
      endTime: '18:00:00',
    },
    {
      label: (
        <div className='d-flex justify-content-between'>
          <div>18:00 - 21:00</div>
          <div className='fw-bold'>Late Evening</div>
        </div>
      ),
      value: '5',
      startTime: '18:00:00',
      endTime: '21:00:00',
    },
  ]

  const headerTitle = {
    title: 'Customers',
    subTitle: 'Preferences',
  }

  const initialDays = {
    days: [],
    AllDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    start_time: '',
    end_time: '',
    product_groups: [],
  }

  const initialValidation = {productGroups: '', timeSlot: ''}

  const [searchSelect, setSearchSelect] = useState<string>('')
  const [productOffSet, setProductOffSet] = useState<any>('')
  const [timeSlot, setTimeSlot] = useState<any>(null)
  const [products, setProducts] = useState<any>(null)
  const [validation, setValidation] = useState<any>(initialValidation)
  const [days, setDays] = useState<any>(initialDays)
  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [buttonText, setButtonText] = useState('Submit')

  const fetchTimeSlot = async (search?: any) => {
    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options,
      hasMore: false,
    }
  }

  const fetchProductGroup = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
      setProductOffSet('')
    }

    const productApiUrl: string = `${apiBaseURL()}master/product/group?search=${search}&limit=${
      tenantConfiguration.limit
    }&offset=${productOffSet}`

    const productSKUResponse = await getRequest(productApiUrl, true)

    let options: any = []

    let hasMore: boolean = false

    if (productSKUResponse.next !== null) {
      const queryParams = new URLSearchParams(productSKUResponse.next)
      let newOffset: any = queryParams.get('offset')
      setProductOffSet(newOffset)
      hasMore = true
    }

    if (productSKUResponse.results.length > 0) {
      productSKUResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCustomerPreferences = async () => {
    setLoading(true)

    const url = `${apiBaseURL()}customers/${customerId}/preferences`
    const response = await getRequest(url, true)

    if (response && response.result) {
      const data = response.result
      let updatedDays = {...days}

      updatedDays.days = data?.days?.length === 0 ? [] : data?.days
      updatedDays.start_time = isEmpty(data?.start_time) ? '' : data?.start_time
      updatedDays.end_time = isEmpty(data?.end_time) ? '' : data?.end_time
      updatedDays.product_groups =
        data?.product_groups?.length === 0
          ? []
          : data?.product_groups.map((i: any) => ({
              label: i.name,
              value: i.id,
            }))

      data?.product_groups &&
        setProducts(
          data?.product_groups.map((i: any) => ({
            label: i.name,
            value: i.id,
          }))
        )

      setDays(updatedDays)

      if (!isEmpty(data?.start_time)) {
        let time = options.find((i) => i.startTime === data?.start_time)
        setTimeSlot(time)
      }
    }

    setLoading(false)
  }

  const isValid = () => {
    let valid = true
    let updatedValidation = {...validation}

    if (days.product_groups.length === 0) {
      valid = false
      updatedValidation.productGroups = 'is-invalid'
    }

    if (isEmpty(days.start_time)) {
      valid = false
      updatedValidation.timeSlot = 'is-invalid'
    }

    setValidation(updatedValidation)
    return valid
  }

  const savePreferences = async () => {
    if (isValid()) {
      const formData = new FormData()

      days.days.map((item: any, index: number) => {
        formData.append(`preference_days[${index}]`, item)
      })

      days.product_groups.map((item: any, index: number) => {
        formData.append(`preference_product_groups[${index}]`, item.value)
      })

      formData.append('preference_time_slot_start_time', days.start_time)
      formData.append('preference_time_slot_end_time', days.end_time)

      const url = `${apiBaseURL()}customers/update/${customerId}`

      const response = await putRequest(url, formData, true)

      if (response && response.status === 200) {
        setButtonText('Please Wait')
        setMessage('Your customer preference is updated.')
        setIsAlert(true)

        fetchCustomerPreferences()

        setTimeout(() => {
          setIsAlert(false)
        }, 2000)
      }

      setButtonText('Submit')
    }
  }

  useEffect(() => {
    customerId && fetchCustomerPreferences()
  }, [customerId])

  useEffect(() => {
    setPreference && setPreference(days)
  }, [days])

  return (
    <>
      {!setPreference && (
        <>
          <EATitle title='Preferences' />
          <PageTitle breadcrumbs={[headerTitle]}>Preferences</PageTitle>
        </>
      )}

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      <div className='card mb-5 mb-xl-10'>
        {loading ? (
          <FetchingRecords />
        ) : (
          <>
            <div className={setPreference ? 'mt-5' : 'card-body'}>
              {isUi && (
                <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'>Customer Preferences</h3>
              )}

              {isUi ? (
                <label className='font-14 text-muted mb-2'>Preferred Delivery Days</label>
              ) : (
                <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'>Preferred Delivery Days</h3>
              )}
              <div className='row w-50'>
                {days.AllDays.length > 0 &&
                  days.AllDays.map((item: any, index: number) => {
                    return (
                      <div className='col-md-3 mb-4'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                          <input
                            id={'day-' + index}
                            className='form-check-input widget-13-check me-1'
                            type='checkbox'
                            checked={days.days.includes(item)}
                            onChange={(e) => {
                              if (setPreference) {
                                let updatedState = {...days}
                                let updateDays: any = [...updatedState.days]

                                if (e.target.checked) {
                                  updateDays?.push(item)

                                  updatedState.days = updateDays
                                } else {
                                  const filteredData = updateDays.filter((i: any) => i !== item)

                                  updatedState.days = filteredData
                                }

                                setDays(updatedState)
                                setValidation({...validation, timeSlot: ''})
                              }
                            }}
                          />
                          <label htmlFor={'day-' + index} className='form-check-label'>
                            {firstLetterCapital(item)}
                          </label>
                        </div>
                      </div>
                    )
                  })}
              </div>

              {isUi ? (
                <>
                  <div className='row'>
                    <div className='col-sm-12 col-md-12 mb-6 mt-3'>
                      <label className='font-14 text-muted mb-2'>Delivery Time Slots</label>
                      <div className='w-170px'>
                        <span className='font-14 font-medium'>
                          {isEmpty(timeSlot?.label) ? '-' : timeSlot?.label}
                        </span>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-4 mb-6 mt-3'>
                      <label className='font-14 text-muted mb-2'>Preferred Product Groups</label>
                      <div className='w-500px'>
                        {products && products.length > 0
                          ? products.map((i: any) => {
                              return !isEmpty(i?.label) ? (
                                <span className='badge font-medium badge-secondary text-dark me-3 mb-4'>
                                  {i?.label}
                                </span>
                              ) : null
                            })
                          : '-'}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'>Delivery Time Slots</h3>

                  <AsyncPaginate
                    loadOptions={fetchTimeSlot}
                    isSearchable={false}
                    name='time'
                    placeholder={!setPreference ? '-' : 'Select'}
                    className={`react-select-container w-25 mb-5 ${validation.timeSlot}`}
                    classNamePrefix='react-select'
                    value={timeSlot}
                    isDisabled={!setPreference}
                    onChange={(e: any) => {
                      setTimeSlot(e)

                      let updateDays = {...days}

                      updateDays.start_time = e.startTime
                      updateDays.end_time = e.endTime

                      setDays(updateDays)
                      setValidation({...validation, productGroups: ''})
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />

                  <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'>
                    Preferred Product Groups
                  </h3>

                  <AsyncPaginate
                    loadOptions={fetchProductGroup}
                    isSearchable
                    isMulti
                    name='productGroups'
                    placeholder={!setPreference ? '-' : 'Select'}
                    className={`react-select-container w-25 ${validation.productGroups}`}
                    classNamePrefix='react-select'
                    value={products}
                    isDisabled={!setPreference}
                    onChange={(e: any) => {
                      setProducts(e)

                      let updateDays = {...days}

                      updateDays.product_groups = e

                      setDays(updateDays)

                      setValidation({...validation, productGroups: ''})
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Preferences
