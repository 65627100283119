import React, {useEffect, useState} from 'react'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  GLOBAL_DATE_FORMAT,
  KTSVG,
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchStaticAsyncDropdown,
  globalDateFormate,
  isWarehouseManager,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import tenantConfiguration from '../../../TenantVariables'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import {AsyncPaginate} from 'react-select-async-paginate'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import {isAdmin} from '../../../_eaFruitsDms/helpers'

const statusoptions = [
  {value: '', label: 'All Status'},
  {value: 'open', label: 'Open'},
  {value: 'assigned', label: 'Assigned'},
  {value: 'closed', label: 'Closed'},
]

const SupportRequestNewMessage = () => {
  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'Complaints',
    subTitle: 'Complaints',
  }
  const location = useLocation()
  const userType = localStorage.getItem('user_type') || ''

  let supportRequestFiltered = retrieveFilter(filters.ts_support_request_newmessages)
  let monthStart = dateFormat(moment().startOf('month'))
  let monthEnd = dateFormat(moment().endOf('month'))

  const initialState = {
    warehouseId: supportRequestFiltered?.warehouseId || {label: 'All Warehouses', value: ''},
    status: supportRequestFiltered?.status || {label: 'All Status', value: ''},
    startDate: supportRequestFiltered?.startDate || monthStart,
    endDate: supportRequestFiltered?.endDate || monthEnd,
    search: supportRequestFiltered?.search || '',
    selected: supportRequestFiltered?.selected || '',
    currentOffSet: supportRequestFiltered?.currentOffset || '',
    buttonDisable: true,
  }

  const initialDateFilter = {
    startDate: supportRequestFiltered?.startDate || monthStart,
    endDate: supportRequestFiltered?.endDate || monthEnd,
  }
  const [newMessage, setNewMessage] = useState([])
  const [count, setCount] = useState('')
  const [search, setSearch] = useState(supportRequestFiltered?.search || '')
  const [selected, setSelected] = useState<number>(supportRequestFiltered?.selected || 0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [currentOffset, setCurrentOffset] = useState<number>(
    supportRequestFiltered?.currentOffset || '0'
  )
  const [isLoading, setIsLoading] = useState(true)
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    supportRequestFiltered?.buttonDisable || false
  )
  const [requestDate, setRequestDate] = useState<any>(initialDateFilter)
  const [status, setStatus] = useState<any>(
    supportRequestFiltered?.status || {label: 'All Status', value: ''}
  )
  const [warehouseId, setWarehouseId] = useState<any>(
    supportRequestFiltered?.warehouseId || {label: 'All Warehouse', value: ''}
  )
  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouseOffSet, setWarehouseOffSet] = useState('')
  const [searchSelect, setSearchSelect] = useState('')

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    localStorage.removeItem('supportRequestType')
    localStorage.removeItem('supportRequestTitle')
  }, [])

  useEffect(() => {
    newMessagesRequest()
  }, [currentOffset, debounceSearch, status, requestDate, warehouseId])

  useEffect(() => {
    setWarehouseOffSet('')
  }, [searchSelect])

  const newMessagesRequest = async () => {
    setIsLoading(true)
    const tsId = localStorage.getItem('teleUserId')

    let teleSalesId = ''
    if (
      location.pathname.includes('/tele-sales/tele-sales-name/complaints') &&
      checkUserType(['sa'])
    ) {
      teleSalesId = `&assign_to=${tsId}&warehouse_id=${warehouseId.value}`
    }

    const cId = localStorage.getItem('cId')
    let customerId = ''
    if (
      ['wm', 'sa', 'ts'].includes(userType.toLowerCase()) &&
      location.pathname === '/customers/singlecustomer/complaints-request'
    ) {
      customerId = `&customer=${cId}`
    }

    let warehouseDc = ''
    if (
      location.pathname.includes('/warehouse/DC/singlewarehousename/complaint') &&
      checkUserType(['sa'])
    ) {
      const wId = localStorage.getItem('wId')
      warehouseDc = `&warehouse_id=${wId}`
    }

    const url =
      `${apiBaseURL()}support_request/web?limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&status=${
        status.value
      }&start_date=${requestDate.startDate.apiDate}&end_date=${requestDate.endDate.apiDate}` +
      teleSalesId +
      customerId +
      warehouseDc
    const response = await getRequest(url, true)
    setNewMessage(response.results)
    setCount(response.count)

    if (response) {
      setIsLoading(false)
    }

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable
    setFilterStates(updatedState)

    saveFilter(filters.ts_support_request_newmessages, filterStates)

    if (
      search == '' &&
      status.value == '' &&
      requestDate.startDate.apiDate == monthStart.apiDate &&
      requestDate.endDate.apiDate == monthEnd.apiDate
    ) {
      setButtonDisable(false)
    }
  }

  const fetchStatus = (search?: string) => {
    return fetchStaticAsyncDropdown(statusoptions, search)
  }

  // warehouse dropdown require in tele sales > support request module from super admin
  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?search=${search}&ordering=name&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=&is_parent=True`,
      true
    )

    let options: any = warehouses.length == 0 ? [{label: 'All Warehouses', value: ''}] : []

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const CustomDatePickerComponent = () => {
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            setButtonDisable(true)
            const callBackDate = {
              startDate: dateFormat(start._d),
              endDate: dateFormat(end._d),
            }
            let updatedState: any = {...filterStates}
            updatedState['startDate'] = callBackDate.startDate
            updatedState['endDate'] = callBackDate.endDate
            setFilterStates(updatedState)
            setRequestDate(callBackDate)
          }}
          initialSettings={{
            startDate: moment(requestDate.startDate.apiDate).toDate(),
            endDate: moment(requestDate.endDate.apiDate).toDate(),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={requestDate}
            disabled={isLoading}
          />
        </DateRangePicker>
      </>
    )
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    switch (inputName) {
      case 'status':
        setStatus(e)
        setFilterStates({...filterStates, ['status']: e})
        break
      case 'warehouse':
        setWarehouseId(e)
        setFilterStates({...filterStates, ['warehouseId']: e})
        break
      default:
        setSearch(inputValue)
        setFilterStates({...filterStates, ['search']: inputValue})
    }
  }

  const handleChangeReset = () => {
    setSearch('')
    setStatus({label: 'All Status', value: ''})
    setWarehouseId({label: 'All Warehouses', value: ''})
    setCurrentOffset(0)
    setSelected(0)

    let updatedDateState: any = {...requestDate}
    updatedDateState['startDate'] = monthStart
    updatedDateState['endDate'] = monthEnd
    setRequestDate(updatedDateState)

    let updatedState: any = {...filterStates}
    updatedState['status'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    updatedState['startDate'] = ''
    updatedState['endDate'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      {/* {(!['wm' , 'sa'].includes(userType.toLowerCase()) && location.pathname == '/complaint/new-message') && <EATitle title='New Messages' />} */}
      <EATitle
        title={location.pathname == '/complaint/new-message' ? 'New Messages' : 'Complaint'}
      />
      <div>
        {!['wm', 'sa'].includes(userType.toLowerCase()) &&
          location.pathname == '/complaint/new-message' && (
            <PageTitle breadcrumbs={[adminHeaderTitle]}>
              {intl.formatMessage({id: 'New Messages'})}
            </PageTitle>
          )}
        <div className='card'>
          {isLoading && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={handleFilterChange} value={search} />
            <div className='ms-auto'>
              <div className='card-toolbar my-0'>
                {isAdmin() && location.pathname === '/tele-sales/tele-sales-name/complaints' && (
                  <AsyncPaginate
                    loadOptions={fetchWarehouses}
                    placeholder='All Warehouses '
                    isDisabled={isLoading}
                    onChange={(e) => handleFilterChange(e, 'warehouse')}
                    isSearchable
                    value={warehouseId}
                    className='react-select-container my-1 me-3 w-160px'
                    classNamePrefix='react-select'
                    name='warehouse'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  isDisabled={isLoading}
                  placeholder='All Status'
                  className='react-select-container my-1 me-3 w-110px'
                  classNamePrefix='react-select'
                  name='status'
                  value={status}
                  onChange={(e: any) => handleFilterChange(e, 'status')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <CustomDatePickerComponent />
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
              </div>
            </div>
          </div>
          <div className='card-body py-3'>
            {location.pathname == '/complaint/new-message' ? (
              <>
                {newMessage.length === 0 ? (
                  <NoRecords />
                ) : ['wm', 'sa'].includes(userType.toLowerCase()) ? (
                  <SaSupportRequestTable list={newMessage} />
                ) : (
                  <TsSupportRequestTable list={newMessage} />
                )}
              </>
            ) : (
              <>
                {newMessage.length === 0 ? (
                  <NoRecords />
                ) : ['wm', 'sa', 'ts'].includes(userType.toLowerCase()) ? (
                  <SaSupportRequestTable list={newMessage} />
                ) : (
                  <TsSupportRequestTable list={newMessage} />
                )}
              </>
            )}

            {/* {newMessage.length === 0 ? <NoRecords /> :  ['wm' , 'sa'].includes(userType.toLowerCase()) && location.pathname == '/customers/singlecustomer/complaint'? <SaSupportRequestTable list={newMessage} /> : <TsSupportRequestTable list={newMessage} />} */}
          </div>
        </div>
        <CustomPaginate
          data={newMessage}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={isLoading}
          saveFilterName={filters.ts_support_request_newmessages}
          filterStates={filterStates}
        />
      </div>
    </>
  )
}
export default SupportRequestNewMessage

export const TsSupportRequestTable = (props: any) => {
  const navigate = useNavigate()

  const goToDetailPage = (item: any) => {
    localStorage.setItem('supportRequestType', 'new-message')
    localStorage.setItem('supportRequestId', item.id)
    navigate('complaint-detail/overview')
  }

  return (
    <div className='table-responsive'>
      <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2 table-hover'>
        <thead>
          <tr className='text-muted fw-500'>
            <th className='min-w-70px'>Complaint ID</th>
            <th className='min-w-100px'>Complaint Tilte</th>
            <th className='min-w-120px'>Customer Name</th>
            <th className='min-w-120px'>Created on</th>
            <th className='min-w-120px'>Last Updated</th>
            <th className='min-w-80px'>Status</th>
          </tr>
        </thead>
        <tbody>
          {props.list.map((item: any) => {
            let id = item.id
            return (
              <tr onClick={() => goToDetailPage(item)} className='cursor-pointer'>
                <td>#{item.support_number}</td>
                <td>{item.title}</td>
                <td>{item.customer.business_name}</td>
                <td>
                  {item.created_at && item.created_at !== '' ? (
                    <>
                      {dateFormat(item.created_at).displayDate}
                      <p className='font-13 text-muted mb-0'>
                        {dateFormat(item.created_at, 'LT').displayDate}
                      </p>
                    </>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {item.updated_at && item.updated_at !== '' ? (
                    <>
                      {dateFormat(item.updated_at).displayDate}
                      <p className='font-13 text-muted mb-0'>
                        {dateFormat(item.updated_at, 'LT').displayDate}
                      </p>
                    </>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {item.status === 'open' ? (
                    <span className='badge badge-light-success text-dark'>Open</span>
                  ) : item.status === 'closed' ? (
                    <span className='badge badge-light-danger text-dark'>Closed</span>
                  ) : item.status === 'assigned' ? (
                    <span className='badge badge-light-primary text-dark'>Assigned</span>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

// this table is used for SA > tele Sales > support request, SA > customer > support Request , SA > warehouse dc > support request
export const SaSupportRequestTable = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToDetailPage = (item: any) => {
    if (location.pathname == '/tele-sales/tele-sales-name/complaints') {
      localStorage.setItem('supportRequestTitle', item.title)
      localStorage.setItem('supportRequestId', item.id)
      navigate('/tele-sales/complaint-title')
    }
  }

  return (
    <div className='table-responsive'>
      <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2 table-hover'>
        <thead>
          <tr className='text-muted fw-500'>
            <th className='min-w-70px'>Complaint ID</th>
            <th className='min-w-120px'>Date & Time</th>
            <th className='min-w-150px'>Complaint Tilte</th>
            {location.pathname === '/customers/singlecustomer/complaints-request' ? (
              <th className='min-w-140px'>Tele Sales Person</th>
            ) : (
              <th className='min-w-140px'>Customer Name</th>
            )}
            {/* <th className='min-w-120px'>Flag</th> */}
            <th className='min-w-120px'>Rating</th>
            <th className='min-w-80px'>Status</th>
          </tr>
        </thead>
        <tbody>
          {props.list.map((item: any) => {
            let id = item.id
            return (
              <tr onClick={() => goToDetailPage(item)} className='cursor-pointer'>
                <td>#{item.support_number}</td>
                <td>
                  {item.created_at && item.created_at !== '' ? (
                    <>
                      {dateFormat(item.created_at).displayDate}
                      <p className='font-13 text-muted mb-0'>
                        {dateFormat(item.created_at, 'LT').displayDate}
                      </p>
                    </>
                  ) : (
                    '-'
                  )}
                </td>
                <td>{item.title}</td>
                <td>
                  {location.pathname !== '/customers/singlecustomer/complaints-request'
                    ? item.customer !== null
                      ? item.customer.business_name
                      : '-'
                    : item.assign_to !== null
                    ? item.assign_to.first_name + '' + item.assign_to.last_name
                    : '-'}
                </td>
                {/* <td>flag</td> */}
                <td>
                  {item.rating == null ? (
                    '-'
                  ) : (
                    <>
                      <KTSVG
                        path='/media/icons/duotune/general/gen029.svg'
                        className='ic text-warning me-1'
                      />{' '}
                      {item.rating}
                    </>
                  )}
                </td>
                <td>
                  {item.status === 'open' ? (
                    <span className='badge badge-light-success text-dark'>Open</span>
                  ) : item.status === 'closed' ? (
                    <span className='badge badge-light-danger text-dark'>Closed</span>
                  ) : item.status === 'assigned' ? (
                    <span className='badge badge-light-primary text-dark'>Assigned</span>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
