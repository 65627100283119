import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
  numberFormat,
} from '../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {getRequest, putRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageLink, PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {Modal} from 'react-bootstrap'

type Props = {
  className: string
}
const CreditRequestCap: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const initialRow: any = {
    user_type: 'ss',
    wholesaler_percentage: '0',
    retailer_percentage: '0',
    warehouse: localStorage.getItem('w_id'),
  }
  const initialCustomerValidation: any = {
    wholesaler_percentage: '',
    retailer_percentage: '',
  }

  const [requestCap, setRequestCap] = useState<any>([])
  const [listLoadder, setListLoader] = useState<any>(false)
  const [timer, setTimer] = useState<any>(null)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [limit, setLimit] = useState<number>(10)
  const [creditRequest, setCreditRequest] = useState<any>(initialRow)
  const [creditRequestValidation, setCreditRequestValidation] =
    useState<any>(initialCustomerValidation)
  const [disableBtn, setDisable] = useState(false)
  const [permission, setPermission] = useState(defaultPermission)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [raiseIsButtonLoader, setRaiseIsButtonLoader] = useState<any>('Update')
  const [modalLoader, setModalLoader] = useState<any>(false)

  const creditRequestPage: Array<PageLink> = [
    {
      title: 'Customers',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    setTimeout(() => creditCustomerCap().then(), timer)
  }, [])

  const fetchPermission = async () => {
    let res = await checkPermissions('customer_credit_requests_cap')
    setPermission(res)
  }

  const creditCustomerCap = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}credit/requests/cap?warehouse_id=${localStorage.getItem(
      'w_id'
    )}&limit=${limit}`
    const response = await getRequest(API, true)
    setRequestCap(response.results)
    setListLoader(false)
  }

  const singleData = async (item: any) => {
    setModalLoader(true)
    const formDetail = item
    let updatedData: any = {...creditRequest}
    updatedData['wholesaler_percentage'] = formDetail.wholesaler_percentage
    updatedData['retailer_percentage'] = formDetail.retailer_percentage
    setCreditRequest(updatedData)
    setShowModal(true)
    setModalLoader(false)
  }

  const handleChange = (e: any) => {
    const inputName = e.target.name
    const inputValue = e.target.value

    if (inputValue !== '' || inputValue <= 100) {
      setCreditRequestValidation({...creditRequestValidation, [inputName]: ''})
    }

    setCreditRequest({...creditRequest, [inputName]: inputValue})
  }

  const validateForm = () => {
    let navigateField = {
      wholesaler_percentage: creditRequest.wholesaler_percentage,
      retailer_percentage: creditRequest.retailer_percentage,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])
    let validations = {...creditRequestValidation}
    let isValid = true

    if (creditRequest.wholesaler_percentage === '' || creditRequest.wholesaler_percentage > 100) {
      validations.wholesaler_percentage = 'is-invalid'
      isValid = false
    }

    if (creditRequest.retailer_percentage === '' || creditRequest.retailer_percentage > 100) {
      validations.retailer_percentage = 'is-invalid'
      isValid = false
    }

    setCreditRequestValidation(validations)
    return isValid
  }

  const apiCall = async () => {
    const creditRequestInfo = {...creditRequest}
    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }
    setDisable(true)
    setRaiseIsButtonLoader('Please Wait...')

    putRequest(`${apiBaseURL()}credit/requests/cap/edit`, creditRequestInfo, true).then(
      (response: any) => {
        if (response.status === 200) {
          notify.success = true
        }
        if (response.statusText === 'Bad Request') {
          notify.failed = true
          notify.errorField = response.data.error.field
        }
        if (notify.success) {
          setRaiseIsButtonLoader('Update')
          setDisable(false)
          creditCustomerCap()
          setShowModal(false)
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 2000)
          setIsAlertSuccess(true)
        }
      }
    )
  }

  const updateCreditAmountCap = () => {
    const isValid = validateForm()
    if (isValid) {
      apiCall()
    }
  }

  const hideModel = () => {
    setShowModal(false)
    setRaiseIsButtonLoader('Update')
    setCreditRequest(initialRow)
    setCreditRequestValidation('')
    setDisable(false)
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Credit Requests Cap - ' />
      <div>
        <PageTitle breadcrumbs={creditRequestPage}>
          {intl.formatMessage({id: 'Credit Requests Cap'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={FormatMessage('CREDIT_REQUESTS_CAP')}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className={`card ${className}`}>
          {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <h1 className='d-flex align-items-center text-dark font-bold my-1 font-17'>
                Credit Requests Cap
              </h1>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
              {listLoadder ? (
                <IsLoader />
              ) : (
                <>
                  <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='min-w-30px py-2'>Approval From</th>
                        <th className='min-w-100px py-2'>Cap for Wholesaler Customer (%)</th>
                        <th className='min-w-100px py-2'>Cap for Retailer Customer (%)</th>
                        {permission.write && <th className='min-w-80px action-th py-2'>Actions</th>}
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {requestCap.map((item: any) => {
                        return (
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div>
                                  <span>{item && item.display_user_type}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div>
                                  <span>
                                    {item && numberFormat(item.wholesaler_percentage).displayFormat}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div>
                                  <span>
                                    {item && numberFormat(item.retailer_percentage).displayFormat}
                                  </span>
                                </div>
                              </div>
                            </td>
                            {permission.write && (
                              <td className='text-end'>
                                {/* {permission.write && ( */}
                                <a
                                  href='javascript:void(0)'
                                  className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2 cursor-pointer'
                                  onClick={() => singleData(item)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='ic mr-0'
                                  />
                                </a>
                                {/* )} */}
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </>
              )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
      </div>

      <Modal show={showModal} centered onHide={() => setShowModal(false)} backdrop='static'>
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Raise Payment Issue</h2>
          </Modal.Title>
          <div
            onClick={hideModel}
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            data-bs-dismiss='modal'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        {modalLoader && <IsLoader />}
        <Modal.Body>
          <>
            <div className='col-sm-12 mb-4'>
              <label className='form-label'>
                Cap for Wholesaler Customer (%)<span className='text-danger'>*</span>
              </label>
              <input
                id='wholesaler_percentage'
                className={clsx('form-control', creditRequestValidation.wholesaler_percentage)}
                placeholder={
                  creditRequest && numberFormat(creditRequest.wholesaler_percentage).displayFormat
                }
                name='wholesaler_percentage'
                onChange={handleChange}
              />
            </div>
            <div className='col-sm-12 mb-4'>
              <label className='form-label'>
                Cap for Retailer Customer (%)<span className='text-danger'>*</span>
              </label>
              <input
                id='retailer_percentage'
                className={clsx('form-control', creditRequestValidation.retailer_percentage)}
                placeholder={
                  creditRequest && numberFormat(creditRequest.retailer_percentage).displayFormat
                }
                name='retailer_percentage'
                onChange={handleChange}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn btn-primary'
              onClick={updateCreditAmountCap}
              disabled={disableBtn}
            >
              {raiseIsButtonLoader}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreditRequestCap
