import { apiBaseURL } from "../../helpers";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../https/apiCall";

export const WareHouseCreate = async (
  name: any,
  warehouse_type: any,
  parent_warehouse: any,
  address: any,
  post_code: any,
  region: any,
  wards: any,
  manual_latitude: any,
  manual_longitude: any,
  wards_id: any,

  gm_first_name?: any,
  gm_last_name?: any,
  email?: any,
  phone_no?: any,
  role?: any,

  geoaddress?: any,
  start_date?: any,
  shot_disc?: any
) => {
  const notify = {
    success: false,
    warehouseId: null,
    failed: false,
    errorField: '',
  }
  let body = {
    name: name,
    warehouse_type: warehouse_type,
    parent_warehouse: parent_warehouse,
    address: address,
    post_code: post_code,
    manual_latitude: manual_latitude,
    manual_longitude: manual_longitude,
    region: region,
    wards: wards,
    gm_first_name: gm_first_name ? gm_first_name : null,
    gm_last_name: gm_last_name ? gm_last_name : null,
    email: email ? email : null,
    phone_no: phone_no ? phone_no : null,
    wards_id: wards_id,
  }

  await postRequest(`${apiBaseURL()}warehouse/create`, body, true).then((res) => {
    if (res.status === 201) {
      localStorage.setItem('wId', res.data.id)
      notify.success = true
      notify.warehouseId = res.data.id
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const WareHouseDelete = async (id: any, w_id: any) => {
  const notify = {
    success: false,
    failed: false,
    sts: '',
    name: '',
  }
  await deleteRequest(`${apiBaseURL()}warehouse/delete/${id}/`, true).then(
    (res) => {
      if (res.status === 204) {
        notify.success = true
      }
      if (res.statusText === 'Bad Request') {
        notify.failed = true // catch
      }
    }
  )
  return notify
}

export const WareHouseUpdate = async (
  isExpanded:any,
  id: any,
  name: any,
  warehouse_type: any,
  parent_warehouse: any,
  address: any,
  post_code: any,
  manual_latitude: any,
  manual_longitude: any,
  region: any,
  wards: any,
  wm: any,

  wards_id: any,
  start_date?: any,

  gm_first_name?: any,
  gm_last_name?: any,
  email?: any,
  phone_no?: any,
  role?: any,

  geoaddress?: any,
  shot_disc?: any
) => {
  const notify = {
    success: false,
    failed: false,
    sts: '',
    name: '',
    errorField: '',
  }
  let body = {
    name: name,
    warehouse_type: warehouse_type,
    parent_warehouse: parent_warehouse,
    address: address,
    post_code: post_code,
    manual_latitude: manual_latitude,
    manual_longitude: manual_longitude,
    region: region,
    wards: wards,
    gm_first_name: gm_first_name ? gm_first_name : null,
    gm_last_name: gm_last_name ? gm_last_name : null,
    email: email ? email : null,
    phone_no: phone_no ? phone_no : null,
    start_date: start_date,
    wards_id: wards_id,
    // role: 19, //TODO: patch for the production
  }

  let url = `${apiBaseURL()}warehouse/update/${id}/?WM=${wm}`

  if(!isExpanded){
      url = `${apiBaseURL()}warehouse/update/${id}/?WM=${wm}`
  }

  await putRequest(
    url,
    body,
    true
  ).then((res) => {
    if (res.status === 200 || res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const WareHouseList = async (limit: any, offset: any, val?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  await getRequest(
    `${apiBaseURL()}warehouse/list?limit=${limit}&offset=${offset}${
      val ? `&search=${val}` : ''
    }`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const WareHouseSingle = async (id: any) => {
  let data = ''
  await getRequest(`${apiBaseURL()}warehouse/${id}`, true).then((res) => {
    data = res
  })
  return data
}
