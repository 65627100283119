import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { apiBaseURL } from "../../../../_eaFruitsDms/helpers";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import TsWarehouseTable from "./components/table";
import tenantConfiguration from "../../../../TenantVariables";
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate";

type Props = {
    className: string
}

const TSWarehouses: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const masterHeaderTitle = {
        title: 'Warehouse',
        subTitle: 'Warehouses',
    }
    const [list, setList] = useState([])
    const [search, setSearch] = useState('')
    const [count, setCount] = useState('');
    const [next, setNext] = useState('');
    const [prev, setPrev] = useState('');
    const [timer, setTimer] = useState<any>(null);
    const [selected, setSelected] = useState(0);
    const [limit, setLimit] = useState(tenantConfiguration.limit);
    const [currentOffset, setCurrentOffset] = useState<any>('');
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        // Develop BY: Kashyap 
        // Description: get table list 

        getWarehouseList()
    }, [currentOffset, search])

    const getWarehouseList = async () => {
        setIsLoading(true)
        const url = `${apiBaseURL()}warehouse/warehouse_details/list?limit=${limit}&offset=${currentOffset}&search=${search}`
        const response = await getRequest(url, true);
        setNext(response.next)
        setList(response.results)
        setCount(response.count)
        setPrev(response.previous)
        if (response) {
            setIsLoading(false)
        }
    }

    const numberClick = (val: any, index: any) => {
        if (val) {
            setSelected(val - 1)
            let newOffset = index * 10;
            setCurrentOffset(newOffset)
        }
    }

    const prevPage = () => {
        if (prev !== null) {
            const queryParams = new URLSearchParams(prev);
            const newOffset = queryParams.get('offset');
            setCurrentOffset(newOffset)
            setSelected(selected - 1)
        }
    }

    const nextPage = () => {
        if (next !== null) {
            const queryParams = new URLSearchParams(next);
            const newOffset = queryParams.get('offset');
            setCurrentOffset(newOffset)
            setSelected(selected + 1)
        }
    }

    const searchList = (e: any) => {
        clearTimeout(timer)
        const newTimer = setTimeout(() => {
            setSelected(0)
            setSearch(e.target.value)
        }, 500)
        setTimer(newTimer)
    }

    return (
        <>
            <EATitle title='Warehouses' />
            <div>
                <PageTitle breadcrumbs={[masterHeaderTitle]}>
                    {intl.formatMessage({ id: 'Warehouses' })}
                </PageTitle>

                <div className={`card ${className}`}>
                    <div className='card-header border-0 px-7'>
                        <div className='card-toolbar row g-0 w-100'>
                            <CustomSearchBar onChange={searchList} />
                        </div>
                    </div>
                    <div className='card-body py-4'>
                        {isLoading ? <IsLoader /> : <TsWarehouseTable list={list} />}
                    </div>
                </div>
                <CustomPaginate
                    data={list}
                    selected={selected}
                    setSelected={setSelected}
                    setCurrentOffset={setCurrentOffset}
                    totalRecords={count}
                    limit={limit}
                />
            </div>

        </>
    )
}

export default TSWarehouses
