import React, {useEffect, useState} from 'react'
import {
  apiBaseURL,
  dateFormat,
  dateIsToday,
  dateTimeFormat,
  fetchStaticAsyncDropdown,
  isEmpty,
  KTSVG,
  numberFormat,
  numberFormatWithCode,
} from '../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {Modal, OverlayTrigger} from 'react-bootstrap'
import {blobRequest, getRequest, patchRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {AsyncPaginate} from 'react-select-async-paginate'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import tenantConfiguration from '../../../../TenantVariables'
import clsx from 'clsx'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import Tooltip from 'react-bootstrap/Tooltip'
import {useNavigate} from 'react-router-dom'

const paymentType = [
  {value: '', label: 'Collection Payment Mode'},
  {value: 'bank_transfer', label: 'Bank Transfer'},
  {value: 'cash', label: 'Cash'},
  {value: 'cheque', label: 'Cheque'},
  {value: 'credit_request', label: 'Credit Request'},
  {value: 'mobile_wallet', label: 'Mobile Wallet'},
  {value: 'pay_later_today', label: 'Pay Later Today'},
]
const paymentModel = [
  {value: '', label: 'Deposit Payment Mode'},
  {value: 'bank', label: 'Bank'},
  {value: 'mobile_wallet', label: 'Mobile Wallet'},
]

const Transactions = (props: any) => {
  const paymentIssueId = localStorage.getItem('paymentIssueId')
  const userType = localStorage.getItem('user_type') || ''
  const navigate = useNavigate()

  const initialSettledValidation: any = {
    receivable_amount: '',
    received_amount: '',
    settlement_datetime: '',
    reason_note: '',
  }
  const initialRaiseIssue: any = {
    status: '',
    reason_note: '',
  }
  const initialRaiseIssueValidation: any = {
    reason_note: '',
  }

  const [transactions, setTransactions] = useState<any>([])
  const [listLoadder, setListLoader] = useState<any>(true)
  const [limit, setLimit] = useState<number>(10)
  const [selectedType, setSelectedType] = useState<any>('')
  const [selectedMode, setSelectedMode] = useState<any>('')
  const [search, setSearch] = useState<any>('')
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(0)
  const [raisedIssue, setRaiseIssue] = useState<any>(initialRaiseIssue)
  const [raisedIssueValidation, setRaisedIssueValidation] = useState<any>(
    initialRaiseIssueValidation
  )
  const [metaData, setMetaData] = useState<any>([])
  const [showRaisePaymenyModal, setShowRaisPaymentModal] = useState<boolean>(false)
  const [showRaisePaymentViewModal, setShowRaisePaymentViewModal] = useState<boolean>(false)
  const [showViewDetailModal, setShowViewDetailModal] = useState<boolean>(false)
  const [transactionId, setTransactionId] = useState('')
  const [transactionDetail, setTransactionDetail] = useState<any>([])
  const [raiseIsButtonLoader, setRaiseIsButtonLoader] = useState<any>('Yes , Proceed')
  const [receivedAmount, setReceivedAmount] = useState('')
  const [disableDownloadButton, setDisableDownloadButton] = useState<boolean>(false)
  const [viewDetailModalLoader, setViewDetailModalLoader] = useState<boolean>(false)
  const [raiseIssueViewModalLoader, setRaiseIssueViewModalLoader] = useState<boolean>(false)

  const [issueDetails, setIssueDetails] = useState<any>('')
  const [issueItems, setIssueItems] = useState<any>('')
  const [raisePaymentModal, setRaisePaymentModal] = useState(false)
  const [raisePaymentDescription, setRaisePaymentDescription] = useState('')
  const [raisePaymentDescriptionValid, setRaisePaymentDescriptionValid] = useState('')
  const [raisePaymentModalLoading, setRaisePaymentModalLoading] = useState('Yes , Proceed')

  useEffect(() => {
    setTimeout(() => fetchTransactions().then(), timeoutSeconds)
  }, [selectedType, selectedMode, search])

  const fetchTransactions = async () => {
    const API = `${apiBaseURL()}payment/issues/${paymentIssueId}/transactions?ordering=-updated_date&search=${search}&payment_mode=${selectedMode}&payment_type=${selectedType}`
    const response = await getRequest(API, true)

    if (!isEmpty(response.results)) {
      setTransactions(response.results)
    }
    setMetaData(response.meta)
    setListLoader(false)
  }

  const fetchPaymentMode = (search?: string) => {
    return fetchStaticAsyncDropdown(paymentModel, search)
  }

  const fetchPaymentType = (search?: string) => {
    return fetchStaticAsyncDropdown(paymentType, search)
  }

  const handleFilterChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    switch (inputName) {
      case 'type':
        setTimeoutSeconds(0)
        setSelectedType(inputValue)
        break
      case 'mode':
        setTimeoutSeconds(0)
        setSelectedMode(inputValue)
        break
      case 'search':
        setTimeoutSeconds(500)
        setSearch(inputValue)
        break
    }
  }

  const hideModel = () => {
    setShowRaisPaymentModal(false)
    setShowViewDetailModal(false)
    setRaiseIssue(initialRaiseIssue)
    setRaiseIsButtonLoader('Yes, Proceed')
    setRaisedIssueValidation(initialRaiseIssueValidation)
    setShowRaisePaymentViewModal(false)
  }

  const onClickRaisePayment = (item: any) => {
    setRaiseIssue({...raisedIssue, ['status']: item.status == 'raised' ? item.status : 'raised'})

    setTransactionId(item.id)
    setReceivedAmount(item.received_amount)
    setShowRaisPaymentModal(true)
  }
  const onClickViewDetail = async (item: any, key: any) => {
    setViewDetailModalLoader(true)
    setRaiseIsButtonLoader(true)
    const API = `${apiBaseURL()}payment/issues/${paymentIssueId}/transactions/${item.id}/${
      item.record_type
    }?limit=${limit}&ordering=-updated_date&search=${search}`
    const response = await getRequest(API, true)
    setTransactionDetail(response.results)
    if (key == 'settlement') {
      setShowViewDetailModal(true)
      setViewDetailModalLoader(false)
    }
    if (key == 'raisePayment') {
      setShowRaisePaymentViewModal(true)
      setRaiseIsButtonLoader(false)
    }
  }

  const downloadReport = async (item: any) => {
    setDisableDownloadButton(true)
    //Cash Receipt #No. - Date Time.pdf
    const fileName = `Cash Receipt #${item.ref_no} - ${dateTimeFormat(item.settlement_datetime)}`
    await blobRequest(
      `${apiBaseURL()}payment/issues/${paymentIssueId}/transactions/${
        item.id
      }/cash-receipt/download`,
      true,
      fileName,
      setDisableDownloadButton
    )
  }

  const handleChangeRaiseIssue = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value
    setRaisedIssueValidation({...raisedIssueValidation, [inputName]: ''})
    setRaiseIssue({...raisedIssue, [inputName]: inputValue})
  }

  const validateRaiseIssue = () => {
    let validations = {...raisedIssue}
    let isValid = true
    if (raisedIssue.reason_note === '') {
      validations.reason_note = 'is-invalid'
      isValid = false
    }
    setRaisedIssueValidation(validations)
    return isValid
  }

  const raisePaymentIssue = () => {
    const isValid = validateRaiseIssue()
    if (isValid) {
      const raiseIssueInfo = {...raisedIssue}
      setRaiseIsButtonLoader('Please Wait...')
      patchRequest(
        `${apiBaseURL()}payment/issues/${paymentIssueId}/transactions/${transactionId}/raise/unsettle`,
        raiseIssueInfo,
        true
      ).then((response: any) => {
        setRaiseIsButtonLoader('Yes, Proceed')
        if (response.status === 400) {
          setShowRaisPaymentModal(true)
        }
        if (response.status === 200) {
          props.transactionDetail(true)
          setShowRaisPaymentModal(false)
          fetchTransactions()
        }
      })
    }
  }

  const openRaisedIssue = async (item: any) => {
    setIssueItems(item)
    setRaisePaymentModal(true)
  }

  const onRaisePaymentIssue = async () => {
    let paymentIssueId = metaData && metaData.payment_issue_id
    let id = issueItems.id
    let url =
      apiBaseURL() + `payment/issues/${paymentIssueId}/transactions/${id}/raise/unsettle-payment`
    let body = {
      description: raisePaymentDescription,
    }
    if (raisePaymentDescription !== '') {
      setRaisePaymentModalLoading('Please Wait')
      const response = await patchRequest(url, body, true)
      if (response) {
        fetchTransactions()
        props.transactionDetail(true)
        setRaisePaymentModalLoading('Yes , Proceed')
        setRaisePaymentDescriptionValid('')
        setRaisePaymentDescription('')
        setRaisePaymentModal(false)
      }
    } else {
      setRaisePaymentDescriptionValid('is-invalid')
    }
  }

  const goToCreditIssue = async (creditIssueId: number) => {
    setListLoader(true)
    const response = await getRequest(`${apiBaseURL()}credit/issues/${creditIssueId}`, true)
    if (response && response.result) {
      navigate('/credit-issues/detail', {
        state: {credit_issue: response.result, creditIssueId: creditIssueId},
      })
    }
    setListLoader(false)
  }

  return (
    <>
      <div className='card mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={(e: any) => handleFilterChange(e, 'search')} />
          <div className='ms-auto'>
            <div className='card-toolbar my-0'>
              <AsyncPaginate
                loadOptions={fetchPaymentType}
                isSearchable
                placeholder='Collection Paymen...'
                className='react-select-container my-1 me-3 w-180px'
                classNamePrefix='react-select'
                name='sales'
                onChange={(e: any) => handleFilterChange(e, 'type')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchPaymentMode}
                isSearchable
                placeholder='Deposit Payment Mo..'
                className='react-select-container my-1 w-190px'
                classNamePrefix='react-select'
                name='status'
                onChange={(e: any) => handleFilterChange(e, 'mode')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {listLoadder ? (
              <IsLoader />
            ) : (
              <>
                {transactions.length == 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-150px'>Description</th>
                        <th className='min-w-140px'>Receivable</th>
                        <th className='min-w-120px'>Received</th>
                        <th className='min-w-120px'>Balance</th>
                        <th className='min-w-220px'>Payment Mode</th>
                        <th className='min-w-100px'>Ref. No.</th>
                        <th className='min-w-180px'>Verify Ref. No. </th>
                        {/* <th className='min-w-50px'></th> */}
                        <th className='min-w-120px'></th>
                        <th></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {transactions.map((item: any) => {
                        return (
                          <tr>
                            <td>{item && item.description}</td>
                            <td>
                              {item && item.receivable_amount
                                ? `${tenantConfiguration.currencyCode}` +
                                  ' ' +
                                  `${numberFormat(item.receivable_amount).displayFormat}`
                                : '-'}
                            </td>
                            <td>
                              {item && item.received_amount
                                ? `${tenantConfiguration.currencyCode}` +
                                  ' ' +
                                  `${numberFormat(item.received_amount).displayFormat}`
                                : '-'}
                            </td>
                            <td
                              className={clsx(
                                isEmpty(item.credit_request_id)
                                  ? ''
                                  : 'text-decoration-line-through'
                              )}
                            >
                              {item && item.diff_amount
                                ? `${tenantConfiguration.currencyCode}` +
                                  ' ' +
                                  `${numberFormat(item.diff_amount).displayFormat}`
                                : '-'}
                            </td>
                            <td>{item && item.payment_mode ? item.payment_mode : '-'}</td>
                            <td>{item && item.ref_no ? item.ref_no : '-'}</td>
                            <td>
                              {isEmpty(item?.ref_no_verification_status) ? (
                                '-'
                              ) : item?.ref_no_verification_status === 'pending' ? (
                                <span className='text-danger'>Not Verified</span>
                              ) : (
                                <span className='text-success'>Verified</span>
                              )}
                            </td>
                            {/* <td>
                                          <KTSVG
                                              path='/media/icons/duotune/communication/com008.svg'
                                              className='me-1'
                                          />
                                      </td> */}
                            {item.record_type === 'payment_issue_settlement' ? (
                              // Cash Settlement Entry
                              <td className='text-end'>
                                <span
                                  className='badge badge-light text-dark cursor-pointer'
                                  onClick={() => onClickViewDetail(item, 'settlement')}
                                >
                                  View Detail
                                </span>
                              </td>
                            ) : item.record_type === 'cash_deposit' &&
                              item.status !== 'raised' &&
                              !['wm', 'sa'].includes(userType.toLowerCase()) ? (
                              // Cash Deposit Order
                              <td className='text-end'>
                                <span
                                  className='badge badge-light text-dark cursor-pointer'
                                  onClick={() => onClickRaisePayment(item)}
                                >
                                  Raise Payment Issue
                                </span>
                              </td>
                            ) : !['wm', 'sa'].includes(userType.toLowerCase()) &&
                              item.status === 'raised' &&
                              item.record_type === 'cash_deposit' ? (
                              <td className='text-end'>
                                <span
                                  className='text-danger font-medium me-2 cursor-pointer'
                                  onClick={() => onClickViewDetail(item, 'raisePayment')}
                                >
                                  Raised Issue
                                </span>
                              </td>
                            ) : null}

                            {['am'].includes(userType.toLowerCase()) ? (
                              !isEmpty(item.is_issue_raised) && item.is_issue_raised ? (
                                <td className='text-end'>
                                  <span className='text-danger font-medium me-2 cursor-pointer'>
                                    Raised Issue
                                  </span>
                                </td>
                              ) : !isEmpty(item.settled_by_accountant) &&
                                item.settled_by_accountant ? (
                                <td className='text-end'>
                                  <span
                                    className='badge badge-light text-dark cursor-pointer'
                                    onClick={() => openRaisedIssue(item)}
                                  >
                                    Raise Payment Issue
                                  </span>
                                </td>
                              ) : null
                            ) : null}
                            <td>
                              {isEmpty(item.credit_request_id) ? (
                                ''
                              ) : (
                                <>
                                  <OverlayTrigger
                                    delay={{hide: 450, show: 300}}
                                    overlay={(props) => (
                                      <Tooltip {...props} className='tooltip-dark'>
                                        The outstanding amount of{' '}
                                        {numberFormatWithCode(item.diff_amount).displayFormat} got
                                        auto converted into the Customer’s Credit Issue #
                                        {item.credit_request_id}!
                                      </Tooltip>
                                    )}
                                    placement='top'
                                  >
                                    <span className='me-2'>
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen045.svg'
                                        className='ic text-primary mr-0'
                                      />
                                    </span>
                                  </OverlayTrigger>
                                  <a
                                    onClick={() => goToCreditIssue(item.credit_request_id)}
                                    className='btn btn-hover-color-primary btn-sm ms-3'
                                  >
                                    <OverlayTrigger
                                      delay={{hide: 450, show: 300}}
                                      overlay={(props) => (
                                        <Tooltip {...props} className='tooltip-dark'>
                                          View Credit Issue Details #{item.credit_request_id}!
                                        </Tooltip>
                                      )}
                                      placement='top'
                                    >
                                      <span className='me-2'>
                                        <KTSVG
                                          path='/media/icons/ic_eye_on.svg'
                                          className='ic ms-n4'
                                        />
                                      </span>
                                    </OverlayTrigger>
                                  </a>
                                </>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      {!metaData || isEmpty(metaData.sr_user_name) ? '-' : metaData.sr_user_name}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                    <span className='d-flex align-items-center me-6 mb-2'>
                      <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                      {!metaData || isEmpty(metaData.date)
                        ? '-'
                        : dateFormat(metaData.date).displayDate}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='ms-auto'>
              {metaData &&
              metaData.unsettled_cash_amount === 0 &&
              metaData.unsettled_pay_later === 0 ? (
                <>
                  <span className='badge badge-light-success text-dark cursor-pointer'>
                    Settled
                  </span>
                </>
              ) : (
                <>
                  <div>
                    <span className='text-danger font-medium mx-2'>
                      {`${
                        numberFormatWithCode(
                          metaData && !isEmpty(metaData.unsettled_cash_amount)
                            ? metaData.unsettled_cash_amount
                            : 0
                        ).displayFormat
                      } `}
                      Unsettled Cash
                    </span>
                    {metaData &&
                      metaData.unsettled_cash_amount > 0 &&
                      !['wm', 'sa'].includes(userType.toLowerCase()) && (
                        <button
                          className='btn btn-primary cursor-pointer'
                          data-bs-target='#ea_modal_payment_settlement'
                          data-bs-toggle='modal'
                        >
                          Settle Cash
                        </button>
                      )}
                  </div>
                  {metaData &&
                    metaData.unsettled_pay_later > 0 &&
                    !isEmpty(metaData.date) &&
                    dateIsToday(metaData.date) && (
                      <div className='mt-4'>
                        <span className='text-danger font-medium mx-2'>
                          {metaData &&
                            metaData.unsettled_pay_later &&
                            `${tenantConfiguration.currencyCode} ${
                              numberFormat(metaData.unsettled_pay_later).displayFormat
                            } `}
                          Unsettled Pay Later Today
                        </span>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Raise Payment Issue */}
      <Modal
        show={showRaisePaymenyModal}
        centered
        onHide={() => setShowRaisPaymentModal(false)}
        backdrop='static'
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Raise Payment Issue</h2>
          </Modal.Title>
          <div
            onClick={hideModel}
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            data-bs-dismiss='modal'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='mb-4'>
              <span>
                You are about to raise the payment issue against this deposited cash, which will
                automatically unsettle the amount of {numberFormat(receivedAmount).displayFormat}{' '}
                Are you sure you want to proceed further?
              </span>
            </div>
            <div className='mb-4'>
              <label className='form-label'>Comments</label>
              <textarea
                className={clsx('form-control', raisedIssueValidation.reason_note)}
                rows={4}
                placeholder='Type here…'
                onChange={(e) => handleChangeRaiseIssue(e, 'reason_note')}
                value={raisedIssue.reason_note}
                name='reason_note'
              ></textarea>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button className='btn btn-secondary me-3' onClick={hideModel}>
              No
            </button>
            <button className='btn btn-primary' onClick={raisePaymentIssue}>
              {raiseIsButtonLoader}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* new payment raise */}
      <Modal
        show={raisePaymentModal}
        centered
        onHide={() => setRaisePaymentModal(false)}
        backdrop='static'
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Raise Payment Issue</h2>
          </Modal.Title>
          <div
            onClick={() => setRaisePaymentModal(false)}
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            data-bs-dismiss='modal'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='mb-4'>
              <div>
                You are about to raise the payment issue against the Sales Rep{' '}
                {metaData && metaData.sr_user_name} for the payment received under{' '}
                {issueItems && issueItems.description} It will automatically unsettle the amount of{' '}
                {numberFormatWithCode(issueItems.received_amount).displayFormat}.
              </div>
              <div className='mt-1'>Are you sure you want to proceed further?</div>
            </div>
            <div className='mb-4'>
              <label className='form-label'>Comments</label>
              <textarea
                className={clsx('form-control', raisePaymentDescriptionValid)}
                rows={4}
                placeholder='Type here…'
                onChange={(e) => {
                  setRaisePaymentDescription(e.target.value)
                  if (e.target.value === '') {
                    setRaisePaymentDescriptionValid('is-invalid')
                  } else {
                    setRaisePaymentDescriptionValid('')
                  }
                }}
                value={raisePaymentDescription}
                name='description'
              ></textarea>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn btn-secondary me-3'
              onClick={() => {
                setRaisePaymentDescriptionValid('')
                setRaisePaymentDescription('')
                setRaisePaymentModal(false)
              }}
            >
              No
            </button>
            <button
              disabled={raisePaymentModalLoading !== 'Yes , Proceed' ? true : false}
              className='btn btn-primary'
              onClick={onRaisePaymentIssue}
            >
              {raisePaymentModalLoading}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Raise Payment View */}
      <Modal
        show={showRaisePaymentViewModal}
        centered
        onHide={() => setShowRaisePaymentViewModal(false)}
        backdrop='static'
      >
        {raiseIssueViewModalLoader && <IsLoader />}
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Raised Issue</h2>
          </Modal.Title>
          <div
            onClick={hideModel}
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            data-bs-dismiss='modal'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='row'>
              <div className='col-sm-6 mb-6'>
                <label className='font-14 text-muted mb-1'>Issue Raised On</label>
                <div className='font-14 text-dark'>
                  {transactionDetail && transactionDetail.updated_at
                    ? dateFormat(transactionDetail.updated_at).displayDate
                    : '-'}
                </div>
              </div>
              <div className='col-sm-6 mb-6'>
                <label className='font-14 text-muted mb-1'>Raised By</label>
                <div className='font-14 text-dark'>
                  {transactionDetail && transactionDetail.updated_by_name
                    ? transactionDetail.updated_by_name
                    : '-'}
                </div>
              </div>
              <div className='col-sm-12 mb-6'>
                <label className='font-14 text-muted mb-1'>Amount in Raised Issue</label>
                <div className='font-14 text-dark'>
                  {transactionDetail && transactionDetail.received_amount
                    ? `${tenantConfiguration.currencyCode}` +
                      ' ' +
                      `${numberFormat(transactionDetail.received_amount).displayFormat}`
                    : '-'}
                </div>
              </div>
              <div className='col-sm-12 mb-6'>
                <label className='font-14 text-muted mb-1'>Comment</label>
                <div className='font-14 text-dark'>
                  {transactionDetail && transactionDetail.reason_note}
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button className='btn btn-primary' onClick={hideModel}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* case settlement view page */}
      <Modal
        show={showViewDetailModal}
        centered
        onHide={() => setShowViewDetailModal(false)}
        backdrop='static'
      >
        {viewDetailModalLoader && <IsLoader />}
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Cash Settlement</h2>
          </Modal.Title>
          <div
            onClick={hideModel}
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            data-bs-dismiss='modal'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='row'>
              <div className='col-sm-6 mb-6'>
                <label className='font-14 text-muted mb-1'>Received On</label>
                <div className='font-14 text-dark'>
                  {transactionDetail && transactionDetail.settlement_datetime
                    ? dateFormat(transactionDetail.settlement_datetime).displayDate
                    : '-'}
                </div>
              </div>
              <div className='col-sm-6 mb-6'>
                <label className='font-14 text-muted mb-1'>Received By</label>
                <div className='font-14 text-dark'>
                  {transactionDetail && transactionDetail.settlement_by_name
                    ? transactionDetail.settlement_by_name
                    : '-'}
                </div>
              </div>
              <div className='col-sm-12 mb-6'>
                <label className='font-14 text-muted mb-1'>Settled Cash</label>
                <div className='font-14 text-dark'>
                  {transactionDetail && transactionDetail.received_amount
                    ? `${tenantConfiguration.currencyCode}` +
                      ' ' +
                      `${numberFormat(transactionDetail.received_amount).displayFormat}`
                    : '-'}
                </div>
              </div>
              {transactionDetail &&
              transactionDetail.reason !== '' &&
              transactionDetail.reason !== null ? (
                <div className='col-sm-12 mb-6'>
                  <label className='font-14 text-muted mb-1'>Received Against</label>
                  <div className='font-14 text-dark'>
                    {transactionDetail && transactionDetail.reason}
                  </div>
                </div>
              ) : (
                <div className='col-sm-12 mb-6'>
                  <label className='font-14 text-muted mb-1'>Received Against</label>
                  <div className='font-14 text-dark'>
                    {transactionDetail && transactionDetail.reason_note}
                  </div>
                </div>
              )}
              <div className='col-sm-12 mb-2'>
                {transactionDetail && transactionDetail.generate_cash_receipt && (
                  <button
                    type='button'
                    className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center ml-4'
                    onClick={() => downloadReport(transactionDetail)}
                    disabled={disableDownloadButton}
                  >
                    <KTSVG
                      path='/media/icons/duotune/files/fil021.svg'
                      className='svg-icon-muted ic'
                    />
                    {disableDownloadButton ? 'Downloading Receipt...' : 'Download Cash Receipt'}
                  </button>
                )}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button className='btn btn-primary' onClick={hideModel}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Transactions
