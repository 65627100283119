import { apiBaseURL } from "../../../helpers"
import { postRequest } from "../../../https/apiCall"

const EaFruitsLogout = async () => {
    const deviceId = localStorage.getItem('device_id')

    postRequest(`${apiBaseURL()}auth/delete_fcm_token`, {'device_id': deviceId}).then(() => {
            localStorage.clear();
            window.location.reload();
        })
}

export default EaFruitsLogout
