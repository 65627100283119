import {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import {
  KTSVG,
  apiBaseURL,
  getQuarter,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
  staticSearch,
} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaChartBar, EaChartPie} from '../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {AsyncPaginate} from 'react-select-async-paginate'

const TSDashboard = () => {
  const tsDashboardId = document.getElementById('ts-dashboard-filters')
  // for bar chart
  let app = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
  }

  // for bar chart
  const labelOption = (name: any) => {
    return {
      show: true,
      position: app.position,
      distance: app.distance,
      align: app.align,
      verticalAlign: app.verticalAlign,
      rotate: app.rotate,
      // formatter: '{c}  {name|{a}}',
      // formatter: function (params: any) {
      formatter: function (params: any) {
        return numberFormat(params.data).displayFormat
      },
      //   return numberFormatWithCode(params.data).displayFormat
      // },
      fontSize: 10,
      rich: {
        name: {},
      },
    }
  }

  const [metaCounts, setMetaCounts] = useState({
    open_complaints: 0,
    closed_complaints: 0,
    survey_conducted: 0,
    total_customers: 0,
    total_orders: 0,
    generated_orders: 0,
    new_complaint_requests: 0,
    new_complaint_comments: 0,
    orders_feedbacks: 0,
  })
  const [isChartLoading, setChartLoading] = useState(true)
  const [barChart, setBarChart] = useState({})
  const [warehouse, setWarehouse] = useState({label: 'All Warehouse', value: ''})
  const [month, setMonth] = useState({label: 'This Month', value: 'this_month'})
  const [barCharEmpty, setBarChartEmpty] = useState<any>([])
  const [warehouseChart, setWarehouseChart] = useState({
    data: [],
    title: 'Complaints Count',
    bottom: '5%',
    selectedMode: true,
  })
  const [fetchingWarehouse, setFetchingWarehouse] = useState(true)
  const [warehouseReportId, setWarehouseReportId] = useState('')
  const [fetchingRequest, setFetchingRequest] = useState(true)
  const [request, setRequest] = useState({
    data: [],
    title: 'Complaints Attended',
    bottom: '5%',
    selectedMode: false,
  })

  const fetchMetaCounts = async () => {
    const url = `${apiBaseURL()}reports/ts-dashboard/meta-counts?warehouse_id=${
      warehouse.value
    }&timeline=${month.value}`
    const response = await getRequest(url, true)
    if (response && response.result) {
      setMetaCounts(response.result)
    }
  }

  const vehicleChartReport = async () => {
    setChartLoading(true)

    const url = `${apiBaseURL()}reports/ts-dashboard/overview?warehouse_id=${
      warehouse.value
    }&timeline=${month.value}`

    const response = await getRequest(url, true)

    if (response && response.result) handleChartResponse(response.result)

    setChartLoading(false)
  }

  const handleChartResponse = (response: any) => {
    let initialChart: any = {}
    let xAxisData: string[] = []
    let seriesData: any = []
    let legend: any = []
    let type: any = []
    let key = 'label'
    let lastQuarter = getQuarter().currentQuarter - 1
    let year: any = []

    if (response.length > 0) {
      setBarChartEmpty([1])
    } else {
      setBarChartEmpty([])
    }

    if (response.length > 0) {
      response[0].chart_data.forEach((item: any) => {
        legend.push(item[key])
        type.push({name: item[key]})
      })

      response.forEach((item: any) => {
        year.push(getQuarter(item.range, true).concatMonths)

        xAxisData.push(
          isEmpty(warehouse.value)
            ? !isEmpty(item.warehouse_name)
              ? item.warehouse_name
              : '-'
            : item.timeline_type + ' ' + item.range
        )
      })

      type.forEach((i: any) => {
        let data: any = []
        response.forEach((item: any) => {
          item.chart_data.length > 0 &&
            item.chart_data.forEach((item: any, index: any) => {
              if (i.name === item[key]) {
                data.push(
                  parseFloat(item.value)
                    .toFixed(2)
                    .replace(/\.?0+$/, '')
                )
              }
            })
        })

        seriesData.push({
          name: i.name,
          type: 'bar',
          width: '100%',
          label: labelOption(i.name),
          tooltip: {
            valueFormatter: function (params: any) {
              return params
            },
          },
          emphasis: {
            focus: 'series',
          },
          data: data,
        })
      })
    }

    switch (month.value) {
      case 'this_quarter':
        initialChart.xAxisData = getQuarter().quarterMonths
        initialChart.rotate = 1
        break

      case 'last_quarter':
        initialChart.xAxisData = getQuarter(lastQuarter).quarterMonths
        initialChart.rotate = 1
        break

      case 'this_year':
        initialChart.xAxisData = year
        initialChart.rotate = 1
        break

      case 'last_year':
        initialChart.xAxisData = year
        initialChart.rotate = 1
        break

      default:
        initialChart.xAxisData = xAxisData
        initialChart.rotate = 30
        break
    }

    if (isEmpty(warehouse.value)) {
      initialChart.xAxisData = xAxisData
      initialChart.rotate = 30
    }

    initialChart.series = seriesData
    initialChart.legend = legend
    initialChart.startZoom = 0
    initialChart.endZoom = 100

    setBarChart(initialChart)
  }

  const fetchMonthFilter = async (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        value: 'today',
        label: 'Today',
      },
      {
        value: 'yesterday',
        label: 'Yesterday',
      },
      {
        value: 'this_week',
        label: 'This Week',
      },
      {
        value: 'last_week',
        label: 'Last Week',
      },
      {
        value: 'this_month',
        label: 'This Month',
      },
      {
        value: 'last_month',
        label: 'Last Month',
      },
      {
        value: 'this_quarter',
        label: 'This Quarter',
      },
      {
        value: 'last_quarter',
        label: 'Last Quarter',
      },
      {
        value: 'this_year',
        label: 'This Year',
      },
      {
        value: 'last_year',
        label: 'Last Year',
      },
    ]
    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const warehousePieChart = async () => {
    setFetchingWarehouse(true)

    const url = `${apiBaseURL()}reports/complaint-requests/warehouse-raised?timeline=${month.value}`

    const response = await getRequest(url, true)

    let chart: any = {...warehouseChart}
    let mergeChart: Object[] = []

    if (response.result) {
      if (response.result.length >= 1) {
        setWarehouseReportId(response.result[0].warehouse_id)
      }

      if (response && response.result) {
        response.result.forEach((item: any, index: number) => {
          mergeChart.push({
            name: item.warehouse_name,
            value: item.raised_count,
            warehouse_id: item.warehouse_id,
            selected: index === 0 ? true : false,
          })
        })
      }
    }

    chart.data = mergeChart
    setWarehouseChart(chart)
    setFetchingWarehouse(false)
  }

  const supportRequestAttendedPieChart = async () => {
    setFetchingRequest(true)

    const url = `${apiBaseURL()}reports/complaint-requests/attended/${
      isEmpty(warehouseReportId) ? '' : warehouseReportId
    }?timeline=${month.value}`

    const response = await getRequest(url, true)

    let chart: any = {...request}
    let mergeChart: Object[] = []

    if (response && response.result) {
      response.result.forEach((item: any) => {
        mergeChart.push({
          name: item.request_status.toUpperCase(),
          value: parseFloat(item.requests_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          total_request_count: parseFloat(item.total_request_count)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    chart.data = mergeChart
    setRequest(chart)
    setFetchingRequest(false)
  }

  useEffect(() => {
    if (!isEmpty(warehouseReportId)) {
      supportRequestAttendedPieChart()
    }
  }, [warehouseReportId, month])

  useEffect(() => {
    fetchMetaCounts()
  }, [warehouse, month])

  useEffect(() => {
    warehousePieChart()
  }, [month])

  useEffect(() => {
    vehicleChartReport()
  }, [warehouse, month])

  const Filters = () => {
    return (
      <div className='d-flex'>
        <WarehouseFilter
          warehouse={warehouse}
          handleFilterChange={(e: any) => setWarehouse(e)}
          isDisabled={false}
        />

        <AsyncPaginate
          loadOptions={fetchMonthFilter}
          isSearchable
          className='react-select-container my-1 me-3 w-180px'
          classNamePrefix='react-select'
          placeholder='Today'
          onChange={(e: any) => {
            setMonth(e)
          }}
          value={month}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#0c78a4',
            },
          })}
        />
      </div>
    )
  }

  const toolTipWarehouse = (item: any) => {
    return `<div>
        <p>
          <b>${item.data.name}</b>
        </p>
        <div>
          <b>Total Complaints Raised Counts - ${numberFormat(item.data.value).displayFormat}</b>
        </div>
      </div>`
  }

  const toolTip = (item: any) => {
    return `<div>
        <p>
          <b>Complaint Status - ${item.data.name}</b>
        </p>
        <div>
          <b>Total Complaints Counts - ${
            numberFormat(item.data.total_request_count).displayFormat
          }</b>
        </div>
      </div>`
  }

  const updateSelectedWarehouse = (item: any) => {
    const id = item.data.warehouse_id
    let chart: any = {...warehouseChart}
    let mergeChart: Object[] = []

    chart.data.forEach((item: any, index: number) => {
      mergeChart.push({
        name: item.name,
        value: item.value,
        warehouse_id: item.warehouse_id,
        selected: item.warehouse_id === id ? true : false,
      })
    })

    chart.data = mergeChart
    setWarehouseChart(chart)
  }

  return (
    <>
      <EATitle title='Dashboard' />
      <PageTitle
        breadcrumbs={[
          {
            title: 'Dashboard',
            subTitle: 'Dashboard',
          },
        ]}
      >
        Dashboard
      </PageTitle>

      {tsDashboardId &&
        ReactDOM.render(<Filters />, document.getElementById('ts-dashboard-filters'))}

      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={`${
              isEmpty(metaCounts.closed_complaints) ? '0' : metaCounts.closed_complaints.toString()
            } / ${
              isEmpty(metaCounts.open_complaints) ? '0' : metaCounts.open_complaints.toString()
            }`}
            path='/'
            description='Complaints Requests'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='info'
            title={
              isEmpty(metaCounts.survey_conducted) ? '0' : metaCounts.survey_conducted.toString()
            }
            description='Surveys Conducted'
            path='/'
            label='Current'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={
              isEmpty(metaCounts.orders_feedbacks) ? '0' : metaCounts.orders_feedbacks.toString()
            }
            description='Orders Feedback'
            path='/'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='primary'
            title={
              isEmpty(metaCounts.total_customers) ? '0' : metaCounts.total_customers.toString()
            }
            description='New Customers Created'
            path='/'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='danger'
            title={isEmpty(metaCounts.total_orders) ? '0' : metaCounts.total_orders.toString()}
            description='Total Orders Generated'
            path='/'
          />
        </div>
      </div>

      <div className={`card mb-5`}>
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Tale-Sales Overview</span>
          </h3>
        </div>
        <div className='card-body'>
          {isChartLoading ? (
            <FetchingRecords />
          ) : barCharEmpty.length === 0 ? (
            <NoRecords />
          ) : (
            <EaChartBar {...barChart} />
          )}
        </div>
      </div>

      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Complaints Analytics</span>
              </h3>
            </div>

            {warehouseChart.data.length > 0 ? (
              <div className='card-body'>
                <div className='row'>
                  {fetchingWarehouse ? (
                    <div
                      style={{
                        marginTop: '30%',
                      }}
                    >
                      <FetchingRecords />
                    </div>
                  ) : (
                    <div className='col-md-6'>
                      <EaChartPie
                        item={warehouseChart}
                        tooltip={toolTipWarehouse}
                        onClick={(item: any) => {
                          setWarehouseReportId(item.data.warehouse_id)
                          updateSelectedWarehouse(item)
                        }}
                        isAnimationDisable={true}
                      />
                    </div>
                  )}
                  <div className='col-md-6'>
                    {request.data.length > 0 && (
                      <>
                        {fetchingRequest ? (
                          <div
                            style={{
                              marginTop: '30%',
                            }}
                          >
                            <FetchingRecords />
                          </div>
                        ) : (
                          <EaChartPie tooltip={toolTip} item={request} isAnimationDisable={true} />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <NoRecords />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TSDashboard
