import React from "react"

interface buttonProps {
    dataTarget?: string | boolean,
    isModal?: boolean,
    btnName?: string,
    className?: string,
    classNameText?: string,
    onClick?: any,
    isDisable?: boolean
}

const EaButton: React.FC<buttonProps> = ({ onClick, dataTarget, btnName, className, classNameText, isModal, isDisable }) => {
    return (
        <button
            className={className ? `btn ${className}` : 'btn btn-primary'}
            data-bs-toggle={isModal ? 'modal' : ''}
            data-bs-target={isModal ? dataTarget : ''}
            disabled={isDisable && isDisable}
            onClick={onClick}
        >
            {btnName}
        </button>
    )
}

export default EaButton
{/* <div className={`col-md-auto ms-auto mt-3 mt-md-0 ${className}`}> */ }
