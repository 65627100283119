import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { dateFormat, isEmpty, reSendEmail } from "../../../../../_eaFruitsDms/helpers";
import { EaAlertSuccess } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import tenantConfiguration from "../../../../../TenantVariables";

const Overview = (props: any) => {
    const navigator = useNavigate()

    const user = props.user 
    const [isAlertSuccess, setIsAlertSuccess] = useState(false)
     const [isLoading, setIsLoading] = useState('Resend Verification Email')
    
    if (isEmpty(user)) {
        navigator("/tele-sales")
    }

    const handleValidate = () => {
        if(isLoading === "Please Wait...") {
            return false
        }
        setIsLoading('Please Wait...')
        reSendEmail(user && user.email).then((res: any) => {  
          if(res.status == 200)
          setIsLoading('Resend Verification Email')
          setIsAlertSuccess(true)
    
          setTimeout(() => {
            setIsAlertSuccess(false)
          }, 3000)
         
        })
      }

    const isAlertSHow = (val: any) => {
        if (val == 'success') {
        setIsAlertSuccess(false)
        }
    }

    return (
        <>
        <EaAlertSuccess
            onClick={() => isAlertSHow('success')}
            show={isAlertSuccess}
            message={`Verification email has been resent to ${user.email}.`}
        />     
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body py-5'>
                <div className='row'>
                    <div className="col-sm-6 col-xl-4 mb-6">
                        <label className='font-14 text-muted mb-2'>Tele-Sales Name</label>
                        <div>
                            <span className='font-14 text-dark'>{user && user.first_name + " " + user.last_name}</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                        <label className='font-14 text-muted mb-2'>Gender</label>
                        <div>
                            <span className='font-14 text-dark'>{user && user.gender}</span>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {/* <div className="col-sm-6 col-xl-4 mb-6">
                        <label className='font-14 text-muted mb-2'>Address</label>
                        <div>
                            <span className='font-14 text-dark'>755G+PRW, Dar es Salaam, Tanzania</span>
                        </div>
                    </div> */}

                    {/* <div className="col-sm-6 col-xl-4 mb-6">
                        <label className='font-14 text-muted mb-2'>Phone Number</label>
                        <div>
                            <span className='font-14 text-dark'>{user && user.phone_no}</span>
                        </div>
                    </div> */}
                </div>
                <div className='row'>
                    <div className="col-sm-6 col-xl-4 mb-6">
                        <label className='font-14 text-muted mb-2'>Email</label>
                        <div>
                            <span className='font-14 text-dark'>{user && user.email}</span>
                            {user && !user.is_user_verified && 
                                <button
                                className='btn btn-link ms-3 p-0 '
                                type='button'
                                onClick={handleValidate}
                                >
                                {isLoading}
                                </button>
                              }  
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                        <label className='font-14 text-muted mb-2'>Mobile Number</label>
                        <div>
                            <span className='font-14 text-dark'>{user && `${tenantConfiguration.baseCountryCode} ${user.phone_no}`}</span>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-xl-4 mb-6">
                    <label className='font-14 text-muted mb-2'>Date Of Birth</label>
                    <div>
                        <span className='font-14 text-dark'>{user && dateFormat(user.date_of_birth).displayDate}</span>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
const mapStateToProps = (state: any) => {
    return {
        user: state.teleSalesUserReducer.user
    }
}

export default connect(mapStateToProps, null)(Overview)
