import React, { useEffect, useState } from 'react'
import { KTSVG, apiBaseURL, customShortName, dateFormat, downloadFile, shortName } from '../../../../_eaFruitsDms/helpers'
import { getRequest } from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import { isEmpty } from '../../../../_eaFruitsDms/helpers'

const Messages = (props : any) => {
 
    const supportRequestId = localStorage.getItem('supportRequestId')
    const [requestDetails, setRequestDetails] = useState([])
    const [isLoading, setIsLoading] = useState(true);
 
    useEffect(() =>{
        if(props.reload == false){
            supportRequest()
        }
       },[props])

    const supportRequest = async () => {
        setIsLoading(true)
        const url = `${apiBaseURL()}support_request/message/view?support_request=${supportRequestId}`
        const response = await getRequest(url, true);
        setRequestDetails(response.results)
        if (response) {
            setIsLoading(false)
        }
    }

    return (
        <>
            {isLoading && <IsLoader />}
                { requestDetails.length === 0 ?  <div className='card mb-5 mb-xl-6 py-7' ><NoRecords/></div> :
                    requestDetails.map((message : any) =>{
                        let tsFirstName =  message.tele_sales && message.tele_sales !== null ? message.tele_sales.first_name  : 'Tele'
                        let tsLastName =  message.tele_sales && message.tele_sales !== null ? message.tele_sales.last_name : 'Sales'
                        let customerName = message.customer &&  message.customer.business_name
                        // let tsFirstName =  isEmpty(message.tele_sales  ? 'Tele' : message.tele_sales.first_name)
                        // let tsLastName =  isEmpty(message.tele_sales  ? 'Sales' : message.tele_sales.last_name)
                    return(
                        <div className='card mb-5 mb-xl-6' >
                        <div className='card-header border-0 py-2 px-7 align-items-center'>
                            <div className='d-flex'>
                                <span className='rounded-circle bg-light-warning pro-icon me-3 d-inline-block text-dark font-10'>
                                {message.sent_type !== 'customer' ? customShortName(tsFirstName , tsLastName) : shortName( customerName) }
                                </span>
                                {message.sent_type !== 'customer' ? 
                                    <div className='media-body'>
                                        <p className='m-0 mb-1 xs-font fw-500'>{tsFirstName + ' ' + tsLastName && tsFirstName + ' ' + tsLastName}</p>
                                        <p className='m-0 xs-font fw-500'><span className=' text-muted'>Replied to</span> <span className='text-primary'>{customerName && customerName}</span></p>
                                    </div>
                                    : 
                                    <div className='media-body'>
                                        <p className='m-0 mb-1 xs-font fw-500'><span className='text-primary'>{customerName && customerName}</span>  <span className=' text-muted'>Replied this conversation</span></p>
                                        {message.support_request.status && message.support_request.status !== 'open' && message.tele_sales !== null && 
                                        <p className='m-0 xs-font fw-500'><span className=' text-muted'>Survey response for default Survey to {tsFirstName + ' ' + tsLastName}</span> </p>
                                        }
                                        </div>
                                }
                            </div>
                            <div className="ms-md-auto mt-2 mt-md-0">
                                <div className='card-toolbar d-flex my-0'>
                                    <p className=' fw-500 text-muted'>{dateFormat(message.created_at).displayDate} , {dateFormat(message.created_at, 'LT').displayDate}</p>
                                </div>
                            </div>
                        </div>
                        <div className='card-body py-3'>
                        <div className='row'>
                            <div className='col-md-10'>
                                <p className='text-muted mb-1'>{message.message && message.message ? message.message : ''}</p>
                            </div>
                                {message.message_documents.map((document : any) => {
                                    return(
                                        <>
                                        <div className='row'>
                                            <div className='col-md-2 d-flex align-items-end'>     
                                                <p className='my-2 py-1'>
                                                    {document.name
                                                    ? document.name
                                                    : document.document_name.substring(
                                                        document.document_name.lastIndexOf('/') + 1
                                                    )}
                                                </p>
                                            </div>
                                            <div className='col-md-2 d-flex align-items-end flex-column'>
                                            <div className="min-w-80px">
                                                <a
                                                    href='JAVASCRIPT:void(0)'
                                                    className='btn btn-hover-color-primary btn-sm'
                                                    onClick={() => downloadFile(document.document_name)}
                                                >
                                                    <KTSVG path='/media/icons/duotune/files/fil021.svg' className='ic mr-0' />
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                        </>
                                    )
                                })}
                        </div>
                        </div>
                    </div>
                    )
                })}
        </>
    )
}

export default Messages