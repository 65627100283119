import clsx from "clsx"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { KTSVG } from "../../../../_eaFruitsDms/helpers"
import { ResetPasswordEa } from "../../../../_eaFruitsDms/redux/action/resetPassword/resetPasswordAction"

function ResetPassword(props: any) {
  const queryParams = new URLSearchParams(window.location.search)
  const email = queryParams.get('email')
  const confirmation_token = queryParams.get('confirmation_token')
  // email , confirmation_token,
  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  }

   useEffect(()=>{
     console.log("sdasd");
    
   console.log("msg", props.uniquePassword);
   console.log("msg-props", props);
  
   },[])
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);

  const forgotPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'New Password should be minimum 8 characters long')
      .max(50, 'Maximum 50 symbols')
      .required('New Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must be at least 8 characters long, and must contain characters (a-z), digits (0-9), and special characters (e.g. ~!@#$)'
      ),
    confirmPassword: Yup.string()
      .min(8, 'New Password should be minimum 8 characters long')
      .max(50, 'Maximum 50 symbols')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must be at least 8 characters long, and must contain characters (a-z), digits (0-9), and special characters (e.g. ~!@#$)'
      )
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newPassword')], 'Password does not match'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      let newPassword = values.newPassword
      let confirmPassword = values.confirmPassword
      props.loginEaFruitsProp(email, newPassword, confirmPassword, confirmation_token)
      formik.resetForm()
    },
  })

  const navigate = useNavigate()

  const handleTogglePassword = (name : any) => {
    if(name === "newPassword"){
        setShowNewPassword(!showNewPassword);
    }
    if(name === "confirmPassword"){
        setShowCnfPassword(!showCnfPassword);
    } 
  };

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Title */}
        <div className='text-start mb-6'>
          <h1 className='text-dark font-17 font-bold mb-1'>Set New Password</h1>
        </div>
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-4'>
          <label className='form-label text-dark'>New Password</label>
          <div className="d-flex position-relative">
            <input
              type={showNewPassword ? 'text' : 'password'}  
              placeholder='Type here..'
              autoComplete='off'
              {...formik.getFieldProps('newPassword')}
              className={clsx(
                'form-control psw-input',
                {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                {
                  'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                }
              )}
            />
            <a onClick={() => handleTogglePassword("newPassword")} className= "btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm">
                        {showNewPassword  
                        ? <><KTSVG path='/media/icons/ic_eye_off.svg' className="ic ms-n4 position-absolute psw-toggle" /></> 
                        : <><KTSVG path='/media/icons/ic_eye_on.svg' className="ic ms-n4 position-absolute psw-toggle" /></>
                        }  
            </a>
          </div>         
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div className='fv-plugins-message-container'>
              <span role='alert' className="text-danger">{formik.errors.newPassword}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className='fv-row mb-4'>
          <label className='form-label text-dark'>Confirm New Password</label>
        <div className="d-flex position-relative">
          <input
              type={showCnfPassword ? 'text' : 'password'} 
              placeholder='Type here..'
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control psw-input',
                {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            <a onClick={() => handleTogglePassword("confirmPassword")} className= "btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm">
                        {showCnfPassword 
                        ? <><KTSVG path='/media/icons/ic_eye_off.svg' className="ic ms-n4 position-absolute psw-toggle" /></> 
                        : <><KTSVG path='/media/icons/ic_eye_on.svg' className="ic ms-n4 position-absolute psw-toggle" /></>
                        }  
            </a>
        </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <span role='alert' className="text-danger">{formik.errors.confirmPassword}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}

        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary fw-500 w-100'
        >
          {props.isLoading ? (
            <span className='indicator-label'>Please wait...</span>
          ) : (
            <span className='indicator-label'>Reset Password</span>
          )}
        </button>
        {/* end::Form group */}
      </form>
      {props.errorMessage && (
        <div className='text-center mt-2 text-danger'>{props.errorMessage}</div>
      )}
      {props.uniquePassword && (
        <div className='text-center mt-2 text-danger'>{props.uniquePassword}</div>
      )}

      {/* {props.resetResponse && <div className='text-center mt-2 text-primary' >{props.resetResponse}<span className='font-bold text-decoration-underline' onClick={() => navigate('/auth/login')}>Back To Login</span></div>} */}

      {props.resetResponse && (
        <div className='text-center mt-2 text-primary'>
          Your password has been changed successfully.{' '}
          <span
            style={{
              cursor: 'pointer',
            }}
            className='font-bold text-decoration-underline'
            onClick={() => navigate('/auth/login')}
          >
            {' '}
            Back To Login.
          </span>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    resetResponse: state.reset.resetResponse,
    isLoading: state.reset.isLoading,
    errorMessage: state.reset.errorMessage, 
     uniquePassword: state.reset.uniquePassword,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loginEaFruitsProp: (
      email: string,
      password: string,
      confirm_password: string,
      token: string
    ) => {
      dispatch(ResetPasswordEa(email, password, confirm_password, token))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
