import React from "react"
import { useIntl } from "react-intl"

const FormatMessage = (id: any, name?: any, other?: any) => {
    const intl = useIntl();

    if (name !== '') {
        return intl.formatMessage(
            { id: id },
            { name: name }
        )
    } else {
        if (other) {
            return intl.formatMessage(
                { id: id },
                other
            )
        }
        return intl.formatMessage(
            { id: id }
        )
    }
}

export default FormatMessage
