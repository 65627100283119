import React from "react"
import { useIntl } from "react-intl"
import Select from "react-select"
import { KTSVG } from "../../../_eaFruitsDms/helpers"
import { PageTitle } from "../../../_eaFruitsDms/layout/core"

const productsku = [
    { value: 'onjarice', label: 'Onja Rice 1kg' },
    { value: 'Onja', label: 'Onja' },
    { value: 'Golden-Banana', label: 'Golden Banana' }
]

const salesrepresent = [
    { value: 'john doe', label: 'John Doe' },
    { value: 'maria', label: 'Maria' },
]

const batchnumber = [
    { value: '#55737', label: '#55737' },
    { value: '#413123', label: '#413123' },
    { value: '#341312', label: '#341312' }
]


type Props = {
    className: string
}
const ReturnProductStockIn: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const headerTitle = {
        title: "Stock-In",
        subTitle: "Returned Product Stock-in"
    }

    return (
        <>
            <PageTitle breadcrumbs={[headerTitle]} >{intl.formatMessage({ id: 'Returned Product Stock-in' })}</PageTitle>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    {/* begin::Title */}
                    <h3 className='card-title align-items-start flex-column my-0'>
                        <span className='card-label fw-bold font-17'>Returned Product Stock-in</span>
                    </h3>
                    {/* end::Title */}
                </div>


                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-0 pb-3'>
                    <div className='row mb-4'>
                        <div className='col-md-3'>
                            <label className="form-label">Sales Representative</label>
                            <Select
                                options={salesrepresent}
                                isSearchable
                                placeholder="John Doe"
                                className='react-select-container w-220px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />

                        </div>
                    </div>
                    {/* begin::Table container */}
                    <div className='table-responsive border-top'>
                        {/* begin::Table */}
                        <table className='table table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-100px w-100px'>Order ID</th>
                                    <th className='min-w-160px'>Customer Name</th>
                                    <th className='min-w-200px'>Product SKU</th>
                                    <th className='w-120px min-w-120px'>Batch Number</th>
                                    <th className='w-120px min-w-120px'>Receivable Qty</th>
                                    <th className='w-120px min-w-190px'>Actual Qty</th>
                                    <th className='min-w-200px'>Reason</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>

                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="#96830"
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Subway"
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={productsku}
                                            isDisabled={true}
                                            isSearchable
                                            placeholder="Onja Rice 1kg"
                                            className='react-select-container w-100'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={batchnumber}
                                            isDisabled={true}
                                            isSearchable
                                            placeholder="#55737"
                                            className='react-select-container w-140px'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='45 Bags'
                                            defaultValue={'45 Bags'}
                                            disabled
                                        />
                                        <span className='font-13 text-muted mt-1'>45 Kg.</span>
                                    </td>
                                    <td>
                                        <div className='input-group mb-1'>
                                            <input type="number"
                                                placeholder="45"
                                                className='form-control min-w-70px'
                                                defaultValue={'45'} />
                                            <span className="input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px">Bags</span>
                                        </div>
                                        <span className='font-13 text-muted'>45 Kg.</span>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Quality issue"
                                        />
                                    </td>
                                    <td>
                                        <button type="button" className="position-relative btn ms-auto p-0">
                                            <span className="svg-icon-dark">
                                                <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="ic me-0" />
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="#96830"
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Subway"
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={productsku}
                                            isDisabled={true}
                                            isSearchable
                                            placeholder="Onja Rice 5kg"
                                            className='react-select-container w-100'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={batchnumber}
                                            isDisabled={true}
                                            isSearchable
                                            placeholder="#33011"
                                            className='react-select-container w-140px'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="45 Bags"
                                            disabled
                                        />
                                        <span className='font-13 text-muted'>225 Kg.</span>
                                    </td>
                                    <td>
                                        <div className='input-group mb-1'>
                                            <input type="number"
                                                placeholder="45"
                                                className='form-control min-w-70px'
                                                defaultValue={'45'} />
                                            <span className="input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px">Bags</span>
                                        </div>
                                        <span className='font-13 text-muted'>225 Kg.</span>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Wrong Item delivered"
                                        />
                                    </td>
                                    <td>
                                        <button type="button" className="position-relative btn ms-auto p-0">
                                            <span className="svg-icon-dark">
                                                <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="ic me-0" />
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="#96830"
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Subway"
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={productsku}
                                            isDisabled={true}
                                            isSearchable
                                            placeholder="Onja Rice 5kg"
                                            className='react-select-container w-100'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={batchnumber}
                                            isDisabled={true}
                                            isSearchable
                                            placeholder="#33011"
                                            className='react-select-container w-140px'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="45 Bags"
                                            disabled
                                        />
                                        <span className='font-13 text-muted'>225 Kg.</span>
                                    </td>
                                    <td>
                                        <div className='input-group mb-1'>
                                            <input type="number"
                                                placeholder="45"
                                                className='form-control min-w-70px'
                                                defaultValue={'45'} />
                                            <span className="input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px">Bags</span>
                                        </div>
                                        <span className='font-13 text-muted'>225 Kg.</span>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Wrong Item delivered"
                                        />
                                    </td>
                                    <td>
                                        <button type="button" className="position-relative btn ms-auto p-0">
                                            <span className="svg-icon-dark">
                                                <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="ic me-0" />
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="#96830"
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Subway"
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={productsku}
                                            isDisabled={true}
                                            isSearchable
                                            placeholder="Banana L"
                                            className='react-select-container w-100'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={batchnumber}
                                            isDisabled={true}
                                            isSearchable
                                            placeholder="#33011"
                                            className='react-select-container w-140px'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="45 Crate"
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <td>
                                            <div className='input-group mb-1'>
                                                <input type="number"
                                                    placeholder="45"
                                                    className='form-control min-w-70px'
                                                    defaultValue={'45'} />
                                                <span className="input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px">Crate</span>
                                            </div>
                                        </td>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Wrong Item delivered"
                                        />
                                    </td>
                                    <td>
                                        <button type="button" className="position-relative btn ms-auto p-0">
                                            <span className="svg-icon-dark">
                                                <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="ic me-0" />
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Order Id"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Customer Name"
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={productsku}
                                            isSearchable
                                            placeholder="Select"
                                            className='react-select-container w-100'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={batchnumber}
                                            isSearchable
                                            placeholder="Select"
                                            className='react-select-container w-140px'
                                            classNamePrefix="react-select"
                                            menuPosition="fixed"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Type here..."
                                        />
                                    </td>
                                    <td>
                                        <button type="button" className="position-relative btn ms-auto p-0">
                                            <span className="svg-icon-dark">
                                                <KTSVG path="/media/icons/duotune/abstract/abs011.svg" className="ic me-0" />
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                    <div className='pt-4 border-top text-center'>
                        <button type="button" className="btn btn-secondary me-3">Cancel</button>
                        <button className="btn btn-primary">Submit</button>
                    </div>
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default ReturnProductStockIn
