import clsx from 'clsx'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../TenantVariables'
import {
  KTSVG,
  apiBaseURL,
  checkEmpty,
  fetchAsyncDropdown,
  getLoginWarehouse,
  measurementLabel,
  numberFormat,
} from '../../../_eaFruitsDms/helpers'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest, patchRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import SkuThumbnail from '../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import usePermission from '../../../_eaFruitsDms/helpers/components/usePermission'

const WholesalerQuantitySettings = () => {
  const intl = useIntl()

  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [fetchingRecords, setFetchingRecords] = useState(true)
  const [fetchingBulkRecords, setFetchingBulkRecords] = useState(true)
  const [search, setSearch] = useState('')
  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [warehouse, setWarehouse] = useState<any>(getLoginWarehouse())
  const [warehouseOffset, setWarehouseOffset] = useState('')
  const [warehouseList, setWarehouseList] = useState([])
  const [warehouseSearch, setWarehouseSearch] = useState('')
  const [records, setRecords] = useState([])
  const [bulkRecords, setBulkRecords] = useState<any>([])
  const [show, setShow] = useState(false)
  const [updateQuantity, setUpdateQuantity] = useState([])
  const [submit, setSubmit] = useState('Submit')

  const debounceSearch = useDebounce(search, 500)
  const permission = usePermission('wholesaler_quantity_settings')

  const fetchRecords = async () => {
    setFetchingRecords(true)

    const url = `${apiBaseURL()}master/product/wholesaler-products-price-settings?warehouse_id=${
      warehouse.value
    }&search=${debounceSearch}&limit=${limit}&offset=${currentOffset}`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setRecords(response.results)
      setCount(response.count)
    }

    setFetchingRecords(false)
  }

  const fetchBulkValue = async () => {
    setFetchingBulkRecords(true)

    const url = `${apiBaseURL()}master/product/wholesaler-products-price-settings?warehouse_id=${
      warehouse.value
    }&is_drop_down=true`

    const response = await getRequest(url, true)

    if (response && response.length) {
      setBulkRecords(response)
      let setQuantity: any = []

      response.forEach((item: any) => {
        setQuantity.push({
          qty_limit: '',
          product_sku: item.product_id,
        })
      })

      setUpdateQuantity(setQuantity)
    }

    setFetchingBulkRecords(false)
  }

  const fetchSingleValue = async (item: any) => {
    setShow(true)
    setFetchingBulkRecords(true)

    const url = `${apiBaseURL()}master/product/wholesaler-products-price-settings/${
      item.product_id
    }/${warehouse.value}`

    const response = await getRequest(url, true)

    if (response && response.result) {
      setBulkRecords([response.result])

      let setQuantity: any = []

      setQuantity.push({
        qty_limit: '',
        product_sku: response.result.product_id,
      })

      setUpdateQuantity(setQuantity)
    }

    setFetchingBulkRecords(false)
  }

  const handleFilterChange = (e: any) => {
    setCurrentOffset(0)
    setSelected(0)
    setWarehouse(e)
  }

  const fetchWarehouse = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffset,
      setWarehouseOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?parent_warehouse__id=${localStorage.getItem(
        'w_id'
      )}&is_parent=True&ordering=name`,
      search,
      setWarehouseSearch,
      false,
      false
    )
  }

  const isSubmit = async () => {
    setSubmit('Please Wait')

    let updatedQuantity = [...updateQuantity]
    let quantity: any = []

    updatedQuantity.forEach((item: any) => {
      if (!isEmpty(item.qty_limit)) {
        quantity.push({
          product_sku: parseInt(item.product_sku),
          qty_limit: parseInt(item.qty_limit),
        })
      }
    })

    let body = {product_price_setting: quantity}

    if (quantity.length > 0) {
      const url = `${apiBaseURL()}master/product/update-create/wholesaler-products-price-settings/${
        warehouse.value
      }`

      const response = await patchRequest(url, body, true)

      if (response.status === 200) {
        setMessage('Wholesaler quantity setting is updated.')
        setIsAlert(true)
        isClear()
        fetchRecords()
        setTimeout(() => {
          setIsAlert(false)
        }, 2000)
      }
    } else {
      isClear()
    }
    setSubmit('Submit')
  }

  const isClear = () => {
    setUpdateQuantity([])
    setSubmit('Submit')
    setShow(false)
  }

  useEffect(() => {
    fetchRecords()
  }, [debounceSearch, warehouse, currentOffset])

  return (
    <>
      <EATitle title='Wholesaler Quantity Settings' />

      <PageTitle
        breadcrumbs={[
          {
            title: 'Wholesaler Quantity Settings',
            subTitle: 'Wholesaler Quantity Settings',
          },
        ]}
      >
        {intl.formatMessage({id: 'Wholesaler Quantity Settings'})}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      <div className='card'>
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              isDisable={false}
            />
            <div className='col-auto ms-auto'>
              <form
                className=' card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                {/* filter dropdown */}
                <AsyncPaginate
                  loadOptions={fetchWarehouse}
                  onChange={(e: any) => {
                    handleFilterChange(e)
                  }}
                  isSearchable
                  placeholder='All Warehouse'
                  isDisabled={fetchingRecords}
                  value={warehouse}
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                {permission.write && (
                  <button
                    className='btn btn-primary ms-3'
                    onClick={() => {
                      setShow(true)
                      fetchBulkValue()
                    }}
                  >
                    Add Wholesaler Quantity
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {fetchingRecords ? (
              <FetchingRecords />
            ) : records.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-100px py-2'>Product SKU</th>
                    <th className='min-w-100px py-2'>Size</th>
                    <th className='min-w-120px py-2'>Packaging</th>
                    <th className='min-w-120px py-2'>Brand</th>
                    <th className='min-w-120px py-2'>Category</th>
                    <th className='min-w-120px py-2'>Product Group</th>
                    <th className='min-w-120px py-2'>Qty Batchmark</th>
                    {permission.write && <th className='min-w-120px py-2'>Action</th>}
                  </tr>
                </thead>

                <tbody>
                  {records.length > 0 &&
                    records.map((item: any) => {
                      let approxWeight = 0

                      if (item.approx_weight && item.approx_weight > 0) {
                        approxWeight = item.approx_weight / 1000
                      }

                      return (
                        <tr>
                          <td>
                            <SkuThumbnail
                              src={item.product_thumbnail}
                              skuName={item.product_name}
                            />
                          </td>
                          <td>
                            {approxWeight > 0
                              ? approxWeight + ' Kgs'
                              : (item.measurement_unit.toLowerCase() === 'gram'
                                  ? item.size / 1000
                                  : item.size) + ' Kgs'}
                          </td>
                          <td>{checkEmpty(item.material_name)}</td>
                          <td>{checkEmpty(item.brand_name)}</td>
                          <td>
                            <div>
                              <div className='text-muted font-13 mb-1 font-medium'>
                                {checkEmpty(item.parent_category_name)}
                              </div>
                              {checkEmpty(item.category_name)}
                            </div>
                          </td>
                          <td>{checkEmpty(item.group_name)}</td>
                          <td>
                            {item.product_setting_qty && item.product_setting_qty !== '' ? (
                              <>
                                {numberFormat(item.product_setting_qty, true).displayFormat}{' '}
                                {item.material_name && measurementLabel(item.material_name)}
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                          {permission.write && (
                            <td>
                              <div
                                onClick={() => {
                                  fetchSingleValue(item)
                                }}
                                className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm cursor-pointer me-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='ic mr-0'
                                />
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => {
          isClear()
        }}
        centered
        backdrop='static'
        dialogClassName='h-auto mw-800px'
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Wholesaler Quantity Settings</h2>
            <div>{warehouse.label}</div>
          </Modal.Title>
          <div
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            onClick={() => {
              isClear()
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>

        <Modal.Body>
          {fetchingBulkRecords ? (
            <FetchingRecords />
          ) : bulkRecords.length === 0 ? (
            <NoRecords />
          ) : (
            <>
              {
                <div className='row'>
                  <div className='col-md-12'>
                    <table className='table table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='text-muted fw-500'>
                          <th className='min-w-250px me-3'>Product SKUs</th>
                          <th className='min-w-150px me-3'>Size</th>
                          <th className='min-w-200px'>Qty Batchmark</th>
                        </tr>
                      </thead>

                      <tbody>
                        {bulkRecords.length > 0 &&
                          bulkRecords.map((item: any, index: number) => {
                            let size = item.fix_size
                              ? item.measurement_unit.toLowerCase() === 'gram'
                                ? item.size / 1000
                                : item.size
                              : item.approx_weight > 0
                              ? item.approx_weight / 1000
                              : 1

                            return (
                              <tr className='mb-3'>
                                <td className='me-3'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    value={item.product_name}
                                    disabled
                                  />
                                </td>
                                <td className='me-3'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    value={size + ' Kgs'}
                                    disabled
                                  />
                                </td>
                                <td className='me-3'>
                                  <div className='input-group'>
                                    <input
                                      type='text'
                                      className={clsx('form-control', '')}
                                      name='lowStock'
                                      onChange={(e) => {
                                        const valid = /^[0-9\b]+$/
                                        if (e.target.value === '' || valid.test(e.target.value)) {
                                          if (e.target.value !== '0') {
                                            let updatedQuantity: any = [...updateQuantity]
                                            let updatedBulkRecords: any = [...bulkRecords]

                                            updatedQuantity[index].qty_limit = e.target.value
                                            updatedBulkRecords[index].product_setting_qty =
                                              e.target.value

                                            setUpdateQuantity(updatedQuantity)
                                            setBulkRecords(updatedBulkRecords)
                                          }
                                        }
                                      }}
                                      value={
                                        isEmpty(item.product_setting_qty)
                                          ? ''
                                          : item.product_setting_qty
                                      }
                                      placeholder={
                                        !item.product_setting_qty ? '-' : item.product_setting_qty
                                      }
                                    />
                                    <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                                      {measurementLabel(item.material_name)}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn btn-secondary me-3'
              onClick={() => {
                isClear()
              }}
              disabled={submit !== 'Submit'}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                isSubmit()
              }}
              disabled={submit !== 'Submit'}
            >
              {submit}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <CustomPaginate
        data={records}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={fetchingRecords}
        saveFilterName={'feedbackReport'}
        filterStates={''}
      />
    </>
  )
}

export default WholesalerQuantitySettings
