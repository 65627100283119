import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { bulkcreditScore, customerCreditScoreList, } from "../../../../_eaFruitsDms/apiFunctions/master/master"
import { checkPermissions, defaultPermission } from "../../../../_eaFruitsDms/helpers"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"

type Props = {
  className: string
}
const CreditScore: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Credit Score',
  }
  const initialState = [
    {
      id: 0,
      credit_label: '',
      credit_from: 0,
      credit_to: 0,
      disableCreditTrue: false,
    },
    {
      id: 0,
      credit_label: '',
      credit_from: 0,
      credit_to: 0,
      disableCreditTrue: false,
    },
    {
      id: 0,
      credit_label: '',
      credit_from: 0,
      credit_to: 0,
      disableCreditTrue: false,
    },
  ]

  const [getList, setGetList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [masterArray, setMasterArray] = useState<any>([])
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [creditRows, setCreditRows] = useState<any>(initialState)
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  useEffect(() => {
    fetchPermission()
    CreditScoreList()
  }, [])

  useEffect(() => {
    getList.map((item: any, index: number) => {
      const rows = [...creditRows]
      rows[index].id = item.id

      rows[index].credit_label = item.credit_range_name
      rows[index].credit_from = item.credit_from
      rows[index].credit_to = item.credit_to

      setCreditRows(rows)
    })
  }, [getList])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_customers")
    setPermission(res)
   }
 
  const CreditScoreList = () => {
    customerCreditScoreList('10', '0', 'id').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
  }
  const bulkEditHandler = async () => {
    setListLoader(true)
    setModalLoader(true)
    const res = await customerCreditScoreList(10, 0, 'id')
    setListLoader(false)
    setCreditRows(res.data)
    setModalLoader(false)
    console.log(res.data)
  }

  const handleChange = (e: any, index: number) => {
    const updatedRows = [...creditRows]
    const inputName = e.target.name
    const inputValue = e.target.value
    updatedRows[index][inputName] = inputValue
    setCreditRows(updatedRows)
  }

  const clearFunction = () => {
    setMasterArray([])
    setErrorMessage('')
    $('#tableId')
      .find('input[type=number]')
      .each(function () {
        var defaultVal = $(this).data('default')
        $(this).val(defaultVal)
      })
  }

  // Develop BY: Kriti
  // Description: put api function which is called on updateBulkCreditScore function

  const apiCall = () => {
    setIsLoading('Please Wait...')
    let masterData: any = []
    creditRows.forEach((element: any, index: number) => {
      getList.find((item: any) => {
        if (item.id == element.id) {
          masterData.push({
            id: element.id,
            credit_from: element.credit_from ? element.credit_from : item.credit_from,
            credit_to: element.credit_to ? element.credit_to : item.credit_to,
          })
        }
      })
      bulkcreditScore(masterData).then((res) => {
        setMasterArray([])
        // setDisableBtn(false)
        setIsLoading('Submit')

        $('#tableId')
          .find('input[type=number]')
          .each(function () {
            var defaultVal = $(this).data('default')
            $(this).val(defaultVal)
          })

        $('#closeBulkEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertFailed(false)
          setIsAlertSuccess(false)
        }, 1500)

        if (res.success) {
          setIsAlertSuccess(true)
          customerCreditScoreList('10', '0', 'id').then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)

            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
          })
        }
        if (res.failed) {
          setIsAlertFailed(true)
        }
      })
    })
  }

  // Develop BY: Kriti
  // Description: range overlap logic

  const dateRangeOverlaps = (a_start: any, a_end: any, b_start: any, b_end: any) => {
    if ((a_start > b_start && a_start > b_end) || (a_start < b_start && a_start < b_end)) {
      if ((a_end > b_start && a_end > b_end) || (a_end < b_start && a_end < b_end)) {
        return false
      }
    }
    return true
  }

  // Develop BY: Kriti
  // Description: set error message function which is called on submit button

  const updateBulkCreditScore = () => {
    let updatedRows = [...creditRows]
    let i, j
    for (i = 0; i < updatedRows.length; i++) {
      if (updatedRows[i].credit_from == '' && updatedRows[i].credit_to == '') {
        continue
      }
      if (parseInt(updatedRows[i].credit_from) >= parseInt(updatedRows[i].credit_to)) {
        setErrorMessage('To value must be higher than the Form value.')
        return false
      }
      for (j = i + 1; j < updatedRows.length; j++) {
        if (
          dateRangeOverlaps(
            parseInt(updatedRows[i].credit_from),
            parseInt(updatedRows[i].credit_to),
            parseInt(updatedRows[j].credit_from),
            parseInt(updatedRows[j].credit_to)
          )
        ) {
          setErrorMessage(
            'Credit score range are overlapped to each other or duplication range are exists, so please try again with different range.'
          )
          return false
        }
      }
    }
    apiCall()
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Credit Score' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({ id: 'Credit Score' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        message='Your Credit Score has been edited successfully.'
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <div className='col-auto ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                {permission.write && 
                <EaButton
                isModal={true}
                dataTarget={'#ea_modal_update_creditrejection'}
                btnName='Update'
                onClick={bulkEditHandler}
              />}
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            {/* <TableCreditScore /> */}
            {listLoadder ? (
              <IsLoader />
            ) : (
              <>
                {' '}
                <table className='table table-row-bordered align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className=' text-muted fw-500'>
                      <th className='min-w-100px py-2' style={{ width: '25%' }}>
                        Range
                      </th>
                      <th className='min-w-100px py-2'>From — To</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {getList.map((item: any) => {
                      return (
                        <tr>
                          <td>{item.credit_range_name}</td>
                          <td>
                            {item.credit_from} — {item.credit_to}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              </>
            )}

            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <EaModal
        id='ea_modal_update_creditrejection'
        modalTitle='Update Credit Score Range'
        widthcls='mw-570px'
        cancel={clearFunction}
        actionBtnName={isModalLoading}
        onSubmit={updateBulkCreditScore}
        closeAuto='closeBulkEditEaModalBrand'
        btnDisabled={buttonDisabled}
        loader={modalLoader}
      >
        <table
          className='table table-borderless table-row-gray-100 align-middle gs-0 gy-3'
          id='tableId'
          style={{ tableLayout: 'fixed' }}
        >
          {/* begin::Table head */}
          <thead>
            <tr className='text-muted fw-500'>
              <th className='' style={{ width: '52%' }}>
                Range Name
              </th>
              <th className='min-w-100px w-auto'>From</th>
              <th className='min-w-100px w-auto'>To</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}

          <tbody>
            {creditRows.length > 0 &&
              creditRows.map((item: any, index: number) => {
                return (
                  <>
                    <tr>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          placeholder={item.credit_range_name}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type='number'
                          className='form-control'
                          name='credit_from'
                          onChange={(e) => handleChange(e, index)}
                          id={item.id}
                          placeholder={item.credit_from}
                          value={item.credit_from}
                        />
                      </td>
                      <td>
                        <input
                          type='number'
                          className='form-control'
                          name='credit_to'
                          onChange={(e) => handleChange(e, index)}
                          id={item.id}
                          disabled={item.disableCreditTrue}
                          placeholder={item.credit_to}
                          value={item.credit_to}
                        />
                      </td>
                    </tr>
                  </>
                )
              })}
          </tbody>
          {/* end::Table body */}
        </table>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}

export default CreditScore
