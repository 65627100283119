import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  apiBaseURL,
  checkUserType,
  dateFormat,
  GLOBAL_DATE_FORMAT,
  KTSVG,
  retrieveFilter,
  saveFilter,
} from '../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../../_eaFruitsDms/utils/FilterConstants'
import {isEmpty} from 'lodash'
import usePermission from '../../../../_eaFruitsDms/helpers/components/usePermission'

const TSSurveyForms = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()

  const adminHeaderTitle = {
    title: 'Survey Forms',
    subTitle: 'Survey Forms',
  }
  let SurveyFiltered = retrieveFilter(filters.ts_survey)

  const initialState = {
    search: SurveyFiltered?.search || '',
    selected: SurveyFiltered?.selected || '',
    currentOffSet: SurveyFiltered?.currentOffset || '',
    buttonDisable: true,
  }

  const [list, setList] = useState([])
  const [search, setSearch] = useState(SurveyFiltered?.search || '')
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [selected, setSelected] = useState<number>(SurveyFiltered?.selected || 0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [currentOffset, setCurrentOffset] = useState<number>(SurveyFiltered?.currentOffset || '0')
  const [isLoading, setIsLoading] = useState(true)
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    SurveyFiltered?.buttonDisable || false
  )

  const debounceSearch = useDebounce(search, 500)
  const [permission, setPermission] = useState(usePermission('mm_survey_forms'))

  useEffect(() => {
    if (!checkUserType(['wm'])) {
      setPermission({
        delete: true,
        read: true,
        write: true,
      })
    }
  }, [])

  useEffect(() => {
    getSureveyFormList()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable
    setFilterStates(updatedState)

    saveFilter(filters.ts_survey, filterStates)

    if (search == '') {
      setButtonDisable(false)
    }
  }, [currentOffset, debounceSearch])

  const getSureveyFormList = async () => {
    setIsLoading(true)
    const url = `${apiBaseURL()}surveyForms/form/tele-sales-list?limit=${limit}&offset=${currentOffset}&search=${debounceSearch}`
    const response = await getRequest(url, true)
    setNext(response.next)
    setList(response.results)
    setCount(response.count)
    setPrev(response.previous)
    if (response) {
      setIsLoading(false)
    }
  }

  // const numberClick = (val: any, index: any) => {
  //     if (val) {
  //         setSelected(val - 1)
  //         let newOffset = index * 10;
  //         // setCurrentOffset(newOffset)
  //     }
  // }

  // const prevPage = () => {
  //     if (prev !== null) {
  //         const queryParams = new URLSearchParams(prev);
  //         const newOffset = queryParams.get('offset');
  //         // setCurrentOffset(newOffset)
  //         setSelected(selected - 1)
  //     }
  // }

  // const nextPage = () => {
  //     if (next !== null) {
  //         const queryParams = new URLSearchParams(next);
  //         const newOffset = queryParams.get('offset');
  //         // setCurrentOffset(newOffset)
  //         setSelected(selected + 1)
  //     }
  // }

  const searchList = (e: any) => {
    const inputValue = e.target ? e.target.value : e.value
    setButtonDisable(true)
    setSearch(e.target.value)
    setFilterStates({...filterStates, ['search']: inputValue})
  }

  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const successSurveyFormKey = localStorage.getItem('successAddTsSurveyForm')

  useEffect(() => {
    if (successSurveyFormKey === 'success') {
      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertSuccess(false)
        localStorage.removeItem('successAddTsSurveyForm')
      }, 1500)
    }
  }, [successSurveyFormKey])

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  const handleChangeReset = () => {
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = {...filterStates}
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title='Survey Form' />
      <div>
        <PageTitle breadcrumbs={[adminHeaderTitle]}>
          {intl.formatMessage({id: 'Survey Forms'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={isAlertSuccess}
          message='your Survey Feedback Form submited successfully.'
        />
        <div className='card'>
          {isLoading && <IsLoader />}
          {/* {
                        displayLoader && <IsLoader />
                    } */}
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={searchList} value={search} isDisable={isLoading} />
            <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
          </div>
          <div className='card-body py-3'>
            {list.length === 0 ? (
              <NoRecords />
            ) : (
              <TsSureveyTable list={list} permission={permission} />
            )}
          </div>
        </div>
        <CustomPaginate
          data={list}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={isLoading}
          saveFilterName={filters.ts_survey}
          filterStates={filterStates}
        />
      </div>
    </>
  )
}

export default TSSurveyForms

export const TsSureveyTable = (props: any) => {
  const navigate = useNavigate()
  const {permission} = props

  return (
    <div className='table-responsive'>
      <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2 table-hover'>
        <thead>
          <tr className='text-muted fw-500'>
            <th className='min-w-150px'>Form ID</th>
            <th className='min-w-150px'>Survey Form Name</th>
            <th className='min-w-140px'>Conducted To</th>
            <th className='min-w-120px'>Created on</th>
            {permission.read && <th className='min-w-80px action-th py-2'>Action</th>}
          </tr>
        </thead>
        <tbody>
          {props.list.map((item: any) => {
            let id = item.id
            let surveyFeedBackState = {
              state: {feedBackId: id, surveyFormCreatedDate: item.create_date},
            }
            return (
              <tr
                key={id}
                className='cursor-pointer' // use for navigation
              >
                <td
                  onClick={(e) => {
                    navigate('/survey-forms/survey-feedback', surveyFeedBackState)
                    localStorage.setItem('surveyform-id-back-navigate', id)
                  }}
                >
                  #{item.id}
                </td>
                <td
                  onClick={(e) => {
                    navigate('/survey-forms/survey-feedback', surveyFeedBackState)
                    localStorage.setItem('surveyform-id-back-navigate', id)
                  }}
                >
                  {item.survey_form_name}
                </td>
                <td
                  onClick={(e) => {
                    navigate('/survey-forms/survey-feedback', surveyFeedBackState)
                    localStorage.setItem('surveyform-id-back-navigate', id)
                  }}
                >
                  {item.conduct_to ? item.conduct_to + ' Customers' : '-'}
                </td>
                <td
                  onClick={(e) => {
                    navigate('/survey-forms/survey-feedback', surveyFeedBackState)
                    localStorage.setItem('surveyform-id-back-navigate', id)
                  }}
                >
                  {isEmpty(item.create_date) ? (
                    '-'
                  ) : (
                    <>
                      {dateFormat(item.create_date).displayDate}
                      <p className='m-0 font-13 text-muted'>
                        {dateFormat(item.create_date, 'LT').displayDate}
                      </p>
                    </>
                  )}
                </td>
                {permission.read && (
                  <td className='text-end'>
                    <span
                      className='btn py-0 ps-1 pe-0 btn-sm btn-hover-color-primary'
                      onClick={(e) => {
                        navigate('/survey-forms/add-survey-form', surveyFeedBackState)
                        localStorage.setItem('surveyform-id-back-navigate', id)
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs011.svg'
                        className='svg-icon-muted ic'
                      />
                    </span>
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
