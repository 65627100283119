import {useState} from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete'
import capitalizeFirstLetter, {
  capitalizeFirstLetterTitleCase,
} from '../../../helpers/components/CapitalLetter'

const PlaceSearch = (props: any) => {
  const initialLatLong = {
    latitude: '',
    longitude: '',
  }

  const [address, setAddress] = useState<string>(props.selectedAddress)

  const handleChange = (address: any) => {
    setAddress(address)
    props.setPlaceId('')
    props.setSelectedAddress(address)
    props.setLatitudeLongitude(initialLatLong)
    props.onChange && props.onChange(address)
  }

  const handleSelect = async (address: any, placeId: any) => {
    try {
      const results: any = await geocodeByAddress(address)

      if (results[0].formatted_address) {
        setAddress(results[0].formatted_address)
        props.setSelectedAddress(results[0].formatted_address)
      }
      const latLng = await getLatLng(results[0])

      props.setLatitudeLongitude({
        latitude: latLng.lat,
        longitude: latLng.lng,
      })

      props.onLatLongSelect &&
        props.onLatLongSelect({
          latitude: latLng.lat,
          longitude: latLng.lng,
        })

      props.setPlaceId(placeId)
      const [place] = await geocodeByPlaceId(placeId)
      const {long_name: postalCode = ''} =
        place.address_components.find((c) => c.types.includes('postal_code')) || {}

      if (postalCode != '') {
        props.setPostalCode(postalCode)
      }
    } catch (e) {
      props.setPlaceId('')
      console.log('e================', e)
    }
  }

  return (
    <>
      <PlacesAutocomplete
        value={
          address
            ? capitalizeFirstLetterTitleCase(address)
            : capitalizeFirstLetterTitleCase(props.selectedAddress)
        }
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => {
          return (
            <div>
              <input
                {...getInputProps({
                  disabled: props.addressDisable,
                  placeholder: 'Search Places ...',
                  className: 'location-search-input form-control ' + props.validationClass,
                })}
              />
              <div className='autocomplete-dropdown-container'>
                {loading && <div className='loading-item'>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item suggestion-item--active'
                    : 'suggestion-item'
                  // inline style for demonstration purpose
                  // const style = suggestion.active
                  //     ? { cursor: 'pointer' }
                  //     : { cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        // style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }}
      </PlacesAutocomplete>
    </>
  )
}

export default PlaceSearch
