import { apiBaseURL } from "../../helpers"
import { deleteRequest, getRequest, postRequest, putRequest } from "../../https/apiCall"

const warehouseId = localStorage.getItem('w_id')

// Bank Accounts
export const bankAccountsList = async (limit: any, offset: any, search?: any, ordering?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?limit=${limit}&offset=${offset}&search=${search}&ordering=-id&warehouse=${warehouseId}`
  await getRequest(`${apiBaseURL()}account/account/bank-details` + url, true).then(
    (res) => {
      data.count = res.count
      data.next = res.next
      data.prev = res.previous
      data.data = res.results
    }
  )
  return data
}
// Bank Accounts Create
export const addBankAccounts = async (
  paymentBank: string,
  account_number: string,
  branch: string,
  ifsccode: string,
  is_active: string
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    paymentBank: paymentBank,
    branch: branch,
    account_number: account_number,
    ifsccode: ifsccode,
    is_active: is_active,
  }
  await postRequest(`${apiBaseURL()}account/account/bank/create`, body, true).then(
    (res) => {
      if (res.status === 201) {
        notify.success = true
      }
      if (res.statusText === 'Bad Request') {
        notify.failed = true
        notify.errorField = res.data.error.field // catch
      }
    }
  )
  return notify
}
// Bank Accounts Edit

export const bankAccountsUpdate = async (
  paymentBank: string,
  account_number: string,
  branch: string,
  ifsccode: string,
  is_active: string,
  id: string
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    paymentBank: paymentBank,
    branch: branch,
    account_number: account_number,
    ifsccode: ifsccode,
    is_active: is_active,
  }
  await putRequest(
    `${apiBaseURL()}account/account/bank/update/${id}/`,
    body,
    true
  ).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}
// Bank Accounts delete
// export const bankAccountsDelete = async (id: string) => {
//   await deleteRequest(
//     `${apiBaseURL()}account/account/bank/delete/${id}/`,
//     true
//   ).then((res) => {})
// }

export const bankAccountsDelete = async (id: any) => {
  const notify = {
    success: false,
    failed: false,
  }
  await deleteRequest(
    `${apiBaseURL()}account/account/bank/delete/${id}`,
    true
  ).then((res) => {
    if (res.status === 204) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}

// Mobile Wallet Accounts
export const mobileWalletAccountsList = async (
  limit: any,
  offset: any,
  search?: any,
  ordering?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?limit=${limit}&offset=${offset}&search=${search}&ordering=-id&warehouse=${warehouseId}`
  await getRequest(
    `${apiBaseURL()}account/account/wallet-details` + url,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}
//  Mobile Wallet Accounts Create
export const addMobileWalletAccounts = async (
  walletProvide: string,
  wallet_number: string,
  is_active: string
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    walletProvide: walletProvide,
    wallet_number: wallet_number,
    is_active: is_active,
  }
  await postRequest(
    `${apiBaseURL()}account/account/wallet/create`,
    body,
    true
  ).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}
//  Mobile Wallet Accounts Edit

export const mobileWalletAccountsUpdate = async (
  walletProvide: string,
  wallet_number: string,
  is_active: string,
  id: string
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    walletProvide: walletProvide,
    wallet_number: wallet_number,
    is_active: is_active,
  }
  await putRequest(
    `${apiBaseURL()}account/account/wallet/update/${id}/`,
    body,
    true
  ).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const mobileWalletAccountsDelete = async (id: any) => {
  const notify = {
    success: false,
    failed: false,
  }
  await deleteRequest(
    `${apiBaseURL()}account/account/wallet/delete/${id}`,
    true
  ).then((res) => {
    if (res.status === 204) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}
