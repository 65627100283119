/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'

import {getUserType} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import CurrentStock from './currentStock'
import TSCurrentStock from './TScurrentStock'

type Props = {
  className: string
}
const CurrentStockWrapper: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Customers',
  }
  return (
    <>
      {localStorage.getItem('user_type') === 'OO' && <CurrentStock className='oo-cs' />}
      {localStorage.getItem('user_type') === 'TS' && <TSCurrentStock className='ts-cs' />}
    </>
  )
}

export {CurrentStockWrapper}
