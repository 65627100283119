/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import SupportRequestReport from './supportRequestReport'

type Props = {
  className: string
}
const SupportRequestWrapper: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Credit Issue Report',
  }

  return (
    <>
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Credit Issue Report'})}
      </PageTitle>
      <SupportRequestReport />
    </>
  )
}

export {SupportRequestWrapper}
