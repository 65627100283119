import React from "react";

export const capitalizeFirstLetterTitleCase = (string: string) => {
  if (!['', undefined, null, 'null'].includes(string)) {
    return string.replace(/\w\S*/g, function (txt: any) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}

const capitalizeFirstLetter = (string: string) => {
  return string && string[0].toUpperCase() + string.slice(1);
}


export default capitalizeFirstLetter
