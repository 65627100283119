import React from 'react'
import {KTSVG} from '../../../../_eaFruitsDms/helpers'

const Overview = (props: any) => {
  const requestDetails = props.requestDetails

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-5'>
        <div className='row'>
          <div className='col-sm-4 col-xl-3 mb-6'>
            <label className='font-14 text-muted mb-2'>Complaint ID</label>
            <div>
              <span className='font-14 text-dark'>#{requestDetails.support_number}</span>
            </div>
          </div>
          <div className='col-sm-4 col-xl-5 mb-6'>
            <label className='font-14 text-muted mb-2'>Customer Name</label>
            <div>
              <span className='font-14 text-dark'>
                {requestDetails.customer && requestDetails.customer.business_name
                  ? requestDetails.customer.business_name
                  : '-'}
              </span>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4 col-xl-3 mb-6'>
            <label className='font-14 text-muted mb-2'>Customer Address</label>
            <div>
              <span className='font-14 text-dark'>
                {requestDetails.customer && requestDetails.customer.address
                  ? requestDetails.customer.address
                  : '-'}
              </span>
            </div>
          </div>
          <div className='col-sm-4 col-xl-5 mb-6'>
            <label className='font-14 text-muted mb-2'>Rating</label>
            <div>
              {requestDetails !== '' &&
              requestDetails.feedback.length > 0 &&
              requestDetails.feedback[0].rating ? (
                <span className='d-flex align-items-center me-5'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen003.svg'
                    className='text-warning me-1'
                  />
                  {requestDetails.feedback[0].rating}
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4 col-xl-3 mb-6'>
            <label className='font-14 text-muted mb-2'>Mapped Warehouse</label>
            <div>
              <span>
                {requestDetails !== '' &&
                requestDetails.customer.warehouse.length > 0 &&
                requestDetails.customer.warehouse[0].name
                  ? requestDetails.customer.warehouse[0].name
                  : '-'}
              </span>
            </div>
          </div>
          <div className='col-sm-4 col-xl-5 mb-6'>
            <label className='font-14 text-muted mb-2'>Review</label>
            <div>
              <span>
                {requestDetails !== '' &&
                requestDetails.feedback.length > 0 &&
                requestDetails.feedback[0].review
                  ? requestDetails.feedback[0].review
                  : '-'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
