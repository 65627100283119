/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import tenantConfiguration from '../../../../TenantVariables'
import {apiBaseURL, KTSVG, removeFilterImpersonate, roleAndPermission} from '../../../helpers'
import {getRequest} from '../../../https/apiCall'
import {getImpersonationUserDetails} from '../../../localStorage/setItem/setLocalStorage'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import Topbar from './Topbar'

const HeaderWrapper = (props: any) => {
  const navigate = useNavigate()

  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  const isImpersonationLogin = localStorage.getItem('isImpersonationLogin')
  const impersonationId = localStorage.getItem('impersonation-id')
  const impersonationToken = localStorage.getItem('impersonation-token')
  const splashScreen = document.getElementById('splashScreenStyle')

  const {fullName, type} = props.profile

  const handleUserType = () => {
    switch (type) {
      case 'SA':
        return 'Super Admin'
      case 'WM':
        return 'Branch Manager'
      case 'OO':
        return 'Operation Officer'
      case 'LO':
        return 'Logistics Officer'
      case 'TS':
        return 'Tele Sale'
      case 'AM':
        return 'Account Manager'
    }
  }

  const handleImpersonationLogout = async () => {
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }

    const response = await getRequest(`${apiBaseURL()}auth/logout?user_id=${impersonationId}`, true)

    if (response.first_name) {
      localStorage.removeItem('permissions')
      localStorage.removeItem('api_process')
      localStorage.removeItem('sa_dashboard_warehouse')
      localStorage.removeItem('sa_dashboard_month')
      localStorage.removeItem('summary_warehouse')
      localStorage.removeItem('summary_month')
      localStorage.removeItem('support_request_warehouse')
      localStorage.removeItem('support_request_start_date')
      localStorage.removeItem('support_request_end_date')

      removeFilterImpersonate()
      let impersonationUser = {
        role: response.role,
        warehouse: response.warehouse,
        warehouse_name: response.warehouse_name,
        first_name: response.first_name,
        last_name: response.last_name,
        profile_thumbnail: response.profile_thumbnail,
        base_url: response.base_url,
        user_type: response.type,
        id: response.id,
        token: impersonationToken,
        email: response.email,
        role_name: response.role_name,
      }

      // reset the value of imperson user
      localStorage.setItem('isImpersonationLogin', 'false')
      getImpersonationUserDetails(impersonationUser)

      await roleAndPermission()

      // back to imperson panel
      navigate('/dashboard')
      window.location.reload()
    } else {
      if (splashScreen) {
        splashScreen.style.setProperty('display', 'none')
      }
    }
  }

  useEffect(() => {
    if (isImpersonationLogin === 'true') {
      document.body.classList.add('top-sticky')
    } else {
      document.body.classList.remove('top-sticky')
    }
  }, [])

  return (
    <>
      {/* impersonation mode start*/}
      {/* impersonation-bar */}
      {isImpersonationLogin === 'true' && (
        <div
          className={`bg-primary d-flex align-items-center impersonation-bar`}
          style={{
            zIndex: 2,
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <div className='text-white'>
                  You're logged in as
                  <span className='fw-500 ms-1'>
                    {fullName} ({handleUserType()})
                  </span>
                </div>
              </div>
              <div className='col-6 text-end'>
                <div className=' text-white d-flex align-items-center justify-content-end'>
                  <span className='cursor-pointer' onClick={handleImpersonationLogout}>
                    <span className='fw-500'>Exit Impersonation</span>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='ms-1 text-white'
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*  impersonation mode end*/}
      <div
        id='kt_header'
        className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
        {...attributes.headerMenu}
      >
        <div
          className={clsx(
            classes.headerContainer.join(' '),
            'd-flex align-items-stretch justify-content-between'
          )}
        >
          {/* begin::Aside mobile toggle */}
          {aside.display && (
            <div className='d-flex align-items-center d-lg-none me-1' title='Show aside menu'>
              <div
                className='btn btn-icon btn-active-light-primary ms-n1 text-muted btn-active-light w-40px h-40px'
                id='kt_aside_mobile_toggle'
              >
                <span className='etc'>
                  <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='ic' />
                </span>
              </div>
            </div>
          )}
          {/* end::Aside mobile toggle */}
          {/* begin::Logo */}
          {!aside.display && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
              <Link to='/dashboard' className='d-lg-none'>
                <img
                  alt='Logo'
                  src={`${tenantConfiguration.imageBaseUrl}logolight.png`}
                  className='h-30px'
                />
              </Link>
            </div>
          )}
          {/* end::Logo */}

          {aside.display && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
              <Link to='/' className='d-lg-none'>
                <img
                  alt='Logo'
                  src={`${tenantConfiguration.imageBaseUrl}logolight.png`}
                  className='h-30px'
                />
              </Link>
            </div>
          )}

          {/* begin::Wrapper */}
          <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
            {/* begin::Navbar */}
            {header.left === 'menu' && (
              <div className='d-flex align-items-stretch ms-n15px' id='kt_header_nav'>
                <Header />
              </div>
            )}

            {header.left === 'page-title' && (
              <div className='d-flex align-items-center' id='kt_header_nav'>
                <DefaultTitle />
              </div>
            )}

            <div className='d-flex align-items-stretch flex-shrink-0'>
              <Topbar />
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    loginDetails: state.login.loginResponse,
    profile: state.profile.profile,
  }
}

export default connect(mapStateToProps, null)(HeaderWrapper)
