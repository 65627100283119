import { Helmet } from "react-helmet"

const EATitle = ({ title }: any) => {
    return (
        <Helmet>
            <title>{title} EA Fruits DMS</title>
        </Helmet>
    )
}

export default EATitle
