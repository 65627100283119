import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {
  apiBaseURL,
  checkPermissions,
  checkUserType,
  defaultPermission,
  KTSVG,
} from '../../../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {patchRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger} from '../../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import ErrorHandler from '../../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import MobileNumberInput from '../../../../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput'
import tenantConfiguration from '../../../../../../TenantVariables'

const MobileNumber = () => {
  const initialSeconds = 60
  const navigate = useNavigate()
  const userType = localStorage.getItem('user_type') || ''
  const [mobile, setMobile] = useState<any>('')
  const [code, setCode] = useState<any>('')
  const [mobileValidation, setMobileValidation] = useState<string>('')
  const [codeValidation, setCodeValidation] = useState<string>('')
  const [step, setStep] = useState<number>(1)
  const [seconds, setSeconds] = useState<number>(initialSeconds)
  const [startTimer, setStartTimer] = useState<boolean>(false)
  const [startRedirectTimer, setStartRedirectTimer] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [responseMessage, setResponseMessage] = useState<string>('hjghjgjhjg')
  const [isVerifyButtonLoader, setIsVerifyButtonLoader] = useState<any>('Verify')
  const [remainsSeconds, setRemainsSeconds] = useState<any>(5)
  const [isMobileUniqueError, setIsMobileUniqueError] = useState<boolean>(false)
  const [permission, setPermission] = useState(defaultPermission)

  useEffect(() => {
    checkPermissions('customer_verification_request').then((res: any) => {
      setPermission(res)
    })

    const contactNo = localStorage.getItem('contact_no')
    const contactVerify = localStorage.getItem('contact_is_verified')

    setMobile(contactNo)

    if (contactVerify != null && contactVerify === 'true') {
      setStep(3)
      setRemainsSeconds(5)
      setStartRedirectTimer(true)
    }
  }, [])

  useEffect(() => {
    if (startTimer) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }

        if (seconds === 0) {
          setStartTimer(false)
        }
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [seconds, startTimer])

  useEffect(() => {
    if (startRedirectTimer) {
      const interval = setInterval(() => {
        if (remainsSeconds > 0) {
          setRemainsSeconds(remainsSeconds - 1)
        }

        if (remainsSeconds === 0) {
          setStartRedirectTimer(false)
          navigate('/customers/verification-requests/overview')
        }
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [remainsSeconds, startRedirectTimer])

  const validateInput = (inputValue: any) => {
    const regex = /^[0-9\b]+$/

    if (inputValue === '' || regex.test(inputValue)) {
      return true
    }

    return false
  }

  const handleChange = (e: any) => {
    const inputName = e.target.name
    const inputValue = e.target.value
    const validate = validateInput(inputValue)

    if (inputName === 'mobile') {
      if (validate) {
        setMobile(inputValue)
        setMobileValidation('')
        setIsMobileUniqueError(false)
      } else {
        setMobileValidation('is-invalid')
      }
    } else {
      if (validate) {
        setCode(inputValue)
        setCodeValidation('')
      } else {
        setCodeValidation('is-invalid')
      }
    }
  }

  const mobileNumberVerification = async () => {
    if (mobile == '') {
      setMobileValidation('is-invalid')
      return
    }
    setIsVerifyButtonLoader('Please Wait...')
    const mobileVerifyData = {
      contact_no: mobile,
    }
    const cId = localStorage.getItem('cId')

    const verificationResponse: any = await patchRequest(
      `${apiBaseURL()}customers/${cId}/send-otp`,
      mobileVerifyData,
      true
    )

    if (
      verificationResponse.data &&
      verificationResponse.data.error &&
      verificationResponse.data.error.field === 'contact_no'
    ) {
      setIsMobileUniqueError(true)
    } else {
      setSeconds(initialSeconds)
      setStep(2)
      setStartTimer(true)
    }
    setIsVerifyButtonLoader('Verify')
  }

  const verifyMobile = async () => {
    if (code == '') {
      setCodeValidation('is-invalid')
      return
    }
    setIsVerifyButtonLoader('Please Wait...')
    const codeVerifyData = {
      otp: parseInt(code),
    }
    const cId = localStorage.getItem('cId')
    const res = await patchRequest(
      `${apiBaseURL()}customers/${cId}/verify-otp`,
      codeVerifyData,
      true
    )

    if (res.data && res.data.error) {
      if (res.data.error.code == 416) {
        setResponseMessage('notValid')
      }
      if (res.data.error.code == 418) {
        setResponseMessage('Expired')
      }
      setShowAlert(true)
      setCode('')
    } else {
      setSeconds(initialSeconds)
      setStep(3)
      setRemainsSeconds(5)
      localStorage.removeItem('contact_is_verified')
      setStartTimer(false)
      setStartRedirectTimer(true)
    }
    setIsVerifyButtonLoader('Verify')
  }

  return (
    <>
      <EaAlertDanger
        newProps={true}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={
          responseMessage === 'notValid'
            ? FormatMessage('VERIFICATION_REQUEST_WRONG_OTP')
            : FormatMessage('VERIFICATION_REQUEST_EXPIRED_OTP')
        }
      />
      {checkUserType(['sa']) ? (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              {/* begin::Title */}
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17'>Mobile Number Verification</span>
              </h3>
              {/* end::Title */}
            </div>
            <div className='card-body border-top pt-4 pt-md-5'>
              <div className='d-flex align-items-center'>
                <div className='me-4'>
                  {step === 1 ? (
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr011.svg'
                      className='ic svg-icon-3x svg-icon-2hx'
                    />
                  ) : (
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='ic svg-icon-3x svg-icon-2hx'
                    />
                  )}
                </div>
                <div>
                  <h2 className='fw-bold font-20'>{mobile}</h2>
                  <h3 className='font-14 fw-500 mb-0'>
                    {step === 1
                      ? 'Mobile number verification is pending'
                      : 'Mobile number is verified'}
                  </h3>
                  {/* <h3 className='font-14 fw-500 mb-0'>
                      Thank you for the mobile verification. You will be auto-redirected to the
                      customer's overview page for your manual verification and approval.
                    </h3> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {step === 1 && (
            <div className='card mb-5 mb-xl-10'>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                {/* begin::Title */}
                <h3 className='card-title align-items-start flex-column my-0'>
                  <span className='card-label fw-bold font-17'>Mobile Number Verification</span>
                </h3>
                {/* end::Title */}
              </div>
              <div className='card-body border-top pt-4 pt-md-5'>
                <h3 className='font-14 fw-500 pb-4 mb-0'>
                  Initiating mobile number verification process will send verification code to the
                  customer’s registered mobile number.
                </h3>
                <div className='row'>
                  <div className='col-lg-9 col-xl-9 col-xxl-8'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <MobileNumberInput
                          label='Registered Mobile Number'
                          validation={mobileValidation}
                          onChange={handleChange}
                          numberValue={mobile}
                          name='mobile'
                        />
                      </div>
                      {isMobileUniqueError && (
                        <div className='mb-3'>
                          <ErrorHandler
                            errorMessage={FormatMessage('CUSTOMER_MOBILE_ALREADY_EXIST')}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-footer py-4'>
                {(permission.write || userType.toLowerCase() === 'ts') && (
                  <button
                    className='btn btn-primary'
                    disabled={isVerifyButtonLoader !== 'Verify'}
                    onClick={mobileNumberVerification}
                  >
                    {isVerifyButtonLoader}
                  </button>
                )}
              </div>
            </div>
          )}
          {step === 2 && (
            <div className='card mb-5 mb-xl-10'>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                {/* begin::Title */}
                <h3 className='card-title align-items-start flex-column my-0'>
                  <span className='card-label fw-bold font-17'>Mobile Number Verification</span>
                </h3>
                {/* end::Title */}
              </div>
              <div className='card-body border-top pt-4 pt-md-5'>
                <h3 className='font-14 fw-500 pb-4 mb-0'>
                  Initiating mobile number verification process will send verification code to the
                  customer’s registered mobile number.
                </h3>
                <div className='row'>
                  <div className='col-lg-9 col-xl-9 col-xxl-8'>
                    <div className='row align-items-center'>
                      <div className='col-md-4'>
                        <label className='form-label'>Registered Mobile Number</label>
                        <div className={clsx('input-group')}>
                          <span className='input-group-text bg-white border-end-0'>
                            {tenantConfiguration.baseCountryCode}
                          </span>
                          <input
                            disabled
                            type='text'
                            className='form-control border-start-0 ps-1 border-focus-light'
                            placeholder={mobile}
                          />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <label className='form-label'>Verification Code</label>
                        <input
                          type='text'
                          className={clsx('form-control', codeValidation)}
                          placeholder='Type here...'
                          name='code'
                          onChange={handleChange}
                          value={code}
                          maxLength={6}
                        />
                      </div>
                      <div className='col-md-4 p-0 mt-6'>
                        {seconds === 0 ? (
                          <a href='JAVASCRIPT:void(0)' onClick={mobileNumberVerification}>
                            Resend Code
                          </a>
                        ) : (
                          <p className='text-primary disabled opacity-50 mb-0'>
                            Resend Code in 00:{seconds < 10 ? '0' + seconds : seconds}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-footer py-4 d-flex align-items-center justify-content-between'>
                <div>
                  <button
                    type='button'
                    className='btn btn-secondary me-3'
                    onClick={() => {
                      navigate('/customers/verification-requests')
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn btn-primary'
                    onClick={verifyMobile}
                    disabled={isVerifyButtonLoader !== 'Verify'}
                  >
                    {isVerifyButtonLoader}
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => {
                      setStep(1)
                      setIsVerifyButtonLoader('Verify')
                      setCode('')
                    }}
                  >
                    Change Number
                  </button>
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className='card mb-5 mb-xl-10'>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                {/* begin::Title */}
                <h3 className='card-title align-items-start flex-column my-0'>
                  <span className='card-label fw-bold font-17'>Mobile Number Verification</span>
                </h3>
                {/* end::Title */}
              </div>
              <div className='card-body border-top pt-4 pt-md-5'>
                <div className='d-flex align-items-center'>
                  <div className='me-4'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='ic svg-icon-3x svg-icon-2hx'
                    />
                  </div>
                  <div>
                    <h2 className='fw-bold font-20'>{mobile}</h2>
                    <h3 className='font-14 fw-500 mb-0'>Mobile number verified successfully!</h3>
                    <h3 className='font-14 fw-500 mb-0'>
                      Thank you for the mobile verification. You will be auto-redirected to the
                      customer's overview page for your manual verification and approval.
                      {/*{remainsSeconds}*/}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default MobileNumber
