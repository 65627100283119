import moment from "moment"
import React from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import { useIntl } from "react-intl"
import Select from "react-select"
import { KTSVG } from "../../../../_eaFruitsDms/helpers"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"

const warehouseoptions = [
    { value: 'all', label: 'All Warehouses' },
    { value: 'Warehouse1', label: 'Warehouse 1' },
    { value: 'Warehouse2', label: 'Warehouse 2' },
    { value: 'Warehouse3', label: 'Warehouse 3' },
]



type Props = {
    className: string
}
const SalesReport: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Sales Report"
    }
    return (
        <>
            <EATitle title='Sales Report' />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Reports' })}</PageTitle>

            <div className={`card ${className} mb-5`}>
                {/* begin::Header */}
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    <div>
                        <h3 className='card-title align-items-start flex-column my-0'>
                            <span className='card-label fw-bold font-17'>Sales Overview</span>
                        </h3>
                        <p className='font-13 text-muted mb-2'>Headquarter Wise</p>
                    </div>
                    {/* begin::Toolbar */}
                    <div className='card-toolbar d-flex g-3 my-0'>

                        <Select
                            options={warehouseoptions}
                            isSearchable
                            placeholder="All Warehouses "
                            className='react-select-container my-1 w-160px'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                        <span className="v-seperator mx-3"></span>
                        <ul className='nav'>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_1'
                                >
                                    Month
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_2'
                                >
                                    Year
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* end::Toolbar */}
                </div>
                <div className="card-body">
                    Chart Area
                </div>
            </div>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <Select
                                    options={warehouseoptions}
                                    isSearchable
                                    placeholder="All Warehouses "
                                    className='react-select-container my-1 me-3 w-160px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2022',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar my-1 me-3 w-auto min-w-215px" />
                                </DateRangePicker>

                                <label className="form-label mb-0"><button type="button" className="btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">
                                    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                    Export</button>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-150px'>Warehouse Name</th>
                                        <th className='min-w-140px'>Warehouse Type</th>
                                        <th className='min-w-120px'>Parent Warehouse</th>
                                        <th className='min-w-120px'>Region</th>
                                        <th className='min-w-80px py-2'>Total Sales</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <td>Warehouse 1</td>
                                        <td>Headquarter</td>
                                        <td>—</td>
                                        <td>Dar es Salaam</td>
                                        <td>TZS  41411</td>
                                    </tr>
                                    <tr>
                                        <td>Warehouse 1</td>
                                        <td>Headquarter</td>
                                        <td>—</td>
                                        <td>Dar es Salaam</td>
                                        <td>TZS  41411</td>
                                    </tr>
                                    <tr>
                                        <td>Warehouse 1</td>
                                        <td>Headquarter</td>
                                        <td>—</td>
                                        <td>Dar es Salaam</td>
                                        <td>TZS  41411</td>
                                    </tr>
                                    <tr>
                                        <td>Warehouse 1</td>
                                        <td>Headquarter</td>
                                        <td>—</td>
                                        <td>Dar es Salaam</td>
                                        <td>TZS  41411</td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>

                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            Showing 1 to 10 of 50 entries
                        </div>
                        <div className="col">
                            <CustomPagination />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Addwarehouse /> */}
        </>
    )
}

export default SalesReport
