import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Route, Routes} from 'react-router-dom'
import {getUserSingle} from '../../../../_eaFruitsDms/apiFunctions/userMaster/user'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import Attendance from './components/attendance'
import Customers from './components/customer'
import Overview from './components/overview'
import PastOrders from './components/pastOrders'
import PaymentIssue from './components/paymentIssue'
import SalesReps from './components/salesReps'
import Targets from './components/targets'
import {TeamMemberHeader} from './components/teamMemberHeader'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import {globalDateFormate} from '../../../../_eaFruitsDms/helpers'

type Props = {
  className?: string
}
const GeneralManager: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  let teamId = localStorage.getItem('teamId')
  const id = teamId

  // all states
  const [fullname, setFullname] = useState<any>('')
  const [isImpersonateAllow, setIsImpersonateAllow] = useState<boolean>(true)
  const [isInActive, setIsInActive] = useState<boolean>(false)
  const [roleName, setRoleName] = useState<any>('')
  const [regionName, setRegionName] = useState<any>('')
  const [firstName, setFirstName] = useState<any>('')
  const [profileImage, setProfileImg] = useState<any>('')
  const [getWhName, setWhName] = useState<any>('')
  const [getParent, setParent] = useState<any>('')
  const [userType, setUserType] = useState<any>('')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [isHideButton, setHideButton] = useState<boolean>(false)
  const [userDetails, setuDetails] = useState<any>({
    name: '-',
    phone: '-',
    email: '-',
    gender: '-',
    bDate: '-',
  })

  useEffect(() => {
    getSingleTeamDetails()
  }, [])

  const teamMembersTitle = {
    title: 'Team members',
    subTitle: fullname,
  }

  const getSingleTeamDetails = () => {
    setDisplayLoader(true)
    getUserSingle(id).then((res: any) => {
      setWhName(res.warehouse.name)
      setParent(res.warehouse.parent_warehouse)
      setFullname(res.first_name + ' ' + res.last_name)
      setIsImpersonateAllow(res.is_impersonate_allow)
      setHideButton(res.is_hide_login_as)
      setIsInActive(res.is_active)
      setFirstName(res.first_name)
      if (res.role !== null) {
        setRoleName(res.role.name)
        setUserType(res.role.user_type)
      }
      if (res.warehouse.region !== null) {
        setRegionName(res.warehouse.region.name)
      }
      setProfileImg(res.profile_img)
      let newDate = moment(res.date_of_birth).format(globalDateFormate())

      setuDetails({
        name: res.first_name + ' ' + res.last_name,
        phone: res.phone_no ? res.phone_no : '-',
        email: res.email ? res.email : '-',
        gender: res.gender ? res.gender : '-',
        bDate: res.date_of_birth ? newDate : '-',
        warehouse: res.warehouse.name ? res.warehouse.name : '-',
        position: res.role.name ? res.role.name : '-',
        supervisor:
          res.supervisor !== null
            ? res.supervisor.first_name + ' ' + res.supervisor.last_name
            : '-',
        userVerified: res.is_user_verified,
      })
      setDisplayLoader(false)
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[teamMembersTitle]} backNavigate='/team-members'>
        {fullname}
      </PageTitle>
      <div className={`card ${className}`}></div>
      {displayLoader && <IsLoader />}
      <TeamMemberHeader
        getParent={getParent}
        fullname={fullname}
        isImpersonateAllow={isImpersonateAllow}
        isInActive={isInActive}
        roleName={roleName}
        regionName={regionName}
        profileImage={profileImage}
        getWhName={getWhName}
        userType={userType}
        firstName={firstName}
        isHideButton={isHideButton}
      />
      <Routes>
        <Route
          path='overview'
          element={<Overview userDetails={userDetails} userType={userType} fullname={fullname} />}
        />
        <Route path='customers' element={<Customers fullname={fullname} />} />
        <Route path='sales-reps' element={<SalesReps fullname={fullname} />} />
        <Route path='past-orders' element={<PastOrders fullname={fullname} />} />
        <Route path='targets' element={<Targets fullname={fullname} />} />
        <Route path='payment-issue' element={<PaymentIssue fullname={fullname} />} />
        <Route path='attendance' element={<Attendance fullname={fullname} />} />
        <Route index element={<Navigate to='/team-members/member-detail/overview' />} />
      </Routes>
    </>
  )
}

export default GeneralManager
