import React, { useState } from "react";
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core";
import { useLocation } from "react-router-dom";

const OrderFeedback = () => {
    const [invoiceNumber, setInvoiceNumber] = useState<any>("-");
    const HeaderTitle = {
        title: "Sales Invoices",
        subTitle: invoiceNumber
    };
    const location = useLocation()
    return (
        <>
            <PageTitle breadcrumbs={[HeaderTitle]}>{HeaderTitle.subTitle}</PageTitle>

            <div className="card mb-4 mb-xl-5">
                <div className="card-body pt-5 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap">
                                <div className="d-flex flex-column mb-2 mb-sm-0">
                                    <div className="mb-1">
                                        <span className="text-dark font-20 fw-bold me-1">
                                            {invoiceNumber}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-wrap mb-2 pe-2 font-14 text-muted">
                                        {/* <span className="d-flex align-items-center me-6 mb-2">
                                            <KTSVG
                                                path="/media/icons/duotune/general/gen014.svg"
                                                className="me-1"
                                            />
                                            {
                                                invoiceDetai && invoiceDetail.payment.length > 0 && !["", null].includes(invoiceDetail.payment[0].created_at) && dateTimeFormat(invoiceDetail.payment[0].created_at)
                                            }
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* <button className="btn btn-primary" onClick={downloadPdf} disabled={downloadButtonDisabled}>
                                {
                                    downloadButtonDisabled ? 'Downloading...' : "Download Invoice"
                                }
                            </button> */}
                        </div>
                    </div>
                    {/*<div className='d-flex overflow-auto h-40px'>*/}
                    {/*    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>*/}
                    {/*        <li className='nav-item'>*/}
                    {/*            <Link*/}
                    {/*                className={*/}
                    {/*                    `nav-link text-active-primary me-0 ` +*/}
                    {/*                    (location.pathname === '/sales-invoices/sales-invoice-detail' && 'active')*/}
                    {/*                }*/}
                    {/*                to='/sales-invoices/sales-invoice-detail'*/}
                    {/*            >*/}
                    {/*                Sales Invoice Detail*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*        <li className='nav-item'>*/}
                    {/*            <Link*/}
                    {/*                className={*/}
                    {/*                    `nav-link text-active-primary me-0 ` +*/}
                    {/*                    (location.pathname === '/sales-invoices/order-feedback' && 'active')*/}
                    {/*                }*/}
                    {/*                to='/sales-invoices/order-feedback'*/}
                    {/*            >*/}
                    {/*                Order Feedback*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}

                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </div>



            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    oRDER FEEDBACK
                </div>
                {/* begin::Body */}
            </div>

        </>
    )
}

export default OrderFeedback
