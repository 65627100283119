import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  GLOBAL_DATE_FORMAT,
  KTSVG
} from "../../../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage";
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title";
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core";
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce";

type Props = {
  className: string
}
type modalType = {
  startDateApi: string
  startDateView: string
  endDateApi: string
  endDateView: string
}
const JobApplications: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [jobApplicationsList, setJobApplicationsList] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(10)
  const [openingOffSet, setOpeningOffSet] = useState<any>('')
  const [searchSelect, setSearchSelect] = useState('')
  const [currentOpenings, setCurrentOpenings] = useState<any>([])
  const [currentOpeningsId, setCurrentOpeningsId] = useState<any>('')
  const [dateRange, setDateRange] = useState<modalType>({
    startDateApi: '',
    // startDateView: moment(Date.now()).format('DD MMM YYYY'),
    startDateView: 'Select',
    endDateApi: '',
    endDateView: '',
  })
  const [initialSettings, setInitialSettings] = useState({
    startDate: moment(),
    endDate: moment(),
    autoUpdateInput: false,
    cancelClass: 'btn-secondary',
    drops: 'down',
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    },
    alwaysShowCalendars: true,
  })
  const [validation, setValidation] = useState<any>({
    dateRange: '',
    startDateView: '',
  })
  const [dateHandler, setDateHandler] = useState({
    // firstDate: moment(Date.now()).format('YYYY-MM-DD'),
    // lastDate: moment(Date.now()).format('YYYY-MM-DD'),
    firstDate: '',
    lastDate: '',
  })

  const debounceSearch = useDebounce(search, 500);

  const cmsApplications: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Career',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    setTimeout(() => fetchJobApplication().then(), timer)
  }, [
    currentOffset,
    debounceSearch,
    count,
    jobApplicationsList.length,
    currentOpeningsId,
    dateHandler.firstDate,
    dateHandler.lastDate,
  ])

  const modalValueHandler = (e: any, checkBox: any) => {
    setDateRange((preValue: any) => ({
      ...preValue,
      startDateApi: e.startDateApi,
      endDateApi: e.endDateApi,
      startDateView: e.startDateView,
      endDateView: e.endDateView,
    }))

    setDateHandler({firstDate: e.startDateApi, lastDate: e.endDateApi})
  }

  const fetchJobApplication = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}career/Application/get?&limit=${limit}&search=${debounceSearch}&offset=${currentOffset}&currentopening_id=${currentOpeningsId}&created_at_after=${
      dateHandler.firstDate
    }&created_at_before=${dateHandler.lastDate}`
    const response = await getRequest(API, true)
    setJobApplicationsList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChangeSearch = (e: any) => {
    let inputValue = e.target ? e.target.value : e.value
    setSearch(inputValue)
  }

  const fetchCurrentOpenings = async (search?: any) => {
    const response = await fetchAsyncDropdown(
      openingOffSet,
      setOpeningOffSet,
      currentOpenings,
      setCurrentOpenings,
      `career/CurrentOpenings/get?limit=${limit}&search=${search}&offset=${openingOffSet}`,
      search,
      setSearchSelect,
      'title',
      true,
      'All Current Opening'
    )
    response.options.push({
      label: 'General Application',
      value: 0,
    })
    return response
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'currentOpenings':
        setCurrentOpeningsId(inputValue)
        break
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const CustomDateRangePicker = () => {
    return (
      <DateRangePicker
        initialSettings={initialSettings}
        onEvent={(e) => {
          e.preventDefault()
        }}
        onCallback={(start, end, label) => {
          console.log('date', start._d)

          setInitialSettings({
            startDate: start._d,
            endDate: end._d,
            cancelClass: 'btn-secondary',
            drops: 'down',
            autoUpdateInput: false,
            locale: {
              format: GLOBAL_DATE_FORMAT,
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
            alwaysShowCalendars: true,
          })

          let newStartDate = dateFormat(start._d)
          let newEndDate = dateFormat(end._d)

          let startDate = start._d
          let endDate = end._d

          let dateObj = {
            startDateView: newStartDate.displayDate,
            endDateView: newEndDate.displayDate,

            startDateApi: newStartDate.apiDate,
            endDateApi: newEndDate.apiDate,
          }
          modalValueHandler(dateObj, '')
        }}
        onCancel={(event: any, picker: any) => {
          setDateRange((preValue: any) => ({
            ...preValue,
            startDateView: 'Select',
          }))
        }}
      >
        <input
          id='datePickerFocus'
          type='text'
          onFocus={() => {
            // $('.daterangepicker').css('display', 'block')
          }}
          className={clsx('form-control calendar', validation.startDateView)}
          readOnly
          value={
            dateRange.startDateView === 'Select'
              ? 'Select'
              : dateRange.startDateView + ' - ' + dateRange.endDateView
          }
          // value={dateRange.startDateView + ' - ' + dateRange.endDateView}
        />
      </DateRangePicker>
    )
  }

  const redirectPage = (item: any) => {
    navigate('application-details')
    localStorage.setItem('applicationId', item.id)
  }

  return (
    <>
      <EATitle title='Job Applications - ' />
      <div>
        <PageTitle breadcrumbs={cmsApplications}>
          {intl.formatMessage({id: 'Job Applications'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('CMS_BLOG_AUTHORS.ADD')
              : messageHandler === 'successEdit'
              ? FormatMessage('CMS_BLOG_AUTHORS.UPDATE')
              : messageHandler === 'successDelete' && FormatMessage('CMS_BLOG_AUTHORS.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} />
              <div className='col-auto ms-auto'>
                {/* <div className='card-toolbar d-flex my-0'> */}
                <form
                  className='card-toolbar d-flex my-0'
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete='off'
                >
                  <AsyncPaginate
                    
                    loadOptions={fetchCurrentOpenings}
                    isSearchable
                    onChange={(e) => handleChange(e, 'currentOpenings')}
                    className='react-select-container my-1 me-3 w-180px'
                    classNamePrefix='react-select'
                    placeholder='Current Opening'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                  <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0 d-flex '>
                    <CustomDateRangePicker />
                  </div>
                </form>
                {/* </div> */}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {jobApplicationsList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px py-2'>Name</th>
                          <th className='min-w-100px py-2'>Email Address</th>
                          <th className='min-w-100px py-2'>Mobile No</th>
                          <th className='min-w-100px py-2'>Current Opening</th>
                          <th className='min-w-100px py-2'>Received Date</th>
                          <th className='min-w-80px action-th py-2'>Actions</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {jobApplicationsList.map((item: any) => {
                          return (
                            <tr>
                              <td onClick={() => redirectPage(item)}>
                                {item.firstname} {item.lastname}
                              </td>
                              <td onClick={() => redirectPage(item)}>{item.email}</td>
                              <td onClick={() => redirectPage(item)}>{item.phone_no}</td>
                              <td onClick={() => redirectPage(item)}>
                                {item.currentopenings_id !== null
                                  ? item.currentopenings_id.title
                                  : 'General Application'}
                              </td>
                              <td onClick={() => redirectPage(item)}>
                                {dateFormat(item.created_at).displayDate}{' '}
                                {dateFormat(item.created_at, 'LT').displayDate}
                              </td>
                              <td className=' text-end' onClick={() => redirectPage(item)}>
                                <span className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                  <KTSVG path='/media/icons/duotune/arrows/arr007.svg' />
                                </span>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${jobApplicationsList.length == 0 ? 0 : selected * 10 + 1} to ${
                jobApplicationsList.length == 0
                  ? 0
                  : jobApplicationsList.length < 10
                  ? count
                  : (selected + 1) * 10
              } of ${jobApplicationsList.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                count={jobApplicationsList.length == 0 ? 0 : count}
                selected={selected}
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JobApplications
