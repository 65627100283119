import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function OOAsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/products'
        icon='/media/icons/duotune/general/gen017.svg'
        title='Product SKUs'
      />
      <AsideMenuItem
        to='/current-stock'
        icon='/media/icons/duotune/arrows/arr056.svg'
        title='Current Stock'
      />
      <AsideMenuItem
        to='/sales-invoices'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
        title='Sales Invoices'
      />
      <AsideMenuItem
        to='/load-out-requests'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
        title='Load-Out Requests'
      />
      <AsideMenuItem
        to='/all-complaints'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Complaints'
      />
      <AsideMenuItem
        to='/containers'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
        title='Containers'
      />

      <AsideMenuItemWithSub
        to='/reports'
        title='Reports'
        icon='/media/icons/duotune/graphs/gra006.svg'
      >
        <AsideMenuItem
          to='/reports/banana-offloading-report'
          title='Banana Offloading Report'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/daily-stock-in-report'
          title='Daily Stock-In Report'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/daily-stock-out-report'
          title='Daily Stock-Out Report'
          hasBullet={true}
        />
        <AsideMenuItem to='/reports/gatepass-report' title='Gatepass Report' hasBullet={true} />
        <AsideMenuItem
          to='/reports/temperature-humidity-report'
          title='Temperature & Humidity Report Readings'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/temperature-humidity-alert-report'
          title='Temperature & Humidity Alerts'
          hasBullet={true}
        />
        {/*<AsideMenuItem*/}
        {/*  to='/reports/reconciliation-report'*/}
        {/*  title='Reconciliation Report'*/}
        {/*  hasBullet={true}*/}
        {/*/>*/}
        <AsideMenuItem to='/reports/low-stock-report' title='Low-Stock Report' hasBullet={true} />
        <AsideMenuItem to='/reports/expired-products' title='Expired Products' hasBullet={true} />
        {/*<AsideMenuItem to='/reports/returned-products' title='Retured Products' hasBullet={true} />*/}
        <AsideMenuItem
          to='/reports/manual-stock-change-report'
          title='Manual Stock Change'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/sales-invoice-report'
          title='Sales Invoice Report'
          hasBullet={true}
        />
        {/* <AsideMenuItem
          to='/reports/stock-transactions'
          title='Stock Transactions'
          hasBullet={true}
        /> */}
      </AsideMenuItemWithSub>
    </>
  )
}
