import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {connect} from 'react-redux'
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  WareHouseDelete,
  WareHouseSingle,
  WareHouseUpdate,
} from '../../../../../_eaFruitsDms/apiFunctions/wareHouse/warehouse'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  fetchAsyncDropdown,
  focusVatidateField,
  isEmpty,
  isModalValidation,
  isValidLatitude,
  isValidLongitude,
  KTSVG,
  toAbsoluteUrl,
} from '../../../../../_eaFruitsDms/helpers'
import capitalizeFirstLetter from '../../../../../_eaFruitsDms/helpers/components/CapitalLetter'
import FormatMessage from '../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import ValidationHandler from '../../../../../_eaFruitsDms/helpers/components/validationHandler'
import {getRequest, patchRequest, postRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import ErrorHandler from '../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../../../_eaFruitsDms/layout/components/loader/loader'
import MobileNumberInput from '../../../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput'
import EaModal from '../../../../../_eaFruitsDms/layout/components/modal/eaModal'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import {validateEmail} from '../../../../../_eaFruitsDms/validations/eaValidation'
import AreaCoverage from './components/areaCoverage'
import Customers from './components/customers'
import Stock from './components/stock'
import Targets from './components/targets'
import Team from './components/team'
import Vehicles from './components/vehicles'
import {WarehouseHeader} from './components/warehouseHeader'
import WarehouseOverview from './components/warehouseOverview'
import BillingInfo from './components/billingInfo'
import {Modal} from 'react-bootstrap'
import Imagecropper from '../../../brands/components/imageCroper'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import PaymentIssues from '../../../paymentIssues/paymentIssues'
import PreOrders from '../../../preOrder/preOrders'
import SalesInvoiceDetail from '../../../salesInvoices/components/salesInvoiceDetail'
import CreditIssues from '../../../creditIssues/creditIssues'
import CreditIssuesDetail from '../../../creditIssues/components/creditIssuesDetail'
import SalesInvoices from '../../../salesInvoices/salesInvoices'
import SupportRequestNewMessage from '../../../supportRequest/newMessage'
import IncentiveComponents from './components/incentiveComponents'
import tenantConfiguration from '../../../../../TenantVariables'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import MnadaLocation from './components/mnadalocation'
import Complaints from '../../../complaints/complaints'

type Props = {
  className?: string
  props?: any
}

const SingleWareHouse = (props: any) => {
  let ID = localStorage.getItem('wId')
  const location = useLocation()
  const state: any = location.state

  const initBillingInfo = {
    business_name: '',
    full_address: '',
    primary_contact: '',
    secondary_contact: '',
    support_contact: '',
    website_url: '',
    warehouse: ID,
    business_logo: '',
  }

  const initBillingInfoValidation = {
    business_name: '',
    full_address: '',
    primary_contact: '',
    secondary_contact: '',
    support_contact: '',
    website_url: '',
    business_logo: '',
  }
  const initialImage = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')

  const [WarehouseName, setWarehouseName] = useState('Warehouse Name')
  const [warehouseManager, setWarehouseManager] = useState([])
  const [id, seID] = useState(ID)
  const [warehouseId, setWarehouseId] = useState<any>('')
  const [WarehouseType, setWarehouseType] = useState('')
  const [WarehouseRegion, setWarehouseRegion] = useState('')
  const [WarehouseOverView, setWarehouseOverView] = useState<any>('')
  const [WarehouseArea, setWarehouseArea] = useState<any>('')
  const [getWardModal, setWardModal] = useState<any>([])
  const [btnName, setBtn] = useState('Submit')
  const [getParent, setParent] = useState<any>([])
  const [getRegion, setRegion] = useState<any>([])
  const [getWard, setWard] = useState<any>([])
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [warehouseName, setWarehouseNameEdit] = useState('')
  const [warehouseTypeEdit, setWarehouseTypeEdit] = useState('')
  const [parentwarehouse, setparentWarehouse] = useState('')
  const [regionwarehouse, setregionWarehouse] = useState('')
  const [wardwarehouse, setwardWarehouse] = useState<any>('')
  const [waddress, setWAddress] = useState('')
  const [wpostcode, setWPostcode] = useState('')
  const [wManualLatitude, setWManualLatitude] = useState('')
  const [wManualLongitude, setWManualLongitude] = useState('')
  const [wward, setWward] = useState('')
  const [wGmFirstname, setGmFirstname] = useState('')
  const [wGmLastname, setGmLastname] = useState('')
  const [wphoneNumber, setPhoneNumber] = useState('')
  const [wemail, setEmail] = useState('')
  const [warehouseNameValidation, setWarehouseNameValidation] = useState('')
  const [warehouseTypeValidation, setWarehouseTypeValidation] = useState('')
  const [parentwarehouseValidation, setparentWarehouseValidation] = useState('')
  const [regionwarehouseValidation, setregionWarehouseValidation] = useState('')
  const [wardwarehouseValidation, setwardWarehouseValidation] = useState('')
  const [wwardValidation, setWwValidation] = useState('')
  const [waddressValidation, setWAddressValidation] = useState('')
  const [wpostcodeValidation, setWPostcodeValidation] = useState('')
  const [wlatitudeValidation, setWLatitudeValidation] = useState('')
  const [wlongitudeValidation, setWLongitudeValidation] = useState('')
  const [wGmFirstnameValidation, setGmFirstnameValidation] = useState('')
  const [wGmLastnameValidation, setGmLastnameValidation] = useState('')
  const [wphoneNumberValidation, setPhoneNumberValidation] = useState('')
  const [wemailValidation, setEmailValidation] = useState('')
  const [selectWard, setSWard] = useState<any>('')
  const [headquarterChecked, setHeadquarterChecked] = useState(false)
  const [startDate, setstartDate] = useState('')
  const [parentSelectV, setParentSelectValidation] = useState(false)
  const [validationEmail, setValidationEmail] = useState(false)
  const [chekValue, setChekValue] = useState(true)
  const [wardsID, setWID] = useState<any>('')
  const [getWardsDist, setWardDist] = useState<any>([])
  const [KMLfiles, setKMLfiles] = useState<any>([])
  const [checkRegion, setCheckRegion] = useState<any>(false)
  const [shoGmDetails, setShowGmDetails] = useState<any>(false)
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [parentValue, setParentValue] = useState<any>('')
  const [headquarterValue, setHeadquarterValue] = useState<any>('')
  const [wardsValue, setWardsValue] = useState<any>('')
  const [billingInfo, setBillingInfo] = useState(initBillingInfo)
  const [billingInfoValidation, setBillingInfoValidation] = useState(initBillingInfoValidation)
  const [billingButtonText, setBillingButtonText] = useState<string>('Submit')
  const [billingButtonDisabled, setBillingButtonDisabled] = useState<boolean>(false)
  const [billingInfoAdded, setBillingInfoAdded] = useState<boolean>(true)
  const [cropData, setCropData] = useState(initialImage)
  const [imgSizeInvalid, setImgSizeInvalid] = useState('')
  const [imgRequired, setImgRequired] = useState(false)

  // incentive add
  const [incentiveAdded, setIncentiveAdded] = useState<boolean>(true)
  const [incentiveValue, setIncentiveValue] = useState(-1)

  // Mnada location add
  const [mnadaAdded, setMnadaAdded] = useState<boolean>(true)
  const [isMnadaAdded, setIsMnadaAdded] = useState<boolean>(false)

  // if needed in future
  const [showOverview, setShowOverview] = useState<any>(false)
  const [showArea, setShowArea] = useState<any>(false)
  const [showTeam, setShowTeam] = useState<any>(false)
  const [showSales, setShowSales] = useState<any>(false)
  const [showTargets, setShowTargets] = useState<any>(false)
  const [showStock, setShowStock] = useState<any>(false)
  const [showCustomers, setShowCustomers] = useState<any>(false)
  const [showCredit, setShowCredit] = useState<any>(false)
  const [showVehicles, setShowVehicles] = useState<any>(false)
  const [mapDetails, setMapDetails] = useState<any>('')
  const [impersonate, setImpersonate] = useState<any>(false)
  const [isRoleLoading, setIsRoleLoading] = useState<any>(true)
  const [messageHandler, setMessageHandler] = useState('successEdit')
  const [showBillingInfo, setShowBillingInfo] = useState(false)
  const [taxLabels, setTaxLabels] = useState<any>([])
  const [taxInfo, setTaxInfo] = useState<any>([])
  const [taxLabelLoading, setTaxLabelLoading] = useState<boolean>(false)

  const [deletedItem, setDeletedItem] = useState<any>('')
  const [modalLoader, setModalLoader] = useState<any>(false)

  const intl = useIntl()
  const wareHouseHeaderTitle = {
    title: 'Warehouse',
    subTitle: WarehouseName,
  }
  const navigate = useNavigate()

  // default value for react-select
  let optgroup = [
    {
      label: '',
      options: [{label: '', value: ''}],
    },
  ]
  let optionData: any = []

  const [wardDistrict, setWardDistrict] = useState([])
  const [permission, setPermission] = useState(defaultPermission)

  const [incentiveSingle, setIncentiveSingle] = useState<any>({})
  const [error, setError] = useState('')
  const [incentiveModal, setIncentiveModal] = useState(false)
  const [buttonHandler, setButtonHandler] = useState('Submit')
  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [fetching, setFetching] = useState(true)
  const [isWarehouseManager, setIsWarehouseManager] = useState(true)
  const [type, setType] = useState('')
  const [srFirstRole, setSrFirstRole] = useState({label: '', value: ''})

  const fetchingRecords = async () => {
    setFetching(true)

    const srUrl = `${apiBaseURL()}auth/application/role/list/?ordering=name&user_type__in=sr`

    const srResponse = await getRequest(srUrl, true)

    if (srResponse?.results && srResponse?.results?.length > 0) {
      const data = srResponse?.results[0]

      setSrFirstRole({label: data?.name ?? '', value: data?.id ?? ''})

      const firstSrId = data?.id ?? ''

      const url =
        apiBaseURL() +
        'master/kpi/settings/' +
        id +
        `/${firstSrId}` +
        '/incentive-components?show_future=false'

      const response = await getRequest(url, true)

      if (response && response.result) {
        setIncentiveSingle(response.result)
      }

      setFetching(false)
    }
  }

  const onChangeInput = (e: any, isPercentage?: boolean) => {
    let incentive: any = {...incentiveSingle}
    const value = parseFloat(e.target.value)

    if (isPercentage) {
      if (isNaN(value) || value < 0 || value > 100) {
        incentive[`${e.target.name}_valid`] = 'is-invalid'
      } else {
        incentive[`${e.target.name}_valid`] = ''
      }
      incentive[e.target.name] = e.target.value
    } else {
      const re = /^[0-9\b]+$/
      if (e.target.value === '' || re.test(e.target.value)) {
        incentive[e.target.name] = e.target.value
        if (e.target.value === '') {
          incentive[`${e.target.name}_valid`] = 'is-invalid'
        } else {
          incentive[`${e.target.name}_valid`] = ''
        }
      }
    }

    setIncentiveSingle(incentive)
  }

  const checkValid = () => {
    let isValid = true
    let incentive = {...incentiveSingle}

    if (isEmpty(incentive.fixed_incentive_defined) || incentive.fixed_incentive_defined === 0) {
      incentive.fixed_incentive_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.fixed_incentive_defined_valid = ''
    }

    if (isEmpty(incentive.revenue_incentive_defined) || incentive.revenue_incentive_defined === 0) {
      incentive.revenue_incentive_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.revenue_incentive_defined_valid = ''
    }

    if (
      isEmpty(incentive.revenue_incentive_atleast_defined) ||
      incentive.revenue_incentive_atleast_defined === 0
    ) {
      incentive.revenue_incentive_atleast_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.revenue_incentive_atleast_defined_valid = ''
    }

    if (
      !checkPercentage(incentive.bonus_incentive_perc_defined) ||
      incentive.bonus_incentive_perc_defined === 0
    ) {
      incentive.bonus_incentive_perc_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.bonus_incentive_perc_defined_valid = ''
    }

    if (
      !checkPercentage(incentive.discount_prec_atmost_defined) ||
      incentive.discount_prec_atmost_defined === 0
    ) {
      incentive.discount_prec_atmost_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.discount_prec_atmost_defined_valid = ''
    }

    if (
      !checkPercentage(incentive.achieved_perc_atleast_defined) ||
      incentive.achieved_perc_atleast_defined === 0
    ) {
      incentive.achieved_perc_atleast_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.achieved_perc_atleast_defined_valid = ''
    }

    setIncentiveSingle(incentive)

    return isValid
  }

  const updateIncentiveComponent = async () => {
    if (checkValid()) {
      setButtonHandler('Please Wait')
      const url =
        apiBaseURL() +
        'master/kpi/settings/' +
        id +
        `/${srFirstRole?.value}` +
        '/incentive-components/update'

      const body = {
        fixed_incentive_defined: incentiveSingle.fixed_incentive_defined,
        revenue_incentive_defined: incentiveSingle.revenue_incentive_defined,
        bonus_incentive_perc_defined: incentiveSingle.bonus_incentive_perc_defined,
        revenue_incentive_atleast_defined: incentiveSingle.revenue_incentive_atleast_defined,
        discount_prec_atmost_defined: incentiveSingle.discount_prec_atmost_defined,
        achieved_perc_atleast_defined: incentiveSingle.achieved_perc_atleast_defined,
        is_applicable_from_next_month: false,
      }

      const response = await patchRequest(url, body, true)

      if (response.status === 200) {
        setIsAlert(true)
        setMessage('Incentive Component updated successfully.')
        setIncentiveModal(false) // modal close
        setIncentiveAdded(true)
        isClear()
        navigate('/warehouse/DC/singlewarehousename/incentive-components', {
          state: {
            timeStrap: true,
          },
        })
      }

      if (response.status === 400) {
        setButtonHandler('Submit')
        setError('')
      }
    }
  }

  const checkPercentage = (num: any) => {
    const value = parseFloat(num)
    let valid = true
    if (isNaN(value) || value < 0 || value > 100) {
      valid = false
    }

    return valid
  }

  const isClear = () => {
    setIncentiveSingle({})
    setButtonHandler('Submit')
    setTimeout(() => {
      setIsAlert(false)
    }, 2000)
    setError('')
  }

  const handleCloseBillingInfo = () => {
    setBillingInfo(initBillingInfo)
    setTaxInfo([])
    setTaxLabels([])
    setBillingButtonText('Submit')
    setCropData(initialImage)
    setShowBillingInfo(false)
  }
  const handleShowBillingInfo = async () => {
    setTaxLabelLoading(true)
    await fetchTaxLabels()
    setTaxLabelLoading(false)
    setShowBillingInfo(true)
  }

  const handleIncentiveComponents = async () => {
    // await fetchingRecords()
    // setIncentiveModal(true)
    navigate('/warehouse/DC/singlewarehousename/incentive-components')
  }

  const handleMnadaLocation = async () => {
    navigate('/warehouse/DC/singlewarehousename/mnada-location')

    setTimeout(() => {
      setIsMnadaAdded(true)
    }, 200)
  }

  const fetchTaxLabels = async () => {
    const response = await getRequest(`${apiBaseURL()}master/tax-labels`, true)

    if (response) {
      setTaxLabels(response.results)
    }
  }

  useEffect(() => {
    if (state && state.billingRequired) {
      fetchTaxLabels().then(() => {
        setShowBillingInfo(true)
      })
    }
    fetchPermission()
    getRequest(`${apiBaseURL()}master/district_ward/list/?&ordering=name` + limit, true).then(
      (res) => {
        setWardDistrict(res.results)
      }
    )
    if (id !== '') {
      getSingleWarehouse()
      getParentList()
      getWards()
      getRegions()
    }
  }, [])

  const fetchPermission = async () => {
    let res: any
    res = await checkPermissions('warehouse_and_dc')
    setPermission(res)

    res = await checkPermissions('warehouse_impersonation')
    if (res && res.read) {
      setImpersonate(true)
    }

    // if (element.screenName == 'Warehouse > Login to Warehouse') {
    //   let getWrite = element.roles.length > 0 && element.roles[0].write
    //   let getDelete = element.roles.length > 0 && element.roles[0].delete
    // }

    res = await checkPermissions('warehouse_overview')
    if (res.read && res.read) {
      setShowOverview(true)
    }

    res = await checkPermissions('warehouse_area_coverage')
    if (res.read && res.read) {
      setShowArea(true)
    }

    res = await checkPermissions('warehouse_team')
    if (res.read && res.read) {
      setShowTeam(true)
    }

    res = await checkPermissions('warehouse_sales_orders')
    if (res.read && res.read) {
      setShowSales(true)
    }

    res = await checkPermissions('warehouse_targets')
    if (res.read && res.read) {
      setShowTargets(true)
    }

    res = await checkPermissions('warehouse_stocks')
    if (res.read && res.read) {
      setShowStock(true)
    }

    res = await checkPermissions('warehouse_customers')
    if (res.read && res.read) {
      setShowCustomers(true)
    }

    res = await checkPermissions('warehouse_credit_issues')
    if (res.read && res.read) {
      setShowCredit(true)
    }

    res = await checkPermissions('warehouse_vehicles')
    if (res.read && res.read) {
      setShowVehicles(true)
    }
  }

  const goToMap = () => {
    if (!isEmpty(wManualLatitude) && !isEmpty(wManualLongitude)) {
      window.open(
        'https://maps.google.com/maps?q=' + wManualLatitude + ',' + wManualLongitude,
        '_blank'
      )
    }
  }

  const limit = '&limit=1000'

  //request method for api implementation :- last change by kriti
  const getParentList = () => {
    // get parent WH list
    getRequest(`${apiBaseURL()}warehouse/list?ordering=name` + limit, true).then((res) =>
      setParent(res.results)
    )
  }

  const getKmlUrl = (kmlFullUrl: any, kmlBaseUrl: any) => {
    let kmlUrl = null
    if (kmlFullUrl) {
      if (kmlFullUrl.includes('http')) {
        kmlUrl = kmlFullUrl
      } else {
        kmlUrl = kmlBaseUrl + kmlFullUrl
      }
    }
    return kmlUrl
  }

  const getWards = () => {
    // if needed in future
  }

  const getRegions = () => {
    // get regions list
    // axios.get(`${apiBaseURL()}master/region/list/?ordering=name`, header).then((res) => setRegion(res.data))
    getRequest(`${apiBaseURL()}master/region_warehouse/list/?ordering=name` + limit, true).then(
      (res) => {
        setRegion(res.results)
      }
    )
  }

  const regionFromParentDetails = (val: any, wardsfromParent: any) => {
    setModalLoader(true)

    WareHouseSingle(val)
      .then((res: any) => {
        let totalWards = wardsfromParent
        let masterData = res.region !== null ? res.region.district : []
        let WardsList: any = []
        let wardsId = res.wards.results
        let kmlFiles: any = []

        masterData.forEach((element: any) => {
          let obj: any = {}
          obj = element.name
          let obj2: any = []
          // kmlfiles
          let disKml: any = {}
          let wardKml: any = []
          disKml = element.kml_file !== null ? element.kml_file : ''
          element.ward.forEach((element: any) => {
            if (totalWards.includes(element.id)) {
              obj2.push(element.name)
              wardKml.push(
                element.kml_file !== null ? getKmlUrl(element.kml_file, element.base_url) : ''
              )
            }
          })
          let obj3 = {
            dist: obj,
            wards: obj2,
          }
          let distWardKmlFiles = {
            distKml: disKml,
            wardsKml: wardKml,
          }
          WardsList.push(obj3)
          kmlFiles.push(distWardKmlFiles)
        })
        setWardDist(WardsList)
        let filterKmlFiles: any = []
        res.region !== null && filterKmlFiles.push(res.region.kml_file)
        kmlFiles.length !== 0 &&
          kmlFiles.forEach((element: any) => {
            // element.distKml !== "" && filterKmlFiles.push(serverKmlUrl + element.distKml)
            element.wardsKml.forEach((elementDis: any) => {
              // getKmlUrl(elementDis , "baseUrl")
              // elementDis !== "" && filterKmlFiles.push(serverKmlUrl + elementDis)
              elementDis !== '' && filterKmlFiles.push(elementDis)
            })
          })
        setKMLfiles(filterKmlFiles)
        setWarehouseArea({Warehouse_Boundary_Region: res.region !== null ? res.region.name : '-'})

        let WardsArray = wardsfromParent
        let totalDist: any = []
        if (res.region !== null) {
          getRequest(
            `${apiBaseURL()}master/district_ward_filter/list/?region__id=${res.region.id}` + limit,
            true
          ).then((res) => {
            let data = res.results
            let districtName = ''
            let wards: any = []

            let optgroup: any = [
              {
                label: '',
                options: [{label: '', value: ''}],
              },
            ]
            let optionData: any = []
            let wardsList: any = []
            data.forEach((element: any) => {
              let disName = [{label: '-', value: '-'}]
              districtName = element.name
              let removeBlankSpace = false
              let nameOrdering: any = element.ward.sort((a: any, b: any) => {
                return a.name.localeCompare(b.name)
              })
              nameOrdering.forEach((dist: any) => {
                removeBlankSpace = true
                disName.push({label: dist.name, value: dist.id})
                wards.push(dist)
                totalDist.push(dist)
                wardsList.push({name: dist.name, id: dist.id})
              })
              if (removeBlankSpace) {
                disName.shift()
              }
              optionData.push({
                label: districtName,
                options: disName,
              })
            })

            let deletedWardId = WardsArray
            let selectedValue: any = []
            let selectedDis = ''

            let deletedValue: any = []

            wardDistrict.forEach((item: any) => {
              if (item.ward.length !== 0) {
                item.ward.forEach((ward: any) => {
                  if (deletedWardId.includes(ward.id)) {
                    selectedDis = item.name
                    selectedValue.push({
                      label: ward.name,
                      value: ward.id,
                    })
                  }
                })
              }
            })
            deletedValue.push({
              label: selectedDis,
              options: selectedValue,
            })

            optgroup = optionData.concat(deletedValue)

            let output: any = []
            optgroup.forEach((item: any) => {
              var existing = output.filter(function (v: any, i: any) {
                return v.label == item.label
              })
              if (existing.length) {
                var existingIndex = output.indexOf(existing[0])
                output[existingIndex].options = output[existingIndex].options.concat(item.options)
              } else {
                if (typeof item.options == 'string') item.options = [item.options]
                output.push(item)
              }
            })

            setWard(output)

            let nameOrdering: any = wards.sort((a: any, b: any) => {
              return a.name.localeCompare(b.name)
            })
          })
          //request method for api implementation :- last change by kriti
          getRequest(
            `${apiBaseURL()}master/district_ward/list/?region__id=${res.region.id}` + limit,
            true
          ).then((res) => {
            let data = res.results
            let districtName = ''
            let wards: any = []
            let wardsSingle: any = []

            data.forEach((element: any) => {
              let disName = [{label: '-', value: '-'}]
              districtName = element.name
              let removeBlankSpace = false

              element.ward.forEach((res: any) => {
                // if (wardsId.includes(res.id)) {
                wardsSingle.push({
                  label: res.name,
                  value: res.id,
                })
                // }
              })

              let nameOrdering: any = element.ward.sort((a: any, b: any) => {
                return a.name.localeCompare(b.name)
              })
              nameOrdering.forEach((dist: any) => {
                removeBlankSpace = true
                disName.push({label: dist.name, value: dist.id})
                wards.push(dist)
                totalDist.push(dist)
              })
              if (removeBlankSpace) {
                disName.shift()
              }
              optionData.push({
                label: districtName,
                options: disName,
              })
            })
            optgroup = optionData
            // setWard(optgroup)
            let nameOrdering: any = wards.sort((a: any, b: any) => {
              return a.name.localeCompare(b.name)
            })

            setWardModal(wardsSingle)

            let testdata
            let getData: any = []
            WardsArray.forEach((element: any) => {
              testdata = totalDist.find((items: any) => {
                // console.log("total districts == ", items)
                // console.log("total wards array == ", WardsArray)
                return items.id === element
              })
              getData.push(testdata)
            })
            let s_options: any = []
            let ward: any = []
            getData.forEach((element: any) => {
              s_options.push({label: element.name, value: element.id})
              ward.push({name: element.name, id: element.id})
            })
            setwardWarehouse(s_options)
          })
        }
        setIsRoleLoading(false)

        setModalLoader(false)
      })
      .catch(() => {
        setModalLoader(false)
      })
  }
  const getSingleWarehouse = () => {
    setMessageHandler('successEdit')
    setModalLoader(true)
    WareHouseSingle(id).then((res: any) => {
      res?.warehouse_type && setType(res?.warehouse_type)

      setIsWarehouseManager(res?.warehouse_manager === null ? false : true)

      if (!res.mnada_location_exists) {
        setMnadaAdded(false)
      }

      if (
        res.warehouse_kpi_incentive_component_settings &&
        Object.keys(res.warehouse_kpi_incentive_component_settings).length > 0
      ) {
        let incentiveKeys = res.warehouse_kpi_incentive_component_settings
        let checkValid = 0

        for (const key in incentiveKeys) {
          if (key !== 'id') {
            checkValid += parseFloat(incentiveKeys[key])
          }
        }

        if (checkValid === 0) {
          setIncentiveAdded(false)
        }

        setIncentiveValue(checkValid)
      }

      if (res.warehouse_billing.length === 0) {
        setBillingInfoAdded(false)
      }
      let mapDetails: any = {
        kml_file: res.region && res.region.kml_file && res.region.kml_file,
        latitude: res.manual_latitude,
        longitude: res.manual_longitude,
      }

      setMapDetails(mapDetails)

      if (res.warehouse_type !== 'DistributionCenter') {
        setShowGmDetails(true)
      } else {
        setShowGmDetails(false)
      }
      let wardId = ''
      if (res.wards_id !== null) {
        wardId = res.wards_id
      } else {
        wardId = ''
      }

      //request method for api implementation :- last change by kriti
      getRequest(`${apiBaseURL()}master/wards/list/?ordering=name` + limit, true).then((res) => {
        let data = res.results
        let selectWards = ''
        selectWards = data.find((item: any) => {
          return item.id === wardId
        })
        setSWard(selectWards)
      })
      setWarehouseManager(res.warehouse_manager)
      setWID(res.wards)
      setWarehouseName(res.name)
      setWarehouseType(res.warehouse_type)
      if (res.warehouse_type == 'DistributionCenter') {
        setHeadquarterChecked(false)
      } else {
        setHeadquarterChecked(true)
      }
      setWarehouseRegion(res.region !== null ? res.region.name : '-')
      setstartDate(res.startDate)
      let warehouseManager = res.warehouse_manager
      let name = ''
      let lname = ''
      let fname = ''
      let email = ''
      let phone = ''
      let waId = ''
      if (warehouseManager != null) {
        lname = warehouseManager.last_name
        fname = warehouseManager.first_name
        name = warehouseManager.first_name + ' ' + warehouseManager.last_name
        email = warehouseManager.email
        phone = warehouseManager.phone_no
        waId = warehouseManager.id
      }
      if (waId === '') {
        setWarehouseId(0)
      } else {
        setWarehouseId(waId)
      }
      setWarehouseOverView({
        Warehouse_Type: res.warehouse_type,
        Warehouse_Address: res.address,
        General_Manager: name,
        Phone_Number: phone,
        Email: email,
        Verified_Status: res.is_user_verified,
      })
      setWarehouseArea({
        Warehouse_Boundary_Region: res.region !== null ? res.region.name : '-',
        Warehouse_Boundary_Districts:
          res.region !== null && res.region.district.length > 0 ? res.region.district[0].name : '-',
        // Warehouse_Boundary_Wards: res.region !== null ? res.region.district[0].ward[0].name : '-',
      })

      // modal details
      setWarehouseNameEdit(res.name)
      setparentWarehouse(res.parent_warehouse)
      res.parent_warehouse !== null &&
        setParentValue({
          label: res.parent_warehouse_name,
          value: res.parent_warehouse,
        })
      setWarehouseTypeEdit(res.warehouse_type)
      setregionWarehouse(res.region !== null ? res.region.id : '')
      res.region !== null &&
        setHeadquarterValue({
          label: res.region.name,
          value: res.region.id,
        })
      setWardsValue({
        label: res.wards_id_name,
        value: res.wards_id,
      })
      setWward(res.wards_id)
      setWAddress(res.address)
      setWPostcode(res.post_code)
      setWManualLatitude(res.manual_latitude)
      setWManualLongitude(res.manual_longitude)
      setGmFirstname(fname)
      setGmLastname(lname)
      setPhoneNumber(phone)
      setEmail(email)
      setModalLoader(false)

      let totalWards = res.wards
      if (res.region !== null) {
        setModalLoader(true)
        let selectedRegionId = res.region.id
        let selectedRegionName = res.region.name

        setRegion((preValue: any) => [
          ...preValue,
          {name: selectedRegionName, id: selectedRegionId},
        ])
        setCheckRegion(true)
        let masterData = res.region !== null ? res.region.district : []
        let WardsList: any = []
        let kmlFiles: any = []
        masterData.forEach((element: any) => {
          let obj: any = {}
          obj = element.name
          let obj2: any = []
          // kmlfiles
          let disKml: any = {}
          let wardKml: any = []
          disKml = element.kml_file !== null ? element.kml_file : ''

          element.ward.forEach((element: any) => {
            if (totalWards.includes(element.id)) {
              obj2.push(element.name)
              wardKml.push(
                element.kml_file !== null ? getKmlUrl(element.kml_file, element.base_url) : ''
              )
            }
          })
          let obj3 = {
            dist: obj,
            wards: obj2,
          }

          let distWardKmlFiles = {
            distKml: disKml,
            wardsKml: wardKml,
          }
          WardsList.push(obj3)
          kmlFiles.push(distWardKmlFiles)
        })
        setWardDist(WardsList)
        let filterKmlFiles: any = []
        res.region !== null && filterKmlFiles.push(res.region.kml_file)
        kmlFiles.length !== 0 &&
          kmlFiles.forEach((element: any) => {
            // element.distKml !== "" && filterKmlFiles.push(serverKmlUrl + element.distKml)
            element.wardsKml.forEach((elementDis: any) => {
              // getKmlUrl(elementDis , "baseUrl")
              // elementDis !== "" && filterKmlFiles.push(serverKmlUrl + elementDis)
              elementDis !== '' && filterKmlFiles.push(elementDis)
            })
          })
        setKMLfiles(filterKmlFiles)
        let WardsArray = res.wards
        let totalDist: any = []

        if (res.region !== null) {
          let testid = res.region.id
          getRequest(
            `${apiBaseURL()}master/district_ward_filter/list/?region__id=${res.region.id}` + limit,
            true
          ).then((res) => {
            let data = res.results
            let districtName = ''
            let wards: any = []

            let optgroup = [
              {
                label: '',
                options: [{label: '', value: ''}],
              },
            ]
            let optionData: any = []

            let wardsList: any = []
            data.forEach((element: any) => {
              let disName = [{label: '-', value: '-'}]
              districtName = element.name

              let removeBlankSpace = false
              let nameOrdering: any = element.ward.sort((a: any, b: any) => {
                return a.name.localeCompare(b.name)
              })

              nameOrdering.forEach((dist: any) => {
                removeBlankSpace = true
                disName.push({label: dist.name, value: dist.id})
                wards.push(dist)
                totalDist.push(dist)
                wardsList.push({name: dist.name, id: dist.id})
              })

              if (removeBlankSpace) {
                disName.shift()
              }
              optionData.push({
                label: districtName,
                options: disName,
              })
            })
            // optgroup = optionData

            let deletedWardId = WardsArray
            let selectedValue: any = []
            let selectedDis = ''

            let deletedValue: any = []

            wardDistrict.forEach((item: any) => {
              if (item.ward.length !== 0) {
                item.ward.forEach((ward: any) => {
                  if (deletedWardId.includes(ward.id)) {
                    selectedDis = item.name
                    selectedValue.push({
                      label: ward.name,
                      value: ward.id,
                    })
                  }
                })
              }
            })
            deletedValue.push({
              label: selectedDis,
              options: selectedValue,
            })

            optgroup = optionData.concat(deletedValue)
            let output: any = []
            optgroup.forEach((item: any) => {
              var existing = output.filter(function (v: any, i: any) {
                return v.label == item.label
              })
              if (existing.length) {
                var existingIndex = output.indexOf(existing[0])
                output[existingIndex].options = output[existingIndex].options.concat(item.options)
              } else {
                if (typeof item.options == 'string') item.options = [item.options]
                output.push(item)
              }
            })
            // console.log("output == ", output);
            setWard(output)

            let nameOrdering: any = wards.sort((a: any, b: any) => {
              return a.name.localeCompare(b.name)
            })
          })

          //request method for api implementation :- last change by kriti
          getRequest(
            `${apiBaseURL()}master/district_ward/list/?region__id=${res.region.id}` + limit,
            true
          ).then((res) => {
            let data = res.results
            let districtName = ''
            let wards: any = []
            data.forEach((element: any) => {
              let disName = [{label: '-', value: '-'}]
              districtName = element.name
              let removeBlankSpace = false
              let nameOrdering: any = element.ward.sort((a: any, b: any) => {
                return a.name.localeCompare(b.name)
              })
              nameOrdering.forEach((dist: any) => {
                removeBlankSpace = true
                disName.push({label: dist.name, value: dist.id})
                wards.push({label: dist.name, value: dist.id})
                totalDist.push(dist)
              })
              if (removeBlankSpace) {
                disName.shift()
              }
              optionData.push({
                label: districtName,
                options: disName,
              })
            })
            optgroup = optionData
            // setWard(optgroup)
            let nameOrdering: any = wards.sort((a: any, b: any) => {
              return a.label.localeCompare(b.label)
            })

            setWardModal(nameOrdering)
            let testdata
            let getData: any = []
            totalDist.length > 0 &&
              WardsArray.forEach((element: any) => {
                testdata = totalDist.find((items: any) => {
                  return items.id === element
                })
                if (testdata) {
                  getData.push(testdata)
                }
              })
            let ward: any = []
            let s_options: any = []
            getData.forEach((element: any) => {
              s_options.push({label: element.name, value: element.id})
              ward.push({name: element.name, id: element.id})
            })
            setwardWarehouse(s_options)
          })
        }

        setIsRoleLoading(false)
        setModalLoader(false)
      } else {
        setCheckRegion(false)
        regionFromParentDetails(res.parent_warehouse, res.wards)
      }
    })
  }

  const clearWareHouse = () => {
    // clear add modal state
    setErrorMessage('')
    setShowGmDetails(false)
    setWarehouseNameEdit('')
    setWarehouseTypeEdit('')
    setparentWarehouse('')
    setregionWarehouse('')
    setwardWarehouse('')
    setWAddress('')
    setWPostcode('')
    setWManualLatitude('')
    setWManualLongitude('')
    setWward('')
    setGmFirstname('')
    setGmLastname('')
    setPhoneNumber('')
    setEmail('')
    setWarehouseNameValidation('')
    setWarehouseTypeValidation('')
    setparentWarehouseValidation('')
    setregionWarehouseValidation('')
    setwardWarehouseValidation('')
    setWwValidation('')
    setWAddressValidation('')
    setWPostcodeValidation('')
    setWLatitudeValidation('')
    setWLongitudeValidation('')
    setGmFirstnameValidation('')
    setGmLastnameValidation('')
    setPhoneNumberValidation('')
    setEmailValidation('')
    setParentSelectValidation(false)
    setHeadquarterChecked(false)
    setValidationEmail(false)
    setChekValue(true)
    setBtn('Submit')
  }

  const editFunction = () => {
    let navigateField = {
      warehouseName: warehouseName,
      headquarterValue: headquarterValue,
      wardwarehouse: wardwarehouse.length === 0 ? '' : wardwarehouse,
      waddress: waddress,
      wardsValue: wardsValue,
      wGmFirstname: wGmFirstname,
      wGmLastname: wGmLastname,
      wphoneNumber: wphoneNumber,
      wemail: wemail,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (warehouseName == '') {
      setWarehouseNameValidation('is-invalid')
    } else {
      setWarehouseNameValidation('')
    }
    if (warehouseTypeEdit == '') {
      setWarehouseTypeValidation('is-invalid')
    } else {
      setWarehouseTypeValidation('')
    }
    if (!parentwarehouse) {
      setparentWarehouseValidation('is-invalid')
    } else {
      setparentWarehouseValidation('')
    }
    if (regionwarehouse == '') {
      setregionWarehouseValidation('is-invalid')
    } else {
      setregionWarehouseValidation('')
    }
    if (wardwarehouse == '') {
      setwardWarehouseValidation('is-invalid')
    } else {
      setwardWarehouseValidation('')
    }
    if (waddress == '') {
      setWAddressValidation('is-invalid')
    } else {
      setWAddressValidation('')
    }
    if (wward == '') {
      setWwValidation('is-invalid')
    } else {
      setWwValidation('')
    }
    if (wManualLatitude == '' || !isValidLatitude(wManualLatitude)) {
      setWLatitudeValidation('is-invalid')
    } else {
      setWLatitudeValidation('')
    }
    if (wManualLongitude == '' || !isValidLongitude(wManualLongitude)) {
      setWLongitudeValidation('is-invalid')
    } else {
      setWLongitudeValidation('')
    }
    // if (wpostcode == "") { setWPostcodeValidation('is-invalid') } else { setWPostcodeValidation('') }
    if (shoGmDetails) {
      if (wGmFirstname == '') {
        setGmFirstnameValidation('is-invalid')
      } else {
        setGmFirstnameValidation('')
      }
      if (wGmLastname == '') {
        setGmLastnameValidation('is-invalid')
      } else {
        setGmLastnameValidation('')
      }
      if (wphoneNumber == '') {
        setPhoneNumberValidation('is-invalid')
      } else {
        setPhoneNumberValidation('')
      }
      if (wemail == '') {
        setEmailValidation('is-invalid')
      } else {
        setEmailValidation('')
      }
    }

    if (warehouseName !== '') {
      if (warehouseTypeEdit !== '') {
        if (wardwarehouse !== '') {
          if (waddress !== '') {
            if (wward !== '') {
              if (wManualLatitude !== '' && isValidLatitude(wManualLatitude)) {
                if (wManualLongitude !== '' && isValidLongitude(wManualLongitude)) {
                  if (shoGmDetails) {
                    if (wGmFirstname !== '') {
                      if (wGmLastname !== '') {
                        if (wphoneNumber !== '') {
                          if (wemail !== '') {
                            if (validateEmail(wemail)) {
                              if (headquarterValue !== '') {
                                setDisableBtn(true)
                                setIsLoading('Please Wait..')

                                Headquarter()
                              }
                            }
                          }
                        }
                      }
                    }
                  } else {
                    setDisableBtn(true)
                    setIsLoading('Please Wait..')

                    Distribution()
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  const [errorMessage, setErrorMessage] = useState('')

  const Headquarter = () => {
    // add Headquarter
    let wardList: any = []
    let wardData: any = []
    wardwarehouse.forEach((element: any) => {
      wardList.push(element.value)
    })

    wardData = wardList
    setBtn('Please Wait..')
    WareHouseUpdate(
      isWarehouseManager,
      id,
      warehouseName,
      warehouseTypeEdit,
      parentwarehouse,
      waddress,
      wpostcode,
      wManualLatitude,
      wManualLongitude,
      regionwarehouse,
      wardData,
      warehouseId,
      wward,
      startDate,
      wGmFirstname,
      wGmLastname,
      wemail,
      wphoneNumber,
      null
    ).then((res) => {
      setDisableBtn(false)
      setIsLoading('Submit')
      if (res.success) {
        clearWareHouse()
        getSingleWarehouse()
        setIsAlertSuccess(true)
        $('#ea_modal_close_edit_warehouse').trigger('click') // modal close
      }
      if (res.failed) {
        // setIsAlertFailed(true)
      }

      if (res.errorField == 'name') {
        // setShowErrorMessage(true)
        setErrorMessage(
          'Warehouse Name is already exist in this system! Please try again with unique name.'
        )
      }

      if (res.errorField == 'email') {
        // setShowErrorMessage(true)
        setErrorMessage(
          'email is already exist in this system! Please try again with unique email.'
        )
      }
      if (res.errorField == 'lat_long') {
        setErrorMessage('Your enter address is not valid please try again with valid address.')
      }

      setTimeout(() => {
        setIsAlertSuccess(false)
        setIsAlertFailed(false)
      }, 2500)

      setBtn('Submit')
    })
  }

  const Distribution = () => {
    let wardList: any = []
    let wardData: any = []
    wardwarehouse.forEach((element: any) => {
      wardList.push(element.value)
    })
    wardData = wardList
    setBtn('Please Wait..')
    WareHouseUpdate(
      true,
      id,
      warehouseName,
      warehouseTypeEdit,
      parentwarehouse,
      waddress,
      wpostcode,
      wManualLatitude,
      wManualLongitude,
      regionwarehouse,
      wardData,
      warehouseId,
      wward,
      startDate
    ).then((res) => {
      setDisableBtn(false)
      setIsLoading('Submit')
      if (res.success) {
        clearWareHouse()
        getSingleWarehouse()
        setIsAlertSuccess(true)
        $('#ea_modal_close_edit_warehouse').trigger('click') // modal close
      }
      if (res.failed) {
        // setIsAlertFailed(true)
      }

      if (res.errorField == 'name') {
        // setShowErrorMessage(true)
        setErrorMessage(
          'Warehouse Name is already exist in this system! Please try again with unique name.'
        )
      }

      //   if (res.errorField == "email") {
      //     // setShowErrorMessage(true)
      //     setErrorMessage("email is already exist in this system! Please try again with unique email.")
      //   }

      setTimeout(() => {
        setIsAlertSuccess(false)
        setIsAlertFailed(false)
      }, 2500)

      setBtn('Submit')
    })
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val === 'success') {
      setIsAlertSuccess(false)
    }
    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }

  // get district_ward list
  const wardselect = (e: any) => {
    setWard([])
    setWardModal([])
    setwardWarehouse('')
    setWward('')
    setregionWarehouse(e.value)
    //request method for api implementation :- last change by kriti
    getRequest(
      `${apiBaseURL()}master/district_ward_filter/list/?region__id=${e.value}` + limit,
      true
    ).then((res) => {
      let data = res.results
      let districtName = ''
      let wards: any = []
      data.forEach((element: any) => {
        let disName = [{label: '-', value: '-'}]
        districtName = element.name
        let nameOrdering: any = element.ward.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name)
        })
        let removeBlankSpace = false
        nameOrdering.forEach((dist: any) => {
          removeBlankSpace = true
          disName.push({label: dist.name, value: dist.id})
          wards.push(dist)
        })
        if (removeBlankSpace) {
          disName.shift()
        }
        optionData.push({
          label: districtName,
          options: disName,
        })
      })
      optgroup = optionData
      setWard(optgroup)
      let nameOrdering: any = wards.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name)
      })
    })

    //request method for api implementation :- last change by kriti
    getRequest(
      `${apiBaseURL()}master/district_ward/list/?region__id=${e.value}&ordering=name` + limit,
      true
    ).then((res) => {
      let data = res.results
      let wards: any = []
      if (data.length !== 0) {
        data.forEach((element: any) => {
          element.ward.forEach((res: any) => {
            wards.push({
              label: res.name,
              value: res.id,
            })
          })
        })
      }
      setWardModal(wards)
    })
  }

  const deleteClick = () => {
    WareHouseDelete(id, warehouseId).then((res) => {
      if (res.success) {
        setIsAlertSuccess(true)
        setTimeout(() => {
          navigate('/warehouse/DC')
        }, 1200)
      }
      if (res.failed) {
        setIsAlertFailed(true)
      }
      setTimeout(() => {
        setIsAlertSuccess(false)
        setIsAlertFailed(false)
      }, 1000)
      $('#delete_Warehouse').trigger('click')
    })
  }

  const getBoundaries = (id: any) => {
    let totalDist: any = []
    WareHouseSingle(id).then((res: any) => {
      let regionID = res.region.id

      //request method for api implementation :- last change by kriti
      getRequest(
        `${apiBaseURL()}master/district_ward_filter/list/?region__id=${regionID}` + limit,
        true
      ).then((res) => {
        let data = res.results
        let districtName = ''
        let wards: any = []
        data.forEach((element: any) => {
          let disName = [{label: '-', value: '-'}]
          districtName = element.name
          let removeBlankSpace = false
          let nameOrdering: any = element.ward.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name)
          })
          nameOrdering.forEach((dist: any) => {
            removeBlankSpace = true
            disName.push({label: dist.name, value: dist.id})
            wards.push(dist)
            totalDist.push(dist)
          })
          if (removeBlankSpace) {
            disName.shift()
          }
          optionData.push({
            label: districtName,
            options: disName,
          })
        })
        optgroup = optionData
        setWard(optgroup)
        let nameOrdering: any = wards.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name)
        })
      })

      let wardsId = res.wards
      getRequest(
        `${apiBaseURL()}master/district_ward/list/?region__id=${regionID}&ordering=name` + limit,
        true
      ).then((res) => {
        let data = res.results
        let wards: any = []
        if (data.length !== 0) {
          data.forEach((element: any) => {
            element.ward.forEach((res: any) => {
              // if (wardsId.includes(res.id)) {
              wards.push({
                label: res.name,
                value: res.id,
              })
              // }
            })
          })
        }
        setWardModal(wards)
      })
    })
  }

  const singleWarehousePath: any = localStorage.getItem('singleWarehousePath')

  const resetDistributionBoundaries = (deletedWard: any) => {
    let deletedWardId = deletedWard.value
    let selectedValue = ''
    wardDistrict.forEach((item: any) => {
      if (item.ward.length !== 0) {
        item.ward.forEach((ward: any) => {
          if (ward.id === deletedWardId) {
            selectedValue = item.name
          }
        })
      }
    })

    let deletedValue: any = {
      label: selectedValue,
      options: [deletedWard],
    }
    // setDeletedItem(deletedValue)
  }

  const [parentOffset, setParentOffset] = useState('')
  const [parentWarehouse, setParentWarehouse] = useState([])
  const [searchParentWarehouse, setSearchParentWarehouse] = useState('')

  const [headquarterOffset, setHeadquarterOffset] = useState('')
  const [headquarter, setHeadquarter] = useState([])
  const [searchHeadquarter, setSearchHeadquarter] = useState('')

  useEffect(() => {
    setParentOffset('')
  }, [searchParentWarehouse])

  useEffect(() => {
    setHeadquarterOffset('')
  }, [searchHeadquarter])

  const fetchParentWarehouse = (search: any) => {
    let url = `warehouse/list?ordering=name&warehouse_type=Headquarter`
    return fetchAsyncDropdown(
      parentOffset,
      setParentOffset,
      parentWarehouse,
      setParentWarehouse,
      url,
      search,
      setSearchParentWarehouse
    )
  }

  const fetchHeadquarter = (search: any) => {
    let url = `master/region_warehouse/list/?ordering=name`
    return fetchAsyncDropdown(
      headquarterOffset,
      setHeadquarterOffset,
      headquarter,
      setHeadquarter,
      url,
      search,
      setSearchHeadquarter
    )
  }

  const onImageChange = (e: any) => {
    if (e.target.files[0]) {
      const [file] = e.target.files
      let size = e.target.files[0].size / 1024 / 1024
      setImgRequired(false)
      if (size > 5.0) {
        setImgSizeInvalid('text-danger')
      } else {
        setImgSizeInvalid('')
        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
        if (allowedExtensions.exec(e.target.value)) {
          setBillingInfo({...billingInfo, ['business_logo']: e.target.files[0]})
        }
        let img: any = ''
        if (e.target.files[0]) {
          img = new Image()
          let objectUrl = URL.createObjectURL(file)
          img.onload = function () {
            URL.revokeObjectURL(objectUrl)
          }
          img.src = objectUrl
        }
        e.target.value = ''
      }
    }
  }

  const getImage = (val: any) => {
    setBillingButtonDisabled(false)
    setBillingInfo({...billingInfo, ['business_logo']: val})
  }

  const billingHandleChange = (e: any) => {
    const inputValue = e.target.value
    const re = /^[0-9\b]+$/
    const updatedBillingInfo = {...billingInfo}
    setBillingInfoValidation({...billingInfoValidation, [e.target.name]: ''})
    setBillingButtonDisabled(false)
    switch (e.target.name) {
      case 'business_name':
        updatedBillingInfo.business_name = inputValue
        break
      case 'full_address':
        updatedBillingInfo.full_address = inputValue
        break
      case 'support_contact':
        if (inputValue !== '' && !re.test(inputValue)) {
          setBillingInfoValidation({...billingInfoValidation, ['support_contact']: 'is-invalid'})
          setBillingButtonDisabled(true)
        } else {
          setBillingInfoValidation({...billingInfoValidation, ['support_contact']: ''})
          setBillingButtonDisabled(false)
          updatedBillingInfo.support_contact = inputValue
        }
        break
      case 'primary_contact':
        if (inputValue !== '' && !re.test(inputValue)) {
          setBillingInfoValidation({...billingInfoValidation, ['primary_contact']: 'is-invalid'})
          setBillingButtonDisabled(true)
        } else {
          setBillingInfoValidation({...billingInfoValidation, ['primary_contact']: ''})
          setBillingButtonDisabled(false)
          updatedBillingInfo.primary_contact = inputValue
        }
        break
      case 'secondary_contact':
        if (inputValue !== '' && !re.test(inputValue)) {
          setBillingInfoValidation({...billingInfoValidation, ['secondary_contact']: 'is-invalid'})
          setBillingButtonDisabled(true)
        } else {
          setBillingInfoValidation({...billingInfoValidation, ['secondary_contact']: ''})
          setBillingButtonDisabled(false)
          updatedBillingInfo.secondary_contact = inputValue
        }
        break
      case 'website_url':
        let urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/[\w.-]*)*\/?$/i
        const validURL = urlPattern.test(inputValue)
        if (!validURL) {
          setBillingInfoValidation({...billingInfoValidation, ['website_url']: 'is-invalid'})
          setBillingButtonDisabled(true)
        } else {
          setBillingInfoValidation({...billingInfoValidation, ['website_url']: ''})
          setBillingButtonDisabled(false)
        }
        updatedBillingInfo.website_url = inputValue

        break
    }

    setBillingInfo(updatedBillingInfo)
  }

  const handleTaxChange = (e: any, id: number, index: number) => {
    let updatedTaxInfo: any = [...taxInfo]
    const value = e.target.value

    updatedTaxInfo[index] = {
      tax_label_id: id,
      tax_number: value,
    }

    setTaxInfo(updatedTaxInfo)
  }

  const isValdBillingEntries = () => {
    let isValid: boolean = true

    const updatedValidation = {...billingInfoValidation}

    if (isEmpty(billingInfo.business_name)) {
      isValid = false
      updatedValidation['business_name'] = 'is-invalid'
    }

    if (isEmpty(billingInfo.business_logo)) {
      isValid = false
      setImgRequired(true)
    }

    if (isEmpty(billingInfo.full_address)) {
      isValid = false
      updatedValidation['full_address'] = 'is-invalid'
    }

    if (isEmpty(billingInfo.primary_contact)) {
      isValid = false
      updatedValidation['primary_contact'] = 'is-invalid'
    }

    if (isEmpty(billingInfo.support_contact)) {
      isValid = false
      updatedValidation['support_contact'] = 'is-invalid'
    }

    if (isEmpty(billingInfo.website_url)) {
      isValid = false
      updatedValidation['website_url'] = 'is-invalid'
    }

    setBillingInfoValidation(updatedValidation)

    setBillingButtonDisabled(true)

    if (isValid) {
      setBillingButtonDisabled(false)
    }

    return isValid
  }

  const saveBillingInfo = async () => {
    if (isValdBillingEntries()) {
      const billingFormData: any = new FormData()

      setBillingButtonText('Please wait...')

      Object.entries(billingInfo).map((object: any) => {
        billingFormData.append(object[0], object[1])
      })

      if (taxLabels.length > 0) {
        taxLabels.map((tax: any, index: number) => {
          const findIndex = taxInfo.findIndex((option: any) => option.tax_label_id === tax.id)

          billingFormData.append(`warehouse_billing_info_details[${index}]tax_label_info`, tax.id)
          billingFormData.append(`warehouse_billing_info_details[${index}]tax_label_number`, '')
          billingFormData.append(`warehouse_billing_info_details[${index}]warehouse`, ID)

          if (findIndex > -1) {
            billingFormData.append(
              `warehouse_billing_info_details[${index}]tax_label_info`,
              taxInfo[findIndex].tax_label_id
            )
            billingFormData.append(
              `warehouse_billing_info_details[${index}]tax_label_number`,
              taxInfo[findIndex].tax_number
            )
            billingFormData.append(`warehouse_billing_info_details[${index}]warehouse`, ID) //TODO: Himalaya need this key as he said that he can not able to retrieve from warehouse key
          }
        })
      }

      await postRequest(`${apiBaseURL()}warehouse/warehouse-billing`, billingFormData, true)
      setBillingInfoAdded(true)
      localStorage.setItem('billing-add', 'true')
      navigate('/warehouse/DC/singlewarehousename/billing-info', {
        state: {
          timeStamp: true,
        },
      })
      handleCloseBillingInfo()

      // Note: open update incentive components modal, if total of the incentive componets is zero.
      if (incentiveValue === 0) {
        setTimeout(() => {
          setIncentiveModal(true)
        }, 200)
      }
    }
  }

  const clearAreaFields = () => {
    // clear below field while changing the Warehouse Type

    // Headquarter Boundary
    setHeadquarterValue('')

    // Parent Warehouse
    setParentValue('')

    // Distribution Boundaries
    setwardWarehouse('')
    setWard([])

    // Street Address
    setWAddress('')

    // Ward
    setWardsValue('')
    setWardModal([])

    // Postcode, Latitude, Longitude
    setWPostcode('')
    setWManualLatitude('')
    setWManualLongitude('')
  }

  return (
    <>
      <PageTitle breadcrumbs={[wareHouseHeaderTitle]}>
        {intl.formatMessage({id: 'Warehouse & DC'})}
      </PageTitle>
      {isRoleLoading && <IsLoader />}
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        // message="success"
        message={
          messageHandler === 'successEdit'
            ? FormatMessage('WAREHOUSE.UPDATE', WarehouseName)
            : FormatMessage('WAREHOUSE.DELETE', WarehouseName)
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />
      {!billingInfoAdded && (
        <div className='bg-light-primary d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>
          <span className='svg-icon-dark'>
            <KTSVG path='/media/icons/duotune/general/gen045.svg' className='ic me-3' />
          </span>
          <div className='text-dark pe-2'>Please add billing information</div>
          <button
            className='btn btn-link ms-3 p-0  ms-auto text-dark text-hover-decoration-underline text-hover-dark '
            type='button'
            onClick={handleShowBillingInfo}
          >
            Add
          </button>
          {/* <a href="" className='text-dark text-hover-decoration-underline text-hover-dark ms-auto fw-500'>Add</a> */}
        </div>
      )}

      {!incentiveAdded && (
        <div className='bg-light-warning d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>
          <span className='svg-icon-dark'>
            <KTSVG path='/media/icons/duotune/general/gen045.svg' className='ic me-3' />
          </span>
          <div className='text-dark pe-2'>
            Please Configure Incentive Components for the {WarehouseName} warehouse!
          </div>
          <button
            className='btn btn-link ms-3 p-0  ms-auto text-dark text-hover-decoration-underline text-hover-dark '
            type='button'
            onClick={handleIncentiveComponents}
          >
            Add
          </button>
          {/* <a href="" className='text-dark text-hover-decoration-underline text-hover-dark ms-auto fw-500'>Add</a> */}
        </div>
      )}

      {!mnadaAdded && (
        <div className='bg-light-success d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>
          <span className='svg-icon-dark'>
            <KTSVG path='/media/icons/duotune/general/gen045.svg' className='ic me-3' />
          </span>
          <div className='text-dark pe-2'>
            Please Configure Mnada Location for the {WarehouseName} warehouse!
          </div>
          <button
            className='btn btn-link ms-3 p-0  ms-auto text-dark text-hover-decoration-underline text-hover-dark '
            type='button'
            onClick={handleMnadaLocation}
          >
            Add
          </button>
          {/* <a href="" className='text-dark text-hover-decoration-underline text-hover-dark ms-auto fw-500'>Add</a> */}
        </div>
      )}
      {/* header */}
      <WarehouseHeader
        warehouseManager={warehouseManager}
        showOverview={showOverview}
        showArea={showArea}
        showTeam={showTeam}
        showSales={showSales}
        showTargets={showTargets}
        impersonate={impersonate}
        showStock={showStock}
        showCustomers={showCustomers}
        showCredit={showCredit}
        showVehicles={showVehicles}
        deleteMessage={() => setMessageHandler('successDelete')}
        showDelete={permission.delete}
        showWrite={permission.write}
        editClick={getSingleWarehouse}
        WarehouseRegion={WarehouseRegion}
        WarehouseName={WarehouseName}
        WarehouseType={WarehouseType}
      />

      {/* routes of single warehouse */}
      <Routes>
        {showOverview && (
          <Route
            path='overview'
            element={
              <WarehouseOverview
                WarehouseOverView={WarehouseOverView}
                WarehouseName={WarehouseName}
              />
            }
          />
        )}
        {showArea && (
          <Route
            path='area-coverage'
            element={
              <AreaCoverage
                WarehouseType={WarehouseType}
                getWardsDist={getWardsDist}
                wardsID={wardsID}
                WarehouseArea={WarehouseArea}
                selectWard={selectWard}
                wards={getWard}
                mapDetails={mapDetails}
                KMLfiles={KMLfiles}
              />
            }
          />
        )}
        {showTeam && <Route path='team' element={<Team />} />}
        {showSales && (
          <>
            <Route path='sales' element={<SalesInvoices />} />
            <Route path='sales/order-details' element={<SalesInvoiceDetail />} />
          </>
        )}
        <Route path='orders' element={<PreOrders />} />
        {showTargets && <Route path='targets' element={<Targets />} />}
        {showStock && <Route path='stock' element={<Stock />} />}
        {showCustomers && <Route path='warehouse-customers' element={<Customers />} />}
        {showCredit && (
          <>
            <Route path='credit-issues' element={<CreditIssues />} />
            <Route path='credit-issues/credit-issues-detail' element={<CreditIssuesDetail />} />
          </>
        )}
        {showCredit && <Route path='payment-issues' element={<PaymentIssues />} />}
        {showVehicles && <Route path='vehicles' element={<Vehicles />} />}
        <Route path='billing-info' element={<BillingInfo />} />
        {/* <Route path='complaint' element={<Complaints isViewOnly={true} />} /> */}
        <Route
          path='incentive-components'
          element={<IncentiveComponents setIncentiveAdded={setIncentiveAdded} />}
        />
        <Route
          path='mnada-location'
          element={
            <MnadaLocation
              mnadaAdded={mnadaAdded}
              setMnadaAdded={setMnadaAdded}
              isMnadaAdded={isMnadaAdded}
              setIsMnadaAdded={setIsMnadaAdded}
            />
          }
        />
        {/* <Route path='*' element={<Navigate to={singleWarehousePath} />} /> */}
        {/* <Route path='*' element={<Navigate to={"/warehouse/DC"} />} /> */}
      </Routes>

      {/* edit warehouse */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        id='ea_modal_warehouse_edit'
        modalTitle='Edit Warehouse'
        cancel={clearWareHouse}
        closeAuto='ea_modal_close_edit_warehouse'
        onSubmit={() => {
          editFunction()
        }}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Warehouse Name<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='warehouseName'
            value={warehouseName}
            onChange={(e) => {
              setWarehouseNameEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setWarehouseNameValidation(validation)
            }}
            className={clsx('form-control', warehouseNameValidation)}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Warehouse Type<span className='text-danger'>*</span>
          </label>
          <div className='row'>
            <div className='col-auto'>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  name='whType'
                  type='radio'
                  id='headquarter'
                  onClick={() => {
                    setHeadquarterChecked(true)
                    setWarehouseTypeEdit('Headquarter')
                    setParentSelectValidation(true)
                    setparentWarehouse('')
                    setChekValue(false)
                    setShowGmDetails(true)
                    setWardsValue('')
                    setWward('')
                    clearAreaFields()
                  }}
                  checked={warehouseTypeEdit == 'Headquarter' ? true : false}
                />
                <label className='form-check-label' htmlFor='headquarter'>
                  Headquarter
                </label>
              </div>
            </div>
            <div className='col-auto'>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  name='whType'
                  type='radio'
                  checked={warehouseTypeEdit == 'DistributionCenter' ? true : false}
                  onClick={() => {
                    setHeadquarterChecked(false)
                    setWarehouseTypeEdit('DistributionCenter')
                    setChekValue(true)
                    setParentSelectValidation(false)
                    setregionWarehouse('')
                    setShowGmDetails(false)
                    setWardsValue('')
                    setWward('')
                    clearAreaFields()
                  }}
                  id='distribution-center'
                  disabled={type === 'Headquarter'}
                />
                <label className='form-check-label' htmlFor='distribution-center'>
                  Distribution Center<span className='text-danger'>*</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          className='mb-4'
          style={{
            display: headquarterChecked ? 'none' : 'block',
          }}
        >
          <label className='form-label' id='parentValue'>
            Parent Warehouse<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={fetchParentWarehouse}
            isSearchable
            className={clsx('react-select-container w-100%', parentwarehouseValidation)}
            classNamePrefix='react-select'
            value={parentValue}
            placeholder='Select'
            onChange={(e: any) => {
              setParentValue(e)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setparentWarehouseValidation(validation)

              getBoundaries(e.value)
              setparentWarehouse(e.value)
              setParentSelectValidation(true)
              setwardWarehouse('')
              setWardsValue('')
              setWward('')
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
          {/* <select className={
                        clsx('form-select font-14', parentwarehouseValidation)
                    } aria-label="Select example"
                        value={parentwarehouse}
                        onChange={(e) => {
                            const validation = ValidationHandler(e.target.value, "is-invalid")
                            setparentWarehouseValidation(validation)

                            getBoundaries(e.target.value)
                            setparentWarehouse(e.target.value)
                            setParentSelectValidation(true)
                            setwardWarehouse("")
                            setWward("")
                        }}
                    >
                        <option hidden>Select Parent</option>
                        {getParent.map((item: any) => (
                            item.parent_warehouse === null ? <option value={item.id} key={item.id} >{item.name}</option> : ""
                        ))}
                    </select> */}
        </div>

        <div
          className='mb-4'
          style={{
            display: headquarterChecked ? 'block' : 'none',
          }}
        >
          <label className='form-label' id='headquarterValue'>
            Headquarter Boundary<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={fetchHeadquarter}
            isSearchable
            className={clsx('react-select-container w-100%', regionwarehouseValidation)}
            classNamePrefix='react-select'
            value={headquarterValue}
            placeholder='Select'
            onChange={(e: any) => {
              setHeadquarterValue(e)
              setWardsValue('')
              setWward('')
              wardselect(e)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setregionWarehouseValidation(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
          {/* <select
                        value={regionwarehouse}
                        onChange={(e) => {
                            wardselect(e)
                            const validation = ValidationHandler(e.target.value, "is-invalid")
                            setregionWarehouseValidation(validation)
                        }}
                        className={
                            clsx('form-select font-14', regionwarehouseValidation)
                        } aria-label="Select example">
                        <option hidden>Select Region</option>
                        {getRegion.map((item: any) => (
                            <option value={item.id} key={item.id} >{item.name}</option>
                        ))}
                    </select> */}
        </div>
        <div className='mb-4'>
          <label className='form-label' id='wardwarehouse'>
            Distribution Boundaries<span className='text-danger'>*</span>
          </label>
          <Select
            options={getWard}
            placeholder='Select'
            onChange={(e: any, removeEvent: any) => {
              if (removeEvent.action === 'remove-value') {
                let deletedValue = removeEvent.removedValue
                resetDistributionBoundaries(deletedValue)
              }

              let filterData = e.filter((val: any) => {
                if (val.label !== '-') {
                  return val
                }
              })
              setwardWarehouse(filterData)
              let event = e
              if (event.length === 0) {
                event = ''
              }
              const validation = ValidationHandler(event, 'is-invalid')
              setwardWarehouseValidation(validation)
            }}
            value={wardwarehouse}
            isMulti
            className={clsx('react-select-container', wardwarehouseValidation)}
            classNamePrefix='react-select'
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>

        <div className='mb-4'>
          <label className='form-label'>
            Street Address<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='waddress'
            placeholder='Type here...'
            value={waddress}
            onChange={(e) => {
              setWAddress(e.target.value)
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setWAddressValidation(validation)
            }}
            className={clsx('form-control', waddressValidation)}
          />
        </div>
        <div className='row'>
          <div className='col-sm-6 mb-4'>
            <label className='form-label' id='wardsValue'>
              Ward<span className='text-danger'>*</span>
            </label>
            <Select
              options={getWardModal}
              placeholder='Select Ward'
              onChange={(e: any) => {
                setWardsValue(e)
                setWward(e.value)
                const validation = ValidationHandler(e.value, 'is-invalid')
                setWwValidation(validation)
              }}
              value={wardsValue}
              className={clsx('react-select-container', wwardValidation)}
              classNamePrefix='react-select'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />

            {/* <select
                            value={wward}
                            onChange={(e) => {
                                setWward(e.target.value)
                                const validation = ValidationHandler(e.target.value, "is-invalid")
                                setWwValidation(validation)
                            }}
                            className={
                                clsx('form-select font-14', wwardValidation)
                            } aria-label="Select example">
                            <option hidden>Select Ward</option>
                            {getWardModal.map((item: any) => (
                                <option value={item.id} key={item.id} >{item.name}</option>
                            ))}
                        </select> */}
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label'>Postcode</label>
            <input
              type='text'
              value={wpostcode}
              maxLength={8}
              onChange={(e) => {
                const re = /^[0-9\b]+$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  setWPostcodeValidation(validation)
                  setWPostcode(e.target.value)
                }
              }}
              className={clsx('form-control')}
              placeholder='Type here...'
            />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label'>
              Latitude<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              value={wManualLatitude}
              onChange={(e) => {
                setWManualLatitude(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setWLatitudeValidation(validation)
              }}
              className={clsx('form-control', wlatitudeValidation)}
              placeholder='Type here...'
            />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label'>
              Longitude<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              value={wManualLongitude}
              onChange={(e) => {
                setWManualLongitude(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setWLongitudeValidation(validation)
              }}
              className={clsx('form-control', wlongitudeValidation)}
              placeholder='Type here...'
            />
          </div>
          <div className='col-sm-12 mb-4'>
            <a
              href='javascript:void(0)'
              onClick={goToMap}
              className={clsx(
                isEmpty(wManualLatitude) || (isEmpty(wManualLongitude) && 'disabled')
              )}
            >
              Preview on Map
            </a>
          </div>
          <div
            className='col-sm-6 mb-4'
            style={{
              display: shoGmDetails ? 'block' : 'none',
            }}
          >
            <label className='form-label'>
              BM's First Name<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              id='wGmFirstname'
              value={wGmFirstname}
              onChange={(e) => {
                setGmFirstname(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setGmFirstnameValidation(validation)
              }}
              className={clsx('form-control', wGmFirstnameValidation)}
              placeholder='Type here...'
            />
          </div>
          <div
            className='col-sm-6 mb-4'
            style={{
              display: shoGmDetails ? 'block' : 'none',
            }}
          >
            <label className='form-label'>
              Last Name<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              id='wGmLastname'
              value={wGmLastname}
              onChange={(e) => {
                setGmLastname(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setGmLastnameValidation(validation)
              }}
              className={clsx('form-control', wGmLastnameValidation)}
              placeholder='Type here...'
            />
          </div>
          <div
            id='wphoneNumber'
            className='col-sm-6'
            style={{
              display: shoGmDetails ? 'block' : 'none',
            }}
          >
            <MobileNumberInput
              validation={wphoneNumberValidation}
              setValidation={setPhoneNumberValidation}
              numberValue={wphoneNumber}
              setNumberValue={setPhoneNumber}
            />

            {/* <label className='form-label'>Phone Number</label>
            <input
              type='text'
              placeholder='Type here...'
              value={wphoneNumber}
              onChange={(e) => {
                const re = /^[0-9\b]+$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  setPhoneNumber(e.target.value)
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  setPhoneNumberValidation(validation)
                }
              }}
              maxLength={9}
              className={clsx('form-control', wphoneNumberValidation)}
            /> */}
          </div>
          <div
            className='col-sm-6 mb-4'
            style={{
              display: shoGmDetails ? 'block' : 'none',
            }}
          >
            <label className='form-label'>
              Email<span className='text-danger'>*</span>
            </label>
            <input
              id='wemail'
              type='text'
              value={wemail}
              disabled={isWarehouseManager}
              onChange={(e) => {
                setEmail(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setEmailValidation(validation)
              }}
              className={clsx('form-control', wemailValidation)}
              placeholder='Type here...'
              onBlur={() => {
                if (validateEmail(wemail)) {
                  setValidationEmail(false)
                } else {
                  setValidationEmail(true)
                }
              }}
            />
            {validationEmail && <p className='text-danger'>Wrong email format</p>}
          </div>
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>

      {/* delete warehouse */}
      <EaModal
        modalTitle='Delete Warehouse'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{warehouseName}?</span>
          </p>
        }
        closeAuto='delete_Warehouse'
        id={'deleteWarehouse'}
        actionBtnName='Yes, Proceed'
        onSubmit={deleteClick}
      />

      {/* Add billing info */}
      <Modal show={showBillingInfo} onHide={handleCloseBillingInfo} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Billing Info</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          <div className='mb-6'>
            <Imagecropper
              imageRequire={imgRequired}
              text='Max. File Size: 5MB & 500px × 500px'
              isUpload={true}
              title='business_logo'
              getImage={getImage}
              onImageChange={onImageChange}
              imageSize={imgSizeInvalid}
              cropData={cropData}
              setCrop={setCropData}
            />
          </div>
          <div className='mb-6'>
            <label className='form-label'>
              Registered Business Name<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              value={billingInfo.business_name}
              className={clsx('form-control', billingInfoValidation.business_name)}
              name='business_name'
              onChange={billingHandleChange}
              placeholder='Type here...'
            />
          </div>
          <div className='mb-6'>
            <label className='form-label'>
              Warehouse Address<span className='text-danger'>*</span>
            </label>
            <textarea
              className={clsx('form-control', billingInfoValidation.full_address)}
              rows={3}
              onChange={billingHandleChange}
              placeholder='Type here…'
              name='full_address'
            ></textarea>
          </div>
          <div className='mb-6'>
            <MobileNumberInput
              label='Primary Contact No.'
              validation={billingInfoValidation.primary_contact}
              onChange={billingHandleChange}
              placeholder='Enter primary contact'
              numberValue={billingInfo.primary_contact}
              name='primary_contact'
            />
          </div>
          <div className='mb-6'>
            <MobileNumberInput
              label='Secondary Contact No.'
              isOptional={true}
              validation={billingInfoValidation.secondary_contact}
              onChange={billingHandleChange}
              placeholder='Enter secondary contact'
              numberValue={billingInfo.secondary_contact}
              name='secondary_contact'
            />
          </div>
          <div className='mb-6'>
            <MobileNumberInput
              label='Support Contact No.'
              validation={billingInfoValidation.support_contact}
              onChange={billingHandleChange}
              placeholder='Enter support contact'
              numberValue={billingInfo.support_contact}
              name='support_contact'
            />
          </div>
          <div className='mb-6'>
            <label className='form-label'>
              Website Link<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              value={billingInfo.website_url}
              name='website_url'
              className={clsx('form-control', billingInfoValidation.website_url)}
              onChange={billingHandleChange}
              placeholder='Type here...'
            />
          </div>
          <div className='mb-6'>
            <div className='border-bottom form-label mb-2 font-bold'>Taxation Number(s)</div>
            {taxLabelLoading && <IsLoader />}
            {taxLabels.length === 0 ? (
              <NoRecords />
            ) : (
              taxLabels.map((taxLabel: any, index: number) => {
                return (
                  <div className='mb-6'>
                    <label className='form-label'>{taxLabel.name}</label>
                    <input
                      type='text'
                      name='tax'
                      className='form-control'
                      placeholder='Type here...'
                      onChange={(e: any) => handleTaxChange(e, taxLabel.id, index)}
                      value={taxInfo[index] && taxInfo[index][`tax_number`]}
                    />
                  </div>
                )
              })
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-light' onClick={handleCloseBillingInfo}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={saveBillingInfo}
            disabled={billingButtonText !== 'Submit' || billingButtonDisabled}
          >
            {billingButtonText}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add Incentive Components */}
      <>
        <Modal
          show={incentiveModal}
          onHide={() => {
            setIncentiveModal(false)
            isClear()
          }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div>
                <h2 className='modal-title fw-bold'>
                  {warehouseName} - {srFirstRole?.label}
                </h2>
                <p className='mb-0'>Update Incentive Components</p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='px-md-12'>
            {fetching ? (
              <FetchingRecords />
            ) : (
              <>
                <div className='mb-3'>
                  <div className='font-20 font-bold'>Fixed Incentive</div>
                  <p>
                    Add the incentive amount receivable to each Sales Representative if they quality
                    for the KPIs set by Warehouse or Sales Manager.
                  </p>
                  <div className='col-sm-12 mt-4'>
                    <div className={clsx('input-group')}>
                      <span className='input-group-text bg-white border-end-0'>
                        {tenantConfiguration.currencyCode}
                      </span>
                      <input
                        onChange={(e) => {
                          onChangeInput(e)
                        }}
                        id='fixed_incentive_defined'
                        value={incentiveSingle.fixed_incentive_defined}
                        name='fixed_incentive_defined'
                        type='text'
                        className={clsx(
                          'form-control border-start-0 ps-0 border-focus-light',
                          incentiveSingle.fixed_incentive_defined_valid
                        )}
                        placeholder='Type Here...'
                      />
                    </div>
                  </div>
                </div>

                <div className='mb-3'>
                  <div className='font-20 font-bold'>Revenue Incentive</div>
                  <p>
                    Add the incentive amount receivable to each Sales Representative if they meet
                    the targeted revenue assigned by Sales Supervisor.
                  </p>
                  <div className='col-sm-12 mt-4'>
                    <div className={clsx('input-group')}>
                      <span className='input-group-text bg-white border-end-0'>
                        {tenantConfiguration.currencyCode}
                      </span>
                      <input
                        onChange={(e) => {
                          onChangeInput(e)
                        }}
                        id='revenue_incentive_defined'
                        value={incentiveSingle.revenue_incentive_defined}
                        name='revenue_incentive_defined'
                        type='text'
                        className={clsx(
                          'form-control border-start-0 ps-0 border-focus-light',
                          incentiveSingle.revenue_incentive_defined_valid
                        )}
                        placeholder='Type Here...'
                      />
                    </div>
                  </div>
                </div>

                <div className='mb-4'>
                  <div className='font-20 font-bold'>Bonus Incentive</div>
                  <p>
                    Add the percentage of achieved revenue as bonus incentive receivable if Sales
                    Representative meet defined conditions below.
                  </p>
                  <div className='col-sm-8 mt-4'>
                    <div className='p-1'>
                      <div
                        className={clsx(
                          'input-group  p-0',
                          incentiveSingle.bonus_incentive_perc_defined_valid === 'is-invalid'
                            ? 'is-invalid-grp'
                            : ''
                        )}
                      >
                        <input
                          type='number'
                          id={'bonus_incentive_perc_defined'}
                          onChange={(e) => {
                            onChangeInput(e, true)
                          }}
                          placeholder='0'
                          value={incentiveSingle.bonus_incentive_perc_defined}
                          name='bonus_incentive_perc_defined'
                          className={clsx('form-control border-start-0')}
                        />
                        <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                          % Of Revenue Achieved
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mb-3'>
                  <div className='font-bold'>Condition for bonus incentive eligibility</div>
                  <hr />
                  <div className='row mb-3'>
                    <div className='col-md-6'>
                      <div>Minimum Revenue Achievement</div>
                    </div>
                    <div className='col-md-6'>
                      <div className={clsx('input-group')}>
                        <span className='input-group-text bg-white border-end-0'>
                          {tenantConfiguration.currencyCode}
                        </span>
                        <input
                          onChange={(e) => {
                            onChangeInput(e)
                          }}
                          id='revenue_incentive_atleast_defined'
                          value={incentiveSingle.revenue_incentive_atleast_defined}
                          name='revenue_incentive_atleast_defined'
                          type='text'
                          className={clsx(
                            'form-control border-start-0 ps-0 border-focus-light',
                            incentiveSingle.revenue_incentive_atleast_defined_valid
                          )}
                          placeholder='Type Here...'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col-md-6'>
                      <div>Total Discount given not more than</div>
                    </div>
                    <div className='col-md-6'>
                      <div className='p-1'>
                        <div
                          className={clsx(
                            'input-group  p-0',
                            incentiveSingle.discount_prec_atmost_defined_valid === 'is-invalid'
                              ? 'is-invalid-grp'
                              : ''
                          )}
                        >
                          <input
                            type='number'
                            id={'discount_prec_atmost_defined'}
                            onChange={(e) => {
                              onChangeInput(e, true)
                            }}
                            placeholder='0'
                            value={incentiveSingle.discount_prec_atmost_defined}
                            name='discount_prec_atmost_defined'
                            className={clsx('form-control border-start-0')}
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col-md-6'>
                      <div>Minimum Achievement of Fixed incentive</div>
                    </div>
                    <div className='col-md-6'>
                      <div className='p-1'>
                        <div
                          className={clsx(
                            'input-group  p-0',
                            incentiveSingle.achieved_perc_atleast_defined_valid === 'is-invalid'
                              ? 'is-invalid-grp'
                              : ''
                          )}
                        >
                          <input
                            type='number'
                            id={'achieved_perc_atleast_defined'}
                            onChange={(e) => {
                              onChangeInput(e, true)
                            }}
                            placeholder='0'
                            value={incentiveSingle.achieved_perc_atleast_defined}
                            name='achieved_perc_atleast_defined'
                            className={clsx('form-control border-start-0')}
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className='mb-3'>
                  <ErrorHandler errorMessage={error} />
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className='modal-footer justify-content-center'>
            <button
              type='button'
              className='btn btn-light'
              onClick={() => {
                setIncentiveModal(false)
                isClear()
              }}
              disabled={buttonHandler !== 'Submit' ? true : false}
            >
              Cancel
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={updateIncentiveComponent}
              disabled={buttonHandler !== 'Submit' ? true : false}
            >
              {buttonHandler}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    warehouse: state.warehouse.warehouseId,
  }
}

export default connect(mapStateToProps, null)(SingleWareHouse)
