import {GoogleMap, Marker, withGoogleMap, withScriptjs} from 'react-google-maps'
import {useEffect, useState, useRef} from 'react'
import tenantConfiguration from '../../../../../TenantVariables'
import {focusVatidateField, isEmpty} from '../../../../../_eaFruitsDms/helpers'

const RouteWiseCustomerMap = withScriptjs(
  withGoogleMap((props: any) => {
    const speed = 0.01 // Adjust the speed for smoothness

    const animationRef = useRef<any>(null)

    const [mapRef, setMapRef] = useState<any>(null)
    const [currentPosition, setCurrentPosition] = useState(props?.vehicleLocation)
    const [nextPosition, setNextPosition] = useState<any>(null)
    const [hideCenter, setHideCenter] = useState<any>(true)

    // Update bounds when customerLocation changes
    useEffect(() => {
      if (props?.hideVehicleLocation && mapRef && props?.customerLocation?.length > 0) {
        const bounds = new google.maps.LatLngBounds()

        props.customerLocation.forEach((location: any) => {
          bounds.extend(new google.maps.LatLng(location.latitude, location.longitude))
        })

        mapRef.fitBounds(bounds)
      }
    }, [mapRef, props?.customerLocation, !props?.hideVehicleLocation])

    // Smooth movement logic for the vehicle marker
    useEffect(() => {
      if (props?.vehicleLocation) {
        if (!currentPosition) {
          setCurrentPosition(props.vehicleLocation)
        } else {
          setNextPosition(props.vehicleLocation)
        }
      }
    }, [props?.vehicleLocation])

    useEffect(() => {
      if (nextPosition) {
        const interpolate = (start: number, end: number, factor: number) =>
          start + (end - start) * factor

        const smoothMove = (progress: number) => {
          if (progress >= 1) {
            setCurrentPosition(nextPosition)
            setNextPosition(null)
            return
          }

          const lat = interpolate(currentPosition.lat, nextPosition.lat, progress)
          const lng = interpolate(currentPosition.lng, nextPosition.lng, progress)

          setCurrentPosition({lat, lng})

          animationRef.current = requestAnimationFrame(() => smoothMove(progress + speed))
        }

        animationRef.current = requestAnimationFrame(() => smoothMove(speed))
      }

      return () => cancelAnimationFrame(animationRef.current)
    }, [nextPosition])

    useEffect(() => {
      setTimeout(() => {
        setHideCenter(false)
      }, 9000)
    }, [])

    return (
      <GoogleMap
        ref={(map: any) => setMapRef(map)}
        defaultZoom={20}
        {...(!isEmpty(props?.defaultCenter) &&
          hideCenter && {
            center: props?.defaultCenter,
          })}
      >
        {currentPosition && !props?.hideVehicleLocation && (
          <Marker
            position={currentPosition}
            icon={{
              url: `${tenantConfiguration.imageBaseUrl}ic-truck.svg`,
              anchor: new google.maps.Point(17, 46),
              scaledSize: new google.maps.Size(50, 50),
            }}
          />
        )}

        {props?.warehouseLocation && (
          <Marker
            position={props?.warehouseLocation}
            icon={{
              url: tenantConfiguration.imageBaseUrl + 'warehousepin.png',
              anchor: new google.maps.Point(17, 46),
              scaledSize: new google.maps.Size(50, 50),
            }}
          />
        )}

        {props.customerLocation &&
          props.customerLocation.map((i: any, index: any) => (
            <Marker
              key={`${i.latitude}-${i.longitude}-${index}-${i?.manual_seq}`} // Unique key
              position={{lat: i.latitude, lng: i.longitude}}
              label={{
                text: isEmpty(i?.manual_seq) ? '-' : i?.manual_seq,
                color: 'black',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
              onClick={() => {
                focusVatidateField(`sr_drop_point_${i?.manual_seq}`)
                props?.setHighLightCard && props?.setHighLightCard(i?.manual_seq)
              }}
              icon={{
                url: `${tenantConfiguration.imageBaseUrl}${
                  i?.status === 'visited' ? 'ic-map.svg' : 'ic-map-1.svg'
                }`,
                anchor: new google.maps.Point(17, 46),
                scaledSize: new google.maps.Size(40, 40),
              }}
            />
          ))}
      </GoogleMap>
    )
  })
)

export default RouteWiseCustomerMap
