import React, {useState} from 'react'
import {
  apiBaseURL,
  checkUserType,
  fetchAsyncDropdown,
  isEmpty,
  measurementLabel,
  numberFormatWithCode,
} from '../../../../../_eaFruitsDms/helpers'
import {patchRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {useLocation, useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import EaModal from '../../../../../_eaFruitsDms/layout/components/modal/eaModal'
import {AsyncPaginate} from 'react-select-async-paginate'
import $ from 'jquery'

const OrderQuantityProducts = (props: any) => {
  const location = useLocation()
  const navigate = useNavigate()

  const {orderedProducts, productRequest, setProductsRequest, orderId, orderStatus} = props

  const initialRejectValidation: any = {
    option: '',
    comments: '',
  }

  const initialRejectReason = {
    option: {label: 'Select', value: null},
    comments: '',
  }

  const [reasonOffSet, setReasonOffSet] = useState<any>('')
  const [reasonOption, setReasonOption] = useState<any>([])
  const [reasonValidation, setReasonValidation] = useState<any>(initialRejectValidation)
  const [rejectReason, setRejectReason] = useState(initialRejectReason)
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [disableApproveBtn, setDisableArroveBtn] = useState(false)
  const [disableRejectBtn, setDisableRejectBtn] = useState(false)
  const [isRejectLoading, setIsRejectLoading] = useState('Reject')
  const [isApproveLoading, setIsApproveLoading] = useState('Approve')

  const handleChangeApprove = (e: any, index: any, product: any) => {
    let inputValue = e.target ? e.target.value : e.value.replace(/\D/g, '')
    let rows: any = [...productRequest]

    let maxQty = product.max_approved_qty

    let appliedPrice =
      parseInt(rows[index].product_setting_qty) === 0
        ? rows[index].discounted_price === 0
          ? rows[index].product_price
          : rows[index].discounted_price
        : inputValue >= parseInt(rows[index].product_setting_qty)
        ? rows[index].wholesaler_discounted_price === 0
          ? rows[index].wholesaler_price
          : rows[index].wholesaler_discounted_price
        : rows[index].discounted_price === 0
        ? rows[index].product_price
        : rows[index].discounted_price

    rows[index]['approved_qty'] = inputValue
    rows[index]['product_sku'] = product.product_sku.id
    // rows[index]['total_price'] = product.delivery_date_price * inputValue
    rows[index]['total_price'] = appliedPrice * inputValue
    rows[index]['max_qty'] = maxQty

    if (
      inputValue.trim() !== '' &&
      inputValue !== 0 &&
      inputValue <= rows[index].requested_quantity
    ) {
      rows[index]['validation_for_qty'] = ''
    } else {
      rows[index]['validation_for_qty'] = 'is-invalid'
    }

    setProductsRequest(rows)
  }

  const approvedValidation = () => {
    let isValid: boolean = true
    let updatedRows = [...productRequest]

    updatedRows.map((row: any, index: number) => {
      if (
        (row['approved_qty'] === '' || row['approved_qty'] > row['requested_quantity']) &&
        row.is_crossed_max_qty
      ) {
        isValid = false
        updatedRows[index]['validation_for_qty'] = 'is-invalid'
      }
    })
    setProductsRequest(updatedRows)
    return isValid
  }

  const fetchRequestReason = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    const response = await fetchAsyncDropdown(
      reasonOffSet,
      setReasonOffSet,
      reasonOption,
      setReasonOption,
      `master/options-master/?type=sales_qty_request_reason`,
      search,
      setSearchSelect,
      'option'
    )
    response.options.push({
      label: 'Other',
      value: '',
    })
    return response
  }

  const handleChangeReason = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setReasonValidation({...reasonValidation, [inputName]: ''})
    let updatedRejectReason: any = {...rejectReason}

    switch (inputName) {
      case 'option':
        updatedRejectReason['option'] = e
        updatedRejectReason['comments'] = ''
        break
      case 'comments':
        updatedRejectReason['comments'] = inputValue
        break
    }
    setRejectReason(updatedRejectReason)
  }

  const reasonValidate = () => {
    let validations = {...reasonValidation}
    let isValid = true

    if (rejectReason.option.value == null) {
      validations.option = 'is-invalid'
      isValid = false
    }
    if (!rejectReason.option.value && rejectReason.comments == '') {
      validations.comments = 'is-invalid'
      isValid = false
    }

    setReasonValidation(validations)
    return isValid
  }

  const rejectClearFunction = () => {
    setRejectReason(initialRejectReason)
    setReasonValidation(initialRejectValidation)
    setDisableRejectBtn(false)
    setIsRejectLoading('Reject')
  }

  const approvedRejectOrder = async (name: any) => {
    const isValidApprove = approvedValidation()
    const isValidReject = reasonValidate()

    let updateProductArray: any = [...productRequest]

    const modifiedArray = updateProductArray.map((item: any) => {
      const {total_price, validation_for_qty, max_qty, ...rest} = item
      return rest
    })

    const approveInfo: any = {
      status: 'approved',
      product_skus: modifiedArray,
    }

    const rejectInfo: any = {
      status: 'rejected',
      reason: rejectReason.option.value,
      reason_note: rejectReason.comments == '' ? null : rejectReason.comments,
    }

    if (name === 'approve') {
      if (isValidApprove) {
        setDisableArroveBtn(true)
        setIsApproveLoading('Please Wait...')
        apiHandler(approveInfo)
      }
    } else {
      if (isValidReject) {
        setDisableRejectBtn(true)
        setIsRejectLoading('Please Wait...')
        apiHandler(rejectInfo)
      }
    }
  }

  const apiHandler = async (formData: any) => {
    const res = await patchRequest(
      `${apiBaseURL()}preorder/sales-quantity-requests/${orderId}/update`,
      formData,
      true
    )
    if (res.status == 201 || res.status == 200) {
      $('#closeRejectModal').trigger('click')
      navigate('/customers/order-quantity-requests')
      setTimeout(() => {
        rejectClearFunction()
      }, 201)
    } else {
      setDisableRejectBtn(false)
      setIsRejectLoading('Reject')

      setDisableArroveBtn(false)
      setIsApproveLoading('Approve')
    }
  }

  return (
    <>
      <div className='card-body pt-0 pb-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-gray-100 align-baseline gs-0 gy-2'>
            {/* begin::Table head */}
            <thead>
              <tr className='text-muted fw-500'>
                <th className='min-w-100px w-220px'>Product SKU</th>
                <th className='min-w-120px w-200px'>Price</th>
                {(orderStatus === 'rejected' ||
                  orderStatus === 'cancelled' ||
                  orderStatus === 'pending') && (
                  <th className='min-w-120px w-200px'>Requested Quantity</th>
                )}

                {(orderStatus === 'approved' || orderStatus === 'pending') && (
                  <th className='min-w-100px w-220px'>
                    Approved Quantity<span className='text-danger'>*</span>
                  </th>
                )}
                <th className='w-120px min-w-130px'>Total</th>
                <th></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {orderedProducts.map((product: any, index: number): any => {
                const measurement = product.product_sku.measurement_unit
                let productUnit = product.product_sku.material.name
                if (!product.product_sku.fix_size && product.product_sku.approx_weight === 0) {
                  productUnit = measurement
                }
                let multiplyValue = 1

                if (product.product_sku.fix_size) {
                  if (product.product_sku.measurement_unit.toLowerCase() === 'gram') {
                    multiplyValue = product.product_sku.size / 1000
                  } else {
                    multiplyValue = product.product_sku.size
                  }
                } else {
                  if (
                    !isEmpty(product.product_sku.approx_weight) &&
                    product.product_sku.approx_weight > 0
                  ) {
                    multiplyValue = product.product_sku.approx_weight / 1000
                  }
                }

                if (product)
                  return (
                    <>
                      <tr key={index.toString()}>
                        <td>
                          <input
                            type='text'
                            name='sku_name'
                            className='form-control'
                            disabled
                            value={product.product_sku.name}
                          />
                        </td>
                        <td>
                          <input
                            type='text'
                            name='price'
                            className='form-control'
                            disabled
                            value={
                              numberFormatWithCode(
                                parseInt(productRequest[index].product_setting_qty) === 0
                                  ? productRequest[index].discounted_price === 0
                                    ? productRequest[index].product_price
                                    : productRequest[index].discounted_price
                                  : parseInt(productRequest[index].approved_qty) >=
                                    parseInt(productRequest[index].product_setting_qty)
                                  ? productRequest[index].wholesaler_discounted_price === 0
                                    ? productRequest[index].wholesaler_price
                                    : productRequest[index].wholesaler_discounted_price
                                  : productRequest[index].discounted_price === 0
                                  ? productRequest[index].product_price
                                  : productRequest[index].discounted_price
                              ).displayFormat
                            }
                          />
                        </td>

                        {(orderStatus === 'rejected' ||
                          orderStatus === 'cancelled' ||
                          orderStatus === 'pending') && (
                          <td className='align-baseline'>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                name='quantity'
                                id={product.id}
                                value={
                                  productRequest.length > 0
                                    ? productRequest[index].requested_quantity
                                    : ''
                                }
                                disabled
                              />
                              <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                                {productUnit ? measurementLabel(productUnit) : ''}
                              </span>
                            </div>
                            {product.crossed_max_limit && !checkUserType(['ts']) && (
                              <div>
                                <p className='font-13 text-muted mt-1'>
                                  {(measurement.toLowerCase() === 'gram'
                                    ? product.requested_qty / 1000
                                    : product.requested_qty) * multiplyValue}{' '}
                                  Kgs
                                </p>
                                <p className='font-13 text-danger mt-1'>
                                  Max Allowed Qty.&nbsp;
                                  {product.product_sku.sales_max_size_limit / 1000} Kgs
                                </p>
                              </div>
                            )}
                          </td>
                        )}

                        {(orderStatus === 'approved' || orderStatus === 'pending') && (
                          <td className='align-baseline'>
                            <div
                              className='input-group'
                              style={{
                                borderColor:
                                  productRequest.length > 0 &&
                                  productRequest[index].validation_for_qty != ''
                                    ? 'Red'
                                    : '',
                              }}
                            >
                              <input
                                type='text'
                                className={clsx(
                                  'form-control',
                                  productRequest.length > 0
                                    ? productRequest[index].validation_for_qty
                                    : ''
                                )}
                                name='quantity'
                                id={product.id}
                                value={
                                  orderStatus.toLowerCase() === 'cancelled' ||
                                  (orderStatus.toLowerCase() === 'pending' &&
                                    location.pathname ===
                                      '/reports/order-quantity-requests/quantity-request-detail')
                                    ? 0
                                    : productRequest.length > 0
                                    ? productRequest[index].approved_qty
                                    : ''
                                }
                                onChange={(e) => {
                                  if (e.target.value !== ' ') {
                                    if (e.target.value !== '0') {
                                      handleChangeApprove(e, index, product)
                                    }
                                  }
                                }}
                                placeholder={product.requested_qty ? product.requested_qty : ''}
                                disabled={
                                  orderStatus !== 'pending' ||
                                  !product.crossed_max_limit ||
                                  location.pathname ===
                                    '/reports/order-quantity-requests/quantity-request-detail' ||
                                  checkUserType(['ts'])
                                }
                              />
                              <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                                {productUnit ? measurementLabel(productUnit) : ''}
                              </span>
                            </div>
                            {product.crossed_max_limit && !checkUserType(['ts']) && (
                              <div>
                                <span className='font-13 text-muted mt-1'>
                                  {productRequest.length > 0 &&
                                  (orderStatus.toLowerCase() === 'cancelled' ||
                                    (orderStatus.toLowerCase() === 'pending' &&
                                      location.pathname ===
                                        '/reports/order-quantity-requests/quantity-request-detail'))
                                    ? 0
                                    : (measurement.toLowerCase() === 'gram'
                                        ? productRequest[index].approved_qty / 1000
                                        : productRequest[index].approved_qty) * multiplyValue}{' '}
                                  Kgs
                                </span>
                              </div>
                            )}
                          </td>
                        )}
                        <td>
                          <input
                            type='text'
                            className='form-control min-w-70px'
                            value={
                              productRequest.length > 0
                                ? numberFormatWithCode(productRequest[index].total_price)
                                    .displayFormat
                                : ''
                            }
                            disabled
                          />
                        </td>
                      </tr>
                    </>
                  )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {!checkUserType(['ts']) &&
          orderStatus === 'pending' &&
          location.pathname !== '/reports/order-quantity-requests/quantity-request-detail' && (
            <div className='card-footer py-4'>
              <button
                type='button'
                className='btn btn-secondary me-3'
                data-bs-toggle='modal'
                data-bs-target='#qty_request_reject_modal'
                // disabled={orderStatus !== "pending"}
              >
                Reject
              </button>
              <button
                className='btn btn-primary'
                onClick={() => approvedRejectOrder('approve')}
                disabled={disableApproveBtn}
              >
                {isApproveLoading}
              </button>
            </div>
          )}
      </div>

      <EaModal
        id='qty_request_reject_modal'
        modalTitle='Reason'
        onSubmit={() => approvedRejectOrder('reject')}
        cancel={rejectClearFunction}
        closeAuto='closeRejectModal'
        btnDisabled={disableRejectBtn}
        actionBtnName={isRejectLoading}
      >
        <label className='form-label'>
          Rejecting order quantity request will set order quantity to maximum allowed quantity in
          the order
        </label>
        <div className='mb-4'>
          <label className='form-label'>Reason</label>
          <AsyncPaginate
            loadOptions={fetchRequestReason}
            isSearchable
            placeholder='Select'
            className={clsx('react-select-container w-100', reasonValidation.option)}
            classNamePrefix='react-select'
            value={rejectReason.option}
            onChange={(e: any) => handleChangeReason(e, 'option')}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        {rejectReason.option.value == '' && (
          <>
            <label className='form-label'>Comments</label>
            <textarea
              className={clsx('form-control', reasonValidation.comments)}
              rows={4}
              placeholder='Type here…'
              value={rejectReason.comments}
              name='comments'
              onChange={(e: any) => handleChangeReason(e, 'comments')}
            ></textarea>
          </>
        )}
      </EaModal>
    </>
  )
}

export default OrderQuantityProducts
