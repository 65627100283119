import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiBaseURL,
  checkEmpty,
  displayAddedBy,
  formatVerificationType,
  KTSVG,
  retrieveFilter,
  saveFilter,
  staticSearch,
  toAbsoluteUrl,
  verificationPending
} from "../../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import tenantConfiguration from "../../../../TenantVariables";
import { categoryStyle } from "../../../../_eaFruitsDms/helpers/components/categoryStyle";
import { fetchUserTypes, userTypes } from "../WMcustomers/CustomerConst";
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import moment from "moment/moment";
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton";
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants";

type Props = {
  className: string
}

const TSCustomers: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate()
  const intl = useIntl()

  const customerHeaderTitle = {
    title: 'Customers',
    subTitle: 'Customers',
  }

  let customersFiltered : any = retrieveFilter(filters.ts_all_customers);

  const initialState = {
      warehouseId : customersFiltered?.warehouseId || {label : 'All Warehouses' , value : ''},
      categoryId: customersFiltered?.categoryId || {label : 'All Categories' , value : ''},
      typeId: customersFiltered?.typeId || '',
      userId: customersFiltered?.userId || {label : 'All Users' , value : ''},
      userType: customersFiltered?.userType || '',
      selected: customersFiltered?.selected || '',
      search: customersFiltered?.search || '',
      currentOffSet: customersFiltered?.currentOffSet || '',
      buttonDisable: true
    }
  const [customers, setCustomers] = useState<any>([])
  const [categories, setCategories] = useState<any>([])
  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouseOffSet, setWarehouseOffSet] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<any>(customersFiltered?.currentOffSet || '0');
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>(customersFiltered?.search || '')
  const [selected, setSelected] = useState<number>(customersFiltered?.selected || 0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  // const [warehouseId, setWarehouseId] = useState<any>('')
  const [warehouseId, setWarehouseId] = useState<any>(customersFiltered?.warehouseId || {
    label: 'All Warehouses',
    value: ''
});
  // const [categoryId, setCategoryId] = useState<any>('')
  const [categoryId, setCategoryId] = useState<any>(customersFiltered?.categoryId || {
    label: 'All Categories',
    value: ''
});
  const [typeId, setTypeId] = useState<any>(customersFiltered?.typeId || '')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [messageHandler, setMessageHandler] = useState('')
  const [messageTitle, setMessageTitle] = useState<any>('')
  const [userOffset, setUserOffset] = useState('');
  const [userType, setUserType] = useState<any>(customersFiltered?.userType || '')
  const [userId, setUserId] = useState<any>(customersFiltered?.userId || {
    label: 'All Users',
    value: ''
})
  const [userReset, setUserReset] = useState<any>(moment());
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(customersFiltered?.buttonDisable || false) 

  const debounceSearch = useDebounce(searchByName, 500);

  useEffect(() => {
    localStorage.removeItem('cId')

    if (localStorage.getItem('type')) {
      setMessageHandler(`success${localStorage.getItem('type')}`)
      setMessageTitle(localStorage.getItem('name'))

      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 2000)
      setIsAlertSuccess(true)

      localStorage.removeItem('type')
      localStorage.removeItem('name')
    }
  }, [])

  useEffect(() => {
    setWarehouseOffSet(0)
    setUserOffset('')
  }, [searchSelect])

  useEffect(() => {
    fetchCustomers().then()

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.ts_all_customers , filterStates)
    if(warehouseId.value == '' && categoryId.value == '' && searchByName == '' && typeId == '' && (userType == undefined || userType == '')){
        setButtonDisable(false)
    }

  }, [currentOffset, debounceSearch, warehouseId, categoryId, typeId, userType, userId])

  const fetchCustomers = async () => {
    setDisplayLoader(true)

    const customersResponse = await getRequest(
      `${apiBaseURL()}customers?limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&warehouse_id=${warehouseId.value}&category_ids=${categoryId.value}&reg_by_type=${userType}&reg_by_id=${userId !== '' ? userId.value : ''}`,
      true
    )
    let customerList: any = []
    if (customersResponse.results && customersResponse.results.length > 0) {
      customersResponse.results.map((customer: any) => {
        const verificationStatus = verificationPending(customer)
        customer.pending = verificationStatus.pending
        customer.verification_type = verificationStatus.type

        let customerInfo: any = { ...customer }
        customerList.push(customerInfo)
      })
    }
    setCustomers(customerList)
    setCount(customersResponse.count)
    setNext(customersResponse.next)
    setPrev(customersResponse.previous)
    setDisplayLoader(false)
  }

  // Develop BY: Kriti
  // Description: fetch Warehouse drop-down's options which is called on AsyncPaginate

  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const customerTypes: any = [
    {
      value: '',
      label: 'All Types',
    },
    {
      label: 'Wholesaler',
      value: 'Wholesaler',
    },
    {
      label: 'Retailer',
      value: 'Retailer',
    },
  ]

  // Develop BY: Kriti
  // Description: fetch Customer Types drop-down's options which is called on AsyncPaginate

  const fetchCustomerTypes = async (search?: any) => {
    let options = customerTypes

    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  // Develop BY: Kriti
  // Description: fetch Category drop-down's options which is called on AsyncPaginate

  const fetchCategories = async (search?: any) => {
    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/customer-category-parent/list/`,
      true
    )

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    const categoryKeys = Object.keys(categoriesResponse)

    categoryKeys.forEach((value) => {
      let optionId: string = ''

      options.push({
        label: value,
        value: optionId,
        color: '#6d6e6f',
        bg: '#f7f7f7',
        transform: 'uppercase',
        boxSizing: 'borderBox',
        weight: '500',
        cursor: 'default',
        size: '.75rem !important',
      })

      categoriesResponse[value].map((option: any) => {
        if (optionId === '') {
          optionId += option.id
        } else {
          optionId += ',' + option.id
        }
        options.push({
          label: option.category,
          value: option.id,
        })
        return options
      })

      let index = options.findIndex((x: any) => x.label === value)
      options[index].value = optionId
    })

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options: options,
      hasMore: false,
    }
  }

  // Develop BY: Kriti
  // Description: OnChange Handler

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    switch (inputName) {
      case 'warehouse':
        setWarehouseId(e)
        setUserOffset('')
        setUserReset(moment())
        setFilterStates({ ...filterStates, ['warehouseId']: e })
        break
      case 'category':
        setCategoryId(e)
        setFilterStates({ ...filterStates, ['categoryId']: e })
        break
      case 'type':
        setTypeId(inputValue)
        setFilterStates({ ...filterStates, ['typeId']: inputValue })
        break
      case 'user-type':
        setUserType(inputValue)
        setUserOffset('')
        setUserReset(moment())
        setUserId('')
        setFilterStates({ ...filterStates, ['userType']: inputValue })
        break
      case 'users':
        setUserId(e)
        setFilterStates({ ...filterStates, ['userId']: e })
        break
      default:
        setCurrentOffset(0)
        setSelected(0)
        setSearchByName(inputValue)
        setFilterStates({ ...filterStates, ['search']: inputValue })
    }
  }


  // Develop BY: Kriti
  // Description: set customer-id in localstorage 

  const goToOverview = (cId: any) => {
    localStorage.setItem('customerPermission', "allCustomer")
    localStorage.setItem('cId', cId)
    saveFilter(filters.ts_all_customers , filterStates)
    navigate('singlecustomer/overview')
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  const fetchUsers = async (search?: any) => {
    //auth/users/team/list?offset=0&warehouse__id=6&type=sr
    const options: any = []
    let hasMore: boolean = false

    if (userType !== "") {
      if (search !== "") {
        setSearchSelect(search)
      }

      const wId: any = (!['ts'].includes(userType)) ? warehouseId.value : ''

      const response: any = await getRequest(`${apiBaseURL()}auth/users/team/list?search=${searchSelect}&type=${userType}&limit=${limit}&offset=${userOffset}&ordering=first_name&warehouse__id=${wId}`, true)

      if (!response.results) {
        hasMore = false
      } else {
        if (response.next !== null) {
          const queryParams = new URLSearchParams(response.next)
          let newOffset: any = queryParams.get('offset')
          setUserOffset(newOffset)
          hasMore = true
        }

        if (options.length === 0) {
          options.push({
            label: 'All Users',
            value: '',
          })
        }

        if (response.results.length > 0) {
          response.results.map((option: any) => {
            options.push({
              label: option.first_name + " " + option.last_name + " (" + option.type.toUpperCase() + ")",
              value: option.id,
            })
          })
        }
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleChangeReset = () =>{
    setSearchByName('')
    setWarehouseId({label : 'All Warehouses' , value : ''})
    setCategoryId({label : 'All Categories' , value : ''})
    setUserId({label : 'All Users' , value : ''})
    setTypeId('')
    setUserType('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['warehouseId'] = ''
    updatedState['categoryId'] = ''
    updatedState['typeId'] = ''
    updatedState['userId'] = ''
    updatedState['userType'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <div>
        <EATitle title='Customer' />
        <PageTitle breadcrumbs={[customerHeaderTitle]}>
          {intl.formatMessage({ id: 'Customers' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess && messageHandler !== ""}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('CUSTOMER.ADD', messageTitle)
              : FormatMessage('CUSTOMER.UPDATE', messageTitle)
          }
        />
        <div className='card'>
          {/* begin::Header */}
          {displayLoader && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={handleChange} value={searchByName} isDisable={displayLoader}/>
            <div className='ms-md-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form className='card-toolbar d-flex my-0' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                <AsyncPaginate
                  loadOptions={fetchWarehouses}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  placeholder='All Warehouses'
                  value={warehouseId}
                  // value={warehouses.find((option: any) => option.value === warehouseId)}
                  onChange={(e: any) => handleChange(e, 'warehouse')}
                  isDisabled={displayLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCategories}
                  isSearchable
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  placeholder='All Categories'
                  styles={categoryStyle}
                  name='categories'
                  value={categoryId}
                  // value={categories.filter((option: any) => option.value === categoryId)}
                  onChange={(e: any) => handleChange(e, 'category')}
                  isDisabled={displayLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCustomerTypes}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Types'
                  value={customerTypes.find((option: any) => option.value == typeId)}
                  onChange={(e: any) => handleChange(e, 'type')}
                  isDisabled={displayLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchUserTypes}
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='Added By ...'
                  // value={userType}
                  value={userTypes.find((option: any) => option.value === userType)}
                  onChange={(e: any) => handleChange(e, 'user-type')}
                  isDisabled={displayLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {
                  userType !== "" && <AsyncPaginate
                    key={userReset}
                    loadOptions={fetchUsers}
                    className='react-select-container my-1 me-3 w-200px'
                    classNamePrefix='react-select'
                    placeholder='All Users'
                    onChange={(e: any) => handleChange(e, 'users')}
                    isDisabled={displayLoader}
                    value={userId || ''}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                }
                <button className='btn btn-primary me-3' onClick={() => navigate('manage-customer')}><i className="fa fa-plus" style={{textAlign: "center"}}></i></button>
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </form>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {customers.length == 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-60px'>ID</th>
                      <th className='min-w-170px'>Name</th>
                      <th className='min-w-170px'>Address</th>
                      <th className='min-w-170px'>Contact Info</th>
                      <th className='min-w-140px'>Category</th>
                      <th className='min-w-120px'>Type</th>
                      <th className='min-w-180px'>Warehouse</th>
                      <th className='min-w-120px'>Credit Range</th>
                      <th className='min-w-120px'>Credit Issue</th>
                      <th className='min-w-130px'>Added By</th>
                      <th className='min-w-100px'>Verification</th>
                      <th className='min-w-80px action-th py-2'>Actions</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {customers.map((customer: any) => {
                      return (
                        <>
                          <tr>
                          <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                              >
                              <div className='media-body'>
                                <p className='m-0 xs-font'>#{customer.id}</p>
                              </div>
                            </td>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              <div className='d-flex align-items-center'>
                                <span
                                  className='rounded-circle pro-icon me-2'
                                  data-bs-toggle='tooltip'
                                  title={customer.business_name}
                                >
                                  {customer.logo_thumbnail === null ? (
                                    <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} alt='' className="rounded-circle pro-icon" />
                                  ) : (
                                    <img src={customer.logo_thumbnail} alt='' className="rounded-circle pro-icon" />
                                  )}
                                </span>
                                <div className='media-body'>
                                  <p className='m-0 xs-font'>{customer.business_name}</p>
                                </div>
                              </div>
                            </td> 
                            <td
                              onClick={() => goToOverview(customer.id)}
                              className='cursor-pointer'
                            >
                              { checkEmpty(customer.address, '-')}
                            </td>
                            <td
                              onClick={() => goToOverview(customer.id)}
                              className='cursor-pointer'
                            >
                              <div>
                                <div className='mb-1'>
                                  {tenantConfiguration.baseCountryCode} {customer.contact_no}
                                </div>
                                {![null, ''].includes(customer.email) && <div className="mb-1">
                                  {customer.email  ? customer.email : ''}
                                </div>}
                              </div>
                            </td>
                            <td
                              onClick={() => goToOverview(customer.id)}
                              className='cursor-pointer'
                            >
                              <div>
                                <div className='text-muted font-13 mb-1 font-medium text-capitalize'>
                                  {customer.nature_of_business
                                    ? customer.nature_of_business
                                    : '-'}
                                </div>
                                {customer.category_name
                                  ? customer.category_name
                                  : '-'}
                              </div>
                            </td>
                            <td
                              onClick={() => goToOverview(customer.id)}
                              className='cursor-pointer text-capitalize'
                            >
                              {customer.type}
                            </td>
                            <td
                              onClick={() => goToOverview(customer.id)}
                              className='cursor-pointer'
                            >
                              {/* Warehouse Name */}
                              {customer.warehouse_name
                                ? customer.warehouse_name
                                : '-'}
                            </td>
                            <td
                              onClick={() => goToOverview(customer.id)}
                              className='cursor-pointer'
                            >
                               {customer && customer.credit_range ? customer.credit_range : '-'}
                            </td>
                            <td
                              onClick={() => goToOverview(customer.id)}
                              className='cursor-pointer'
                            >
                              {/* TZS-{customer.credit_amount_cap} */}-
                            </td>
                            <td
                              onClick={() => goToOverview(customer.id)}
                              className='cursor-pointer'
                            >
                              {displayAddedBy(customer.reg_by)}
                            </td>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              {customer.pending == 0 ? (
                                <>
                                  <span className='btn py-0 ps-1 pe-0 btn-sm me-3'>
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr012.svg'
                                      className='ic mr-0'
                                    />
                                  </span>
                                </>
                              ) : (
                                customer.type.length > 0 && (
                                  <div className='d-flex align-items-center'>
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props} className='tooltip-dark'>
                                          <span>{formatVerificationType(customer.verification_type)}</span>
                                        </Tooltip>
                                      )}
                                      placement='top'
                                    >
                                      <span className='cursor-pointer text-decoration-underline'>
                                        {customer.pending} Pending
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                )
                              )}
                            </td>
                            <td className='text-end'>
                              <a
                                href='#'
                                onClick={() => {
                                  localStorage.setItem('cId', customer.id)
                                  navigate('manage-customer')
                                }}
                              >
                                <span className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'>
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='ic mr-0'
                                  />
                                </span>
                              </a>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={customers}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.ts_all_customers}
          filterStates={filterStates}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default TSCustomers
