import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { apiBaseURL, dateFormat, downloadFile, KTSVG } from "../../../../../_eaFruitsDms/helpers"
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const ApplicationDetails = () => {
  const navigate = useNavigate()
  const applicationId = localStorage.getItem('applicationId')
  const intl = useIntl()
  const cmsApplications: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Career',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  const adminHeaderTitle = {
    title: 'CMS',
  }

  const [details, setDetails] = useState<any>([])
  const [listLoadder, setListLoader] = useState<any>(true)

  useEffect(() => {
    fetchApplicationDetails().then()
  }, [])

  // Develop BY: Kriti
  // Description: fetchDetail from get ID

  const fetchApplicationDetails = async () => {
    let applications = await getRequest(`${apiBaseURL()}career/Application/${applicationId}/`, true)
    setDetails(applications)
    setListLoader(false)
  }

  return (
    <>
      <div>
        <EATitle title='Job Applications - ' />
        <PageTitle breadcrumbs={cmsApplications} backNavigate='/cms/career/application'>
          {intl.formatMessage({id: 'Job Applications'})}
        </PageTitle>
        {/* <div className='card mb-5 mb-xl-10'> */}
        {listLoadder ? (
          <IsLoader />
        ) : (
          <>
            <div className='card mb-4 mb-xl-5'>
              <div className='card-body pt-5 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap'>
                  <div className='flex-grow-1'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap'>
                      <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center mb-1'>
                          <span className='text-dark font-17 fw-bold me-1 mb-4'>
                            Current Opening
                          </span>
                          <span className='v-seperator'></span>
                          <span className='text-dark font-17 mb-4'>
                            {details.currentopenings_id !== null
                              ? details.currentopenings_id.title
                              : 'General Application'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-body py-5'>
                <div className='row mb-6'>
                  <label className='col-lg-12 font-14 text-muted'>Name</label>
                  <div className='col-lg-12'>
                    <span className='font-14 text-dark'>
                      {details.firstname + ' ' + details.lastname}
                    </span>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-12 font-14 text-muted'>Email</label>
                  <div className='col-lg-12'>
                    <span className='font-14 text-dark'>{details.email}</span>
                  </div>
                </div>
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-12 font-14 text-muted'>Mobile No.</label>
                    <div className='col-lg-12'>
                      <span className='font-14 text-dark'>{details.phone_no}</span>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-12 font-14 text-muted'>Address</label>
                    <div className='col-lg-12'>
                      <span className='font-14 text-dark'>{details.address}</span>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-12 font-14 text-muted'>Received Date</label>
                    <div className='col-lg-12'>
                      <span className='font-14 text-dark'>
                        {dateFormat(details.created_at).displayDate}{' '}
                        {dateFormat(details.created_at, 'LT').displayDate}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-6 '>
                    <label className='col-lg-12 font-14 text-muted'>Resume</label>
                    <div className='col-lg-12'>
                      <button
                        type='button'
                        onClick={() => downloadFile(details.resume)}
                        className='mx-auto btn btn-secondary btn-sm btn-icon-dark btn-text-dark'
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil021.svg'
                          className='svg-icon-muted ic'
                        />
                        Download
                      </button>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
        {/* </div> */}
      </div>
    </>
  )
}

export default ApplicationDetails
