import {
  SUPPORT_REQUEST_REPORT_WAREHOUSE,
  SUPPORT_REQUEST_REPORT_START_DATE,
  SUPPORT_REQUEST_REPORT_END_DATE,
  SUPPORT_REQUEST_REPORT_DATE,
} from '../../types/types'

export const setWarehouseValue = (val: any) => (dispatch: any) => {
  dispatch({
    type: SUPPORT_REQUEST_REPORT_WAREHOUSE,
    payload: val,
  })
}

export const setStartDate = (val: any) => (dispatch: any) => {
  dispatch({
    type: SUPPORT_REQUEST_REPORT_START_DATE,
    payload: val,
  })
}

export const setEndDate = (val: any) => (dispatch: any) => {
  dispatch({
    type: SUPPORT_REQUEST_REPORT_END_DATE,
    payload: val,
  })
}

export const setDate = (val: any) => (dispatch: any) => {
  dispatch({
    type: SUPPORT_REQUEST_REPORT_DATE,
    payload: val,
  })
}
