import React from 'react'

const ThreeDotsLoader = ({color = '#0c78a4', size = 50}) => {
  const dotStyle = {
    backgroundColor: color,
    width: size / 5,
    height: size / 5,
    borderRadius: '50%',
    margin: `0 ${size / 10}px`,
    animation: 'dot-flash 1.5s infinite ease-in-out',
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div style={{...dotStyle, animationDelay: '0s'}}></div>
      <div style={{...dotStyle, animationDelay: '0.2s'}}></div>
      <div style={{...dotStyle, animationDelay: '0.4s'}}></div>

      {/* Custom animation styles */}
      <style>
        {`
          @keyframes dot-flash {
            0%, 80%, 100% {
              transform: scale(0);
            }
            40% {
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  )
}

export default ThreeDotsLoader
