import clsx from "clsx"
import $ from "jquery"
import moment from "moment"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import {
  apiBaseURL,
  dateFormat,
  focusVatidateField,
  GLOBAL_DATE_FORMAT,
  isModalValidation,
  toAbsoluteUrl,
} from "../../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage"
import DatePickerCustom from "../../../../../_eaFruitsDms/helpers/datePicker"
import { getRequest, postRequest, putRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import CustomSunEditor from "../../../../../_eaFruitsDms/layout/components/sunEditor/CustomSunEditor"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import Imagecropper from "./imagecropper"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"

const cmsStory: Array<PageLink> = [
  {
    title: 'CMS',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Success Stories',
    path: '',
    isSeparator: false,
    isActive: false,
  },
]

const AddSuccessStories = () => {
  const navigate = useNavigate()
  const storiesId = localStorage.getItem('storyId')
  const usertype = localStorage.getItem('StoryType')

  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'CMS',
  }

  const initialStories = {
    firstname: '',
    lastname: '',
    story_title: '',
    subtitle: '',
    publish_date: '',
    date_view: new Date(),
    usertype: usertype,
    story_description: '',
    story_short_description: '',
    location_text: '',
    profile_picture: '',
    is_active: 'true',
  }
  const initialStoriesValidation = {
    firstname: '',
    lastname: '',
    story_title: '',
    subtitle: '',
    publish_date: '',
    usertype: '',
    story_description: '',
    story_short_description: '',
    location_text: '',
    profile_picture: '',
    is_active: '',
  }

  const initialDateSetting: any = {
    startDate: moment(),
    maxDate: moment(),
    alwaysShowCalendars: true,
    autoUpdateInput: false,
    cancelClass: 'btn-secondary',
    singleDatePicker: true,
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    drops: 'auto',
    showDropdowns: true,
  }
  const [stories, setStories] = useState<any>(initialStories)
  const [imageValidation, setImageValidation] = useState<boolean>(false)
  const [img, setImg] = useState<any>('')
  const [imageSize, setImageSize] = useState('')
  const [imageRequire, setImageRequire] = useState('')
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [cropData, setCropData] = useState(defaultSrc)
  const [imgSizeInvalid, setimgSizeInvalid] = useState('')
  const [limit, setLimit] = useState<number>(10)
  const [storiesValidation, setStoriesValidation] = useState<any>(initialStoriesValidation)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [storyDetailInitial, setStoryDetailInitial] = useState<any>(initialStories)
  const [imageRequireMessage, setImageRequireMessage] = useState('')
  const [isButtonLoader, setIsButtonLoader] = useState<any>('Submit')
  const [datePicker, setDatePicker] = useState<any>('')
  const [isCalenderOpen, setIsCalenderOpen] = useState<boolean>(false)
  const [accessSts, setAccessSts] = useState<any>(true)
  const [value, onChange] = useState(new Date())
  const [description, setDescription] = useState('')
  const [descriptionValidation, setDescriptionValidation] = useState('')
  const [initialSettings, setInitialSettings] = useState<any>(initialDateSetting)
  const [dateShow, setDateShow] = useState('Select')
  const [isResonseLoading, setIsResponseLoading] = useState<any>('')

  useEffect(() => {
    fetchStoriesDetail().then()
  }, [])

  useEffect(() => {
    setStories(storyDetailInitial)
  }, [storyDetailInitial])

  useEffect(() => {
    if (datePicker != '') {
      setIsCalenderOpen(false)
    }
  }, [datePicker])

  useEffect(() => {
    let updateSuccessStory: any = { ...stories }
    updateSuccessStory.publish_date = moment(value).format('YYYY-MM-DD')
    setStories(updateSuccessStory)
  }, [value])

  const fetchStoriesDetail = async () => {
    setStories(initialStories)
    if (storiesId != null) {
      setIsResponseLoading(true)
      let storiesResponse = await getRequest(`${apiBaseURL()}successstories/${storiesId}/`, true)
      if (storiesResponse) {
        let storiesDetail: any = { ...storyDetailInitial }

        Object.entries(storiesResponse).map((object: any) => {
          if (object[0] in storiesDetail) {
            return (storiesDetail[object[0]] = object[1])
          }
        })

        setAccessSts(storiesResponse.is_active)
        if (storiesResponse.profile_picture != null) {
          setCropData(storiesResponse.profile_picture)
        } else {
          setCropData(defaultSrc)
        }
        if (storiesResponse.publish_date) {
          const dob = dateFormat(storiesResponse.publish_date)
          setDateShow(dob.displayDate)
          setInitialSettings({ ...initialDateSetting, startDate: dob.displayDate })
        }
        setStoryDetailInitial(storiesDetail)
        setIsResponseLoading(false)
      }
    }
  }

  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.Jfif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImg(URL.createObjectURL(file))
        setStories({ ...stories, ['profile_picture']: e.target.files[0] })
      }
      let img: any = ''
      if (e.target.files[0]) {
        setImageRequire('')
        setImageRequireMessage('')
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setimgSizeInvalid('text-danger')
            setImageValidation(false)
            setImageRequireMessage('')
          } else {
            setimgSizeInvalid('')
            setImageValidation(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }
  const getImage = (val: any) => {
    setStories({ ...stories, ['profile_picture']: val })
  }

  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let date = start._d
          let newDate = dateFormat(start._d)
          setStories({ ...stories, publish_date: newDate.apiDate })
          setDateShow(newDate.displayDate)
          setInitialSettings((pre: any) => ({ ...pre, startDate: start._d }))
        }}
        initialSettings={initialSettings}
        dateValidation={'w-100'}
        dateValue={dateShow}
      />
    )
  }

  const handleChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setStoriesValidation({ ...storiesValidation, [inputName]: '' })
    let updatedStories: any = { ...stories }

    switch (inputName) {
      case 'story_title':
        updatedStories[inputName] = inputValue
        break
      case 'firstname':
        updatedStories[inputName] = inputValue
        break
      case 'lastname':
        updatedStories[inputName] = inputValue
        break
      case 'subtitle':
        updatedStories[inputName] = inputValue
        break
      case 'story_description':
        updatedStories[inputName] = inputValue
        break
      case 'story_short_description':
        updatedStories[inputName] = inputValue
        break
      case 'location_text':
        updatedStories[inputName] = inputValue
        break
    }
    setStories(updatedStories)
  }

  const handleDescription = (e: any) => {
    setDescriptionValidation('')
    setDescription(e)
  }

  const validateForm = () => {
    const validation = { ...stories }
    let isValid = true

    let navigateField = {
      story_title: stories.story_title,
      firstname: stories.firstname,
      lastname: stories.lastname,
      subtitle: stories.subtitle,
      story_short_description: stories.story_short_description,
      description: description,
      profile_picture: stories.profile_picture,
      location_text: stories.location_text,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (stories.firstname === '') {
      validation.firstname = 'is-invalid'
      isValid = false
    }
    if (stories.lastname === '') {
      validation.lastname = 'is-invalid'
      isValid = false
    }
    if (stories.story_title === '') {
      validation.story_title = 'is-invalid'
      isValid = false
    }
    if (stories.subtitle === '') {
      validation.subtitle = 'is-invalid'
      isValid = false
    }
    // if (stories.story_description === '') {
    //   // validation.story_description = 'is-invalid'
    // setDescriptionValidation('is-invalid')
    // isValid = false
    // }

    if (description === '' || description === '<p><br></p>') {
      setDescriptionValidation('is-invalid')
      isValid = false
    }
    if (stories.story_short_description === '') {
      validation.story_short_description = 'is-invalid'
      isValid = false
    }
    if (stories.location_text === '') {
      validation.location_text = 'is-invalid'
      isValid = false
    }
    if (stories.profile_picture === '' || imgSizeInvalid == 'text-danger') {
      setImageRequire('file-icon-inValid')
      if (imgSizeInvalid != 'text-danger') {
        setImageRequireMessage('file-icon-inValid')
      }
      validation.profile_picture = 'is-invalid'
      isValid = false
    }
    setStoriesValidation(validation)
    return isValid
  }

  const addStories = () => {
    const isValid = validateForm()
    if (isValid) {
      setIsButtonLoader('Please Wait...')
      let customerData = new FormData()
      const customerInfo: any = { ...stories }
      delete customerInfo.date_view

      customerInfo.story_description = description

      Object.entries(customerInfo).map((object: any) => {
        if (object[0] == 'profile_picture') {
          if (object[1] != null && typeof object[1] != 'string') {
            return customerData.append(object[0], object[1])
          }
        } else {
          return customerData.append(object[0], object[1])
        }
      })

      if (storiesId != null) {
        putRequest(`${apiBaseURL()}successstories/${storiesId}/`, customerData, true).then(
          (res) => {
            handleResponse(res, 'Edit')
          }
        )
      } else {
        postRequest(`${apiBaseURL()}successstories/create`, customerData, true).then((res) => {
          handleResponse(res, 'Add')
        })
      }
    }
  }

  const fetchRedirect = () => {
    if (usertype == 'Employee') {
      navigate('/cms/scuccess-stories/employees')
    }
    if (usertype == 'Farmer') {
      navigate('/cms/scuccess-stories/farmers')
    }
    if (usertype == 'Customer') {
      navigate('/cms/scuccess-stories/vendors')
    }
  }

  const handleResponse = (res: any, type: string) => {
    setIsButtonLoader('Submit')
    if (res.status === 201 || res.status === 200 || res.status === 204) {
      setIsAlertSuccess(true)
      setStories(initialStories)
      localStorage.setItem('type', type)
      localStorage.removeItem('storyId')
      fetchRedirect()
    } else {
      setIsAlertFailed(true)
    }
    setTimeout(() => {
      setIsAlertFailed(false)
      setIsAlertSuccess(false)
    }, 1500)
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <div>
        {usertype === 'Employee' ? (
          <EATitle title='Employees Success Stories - ' />
        ) : usertype === 'Farmer' ? (
          <EATitle title='Farmers Success Stories - ' />
        ) : (
          usertype === 'Customer' && <EATitle title='Customer Success Stories - ' />
        )}

        {usertype === 'Employee' ? (
          <PageTitle breadcrumbs={cmsStory} backNavigate='/cms/scuccess-stories/employees'>
            {intl.formatMessage({
              id:
                storiesId == null
                  ? 'Add Employee Success Stories '
                  : 'Edit Employee Success Stories',
            })}
          </PageTitle>
        ) : usertype === 'Farmer' ? (
          <PageTitle breadcrumbs={cmsStory} backNavigate='/cms/scuccess-stories/farmers'>
            {intl.formatMessage({
              id: storiesId == null ? 'Add Farmer Success Stories ' : 'Edit Farmer Success Stories',
            })}
          </PageTitle>
        ) : (
          usertype === 'Customer' && (
            <PageTitle breadcrumbs={cmsStory} backNavigate='/cms/scuccess-stories/vendors'>
              {intl.formatMessage({
                id:
                  storiesId == null
                    ? 'Add Customer Success Stories'
                    : 'Edit Customer Success Stories',
              })}
            </PageTitle>
          )
        )}

        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={FormatMessage('success')}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        {isResonseLoading !== '' && isResonseLoading == true && <IsLoader />}
        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
            {/* begin::Title */}
            <h3 className='card-title align-items-start flex-column my-0'>
              {usertype === 'Employee' ? (
                <>
                  {storiesId == null ? (
                    <>
                      <span className='card-label fw-bold font-17'>
                        Add Employee Success Stories
                      </span>
                    </>
                  ) : (
                    <span className='card-label fw-bold font-17'>
                      Edit Employee Success Stories
                    </span>
                  )}
                </>
              ) : usertype === 'Farmer' ? (
                <>
                  {storiesId == null ? (
                    <>
                      <span className='card-label fw-bold font-17'>Add Farmer Success Stories</span>
                    </>
                  ) : (
                    <span className='card-label fw-bold font-17'>Edit Farmer Success Stories</span>
                  )}
                </>
              ) : (
                usertype === 'Customer' && (
                  <>
                    {storiesId == null ? (
                      <>
                        <span className='card-label fw-bold font-17'>
                          Add Customer Success Stories
                        </span>
                      </>
                    ) : (
                      <span className='card-label fw-bold font-17'>
                        Edit Customer Success Stories
                      </span>
                    )}
                  </>
                )
              )}
            </h3>
            {/* end::Title */}
          </div>
          {/* <div className='card-body pt-4'> */}
          <form onSubmit={(e) => e.preventDefault()} autoComplete='off' className='card-body pt-4'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='mb-4'>
                  <label className='form-label'>
                    Success Story Title<span className="text-danger">*</span>
                  </label>
                  <input
                    type='text'
                    id='story_title'
                    className={clsx('form-control', storiesValidation.story_title)}
                    name='story_title'
                    value={stories.story_title}
                    placeholder='Type here...'
                    onChange={(e: any) => handleChange(e, 'story_title')}
                  />
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='mb-3'>
                      <label className='form-label'>
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type='text'
                        id='firstname'
                        className={clsx('form-control', storiesValidation.firstname)}
                        placeholder='Type here...'
                        value={stories.firstname}
                        name='firstname'
                        onChange={(e) => handleChange(e, 'firstname')}
                      />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='mb-3'>
                      <label className='form-label'>
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type='text'
                        id='lastname'
                        className={clsx('form-control', storiesValidation.lastname)}
                        placeholder='Type here...'
                        value={stories.lastname}
                        name='lastname'
                        onChange={(e) => handleChange(e, 'lastname')}
                      />
                    </div>
                  </div>

                  <div className='mb-4'>
                    <label className='form-label'>
                      Sub Title<span className="text-danger">*</span>
                    </label>
                    <input
                      className={clsx('form-control', storiesValidation.subtitle)}
                      id='subtitle'
                      placeholder='Type here…'
                      value={stories.subtitle}
                      name='subtitle'
                      onChange={(e: any) => handleChange(e, 'subtitle')}
                    ></input>
                  </div>
                  <div className='mb-4'>
                    <label className='form-label'>
                      Short Story Description<span className="text-danger">*</span>
                    </label>
                    <input
                      type='text'
                      id='story_short_description'
                      className={clsx('form-control', storiesValidation.story_short_description)}
                      name='story_short_description'
                      value={stories.story_short_description}
                      placeholder='Type here...'
                      onChange={(e: any) => handleChange(e, 'story_short_description')}
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='form-label' id='description'>
                      Success Story Description<span className="text-danger">*</span>
                    </label>
                    <CustomSunEditor
                      data={stories.story_description}
                      handleChange={handleDescription}
                      fieldName='story_description'
                      validationTriggered={descriptionValidation}
                      message='Success Story Description Field is required.'
                    />
                  </div>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <label className='form-check-label me-3'>Publish Story</label>
                    <input
                      checked={stories.is_active}
                      id='accessStatus'
                      onChange={(e) => {
                        let checkedEdit = $('#accessStatus').is(':checked')
                        if (checkedEdit == true) {
                          setAccessSts(true)
                          setStories({ ...stories, ['is_active']: true })
                        } else {
                          setAccessSts(false)
                          setStories({ ...stories, ['is_active']: false })
                        }
                      }}
                      value={stories.is_active}
                      className='form-check-input h-30px w-50px'
                      type='checkbox'
                      name='layout-builder[layout][header][fixed][desktop]'
                      defaultChecked={true}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div id='profile_picture'>
                  <Imagecropper
                    title={usertype}
                    getImage={getImage}
                    onImageChange={onImageChange}
                    imageRequire={imageRequire}
                    imageRequireMessage={imageRequireMessage}
                    imageSize={imgSizeInvalid}
                    cropData={cropData}
                    setCrop={setCropData}
                  />
                </div>
                <div className='row'>
                  <div className='mb-4'>
                    <label className='form-label'>
                      Location<span className="text-danger">*</span>
                    </label>
                    <input
                      type='text'
                      id='location_text'
                      className={clsx('form-control', storiesValidation.location_text)}
                      name='location_text'
                      value={stories.location_text}
                      placeholder='Type here...'
                      onChange={(e: any) => handleChange(e, 'location_text')}
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='form-label' id='publish_date'>
                      Publish Date
                    </label>
                    <CustomDatePicker />
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* </div> */}
          <div className='card-footer py-2'>
            <button
              type='button'
              className='btn btn-secondary me-3'
              onClick={() => {
                fetchRedirect()
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              disabled={isButtonLoader !== 'Submit'}
              onClick={() => {
                addStories()
              }}
            >
              {isButtonLoader}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddSuccessStories
