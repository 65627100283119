import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import {
  apiBaseURL,
  checkPermissions,
  cmsInquiresTitle,
  dateFormat,
  defaultPermission,
  KTSVG
} from "../../../../../_eaFruitsDms/helpers"
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce"

type Props = {
  className: string
}

const ContactUs: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const masterHeaderTitle = {
    title: 'Categories',
    subTitle: 'CMS',
  }

  // all states
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [count, setCount] = useState('1')
  const [timer, setTimer] = useState<number>(0)
  const [search, setSearch] = useState<any>('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [limit, setLimit] = useState<number>(10)
  const [contactList, setContactList] = useState([])
  const [permission, setPermission] = useState(defaultPermission)
  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    checkPermissions("cms").then((res: any) => {
      setPermission(res)
      console.log(res);
    })
  }, [])

  useEffect(() => {
    localStorage.removeItem('inquiriesId')
    localStorage.removeItem('inquiriesType')
    setTimeout(() => fetchContactUs().then(), timer)
  }, [currentOffset, debounceSearch, count, contactList.length])

  const fetchContactUs = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}cms/inquiries/?inquiry_type=General&limit=${limit}&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    setContactList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChangeSearch = (e: any) => {
    let inputValue = e.target ? e.target.value : e.value
    setSearch(inputValue)
  }

  const redirectPage = (item: any) => {
    localStorage.setItem('inquiriesId', item.id)
    localStorage.setItem('inquiriesType', item.inquiry_type)
    navigate('details')
  }

  return (
    <>
      <EATitle title='Contact Us - ' />
      <PageTitle breadcrumbs={cmsInquiresTitle}>{intl.formatMessage({ id: 'Contact Us' })}</PageTitle>
      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={handleChangeSearch} />
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
          {contactList.length == 0 ? (
                  <NoRecords />
                ) : (
                  <>
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-120px py-2'>Name</th>
                          <th className='min-w-120px py-2'>Email</th>
                          <th className='min-w-120px py-2'>Mobile No</th>
                          <th className='min-w-120px py-2'>Inquiry Subject</th>
                          <th className='min-w-120px py-2'>Received Date</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactList &&
                          contactList.length > 0 &&
                          contactList.map((item: any) => {
                            return (
                              <>
                                <tr className="cursor-pointer">
                                  <td onClick={() => redirectPage(item)}>{item.first_name + " " + item.last_name}</td>
                                  <td onClick={() => redirectPage(item)}>{item.email}</td>
                                  <td onClick={() => redirectPage(item)}>{item.mobile_number}</td>
                                  <td onClick={() => redirectPage(item)}>{item.inquiry_subject}</td>
                                  <td onClick={() => redirectPage(item)}>
                                    {dateFormat(item.received_on).displayDate}{' '}
                                    {dateFormat(item.received_on, 'LT').displayDate}
                                  </td>
                                  <td className=' text-end' onClick={() => redirectPage(item)}>
                                    <span className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                      <KTSVG path='/media/icons/duotune/arrows/arr007.svg' />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                      </tbody>
                    </table>
                  </>
                )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${contactList.length == 0 ? 0 : selected * limit + 1} to ${contactList.length == 0 ? 0 : contactList.length < 10 ? count : (selected + 1) * 10
              } of ${contactList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              prev={prevPage}
              next={nextPage}
              count={count}
              selected={selected}
              numberClick={numberClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs
