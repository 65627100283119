import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  discountCappingPostUrl,
  discountCappingUrl,
} from '../../../_eaFruitsDms/apiFunctions/priceDiscount/apiListPrice'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
  numberFormat,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import ValidationHandler from '../../../_eaFruitsDms/helpers/components/validationHandler'
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../_eaFruitsDms/layout/components/pagination/pagination'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import tenantConfiguration from '../../../TenantVariables'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'

type Props = {
  className: string
}
const DiscountCapping: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const priceHeaderTitle = [
    {
      title: 'Price & Discounts',
    },
  ]

  const initialRow: any = {
    user_type: 'ss',
    wholesaler_percentage: '0',
    retailer_percentage: '0',
    warehouse: localStorage.getItem('w_id'),
  }
  const initialCustomerValidation: any = {
    wholesaler_percentage: '',
    retailer_percentage: '',
  }

  let discountCappingFiltered: any = retrieveFilter(filters.wm_discount_capping)

  const initialState = {
    selected: discountCappingFiltered?.selected || '',
    search: discountCappingFiltered?.search || '',
    currentOffSet: discountCappingFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  const [getList, setGetList] = useState<any>([]) // getdata
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(false)
  const [timer, setTimer] = useState<any>(null)
  const [currentOffset, setCurrentOffset] = useState<any>(
    discountCappingFiltered?.currentOffSet || '0'
  )
  const [search, setSearch] = useState<string>(discountCappingFiltered?.search || '')
  const [selected, setSelected] = useState<number>(discountCappingFiltered?.selected || 0)
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [checkMinMaxValue, setCheckMinMaxValue] = useState(true)
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [searchSelect, setSearchSelect] = useState('')
  const [DeleteId, setDeleteId] = useState<any>('')

  const [priceValue, setPriceValue] = useState({
    minOrder: '',
    maxOrder: '',
    wholesaleDiscount: '',
    retailDiscount: '',
  })

  const [oldPriceValue, setOldPriceValue] = useState({
    minOrder: '',
    maxOrder: '',
  })

  const [validation, setValidation] = useState({
    minOrder: '',
    maxOrder: '',
    wholesaleDiscount: '',
    retailDiscount: '',
  })

  const [permission, setPermission] = useState(defaultPermission)
  const debounceSearch = useDebounce(search, 500)
  const [modalLoader, setModalLoader] = useState<any>(false)

  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    discountCappingFiltered?.buttonDisable || false
  )

  const [creditRequest, setCreditRequest] = useState<any>(initialRow)
  const [creditRequestValidation, setCreditRequestValidation] =
    useState<any>(initialCustomerValidation)

  useEffect(() => {
    fetchPermission()
    // Develop BY: Kashyap
    // Description: get table list
    getListFromApi(limit, currentOffset, debounceSearch)

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.wm_discount_capping, filterStates)
    if (search == '') {
      setButtonDisable(false)
    }
  }, [debounceSearch, currentOffset])

  const fetchPermission = async () => {
    let res = await checkPermissions('price_and_discount_discount_capping_management')
    setPermission(res)
  }

  const getListFromApi = (limit: any, offset: any, debounceSearch: any) => {
    setListLoader(true)
    // let url = limit=${limit}&offset=${offset}&warehouse_id=${localStorage.getItem('w_id')}&ordering=name&search=${debounceSearch}
    // warehouse_id=${localStorage.getItem('w_id')}

    const url = `${apiBaseURL()}pricing/additional-discount/cap?warehouse_id=${localStorage.getItem(
      'w_id'
    )}`

    getRequest(url, true).then((res: any) => {
      setGetList(res.results)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.previous)
      if (res.results.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
  }

  // const numberClick = (val: any, index: any) => {
  //   if (val) {
  //     setSelected(val - 1)
  //     let newOffset = index * 10
  //     setCurrentOffset(newOffset)
  //     setListLoader(true)
  //     getListFromApi('10', newOffset, debounceSearch)
  //     setListLoader(false)
  //   }
  // }

  // const prevPage = () => {
  //   if (prev !== null) {
  //     const queryParams = new URLSearchParams(prev)
  //     const newOffset = queryParams.get('offset')
  //     setCurrentOffset(newOffset)
  //     setSelected(selected - 1)
  //     setListLoader(true)
  //     getListFromApi('10', newOffset, debounceSearch)
  //     setListLoader(false)
  //   }
  // }

  // const nextPage = () => {
  //   if (next !== null) {
  //     const queryParams = new URLSearchParams(next)
  //     const newOffset = queryParams.get('offset')
  //     setCurrentOffset(newOffset)
  //     setSelected(selected + 1)
  //     setListLoader(true)
  //     getListFromApi('10', newOffset, debounceSearch)
  //     setListLoader(false)
  //   }
  // }

  const searchList = (e: any) => {
    setButtonDisable(true)
    setSearch(e.target.value)
    setCurrentOffset(0)
    setSelected(0)
    setFilterStates({...filterStates, ['search']: e.target.value})
  }

  const addDiscount = () => {
    let valid = validateForm()

    if (valid) {
      setDisableBtn(true)
      setIsLoading('Please Wait...')
      const creditRequestInfo = {...creditRequest}
      const url = `${apiBaseURL()}pricing/additional-discount/cap/edit`

      putRequest(url, creditRequestInfo, true).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        setIsAlertSuccess(true)
        $('#closeAddModal').trigger('click')
        clearFunctionAdd()
        setIsAlertSuccess(true)
        getListFromApi('10', currentOffset, debounceSearch)
      })

      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)
    }
  }

  const onChangeAdd = (e: any) => {
    const {value, name} = e.target
    setPriceValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const clearFunctionAdd = () => {
    setCreditRequest(initialRow)
    setCreditRequestValidation(initialCustomerValidation)

    setModalLoader(false)
    setPriceValue({
      minOrder: '',
      maxOrder: '',
      wholesaleDiscount: '',
      retailDiscount: '',
    })

    setValidation({
      minOrder: '',
      maxOrder: '',
      wholesaleDiscount: '',
      retailDiscount: '',
    })
    setErrorMessage('')
    setTimeout(() => {
      setRequest('')
    }, 200)
  }
  const onDelete = (item: any) => {
    setDeleteId(item.id)
  }

  const deleteApi = async () => {
    const notify = {
      success: false,
      failed: false,
    }
    await deleteRequest(`${apiBaseURL()}pricing/capping/${DeleteId}/`, true).then((res) => {
      if (res.status === 204) {
        notify.success = true
      }
      if (res.statusText === 'Bad Request') {
        notify.failed = true
      }
    })
    if (notify.success) {
      getListFromApi('10', currentOffset, debounceSearch)
      setIsAlertSuccess(true)
    }
    if (notify.failed) {
      setIsAlertFailed(true)
    }
    $('#closeDeleteModal').trigger('click') // modal close
    setTimeout(() => {
      setIsAlertSuccess(false)
      setIsAlertFailed(false)
    }, 2000)
  }
  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const handleChangeReset = () => {
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = {...filterStates}
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  const handleChange = (e: any) => {
    const inputName = e.target.name
    const inputValue = e.target.value

    if (checkPercentage(inputValue)) {
      setCreditRequestValidation({...creditRequestValidation, [inputName]: ''})
    } else {
      setCreditRequestValidation({...creditRequestValidation, [inputName]: 'is-invalid'})
    }
    setCreditRequest({...creditRequest, [inputName]: inputValue})
  }

  const checkPercentage = (num: any) => {
    const value = parseFloat(num)
    let valid = true
    if (isNaN(value) || value < 0 || value > 100) {
      valid = false
    }

    return valid
  }

  const validateForm = () => {
    let navigateField = {
      wholesaler_percentage: creditRequest.wholesaler_percentage,
      retailer_percentage: creditRequest.retailer_percentage,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])
    let validations = {...creditRequestValidation}
    let isValid = true

    if (creditRequest.wholesaler_percentage === '' || creditRequest.wholesaler_percentage > 100) {
      validations.wholesaler_percentage = 'is-invalid'
      isValid = false
    }

    if (creditRequest.retailer_percentage === '' || creditRequest.retailer_percentage > 100) {
      validations.retailer_percentage = 'is-invalid'
      isValid = false
    }

    setCreditRequestValidation(validations)
    return isValid
  }

  return (
    <>
      <EATitle title='Discount Capping' />
      <div>
        <PageTitle breadcrumbs={priceHeaderTitle}>
          {intl.formatMessage({id: 'Discount Capping'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          // message="success"
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('DISCOUNT.CAPING.ADD')
              : messageHandler === 'successEdit'
              ? FormatMessage('DISCOUNT.CAPING.UPDATE')
              : messageHandler === 'successDelete' && FormatMessage('DISCOUNT.CAPING.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <h1 className='d-flex align-items-center text-dark font-bold my-1 font-17'>
                Discount Capping
              </h1>
            </div>
          </div>
          <div className='card-body py-4'>
            {listLoadder ? (
              <FetchingRecords />
            ) : getList.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-150px'>Approval From</th>
                    <th className='min-w-140px'>Cap for Wholesaler Customer (%)</th>
                    <th className='min-w-120px'> Cap for Retailer Customer (%)</th>
                    <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                  </tr>
                </thead>
                <tbody>
                  {getList.map((item: any) => (
                    <tr>
                      <td>{item.display_user_type}</td>
                      <td>{`${numberFormat(item.wholesaler_percentage, true).displayFormat}`}</td>
                      <td>{`${numberFormat(item.retailer_percentage, true).displayFormat}`}</td>
                      <td className='text-end'>
                        {permission.write && (
                          <a
                            href='#'
                            className='btn py-0 ps-1 pe-0 btn-active-color-primary btn-sm me-3'
                            data-bs-toggle='modal'
                            data-bs-target='#ea_modal_create_discount'
                            onClick={() => {
                              setModalLoader(true)

                              const formDetail = item
                              let updatedData: any = {...creditRequest}
                              updatedData['wholesaler_percentage'] =
                                formDetail.wholesaler_percentage
                              updatedData['retailer_percentage'] = formDetail.retailer_percentage
                              setCreditRequest(updatedData)

                              setRequest('edit')
                              setMessageHandler('successEdit')
                              setId(item.id)
                              setOldPriceValue({
                                minOrder: item.min_value,
                                maxOrder: item.max_value,
                              })
                              setPriceValue({
                                minOrder: item.min_value,
                                maxOrder: item.max_value,
                                wholesaleDiscount: item.wholesale_discount,
                                retailDiscount: item.retail_discount,
                              })
                              setModalLoader(false)
                            }}
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      {/*  discounting modal */}
      <EaModal
        onSubmit={addDiscount}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        closeAuto={'closeAddModal'}
        cancel={clearFunctionAdd}
        modalTitle={requestHandler === 'add' ? 'Add New Discount Capping' : 'Edit Discount Capping'}
        id='ea_modal_create_discount'
        loader={modalLoader}
      >
        <>
          <div className='col-sm-12 mb-4'>
            <label className='form-label'>
              Cap for Wholesaler Customer (%)<span className='text-danger'>*</span>
            </label>
            <input
              id='wholesaler_percentage'
              className={clsx('form-control', creditRequestValidation.wholesaler_percentage)}
              placeholder={
                creditRequest && numberFormat(creditRequest.wholesaler_percentage).displayFormat
              }
              value={
                creditRequest && numberFormat(creditRequest.wholesaler_percentage).displayFormat
              }
              name='wholesaler_percentage'
              type='number'
              onChange={handleChange}
            />
          </div>
          <div className='col-sm-12 mb-4'>
            <label className='form-label'>
              Cap for Retailer Customer (%)<span className='text-danger'>*</span>
            </label>
            <input
              id='retailer_percentage'
              className={clsx('form-control', creditRequestValidation.retailer_percentage)}
              placeholder={
                creditRequest && numberFormat(creditRequest.retailer_percentage).displayFormat
              }
              value={creditRequest && numberFormat(creditRequest.retailer_percentage).displayFormat}
              type='number'
              name='retailer_percentage'
              onChange={handleChange}
            />
          </div>
        </>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
      {/* delete discounting modal*/}
      <EaModal
        modalTitle='Delete Discount Capping'
        id='ea_modal_delete_discount'
        actionBtnName='Yes, Proceed'
        onSubmit={deleteApi}
        closeAuto={'closeDeleteModal'}
      >
        <p>Are you sure you want to delete discount capping ?</p>
      </EaModal>
    </>
  )
}

export default DiscountCapping
