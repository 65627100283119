import React, {useEffect, useState} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {Props} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../TenantVariables'
import {
  apiBaseURL,
  checkEmpty,
  fetchAsyncDropdown,
  fetchStaticAsyncDropdown,
  isEmpty,
  numberFormat,
} from '../../../_eaFruitsDms/helpers'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'

const RouteRequest = () => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Routes Requests',
    subTitle: 'Routes Requests',
  }

  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [routes, setRoutes] = useState<any>([])
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [showRouteModal, setShowRouteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [salesTeam, setSalesTeam] = useState<any>('')
  const [status, setStatus] = useState<any>('')

  const [showAlert, setShowAlert] = useState(false)

  const navigate = useNavigate()

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchRoutes()
  }, [currentOffset, debounceSearch, salesTeam, status])

  const fetchRoutes = async () => {
    setDisplayLoader(true)

    const url = `${apiBaseURL()}routes/requests?ordering=-id&limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&status=${
      status?.value ?? ''
    }&sales_team_id=${salesTeam?.value ?? ''}`

    const response = await getRequest(url, true)

    if (!isEmpty(response.results)) {
      setRoutes(response.results)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const fetchSalesTeam = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      'master/sales-team?ordering=name',
      search,
      setDropDownSearch,
      'name',
      true,
      'All Sales Team'
    )
  }

  const fetchStatus = async (search: any) => {
    const status = [
      {label: 'All Status', value: ''},
      {label: 'Approved', value: 'approved'},
      {label: 'Pending', value: 'pending'},
      {label: 'Rejected', value: 'rejected'},
      {label: 'Cancelled', value: 'cancelled'},
    ]

    return fetchStaticAsyncDropdown(status, search)
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e
    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'salesTeam':
        setSalesTeam(inputValue)
        break
      case 'status':
        setStatus(inputValue)
        break
      case 'search':
        setSearchByName(inputValue)
        break
      default:
        setSearchByName(inputValue)
        break
    }
  }

  const streetsTooltip = (items: any) => {
    if (items && items.length > 0) {
      if (items.length > 1) {
        return (
          <OverlayTrigger
            delay={{hide: 450, show: 300}}
            overlay={(props) => (
              <Tooltip {...props} className='tooltip-dark'>
                {items.map((item: any, index: any) => {
                  return (
                    <>
                      <span>
                        {index ? ',' : ''} {item.street_name}{' '}
                      </span>
                    </>
                  )
                })}
              </Tooltip>
            )}
            placement='top'
          >
            <span className='cursor-pointer'>
              {items[0].street_name}
              {` & ${items.length - 1} more`}
            </span>
          </OverlayTrigger>
        )
      } else {
        return <span className='cursor-pointer'>{items[0].street_name}</span>
      }
    } else {
      return <></>
    }
  }

  return (
    <>
      <EATitle title='Routes Requests' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id: 'Routes Requests',
        })}
      </PageTitle>

      <EaAlertSuccess
        newProps={true}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={'Route Updated Successfully.'}
      />

      <div className={`card`}>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <AsyncPaginate
                loadOptions={fetchSalesTeam}
                onChange={(e: any) => {
                  handleFilterChange(e, 'salesTeam')
                }}
                isSearchable
                placeholder='All Sales Team'
                isDisabled={displayLoader}
                value={salesTeam}
                className='react-select-container my-1 me-3 w-150px'
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              <AsyncPaginate
                loadOptions={fetchStatus}
                onChange={(e: any) => {
                  handleFilterChange(e, 'status')
                }}
                isSearchable
                placeholder='All Status'
                isDisabled={displayLoader}
                value={status}
                className='react-select-container my-1 me-3 w-150px'
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              {/* <button className='btn btn-primary' type='button' onClick={() => {}}>
                Create Route
              </button> */}
            </div>
          </div>
        </div>
        <div className='card-body pt-0 pb-3'>
          <div className='table-responsive'>
            {displayLoader ? (
              <FetchingRecords />
            ) : routes.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='w-100px'>Request No.</th>
                    <th className='w-100px'>Route Name</th>
                    <th className='w-150px'>Sales Team</th>
                    <th className='w-150px'>Requested By</th>
                    <th className='w-150px'>Approved By</th>
                    <th className='w-170px'>Total Streets</th>
                    <th className='w-100px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {routes.map((routes: any) => {
                    return (
                      <tr className='cursor-pointer'>
                        <td
                          onClick={() => {
                            navigate('/route/route-request-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {isEmpty(routes.ref_no) ? '-' : `#${routes.ref_no}`}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/route-request-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {checkEmpty(routes.name)}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/route-request-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {checkEmpty(routes.sales_team_name)}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/route-request-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {isEmpty(routes.created_by_name)
                            ? '-'
                            : `${routes.created_by_name} (${routes.created_by_type.toUpperCase()})`}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/route-request-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {routes.status === 'cancelled' ? (
                            '-'
                          ) : (
                            <>
                              {isEmpty(routes.updated_by_name)
                                ? '-'
                                : `${
                                    routes.updated_by_name
                                  } (${routes.updated_by_type.toUpperCase()})`}
                            </>
                          )}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/route-request-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {isEmpty(routes.streets) || routes?.streets.length === 0
                            ? '-'
                            : streetsTooltip(routes.streets)}
                        </td>

                        <td
                          onClick={() => {
                            navigate('/route/route-request-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {isEmpty(routes.status) ? (
                            '-'
                          ) : (
                            <>
                              {routes.status === 'approved' && (
                                <span className='badge mb-2 mt-2 badge-light-success text-dark'>
                                  Approved
                                </span>
                              )}

                              {routes.status === 'rejected' && (
                                <span className='badge mb-2 badge-light-danger text-dark'>
                                  Rejected
                                </span>
                              )}

                              {routes.status === 'pending' && (
                                <span className='badge mb-2 badge-light-warning text-dark'>
                                  Pending
                                </span>
                              )}

                              {routes.status === 'cancelled' && (
                                <span className='badge mb-2 badge-light-dark text-dark'>
                                  Cancelled
                                </span>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <CustomPaginate
        data={routes}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default RouteRequest
