import React from "react"
import { KTSVG, measurementLabel } from "../../../_eaFruitsDms/helpers"
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import SkuThumbnail from "../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail"

const TableLowStack = (props: any) => {
  const getPropsEdit = (productId: any) => {
    props.getValue(productId)
  }

  return (
    <>
       <table className='table table-row-bordered table-hover align-middle gs-0 gy-1'>
          {/* begin::Table head */}
          <thead>
            <tr className=' text-muted fw-500'>
              <th className='min-w-100px py-2'>Product SKU</th>
              <th className='min-w-100px py-2'>Size</th>
              <th className='min-w-100px py-2'>Packaging</th>
              <th className='min-w-100px py-2'>Brand</th>
              <th className='min-w-100px py-2'>Category</th>
              <th className='min-w-100px py-2'>Product Group</th>
              <th className='min-w-100px py-2'>Low Stock Alert At</th>
              <th className='text-end'>{props.showWrite && "Action"}</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {props.tableLowStockList.map((item: any) => {
              return (
                <tr
                  // onClick={() => GeneralManager(item)}
                  key={item.id}
                  className='cursor-pointer'
                >

                  <SkuThumbnail src={item.product_thumbnail} skuName={item.name} />

                  {/* <td> {item.fix_size ? item.size + " " + item.measurement_unit : "-"}</td> */}
                  <td>
                    {
                      item.fix_size ? ((item.measurement_unit.toLowerCase() === 'gram') ? item.size / 1000 : item.size) : (item.variants.approx_weight > 0) ? item.variants.approx_weight / 1000 : 1
                    } Kgs
                  </td>
                  <td>{measurementLabel(item.material.name)}</td>
                  <td>{item.brand_id}</td>
                  <td>{item.category_id}</td>
                  <td>{item.group_name}</td>
                  <td>{item.low_stock_alert[0] ? item.low_stock_alert[0].low_stock + " " + measurementLabel(item.material.name) : "-"}</td>
                  {/* <td>{item.length == !0 ? item.low_stock_alert.low_stock : '-'}</td> */}
                  <td className='text-end'>
                    {props.showWrite &&
                       <a
                       href='javascript:void(0)'
                       className='btn py-0 ps-1 pe-0 btn-active-color-primary btn-sm'
                       data-bs-toggle='modal'
                       data-bs-target='#ea_modal_edit_lowstock'
                       onClick={(event) => {
                         {
                           getPropsEdit(item.id)
                         }
                       }}
                     >
                       <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                     </a>
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>

          {/* end::Table body */}
        </table>
      {/* <EditStockSetting singledata={getSinglePrduct} lowStock={lowStockValue}
      //  onChange={onChange}
        /> */}
      {/* {getSinglePrduct.length > 0 ?  <EditStockSetting singledata ={getSinglePrduct}/> : ""} */}
    </>
  )
}

export default TableLowStack
