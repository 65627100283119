import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { KTSVG } from "../../../../_eaFruitsDms/helpers";

type Prop = {
  questionTypeOptions: any
  index: number
  isLastRow: boolean
  addRow: any
  removeRow: any
  handleChange: any
  surveyQuestionsRows: any
  id: any
}

type formRow = {
  question_title: string
  question_title_id: any
  questionType_id: any
  question_type: any
  use_for_analytic: any
  // useForAnalytics: false
  // value: string
  option_text: any
  useForAnalyticsDisplay: false
}
const SurveyFeedBackRow: React.FC<Prop> = (props) => {
  const initialQuestion: any = {
    question_title: '',
    question_type: '',
    question_title_id: '',
    questionType_id: '',
    // useForAnalytics: false,
    use_for_analytic: false,
    // value: '',
    option_text: '',
    useForAnalyticsDisplay: false,
  }
  const [formQuestion, setFormQuestion] = useState<formRow>(initialQuestion)
  const [placeholder, setPlaceholder] = useState<string>('')
  const [isOptionDivVisible, setIsOptionDivVisible] = useState<boolean>(false)

  const [cursor, setCursor] = useState(null)
  const refQuestionLabel = useRef(null)
  const refcommaSeparated = useRef(null)
  const refPlaceholder = useRef(null)

  useEffect(() => {
    const inputQuestion: any = refQuestionLabel.current
    const inputCommaSeparated: any = refcommaSeparated.current
    const inputPlaceholder: any = refPlaceholder.current

    if (inputQuestion) inputQuestion.setSelectionRange(cursor, cursor)
    if (inputCommaSeparated) inputCommaSeparated.setSelectionRange(cursor, cursor)
    if (inputPlaceholder) inputPlaceholder.setSelectionRange(cursor, cursor)
  }, [refQuestionLabel, refcommaSeparated, refPlaceholder, cursor, formQuestion])

  useEffect(() => {
    if (props.surveyQuestionsRows) {
      const formDetail = props.surveyQuestionsRows[props.index]
      let updatedData: any = { ...formQuestion }

      updatedData['question_title'] = formDetail.question_title
      props.questionTypeOptions().options.map((option: any) => {
        if (option.value == formDetail.question_type) {
          updatedData['question_type'] = option
        }
      })
      updatedData['use_for_analytic'] = formDetail.use_for_analytic
      updatedData['option_text'] = formDetail.option_text

      if (
        formDetail.question_type == 'single_choice' ||
        formDetail.question_type == 'multiple_choice'
      ) {
        setIsOptionDivVisible(true)
      } else {
        setIsOptionDivVisible(false)
      }

      updatedData['useForAnalyticsDisplay'] = formDetail.useForAnalyticsDisplay
      props.surveyQuestionsRows[props.index].requireOption =
        formDetail.question_type == 'single_choice' || formDetail.question_type == 'multiple_choice'

      setFormQuestion(updatedData)
    }
    setPlaceholder(props.surveyQuestionsRows[props.index].placeholder)
  }, [props.surveyQuestionsRows])

  return (
    <>
      <div className='row ps-3 align-items-center mb-4'>
        <div className='col-md-8'>
          <div className='row p-4 border rounded-2'>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <p className='mb-0 fw-500'>Question {props.index + 1}</p>
              {props.surveyQuestionsRows[props.index].useForAnalyticsDisplay && (
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input widget-13-check me-2'
                    type='checkbox'
                    // value='1'
                    name='use_for_analytic'
                    checked={formQuestion && formQuestion.use_for_analytic}
                    id={props.index + ''}
                    onChange={(e: any) => {
                      props.handleChange(e, props.index)
                    }}
                  />
                  <label className='fw-500' htmlFor={props.index + ''}>
                    Use for Analytics
                  </label>
                </div>
              )}
            </div>
            <div className='col-md-8'>
              <label className='form-label'>
                Question Label<span className="text-danger">*</span>
              </label>
              <input
                type='text'
                name='question_title'
                id={props.surveyQuestionsRows[props.index].question_title_id}
                className={clsx(
                  'form-control',
                  props.surveyQuestionsRows[props.index].validationQuestion &&
                  props.surveyQuestionsRows[props.index].validationQuestion
                )}
                value={formQuestion && formQuestion.question_title}
                ref={refQuestionLabel}
                onChange={(e: any) => {
                  setCursor(e.target.selectionStart)
                  props.handleChange(e, props.index)
                }}
                placeholder='Type here...'
              />
            </div>
            <div className='col-md-4'>
              <label
                className='form-label'
                id={props.surveyQuestionsRows[props.index].questionType_id}
              >
                Question Type<span className="text-danger">*</span>
              </label>
              <AsyncPaginate
                    
                loadOptions={props.questionTypeOptions}
                placeholder='Select'
                isSearchable
                className={clsx(
                  'react-select-container',
                  props.surveyQuestionsRows[props.index].validationQuestionType &&
                  props.surveyQuestionsRows[props.index].validationQuestionType
                )}
                classNamePrefix='react-select'
                name='question_type'
                value={(formQuestion && formQuestion.question_type) || ''}
                onChange={(e: any) => {
                  props.handleChange(e, props.index)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            {isOptionDivVisible && (
              <div className='col-md-12 mt-4'>
                <label className='form-label'>Add options comma separated</label>
                <input
                  key={props.index + 1}
                  type='text'
                  name='option_text'
                  className='form-control'
                  // value={formQuestion && formQuestion.value}
                  // defaultValue={formQuestion && formQuestion.option_text}
                  ref={refcommaSeparated}
                  value={formQuestion && formQuestion.option_text}
                  onChange={(e: any) => {
                    setCursor(e.target.selectionStart)
                    props.handleChange(e, props.index)
                  }}
                  placeholder='Option 1, Option 2, Option 3'
                />
              </div>
            )}
            <div className='col-md-12 mt-4'>
              <label className='form-label'>Placeholder</label>
              <input
                type='text'
                name='placeholder'
                ref={refPlaceholder}
                className='form-control'
                onChange={(e: any) => {
                  setCursor(e.target.selectionStart)
                  props.handleChange(e, props.index)
                }}
                // defaultValue={placeholder}
                value={placeholder}
              />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          {props.isLastRow ? (
            <button
              type='button'
              className='position-relative btn ms-auto p-0'
              onClick={() => {
                props.addRow(props.index)
                formQuestion.question_type = ''
              }}
            >
              <span className='svg-icon-dark'>
                <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='ic me-0' />
              </span>
            </button>
          ) : (
            <button
              type='button'
              className='position-relative btn ms-auto p-0'
              onClick={() => {
                formQuestion.question_type = ''
                props.removeRow(props.index, props.id)
              }}
            >
              <span className='svg-icon-dark'>
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='ic me-0' />
              </span>
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default SurveyFeedBackRow
