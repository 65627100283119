import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";
import { apiBaseURL, checkPermissions, defaultPermission, fetchAsyncDropdown } from "../../../../_eaFruitsDms/helpers";
import ShowingEntries from "../../../../_eaFruitsDms/helpers/components/ShowingEntries";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaChartPie } from "../../../../_eaFruitsDms/layout/components/eaCharts/eaChart";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import TableTeamReport from "./components/tableTeamReport";

type Props = {
	className: string
}

const TeamReport: React.FC<Props> = ({ className }) => {
	const intl = useIntl()
	const reportHeaderTitle = {
		title: "Reports",
		subTitle: "Team Report"
	}
	const initialPageState = {
		search: "",
		limit: 10,
		offset: "",
		list: [],
		next: "",
		count: "",
		previous: "",
		isLoading: true,
		currentOffset: "",
		selected: 0
	}
	const [pageState, setPageState] = useState(initialPageState)
	const [offset, setOffset] = useState(0)
	const [warehouseList, setWarehouseList] = useState([])
	const [dropDownSearch, setDropDownSearch] = useState("")
	const [selectWarehouseType, setSelectWarehouseType] = useState<any>({ label: "All Warehouse Type", value: "" })
	const [reportData, setReportData] = useState([])
	const [reportDataRole, setReportDataRole] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [WarehouseName, setWarehouseName] = useState(true)
	const [WarehouseType, setWarehouseType] = useState(true)
	const [Region, setRegion] = useState(true)
	const [ParentWarehouse, setParentWarehouse] = useState(true)
	const [orderColumnKey, setOrderColumnKey] = useState("")
	const initialOrdering = "name"
	const [ordering, setOrdering] = useState(initialOrdering)
	const [permission, setPermission] = useState(defaultPermission)

	const getTeamList = async () => {
		// Develop BY: Kashyap
		// Description: get List Details
		setPageState({
			...pageState,
			isLoading: true,
		  })
		const url = apiBaseURL() + "warehouse/list?" + `ordering=${ordering}&limit=${pageState.limit}&offset=${pageState.currentOffset}&search=${pageState.search}&warehouse_type=${selectWarehouseType.value}`
		const response = await getRequest(url, true)
		setPageState({
			...pageState,
			list: response.results,
			next: response.next,
			count: response.count,
			previous: response.previous,
			isLoading: response ? false : true
		})
	}

	const getReportsDetails = async () => {
		setIsLoading(true)
		const warehouseUrl = apiBaseURL() + "warehouse/list?" + `ordering=name&limit=10000`
		const warehouseResponse = await getRequest(warehouseUrl, true)
		if (warehouseResponse) {
			let warehouseData: any = []
			warehouseResponse.results.length !== 0 && warehouseResponse.results.forEach((item: any) => {
				item.team_size > 0 && warehouseData.push({ value: item.team_size, name: item.name })
			})
			setReportData(warehouseData)
		}

		const roleUrl = apiBaseURL() + "auth/application/role/list/?ordering=name&user_type__in=wm,oo,lo,ss,sr,sg,ls,am&limit=10000"
		const roleResponse = await getRequest(roleUrl, true)
		if (roleResponse) {
			let data: any = []
			roleResponse.results.length !== 0 && roleResponse.results.forEach((item: any) => {
				item.role_count > 0 && data.push({ value: item.role_count, name: item.name })
			})
			setReportDataRole(data)
		}
		setIsLoading(false)
	}

	const fetchWarehouse = (search: string) => {
		return fetchAsyncDropdown(offset, setOffset, warehouseList, setWarehouseList, "warehouse/list?ordering=name", search, setDropDownSearch, false, true, "All Warehouse")
	}

	useEffect(() => {
		checkPermissions("warehouse_team").then((res: any) => {
			setPermission(res)
		})

		getTeamList()
	}, [pageState.currentOffset, pageState.search, selectWarehouseType.value, ordering])

	useEffect(() => {
		getReportsDetails()
		localStorage.removeItem('navigateBackToReport')
	}, [])

	useEffect(() => {
		setOffset(0)
	}, [dropDownSearch])

	const reportDataItem = {
		data: reportData,
		bottom: "30%"
	}

	const reportDataRoleItem = {
		data: reportDataRole,
		bottom: "30%"
	}

	const handleOrdering = (key: any) => {
		setOrderColumnKey(key)

		if (key === "WarehouseName") {
			setWarehouseName(!WarehouseName)
			setWarehouseType(true)
			setParentWarehouse(true)
			setRegion(true)
		}

		if (key === "WarehouseType") {
			setWarehouseType(!WarehouseType)
			setWarehouseName(true)
			setParentWarehouse(true)
			setRegion(true)
		}

		if (key === "ParentWarehouse") {
			setParentWarehouse(!ParentWarehouse)
			setWarehouseType(true)
			setWarehouseName(true)
			setRegion(true)
		}

		if (key === "Region") {
			setRegion(!Region)
			setParentWarehouse(true)
			setWarehouseType(true)
			setWarehouseName(true)
		}
	}

	useEffect(() => {
		if (orderColumnKey === "WarehouseName") {
			if (WarehouseName) {
				setOrdering("name")
			} else {
				setOrdering("-name")
			}
		}

		if (orderColumnKey === "WarehouseType") {
			if (WarehouseType) {
				setOrdering("warehouse_type")
			} else {
				setOrdering("-warehouse_type")
			}
		}

		if (orderColumnKey === "ParentWarehouse") {
			if (ParentWarehouse) {
				setOrdering("parent_warehouse__name")
			} else {
				setOrdering("-parent_warehouse__name")
			}
		}

		if (orderColumnKey === "Region") {
			if (Region) {
				setOrdering("region__name")
			} else {
				setOrdering("-region__name")
			}
		}

	}, [WarehouseName, WarehouseType, Region, ParentWarehouse, orderColumnKey])

	return (
		<>
			<EATitle title='Team Report' />
			<PageTitle breadcrumbs={[reportHeaderTitle]}>{intl.formatMessage({ id: "Team Report" })}</PageTitle>
			<div className="row mt-3 mt-md-0">
				<div className="col-12 mt-md-0">
					<div className="card card-xl-stretch mb-5">
						<div className="card-header border-0 py-3 py-md-2 px-5 px-md-7">
							{/* begin::Title */}
							<div><h3 className="card-title align-items-start flex-column my-0">
								<span className="card-label fw-bold font-17">Team Overview</span>
							</h3>
								<p className="font-13 text-muted mb-0">Based on Team Size</p>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								{isLoading ? <IsLoader /> :
									<>
										<div className="col-md-6">
											<EaChartPie item={reportDataItem} />
										</div>
										<div className="col-md-6">
											<EaChartPie item={reportDataRoleItem} />
										</div>
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className="card">
					{pageState.isLoading && <IsLoader />}
					<div className="card-header border-0 py-2 px-7 align-items-center">
						<CustomSearchBar newSearch={true} setResponse={setPageState} />
						<div className="ms-auto">
							{/* <div className='card-toolbar d-flex my-0'> */}
							<form onSubmit={(e) => e.preventDefault()} autoComplete="off">
								{/* <AsyncPaginate
                    
								 loadOptions={fetchWarehouse}
								 isSearchable
								 className={`react-select-container my-1 me-3 w-150px`}
								 classNamePrefix="react-select"
								 name='All Warehouse'
								 onChange={(e: any) => {
								 setSelectWarehouse(e)
								 }}
								 value={selectWarehouse}
								 theme={(theme) => ({
								 ...theme,
								 colors: {
								 ...theme.colors,
								 primary: '#0c78a4',
								 },
								 })}
								 /> */ }
								<Select
									options={[
										{ label: "All Warehouse Type", value: "" },
										{ label: "Headquarter", value: "Headquarter" },
										{ label: "Distribution Center", value: "DistributionCenter" },
									]}
									isSearchable
									placeholder="All Warehouses Type"
									className="react-select-container me-3 w-180px"
									onChange={(e: any) => {
										setSelectWarehouseType(e)
									}}
									value={selectWarehouseType}
									classNamePrefix="react-select"
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											primary: "#0c78a4",
										},
									})}
								/>
								{/* <label className="form-label mb-0">
								 <button type="button" className="btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">
								 <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
								 Export</button>
								 </label> */ }
							</form>
							{/* </div> */}
						</div>
					</div>
					{/* end::Header */}
					{/* begin::Body */}
					<div className="card-body py-3">
						{pageState.list.length === 0 ? <NoRecords /> :
							<TableTeamReport
								list={pageState.list}
								showRead={permission.read}
								WarehouseName={WarehouseName}
								WarehouseType={WarehouseType}
								Region={Region}
								ParentWarehouse={ParentWarehouse}
								handleOrdering={handleOrdering}
							/>
						}
					</div>
					{/* begin::Body */}
				</div>
				<div className="table-info py-5">
					<div className="row g-0">
						<div className="col">
							<ShowingEntries response={pageState} />
						</div>
						<div className="col">
							<CustomPagination newProp={true}
								pageState={pageState}
								setPageState={setPageState} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default TeamReport
