export const gatePassStatus: any = {
  late: "Late",
  on_time: "On Time"
}

export const requestStatus: any = {
  sr_requested: "SR Requested",
  ss_approved: "SS Approved",
  ss_rejected: "SS Rejected",
  sg_approved_at_exit_check: "SG Approved At Exit",
  sg_rejected_at_exit_check: "SG Rejected At Exit",
  sg_reported_at_entry_check: "SG Reported At Entry",
  sg_approved_at_entry_check: "SG Approved At Entry"
}
