import moment from 'moment'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import tenantConfiguration from '../../../../TenantVariables'
import {
  apiBaseURL,
  checkPermissions,
  checkUserType,
  dateFormat,
  wmDefaultPermission,
} from '../../../../_eaFruitsDms/helpers'
import {CustomerFilter} from '../../../../_eaFruitsDms/helpers/components/CustomerFilter'
import {CustomDatePickerComponent} from '../../../../_eaFruitsDms/helpers/components/DatePickerFilter'
import {DistrictFilter} from '../../../../_eaFruitsDms/helpers/components/DistrictFilter'
import DynamicTable from '../../../../_eaFruitsDms/helpers/components/DynamicTable'
import {StreetFilter} from '../../../../_eaFruitsDms/helpers/components/StreetFilter'
import {UserTypeFilter} from '../../../../_eaFruitsDms/helpers/components/UserTypeFilter'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import HeaderTitle from '../../../../_eaFruitsDms/layout/components/HeaderTitle'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {customerLeadColumns} from './customerLeadColumns'
import {StaticFilter} from '../../../../_eaFruitsDms/helpers/components/StaticFilter'
import {statusOption} from './statusOption'

const CustomerLead = () => {
  const navigate = useNavigate()

  const [limit] = useState<number>(tenantConfiguration.limit)
  const [data, setData] = useState([{id: '1'}])
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [customer, setCustomer] = useState({
    label: 'All Customer',
    value: '',
  })
  const [warehouse, setWarehouse] = useState({
    label: 'All Warehouse',
    value: '',
  })
  const [district, setDistrict] = useState({
    label: 'All District',
    value: '',
  })
  const [street, setStreet] = useState({
    label: 'All Street',
    value: '',
  })
  const [createdBy, setCreatedBy] = useState({
    label: 'All Created By',
    value: '',
  })
  const [registeredBy, setRegisteredBy] = useState({
    label: 'All Registered By',
    value: '',
  })
  const [status, setStatus] = useState({
    label: 'All Status',
    value: '',
  })
  const [filteredDate, setFilteredDate] = useState<any>({
    startDate: dateFormat(moment().startOf('month')).apiDate,
    endDate: dateFormat(moment().endOf('month')).apiDate,
  })
  const [permissionOverview, setPermissionOverview] = useState(wmDefaultPermission)

  const debounceSearch = useDebounce(searchByName, 500)

  const fetchPermission = async () => {
    const res = await checkPermissions('customer_overview')

    setPermissionOverview(res)
  }

  const fetchCustomerLead = async () => {
    setDisplayLoader(true)

    const startDate = filteredDate.startDate
    const endDate = filteredDate.endDate

    const url = `${apiBaseURL()}customers/leads?limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&warehouse_id=${
      warehouse?.value ?? ''
    }&district_ids=${district?.value ?? ''}&street_ids=${street?.value ?? ''}&customer_id=${
      customer?.value ?? ''
    }&created_by_id=${createdBy?.value ?? ''}&action_by_id=${
      registeredBy?.value ?? ''
    }&created_at_duration_after=${startDate}&created_at_duration_before=${endDate}&status=${
      status?.value ?? ''
    }`

    const response = await getRequest(url, true)

    if (response?.results) {
      setData(response.results)
      setCount(response.count)
    } else {
      setData([])
      setCount('1')
    }

    setDisplayLoader(false)
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e

    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'search':
        setSearchByName(inputValue)
        break

      case 'customer':
        setCustomer(inputValue)
        break

      case 'warehouse':
        setWarehouse(inputValue)
        setCreatedBy({label: 'All Created By', value: ''})
        setRegisteredBy({label: 'All Registered By', value: ''})
        setCustomer({
          label: 'All Customer',
          value: '',
        })
        break

      case 'district':
        setDistrict(inputValue)
        break

      case 'status':
        setStatus(inputValue)
        break

      case 'street':
        setStreet(inputValue)
        break

      case 'created_by':
        setCreatedBy(inputValue)
        break

      case 'registered_by':
        setRegisteredBy(inputValue)
        break
    }
  }

  useEffect(() => {
    if (checkUserType(['wm'])) {
      fetchPermission()
    }
  }, [])

  useEffect(() => {
    fetchCustomerLead()
  }, [
    debounceSearch,
    customer,
    warehouse,
    district,
    street,
    createdBy,
    registeredBy,
    filteredDate,
    status,
  ])

  return (
    <>
      <HeaderTitle
        pageTitle='Customer Lead'
        headerSubTitle='Customers'
        headerTitle='Customer Lead'
      />

      <div className={`card `}>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <div className='card-toolbar d-flex my-0 w-100'>
            <CustomSearchBar onChange={handleFilterChange} />

            <div className='d-flex ms-auto'>
              <WarehouseFilter
                warehouse={warehouse}
                handleFilterChange={handleFilterChange}
                isDisabled={displayLoader}
                className='w-130px ms-1 me-2'
              />

              {/* <CustomerFilter
              customer={customer}
              handleFilterChange={handleFilterChange}
              isDisabled={displayLoader}
              className='w-130px me-2'
              warehouseId={warehouse?.value ?? ''}
            /> */}

              <DistrictFilter
                district={district}
                handleFilterChange={handleFilterChange}
                isDisabled={displayLoader}
                className='w-130px me-2'
              />

              <StreetFilter
                street={street}
                handleFilterChange={handleFilterChange}
                isDisabled={displayLoader}
                className='w-130px me-2'
              />

              <UserTypeFilter
                user={createdBy}
                handleFilterChange={(e: any) => handleFilterChange(e, 'created_by')}
                isDisabled={displayLoader}
                className='w-130px me-2'
                type='mo'
                label='All Created By'
                warehouseId={warehouse?.value ?? ''}
              />

              <UserTypeFilter
                user={registeredBy}
                handleFilterChange={(e: any) => handleFilterChange(e, 'registered_by')}
                isDisabled={displayLoader}
                className='w-130px me-2'
                type='sr'
                label='All Registered By'
                warehouseId={warehouse?.value ?? ''}
              />

              <StaticFilter
                handleFilterChange={(e: any) => handleFilterChange(e, 'status')}
                isDisabled={displayLoader}
                option={statusOption}
                value={status}
                className='w-130px me-2'
              />

              <div className='me-2 w-230px'>
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setFilteredDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                  }}
                  value={filteredDate}
                  startDate={filteredDate.startDate}
                  endDate={filteredDate.endDate}
                  displayLoader={displayLoader}
                  drops='down'
                  singleDatePicker={false}
                  ranges
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-body pt-0 pb-3'>
          <DynamicTable
            columns={customerLeadColumns(navigate, permissionOverview)}
            data={data}
            displayLoader={displayLoader}
            trClassName='cursor-pointer'
          />
        </div>
      </div>

      <CustomPaginate
        data={data}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default CustomerLead
