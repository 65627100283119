import React from "react"
import { KTSVG } from "../../../../../../_eaFruitsDms/helpers"

const Targets: React.FC = () => {
    return (
        <>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>

                    <div>
                        <input type="text" className='form-control form-search' placeholder='Search' />
                    </div>
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <a
                                href='#!'
                                className='btn btn-primary btn-sm my-1'
                            >
                                Add Target
                            </a>
                        </div>
                    </div>


                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-140px'>Target Name</th>
                                    <th className='min-w-140px'>Duration</th>
                                    <th className='min-w-100px'>New Customers</th>
                                    <th className='min-w-120px'>Sales Amount</th>
                                    <th className='min-w-120px'>Status</th>
                                    <th className='min-w-80px action-th py-2'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>
                                    <td>Jun 2022</td>
                                    <td>
                                        1 Jun 2022 — 30 Jun 2022
                                    </td>
                                    <td>
                                        10 Customers
                                    </td>
                                    <td>
                                        TZS 10,00,000
                                    </td>
                                    <td>
                                        <span className='badge badge-light-warning text-dark'>Scheduled</span>
                                    </td>
                                    <td className='text-end'>
                                        <span

                                            className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </span>
                                        <span className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2022 Yearly Target</td>
                                    <td>
                                        1 Jan 2022 — 31 Dec 2022
                                    </td>
                                    <td>
                                        10 Customers
                                        <div className='d-flex flex-column w-100 me-2'>
                                            <div className='d-flex flex-stack mb-2'>
                                                <span className='me-2 font-13'>5 Customers (50%)</span>
                                            </div>
                                            <div className='progress h-6px w-100'>
                                                <div
                                                    className='progress-bar rounded  bg-warning'
                                                    role='progressbar'
                                                    style={{ width: '50%' }}
                                                ></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        TZS 10,00,000
                                        <div className='d-flex flex-column w-100 me-2'>
                                            <div className='d-flex flex-stack mb-2'>
                                                <span className='me-2 font-13'>TZS 7,00,000 (70%)</span>
                                            </div>
                                            <div className='progress h-6px w-100'>
                                                <div
                                                    className='progress-bar rounded  bg-success'
                                                    role='progressbar'
                                                    style={{ width: '70%' }}
                                                ></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className='badge badge-light-success text-dark'>Ongoing</span>
                                    </td>
                                    <td className='text-end'>
                                        <span

                                            className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </span>
                                        <span className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2nd Quarter 2022</td>
                                    <td>
                                        1 Apr 2022 — 30 Jun 2022
                                    </td>
                                    <td>
                                        —
                                    </td>
                                    <td>
                                        TZS 10,00,000
                                        <div className='d-flex flex-column w-100 me-2'>
                                            <div className='d-flex flex-stack mb-2'>
                                                <span className='me-2 font-13'>TZS 7,00,000 (70%)</span>
                                            </div>
                                            <div className='progress h-6px w-100'>
                                                <div
                                                    className='progress-bar rounded  bg-success'
                                                    role='progressbar'
                                                    style={{ width: '70%' }}
                                                ></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className='badge badge-light-success text-dark'>Ongoing</span>
                                    </td>
                                    <td className='text-end'>
                                        <span

                                            className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </span>
                                        <span className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Festive Competition</td>
                                    <td>
                                        1 Mar 2022 — 31 Mar 2022
                                    </td>
                                    <td>
                                        10 Customers
                                        <div className='d-flex flex-column w-100 me-2'>
                                            <div className='d-flex flex-stack mb-2'>
                                                <span className='me-2 font-13'>5 Customers (50%)</span>
                                            </div>
                                            <div className='progress h-6px w-100'>
                                                <div
                                                    className='progress-bar rounded  bg-muted'
                                                    role='progressbar'
                                                    style={{ width: '50%' }}
                                                ></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        —
                                    </td>
                                    <td>
                                        <span className='badge badge-secondary text-dark'>Past</span>
                                    </td>
                                    <td className='text-end'>
                                        <span

                                            className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </span>
                                        <span className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>February 2022</td>
                                    <td>
                                        1 Feb 2022 — 28 Feb 2022
                                    </td>
                                    <td>
                                        10 Customers
                                        <div className='d-flex flex-column w-100 me-2'>
                                            <div className='d-flex flex-stack mb-2'>
                                                <span className='me-2 font-13'>5 Customers (50%)</span>
                                            </div>
                                            <div className='progress h-6px w-100'>
                                                <div
                                                    className='progress-bar rounded bg-muted'
                                                    role='progressbar'
                                                    style={{ width: '50%' }}
                                                ></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        —
                                    </td>
                                    <td>
                                        <span className='badge badge-secondary text-dark'>Past</span>
                                    </td>
                                    <td className='text-end'>
                                        <span
                                            className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </span>
                                        <span className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <ul className="pagination justify-content-md-end">
                            <li className="page-item previous disabled">
                                <a href="#" className="page-link">
                                    <i className="previous"></i>
                                </a>
                            </li>
                            <li className="page-item offset active">
                                <a href="#" className="page-link">
                                    1
                                </a>
                            </li>
                            <li className="page-item offset ">
                                <a href="#" className="page-link">
                                    2
                                </a>
                            </li>
                            <li className="page-item offset">
                                <a href="#" className="page-link">
                                    3
                                </a>
                            </li>
                            <li className="page-item offset">
                                <a href="#" className="page-link">
                                    4
                                </a>
                            </li>
                            <li className="page-item offset">
                                <a href="#" className="page-link">
                                    5
                                </a>
                            </li>
                            <li className="page-item offset">
                                <a href="#" className="page-link">
                                    6
                                </a>
                            </li>
                            <li className="page-item next">
                                <a href="#" className="page-link">
                                    <i className="next"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Targets
