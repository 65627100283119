import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdownNew,
  fetchStaticAsyncDropdown,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import { AsyncPaginate } from 'react-select-async-paginate'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../../TenantVariables'
import { getRequest } from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import { creditRequestStatus } from './CustomerConst'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import { WarehouseFilter } from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import moment from 'moment'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'

type Props = {
  className: string
}

const OrderQuantityRequest: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const location = useLocation()

  const intl = useIntl();
  const customerHeaderTitle = {
    title: location.pathname === "/customers/order-quantity-requests" ? "Customers" : "Reports"
  };

  let orderStatus = location.pathname === "/reports/order-quantity-requests" ? { label: 'All Requests', value: '' } : { label: 'Pending', value: 'pending' }

  const [orderQuantity, setOrderQuantity] = useState<any>([]);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit);
  const [selected, setSelected] = useState<number>(0);
  const [count, setCount] = useState<string>("1");
  const [displayLoader, setDisplayLoader] = useState<boolean>(true);
  const [searchByName, setSearchByName] = useState<string>('')

  // filters states
  const [customerOption, setCustomersOption] = useState<any>([])
  const [customerOffSet, setCustomerOffSet] = useState<any>('')
  const [srOffSet, setSrOffSet] = useState('');
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [customerReset, setCustomerReset] = useState<any>(moment())
  const [srReset, setSrReset] = useState<any>(new Date())
  const [searchSelect, setSearchSelect] = useState<string>('')

  const [warehouse, setWarehouse] = useState<any>({ label: 'All Warehouses', value: '' })
  const [customer, setCustomer] = useState<any>({ label: 'All Customers', value: '' })
  const [status, setStatus] = useState<any>(orderStatus)
  const [sr, setSr] = useState<any>({ label: 'All Sales Reps', value: '' })

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    setCustomerOffSet(0)
  }, [searchSelect])

  useEffect(() => {
    fetchOrderQuantityRequest().then();
  }, [debounceSearch, status, currentOffset, warehouse, sr, customer]);

  const fetchOrderQuantityRequest = async () => {
    setDisplayLoader(true);
    const response = await getRequest(`${apiBaseURL()}preorder/sales-quantity-requests?limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&warehouse_id=${warehouse.value}&customer_id=${customer.value}&sr_id=${sr.value}&status=${status.value}`, true);

    if (response.results) {
      setOrderQuantity(response.results);
      setCount(response.count);
    }

    setDisplayLoader(false);
  };

  const fetchOpenRequest = async (search?: any) => {
    return fetchStaticAsyncDropdown(creditRequestStatus, search);
  };

  const fetchCustomer = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }

    const customerResponse = await getRequest(
      `${apiBaseURL()}customers/drop-down?ordering=business_name&on_boarding_status=Approved&search=${search}&limit=${limit}&offset=${customerOffSet}&warehouse_id=${warehouse.value}`,
      true
    )

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if (customerResponse.next !== null) {
      const queryParams = new URLSearchParams(customerResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCustomerOffSet(newOffset)
      hasMore = true
    }

    if (customerOption.length === 0) {
      options.push({
        label: "All Customers",
        value: ""
      })
    }

    if (customerResponse.results.length > 0) {
      customerResponse.results.map((option: any) => {
        options.push({
          label: option.business_name + ', ' + option.street,
          value: option.id,
        })
        optionData.push(option)
      })
    }

    if (customerOption.length > 0) {
      setCustomersOption(customerOption.concat(optionData))
    } else {
      setCustomersOption(optionData)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  };

  const fetchSrs = async (search?: string) => {
    return await fetchAsyncDropdownNew(
      srOffSet,
      setSrOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `auth/users/team/list?ordering=first_name&warehouse__id=${warehouse.value}&type=sr`,
      search,
      setSearchSelect,
      'first_name',
      'last_name',
      true,
      'All Sales Reps'
    )
  }

  const filterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    setSearchSelect('')
    setCurrentOffset(0)
    setSelected(0)
    const currentMomentObj: any = moment()

    switch (inputName) {
      case 'warehouse':
        localStorage.setItem('selected-wid', inputValue?.value ?? '')
        setWarehouse(e)
        setCustomerOffSet('')
        setCustomersOption([])
        setCustomerReset(currentMomentObj)
        setSrOffSet('')
        setSrReset(new Date())
        setSalesRepsOption([])
        break
      case 'customer':
        setCustomer(e)
        break
      case 'sr':
        setSr(e)
        break
      case 'status':
        setStatus(e)
        break
      default:
        setSearchByName(inputValue)
    }
  }

  const goToDetailPage = (orderId: any) => {
    if (location.pathname === '/customers/order-quantity-requests') {
      navigate("/customers/order-quantity-requests/quantity-request-detail", {
        state: {
          order_id: orderId
        }
      })
    } else {
      navigate("/reports/order-quantity-requests/quantity-request-detail", {
        state: {
          order_id: orderId
        }
      })
    }
  }

  useEffect(() => {
    localStorage.setItem('selected-wid', '')
  }, [])
  
  return <>
    <div>
      <EATitle title={location.pathname === "/customers/order-quantity-requests" ? 'Order Quantity Request' : 'Sales Quantity Control Report'} />
      <PageTitle breadcrumbs={[customerHeaderTitle]}>
        {intl.formatMessage({ id: location.pathname === "/customers/order-quantity-requests" ? 'Order Quantity Request' : 'Sales Quantity Control Report' })}
      </PageTitle>
      <div className="card">
        {displayLoader && <IsLoader />}
        <div className="card-header border-0 py-2 px-7 align-items-center">
          <CustomSearchBar onChange={filterChange} />
          <div className="ms-md-auto">
            <form className="card-toolbar d-flex my-0">
              <WarehouseFilter
                warehouse={warehouse}
                handleFilterChange={(e: any) => filterChange(e, 'warehouse')}
                isDisabled={displayLoader}
              />
              <AsyncPaginate
                key={customerReset}
                loadOptions={fetchCustomer}
                className="react-select-container my-1 me-3 w-175px"
                classNamePrefix="react-select"
                placeholder="All Customer"
                onChange={(e: any) => filterChange(e, 'customer')}
                value={customer}
                isDisabled={displayLoader}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#0c78a4"
                  }
                })}
              />
              <AsyncPaginate
                key={srReset}
                loadOptions={fetchSrs}
                className="react-select-container my-1 w-175px"
                classNamePrefix="react-select"
                placeholder="All SRs"
                onChange={(e: any) => filterChange(e, 'sr')}
                value={sr}
                isDisabled={displayLoader}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#0c78a4"
                  }
                })}
              />
              <AsyncPaginate
                loadOptions={fetchOpenRequest}
                className="react-select-container my-1 ms-3 w-150px"
                classNamePrefix="react-select"
                onChange={(e: any) => filterChange(e, 'status')}
                value={status}
                isDisabled={displayLoader}
                placeholder="Pending"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#0c78a4"
                  }
                })}
              />
            </form>
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            {
              displayLoader ? <FetchingRecords /> : orderQuantity.length === 0 ? <NoRecords />
                :
                <table className="table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2">
                  <thead>
                    <tr className="text-muted fw-500">
                      <th className="min-w-110px">Date</th>
                      <th className="min-w-120px">Order No.</th>
                      <th className="min-w-120px">SR Name</th>
                      <th className="min-w-120px">Customer Name</th>
                      <th className="min-w-100px action-th py-2 text-end">Status</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {
                      orderQuantity.map((quantity: any) => {
                        return <tr onClick={() => goToDetailPage(quantity.id)} className='cursor-pointer'>
                          <td>
                            {
                              dateFormat(quantity.requested_at).displayDate
                            }
                            <p
                              className="font-13 text-muted mb-0">{dateFormat(quantity.requested_at, "LT").displayDate}</p>
                          </td>
                          <td> #{quantity.order_no}</td>
                          <td>{isEmpty(quantity.requested_by_name) ? '-' : quantity.requested_by_name}</td>
                          <td>{isEmpty(quantity.customer_name) ? '-' : quantity.customer_name}</td>
                          <td className="text-end">
                            {quantity.status === 'approved' ? (
                              <span className='badge badge-light-success text-dark'>Approved</span>
                            ) : quantity.status === 'pending' ? (
                              <span className='badge badge-light-danger text-dark'>Pending</span>
                            ) : quantity.status === 'rejected' ? (
                              <span className='badge badge-light-info text-dark'>Rejected</span>
                            ) : quantity.status === 'cancelled' ? (
                              <span className='badge badge-light-dark text-dark'>Cancelled</span>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>;
                      })
                    }
                  </tbody>
                  {/* end::Table body */}
                </table>
            }
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CustomPaginate
        data={orderQuantity}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </div>
  </>;
};

export default OrderQuantityRequest;
