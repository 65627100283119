export const paymentsStatus: any = [
    {
      label: "All Status",
      value: ""
    },
    {
      label: "Closed",
      value: "closed"
    },
    {
      label: "Open",
      value: "open"
    },
    {
      label: "Overdue",
      value: "overdue"
    },
   ]
    
  export const paymentsPayLaterToday: any = [
    {
      label: "All Payments",
      value: ""
    },
    {
      label: "Paid on Same Day",
      value: "paid_on_same_day"
    },
    {
      label: "Partially Paid on Same Day",
      value: "partially_paid_on_same_day"
    },
    {
      label: "Not Paid on Same Day",
      value: "not_paid_on_same_day"
    },
  ]
  