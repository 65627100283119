import React from 'react'

const SideBarLoader = () => {
  return (
    <>
      <div className='d-flex justify-content-center p-5 mt-5'>
        <div className='spinner-border text-light'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
      <div className='text-light my-3 text-center'>Please Wait...</div>
    </>
  )
}

export default SideBarLoader
