import React, { useEffect, useState } from "react"
import CustomPagination from "../../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import { GLOBAL_DATE_FORMAT, apiBaseURL, dateFormat, retrieveFilter } from "../../../../../../_eaFruitsDms/helpers"
import { getRequest } from "../../../../../../_eaFruitsDms/https/apiCall"
import IsLoader from "../../../../../../_eaFruitsDms/layout/components/loader/loader"
import moment from "moment"
import CustomPaginate from "../../../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import tenantConfiguration from "../../../../../../TenantVariables"
import { filters } from "../../../../../../_eaFruitsDms/utils/FilterConstants"
import useDebounce from "../../../../../../_eaFruitsDms/helpers/components/useDebounce"
import NoRecords from "../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"

const Messages = () => {
  let supportRequestFiltered = retrieveFilter(filters.sa_telesales_supportrequest_message);

    const initialState = {
        search: supportRequestFiltered?.search || '',
        selected: supportRequestFiltered?.selected || '',
        currentOffSet: supportRequestFiltered?.currentOffset || '',
        buttonDisable: true
      }

    const supportRequestId = localStorage.getItem('supportRequestId')
    const [requestDetails, setRequestDetails] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState('');
    const [selected, setSelected] = useState<number>(supportRequestFiltered?.selected || 0);
    const [limit, setLimit] = useState(tenantConfiguration.limit);
    const [currentOffset, setCurrentOffset] = useState<number>(supportRequestFiltered?.currentOffset || '0');
    const [search, setSearch] = useState(supportRequestFiltered?.search || '')
    const [filterStates, setFilterStates] = useState<any>(initialState);
    const [buttonDisable, setButtonDisable] = useState<boolean>(supportRequestFiltered?.buttonDisable || false) 
 
    const debounceSearch = useDebounce(search, 500);
 
    useEffect(() =>{
        supportRequest()
       },[debounceSearch, currentOffset])

    const supportRequest = async () => {
        setIsLoading(true)
        const url = `${apiBaseURL()}support_request/message/view?support_request=${supportRequestId}&search=${debounceSearch}&limit=${limit}&offset=${currentOffset}`
        const response = await getRequest(url, true);
        setRequestDetails(response.results)
        setCount(response.count)
        if (response) {
            setIsLoading(false)
        }
    }

    const handleSearch = (e : any) =>{
        const inputValue = e.target ? e.target.value : e.value
          setCurrentOffset(0)
          setSelected(0)
          setButtonDisable(true)
          setSearch(inputValue)
          setFilterStates({...filterStates, ['search']: inputValue})
    }

    return (
        <>
            {isLoading && <IsLoader />}
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar  onChange={handleSearch} value={search} isDisable={isLoading}/>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        {requestDetails.length === 0 ? <NoRecords /> :  
                         <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                         {/* begin::Table head */}
                         <thead>
                             <tr className='text-muted fw-500'>
                                 <th className='min-w-100px py-2'>Date & Time</th>
                                 <th className='min-w-100px py-2'>Message By</th>
                                 <th className='min-w-150px py-2'>Message</th>
                                 {/* <th className='min-w-120px py-2'>Flag</th> */}
                             </tr>
                         </thead>
                         {/* end::Table head */}
                         {/* begin::Table body */}
                         <tbody>
                           {requestDetails.map((message: any)=>{
                             return( 
                                 <tr>
                                 <td>
                                    {
                                        message.created_at && message.created_at !== '' ?
                                        <>
                                        {dateFormat(message.created_at).displayDate}
                                        <p className='font-13 text-muted mb-0'>{dateFormat(message.created_at, 'LT').displayDate}</p>
                                        </> : '-'
                                    }
                                </td>
                                 <td>
                                     {message.tele_sales !== null ? message.support_request.assign_to.first_name + ' ' + message.support_request.assign_to.last_name : message.support_request.customer.business_name}
                                 </td>
                                 <td>
                                     {message && message.message ?  message.message : '-'}
                                 </td>
                                 {/* <td>
                                     Garrett Mullins static
                                 </td> */}
                             </tr>
                             )
                           })}
                         </tbody>
                         {/* end::Table body */}
                     </table>
                        }
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <CustomPaginate
              data={requestDetails}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              isDisable={isLoading}
              saveFilterName={filters.sa_telesales_supportrequest_message}
              filterStates={filterStates}
            />
        </>
    )
}

export default Messages
