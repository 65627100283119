import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import tenantConfiguration from '../../../../../TenantVariables'
import {apiBaseURL, dateFormat, isEmpty, numberFormat} from '../../../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import useDebounce from '../../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import InfoIcon from '../../../../../_eaFruitsDms/layout/components/header/infoIcon'

const OrderLimitAccessRequest = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const customersHeaderTitle = {
    title: 'Customers',
    subTitle: 'Order Limit Access Requests',
  }

  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState(tenantConfiguration.limit)
  const [warehouse, setWarehouse] = useState<any>({label: 'All Warehouse', value: ''})
  const [fetchingRecords, setFetchingRecords] = useState(false)
  const [orderLimits, setOrderQuantity] = useState([])
  const [count, setCount] = useState<string>('1')

  const debounceSearch = useDebounce(search, 500)

  const fetchOrderLimit = async () => {
    setFetchingRecords(true)

    const url = `${apiBaseURL()}preorder/order-limit-requests?warehouse_id=${
      warehouse.value
    }&search=${debounceSearch}&limit=${limit}&offset=${currentOffset}`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setOrderQuantity(response.results)
      setCount(response.count)
    }

    setFetchingRecords(false)
  }

  const handleFilterChange = (e: any) => {
    setCurrentOffset(0)
    setSelected(0)
    setWarehouse(e)
  }

  const isNavigateTo = (item: any) => {
    navigate('/customers/order-limit-access-request-details', {
      state: {
        id: item.id,
      },
    })
  }

  useEffect(() => {
    fetchOrderLimit()
  }, [currentOffset, debounceSearch, warehouse])

  useEffect(() => {
    const status = localStorage.getItem('limitStatus')
    const requestStatus = localStorage.getItem('requestStatus')

    if (!isEmpty(status)) {
      setIsAlert(true)
      setMessage(`Order limit request is ${requestStatus}`)

      setTimeout(() => {
        setIsAlert(false)
      }, 2000)
    }

    setTimeout(() => {
      localStorage.removeItem('limitStatus')
      localStorage.removeItem('requestStatus')
    }, 500)
  }, [])

  return (
    <>
      <EATitle title='Order Limit Access Requests' />
      <PageTitle breadcrumbs={[customersHeaderTitle]}>
        {intl.formatMessage({id: 'Order Limit Access Requests'})}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      <div className={`card`}>
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              isDisable={fetchingRecords}
            />
            <div className='col-auto ms-auto'>
              <form
                className=' card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                {/* filter dropdown */}
                <WarehouseFilter
                  warehouse={warehouse}
                  handleFilterChange={handleFilterChange}
                  isDisabled={fetchingRecords}
                />
              </form>
            </div>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {fetchingRecords ? (
              <FetchingRecords />
            ) : orderLimits.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-80px'>Request No</th>
                    <th className='min-w-100px'>Request Date</th>
                    <th className='min-w-150px'>Customer Name</th>
                    <th className='min-w-150px'>Request by</th>
                    <th className='min-w-120px'>
                      <div className='d-flex'>
                        <div>Order Limits </div>
                        <InfoIcon
                          placement='top'
                          message='This is default or specially granted "Per Day Order Limit" for the Customer account.'
                        />
                      </div>
                    </th>
                    <th className='min-w-120px'>
                      <div className='d-flex'>
                        <div>Total Orders </div>
                        <InfoIcon
                          placement='top'
                          message='This is already "Placed Orders Count" for the Day, for the Customer account.'
                        />
                      </div>
                    </th>
                    <th className='min-w-120px'>Response by</th>
                    <th className='min-w-120px'>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {orderLimits.length > 0 &&
                    orderLimits.map((item: any) => {
                      return (
                        <tr>
                          <td className='py-3'>{isEmpty(item.ref_no) ? '-' : `#${item.ref_no}`}</td>
                          <td className='py-3'>
                            {isEmpty(item.created_at) ? (
                              '-'
                            ) : (
                              <>
                                {dateFormat(item.created_at).displayDate}
                                <div>{dateFormat(item.created_at, 'LT').displayDate}</div>
                              </>
                            )}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.customer_name) ? '-' : item.customer_name}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.requested_by_name) ? '-' : item.requested_by_name}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.order_limit)
                              ? '-'
                              : numberFormat(item.order_limit).displayFormat}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.total_order)
                              ? '-'
                              : numberFormat(item.total_order).displayFormat}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.action_by_name) && isEmpty(item.action_by_type)
                              ? '-'
                              : `${item.action_by_name} (${item.action_by_type.toUpperCase()})`}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.status) ? (
                              '-'
                            ) : (
                              <span onClick={() => isNavigateTo(item)}>
                                {item.status === 'pending' && (
                                  <span className='cursor-pointer badge badge-secondary text-dark'>
                                    Pending
                                  </span>
                                )}

                                {item.status === 'approved' && (
                                  <span className='cursor-pointer badge badge-light-success text-dark'>
                                    Approved
                                  </span>
                                )}

                                {item.status === 'cancelled' && (
                                  <span className='cursor-pointer badge badge-secondary text-dark'>
                                    Cancelled
                                  </span>
                                )}

                                {item.status === 'rejected' && (
                                  <span className='cursor-pointer badge badge-light-danger text-dark'>
                                    Rejected
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <CustomPaginate
        data={orderLimits}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={fetchingRecords}
        saveFilterName={'feedbackReport'}
        filterStates={''}
      />
    </>
  )
}

export default OrderLimitAccessRequest
