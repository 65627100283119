import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { apiBaseURL, cmsInquiresTitle, dateFormat } from "../../../../../_eaFruitsDms/helpers"
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"

const InquiriesDetails = () => {
  const navigate = useNavigate()
  const inquiriesId = localStorage.getItem('inquiriesId')
  const inquiriesType = localStorage.getItem('inquiriesType')
  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'CMS',
  }

  const [details, setDetails] = useState<any>([])
  const [listLoadder, setListLoader] = useState<any>(true)

  useEffect(() => {
    fetchTestimonialDetail().then()
  }, [])

  // Develop BY: Kriti
  // Description: fetchDetail from get ID

  const fetchTestimonialDetail = async () => {
    let testimonialResponse = await getRequest(`${apiBaseURL()}cms/inquiries/${inquiriesId}/`, true)
    setDetails(testimonialResponse)
    setListLoader(false)
  }

  return (
    <>
      <div>
        {inquiriesType == 'General' ? (
          <>
            <EATitle title='Contact Us - ' />
          </>
        ) : (
          <>
            <EATitle title='Investor & Partner - ' />
          </>
        )}

        {inquiriesType == 'General' ? (
          <>
            <PageTitle breadcrumbs={cmsInquiresTitle} backNavigate='/cms/inquiries/contact' >
              {intl.formatMessage({
                id: 'Contact Us',
              })}
            </PageTitle>
          </>
        ) : (
          <>
            <PageTitle breadcrumbs={cmsInquiresTitle} backNavigate='/cms/inquiries/investors'>
              {intl.formatMessage({
                id: 'Investor & Partner',
              })}
            </PageTitle>
          </>
        )}
        <div className='card mb-5 mb-xl-10'>
          {listLoadder ? (
            <IsLoader />
          ) : (
            <>
              <div className='card-body py-5'>
                <div className='row mb-6'>
                  <label className='col-lg-12 font-14 text-muted'>Name</label>
                  <div className='col-lg-12'>
                    <span className='font-14 text-dark'>{details.first_name + " " + details.last_name}</span>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-12 font-14 text-muted'>Email</label>
                  <div className='col-lg-12'>
                    <span className='font-14 text-dark'>{details.email}</span>
                  </div>
                </div>
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-12 font-14 text-muted'>Mobile No.</label>
                    <div className='col-lg-12'>
                      <span className='font-14 text-dark'>{details.mobile_number}</span>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-12 font-14 text-muted'>Inquiry Subject</label>
                    <div className='col-lg-12'>
                      <span className='font-14 text-dark'>{details.inquiry_subject}</span>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-12 font-14 text-muted'>
                      {' '}
                      Received On (date & time)
                    </label>
                    <div className='col-lg-12'>
                      <span className='font-14 text-dark'>
                        {dateFormat(details.received_on).displayDate}{' '}
                        {dateFormat(details.received_on, 'LT').displayDate}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-12 font-14 text-muted'>Inquiry Message</label>
                    <div className='col-lg-12'>
                      <span className='font-14 text-dark'>{details.inquiry_message}</span>
                    </div>
                  </div>
                </>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default InquiriesDetails
