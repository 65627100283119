import { KTSVG } from "../../../../../_eaFruitsDms/helpers"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"

const TableCategory = (props: any) => {
    return (
        <>
             <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                    <thead>
                        <tr className=' text-muted fw-500'>
                            {/* <th className='w-25px py-2'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                />
                            </div>
                        </th> */}
                            <th className='min-w-100px py-2' style={{ width: "25%" }}>Category</th>
                            <th className='min-w-100px py-2'>Parent Category</th>
                            {props.showWrite || props.showDelete ? <th className='min-w-80px action-th py-2'>Actions</th> : <th className='min-w-80px action-th py-2'></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {props.tableList.map((item: any) => (
                            <tr key={item.id} >
                                {/* <td>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                </div>
                            </td> */}
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.parent_category ? item.parent_category.name : '-'}
                                </td>
                                <td className='text-end'>
                                    {props.showWrite &&
                                        <a
                                            onClick={() => props.getPropsEdit(item)} // onpress event edit event 
                                            href='#'
                                            data-bs-toggle='modal'
                                            data-bs-target='#ea_modal_edit_product_category'
                                            className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-2'
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </a>}
                                    {/* <a
                                    data-bs-toggle='modal'
                                    data-bs-target='#deleteProductCategories'
                                    href='#' className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'
                                    onClick={() => props.getPropsDelete(item)} // onpress event delete event 

                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                </a> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </>
    )
}

export default TableCategory
