import React, {useEffect, useState} from 'react'
import {getRequest, postRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {apiBaseURL, focusVatidateField, goToTop, isEmpty} from '../../../../../_eaFruitsDms/helpers'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import clsx from 'clsx'
import {EaAlertSuccess} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FormatMessage from '../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'

const OdooCustomerCategory = (props: any) => {
  const [customerCategory, setCustomerCategory] = useState<any>([])
  const [fetchingRecords, setFetchingRecords] = useState<boolean>(false)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isSubmit, setIsSubmit] = useState('Submit')

  useEffect(() => {
    fetchingDetails().then()
  }, [])

  const fetchingDetails = async () => {
    setFetchingRecords(true)
    const response = await getRequest(`${apiBaseURL()}odoo-mapping/customer/categories`, true)

    if (!isEmpty(response) && !isEmpty(response.result)) {
      setCustomerCategory(response.result)
    }

    setFetchingRecords(false)
  }

  const handleChange = (e: any, index: number) => {
    const inputValue = e.target.value
    setCustomerCategory((prevCustomerCategory: any) => {
      const updatedCustomerCategoryDetail = [...prevCustomerCategory]
      updatedCustomerCategoryDetail[index] = {
        ...updatedCustomerCategoryDetail[index],
        odoo_category_name: inputValue,
        validation: !isEmpty(inputValue) ? '' : 'is-invalid',
      }
      return updatedCustomerCategoryDetail
    })
  }

  const submitCustomerCategoryInfo = async () => {
    let isValid: boolean = true
    let category = [...customerCategory]

    category.forEach((item: any, i: number) => {
      if (isEmpty(item.odoo_category_name)) {
        focusVatidateField(i)
        isValid = false
        category[i].validation = 'is-invalid'
      }
    })

    setCustomerCategory(category)

    if (isValid) {
      setIsSubmit('Please Wait')
      let url = apiBaseURL() + 'odoo-mapping/customer/categories/edit'
      let customerCategoryPayload: any = []

      customerCategory.forEach((item: any) => {
        customerCategoryPayload.push({
          category: item.id,
          name: item.odoo_category_name,
        })
      })

      const response = await postRequest(
        url,
        {mapping_customer_category: customerCategoryPayload},
        true
      )

      if (response) {
        setIsAlertSuccess(true)
        setIsSubmit('Submit')
        goToTop()
        setTimeout(() => {
          setIsAlertSuccess(false)
        }, 2000)
      }
    }
  }

  return (
    <>
      <EATitle title='DMS to Odoo Mapping Config - ' />
      <EaAlertSuccess
        onClick={() => setIsAlertSuccess(false)}
        show={IsAlertSuccess}
        message={FormatMessage('Customer Categories configuration updated successfully.')}
      />
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-5'>
          {fetchingRecords ? (
            <FetchingRecords />
          ) : customerCategory.length === 0 ? (
            <NoRecords />
          ) : (
            <>
              <div className='row mb-5 align-items-center'>
                <div className='col-md-7 col-lg-3'>
                  <b className='font-14 text-muted'>Customer Categories In DMS</b>
                </div>
                <div className='col-md-3 col-lg-4'>
                  <b className='font-14 text-muted'>Customer Tags in Odoo</b>
                </div>
              </div>
              <hr />
              {customerCategory.map((item: any, index: number) => {
                return (
                  <div className='row mb-6 ' id={index.toString()}>
                    <div className='col-md-7 col-lg-3'>
                      <label className='font-14 text-muted'>
                        {item.category}
                        <span className='text-danger'>*</span>
                      </label>
                    </div>
                    <div className='col-md-3 col-lg-4'>
                      <input
                        type='text'
                        className={clsx('form-control', item.validation)}
                        onChange={(e: any) => handleChange(e, index)}
                        placeholder='Type here..'
                        value={isEmpty(item.odoo_category_name) ? '' : item.odoo_category_name}
                      />
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
        {customerCategory.length > 0 && !fetchingRecords && (
          <div className='card-footer'>
            <button
              disabled={isSubmit !== 'Submit' ? true : false}
              className='btn btn-primary'
              onClick={submitCustomerCategoryInfo}
            >
              {isSubmit}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default OdooCustomerCategory
