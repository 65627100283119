import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Rating} from 'react-simple-star-rating'
import {apiBaseURL, dateFormat, isEmpty, KTSVG} from '../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'

type Props = {
  className: string
}

const TSSurveyName: React.FC<Props> = ({className}) => {
  const adminHeaderTitle = {
    title: 'Survey Feedback',
  }

  const location: any = useLocation()

  const navigate = useNavigate()

  const initialAnswerValue = {
    id: 0,
    surveyform_id: {
      id: 0,
      name: '',
    },
    answer_details: [
      {
        id: 8,
        questions_id: 0,
        question_title: '',
        question_type: '',
        placeholder: '',
        questions_options: '',
        option_text: '',
        answers: '',
      },
    ],
    customer_id: {
      id: 0,
      business_name: '-',
      contact_no: '-',
      warehouse: [{name: ''}],
    },
    submited_at: '',
    feedback_by: {
      id: 0,
      first_name: '',
      last_name: '',
      type: '',
    },
    participant_name: '',
  }

  const [feedBackId, setFeedBackId] = useState<number>(0)
  const [answerState, setAnswerState] = useState(initialAnswerValue)

  useEffect(() => {
    if (location.state) {
      setFeedBackId(location.state.feedBackAnswerId)
    } else {
      navigate('/survey-forms')
    }
  }, [])

  useEffect(() => {
    if (feedBackId !== 0) {
      getSurveyFeedBackDetails()
    }
  }, [feedBackId])

  const getSurveyFeedBackDetails = () => {
    getRequest(apiBaseURL() + 'surveyForms/answer/' + feedBackId, true).then((res) => {
      setAnswerState(res)
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[adminHeaderTitle]} backNavigate='/survey-forms/survey-feedback'>
        {isEmpty(answerState.surveyform_id) ? '-' : answerState.surveyform_id.name}
      </PageTitle>
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      {isEmpty(answerState.surveyform_id) ? '-' : answerState.surveyform_id.name}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                    <span className='d-flex align-items-center me-6 mb-3'>
                      <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                      {!isEmpty(answerState.submited_at) ? (
                        <>
                          {dateFormat(answerState.submited_at).displayDate} •{' '}
                          {dateFormat(answerState.submited_at, 'LT').displayDate}
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body py-4'>
          <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-0'>Survey Information</h3>
          <div className='row'>
            <div className='col-lg-8 col-xl-6 col-xxl-6'>
              <div className='row'>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {isEmpty(answerState.customer_id)
                        ? isEmpty(answerState.participant_name)
                          ? '-'
                          : answerState.participant_name
                        : answerState.customer_id.business_name}
                    </span>
                  </div>
                </div>
                {!isEmpty(answerState.customer_id) && (
                  <div className='col-sm-6 col-md-4 mb-6'>
                    <label className='font-14 text-muted mb-2'>Warehouse Name</label>
                    <div>
                      <span className='font-14 text-dark'>
                        {isEmpty(answerState.customer_id)
                          ? '-'
                          : answerState.customer_id.warehouse.length !== 0
                          ? answerState.customer_id.warehouse[0].name
                          : '-'}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Survey Captured by</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {isEmpty(answerState.feedback_by) ? (
                        '-'
                      ) : answerState.feedback_by.type !== '' ? (
                        <>
                          {answerState.feedback_by.first_name +
                            ' ' +
                            answerState.feedback_by.last_name}{' '}
                          {`(${answerState.feedback_by.type})`}
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-4'>Survey Feedback</h3>

          {answerState.answer_details.map((item, index) => {
            return (
              <>
                <label className='font-14 text-muted mb-2'>{item.question_title}</label>
                <div className='font-14 text-dark mb-5'>
                  {item.question_type === 'star_rating' ? (
                    <Rating
                      size={24}
                      transition
                      allowFraction
                      className='react-simple-star-rating'
                      SVGclassName='star-svg'
                      fillColor='#f9c527'
                      emptyColor='#ebebeb'
                      disableFillHover={false}
                      allowHover={false}
                      initialValue={parseFloat(item.answers)}
                      readonly={true}
                    />
                  ) : (
                    item.answers
                  )}
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default TSSurveyName
