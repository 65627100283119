import moment from 'moment'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../../TenantVariables'
import {
  KTSVG,
  apiBaseURL,
  checkEmpty,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  globalDateFormate,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {getRequest, putRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaChartBar} from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'

const TempHumidityAlertReport = () => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Temperature & Humidity Alerts',
    subTitle: 'Temperature & Humidity Alerts',
  }

  const [reports, setReports] = useState([])
  const [warehouse, setWarehouse] = useState({label: 'All Warehouse', value: ''})
  const [barChart, setBarChart] = useState({})
  const [show, setShow] = useState(false)
  const [fetchingChart, setFetchingChart] = useState(false)
  const [fetchingReport, setFetchingReport] = useState(false)
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [reasonOffset, setReasonOffset] = useState(0)
  const [reasonList, setReasonList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [reason, setReason] = useState<any>({label: 'Select', value: ''})
  const [additionalNote, setAdditionalNote] = useState('')
  const [reasonNote, setReasonNote] = useState('')
  const [validation, setValidation] = useState({note: '', reason: '', reasonNote: ''})
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [id, setId] = useState('')
  const [submit, setSubmit] = useState('Submit')
  const [limit] = useState<number>(tenantConfiguration.limit)

  const fetchReports = async () => {
    setFetchingReport(true)

    const url = `${apiBaseURL()}reports/weather-boxes/alert-logs?warehouse_id=${
      warehouse.value
    }&limit=${limit}&offset=${currentOffset}&start_date=${apiDate.startDate}&end_date=${
      apiDate.endDate
    }&ordering=-id`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setReports(response.results)
      setCount(response.count)
    }

    setFetchingReport(false)
  }

  const fetchBarChart = async () => {
    setFetchingChart(true)

    const url = `${apiBaseURL()}reports/weather-boxes/alert-logs/graph?warehouse_id=${
      warehouse.value
    }&start_date=${apiDate.startDate}&end_date=${apiDate.endDate}`

    const response = await getRequest(url, true)
    if (response.result) {
      handleChartResponse(response.result)
    }

    setFetchingChart(false)
  }

  const handleChartResponse = (response: any) => {
    // for bar chart
    let app = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
    }

    // for bar chart
    const labelOption = {
      show: true,
      position: app.position,
      distance: app.distance,
      align: app.align,
      verticalAlign: app.verticalAlign,
      rotate: app.rotate,
      formatter: function (params: any) {
        return params.value == 0 ? '' : params.value
      },
      fontSize: 10,
      rich: {
        name: {},
      },
    }

    let initialChart: any = {}
    let xAxisData: string[] = []
    let seriesData: any = []
    let legend: any = []

    if (response.length > 0) {
      let tempAlertCount: any = []
      let humidityAlertCount: any = []
      let missingReadingCount: any = []

      response.forEach((item: any) => {
        xAxisData.push(item.container_name)
        tempAlertCount.push({
          value: item.temp_alert_count,
          itemStyle: {
            color: '#0c78a4',
          },
        })
        humidityAlertCount.push({
          value: item.humidity_alert_count,
          itemStyle: {
            color: '#f9c527',
          },
        })
        missingReadingCount.push({
          value: item.missing_reading_count,
          itemStyle: {
            color: '#dddddd',
          },
        })
      })

      seriesData.push({
        name: 'Temperature',
        type: 'bar',
        width: '100%',
        label: labelOption,
        stack: 'one',
        tooltip: {
          valueFormatter: function (params: any) {
            return `Temperature - ${params}`
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: tempAlertCount,
      })

      seriesData.push({
        name: 'Humidity',
        type: 'bar',
        width: '100%',
        label: labelOption,
        stack: 'one',
        tooltip: {
          valueFormatter: function (params: any) {
            return `Humidity - ${params}`
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: humidityAlertCount,
      })

      seriesData.push({
        name: 'Missing Reading Count',
        type: 'bar',
        width: '100%',
        label: labelOption,
        stack: 'one',
        tooltip: {
          valueFormatter: function (params: any) {
            return `Missing Reading Count - ${params}`
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: missingReadingCount,
      })
    }

    legend.push({
      name: 'Temperature',
      itemStyle: {
        color: '#0c78a4',
      },
    })
    legend.push({
      name: 'Humidity',
      itemStyle: {
        color: '#f9c527',
      },
    })
    legend.push({
      name: 'Missing Reading Count',
      itemStyle: {
        color: '#dddddd',
      },
    })

    initialChart.xAxisData = xAxisData
    initialChart.rotate = 0
    initialChart.series = seriesData
    initialChart.legend = legend
    initialChart.startZoom = 0
    initialChart.endZoom = 100

    setBarChart(initialChart)
  }

  const addReason = (item: any) => {
    setShow(true)
    setId(item.id)
    if (item.status === 'closed') {
      // single request
    }
  }

  const fetchReason = async (search: string) => {
    const response = await fetchAsyncDropdown(
      reasonOffset,
      setReasonOffset,
      reasonList,
      setReasonList,
      `master/options-master/?type=weather_box_alert_reason`,
      search,
      setDropDownSearch,
      'option',
      false
    )

    response.options.push({
      label: 'Other',
      value: '-',
    })

    return response
  }

  const isClearModal = () => {
    setShow(false)
    setSubmit('Submit')
    setValidation({note: '', reason: '', reasonNote: ''})
    setReasonNote('')
    setReason({label: 'Select', value: ''})
    setAdditionalNote('')
    setReasonList([])
    setReasonOffset(0)
    setId('')
  }

  const isSubmit = async () => {
    let valid = true
    let updatedValidation = {...validation}

    if (isEmpty(reason.value)) {
      valid = false
      updatedValidation.reason = 'is-invalid'
    }

    if (reason.label === 'Other' && isEmpty(reasonNote)) {
      valid = false
      updatedValidation.reasonNote = 'is-invalid'
    }

    setValidation(updatedValidation)

    if (valid) {
      setSubmit('Please Wait..')

      const url = `${apiBaseURL()}reports/weather-boxes/alert-logs/reason/${id}`
      const body = {
        reason: reason.value === '-' ? null : reason.value,
        reason_note: reason.value === '-' ? reasonNote : null,
        additional_note: isEmpty(additionalNote) ? null : additionalNote,
      }

      const response = await putRequest(url, body, true)

      if (response.status === 200) {
        isClearModal()
        fetchReports()
      }

      setShow(false)
      setSubmit('Submit')
    }
  }

  useEffect(() => {
    fetchReports()
  }, [apiDate, warehouse, currentOffset])

  useEffect(() => {
    fetchBarChart()
  }, [apiDate, warehouse])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
            setCurrentOffset(0)
            setSelected(0)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
            disabled={fetchingChart || fetchingReport}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Temperature & Humidity Alerts - ' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({id: 'Temperature & Humidity Alerts'})}
      </PageTitle>

      <div className='card mb-5'>
        <div className='card-header border-0 py-5 px-7 align-items-center'>
          <h3 className='fw-bolder'>Temperature & Humidity Alerts</h3>

          <div className='ms-auto d-flex'>
            <CustomDatePickerComponent
              onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                setApiDate({
                  startDate: callBakeDate.startDate,
                  endDate: callBakeDate.endDate,
                })
              }}
              value={apiDate}
              startDate={apiDate.startDate}
              endDate={apiDate.endDate}
            />

            <WarehouseFilter
              warehouse={warehouse}
              handleFilterChange={(e: any) => {
                setWarehouse(e)
                setCurrentOffset(0)
                setSelected(0)
              }}
              isDisabled={fetchingChart || fetchingReport}
            />
          </div>
        </div>

        <div>
          {fetchingChart ? (
            <FetchingRecords />
          ) : !barChart ? (
            <NoRecords />
          ) : (
            <EaChartBar {...barChart} />
          )}
        </div>
      </div>

      <div className='card mb-5'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {fetchingReport ? (
              <FetchingRecords />
            ) : reports.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-100px py-2'>Date</th>
                    <th className='min-w-100px py-2'>ID</th>
                    <th className='min-w-100px py-2'>Container</th>
                    <th className='min-w-100px py-2'>Type</th>
                    <th className='min-w-100px py-2'>Threshold Value</th>
                    <th className='min-w-100px py-2'>Reason for Alert</th>
                    <th className='min-w-100px py-2'>Additional Note</th>
                    <th className='min-w-100px py-2'>Action By</th>
                    <th className='min-w-100px py-2'>
                      {checkUserType([['oo']]) ? 'Actions' : 'Status'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reports.length > 0 &&
                    reports.map((item: any) => {
                      return (
                        <tr className={item.status === 'open' ? 'cursor-pointer' : ''}>
                          <td className='min-w-100px py-2'>
                            {isEmpty(item.created_at) ? (
                              '-'
                            ) : (
                              <>
                                {dateFormat(item.created_at).displayDate}
                                <p className='font-13 text-muted mb-0'>
                                  {dateFormat(item.created_at, 'LT').displayDate}
                                </p>
                              </>
                            )}
                          </td>
                          <td className='min-w-100px py-2'>
                            {isEmpty(item.id) ? '-' : `#${item.id}`}
                          </td>
                          <td className='min-w-100px py-2'>{checkEmpty(item.container_name)}</td>
                          <td className='min-w-100px py-2'>
                            {isEmpty(item.temperature) ? 'Missing Reading' : 'Threshold Crossed'}
                          </td>
                          <td className='min-w-100px py-2'>
                            <div>
                              <p>
                                {isEmpty(item.temperature) ? (
                                  '-'
                                ) : (
                                  <>
                                    {item.temperature} &#8451;
                                    {item.temp_threshold_type === 'above_range' && (
                                      <KTSVG
                                        path='/media/icons/arrow/ic_up.svg'
                                        className='ic mr-0'
                                      />
                                    )}
                                    {item.temp_threshold_type === 'below_range' && (
                                      <KTSVG
                                        path='/media/icons/arrow/ic_down.svg'
                                        className='ic mr-0'
                                      />
                                    )}
                                  </>
                                )}
                              </p>
                              <div>
                                {isEmpty(item.humidity) ? (
                                  '-'
                                ) : (
                                  <>
                                    {' '}
                                    {`${item.humidity} g/m3`}
                                    {item.humidity_threshold_type === 'above_range' && (
                                      <KTSVG
                                        path='/media/icons/arrow/ic_up.svg'
                                        className='ic mr-0'
                                      />
                                    )}
                                    {item.humidity_threshold_type === 'below_range' && (
                                      <KTSVG
                                        path='/media/icons/arrow/ic_down.svg'
                                        className='ic mr-0'
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className='min-w-100px py-2'>
                            {isEmpty(item.reason_option)
                              ? isEmpty(item.reason_note)
                                ? '-'
                                : item.reason_note
                              : isEmpty(item.reason_option)
                              ? '-'
                              : item.reason_option}
                          </td>
                          <td className='min-w-100px py-2'>{checkEmpty(item.additional_note)}</td>
                          <td className='min-w-100px py-2'>{checkEmpty(item.action_by)}</td>
                          <td className='min-w-100px py-2'>
                            {isEmpty(item.status) ? (
                              '-'
                            ) : checkUserType(['oo']) ? (
                              <>
                                {item.status === 'open' && (
                                  <button
                                    onClick={() => addReason(item)}
                                    className='btn btn-primary'
                                  >
                                    Submit Reason
                                  </button>
                                )}

                                {item.status === 'closed' && (
                                  <span onClick={() => addReason(item)} className='btn btn-primary'>
                                    Edit Reason
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item.status === 'open' && (
                                  <span className='badge badge-light-danger text-dark'>Open</span>
                                )}

                                {item.status === 'closed' && (
                                  <span className='badge badge-light-primary text-dark'>
                                    Closed
                                  </span>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={isClearModal}
        centered
        backdrop='static'
        dialogClassName='h-auto mw-570px'
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Reason for Alert</h2>
          </Modal.Title>
          <div
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            onClick={() => {
              isClearModal()
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='row'>
              <div className='col-md-12 mb-3'>
                <label className='form-label'>
                  Reason<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  loadOptions={fetchReason}
                  isSearchable
                  placeholder='Select'
                  className={`react-select-container ${validation.reason}`}
                  onChange={(e: any) => {
                    setReason(e)
                    setValidation({...validation, reason: ''})
                  }}
                  value={reason}
                  isDisabled={false}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>

              {reason.label === 'Other' && (
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>Reason Note</label>
                  <textarea
                    className={`form-control  ${validation.reasonNote}`}
                    rows={3}
                    name='comments'
                    value={reasonNote}
                    onChange={(e: any) => {
                      setReasonNote(e.target.value)
                      setValidation({...validation, reasonNote: ''})
                    }}
                  ></textarea>
                </div>
              )}

              <div className='col-md-12'>
                <label className='form-label'>Additional Note</label>
                <textarea
                  className={`form-control`}
                  rows={3}
                  name='comments'
                  value={additionalNote}
                  onChange={(e: any) => {
                    setAdditionalNote(e.target.value)
                  }}
                ></textarea>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn btn-secondary me-3'
              onClick={() => {
                isClearModal()
              }}
              disabled={submit !== 'Submit' ? true : false}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                isSubmit()
              }}
              disabled={submit !== 'Submit' ? true : false}
            >
              {submit}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <CustomPaginate
        data={reports}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default TempHumidityAlertReport
