import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_eaFruitsDms/helpers'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { AsyncPaginate } from 'react-select-async-paginate'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
const fetchWarehouse = async (search?: any) => {
    let options: any = [{ label: 'Option 1', value: 'option1' }, { label: 'Option 3', value: 'option3' }]
    let hasMore: boolean = false
    options.push({
        label: "All Warehouses",
        value: ''
    })
    return {
        options: options,
        hasMore: hasMore
    }
}
const fetchOption = async (search?: any) => {
    let options: any = [{ label: 'Option 1', value: 'option1' }, { label: 'Option 3', value: 'option3' }]
    let hasMore: boolean = false
    options.push({
        label: "All Options",
        value: ''
    })
    return {
        options: options,
        hasMore: hasMore
    }
}
type Props = {
    className: string
}
const ComparisonReportWM: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Comparison Report"
    }
    return (
        <>
            <EATitle title="Comparison Report - " />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Reports' })}</PageTitle>
            <div className={`card ${className} mb-5`}>
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    <h3 className='card-title align-items-start flex-column my-0'>
                        <span className='card-label fw-bold font-17'>Comparison Report</span>
                    </h3>
                </div>
                {/* end card header */}
                <div className="card-body pt-1 pb-0">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <label className="form-label">Comparison Between</label>
                            <AsyncPaginate
                                loadOptions={fetchOption}
                                isSearchable
                                placeholder="HQs"
                                className='react-select-container my-1 me-3 w-150px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto mb-4">
                            <label className="form-label">Start Date</label>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: '01/01/2020',
                                        endDate: '01/12/2020',
                                        alwaysShowCalendars: true,
                                        cancelClass: 'btn-secondary',
                                        drops: "auto",
                                        locale: {
                                            format: 'DD/MM/YYYY'
                                        },
                                        ranges: {
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                        },
                                    }}
                            >
                                <input type="text" className="form-control calendar" />
                            </DateRangePicker>
                        </div>
                    </div>
                </div>
                <div className="card-footer py-2">
                    <button type="button" className="btn btn-secondary me-3" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" className="btn btn-primary">
                        Submit
                    </button>
                </div>
            </div>
            {/* end:: card */}
            {/* Begin:: card */}
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <h3 className='card-title align-items-start flex-column my-0'>
                        <span className='card-label fw-bold font-17'>Sales Supervisors Comparison Report</span>
                    </h3>
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <AsyncPaginate
                                loadOptions={fetchWarehouse}
                                isSearchable
                                placeholder="All Warehouses"
                                className='react-select-container my-1 me-3 w-150px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <button type="button" className="btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">
                                <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                Export</button>
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-1 pb-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            <thead>
                                <tr className='text-muted fw-500 align-middle'>
                                    <th className='min-w-150px'>Select Warehouses</th>
                                    <th className='min-w-120px'>
                                        <AsyncPaginate
                                            loadOptions={fetchOption}
                                            isSearchable
                                            placeholder="All Sales Supervisor"
                                            className='react-select-container w-180px'
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </th>
                                    <th className='min-w-80px'>
                                        <AsyncPaginate
                                            loadOptions={fetchOption}
                                            isSearchable
                                            placeholder="All Sales Supervisor"
                                            className='react-select-container w-180px'
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Warehouse Type</td>
                                    <td>Headquarter</td>
                                    <td>Headquarter</td>
                                </tr>
                                <tr>
                                    <td>Warehouse Location</td>
                                    <td>Dar Es Salaam</td>
                                    <td>Aarusha</td>
                                </tr>
                                <tr>
                                    <td>Warehouse Type</td>
                                    <td>Headquarter</td>
                                    <td>Headquarter</td>
                                </tr>
                                <tr>
                                    <td>Wards Covering</td>
                                    <td>4 Wards</td>
                                    <td>2 Wards</td>
                                </tr>
                                <tr>
                                    <td>Warehouse Manager</td>
                                    <td>Klavdia Dedova</td>
                                    <td>Hirini Hakopa</td>
                                </tr>
                                <tr>
                                    <td>Team Size</td>
                                    <td>30 Members</td>
                                    <td>22 Members</td>
                                </tr>
                                <tr>
                                    <td>Vehicles</td>
                                    <td>18 Vehicles</td>
                                    <td>22 Vehicles</td>
                                </tr>
                                <tr>
                                    <td>Total SKUs</td>
                                    <td>21 SKUs</td>
                                    <td>21 SKUs</td>
                                </tr>
                                <tr>
                                    <td>Sales</td>
                                    <td>TZS  2053</td>
                                    <td>TZS  89597</td>
                                </tr>
                                <tr>
                                    <td>Return</td>
                                    <td>TZS  399</td>
                                    <td>TZS  290</td>
                                </tr>
                                <tr>
                                    <td>Credit Issues</td>
                                    <td>TZS  214</td>
                                    <td>TZS  360</td>
                                </tr>

                            </tbody>
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            {/* end:: card */}
        </>
    )
}

export default ComparisonReportWM