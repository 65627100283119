import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { apiBaseURL, checkPermissions, defaultPermission, isModalValidation, KTSVG } from "../../../../_eaFruitsDms/helpers"
import ShowingEntries from "../../../../_eaFruitsDms/helpers/components/ShowingEntries"
import { getRequest, postRequest, putRequest } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"

const ManualStockUpdate = () => {
  const initialPageState = {
    search: '',
    limit: 10,
    offset: '',
    list: [],
    next: '',
    count: '',
    previous: '',
    isLoading: true,
    currentOffset: '',
    selected: 0,
  }
  const initialValue = { option: '', type: 'Manual_Stock_Update' }
  const [pageState, setPageState] = useState(initialPageState)
  const [validation, setValidation] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [id, setId] = useState('')
  const [disableBtn, setDisable] = useState(false)
  const [isLoading, setIsLoading] = useState('Submit')
  const [requestHandler, setRequest] = useState('Add')
  const [name, setName] = useState('')
  const [manualStock, setManualStock] = useState(initialValue)
  const [errorMessage, setErrorMessage] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  const getStockUpdateList = async () => {
    // Develop BY: Kashyap
    // Description: get List Details
    setPageState({
      ...pageState,
      isLoading: true,
    })
    const url =
      apiBaseURL() +
      'master/options-master/?' +
      `ordering=-updated_date&limit=${pageState.limit}&offset=${pageState.currentOffset}&search=${pageState.search}&type=Manual_Stock_Update`
    const response = await getRequest(url, true)
    setPageState({
      ...pageState,
      list: response.results,
      next: response.next,
      count: response.count,
      previous: response.previous,
      isLoading: response ? false : true,
    })
  }

  useEffect(() => {
    getStockUpdateList()
  }, [pageState.currentOffset, pageState.search])
 
  useEffect(() => {
  fetchPermission()
 },[])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_rejection_options")
    setPermission(res)
   }

  const cancleFunction = () => {
    setManualStock(initialValue)
    setIsLoading('Submit')
    setDisable(false)
    setValidation(false)
    setErrorMessage('')
    setModalLoader(false)
  }

  const modalHandler = (event: any, key: any) => {
    // Develop BY: Kashyap
    // Description: handling modal value when user add details
    key === 'input' && setManualStock({ ...manualStock, [event.target.name]: event.target.value })
    if (key === 'edit') {
      setRequest('Edit')
      setModalLoader(true)
      setId(event.id)
      setManualStock({ ...manualStock, option: event.option })
      setModalLoader(false)
    }
  }

  const onSubmit = async () => {
    setValidation(true)
    if (isModalValidation(manualStock).length === 0) {
      setName(manualStock.option)
      setDisable(true)
      setIsLoading('Please Wait...')
      let url = apiBaseURL() + 'master/options-master/'
      let editUrl = apiBaseURL() + 'master/options-master/' + id + '/'

      // Develop BY: Kashyap
      // Description: make the requestHandler for post and patch request with same code.
      const response =
        requestHandler === 'Add'
          ? await postRequest(url, manualStock, true)
          : await putRequest(editUrl, manualStock, true)
      let successArray = [201, 200]
      let failArray = [400, 500]
      successArray.includes(response.status) && responseSuccess()
      failArray.includes(response.status) && responseFail()

      if (response.data.error) {
        if (response.data.error.field === 'non_field_errors') {
          setErrorMessage(
            'Your added stock update reason already exists in this system! Please try again with a unique reason.'
          )
        }
      }
    }
  }

  const responseSuccess = () => {
    getStockUpdateList()
    setShowAlert(true)
    setIsLoading('Submit')
    setDisable(false)
  }

  const responseFail = () => {
    setIsLoading('Submit')
    setDisable(false)
  }
  const masterHeaderTitle = {
    title: 'Masters',
  }

  return (
    <>
      <EATitle title='Manual Stock Update Reason - ' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>{'Manual Stock Update Reason'}</PageTitle>
      <EaAlertSuccess
        newProps={true}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={
          requestHandler === 'Add'
            ? 'MANUAL.STOCK.UPDATE.ADD'
            : requestHandler === 'Edit' && 'MANUAL.STOCK.UPDATE.EDIT'
        }
        name={name}
      />

      <div className={`card`}>
        {pageState.isLoading && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar newSearch={true} setResponse={setPageState} />
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
              {permission.write && 
              <EaButton
              onClick={() => setRequest('Add')}
              isModal={true}
              dataTarget='#ea_modal_create_blogauthor'
              btnName='Add Manual Stock Update Reason'
            />}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <TeamTable
            list={pageState.list}
            showWrite={permission.write}
            getPropsEdit={(item: any) => modalHandler(item, 'edit')}
          />
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            <ShowingEntries response={pageState} />
          </div>
          <div className='col'>
            <CustomPagination newProp={true} pageState={pageState} setPageState={setPageState} />
          </div>
        </div>
      </div>

      {/* add group */}
      <EaModal
        actionBtnName={isLoading}
        btnDisabled={disableBtn}
        cancel={cancleFunction}
        onSubmit={onSubmit}
        id='ea_modal_create_blogauthor'
        modalTitle={
          requestHandler === 'Add' ? 'Add Stock Update Reason' : 'Edit Stock Update Reason'
        }
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Stock Update Reason<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx(
              'form-control',
              validation && manualStock.option === '' ? 'is-invalid' : ''
            )}
            name='option'
            onChange={(e) => modalHandler(e, 'input')}
            value={manualStock.option}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-4'>{errorMessage && <ErrorHandler errorMessage={errorMessage} />}</div>
      </EaModal>
    </>
  )
}

export default ManualStockUpdate

const TeamTable = (props: any) => {
  return (
    <div className='table-responsive'>
      <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
        <thead>
          <tr className=' text-muted fw-500'>
            <th className='min-w-120px py-2'>Option</th>
            <th className='min-w-80px action-th py-2'>{props.showWrite && "Actions"}</th>
          </tr>
        </thead>
        <tbody>
          {props.list.length !== 0 &&
            props.list.map((item: any) => (
              <tr>
                <td>{item.option}</td>
                <td className='text-end'>
                 {props.showWrite && 
                  <a
                  onClick={() => props.getPropsEdit(item)}
                  href='#ea_modal_create_blogauthor'
                  data-bs-toggle='modal'
                  data-bs-target='#ea_modal_create_blogauthor'
                  className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                </a>
                }
                </td>
              </tr>
            ))}
          <tr>
            <td>Other</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
