
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core';
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title';
import { useIntl } from 'react-intl';
import { AsyncPaginate } from 'react-select-async-paginate'

const fetchWarehouse = async (search?: any) => {
    let options: any = [{ label: 'Option 1', value: 'option1' }, { label: 'Option 3', value: 'option3' }]
    let hasMore: boolean = false
    options.push({
        label: "All Warehouses",
        value: ''
    })
    return {
        options: options,
        hasMore: hasMore
    }
}

const CreditUsageReport = () => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Credit Usage Report"
    }
    return (
        <>
            <EATitle title="Credit Usage Report - " />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Credit Usage Report' })}</PageTitle>
            {/* chart start */}
            <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            {/* begin::Title */}
                            <div>
                                <h3 className="card-title align-items-start flex-column my-0">
                                    <span className="card-label fw-bold font-17">Top Credit Usage</span>
                                </h3>
                                <p className="font-13 text-muted mb-0">Based on total amount of the Credit Issues</p>
                            </div>
                            {/* end::Title */}
                            {/* begin::Toolbar */}
                            <div className='card-toolbar d-flex g-3 my-0'>
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2020',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar my-1 me-3 w-auto" />
                                </DateRangePicker>
                                <AsyncPaginate
                                    loadOptions={fetchWarehouse}
                                    isSearchable
                                    placeholder="All Warehouses"
                                    className='react-select-container my-1 w-150px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                            </div>
                            {/* end::Toolbar */}
                        </div>

                        <div className='card-body'>
                            Chart Area
                        </div>
                    </div>
                </div>
            </div>
            {/* chart end */}
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    {/* begin::Toolbar */}
                    <div className='card-toolbar d-flex g-3 my-0'>
                        <DateRangePicker
                            initialSettings={
                                {
                                    startDate: '01/01/2020',
                                    endDate: '01/12/2020',
                                    alwaysShowCalendars: true,
                                    cancelClass: 'btn-secondary',
                                    drops: "auto",
                                    locale: {
                                        format: 'DD/MM/YYYY'
                                    },
                                    ranges: {
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    },
                                }}
                        >
                            <input type="text" className="form-control calendar my-1 me-3 w-auto" />
                        </DateRangePicker>
                        <AsyncPaginate
                            loadOptions={fetchWarehouse}
                            isSearchable
                            placeholder="All Warehouses"
                            className='react-select-container my-1 w-150px'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                    </div>
                    {/* end::Toolbar */}
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-150px py-2'>Customer Name</th>
                                    <th className='min-w-120px py-2'>Total Sales</th>
                                    <th className='min-w-100px py-2'>% of Credit</th>
                                    <th className='min-w-120px py-2'>Credit Issued</th>
                                    <th className='min-w-150px py-2'>Open Credit Issue</th>
                                    <th className='min-w-150px py-2'>On Time Payment</th>
                                    <th className='min-w-150px py-2'>Delayed Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Leroy Harrington</td>
                                    <td>TZS 61321</td>
                                    <td>99%</td>
                                    <td>TZS 48498</td>
                                    <td>TZS 572</td>
                                    <td>23</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td>Leroy Harrington</td>
                                    <td>TZS 61321</td>
                                    <td>99%</td>
                                    <td>TZS 48498</td>
                                    <td>TZS 572</td>
                                    <td>23</td>
                                    <td>0</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>

            <div className='card mt-3'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    {/* begin::Toolbar */}
                    <div className='card-toolbar d-flex g-3 my-0'>
                        <DateRangePicker
                            initialSettings={
                                {
                                    startDate: '01/01/2020',
                                    endDate: '01/12/2020',
                                    alwaysShowCalendars: true,
                                    cancelClass: 'btn-secondary',
                                    drops: "auto",
                                    locale: {
                                        format: 'DD/MM/YYYY'
                                    },
                                    ranges: {
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    },
                                }}
                        >
                            <input type="text" className="form-control calendar my-1 me-3 w-auto" />
                        </DateRangePicker>
                        <AsyncPaginate
                            loadOptions={fetchWarehouse}
                            isSearchable
                            placeholder="All Warehouses"
                            className='react-select-container my-1 w-150px'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                    </div>
                    {/* end::Toolbar */}
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-150px py-2'>Sales Reps. Name</th>
                                    <th className='min-w-120px py-2'>Total Sales</th>
                                    <th className='min-w-100px py-2'>% of Credit</th>
                                    <th className='min-w-120px py-2'>Credit Issued</th>
                                    <th className='min-w-150px py-2'>Open Credit Issue</th>
                                    <th className='min-w-150px py-2'>On Time Payment</th>
                                    <th className='min-w-150px py-2'>Delayed Payment</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>
                                    <td>Leroy Harrington</td>
                                    <td>TZS 61321</td>
                                    <td>99%</td>
                                    <td>TZS 48498</td>
                                    <td>TZS 572</td>
                                    <td>23</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td>Leroy Harrington</td>
                                    <td>TZS 61321</td>
                                    <td>99%</td>
                                    <td>TZS 48498</td>
                                    <td>TZS 572</td>
                                    <td>23</td>
                                    <td>0</td>
                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>

                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreditUsageReport
