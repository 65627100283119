import FetchingRecords from '../../layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../layout/components/noRecords/noRecords'

interface Props {
  displayLoader: boolean
  data: any
  columns: any
  trClassName?: string
}

const DynamicTable = ({displayLoader, data, columns, trClassName}: Props) => {
  // Example
  // <div className='card-body pt-0 pb-3'>
  //     <DynamicTable columns={columns} data={data} displayLoader={displayLoader} />
  // </div>

  // const columns = [
  //   {
  //     label: 'Date of Ref.',
  //     format: (val: any) => (isEmpty(val?.date) ? '-' : dateFormat(val?.date).apiDate),
  //     onClick: (val: any) => {},
  //   },
  //   {
  //     label: 'Status',
  //     format: () => <span className='badge badge-light-success text-dark'>Active</span>,
  //   },
  //   {
  //     label: 'Action',
  //     format: (val: any) => (
  //       <div
  //         onClick={() => {
  //           alert(JSON.stringify(val))
  //         }}
  //       >
  //         <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
  //       </div>
  //     ),
  //   },
  // ]

  return (
    <>
      <div className='table-responsive'>
        {displayLoader ? (
          <FetchingRecords />
        ) : data.length === 0 ? (
          <NoRecords />
        ) : (
          <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
            <thead>
              <tr className='text-muted fw-500'>
                {columns.map((col: any, index: any) => (
                  <th key={col?.label?.trim() ?? index} className='min-w-100px'>
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row: any, rowIndex: any) => (
                <tr key={rowIndex} className={trClassName ? trClassName : ''}>
                  {columns.map((col: any, index: string) => (
                    <td
                      key={rowIndex + index}
                      className={`${col?.className ? col.className : ''}`}
                      {...(col?.onClick && {
                        onClick: () => {
                          col?.onClick(row)
                        },
                      })}
                    >
                      {col?.format ? col?.format(row) : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  )
}

export default DynamicTable
