/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {getUserType} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import OOProductSku from './OOproductSku'

import ProductSku from './productSku'
// import { OO, superAdmin, w_manager } from '../../../_eaFruitsDms/localstorage/setitemStore';

const superAdmin = localStorage.getItem('superAdmin')
const w_manager = localStorage.getItem('w_manager')
const OO = localStorage.getItem('OO')

const ProductSkuWrapper: FC = () => {
  const intl = useIntl()
  const headerTitle = {
    title: 'ProductSku',
  }
  return (
    <>
      {/* <PageTitle breadcrumbs={[headerTitle]} >{intl.formatMessage({ id: 'ProductSku' })}</PageTitle> */}

      {localStorage.getItem('user_type') === 'SA' && (
        <ProductSku className='super-admin-productsku' />
      )}
      {/* {localStorage.getItem('user_type') === "WM" && <WMProductSku className='wm-admin-productsku' />} */}
      {localStorage.getItem('user_type') === 'OO' && (
        <OOProductSku className='oo-admin-productsku' />
      )}
    </>
  )
}

export {ProductSkuWrapper}
