import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function LOAsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/vehicle-management'
        icon='/media/icons/duotune/abstract/abs037.svg'
        title='Vehicle Management'
      />

      <AsideMenuItem
        to='/all-complaints'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Complaints'
      />

      <AsideMenuItemWithSub
        to='/reports'
        title='Reports'
        icon='/media/icons/duotune/graphs/gra006.svg'
      >
        <AsideMenuItem
          to='/reports/vehicle-usage-report'
          title='Vehicle Usage Report'
          hasBullet={true}
        />
        {/* <AsideMenuItem to='/reports/vehicle-usage-report' title='Vehicle Usage Report' hasBullet={true} /> */}
        <AsideMenuItem
          to='/reports/vehicle-breakdown-report'
          title='Vehicle Breakdown Report'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
    </>
  )
}
