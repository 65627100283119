/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'

import {getUserType} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import CustomerPage from './customerPage'
import TSCustomers from './TScustomers/TSCustomers'
import WMCustomers from './WMcustomers/WMCustomers'

const superAdmin = localStorage.getItem('superAdmin')
const w_manager = localStorage.getItem('w_manager')
const OO = localStorage.getItem('OO')

type Props = {
  className: string
}
const CustomersWrapper: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Customers',
  }
  return (
    <>
      <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({id: 'Customers'})}</PageTitle>
      {localStorage.getItem('user_type') === 'SA' && (
        <CustomerPage className='super-admin-customers' />
      )}
      {localStorage.getItem('user_type') === 'WM' && <WMCustomers className='wm-customers' />}
      {localStorage.getItem('user_type') === 'TS' && <TSCustomers className='ts-customers' />}
      {/*{localStorage.getItem('user_type') === "OO" && <OOProductSku className='oo-admin-productsku' />} */}
    </>
  )
}

export {CustomersWrapper}
