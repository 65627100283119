import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  getLoginWarehouse,
  globalDateFormate,
  isAdmin,
  isEmpty,
  numberFormat,
} from '../../../../_eaFruitsDms/helpers'
import {RouteFilter} from '../../../../_eaFruitsDms/helpers/components/RouteFilter'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'
import {useNavigate} from 'react-router-dom'
import {SalesTeamFilter} from '../../../../_eaFruitsDms/helpers/components/SalesTeamFilter'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'

const SrTimeTrackingReport = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'SRs Daily Routes',
  }

  const [currentOffSet, setCurrentOffSet] = useState<any>('0')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [filteredDate, setFilteredDate] = useState<any>({
    startDate: dateFormat(moment()).apiDate,
    endDate: dateFormat(moment()).apiDate,
  })
  const [warehouse, setWarehouse] = useState<any>(
    isAdmin() ? {label: 'All Warehouse', value: ''} : getLoginWarehouse()
  )
  const [route, setRoute] = useState<any>({
    label: 'All Routes',
    value: '',
  })
  const [salesTeam, setSalesTeam] = useState<any>({
    label: 'All Sales Team',
    value: '',
  })
  const [records, setRecords] = useState([])

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchReportList().then()
  }, [debounceSearch, route, selected, currentOffSet, warehouse, salesTeam, filteredDate])

  const fetchReportList = async () => {
    setDisplayLoader(true)

    const routeId = isEmpty(route.value) ? '' : route.value
    const salesTeamId = isEmpty(salesTeam.value) ? '' : salesTeam.value

    const response = await getRequest(
      `${apiBaseURL()}routes/sr-daily-route-report?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&route_id=${routeId}&warehouse_id=${
        isEmpty(warehouse?.value) ? '' : warehouse?.value
      }&route_date=${dateFormat(filteredDate?.startDate).apiDate}&sales_team_id=${salesTeamId}`,
      true
    )

    if (response?.results) {
      setRecords(response?.results)
      setCount(response?.count)
    }

    setDisplayLoader(false)
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name

    setCurrentOffSet('')
    setSelected(0)

    switch (inputName) {
      case 'route':
        setRoute(e)
        break

      case 'salesTeam':
        setSalesTeam(e)
        setRoute({
          label: 'All Routes',
          value: '',
        })

        break

      case 'warehouse':
        setWarehouse(e)
        setRoute({
          label: 'All Routes',
          value: '',
        })

        break

      case 'search':
        setSearchByName(e.target.value)
        break
    }
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            maxDate: moment(),
            singleDatePicker: true,
            locale: {
              format: globalDateFormate(),
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-1'
            defaultValue={value}
            disabled={displayLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='SRs Daily Routes' />

      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'SRs Daily Routes'})}
      </PageTitle>

      <div>
        <div className='card'>
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={handleFilterChange} isDisable={displayLoader} />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                <div className='w-180px me-3'>
                  <CustomDatePickerComponent
                    onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                      setFilteredDate({
                        startDate: callBakeDate.startDate,
                        endDate: callBakeDate.endDate,
                      })
                    }}
                    value={filteredDate}
                    startDate={filteredDate.startDate}
                    endDate={filteredDate.endDate}
                  />
                </div>

                <WarehouseFilter
                  warehouse={warehouse}
                  handleFilterChange={handleFilterChange}
                  isDisabled={displayLoader}
                  allWarehouse={isAdmin()}
                />

                <SalesTeamFilter
                  salesTeam={salesTeam}
                  isDisabled={displayLoader}
                  handleFilterChange={handleFilterChange}
                />

                <RouteFilter
                  route={route}
                  isDisabled={
                    (isAdmin() && isEmpty(warehouse?.value)) || displayLoader ? true : displayLoader
                  }
                  handleFilterChange={handleFilterChange}
                  warehouse={isEmpty(warehouse?.value) ? '' : warehouse?.value}
                  sales_team_id={isEmpty(salesTeam?.value) ? '' : salesTeam?.value}
                />
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              {displayLoader ? (
                <FetchingRecords />
              ) : records.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th>Route No</th>
                      <th>Route Name</th>
                      <th>Warehouse Name</th>
                      <th>Sales Team</th>
                      <th>Manage By</th>
                      <th>Total Customer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((item: any) => {
                      return (
                        <>
                          <tr
                            className='cursor-pointer'
                            onClick={() => {
                              navigate('/reports/sr-daily-routes', {
                                state: {
                                  itemId: isEmpty(item?.id) ? '' : item?.id,
                                  routeName: checkEmpty(item?.name),
                                  routeDate: isEmpty(filteredDate?.startDate)
                                    ? ''
                                    : filteredDate?.startDate,
                                },
                              })
                            }}
                          >
                            <td>{isEmpty(item?.route_no) ? '-' : `#${item?.route_no}`}</td>
                            <td>{checkEmpty(item?.name)}</td>
                            <td>{checkEmpty(item?.warehouse_name)}</td>
                            <td>{checkEmpty(item?.sales_team_name)}</td>
                            <td>{checkEmpty(item?.manage_by_name)}</td>
                            <td>
                              {isEmpty(item?.customer_count)
                                ? '-'
                                : numberFormat(item?.customer_count).displayFormat}
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <CustomPaginate
          data={records}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffSet}
          totalRecords={count}
          limit={limit}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default SrTimeTrackingReport
