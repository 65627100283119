import * as React from "react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import { apiBaseURL, categoryOptions, KTSVG, measurementLabel, numberFormat, retrieveFilter, saveFilter } from "../../../_eaFruitsDms/helpers";
import { categoryStyle } from "../../../_eaFruitsDms/helpers/components/categoryStyle";
import { getRequest } from "../../../_eaFruitsDms/https/apiCall";
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomSearchBar from "../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import SkuThumbnail from "../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail";
import { PageLink, PageTitle } from "../../../_eaFruitsDms/layout/core";
import tenantConfiguration from "../../../TenantVariables";
import EATitle from "../../../_eaFruitsDms/layout/components/title/title";
import CustomPaginate from "../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import useDebounce from "../../../_eaFruitsDms/helpers/components/useDebounce";
import ResetButton from "../../../_eaFruitsDms/layout/components/button/resetButton";
import { filters } from "../../../_eaFruitsDms/utils/FilterConstants";

type Props = {
  className: string
}

const TSCurrentStock: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Current Stock & Pricing',
    subTitle: 'Current Stock & Pricing',
  }
  const currentBreadCrumbs: Array<PageLink> = [
    {
      title: 'Current Stock & Pricing',
      path: '/current-stock',
      isSeparator: false,
      isActive: false,
    },
  ]

  let stockFiltered : any = retrieveFilter(filters.ts_current_stock);

  const initialState = {
      selectedWarehouseId : stockFiltered?.selectedWarehouseId || {label : 'All Warehouses' , value : ''},
      selectedBrandId: stockFiltered?.selectedBrandId || {label : 'All Categories' , value : ''},
      selectedCategoryId : stockFiltered?.selectedCategoryId || {label : 'All Brands' , value : ''},
      selectedGroupId: stockFiltered?.selectedGroupId || {label : 'All Group' , value : ''},
      selected: stockFiltered?.selected || '',
      search: stockFiltered?.search || '',
      currentOffSet: stockFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [currentStockList, setCurrentStockList] = useState<any>([])
  const [count, setCount] = useState<any>('')
  const [next, setNext] = useState<any>('')
  const [prev, setPrev] = useState<any>('')
  const [search, setSearch] = useState<any>('')
  const [selected, setSelected] = useState<number>(stockFiltered?.selected || 0)
  const [currentOffset, setCurrentOffset] = useState<any>(stockFiltered?.currentOffSet || '0');
  const [searchSelect, setSearchSelect] = useState('')
  const [brandOffset, setBrandOffset] = useState<any>('')
  const [brandOption, setBrandOption] = useState<any>([])
  const [wareHouseOffset, setWareHouseOffset] = useState<any>('')
  const [wareHouseOption, setWareHouseOption] = useState<any>([])
  const [categoryOffset, setCategoryOffset] = useState<any>('')
  const [categoryOption, setCategoryOption] = useState<any>([])
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<any>(stockFiltered?.selectedWarehouseId || {
    label: 'Warehouse',
    value: ''
});
  const [initialWareHouseId, setInitialWareHouseId] = useState<any>('')
  const [selectedBrandId, setSelectedBrandId] = useState<any>(stockFiltered?.selectedBrandId || {
    label: 'All Brands',
    value: ''
});
  const [selectedCategoryId, setSelectedCategoryId] = useState<any>(stockFiltered?.selectedCategoryId || {
    label: 'All Categories',
    value: ''
});
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(0)
  const [searchByName, setSearchByName] = useState<string>(stockFiltered?.search || '')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [groupOffSet, setGroupOffSet] = useState<any>('')
  const [groupOption, setGroupOption] = useState<any>([])
  const [selectedGroupId, setSelectedGroupId] = useState<any>(stockFiltered?.selectedGroupId || {
    label: 'All Group',
    value: ''
})
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(stockFiltered?.buttonDisable || false) 
  const [defaultdWarehouseName, setDefaultdWarehouseName] = useState<any>('') 
  const [defaultdWarehouseId, setDefaultdWarehouseId] = useState<any>('') 

  const debounceSearch = useDebounce(searchByName, 500);

  useEffect(() => {
    initialCurrentStockList()
  }, [])
  useEffect(() => {
    if (selectedWarehouseId.value != '') {
      fetchCurrentStock()

      let updatedState: any = { ...filterStates }
      updatedState['selected'] = selected
      updatedState['currentOffSet'] = currentOffset
      updatedState['buttonDisable'] = buttonDisable
  
      setFilterStates(updatedState)
      saveFilter(filters.ts_current_stock , filterStates)

      if(selectedWarehouseId.value == defaultdWarehouseId && selectedBrandId.value == '' && searchByName == '' && selectedCategoryId.value == '' && selectedGroupId.value == ''){
          setButtonDisable(false)
      }
    }
  }, [
    currentOffset,
    selectedWarehouseId,
    selectedBrandId,
    selectedCategoryId,
    debounceSearch,
    initialWareHouseId,
    selectedGroupId,
  ])

  useEffect(() => {
    setWareHouseOffset('')
    setBrandOffset('')
    setCategoryOffset('')
    setGroupOffSet('')
  }, [searchSelect])

  // Develop BY: Kriti
  // Description: initially load warehouse dropdown

  const initialCurrentStockList = async () => {
    const API = `${apiBaseURL()}warehouse/list?limit=${limit}&ordering=name&offset=${wareHouseOffset}&search=${search}`
    const response = await getRequest(API, true)
    // setSelectedWarehouseId(response.results[0].id)
    setSelectedWarehouseId( stockFiltered?.selectedWarehouseId ||
      {label: response.results[0].name,
      value: response.results[0].id})
      
    setDefaultdWarehouseName(response.results[0].name)
    setDefaultdWarehouseId(response.results[0].id)

    setWareHouseOption([{ label: response.results[0].name, value: response.results[0].id }])
  }

  const fetchCurrentStock = async () => {
    setListLoader(true)
    const Url = `&warehouse_id=${selectedWarehouseId.value}&brand_id__id=${selectedBrandId.value}&category_id__id=${selectedCategoryId.value}&variants__group_name__id=${selectedGroupId.value}&trade_only=True`
    const CurrentStockList = `${apiBaseURL()}inventory/product-SKUs/?&limit=${limit}&offset=${currentOffset}&search=${debounceSearch}`

    const response = await getRequest(CurrentStockList + Url, true)
    setCurrentStockList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.prev)
    setListLoader(false)
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  // Develop BY: Kriti
  // Description: fetch warehouse drop-down's options which is called on AsyncPaginate

  const fetchWarehouse = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const WAREHOUSE_API = `${apiBaseURL()}warehouse/list?limit=${limit}&ordering=name&offset=${wareHouseOffset}&search=${search}`
    const response = await getRequest(WAREHOUSE_API, true)
    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setWareHouseOffset(newOffset)
      hasMore = true
    }
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }
    if (wareHouseOption.length > 0) {
      setWareHouseOption(wareHouseOption.concat(options))
    } else {
      setWareHouseOption(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  // Develop BY: Kriti
  // Description: fetch Brands drop-down's options which is called on AsyncPaginate

  const fetchBrand = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const BRAND_API = `${apiBaseURL()}master/product/brand?&search=${search}&limit=${limit}&offset=${brandOffset}`
    const response = await getRequest(BRAND_API, true)
    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setBrandOffset(newOffset)
      hasMore = true
    }
    if (brandOption.length == 0) {
      options.push({
        label: 'All Brand',
        value: '',
      })
    }
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }
    if (brandOption.length > 0) {
      setBrandOption(brandOption.concat(options))
    } else {
      setBrandOption(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  // Develop BY: Kriti
  // Description: fetch Category drop-down's options which is called on AsyncPaginate

  const fetchCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const CATEGORY_API = `${apiBaseURL()}master/product/parentcategory/category?&search=${search}&limit=${limit}&offset=${categoryOffset}&ordering=name`
    const response = await getRequest(CATEGORY_API, true)
    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffset(newOffset)
      hasMore = true
    }

    if (categoryOption.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          ...categoryOptions,
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }
    if (categoryOption.length > 0) {
      setCategoryOption(categoryOption.concat(options))
    } else {
      setCategoryOption(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  // Develop BY: Kriti
  // Description: fetch Group drop-down's options which is called on AsyncPaginate

  const fetchGroups = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${limit}&offset=${groupOffSet}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setGroupOffSet(newOffset)
      hasMore = true
    }
    if (groupOption.length == 0) {
      options.push({
        label: 'All Group',
        value: '',
      })
    }
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (groupOption.length > 0) {
      setGroupOption(groupOption.concat(options))
    } else {
      setGroupOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const handleFilterOptionChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    switch (inputName) {
      case 'warehouse':
        setTimeoutSeconds(0)
        setSelectedWarehouseId(e)
        setFilterStates({ ...filterStates, ['selectedWarehouseId']: e })
        break
      case 'brand':
        setTimeoutSeconds(0)
        setSelectedBrandId(e)
        setFilterStates({ ...filterStates, ['selectedBrandId']: e })
        break
      case 'category':
        setTimeoutSeconds(0)
        setSelectedCategoryId(e)
        setFilterStates({ ...filterStates, ['selectedCategoryId']: e })
        break
      case 'group':
        setTimeoutSeconds(0)
        setSelectedGroupId(e)
        setFilterStates({ ...filterStates, ['selectedGroupId']: e })
        break
      case 'search':
        setTimeoutSeconds(500)
        setSearchByName(inputValue)
        setFilterStates({ ...filterStates, ['search']: inputValue })
        break
    }
  }

  const handleChangeReset = () =>{
    setSearchByName('')
    setSelectedWarehouseId({label : defaultdWarehouseName , value : defaultdWarehouseId})
    setSelectedCategoryId({label : 'All Categories' , value : ''})
    setSelectedBrandId({label : 'All Brands' , value : ''})
    setSelectedGroupId({label : 'All Group' , value : ''})
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['selectedWarehouseId'] = ''
    updatedState['selectedBrandId'] = ''
    updatedState['selectedCategoryId'] = ''
    updatedState['selectedGroupId'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}
  return (
    <>
      <EATitle title='Current Stock & Pricing' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({ id: 'Current Stock & Pricing' })}
      </PageTitle>
      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={handleFilterOptionChange} value={searchByName} isDisabled={listLoadder} />
            <div className='col-auto ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form className='card-toolbar d-flex my-0' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                <AsyncPaginate
                    
                  loadOptions={fetchWarehouse}
                  isSearchable
                  // value={wareHouseOption.filter(
                  //   (option: any) => option.value == selectedWarehouseId
                  // )}
                  value={selectedWarehouseId}
                  onChange={(e: any) => handleFilterOptionChange(e, 'warehouse')}
                  isDisabled={listLoadder}
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchBrand}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  // value={brandOption.filter((option: any) => option.value == selectedBrandId)}
                  value={selectedBrandId}
                  onChange={(e: any) => handleFilterOptionChange(e, 'brand')}
                  isDisabled={listLoadder}
                  placeholder='All Brands'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchCategory}
                  isSearchable
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  placeholder='All Categories'
                  styles={categoryStyle}
                  value={selectedCategoryId}
                  // value={categoryOption.filter((option: any) => option.value == selectedCategoryId)}
                  onChange={(e: any) => handleFilterOptionChange(e, 'category')}
                  isDisabled={listLoadder}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchGroups}
                  isSearchable
                  className='react-select-container my-1 w-120px'
                  classNamePrefix='react-select'
                  value={selectedGroupId}
                  // value={groupOption.filter((option: any) => option.value == selectedGroupId)}
                  onChange={(e: any) => handleFilterOptionChange(e, 'group')}
                  isDisabled={listLoadder}
                  placeholder='All Group'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
                {/* </div> */}
              </form>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
          {currentStockList.length == 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-150px py-2'>Product SKU</th>
                        <th className='min-w-120px py-2'>Brand</th>
                        <th className='min-w-120px py-2'>Category</th>
                        <th className='min-w-130px py-2'>Product Group</th>
                        <th className='min-w-90px py-2'>Size</th>
                        <th className='min-w-100px py-2'>Packaging</th>
                        <th className='min-w-100px py-2'>Stock</th>
                        <th className='min-w-130px py-2'>Retail Price</th>
                        <th className='min-w-130px py-2'>Wholesale Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentStockList.length > 0 &&
                        currentStockList.map((item: any) => {
                          const quantityLabel: string = measurementLabel(
                            item.fix_size
                              ? item.material.name
                              : item.variants && item.variants.approx_weight > 0
                                ? item.material.name
                                : item.measurement_unit
                          )

                          let approxWeight = 0

                          if (item.variants && item.variants.approx_weight > 0) {
                            approxWeight = item.variants.approx_weight / 1000
                          }

                          return (
                            <>
                              <tr>
                                <td>
                                  <SkuThumbnail src={item.product_thumbnail} skuName={item.name} />
                                </td>
                                <td>{item.brand_id}</td>
                                <td>
                                  <div>
                                    <div className='text-muted font-13 mb-1 font-medium'>
                                      {item.parent_category}
                                    </div>
                                    {item.category_id}
                                  </div>
                                </td>
                                <td>{item.group_name}</td>
                                <td>
                                  {approxWeight > 0
                                    ? approxWeight + ' Kgs'
                                    : (item.measurement_unit.toLowerCase() === 'gram'
                                      ? item.size / 1000
                                      : item.size) + ' Kgs'}
                                </td>
                                <td>{item.material.name}</td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <span>{`${numberFormat(item.total_qty).displayFormat}`}</span>
                                    {item.low_stock ? (
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr057.svg'
                                        className='ic ms-1'
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </td>
                                {item.price.length === 0 ? (
                                  <td>-</td>
                                ) : (
                                  item.price &&
                                  item.price.map((data: any) => {
                                    return (
                                      <>
                                        <td>
                                          {tenantConfiguration.currencyCode}{' '}
                                          {`${numberFormat(data.retail_price).displayFormat}`}
                                          <div>
                                            {tenantConfiguration.currencyCode}{' '}
                                            {`${numberFormat(data.retail_expiry_price).displayFormat
                                              }`}{' '}
                                            (NE)
                                          </div>
                                        </td>
                                      </>
                                    )
                                  })
                                )}

                                {item.price.length === 0 ? (
                                  <td>-</td>
                                ) : (
                                  item.price &&
                                  item.price.map((data: any) => {
                                    return (
                                      <>
                                        <td>
                                          {tenantConfiguration.currencyCode}{' '}
                                          {`${numberFormat(data.retail_price).displayFormat}`}
                                          <div>
                                            {tenantConfiguration.currencyCode}{' '}
                                            {`${numberFormat(data.wholesale_expiry_price)
                                              .displayFormat
                                              }`}{' '}
                                            (NE)
                                          </div>
                                        </td>
                                      </>
                                    )
                                  })
                                )}
                              </tr>
                            </>
                          )
                        })}
                    </tbody>
                  </table>
                )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CustomPaginate
        data={currentStockList}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        saveFilterName={filters.ts_current_stock}
        filterStates={filterStates}
        isDisabled={listLoadder}
      />
    </>
  )
}

export default TSCurrentStock
