import $ from "jquery"

// validations

export const validateNumberField = (number: any) => {
    let numberParse = parseInt(number)
    const numberRegEx = /^[0-9]*$/;
    return numberRegEx.test(String(numberParse).toLowerCase());
};

export const validateStringField = (string: any) => {
    let newString = string.replace(/ /g, '');
    const stringRegEx = /^[a-zA-Z]+$/;
    return stringRegEx.test(newString);
};

export const kmlValidation = (file: any) => {
    const allowedExtensions =
        /(\.kml)$/i;
    if (!allowedExtensions.exec(file)) {
        return false;
    } else {
        return true;
    }
}

export const imageValidation = (id: any) => {
    let imgWidth: any = $(`#${id}`).width();
    let imgHeight: any = $(`#${id}`).height();
    if (imgWidth > 500 || imgHeight > 500) {
        return false
    } else {
        return true
    }
}


export const validateEmail = (email: any) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true)
    } else {
        return (false)
    }
};
