import moment from 'moment'
import {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  getFirstWarehouse,
  getLoginWarehouse,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
  staticSearch,
} from '../../../../_eaFruitsDms/helpers'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaChartBar} from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'

const RouteUsageReport = () => {
  const intl = useIntl()

  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Route Usage Report',
  }

  const routeUsageReportId = document.getElementById('route-usage-report-filters')

  const [currentOffSet, setCurrentOffSet] = useState<any>('0')
  const [displayLoader, setDisplayLoader] = useState<boolean>(true)
  const [displayDetailsLoader, setDisplayDetailsLoader] = useState<boolean>(false)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [warehouse, setWarehouse] = useState<any>({label: 'Select', value: ''})
  const [month, setMonth] = useState({label: 'This Month', value: 'this_month'})
  const [barChart, setBarChart] = useState({})
  const [barCharEmpty, setBarChartEmpty] = useState<any>([])
  const [routeUsageList, setRouteUsageList] = useState<any>([])
  const [routeDetails, setRouteDetails] = useState<any>([])
  const [isChartLoading, setChartLoading] = useState(true)
  const [currentRouteId, setCurrentRouteId] = useState<number>(0)
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [salesTeam, setSalesTeam] = useState<any>('')
  const [type, setType] = useState({label: 'Sales Amount', value: ''})
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    warehouse.value !== '' && fetchRouteUsageReport().then()
  }, [debounceSearch, warehouse, selected, currentOffSet, month, salesTeam])

  useEffect(() => {
    warehouse.value !== '' && fetchBarChart()
  }, [warehouse, month, type])

  useEffect(() => {
    getFirstWh()
  }, [])

  const fetchRouteUsageReport = async () => {
    setDisplayLoader(true)

    const startDate = formateRangeDate(month.value).date_range_start
    const endDate = formateRangeDate(month.value).date_range_end

    let selectedWarehouseId = isEmpty(warehouse.value) ? '' : warehouse.value

    const response = await getRequest(
      `${apiBaseURL()}routes/route-usage-report?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&warehouse_id=${selectedWarehouseId}&sales_team_id=${
        isEmpty(salesTeam?.value) ? '' : salesTeam?.value
      }&date_range_after=${startDate}&date_range_before=${endDate}`,
      true
    )

    if (response?.results) {
      setCount(response?.count ?? '1')
      setRouteUsageList(response?.results ?? [])
    }

    setDisplayLoader(false)
  }

  const fetchRouteUsageDetail = async (id: number) => {
    if (id !== currentRouteId) {
      setCurrentRouteId(id)
      setDisplayDetailsLoader(true)
      setRouteDetails([])

      let selectedWarehouseId = isEmpty(warehouse.value) ? '' : warehouse.value

      const startDate = formateRangeDate(month.value).date_range_start
      const endDate = formateRangeDate(month.value).date_range_end

      const response = await getRequest(
        `${apiBaseURL()}routes/route-usage-report/${id}?warehouse_id=${selectedWarehouseId}&sales_team_id=${
          isEmpty(salesTeam?.value) ? '' : salesTeam?.value
        }&date_range_after=${startDate}&date_range_before=${endDate}`,
        true
      )

      if (response?.results) {
        setRouteDetails(response?.results ?? [])
      }

      setDisplayDetailsLoader(false)
    }
  }

  const fetchMonthFilter = async (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        value: 'today',
        label: 'Today',
      },
      {
        value: 'yesterday',
        label: 'Yesterday',
      },
      {
        value: 'this_week',
        label: 'This Week',
      },
      {
        value: 'last_week',
        label: 'Last Week',
      },
      {
        value: 'this_month',
        label: 'This Month',
      },
      {
        value: 'last_month',
        label: 'Last Month',
      },
      {
        value: 'this_quarter',
        label: 'This Quarter',
      },
      {
        value: 'last_quarter',
        label: 'Last Quarter',
      },
      {
        value: 'this_year',
        label: 'This Year',
      },
      {
        value: 'last_year',
        label: 'Last Year',
      },
    ]

    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }

    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchChartType = async (search: any) => {
    let options: any

    const defaultOptions: any = [
      {label: 'Orders', value: 'orders'},
      {label: 'Sales Amount', value: ''},
      // {label: 'SR Visits', value: 'sr_visited'},
      {label: 'Credit Collection', value: 'credit_collection'},
      {label: 'Credits', value: 'credits'},
      {label: 'Customers', value: 'customers'},
      {label: 'Streets', value: 'streets'},
      {label: 'Serving Time Crossed', value: 'serving_time_crossed'},
      {label: 'Route Jumps', value: 'route_jump'},
      {label: 'Discounts', value: 'discounts'},
    ]

    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }

    return {
      options: options,
      hasMore: false,
    }
  }

  const formateRangeDate = (value: string) => {
    let date = {
      date_range_start: '',
      date_range_end: '',
    }

    switch (value) {
      case 'today':
        date = {
          date_range_start: dateFormat(moment()).apiDate,
          date_range_end: dateFormat(moment()).apiDate,
        }
        break

      case 'yesterday':
        date = {
          date_range_start: dateFormat(moment().subtract(1, 'day')).apiDate,
          date_range_end: dateFormat(moment().subtract(1, 'day')).apiDate,
        }
        break

      case 'this_week':
        date = {
          date_range_start: dateFormat(moment().startOf('week')).apiDate,
          date_range_end: dateFormat(moment().endOf('week')).apiDate,
        }
        break

      case 'last_week':
        date = {
          date_range_start: dateFormat(moment().subtract(1, 'week').startOf('week')).apiDate,
          date_range_end: dateFormat(moment().subtract(1, 'week').endOf('week')).apiDate,
        }
        break

      case 'this_month':
        date = {
          date_range_start: dateFormat(moment().startOf('month')).apiDate,
          date_range_end: dateFormat(moment().endOf('month')).apiDate,
        }
        break

      case 'last_month':
        date = {
          date_range_start: dateFormat(moment().subtract(1, 'month').startOf('month')).apiDate,
          date_range_end: dateFormat(moment().subtract(1, 'month').endOf('month')).apiDate,
        }
        break

      case 'this_quarter':
        date = {
          date_range_start: dateFormat(moment().startOf('quarter')).apiDate,
          date_range_end: dateFormat(moment().endOf('quarter')).apiDate,
        }
        break

      case 'last_quarter':
        date = {
          date_range_start: dateFormat(moment().subtract(1, 'quarter').startOf('quarter')).apiDate,
          date_range_end: dateFormat(moment().subtract(1, 'quarter').endOf('quarter')).apiDate,
        }
        break

      case 'this_year':
        date = {
          date_range_start: dateFormat(moment().startOf('year')).apiDate,
          date_range_end: dateFormat(moment().endOf('year')).apiDate,
        }
        break

      case 'last_year':
        date = {
          date_range_start: dateFormat(moment().subtract(1, 'year').startOf('year')).apiDate,
          date_range_end: dateFormat(moment().subtract(1, 'year').endOf('year')).apiDate,
        }
        break

      default:
        break
    }

    return date
  }

  const getFirstWh = async () => {
    if (checkUserType(['sa'])) {
      const url = `${apiBaseURL()}warehouse/list?ordering=name&limit=2`

      const response = await getFirstWarehouse(url)

      if (response) {
        setWarehouse(response)
      }
    } else if (checkUserType(['wm'])) {
      setWarehouse(getLoginWarehouse())
    }
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name

    setCurrentRouteId(0)

    switch (inputName) {
      case 'warehouse':
        setWarehouse(e)
        break

      case 'month':
        setMonth(e)
        break

      case 'sales_team':
        setSalesTeam(e)
        break

      case 'search':
        setSearchByName(e.target.value)
        break
    }
  }

  const fetchBarChart = async () => {
    setChartLoading(true)

    let selectedWarehouseId = isEmpty(warehouse.value) ? '' : warehouse.value

    const startDate = formateRangeDate(month.value).date_range_start
    const endDate = formateRangeDate(month.value).date_range_end

    const url = `${apiBaseURL()}routes/route-usage-report/graph?warehouse_id=${selectedWarehouseId}&date_range_after=${startDate}&date_range_before=${endDate}&records=${
      type?.value
    }`

    const response = await getRequest(url, true)

    if (response.result) {
      handleChartResponse(response.result)
    }

    setChartLoading(false)
  }

  const handleChartResponse = (response: any) => {
    let xAxisData: string[] = []
    let data: any = []

    if (response.length > 0) {
      setBarChartEmpty([1])
    } else {
      setBarChartEmpty([])
    }

    if (response.length > 0) {
      response.forEach((item: any) => {
        xAxisData.push(item.name)

        data.push(item.value)
      })
    }

    setBarChart({
      xAxisData: xAxisData,
      rotate: 30,
      series: [
        {
          type: 'bar',
          width: '100%',
          label: {
            show: true,
            position: 'insideBottom',
            distance: 15,
            align: 'left',
            verticalAlign: 'middle',
            formatter: function (params: any) {
              return ['', 'credit_collection', 'credits', 'discounts'].includes(type?.value)
                ? numberFormatWithCode(params.data).displayFormat
                : params.data
            },
            rotate: 90,
            fontSize: 10,
            rich: {
              name: {},
            },
          },
          tooltip: {
            valueFormatter: function (params: any) {
              return ['', 'credit_collection', 'credits', 'discounts'].includes(type?.value)
                ? numberFormatWithCode(params).displayFormat
                : params
            },
          },
          emphasis: {
            focus: 'series',
          },
          data: data,
        },
      ],
      startZoom: 0,
      endZoom: 40,
    })
  }

  const fetchSalesTeam = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      'master/sales-team?ordering=name',
      search,
      setDropDownSearch,
      'name',
      true,
      'All Sales Team'
    )
  }

  const Filters = () => {
    return (
      <div className='d-flex'>
        <WarehouseFilter
          warehouse={warehouse}
          handleFilterChange={handleFilterChange}
          isDisabled={isChartLoading || displayLoader}
          allWarehouse={false}
          className='w-200px my-1 me-3'
        />

        <AsyncPaginate
          loadOptions={fetchMonthFilter}
          isSearchable
          className='react-select-container my-1 me-3 w-200px'
          classNamePrefix='react-select'
          placeholder='This Month'
          isDisabled={isChartLoading || displayLoader}
          onChange={(e: any) => {
            handleFilterChange(e, 'month')
          }}
          value={month}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#0c78a4',
            },
          })}
        />
      </div>
    )
  }

  return (
    <>
      <EATitle title='Route Usage Report' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Route Usage Report'})}
      </PageTitle>

      {routeUsageReportId &&
        ReactDOM.render(<Filters />, document.getElementById('route-usage-report-filters'))}

      <div>
        <div className='row mt-3 mt-md-0'>
          <div className='col-12 mt-md-0'>
            <div className='card card-xl-stretch mb-5'>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                <h3 className='card-title align-items-start flex-column my-0'>
                  <span className='card-label fw-bold font-17 mb-1'>Route Usage Overview</span>
                </h3>

                <AsyncPaginate
                  loadOptions={fetchChartType}
                  isSearchable
                  className='react-select-container my-1 me-3 w-200px'
                  classNamePrefix='react-select'
                  placeholder='Select'
                  onChange={(e: any) => {
                    setType(e)
                  }}
                  isDisabled={isChartLoading}
                  value={type}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              <div className='border-0 py-3 py-md-2 px-5 px-md-7'>
                {isChartLoading ? (
                  <FetchingRecords />
                ) : barCharEmpty.length === 0 ? (
                  <NoRecords />
                ) : (
                  <EaChartBar {...barChart} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={handleFilterChange}
              isDisable={displayLoader || displayDetailsLoader}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                {checkUserType(['sa', 'wm']) && (
                  <>
                    <AsyncPaginate
                      loadOptions={fetchSalesTeam}
                      onChange={(e: any) => {
                        handleFilterChange(e, 'sales_team')
                      }}
                      isSearchable
                      placeholder='All Sales Team'
                      isDisabled={displayLoader}
                      value={salesTeam}
                      className='react-select-container my-1 me-3 w-150px'
                      classNamePrefix='react-select'
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div
              className='table-responsive expandable'
              style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
              id='expandTable'
            >
              {displayLoader ? (
                <FetchingRecords />
              ) : routeUsageList.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className=''>Route Name</th>
                      <th className=''>Sales Team Name</th>
                      <th className=''>SS Name</th>
                      <th className=''>Total Customers</th>
                      {/* <th className=''>Total Visits</th> */}
                      <th className=''>Route Jump</th>
                      <th className=''>Total Orders</th>
                      <th className=''>Total Sales Amount</th>
                      <th className=''>Total Open Credit Issues</th>
                    </tr>
                  </thead>

                  <tbody>
                    {routeUsageList.map((route: any) => {
                      return (
                        <>
                          <tr
                            data-bs-toggle='collapse'
                            data-bs-target={`.row-collapse${route.id}`}
                            aria-controls={`.rowCollapse${route.id}`}
                            className='cursor-pointer'
                            onClick={() => {
                              fetchRouteUsageDetail(route.id)
                            }}
                          >
                            <td>{checkEmpty(route?.name)}</td>
                            <td>{checkEmpty(route?.sales_team_name)}</td>
                            <td>{checkEmpty(route?.ss_name)}</td>
                            <td>
                              {isEmpty(route?.total_customers_count)
                                ? '-'
                                : numberFormat(route?.total_customers_count).displayFormat}
                            </td>
                            {/* <td>
                              {isEmpty(route?.total_visit_count)
                                ? '-'
                                : numberFormat(route?.total_visit_count).displayFormat}
                            </td> */}
                            <td>
                              {isEmpty(route?.total_route_jump_count)
                                ? '-'
                                : numberFormat(route?.total_route_jump_count).displayFormat}
                            </td>
                            <td>
                              {isEmpty(route?.total_orders_count)
                                ? '-'
                                : numberFormat(route?.total_orders_count).displayFormat}
                            </td>
                            <td>
                              {isEmpty(route?.total_sales_amount)
                                ? '-'
                                : numberFormatWithCode(route?.total_sales_amount).displayFormat}
                            </td>
                            <td>
                              {isEmpty(route?.total_open_credit_issue_amount)
                                ? '-'
                                : numberFormatWithCode(route?.total_open_credit_issue_amount)
                                    .displayFormat}
                            </td>
                          </tr>
                          <tr className='expandable-row'>
                            <td className='reset-expansion-style' colSpan={11}>
                              <div
                                className={`row-expansion-style collapse row-collapse${route.id} collapsible`}
                                id={`rowCollapse${route.id}`}
                                data-bs-parent='#expandTable'
                              >
                                <div>
                                  {displayDetailsLoader ? (
                                    <FetchingRecords />
                                  ) : routeDetails.length === 0 ? (
                                    <NoRecords />
                                  ) : (
                                    <table className='table table-bordered gs-4 mb-0'>
                                      <thead>
                                        <tr className='text-muted fw-500 bg-light2'>
                                          <th>SR Name</th>
                                          {/* <th>Total Visits by SR</th> */}
                                          <th>Route Jump</th>
                                          <th>Total Pre-Orders</th>
                                          <th>Total Credit Collections</th>
                                          <th>Total Sales Orders</th>
                                          <th>Total Sales Amount</th>
                                          <th>Total Credit Amount</th>
                                          <th>Total Discount Amount</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {routeDetails.map((i: any) => {
                                          return (
                                            <tr>
                                              <td>{checkEmpty(i?.name)}</td>
                                              {/* <td>
                                                {isEmpty(i?.total_visit_count)
                                                  ? '-'
                                                  : numberFormat(i?.total_visit_count)
                                                      .displayFormat}
                                              </td> */}
                                              <td>
                                                {isEmpty(i?.total_route_jump_count)
                                                  ? '-'
                                                  : numberFormat(i?.total_route_jump_count)
                                                      .displayFormat}
                                              </td>
                                              <td>
                                                {isEmpty(i?.total_pre_orders_count)
                                                  ? '-'
                                                  : numberFormat(i?.total_pre_orders_count)
                                                      .displayFormat}
                                              </td>
                                              <td>
                                                {isEmpty(i?.total_credit_collection_amount)
                                                  ? '-'
                                                  : numberFormat(i?.total_credit_collection_amount)
                                                      .displayFormat}
                                              </td>
                                              <td>
                                                {isEmpty(i?.total_orders_count)
                                                  ? '-'
                                                  : numberFormat(i?.total_orders_count)
                                                      .displayFormat}
                                              </td>
                                              <td>
                                                {isEmpty(i?.total_sales_amount)
                                                  ? '-'
                                                  : numberFormatWithCode(i?.total_sales_amount)
                                                      .displayFormat}
                                              </td>
                                              <td>
                                                {isEmpty(i?.total_credit_amount)
                                                  ? '-'
                                                  : numberFormatWithCode(i?.total_credit_amount)
                                                      .displayFormat}
                                              </td>
                                              <td>
                                                {isEmpty(i?.total_discount_amount)
                                                  ? '-'
                                                  : numberFormatWithCode(i?.total_discount_amount)
                                                      .displayFormat}
                                              </td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <CustomPaginate
          data={routeUsageList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffSet}
          totalRecords={count}
          limit={limit}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default RouteUsageReport
