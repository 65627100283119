import clsx from "clsx"
import { useFormik } from "formik"
import { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { KTSVG } from "../../../../_eaFruitsDms/helpers"
import Notifications from "../../../../_eaFruitsDms/Notifications/Notifications"
import { loginEaFruits } from "../../../../_eaFruitsDms/redux/action/login/loginAction"

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    // .min(8, 'Password should be minimum 8 characters long')
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Password should be minimum 8 characters long with alphanumeric, minimum one capital and one special character")
    // .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: "",
  password: "",
}

const Login = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      let email = values.email;
      let password = values.password;
      props.loginEaFruitsProp(email, password)
    },
  })

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-start mb-6'>
        <h1 className='text-dark font-17 font-bold'>Sign in to EA Fruits DMS</h1>
      </div>
      <Notifications />
      {/* begin::Form group */}
      <div className='fv-row mb-6'>
        <label className='form-label text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-6'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label text-dark mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fw-bolder'
              style={{ marginLeft: '17px' }}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <div className='d-flex position-relative'>
          <input
            placeholder='Password'
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control psw-input',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <a onClick={handleTogglePassword} className= "btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm">
            {showPassword 
              ? <><KTSVG path='/media/icons/ic_eye_off.svg' className="ic ms-n4 position-absolute psw-toggle" /></> 
              : <><KTSVG path='/media/icons/ic_eye_on.svg' className="ic ms-n4 position-absolute psw-toggle" /></>
            }  
          </a>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Action */}

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary w-100 mb-2'
          disabled={props.isLoading}
        >
          {!props.isLoading && <span className='indicator-label'>Login</span>}
          {props.isLoading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {props.errorLogin && <span className='text-danger'>{props.errorLogin}</span>}
      </div>
      {/* end::Action */}
    </form>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.login.isLoading,
    isLogin: state.login.isLogin,
    loginResponse: state.login.loginResponse,
    errorLogin: state.login.errorMessage
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loginEaFruitsProp: (email: string, password: string) => {
      dispatch(loginEaFruits(email, password))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login); 
