import { KTSVG, toAbsoluteUrl } from "../../../../_eaFruitsDms/helpers"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import {
	OrderingArrowDown,
	OrderingArrowUp
} from "../../../../_eaFruitsDms/layout/components/orderingArrow/orderingArrow"

const TableBrand = (props: any) => {
    return (
        <>
           <table className='table table-row-bordered align-middle gs-0 gy-1'>
                    <thead>
                        <tr className=' text-muted fw-500'>
                            <th className='min-w-100px py-2'>Brand Name
                                <span onClick={props.handleOrdering}>
                                    {props.orderingBrandName ? <OrderingArrowUp /> : <OrderingArrowDown />}
                                </span>
                            </th>
                            <th className='min-w-100px py-2'>TIN Number</th>
                            <th className='min-w-100px py-2'>VRN Number</th>
                            <th className='min-w-100px py-2'>Product SKUs</th>
                            {props.showWrite || props.showDelete ? <th className='min-w-200px action-th py-2'>Actions</th> : <th className='min-w-200px action-th py-2'></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {props.tableList.map((item: any) => (
                            <tr key={item.id}>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-28px me-2">
                                            <span className='symbol-label'>
                                                {item.brand_thumbnail === null ?
                                                    <img
                                                        alt='group image'
                                                        className='h-35px rounded-1'
                                                        src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                                                    /> :
                                                    <img src={item.brand_thumbnail} className='h-40 rounded-1' alt="" style={{
                                                        height: '100%', width: '100%'
                                                    }} />}
                                            </span>
                                        </div>
                                        <span>{item.name}</span>
                                    </div>
                                </td>
                                <td>
                                    {item.brand_tin_number !== "" ? <>#{item.brand_tin_number}</> : "-"}
                                </td>
                                <td>
                                    {item.brand_vrn_number !== "" ? <>#{item.brand_vrn_number}</> : "-"}
                                </td>
                                <td>
                                    {item.product_sku} SKUs
                                </td>
                                <td className='text-end'>
                                    {/* <a href='#'
                                    data-bs-toggle='modal'
                                    data-bs-target='#ea_modal_create_sku'
                                    className='btn btn-primary btn-sm font-13 me-3'>
                                    Add SKU
                                </a> */}
                                    {props.showWrite &&
                                        <a
                                            className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                                            href='#'
                                            onClick={() => props.getPropsEdit(item)}
                                            data-bs-toggle='modal'
                                            data-bs-target='#ea_modal_update_brand'
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </a>}

                                    {/* {props.showDelete &&
                                        <a
                                            onClick={() => props.getPropsDelete(item)}
                                            data-bs-toggle='modal'
                                            data-bs-target='#delete_brand'
                                            href='#' className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                        </a>} */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </>
    )
}

export default TableBrand
