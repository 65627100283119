import moment from 'moment'
import {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  checkUserType,
  fetchAsyncDropdown,
  firstLetterCapital,
  getFirstWarehouse,
  getLoginWarehouse,
  GLOBAL_DATE_FORMAT,
  isEmpty,
  KTSVG,
} from '../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaLineChart} from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'

const TempHumidityReport = () => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Temperature & Humidity Report Readings',
    subTitle: 'Temperature & Humidity Report Readings',
  }
  const url = apiBaseURL() + `warehouse/list?ordering=name`

  const [warehouse, setWarehouse] = useState<any>(
    checkUserType(['sa']) ? {label: '', value: ''} : getLoginWarehouse()
  )
  const [firstContainer, setFirstContainer] = useState({label: 'All Container', value: ''})
  const [containerBox, setContainerBox] = useState({label: 'All Container', value: ''})
  const [startDate, setStartDate] = useState(moment(Date.now()).format('YYYY-MM-DD'))
  const [resetContainer, setResetContainer] = useState<any>(new Date())
  const [lineChart, setLineChart] = useState<any>({
    xAxisData: [],
    seriesData: [],
    minTemperature: 0,
    maxTemperature: 0,
    name: '',
    formatter: '',
    maxYaxis: 0,
  })
  const [type, setType] = useState('temperature')
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [containerSearch, setSearch] = useState([])
  const [fetchingChart, setFetchingChart] = useState(false)
  const [fetchingReport, setFetchingReport] = useState(false)
  const [tempHumidityReport, setTempHumidityReport] = useState<any>({
    container: [],
    reports: [],
  })
  const [warehouseOffset, setWarehouseOffset] = useState('')
  const [warehouseList, setWarehouseList] = useState([])
  const [warehouseSearch, setWarehouseSearch] = useState('')

  useEffect(() => {
    console.log('tempHumidityReport================', tempHumidityReport)
  }, [tempHumidityReport])

  const fetchTempHumidityReport = async () => {
    setFetchingReport(true)

    const url = `${apiBaseURL()}weather-boxes/drop-down?warehouse_id=${
      warehouse.value
    }&date=${startDate}&status=associated`

    const response = await getRequest(url, true)

    let tempData: any = {
      container: [],
      reports: [],
    }

    if (response && response.length > 0) {
      let containerId: any = []

      response.forEach((item: any) => {
        containerId.push(item.id)
      })

      if (isEmpty(containerBox.value)) {
        setContainerBox({label: response[0].container_name, value: response[0].id})
      }

      setFirstContainer({label: response[0].container_name, value: response[0].id})

      tempData.container = response

      const url = `${apiBaseURL()}reports/weather-boxes/temperature-and-humidity?warehouse_id=${
        warehouse.value
      }&date=${startDate}`

      const tempResponse = await getRequest(url, true)

      if (tempResponse && tempResponse.result) {
        tempResponse.result.forEach((item: any) => {
          for (const key in item) {
            let ordering: any = containerId.map((id: any) =>
              item[key].find((v: any) => v.weather_box_id == id)
            )

            tempData.reports.push({
              time: key,
              data: ordering,
            })
          }
        })
      }

      setTempHumidityReport(tempData)
    }
    setFetchingReport(false)
  }

  const fetchLineChart = async () => {
    setFetchingChart(true)

    const url = `${apiBaseURL()}reports/weather-boxes/${type}/${
      containerBox.value
    }/graph?date=${startDate}`

    const response = await getRequest(url, true)

    const value: any = {
      name: 'Value',
      type: 'line',
      data: [],
      lineStyle: {
        color: '#5470C6',
        width: 1.5,
      },
    }

    const min: any = {
      name: `Min ${firstLetterCapital(type)}`,
      type: 'line',
      data: [],
      lineStyle: {
        color: '#45C0FF',
        width: 1.5,
        type: 'dashed',
      },
    }

    const max: any = {
      name: `Max ${firstLetterCapital(type)}`,
      type: 'line',
      data: [],
      lineStyle: {
        color: '#FB5F3D',
        width: 1.5,
        type: 'dashed',
      },
    }

    response.result.data.forEach((i: any) => {
      value.data.push(i.value)
      min.data.push(i.min)
      max.data.push(i.max)
    })

    const seriesData: any = [value, min, max]

    setLineChart({
      xAxisData: response.result.time_slots,
      seriesData: seriesData,
      formatter: type === 'temperature' ? '{value} °C' : '{value} g/m3',
      name: type === 'temperature' ? 'Temperature' : 'Humidity',
    })

    setFetchingChart(false)
  }

  // const fetchLineChart = async () => {
  //   setFetchingChart(true)

  //   const url = `${apiBaseURL()}reports/weather-boxes/${type}/${
  //     containerBox.value
  //   }/graph?date=${startDate}`
  //   const response = await getRequest(url, true)
  //   if (response.result) {
  //     setLineChart({
  //       xAxisData: response.result.time_slots,
  //       seriesData: response.result.data,
  //       minTemperature:
  //         type === 'temperature' ? response.result.min_temperature : response.result.min_humidity,
  //       maxTemperature:
  //         type === 'temperature' ? response.result.max_temperature : response.result.max_humidity,
  //       formatter: type === 'temperature' ? '{value} °C' : '{value} g/m3',
  //       name: type === 'temperature' ? 'Temperature' : 'Humidity',
  //       maxYaxis: type === 'temperature' ? 40 : 120,
  //     })
  //   }

  //   setFetchingChart(false)
  // }

  const fetchFirstWarehouse = async () => {
    if (checkUserType(['sa'])) {
      const response = await getFirstWarehouse(url)

      if (response) {
        setWarehouse(response)
      }
    }
  }

  const fetchContainer = (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `weather-boxes?warehouse_id=${warehouse.value}&ordering=name`,
      search,
      setSearch,
      'container_name',
      false
    )

    // &status=associated : for active container
  }

  const fetchWarehouse = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffset,
      setWarehouseOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?parent_warehouse__id=${
        checkUserType(['sa']) ? '' : localStorage.getItem('w_id')
      }&is_parent=True&ordering=name`,
      search,
      setWarehouseSearch,
      false,
      false
    )
  }

  useEffect(() => {
    if (!isEmpty(warehouse.value)) {
      fetchTempHumidityReport()
    }
  }, [startDate, warehouse])

  useEffect(() => {
    if (!isEmpty(containerBox.value)) {
      fetchLineChart()
    }
  }, [type, containerBox, startDate])

  useEffect(() => {
    fetchFirstWarehouse()
  }, [])

  return (
    <>
      <EATitle title='Temperature & Humidity Report Readings - ' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({id: 'Temperature & Humidity Report Readings'})}
      </PageTitle>

      <div className='card mb-5'>
        <div className='card-header border-0 py-5 px-7 align-items-center'>
          <h3 className='fw-bolder'>Temperature & Humidity Report Readings</h3>

          <div className='ms-auto d-flex'>
            <button
              onClick={() => {
                setType('temperature')
              }}
              className={`btn me-3 ${type === 'temperature' ? 'btn-primary' : 'btn-secondary'}`}
              disabled={fetchingChart || fetchingReport}
            >
              Temperature
            </button>

            <button
              onClick={() => {
                setType('humidity')
              }}
              className={`btn me-3 ${type === 'humidity' ? 'btn-primary' : 'btn-secondary'}`}
              disabled={fetchingChart || fetchingReport}
            >
              Humidity
            </button>

            <DateRangePicker
              onCallback={(dateView: any, dateApi: any) => {
                setStartDate(moment(dateView).format('YYYY-MM-DD'))
              }}
              initialSettings={{
                startDate: {startDate},
                // endDate: {endDate}
                alwaysShowCalendars: true,
                cancelClass: 'btn-secondary',
                maxDate: moment(Date.now()),
                singleDatePicker: true,
                drops: 'auto',
                locale: {
                  format: GLOBAL_DATE_FORMAT,
                },
              }}
            >
              <input
                disabled={fetchingChart || fetchingReport}
                type='text'
                className='form-control calendar my-1 me-3 w-150px'
              />
            </DateRangePicker>

            <AsyncPaginate
              loadOptions={fetchWarehouse}
              onChange={(e: any) => {
                setWarehouse(e)
                setContainerBox(firstContainer)
                if (e.value === '') {
                  setTempHumidityReport({
                    container: [],
                    reports: [],
                  })

                  setLineChart({
                    xAxisData: [],
                    seriesData: [],
                    minTemperature: 0,
                    maxTemperature: 0,
                    name: '',
                    formatter: '',
                    maxYaxis: 0,
                  })
                }
                setList([])
                setOffset(0)
                setResetContainer(new Date())
              }}
              isSearchable
              placeholder='Select Warehouse'
              isDisabled={fetchingChart || fetchingReport}
              value={warehouse}
              className='react-select-container my-1 me-3 w-150px'
              classNamePrefix='react-select'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />

            <AsyncPaginate
              key={resetContainer}
              loadOptions={fetchContainer}
              onChange={(e: any) => {
                setContainerBox(e)

                if (e.value === '') {
                  setLineChart({
                    xAxisData: [],
                    seriesData: [],
                    minTemperature: 0,
                    maxTemperature: 0,
                    name: '',
                    formatter: '',
                    maxYaxis: 0,
                  })
                }
              }}
              isSearchable
              placeholder='All Container'
              isDisabled={fetchingChart || fetchingReport || isEmpty(warehouse.value)}
              value={containerBox}
              className='react-select-container my-1 me-3 w-150px'
              classNamePrefix='react-select'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
        </div>

        <div>
          {fetchingChart ? (
            <FetchingRecords />
          ) : lineChart.seriesData.length === 0 ? (
            <NoRecords />
          ) : (
            <EaLineChart {...lineChart} />
          )}
        </div>
      </div>

      <div className='card mb-5'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {fetchingReport ? (
              <FetchingRecords />
            ) : tempHumidityReport.container &&
              tempHumidityReport.container.length === 0 &&
              tempHumidityReport.reports &&
              tempHumidityReport.reports.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-100px py-2'>Time</th>
                    {tempHumidityReport.container &&
                      tempHumidityReport.container.length > 0 &&
                      tempHumidityReport.container.map((item: any) => {
                        return (
                          <th className='min-w-100px py-2'>{checkEmpty(item.container_name)}</th>
                        )
                      })}
                  </tr>
                </thead>
                <tbody>
                  {tempHumidityReport.reports &&
                    tempHumidityReport.reports.map((item: any) => {
                      return (
                        <tr>
                          <td>
                            {isEmpty(item.time)
                              ? '-'
                              : moment(item.time, 'HH:mm:ss').format('hh:mm A')}{' '}
                          </td>
                          {!isEmpty(item.data) &&
                            item.data.map((temp: any) => {
                              return isEmpty(temp) ? (
                                <td className={`py-5`}>-</td>
                              ) : (
                                <td
                                  style={{
                                    backgroundColor:
                                      temp.temp_threshold_type !== 'in_range' ||
                                      temp.humidity_threshold_type !== 'in_range'
                                        ? '#F2E0E4'
                                        : '',
                                  }}
                                  className={`py-5`}
                                >
                                  <div>
                                    <p>
                                      {isEmpty(temp.temperature) ? (
                                        '-'
                                      ) : (
                                        <>
                                          {temp.temperature} &#8451;
                                          {temp.temp_threshold_type === 'above_range' && (
                                            <KTSVG
                                              path='/media/icons/arrow/ic_up.svg'
                                              className='ic mr-0'
                                            />
                                          )}
                                          {temp.temp_threshold_type === 'below_range' && (
                                            <KTSVG
                                              path='/media/icons/arrow/ic_down.svg'
                                              className='ic mr-0'
                                            />
                                          )}
                                        </>
                                      )}
                                    </p>
                                    <div>
                                      {isEmpty(temp.humidity) ? (
                                        '-'
                                      ) : (
                                        <>
                                          {' '}
                                          {`${temp.humidity} g/m3`}
                                          {temp.humidity_threshold_type === 'above_range' && (
                                            <KTSVG
                                              path='/media/icons/arrow/ic_up.svg'
                                              className='ic mr-0'
                                            />
                                          )}
                                          {temp.humidity_threshold_type === 'below_range' && (
                                            <KTSVG
                                              path='/media/icons/arrow/ic_down.svg'
                                              className='ic mr-0'
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              )
                            })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TempHumidityReport
