import React, {useEffect, useState} from 'react'
import {apiBaseURL} from '../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import IsLoader from '../../../../../_eaFruitsDms/layout/components/loader/loader'
import {EaChartPie} from '../../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import SupportRequestNewMessage from '../../../supportRequest/newMessage'

const SupportRequests = () => {
  const teleUserId = localStorage.getItem('teleUserId')

  const initialRow: any = {
    request: true,
    rating: true,
  }

  const [requestCount, setRequestCount] = useState<{value: number; name: string}[]>([])
  const [ratingCount, setRatingCount] = useState<{value: number; name: string}[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [noRecordData, setNoRecordData] = useState(initialRow)

  useEffect(() => {
    getReportsDetails()
  }, [])

  const requestCountItem = {
    data: requestCount,
    title: 'Requests Attended',
    bottom: '30%',
  }

  const ratingCountItem = {
    data: ratingCount,
    title: 'Ratings Received',
    bottom: '30%',
  }

  const getReportsDetails = async () => {
    setIsLoading(true)
    const reportUrl = apiBaseURL() + `support_request/count?telesales_id=${teleUserId}`
    const reportResponse: any = await getRequest(reportUrl, true)
    let updatedState: any = {...noRecordData}

    if (reportResponse.support_request_counts) {
      let requestData: any = []
      const {assigned_count, closed_count} = reportResponse.support_request_counts
      if (assigned_count > 0) {
        requestData.push({value: assigned_count, name: 'Assigned Count'})
      }
      if (closed_count > 0) {
        requestData.push({value: closed_count, name: 'Closed Count'})
      }
      setRequestCount(requestData)
      if (assigned_count == 0 && closed_count == 0) {
        updatedState['request'] = false
      }
    }

    let ratingData: {value: number; name: string}[] = []
    const {rating_counts} = reportResponse

    if (rating_counts && rating_counts.rating_counts) {
      const {
        '1': rate_1,
        '2': rate_2,
        '3': rate_3,
        '4': rate_4,
        '5': rate_5,
      } = rating_counts.rating_counts
      for (let i = 1; i <= 5; i++) {
        const rate = eval(`rate_${i}`)
        if (rate > 0) {
          ratingData.push({value: rate, name: String(i)})
        }
      }
      setRatingCount(ratingData)
      setIsLoading(false)

      let allZero = true
      for (let i = 1; i <= 5; i++) {
        const rate = eval(`rate_${i}`)
        if (rate !== 0) {
          allZero = false
          break
        }
      }
      updatedState['rating'] = !allZero
    }
    setNoRecordData(updatedState)
  }

  return (
    <>
      {/* chart start */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              {/* begin::Title */}
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Complaints Analytics</span>
              </h3>
              {/* end::Title */}
            </div>
            <div className='card-body'>
              {noRecordData.request || noRecordData.rating ? (
                <div className='row'>
                  {isLoading ? (
                    <IsLoader />
                  ) : (
                    <>
                      {noRecordData.request && (
                        <div className='col-md-6'>
                          <EaChartPie item={requestCountItem} />
                        </div>
                      )}
                      {noRecordData.rating && (
                        <div className='col-md-6'>
                          <EaChartPie item={ratingCountItem} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <NoRecords />
              )}
              <div>{noRecordData.request}</div>
              <div>{noRecordData.rating}</div>
            </div>
          </div>
        </div>
      </div>
      {/* chart end */}
      <SupportRequestNewMessage />
    </>
  )
}

export default SupportRequests
