import moment from 'moment'
import {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdownNew,
  fetchStaticAsyncDropdown,
  globalDateFormate,
  isAdmin,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'
import {useNavigate} from 'react-router-dom'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {getActivityEnumName} from '../../../../_eaFruitsDms/helpers/activitiesEnum'

type Props = {
  className: string
}
const RouteJumpReport = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Route Jump Report',
  }

  const [routes, setRoutes] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [currentOffSet, setCurrentOffSet] = useState<any>('0')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [routeOffset, setRouteOffset] = useState<number>(0)
  const [srOffSet, setSrOffSet] = useState<number>(0)
  const [sr, setSr] = useState<any>({
    label: 'All SR',
    value: '',
  })
  const [status, setStatus] = useState<any>({
    label: 'All Status',
    value: '',
  })
  const [warehouse, setWarehouse] = useState<any>({label: 'All Warehouse', value: ''})
  const [srReset, setSrReset] = useState<any>(moment())
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [filteredDate, setFilteredDate] = useState<any>({
    startDate: dateFormat(moment()).apiDate,
    endDate: dateFormat(moment()).apiDate,
  })
  const [routesObj, setRoutesObj] = useState<any>({
    label: 'All Routes',
    value: '',
  })
  const [routeJumpList, setRouteJumpList] = useState<any>([])
  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchReportList().then()
  }, [debounceSearch, routesObj, sr, selected, currentOffSet, filteredDate, status, warehouse])

  useEffect(() => {
    setRouteOffset(0)
    setSrOffSet(0)
  }, [searchSelect])

  const fetchReportList = async () => {
    setDisplayLoader(true)

    const startDate = filteredDate.startDate
    const endDate = filteredDate.endDate

    let routeID = isEmpty(routesObj.value) ? '' : routesObj.value

    const response = await getRequest(
      `${apiBaseURL()}reports/route-jump-report?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&route_id=${routeID}&sr_id=${
        isEmpty(sr.value) ? '' : sr.value
      }&date_after=${startDate}&date_before=${endDate}&status=${
        isEmpty(status?.value) ? '' : status?.value
      }&warehouse_id=${isEmpty(warehouse?.value) ? '' : warehouse?.value}`,
      true
    )

    if (response?.results) {
      setRouteJumpList(response?.results)
      setCount(response?.count)
    }

    setDisplayLoader(false)
  }

  const fetchRoute = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }

    let options: any = []
    const routeResponse = await getRequest(
      `${apiBaseURL()}routes?ordering=id&status=approved&search=${search}&limit=${limit}&offset=${routeOffset}`,
      true
    )

    let hasMore: boolean = false

    if (routeResponse.next !== null) {
      const queryParams = new URLSearchParams(routeResponse.next)
      let newOffset: any = queryParams.get('offset')
      setRouteOffset(newOffset)
      hasMore = true
    }

    if (routes.length == 0) {
      options.push({
        label: 'All Routes',
        value: '',
      })
    }

    if (routeResponse.results.length > 0) {
      routeResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (routes.length > 0) {
      setRoutes(routes.concat(options))
    } else {
      setRoutes(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSrs = async (search?: string) => {
    return await fetchAsyncDropdownNew(
      srOffSet,
      setSrOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `reports/users/drop-down?ordering=first_name&type=sr`,
      search,
      setSearchSelect,
      'full_name',
      '',
      true,
      'All Sales Reps'
    )
  }

  const fetchStatus = (search?: string) => {
    const status = [
      {label: 'All Status', value: ''},
      {label: 'Open', value: 'open'},
      {label: 'Resolved', value: 'resolved'},
    ]

    return fetchStaticAsyncDropdown(status, search)
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-1 w-auto'
            defaultValue={value}
            disabled={displayLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name

    setCurrentOffSet('')
    setSelected(0)

    switch (inputName) {
      case 'route':
        setRoutesObj(e)
        break
      case 'status':
        setStatus(e)
        break
      case 'warehouse':
        setWarehouse(e)
        break
      case 'search':
        setSearchByName(e.target.value)
        break
      case 'sr':
        setSr(e)
        break
    }
  }

  return (
    <>
      <EATitle title='Route Jump Report' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Route Jump Report'})}
      </PageTitle>
      <div>
        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={handleFilterChange} isDisable={displayLoader} />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                {isAdmin() && (
                  <WarehouseFilter
                    warehouse={warehouse}
                    handleFilterChange={handleFilterChange}
                    isDisabled={false}
                  />
                )}

                <AsyncPaginate
                  loadOptions={fetchRoute}
                  isDisabled={displayLoader}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  placeholder='All Route'
                  value={routesObj}
                  onChange={(e: any) => handleFilterChange(e, 'route')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  key={srReset}
                  loadOptions={fetchSrs}
                  className='react-select-container my-1 me-3 w-140px'
                  classNamePrefix='react-select'
                  isDisabled={displayLoader}
                  placeholder='All SR'
                  onChange={(e: any) => handleFilterChange(e, 'sr')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchStatus}
                  className='react-select-container my-1 me-3 w-140px'
                  classNamePrefix='react-select'
                  isDisabled={displayLoader}
                  placeholder='All Status'
                  onChange={(e: any) => handleFilterChange(e, 'status')}
                  value={status}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setFilteredDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                  }}
                  value={filteredDate}
                  startDate={filteredDate.startDate}
                  endDate={filteredDate.endDate}
                />
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {displayLoader ? (
                <FetchingRecords />
              ) : routeJumpList.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='w-150px min-w-150px'>Date & Time</th>
                      <th className='w-150px min-w-150px'>SR</th>
                      <th className='w-150px min-w-150px'>Assigned Route</th>
                      <th className='w-150px min-w-150px'>Jump Type</th>
                      <th className='w-150px min-w-150px'>Reason Type</th>
                      <th className='w-150px min-w-150px'>Description</th>
                      <th className='w-150px min-w-150px'>Status</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {routeJumpList.map((item: any) => {
                      return (
                        <>
                          <tr
                            className='cursor-pointer'
                            onClick={() => {
                              if (item?.jump_type === 'customer_creation') {
                                localStorage.setItem('customerPermission', 'allCustomer')
                                localStorage.setItem('cId', item?.model_id ?? '')
                                navigate('/customers/singlecustomer/overview')
                              }

                              if (item?.jump_type === 'make_a_sale') {
                                localStorage.setItem('oId', item?.model_id ?? '')
                                navigate('/reports/order-report/order-detail')
                              }

                              if (item?.jump_type === 'credit_collection') {
                                navigate('/reports/credit-collection-report/credit-issues-detail', {
                                  state: {
                                    credit_issue: 'credit_issue',
                                    reports: 'report',
                                    creditIssueId: item?.model_id ?? '',
                                    creditCollection: 'creditCollection',
                                  },
                                })
                              }
                            }}
                          >
                            <td>
                              <>
                                {isEmpty(item?.created_at) ? (
                                  '-'
                                ) : (
                                  <>
                                    {dateFormat(item.created_at).displayDate}
                                    <p className='font-13 text-muted mb-0'>
                                      {dateFormat(item.created_at, 'LT').displayDate}
                                    </p>
                                  </>
                                )}
                              </>
                            </td>
                            <td>{isEmpty(item?.sr_name) ? '-' : item?.sr_name}</td>
                            <td>{isEmpty(item?.route_name) ? '-' : item?.route_name}</td>
                            <td>
                              {isEmpty(item?.jump_type) ? (
                                '-'
                              ) : (
                                <>{getActivityEnumName(item?.jump_type ?? '')}</>
                              )}
                            </td>
                            <td>{isEmpty(item?.reason_name) ? '-' : item?.reason_name}</td>
                            <td>{isEmpty(item?.reason_note) ? '-' : item?.reason_note}</td>
                            <td>
                              {isEmpty(item?.status) ? (
                                '-'
                              ) : (
                                <>
                                  {item?.status === 'resolved' ? (
                                    <span className='badge badge-light text-dark'>Resolved</span>
                                  ) : (
                                    <span className='badge badge-light-danger text-dark'>Open</span>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={routeJumpList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffSet}
          totalRecords={count}
          limit={limit}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default RouteJumpReport
