import $ from 'jquery'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {WareHouseSingle} from '../../../_eaFruitsDms/apiFunctions/wareHouse/warehouse'
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  focusVatidateField,
  GLOBAL_DATE_FORMAT,
  isEmpty,
  isModalValidation,
  KTSVG,
  toAbsoluteUrl,
} from '../../../_eaFruitsDms/helpers'
import capitalizeFirstLetter from '../../../_eaFruitsDms/helpers/components/CapitalLetter'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import ValidationHandler from '../../../_eaFruitsDms/helpers/components/validationHandler'
import DatePickerCustom from '../../../_eaFruitsDms/helpers/datePicker'
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import EaDropDown from '../../../_eaFruitsDms/layout/components/dropdown/dropdown'
import ErrorHandler from '../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import Banner from '../../../_eaFruitsDms/layout/components/forms/banner'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import CustomPagination from '../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import VehicleTableLo from './component/vehicleTableLO'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'

type Props = {
  className: string
}

const year = [
  {value: '1990', label: '1990'},
  {value: '1991', label: '1991'},
  {value: '1992', label: '1992'},
  {value: '1993', label: '1993'},
  {value: '1994', label: '1994'},
  {value: '1995', label: '1995'},
  {value: '1996', label: '1996'},
  {value: '1997', label: '1997'},
  {value: '1998', label: '1998'},
  {value: '1999', label: '1999'},
  {value: '2000', label: '2000'},
  {value: '2001', label: '2001'},
  {value: '2002', label: '2002'},
  {value: '2003', label: '2003'},
  {value: '2004', label: '2004'},
  {value: '2005', label: '2005'},
  {value: '2006', label: '2006'},
  {value: '2007', label: '2007'},
  {value: '2008', label: '2008'},
  {value: '2009', label: '2009'},
  {value: '2010', label: '2010'},
  {value: '2011', label: '2011'},
  {value: '2012', label: '2012'},
  {value: '2013', label: '2013'},
  {value: '2014', label: '2014'},
  {value: '2015', label: '2015'},
  {value: '2016', label: '2016'},
  {value: '2017', label: '2017'},
  {value: '2018', label: '2018'},
  {value: '2019', label: '2019'},
  {value: '2020', label: '2020'},
  {value: '2021', label: '2021'},
  {value: '2022', label: '2022'},
  {value: '2023', label: '2023'},
]

const VehicleManagement: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const customerHeaderTitle = {
    title: 'Vehicle Management',
    subTitle: '',
  }
  const locationOptions = [
    {value: 'AtWarehouse', label: 'At Warehouse'},
    {value: 'InRoute', label: 'In Route'},
  ]
  const limit = '&limit=1000'
  const wardColor = '#109433'
  const defaultSrc = toAbsoluteUrl('/media/icons/img_crop_banner.png')
  const validaionMessage =
    'You are allowing vehicle to the wards belongs to multiple sales supervisors.'

  const [getList, setGetList] = useState<any>([]) // getdata
  const [getListDropDown, setGetListDropDown] = useState<any>([]) // getdata
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [selected, setSelected] = useState(0)
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [id, setId] = useState('')
  const [deleteId, setDeleteId] = useState<any>([])
  const [cropData, setCropData] = useState(defaultSrc)
  const [checked, setChecked] = useState('')
  const [imageIndex, setImageIndex] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const [wardReset, setWardReset] = useState<any>(moment())
  const [isVehicleForHoreca, setVehicleForHoreca] = useState<boolean>(false)
  const [dropDownList, setDropDownList] = useState({
    vehicleType: [],
    make: [],
    brands: [],
    wards: [],
    status: [
      {value: '', label: 'All Status'},
      {value: 'Available', label: 'Available'},
      {value: 'Breakdown', label: 'Breakdown'},
      {value: 'InService', label: 'In Service'},
    ],
    model: [],
  })

  const [dropDownModalList, setDropDownModalList] = useState({
    vehicleType: [],
    make: [],
    brands: [],
    wards: [],
    status: [
      {value: 'Available', label: 'Available'},
      {value: 'Breakdown', label: 'Breakdown'},
      {value: 'InService', label: 'In Service'},
    ],
    model: [],
  })

  const [errorMessage, setErrorMessage] = useState('')
  const [name, setName] = useState('')
  const [dropDownValue, setDropDownValue] = useState({
    vehicleType: [{value: '', label: 'All Vehicle'}],
    make: [{value: '', label: 'All Make'}],
    brands: [{value: '', label: 'All Brands'}],
    wards: [{value: '', label: 'All Wards'}],
    status: [{value: '', label: 'All Status'}],
  })
  const [modal, setModal] = useState<any>({
    vehicleNumber: '',
    vehicleType: [],
    make: [],
    modal: [],
    year: [],
    wards: [],
    capacity: '',
    status: [],
    image: [],
    brands: [],
    startDate: '',
    endDate: '',
    date: 'Select',
  })

  const [validaiton, setValidation] = useState({
    vehicleNumber: '',
    vehicleType: '',
    make: '',
    modal: '',
    year: '',
    wards: '',
    capacity: '',
    status: '',
    brands: '',
    date: '',
    imageSize: '',
    reason: '',
    location: '',
    description: '',
  })

  const [isModalLoading, setIsModalLoading] = useState('Submit')
  const [disableBtn, setDisableBtn] = useState(false)
  const [requestHandler, setRequestHandler] = useState<any>('add')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [image, setImage] = useState<any>([])
  const [index, setIndex] = useState(0)
  const [wardsOffset, setWardsOffset] = useState('')
  const [wardsList, setWardsList] = useState('')
  const [wardSearch, setWardsSearch] = useState('')
  const [location, setLocation] = useState<any>('')
  const [reason, setReason] = useState<any>('')
  const [otherReason, setOtherReason] = useState<any>('')
  const [reasonOffset, setReasonOffset] = useState('')
  const [reasonList, setReasonList] = useState('')
  const [reasonSearch, setReasonSearch] = useState('')
  const [imageView, setImageView] = useState([])
  const [modalLoader, setModalLoader] = useState<any>(false)

  const modalHandler = (e: any, key?: any) => {
    // Develop BY: Kashyap
    // Description: handling user details

    if (key === 'input') {
      const {value, name} = e.target
      setModal((preValue: any) => ({
        ...preValue,
        [name]: value,
      }))
    }

    let event = e
    if (event.length === 0) {
      event = ''
    }
    const validation = ValidationHandler(event, 'is-invalid')

    if (key === 'vehicleType') {
      setModal((preValue: any) => ({...preValue, vehicleType: e}))
      setValidation((preValue: any) => ({...preValue, vehicleType: validation}))
    }
    if (key === 'make') {
      setModal((preValue: any) => ({...preValue, make: e, modal: []}))
      setValidation((preValue: any) => ({...preValue, make: validation}))

      getRequest(
        `${apiBaseURL()}master/vechile-model?ordering=name&vechile_make__vechile_make=${e.label}` +
          limit,
        true
      ).then((res) => {
        let modalModal: any = []
        res.results.forEach((element: any) => {
          modalModal.push({value: element.id, label: element.vechile_model})
        })
        setDropDownModalList((preValue: any) => ({...preValue, model: modalModal}))
      })
    }

    if (key === 'modal') {
      setModal((preValue: any) => ({...preValue, modal: e}))

      setValidation((preValue: any) => ({...preValue, modal: validation}))
    }
    if (key === 'brands') {
      setModal((preValue: any) => ({...preValue, brands: e}))
      setValidation((preValue: any) => ({...preValue, brands: validation}))
      setModal((preValue: any) => ({...preValue, wards: []}))
      setValidationMessage('')
    }

    if (key === 'status') {
      setModal((preValue: any) => ({
        ...preValue,
        status: e,
        date: 'Select',
        startDate: '',
        endDate: '',
      }))
      setValidation((preValue: any) => ({...preValue, status: validation}))
      setInitialSettings((pre: any) => ({
        ...pre,
        startDate: moment(),
        endDate: moment(),
      }))
      setLocation('')
      setReason('')
      setOtherReason('')
    }
    if (key === 'wards') {
      setModal((preValue: any) => ({...preValue, wards: e}))
      setValidation((preValue: any) => ({...preValue, wards: validation}))
    }
    if (key === 'year') {
      setModal((preValue: any) => ({...preValue, year: e}))
      setValidation((preValue: any) => ({...preValue, year: validation}))
    }
    if (key === 'dateRange') {
      setModal((preValue: any) => ({
        ...preValue,
        startDate: e.startDateApi,
        endDate: e.endDateApi,
        date: e.startDateView + ' ' + '-' + ' ' + e.endDateView,
      }))
    }
  }

  useEffect(() => {
    // Develop BY: Kashyap
    // Description: get table list and dropdown list
    getVehicleList(
      '10',
      '0',
      '',
      dropDownValue.vehicleType[0].value,
      dropDownValue.make[0].value,
      dropDownValue.wards[0].value,
      dropDownValue.brands[0].value,
      dropDownValue.status[0].value
    )
    getModalValue()
  }, [])

  const getModalValue = () => {
    getRequest(`${apiBaseURL()}master/vechile-type/?ordering=vechile_type` + limit, true).then(
      (res) => {
        let vehicleType: any = [{value: '', label: 'All Vehicle'}]
        let vehicleTypeModal: any = []
        res.results.forEach((element: any) => {
          vehicleType.push({value: element.id, label: element.vechile_type})
          vehicleTypeModal.push({value: element.id, label: element.vechile_type})
        })
        setDropDownList((preValue: any) => ({...preValue, vehicleType: vehicleType}))
        setDropDownModalList((preValue: any) => ({...preValue, vehicleType: vehicleTypeModal}))
      }
    )

    getRequest(`${apiBaseURL()}master/vechile-make/?ordering=vechile_make` + limit, true).then(
      (res) => {
        let vehicleMake: any = [{value: '', label: 'All Make'}]
        let vehicleMakeModal: any = []
        res.results.forEach((element: any) => {
          vehicleMake.push({value: element.id, label: element.vechile_make})
          vehicleMakeModal.push({value: element.id, label: element.vechile_make})
        })
        setDropDownList((preValue: any) => ({...preValue, make: vehicleMake}))
        setDropDownModalList((preValue: any) => ({...preValue, make: vehicleMakeModal}))
      }
    )

    getRequest(`${apiBaseURL()}master/product/brand?ordering=name` + limit, true).then((res) => {
      let brands: any = [{value: '', label: 'All Brands'}]
      let brandsModal: any = []
      res.results.forEach((element: any) => {
        brands.push({value: element.id, label: element.name})
        brandsModal.push({value: element.id, label: element.name})
      })
      setDropDownList((preValue: any) => ({...preValue, brands: brands}))
      setDropDownModalList((preValue: any) => ({...preValue, brands: brandsModal}))
    })

    getRequest(`${apiBaseURL()}master/vechile-model/?ordering=name` + limit, true).then((res) => {
      let modal: any = [{value: '', label: 'All Modal'}]
      let modalModal: any = []
      res.results.forEach((element: any) => {
        modal.push({value: element.id, label: element.vechile_model})
        modalModal.push({value: element.id, label: element.vechile_model})
      })
      setDropDownList((preValue: any) => ({...preValue, model: modal}))
      setDropDownModalList((preValue: any) => ({...preValue, model: modalModal}))
    })

    WareHouseSingle(localStorage.getItem('w_id')).then((res: any) => {
      getRequest(`${apiBaseURL()}master/wards/list/?ordering=name` + limit, true).then((ward) => {
        let getLoWards: any = [{value: '', label: 'All Wards'}]
        let getLoWardsModal: any = []
        ward.results.forEach((element: any) => {
          if (res.wards.includes(element.id)) {
            getLoWards.push({value: element.id, label: element.name})
            getLoWardsModal.push({
              value: element.id,
              label: (
                <>
                  <span>{element.name}</span>
                  <OverlayTrigger
                    delay={{hide: 450, show: 300}}
                    overlay={(props) => (
                      <Tooltip {...props} className='tooltip-dark'>
                        Sales Representative
                      </Tooltip>
                    )}
                    placement='bottom'
                  >
                    <b className='cursor-pointer'> - SR</b>
                  </OverlayTrigger>
                </>
              ),
              // color: wardColor
            })
          }
        })
        setDropDownList((preValue: any) => ({...preValue, wards: getLoWards}))
        setDropDownModalList((preValue: any) => ({...preValue, wards: getLoWardsModal}))
      })
    })
  }

  const getVehicleList = (
    limit: any,
    offset: any,
    search: any,
    vehicleType: any,
    make: any,
    ward: any,
    brand: any,
    status: any
  ) => {
    setListLoader(true)
    const url = `${apiBaseURL()}vehicle/view/?limit=${limit}&offset=${offset}&search=${search}&type__id=${vehicleType}&make__id=${make}&wards__id=${ward}&brand_name__id=${brand}&status=${status}&warehouse_id=${localStorage.getItem(
      'w_id'
    )}`
    // &warehouse_id=${localStorage.getItem('w_id')}
    getRequest(url, true).then((res) => {
      setGetList(res.results)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.previous)
      setListLoader(false)
    })
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      getVehicleList(
        '10',
        newOffset,
        search,
        dropDownValue.vehicleType[0].value,
        dropDownValue.make[0].value,
        dropDownValue.wards[0].value,
        dropDownValue.brands[0].value,
        dropDownValue.status[0].value
      )
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      getVehicleList(
        '10',
        newOffset,
        search,
        dropDownValue.vehicleType[0].value,
        dropDownValue.make[0].value,
        dropDownValue.wards[0].value,
        dropDownValue.brands[0].value,
        dropDownValue.status[0].value
      )
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      getVehicleList(
        '10',
        newOffset,
        search,
        dropDownValue.vehicleType[0].value,
        dropDownValue.make[0].value,
        dropDownValue.wards[0].value,
        dropDownValue.brands[0].value,
        dropDownValue.status[0].value
      )
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      getVehicleList(
        '10',
        '',
        e.target.value,
        dropDownValue.vehicleType[0].value,
        dropDownValue.make[0].value,
        dropDownValue.wards[0].value,
        dropDownValue.brands[0].value,
        dropDownValue.status[0].value
      )
      setSelected(0)
    }, 500)
    setTimer(newTimer)
  }

  const getImage = (val: any, url: any) => {
    if (val.size < 27924109) {
      let size = val.size / 1024 / 1024
      var objectUrl = url
      setModal((preValue: any) => ({
        ...preValue,
        image: [
          ...preValue.image,
          {
            id: index,
            apiImage: val,
            listImage: {
              image: objectUrl,
              imageSize: size,
            },
          },
        ],
      }))
    }
  }

  useEffect(() => {
    // Develop BY: Kashyap
    // Description: set index for handling multipal images
    setIndex(index + 1)
  }, [cropData])

  const onImageChange = (e: any) => {
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        let image = URL.createObjectURL(file)
        let imageSize = size
        let imageBundle = {
          image: cropData,
          imageSize: imageSize,
        }
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height

          if (size > 5.0) {
            setValidation((preValue: any) => ({...preValue, imageSize: true}))
          } else {
            setValidation((preValue: any) => ({...preValue, imageSize: false}))
            setImage(imageBundle)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  const clearFunction = () => {
    setWardsOffset('')
    setValidationMessage('')
    setImageIndex('')
    setErrorMessage('')
    setValidation({
      reason: '',
      location: '',
      description: '',
      vehicleNumber: '',
      vehicleType: '',
      make: '',
      modal: '',
      year: '',
      wards: '',
      capacity: '',
      status: '',
      brands: '',
      date: '',
      imageSize: '',
    })
    setDeleteId([])
    setModal({
      vehicleNumber: '',
      vehicleType: [],
      make: [],
      modal: [],
      year: [],
      wards: [],
      capacity: '',
      status: [],
      image: [],
      brands: [],
      date: 'Select',
      startDate: '',
      endDate: '',
    })
    setInitialSettings((pre: any) => ({
      ...pre,
      startDate: moment(),
      endDate: moment(),
    }))
    setLocation('')
    setReason('')
    setOtherReason('')
    setModalLoader(false)
    setVehicleForHoreca(false)
  }

  const submitModal = () => {
    let navigateField = {
      datePickerClose: modal.vehicleNumber,
      vehicleType: modal.vehicleType.length !== 0 ? modal.vehicleType.value : '',
      make: modal.make.length !== 0 ? modal.make.value : '',
      modal: modal.modal.length !== 0 ? modal.modal.value : '',
      year: modal.year.length !== 0 ? modal.year.value : '',
      brands: isVehicleForHoreca ? '1' : modal.brands.length !== 0 ? modal.brands.value : '',
      wards: modal.wards.length !== 0 ? modal.wards.value : '',
      capacity: modal.capacity,
      status: modal.status.length !== 0 ? modal.status.value : '',
      reason: reason ? reason.value : '',
      // otherReason: modal.otherReason.length !== 0 ? modal.otherReason.value : '',
      location: location.value ? location.value : '',
      date: modal.startDate,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (modal.vehicleNumber === '') {
      setValidation((preValue: any) => ({...preValue, vehicleNumber: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, vehicleNumber: ''}))
    }
    if (modal.vehicleType.length === 0) {
      setValidation((preValue: any) => ({...preValue, vehicleType: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, vehicleType: ''}))
    }
    if (modal.make.length === 0) {
      setValidation((preValue: any) => ({...preValue, make: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, make: ''}))
    }
    if (modal.modal.length === 0) {
      setValidation((preValue: any) => ({...preValue, modal: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, modal: ''}))
    }
    if (modal.year.length === 0) {
      setValidation((preValue: any) => ({...preValue, year: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, year: ''}))
    }
    if (modal.wards.length === 0) {
      setValidation((preValue: any) => ({...preValue, wards: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, wards: ''}))
    }
    if (modal.capacity === '') {
      setValidation((preValue: any) => ({...preValue, capacity: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, capacity: ''}))
    }
    if (modal.status.length === 0) {
      setValidation((preValue: any) => ({...preValue, status: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, status: ''}))
    }

    if (!isVehicleForHoreca && modal.brands.length === 0) {
      setValidation((preValue: any) => ({...preValue, brands: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, brands: ''}))
    }

    let isValid = true
    if (modal.status.value === 'InService') {
      if (modal.startDate === '') {
        isValid = false
        setValidation((preValue: any) => ({...preValue, date: 'is-invalid'}))
      } else {
        setValidation((preValue: any) => ({...preValue, date: ''}))
      }
    }

    if (modal.status.label === 'Breakdown') {
      if (location === '') {
        isValid = false
        setValidation((preValue: any) => ({...preValue, location: 'is-invalid'}))
      } else {
        setValidation((preValue: any) => ({...preValue, location: ''}))
      }

      if (reason === '') {
        isValid = false
        setValidation((preValue: any) => ({...preValue, reason: 'is-invalid'}))
      } else {
        setValidation((preValue: any) => ({...preValue, reason: ''}))
      }

      if (reason.label === 'Other') {
        if (otherReason === '') {
          isValid = false
          setValidation((preValue: any) => ({...preValue, description: 'is-invalid'}))
        } else {
          setValidation((preValue: any) => ({...preValue, description: ''}))
        }
      }
    }

    let isBrandValid = true

    if (!isVehicleForHoreca && modal.brands.length === 0) {
      isBrandValid = false
    }

    if (
      modal.vehicleNumber !== '' &&
      modal.vehicleType.length !== 0 &&
      modal.make.length !== 0 &&
      modal.modal.length !== 0 &&
      modal.year.length !== 0 &&
      modal.wards.length !== 0 &&
      modal.capacity !== '' &&
      modal.status.length !== 0 &&
      isValid &&
      isBrandValid
    ) {
      setName(modal.vehicleNumber)
      let wards: any = []
      modal.wards.forEach((element: any) => {
        wards.push(element.value)
      })

      let getLocation = location?.value
      let getReason = reason?.value

      const url = `${apiBaseURL()}vehicle`

      let formData: any = new FormData()

      formData.append('number', modal.vehicleNumber)
      formData.append('year', modal.year.value)
      formData.append('capacity', modal.capacity)
      formData.append('status', modal.status.value)
      formData.append('type', modal.vehicleType.value)
      formData.append('make', modal.make.value)
      formData.append('model', modal.modal.value)
      formData.append('brand_name', isEmpty(modal.brands.value) ? '' : modal.brands.value)
      formData.append('is_for_horeca', isVehicleForHoreca)

      if (modal.status.label === 'Breakdown') {
        formData.append('breakdown_at', getLocation)
        formData.append('breakdown_reason', getReason)

        if (reason.label === 'Other') {
          formData.append('breakdown_reason_description', otherReason)
        }
      }

      // Develop BY: Kashyap
      // Description: request handler for post and patch request
      if (requestHandler === 'add' && modal.image.length !== 0) {
        formData.append(`vehicle_images[0]is_cover`, true)
      }

      if (modal.image.length !== 0 && imageIndex !== '') {
        formData.append(`vehicle_images[${imageIndex}]is_cover`, true)
      }

      let array = wards
      let imageArray = modal.image

      for (var i = 0; i < array.length; i++) {
        formData.append('wards', array[i])
      }

      for (var i = 0; i < imageArray.length; i++) {
        if (modal.image[i].apiImage !== '1') {
          formData.append(`vehicle_images[${i}]vehicle_image`, modal.image[i].apiImage)
        }
      }

      if (modal.status.value === 'InService') {
        formData.append('service_start_date', modal.startDate)
        formData.append('service_end_date', modal.endDate)
      }

      setDisableBtn(true)
      setIsModalLoading('Please Wait...')

      if (requestHandler === 'add') {
        postRequest(url + '/create', formData, true).then((res) => {
          setDisableBtn(false)
          setIsModalLoading('Submit')
          if (res.status === 201) {
            getVehicleList(
              '10',
              '',
              search,
              dropDownValue.vehicleType[0].value,
              dropDownValue.make[0].value,
              dropDownValue.wards[0].value,
              dropDownValue.brands[0].value,
              dropDownValue.status[0].value
            )
            setSelected(0)
            $('#closeDisModal').trigger('click')
            setIsAlertSuccess(true)
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 1500)
          } else {
            setErrorMessage('something went wrong')
          }
        })
      } else {
        if (deleteId.length !== 0) {
          deleteId.map((id: any) => {
            let url = `${apiBaseURL()}vehicle/images/${id}`
            deleteRequest(url, true).then((res) => {
              getVehicleList(
                '10',
                '',
                search,
                dropDownValue.vehicleType[0].value,
                dropDownValue.make[0].value,
                dropDownValue.wards[0].value,
                dropDownValue.brands[0].value,
                dropDownValue.status[0].value
              )
            })
          })
        }

        patchRequest(url + '/update/' + `${id}/`, formData, true).then((res) => {
          setDisableBtn(false)
          setIsModalLoading('Submit')
          if (res.status === 200) {
            setSelected(0)
            getVehicleList(
              '10',
              '',
              search,
              dropDownValue.vehicleType[0].value,
              dropDownValue.make[0].value,
              dropDownValue.wards[0].value,
              dropDownValue.brands[0].value,
              dropDownValue.status[0].value
            )
            $('#closeDisModal').trigger('click')
            $('#closeStatusUpdateModal').trigger('click')
            setIsAlertSuccess(true)
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 1500)
          } else {
            setErrorMessage('something went wrong')
          }
        })
      }
    }
  }

  const getProps = async (val: any, key: any) => {
    setModalLoader(true)
    setId(val.id)
    setRequestHandler('edit')
    setName(val.number)
    if (key === 'edit') {
      setRequestHandler('edit')
      setMessageHandler('successEdit')
      let totalStatus = dropDownList.status
      let selectedStatus: any = []
      totalStatus.forEach((element: any) => {
        if (val.status === element.value) {
          // selectedStatus.push(element)
          selectedStatus = {value: element.value, label: element.label}
        }
      })

      let selectedWards: any = []

      let location: any =
        val.breakdown_at && locationOptions.find((item: any) => item.value === val.breakdown_at)
      val.breakdown_at && setLocation(location)

      const response = await getRequest(
        `${apiBaseURL()}vehicle/view/${val.id}/?brand_id=${
          isEmpty(val.brand_name) ? '' : val.brand_name.id
        }`,
        true
      )

      setVehicleForHoreca(response.is_for_horeca)
      setImageView(response.vehicle_images)

      let totalWards = response.wards
      setModalLoader(false)

      if (totalWards.length !== 0) {
        totalWards.forEach((element: any) => {
          selectedWards.push({
            value: element.id,
            // label: element.name
            label: (
              <>
                <span>{element.name}</span>
                {element.user_id.length !== 0 &&
                  element.user_id.map((item: any) => {
                    let fName = item.first_name
                    let lName = item.last_name
                    let concatName = fName.charAt(0) + lName.charAt(0)
                    return (
                      <OverlayTrigger
                        delay={{hide: 450, show: 300}}
                        overlay={(props) => (
                          <Tooltip {...props} className='tooltip-dark'>
                            {item.first_name + ' ' + item.last_name}
                          </Tooltip>
                        )}
                        placement='bottom'
                      >
                        <b className='cursor-pointer'> , {concatName}</b>
                      </OverlayTrigger>
                    )
                  })}
              </>
            ),
            isError: element.user_id.length !== 0 ? element.user_id : [],
            // color: wardColor
          })
        })
      }

      let totalImage: any = []
      if (val.vehicle_images.length !== 0) {
        val.vehicle_images.forEach((item: any) => {
          if (item.is_cover) {
            setChecked(item.id)
          }
          totalImage.push({
            id: item.id,
            apiImage: '1',
            listImage: {
              image: item.vehicle_image,
              imageSize: '',
              isCover: item.is_cover,
            },
          })
        })
      }

      getRequest(
        `${apiBaseURL()}master/vechile-model/?ordering=name&vechile_make__vechile_make=${
          val.make.vechile_make
        }` + limit,
        true
      ).then((res) => {
        let modalModal: any = []
        res.results.forEach((element: any) => {
          modalModal.push({value: element.id, label: element.vechile_model})
        })
        setDropDownModalList((preValue: any) => ({...preValue, model: modalModal}))
      })

      let startDate = dateFormat(val.service_start_date)
      let endDate = dateFormat(val.service_end_date)

      setModal({
        vehicleNumber: val.number,
        vehicleType: {value: val.type.id, label: val.type.vechile_type},
        make: {value: val.make.id, label: val.make.vechile_make},
        modal: {value: val.model.id, label: val.model.vechile_model},
        year: {value: val.year, label: val.year},
        wards: selectedWards,
        capacity: val.capacity,
        brands:
          response.is_for_horeca || isEmpty(val.brand_name)
            ? []
            : {label: val.brand_name.name, value: val.brand_name.id},
        image: totalImage,
        status: selectedStatus,
        date:
          val.service_end_date !== null
            ? startDate.displayDate + ' ' + '-' + ' ' + endDate.displayDate
            : 'Select',
        startDate: val.service_start_date,
        endDate: val.service_end_date,
      })

      if (val.status === 'Breakdown' && val.breakdown_reason === null) {
        setReason({label: 'Other', value: ''})
        if (
          val.breakdown_reason_description == null ||
          val.breakdown_reason_description == 'null'
        ) {
          setOtherReason('')
        } else {
          setOtherReason(val.breakdown_reason_description)
        }
      }

      if (val.breakdown_reason !== null) {
        setReason({label: val.breakdown_reason.option, value: val.breakdown_reason.id})
      }

      setInitialSettings((pre: any) => ({
        ...pre,
        startDate: startDate.displayDate,
        endDate: endDate.displayDate,
      }))
    }

    if (key === 'delete') {
      setMessageHandler('successDelete')
      setModal((preValue: any) => ({...preValue, vehicleNumber: val.number}))
    }
  }

  // delete vehicle
  const deleteVehicle = () => {
    const url = `${apiBaseURL()}vehicle/update/${id}/`

    deleteRequest(url, true).then((res) => {
      getVehicleList(
        '10',
        '',
        search,
        dropDownValue.vehicleType[0].value,
        dropDownValue.make[0].value,
        dropDownValue.wards[0].value,
        dropDownValue.brands[0].value,
        dropDownValue.status[0].value
      )

      $('#closeDelModal').trigger('click')
      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)
    })
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const filterVehicleType = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, vehicleType: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      e.value,
      dropDownValue.make[0].value,
      dropDownValue.wards[0].value,
      dropDownValue.brands[0].value,
      dropDownValue.status[0].value
    )
  }
  const filterMake = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, make: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      dropDownValue.vehicleType[0].value,
      e.value,
      dropDownValue.wards[0].value,
      dropDownValue.brands[0].value,
      dropDownValue.status[0].value
    )
  }
  const filterBrands = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, brands: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      dropDownValue.vehicleType[0].value,
      dropDownValue.make[0].value,
      dropDownValue.wards[0].value,
      e.value,
      dropDownValue.status[0].value
    )
  }
  const filterWards = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, wards: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      dropDownValue.vehicleType[0].value,
      dropDownValue.make[0].value,
      e.value,
      dropDownValue.brands[0].value,
      dropDownValue.status[0].value
    )
  }
  const filterStatus = (e: any) => {
    setDropDownValue((preValue) => ({...preValue, status: [e]}))
    getVehicleList(
      '10',
      '0',
      search,
      dropDownValue.vehicleType[0].value,
      dropDownValue.make[0].value,
      dropDownValue.wards[0].value,
      dropDownValue.brands[0].value,
      e.value
    )
  }

  const deleteImage = (item: any, index: any) => {
    let deletedImage = [...deleteId]
    let id = item.id
    deletedImage.push(id)
    setDeleteId(deletedImage)
    let imageBundle = modal.image.filter((item: any) => {
      return item.id !== id
    })
    setModal((preValue: any) => ({...preValue, image: imageBundle}))
  }

  const checkItem = (item: any, index: any) => {
    setChecked(item.id)

    setImageIndex(index)

    modal.image.forEach((element: any) => {
      if (element.apiImage === '1') {
        let body = {
          is_cover: false,
        }
        if (element.id === item.id) {
          body.is_cover = true
        }
        let url = `${apiBaseURL()}vehicle/images/${element.id}/`
        patchRequest(url, body, true).then((res) => {
          getVehicleList(
            '10',
            '',
            search,
            dropDownValue.vehicleType[0].value,
            dropDownValue.make[0].value,
            dropDownValue.wards[0].value,
            dropDownValue.brands[0].value,
            dropDownValue.status[0].value
          )
        })
      }
    })
  }
  const [initialSettings, setInitialSettings] = useState({
    startDate: moment(),
    endDate: moment(),
    alwaysShowCalendars: true,
    autoUpdateInput: false,
    cancelClass: 'btn-secondary',
    drops: 'auto',
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    },
  })
  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let startDate = start._d
          let endDate = end._d

          let newStartDate = dateFormat(startDate)
          let newEndDate = dateFormat(endDate)

          let dateObj = {
            startDateView: newStartDate.displayDate,
            endDateView: newEndDate.displayDate,

            startDateApi: newStartDate.apiDate,
            endDateApi: newEndDate.apiDate,
          }
          modalHandler(dateObj, 'dateRange')
          setValidation((preValue: any) => ({...preValue, date: ''}))
          $(`#datePickerClose`).blur()
          setInitialSettings((pre: any) => ({
            ...pre,
            startDate: startDate,
            endDate: endDate,
          }))
        }}
        initialSettings={initialSettings}
        dateValidation={validaiton.date}
        dateValue={modal.date}
      />
    )
  }

  const fetchWards = async (search: any) => {
    if (search != '') {
      setWardsSearch(search)
    }

    let limit = 10

    let options: any = []

    let concatUrl =
      apiBaseURL() +
      `master/wards/list/?ordering=name&warehouse_id=${localStorage.getItem('w_id')}&brand_id=${
        isEmpty(modal.brands.value) ? '' : modal.brands.value
      }&search=${search}&limit=${limit}&offset=${wardsOffset}`

    const response = await getRequest(concatUrl, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setWardsOffset(newOffset)
      hasMore = true
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: (
            <>
              <span>{option.name}</span>
              {option.user_id.length !== 0 &&
                option.user_id.map((item: any, index: number) => {
                  let fName = item.first_name
                  let lName = item.last_name
                  let concatName = fName.charAt(0) + lName.charAt(0)
                  return (
                    <OverlayTrigger
                      delay={{hide: 450, show: 300}}
                      overlay={(props) => (
                        <Tooltip {...props} className='tooltip-dark'>
                          {item.first_name + ' ' + item.last_name}
                        </Tooltip>
                      )}
                      placement='bottom'
                    >
                      <b className='cursor-pointer'>
                        {' '}
                        {index !== 0 ? ',' : ''} {concatName}
                      </b>
                    </OverlayTrigger>
                  )
                })}
            </>
          ),
          value: option.id,
          isError: option.user_id.length !== 0 ? option.user_id : [],
        })
      })
    }

    if (wardsList.length > 0) {
      setWardsList(wardsList.concat(options))
    } else {
      setWardsList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const colourStyles = {
    multiValueLabel: (styles: any, {data}: any) => ({
      ...styles,
      color: data.isError.length !== 0 && 'black',
      borderRadius: 0,
      backgroundColor: data.isError.length !== 0 && data.isError[0].colour_code,
      ':hover': {
        backgroundColor: data.isError.length !== 0 && data.isError[0].colour_code,

        color: data.isError.length !== 0 && 'black',
      },
    }),
    multiValueRemove: (styles: any, {data}: any) => ({
      ...styles,
      color: data.isError.length !== 0 && 'black',
      borderRadius: 0,
      backgroundColor: data.isError.length !== 0 && data.isError[0].colour_code,
      ':hover': {
        backgroundColor: data.isError.length !== 0 && `${data.isError[0].colour_code} !important`,
        color: data.isError.length !== 0 && 'black !important',
      },
    }),
  }

  const checkDuplicate = (array: any) => {
    for (var i = 0; i < array.length; i++) {
      for (var j = i + 1; j < array.length; j++) {
        if (array[i] !== array[j]) {
          return true
        }
      }
    }
    return false
  }

  const handleErrorMessage = (e: any) => {
    let arrayOfSSId: any = []

    e.length !== 0 &&
      e.map((item: any) => {
        item.isError.length !== 0 &&
          item.isError.map((user: any) => {
            arrayOfSSId.push(user.id)
          })
      })

    if (checkDuplicate(arrayOfSSId)) {
      setValidationMessage(validaionMessage)
    }
  }

  const fetchReason = async (search: any) => {
    const response = await fetchAsyncDropdown(
      reasonOffset,
      setReasonOffset,
      reasonList,
      setReasonList,
      `master/options-master/?type=VehicleBreakdown&ordering=name`,
      search,
      setReasonSearch,
      'option'
    )

    response.options.push({
      label: 'Other',
      value: '',
    })

    return response
  }

  useEffect(() => {
    setWardsOffset('')
  }, [wardSearch])

  useEffect(() => {
    setReasonOffset('')
  }, [reasonSearch])

  useEffect(() => {
    isVehicleForHoreca
      ? setModal((preValue: any) => ({...preValue, brands: {label: 'All Brands', value: ''}}))
      : setModal((preValue: any) => ({...preValue, brands: []}))
  }, [isVehicleForHoreca])

  return (
    <>
      <EATitle title='Vehicle Management - ' />
      <PageTitle breadcrumbs={[customerHeaderTitle]}>
        {intl.formatMessage({id: 'Vehicle Management'})}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('VEHICLE.ADD', name)
            : messageHandler === 'successEdit'
            ? FormatMessage('VEHICLE.UPDATE', name)
            : FormatMessage('VEHICLE.DELETE', name)
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar id='searcListProdcut' onChange={searchList} />
            <div className='col-auto ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <EaDropDown
                  list={dropDownList.vehicleType}
                  placeholder='All Type'
                  onChange={filterVehicleType}
                  value={dropDownValue.vehicleType}
                  className='my-1 me-3 w-110px'
                />
                <EaDropDown
                  className='my-1 me-3 w-115px'
                  list={dropDownList.make}
                  placeholder='All Make'
                  onChange={filterMake}
                  value={dropDownValue.make}
                />
                <EaDropDown
                  className='my-1 me-3 w-120px'
                  list={dropDownList.brands}
                  placeholder='All Brands'
                  onChange={filterBrands}
                  value={dropDownValue.brands}
                />
                <EaDropDown
                  className='my-1 me-3 w-110px'
                  list={dropDownList.wards}
                  placeholder='All Wards'
                  onChange={filterWards}
                  value={dropDownValue.wards}
                />
                <EaDropDown
                  className='my-1 me-3 w-115px'
                  list={dropDownList.status}
                  placeholder='All Status'
                  onChange={filterStatus}
                  value={dropDownValue.status}
                />

                <EaButton
                  isModal={true}
                  dataTarget='#ea_modal_create_vehicle'
                  btnName='Add Vehicle'
                  onClick={() => {
                    setMessageHandler('successAdd')
                    setRequestHandler('add')
                    getRequest(
                      `${apiBaseURL()}master/vechile-model/?ordering=name` + limit,
                      true
                    ).then((res) => {
                      let modalModal: any = []
                      res.results.forEach((element: any) => {
                        modalModal.push({value: element.id, label: element.vechile_model})
                      })
                      setDropDownModalList((preValue: any) => ({...preValue, model: modalModal}))
                    })
                  }}
                />
              </form>
              {/* </div> */}
            </div>
          </div>
        </div>
        {getList.length === 0 ? (
          <NoRecords />
        ) : (
          <VehicleTableLo list={getList} getProps={getProps} showIcon={true} />
        )}
      </div>

      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${
              getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
            } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>
      {/* add vehicle modal */}
      <EaModal
        id='ea_modal_create_vehicle'
        cancel={clearFunction}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        closeAuto='closeDisModal'
        modalTitle={requestHandler === 'add' ? 'Add Vehicle' : 'Edit Vehicle'}
        onSubmit={submitModal}
        loader={modalLoader}
      >
        {/* <Form> */}
        <div className='mb-4'>
          <label className='form-label'>
            Vehicle Number<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={`form-control ${validaiton.vehicleNumber}`}
            placeholder='Type here...'
            onChange={(e) => {
              modalHandler(e, 'input')
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidation((preValue: any) => ({...preValue, vehicleNumber: validation}))
            }}
            name='vehicleNumber'
            value={capitalizeFirstLetter(modal.vehicleNumber)}
            id='datePickerClose'
          />
        </div>
        <div className='row'>
          <div className='col-sm-6 mb-4'>
            <label className='form-label' id='vehicleType'>
              Vehicle Type<span className='text-danger'>*</span>
            </label>
            <EaDropDown
              className={`my-1 me-3 w-100 ${validaiton.vehicleType}`}
              list={dropDownModalList.vehicleType}
              placeholder='Select'
              onChange={(e: any) => {
                modalHandler(e, 'vehicleType')
              }}
              value={modal.vehicleType}
            />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label' id='make'>
              Make<span className='text-danger'>*</span>
            </label>
            <EaDropDown
              className={`my-1 w-100 ${validaiton.make}`}
              list={dropDownModalList.make}
              placeholder='Select'
              onChange={(e: any) => {
                modalHandler(e, 'make')
              }}
              value={modal.make}
            />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label' id='modal'>
              Model<span className='text-danger'>*</span>
            </label>
            <EaDropDown
              className={`my-1 me-3 w-100 ${validaiton.modal}`}
              list={dropDownModalList.model}
              placeholder='Select'
              onChange={(e: any) => {
                modalHandler(e, 'modal')
              }}
              value={modal.modal}
            />
          </div>
          <div className='col-sm-6 mb-4'>
            <label className='form-label' id='year'>
              Year<span className='text-danger'>*</span>
            </label>
            <EaDropDown
              className={`my-1 w-100  ${validaiton.year}`}
              list={year}
              placeholder='Select'
              onChange={(e: any) => {
                modalHandler(e, 'year')
              }}
              value={modal.year}
            />
          </div>
        </div>
        <div className='mb-4'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input widget-13-check'
              name='horecaCustomerVehicle'
              checked={isVehicleForHoreca}
              onChange={(e: any) => {
                setVehicleForHoreca(!isVehicleForHoreca)
                setWardReset(moment())
                setModal((preValue: any) => ({...preValue, wards: []}))
                setValidationMessage('')
              }}
              type='checkbox'
              disabled={false}
              value=''
              id='horecaCustomerVehicle'
            />
            <label className='form-check-label' htmlFor='horecaCustomerVehicle'>
              This Vehicle is for HORECA Customers
            </label>
          </div>
        </div>
        <div className='mb-4'>
          <label className='form-label' id='brands'>
            Brand<span className='text-danger'>*</span>
          </label>
          <EaDropDown
            className={`my-1 ${validaiton.brands}`}
            list={dropDownModalList.brands}
            placeholder='Select'
            onChange={(e: any) => {
              setWardReset(moment())
              modalHandler(e, 'brands')
            }}
            value={isVehicleForHoreca ? {label: 'All Brands', value: ''} : modal.brands}
            isDisable={isVehicleForHoreca}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='wards'>
            Allocated to Wards<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            key={wardReset}
            loadOptions={fetchWards}
            isSearchable
            isMulti
            placeholder='Select'
            className={`react-select-container my-1 ${validaiton.wards}`}
            classNamePrefix='react-select'
            name='select_team'
            styles={colourStyles}
            onChange={(e: any) => {
              setValidationMessage('')
              modalHandler(e, 'wards')
              handleErrorMessage(e)
            }}
            isDisabled={isVehicleForHoreca ? false : modal.brands.length === 0 ? true : false}
            value={modal.wards}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
          {/* <Select
                        options={dropDownModalList.wards}
                        closeMenuOnSelect={false}
                        isSearchable
                        isMulti={true}
                        onChange={(e: any) => { modalHandler(e, "wards") }}
                        value={modal.wards}
                        placeholder='Select...'
                        className={`react-select-container my-1 ${validaiton.wards}`}
                        styles={colourStyles}
                        classNamePrefix="react-select"
                        isDisabled={modal.brands.length === 0 ? true : false}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#0c78a4',
                            },
                        })}
                    /> */}

          {/* <EaDropDown isMulti={true} className={`my-1 ${validaiton.wards}`} list={dropDownModalList.wards} placeholder="Select..." onChange={(e: any) => { modalHandler(e, "wards") }} value={modal.wards} /> */}
        </div>
        <div className='mb-4'>
          <ErrorHandler errorMessage={validationMessage} />
        </div>

        <div className='mb-4'>
          <label className='form-label'>
            Capacity (KG)<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='capacity'
            className={`form-control ${validaiton.capacity}`}
            placeholder='Type here...'
            onChange={(e) => {
              const re = /^[0-9\b]+$/
              if (e.target.value === '' || re.test(e.target.value)) {
                modalHandler(e, 'input')
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setValidation((preValue: any) => ({...preValue, capacity: validation}))
              }
            }}
            name='capacity'
            value={modal.capacity}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='status'>
            Status<span className='text-danger'>*</span>
          </label>
          <EaDropDown
            className={`my-1 ${validaiton.status}`}
            list={dropDownModalList.status}
            placeholder='Select'
            onChange={(e: any) => {
              modalHandler(e, 'status')
            }}
            value={modal.status}
          />
        </div>
        {modal.status?.value === 'Breakdown' && (
          <>
            <div className='mb-4'>
              <label className='form-label' id='reason'>
                Brakdown Reason<span className='text-danger'>*</span>
              </label>
              <AsyncPaginate
                loadOptions={fetchReason}
                onChange={(e: any) => {
                  setReason(e)
                  const validation = ValidationHandler(e.label, 'is-invalid')
                  setValidation((preValue: any) => ({...preValue, reason: validation}))
                  setOtherReason('')
                }}
                isSearchable
                placeholder='Select'
                value={reason}
                className={`react-select-container ${validaiton.reason}`}
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>

            {reason?.label === 'Other' && (
              <div className='mb-4'>
                <label className='form-label' id='otherReason'>
                  Reason Description<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className={`form-control ${validaiton.description}`}
                  placeholder='Type here...'
                  onChange={(e) => {
                    setOtherReason(e.target.value)
                    const validation = ValidationHandler(e.target.value, 'is-invalid')
                    setValidation((preValue: any) => ({...preValue, description: validation}))
                  }}
                  name='otherReason'
                  value={otherReason}
                />
              </div>
            )}

            <div className='mb-4'>
              <label className='form-label' id='location'>
                Location<span className='text-danger'>*</span>
              </label>
              <Select
                options={locationOptions}
                isSearchable
                className={`react-select-container ${validaiton.location}`}
                classNamePrefix='react-select'
                placeholder='Select'
                value={location}
                onChange={(e, removeEvent) => {
                  setLocation(e)
                  const validation = ValidationHandler(e.value, 'is-invalid')
                  setValidation((preValue: any) => ({...preValue, location: validation}))
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </>
        )}
        <div
          className='mb-4'
          style={{
            display: modal.status.value === 'InService' ? 'block' : 'none',
          }}
        >
          <label className='form-label' id='date'>
            Select Range<span className='text-danger'>*</span>
          </label>
          <CustomDatePicker />
        </div>
        <div>
          <label className='form-label'>Upload Picture (Optional)</label>
          <Banner
            aspectRatio={1 / 1}
            minCropBoxHeight={200}
            minCropBoxWidth={200}
            isUpload={false}
            isBanner={true}
            getImage={getImage}
            onImageChange={onImageChange}
            imageRequire={false}
            imageSize={validaiton.imageSize}
            cropData={cropData}
            text='Max. File Size: 5MB & 500px × 500px'
            setCrop={() => {
              setIndex(index + 1)
            }}
            id='datePickerFocus'
          />
          {modal.image.length !== 0 &&
            modal.image.map((item: any, index: any) => {
              return (
                <div className='d-flex align-items-center mt-4'>
                  <div className='symbol symbol-50px symbol-fixed me-4'>
                    <img src={item.listImage.image} alt='' className='border' />
                  </div>
                  <div>
                    <p className='text-deark mb-0'>Img {item.id}.jpg</p>
                    <p className='text-muted mb-0'>
                      {item.listImage.imageSize !== '' &&
                        `${parseFloat(item.listImage.imageSize).toFixed(2)} MB`}
                    </p>
                  </div>
                  <div className='ms-auto d-flex'>
                    {requestHandler === 'edit' && (
                      <span className='form-check form-check-custom form-check-check me-3'>
                        <input
                          className='form-check-input'
                          checked={checked === item.id ? true : false}
                          onClick={() => checkItem(item, index)}
                          type='radio'
                          name='flexRadioDefault'
                          value=''
                          id='flexRadioDefault1'
                        />
                      </span>
                    )}
                    <span
                      onClick={() => deleteImage(item, index)}
                      className='btn py-0 ps-1 pe-0 text-hover-danger btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon ic'
                      />
                    </span>
                  </div>
                </div>
              )
            })}
          <div className='mt-3'>
            <ErrorHandler errorMessage={errorMessage} />
          </div>
        </div>
      </EaModal>

      {/* Update vehical Status from Vehicle Management list*/}
      <EaModal
        id='ea_modal_vehicle_status'
        cancel={clearFunction}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        closeAuto='closeStatusUpdateModal'
        modalTitle='Update Vehicle Status'
        onSubmit={submitModal}
        loader={modalLoader}
      >
        {/* <Form> */}
        <div className='d-flex align-items-sm-center mb-4'>
          {imageView.length !== 0 ? (
            <>
              {imageView.map((item: any) => {
                if (item.is_cover) {
                  return (
                    <>
                      <div className='symbol symbol-50px symbol-1by1 me-2'>
                        <div
                          className='symbol-label'
                          style={{backgroundImage: `url(${item.vehicle_image})`}}
                        ></div>
                      </div>
                    </>
                  )
                }
              })}
            </>
          ) : (
            <>
              <div className='symbol symbol-50px symbol-1by1 me-2'>
                <div
                  className='symbol-label'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/smiles/img_brand.png')})`}}
                ></div>
              </div>
            </>
          )}

          <div>
            <div className='font-17 fw-bold'>
              {modal.make.label} {modal.modal.label} {modal.year.label}
            </div>
            <div className='font-17 fw-bold'>{modal.vehicleNumber}</div>
          </div>
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Status<span className='text-danger'>*</span>
          </label>
          <EaDropDown
            className={`my-1 ${validaiton.status}`}
            list={dropDownModalList.status}
            placeholder='Select'
            onChange={(e: any) => {
              modalHandler(e, 'status')
            }}
            value={modal.status}
          />
        </div>
        {modal.status?.value === 'Breakdown' && (
          <>
            <div className='mb-4'>
              <label className='form-label'>
                Brakdown Reason<span className='text-danger'>*</span>
              </label>
              <AsyncPaginate
                loadOptions={fetchReason}
                onChange={(e: any) => {
                  setReason(e)
                  const validation = ValidationHandler(e.label, 'is-invalid')
                  setValidation((preValue: any) => ({...preValue, reason: validation}))
                  setOtherReason('')
                }}
                isSearchable
                placeholder='Select'
                value={reason}
                className={`react-select-container ${validaiton.reason}`}
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>

            {reason?.label === 'Other' && (
              <div className='mb-4'>
                <label className='form-label'>
                  Reason Description<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className={`form-control ${validaiton.description}`}
                  placeholder='Type here...'
                  onChange={(e) => {
                    setOtherReason(e.target.value)
                    const validation = ValidationHandler(e.target.value, 'is-invalid')
                    setValidation((preValue: any) => ({...preValue, description: validation}))
                  }}
                  name='otherReason'
                  value={otherReason}
                />
              </div>
            )}

            <div className='mb-4'>
              <label className='form-label'>
                Location<span className='text-danger'>*</span>
              </label>
              <Select
                options={locationOptions}
                isSearchable
                className={`react-select-container ${validaiton.location}`}
                classNamePrefix='react-select'
                placeholder='Select'
                value={location}
                onChange={(e, removeEvent) => {
                  setLocation(e)
                  const validation = ValidationHandler(e.value, 'is-invalid')
                  setValidation((preValue: any) => ({...preValue, location: validation}))
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </>
        )}
        <div
          className='mb-4'
          style={{
            display: modal.status.value === 'InService' ? 'block' : 'none',
          }}
        >
          <label className='form-label'>
            Select Range<span className='text-danger'>*</span>
          </label>
          <CustomDatePicker />
        </div>
      </EaModal>

      {/* delete Vehicle */}
      <EaModal
        actionBtnName={isModalLoading === 'Submit' ? 'Yes, Proceed' : isModalLoading}
        btnDisabled={disableBtn}
        closeAuto='closeDelModal'
        onSubmit={deleteVehicle}
        cancel={clearFunction}
        modalTitle='Delete Vehicle'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{modal.vehicleNumber}?</span>
          </p>
        }
        id={'ea_modal_delete_vehicle'}
      />
    </>
  )
}

export default VehicleManagement
