import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import tenantConfiguration from '../../../../../../TenantVariables'
import {
  KTSVG,
  apiBaseURL,
  googleApi,
  isEmpty,
  isValidLatitude,
  isValidLongitude,
} from '../../../../../../_eaFruitsDms/helpers'
import {useExternalScript} from '../../../../../../_eaFruitsDms/helpers/components/scriptHandler'
import useDebounce from '../../../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest, patchRequest, postRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import PlaceSearch from '../../../../../../_eaFruitsDms/layout/components/placeSearch/PlaceSearch'
import CustomSearchBar from '../../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../../_eaFruitsDms/layout/core'
import {initialLatLong} from '../../../../customers/WMcustomers/CustomerConst'
import ErrorHandler from '../../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'

const MnadaLocation = (props: any) => {
  const {mnadaAdded, setMnadaAdded, isMnadaAdded, setIsMnadaAdded} = props
  const intl = useIntl()
  const warehouseId = localStorage.getItem('wId')

  const [search, setSearch] = useState('')
  const [fetching, setFetching] = useState(true)
  const [id, setId] = useState('')
  const [buttonHandler, setButtonHandler] = useState('Submit')
  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [records, setRecords] = useState([])
  const [currentOffset, setCurrentOffset] = useState(0)
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [locationName, setLocationName] = useState('')
  const [limit] = useState(tenantConfiguration.limit)
  const [selectedAddress, setSelectedAddress] = useState<string>('')
  const [placeId, setPlaceId] = useState<string>('')
  const [latitudeLongitude, setLatitudeLongitude] = useState(initialLatLong)
  const [disableLat, setDisableLat] = useState(false)
  const [disableLong, setDisableLong] = useState(false)
  const [postalCode, setPostalCode] = useState<string>('')
  const [checked, setChecked] = useState(true)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [validation, setValidation] = useState({
    locationName: '',
    address: '',
    latitude: '',
    longitude: '',
  })

  const debounceSearch = useDebounce(search, 500)
  const state = useExternalScript(googleApi)

  const fetchingRecords = async () => {
    setFetching(true)
    const url = `${apiBaseURL()}warehouse/mnada_locations?warehouse_id=${warehouseId}&limit=${10}&offset=${currentOffset}&search=${debounceSearch}`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setRecords(response.results)
      setCount(response.count)
    }

    setFetching(false)
  }

  const fetchSingleRecords = async (id: string) => {
    setId(id)
    setIsMnadaAdded(true)
    setModalLoading(true)
    const url = `${apiBaseURL()}warehouse/mnada_locations/${id}/details`
    const response = await getRequest(url, true)

    setLocationName(response.name)
    setSelectedAddress(response.address)
    setLatitudeLongitude({
      latitude: response.latitude,
      longitude: response.longitude,
    })

    onLatLongSelect({
      latitude: response.latitude,
      longitude: response.longitude,
    })

    setChecked(response.is_active)

    setModalLoading(false)
  }

  const submit = async () => {
    if (isValid()) {
      setButtonHandler('Please Wait')

      const body = {
        name: locationName,
        address: selectedAddress,
        latitude: latitudeLongitude.latitude,
        longitude: latitudeLongitude.longitude,
        is_active: checked,
      }

      if (isEmpty(id)) {
        const url = `${apiBaseURL()}warehouse/${warehouseId}/mnada_locations/create`

        const response = await postRequest(url, body, true)

        if (response.status === 200) {
          setMnadaAdded(true)
          setIsAlert(true)
          setMessage('mnada Location is added successfully')
          isClear()
          fetchingRecords()
        } else {
          handleValidation(response)
        }

        setButtonHandler('Submit')
      } else {
        const url = `${apiBaseURL()}warehouse/mnada_locations/${id}/update`

        const response = await patchRequest(url, body, true)

        if (response.status === 200) {
          setIsAlert(true)
          setMessage('mnada Location is updated successfully')
          isClear()
          fetchingRecords()
        } else {
          handleValidation(response)
        }

        setButtonHandler('Submit')
      }
    }
  }

  const isValid = () => {
    let valid = true
    let updatedValidation = {...validation}

    if (isEmpty(locationName.trim())) {
      valid = false
      updatedValidation.locationName = 'is-invalid'
    }

    if (isEmpty(selectedAddress.trim())) {
      valid = false
      updatedValidation.address = 'is-invalid'
    }

    if (!isValidLatitude(latitudeLongitude.latitude)) {
      updatedValidation.latitude = 'is-invalid'
      valid = false
    }

    if (!isValidLongitude(latitudeLongitude.longitude)) {
      updatedValidation.longitude = 'is-invalid'
      valid = false
    }

    setValidation(updatedValidation)

    return valid
  }

  const handleValidation = (response: any) => {
    if (!isEmpty(response.status) && response.status === 400) {
      if (
        !isEmpty(response.data) &&
        !isEmpty(response.data.error) &&
        !isEmpty(response.data.error.field)
      ) {
        const field = response.data.error.field
        switch (field) {
          case 'name':
            setErrorMessage('Location name is required.')
            break
          case 'address':
            setErrorMessage('Address is required.')
            break

          case 'mnada_location_already_exists':
            setErrorMessage('Mnada Location is already exists')
            break

          case 'latitude':
            setErrorMessage('Latitude is required.')
            break

          case 'longitude':
            setErrorMessage('Longitude is required.')
            break
        }
      }
    }
  }

  const onLatLongSelect = (e: any) => {
    if (e.latitude) {
      setDisableLat(true)
    }

    if (e.longitude) {
      setDisableLong(true)
    }
  }

  const onChange = (address: any) => {
    setDisableLong(false)
    setDisableLat(false)

    if (isEmpty(address)) {
      setValidation({...validation, address: 'is-invalid'})
    } else {
      setValidation({...validation, address: '', latitude: '', longitude: ''})
    }
  }

  const isClear = () => {
    setDisableLong(false)
    setDisableLat(false)
    setId('')
    setButtonHandler('Submit')
    setIsMnadaAdded(false)
    setErrorMessage('')
    setLocationName('')
    setSelectedAddress('')
    setLatitudeLongitude({
      latitude: '',
      longitude: '',
    })
    setChecked(true)
    setValidation({
      locationName: '',
      address: '',
      latitude: '',
      longitude: '',
    })
    setTimeout(() => {
      setIsAlert(false)
      setMessage('')
    }, 2000)
  }

  useEffect(() => {
    fetchingRecords()
  }, [currentOffset, debounceSearch])

  return (
    <>
      <div>
        <EATitle title='Mnada Location - ' />
        <PageTitle breadcrumbs={[{title: 'warehouse'}]}>
          {intl.formatMessage({id: 'Mnada Location'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => {
            setIsAlert(false)
          }}
          show={alert}
          message={message}
        />

        <div className='card'>
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              isDisable={fetching}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                <button
                  className='btn btn-primary'
                  disabled={fetching}
                  onClick={() => {
                    setIsMnadaAdded(true)
                  }}
                >
                  Add Mnada Location
                </button>
              </div>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {fetching ? (
                <FetchingRecords />
              ) : records.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='py-2'>Location Name</th>
                      <th className='py-2'>Address</th>
                      <th className='py-2'>Status</th>
                      <th className='py-2'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.length > 0 &&
                      records.map((item: any) => {
                        return (
                          <tr>
                            <td>{isEmpty(item.name) ? '-' : item.name}</td>
                            <td>{isEmpty(item.address) ? '-' : item.address}</td>
                            <td>
                              {isEmpty(item.is_active) ? (
                                '-'
                              ) : (
                                <>
                                  {(() => {
                                    switch (item.is_active) {
                                      case true:
                                        return (
                                          <span className='badge badge-light-primary text-dark'>
                                            Active
                                          </span>
                                        )

                                      case false:
                                        return (
                                          <span className='badge badge-light-success text-dark'>
                                            Inactive
                                          </span>
                                        )
                                    }
                                  })()}
                                </>
                              )}
                            </td>
                            <td>
                              <div
                                onClick={() => {
                                  fetchSingleRecords(item.id)
                                }}
                                className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm cursor-pointer me-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='ic mr-0'
                                />
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <CustomPaginate
          data={records}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={fetching}
          saveFilterName={'feedbackReport'}
          filterStates={''}
        />
      </div>

      <>
        <Modal show={isMnadaAdded} centered backdrop>
          <Modal.Header
            closeButton
            onClick={() => {
              isClear()
            }}
          >
            <Modal.Title> {isEmpty(id) ? 'Add' : 'Edit'} Mnada Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isModalLoading ? (
              <FetchingRecords />
            ) : (
              <div className='row'>
                <div className='col-md-12 mb-4'>
                  <label className='form-label'>
                    Location Name<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    value={locationName}
                    onChange={(e) => {
                      setLocationName(e.target.value)

                      if (isEmpty(e.target.value)) {
                        setValidation({...validation, locationName: 'is-invalid'})
                      } else {
                        setValidation({...validation, locationName: ''})
                      }
                    }}
                    className={clsx('form-control', validation.locationName)}
                    placeholder='Type here...'
                  />
                </div>

                <div className='col-md-12 mb-4'>
                  {state === 'ready' && (
                    <>
                      <label className='form-label'>
                        Address<span className='text-danger'>*</span>
                      </label>
                      <PlaceSearch
                        validationClass={validation.address}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddress={selectedAddress}
                        setLatitudeLongitude={setLatitudeLongitude}
                        setPostalCode={setPostalCode}
                        setPlaceId={setPlaceId}
                        addressDisable={false}
                        onLatLongSelect={onLatLongSelect}
                        onChange={onChange}
                      />
                    </>
                  )}
                </div>

                <div className='col-md-6 mb-4'>
                  <label className='form-label'>
                    Latitude<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    value={latitudeLongitude.latitude}
                    onChange={(e) => {
                      setDisableLat(false)
                      setLatitudeLongitude({...latitudeLongitude, latitude: e.target.value})

                      if (isEmpty(e.target.value)) {
                        setValidation({...validation, latitude: 'is-invalid'})
                      } else {
                        setValidation({...validation, latitude: ''})
                      }
                    }}
                    disabled={disableLat}
                    className={clsx('form-control', validation.latitude)}
                    placeholder='Type here...'
                  />
                </div>
                <div className='col-md-6 mb-4'>
                  <label className='form-label'>
                    Longitude<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    value={latitudeLongitude.longitude}
                    onChange={(e) => {
                      setDisableLong(false)
                      setLatitudeLongitude({...latitudeLongitude, longitude: e.target.value})

                      if (isEmpty(e.target.value)) {
                        setValidation({...validation, longitude: 'is-invalid'})
                      } else {
                        setValidation({...validation, longitude: ''})
                      }
                    }}
                    disabled={disableLong}
                    className={clsx('form-control', validation.longitude)}
                    placeholder='Type here...'
                  />
                </div>

                <div className='col-md-12 mb-4'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <label className='form-check-label me-3 ms-0'>Access Status</label>
                    <input
                      checked={checked}
                      id='accessStatus'
                      onChange={(e) => {
                        setChecked(!checked)
                      }}
                      className='form-check-input h-30px w-50px'
                      type='checkbox'
                      name='layout-builder[layout][header][fixed][desktop]'
                      defaultChecked={true}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className='mb-3'>
              <ErrorHandler errorMessage={errorMessage} />
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{
              alignItems: 'center',
            }}
          >
            <button
              onClick={() => {
                isClear()
              }}
              className='btn btn-light'
              disabled={buttonHandler !== 'Submit' ? true : false}
            >
              Cancel
            </button>
            <button
              disabled={buttonHandler !== 'Submit' ? true : false}
              onClick={() => submit()}
              className='btn btn-primary'
            >
              {buttonHandler !== 'Submit' ? 'Please Wait' : 'Submit'}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export default MnadaLocation
