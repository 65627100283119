import React, {useEffect, useRef} from 'react'
import {KTSVG } from '../../../../../_eaFruitsDms/helpers'
import { permissionEditDelete, permissionEditRead, permissionEditWrite} from '../../../../../_eaFruitsDms/apiFunctions/rolesAndPermission/RoleAndPermission'

const TableRolesPermission = (props: any) => {

    const checkedPermissionRead = (checkedRead: any, rolesIdRead: any) => {
        permissionEditRead(checkedRead, rolesIdRead)
    }

    const checkedPermissionWrite = (checkedWrite: any, rolesIdWrite: any) => {
        permissionEditWrite(checkedWrite, rolesIdWrite)
    }             
    
    const checkedPermissionDelete = (checkedDelete: any, rolesIdDelete: any) => {
        permissionEditDelete(checkedDelete, rolesIdDelete)
    }   
           
    const truncate = (str: any) => {
        return str.length > 10 ? str.substring(0, 11) + "..." : str;
    }

    const scrl: any = useRef();

    const slide = (shift: any) => {
        // scrl.current.scrollLeft += shift;
        console.log("shift == ", shift);
        const scroll: any = document.getElementById('smoothscrolling');
        scroll.scrollLeft += 50;

    };

    return (
        <>
            <div>
                {/* <button onClick={() => slide('')} >  click</button> */}

                <table className='table table-row-bordered align-middle gs-0 gy-2 scrolling-table'>
                    <thead>
                        <tr className=' text-muted fw-500'>
                            <th className='min-w-250px py-2'>Feature Access</th>
                            {props.getRolesList.map((item: any) => (
                                    <>
                                        <th key={item.id} className='min-w-150px py-2'>
                                            {truncate(item.name)}
                                            {props.showWrite && <span
                                                data-bs-toggle='modal'
                                                data-bs-target='#ea_modal_edit_role'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                                onClick={() => props.getPropsEdit(item)}
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0'
                                                />
                                            </span>}
                                        </th>
                                    </>
                                ))
                             }
                        </tr>
                    </thead>
                    <tbody>
                        {props.tableList.map((main_item: any) => {
                            let featureId = main_item.feature_id
                            return (
                                <tr key={main_item.feature_id}>
                                    <td>{main_item.display_name}</td>
                                        {main_item.roles.map((roles_item: any) => {
                                              
                                        let rolesIdRead = ""
                                        let rolesIdWrite = ""
                                        let rolesIdDelete = ""
                                        let read = false
                                        let write = false
                                        let del = false

                                        roles_item.role_feature_permissions.length > 0 && roles_item.role_feature_permissions.map((item : any) =>{
                                           if(item.permission_type === "read"){
                                                read  = item.value
                                                rolesIdRead = item.role_feature_permissions_id
                                            }
                                           if(item.permission_type === "write"){
                                                write  = item.value
                                                rolesIdWrite = item.role_feature_permissions_id
                                            }
                                            if(item.permission_type === "delete"){
                                                del  = item.value
                                                rolesIdDelete = item.role_feature_permissions_id
                                            }
                                        })

                                        let checkedRead: any;
                                        let checkedWrite: any;
                                        let checkedDelete: any;
                                        let checkboxUniqId = featureId + "_" + rolesIdRead 
  
                                        return (
                                            <td className='' key={roles_item.id}>
                                            <>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                    <input disabled={props.showWrite  ? false : true} id={checkboxUniqId + "_read"} className='form-check-input widget-13-check me-1' type='checkbox'
                                                        defaultChecked={read}
                                                        checked={checkedRead}
                                                        onChange={(e) => {
                                                            checkedPermissionRead(e.target.checked, rolesIdRead)
                                                            checkedRead = e.target.checked
                                                        }}
                                                    />
                                                    <label htmlFor={checkboxUniqId + "_read"} className="form-check-label" >Read</label>
                                                </div>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                    <input
                                                        disabled={!main_item.feature_name.includes("impersonation") && props.showWrite ? false : true}
                                                        checked={checkedWrite}
                                                        defaultChecked={write}
                                                        onChange={(e) => {
                                                            checkedPermissionWrite(e.target.checked, rolesIdWrite)
                                                            checkedWrite = e.target.checked
                                                        }}
                                                        id={checkboxUniqId + "_write"}
                                                        className='form-check-input widget-13-check me-1' type='checkbox' />
                                                    <label htmlFor={checkboxUniqId + "_write"} className="form-check-label" >Write</label>
                                                </div>
                                                <div
                                                    className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                    <input
                                                        disabled={!main_item.feature_name.includes("impersonation") && props.showWrite ? false : true}
                                                        checked={checkedDelete}
                                                        defaultChecked={del}
                                                        onChange={(e) => {  
                                                            checkedPermissionDelete(e.target.checked, rolesIdDelete)
                                                            checkedDelete = e.target.checked
                                                        }}
                                                        id={checkboxUniqId + "_delete"}
                                                        className='form-check-input widget-13-check me-1' type='checkbox' />
                                                    <label htmlFor={checkboxUniqId + "_delete"} className="form-check-label" >Delete</label>
                                                </div>
                                            </>
                                            </td>
                                        )
                                    }
                                    )}
                                </tr>
                            )
                        })}
                    </tbody>

                </table>
            </div>
        </>
    )
}

export default TableRolesPermission
