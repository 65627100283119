import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  dateTimeFormat,
  fetchStaticAsyncDropdown,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import usePermission from '../../../../_eaFruitsDms/helpers/components/usePermission'
import {
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from '../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import Message from '../components/message'
import Overview from '../components/overview'
import MobileNumberInput from '../../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput'
import tenantConfiguration from '../../../../TenantVariables'

const ComplaintsDetails = () => {
  const location: any = useLocation()
  const navigate = useNavigate()

  const status = [
    {
      label: 'Resolved Via Phone Call',
      value: 'resolved_via_phone_call',
    },
  ]

  const initialSeconds = 60

  const [complaintsId] = useState(localStorage.getItem('complaints-detail-id') || '')
  const [fetchingRecords, setFetchingRecords] = useState(true)
  const [records, setRecords] = useState<any>({})
  const [showAddComment, setShowAddComment] = useState(false)
  const [reason, setReason] = useState<any>(
    status.find((i) => i.value === 'resolved_via_phone_call')
  )
  const [submit, setSubmit] = useState('Submit')
  const [reply, setReply] = useState('')
  const [replyValid, setReplyValid] = useState('')
  const [message, setMessage] = useState('')
  const [alert, setIsAlert] = useState(false)
  const [type, setType] = useState('')
  const [file, setFile] = useState<any>(null)
  const [fileError, setFileError] = useState<any>('')
  const [isFileEmpty, setFileEmpty] = useState<any>(false)
  const [otp, setOtp] = useState<any>('')
  const [sentOtp, setSentOtp] = useState<any>(false)
  const [otpError, setOtpError] = useState<any>('')
  const [otpBlank, setOtpBlank] = useState<any>('')
  const [otpSuccess, setOtpSuccess] = useState<any>('')
  const [seconds, setSeconds] = useState<number>(initialSeconds)
  const [startTimer, setStartTimer] = useState<boolean>(false)

  const permission = usePermission('complaint_request')
  const initialPermission = {read: true, write: true, delete: true}

  useEffect(() => {
    if (!isEmpty(complaintsId)) {
      fetchComplaintsDetails()
    }
  }, [complaintsId])

  const fetchComplaintsDetails = async () => {
    setFetchingRecords(true)
    const url = `${apiBaseURL()}complaint_request/detail-complaint/${complaintsId}`
    const response = await getRequest(url, true)

    if (response.result) {
      setRecords(response.result)
      setFetchingRecords(false)
    }
  }

  const clear = () => {
    setSubmit('Submit')
    setReply('')
    setReplyValid('')
    setShowAddComment(false)
    setType('')
    setFile(null)
    setFileError('')
    setFileEmpty(false)
    setReason(status.find((i) => i.value === 'resolved_via_phone_call'))
    setOtpError('')
    setOtpSuccess('')
    setOtp('')
    setSentOtp(false)
    setSeconds(initialSeconds)
    setStartTimer(false)
    setOtpBlank('')
  }

  const checkValidation = () => {
    let valid = true

    if (isEmpty(reply.trim()) && type !== 'resolved') {
      valid = false
      setReplyValid('is-invalid')
    }

    if (type === 'resolved') {
      if (isEmpty(file)) {
        valid = false
        setFileEmpty(true)
      }

      if (!isEmpty(fileError)) {
        valid = false
        setFileError('Please select a valid audio file (up to 12 MB).')
      }
    }

    return valid
  }

  const onSubmit = async (type: any) => {
    const valid = checkValidation()

    if (type === 'closed') {
      setOtpError('')
      setOtpSuccess('')

      if (sentOtp) {
        if (!isEmpty(otp)) {
          setSubmit('Please Wait')

          const url = `${apiBaseURL()}complaint_request/complaint-closure/${complaintsId}/verify-otp`

          const body = {
            otp: Number(otp),
          }

          const response = await patchRequest(url, body, true)

          if (response.status === 200) {
            setSubmit('Submit')
            clear()
            fetchComplaintsDetails()
            setIsAlert(true)
            setTimeout(() => {
              setIsAlert(false)
            }, 2000)
            setMessage('Complaint closed successfully.')
          } else {
            setSubmit('Submit')
            setOtpError('The OTP you entered is incorrect.')
          }
        } else {
          setOtpBlank('is-invalid')
        }

        return
      }

      sentOtpToCustomer()
    }

    if (valid) {
      if (type === '' || type === 'resolved') {
        setSubmit('Please Wait')

        const url = `${apiBaseURL()}complaint_request/create-complaint-comment`

        if (type === 'resolved') {
          const formData = new FormData()

          formData.append('complaint_request_id', complaintsId)

          formData.append('resolution_type', reason?.value)

          !isEmpty(reply) && formData.append('message', reply)

          if (!isEmpty(file)) {
            Object.entries(file).map(([key, value]: any) => {
              formData.append(`message_documents[${key}]document_name`, value)
              formData.append(`message_documents[${key}]mime_type`, value.type)
            })
          }

          const response = await postRequest(url, formData, true)

          if (response.status === 201) {
            handleStatus('resolved')

            setIsAlert(true)

            setTimeout(() => {
              setIsAlert(false)
            }, 2000)

            setMessage('Your complaint has been successfully marked as resolved.')

            navigate('/all-complaints-details/comments')
          }
        } else {
          const body = {
            complaint_request_id: complaintsId,
            message: reply,
          }

          const response = await postRequest(url, body, true)

          if (response.status === 201) {
            clear()
            setIsAlert(true)
            setTimeout(() => {
              setIsAlert(false)
            }, 2000)
            setMessage('Your complaint added successfully.')
            navigate('/all-complaints-details/comments')
          }
        }

        setSubmit('Submit')
      } else {
        handleStatus(type)
      }
    }
  }

  const sentOtpToCustomer = async () => {
    setOtpError('')
    setOtpSuccess('')

    setSubmit('Please Wait')

    const url = `${apiBaseURL()}complaint_request/complaint-closure/send-otp`

    const body = {
      complaint_request_id: complaintsId,
    }

    const response = await postRequest(url, body, true)

    if (response.status === 200) {
      setSubmit('Submit')
      setSentOtp(true)
      setSeconds(initialSeconds)
      setStartTimer(true)

      const prefix = tenantConfiguration.baseCountryCode

      setOtpSuccess(
        `An OTP has been dispatched to the number ${
          isEmpty(records?.customer?.phone_no) ? '-' : `${prefix} ${records?.customer?.phone_no}`
        }.`
      )
    } else {
      setSubmit('Submit')
      setOtpError(`You can request a new OTP only after ${initialSeconds} seconds.`)
    }
  }

  const handleStatus = async (status: string, openModal?: boolean) => {
    if (openModal) {
      setShowAddComment(true)
      setType(status)
      return
    }

    setSubmit('Please Wait')

    const url = `${apiBaseURL()}complaint_request/update-complaint-status/${complaintsId}`

    const body = {
      status: status,
      note: isEmpty(reply) ? null : reply,
    }

    await patchRequest(url, body, true)
    fetchComplaintsDetails()
    clear()
  }

  const onChange = (e: any) => {
    const allowedExtensions = [
      'audio/mpeg', // .mp3
      'audio/wav', // .wav
      'audio/ogg', // .ogg
      'audio/x-wav', // .wav alternative
      'audio/x-m4a', // .m4a
      'audio/flac', // .flac
      'audio/aac', // .aac
      'audio/webm', // .webm
    ]

    const maxFileSize = 25 * 1024 * 1024

    let valid = true

    Object.entries(e.target.files).map(([key, value]: any) => {
      if (!allowedExtensions.includes(value.type) || value.size > maxFileSize) {
        valid = false
      }
    })

    if (valid) {
      setFile(e?.target?.files ?? null)
      setFileError('')
    } else {
      setFileError('Please select a valid audio file (up to 25 MB).')
    }
  }

  const fetchReason = (search?: string) => {
    return fetchStaticAsyncDropdown(status, search)
  }

  useEffect(() => {
    if (startTimer) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }

        if (seconds === 0) {
          setStartTimer(false)
        }
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [seconds, startTimer])

  return (
    <>
      <EATitle title='Complaints' />

      <PageTitle
        breadcrumbs={[
          {
            title: 'Complaints',
            subTitle: 'Complaints',
          },
        ]}
      >
        Complaints
      </PageTitle>

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      {fetchingRecords ? (
        <FetchingRecords />
      ) : (
        <div className='card mb-4 mb-xl-5'>
          <div className='card-body pt-5 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <span className='text-dark font-20 fw-bold me-3'>
                        {isEmpty(records.ref_no)
                          ? '-'
                          : `#${records.ref_no} ${isEmpty(records.title) ? '' : records.title}`}
                      </span>
                      {isEmpty(records.status) ? (
                        ''
                      ) : (
                        <>
                          {records.status === 'open' && (
                            <span className='badge badge-light-warning text-dark'>Open</span>
                          )}

                          {records.status === 'resolved' && (
                            <span className='badge badge-light-primary text-dark'>Resolved</span>
                          )}

                          {records.status === 'closed' && (
                            <span className='badge badge-light-success text-dark'>Closed</span>
                          )}

                          {records.status === 'in_process' && (
                            <span className='badge badge-light-danger text-dark'>In Progress</span>
                          )}
                        </>
                      )}
                    </div>

                    <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                      <span className='d-flex align-items-center me-6 mb-2'>
                        <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='me-1' />
                        {isEmpty(records.customer) || isEmpty(records.customer.business_name)
                          ? '-'
                          : records.customer.business_name}
                      </span>

                      <span className='d-flex align-items-center me-5 mb-2 '>
                        <KTSVG path='/media/icons/duotune/general/gen045.svg' className='me-1' />
                        {isEmpty(records.assign_at) ? '-' : dateTimeFormat(records.assign_at)}
                      </span>
                    </div>
                  </div>

                  {records.status !== 'closed' ? (
                    (checkUserType(['wm']) ? permission.write : initialPermission.write) && (
                      <div>
                        {records.status === 'open' && !checkUserType(['ts', 'sa']) && (
                          <button
                            onClick={() => {
                              handleStatus('in_process')
                            }}
                            className='btn btn-light me-3'
                          >
                            Mark as In Progress
                          </button>
                        )}

                        {records.status === 'in_process' && !checkUserType(['ts', 'sa']) && (
                          <button
                            onClick={() => {
                              handleStatus('resolved', true)
                            }}
                            className='btn btn-light me-3'
                          >
                            Mark as Resolved
                          </button>
                        )}

                        <button
                          onClick={() => {
                            setShowAddComment(true)
                          }}
                          className='btn btn-primary me-2'
                          type='button'
                        >
                          Add Comment
                        </button>

                        {checkUserType(['ts']) && records.status === 'resolved' && (
                          <button
                            className='btn btn-danger'
                            type='button'
                            onClick={() => {
                              handleStatus('closed', true)
                            }}
                          >
                            Close Complaint
                          </button>
                        )}
                      </div>
                    )
                  ) : (
                    <div>
                      <button
                        onClick={() => {
                          setShowAddComment(true)
                        }}
                        className='btn btn-primary me-2'
                        type='button'
                      >
                        Add Comment
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex overflow-auto h-40px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/all-complaints-details/overview' && 'active')
                    }
                    to='/all-complaints-details/overview'
                  >
                    Overview
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/all-complaints-details/comments' && 'active')
                    }
                    to={`/all-complaints-details/comments`}
                  >
                    Comments
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <Routes>
        <Route
          path='overview'
          element={<Overview records={records} fetchingRecords={fetchingRecords} />}
        />
        <Route path='comments' element={<Message loading={fetchingRecords} alert={alert} />} />
      </Routes>

      <>
        <Modal
          show={showAddComment}
          onHide={clear}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
          className='complaint-modal'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>
                {type === 'resolved' && 'Mark as Resolved'}
                {type === 'closed' && 'Close Complaint'}
                {type === '' && 'Add Comment'}
              </h2>
            </Modal.Title>
            <div className='btn p-1 mh-24 btn-active-light-primary ms-2' onClick={clear}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            {type === 'closed' ? (
              <>
                <div className='row'>
                  <div className='col-sm-12 mb-4'>
                    <label className='form-label'>Customer Name</label>
                    <input
                      type='text'
                      placeholder='Type here'
                      className={`form-control`}
                      disabled
                      value={
                        isEmpty(records?.customer?.business_name)
                          ? '-'
                          : records?.customer?.business_name
                      }
                      name='customer_business_name'
                    />
                  </div>

                  <div className='col-sm-12 mb-4'>
                    <MobileNumberInput
                      validation={''}
                      setValidation={() => {}}
                      numberValue={
                        isEmpty(records?.customer?.phone_no)
                          ? '-'
                          : ` ${records?.customer?.phone_no}`
                      }
                      setNumberValue={() => {}}
                      isOptional
                      contactNoVerify={true}
                    />
                  </div>

                  {sentOtp && (
                    <>
                      <div className='col-sm-12 mb-4'>
                        <label className='form-label'>Please Enter OTP</label>
                        <input
                          type='text'
                          placeholder='Type here'
                          className={`form-control ${otpBlank}`}
                          value={otp}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/
                            if (e.target.value === '' || re.test(e.target.value)) {
                              setOtp(e.target.value)
                              setOtpError('')
                              setOtpSuccess('')
                              setOtpBlank('')
                            }
                          }}
                          name='customer_otp'
                        />

                        <div className='col-md-12 p-0 d-flex flex-row-reverse mt-1'>
                          {seconds === 0 ? (
                            <a
                              href='JAVASCRIPT:void(0)'
                              onClick={() => {
                                sentOtpToCustomer()
                              }}
                            >
                              Resend Code
                            </a>
                          ) : (
                            <p className='text-primary disabled opacity-50 mb-0'>
                              Resend Code in 00:{seconds < 10 ? '0' + seconds : seconds}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  {!isEmpty(otpSuccess) && <span className='text-primary'>{otpSuccess}</span>}

                  {!isEmpty(otpError) && <ErrorHandler errorMessage={otpError} />}
                </div>
              </>
            ) : (
              <>
                {type === 'resolved' && (
                  <>
                    <div className=''>
                      <label className='form-label'>How did you resolve the complaint?</label>
                      <AsyncPaginate
                        loadOptions={fetchReason}
                        isDisabled
                        placeholder='Select'
                        isSearchable
                        className={clsx('react-select-container')}
                        classNamePrefix='react-select'
                        menuPosition='fixed'
                        value={reason}
                        onChange={(e: any) => {
                          setReason(e)
                        }}
                        name='reason'
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#0c78a4',
                          },
                        })}
                      />
                    </div>

                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='my-4 position-relative'>
                          <label className='form-label me-2'>
                            Attachment<span className='text-danger'>*</span>
                          </label>
                          <label
                            htmlFor='attachment-cl'
                            className={clsx(
                              'form-control file-input text-truncate pe-6',
                              !isEmpty(fileError) || isFileEmpty ? 'is-invalid' : ''
                            )}
                          >
                            <span className='text-muted'>
                              {!isEmpty(file) && Object.entries(file).length > 0
                                ? Object.entries(file).map(([key, value]: any) => {
                                    return <div>{value?.name}</div>
                                  })
                                : 'Browse'}
                            </span>
                          </label>
                          <input
                            type='file'
                            id='attachment-cl'
                            accept='audio/*' // This will accept all audio file types
                            name='audio-attachment-cl'
                            onChange={onChange}
                            className={clsx('form-control')}
                            multiple
                          />

                          {!isEmpty(fileError) && <ErrorHandler errorMessage={fileError} />}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className='row'>
                  <div className='col-sm-12'>
                    {type === 'resolved' && <label className='form-label me-2'>Description</label>}

                    <textarea
                      className={`form-control ${replyValid}`}
                      rows={4}
                      placeholder='Type here…'
                      name='reply'
                      value={reply}
                      onChange={(e: any) => {
                        setReply(e.target.value)
                        setReplyValid('')
                      }}
                    ></textarea>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={clear}
                disabled={submit !== 'Submit'}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary'
                onClick={() => onSubmit(type)}
                disabled={submit !== 'Submit'}
              >
                {type === 'closed' ? (sentOtp ? 'Submit' : 'Send OTP') : submit}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export default ComplaintsDetails
