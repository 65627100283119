import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  addCustomerTags,
  customerTagsDelete,
  customerTagsList,
  customerTagsUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import {
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
} from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"

type Props = {
  className: string
}
const CustomerTags: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Customer Tags',
  }
  const [getList, setGetList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [EditId, setEditId] = useState<any>('')
  const [getTagNameEdit, setTagNameEdit] = useState<any>([])
  const [TagName, setTagName] = useState<any>('')
  const [DeleteId, setDeleteId] = useState<any>('')
  const [ValidationEdit, setValidationEdit] = useState<any>('')
  const [Validation, setValidation] = useState<any>('')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [errorMessage, setErrorMessage] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  useEffect(() => {
    fetchPermission()
    TagList()
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_customers")
    setPermission(res)
   }
 
  const TagList = () => {
    setListLoader(true)
    customerTagsList('10', '0', '').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)

      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      customerTagsList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      customerTagsList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)  
      customerTagsList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
      setListLoader(false)
      })
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)
      customerTagsList('10', '0', e.target.value).then((res) => {
        setGetList(res.data)

        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setListLoader(false)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
      })
    }, 500)

    setTimer(newTimer)
  }

  // Develop BY: Kriti
  // Description: Add Customer tag function

  const AddFunction = () => {
    let navigateField = {
      TagName: TagName,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (TagName === '') {
      setValidation('is-invalid')
    } else {
      setValidation('')
    }
    if (TagName !== '') {
      AddFunctionApi()
    }
  }

  const clearFunctionAdd = () => {
    setValidation('')
    setErrorMessage('')
    setTagName('')

    $('#tableId')
      .find('input[type=number]')
      .each(function () {
        var defaultVal = $(this).data('default')
        $(this).val(defaultVal)
      })
  }

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    addCustomerTags(TagName).then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunctionAdd()
        setListLoader(true)
        customerTagsList('10', '0', '').then((res) => {
          setGetList(res.data)

          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setListLoader(false)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
        })

        $('#closeAddEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      // if (notify.failed) {
      // }
      if (notify.errorField == 'tag_name') {
        setErrorMessage(
          'Your added Customer tag name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  // Develop BY: Kriti
  // Description: Edit Customer tag function

  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setTagNameEdit(item.tag_name)
    setEditId(item.id)
    setModalLoader(false)
  }

  const EditFunction = () => {
    let navigateField = {
      getTagNameEdit: getTagNameEdit,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (getTagNameEdit === '') {
      setValidationEdit('is-invalid')
    } else {
      setValidationEdit('')
    }
    if (getTagNameEdit !== '') {
      EditFunctionApi()
    }
  }
  const clearFunctionEdit = () => {
    setValidationEdit('')
    setErrorMessage('')

    $('#tableId')
      .find('input[type=number]')
      .each(function () {
        var defaultVal = $(this).data('default')
        $(this).val(defaultVal)
      })
  }

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    customerTagsUpdate(getTagNameEdit, EditId).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
        setListLoader(true)
        customerTagsList('10', '0', search).then((res) => {
          setGetList(res.data)

          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setListLoader(false)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
        })
        $('#closeEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'tag_name') {
        setErrorMessage(
          'Your added Customer Tags name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  const onDelete = (item: any) => {
    setDeleteId(item.id)
    // api call
    customerTagsDelete(DeleteId)
    TagList()
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Customer Tags' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({ id: 'Customer Tags' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? 'Your customer tags has been added successfully.'
            : 'Your customer tags has been edited successfully.'
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            {/* <CustomSearchBar /> */}
            <div className='col-12 col-md-auto mt-0'>
              <input
                type='text'
                placeholder='Search'
                className='form-control form-search'
                onChange={searchList}
              />
            </div>
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
              {permission.write && (
                <EaButton
                  isModal={true}
                  dataTarget='#ea_modal_create_tag'
                  btnName='Add Tag'
                  onClick={() => {
                    setMessageHandler('successAdd')
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {/* <div className='table-responsive'> */}
          {/* begin::Table */}
          {/* <TableTags /> */}
          {getList.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered align-middle gs-0 gy-2' id='tableId'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className=' text-muted fw-500'>
                      <th className='min-w-100px py-2'>Customer Tags</th>
                      <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {getList.map((item: any) => {
                      return (
                        <tr>
                          <td>{item.tag_name}</td>
                          <td className='text-end'>
                            {permission.write && (
                              <a
                                href='javascript:void(0)'
                                data-bs-toggle='modal'
                                data-bs-target='#ea_modal_edit_tag'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                onClick={(event) => {
                                  onChangeAdd(item)
                                  setMessageHandler('successEdit')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='ic mr-0'
                                />
                              </a>
                            )}
                            {/* {permission.delete && (
                              <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                onClick={(event) => {
                                  onDelete(item)
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='ic mr-0'
                                />
                              </a>
                            )} */}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
          {/* end::Table */}
          {/* </div> */}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
              } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>

      {/* edit Customer tag */}
      <EaModal
        id='ea_modal_edit_tag'
        modalTitle='Edit Customer Tags'
        onSubmit={EditFunction}
        closeAuto='closeEditEaModalBrand'
        actionBtnName={isLoadingEdit}
        cancel={clearFunctionEdit}
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Tag Name<span className="text-danger">*</span>
          </label>

          <input
            type='text'
            id='getTagNameEdit'
            className={clsx('form-control', ValidationEdit)}
            placeholder='Type here...'
            name='tag_name'
            value={getTagNameEdit}
            onChange={(e) => {
              setTagNameEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidationEdit(validation)
            }}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>

      {/* added Customer tag */}
      <EaModal
        id='ea_modal_create_tag'
        modalTitle='Add Customer Tags'
        onSubmit={AddFunction}
        closeAuto='closeAddEaModalBrand'
        // cancel={() => setValidation('')}
        cancel={clearFunctionAdd}
        actionBtnName={isLoadingAdd}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Tag Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='TagName'
            className={clsx('form-control', Validation)}
            placeholder='Type here...'
            onChange={(e) => {
              setTagName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidation(validation)
            }}
            value={TagName}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}

export default CustomerTags
