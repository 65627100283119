import moment from 'moment'
import {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../TenantVariables'
import {getRequest} from '../../https/apiCall'
import {apiBaseURL, isEmpty} from '../CommonHelpers'

interface Props {
  user: any
  handleFilterChange: any
  isDisabled: boolean
  className?: string
  type?: string
  allUser?: boolean
  warehouseId?: string
  label?: string
}

export const UserTypeFilter = ({
  user,
  handleFilterChange,
  isDisabled,
  className,
  allUser = true,
  warehouseId,
  type,
  label,
}: Props) => {
  const [users, setUsers] = useState<any>([])
  const [offset, setOffset] = useState<number>(0)
  const [reset, setReset] = useState<any>(moment())

  const fetchUsers = async (search?: any) => {
    if (search !== '') {
      setOffset(0)
    }

    let options: any = []

    const userType = localStorage.getItem('user_type') || ''

    let waId: any = ['sa', 'wm', 'ts'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id') || ''

    if (!isEmpty(warehouseId)) {
      waId = warehouseId
    }

    const wId = isEmpty(waId) ? '' : waId

    const response = await getRequest(
      `${apiBaseURL()}auth/users/team/list?ordering=first_name&search=${search}&limit=${
        tenantConfiguration.limit
      }&offset=${offset}&ward__id=${isEmpty(wId) ? '' : wId}&type=${isEmpty(type) ? '' : type}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setOffset(newOffset)
      hasMore = true
    }

    if (allUser && users.length === 0) {
      options.push({
        label: label || 'All Users',
        value: '',
      })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: `${option?.first_name} ${option?.last_name}`,
          value: option.id,
        })
      })
    }

    if (users.length > 0) {
      setUsers(users.concat(options))
    } else {
      setUsers(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  useEffect(() => {
    setReset(moment())
    setOffset(0)
    setUsers([])
  }, [warehouseId, type])

  return (
    <>
      <AsyncPaginate
        key={reset}
        loadOptions={fetchUsers}
        isDisabled={isDisabled}
        isSearchable
        className={`react-select-container ${className ? className : 'my-1 me-3 w-190px'}`}
        classNamePrefix='react-select'
        placeholder='All Users'
        value={user}
        onChange={(e: any) => handleFilterChange(e, 'user')}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#0c78a4',
          },
        })}
      />
    </>
  )
}
