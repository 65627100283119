import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {AsyncPaginate} from 'react-select-async-paginate'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  dateFormat,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
  toAbsoluteUrl,
} from '../../../../../_eaFruitsDms/helpers'
import {useLocation, useNavigate} from 'react-router-dom'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import HorecaModal from './components/horecaModal'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../../../TenantVariables'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import useDebounce from '../../../../../_eaFruitsDms/helpers/components/useDebounce'
import {EaAlertSuccess} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import {EaStatisticsWidget} from '../../../../../_eaFruitsDms/partials/widgets'
import usePermission from '../../../../../_eaFruitsDms/helpers/components/usePermission'
import {WarehouseFilter} from '../../../../../_eaFruitsDms/helpers/components/WarehouseFilter'

const WMHorecaContract = (props: any) => {
  const {customer} = props
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()

  const customersHeaderTitle = {
    title: 'Customers',
    subTitle: 'HORECA Customers',
  }

  const [show, setShow] = useState(false)
  const [isCopyContract, setCopyContract] = useState('')
  const [records, setRecords] = useState<any>([])
  const [horecaContract, setHorecaContract] = useState<any>([])
  const [customerRecords, setCustomerRecords] = useState<any>([])
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [fetchingRecords, setFetchingRecords] = useState(true)
  const [fetchingContractRecords, setFetchingContractRecords] = useState(true)
  const [isLoading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [id, setId] = useState('')
  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState<any>({label: 'All HORECA Customers', value: ''})
  const [cId, setCustomerId] = useState('')
  const [meta, setMeta] = useState({
    active_contracts: 0,
    no_active_contracts: 0,
    pending_contracts: 0,
  })
  const [warehouse, setWarehouse] = useState<any>({label: 'All Warehouse', value: ''})

  const debounceSearch = useDebounce(search, 500)
  const permission = usePermission('customer_horeca_contracts')
  const warehouseId = !checkUserType(['sa', 'ts']) ? localStorage.getItem('w_id') : ''
  const customerId = localStorage.getItem('cId')

  const fetchHorecaCustomerRecords = async () => {
    setFetchingRecords(true)

    const url =
      apiBaseURL() +
      `customers/horeca?warehouse_id=${warehouse.value}&search=${debounceSearch}&limit=${limit}&ordering=-id&contract_status=${status.value}&offset=${currentOffset}`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setMeta(response.meta)
      setCustomerRecords(response.results)
      setCount(response.count)
    }

    setFetchingRecords(false)
  }

  const fetchContractRecords = async () => {
    setFetchingContractRecords(true)

    const id = isEmpty(customerId) ? '' : customerId

    const url =
      apiBaseURL() +
      `customers/contracts?warehouse_id=${warehouseId}&search=${debounceSearch}&limit=${limit}&ordering=-id&status=${status.value}&customer_id=${id}&offset=${currentOffset}`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setRecords(response.results)
      setCount(response.count)
    }

    setFetchingContractRecords(false)
  }

  const fetchHorecaContracts = async () => {
    setLoading(true)

    const id = isEmpty(cId) ? '' : cId

    const url =
      apiBaseURL() + `customers/contracts?ordering=-id&customer_id=${id}&is_drop_down=true`

    const response = await getRequest(url, true)

    if (response) {
      setHorecaContract(response)
    }

    setLoading(false)
  }

  const fetchStatus = async (search?: any) => {
    let options: any = [
      {label: 'All HORECA Customers', value: ''},
      {label: 'Active Contract Only', value: 'active'},
      {label: 'Expired Contract Only', value: 'expired'},
      {label: 'No Contract Yet', value: 'pending'},
    ]

    let hasMore: boolean = false

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const onClick = (id: any) => {
    if (location.pathname.includes('customers/singlecustomer/')) {
      navigate('/customers/horeca-contracts/horeca-contract-detail', {
        state: {
          disableCustomerDropDown: true,
        },
      })
    } else {
      navigate('/customers/horeca-contracts/horeca-contract-detail')
    }

    localStorage.setItem('horeca-id', id)
  }

  const onEdit = (id: any, isCopyContract?: boolean) => {
    setShow(true)
    setId(id)

    if (isCopyContract) {
      setCopyContract('copyContract')
    }
  }

  const handleFilterChange = (e: any) => {
    setCurrentOffset(0)
    setSelected(0)
    setWarehouse(e)
  }

  useEffect(() => {
    if (location.pathname.includes('customers/singlecustomer/horeca-contracts')) {
      fetchContractRecords()
    }

    if (location.pathname.includes('customers/horeca-contracts')) {
      fetchHorecaCustomerRecords()
    }
  }, [debounceSearch, status, warehouse, currentOffset])

  useEffect(() => {
    if (!isEmpty(cId)) {
      fetchHorecaContracts()
    }
  }, [cId])

  const Table = () => {
    return (
      <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
        <thead>
          <tr className='text-muted fw-500'>
            <th className='min-w-100px py-2'>ID</th>
            <th className='min-w-100px py-2'>Name </th>
            <th className='min-w-120px py-2'>Contract Duration</th>
            <th className='min-w-120px py-2'>Credit Cap</th>
            <th className='min-w-120px py-2'>Credit Duration</th>
            <th className='min-w-120px py-2'>Status</th>
            {checkUserType(['wm']) && permission.write && (
              <th className='min-w-120px py-2'>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {records &&
            records.length > 0 &&
            records.map((item: any) => {
              return (
                <tr className='cursor-pointer'>
                  <td onClick={() => onClick(item.id)}>#{isEmpty(item.id) ? '-' : item.id}</td>
                  <td onClick={() => onClick(item.id)}>
                    <div className='d-flex align-items-center'>
                      <div>
                        {isEmpty(item.customer_name) ? '-' : item.customer_name}
                        <div className='font-13 text-muted'>
                          {isEmpty(item.customer_street) ? '-' : item.customer_street}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td onClick={() => onClick(item.id)}>
                    {!isEmpty(item.start_date) || !isEmpty(item.end_date) ? (
                      <>
                        {dateFormat(item.start_date).displayDate} —{' '}
                        {dateFormat(item.end_date).displayDate}
                      </>
                    ) : (
                      '-'
                    )}
                  </td>

                  <td onClick={() => onClick(item.id)}>
                    {isEmpty(item.credit_amount_cap)
                      ? '-'
                      : numberFormatWithCode(item.credit_amount_cap).displayFormat}
                  </td>

                  <td onClick={() => onClick(item.id)}>
                    {isEmpty(item.credit_cycle) ? '-' : `${item.credit_cycle} Days`}
                  </td>

                  <td onClick={() => onClick(item.id)}>
                    {isEmpty(item.status) ? (
                      '-'
                    ) : (
                      <>
                        {item.status === 'expired' ? (
                          <span className='badge badge-secondary text-dark'>Expired</span>
                        ) : item.status === 'scheduled' ? (
                          <span className='badge badge-light-warning text-dark'>Scheduled</span>
                        ) : (
                          <span className='badge badge-light-success text-dark'>Active</span>
                        )}
                      </>
                    )}
                  </td>

                  {checkUserType(['wm']) && permission.write && (
                    <td>
                      <div
                        onClick={() => onEdit(item.id, true)}
                        className='btn py-1 ps-1 pe-1 btn-hover-color-primary btn-sm cursor-pointer'
                      >
                        <KTSVG path='/media/icons/duotune/art/copy-solid.svg' className='ic mr-0' />
                      </div>
                      {['scheduled', 'active'].includes(item.status) && (
                        <>
                          <div
                            onClick={() => onEdit(item.id)}
                            className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm cursor-pointer me-2'
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                          </div>
                        </>
                      )}
                    </td>
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  }

  return (
    <>
      <EATitle title='HORECA Customers' />
      <PageTitle breadcrumbs={[customersHeaderTitle]}>
        {intl.formatMessage({id: 'HORECA Customers'})}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      {location.pathname.includes('customers/horeca-contracts') && (
        <>
          <div className='row g-3 g-xl-5'>
            <div className='col-sm-6 col-md-6 col-lg col-xl'>
              <EaStatisticsWidget
                className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                color='success'
                title={
                  numberFormat(isEmpty(meta.active_contracts) ? 0 : meta.active_contracts)
                    .displayFormat
                }
                description={`Active contracts`}
              />
            </div>
            <div className='col-sm-6 col-md-6 col-lg col-xl'>
              <EaStatisticsWidget
                className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                color='warning'
                title={
                  numberFormat(isEmpty(meta.no_active_contracts) ? 0 : meta.no_active_contracts)
                    .displayFormat
                }
                description={`Expired Contracts`}
              />
            </div>
            <div className='col-sm-6 col-md-6 col-lg col-xl'>
              <EaStatisticsWidget
                className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                color='info'
                title={
                  numberFormat(isEmpty(meta.pending_contracts) ? 0 : meta.pending_contracts)
                    .displayFormat
                }
                description={`No Contracts Yet`}
              />
            </div>
          </div>
        </>
      )}

      <div className={`card`}>
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              isDisable={
                location.pathname.includes('customers/horeca-contracts')
                  ? fetchingRecords
                  : fetchingContractRecords
              }
            />
            <div className='col-auto ms-auto'>
              <form
                className=' card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                {/* filter dropdown */}
                {location.pathname.includes('customers/horeca-contracts') && (
                  <WarehouseFilter
                    warehouse={warehouse}
                    handleFilterChange={handleFilterChange}
                    isDisabled={false}
                  />
                )}
                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  placeholder='All HORECA Customers'
                  className='react-select-container w-200px'
                  classNamePrefix='react-select'
                  value={status}
                  onChange={(e) => {
                    setCurrentOffset(0)
                    setSelected(0)
                    setStatus(e)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                {checkUserType(['wm']) && permission.write && (
                  <button className='btn btn-primary ms-3' onClick={() => setShow(true)}>
                    Add Contract
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {location.pathname.includes('customers/horeca-contracts') ? (
              <>
                {fetchingRecords ? (
                  <FetchingRecords />
                ) : customerRecords.length === 0 ? (
                  <NoRecords />
                ) : (
                  <div
                    className='table-responsive expandable'
                    style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
                    id='expandTable'
                  >
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='text-muted fw-500'>
                          <th className='min-w-100px py-2'>ID</th>
                          <th className='w-170px py-2'>Name </th>
                          <th className='w-170px py-2'>Address </th>
                          <th className='min-w-100px py-2'>Contact Info </th>
                          <th className='min-w-120px py-2'>Contract Duration</th>
                          <th className='min-w-120px py-2'>Credit Cap</th>
                          <th className='min-w-120px py-2'>Credit Duration</th>
                          <th className='min-w-120px py-2'>Contract Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerRecords.map((item: any) => {
                          return (
                            <>
                              <tr
                                data-bs-toggle='collapse'
                                data-bs-target={`.row-collapse${item.id}`}
                                aria-controls={`.rowCollapse${item.id}`}
                                className='cursor-pointer'
                                onClick={() => setCustomerId(item.id)}
                              >
                                <td>#{isEmpty(item.id) ? '-' : item.id}</td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div>
                                      {isEmpty(item.business_name) ? '-' : item.business_name}
                                      <div className='font-13 text-muted'>
                                        {isEmpty(item.street) ? '-' : item.street}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>{isEmpty(item.address) ? '-' : item.address}</td>

                                <td className='cursor-pointer'>
                                  <div>
                                    <div className='mb-1'>
                                      {!isEmpty(item.contact_no) && (
                                        <>
                                          {' '}
                                          {tenantConfiguration.baseCountryCode} {item.contact_no}{' '}
                                        </>
                                      )}
                                    </div>

                                    {!isEmpty(item.email) && (
                                      <div className='mb-1'>{item.email}</div>
                                    )}
                                  </div>
                                </td>

                                <td>
                                  {!isEmpty(item.contract_start_date) ||
                                  !isEmpty(item.contract_end_date) ? (
                                    <>
                                      {dateFormat(item.contract_start_date).displayDate} —{' '}
                                      {dateFormat(item.contract_end_date).displayDate}
                                    </>
                                  ) : (
                                    '-'
                                  )}
                                </td>

                                <td>
                                  {isEmpty(item.contract_credit_amount_cap)
                                    ? '-'
                                    : numberFormatWithCode(item.contract_credit_amount_cap)
                                        .displayFormat}
                                </td>

                                <td>
                                  {isEmpty(item.contract_credit_cycle)
                                    ? '-'
                                    : `${item.contract_credit_cycle} Days`}
                                </td>

                                <td>
                                  {isEmpty(item.contract_status) ? (
                                    '-'
                                  ) : (
                                    <>
                                      {item.contract_status === 'expired' ? (
                                        <span className='badge badge-secondary text-dark'>
                                          Expired
                                        </span>
                                      ) : item.contract_status === 'pending' ? (
                                        <span className='badge badge-light-warning text-dark'>
                                          Pending
                                        </span>
                                      ) : (
                                        <span className='badge badge-light-success text-dark'>
                                          Active
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr className='expandable-row'>
                                <td className='reset-expansion-style' colSpan={11}>
                                  <div
                                    className={`row-expansion-style collapse row-collapse${item.id} collapsible`}
                                    id={`rowCollapse${item.id}`}
                                    data-bs-parent='#expandTable'
                                  >
                                    <div className='p-2'>
                                      {isLoading ? (
                                        <FetchingRecords />
                                      ) : horecaContract.length === 0 ? (
                                        <NoRecords />
                                      ) : (
                                        <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                          <thead>
                                            <tr className='text-muted fw-500'>
                                              <th className='min-w-120px py-2'>
                                                Contract Duration
                                              </th>
                                              <th className='min-w-120px py-2'>Credit Cap</th>
                                              <th className='min-w-120px py-2'>Credit Duration</th>
                                              <th className='min-w-120px py-2'>Status</th>
                                              {checkUserType(['wm']) && permission.write && (
                                                <th className='min-w-120px py-2'>Actions</th>
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {horecaContract &&
                                              horecaContract.length > 0 &&
                                              horecaContract.map((item: any) => {
                                                return (
                                                  <tr className='cursor-pointer'>
                                                    <td onClick={() => onClick(item.id)}>
                                                      {!isEmpty(item.start_date) ||
                                                      isEmpty(item.end_date) ? (
                                                        <>
                                                          {dateFormat(item.start_date).displayDate}{' '}
                                                          — {dateFormat(item.end_date).displayDate}
                                                        </>
                                                      ) : (
                                                        '-'
                                                      )}
                                                    </td>

                                                    <td onClick={() => onClick(item.id)}>
                                                      {isEmpty(item.credit_amount_cap)
                                                        ? '-'
                                                        : numberFormatWithCode(
                                                            item.credit_amount_cap
                                                          ).displayFormat}
                                                    </td>

                                                    <td onClick={() => onClick(item.id)}>
                                                      {isEmpty(item.credit_cycle)
                                                        ? '-'
                                                        : item.credit_cycle}{' '}
                                                      Days
                                                    </td>

                                                    <td onClick={() => onClick(item.id)}>
                                                      {isEmpty(item.status) ? (
                                                        '-'
                                                      ) : (
                                                        <>
                                                          {item.status === 'expired' ? (
                                                            <span className='badge badge-secondary text-dark'>
                                                              Expired
                                                            </span>
                                                          ) : item.status === 'scheduled' ? (
                                                            <span className='badge badge-light-warning text-dark'>
                                                              Scheduled
                                                            </span>
                                                          ) : (
                                                            <span className='badge badge-light-success text-dark'>
                                                              Active
                                                            </span>
                                                          )}
                                                        </>
                                                      )}
                                                    </td>

                                                    {checkUserType(['wm']) && permission.write && (
                                                      <td>
                                                        <div
                                                          onClick={() => onEdit(item.id, true)}
                                                          className='btn py-1 ps-1 pe-1 btn-hover-color-primary btn-sm cursor-pointer'
                                                        >
                                                          <KTSVG
                                                            path='/media/icons/duotune/art/copy-solid.svg'
                                                            className='ic mr-0'
                                                          />
                                                        </div>
                                                        {['scheduled', 'active'].includes(
                                                          item.status
                                                        ) && (
                                                          <>
                                                            <div
                                                              onClick={() => onEdit(item.id)}
                                                              className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm cursor-pointer me-2'
                                                            >
                                                              <KTSVG
                                                                path='/media/icons/duotune/art/art005.svg'
                                                                className='ic mr-0'
                                                              />
                                                            </div>
                                                          </>
                                                        )}
                                                      </td>
                                                    )}
                                                  </tr>
                                                )
                                              })}
                                          </tbody>
                                        </table>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                )}
              </>
            ) : (
              <>
                {fetchingContractRecords ? (
                  <FetchingRecords />
                ) : records.length === 0 ? (
                  <NoRecords />
                ) : (
                  <Table />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <CustomPaginate
        data={location.pathname.includes('customers/horeca-contracts') ? customerRecords : records}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={
          location.pathname.includes('customers/horeca-contracts')
            ? fetchingRecords
            : fetchingContractRecords
        }
        saveFilterName={'feedbackReport'}
        filterStates={''}
      />

      <HorecaModal
        show={show}
        setShow={setShow}
        onPageStateUpdate={
          location.pathname.includes('customers/horeca-contracts')
            ? fetchHorecaCustomerRecords
            : fetchContractRecords
        }
        id={id}
        setId={setId}
        setIsAlert={setIsAlert}
        setMessage={setMessage}
        customerDetails={isEmpty(customer) ? '' : customer}
        isCopyContract={isCopyContract}
        setCopyContract={setCopyContract}
      />
    </>
  )
}

export default WMHorecaContract
