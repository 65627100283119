import React, { useState } from "react"
import { KTSVG, reSendEmail } from "../../../../../_eaFruitsDms/helpers"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import tenantConfiguration from "../../../../../TenantVariables"

const TableUserAccounts = (props: any,) => {
    const [clickedIndex, setClickedIndex] = useState<number>(-1)

    const handleClick = async (index: number, item: any) => {
        if (clickedIndex === -1) {
            setClickedIndex(index)
            await reSendEmail(item.email)
            setClickedIndex(-1)
            props.emailData(item.email)
        }
    }

    return (
        <>
           <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                    <thead>
                        <tr className='text-muted fw-500'>
                            {/* <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                />
                            </div>
                        </th> */}
                            <th className='min-w-150px'>Name</th>
                            <th className='min-w-140px'>Email</th>
                            <th className='min-w-130px'>Mobile Number</th>
                            <th className='min-w-130px'>Role</th>
                            <th className='min-w-90px'>Status</th>
                            <th className='min-w-200px'></th>
                            {props.showDelete || props.showWrite ? <th className='min-w-50px action-th py-2'>Actions</th> : <th className='min-w-50px action-th py-2'></th>}

                        </tr>
                    </thead>
                    <tbody>
                        {props.tableList.map((item: any, index: number) => {
                            let fName = item.first_name;
                            let lName = item.last_name;
                            let concatName = fName.charAt(0) + lName.charAt(0);;
                            return (
                                <tr key={item.id}>
                                    {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                            </div>
                        </td> */}
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {item.profile_thumbnail === null ?
                                                <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">{concatName == "" ? "-" : concatName}</span> :
                                                <img
                                                    src={item.profile_thumbnail}
                                                    className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10"
                                                />}
                                            <div className="media-body">
                                                <p className="m-0 xs-font">{item.first_name == "" ? "-" : item.first_name + " " + item.last_name}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <a className='text-dark   d-block mb-1'>
                                            {item.email == "" ? "-" : item.email}
                                        </a>
                                    </td>
                                    <td>
                                        <a className='text-dark   d-block mb-1'>
                                            {item.phone_no == "" ? "-" : `${tenantConfiguration.baseCountryCode} ${item.phone_no}`}
                                        </a>
                                    </td>

                                    <td className='text-dark  '>
                                        {item.role == null ? "-" : item.role.name}
                                    </td>
                                    <td>
                                        {item.is_active ?
                                            <span className='badge badge-light-success text-dark'>Active</span> :
                                            <span className='badge badge-secondary text-dark'>Inactive</span>
                                        }
                                    </td>
                                    <td>
                                        {item && !item.is_user_verified &&
                                            <button
                                                className='btn btn-link ms-3 p-0 '
                                                type='button'
                                                // onClick={() => reSendEmail(item && item.email)}
                                                onClick={() => handleClick(index, item)}
                                            >
                                                {(clickedIndex === -1 || clickedIndex !== index) ? "Resend Verification Email" : "Please" +
                                                    " wait..."}
                                            </button>
                                        }
                                    </td>
                                    <td className='text-end'>
                                        {!item.is_staff &&
                                            <>
                                                {props.showWrite &&
                                                    <a
                                                        className='cursor-pointer btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#ea_modal_edit_adminuser'
                                                        onClick={() => props.getPropsEdit(item)}
                                                    >
                                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                                    </a>}
                                                {/* {props.showDelete &&
                                                    <a href='#' className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#deleteAdminSku'
                                                        onClick={() => props.getPropsDelete(item)}
                                                    >
                                                        <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                                    </a>} */}
                                            </>
                                        }
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
        </>
    )
}

export default TableUserAccounts
