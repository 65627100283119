//warehouse/DC/singlewarehousename/warehouse-customers TODO: For our reference do not remove this comment

import React, {useEffect, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  displayAddedBy,
  KTSVG,
  staticSearch,
  toAbsoluteUrl,
  verificationPending,
} from '../../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomSearchBar from '../../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import tenantConfiguration from '../../../../../../TenantVariables'
import {categoryStyle} from '../../../../../../_eaFruitsDms/helpers/components/categoryStyle'
import {fetchUserTypes} from '../../../../customers/WMcustomers/CustomerConst'
import CustomPaginate from '../../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import moment from 'moment/moment'
import EATitle from '../../../../../../_eaFruitsDms/layout/components/title/title'

const Customers: React.FC = () => {
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [customers, setCustomers] = useState<any>([])
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [timer, setTimer] = useState<number>(0)
  const [categoryId, setCategoryId] = useState<any>('')
  const [typeId, setTypeId] = useState<any>('')
  const [searchByName, setSearchByName] = useState<string>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [categories, setCategories] = useState<any>([])
  const [userOffset, setUserOffset] = useState('')
  const [userType, setUserType] = useState<any>('')
  const [userId, setUserId] = useState<any>('')
  const [userReset, setUserReset] = useState<any>(moment())

  const wareHouseId = localStorage.getItem('wId')

  useEffect(() => {
    setTimeout(() => fetchCustomers().then(), timer)
  }, [currentOffset, searchByName, wareHouseId, categoryId, typeId, userType, userId])

  useEffect(() => {
    setUserOffset('')
  }, [searchSelect])

  const fetchCustomers = async () => {
    setDisplayLoader(true)

    const customersResponse = await getRequest(
      `${apiBaseURL()}customers?on_boarding_status__in=Approved,Pending,Reject&search=${searchByName}&limit=${limit}&offset=${currentOffset}&customer_type=${typeId}&customer_category__id__in=${categoryId}&warehouse_id=${wareHouseId}&search=${searchByName}&limit=${limit}&offset=${currentOffset}&customer_type=${typeId}&category_ids=${categoryId}&reg_by_type=${userType}&reg_by_id=${
        userId !== '' ? userId.value : ''
      }`,
      true
    )
    let customerList: any = []
    if (customersResponse.results && customersResponse.results.length > 0) {
      customersResponse.results.map((customer: any) => {
        const verificationStatus = verificationPending(customer)
        customer.pending = verificationStatus.pending
        customer.type = verificationStatus.type

        let customerInfo: any = {...customer}
        customerList.push(customerInfo)
      })
    }
    setCustomers(customerList)
    setCount(customersResponse.count)
    setNext(customersResponse.next)
    setPrev(customersResponse.previous)
    setDisplayLoader(false)
  }

  const customerTypes: any = [
    {
      value: '',
      label: 'All Types',
    },
    {
      label: 'Wholesaler',
      value: 'Wholesaler',
    },
    {
      label: 'Retailer',
      value: 'Retailer',
    },
  ]

  const fetchCustomerTypes = async (search?: any) => {
    let options = customerTypes

    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchUsers = async (search?: any) => {
    //auth/users/team/list?offset=0&warehouse__id=6&type=sr
    const options: any = []
    let hasMore: boolean = false

    if (userType !== '') {
      if (search !== '') {
        setSearchSelect(search)
      }

      const wId: any = !['ts'].includes(userType) ? wareHouseId : ''

      const response: any = await getRequest(
        `${apiBaseURL()}auth/users/team/list?search=${searchSelect}&type=${userType}&limit=${limit}&offset=${userOffset}&ordering=first_name&warehouse__id=${wId}`,
        true
      )

      if (!response.results) {
        hasMore = false
      } else {
        if (response.next !== null) {
          const queryParams = new URLSearchParams(response.next)
          let newOffset: any = queryParams.get('offset')
          setUserOffset(newOffset)
          hasMore = true
        }

        if (options.length === 0) {
          options.push({
            label: 'All Users',
            value: '',
          })
        }

        if (response.results.length > 0) {
          response.results.map((option: any) => {
            options.push({
              label:
                option.first_name + ' ' + option.last_name + ' (' + option.type.toUpperCase() + ')',
              value: option.id,
            })
          })
        }
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategories = async (search?: any) => {
    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/customer-category-parent/list/`,
      true
    )

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    const categoryKeys = Object.keys(categoriesResponse)

    categoryKeys.forEach((value) => {
      let optionId: string = ''

      options.push({
        label: value,
        value: optionId,
        color: '#6d6e6f',
        bg: '#f7f7f7',
        transform: 'uppercase',
        boxSizing: 'borderBox',
        weight: '500',
        cursor: 'default',
        size: '.75rem !important',
      })

      categoriesResponse[value].map((option: any) => {
        if (optionId === '') {
          optionId += option.id
        } else {
          optionId += ',' + option.id
        }
        options.push({
          label: option.category,
          value: option.id,
        })
        return options
      })

      let index = options.findIndex((x: any) => x.label === value)
      options[index].value = optionId
    })

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options: options,
      hasMore: false,
    }
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setTimer(0)

    switch (inputName) {
      case 'category':
        setCategoryId(inputValue)
        break
      case 'type':
        setTypeId(inputValue)
        break
      case 'user-type':
        setUserType(inputValue)
        setUserOffset('')
        setUserId('')
        break
      case 'users':
        setUserId(e)
        setUserReset(moment())
        break
      default:
        setTimer(1000)
        setCurrentOffset(0)
        setSelected(0)
        setSearchByName(inputValue)
    }
  }

  return (
    <>
      <EATitle title='Customers' />
      <div className='card'>
        {/* begin::Header */}
        {displayLoader && <IsLoader />}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleChange} />
          <div className='ms-md-auto'>
            <div className='card-toolbar d-flex my-0'>
              <AsyncPaginate
                loadOptions={fetchCategories}
                isSearchable
                className='react-select-container my-1 me-3 w-150px'
                classNamePrefix='react-select'
                placeholder='All Categories'
                styles={categoryStyle}
                name='categories'
                value={categories.filter((option: any) => option.value === categoryId)}
                onChange={(e: any) => handleChange(e, 'category')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchCustomerTypes}
                isSearchable
                className='react-select-container my-1 me-3 w-120px'
                classNamePrefix='react-select'
                placeholder='All Types'
                value={customerTypes.find((option: any) => option.value === typeId)}
                onChange={(e: any) => handleChange(e, 'type')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchUserTypes}
                className='react-select-container my-1 me-3 w-120px'
                classNamePrefix='react-select'
                placeholder='Added By ...'
                // value={types.find((option: any) => option.value === typeId)}
                onChange={(e: any) => handleChange(e, 'user-type')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              {userType !== '' && (
                <AsyncPaginate
                  key={userReset}
                  loadOptions={fetchUsers}
                  className='react-select-container my-1 me-3 w-200px'
                  classNamePrefix='react-select'
                  placeholder='All Users'
                  onChange={(e: any) => handleChange(e, 'users')}
                  value={userId || ''}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            {customers.length == 0 ? (
              <>
                <NoRecords />
              </>
            ) : (
              <>
                <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-60px'>ID</th>
                      <th className='min-w-160px'>Name</th>
                      <th className='min-w-110px'>Street Name</th>
                      <th className='min-w-130px'>Mobile Number</th>
                      <th className='min-w-120px'>Credit Range</th>
                      <th className='min-w-120px'>Added By</th>
                      <th className='min-w-100px'>Verification</th>
                      <th className='min-w-120px'>Total Sales</th>
                      <th className='min-w-120px'>Credit Days</th>
                      <th className='min-w-120px'>Credit Issue</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {customers.map((customer: any) => {
                      return (
                        <>
                          <tr>
                            <td>#{customer.id}</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <span
                                  className='rounded-circle pro-icon me-2'
                                  data-bs-toggle='tooltip'
                                  title={customer.business_name}
                                >
                                  {customer.logo_thumbnail === null ? (
                                    <img
                                      src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                                      alt=''
                                      className='rounded-circle pro-icon'
                                    />
                                  ) : (
                                    <img
                                      src={customer.logo_thumbnail}
                                      alt=''
                                      className='rounded-circle pro-icon'
                                    />
                                  )}
                                </span>
                                <div className='media-body'>
                                  <p className='m-0 xs-font'>{customer.business_name}</p>
                                </div>
                              </div>
                            </td>
                            <td>{customer.street && customer.street}</td>
                            <td>
                              {customer.contact_no && tenantConfiguration.baseCountryCode}{' '}
                              {customer.contact_no}
                            </td>
                            <td>{customer.credit_range}</td>
                            <td>{displayAddedBy(customer.reg_by)}</td>
                            <td>
                              {customer.pending == 0 ? (
                                <>
                                  <span className='btn py-0 ps-1 pe-0 btn-sm me-3'>
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr012.svg'
                                      className='ic mr-0'
                                    />
                                  </span>
                                </>
                              ) : (
                                customer.type.length > 0 && (
                                  <div className='d-flex align-items-center'>
                                    <OverlayTrigger
                                      delay={{hide: 450, show: 300}}
                                      overlay={(props) => (
                                        <Tooltip {...props} className='tooltip-dark'>
                                          {customer.type.map((type: any, index: number) => {
                                            return (
                                              <>
                                                <span>
                                                  {' '}
                                                  {type}
                                                  {index + 1 < customer.type.length
                                                    ? index == 0 && customer.type.length > 2
                                                      ? ', '
                                                      : ' & '
                                                    : ''}{' '}
                                                </span>
                                              </>
                                            )
                                          })}
                                        </Tooltip>
                                      )}
                                      placement='top'
                                    >
                                      <span className='cursor-pointer text-decoration-underline'>
                                        {customer.pending} Pending
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                )
                              )}
                            </td>
                            <td>-</td>
                            <td>{customer.credit_cycle}</td>
                            <td>-</td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              </>
            )}
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CustomPaginate
        data={customers}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default Customers
