import {
  checkEmpty,
  dateFormat,
  formatTags,
  formatVerificationType,
  isEmpty,
  KTSVG,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import React from 'react'

const CustomerHeader = ({customer}: any) => {
  return (
    <>
      <div className='symbol symbol-60px symbol-1by1 me-4'>
        <div
          className='symbol-label'
          style={{
            backgroundImage: `url(${
              customer && !['', null, 'null'].includes(customer.logo_thumbnail)
                ? customer.logo_thumbnail
                : toAbsoluteUrl('/media/avatars/300-5.jpg')
            })`,
          }}
        ></div>
      </div>
      <div className='d-flex flex-column mb-2 mb-sm-0'>
        <div className='d-flex align-items-center mb-1'>
          <span className='text-dark font-20 fw-bold me-1'>
            {customer && customer.business_name}
          </span>
          {customer && customer.tags.length > 0 && (
            <div className='d-flex align-items-center'>
              {customer.tags.length === 1 ? (
                <span className='ms-2 btn btn-sm bg-light-primary py-1 cursor-default'>
                  {customer.tags[0].tag_name}
                </span>
              ) : (
                <OverlayTrigger
                  delay={{hide: 450, show: 300}}
                  overlay={(props) => (
                    <Tooltip {...props} className='tooltip-dark'>
                      <span>{formatTags(customer.tags)}</span>
                    </Tooltip>
                  )}
                  placement='top'
                >
                  <span className='ms-2 btn btn-sm bg-light-primary py-1 cursor-default'>
                    {customer.tags[0].tag_name}{' '}
                    {customer.tags.length > 1 && `& ${customer.tags.length - 1} More`}
                  </span>
                </OverlayTrigger>
              )}
            </div>
          )}
          {customer && customer.on_boarding_status === 'Approved' && (
            <>
              <OverlayTrigger
                delay={{hide: 450, show: 300}}
                overlay={(props) => (
                  <Tooltip {...props} className='tooltip-dark'>
                    {`Active since ${dateFormat(customer.on_boarding_date).displayDate} by ${
                      customer.action_by.first_name
                    } ${customer.action_by.last_name}`}
                  </Tooltip>
                )}
                placement='top'
              >
                <span className='ms-2 btn btn-sm bg-light-success py-1'>Active</span>
              </OverlayTrigger>
            </>
          )}
          {customer && customer.on_boarding_status === 'Revoked' && (
            <>
              <OverlayTrigger
                delay={{hide: 450, show: 300}}
                overlay={(props) => (
                  <Tooltip {...props} className='tooltip-dark'>
                    {`Suspended on ${dateFormat(customer.on_boarding_date).displayDate} by ${
                      customer.action_by.first_name
                    } ${customer.action_by.last_name} for ${customer.revoke_access_option_note}`}
                  </Tooltip>
                )}
                placement='top'
              >
                <span className='ms-2 btn btn-sm bg-light-danger py-1'>Inactive</span>
              </OverlayTrigger>
            </>
          )}
          {customer && customer.pending > 0 && (
            <div className='d-flex align-items-center'>
              <OverlayTrigger
                delay={{hide: 450, show: 300}}
                overlay={(props) => (
                  <Tooltip {...props} className='tooltip-dark'>
                    <span>{formatVerificationType(customer.verification_type)}</span>
                  </Tooltip>
                )}
                placement='top'
              >
                <span className='ms-2 btn btn-sm bg-light-danger py-1 cursor-default'>
                  {customer.pending} Verification Pending
                </span>
              </OverlayTrigger>
            </div>
          )}
        </div>
        <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
          <span className='d-flex align-items-center me-6 mb-2'>
            {customer && '#' + customer.id}
          </span>
          {customer && (
            <>
              <span className='d-flex align-items-center me-6 mb-2'>
                <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                Registered on{' '}
                {dateFormat(customer.created_at).displayDate +
                  ' ' +
                  dateFormat(customer.created_at, 'LT').displayDate}
              </span>
              <span className='d-flex align-items-center me-6 mb-2'>
                <KTSVG path='/media/icons/duotune/general/gen018.svg' className='me-1' />
                {customer && customer.region}
              </span>
              <span className='d-flex align-items-center me-5 mb-2 '>
                <KTSVG path='/media/icons/duotune/communication/com006.svg' className='me-1' />
                {customer && isEmpty(customer.reg_by)
                  ? 'Self Registered'
                  : 'Added by ' +
                    checkEmpty(customer.reg_by.first_name + ' ' + customer.reg_by.last_name)}{' '}
                {!isEmpty(customer.reg_by) && `(${customer.reg_by.type.toUpperCase()})`}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CustomerHeader
