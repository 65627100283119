import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  addPaymentWallets,
  paymentWalletsList,
  paymentWalletsUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import { checkPermissions, defaultPermission, KTSVG } from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../_eaFruitsDms/layout/core"

type Props = {
  className: string
}
const Wallets: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterBreadCrumbs: Array<PageLink> = [
    {
      title: 'Masters',
      path: '/masters/paymentmaster',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Payment Master',
      path: '/masters/paymentmaster',
      isSeparator: false,
      isActive: false,
    },
  ]

  const [getList, setGetList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [paymentWallets, setPaymentWallets] = useState<any>('')
  const [paymentWalletsEdit, setPaymentWalletsEdit] = useState<any>('')
  const [validation, setValidation] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [EditId, setEditId] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [validationEdit, setValidationEdit] = useState<any>('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  useEffect(() => {
    fetchPermission()
    VehicalList()
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_payment")
    setPermission(res)
   }

  const VehicalList = () => {
      setListLoader(true)
    paymentWalletsList('10', '0', '', '').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      paymentWalletsList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false) })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      paymentWalletsList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false) })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      paymentWalletsList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
      setListLoader(false) })
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)
      paymentWalletsList('10', '0', e.target.value).then((res) => {
        setGetList(res.data)

        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
        setListLoader(false)  
      })
    }, 500)

    setTimer(newTimer)
  }

  // AddFunction
  const clearFunction = () => {
    setPaymentWallets('')
    setValidation('')
    setErrorMessage('')
  }

  const AddFunction = () => {
    if (paymentWallets === '') {
      setValidation('is-invalid')
    } else {
      setValidation('')
      AddFunctionApi()
    }
  }

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    addPaymentWallets(paymentWallets).then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunction()
        setListLoader(true)
        paymentWalletsList('10', '0', '').then((res) => {
          setGetList(res.data)

          setCount(res.count)
          setNext(res.next)
          setListLoader(false)   
          setPrev(res.prev)
          setSelected(0)
        })
        $('#closeAddEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'provider_name') {
        setErrorMessage(
          'Your added Wallets Name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  // edit function

  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setPaymentWalletsEdit(item.provider_name)
    setEditId(item.id)
    setModalLoader(false)
  }

  const EditFunction = () => {
    if (paymentWalletsEdit === '') {
      setValidationEdit('is-invalid')
    } else {
      setValidationEdit('')
      EditFunctionApi()
    }
  }

  const clearFunctionEdit = () => {
    setValidationEdit('')
    setPaymentWalletsEdit('')
    setErrorMessage('')
  }

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    paymentWalletsUpdate(paymentWalletsEdit, EditId).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
        setListLoader(true)
        paymentWalletsList('10', '0', search, 'provider_name').then((res) => {
          setGetList(res.data)

          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)   
        })
        $('#closeEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'provider_name') {
        setErrorMessage(
          'Your added  Wallets Name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <div>
        <EATitle title='Wallets - ' />
        <PageTitle breadcrumbs={masterBreadCrumbs}>{intl.formatMessage({ id: 'Wallets' })}</PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? 'Your Wallets Name has been added successfully.'
              : 'Your Wallets Name has been edited  successfully.'
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              {/* <CustomSearchBar /> */}
              <div className='col-12 col-md-auto mt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className='form-control form-search'
                  onChange={searchList}
                />
              </div>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_wallet'
                    btnName='Add Wallet'
                    onClick={() => {
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {getList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px py-2'>Wallet Name</th>
                          <th className='min-w-80px action-th py-2'>
                            {permission.write && 'Actions'}
                          </th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {getList.map((item: any) => {
                          return (
                            <tr>
                              <td>{item.provider_name}</td>
                              <td className='text-end'>
                                {permission.write && (
                                  <a
                                    href='javascript:void(0)'
                                    data-bs-toggle='modal'
                                    data-bs-target='#ea_modal_edit_wallet'
                                    onClick={(event) => {
                                      onChangeAdd(item)
                                      setMessageHandler('successEdit')
                                    }}
                                    className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )}

                                {/* <a
        href='javascript:void(0)'
        className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
      >
        <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
      </a> */}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
                } of ${getList.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
                count={getList.length == 0 ? 0 : count}
                selected={selected}
              />
            </div>
          </div>
        </div>
      </div>

      <EaModal
        id='ea_modal_create_wallet'
        modalTitle='Add Wallet'
        onSubmit={AddFunction}
        closeAuto='closeAddEaModalBrand'
        cancel={clearFunction}
        actionBtnName={isLoadingAdd}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Wallet Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validation)}
            placeholder='Type here...'
            onChange={(e) => setPaymentWallets(capitalizeFirstLetter(e.target.value))}
            value={paymentWallets}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
      <EaModal
        id='ea_modal_edit_wallet'
        modalTitle='Edit Wallet'
        onSubmit={EditFunction}
        closeAuto='closeEditEaModalBrand'
        cancel={clearFunctionEdit}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Wallet Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validationEdit)}
            placeholder='Type here...'
            onChange={(e) => setPaymentWalletsEdit(capitalizeFirstLetter(e.target.value))}
            value={paymentWalletsEdit}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}

export default Wallets
