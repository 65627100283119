import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  streetCreate,
  StreetList,
  StreetSingle,
  StreetUpdate
} from "../../../../_eaFruitsDms/apiFunctions/master/master";
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation
} from "../../../../_eaFruitsDms/helpers";
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import { getToken } from "../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage";
import TableStreets from "./component/tableStreets";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";

type Props = {
  className: string
}

const Streets: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Streets',
  }

  // all states
  const [getList, setGetList] = useState<any>([])
  const [id, setId] = useState('')
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [count, setCount] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [getLength, setLength] = useState('1')
  const [getRegionsList, setRegionsList] = useState<any>([])
  const [getDistrictsList, setDistrictsList] = useState<any>([])
  const [getWardList, setWardList] = useState<any>([])
  const [streetDistrict, setSdistrict] = useState<any>({ label: '', value: '' })
  const [streetWard, setSWard] = useState<any>({ label: '', value: '' })
  const [streetRegions, setSregion] = useState<any>({ label: '', value: '' })
  const [districtData, setDistrictData] = useState<any>([])
  const [districtValidation, setDsitrictValidation] = useState('')
  const [districtValidationEdit, setDsitrictValidationEdit] = useState('')
  const [streetValidation, setSvalidation] = useState('')
  const [streetValidationEdit, setSvalidationEdit] = useState('')
  const [kmlFileName, setKmlFileName] = useState('Browse')
  const [kmlFile, setKmlFile] = useState('')
  const [streetName, setStreetName] = useState('')
  const [selectWard, setDModal] = useState<any>('')
  const [kmlFileNameEdit, setKmlFileNameEdit] = useState('Browse')
  const [kmlFileEdit, setKmlFileEdit] = useState('')
  const [streetNameEdit, setStreetNameEdit] = useState('')
  const [selectWardEdit, setDModalEdit] = useState<any>('')
  const [showWrite, setShowWrite] = useState<any>(false)
  const [showDelete, setShowDelete] = useState<any>(false)
  const [listLoadder, setListLoader] = useState<any>(true)
  const [editDis, setDis] = useState(false)
  const [newValue, setNewValue] = useState<any>('')
  const [sKml, setSKml] = useState('')
  const [sKmlLink, setSKmlLink] = useState('#')
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [searchSelect, setSearchSelect] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  // Authorization Token
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  useEffect(() => {
    setWardOffset('')
    setRegionOffset('')
    setDistrictOffset('')
    setWardGroupOffset('')
  }, [searchSelect])

  useEffect(() => {
    fetchPermission()

    setListLoader(true)
    StreetList('10', '0', '', '', '', '').then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })
    getDistricts()
    getRegions()
    getWards()
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_distribution_boundaries")
    setPermission(res)
   }

  let optGroup = [
    {
      label: '',
      options: [{ label: '', value: '' }],
    },
  ]
  let optionData: any = []

  const getDistricts = () => {
    // get District list
    // axios.get(`${apiBaseURL()}master/district/list/?ordering=name`, header).then((res) => {
    //     setDistrictsList(res.data.results)
    // })
    // get region_district_ward
    // axios.get(`${apiBaseURL()}master/region_district_ward/list/?ordering=name`, header).then((res) => {
    //     let data = res.data.results
    //     let district = ''
    //     // get region_district_ward api response and set into react-select dependency data-format.
    //     data.forEach((element: any) => {
    //         let districtName: any = [{ label: "-", value: "-" }]
    //         if (element.district.length !== 0) {
    //             district = element.district[0].name
    //             let nameFormating = element.district[0].ward.sort((a: any, b: any) => {
    //                 return a.name.localeCompare(b.name)
    //             })
    //             let removeBlankSpace = false
    //             nameFormating.forEach((dist: any) => {
    //                 removeBlankSpace = true
    //                 districtName.push({ label: dist.name, value: dist.id })
    //             })
    //             if (removeBlankSpace) {
    //                 districtName.shift()
    //             }
    //             optionData.push({
    //                 label: district, options: districtName
    //             })
    //         }
    //     });
    //     optGroup = optionData
    //     setDistrictData(optionData)
    // })
  }

  const getRegions = () => {
    // get region list with name ordering
    // axios.get(`${apiBaseURL()}master/region/list/?ordering=name`, header).then((res) => setRegionsList(res.data.results))
  }

  const limit = '&limit=1000'

  const getWards = async () => {
    // get wards list with name ordering
    // request method for api implementation :- last change by kriti
    await getRequest(`${apiBaseURL()}master/wards/list/?ordering=name` + limit, true).then(
      (res) => {
        let data = res.results
        let wards: any = []
        data.forEach((item: any) => {
          wards.push({ label: item.name, value: item.id })
        })
        setDistrictData(wards)
      }
    )
  }

  const getProps = (val: any) => {
    setMessageHandler('successEdit')
    setModalLoader(true)
    setId(val.id)
    // get selected value
    StreetSingle(val.id).then((res: any) => {
      // for kml
      if (res.kml_file !== null) {
        var filename = res.kml_file.substring(res.kml_file.lastIndexOf('/') + 1)
        setSKml(filename)
        setSKmlLink(res.kml_file)
      } else {
        setSKml('')
        setSKmlLink('#')
      }
      setModalLoader(false)
      setStreetNameEdit(res.name)
      setDModalEdit(res.ward)

      let getWards: any = ''
      let selectedWards: any = []
      // districtData.forEach((element: any) => {
      //   getWards = element.options.filter((val: any) => {
      //     return val.value === res.ward
      //   })
      //   getWards.forEach((element: any) => {
      //     selectedWards.push(element)
      //   })
      // })
      // setNewValue(selectedWards)
    })
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)      
      StreetList(
        '10',
        newOffset,
        streetWard.value,
        streetDistrict.value,
        streetRegions.value,
        search
      ).then((res) => {
        setGetList(res.data)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)     
      StreetList(
        '10',
        newOffset,
        streetWard.value,
        streetDistrict.value,
        streetRegions.value,
        search
      ).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true) 
      StreetList(
        '10',
        newOffset,
        streetWard.value,
        streetDistrict.value,
        streetRegions.value,
        search
      ).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)    
      StreetList(
        '10',
        '0',
        streetWard.value,
        streetDistrict.value,
        streetRegions.value,
        e.target.value
      ).then((res) => {
        setGetList(res.data)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
        setListLoader(false)
      })
    }, 500)
    setTimer(newTimer)
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const changeDistrict = (val: any) => {
    if (val.label === 'All Districts') {
      setSdistrict({ label: '', value: val.value })
    } else {
      setSdistrict(val)
    }
    setListLoader(true)  
    StreetList(
      '10',
      '0',
      streetWard.value,
      val.label === 'All Districts' ? '' : val.value,
      streetRegions.value,
      search
    ).then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
      setListLoader(false)
    })
  }

  const changeWard = (val: any) => {
    if (val.label === 'All Wards') {
      setSWard({ label: '', value: val.value })
    } else {
      setSWard(val)
    }
    setListLoader(true) 
    StreetList(
      '10',
      '0',
      val.label === 'All Wards' ? '' : val.value,
      streetDistrict.value,
      streetRegions.value,
      search
    ).then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
      setListLoader(false)
    })
  }

  const changeRegion = (val: any) => {
    if (val.label === 'All Regions') {
      setSregion({ label: '', value: val.value })
    } else {
      setSregion(val)
    }
    setListLoader(true)
    StreetList(
      '10',
      '0',
      streetWard.value,
      streetDistrict.value,
      val.label === 'All Regions' ? '' : val.value,
      search
    ).then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setSelected(0)
      setListLoader(false)
    })
  }

  const clearFunctionStreet = () => {
    // clear add modal state
    setStreetName('')
    setDModal('')
    setSvalidation('')
    setKmlFile('')
    setKmlFileName('Browse')
    setDsitrictValidation('')
    setErrorMessage('')
    setWardGroupOffset('')
    setWardOffset('')
    setRegionOffset('')
    setDistrictOffset('')
    setWardGroupOffset('')
  }

  const clearFunctionStreetEdit = () => {
    // clear edit modal state
    setErrorMessage('')
    setDis(false)
    setWardGroupOffset('')
    setStreetNameEdit('')
    setDModalEdit('')
    setSvalidationEdit('')
    setKmlFileEdit('')
    setKmlFileNameEdit('Browse')
    setDsitrictValidationEdit('')

    setWardOffset('')
    setRegionOffset('')
    setDistrictOffset('')
    setWardGroupOffset('')
  }

  const addFunctionStreet = () => {
    let navigateField = {
      streetName: streetName,
      selectWard: selectWard,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // ui validation [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    if (streetName == '') {
      setSvalidation('is-invalid')
    } else {
      setSvalidation('')
    }
    if (selectWard == '') {
      setDsitrictValidation('is-invalid')
    } else {
      setDsitrictValidation('')
    }

    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    if (streetName.trim() !== '') {
      if (selectWard !== '') {
        setDisableBtn(true)
        setIsLoading('Please Wait..')
        streetCreate(streetName, selectWard, '', kmlFile).then((res) => {
          setDisableBtn(false)
          setIsLoading('Submit')
          setWardList([])
          setDistrictsList([])
          setRegionsList([])
          setSWard({ label: '', value: '' })
          setSdistrict({ label: '', value: '' })
          setSregion({ label: '', value: '' })
          if (res.success) {
            clearFunctionStreet()
            setListLoader(true) 
            StreetList('10', '0', '', '', '', '').then((res) => {
              setGetList(res.data)
              if (res.data.length == 0) {
                setLength('2')
              } else {
                setLength('1')
              }
              setCount(res.count)
              setNext(res.next)
              setPrev(res.prev)
              setSelected(0)
              setListLoader(false)
            })
            setTimeout(() => {
              setIsAlertSuccess(false)
              setIsAlertFailed(false)
            }, 2000)
            $('#ea_modal_close_add_Street').trigger('click') // modal close
            setIsAlertSuccess(true)
          }
          if (res.failed) {
            // setIsAlertFailed(true)
          }
          if (res.errorField == 'name') {
            setErrorMessage(
              'Your added street name already exists in this system! Please try again with a unique name.'
            )
          }
        })
      }
    }
  }

  const editFunctionStreet = () => {
    let navigateField = {
      streetNameEdit: streetNameEdit,
      selectWardEdit: selectWardEdit,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (streetNameEdit == '') {
      setSvalidationEdit('is-invalid')
    } else {
      setSvalidationEdit('')
    }
    if (selectWardEdit == '') {
      setDsitrictValidationEdit('is-invalid')
    } else {
      setDsitrictValidationEdit('')
    }

    if (streetNameEdit.trim() !== '') {
      if (selectWardEdit !== '') {
        let districtModalValue = ''
        if (selectWardEdit.value) {
          districtModalValue = selectWardEdit.value
        } else {
          districtModalValue = selectWardEdit
        }
        setDisableBtn(true)
        setIsLoading('Please Wait..')
        StreetUpdate(id, streetNameEdit, districtModalValue, kmlFileEdit).then((res) => {
          setDisableBtn(false)
          setIsLoading('Submit')
          if (res.success) {
            clearFunctionStreetEdit()
            setListLoader(true)  
            StreetList(
              '10',
              '0',
              streetWard.value,
              streetDistrict.value,
              streetRegions.value,
              search
            ).then((res) => {
              setGetList(res.data)
              if (res.data.length == 0) {
                setLength('2')
              } else {
                setLength('1')
              }
              setCount(res.count)
              setNext(res.next)
              setPrev(res.prev)
              setSelected(0)
              setListLoader(false)
            })
            setTimeout(() => {
              setIsAlertSuccess(false)
              setIsAlertFailed(false)
            }, 2000)
            $('#ea_modal_close_Edit_Street').trigger('click') // modal close

            setIsAlertSuccess(true)
          }
          if (res.failed) {
            // setIsAlertFailed(true)
          }

          if (res.errorField == 'name') {
            setErrorMessage(
              'Your added street name already exists in this system! Please try again with a unique name.'
            )
          }
        })
      }
    }
  }

  const selectKmlFile = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlFileName(e.target.files[0].name)
      setKmlFile(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const selectKmlFileEdit = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setKmlFileNameEdit(e.target.files[0].name)
      setKmlFileEdit(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const [kmlValidationEdit, setKmlValidationEdit] = useState('')
  const [KmlFile, setKMLFile] = useState('')
  const [kmlFileNameKml, setkmlFileNameKml] = useState('Browse')
  const [districtModalValue, setDistrictModalValue] = useState('')

  const getKmlProps = (val: any) => {
    setMessageHandler('successKml')
    setId(val.id)
    setStreetNameEdit(val.name)
    // get selected value
    StreetSingle(val.id).then((res: any) => {
      console.log(res)
      setDistrictModalValue(res.ward)
    })
  }

  const selectKmlFileKml = (e: any) => {
    if (e.target.files) {
      var allowedExtensions = /(\.kml)$/i
      // if (allowedExtensions.exec(e.target.value)) {
      setkmlFileNameKml(e.target.files[0].name)
      setKMLFile(e.target.files[0])
      // }
      e.target.value = ''
    }
  }

  const clearFunctionKML = () => {
    // clear KML modal state
    setKMLFile('')
    setkmlFileNameKml('Browse')
    setKmlValidationEdit('')
  }

  const kmlFunction = () => {
    if (KmlFile == '') {
      setKmlValidationEdit('is-invalid')
    } else {
      setKmlValidationEdit('')
    }

    if (KmlFile !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')

      StreetUpdate(id, streetNameEdit, districtModalValue, KmlFile).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (res.success) {
          clearFunctionKML()
          clearFunctionStreetEdit()
          setListLoader(true)   
          StreetList('10', '0', '', '', '', '').then((res) => {
            setGetList(res.data)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)
            setListLoader(false)
          })
          setIsAlertSuccess(true)
        }
        if (res.failed) {
          setIsAlertFailed(true)
        }
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        $('#ea_modal_close_kml_region').trigger('click') // modal close
      })
    }
  }

  const [wardLimit, setWardLimit] = useState(10)
  const [wardOffset, setWardOffset] = useState('')

  const [districtLimit, setDistrictLimit] = useState(10)
  const [districtOffset, setDistrictOffset] = useState('')

  const [regionLimit, setRegionLimit] = useState(10)
  const [regionOffset, setRegionOffset] = useState('')

  const [wardGroupLimit, setWardGroupLimit] = useState(10)
  const [wardGroupOffset, setWardGroupOffset] = useState('')

  const fetchWards = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const wardApi = `${apiBaseURL()}master/wards/list/?ordering=name&search=${search}&limit=${wardLimit}&offset=${wardOffset}`

    const response = await getRequest(wardApi, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setWardOffset(newOffset)
      hasMore = true
    }

    if (getWardList.length == 0) {
      options.push({
        label: 'All Wards',
        value: '',
      })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (getWardList.length > 0) {
      setWardList(getWardList.concat(options))
    } else {
      setWardList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchDistricts = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const districtApi = `${apiBaseURL()}master/district/list/?ordering=name&search=${search}&limit=${districtLimit}&offset=${districtOffset}`

    const response = await getRequest(districtApi, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setDistrictOffset(newOffset)
      hasMore = true
    }

    if (getDistrictsList.length == 0) {
      options.push({
        label: 'All Districts',
        value: '',
      })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (getDistrictsList.length > 0) {
      setDistrictsList(getDistrictsList.concat(options))
    } else {
      setDistrictsList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchRegion = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const regionApi = `${apiBaseURL()}master/region/list/?ordering=name&search=${search}&limit=${regionLimit}&offset=${regionOffset}`

    const response = await getRequest(regionApi, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setRegionOffset(newOffset)
      hasMore = true
    }

    if (getRegionsList.length == 0) {
      options.push({
        label: 'All Regions',
        value: '',
      })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (getRegionsList.length > 0) {
      setRegionsList(getRegionsList.concat(options))
    } else {
      setRegionsList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchGroupStreets = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const regionApi = `${apiBaseURL()}master/region_district_ward/list/?ordering=name&search=${search}&limit=${wardGroupLimit}&offset=${wardGroupOffset}`

    const response = await getRequest(regionApi, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setWardGroupOffset(newOffset)
      hasMore = true
    }

    if (districtData.length == 0) {
      // options.push({
      //     label: "All Regions",
      //     value: ""
      // })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {

        if (option.district.length !== 0) {
          option.district.map((optionDistrict: any) => {
            let district = optionDistrict.name
            let districtName: any = [{ label: '-', value: '-' }]

            let nameFormating = optionDistrict.ward.sort((a: any, b: any) => {
              return a.name.localeCompare(b.name)
            })
            let removeBlankSpace = false
            nameFormating.forEach((dist: any) => {
              removeBlankSpace = true
              districtName.push({ label: dist.name, value: dist.id })
            })
            if (removeBlankSpace) {
              districtName.shift()
            }
            options.push({
              label: district,
              options: districtName,
            })
          })
        }

        // options.push({
        //     label: option.name,
        //     value: option.id
        // })
      })
    }

    if (districtData.length > 0) {
      // setDistrictData(districtData.concat(options))
    } else {
      // setDistrictData(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchGroupStreetsEdit = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const regionApi = `${apiBaseURL()}master/region_district_ward/list/?ordering=name&search=${search}&limit=${wardGroupLimit}&offset=${wardGroupOffset}`

    const response = await getRequest(regionApi, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setWardGroupOffset(newOffset)
      hasMore = true
    }

    if (districtData.length == 0) {
      // options.push({
      //     label: "All Regions",
      //     value: ""
      // })
    }

    if (response.results.length > 0) {
      let district = ''

      response.results.map((option: any) => {
        let districtName: any = [{ label: '-', value: '-' }]
        if (option.district.length !== 0) {
          district = option.district[0].name
          let nameFormating = option.district[0].ward.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name)
          })
          let removeBlankSpace = false
          nameFormating.forEach((dist: any) => {
            removeBlankSpace = true
            districtName.push({ label: dist.name, value: dist.id })
          })
          if (removeBlankSpace) {
            districtName.shift()
          }
          options.push({
            label: district,
            options: districtName,
          })
        }

        // options.push({
        //     label: option.name,
        //     value: option.id
        // })
      })
    }

    if (districtData.length > 0) {
      // setDistrictData(districtData.concat(options))
    } else {
      // setDistrictData(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  return (
    <>
      <EATitle title='Street' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>{intl.formatMessage({ id: 'Streets' })}</PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('STREET.ADD')
            : messageHandler === 'successEdit'
              ? FormatMessage('STREET.UPDATE')
              : FormatMessage('KML.ADD')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar d-flex g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => searchList(e)} />
            <form
              onSubmit={(e) => e.preventDefault()}
              autoComplete='off'
              className='ms-auto d-flex'
            >
              <AsyncPaginate
                    
                loadOptions={fetchWards}
                isSearchable
                className={`react-select-container w-110px me-3 ms-md-auto`}
                classNamePrefix='react-select'
                placeholder='All Wards'
                value={getWardList.filter((option: any) => option.value === streetWard.value)}
                onChange={changeWard}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              <AsyncPaginate
                    
                loadOptions={fetchDistricts}
                isSearchable
                className={`react-select-container w-120px me-3 `}
                classNamePrefix='react-select'
                placeholder='All Districts'
                value={getDistrictsList.filter(
                  (option: any) => option.value === streetDistrict.value
                )}
                onChange={changeDistrict}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              <AsyncPaginate
                    
                loadOptions={fetchRegion}
                isSearchable
                className={`react-select-container w-120px me-3 `}
                classNamePrefix='react-select'
                placeholder='All Regions'
                value={getRegionsList.filter((option: any) => option.value === streetRegions.value)}
                onChange={changeRegion}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </form>
            {/* // */}
            {/* <DropDownWard getWardsList={getWardList} changeWard={changeWard} /> */}
            {/* <DropDownDistricts getDistrictsList={getDistrictsList} changeDistrict={changeDistrict} /> */}
            {/* <DropDownRegions getRegionsList={getRegionsList} changeRegion={changeRegion} /> */}
            {permission.write && (
              <div className='w-auto'>
                <EaButton
                  isModal={true}
                  dataTarget='#ea_modal_create_street'
                  btnName='Add Street'
                  onClick={() => {
                    setMessageHandler('successAdd')
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0  ? (
              <NoRecords />
            ) : (
              <TableStreets
                showDelete={permission.delete}
                showWrite={permission.write}
                getLength={getLength}
                tableList={getList}
                getPropsEdit={getProps}
                getKmlProps={getKmlProps}
              />
            )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
              } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>

      {/* add street */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        id='ea_modal_create_street'
        modalTitle='Add Street'
        cancel={clearFunctionStreet}
        closeAuto='ea_modal_close_add_Street'
        onSubmit={() => addFunctionStreet()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Street Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='streetName'
            className={clsx('form-control', streetValidation)}
            placeholder='Type here...'
            value={streetName}
            onChange={(e) => {
              setStreetName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setSvalidation(validation)
            }}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='districtValidation'>
            Select Ward<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchGroupStreets}
            isSearchable
            placeholder='Select'
            className={`react-select-container w-100 ms-md-auto ${districtValidation}`}
            classNamePrefix='react-select'
            name='role'
            // value={selectWard.value !== '-' ? selectWard : setDModal('')}
            value={districtData.filter((option: any) => {
              if (option.value === '-') {
                return setDModal('')
              } else {
                return option.value === selectWard
              }
            })}
            onChange={(e: any) => {
              setDModal(e.value)
              let event = e
              if (event.length === 0) {
                event = ''
              }
              const validation = ValidationHandler(event, 'is-invalid')
              setDsitrictValidation(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className=''>
          <label className='form-label'>KML File</label>
          <label htmlFor='kml-file' className='form-control file-input'>
            <span className='text-muted'>{kmlFileName}</span>
          </label>
          <input type='file' id='kml-file' onChange={selectKmlFile} />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* edit street */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        id='ea_modal_Edit_street'
        modalTitle='Edit Street'
        cancel={clearFunctionStreetEdit}
        closeAuto='ea_modal_close_Edit_Street'
        onSubmit={() => editFunctionStreet()}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Street Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='streetNameEdit'
            className={clsx('form-control', streetValidationEdit)}
            placeholder='Type here...'
            value={streetNameEdit}
            onChange={(e) => {
              setStreetNameEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setSvalidationEdit(validation)
            }}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='districtValidationEdit'>
            Select Ward<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchGroupStreetsEdit}
            isSearchable
            placeholder='Select'
            className={`react-select-container w-100 ms-md-auto ${districtValidationEdit}`}
            classNamePrefix='react-select'
            name='role'
            // value={editDis === true ? selectWardEdit : newValue}
            // value={selectWardEdit}
            value={districtData.filter((option: any) => {
              if (option.value === '-') {
                return setDModalEdit('')
              } else {
                return option.value === selectWardEdit
              }
            })}
            onChange={(e: any) => {
              setDModalEdit(e.value)
              setDis(true)
              let event = e
              if (event.length === 0) {
                event = ''
              }
              const validation = ValidationHandler(event, 'is-invalid')
              setDsitrictValidationEdit(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
          {/* <Select
                        options={districtData}
                        onChange={(e: any) => {
                            setDModalEdit(e)
                            setDis(true)
                            let event = e
                            if (event.length === 0) {
                                event = ""
                            }
                            const validation = ValidationHandler(event, "is-invalid")
                            setDsitrictValidationEdit(validation)
                        }}
                        value={editDis === true ? selectWardEdit : newValue}
                        className={
                            clsx('react-select-container', districtValidationEdit)
                        }
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#0c78a4',
                            },
                        })}
                    /> */}
        </div>
        <div className='position-relative'>
          <div className='d-flex justify-content-between'>
            <label className='form-label me-2'>KML File</label>
            <a
              href={sKmlLink}
              className='text-hover-primary text-decoration-underline text-hover-decoration-underline text-truncate'
              target='_blank'
            >
              {sKml}
            </a>
          </div>
          <label htmlFor='kml-file-edit' className='form-control file-input'>
            <span className='text-muted'>{kmlFileNameEdit}</span>
          </label>
          <input type='file' id='kml-file-edit' onChange={selectKmlFileEdit} />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionKML}
        id='ea_modal_create_kmlfile'
        modalTitle='Add KML File'
        closeAuto='ea_modal_close_kml_region'
        onSubmit={() => kmlFunction()}
      >
        <div className='mb-4'>
          <label className='form-label'>
            KML File<span className="text-danger">*</span>
          </label>
          <label htmlFor='kml-file' className={clsx('form-control file-input', kmlValidationEdit)}>
            <span className='text-muted'>{kmlFileNameKml}</span>
          </label>
          <input
            type='file'
            className={clsx('form-control')}
            placeholder='Type here...'
            //
            onChange={selectKmlFileKml}
            id='kml-file'
          />
        </div>
      </EaModal>
    </>
  )
}

export default Streets
