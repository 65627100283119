import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import { KTSVG } from "../../../../_eaFruitsDms/helpers";
import { categoryStyle } from "../../../../_eaFruitsDms/helpers/components/categoryStyle";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";

type Props = {
    className: string
}
const fetchBrands = async (search: any) => {
    let options: any = []
    options.push(
        {
            label: 'All Brand',
            value: 'all',
        },
        {
            label: 'Onja',
            value: 'onja',
        },
        { value: 'Golden-Banana', label: 'Golden Banana' })
    return {
        options: options,
        hasMore: false,
    }
}

const fetchCategory = async (search: any) => {
    let options: any = []
    options.push(
        {
            label: 'All Categories',
            value: '',
        },
        {
            label: "Grains",
            options: [
                { label: "Rices", value: "rices" },
                { label: "Beans", value: "beans" }
            ]
        },
        {
            label: "Fruits",
            options: [
                { label: "Fresh Produce", value: "Fruits" },
                { label: "Fresh Produce 2", value: "Fruits" }
            ]
        },)
    return {
        options: options,
        hasMore: false,
    }

}

const fetchProductsGroups = async (search?: any) => {
    let options: any = []
    options.push(
        {
            label: 'All Product Group',
            value: 'all',
        },
        {
            label: 'Rice',
            value: 'Rice',
        })
    return {
        options: options,
        hasMore: false,
    }
}
const ReconciliationReport: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Reconciliation Report"
    }
    return (
        <>
            <EATitle title='Reconciliation Report' />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Reconciliation Report' })}</PageTitle>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: '01/01/2020',
                                        endDate: '01/12/2022',
                                        alwaysShowCalendars: true,
                                        cancelClass: 'btn-secondary',
                                        singleDatePicker: true,
                                        drops: "auto",
                                        locale: {
                                            format: 'DD/MM/YYYY'
                                        },
                                    }}
                            >
                                <input type="text" className="form-control calendar my-1 me-3 w-140px" />
                            </DateRangePicker>
                            <AsyncPaginate
                    
                                loadOptions={fetchBrands}
                                isSearchable
                                className='react-select-container my-1 me-3 w-120px'
                                classNamePrefix='react-select'
                                placeholder='All Brands'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <AsyncPaginate
                    
                                loadOptions={fetchCategory}
                                isSearchable
                                className='react-select-container my-1 me-3 w-160px'
                                classNamePrefix='react-select'
                                styles={categoryStyle}
                                placeholder='All Categories'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <AsyncPaginate
                    
                                loadOptions={fetchProductsGroups}
                                isSearchable
                                className='react-select-container my-1 w-170px me-3'
                                classNamePrefix='react-select'
                                placeholder='All Product Groups'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">
                                <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                Export</button>
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive expandable' style={{ 'transition': '.5s linear all', 'borderCollapse': 'collapse' }} id="expandTable">
                        <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                            <thead>
                                <tr className=' text-muted fw-500'>
                                    <th className='min-w-110px py-2'>Product SKU</th>
                                    <th className='min-w-100px py-2'>Brand</th>
                                    <th className='min-w-120px py-2'>Category</th>
                                    <th className='min-w-130px py-2'>Product Group</th>
                                    <th className='min-w-100px py-2'>Variant</th>
                                    <th className='min-w-100px py-2'>Size</th>
                                    <th className='min-w-100px py-2'>Start Qty.</th>
                                    <th className='min-w-100px py-2'>Stock-In</th>
                                    <th className='min-w-100px py-2'>Sold(Invoiced)</th>
                                    <th className='min-w-100px py-2'>Missing</th>
                                    <th className='min-w-100px py-2'>Customer Return</th>
                                    <th className='min-w-100px py-2'>EOD Qty.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    data-bs-toggle="collapse"
                                    data-bs-target=".row-collapse1"
                                    aria-controls="rowCollapse1"
                                    className='cursor-pointer'
                                >
                                    <td>Onja Rice 1kg</td>
                                    <td>Onja</td>
                                    <div>
                                        <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                        Rice
                                    </div>
                                    <td>Rice</td>
                                    <td>Standard</td>
                                    <td>1kg</td>
                                    <td>601</td>
                                    <td>0</td>
                                    <td>203</td>
                                    <td>986</td>
                                    <td>10</td>
                                    <td>265</td>
                                </tr>
                                <tr className='expandable-row'>
                                    <td className="reset-expansion-style" colSpan={12}>
                                        <div className='row-expansion-style collapse row-collapse1 collapsible' id="rowCollapse1" data-bs-parent="#expandTable">
                                            <div>
                                                <table className='table table-bordered gs-4 mb-0'>
                                                    <thead>
                                                        <tr className='text-muted fw-500 bg-light2'>
                                                            <th className='w-200px py-2'>Batch Number</th>
                                                            <th className='w-120px py-2'>Qty.</th>
                                                            <th className='min-w-120px py-2'>Expiry Date</th>
                                                            <th className='min-w-120px py-2'>Start Qty.</th>
                                                            <th className='min-w-120px py-2'>Stock-In</th>
                                                            <th className='min-w-120px py-2'>Sold</th>
                                                            <th className='min-w-120px py-2'>Return</th>
                                                            <th className='min-w-120px py-2'>EOD Qty.</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>#15177</td>
                                                            <td>491</td>
                                                            <td>28 May 2022</td>
                                                            <td>344</td>
                                                            <td>0</td>
                                                            <td>540</td>
                                                            <td>248</td>
                                                            <td>466</td>
                                                        </tr>
                                                        <tr>
                                                            <td>#15177</td>
                                                            <td>491</td>
                                                            <td>28 May 2022</td>
                                                            <td>344</td>
                                                            <td>0</td>
                                                            <td>540</td>
                                                            <td>248</td>
                                                            <td>466</td>
                                                        </tr>
                                                        <tr>
                                                            <td>#15177</td>
                                                            <td>491</td>
                                                            <td>28 May 2022</td>
                                                            <td>344</td>
                                                            <td>0</td>
                                                            <td>540</td>
                                                            <td>248</td>
                                                            <td>466</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {/* end first row expand */}
                                <tr
                                    data-bs-toggle="collapse"
                                    data-bs-target=".row-collapse2"
                                    aria-controls="rowCollapse2"
                                    className='cursor-pointer'
                                >
                                    <td>Onja Rice 5kg</td>
                                    <td>Onja</td>
                                    <div>
                                        <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                        Rice
                                    </div>
                                    <td>Rice</td>
                                    <td>Standard</td>
                                    <td>5kg</td>
                                    <td>601</td>
                                    <td>0</td>
                                    <td>203</td>
                                    <td>986</td>
                                    <td>10</td>
                                    <td>265</td>
                                </tr>
                                <tr className='expandable-row'>
                                    <td className="reset-expansion-style" colSpan={12}>
                                        <div className='row-expansion-style collapse row-collapse2 collapsible' id="rowCollapse2" data-bs-parent="#expandTable">
                                            <div>
                                                <table className='table table-bordered gs-4 mb-0'>
                                                    <thead>
                                                        <tr className='text-muted fw-500 bg-light2'>
                                                            <th className='w-200px py-2'>Batch Number</th>
                                                            {/* <th className='w-120px py-2'>Qty.</th> */}
                                                            <th className='min-w-120px py-2'>Expiry Date</th>
                                                            <th className='min-w-120px py-2'>Start Qty.</th>
                                                            <th className='min-w-120px py-2'>Stock-In</th>
                                                            <th className='min-w-120px py-2'>Sold</th>
                                                            <th className='min-w-120px py-2'>Return</th>
                                                            <th className='min-w-120px py-2'>EOD Qty.</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className='bg-light-danger'>
                                                            <td colSpan={7}>
                                                                # Qty are reported missing from this SKU so batch wise stock might be inaccurate for this date.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>#15177</td>
                                                            {/* <td>491</td> */}
                                                            <td>28 May 2022</td>
                                                            <td>344</td>
                                                            <td>0</td>
                                                            <td>540</td>
                                                            <td>248</td>
                                                            <td>466</td>
                                                        </tr>
                                                        <tr>
                                                            <td>#15177</td>
                                                            {/* <td>491</td> */}
                                                            <td>28 May 2022</td>
                                                            <td>344</td>
                                                            <td>0</td>
                                                            <td>540</td>
                                                            <td>248</td>
                                                            <td>466</td>
                                                        </tr>
                                                        <tr>
                                                            <td>#15177</td>
                                                            {/* <td>491</td> */}
                                                            <td>28 May 2022</td>
                                                            <td>344</td>
                                                            <td>0</td>
                                                            <td>540</td>
                                                            <td>248</td>
                                                            <td>466</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {/* end second row expand */}
                                <tr>
                                    <td>Onja Rice 20kg</td>
                                    <td>Onja</td>
                                    <div>
                                        <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                        Rice
                                    </div>
                                    <td>Rice</td>
                                    <td>Standard</td>
                                    <td>20kg</td>
                                    <td>601</td>
                                    <td>0</td>
                                    <td>203</td>
                                    <td>986</td>
                                    <td>10</td>
                                    <td>265</td>
                                </tr>
                                <tr>
                                    <td>Onja Rice 1kg</td>
                                    <td>Onja</td>
                                    <div>
                                        <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                        Rice
                                    </div>
                                    <td>Rice</td>
                                    <td>Standard</td>
                                    <td>1kg</td>
                                    <td>601</td>
                                    <td>0</td>
                                    <td>203</td>
                                    <td>986</td>
                                    <td>10</td>
                                    <td>265</td>
                                </tr>
                                {/* end third row expand */}
                                <tr>
                                    <td>Onja Rice 25kg</td>
                                    <td>Onja</td>
                                    <td>
                                        <div>
                                            <div className='text-muted font-13 mb-1 font-medium'>Grains</div>
                                            Rice
                                        </div>
                                    </td>
                                    <td>Rice</td>
                                    <td>Standard</td>
                                    <td>25kg</td>
                                    <td>601</td>
                                    <td>0</td>
                                    <td>203</td>
                                    <td>986</td>
                                    <td>10</td>
                                    <td>265</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReconciliationReport
