import moment from 'moment'
import {useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import {staticSearch} from '../CommonHelpers'

interface Props {
  value: any
  handleFilterChange: any
  isDisabled: boolean
  option: any
  className?: string
}

export const StaticFilter = ({value, handleFilterChange, isDisabled, className, option}: Props) => {
  const [reset] = useState<any>(moment())

  const fetchRecords = async (search?: any) => {
    let options = option

    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options: options,
      hasMore: false,
    }
  }

  return (
    <>
      <AsyncPaginate
        key={reset}
        loadOptions={fetchRecords}
        isDisabled={isDisabled}
        isSearchable
        className={`react-select-container ${className ? className : 'my-1 me-3 w-160px'}`}
        classNamePrefix='react-select'
        placeholder={'Select'}
        value={value}
        onChange={(e: any) => handleFilterChange(e, 'static_filter')}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#0c78a4',
          },
        })}
      />
    </>
  )
}
