import { useNavigate } from "react-router-dom"
import { KTSVG } from "../../../../_eaFruitsDms/helpers"
import tenantConfiguration from "../../../../TenantVariables"

export const TeamMemberTable = (props: any) => {

    const navigate = useNavigate()
    const GeneralManager = (val: any) => {
        let id = val.id
        localStorage.setItem('teamId', id)
        navigate('/team-members/member-detail/overview')
    }

    return (
        <div className='table-responsive'>
            <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                    <tr className='text-muted fw-500'>
                        <th className='min-w-150px'>Name</th>
                        <th className='min-w-140px'>Email</th>
                        <th className='min-w-120px'>Mobile Number</th>
                        <th className='min-w-120px'>Role</th>
                        <th className='min-w-120px'>Status</th>
                        <th className='min-w-80px action-th py-2'>{props.showWrite && "Actions"}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.list.length !== 0 && props.list.map((item: any) => {
                        let fName = item.first_name
                        let lName = item.last_name
                        let concatName = fName.charAt(0) + lName.charAt(0)
                        return (
                            <tr onClick={() => GeneralManager(item)} key={item.id} className='cursor-pointer'>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        {item.profile_img === null ? (
                                            <span className='rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10'>
                                                {concatName}
                                            </span>
                                        ) : (
                                            <img
                                                src={item.profile_img}
                                                className='rounded-circle pro-icon me-1 d-inline-block text-dark font-10'
                                            />
                                        )}
                                        <div className='media-body'>
                                            <p className='m-0 xs-font'>{fName + ' ' + lName}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>{item.email}</td>
                                <td>{item.phone_no && tenantConfiguration.baseCountryCode} {item.phone_no}</td>
                                <td className='text-dark'>{item.role === null ? '-' : item.role.name}</td>
                                <td>
                                    {item.is_active ? (
                                        <span className='badge badge-light-success text-dark'>Active</span>
                                    ) : (
                                        <span className='badge badge-secondary text-dark'>Inactive</span>
                                    )}
                                </td>
                                <td className='text-end'>
                                    {props.showWrite && 
                                    <span
                                    className='btn py-0 ps-1 pe-0 btn-active-color-primary btn-sm'
                                    data-bs-toggle='modal'
                                    data-bs-target='#ea_modal__user'
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        props.getProps(item)
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                </span>}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
