import React, { useState } from "react"
import { reSendEmail } from "../../../../../_eaFruitsDms/helpers"
import { EaAlertSuccess } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import tenantConfiguration from "../../../../../TenantVariables"

const Overview = (props: any) => {
  // const Attendance: React.FC = () => {
  // const intl = useIntl()
  // const teamHeaderTitle = {
  //   title: 'Team members',
  //   subTitle: 'Connor Lawson',
  // }
  const teamMembersTitle = {
    title: 'Team members',
    subTitle: props.fullname,
  }

  const { name, phone, email, gender, bDate, warehouse, position, supervisor, userVerified } = props.userDetails
  const userType = props.userType
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState('Resend Verification Email')

  const handleValidate = () => {
    if(isLoading === "Please Wait...") {
      return false
    }
    setIsLoading('Please Wait...')
    reSendEmail(email).then((res: any) => {  
      if(res.status == 200)
      setIsLoading('Resend Verification Email')
      setIsAlertSuccess(true)

      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)
     
    })
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  return (
    <>
      {/* <PageTitle breadcrumbs={[teamHeaderTitle]}>
        {intl.formatMessage({id: 'Connor Lawson'})}
      </PageTitle> */}
      <PageTitle breadcrumbs={[teamMembersTitle]}>{props.fullname}</PageTitle>
      <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={isAlertSuccess}
          message={`Verification email has been resent to ${email}.`}
        />
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-4'>
          <div className='row mb-6'>
            <label className='col-lg-4 font-14 text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='font-14 text-dark'>{name}</span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 font-14 text-muted'>Mobile Number</label>

            <div className='col-lg-8 fv-row'>
              <a href='#' className='font-14 text-dark'>
                {phone && tenantConfiguration.baseCountryCode} {phone}
              </a>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 font-14 text-muted'>Email</label>

            <div className='col-lg-8 fv-row'>
              <a href='#' className='font-14 text-dark'>
                {email}
              </a>
              {!userVerified && 
                 <button
                    className='btn btn-link ms-3 p-0 '
                    type='button'
                    onClick={handleValidate}
                >
                    {isLoading}
                  </button>
              }              
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 font-14 text-muted'>Gender</label>

            <div className='col-lg-8 fv-row'>
              <span className='font-14 text-dark'>{gender}</span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 font-14 text-muted'>Date of Birth</label>

            <div className='col-lg-8'>
              <span className='font-14 text-dark'>
                {/* 11 July 1993 */}
                {bDate}
              </span>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 font-14 text-muted'>Warehouse</label>
            <div className='col-lg-8'>
              <span className='font-14 text-dark'>{warehouse}</span>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 font-14 text-muted'>Position</label>
            <div className='col-lg-8'>
              <span className='font-14 text-dark'>{position}</span>
            </div>
          </div>
          {userType == 'SS' ? (
            <>
              {' '}
              <div className='row mb-6'>
                <label className='col-lg-4 font-14 text-muted'>Mapped Wards & Brands</label>
                <div className='col-lg-8'>
                  <span className='font-14 text-dark'>-</span>
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          {userType == 'SR' ? (
            <>
              <div className='row mb-6'>
                <label className='col-lg-4 font-14 text-muted'>Mapped Sales Supervisor</label>
                <div className='col-lg-8'>
                  <span className='font-14 text-dark'>{supervisor}</span>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 font-14 text-muted'>Aassigned Vehicle</label>
                <div className='col-lg-8'>
                  <span className='font-14 text-dark'>-</span>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
    
  )
}

export default Overview
