import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  fetchAsyncDropdown,
  getLoginWarehouse,
  isEmpty,
  KTSVG,
  numberFormat,
} from '../../../_eaFruitsDms/helpers'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageLink, PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {getRequest, patchRequest} from '../../../_eaFruitsDms/https/apiCall'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {AsyncPaginate} from 'react-select-async-paginate'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {Modal} from 'react-bootstrap'

type Props = {
  className: string
}
const GeneralKPI: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const kpiBreadCrumbs: Array<PageLink> = [
    {
      title: 'Target KPIs Settings',
      path: 'target-kpi-settings/general-kpi',
      isSeparator: false,
      isActive: false,
    },
  ]
  const [commissionButton, setCommissionButton] = useState('Submit')
  const [commissionButtonBulk, setCommissionButtonBulk] = useState('Save')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [listLoader, setListLoader] = useState<boolean>(false)
  const [listLoaderBulk, setListLoaderBulk] = useState<boolean>(false)
  const [permission, setPermission] = useState(defaultPermission)
  const [kpiSettings, setKpiSettings] = useState<any>([])
  const [kpiSettingsBulk, setKpiSettingsBulk] = useState<any>([])
  const [updateKpiSettingsBulk, setUpdateKpiSettingsBulk] = useState<any>([])
  const [commission, setCommission] = useState<any>([])
  const [fetchingCommission, setFetchingCommission] = useState<any>(true)
  const [showNextMonthCommission, setShowNextMonthCommission] = useState(false)
  const [showNextMonthGeneral, setShowNextMonthGeneral] = useState(false)
  const [showNextMonthGeneralBulk, setShowNextMonthGeneralBulk] = useState(false)
  const [isNextMonthCommission, setIsNextMonthCommission] = useState(true)
  const [isNextMonthCommissionBulk, setIsNextMonthCommissionBulk] = useState(true)
  const [warehouseOffset, setWarehouseOffset] = useState('')
  const [warehouseList, setWarehouseList] = useState([])
  const [warehouseSearch, setWarehouseSearch] = useState('')
  const [warehouseCommission, setWarehouseCommission] = useState<any>(getLoginWarehouse())
  const [warehouseOffsetList, setWarehouseOffsetList] = useState('')
  const [warehouseGeneralList, setWarehouseGeneralList] = useState([])
  const [warehouseSearchList, setWarehouseSearchList] = useState('')
  const [warehouseGeneral, setWarehouseGeneral] = useState<any>(getLoginWarehouse())
  const [warehouseOffsetBulk, setWarehouseOffsetBulk] = useState('')
  const [warehouseGeneralListBulk, setWarehouseGeneralListBulk] = useState([])
  const [warehouseSearchBulk, setWarehouseSearchBulk] = useState('')
  const [warehouseGeneralBulk, setWarehouseGeneralBulk] = useState<any>(getLoginWarehouse())
  const [alertPopup, setAlertPopup] = useState(false)
  const [isInputChanged, setIsInputChanged] = useState(true)
  const [saveWarehouse, setSaveWarehouse] = useState<any>('')
  const [saveCheckBox, setSaveCheckBox] = useState<any>('')
  const [type, setType] = useState<any>('')
  const [isCurrentMonthApplyPopup, setCurrentMonthApplyPopup] = useState(false)

  // role filter state
  const [role, setRole] = useState({label: 'All Roles', value: ''})
  const [roleLoading, setRoleLoading] = useState(false)
  const [roleOffset, setRoleOffset] = useState('')
  const [roleList, setRoleList] = useState([])
  const [roleSearch, setRoleSearch] = useState('')

  // role bulk filter state
  const [roleBulk, setRoleBulk] = useState({label: 'All Roles', value: ''})
  const [roleLoadingBulk, setRoleLoadingBulk] = useState(false)
  const [roleOffsetBulk, setRoleOffsetBulk] = useState('')
  const [roleListBulk, setRoleListBulk] = useState([])
  const [roleSearchBulk, setRoleSearchBulk] = useState('')

  useEffect(() => {
    fetchPermission().then()
  }, [])

  const fetchGeneralKpis = async () => {
    setListLoader(true)

    const url = `${apiBaseURL()}master/kpi/settings/${warehouseGeneral && warehouseGeneral.value}/${
      role?.value ?? ''
    }/generals?show_future=${showNextMonthGeneral}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      setKpiSettings(response.result)
    }
    setListLoader(false)
  }

  const fetchGeneralKpisBulk = async () => {
    setListLoaderBulk(true)

    const url = `${apiBaseURL()}master/kpi/settings/${
      warehouseGeneralBulk && warehouseGeneralBulk.value
    }/${roleBulk?.value ?? ''}/generals/detail?show_future=${showNextMonthGeneralBulk}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      setKpiSettingsBulk(response.result)
      setUpdateKpiSettingsBulk(response.result)
    }
    setListLoaderBulk(false)
  }

  const fetchSingleCommission = async () => {
    setFetchingCommission(true)
    const param = 'show_future=' + showNextMonthCommission
    const url =
      `${apiBaseURL()}master/kpi/settings/${warehouseCommission ? warehouseCommission.value : ''}/${
        role?.value ?? ''
      }/commission-divisions?` + param

    const response = await getRequest(url, true)
    if (response && response.result) {
      setCommission(response.result)
    }

    setFetchingCommission(false)
  }

  const fetchPermission = async () => {
    let res = await checkPermissions('sales_reps_target')
    setPermission(res)
  }

  const onChange = (e: any, index: number) => {
    const value = e.target.value
    const validationKey = `valid_${index}`

    let updateCommission: any = [...commission]

    updateCommission[index]['commission_value'] = value

    if (!checkPercentage(value)) {
      updateCommission[index][validationKey] = 'is-invalid'
    } else {
      updateCommission[index][validationKey] = ''
    }

    setCommission(updateCommission)
    setIsInputChanged(false)
  }

  const onBulkChange = (e: any, salesTeam: any, index: number, salesIndex: number, item: any) => {
    let updateBulkValue = [...updateKpiSettingsBulk]

    const value = e.target.value
    const validationKey = `valid_${index + salesIndex}`

    const re = /^[0-9\b]+$/

    if (item.measurement_unit === 'count') {
      if (e.target.value === '' || re.test(e.target.value)) {
        updateBulkValue[index].sales_teams[salesIndex]['measurement_value'] = value
      }
    } else {
      updateBulkValue[index].sales_teams[salesIndex]['measurement_value'] = value
      if (!checkPercentage(value)) {
        updateBulkValue[index].sales_teams[salesIndex][validationKey] = 'is-invalid'
      } else {
        updateBulkValue[index].sales_teams[salesIndex][validationKey] = ''
      }
    }

    setUpdateKpiSettingsBulk(updateBulkValue)
    setIsInputChanged(false)
  }

  const checkTotal = () => {
    let checkValid = 0
    let isValid = true

    commission.forEach((value: any) => {
      if (isEmpty(value.commission_value) || value.commission_value === 0) isValid = false
      if (value.commission_value !== 0) checkValid += parseFloat(value.commission_value)
    })

    if (checkValid !== 100 || !isValid) {
      setErrorMessage(`The Total percentage isn't 100%. Please match 100%.`)
      isValid = false
    } else {
      setErrorMessage('')
    }

    return isValid
  }

  const updateCommission = async (type: string, isCurrentMonth: Boolean) => {
    if (checkTotal()) {
      setCommissionButton('Please Wait')
      const url =
        apiBaseURL() +
        `master/kpi/settings/${warehouseCommission && warehouseCommission.value}/${
          role?.value ?? ''
        }/commission-divisions/update`
      let kpiParameter: any = []

      commission.forEach((i: any) => {
        kpiParameter.push({
          kpi_parameter_id: i.kpi_parameter_id,
          commission_value: parseFloat(i.commission_value),
        })
      })

      const body = {
        kpis: kpiParameter,
        is_applicable_from_next_month:
          showNextMonthCommission || isNextMonthCommission ? true : false,
      }

      const response = await patchRequest(url, body, true)

      if (response.status === 200) {
        saveWarehouse !== '' && setWarehouseCommission(saveWarehouse)
        saveCheckBox !== '' && setShowNextMonthCommission(saveCheckBox)
        if (type === 'submit') {
          setMessage(
            isCurrentMonth
              ? `Your change(s) saved successfully! It may take some time while reflecting across all Sales Reps' Target KPIs & Incentives!`
              : 'Your Commission Distribution has been updated successfully.'
          )
          setIsAlertSuccess(true)
          setTimeout(() => {
            setIsAlertSuccess(false)
          }, 2000)
          $('#closeCommissionModal').trigger('click') // modal close
          fetchGeneralKpis()
        }
        setCommissionButton('Submit')
        setAlertPopup(false)
        setIsInputChanged(true)
        setCurrentMonthApplyPopup(false)
      }

      if (response.status === 400) {
        setCommissionButton('Submit')
      }
    } else {
      setAlertPopup(false)
      setCurrentMonthApplyPopup(false)
    }
  }

  const checkBulkValid = () => {
    let valid = true

    let bulkValue = [...updateKpiSettingsBulk]

    updateKpiSettingsBulk.forEach((item: any, index: any) => {
      item.sales_teams.forEach((i: any, salesIndex: number) => {
        const validationKey = `valid_${index + salesIndex}`
        let value = bulkValue[index].sales_teams[salesIndex]['measurement_value']
        if (item.measurement_unit === 'percentage') {
          if (!checkPercentage(value)) {
            bulkValue[index].sales_teams[salesIndex][validationKey] = 'is-invalid'
            valid = false
          } else {
            bulkValue[index].sales_teams[salesIndex][validationKey] = ''
          }
        }
      })
    })

    setUpdateKpiSettingsBulk(bulkValue)
    return valid
  }

  const updateBulkValue = async (type: string, isCurrentMonth: boolean) => {
    let bulkValue = [...updateKpiSettingsBulk]

    if (checkBulkValid()) {
      setCommissionButtonBulk('Please Wait')
      let kpisParam: any = []

      bulkValue.forEach((item) => {
        let salesTeam: any = []

        item.sales_teams.forEach((i: any) => {
          salesTeam.push({
            sales_team_id: i.sales_team_id,
            measurement_value:
              item.measurement_unit === 'percentage'
                ? parseFloat(i.measurement_value)
                : parseInt(i.measurement_value),
          })
        })

        kpisParam.push({
          id: item.id,
          sales_teams: salesTeam,
        })
      })

      const body = {
        kpis: kpisParam,
        is_applicable_from_next_month:
          showNextMonthGeneralBulk || isNextMonthCommissionBulk ? true : false,
      }

      const url =
        apiBaseURL() +
        `master/kpi/settings/${warehouseGeneralBulk && warehouseGeneralBulk.value}/${
          roleBulk?.value ?? ''
        }/generals/update`

      const response = await patchRequest(url, body, true)
      setCommissionButtonBulk('Save')

      if (response.status === 200) {
        saveWarehouse !== '' && setWarehouseGeneralBulk(saveWarehouse)
        saveCheckBox !== '' && setShowNextMonthGeneralBulk(saveCheckBox)
        if (type === 'submit') {
          setMessage(
            isCurrentMonth
              ? `Your change(s) saved successfully! It may take some time while reflecting across all Sales Reps' Target KPIs & Incentives!`
              : 'Your Target KPIs Settings has been updated successfully.'
          )
          setIsAlertSuccess(true)
          $('#closeBulkModal').trigger('click') // modal close
          setTimeout(() => {
            setIsAlertSuccess(false)
          }, 2000)
          fetchGeneralKpis()
        }
        setAlertPopup(false)
        setIsInputChanged(true)
        setCurrentMonthApplyPopup(false)
      }
    } else {
      setAlertPopup(false)
      setCurrentMonthApplyPopup(false)
    }
  }

  const fetchWarehouse = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffset,
      setWarehouseOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?parent_warehouse__id=${localStorage.getItem(
        'w_id'
      )}&is_parent=True&ordering=name`,
      search,
      setWarehouseSearch,
      false,
      false
    )
  }

  const fetchWarehouseGeneral = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffsetList,
      setWarehouseOffsetList,
      warehouseGeneralList,
      setWarehouseGeneralList,
      `warehouse/list?parent_warehouse__id=${localStorage.getItem(
        'w_id'
      )}&is_parent=True&ordering=name`,
      search,
      setWarehouseSearchList,
      false,
      false
    )
  }

  const fetchWarehouseGeneralBulk = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffsetBulk,
      setWarehouseOffsetBulk,
      warehouseGeneralListBulk,
      setWarehouseGeneralListBulk,
      `warehouse/list?parent_warehouse__id=${localStorage.getItem(
        'w_id'
      )}&is_parent=True&ordering=name`,
      search,
      setWarehouseSearchBulk,
      false,
      false
    )
  }

  const isAlertSHow = (val: any) => {
    if (val === 'success') {
      setIsAlertSuccess(false)
    }
    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }

  const checkPercentage = (num: any) => {
    const value = parseFloat(num)
    let valid = true
    if (isNaN(value) || value < 0 || value > 100) {
      valid = false
    }

    return valid
  }

  const isCommissionClear = () => {
    setIsNextMonthCommission(true)
    setShowNextMonthCommission(false)
    setWarehouseCommission(getLoginWarehouse())
    setIsInputChanged(true)
    setErrorMessage('')
    setSaveCheckBox('')
    setSaveWarehouse('')
  }

  const isBulkClear = () => {
    setIsNextMonthCommissionBulk(true)
    setShowNextMonthGeneralBulk(false)
    setWarehouseGeneralBulk(getLoginWarehouse())
    setSaveCheckBox('')
    setSaveWarehouse('')
  }

  const discardChanges = () => {
    if (type === 'target') {
      saveWarehouse !== '' && setWarehouseGeneralBulk(saveWarehouse)
      saveCheckBox !== '' && setShowNextMonthGeneralBulk(saveCheckBox)
    } else {
      saveWarehouse !== '' && setWarehouseCommission(saveWarehouse)
      saveCheckBox !== '' && setShowNextMonthCommission(saveCheckBox)
    }

    setAlertPopup(false)
    setIsInputChanged(true)
    setErrorMessage('')
    setTimeout(() => {
      setSaveWarehouse('')
      setSaveCheckBox('')
    }, 200)
  }

  const saveChanges = () => {
    if (type === 'target') {
      updateBulkValue('save', false)
    } else {
      updateCommission('save', false)
    }
  }

  const fetchRolesModal = (search?: any) => {
    return fetchAsyncDropdown(
      roleOffset,
      setRoleOffset,
      roleList,
      setRoleList,
      `auth/application/role/list/?ordering=name&user_type__in=sr`,
      search,
      setRoleSearch,
      false,
      false
    )
  }

  const fetchRolesModalBulk = (search?: any) => {
    return fetchAsyncDropdown(
      roleOffsetBulk,
      setRoleOffsetBulk,
      roleListBulk,
      setRoleListBulk,
      `auth/application/role/list/?ordering=name&user_type__in=sr`,
      search,
      setRoleSearchBulk,
      false,
      false
    )
  }

  const getFirstRole = async () => {
    setRoleLoading(true)

    const url = `${apiBaseURL()}auth/application/role/list/?ordering=name&user_type__in=sr`

    const response = await getRequest(url, true)

    if (response?.results && response?.results?.length > 0) {
      const data = response?.results[0]

      setRole({
        label: data?.name ?? 'Select',
        value: data?.id ?? '',
      })

      setRoleBulk({
        label: data?.name ?? 'Select',
        value: data?.id ?? '',
      })
    }

    setRoleLoading(false)
  }

  useEffect(() => {
    getFirstRole()
  }, [])

  useEffect(() => {
    if (isInputChanged && !isEmpty(role?.value)) {
      fetchSingleCommission()
    }
  }, [warehouseCommission, showNextMonthCommission, isInputChanged, role])

  useEffect(() => {
    if (!isEmpty(role?.value)) {
      fetchGeneralKpis()
    }
  }, [warehouseGeneral, showNextMonthGeneral, role])

  useEffect(() => {
    if (isInputChanged && !isEmpty(roleBulk?.value)) {
      fetchGeneralKpisBulk()
    }
  }, [warehouseGeneralBulk, showNextMonthGeneralBulk, isInputChanged, roleBulk])

  return (
    <>
      <EATitle title='Target KPIs Settings' />
      <div>
        <PageTitle breadcrumbs={kpiBreadCrumbs}>
          {intl.formatMessage({id: 'Target KPIs Settings '})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={message}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className={`card ${className}`}>
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <AsyncPaginate
                loadOptions={fetchWarehouseGeneral}
                className='react-select-container my-1 me-3 w-200px'
                classNamePrefix='react-select'
                value={warehouseGeneral}
                isDisabled={listLoader || roleLoading}
                onChange={setWarehouseGeneral}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchRolesModal}
                onChange={(e: any) => {
                  setRole(e)
                }}
                isSearchable
                isDisabled={listLoader || roleLoading}
                placeholder='Select'
                value={role}
                className={`react-select-container w-160px`}
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                <form
                  className='card-toolbar d-flex my-0'
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete='off'
                >
                  <div className='form-check form-check-sm form-check-custom form-check-solid my-1 me-3'>
                    <input
                      className='form-check-input widget-13-check me-2'
                      type='checkbox'
                      id='generalList'
                      onChange={(e: any) => {
                        setShowNextMonthGeneral(e.target.checked)
                      }}
                    />
                    <label htmlFor='generalList'>Show Applicable from Next Month</label>
                  </div>
                  <div className='me-3'>
                    {permission.write && (
                      <EaButton
                        isModal={true}
                        dataTarget='#ea_modal_edit_KPI'
                        btnName='Edit Commission'
                        onClick={() => {
                          fetchSingleCommission()
                          setType('commission')
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {permission.write && (
                      <EaButton
                        isModal={true}
                        dataTarget='#ea_modal_edit_target-kpi'
                        btnName='Edit Target'
                        onClick={() => {
                          fetchGeneralKpisBulk()
                          setType('target')
                        }}
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className='card-body py-0 pb-3'>
            <div className='table-responsive'>
              {listLoader ? (
                <FetchingRecords />
              ) : kpiSettings.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-250px py-2'>KPIs</th>
                      {kpiSettings &&
                        kpiSettings.length >= 1 &&
                        kpiSettings[0].sales_teams.map((item: any) => {
                          return <th className='min-w-220px py-2'>{item.name}</th>
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {kpiSettings &&
                      kpiSettings.length > 0 &&
                      kpiSettings.map((item: any) => {
                        return (
                          <tr>
                            <td>{item.display_name}</td>
                            {item.sales_teams.map((i: any) => {
                              return (
                                <td>
                                  {
                                    numberFormat(
                                      i.measurement_value,
                                      item.measurement_unit === 'percentage' ? true : false
                                    ).displayFormat
                                  }
                                  {i.sales_team_id === 0
                                    ? '%'
                                    : item.measurement_unit === 'percentage'
                                    ? '%'
                                    : ''}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Commission Modal Start */}
      <EaModal
        id='ea_modal_edit_KPI'
        modalTitle='Update Commission Distribution'
        subTitle={role?.label ?? ''}
        closeAuto='closeCommissionModal'
        cancel={isCommissionClear}
        onSubmit={() => {
          if (isNextMonthCommission) {
            updateCommission('submit', false)
          } else {
            checkTotal() && setCurrentMonthApplyPopup(true)
          }
        }}
        actionBtnName={commissionButton}
        loader={fetchingCommission}
        btnDisabled={commissionButton !== 'Submit' ? true : false}
      >
        <div>
          <div className='form-check form-check-sm form-check-custom form-check-solid mb-4'>
            <input
              className='form-check-input widget-13-check me-2'
              type='checkbox'
              id='commissionUpdate'
              onChange={(e: any) => {
                if (!isInputChanged) {
                  setAlertPopup(true)
                  setSaveCheckBox(e.target.checked)
                } else {
                  setShowNextMonthCommission(e.target.checked)
                }
              }}
              checked={showNextMonthCommission}
            />
            <label htmlFor='commissionUpdate'>Show Applicable from Next Month</label>
          </div>
          <AsyncPaginate
            loadOptions={fetchWarehouse}
            className='react-select-container mb-4 w-200px'
            classNamePrefix='react-select'
            placeholder='Warehouse HQ'
            value={warehouseCommission}
            onChange={(e) => {
              if (!isInputChanged) {
                setAlertPopup(true)
                setSaveWarehouse(e)
              } else {
                setWarehouseCommission(e)
              }
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
          <p className='mb-4'>
            Total of the commission divisions between these KPIs must be always 100%.
          </p>
          <table className='table table-borderless gy-1 mb-4'>
            {commission &&
              commission.length > 0 &&
              commission.map((item: any, index: any) => {
                const isValid = `valid_${index}`
                return (
                  <tr>
                    <td className='w-200px'>{item.kpi_parameter_display_name} (in %)</td>
                    <td>
                      <div className='p-1'>
                        <div className='input-group  p-0'>
                          <input
                            type='number'
                            onChange={(e: any) => onChange(e, index)}
                            id={item.kpi_parameter_id}
                            value={item.commission_value === 0 ? '' : item.commission_value}
                            placeholder={'0'}
                            className={`form-control border-start-0 ${item[isValid]}`}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
          </table>
          <div className='mb-3'>
            <ErrorHandler errorMessage={errorMessage} />
          </div>
          {!showNextMonthCommission && (
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input widget-13-check me-2'
                type='checkbox'
                checked={isNextMonthCommission}
                id='isNextMonthCommission'
                onChange={(e) => {
                  setIsNextMonthCommission(e.target.checked)
                }}
              />
              <label htmlFor='isNextMonthCommission'>
                Apply the change(s) from the next month!
              </label>
            </div>
          )}
        </div>
      </EaModal>

      {/* Edit Target Modal Start */}
      <div
        className='modal fade'
        id='ea_modal_edit_target-kpi'
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div className='modal-dialog modal-dialog-centered mw-470px modal-fullscreen'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h2 className='modal-title fw-bold'>Update KPIs Target</h2>
              <div className='ms-auto d-flex align-items-center'>
                <div className='d-flex'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-4'>
                    <input
                      className='form-check-input widget-13-check me-2'
                      type='checkbox'
                      id='bulkChecked'
                      onChange={(e) => {
                        if (!isInputChanged) {
                          setAlertPopup(true)
                          setSaveCheckBox(e.target.checked)
                        } else {
                          setShowNextMonthGeneralBulk(e.target.checked)
                        }
                      }}
                      checked={showNextMonthGeneralBulk}
                    />
                    <label htmlFor='bulkChecked'>Show Applicable from Next Month</label>
                  </div>
                  <AsyncPaginate
                    loadOptions={fetchWarehouseGeneralBulk}
                    className='react-select-container w-200px me-3'
                    classNamePrefix='react-select'
                    value={warehouseGeneralBulk}
                    onChange={(e) => {
                      if (!isInputChanged) {
                        setAlertPopup(true)
                        setSaveWarehouse(e)
                      } else {
                        setWarehouseGeneralBulk(e)
                      }
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />

                  <AsyncPaginate
                    loadOptions={fetchRolesModalBulk}
                    onChange={(e: any) => {
                      setRoleBulk(e)
                    }}
                    isSearchable
                    isDisabled={listLoaderBulk || roleLoading}
                    placeholder='Select'
                    value={roleBulk}
                    className={`react-select-container w-160px`}
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
                <div
                  onClick={isBulkClear}
                  className='btn p-1 mh-24 btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
                </div>
              </div>
            </div>
            {listLoaderBulk ? (
              <FetchingRecords />
            ) : kpiSettingsBulk.length === 0 ? (
              <NoRecords />
            ) : (
              <>
                <div className='modal-body py-3'>
                  <table
                    id='tableId'
                    className='table table-borderless gx-3 table-row-gray-100 align-middle gs-0 gy-3'
                  >
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-300px'>KPIs</th>
                        {kpiSettingsBulk &&
                          kpiSettingsBulk.length >= 1 &&
                          kpiSettingsBulk[0].sales_teams.map((item: any) => {
                            return <th className='min-w-250px w-auto'>{item.name}</th>
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {kpiSettingsBulk &&
                        kpiSettingsBulk.length > 0 &&
                        kpiSettingsBulk.map((item: any, index: number) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  type='text'
                                  className='form-control '
                                  value={
                                    updateKpiSettingsBulk &&
                                    updateKpiSettingsBulk[index] &&
                                    updateKpiSettingsBulk[index].display_name
                                  }
                                  disabled
                                />
                              </td>
                              {item.sales_teams.map((salesTeam: any, salesIndex: number) => {
                                let team =
                                  updateKpiSettingsBulk &&
                                  updateKpiSettingsBulk[index] &&
                                  updateKpiSettingsBulk[index].sales_teams[salesIndex]

                                const validationKey = `valid_${index + salesIndex}`
                                return (
                                  <td>
                                    {item.measurement_unit === 'count' ? (
                                      <input
                                        type='text'
                                        placeholder={salesTeam.measurement_value}
                                        className={`form-control ${team[validationKey]}`}
                                        onChange={(e) =>
                                          onBulkChange(e, team, index, salesIndex, item)
                                        }
                                        value={
                                          team && team.measurement_value !== 0
                                            ? team.measurement_value
                                            : ''
                                        }
                                      />
                                    ) : (
                                      <div className='p-1'>
                                        <div
                                          className={clsx(
                                            'input-group  p-0',
                                            team[validationKey] === 'is-invalid'
                                              ? 'is-invalid-grp'
                                              : ''
                                          )}
                                        >
                                          <input
                                            type='number'
                                            onChange={(e) =>
                                              onBulkChange(e, team, index, salesIndex, item)
                                            }
                                            placeholder={salesTeam.measurement_value}
                                            value={
                                              team && team.measurement_value !== 0
                                                ? team.measurement_value
                                                : ''
                                            }
                                            className={clsx('form-control border-start-0')}
                                          />
                                          <span className='input-group-text bg-light px-3 ms-0'>
                                            %
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
                <div className='modal-footer justify-content-between'>
                  <button
                    type='button'
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                    onClick={isBulkClear}
                    id='closeBulkModal'
                    disabled={commissionButtonBulk !== 'Save' ? true : false}
                  >
                    Cancel
                  </button>
                  <div className='d-flex align-items-center'>
                    {!showNextMonthGeneralBulk && (
                      <div className='form-check form-check-sm form-check-custom form-check-solid border-end pe-4 me-4'>
                        <input
                          className='form-check-input widget-13-check me-2'
                          type='checkbox'
                          checked={isNextMonthCommissionBulk}
                          id='isNextMonthCommissionBulk'
                          onChange={(e) => {
                            setIsNextMonthCommissionBulk(e.target.checked)
                          }}
                        />
                        <label htmlFor='isNextMonthCommissionBulk'>
                          Apply the change(s) from the next month!
                        </label>
                      </div>
                    )}
                    <button
                      disabled={commissionButtonBulk !== 'Save' ? true : false}
                      type='button'
                      className='btn btn-primary'
                      onClick={() => {
                        if (isNextMonthCommissionBulk) {
                          updateBulkValue('submit', false)
                        } else {
                          checkBulkValid() && setCurrentMonthApplyPopup(true)
                        }
                      }}
                    >
                      {commissionButtonBulk}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <>
        <Modal show={alertPopup} centered>
          <Modal.Header
            closeButton
            onClick={() => {
              setAlertPopup(false)
              setSaveWarehouse('')
              setSaveCheckBox('')
            }}
          >
            <Modal.Title>{''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Oh, it seems the Change(s) you have made to the form is not Saved yet! </b>
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={
                commissionButton !== 'Submit' || commissionButtonBulk !== 'Save' ? true : false
              }
              className='btn btn-secondary'
              onClick={discardChanges}
            >
              {'Discard Change(s)'}
            </button>
            <button
              className='btn btn-primary'
              onClick={saveChanges}
              disabled={
                commissionButton !== 'Submit' || commissionButtonBulk !== 'Save' ? true : false
              }
            >
              {commissionButton !== 'Submit' || commissionButtonBulk !== 'Save'
                ? 'Please Wait'
                : 'Save & Continue'}
            </button>
          </Modal.Footer>
        </Modal>
      </>

      <>
        <Modal show={isCurrentMonthApplyPopup} centered>
          <Modal.Header
            closeButton
            onClick={() => {
              setCurrentMonthApplyPopup(false)
            }}
          >
            <Modal.Title>{''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>
              The change(s) will impact the ongoing month's Target of all Sales Reps'
              KPIs/Incentives! Are you sure to apply them in the Current month?
            </b>
          </Modal.Body>
          <Modal.Footer
            style={{
              alignItems: 'center',
            }}
          >
            <button
              onClick={() => {
                setCurrentMonthApplyPopup(false)
              }}
              className='btn btn-light'
              disabled={
                commissionButton !== 'Submit' || commissionButtonBulk !== 'Save' ? true : false
              }
            >
              Cancel
            </button>
            <button
              disabled={
                commissionButton !== 'Submit' || commissionButtonBulk !== 'Save' ? true : false
              }
              onClick={() => {
                if (type === 'target') {
                  updateBulkValue('submit', true)
                } else {
                  updateCommission('submit', true)
                }
              }}
              className='btn btn-primary'
            >
              {commissionButton !== 'Submit' || commissionButtonBulk !== 'Save'
                ? 'Please Wait'
                : 'Yes, Continue'}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export default GeneralKPI
