import moment from 'moment'
import {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  checkUserType,
  dateFormat,
  dateTimeFormat,
  fetchAsyncDropdownNew,
  globalDateFormate,
  isAdmin,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'

const IdleTimeReport = () => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Idle Time Report',
  }

  const currentWarehouseId = isAdmin() ? '' : localStorage.getItem('w_id')

  const [warehouses, setWarehouses] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [currentOffSet, setCurrentOffSet] = useState<any>('0')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [customerLoader, setCustomerLoader] = useState<boolean>(false)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [warehouseOffSet, setWarehouseOffSet] = useState<number>(0)
  const [srOffSet, setSrOffSet] = useState<number>(0)
  const [sr, setSr] = useState<any>({
    label: 'All Sales Reps',
    value: '',
  })
  const [srReset, setSrReset] = useState<any>(moment())
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [filteredDate, setFilteredDate] = useState<any>({
    startDate: dateFormat(moment()).apiDate,
    endDate: dateFormat(moment()).apiDate,
  })
  const [warehouseId, setWarehouseId] = useState<any>({
    label: 'All Warehouses',
    value: '',
  })
  const [routeList, setRouteList] = useState<any>([])
  const [customerList, setCustomerList] = useState<any>([])
  const [route, setRoute] = useState<any>(null)

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchRoutes()
  }, [debounceSearch, warehouseId, sr, selected, currentOffSet, filteredDate])

  useEffect(() => {
    setWarehouseOffSet(0)
    setSrOffSet(0)
  }, [searchSelect])

  const fetchRoutes = async () => {
    setDisplayLoader(true)

    const startDate = filteredDate.startDate
    const endDate = filteredDate.endDate

    let selectedWarehouseId = isEmpty(warehouseId.value) ? '' : warehouseId.value

    const response = await getRequest(
      `${apiBaseURL()}routes/ideal-time-report?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&warehouse_id=${selectedWarehouseId}&sr_id=${
        isEmpty(sr.value) ? '' : sr.value
      }&date_range_after=${startDate}&date_range_before=${endDate}`,
      true
    )

    if (response?.results) {
      setCount(response?.count ?? '1')
      setRouteList(response?.results ?? [])
    } else {
      setRouteList([])
      setCount('1')
    }

    setDisplayLoader(false)
  }

  const fetchCustomers = async () => {
    setCustomerLoader(true)

    const startDate = filteredDate.startDate
    const endDate = filteredDate.endDate

    let selectedWarehouseId = isEmpty(warehouseId.value) ? '' : warehouseId.value

    let url = `${apiBaseURL()}routes/ideal-time-report/customers?date_range_after=${startDate}&date_range_before=${endDate}&route_id=${
      route?.route_id ?? ''
    }&warehouse_id=${selectedWarehouseId}&gate_pass_id=${route?.gate_pass_id ?? ''}`

    if (!isEmpty(sr.value)) {
      url += `&sr_id=${isEmpty(sr.value) ? '' : sr.value}`
    }

    const response = await getRequest(url, true)

    if (response?.results) {
      setCustomerList(response.results)
    } else {
      setCustomerList([])
      setRoute(null)
    }

    setCustomerLoader(false)
  }

  const fetchWarehouses = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=${currentWarehouseId}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSrs = async (search?: string) => {
    let selectedWarehouseId = isEmpty(warehouseId.value) ? '' : warehouseId.value

    if (checkUserType('oo')) {
      selectedWarehouseId = currentWarehouseId
    }

    return await fetchAsyncDropdownNew(
      srOffSet,
      setSrOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `reports/users/drop-down?ordering=first_name&warehouse_id=${selectedWarehouseId}&type=sr`,
      search,
      setSearchSelect,
      'full_name',
      '',
      true,
      'All Sales Reps'
    )
  }

  useEffect(() => {
    if (!isEmpty(route?.route_id)) {
      fetchCustomers()
    }
  }, [route])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-1'
            defaultValue={value}
            disabled={displayLoader || customerLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name

    switch (inputName) {
      case 'warehouse':
        setWarehouseId(e)
        setSalesRepsOption([])
        setSrReset(moment())
        break
      case 'search':
        setSearchByName(e.target.value)
        break
      case 'sr':
        setSr(e)
        break
    }
  }

  const activityType = (type: string) => {
    const typeEnum = [
      {value: 'gate_pass_exit_check', label: 'Gate Pass Exit Check'},
      {value: 'location_check', label: 'Location Check'},
      {value: 'make_a_sale', label: 'Make a Sale'},
      {value: 'mark_in_transit', label: 'Mark In Transit'},
      {value: 'start_delivery', label: 'Start Delivery'},
      {value: 'credit_request_send', label: 'Credit Request Send'},
      {value: 'credit_request_approved', label: 'Credit Request Approved'},
      {value: 'credit_request_rejected', label: 'Credit Request Rejected'},
      {value: 'discount_request_send', label: 'Discount Request Send'},
      {value: 'discount_request_approved', label: 'Discount Request Approved'},
      {value: 'discount_request_rejected', label: 'Discount Request Rejected'},
      {value: 'order_qty_request_send', label: 'Order QTY Request Send'},
      {value: 'order_qty_request_approved', label: 'Order QTY Request Approved'},
      {value: 'order_qty_request_rejected', label: 'Order QTY Request Rejected'},
      {value: 'order_limit_request_send', label: 'Order Limit Request Send'},
      {value: 'order_limit_request_approved', label: 'Order Limit Request Approved'},
      {value: 'order_limit_request_rejected', label: 'Order Limit Request Rejected'},
      {value: 'payment_received', label: 'Payment Received'},
      {value: 'signature', label: 'Signature'},
      {value: 'credit_collection', label: 'Credit Collection'},
      {value: 'cash_deposit', label: 'Cash Deposit'},
      {value: 'customer_creation', label: 'Customer Creation'},
      {value: 'gate_pass_entry_check', label: 'Gate Pass Entry Check'},
    ]

    return typeEnum.find((i) => i.value === type)?.label || '-'
  }

  const duration = (startDate: any, endDate: any) => {
    // Format both startDate and endDate using your dateFormat function
    const startTime = moment
      .utc(startDate)
      .tz(tenantConfiguration.timezone)
      .format('YYYY-MM-DD HH:mm:ss')

    const endTime = moment
      .utc(endDate)
      .tz(tenantConfiguration.timezone)
      .format('YYYY-MM-DD HH:mm:ss')

    // Parse the time strings back to moment objects in UTC
    const start = moment.utc(startTime, 'YYYY-MM-DD HH:mm:ss')
    const end = moment.utc(endTime, 'YYYY-MM-DD HH:mm:ss')

    // Calculate the difference in minutes
    const duration = moment.duration(end.diff(start))
    const minutes = duration.asMinutes()

    return `${Math.ceil(minutes)} Mins`
  }

  return (
    <>
      <EATitle title='Idle Time Report' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Idle Time Report'})}
      </PageTitle>
      <div>
        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={handleFilterChange}
              isDisable={displayLoader || customerLoader}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                {checkUserType(['sa', 'wm']) && (
                  <AsyncPaginate
                    loadOptions={fetchWarehouses}
                    isDisabled={displayLoader || customerLoader}
                    isSearchable
                    className='react-select-container my-1 me-3 w-170px'
                    classNamePrefix='react-select'
                    placeholder='All Warehouses'
                    value={warehouseId}
                    onChange={(e: any) => handleFilterChange(e, 'warehouse')}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
                <AsyncPaginate
                  key={srReset}
                  loadOptions={fetchSrs}
                  className='react-select-container my-1 me-3 w-180px'
                  classNamePrefix='react-select'
                  isDisabled={displayLoader || customerLoader}
                  placeholder='All Sales Reps'
                  onChange={(e: any) => handleFilterChange(e, 'sr')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <div className='w-250px'>
                  <CustomDatePickerComponent
                    onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                      setFilteredDate({
                        startDate: callBakeDate.startDate,
                        endDate: callBakeDate.endDate,
                      })
                    }}
                    value={filteredDate}
                    startDate={filteredDate.startDate}
                    endDate={filteredDate.endDate}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            <div
              className='table-responsive expandable'
              style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
              id='expandTable'
            >
              {displayLoader ? (
                <FetchingRecords />
              ) : routeList.length === 0 ? (
                <div className='mt-3'>
                  <NoRecords />
                </div>
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* Table Head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='w-150px min-w-150px'>Date</th>
                      <th className='w-150px min-w-150px'>Warehouse Name</th>
                      <th className='w-150px min-w-150px'>SR Name</th>
                      <th className='w-150px min-w-150px'>Sales Team Name</th>
                      <th className='w-150px min-w-220px'>Route Name</th>
                      <th className='w-150px min-w-150px'>Avg. Idle Time</th>
                      <th className='w-220px min-w-220px'>Total Idle Time Crossed</th>
                    </tr>
                  </thead>
                  {/* Table Body */}
                  <tbody>
                    {routeList.map((routeItem: any) => {
                      return (
                        <>
                          {/* Level 1 */}
                          <tr
                            data-bs-toggle='collapse'
                            data-bs-target={`.row-collapse${routeItem?.id ?? '-'}`}
                            aria-controls={`rowCollapse${routeItem?.id ?? '-'}`}
                            className='cursor-pointer'
                            onClick={() => {
                              if (isEmpty(routeItem?.route_id)) {
                                setCustomerList([])
                              }

                              setRoute(routeItem)
                            }}
                          >
                            <td>
                              {isEmpty(routeItem?.date_time)
                                ? '-'
                                : dateFormat(routeItem?.date_time, false, true).displayDate}
                            </td>
                            <td>{checkEmpty(routeItem?.warehouse_name)}</td>
                            <td>{checkEmpty(routeItem?.sr_name)}</td>
                            <td>{checkEmpty(routeItem?.sales_team_name)}</td>
                            <td>{checkEmpty(routeItem?.route_name)}</td>
                            <td>
                              {isEmpty(routeItem?.avg_ideal_time)
                                ? '-'
                                : `${routeItem?.avg_ideal_time} Mins`}
                            </td>
                            <td>{checkEmpty(routeItem?.total_ideal_time_crossed)}</td>
                          </tr>
                          <tr className='expandable-row'>
                            <td className='reset-expansion-style' colSpan={11}>
                              <div
                                className={`row-expansion-style collapse row-collapse${routeItem?.id} collapsible`}
                                id={`rowCollapse${routeItem?.id}`}
                                data-bs-parent='#expandTable'
                              >
                                {/* Level 3 */}
                                {customerLoader ? (
                                  <FetchingRecords />
                                ) : customerList?.length === 0 ? (
                                  <div className='mt-3'>
                                    <NoRecords />
                                  </div>
                                ) : (
                                  <div className='table table-bordered mb-0'>
                                    <div className='border bg-light text-muted fw-500 p-1 row'>
                                      <div className='col-md-3'>Customer Name</div>
                                      <div className='col-md-3'>Activity Name</div>

                                      <div className='col-md-3'>Date & Time</div>
                                      <div className='col-md-3'>Location</div>
                                    </div>

                                    {customerList.map((item: any) => (
                                      <div className='border-bottom border-dark'>
                                        <div className='text-muted fw-500 p-1 row'>
                                          <div className='col-md-3'>
                                            {isEmpty(item?.previous_activity_customer_name) ? (
                                              <>
                                                {isEmpty(item?.previous_activity_name)
                                                  ? '-'
                                                  : [
                                                      'gate_pass_entry_check',
                                                      'gate_pass_exit_check',
                                                    ].includes(item?.previous_activity_name)
                                                  ? checkEmpty(routeItem?.warehouse_name)
                                                  : '-'}
                                              </>
                                            ) : (
                                              <>
                                                {checkEmpty(item?.previous_activity_customer_name)}
                                              </>
                                            )}
                                          </div>

                                          <div className='col-md-3'>
                                            {activityType(item?.previous_activity_name)}
                                          </div>

                                          <div className='col-md-3'>
                                            {isEmpty(item?.previous_activity_time) ? (
                                              '-'
                                            ) : (
                                              <>{dateTimeFormat(item.previous_activity_time)}</>
                                            )}
                                          </div>

                                          <div className='col-md-3'>
                                            {!isEmpty(item?.previous_activity_customer_ward)
                                              ? `${item?.previous_activity_customer_ward}, `
                                              : '-'}

                                            {!isEmpty(item?.previous_activity_customer_street)
                                              ? item?.previous_activity_customer_street
                                              : '-'}
                                          </div>
                                        </div>

                                        <div className='mt-5'>
                                          <div
                                            className='text-center text-success'
                                            style={{
                                              position: 'relative',
                                              borderTopWidth: 1,
                                              borderTopColor: '#e8ebe9',
                                              borderTopStyle: 'solid',
                                            }}
                                          >
                                            <div
                                              style={{
                                                position: 'absolute',
                                                top: '-12px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                backgroundColor: 'white',
                                                padding: '0 10px',
                                              }}
                                            >
                                              {isEmpty(item?.previous_activity_time) &&
                                              isEmpty(item?.last_activity_time)
                                                ? '-'
                                                : duration(
                                                    item?.previous_activity_time,
                                                    item?.last_activity_time
                                                  )}
                                            </div>
                                          </div>
                                        </div>

                                        <div className='text-muted fw-500 p-1 row'>
                                          <div className='col-md-3'>
                                            {isEmpty(item?.last_activity_customer_name) ? (
                                              <>
                                                {isEmpty(item?.last_activity_name)
                                                  ? '-'
                                                  : [
                                                      'gate_pass_entry_check',
                                                      'gate_pass_exit_check',
                                                    ].includes(item?.last_activity_name)
                                                  ? checkEmpty(routeItem?.warehouse_name)
                                                  : '-'}
                                              </>
                                            ) : (
                                              <>{checkEmpty(item?.last_activity_customer_name)}</>
                                            )}
                                          </div>

                                          <div className='col-md-3'>
                                            {activityType(item?.last_activity_name)}
                                          </div>

                                          <div className='col-md-3'>
                                            {isEmpty(item?.last_activity_time)
                                              ? '-'
                                              : dateTimeFormat(item?.last_activity_time)}
                                          </div>

                                          <div className='col-md-3'>
                                            {!isEmpty(item?.last_activity_customer_ward)
                                              ? `${item?.last_activity_customer_ward}, `
                                              : '-'}

                                            {!isEmpty(item?.last_activity_customer_street)
                                              ? item?.last_activity_customer_street
                                              : '-'}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={routeList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffSet}
          totalRecords={count}
          limit={limit}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default IdleTimeReport
