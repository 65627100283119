import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  VariantsCreate,
  VariantsDelete,
  VariantsList,
  VariantsSingle,
  VariantsUpdate,
} from '../../../../_eaFruitsDms/apiFunctions/master/master'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  fetchAsyncDropdown,
  focusVatidateField,
  isModalValidation,
} from '../../../../_eaFruitsDms/helpers'
import capitalizeFirstLetter from '../../../../_eaFruitsDms/helpers/components/CapitalLetter'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import ValidationHandler from '../../../../_eaFruitsDms/helpers/components/validationHandler'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getToken} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import TableProductVariant from './components/tableProductVariant'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'

type Props = {
  className: string
}

const ProductVarients: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Product Variants',
  }

  // all states
  const [variantName, setVariantName] = useState('')
  const [apporxWeight, setApproxWeight] = useState('')
  const [groupName, setGroupName] = useState<any>({label: '', value: ''})
  const [groupNameList, setGroupNameList] = useState<any>([])
  const [getGropsModal, setGropsModal] = useState<any>([])
  const [groupResponse, setGroupResponse] = useState<any>([])
  const [selectedProductGroup, setSelectedProductGroup] = useState<string>('')
  const [getGropsModalList, setGropsModalList] = useState<any>([])
  const [getList, setGetList] = useState<any>([])
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [validationName, setValidationName] = useState('')
  const [validationGroup, setValidationGroup] = useState('')
  const [validationApproxWeight, setValidationApproxWeight] = useState('')
  const [validationNameEdit, setValidationNameEdit] = useState('')
  const [validationGroupEdit, setValidationGroupEdit] = useState('')
  const [catNameEdit, setCatNameEdit] = useState('')
  const [parentcatNameEdit, parentsetCatNameEdit] = useState<any>('')
  const [id, setId] = useState('')
  const [varNameDelete, setVarNameDelete] = useState('')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [count, setCount] = useState('')
  const [filterGroup, setfilterGroup] = useState({value: '', label: ''})
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [getLength, setLength] = useState('1')
  const [showWrite, setShowWrite] = useState<any>(false)
  const [showDelete, setShowDelete] = useState<any>(false)
  const [listLoadder, setListLoader] = useState<any>(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [searchSelect, setSearchSelect] = useState('')
  const [checkValue, setCheckValue] = useState<any>(false)
  const [checkValueEdit, setCheckValueEdit] = useState<any>(false)
  const [validationSelcetedstandardShelf, setValidationSetstandardShelf] = useState('')
  const [validationSelcetednearExpiry, validationSetNearExpiry] = useState('')
  const [selcetedstandardShelf, setstandardShelf] = useState('')
  const [selcetednearExpiry, setNearExpiry] = useState('')
  const [packageOffset, setPackageOffset] = useState('')
  const [packageList, setPackageList] = useState('')
  const [packageSearch, setPackageSearch] = useState('')
  const [packaging, setPackaging] = useState<any>('')
  const [packagingValidation, setPackagingValidation] = useState<any>('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [groupEdit, setGroupEdit] = useState<any>('')
  const [gropsModalFilter, setGropsModalFilter] = useState<any>([])
  const [groupFilterOffSet, setGroupFilterOffSet] = useState('')

  // Authorization token
  const header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }

  useEffect(() => {
    setGroupOffset('')
    setGroupFilterOffSet('')
  }, [searchSelect])

  const limit = '&limit=10000'

  useEffect(() => {
    fetchPermission()
    setListLoader(true)
    VariantsList('10', '0', search, filterGroup.value).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })

    // get groups in modal with name ordering

    //request method for api implementation :- last change by kriti

    getRequest(`${apiBaseURL()}master/product/group?ordering=name` + limit, header).then((res) => {
      let data = res.results
      let groups: any = []
      data.forEach((item: any) => {
        groups.push({label: item.name, value: item.id})
      })
      setGropsModalList(groups)
    })
  }, [])

  const fetchPermission = async () => {
    let res = await checkPermissions('masters_products')
    setPermission(res)
  }

  const addFunction = () => {
    let navigateField = {
      variantName: variantName,
      groupName: groupName.value,
      packaging: packaging,
      // catNameEdit: catNameEdit,
      selcetedstandardShelf: selcetedstandardShelf,
      selcetednearExpiry: selcetednearExpiry,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // ui validation [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (variantName.trim() == '') {
      setValidationName('is-invalid')
    }

    if (groupName.value == '') {
      setValidationGroup('is-invalid')
    }

    if (packaging === '') {
      setPackagingValidation('is-invalid')
    }

    if (selcetedstandardShelf === '') {
      setValidationSetstandardShelf('is-invalid')
    }

    if (selcetednearExpiry === '') {
      validationSetNearExpiry('is-invalid')
    }

    let isValid = true

    if (
      !['kilogram', 'gram', 'Kilogram', 'Gram'].includes(selectedProductGroup) &&
      apporxWeight == ''
    ) {
      setValidationApproxWeight('is-invalid')
      isValid = false
    }

    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (
      variantName.trim() !== '' &&
      groupName.value !== '' &&
      packaging !== '' &&
      selcetedstandardShelf !== '' &&
      selcetednearExpiry !== '' &&
      isValid
    ) {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      let packageValue = packaging.value
      VariantsCreate(
        variantName,
        groupName.value,
        apporxWeight,
        checkValue,
        packageValue,
        selcetedstandardShelf,
        selcetednearExpiry
      ).then((notify) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (notify.success) {
          clearFunctionAdd()
          setListLoader(true)
          VariantsList('10', '0', search, filterGroup.value).then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setListLoader(false)
            setSelected(0)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
          })
          $('#ea_close_create_product_variant').trigger('click') // modal close
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
          setIsAlertSuccess(true)
        }
        if (notify.failed) {
          // setIsAlertFailed(true)
        }

        if (notify.errorField == 'non_field_errors') {
          setErrorMessage(
            'Your added variant name already exists in this system! Please try again with a unique variant name.'
          )
        }
      })
    }
  }

  const clearFunctionAdd = () => {
    // clear add modal state
    setErrorMessage('')
    setVariantName('')
    setGroupName({label: '', value: ''})
    setApproxWeight('')
    setValidationGroup('')
    setValidationName('')
    setValidationApproxWeight('')
    setCheckValue(false)
    setPackaging('')
    setPackageOffset('')
    setValidationSetstandardShelf('')
    setNearExpiry('')
    setstandardShelf('')
    validationSetNearExpiry('')
    setPackagingValidation('')
    setGroupOffset('')
  }

  const clearFunctionEdit = () => {
    // clear edit modal state
    setCatNameEdit('')
    // setGroupName('')
    setGroupName({label: '', value: ''})
    setApproxWeight('')
    setErrorMessage('')
    setValidationGroupEdit('')
    setValidationNameEdit('')
    setValidationApproxWeight('')
    setCheckValueEdit(false)
    setPackaging('')
    setPackageOffset('')
    setValidationSetstandardShelf('')
    setNearExpiry('')
    setstandardShelf('')
    validationSetNearExpiry('')
    setPackagingValidation('')
    setGroupOffset('')
  }

  const getProps = (val: any) => {
    setMessageHandler('successEdit')
    setModalLoader(true)
    // getGroupName()
    setApproxWeight(val.approx_weight)
    setCatNameEdit(val.name)
    setId(val.id)
    setSelectedProductGroup(val.group_name.measurement_unit)
    setGroupEdit({label: val.group_name.name, value: val.group_name.id})
    // get single props
    VariantsSingle(val.id).then((res: any) => {
      setCatNameEdit(res.name)
      setCheckValueEdit(res.display_on_website)
      parentsetCatNameEdit(res.group_name)
      setNearExpiry(res.near_expiry)
      setstandardShelf(res.shelf_life)
      let material = res.material && {label: res.material.name, value: res.material.id}
      res.material && setPackaging(material)
      setModalLoader(false)
    })
  }

  // const getGroupName = async () => {
  //   // get grop list
  //   //request method for api implementation :- last change by kriti
  //   getRequest(`${apiBaseURL()}master/product/group?ordering=name`, header).then((res) => {
  //     setGroupNameList(res.results)
  //   })
  // }

  const updateFunction = () => {
    let navigateField = {
      catNameEdit: catNameEdit,
      parentcatNameEdit: parentcatNameEdit,
      variantName: variantName,
      groupName: groupName.value,
      packaging: packaging,

      selcetedstandardShelf: selcetedstandardShelf,
      selcetednearExpiry: selcetednearExpiry,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (catNameEdit == '') {
      setValidationNameEdit('is-invalid')
    }
    if (parentcatNameEdit == '') {
      setValidationGroupEdit('is-invalid')
    }

    if (packaging === '') {
      setPackagingValidation('is-invalid')
    }

    if (selcetedstandardShelf === '') {
      setValidationSetstandardShelf('is-invalid')
    }

    if (selcetednearExpiry === '') {
      validationSetNearExpiry('is-invalid')
    }

    let isValid = true

    if (
      !['kilogram', 'gram', 'Kilogram', 'Gram'].includes(selectedProductGroup) &&
      apporxWeight == ''
    ) {
      setValidationApproxWeight('is-invalid')
      isValid = false
    }

    if (
      catNameEdit !== '' &&
      parentcatNameEdit &&
      isValid &&
      packaging !== '' &&
      selcetedstandardShelf !== '' &&
      selcetednearExpiry !== ''
    ) {
      setDisableBtn(true)
      setIsLoading('Please Wait..')

      let packgingValue = packaging.value

      VariantsUpdate(
        catNameEdit,
        parentcatNameEdit,
        apporxWeight,
        checkValueEdit,
        packgingValue,
        selcetedstandardShelf,
        selcetednearExpiry,
        id
      ).then((notify) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (notify.success) {
          clearFunctionEdit()
          setListLoader(true)
          VariantsList('10', '0', search, filterGroup.value).then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setListLoader(false)
            setSelected(0)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
          })
          $('#ea_close_update_product_variants').trigger('click') // modal close
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
          setIsAlertSuccess(true)
        }
        if (notify.failed) {
          // setIsAlertFailed(true)
        }

        if (notify.errorField == 'non_field_errors') {
          setErrorMessage(
            'Your added variant name already exists in this system! Please try again with a unique variant name.'
          )
        }
      })
    }
  }

  const deleteFuntion = () => {
    VariantsDelete(id).then((notify) => {
      if (notify.success) {
        setListLoader(true)
        VariantsList('10', '0', search, filterGroup).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setListLoader(false)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setSelected(0)
        })
        setIsAlertSuccess(true)
      }
      if (notify.failed) {
        setIsAlertFailed(true)
      }
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)
      $('#ea_close_delete_product_variant').trigger('click') // modal close
    })
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)
      VariantsList('10', '0', e.target.value, filterGroup.value).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
        setSelected(0)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
      })
    }, 500)
    setTimer(newTimer)
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      VariantsList('10', newOffset, search, filterGroup.value).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      VariantsList('10', newOffset, search, filterGroup.value).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const getPropsDelete = (val: any) => {
    setId(val.id)
    setVarNameDelete(val.name)
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      VariantsList('10', newOffset, search, filterGroup.value).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const filterSearchGroup = (val: any) => {
    setfilterGroup(val)
    setListLoader(true)
    VariantsList('10', '0', search, val.value).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
      setSelected(0)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
    })
  }

  const [groupLimit, setGroupLimit] = useState(10)
  const [groupOffset, setGroupOffset] = useState('')

  const fetchGroups = async (key?: any, search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const variantApi = `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${groupLimit}&offset=${groupOffset}`

    const response = await getRequest(variantApi, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setGroupOffset(newOffset)
      hasMore = true
    }

    // if (key !== 'modal') {
    //   if (getGropsModal.length == 0) {
    //     options.push({
    //       label: 'All Groups',
    //       value: '',
    //     })
    //   }
    // }
    let optionData: any = []
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        optionData.push(option)
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (getGropsModal.length > 0) {
      setGropsModal(getGropsModal.concat(options))
      setGroupResponse(groupResponse.concat(optionData))
    } else {
      setGroupResponse(optionData)
      setGropsModal(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchGroupsFilter = async (key?: any, search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const variantApi = `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${groupLimit}&offset=${groupFilterOffSet}`

    const response = await getRequest(variantApi, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setGroupFilterOffSet(newOffset)
      hasMore = true
    }

    if (gropsModalFilter.length == 0) {
      options.push({
        label: 'All Groups',
        value: '',
      })
    }

    let optionData: any = []
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        optionData.push(option)
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (gropsModalFilter.length > 0) {
      setGropsModalFilter(gropsModalFilter.concat(options))
      // setGroupResponse(groupResponse.concat(optionData))
    } else {
      // setGroupResponse(optionData)
      setGropsModalFilter(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchPackaging = (search: any) => {
    let url = `master/product/material?ordering=name`
    return fetchAsyncDropdown(
      packageOffset,
      setPackageOffset,
      packageList,
      setPackageList,
      url,
      search,
      setPackageSearch
    )
  }

  const fetchPackagingEdit = (search: any) => {
    let url = `master/product/material?ordering=name`
    return fetchAsyncDropdown(
      packageOffset,
      setPackageOffset,
      packageList,
      setPackageList,
      url,
      search,
      setPackageSearch
    )
  }

  useEffect(() => {
    setPackageOffset('')
  }, [packageSearch])

  useEffect(() => {
    console.log('packaging == ', packaging)
  }, [packaging])

  return (
    <>
      <EATitle title='Product Variants' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({id: 'Product Variants'})}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('PRODUCT.VARIANTS.ADD')
            : FormatMessage('PRODUCT.VARIANTS.UPDATE')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => searchList(e)} id='searcList_variants' />
            <div className='col-auto ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
                className='card-toolbar d-flex my-0'
              >
                <AsyncPaginate
                  loadOptions={(search) => fetchGroupsFilter('', search)}
                  placeholder={'All Groups'}
                  isSearchable
                  className='react-select-container me-3 my-1 w-120px'
                  classNamePrefix='react-select'
                  value={gropsModalFilter.filter(
                    (option: any) => option.value === filterGroup.value
                  )}
                  onChange={filterSearchGroup}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_product_variant'
                    btnName='Add Product Variant'
                    onClick={() => {
                      // getGroupName()
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
              </form>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0 ? (
              <NoRecords />
            ) : (
              <TableProductVariant
                showDelete={permission.delete}
                showWrite={permission.write}
                getLength={getLength}
                getPropsDelete={getPropsDelete}
                getPropsEdit={getProps}
                tableList={getList}
              />
            )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${
              getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
            } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={getList.length == 0 ? 0 : selected}
              numberClick={numberClick}
            />
          </div>
        </div>
      </div>

      {/* add Variants*/}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionAdd}
        onSubmit={addFunction}
        closeAuto={'ea_close_create_product_variant'}
        id='ea_modal_create_product_variant'
        modalTitle='Add Product Variant'
      >
        <div className='mb-3'>
          <label className='form-label'>
            Variant Name<span className='text-danger'>*</span>
          </label>
          <input
            id='variantName'
            type='text'
            className={clsx('form-control', validationName)}
            placeholder='Type here...'
            value={variantName}
            onChange={(e) => {
              setVariantName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidationName(validation)
            }}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label' id='groupName'>
            Group Name<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={(search) => fetchGroups('modal', search)}
            placeholder={'Select'}
            isSearchable
            className={`react-select-container ${validationGroup}`}
            classNamePrefix='react-select'
            value={getGropsModal.filter((option: any) => option.value === groupName.value)}
            onChange={(e) => {
              setGroupName(e)
              groupResponse.map((option: any) => {
                if (option.id === e.value) {
                  setSelectedProductGroup(option.measurement_unit)
                  return false
                }
              })
              const validation = ValidationHandler(e.value, 'is-invalid')
              setValidationGroup(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>

        {!['Kilogram', 'Gram', 'kilogram', 'gram'].includes(selectedProductGroup) &&
          selectedProductGroup !== '' && (
            <div className='mb-3'>
              <label className='form-label'>
                Approx Weight<span className='text-danger'>*</span>
              </label>
              <div
                className='input-group'
                style={{borderColor: validationApproxWeight !== '' ? '#d01f45' : ''}}
              >
                <input
                  type='text'
                  className={clsx('form-control', validationApproxWeight)}
                  placeholder='Type here...'
                  value={apporxWeight}
                  maxLength={5}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/
                    if (e.target.value === '' || re.test(e.target.value)) {
                      if (e.target.value !== '0') {
                        setApproxWeight(e.target.value)
                        const validation = ValidationHandler(e.target.value, 'is-invalid')
                        setValidationApproxWeight(validation)
                      }
                    }
                  }}
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-100px'>
                  In Grams
                </span>
              </div>
            </div>
          )}
        <div className='mb-4'>
          <label className='form-label' id='packaging'>
            Packaging Material<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={fetchPackaging}
            isSearchable
            className={`react-select-container ${packagingValidation}`}
            classNamePrefix='react-select'
            placeholder='Select'
            value={packaging}
            onChange={(e) => {
              setPackaging(e)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setPackagingValidation(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='selcetedstandardShelf'>
            Standard Shelf Life<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validationSelcetedstandardShelf)}
            placeholder='x Days'
            value={selcetedstandardShelf}
            maxLength={5}
            onChange={(e) => {
              const re = /^[0-9\b]+$/
              if (e.target.value === '' || re.test(e.target.value)) {
                setstandardShelf(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setValidationSetstandardShelf(validation)
              }
            }}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='selcetednearExpiry'>
            Near Expiry<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validationSelcetednearExpiry)}
            maxLength={5}
            placeholder='x Days'
            value={selcetednearExpiry}
            onChange={(e) => {
              const re = /^[0-9\b]+$/
              if (e.target.value === '' || re.test(e.target.value)) {
                setNearExpiry(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                validationSetNearExpiry(validation)
              }
            }}
          />
        </div>
        <div className='mb-4'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input widget-13-check mx-2'
              name='checkValue'
              checked={checkValue}
              type='checkbox'
              onChange={(e: any) => {
                setCheckValue(e.target.checked)
              }}
              value=''
            />
            <label className='form-check-label'>Display on Website</label>
          </div>
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* edit Variants*/}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionEdit}
        onSubmit={updateFunction}
        closeAuto={'ea_close_update_product_variants'}
        id='ea_modal_edit_product_variant'
        modalTitle='Edit Product Variant'
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label' id='catNameEdit'>
            Variant Name<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            placeholder='Type here...'
            value={catNameEdit}
            onChange={(e) => {
              setCatNameEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidationNameEdit(validation)
            }}
            className={clsx('form-control', validationNameEdit)}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label' id='parentcatNameEdit'>
            Group Name<span className='text-danger'>*</span>
          </label>

          <AsyncPaginate
            loadOptions={(search) => fetchGroups('modal', search)}
            placeholder={'Select'}
            isSearchable
            className={`react-select-container ${validationGroupEdit}`}
            classNamePrefix='react-select'
            // value={getGropsModalList.filter((option: any) => option.value === parentcatNameEdit)}
            value={groupEdit}
            onChange={(e) => {
              setGroupEdit(e)
              parentsetCatNameEdit(e.value)
              groupResponse.map((option: any) => {
                if (option.id === e.value) {
                  setSelectedProductGroup(option.measurement_unit)
                  if (['kilogram', 'Kilogram', 'gram', 'Gram'].includes(option.measurement_unit)) {
                    setApproxWeight('')
                  }
                  return false
                }
              })
              const validation = ValidationHandler(e.value, 'is-invalid')
              setValidationGroupEdit(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        {!['Kilogram', 'Gram', 'kilogram', 'gram'].includes(selectedProductGroup) &&
          selectedProductGroup !== '' && (
            <div className='mb-3'>
              <label className='form-label'>
                Approx Weight<span className='text-danger'>*</span>
              </label>
              <div
                className='input-group'
                style={{borderColor: validationApproxWeight !== '' ? '#d01f45' : ''}}
              >
                <input
                  type='text'
                  className={clsx('form-control', validationApproxWeight)}
                  placeholder='Type here...'
                  value={apporxWeight}
                  maxLength={5}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/
                    if (e.target.value === '' || re.test(e.target.value)) {
                      if (e.target.value !== '0') {
                        setApproxWeight(e.target.value)
                        const validation = ValidationHandler(e.target.value, 'is-invalid')
                        setValidationApproxWeight(validation)
                      }
                    }
                  }}
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-100px'>
                  In Grams
                </span>
              </div>
            </div>
          )}

        <div className='mb-4'>
          <label className='form-label' id='packaging'>
            Packaging Material<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={fetchPackagingEdit}
            isSearchable
            className={`react-select-container ${packagingValidation}`}
            classNamePrefix='react-select'
            placeholder='Select'
            value={packaging}
            onChange={(e) => {
              setPackaging(e)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setPackagingValidation(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='selcetedstandardShelf'>
            Standard Shelf Life<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validationSelcetedstandardShelf)}
            placeholder='x Days'
            value={selcetedstandardShelf}
            maxLength={5}
            onChange={(e) => {
              const re = /^[0-9\b]+$/
              if (e.target.value === '' || re.test(e.target.value)) {
                setstandardShelf(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setValidationSetstandardShelf(validation)
              }
            }}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Near Expiry<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='selcetednearExpiry'
            className={clsx('form-control', validationSelcetednearExpiry)}
            maxLength={5}
            placeholder='x Days'
            value={selcetednearExpiry}
            onChange={(e) => {
              const re = /^[0-9\b]+$/
              if (e.target.value === '' || re.test(e.target.value)) {
                setNearExpiry(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                validationSetNearExpiry(validation)
              }
            }}
          />
        </div>
        <div className='mb-4'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input widget-13-check mx-2'
              name='checkValue'
              checked={checkValueEdit}
              type='checkbox'
              onChange={(e: any) => {
                setCheckValueEdit(e.target.checked)
              }}
              value=''
              id='display-website'
            />
            <label className='form-check-label' htmlFor='display-website'>
              Display on Website
            </label>
          </div>
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* delete Variants*/}
      <EaModal
        modalTitle='Delete Product Variant'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{varNameDelete}?</span>
          </p>
        }
        onSubmit={deleteFuntion}
        id={'deleteProductVariant'}
        closeAuto='ea_close_delete_product_variant'
        actionBtnName='Yes, Proceed'
      />
    </>
  )
}

export default ProductVarients
