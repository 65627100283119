import React, {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  isEmpty,
  KTSVG,
  measurementLabel,
} from '../../../_eaFruitsDms/helpers'
import {getRequest, patchRequest} from '../../../_eaFruitsDms/https/apiCall'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import moment from 'moment'
import {useLocation, useNavigate} from 'react-router-dom'
import {EaAlertDanger} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'

let tableRowIndex: number = 0

/**
 * React component to handle Banana transfer functionality, including adding, deleting, and submitting transfer rows
 *
 * @return {JSX.Element} The JSX for the Banana Transfer component
 */
const BananaTransfer = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {state}: any = location
  const containerName = state?.container_name || ''
  const containerId = state?.container_id || ''
  const fromContainerMinTemperature = checkEmpty(state?.minimum_temperature, '-', true) || '-'
  const fromContainerMaxTemperature = checkEmpty(state?.maximum_temperature, '-', true) || '-'
  const fromContainerMinHumidity = checkEmpty(state?.minimum_humidity, '-', true) || '-'
  const fromContainerMaxHumidity = checkEmpty(state?.maximum_humidity, '-', true) || '-'

  const headerTitle = {
    title: 'Banana Transfer',
    subTitle: 'Banana Transfer',
  }

  const currentWarehouseId = localStorage.getItem('w_id')

  const initialRow: any = {
    index: 0,
    product_id: '',
    batch_id: '',
    batch_quantity: '',
    offload_days: '',
    quantity: '',
    validation_for_product: '',
    validation_for_batch: '',
    validation_for_offload_days: '',
    validation_for_quantity: '',
    validation_for_quantity_exceed: '',
    validation_for_min_temperature: '',
    validation_for_max_temperature: '',
    validation_for_min_humidity: '',
    validation_for_max_humidity: '',
    quantity_label: '',
    min_temperature: fromContainerMinTemperature,
    max_temperature: fromContainerMaxTemperature,
    min_humidity: fromContainerMinHumidity,
    max_humidity: fromContainerMaxHumidity,
    inward_quantity: '',
  }

  const [containerCapacity, setContainerCapacity] = useState(0)
  const [productReset, setProductReset] = useState<any>(moment())
  const [bananaTransferRows, setBananaTransferRows] = useState<any>([initialRow])
  const [batches, setBatches] = useState<any>([])
  const [batchReset, setBatchReset] = useState<any>(moment())
  const [searchSelect, setSearchSelect] = useState('')
  const [productSkus, setProductSkus] = useState<any>([])
  const [containerReset, setContainerReset] = useState<any>(moment())
  const [selectedContainer, setSelectedContainer] = useState<any>(null)
  const [containers, setContainers] = useState<any>([])
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)
  const [limit] = useState<number>(10)

  useEffect(() => {
    if (isEmpty(containerName) || isEmpty(containerId)) {
      navigate('/containers')
    }
  }, [])

  /**
   * Fetches product SKU based on the search criteria.
   *
   * @param {any} search - the search criteria
   * @return {object} an object containing options and hasMore flag
   */
  const fetchProductSku = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }
    const productResponse = await getRequest(
      `${apiBaseURL()}inventory/ripening-product-list/${containerId}`,
      true
    )
    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if (productResponse.length > 0) {
      productResponse.map((option: any) => {
        optionData.push(option)
        options.push({
          label: option.product_name,
          value: option.product_id,
          ...option,
        })
      })
    }

    if (productSkus.length > 0) {
      setProductSkus(productSkus.concat(options))
    } else {
      setProductSkus(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  /**
   * Fetches containers based on the search parameter and updates the state accordingly.
   *
   * @param {any} search - optional search parameter
   * @return {object} an object containing options and hasMore properties
   */
  const fetchContainers = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }
    const containerResponse = await getRequest(
      `${apiBaseURL()}weather-boxes/available-containers-list?warehouse_id=${currentWarehouseId}&ignore_container_id=${containerId}`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (containerResponse.length > 0) {
      containerResponse.map((option: any) => {
        options.push({
          label: option.container_name,
          value: option.id,
          ...option,
        })
      })
    }

    if (containers.length > 0) {
      setContainers(containers.concat(options))
    } else {
      setContainers(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  /**
   * Fetches batches based on the index and optional search criteria.
   *
   * @param {number} index - The index used to fetch batches
   * @param {any} search - Optional search criteria
   * @return {object} An object containing the fetched options and a flag indicating if there are more batches
   */
  const fetchBatches = async (index: number, search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }
    const batchResponse = await getRequest(
      `${apiBaseURL()}inventory/ripening-batch-list/${containerId}/${
        bananaTransferRows[index].product_id
      }`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (batchResponse.length > 0) {
      batchResponse.map((option: any) => {
        const findBatchIndex = bananaTransferRows.findIndex(
          (row: any) =>
            row.product_id === bananaTransferRows[index].product_id && row.batch_id === option.id
        )

        if (findBatchIndex === -1) {
          options.push({
            label: option.batch_number,
            value: option.id,
            ...option,
          })
        }
      })
    }

    if (batches.length > 0) {
      setBatches(batches.concat(options))
    } else {
      setBatches(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  /**
   * Handles the change event for a specified input field, updating the banana transfer rows accordingly.
   *
   * @param {any} e - the event object
   * @param {any} index - the index of the row being updated
   * @param {string} name - (optional) the name of the input field
   */
  const handleChange = (e: any, index: any, name?: string) => {
    let rows = [...bananaTransferRows]
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'product':
        rows[index]['product_id'] = inputValue
        rows[index]['batch'] = ''
        rows[index]['quantity'] = ''
        rows[index]['inward_quantity'] = 0
        rows[index]['validation_for_product'] = ''
        rows[index]['quantity_label'] = measurementLabel(
          e.fix_size ? e.material_name : e.approx_weight > 0 ? e.material_name : e.measurement_unit
        )
        break
      case 'batch':
        rows[index]['batch_id'] = inputValue
        rows[index]['validation_for_batch'] = ''
        rows[index]['batch_quantity'] = parseInt(e.qty)
        rows[index]['offload_days'] = parseInt(e.offload_days)

        break
      case 'offload_days':
        inputValue = inputValue.replace(/[^0-9]/g, '')

        rows[index]['offload_days'] = inputValue
        rows[index]['validation_for_offload_days'] = ''

        if (isEmpty(inputValue.trim()) && parseInt(inputValue) === 0) {
          rows[index]['validation_for_offload_days'] = 'is-invalid'
        }
        break
      case 'quantity':
        if (inputValue.length === 1 && inputValue == 0) {
          return false
        }
        inputValue = inputValue.replace(/[^0-9]/g, '')

        rows[index]['quantity'] = inputValue

        rows[index]['validation_for_quantity'] = ''
        rows[index]['validation_for_quantity_exceed'] = ''
        rows[index]['inward_quantity'] = calculateInwardQuantity(index, inputValue)

        const isValid = validateQuantity(index, inputValue)

        if (isValid) {
          if (parseInt(inputValue) > rows[index]['batch_quantity']) {
            rows[index]['validation_for_quantity'] = 'is-invalid'
            rows[index][
              'validation_for_quantity_exceed'
            ] = `Only ${rows[index]['batch_quantity']} crates transferable`
          }
        }
        break
      case 'min_temperature':
      case 'max_temperature':
      case 'min_humidity':
      case 'max_humidity':
        if (inputValue.length === 1 && inputValue === '.') {
          return false
        }
        if (inputValue.length > 1) {
          inputValue = inputValue.replace(/(\d)-/g, '$1')
        }

        if (['min_temperature', 'max_temperature'].includes(inputName)) {
          if ((inputValue.split('-').length-1) > 1) {
            return false
          }
        } else {
          inputValue = inputValue.replace('-')
        }

        inputValue = inputValue.replace(/[^0-9.-]|(\.[0-9]*)\./g, '$1')

        rows.map((row, index) => {
          rows[index][inputName] = inputValue
        })

        // Validation checks for min_temperature < max_temperature and min_humidity < max_humidity
        const minTemp = parseFloat(rows[index]['min_temperature'])
        const maxTemp = parseFloat(rows[index]['max_temperature'])
        const minHumidity = parseFloat(rows[index]['min_humidity'])
        const maxHumidity = parseFloat(rows[index]['max_humidity'])

        if (!isNaN(minTemp) && !isNaN(maxTemp) && minTemp > maxTemp) {
          // Set validation for min_temperature and max_temperature
          rows[index]['validation_for_min_temperature'] = 'is-invalid'
          rows[index]['validation_for_max_temperature'] = 'is-invalid'
        } else {
          rows[index]['validation_for_min_temperature'] = ''
          rows[index]['validation_for_max_temperature'] = ''
        }

        if (!isNaN(minHumidity) && !isNaN(maxHumidity) && minHumidity > maxHumidity) {
          // Set validation for min_humidity and max_humidity
          rows[index]['validation_for_min_humidity'] = 'is-invalid'
          rows[index]['validation_for_max_humidity'] = 'is-invalid'
        } else {
          rows[index]['validation_for_min_humidity'] = ''
          rows[index]['validation_for_max_humidity'] = ''
        }
        break
    }

    setBananaTransferRows(rows)
  }

  /**
   * Calculate the inward quantity based on the product details.
   *
   * @param {number} index - the index of the product
   * @param {number} quantity - the quantity of the product
   * @return {number} the calculated inward quantity
   */
  const calculateInwardQuantity = (index: number, quantity: number) => {
    let product = productSkus.find(
      (option: any) => option.value === bananaTransferRows[index]['product_id']
    )

    if (product.length > 0) product = product[0]

    if (product.fix_size) {
      if (product.measurement_unit.toLowerCase() === 'gram') {
        return quantity * (product.size / 1000)
      } else {
        return quantity * product.size
      }
    } else {
      if (product.variants && product.variants.approx_weight > 0) {
        return (quantity * product.variants.approx_weight) / 1000
      } else {
        return quantity
      }
    }
  }

  /**
   * Validates the quantity of bananas being transferred.
   *
   * @param {number} index - the index of the row being validated
   * @param {number} quantity - the quantity of bananas being transferred
   * @return {boolean} whether the quantity is valid or not
   */
  const validateQuantity = (index: number, quantity: number) => {
    const rows = [...bananaTransferRows]
    let totalQuantity: number = 0

    bananaTransferRows.map((row: any, index: number) => {
      totalQuantity += parseInt(row.quantity)
    })

    let isValid = true

    if (totalQuantity > containerCapacity) {
      rows[index]['validation_for_quantity'] = 'is-invalid'
      rows[index][
        'validation_for_quantity_exceed'
      ] = `Container has maximum capacity of ${containerCapacity} Crates`

      isValid = false
    }

    setBananaTransferRows(rows)

    return isValid
  }

  /**
   * Validates the banana transfer object.
   *
   * @return {boolean} the validation result
   */
  const validateBananaTransferObj = () => {
    const rows = [...bananaTransferRows]
    let isValid = true

    rows.map((row: any, index: number) => {
      if (isEmpty(row.product_id)) {
        isValid = false
        rows[index]['validation_for_product'] = 'is-invalid'
      }

      if (isEmpty(row.batch_id)) {
        isValid = false
        rows[index]['validation_for_batch'] = 'is-invalid'
      }

      if (isEmpty(row.offload_days, true)) {
        isValid = false
        rows[index]['validation_for_offload_days'] = 'is-invalid'
      }

      if (isEmpty(row.quantity, true)) {
        isValid = false
        rows[index]['validation_for_quantity'] = 'is-invalid'
      }

      if (isEmpty(row.min_temperature)) {
        isValid = false
        rows[index]['validation_for_min_temperature'] = 'is-invalid'
      } else {
        if (parseFloat(row.min_temperature) > parseFloat(row.max_temperature)) {
          isValid = false
          rows[index]['validation_for_min_temperature'] = 'is-invalid'
          rows[index]['validation_for_max_temperature'] = 'is-invalid'
        }
      }

      if (isEmpty(row.max_temperature)) {
        isValid = false
        rows[index]['validation_for_max_temperature'] = 'is-invalid'
      } else {
        if (parseFloat(row.min_temperature) > parseFloat(row.max_temperature)) {
          isValid = false
          rows[index]['validation_for_min_temperature'] = 'is-invalid'
          rows[index]['validation_for_max_temperature'] = 'is-invalid'
        }
      }

      if (isEmpty(row.min_humidity)) {
        isValid = false
        rows[index]['validation_for_min_humidity'] = 'is-invalid'
      } else {
        if (parseFloat(row.min_humidity) > parseFloat(row.max_humidity)) {
          isValid = false
          rows[index]['validation_for_min_humidity'] = 'is-invalid'
          rows[index]['validation_for_max_humidity'] = 'is-invalid'
        }
      }

      if (isEmpty(row.max_humidity)) {
        isValid = false
        rows[index]['validation_for_max_humidity'] = 'is-invalid'
      } else {
        if (parseFloat(row.min_humidity) > parseFloat(row.max_humidity)) {
          isValid = false
          rows[index]['validation_for_min_humidity'] = 'is-invalid'
          rows[index]['validation_for_max_humidity'] = 'is-invalid'
        }
      }
    })

    setBananaTransferRows(rows)

    return isValid
  }

  /**
   * This function adds a new transfer row and updates the banana transfer rows if the transfer object is valid.
   */
  const addNewTransferRow = () => {
    const isValid = validateBananaTransferObj()

    if (isValid) {
      tableRowIndex++

      const updatedRows = [...bananaTransferRows]
      initialRow.index = tableRowIndex

      if (updatedRows.length > 0) {
        initialRow['min_temperature'] = checkEmpty(updatedRows[0].min_temperature, '')
        initialRow['max_temperature'] = checkEmpty(updatedRows[0].max_temperature, '')
        initialRow['min_humidity'] = checkEmpty(updatedRows[0].min_humidity, '')
        initialRow['max_humidity'] = checkEmpty(updatedRows[0].max_humidity, '')
      }
      updatedRows[tableRowIndex] = initialRow
      setBananaTransferRows(updatedRows)
    }
  }

  /**
   * Deletes a row at the specified index.
   *
   * @param {number} index - the index of the row to delete
   * @return {any}
   */
  const deleteRow = (index: number): any => {
    if (bananaTransferRows.length === 1) {
      alert('You can not remove this row because you need to add at least one offload item.!')
      return false;
    }

    if (bananaTransferRows.length > 0) {
      let updatedRows: any = [...bananaTransferRows]
      updatedRows.splice(index, 1)

      tableRowIndex--
      updatedRows.forEach((row: any, index: any) => {
        row.index = index
      })
      setBananaTransferRows(updatedRows)
    }
  }

  /**
   * A function to submit banana transfer.
   *
   * @return {Promise<void>} no return value
   */
  const bananaTransferSubmit = async () => {
    if (validateBananaTransferObj()) {
      const requestObj: any = {stock_entries: []}
      setIsSubmit(true)
      bananaTransferRows.map((row: any, index: number) => {
        requestObj['stock_entries'].push({
          product: row.product_id,
          qty: row.quantity,
          offload_days: row.offload_days,
          ripening_stock_id: row.batch_id,
          associate_weather_min_temp: row.min_temperature,
          associate_weather_max_temp: row.max_temperature,
          associate_weather_min_humidity: row.min_humidity,
          associate_weather_max_humidity: row.max_humidity,
        })
      })

      const response = await patchRequest(
        `${apiBaseURL()}inventory/ripening-stock-transfer/${containerId}/${
          selectedContainer.value
        }`,
        requestObj,
        true
      )

      handleResponse(response)
    }
  }

  /**
   * Handle the response from the server.
   *
   * @param {any} res - the response from the server
   * @return {void}
   */
  const handleResponse = (res: any) => {
    setIsSubmit(false)
    if (res.status === 201 || res.status === 200 || res.status === 204) {
      resetStates()
      localStorage.setItem('transfer_message', `Bananas has been transferred from ${containerName} to ${selectedContainer
        .label} successfully`)
      navigate('/containers')
    } else {
      setIsAlertFailed(true)
    }

    setTimeout(() => {
      setIsAlertFailed(false)
    }, 1500)
  }

  /**
   * Check if the value is 'success' or 'failed' and update state accordingly.
   *
   * @param {any} val - the value to be checked
   * @return {void}
   */
  const isAlertShow = (val: any) => {
    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }

  /**
   * Cancels the ongoing transfer by resetting the transfer-related state and navigating to the '/containers' route.
   *
   * @return {void}
   */
  const cancelTransfer = () => {
    setBananaTransferRows([])
    setContainers([])
    setSelectedContainer(null)
    setContainerReset(moment())
    setContainerCapacity(0)
    navigate('/containers')
  }

  /**
   * Resets the states by clearing the banana transfer rows, containers, selected container,
   * and setting the container reset time and capacity to 0.
   */
  const resetStates = () => {
    setBananaTransferRows([])
    setContainers([])
    setSelectedContainer(null)
    setContainerReset(moment())
    setContainerCapacity(0)
  }

  return (
    <>
      <EATitle title='Banana Transfer' />
      <PageTitle breadcrumbs={[headerTitle]}>Banana Transfer</PageTitle>

      <div className='card p-5'>
        <h3 className='mb-5'>Banana Transfer</h3>
        <EaAlertDanger
          onClick={() => isAlertShow('failed')}
          show={isAlertFailed}
          message='Something went wrong. Please try again.!'
        />
        <div className='mb-5'>
          <div className='row'>
            <div className='col-md-5'>
              <label className='form-label'>From Container</label>
              <AsyncPaginate
                loadOptions={fetchContainers}
                placeholder='Select'
                isSearchable
                isDisabled
                className={'react-select-container'}
                classNamePrefix='react-select'
                menuPosition='fixed'
                name='product_sku_id'
                value={{
                  label: containerName,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='min-w-130px'>Min. Temperature</th>
                      <th className='min-w-130px'>Max. Temperature</th>
                      <th className='min-w-130px'>Min. Humidity</th>
                      <th className='min-w-130px'>Max. Humidity</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className='input-group'>
                          <input
                            className='form-control'
                            type='text'
                            disabled={true}
                            value={fromContainerMinTemperature}
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                            ℃
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='input-group'>
                          <input
                            className='form-control'
                            type='text'
                            disabled={true}
                            value={fromContainerMaxTemperature}
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                            ℃
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='input-group'>
                          <input
                            className='form-control'
                            type='text'
                            disabled={true}
                            value={fromContainerMinHumidity}
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                            g/m3
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='input-group'>
                          <input
                            className='form-control'
                            type='text'
                            disabled={true}
                            value={fromContainerMaxHumidity}
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                            g/m3
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-md-1'>
              <div className='d-flex justify-content-center align-items-center mt-5'>
                <i className='bi bi-arrow-right font-20'></i>
              </div>
            </div>
            <div className='col-md-5'>
              <label className='form-label'>To Container</label>
              <AsyncPaginate
                key={containerReset}
                loadOptions={fetchContainers}
                placeholder='Select'
                isSearchable
                className={'react-select-container'}
                classNamePrefix='react-select'
                menuPosition='fixed'
                onChange={(e: any) => {
                  setContainerCapacity(e.available_qty)

                  setBananaTransferRows([initialRow])
                  setSelectedContainer(e)
                }}
                name='product_sku_id'
                // value={}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='min-w-130px'>Min. Temperature</th>
                      <th className='min-w-130px'>Max. Temperature</th>
                      <th className='min-w-130px'>Min. Humidity</th>
                      <th className='min-w-130px'>Max. Humidity</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className='input-group'>
                          <input
                            className='form-control'
                            type='text'
                            disabled={true}
                            value={
                              selectedContainer?.available_qty === selectedContainer?.capacity ? '-' : (checkEmpty(
                                selectedContainer?.associate_weather_min_temp,
                                '-',
                                true
                              ) || '-')
                            }
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                            ℃
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='input-group'>
                          <input
                            className='form-control'
                            type='text'
                            disabled={true}
                            value={
                               selectedContainer?.available_qty === selectedContainer?.capacity ? '-' : checkEmpty(
                                selectedContainer?.associate_weather_max_temp,
                                '-',
                                true
                              ) || '-'
                            }
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                            ℃
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='input-group'>
                          <input
                            className='form-control'
                            type='text'
                            disabled={true}
                            value={
                              selectedContainer?.available_qty === selectedContainer?.capacity ? '-' : (checkEmpty(
                                selectedContainer?.associate_weather_min_humidity,
                                '-',
                                true
                              ) || '-')
                            }
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                            g/m3
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='input-group'>
                          <input
                            className='form-control'
                            type='text'
                            disabled={true}
                            value={
                              selectedContainer?.available_qty === selectedContainer?.capacity ? '-' : (checkEmpty(
                                selectedContainer?.associate_weather_max_humidity,
                                '-',
                                true
                              ) || '-')
                            }
                          />
                          <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                            g/m3
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {!isEmpty(selectedContainer) && (
          <div>
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th className='min-w-140px'>Product SKU</th>
                    <th className='min-w-140px'>Batch Number</th>
                    <th className='min-w-140px'>Offload Days</th>
                    <th className='min-w-140px'>Quantity</th>
                    <th className='min-w-140px'>Inward Qty.</th>
                    <th className='min-w-140px'>Min. Temperature</th>
                    <th className='min-w-140px'>Max. Temperature</th>
                    <th className='min-w-140px'>Min. Humidity</th>
                    <th className='min-w-140px'>Max. Humidity</th>
                  </tr>
                </thead>
                <tbody>
                  {bananaTransferRows.map((bananaTransferRow: any, index: number) => {
                    return (
                      <tr key={index.toString()}>
                        <td>
                          <AsyncPaginate
                            key={productReset}
                            loadOptions={fetchProductSku}
                            placeholder='Select'
                            isSearchable
                            className={`react-select-container ${bananaTransferRow.validation_for_product}`}
                            classNamePrefix='react-select'
                            menuPosition='fixed'
                            onChange={(e) => {
                              handleChange(e, index, 'product')
                            }}
                            name='product_sku_id'
                            value={
                              productSkus.find(
                                (option: any) => option.value == bananaTransferRow.product_id
                              ) || ''
                            }
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: '#0c78a4',
                              },
                            })}
                          />
                        </td>
                        <td>
                          <AsyncPaginate
                            key={batchReset}
                            loadOptions={(search) => fetchBatches(index, search)}
                            placeholder='Select'
                            isSearchable
                            isDisabled={isEmpty(bananaTransferRow.product_id)}
                            className={`react-select-container ${bananaTransferRow.validation_for_batch}`}
                            classNamePrefix='react-select'
                            menuPosition='fixed'
                            onChange={(e) => {
                              handleChange(e, index, 'batch')
                            }}
                            name='batch_id'
                            value={
                              batches.find(
                                (option: any) => option.value === bananaTransferRow.batch_id
                              ) || ''
                            }
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: '#0c78a4',
                              },
                            })}
                          />
                          {!isEmpty(bananaTransferRow.batch_id) && (
                            <span className='font-13 text-danger mt-1'>
                              Transferable: {bananaTransferRow.batch_quantity} Crates
                            </span>
                          )}
                        </td>
                        <td>
                          <div
                            className='input-group'
                            style={{
                              borderColor: !isEmpty(bananaTransferRow.validation_for_offload_days)
                                ? 'Red'
                                : '',
                            }}
                          >
                            <input
                              type='text'
                              placeholder='0'
                              name='offload_days'
                              disabled={isEmpty(bananaTransferRow.batch_id)}
                              className={`form-control`}
                              value={bananaTransferRow.offload_days}
                              onChange={(e: any) => handleChange(e, index)}
                            />
                            <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                              Days
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            className='input-group'
                            style={{
                              borderColor: !isEmpty(bananaTransferRow.validation_for_quantity)
                                ? 'Red'
                                : '',
                            }}
                          >
                            <input
                              type='text'
                              placeholder='0'
                              name='quantity'
                              disabled={isEmpty(bananaTransferRow.batch_id)}
                              value={bananaTransferRow.quantity}
                              onChange={(e) => handleChange(e, index)}
                              className={`form-control`}
                            />
                            <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                              {bananaTransferRow.quantity_label}
                            </span>
                          </div>
                          {!isEmpty(bananaTransferRow.validation_for_quantity_exceed) && (
                            <span className='font-13 text-danger mt-1'>
                              {bananaTransferRow.validation_for_quantity_exceed}
                            </span>
                          )}
                        </td>
                        <td>
                          <div className='input-group'>
                            <input
                              type='text'
                              placeholder='0'
                              disabled
                              name='inward_quantity'
                              value={bananaTransferRow.inward_quantity}
                              className={`form-control`}
                            />
                            <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                              Kgs
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            className='input-group'
                            style={{
                              borderColor: !isEmpty(
                                bananaTransferRow.validation_for_min_temperature
                              )
                                ? 'Red'
                                : '',
                            }}
                          >
                            <input
                              type='text'
                              placeholder='0'
                              name='min_temperature'
                              value={bananaTransferRow.min_temperature}
                              onChange={(e: any) => handleChange(e, index)}
                              className={`form-control`}
                            />
                            <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                              ℃
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            className='input-group'
                            style={{
                              borderColor: !isEmpty(
                                bananaTransferRow.validation_for_max_temperature
                              )
                                ? 'Red'
                                : '',
                            }}
                          >
                            <input
                              type='text'
                              placeholder='0'
                              name='max_temperature'
                              value={bananaTransferRow.max_temperature}
                              onChange={(e: any) => handleChange(e, index)}
                              className={`form-control`}
                            />
                            <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                              ℃
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            className='input-group'
                            style={{
                              borderColor: !isEmpty(bananaTransferRow.validation_for_min_humidity)
                                ? 'Red'
                                : '',
                            }}
                          >
                            <input
                              type='text'
                              placeholder='0'
                              name='min_humidity'
                              value={bananaTransferRow.min_humidity}
                              onChange={(e: any) => handleChange(e, index)}
                              className={`form-control`}
                            />
                            <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                              g/m3
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            className='input-group'
                            style={{
                              borderColor: !isEmpty(bananaTransferRow.validation_for_max_humidity)
                                ? 'Red'
                                : '',
                            }}
                          >
                            <input
                              type='text'
                              placeholder='0'
                              name='max_humidity'
                              value={bananaTransferRow.max_humidity}
                              onChange={(e: any) => handleChange(e, index)}
                              className={`form-control`}
                            />
                            <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 '>
                              g/m3
                            </span>
                          </div>
                        </td>
                        <td>
                          <button
                            type='button'
                            className='position-relative btn ms-auto p-0'
                            onClick={() => deleteRow(index)}
                          >
                            <span className='svg-icon-dark'>
                              <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className='ic me-0 mt-1'
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className="ms-4 mb-2">
                <button
                  type='button'
                  className='position-relative btn ms-auto p-0'
                  onClick={addNewTransferRow}
                >
                  <span className='svg-icon-dark'>
                    <KTSVG
                      path='/media/icons/duotune/abstract/abs011.svg'
                      className='ic me-0 mt-1'
                    />
                  </span>
                </button>
              </div>
            </div>
            <div>
              <div className='card-footer py-2 border-top text-center justify-content-center'>
                <button type='button' className='btn btn-secondary me-3' onClick={cancelTransfer}>
                  Cancel
                </button>
                <button
                  className='btn btn-primary'
                  onClick={bananaTransferSubmit}
                  disabled={isSubmit}
                >
                  {isSubmit ? 'Please Wait...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BananaTransfer
