import clsx from 'clsx'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  checkPermissions,
  defaultPermission,
  fetchLocation,
  globalDateFormate,
  googleApi,
  isEmpty,
  KTSVG,
  numberFormat,
  saveLocation,
} from '../../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {patchRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import ErrorHandler from '../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import MobileNumberInput from '../../../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput'
import EaModal from '../../../../../_eaFruitsDms/layout/components/modal/eaModal'
import tenantConfiguration from '../../../../../TenantVariables'
import Documents from '../../components/Documents'
import {familySize, initialCustomerLatLong} from '../CustomerConst'
import CustomerMap from '../../../../../_eaFruitsDms/layout/components/eaMap/customerMap'

const fetchRejectionReason = (search?: any) => {
  let options = [
    {
      label: 'Reason 1',
      value: 'reason1',
    },
    {
      label: 'Reason 2',
      value: 'reason2',
    },
    {
      label: 'Reason 3',
      value: 'reason3',
    },
    {
      label: 'Reason 4',
      value: 'reason4',
    },
    {
      label: 'Reason 5',
      value: 'reason5',
    },
  ]

  return {
    options,
    hasMore: false,
  }
}

const Overview = ({customer, fetchCustomerDetails}: any) => {
  const navigate = useNavigate()
  const userType = localStorage.getItem('user_type') || ''

  const initialSeconds = 60

  const [show, setShow] = useState(false)
  const [showChangeNumber, setShowChangeNumber] = useState(false)
  const [verified, setVerified] = useState(false)
  const [mobileVerification, setMobileVerification] = useState(false)
  const [documents, setDocuments] = useState<any>([])
  const [captureLocation, setCaptureLocation] = useState(false)
  const [locationButtonDisable, setLocationButtonDisable] = useState<boolean>(false)
  const [customerCaptureLatLong, setCustomerCaptureLatLong] = useState<any>(initialCustomerLatLong)
  const [personalFamilySize, setPersonalFamilySize] = useState<any>('')
  const [code, setCode] = useState<any>('')
  const [mobileNumber, setMobileNumber] = useState<any>('')
  const [seconds, setSeconds] = useState<number>(initialSeconds)
  const [startTimer, setStartTimer] = useState<boolean>(false)
  const [responseMessage, setResponseMessage] = useState<string>('')
  const [mobileValidation, setMobileValidation] = useState<string>('')
  const [codeValidation, setCodeValidation] = useState<string>('')
  const [verifieConfirm, setVerifieConfirm] = useState(false)
  const [isVerifyButtonLoader, setIsVerifyButtonLoader] = useState<any>('Verify')
  const [otpVerifyLoader, setOtpVerifyLoader] = useState<any>('Verify')
  const [changeNumberVerifyLoader, setChangeNumberVerifyLoader] = useState<any>('Verify')
  const [isMobileUniqueError, setIsMobileUniqueError] = useState<boolean>(false)
  const [permission, setPermission] = useState(defaultPermission)
  const [locationDisable, setLocationDisable] = useState<boolean>(false)

  useEffect(() => {
    const customerPermission = localStorage.getItem('customerPermission')

    if (customerPermission == 'allCustomer') {
      checkPermissions('customer').then((res: any) => {
        setPermission(res)
      })
    }
    if (customerPermission == 'suspendedCustomer') {
      checkPermissions('suspended_customer').then((res: any) => {
        setPermission(res)
      })
    }
  }, [])

  useEffect(() => {
    if (customer && customer.other_documents.length > 0) {
      let uploadedDocuments: any = []
      customer.other_documents.map((document: any) => {
        uploadedDocuments.push(document)
      })
      setDocuments(uploadedDocuments)
    }
    if (customer) {
      setMobileNumber(customer.contact_no)
      const family = familySize.find((option: any) => option.value == customer.personal_family_size)
      family && setPersonalFamilySize(family.label)
    }

    if (customer && !isEmpty(customer.captured_latitude) && !isEmpty(customer.captured_longitude)) {
      setCustomerCaptureLatLong({
        latitude: customer.captured_latitude,
        longitude: customer.captured_longitude,
      })
    }
  }, [customer])

  useEffect(() => {
    if (startTimer) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds === 0) {
          setStartTimer(false)
        }
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [seconds, startTimer])

  const handleCloseOtp = () => {
    setShow(false)
  }
  const handleCloseChangeNumber = () => {
    if (isMobileUniqueError) {
      setShow(false)
    }
    setShowChangeNumber(false)
    setMobileValidation('')
    setCode('')
  }
  const handleCloseVerified = () => {
    setVerified(false)
    setVerifieConfirm(true)
    fetchCustomerDetails().then()
  }
  const handleCloseMobileVerification = () => setMobileVerification(false)

  const handleShowMobileVerification = () => setMobileVerification(true)

  /* mobile verification verify button onchange function*/
  const mobileVerified = async () => {
    setVerified(false)
    setShow(true)
    setMobileVerification(false)
    setShowChangeNumber(false)
    setResponseMessage('')
    setMobileNumber('')
    setMobileValidation('')
    setCodeValidation('')
    setCode('')

    setIsVerifyButtonLoader('Please Wait...')
    const mobileVerifyData = {
      contact_no: customer.contact_no,
    }
    const cId = localStorage.getItem('cId')
    const verificationResponse = await patchRequest(
      `${apiBaseURL()}customers/${cId}/send-otp`,
      mobileVerifyData,
      true
    )

    if (
      verificationResponse.data &&
      verificationResponse.data.error &&
      verificationResponse.data.error.field === 'contact_no'
    ) {
      setShowChangeNumber(true)
      setMobileNumber(customer.contact_no)
      setIsMobileUniqueError(true)
    } else {
      setSeconds(initialSeconds)
      setStartTimer(true)
    }

    setIsVerifyButtonLoader('Verify')
  }

  const handleCloseCaptureLocation = () => {
    if (customer.captured_latitude !== customerCaptureLatLong.latitude) {
      setCustomerCaptureLatLong({
        latitude: customer.captured_latitude,
        longitude: customer.captured_longitude,
      })
    }
    setCaptureLocation(false)
  }

  /* otp modal ' s verify button onChange function*/
  const otpVerified = async () => {
    if (code.trim() === '') {
      setCodeValidation('is-invalid')
      return false
    }
    setOtpVerifyLoader('Please Wait...')

    const codeVerifyData = {
      otp: parseInt(code),
    }
    const cId = localStorage.getItem('cId')
    const res = await patchRequest(
      `${apiBaseURL()}customers/${cId}/verify-otp`,
      codeVerifyData,
      true
    )

    if (res.data && res.data.error) {
      if (res.data.error.code == 416) {
        setResponseMessage('Your OTP number is invalid')
        setShow(true)
      }
      if (res.data.error.code == 418) {
        setResponseMessage(
          'Your one-time password is no longer valid. Please click the resend button to send a fresh OTP to the provided mobile number.'
        )
        setShow(true)
      }
      setCode('')
    } else {
      setSeconds(initialSeconds)
      setStartTimer(false)
      setVerified(true)
      setResponseMessage('')
      setShow(false)
      setCode('')
    }
    setOtpVerifyLoader('Verify')
  }

  const validateInput = (inputValue: any) => {
    const regex = /^[0-9\b]+$/

    if (inputValue === '' || regex.test(inputValue)) {
      return true
    }
    return false
  }

  const handleChange = (e: any) => {
    const inputName = e.target.name
    const inputValue = e.target.value
    const validate = validateInput(inputValue)

    if (inputName === 'mobile') {
      if (validate) {
        setMobileNumber(inputValue)
        setMobileValidation('')
      } else {
        setMobileValidation('is-invalid')
      }
    } else {
      if (validate) {
        setCode(inputValue)
        setCodeValidation('')
      } else {
        setCodeValidation('is-invalid')
      }
    }
  }

  /* change number ' s verify button onChange function*/
  const changeNumberVerified = async () => {
    if (mobileNumber == '') {
      setMobileValidation('is-invalid')
      return
    }

    setChangeNumberVerifyLoader('Please Wait...')
    const mobileVerifyData = {
      contact_no: mobileNumber,
    }
    const cId = localStorage.getItem('cId')
    const verificationResponse: any = await patchRequest(
      `${apiBaseURL()}customers/${cId}/send-otp`,
      mobileVerifyData,
      true
    )

    if (
      verificationResponse.data &&
      verificationResponse.data.error &&
      verificationResponse.data.error.field === 'contact_no'
    ) {
      setIsMobileUniqueError(true)
    } else {
      setVerified(false)
      setMobileVerification(false)
      setShowChangeNumber(false)
      setResponseMessage('')
      setSeconds(60)
    }

    setChangeNumberVerifyLoader('Verify')
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-5'>
          <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'> Business Information</h3>
          <div className='row'>
            <div className='col-lg-9 col-xl-9 col-xxl-8'>
              <div className='row'>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Business Name</label>
                  <div>
                    <span className='font-14 text-dark'>{customer && customer.business_name}</span>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Type</label>
                  <div>
                    <span className='font-14 text-dark text-capitalize'>
                      {customer && customer.type ? customer.type : '-'}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Nature of Business</label>
                  <div>
                    <span className='font-14 text-dark text-capitalize'>
                      {!customer || isEmpty(customer.nature_of_business)
                        ? '-'
                        : customer.nature_of_business}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Category</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!customer || isEmpty(customer.category_name) ? '-' : customer.category_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-md-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Years of Operations</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && moment(customer.operations_since).fromNow()}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Size of Business</label>
                  <div>
                    <span className='font-14 text-dark text-capitalize'>
                      {customer && customer.size}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-2'>Contact Information</h3>
          <div className='row'>
            <div className='col-lg-9 col-xl-9 col-xxl-8'>
              <div className='row'>
                <div className='col-12 mb-6'>
                  <label className='font-14 text-muted mb-2'>Address</label>
                  {customer && checkEmpty(customer.address) !== '-' ? (
                    <div>
                      {customer &&
                        customer.verification_statuses &&
                        !customer.verification_statuses.business_address_verified && (
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-muted ic text-danger me-1'
                          />
                        )}
                      <span className='font-14 text-dark'>{checkEmpty(customer.address)}</span>
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>District</label>
                  <div>
                    <span className='font-14 text-dark'>{customer && customer.district}</span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Ward</label>
                  <div>
                    <span className='font-14 text-dark'>{customer && customer.ward}</span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Street</label>
                  <div>
                    <span className='font-14 text-dark'>{customer && customer.street}</span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Pincode</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && customer.pin_code ? customer.pin_code : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Promoter's First Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && checkEmpty(customer.first_name, '-')}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Promoter's Last Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && checkEmpty(customer.last_name, '-')}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Mobile Number</label>
                  <div className='d-flex align-items-center'>
                    <>
                      {userType.toLowerCase() !== 'sa' &&
                        customer &&
                        customer.verification_statuses &&
                        !customer.verification_statuses.contact_verified && (
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-muted ic text-danger me-1'
                          />
                        )}
                    </>
                    <span className='font-14 text-dark'>
                      {customer && `${tenantConfiguration.baseCountryCode} ${customer.contact_no}`}
                    </span>
                    <>
                      {userType.toLowerCase() !== 'sa' &&
                        customer &&
                        customer.verification_statuses &&
                        !customer.verification_statuses.contact_verified && (
                          <>
                            <button
                              className='btn btn-link ms-3 p-0 '
                              type='button'
                              onClick={handleShowMobileVerification}
                            >
                              Verify
                            </button>
                          </>
                        )}
                    </>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Email</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && checkEmpty(customer.email)}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>DOB of Promoter</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && customer.dob_of_promoter
                        ? moment(customer.dob_of_promoter).format(globalDateFormate())
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Premises (Address)</label>
                  <div>
                    <span className='font-14 text-dark'>{customer && customer.premises_type}</span>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Mnada Locations</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!isEmpty(customer) &&
                      !isEmpty(customer.is_mnandani) &&
                      !isEmpty(customer.mnada_locations) &&
                      customer.mnada_locations.length > 0 ? (
                        <>
                          {customer &&
                            customer.mnada_locations &&
                            customer.mnada_locations.map((item: any) => {
                              return (
                                <>
                                  <div className='row'>
                                    <div className='col-md-6'>
                                      <div className='mb-3'>
                                        {' '}
                                        {isEmpty(item.day)
                                          ? '-'
                                          : item.day.replace(/^./, item.day[0].toUpperCase())}{' '}
                                      </div>
                                    </div>

                                    <div className='col-md-6'>
                                      {' '}
                                      {isEmpty(item.mnada_location_name)
                                        ? '-'
                                        : item.mnada_location_name}
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-xl-3 col-xxl-4'>
              <div className='row'>
                <div className='col'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='pb-2'>Customer Location</label>
                    {!['ts', 'sa'].includes(userType.toLowerCase()) && (
                      <button
                        className='btn btn-link py-0 ps-2 pb-2'
                        onClick={() =>
                          fetchLocation(
                            setCustomerCaptureLatLong,
                            setCaptureLocation,
                            setLocationDisable
                          )
                        }
                      >
                        {locationDisable
                          ? 'Please Wait...'
                          : customer &&
                            customerCaptureLatLong &&
                            !isEmpty(customerCaptureLatLong.latitude) &&
                            !isEmpty(customerCaptureLatLong.longitude)
                          ? 'Re-Capture Location'
                          : 'Capture Location'}
                      </button>
                    )}
                  </div>
                  <div className='rounded h-220px bg-light'>
                    {!isEmpty(customerCaptureLatLong.latitude) &&
                    !isEmpty(customerCaptureLatLong.longitude) ? (
                      <CustomerMap
                        googleMapURL={googleApi}
                        loadingElement={<div style={{height: `100%`}} />}
                        containerElement={<div style={{height: `100%`}} />}
                        mapElement={<div style={{height: `100%`}} />}
                        center={{
                          lat: parseFloat(customerCaptureLatLong.latitude),
                          lng: parseFloat(customerCaptureLatLong.longitude),
                        }}
                        customerLocation={{
                          latitude: parseFloat(customerCaptureLatLong.latitude),
                          longitude: parseFloat(customerCaptureLatLong.longitude),
                        }}
                        isMarkerShown
                      />
                    ) : (
                      <p className='text-center' style={{paddingTop: '27%'}}>
                        <h3>Yet to be captured by SR!</h3>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<NoRecords/>*/}
          {/* end::Item */}
          <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-5'>Credit Information</h3>
          <div className='row'>
            <div className='col-lg-9 col-xl-9 col-xxl-8'>
              <div className='row'>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Credit Range</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!customer || isEmpty(customer.credit_range) ? '-' : customer.credit_range}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Credit Amount Cap</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!customer || isEmpty(customer.credit_amount_cap)
                        ? '-'
                        : `${tenantConfiguration.currencyCode} ${
                            numberFormat(customer.credit_amount_cap).displayFormat
                          }`}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Credit Cycle Cap</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!customer || isEmpty(customer.credit_cycle) ? '-' : customer.credit_cycle}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-2'>Other Information</h3>
          <div className='row'>
            <div className='col-lg-9 col-xl-9 col-xxl-8'>
              <div className='row'>
                <div className='col-sm-12 mb-6'>
                  <label className='font-14 text-muted mb-2'>Notes</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && customer.note !== '' ? customer.note : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Prior Relation with EAF</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {customer && ['True', true].includes(customer.prior_relationship)
                        ? 'Yes'
                        : 'No'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Personal Family Size</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {/* {customer && customer.personal_family_size} */}
                      {personalFamilySize != '' ? personalFamilySize : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Documents
            showWrite={permission.write || userType.toLowerCase() === 'ts'}
            documents={documents}
            setDocuments={setDocuments}
            isOverView={true}
            customerId={customer ? customer.id : 0}
          />
        </div>
      </div>

      {/* mobile verification */}
      <Modal show={mobileVerification} onHide={handleCloseMobileVerification} centered>
        <Modal.Header closeButton>
          <Modal.Title>Mobile Number Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          <p className='mb-0'>Do you want to initiate mobile verification now?</p>
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-light' onClick={handleCloseMobileVerification}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={mobileVerified}
            disabled={isVerifyButtonLoader !== 'Verify'}
          >
            {isVerifyButtonLoader}
          </button>
        </Modal.Footer>
      </Modal>

      {/* otp modal */}
      <Modal show={show} onHide={handleCloseOtp} centered>
        <Modal.Header closeButton>
          <Modal.Title>Mobile Number Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          <p className='mb-5'>
            Please enter the verification code sent to customer's mobile number{' '}
            <span className='text-primary'>
              {mobileNumber
                ? mobileNumber
                : customer && `${tenantConfiguration.baseCountryCode} ${customer.contact_no}`}
              {/* {customer && `${tenantConfiguration.baseCountryCode} ${customer.contact_no}`} */}
            </span>
          </p>
          <div className='mb-4'>
            <label className='form-label'>Verification Code</label>
            <input
              type='text'
              className={clsx('form-control', codeValidation)}
              placeholder='Type here...'
              name='code'
              onChange={handleChange}
              value={code}
              maxLength={6}
            />
          </div>
          <div className='mb-3'>
            <ErrorHandler errorMessage={responseMessage} />
          </div>
          <div className='row'>
            <div className='col'>
              <a
                href='#'
                className='text-primary'
                onClick={() => {
                  setIsMobileUniqueError(false)
                  setShowChangeNumber(true)
                  setMobileNumber('')
                  setResponseMessage('')
                  setCodeValidation('')
                }}
              >
                Change Number
              </a>
            </div>
            <div className='col text-end'>
              {seconds === 0 ? (
                <a href='JAVASCRIPT:void(0)' onClick={mobileVerified}>
                  Resend Code
                </a>
              ) : (
                <p className='text-primary disabled opacity-50 mb-0'>
                  Resend Code in 00:{seconds < 10 ? '0' + seconds : seconds}
                </p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-light' onClick={handleCloseOtp}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={otpVerified}
            disabled={otpVerifyLoader !== 'Verify'}
          >
            {otpVerifyLoader}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Change number */}
      <Modal show={showChangeNumber} onHide={handleCloseChangeNumber} centered>
        <Modal.Header closeButton>
          <Modal.Title>Mobile Number Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          <p className='mb-5'>
            Initiating mobile number verification process will send verification code to the
            customer’s registered mobile number.
          </p>
          <div className='mb-4'>
            <MobileNumberInput
              label='Registered Mobile Number'
              validation={mobileValidation}
              onChange={handleChange}
              numberValue={mobileNumber}
              name='mobile'
            />
          </div>
          {isMobileUniqueError && (
            <div className='mb-3'>
              <ErrorHandler errorMessage={FormatMessage('CUSTOMER_MOBILE_ALREADY_EXIST')} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-light' onClick={handleCloseChangeNumber}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={changeNumberVerified}
            disabled={changeNumberVerifyLoader !== 'Verify'}
          >
            {changeNumberVerifyLoader}
          </button>
        </Modal.Footer>
      </Modal>

      {/* verified modal */}
      <Modal show={verified} onHide={handleCloseVerified} centered>
        <Modal.Header closeButton>
          <Modal.Title>Mobile Number Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-12'>
          Congratulations, <span>{customer && customer.business_name}'s</span> mobile number has
          been verified successfully.
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-primary' onClick={handleCloseVerified}>
            OK
          </button>
        </Modal.Footer>
      </Modal>

      <EaModal
        modalTitle='Business Address Verification Request'
        id='ea_verification_request_modal'
        actionBtnName='OK'
      >
        <>
          <p className='mb-0'>
            Business address verification request has been initiated and will be sent to the SR in
            the next customer order.
          </p>
        </>
      </EaModal>
      {/* rejection reason popup */}
      <EaModal
        modalTitle='Reject Document'
        children={
          <>
            <p>Are you sure, you want to reject this document?</p>
            <div className='mb-4'>
              <label className='form-label'>Reject Document Reason</label>
              <AsyncPaginate
                loadOptions={fetchRejectionReason}
                isSearchable
                className={clsx('react-select-container w-100', '')}
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            <div>
              <label className='form-label'>Comments</label>
              <textarea className='form-control' rows={4} placeholder='Type here…'></textarea>
            </div>
          </>
        }
        id={'ea_modal_reject_reason'}
        actionBtnName='Yes, Proceed'
      />

      {/* Capture Location */}
      <Modal show={captureLocation} onHide={handleCloseCaptureLocation} centered>
        <Modal.Header closeButton>
          <Modal.Title>Capture Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className='bg-light h-200px'>
            <CustomerMap
              googleMapURL={googleApi}
              loadingElement={<div style={{height: `100%`}} />}
              containerElement={<div style={{height: `100%`}} />}
              mapElement={<div style={{height: `100%`}} />}
              center={{
                lat: parseFloat(customerCaptureLatLong.latitude),
                lng: parseFloat(customerCaptureLatLong.longitude),
              }}
              customerLocation={{
                latitude: parseFloat(customerCaptureLatLong.latitude),
                longitude: parseFloat(customerCaptureLatLong.longitude),
              }}
              isMarkerShown
            />
          </div>
          <div className='px-md-4'>
            <p className='mb-0 py-6'>
              Please press the "Capture Now" button if you are at the customer location and
              accurately see the location pin on the above map.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer justify-content-center'>
          <button type='button' className='btn btn-light' onClick={handleCloseCaptureLocation}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            disabled={locationButtonDisable}
            onClick={() =>
              saveLocation(
                customer.id,
                customerCaptureLatLong,
                setLocationButtonDisable,
                setCaptureLocation,
                fetchCustomerDetails
              )
            }
          >
            {locationButtonDisable ? 'Please Wait...' : 'Capture Now'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Overview
