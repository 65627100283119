import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { roleCreate, roleEdit, RolesPermissions, RolesSingle } from '../../../../_eaFruitsDms/apiFunctions/rolesAndPermission/RoleAndPermission'
import { EaAlertDanger, EaAlertSuccess } from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import TableRolesPermission from './components/tableRolesPermission'
import $ from 'jquery'
import clsx from 'clsx'
import { getToken } from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import { Helmet } from 'react-helmet'
import capitalizeFirstLetter from '../../../../_eaFruitsDms/helpers/components/CapitalLetter'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import ValidationHandler from '../../../../_eaFruitsDms/helpers/components/validationHandler'
import { apiBaseURL, checkPermissions, defaultPermission, focusVatidateField, isModalValidation, KTSVG, } from '../../../../_eaFruitsDms/helpers'
import { getRequest } from '../../../../_eaFruitsDms/https/apiCall'
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"

type Props = {
  className: string
}

const RolesPermission: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'Super Admin Users',
    subTitle: 'Roles & Permissions',
  }

  // all states
  const [getList, setGetList] = useState<any>([])
  const [getList_2, setGetList_2] = useState<any>([])
  const [getRolesList, setGetRolesList] = useState<any>([])
  const [id, setId] = useState<any>('')
  const [roleName, setRoleName] = useState<any>('')
  const [description, setDescription] = useState<any>('')
  const [roleNameValidation, setRoleNameValidation] = useState<any>('')
  const [isLoadingAdd, setisLoadingAdd] = useState<any>('Submit')
  const [isLoadingEdit, setisLoadingEdit] = useState<any>('Submit')
  const [roleNameEdit, setRoleNameEdit] = useState<any>('')
  const [descriptionEdit, setDescriptionEdit] = useState<any>('')
  const [roleNameValidationEdit, setRoleNameValidationEdit] = useState<any>('')
  const [userType, setUserType] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [listLoadder, setListLoader] = useState<any>(true)
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [messageTitle, setMessageTitle] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [errorMessage, setErrorMessage] = useState('')
  const [editErrorMessage, setEditErrorMessage] = useState('')
  const [modalLoader, setModalLoader] = useState<any>(false)

  // Authorization token
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  useEffect(() => {
    fetchPermission()

    getRoles()
    RolesPermissions().then((res) => {
      setGetList(res)
      setListLoader(false)
    })
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("super_admin_roles_and_permissions")
    setPermission(res)
   }

  // get role list
  //request method for api implementation :- last change by kriti

  const getRoles = async () => {
    const res: any = await getRequest(`${apiBaseURL()}auth/role?limit=1000`, true)
    setGetRolesList(res.results)
  }

  const addRole = () => {
    let navigateField = {
      roleName: roleName,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // ui validation [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    if (roleName == '') {
      setRoleNameValidation('is-invalid')
    } else {
      setRoleNameValidation('')
    }

    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE FUNCTIONS ]
    if (roleName !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      roleCreate(roleName, 'sa', description == '' ? null : description , "sa").then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        setisLoadingAdd('Submit')
        if (res.success) {
          getRoles()
          RolesPermissions().then((res: any) => {
            setGetList(res)
          })
          setIsAlertSuccess(true)
          $('#closeAddUser').trigger('click')
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
        }
        // if (res.failed) {
        //   // setIsAlertFailed(true)
        // }
        if (res.errorField == 'name') {
          setErrorMessage(
            'Your added data is already exist in this system! Please try again with unique data.'
          )
        }
      })
    }
  }

  const editRole = () => {
    let navigateField = {
      roleNameEdit: roleNameEdit,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (roleNameEdit == '') {
      setRoleNameValidationEdit('is-invalid')
    } else {
      setRoleNameValidationEdit('')
    }

    if (roleNameEdit !== '') {
      setisLoadingEdit('Please Wait...')
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      roleEdit(id, roleNameEdit, userType, descriptionEdit).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        setisLoadingEdit('Submit')
        if (res.success) {
          getRoles()
          RolesPermissions().then((res: any) => {
            setGetList(res)
          })
          setIsAlertSuccess(true)
          $('#closeEditUser').trigger('click')
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
        }
        // if (res.failed) {
        //   setIsAlertFailed(true)
        // }
        if (res.errorField == 'name') {
          setEditErrorMessage(
            'Your added data is already exist in this system! Please try again with unique data.'
          )
        }
      })
    }
  }

  const clearFunction = () => {
    // clear add modal state
    setRoleName('')
    setRoleNameValidation('')
    setDescription('')
    setisLoadingAdd('Submit')
    setErrorMessage("")
  }

  const clearFunctionEdit = () => {
    // clear edit modal state
    setRoleNameEdit('')
    setRoleNameValidationEdit('')
    setDescriptionEdit('')
    setisLoadingEdit('Submit')
    setEditErrorMessage("")
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const getProps = (val: any) => {
    setMessageHandler('successEdit')
    setModalLoader(true)
    let id = val.id
    setId(id)
    RolesSingle(id).then((res: any) => {
      setRoleNameEdit(res.name)
      setDescriptionEdit(res.short_description)
      setUserType(res.user_type)
      setModalLoader(false)
    })
  }

  const scroll: any = document.getElementById('smoothscrolling')
  const slide = (shift: any) => {
    scroll.scrollLeft += shift
  }

  const [leftDirection, setLeftDirection] = useState(0)
  const [RightDirection, setRightDirection] = useState(0)

  return (
    <>
      <EATitle title='Roles & Permissions' />
      <div>
        <PageTitle breadcrumbs={[adminHeaderTitle]}>
          {intl.formatMessage({ id: 'Roles & Permissions' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('ROLE.ADD', messageTitle)
              : FormatMessage('ROLE.UPDATE', messageTitle)
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className='card scrolldiv'>
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <div className='ms-md-auto'>
              <div className='card-toolbar d-flex my-0'>
                <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                  <button
                    onClick={() => slide(-300)}
                    className='btn btn-icon py-0 text-hover-primary btn-sm'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-1 me-0'
                    />
                  </button>
                  <button
                    onClick={() => slide(300)}
                    className='btn btn-icon py-0 text-hover-primary btn-sm me-3'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-1' />
                  </button>
                  {permission.write && (
                    <EaButton
                      isModal={true}
                      btnName='Add Role'
                      dataTarget='#ea_modal_create_role'
                      classNameText='my-1 btn-sm'
                      onClick={() => {
                        setMessageHandler('successAdd')
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card-body py-3 overflow-auto'>
            <div className='table-responsive smoothscroll' id='smoothscrolling'>
              {listLoadder ? (
                <IsLoader />
              ) : (
                <TableRolesPermission
                  id='smoothscrolling'
                  showWrite={permission.write}
                  showRead={permission.read}
                  showDelete={permission.delete}
                  tableList={getList}
                  getPropsEdit={getProps}
                  getRolesList={getRolesList}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* add role */}
      <EaModal
        modalTitle='Add New Role'
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        onSubmit={addRole}
        cancel={clearFunction}
        closeAuto='closeAddUser'
        children={
          <>
            <div className='row'>
              <div className='col-sm-12 mb-4'>
                <label className='form-label'>
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  type='text'
                  id='roleName'
                  className={clsx('form-control', roleNameValidation)}
                  value={roleName}
                  onChange={(e) => {
                    setRoleName(capitalizeFirstLetter(e.target.value))
                    const validation = ValidationHandler(e.target.value, 'is-invalid')
                    setRoleNameValidation(validation)
                  }}
                  placeholder='Type here...'
                />
              </div>
            </div>
            <label className='form-label'>Short Description (Optional)</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(capitalizeFirstLetter(e.target.value))}
              className='form-control'
              placeholder='Type here...'
            ></textarea>
             <div className='mt-4'>
              <ErrorHandler errorMessage={errorMessage} />
            </div>
          </>
        }
        id={'ea_modal_create_role'}
      />

      <EaModal
        modalTitle='Edit Role'
        onSubmit={editRole}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionEdit}
        closeAuto='closeEditUser'
        loader={modalLoader}
        children={
          <>
            <div className='row'>
              <div className='col-sm-12 mb-4'>
                <label className='form-label'>Name</label>
                <input
                  type='text'
                  id='roleNameEdit'
                  className={clsx('form-control', roleNameValidationEdit)}
                  value={roleNameEdit}
                  onChange={(e) => {
                    setRoleNameEdit(e.target.value)
                    const validation = ValidationHandler(e.target.value, 'is-invalid')
                    setRoleNameValidationEdit(validation)
                  }}
                  placeholder='Type here...'
                />
              </div>
            </div>
            <label className='form-label'>Short Description (Optional)</label>
            <textarea
              value={descriptionEdit}
              onChange={(e) => setDescriptionEdit(e.target.value)}
              className='form-control'
              placeholder='Type here...'
            ></textarea>
             <div className='mt-4'>
              <ErrorHandler errorMessage={editErrorMessage} />
            </div>
          </>
        }
        id={'ea_modal_edit_role'}
      />
    </>
  )
}

export default RolesPermission
