const ShowingEntries = (props: any) => {
    const { list, selected, count } = props.response

    return (
        <>
            {`Showing ${list.length == 0 ? 0 : selected * 10 + 1} to ${list.length == 0 ? 0 : list.length < 10 ? count : (selected + 1) * 10} of ${list.length == 0 ? 0 : count} entries`}
        </>
    )
}

export default ShowingEntries