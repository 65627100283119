import React, {useEffect, useState} from 'react'
import {
  KTSVG,
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  getFirstWarehouse,
  getLoginWarehouse,
  globalDateFormate,
} from '../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import {EaChartPie} from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../../TenantVariables'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import Select from 'react-select'

const OrderFeedbackReportWM = () => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Reports',
  }

  const ratingsOptions: any = [
    {label: 'All Ratings', value: ''},
    {label: 'Rating Received', value: 'true'},
    {label: 'Rating Pending', value: 'false'},
  ]

  const getUserType = localStorage.getItem('user_type') || ''
  const getW_Id: any = localStorage.getItem('w_id')
  const wId = getUserType.toLowerCase() !== 'sa' ? getW_Id : ''
  const baseUrl = apiBaseURL()
  const [offset, setOffset] = useState(0)
  const [offsetList, setOffsetList] = useState(0)
  const [ratingReceived, setRatingReceived] = useState({
    data: [],
    title: 'Rating Received',
    bottom: '10%',
  })
  const [feedBackOrder, setFeedBackOrder] = useState({
    data: [],
    title: 'Feedback on Total Orders',
    bottom: '10%',
  })
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [dropDownSearchList, setDropDownSearchList] = useState('')
  const [ratings, setRatings] = useState<any>('')
  const [list, setList] = useState([])
  const [listing, setListing] = useState([])
  const [isReportLoading, setIsReportLoading] = useState(true)
  const [isListLoading, setIsListLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [warehouseValueReport, setWarehouseValueReport] = useState<any>(
    getLoginWarehouse().value !== null ? getLoginWarehouse() : ''
  )
  const [warehouseValueList, setWarehouseValueList] = useState<any>(
    getLoginWarehouse().value !== null ? getLoginWarehouse() : ''
  )
  const [date, setDate] = useState<any>({
    startDate: moment(),
    endDate: moment(),
  })
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [reportDate, setReportDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [order, setOrder] = useState([])
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const debounceSearch = useDebounce(search, 500)

  const userType = localStorage.getItem('user_type') || ''

  const checkUserType = () => {
    if (userType !== '') {
      if (userType === 'SA' || userType === 'TS') {
        return true
      } else {
        return false
      }
    }
  }

  // fetch chart Warehouse Dropdown
  const fetchWarehouse = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `warehouse/list?${
        checkUserType() === true ? '' : `parent_warehouse__id=${wId}`
      }&ordering=name`,
      search,
      setDropDownSearch,
      'name',
      checkUserType() === true ? true : false,
      'All Warehouses'
    )
  }

  // fetch chart Warehouse Dropdown
  const fetchWarehouseList = async (search: any) => {
    return fetchAsyncDropdown(
      offsetList,
      setOffsetList,
      listing,
      setListing,
      `warehouse/list?${
        checkUserType() === true ? '' : `parent_warehouse__id=${wId}`
      }&ordering=name`,
      search,
      setDropDownSearchList,
      'name',
      checkUserType() === true ? true : false,
      'All Warehouses'
    )
  }

  // fetch pie-charts
  const fetchPieCharts = async () => {
    setIsReportLoading(true)

    let warehouseFilter = ''

    if (checkUserType() === true) {
      if (warehouseValueReport !== '' && warehouseValueReport.value) {
        warehouseFilter = `&warehouse_id=${warehouseValueReport.value}`
      } else {
        warehouseFilter = `&warehouse_id=`
      }
    }

    if (checkUserType() === false) {
      warehouseFilter = `&warehouse_id=${
        warehouseValueReport !== '' && warehouseValueReport.value !== ''
          ? warehouseValueReport.value
          : wId
      }`
    }

    const param =
      `start_date=${reportDate.startDate}&end_date=${reportDate.endDate}` + warehouseFilter
    const chartUrl = baseUrl + 'reports/orders-feedback?' + param
    const chartResponse = await getRequest(chartUrl, true)

    if (chartResponse.result) {
      const {feedback_received, feedback_pending, rating_counts} = chartResponse.result
      let updateRating: any = {...ratingReceived}
      let updateFeedBackOrder: any = {...feedBackOrder}
      let handleRating: object[] = []

      updateFeedBackOrder.data = [
        {...(feedback_pending !== 0 && {value: feedback_pending, name: 'Feedback pending'})},
        {...(feedback_received !== 0 && {value: feedback_received, name: 'Feedback received'})},
      ]

      for (const key in rating_counts) {
        if (rating_counts[key] !== 0) {
          handleRating.push({name: `${key} star`, value: rating_counts[key]})
        }
      }

      updateRating.data = handleRating

      setRatingReceived(updateRating)
      setFeedBackOrder(updateFeedBackOrder)
    }
    setIsReportLoading(false)
  }

  // fetch list
  const fetchList = async () => {
    setIsListLoading(true)

    let warehouseFilter = ''

    if (checkUserType() === true) {
      if (warehouseValueList !== '' && warehouseValueList.value) {
        warehouseFilter = `&warehouse_id=${warehouseValueList.value}`
      } else {
        warehouseFilter = `&warehouse_id=`
      }
    }

    if (checkUserType() === false) {
      warehouseFilter = `&warehouse_id=${
        warehouseValueList !== '' && warehouseValueList.value !== ''
          ? warehouseValueList.value
          : wId
      }`
    }

    const param =
      `limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&start_date=${
        apiDate.startDate
      }&end_date=${apiDate.endDate}&rating=${ratings !== '' ? ratings.value : ''}` + warehouseFilter
    const url = baseUrl + 'reports/orders?' + param
    const response = await getRequest(url, true)

    if (response) {
      setOrder(response.results)
      setCount(response.count)
    }
    setIsListLoading(false)
  }

  useEffect(() => {
    fetchPieCharts()
  }, [warehouseValueReport, reportDate])

  useEffect(() => {
    fetchList()
  }, [debounceSearch, currentOffset, apiDate, warehouseValueList, ratings])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Orders Feedback - ' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Orders Feedback'})}
      </PageTitle>
      {/* chart start */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              {/* begin::Title */}
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Order Feedback</span>
              </h3>
              {/* end::Title */}
              {/* begin::Toolbar */}
              <div className='card-toolbar d-flex g-3 my-0'>
                <AsyncPaginate
                  loadOptions={fetchWarehouse}
                  isSearchable
                  placeholder='All Warehouses'
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  name='select_team'
                  onChange={(e) => {
                    setWarehouseValueReport(e)
                  }}
                  value={warehouseValueReport}
                  isDisabled={isReportLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setReportDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                  }}
                  value={reportDate}
                  startDate={reportDate.startDate}
                  endDate={reportDate.endDate}
                />
              </div>
              {/* end::Toolbar */}
            </div>

            <div className='card-body'>
              <div className='row'>
                {isReportLoading && <IsLoader />}
                {ratingReceived.data.length === 0 ? (
                  <NoRecords />
                ) : (
                  <>
                    <div className='col-md-6'>
                      <EaChartPie item={feedBackOrder} />
                    </div>
                    <div className='col-md-6'>
                      <EaChartPie item={ratingReceived} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* chart end */}
      <div className='card'>
        {isListLoading && <IsLoader />}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar
            onChange={(e: any) => {
              setSearch(e.target.value)
              setCurrentOffset(0)
              setSelected(0)
            }}
            value={search}
            isDisable={isListLoading}
          />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <Select
                options={ratingsOptions}
                isSearchable
                value={ratings}
                onChange={(e: any) => {
                  setRatings(e)
                  setCurrentOffset(0)
                  setSelected(0)
                }}
                placeholder='All Ratings'
                className='react-select-container my-1 me-3 w-160px'
                classNamePrefix='react-select'
                isDisabled={isListLoading}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchWarehouseList}
                isSearchable
                placeholder='All Warehouses'
                className='react-select-container my-1 me-3 w-160px'
                classNamePrefix='react-select'
                name='select_team'
                onChange={(e) => {
                  setWarehouseValueList(e)
                  setCurrentOffset(0)
                  setSelected(0)
                }}
                value={warehouseValueList}
                isDisabled={isListLoading}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <CustomDatePickerComponent
                onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                  setApiDate({
                    startDate: callBakeDate.startDate,
                    endDate: callBakeDate.endDate,
                  })
                  setCurrentOffset(0)
                  setSelected(0)
                }}
                value={apiDate}
                startDate={apiDate.startDate}
                endDate={apiDate.endDate}
              />
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {order.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-100px py-2'>Date</th>
                    <th className='min-w-100px py-2'>Order ID</th>
                    <th className='min-w-120px py-2'>Customer Name</th>
                    <th className='min-w-120px py-2'>Rating</th>
                  </tr>
                </thead>
                <tbody>
                  {order.length > 0 &&
                    order.map((item: any) => {
                      return (
                        <tr>
                          <td>
                            {item.order_date && item.order_date !== ''
                              ? dateFormat(item.order_date).displayDate
                              : '-'}
                          </td>
                          <td>
                            {item.order_no && item.order_no !== '' ? `#${item.order_no}` : '-'}
                          </td>
                          <td>
                            {item.customer_name && item.customer_name !== ''
                              ? item.customer_name
                              : '-'}
                          </td>
                          <td>
                            {item.rating && item.rating !== null ? (
                              <>
                                <KTSVG
                                  path='/media/icons/duotune/general/gen029.svg'
                                  className='ic text-warning me-1'
                                />
                                {item.rating}
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            )}
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <CustomPaginate
        data={order}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={isListLoading}
        saveFilterName={'feedbackReport'}
        filterStates={''}
      />
    </>
  )
}

export default OrderFeedbackReportWM
