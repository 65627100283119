import React from "react"
import { KTSVG } from "../../../helpers"
import IsLoader from "../loader/loader"

type modalProps = {
  children: any,
  modalTitle: string,
  modalClass?: string,
  id: string,
  actionBtnName?: string,
  onSubmit?: any,
  closeAuto?: any,
  cancel?: any,
  widthcls?: string
  btnDisabled?: any,
  cancelBtnDisabled?: any,
  subTitle?: string
  loader?: any
}
const EaModal: React.FC<modalProps> = ({ children, modalTitle, id, actionBtnName, widthcls, cancel, closeAuto, onSubmit, btnDisabled, subTitle, cancelBtnDisabled, modalClass , loader }) => {

  return (
    <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
      <div
        className={modalClass ? `${modalClass} modal fade` : 'modal fade'}
        id={id} aria-hidden='true' data-bs-backdrop="static" data-bs-keyboard="false">
        <div
          className={
            widthcls ? `${widthcls} modal-dialog modal-dialog-centered` : 'modal-dialog modal-dialog-centered mw-470px'}
        >
          <div className='modal-content'>
          <div className='modal-header'>
              <div className=''>
                <h2 className='modal-title fw-bold'>{modalTitle}</h2>
                <p className='mb-0'>{subTitle}</p>
              </div>
              <div onClick={cancel} className='btn p-1 mh-24 btn-active-light-primary ms-2' data-bs-dismiss='modal' >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
              </div>
            </div>
            {loader && <IsLoader/> } 
            <div className='modal-body px-md-12'>
              {children}
            </div>
            <div className="modal-footer justify-content-center">
              <button onClick={cancel} type="button" disabled={cancelBtnDisabled} className="btn btn-light" id={closeAuto ? closeAuto : 'defaultCloseModal'} data-bs-dismiss="modal">Cancel</button>
              <button disabled={btnDisabled} type="button" className="btn btn-primary" onClick={onSubmit} >
                {actionBtnName ? actionBtnName : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
export default EaModal
