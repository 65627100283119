import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {
  KTSVG,
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  getFirstWarehouse,
  getLoginWarehouse,
  globalDateFormate,
} from '../../../../_eaFruitsDms/helpers'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import tenantConfiguration from '../../../../TenantVariables'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {AsyncPaginate} from 'react-select-async-paginate'

type Props = {
  className: string
}

const IssuesFromSG: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Issues Reported from Security Gatekeeper',
  }
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [gateKeeper, setGateKeeper] = useState([])
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [search, setSearch] = useState('')
  const [isListLoading, setIsListLoading] = useState(false)
  const [warehouseValueReport, setWarehouseValueReport] = useState<any>(
    getLoginWarehouse().value !== null ? getLoginWarehouse() : ''
  )
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')

  const currentWarehouseId: any = localStorage.getItem('w_id')
  const debounceSearch = useDebounce(search, 500)
  const userType = localStorage.getItem('user_type') || ''

  const checkUserType = () => {
    if (userType !== '') {
      if (userType === 'SA' || userType === 'TS') {
        return true
      } else {
        return false
      }
    }
  }

  // fetch chart Warehouse Dropdown
  const fetchWarehouse = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `warehouse/list?${
        checkUserType() === true ? '' : `parent_warehouse__id=${currentWarehouseId}`
      }&ordering=name`,
      search,
      setDropDownSearch,
      'name',
      checkUserType() === true ? true : false,
      'All Warehouses'
    )
  }

  const fetchList = async () => {
    setIsListLoading(true)

    let warehouseFilter = ''

    if (checkUserType() === true) {
      if (warehouseValueReport !== '' && warehouseValueReport.value) {
        warehouseFilter = `&warehouse_id=${warehouseValueReport.value}`
      } else {
        warehouseFilter = `&warehouse_id=`
      }
    }

    if (checkUserType() === false) {
      warehouseFilter = `&warehouse_id=${
        warehouseValueReport !== '' && warehouseValueReport.value !== ''
          ? warehouseValueReport.value
          : currentWarehouseId
      }`
    }

    const param =
      `limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&start_date=${apiDate.startDate}&end_date=${apiDate.endDate}` +
      warehouseFilter
    const url = apiBaseURL() + 'reports/sg-gatepass-issues?' + param
    const response = await getRequest(url, true)

    if (response) {
      setGateKeeper(response.results)
      setCount(response.count)
    }
    setIsListLoading(false)
  }

  useEffect(() => {
    fetchList()
  }, [debounceSearch, currentOffset, apiDate, warehouseValueReport])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format(globalDateFormate()),
            endDate: moment(endDate).format(globalDateFormate()),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: globalDateFormate(),
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
            disabled={isListLoading}
          />
        </DateRangePicker>
      </>
    )
  }

  const checkStatus = (item: any) => {
    if (item.status) {
      if (item.status === 'sg_reported_at_entry_check') return 'SG Reported at Entry'
      if (item.status === 'sg_rejected_at_exit_check') return 'SG Rejected at Exit'
    } else {
      return '-'
    }
  }

  const showBrand = (item: any) => {
    if (item.products_brand && item.products_brand.length > 0) {
      const length = item.products_brand.length

      if (length > 1) {
        return (
          <OverlayTrigger
            delay={{hide: 450, show: 300}}
            overlay={(props) => (
              <Tooltip {...props} className='tooltip-dark'>
                {item.products_brand &&
                  item.products_brand.map((item: any, index: any) => {
                    return (
                      <>
                        <span>
                          {index ? ',' : ''} {item.name}{' '}
                        </span>
                      </>
                    )
                  })}
              </Tooltip>
            )}
            placement='top'
          >
            <span className='ms-2 cursor-pointer'>
              {item.products_brand[0].name}
              {` & ${length} more`}
            </span>
          </OverlayTrigger>
        )
      } else {
        return <span className='ms-2 cursor-pointer'>{item.products_brand[0].name}</span>
      }
    }
  }

  return (
    <>
      <EATitle title='Issues Reported by SG - ' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Issues Reported by SG'})}
      </PageTitle>
      <div>
        <div className='card'>
          {isListLoading && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              value={search}
              isDisable={isListLoading}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                <AsyncPaginate
                  loadOptions={fetchWarehouse}
                  isSearchable
                  placeholder='All Warehouses'
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  name='select_team'
                  onChange={(e) => {
                    setWarehouseValueReport(e)
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={warehouseValueReport}
                  isDisabled={isListLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setApiDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={apiDate}
                  startDate={apiDate.startDate}
                  endDate={apiDate.endDate}
                />
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              {gateKeeper.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-150px py-2'>Date & Time</th>
                      <th className='min-w-150px py-2'>SG Name</th>
                      <th className='min-w-150px py-2'>SR Name</th>
                      <th className='min-w-150px py-2'>Vehicle Number</th>
                      <th className='min-w-150px py-2'>Brand</th>
                      <th className='min-w-150px py-2'>Gatepass Number</th>
                      <th className='min-w-150px py-2'>Gatepass Status</th>
                      <th className='min-w-150px py-2'>Issue Type</th>
                    </tr>
                  </thead>

                  <tbody>
                    {gateKeeper.length > 0 &&
                      gateKeeper.map((item: any) => {
                        return (
                          <tr>
                            <td>
                              {item.rejected_at && item.rejected_at !== '' ? (
                                <>
                                  {dateFormat(item.rejected_at).displayDate}
                                  <p className='font-13 text-muted mb-0'>
                                    {dateFormat(item.rejected_at, 'LT').displayDate}
                                  </p>
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {item.processd_by && item.processd_by.id
                                ? `${item.processd_by.first_name} ${item.processd_by.last_name}`
                                : '-'}
                            </td>
                            <td>
                              {item.sr_user && item.sr_user.id
                                ? `${item.sr_user.first_name} ${item.sr_user.last_name}`
                                : '-'}
                            </td>
                            <td>
                              {item.sr_vehicle_number && item.sr_vehicle_number
                                ? item.sr_vehicle_number
                                : '-'}
                            </td>
                            <td>{showBrand(item)}</td>
                            <td>
                              {item.gatepass_number && item.gatepass_number !== ''
                                ? `#${item.gatepass_number}`
                                : '-'}
                            </td>
                            <td>{checkStatus(item)}</td>
                            <td>
                              <div>
                                <span className='me-2'>
                                  {item.reason && item.reason !== null
                                    ? item.reason.reason_name
                                    : item.reason_note && item.reason_note !== ''
                                    ? item.reason_note
                                    : '-'}
                                </span>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <CustomPaginate
          data={gateKeeper}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={isListLoading}
          saveFilterName={'feedbackReport'}
          filterStates={''}
        />
      </div>
    </>
  )
}

export default IssuesFromSG
