import {apiBaseURL} from '../../helpers'
import {getRequest, postRequest} from '../../https/apiCall'
import {getW_Id} from '../../localStorage/getItem/getLocalStorage'

//request method for api implementation :- last change by kriti
export const lowStockList = async (limit?: any, offset?: any, val?: any, id?: any) => {
  let w_id: any = ''
  if (id == '') {
    w_id = localStorage.getItem('w_id')
  } else {
    w_id = id
  }

  let queryString = ''

  if (limit != 0) {
    queryString += 'limit=' + limit + '&offset=' + offset
  }

  if (val != '') {
    if (limit != 0) {
      queryString += '&search=' + val
    } else {
      queryString += 'search=' + val
    }
  }
  const res = await getRequest(
    `${apiBaseURL()}inventory/product_low_stock/${w_id}/?${queryString}`,
    true
  )

  if (limit != 0) {
    return {
      count: res.count,
      next: res.next,
      prev: res.previous,
      data: res.results,
    }
  }

  return {
    count: res.count,
    next: res.next,
    prev: res.previous,
    // data: res.data,
    data: res.results,
  }
}

//request method for api implementation :- last change by kriti
export const getWareHouse = async () => {
  let warehouseList: any = []
  let totalWh: any = []
  let res = await getRequest(
    `${apiBaseURL()}warehouse/list?parent_warehouse__id=${localStorage.getItem(
      'w_id'
    )}&ordering=name`,
    true
  )
  let data = res.data

  data.forEach(async (element: any) => {
    totalWh.push(element)
    warehouseList.push({value: element.id, label: element.name})
  })

  res = await getRequest(`${apiBaseURL()}warehouse/${localStorage.getItem('w_id')}`, true)

  totalWh.push(res.data)

  warehouseList.push({value: res.data.id, label: res.data.name})
  return totalWh
}

//request method for api implementation :- last change by kriti
export const lowStockEdit = async (id: any, product_id: any, warehouse_id: any, low_stock: any) => {
  let w_id: any = ''
  if (warehouse_id == '') {
    w_id = localStorage.getItem('w_id')
  } else {
    w_id = warehouse_id
  }

  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let data: any = [
    {
      product_id: product_id,
      warehouse_id: w_id,
      low_stock: low_stock,
    },
  ]

  if (id > 0) {
    data = [
      {
        id: id,
        product_id: product_id,
        warehouse_id: w_id,
        low_stock: low_stock,
      },
    ]
  }

  await postRequest(`${apiBaseURL()}inventory/bulk_low_stock_create_update`, data, true)
    .then((res) => {
      notify.success = true // res
    })
    .catch((err) => {
      notify.failed = true // catch
    })
  return notify
}

export const bulkCreateUpdate = async (param: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  await postRequest(`${apiBaseURL()}inventory/bulk_low_stock_create_update`, param, true).then(
    (res) => {
      if (res.status === 201) {
        notify.success = true
      }
      if (res.status === 500) {
        notify.failed = true
      }
    }
  )

  return notify
}

// export const lowStockEdit = async (id: any, warehouse_id: any, low_stock: any) => {
//   const notify = {
//     success: false,
//     failed: false,
//     errorField: '',
//   }

//   const data = {
//     data: [{id: id, warehouse_id: warehouse_id, low_stock: low_stock}],
//   }

//   await axios
//     .post(`${apiBaseURL()}inventory/bulk_low_stock_create_update`, data, header)
//     .then((res) => {
//       notify.success = true // res
//     })
//     .catch((err) => {
//       notify.failed = true // catch
//     })
//   return notify
// }
