import React from "react"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"

const TSCustomers: React.FC = () => {
    return (
        <>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>

                    <div>
                        <input type="text" className='form-control form-search' placeholder='Search' />
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-150px'>Name</th>
                                    <th className='min-w-120px'>Phone Number</th>
                                    <th className='min-w-100px'>Total Sales</th>
                                    <th className='min-w-120px'>Credit Range</th>
                                    <th className='min-w-120px'>Credit Days</th>

                                    <th className='min-w-Safepx action-th py-2'>Credit Issue</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">AS</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Artem Sazonov</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        (484) 712-9639
                                    </td>
                                    <td>
                                        TZS 23199
                                    </td>
                                    <td>Safe</td>
                                    <td>3</td>
                                    <td>TZS 127</td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">MB</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Monica Böttger</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        (484) 712-9639
                                    </td>
                                    <td>
                                        TZS 23199
                                    </td>
                                    <td>Safe</td>
                                    <td>3</td>
                                    <td>TZS 127</td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">AS</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Artem Sazonov</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        (484) 712-9639
                                    </td>
                                    <td>
                                        TZS 23199
                                    </td>
                                    <td>Risky</td>
                                    <td>3</td>
                                    <td>TZS 127</td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">MB</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Monica Böttger</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        (484) 712-9639
                                    </td>
                                    <td>
                                        TZS 23199
                                    </td>
                                    <td>Safe</td>
                                    <td>3</td>
                                    <td>TZS 127</td>


                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">AS</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Artem Sazonov</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        (484) 712-9639
                                    </td>
                                    <td>
                                        TZS 23199
                                    </td>
                                    <td>Safe</td>
                                    <td>3</td>
                                    <td>TZS 127</td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">MB</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Monica Böttger</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        (484) 712-9639
                                    </td>
                                    <td>
                                        TZS 23199
                                    </td>
                                    <td>Safe</td>
                                    <td>3</td>
                                    <td>TZS 127</td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">AS</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Artem Sazonov</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        (484) 712-9639
                                    </td>
                                    <td>
                                        TZS 23199
                                    </td>
                                    <td>Safe</td>
                                    <td>3</td>
                                    <td>TZS 127</td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">MB</span>
                                            <div className="media-body">
                                                <p className="m-0 xs-font">Monica Böttger</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        (484) 712-9639
                                    </td>
                                    <td>
                                        TZS 23199
                                    </td>
                                    <td>Moderate</td>
                                    <td>3</td>
                                    <td>TZS 127</td>

                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div className='table-info py-5'>
                <div className='row g-0'>
                    <div className='col'>
                        Showing 1 to 10 of entries
                    </div>
                    <div className='col'>
                        <CustomPagination />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TSCustomers
