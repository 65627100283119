import React from "react"
import { useNavigate } from "react-router-dom"
import { KTSVG } from "../../../../../_eaFruitsDms/helpers"
import {
	OrderingArrowDown,
	OrderingArrowUp
} from "../../../../../_eaFruitsDms/layout/components/orderingArrow/orderingArrow"

const TableTeamReport = (props: any) => {
    const navigate = useNavigate();
    const storeWarehouseId = (id: string) => {
        localStorage.setItem('wId', id)
        localStorage.setItem('navigateBackToReport', 'true')
        navigate('/../warehouse/DC/singlewarehousename/team')
    }

    const { WarehouseName, WarehouseType, Region, ParentWarehouse, handleOrdering } = props

    return (
        <>
            <div className='table-responsive'>
                <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                    <thead>
                        <tr className='text-muted fw-500'>
                            <th className='min-w-150px'>Warehouse Name
                                <span onClick={() => handleOrdering("WarehouseName")}>
                                    {WarehouseName ? <OrderingArrowUp /> : <OrderingArrowDown />}
                                </span>
                            </th>
                            <th className='min-w-140px'>Warehouse Type
                                <span onClick={() => handleOrdering("WarehouseType")}>
                                    {WarehouseType ? <OrderingArrowUp /> : <OrderingArrowDown />}
                                </span>
                            </th>
                            <th className='min-w-120px'>Parent Warehouse
                                <span onClick={() => handleOrdering("ParentWarehouse")}>
                                    {ParentWarehouse ? <OrderingArrowUp /> : <OrderingArrowDown />}
                                </span>
                            </th>
                            <th className='min-w-120px'>Region
                                <span onClick={() => handleOrdering("Region")}>
                                    {Region ? <OrderingArrowUp /> : <OrderingArrowDown />}
                                </span>
                            </th>
                            <th className='min-w-120px'>Team Size</th>
                            <th className='min-w-40px w-40px py-2'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.list.length !== 0 && props.list.map((item: any) => (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item.warehouse_type === "DistributionCenter" ? "Distribution Center" : "Headquarter"} </td>
                                    <td>{item.parent_warehouse !== null ? item.parent_warehouse_name : "—"}</td>
                                    <td>{item.region !== null ? item.region.name : item.distribution_region}</td>
                                    <td>{item.team_size === 0 ? "—" : item.team_size}</td>
                                    <td>{props.showRead && 
                                        <button type="button" className="btn btn-sm btn-icon-muted btn-text-muted cursor-pointer mb-0 py-1"
                                               onClick={() => storeWarehouseId(item.id)}>
                                            <KTSVG path="/media/icons/duotune/arrows/arr071.svg" className="svg-icon-muted ic me-0" />
                                        </button>}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default TableTeamReport
