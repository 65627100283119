import {useEffect, useState} from 'react'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  fetchAsyncDropdown,
  focusVatidateField,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
} from '../../../../../_eaFruitsDms/helpers'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../../../TenantVariables'
import {AsyncPaginate} from 'react-select-async-paginate'

const SokoPoints = ({fromPreOrder, orderCustomerId}: any) => {
  const customerId = orderCustomerId ? orderCustomerId : localStorage.getItem('cId')

  const [balanceLoader, setBalanceLoader] = useState(true)
  const [balance, setBalance] = useState([])
  const [limit] = useState(tenantConfiguration.limit)
  const [transactionLoader, setTransactionLoader] = useState(true)
  const [transaction, setTransaction] = useState([])
  const [selected, setSelected] = useState<number>(0)
  const [currentOffSet, setCurrentOffSet] = useState<any>('0')
  const [count, setCount] = useState<string>('1')
  const [brand, setBrand] = useState({label: '', value: ''})
  const [searchSelect, setSearchSelect] = useState<any>('')
  const [brandsOffSet, setBrandsOffSet] = useState<any>('')
  const [brandsOption, setBrandsOption] = useState<any>([])

  const fetchSokoPointBalance = async () => {
    const url = `${apiBaseURL()}customers/sokopoint-balance?customer_id=${customerId}&ordering=name`
    const response = await getRequest(url, true)

    if (response?.result) {
      setBalance(response.result)
    }

    setBalanceLoader(false)
  }

  const fetchSokoPointTransaction = async () => {
    setTransactionLoader(true)

    let url = `${apiBaseURL()}customers/sokopoint-transaction?limit=${limit}&offset=${currentOffSet}&brand_id=${
      isEmpty(brand?.value) ? '' : brand?.value
    }&customer_id=${customerId}`

    const response = await getRequest(url, true)

    if (response?.results) {
      setTransaction(response.results)
      setCount(response.count)
    }

    setTransactionLoader(false)
  }

  const selectFirstBrand = async () => {
    const url = `${apiBaseURL()}master/product/brandSKU?ordering=name`
    const res = await getRequest(url, true)

    if (res?.results && res?.results?.length > 0) {
      let label = res?.results[0].name ?? ''
      let value = res?.results[0].id ?? ''

      setBrand({label: label, value: value})
    }
  }

  const fetchBrands = async (search?: string) => {
    return await fetchAsyncDropdown(
      brandsOffSet,
      setBrandsOffSet,
      brandsOption,
      setBrandsOption,
      `master/product/brandSKU?ordering=name`,
      search,
      setSearchSelect,
      'name',
      false
    )
  }

  useEffect(() => {
    if (!isEmpty(customerId)) {
      fetchSokoPointBalance()
    }
  }, [customerId])

  useEffect(() => {
    if (!isEmpty(customerId) && !isEmpty(brand?.value)) {
      fetchSokoPointTransaction()
    }
  }, [customerId, currentOffSet, brand])

  useEffect(() => {
    selectFirstBrand()
  }, [])

  return (
    <>
      <EATitle title='Sokopoint' />
      <PageTitle
        breadcrumbs={[
          {
            title: 'Customers',
            subTitle: 'Sokopoint',
          },
        ]}
      >
        Soko Points
      </PageTitle>

      <div className='card mb-5 mb-xl-10'>
        {
          isEmpty(fromPreOrder) && <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7">
            <h3 className="card-title align-items-start flex-column my-0">
              <span className="card-label fw-bold font-17">SokoPoints Balance</span>
            </h3>
          </div>
        }
        <div className="card-body border-top py-2">
          {balanceLoader ? (
            <FetchingRecords />
          ) : balance.length === 0 ? (
            <NoRecords />
          ) : (
            <div className="table-responsive">
              <table className="table table-row-bordered table-hover align-middle gs-0 gy-2">
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-100px py-2' style={{width: '25%'}}>
                      Brand
                    </th>
                    <th className='min-w-100px py-2' style={{width: '25%'}}>
                      SokoPoints
                    </th>
                    <th className='min-w-100px py-2' style={{width: '15%'}}>
                      SokoAmount
                    </th>
                    {
                      isEmpty(fromPreOrder) && <th className="min-w-100px py-2 text-end" style={{width: '15%'}}>
                        Transaction
                      </th>
                    }
                  </tr>
                </thead>

                <tbody>
                {balance?.map((i: any) => {
                  return (
                      <tr key={i?.id} id={i.id}>
                        <td className='py-4'>{checkEmpty(i?.brand_name)}</td>
                        <td className='py-4'>
                          {isEmpty(i?.total_sokopoints) || i?.total_sokopoints == 0
                            ? '-'
                            : numberFormat(i?.total_sokopoints).displayFormat}
                        </td>
                        <td className='py-4'>
                          {isEmpty(i?.total_value) || i?.total_value == 0
                            ? '-'
                            : numberFormatWithCode(i?.total_value).displayFormat}
                        </td>

                        {
                          isEmpty(fromPreOrder) && <td
                            className="cursor-pointer text-end pe-3"
                            onClick={() => {
                              setBrand({
                                label: i?.brand_name,
                                value: i?.id,
                              })

                              focusVatidateField('sokopoint-transaction')
                            }}
                          >
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                          </td>
                        }
                      </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {
        isEmpty(fromPreOrder) && <div className="card mb-5 mb-xl-10">
          <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7">
            <h3 className="card-title align-items-start flex-column my-0">
              <span className="card-label fw-bold font-17">SokoPoints Transactions</span>
            </h3>
            <AsyncPaginate
              loadOptions={fetchBrands}
              isDisabled={transactionLoader}
              isSearchable
              className="react-select-container my-1 me-1 w-150px"
              classNamePrefix="react-select"
              placeholder="Select"
              value={brand}
              onChange={(e: any) => setBrand(e)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
          <div className="card-body border-top py-2" id="sokopoint-transaction">
            {transactionLoader ? (
              <FetchingRecords />
            ) : transaction.length === 0 ? (
              <NoRecords />
            ) : (
              <div className="table-responsive">
                <table className="table table-row-bordered table-hover align-middle gs-0 gy-2">
                  <thead>
                  <tr className=' text-muted fw-500'>
                    <th className='min-w-100px py-2'>Created Date</th>
                    <th className='min-w-100px py-2'>Order Id</th>
                    <th className='min-w-100px py-2'>Total Payable</th>
                    <th className='min-w-100px py-2'>Total Received</th>
                    <th className='min-w-100px py-2'>SokoPoints</th>
                    <th className='min-w-100px py-2'>SokoAmount</th>
                    <th className='min-w-100px py-2'>Type</th>
                    <th className='min-w-100px py-2'>Expiry Date</th>
                  </tr>
                  </thead>

                  <tbody>
                  {transaction?.map((i: any) => {
                    return (
                      <tr key={i?.id}>
                        <td className='py-4'>
                          {isEmpty(i?.created_at) ? (
                            '-'
                          ) : (
                            <>{dateFormat(i?.created_at).displayDate}</>
                          )}
                        </td>

                        <td className='py-4'>{isEmpty(i?.order_id) ? '-' : `#${i?.order_id}`}</td>

                        <td className='py-4'>
                          {isEmpty(i?.total_amount) || i?.total_amount == 0
                            ? '-'
                            : numberFormatWithCode(i?.total_amount).displayFormat}
                        </td>

                        <td className='py-4'>
                          {isEmpty(i?.received_amount) || i?.received_amount == 0
                            ? '-'
                            : numberFormatWithCode(i?.received_amount).displayFormat}
                        </td>

                        <td className='py-4'>
                          {isEmpty(i?.sokopoints) || i?.sokopoints == 0
                            ? '-'
                            : numberFormat(i?.sokopoints).displayFormat}
                        </td>

                        <td className='py-4'>
                          {isEmpty(i?.soko_amount) || i?.soko_amount == 0
                            ? '-'
                            : numberFormat(i?.soko_amount).displayFormat}
                        </td>

                        <td className='py-4'>
                          {isEmpty(i?.transaction_category) ? (
                            '-'
                          ) : (
                            <>
                              {i?.transaction_category === 'spend' ? 'Spend' : ''}
                              {i?.transaction_category === 'earned' ? 'Earned' : ''}
                              {i?.transaction_category === 'expired' ? 'Expired' : ''}
                            </>
                          )}
                        </td>
                        <td className='py-4'>
                          {isEmpty(i?.expiry_date) ? (
                            '-'
                          ) : (
                            <>{dateFormat(i?.expiry_date).displayDate}</>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </div>
            )}

            <CustomPaginate
              data={transaction}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffSet}
              totalRecords={count}
              limit={limit}
              isDisabled={transactionLoader}
            />
          </div>
        </div>
      }
    </>
  )
}

export default SokoPoints
