import {apiBaseURL} from '../../helpers'
import {getW_Id} from '../../localStorage/getItem/getLocalStorage'

export const brandGetUrl = `${apiBaseURL()}master/product/brand`
export const productGetUrl = `${apiBaseURL()}master/product/view`

export const discountBrand = `${apiBaseURL()}master/product/discount_brand/view?warehouse_id=${localStorage.getItem(
  'w_id'
)}`
export const discountProduct = `${apiBaseURL()}master/product/discount_product_view?warehouse_id=${localStorage.getItem(
  'w_id'
)}`
