import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_eaFruitsDms/helpers'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import { AsyncPaginate } from 'react-select-async-paginate'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'

const fetchWarehouse = async (search?: any) => {
    let options: any = [{ label: 'Option 1', value: 'option1' }, { label: 'Option 3', value: 'option3' }]
    let hasMore: boolean = false
    options.push({
        label: "All Warehouses",
        value: ''
    })
    return {
        options: options,
        hasMore: hasMore
    }
}
type Props = {
    className: string
}
const SalesOrdersFeedbackReport: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Sales Orders Feedback"
    }
    return (
        <>

            <EATitle title='Sales Orders Feedback - ' />
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Sales Orders Feedback' })}</PageTitle>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2022',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar my-1 me-3 w-auto min-w-215px" />
                                </DateRangePicker>

                                <button type="button" className="btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">
                                    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                    Export</button>
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-120px py-2'>Date</th>
                                        <th className='min-w-120px py-2'>Order ID</th>
                                        <th className='min-w-150px py-2'>Customer Name</th>
                                        <th className='min-w-150px'>Sales Reps. Name</th>
                                        <th className='min-w-140px'>Rating</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr
                                        data-bs-toggle='modal'
                                        data-bs-target='#ea_modal_view_salesOrderFeedback'
                                        className='cursor-pointer'
                                    >
                                        <td>29 Mar 2022</td>
                                        <td>#10242</td>
                                        <td>Darika Samak</td>
                                        <td>Wesley Blake</td>
                                        <td>
                                            <KTSVG path="/media/icons/duotune/general/gen029.svg" className="ic text-warning me-1" />
                                            4.2
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>29 Mar 2022</td>
                                        <td>#10242</td>
                                        <td>Darika Samak</td>
                                        <td>Wesley Blake</td>
                                        <td>
                                            <KTSVG path="/media/icons/duotune/general/gen029.svg" className="ic text-warning me-1" />
                                            4.2
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>29 Mar 2022</td>
                                        <td>#10242</td>
                                        <td>Darika Samak</td>
                                        <td>Wesley Blake</td>
                                        <td>
                                            <KTSVG path="/media/icons/duotune/general/gen029.svg" className="ic text-warning me-1" />
                                            4.2
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>29 Mar 2022</td>
                                        <td>#10242</td>
                                        <td>Darika Samak</td>
                                        <td>Wesley Blake</td>
                                        <td>
                                            <KTSVG path="/media/icons/duotune/general/gen029.svg" className="ic text-warning me-1" />
                                            4.2
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>29 Mar 2022</td>
                                        <td>#10242</td>
                                        <td>Darika Samak</td>
                                        <td>Wesley Blake</td>
                                        <td>
                                            <KTSVG path="/media/icons/duotune/general/gen029.svg" className="ic text-warning me-1" />
                                            4.2
                                        </td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>

                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            Showing 1 to 10 of 50 entries
                        </div>
                        <div className="col">
                            <CustomPagination />
                        </div>
                    </div>
                </div>
            </div>

            <EaModal id='ea_modal_view_salesOrderFeedback' modalTitle='#124234 Order Feedback' >
                <div className="mb-4">
                    <label className="form-label text-muted">Review Added By</label>
                    <div>John Doe (Tele Sales)</div>
                </div>
                <div className="mb-4">
                    <label className="form-label text-muted">Order Review</label>
                    <div><KTSVG path="/media/icons/duotune/general/gen029.svg" className="ic text-warning me-1" />
                        4.2</div>
                </div>
                <div className="mb-4">
                    <label className="form-label text-muted">Feedback</label>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempor pellentesque velit ut semper. Fusce justo turpis, tincidunt sit amet lobortis id, ultrices ut sem. Aenean eget tempus erat, eget viverra leo. Nunc augue velit, venenatis ultrices laoreet non, suscipit eu urna. Nunc efficitur mattis lacinia.</div>
                </div>
            </EaModal>
        </>
    )
}

export default SalesOrdersFeedbackReport