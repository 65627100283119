import {applyMiddleware, combineReducers, createStore} from 'redux'
import thunk from 'redux-thunk'
import {ForgotReducer} from '../reducer/forgotPassword/forgotPasswordReducer'
import {LoginReducer} from '../reducer/login/loginReducer'
import {manageProfile} from '../reducer/profile/manageProfile'
import {ResetPasswordReducer} from '../reducer/resetPassword/resetPasswordReducer'
import {TeleSalesUserReducer} from '../reducer/teleSalesUser/teleSalesUserReducer'
import {warehouseReducer} from '../reducer/warehouse/warehouseReducer'
import {SaDashboardFilters} from '../reducer/saDashboardFilters/saDashboardFilters'
import {SummaryFilters} from '../reducer/summaryFilters/summaryFilters'
import {SupportRequestReportFilter} from '../reducer/supportRequestReportFilter/supportRequestReportFilter'

const rootReducers = combineReducers({
  login: LoginReducer,
  forgot: ForgotReducer,
  reset: ResetPasswordReducer,
  warehouse: warehouseReducer,
  teleSalesUserReducer: TeleSalesUserReducer,
  profile: manageProfile,
  saDashboard: SaDashboardFilters,
  summary: SummaryFilters,
  supportRequestReport: SupportRequestReportFilter,
})

export const EafruitsStore = createStore(rootReducers, applyMiddleware(thunk))
