import React, {useEffect, useState} from 'react'
import {getRequest, postRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {apiBaseURL, focusVatidateField, goToTop, isEmpty} from '../../../../../_eaFruitsDms/helpers'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import clsx from 'clsx'
import {EaAlertSuccess} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FormatMessage from '../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'

const OdooWarehouse = (props: any) => {
  const [warehouses, setWarehouses] = useState<any>([])
  const [fetchingRecords, setFetchingRecords] = useState<boolean>(false)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isSubmit, setIsSubmit] = useState('Submit')

  useEffect(() => {
    fetchingDetails().then()
  }, [])

  const fetchingDetails = async () => {
    setFetchingRecords(true)
    const response = await getRequest(`${apiBaseURL()}odoo-mapping/warehouse`, true)

    if (!isEmpty(response) && !isEmpty(response.result)) {
      setWarehouses(response.result)
    }

    setFetchingRecords(false)
  }

  const handleChange = (e: any, index: number) => {
    const inputValue = e.target.value
    const inputName = e.target.name
    setWarehouses((prevWarehouses: any) => {
      const updatedWarehouseDetail = [...prevWarehouses]
      updatedWarehouseDetail[index] = {
        ...updatedWarehouseDetail[index],
        [inputName]: inputValue,
        [`${inputName}_valid`]: !isEmpty(inputValue) ? '' : 'is-invalid',
        unique_validation: '',
      }
      return updatedWarehouseDetail
    })
  }

  const submitWarehouseInfo = async () => {
    let isValid: boolean = true

    for (let i = 0; i < warehouses.length; i++) {
      const warehouse = warehouses[i]
      if (isEmpty(warehouse.odoo_warehouse_name)) {
        isValid = false
        warehouses[i].odoo_warehouse_name_valid = 'is-invalid'
        focusVatidateField(i)
      }
      if (isEmpty(warehouse.odoo_warehouse_id)) {
        isValid = false
        warehouses[i].odoo_warehouse_id_valid = 'is-invalid'
        focusVatidateField(i)
      }
    }

    if (isValid) {
      setIsSubmit('Please Wait')
      let url = apiBaseURL() + 'odoo-mapping/warehouse/edit'
      let warehousePayload: any = []

      warehouses.forEach((item: any) => {
        warehousePayload.push({
          warehouse: item.id,
          odoo_warehouse_name: item.odoo_warehouse_name,
          odoo_warehouse_id: item.odoo_warehouse_id,
        })
      })

      const response = await postRequest(url, {mapping_warehouse: warehousePayload}, true)
      if (response) {
        setIsAlertSuccess(true)
        setIsSubmit('Submit')
        goToTop()
        setTimeout(() => {
          setIsAlertSuccess(false)
        }, 2000)
      }
    } else {
      setWarehouses([...warehouses])
    }
  }

  return (
    <>
      <EATitle title='DMS to Odoo Mapping Config - ' />
      <EaAlertSuccess
        onClick={() => setIsAlertSuccess(false)}
        show={IsAlertSuccess}
        message={FormatMessage('Warehouse configuration updated successfully.')}
      />
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body py-5'>
          {fetchingRecords ? (
            <FetchingRecords />
          ) : warehouses.length === 0 ? (
            <NoRecords />
          ) : (
            <>
              <div className='row mb-5 align-items-center'>
                <div className='col-md-7 col-lg-3'>
                  <b className='font-14 text-muted'>Warehouse Name in DMS</b>
                </div>
                <div className='col-md-3 col-lg-4'>
                  <b className='font-14 text-muted'>Warehouse ID in Odoo</b>
                </div>
                <div className='col-md-3 col-lg-4'>
                  <b className='font-14 text-muted'>Warehouse Name in Odoo</b>
                </div>
              </div>
              <hr />
              {warehouses.map((warehouse: any, index: number) => {
                return (
                  <div className='row mb-6 ' id={index.toString()}>
                    <div className='col-md-7 col-lg-3'>
                      <label className='font-14 text-muted'>
                        {warehouse.name}
                        <span className='text-danger'>*</span>
                      </label>
                    </div>
                    <div className='col-md-3 col-lg-4'>
                      <input
                        type='text'
                        className={clsx('form-control', warehouse.odoo_warehouse_id_valid)}
                        name='odoo_warehouse_id'
                        onChange={(e: any) => {
                          const re = /^[0-9\b]+$/
                          if (e.target.value === '' || re.test(e.target.value)) {
                            if (e.target.value !== '0') {
                              handleChange(e, index)
                            }
                          }
                        }}
                        placeholder='Type here..'
                        value={
                          isEmpty(warehouse.odoo_warehouse_id) ? '' : warehouse.odoo_warehouse_id
                        }
                      />
                      {!isEmpty(warehouse.unique_validation) && (
                        <span className='text-danger fw-bolder'>{warehouse.unique_validation}</span>
                      )}
                    </div>
                    <div className='col-md-3 col-lg-4'>
                      <input
                        type='text'
                        className={clsx('form-control', warehouse.odoo_warehouse_name_valid)}
                        name='odoo_warehouse_name'
                        onChange={(e: any) => handleChange(e, index)}
                        placeholder='Type here..'
                        value={
                          isEmpty(warehouse.odoo_warehouse_name)
                            ? ''
                            : warehouse.odoo_warehouse_name
                        }
                      />
                      {!isEmpty(warehouse.unique_validation) && (
                        <span className='text-danger fw-bolder'>{warehouse.unique_validation}</span>
                      )}
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
        {warehouses.length > 0 && !fetchingRecords && (
          <div className='card-footer'>
            <button
              disabled={isSubmit !== 'Submit' ? true : false}
              className='btn btn-primary'
              onClick={submitWarehouseInfo}
            >
              {isSubmit}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default OdooWarehouse
