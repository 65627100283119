import {useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {
  apiBaseURL,
  dateFormat,
  getDistanceFromLatLonInKm,
  googleApi,
  isEmpty,
} from '../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import Card from './card'
import RouteWiseCustomerMap from './routeWiseCustomerMap'
import './VerticalAlignment.css'
import moment from 'moment'

const DropPointSection = ({setOrderId, type, srId}: any) => {
  const location: any = useLocation()
  const containerRef: any = useRef(null)

  const initialMeta = {
    start_latitude: null,
    start_longitude: null,
  }

  const [dropPoints, setDropPoints] = useState<any>([])
  const [dropPointsMeta, setDropPointsMeta] = useState<any>(initialMeta)
  const [loadingDropPoints, setLoadingDropPoints] = useState(true)
  const [customerLocation, setCustomerLocation] = useState<any>([])
  const [vehicleLocation, setVehicleLocation] = useState<any>(null)
  const [hideVehicleLocation, setHideVehicleLocation] = useState(false)
  const [highLightCard, setHighLightCard] = useState<any>(null)
  const [defaultCenter, setDefaultCenter] = useState<any>(null)

  const fetchRecords = async () => {
    setLoadingDropPoints(true)

    const routeId = location?.state?.itemId || ''

    const url = `${apiBaseURL()}routes/${routeId}/customers-route-history/list?pre_order=true&credit=true&customer=true&ordering=${type}&date=${
      location?.state?.routeDate
    }`

    const response = await getRequest(url, true)

    if (response?.results?.length > 0) {
      const systemGeneratedData: any = []
      const customerLocation: any = []

      response?.results?.map((item: any) => {
        systemGeneratedData.push(item)
        customerLocation.push({
          latitude: Number(item?.latitude) ?? 0,
          longitude: Number(item?.longitude) ?? 0,
          manual_seq:
            type === 'system_generated_seq'
              ? isEmpty(item?.system_generated_seq)
                ? '-'
                : item?.system_generated_seq?.toString()
              : isEmpty(item?.system_generated_seq)
              ? '-'
              : item?.manual_seq?.toString(),
          status: isEmpty(item?.customer_visit_status) ? '' : item?.customer_visit_status,
        })
      })

      setCustomerLocation(customerLocation)
      setDropPoints(systemGeneratedData)
    } else {
      setCustomerLocation([])
      setDropPoints([])
    }

    if (response?.meta) {
      setDropPointsMeta(response?.meta)
      setDefaultCenter({
        lat: parseFloat(response?.meta?.start_latitude),
        lng: parseFloat(response?.meta?.start_longitude),
      })
    } else {
      setDropPointsMeta(initialMeta)
    }

    setLoadingDropPoints(false)
  }

  const fetchUserLocation = async () => {
    if (!isEmpty(srId)) {
      const url = `${apiBaseURL()}routes/user-location/${srId}`

      const response = await getRequest(url, true)

      if (response) {
        const location = {
          latitude: isEmpty(response?.results?.latitude) ? '' : response?.results?.latitude,
          longitude: isEmpty(response?.results?.longitude) ? '' : response?.results?.longitude,
        }

        setVehicleLocation(location)
        setDefaultCenter({
          lat: parseFloat(location?.latitude),
          lng: parseFloat(location?.longitude),
        })
      }
    }
  }

  useEffect(() => {
    if (!isEmpty(location?.state?.itemId)) {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0
      }

      fetchRecords()
    }
  }, [location, type])

  useEffect(() => {
    let interval: any

    if (!isEmpty(location?.state?.itemId) && !isEmpty(location?.state?.routeDate)) {
      let date = dateFormat(location?.state?.routeDate).apiDate
      let toDay = dateFormat(moment()).apiDate

      if (date === toDay) {
        // Call fetchUserLocation initially
        fetchUserLocation()

        // Set up an interval to call fetchUserLocation every 10 seconds
        interval = setInterval(() => {
          fetchUserLocation()
        }, 10000)
      } else {
        setHideVehicleLocation(true)
      }
    }

    // Cleanup the interval on component unmount or dependency change
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [location, srId])

  return (
    <>
      <div className='row pe-5'>
        <>
          {loadingDropPoints ? (
            <div className='col-md-6 mb-5 p-5 h-700px'>
              <FetchingRecords />
            </div>
          ) : dropPoints?.length === 0 ? (
            <div className='col-md-6 mb-5 p-5 h-700px'>
              {' '}
              <NoRecords />{' '}
            </div>
          ) : (
            <div className='col-md-6 mb-5 p-5 h-700px overflow-auto' ref={containerRef}>
              {dropPoints.map((data: any, index: any) => {
                let kms = 0 || ''

                const startLat =
                  index === 0 ? dropPointsMeta.start_latitude : dropPoints[index - 1].latitude
                const startLong =
                  index === 0 ? dropPointsMeta.start_longitude : dropPoints[index - 1].longitude

                const currentLat = data?.latitude
                const currentLong = data?.longitude

                kms = getDistanceFromLatLonInKm(startLat, startLong, currentLat, currentLong)

                return (
                  <Card
                    highLightCard={highLightCard}
                    setOrderId={setOrderId}
                    key={data?.id}
                    data={data}
                    kms={kms}
                    type={type}
                  />
                )
              })}
            </div>
          )}
        </>

        <div className='col-md-6 mb-5 p-2'>
          {!loadingDropPoints &&
            !isEmpty(dropPointsMeta?.start_latitude) &&
            !isEmpty(defaultCenter) && (
              <RouteWiseCustomerMap
                googleMapURL={googleApi}
                loadingElement={<div style={{height: `100%`}} />}
                containerElement={<div style={{height: `100%`}} />}
                mapElement={<div style={{height: `100%`}} />}
                customerLocation={customerLocation.length === 0 ? [] : customerLocation}
                isMarkerShown
                setHighLightCard={setHighLightCard}
                warehouseLocation={{
                  lat: parseFloat(dropPointsMeta?.start_latitude),
                  lng: parseFloat(dropPointsMeta?.start_longitude),
                }}
                vehicleLocation={{
                  lat: isEmpty(vehicleLocation?.latitude)
                    ? parseFloat(dropPointsMeta?.start_latitude)
                    : parseFloat(vehicleLocation?.latitude),

                  lng: isEmpty(vehicleLocation?.longitude)
                    ? parseFloat(dropPointsMeta?.start_longitude)
                    : parseFloat(vehicleLocation?.longitude),
                }}
                hideVehicleLocation={hideVehicleLocation}
                defaultCenter={defaultCenter}
              />
            )}
        </div>
      </div>
    </>
  )
}

export default DropPointSection
