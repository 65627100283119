import {Chart, registerables} from 'chart.js'
import ReactDOM from 'react-dom'
import {QueryClient, QueryClientProvider} from 'react-query'
import {EafruitsI18nProvider} from './_eaFruitsDms/i18n/Eafruits18n'
import AppRoutes from './app/routing/appRoutes'
import tenantConfiguration from './TenantVariables'
import {Provider} from 'react-redux'
import {EafruitsStore} from './_eaFruitsDms/redux/store/store'

require(tenantConfiguration.cssBaseUrl + 'style.scss')
require(tenantConfiguration.cssBaseUrl + 'style.react.scss')

Chart.register(...registerables)
const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <EafruitsI18nProvider>
      <Provider store={EafruitsStore}>
        <AppRoutes />
      </Provider>
    </EafruitsI18nProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)
