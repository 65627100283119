import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const CreditRequestInfo = () => {
  return (
    <div className='me-3'>
      <OverlayTrigger
        delay={{hide: 450, show: 300}}
        overlay={(props) => (
          <Tooltip {...props} className='tooltip-dark'>
            <span>
              When any Pay Later Today' transaction remains unpaid for the day, form next day we
              call it an 'Auto Approved' credit issue!
            </span>
          </Tooltip>
        )}
        placement='left'
      >
        <span className='ms-2 cursor-pointer'>
          <i className='fa fa-info-circle' aria-hidden='true'></i>
        </span>
      </OverlayTrigger>
    </div>
  )
}

export default CreditRequestInfo
