import clsx from "clsx"
import { useState } from "react"
import { HexColorPicker } from "react-colorful"

const CustomColorPicker = ({ colorHex, setColorHex, validationFired, categoryValidation, setCategoryValidation}: any) => {
	const [openPicker, setOpenPicker] = useState(false)
	const [timer, setTimer] = useState<any>(null)

	return <>
		<input type="text" className={clsx(`form-control`, validationFired)} placeholder={ "Choose" +
			" color..." } name="color_hex" value={ colorHex }
			   onClick={ () => setOpenPicker(true) }/>
		{
			openPicker && <>
                <HexColorPicker color={ colorHex && colorHex } onChange={ (e) => {
					setColorHex(e)
					setCategoryValidation({...categoryValidation, 'color_hex': ''})
					clearTimeout(timer)
					const newTimer = setTimeout(() => {
						setOpenPicker(false)
					}, 1000)
					setTimer(newTimer)
				} }
                />
            </>
		}

	</>
}

export default CustomColorPicker
