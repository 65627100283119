import React from "react"
import { toAbsoluteUrl } from "../../../helpers"
import { OverlayTrigger } from "react-bootstrap"
import Tooltip from "react-bootstrap/Tooltip";

interface buttonProps {
    onClick?: any,
    isDisable?: boolean
}

const ResetButton: React.FC<buttonProps> = ({ onClick, isDisable }) => {
  
    const refreshSrc = toAbsoluteUrl('/media/icons/ic_referesh.png')
    const refreshDisableSrc = toAbsoluteUrl('/media/icons/ic_referesh_disable.png')
   
    return (
        <>
          {isDisable ? <>
                <img
                    src={refreshSrc}
                    onClick={onClick}
                    className='cursor-pointer'/>
              </> : 
              <OverlayTrigger
              delay={{ hide: 300, show: 300 }}
                overlay={(props) => (
                  <Tooltip
                      {...props}
                          className='tooltip-dark long-tooltip'>
                             No filter Applied
                  </Tooltip>)}
                      placement='top'>
                    <img
                        src={refreshDisableSrc} 
                        className='rounded text-dark'>
                    </img>
              </OverlayTrigger>
              }
        </>
    )
}

export default ResetButton