import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Route, Routes} from 'react-router-dom'
import {apiBaseURL, checkUserType, verificationPending} from '../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import BusinessDetails from './components/businessDetails'
import CustomerNameHeader from './components/customerNameHeader'
import Overview from './components/overview'
import BusinessAddress from './verificationRequest/components/businessAddress'
import SalesOrder from './components/salesOrder'
import CreditIssues from '../../creditIssues/creditIssues'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import SupportRequestNewMessage from '../../supportRequest/newMessage'
import WMHorecaContract from './horecaContract/horecaContract'
import Complaints from '../../complaints/complaints'
import CallNotes from '../../callNotes/callNotes'
import Preferences from './components/preferences'
import SokoPoints from './components/sokoPoints'

const WMSingleCustomer: React.FC = () => {
  const intl = useIntl()
  const customerType = localStorage.getItem('customerPermission')

  const customersHeaderTitle = {
    title: 'Customers',
    subTitle: 'Customer Name',
  }

  const customerId = localStorage.getItem('cId')

  const [customer, setCustomer] = useState<any>(null)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [navigateOption, setNavigateOption] = useState<any>('')
  const [warehouse, setWarehouse] = useState<any>({label: '', value: ''})
  const [selectedCustomer, seSelectedCustomer] = useState<any>({label: '', value: ''})
  const [streetName, seStreetName] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [orderCustomer, setOrderCustomer] = useState<any>({})

  useEffect(() => {
    fetchCustomerDetails().then()
  }, [])

  useEffect(() => {
    if (customerType == 'suspendedCustomer') {
      setNavigateOption('/customers/suspended-customers')
    } else {
      setNavigateOption('/customers')
    }
  }, [])

  const fetchCustomerDetails = async () => {
    setDisplayLoader(true)
    const customerResponse = await getRequest(`${apiBaseURL()}customers/${customerId}`, true)
    setDisplayLoader(false)

    if (customerResponse) {
      const verificationStatus = verificationPending(customerResponse)
      customerResponse.pending = verificationStatus.pending
      customerResponse.verification_type = verificationStatus.type

      setStatus(customerResponse?.status ?? '')

      seStreetName(customerResponse?.street ?? '')

      if (customerResponse.id) {
        setOrderCustomer({
          id: customerResponse?.id ?? '',
          business_name: customerResponse?.business_name ?? null,
          logo_thumbnail: customerResponse?.logo_thumbnail ?? null,
          street: customerResponse?.street ?? null,
          contact_no: customerResponse?.contact_no ?? null,
          warehouse_id: customerResponse?.warehouse_id ?? null,
          warehouse_name: customerResponse?.warehouse_name ?? null,
          category_having_contract: customerResponse?.category_having_contract ?? false,
          is_active_contract: customerResponse?.is_active_contract ?? false,
          active_contract_end_date: customerResponse?.active_contract_end_date ?? false,
        })
      }

      setWarehouse({
        label: customerResponse?.warehouse_name ?? '',
        value: customerResponse?.warehouse_id ?? '',
      })
      seSelectedCustomer({
        value: customerResponse?.id ?? '',
        label: customerResponse?.business_name ?? '',
      })
      setCustomer(customerResponse)
    }
  }

  return (
    <>
      <PageTitle backNavigate={navigateOption} breadcrumbs={[customersHeaderTitle]}>
        {customer && customer.business_name}
      </PageTitle>
      {displayLoader && <IsLoader />}
      <CustomerNameHeader customer={customer} />
      <Routes>
        <Route
          path='overview'
          element={<Overview customer={customer} fetchCustomerDetails={fetchCustomerDetails} />}
        />
        <Route path='business-details' element={<BusinessDetails />} />
        <Route path='business-address' element={<BusinessAddress customer={customer} />} />
        <Route
          path='sales-order'
          element={
            <SalesOrder
              showAddOrderButton={status === 'approved' && checkUserType(['ts'])}
              warehouse={{id: warehouse.value, name: warehouse.label}}
              selectedCustomer={{
                label: `${selectedCustomer.label}${streetName !== '' ? `, ${streetName}` : ''}`,
                value: selectedCustomer.value,
              }}
              orderCustomer={orderCustomer}
            />
          }
        />
        {/*<Route path='return' element={<Return />} />*/}
        <Route path='credit-issue' element={<CreditIssues />} />
        <Route
          path='complaints-request'
          element={
            <Complaints
              warehouse={warehouse}
              selectedCustomer={selectedCustomer}
              isViewOnly={true}
              customerStatus={status}
            />
          }
        />
        <Route path='horeca-contracts' element={<WMHorecaContract customer={customer} />} />
        <Route path='preferences' element={<Preferences customer={customer} />} />
        <Route path='soko-point' element={<SokoPoints />} />
        {/*<Route path='complaint' element={<SupportRequest />} />*/}
        {/*<Route path='survey-feedback' element={<SurveyFeedback />} />*/}
        <Route
          path='call-notes'
          element={
            <CallNotes
              customerId={customerId}
              warehouse={warehouse}
              selectedCustomer={selectedCustomer}
              customerStatus={status}
            />
          }
        />
        <Route index element={<Navigate to='overview' />} />
      </Routes>
    </>
  )
}

export default WMSingleCustomer
