import Pagination from "@mui/material/Pagination";
import React, { useEffect } from "react";
import ShowingCount from "./showingCount";
import { saveFilter } from "../../../helpers";

const CustomPaginate = ({
  data,
  selected,
  pageState,
  setPageState,
  setSelected,
  setCurrentOffset,
  totalRecords,
  limit,
  isDisable,
  saveFilterName,
  filterStates
}: any) => {
  const limitPerPage = (limit) ? limit : 25;
  let count: any = Math.ceil(totalRecords / limitPerPage);
  
  useEffect(() =>{
    saveFilter(saveFilterName , filterStates)
  },[filterStates])

  const handleChange = (e: any, p: any) => {
    if (pageState === undefined) {
      setSelected(p - 1);
      setCurrentOffset((p - 1) * limitPerPage);
    } else {
      setPageState((preValue: any) => ({
        ...preValue, selected: (p - 1), currentOffset: (p - 1) * limitPerPage
      }));
    }
  };

  return <>
    <div className="table-info py-5">
      <div className="row g-0">
        <div className="col">
          <ShowingCount data={data} selected={selected} count={totalRecords} />
        </div>
        <div className="col pagination justify-content-md-end">
          <Pagination className="test" count={count} color="primary" onChange={handleChange} page={selected + 1} disabled={isDisable} />
        </div>
      </div>
    </div>

  </>;
};

export default CustomPaginate;
