import React from 'react'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_eaFruitsDms/helpers'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import { EaStatisticsWidget } from '../../../../_eaFruitsDms/partials/widgets'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import Select from 'react-select'
import { Helmet } from 'react-helmet'

const categoryoptions = [
    {
        label: "Grains",
        options: [
            { label: "Rices", value: "rices" },
            { label: "Beans", value: "beans" }
        ]
    },
    {
        label: "Fruits",
        options: [
            { label: "Fresh Produce", value: "Fruits" },
            { label: "Fresh Produce 2", value: "Fruits" }
        ]
    },
];

const allbrandoption = [
    { value: 'all', label: 'All Brands' },
    { value: 'other', label: 'Other' },
]


type Props = {
    className: string
}
const BrandwiseSalesReport: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Brandwise Sales Report"
    }
    return (
        <>

            <Helmet>
                <title>Brandwise Sales Report - EA Fruits DMS</title>
            </Helmet>
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Brandwise Sales Report' })}</PageTitle>
            <div className={`card ${className} mb-5`}>
                {/* begin::Header */}
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                    {/* begin::Title */}
                    <div>
                        <h3 className='card-title align-items-start flex-column my-0'>
                            <span className='card-label fw-bold font-17'>Brands Overview</span>
                        </h3>
                        <p className='font-13 text-muted mb-2'>Based on Sales Amount</p>
                    </div>
                    {/* end::Title */}
                    {/* begin::Toolbar */}
                    <div className='card-toolbar d-flex g-3 my-0'>
                        <DateRangePicker
                            initialSettings={
                                {
                                    startDate: '01/01/2020',
                                    endDate: '01/12/2020',
                                    alwaysShowCalendars: true,
                                    cancelClass: 'btn-secondary',
                                    drops: "auto",
                                    locale: {
                                        format: 'DD/MM/YYYY'
                                    },
                                    ranges: {
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    },
                                }}
                        >
                            <input type="text" className="form-control calendar my-1 w-auto min-w-215px" />
                        </DateRangePicker>
                    </div>
                    {/* end::Toolbar */}
                </div>
                <div className="card-body">
                    Chart Area
                </div>
            </div>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: '01/01/2020',
                                            endDate: '01/12/2020',
                                            alwaysShowCalendars: true,
                                            cancelClass: 'btn-secondary',
                                            drops: "auto",
                                            locale: {
                                                format: 'DD/MM/YYYY'
                                            },
                                            ranges: {
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                        }}
                                >
                                    <input type="text" className="form-control calendar my-1 me-3 w-auto min-w-215px" />
                                </DateRangePicker>
                                <Select
                                    options={categoryoptions}
                                    isSearchable
                                    placeholder="All Categories"
                                    className='react-select-container my-1 me-3 w-140px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <Select
                                    options={allbrandoption}
                                    isSearchable
                                    placeholder="All Brands"
                                    className='react-select-container my-1 w-115px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-150px'>Brand Name</th>
                                        <th className='min-w-140px'>Product Category</th>
                                        <th className='min-w-120px'>Product Name</th>
                                        <th className='min-w-120px'>Sales Amount</th>
                                        <th className='min-w-120px'>Tax Collected</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <td>Onja</td>
                                        <td>Grains</td>
                                        <td>Onja Rice 1kg</td>
                                        <td>TZS 52764</td>
                                        <td>TZS 419</td>
                                    </tr>
                                    <tr>
                                        <td>Onja</td>
                                        <td>Grains</td>
                                        <td>Onja Rice 1kg</td>
                                        <td>TZS 52764</td>
                                        <td>TZS 419</td>
                                    </tr>
                                    <tr>
                                        <td>Onja</td>
                                        <td>Grains</td>
                                        <td>Onja Rice 1kg</td>
                                        <td>TZS 52764</td>
                                        <td>TZS 419</td>
                                    </tr>
                                    <tr>
                                        <td>Onja</td>
                                        <td>Grains</td>
                                        <td>Onja Rice 1kg</td>
                                        <td>TZS 52764</td>
                                        <td>TZS 419</td>
                                    </tr>
                                    <tr>
                                        <td>Onja</td>
                                        <td>Grains</td>
                                        <td>Onja Rice 1kg</td>
                                        <td>TZS 52764</td>
                                        <td>TZS 419</td>
                                    </tr>
                                </tbody>
                                <tfoot className='border-bottom'>
                                    <tr className='bg-light2 font-medium border-top border-bottom' >
                                        <td colSpan={3} className='text-center'>Total Sales Amount</td>
                                        <td>TZS 22355</td>
                                        <td>TZS 82152</td>
                                    </tr>
                                </tfoot>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            Showing 1 to 10 of 50 entries
                        </div>
                        <div className="col">
                            <CustomPagination />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Addwarehouse /> */}
        </>
    )
}

export default BrandwiseSalesReport
