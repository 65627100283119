import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkRoleName,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  isEmpty,
  KTSVG,
  measurementLabel,
  numberFormat,
  numberFormatWithCode,
  staticSearch,
  toAbsoluteUrl,
} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import tenantConfiguration from '../../../TenantVariables'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import {EaChartPie} from '../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import moment from 'moment'
import {connect} from 'react-redux'
import {operationsManager} from '../../../_eaFruitsDms/utils/roleNames'

const OODashboard = (props: any) => {
  const {wId, month} = props
  let warehouseId: any = localStorage.getItem('w_id')
  const navigate = useNavigate()

  const [stockDemand, setStockDemand] = useState<any>([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [brandOffset, setBrandOffset] = useState<any>('')
  const [brandOption, setBrandOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [brand, srtBrand] = useState('')
  const [selectedDay, setSelectedDay] = useState('today_date')
  const [returnType, setReturnType] = useState('sales')
  const [stockType, setStockType] = useState('')
  const [lowStock, setLowStock] = useState(false)
  const [nearExpiry, SetNearExpiry] = useState('')
  const [productSku, setProductSku] = useState('')
  const [lowStockSku, setLowStockSku] = useState('')
  const [outOfSku, setOutOfSku] = useState('')
  const [loadOutSku, setLoadOutSku] = useState('')
  const [brandId, setBrandId] = useState('')
  const [productGroupId, setProductGroupId] = useState('')
  const [productGroupLoading, setProductGroupLoading] = useState(false)
  const [productGroupsChart, setProductGroupsChart] = useState({
    data: [],
    title: '',
    bottom: '5%',
    selectedMode: true,
  })
  const [brandPieChart, setBrandPieChart] = useState({
    data: [],
    title: '',
    bottom: '5%',
    selectedMode: true,
  })
  const [productSkuChart, setProductSkuChart] = useState({
    data: [],
    title: '',
    bottom: '5%',
  })
  const [brandsChartLoading, setBrandsChartLoading] = useState(false)
  const [productSkuLoading, setProductSkuLoading] = useState(false)
  const [fetchingLowStockAlert, setFetchingLowStockAlert] = useState(true)
  const [lowStockAlert, setLowStockAlert] = useState([])
  const [fetchingTopSkus, setFetchingTopSkus] = useState(true)
  const [topProductSkus, setTopProductSkus] = useState([])

  const todayDate = dateFormat(moment()).apiDate
  const monthUrl = `&timeline=${month ? month : 'today'}${
    isEmpty(month) || month === 'today'
      ? `&date_duration_before=${todayDate}&date_duration_after=${todayDate}`
      : ''
  }`
  const filter = `warehouse_id=${isEmpty(wId) ? '' : wId}${monthUrl}`

  useEffect(() => {
    // fetchStockDemand().then()
  }, [currentOffset, brand, selectedDay, lowStock])

  useEffect(() => {
    fetchDashboardInfo().then()
  }, [wId])

  const fetchStockDemand: any = async () => {
    if (!isEmpty(warehouseId)) {
      let STOCK_DEMAND_API = `${apiBaseURL()}inventory/stockdemand/?limit=${limit}&offset=${currentOffset}&ordering=&warehouse_id=${warehouseId}&data=${selectedDay}`

      if (brand !== '') {
        STOCK_DEMAND_API += `&brand_id=${brand}`
      }

      // Show Low Stock Products Only flag
      if (lowStock) {
        STOCK_DEMAND_API += `&low_stock_demand=${lowStock}`
      }

      setDisplayLoader(true)
      const stockResponse = await getRequest(STOCK_DEMAND_API, true)
      setDisplayLoader(false)
      setStockDemand(stockResponse.results)
      setCount(stockResponse.count)
      setNext(stockResponse.next)
      setPrev(stockResponse.previous)
      setDisplayLoader(false)
    }
  }

  const fetchDashboardInfo = async () => {
    let count: any = {
      low_stock: 0,
      product: 0,
      near_expiry: 0,
      open_loadout_request: 0,
      out_of_stock_sku: 0,
    }

    let warehouse = checkUserType(['oo'])
      ? isEmpty(warehouseId)
        ? ''
        : warehouseId
      : isEmpty(wId)
      ? ''
      : wId

    let API = `${apiBaseURL()}inventory/dashboardapi/?warehouse_id=${isEmpty(wId) ? '' : wId}`

    const apiResponse: any = await getRequest(API, true)

    if (!isEmpty(apiResponse.low_stock)) {
      count.low_stock = apiResponse.low_stock
    }
    if (!isEmpty(apiResponse.near_expiry)) {
      count.near_expiry = apiResponse.near_expiry
    }
    if (!isEmpty(apiResponse.product)) {
      count.product = apiResponse.product
    }
    if (!isEmpty(apiResponse.open_loadout_request)) {
      count.open_loadout_request = apiResponse.open_loadout_request
    }
    if (!isEmpty(apiResponse.out_of_stock_sku)) {
      count.out_of_stock_sku = apiResponse.out_of_stock_sku
    }

    setLowStockSku(count.low_stock)
    SetNearExpiry(count.near_expiry)
    setProductSku(count.product)
    setLoadOutSku(count.open_loadout_request)
    setOutOfSku(count.out_of_stock_sku)
  }

  const fetchLowStockAlert = async () => {
    setFetchingLowStockAlert(true)
    const url = `${apiBaseURL()}inventory/oo-dashboard/lowest-stock-product-sku?warehouse_id=${
      isEmpty(wId) ? '' : wId
    }&records=${stockType}`
    const response = await getRequest(url, true)

    if (response && response.results) {
      setLowStockAlert(response.results)
    }

    setFetchingLowStockAlert(false)
  }

  const fetchTopSkus = async () => {
    setFetchingTopSkus(true)
    const url = `${apiBaseURL()}inventory/oo-dashboard/top-product-sku?records=${returnType}&${filter}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      setTopProductSkus(response.result)
    }

    setFetchingTopSkus(false)
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const fetchBrands = async (search?: any) => {
    return await fetchAsyncDropdown(
      brandOffset,
      setBrandOffset,
      brandOption,
      setBrandOption,
      `master/product/brand?ordering=name`,
      search,
      setSearchSelect,
      '',
      true,
      'All Brands'
    )
  }

  const fetchDays: any = (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        label: 'Rest of Today',
        value: 'today_date',
      },
      {
        label: 'Tomorrow',
        value: 'tommorow',
      },
      {
        label: 'Rest of This Week',
        value: 'rest_of_week',
      },
      {
        label: 'Next Week',
        value: 'next_week',
      },
    ]
    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const filterChangeHandler = (e: any, name: string) => {
    setSelected(0)
    setCurrentOffset(0)

    switch (name) {
      case 'brand':
        srtBrand(e.value)
        break
      case 'day':
        setSelectedDay(e.value)
        break
      case 'lowStock':
        setLowStock(e.target.checked)
        break
    }
  }

  const fetchBrandPieChart = async () => {
    setBrandsChartLoading(true)
    setProductGroupLoading(true)
    setProductSkuLoading(true)

    const url = apiBaseURL() + 'reports/sa-dashboard/brands?' + filter
    const response = await getRequest(url, true)
    let brandChart: any = {...brandPieChart}
    let mergeBrandChart: Object[] = []

    if (response && response.result) {
      if (response.result.length === 0) {
        setBrandId('')
        setProductGroupId('')
      }

      if (response.result.length >= 1) {
        setBrandId(response.result[0].brand_id)
      }

      response.result.forEach((item: any, index: number) => {
        mergeBrandChart.push({
          name: item.brand_name,
          value: parseFloat(item.sales_qty_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          brand_id: item.brand_id,
          selected: index === 0 ? true : false,
          total_payable_amount: parseFloat(item.total_payable_amount)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    brandChart.data = mergeBrandChart
    setBrandPieChart(brandChart)
    setBrandsChartLoading(false)
  }

  const fetchProductGroupsPieChart = async () => {
    setProductGroupLoading(true)
    setProductSkuLoading(true)

    const url = apiBaseURL() + `reports/sa-dashboard/brands/${brandId}/product-groups?` + filter
    const response = await getRequest(url, true)
    let chart: any = {...productGroupsChart}
    let mergeChart: Object[] = []

    if (response && response.result) {
      if (response.result.length >= 1) {
        setProductGroupId(response.result[0].product_group_id)
      }

      response.result.forEach((item: any) => {
        mergeChart.push({
          name: item.product_group_name,
          value: parseFloat(item.sales_qty_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          product_group_id: item.product_group_id,
          total_payable_amount: parseFloat(item.total_payable_amount)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    chart.data = mergeChart
    setProductGroupsChart(chart)
    setProductGroupLoading(false)
  }

  const fetchProductSkuPieChart = async () => {
    setProductSkuLoading(true)
    const url =
      apiBaseURL() +
      `reports/sa-dashboard/brands/product-groups/${productGroupId}/product-skus?` +
      filter
    const response = await getRequest(url, true)
    let chart: any = {...productSkuChart}
    let mergeChart: Object[] = []

    if (response && response.result) {
      response.result.forEach((item: any, index: number) => {
        mergeChart.push({
          name: item.product_sku_name,
          value: parseFloat(item.sales_qty_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          total_payable_amount: parseFloat(item.total_payable_amount)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    chart.data = mergeChart
    setProductSkuChart(chart)
    setProductSkuLoading(false)
  }

  const updateSelectedBrands = (brands: any) => {
    const id = brands.data.brand_id
    let chart: any = {...brandPieChart}
    let mergeChart: Object[] = []

    chart.data.forEach((item: any, index: number) => {
      mergeChart.push({
        name: item.name,
        value: item.value,
        brand_id: item.brand_id,
        selected: item.brand_id === id ? true : false,
        total_payable_amount: item.total_payable_amount,
      })
    })

    chart.data = mergeChart
    setBrandPieChart(chart)
  }

  const updateSelectedProductGroups = (brands: any) => {
    const id = brands.data.product_group_id
    let chart: any = {...productGroupsChart}
    let mergeChart: Object[] = []

    chart.data.forEach((item: any, index: number) => {
      mergeChart.push({
        name: item.name,
        value: item.value,
        product_group_id: item.product_group_id,
        selected: item.product_group_id === id ? true : false,
        total_payable_amount: item.total_payable_amount,
      })
    })

    chart.data = mergeChart
    setProductGroupsChart(chart)
  }

  useEffect(() => {
    fetchBrandPieChart()
    fetchLowStockAlert()
  }, [filter])

  useEffect(() => {
    fetchLowStockAlert()
  }, [filter, stockType])

  useEffect(() => {
    fetchTopSkus()
  }, [filter, returnType])

  useEffect(() => {
    if (brandId !== '' && !brandsChartLoading) {
      fetchProductGroupsPieChart()
    }
  }, [brandId, brandsChartLoading])

  useEffect(() => {
    if (productGroupId !== '' && !productGroupLoading) {
      fetchProductSkuPieChart()
    }
  }, [productGroupId, productGroupLoading])

  const toolTip = (item: any) => {
    return `<div>
        <p>
          <b>${item.data.name}</b>
        </p>
        <div>
          <b>${numberFormatWithCode(item.data.total_payable_amount).displayFormat}</b>
        </div>
      </div>`
  }

  return (
    <>
      {/* begin::Row statistics*/}
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <a
            href={checkRoleName([operationsManager]) ? 'javascript:void(0)' : '/current-stock'}
            target={checkRoleName([operationsManager]) ? '_self' : '_blank'}
          >
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='success'
              title={numberFormat(productSku).displayFormat}
              path='/products'
              description='Total SKUs'
              label='Current'
            />
          </a>
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <a
            href={
              checkRoleName([operationsManager]) ? 'javascript:void(0)' : 'reports/low-stock-report'
            }
            target={checkRoleName([operationsManager]) ? '_self' : '_blank'}
          >
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='danger'
              title={numberFormat(lowStockSku).displayFormat}
              description='Low Stock SKUs'
              path='reports/low-stock-report'
              label='Current'
            />
          </a>
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <a
            href={
              checkRoleName([operationsManager]) ? 'javascript:void(0)' : 'reports/low-stock-report'
            }
            target={checkRoleName([operationsManager]) ? '_self' : '_blank'}
          >
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='danger'
              title={numberFormat(outOfSku).displayFormat}
              description='Out of Stock SKUs'
              path='reports/low-stock-report'
              label='Current'
            />
          </a>
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <a
            href={checkRoleName([operationsManager]) ? 'javascript:void(0)' : '/load-out-requests'}
            target={checkRoleName([operationsManager]) ? '_self' : '_blank'}
          >
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='info'
              title={numberFormat(loadOutSku).displayFormat}
              description='Open Load Out Requests'
              path='/load-out-requests'
              label='Current'
            />
          </a>
        </div>
      </div>
      {/* end::Row statistics*/}
      {/* Notification row start */}
      <div className='row'>
        <div className='col-md-6'>
          <div className='bg-light-warning d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>
            <span className='svg-icon-dark'>
              <KTSVG path='/media/icons/duotune/general/gen007.svg' className='ic me-3' />
            </span>
            <div className='text-dark pe-2'>
              You have {lowStockSku} stock alerts at your warehouse
            </div>
            {!checkRoleName([operationsManager]) && (
              <a
                style={{cursor: 'pointer'}}
                onClick={() => navigate('/reports/low-stock-report')}
                className='text-dark text-hover-decoration-underline text-hover-dark ms-auto fw-500'
              >
                View All
              </a>
            )}
          </div>
        </div>
        <div className='col-md-6'>
          <div className='bg-light-warning d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>
            <span className='svg-icon-dark'>
              <KTSVG path='/media/icons/duotune/general/gen007.svg' className='ic me-3' />
            </span>
            <div className='text-dark pe-2'>
              You have {nearExpiry} near expiry stock at your warehouse
            </div>
            {!checkRoleName([operationsManager]) && (
              <a
                style={{cursor: 'pointer'}}
                onClick={() => {
                  navigate('/current-stock')
                  localStorage.setItem('expiry_report', 'true')
                }}
                className='text-dark text-hover-decoration-underline text-hover-dark ms-auto fw-500'
              >
                View All
              </a>
            )}
          </div>
        </div>
      </div>
      {/* Notification row end */}

      {/* stock demand start */}
      {/* <div className='row'>
        <div className='col-lg-12'>
          {
            displayLoader && <IsLoader />
          }
          <div className='card'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <div>
                <h3 className='card-title align-items-start flex-column my-0'>
                  <span className='card-label fw-bold font-17 my-2'>Stock Demand</span>
                </h3>
              </div>
              <div className='card-toolbar d-flex g-3 my-0'>
                <AsyncPaginate
                    
                  loadOptions={fetchBrands}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Brands'
                  onChange={(e) => filterChangeHandler(e, 'brand')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchDays}
                  isSearchable
                  className='react-select-container my-1 w-150px'
                  classNamePrefix='react-select'
                  placeholder='Rest of Today'
                  onChange={(e) => filterChangeHandler(e, 'day')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
            <div className='card-body py-0'>
              {stockDemand.length == 0 ? (
                <NoRecords />
              ) : (
                <>
                  <div className='table-responsive'>
                    <table className='table table-row-bordered align-middle gy-3'>
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-150px py-2'>Product Name</th>
                          <th className='min-w-120px py-2'>Brand Name</th>
                          <th className='min-w-120px py-2 text-center'>Current Stock</th>
                          <th className='min-w-120px py-2 text-center'>Stock Demand</th>
                        </tr>
                      </thead>

                      <tbody>
                        {stockDemand.map((item: any) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='symbol symbol-24px me-2'>
                                      <span className='symbol-label'>
                                        <img
                                          src={
                                            item && item.product_thumbnail
                                              ? item.product_thumbnail
                                              : toAbsoluteUrl('/media/products/1.png')
                                          }
                                          className='h-24px align-self-center'
                                          alt=''
                                        />
                                      </span>
                                    </div>
                                    {item.name && item.name}
                                  </div>
                                </td>
                                <td>{item.brand_id && item.brand_id}</td>
                               
                                <td className='text-center'>
                                  {item.total_qty && item.total_qty}{' '}
                                  {item.material && item.material.name}
                                </td>
                                <td className='text-center'>
                                  <span className=''>
                                    {item.preordar && item.preordar}{' '}
                                    {item.material && item.material.name}
                                  </span>
                                 
                                </td>
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
          <CustomPaginate
            data={stockDemand}
            selected={selected}
            setSelected={setSelected}
            setCurrentOffset={setCurrentOffset}
            totalRecords={count}
            limit={limit}
          />
        </div>
      </div> */}
      {/*  stock demand end*/}

      {/* brands and product overview */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Products Overview</span>
                <span className='text-muted font-14'>
                  Based on Sales amount in {tenantConfiguration.currencyCode}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              {brandsChartLoading ? (
                <FetchingRecords />
              ) : (
                <div className='row'>
                  {brandPieChart.data.length === 0 ? (
                    <NoRecords />
                  ) : (
                    <>
                      <div className='col-md-4'>
                        <EaChartPie
                          item={brandPieChart}
                          tooltip={toolTip}
                          onClick={(item: any) => {
                            setBrandId(item.data.brand_id)
                            updateSelectedBrands(item)
                          }}
                          isAnimationDisable={true}
                        />
                      </div>
                      <div className='col-md-4'>
                        {productGroupLoading ? (
                          <div
                            style={{
                              marginTop: '50%',
                            }}
                          >
                            <FetchingRecords />
                          </div>
                        ) : (
                          <EaChartPie
                            item={productGroupsChart}
                            onClick={(item: any) => {
                              setProductGroupId(item.data.product_group_id)
                              updateSelectedProductGroups(item)
                            }}
                            tooltip={toolTip}
                            isAnimationDisable={true}
                          />
                        )}
                      </div>
                      <div className='col-md-4'>
                        {productSkuLoading ? (
                          <div
                            style={{
                              marginTop: '50%',
                            }}
                          >
                            <FetchingRecords />
                          </div>
                        ) : (
                          <EaChartPie
                            tooltip={toolTip}
                            item={productSkuChart}
                            isAnimationDisable={true}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        {/* Top 5 Low Stock */}
        <div className='col-xl-6'>
          <div className={`card card-xl-stretch mb-5`}>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <div>
                <h3 className='card-title align-items-start flex-column my-0'>
                  <span className='card-label fw-bold font-17'>Low Stock Alert</span>
                </h3>
              </div>
              <div className='m-2'>
                <button
                  onClick={() => {
                    setStockType('')
                  }}
                  className={`btn me-3 ${stockType === '' ? 'btn-primary' : 'btn-secondary'}`}
                >
                  Low Stock
                </button>

                <button
                  onClick={() => {
                    setStockType('out_of_stock')
                  }}
                  className={`btn me-3 ${
                    stockType === 'out_of_stock' ? 'btn-primary' : 'btn-secondary'
                  }`}
                >
                  Out of Stock
                </button>

                {!checkRoleName([operationsManager]) && (
                  <button
                    onClick={() => navigate('/reports/low-stock-report')}
                    className='btn btn-primary'
                  >
                    View All
                  </button>
                )}
              </div>
            </div>
            <div className='card-body py-0'>
              <div className='table-responsive'>
                {fetchingLowStockAlert ? (
                  <FetchingRecords />
                ) : lowStockAlert.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered'>
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='py-2'>Product Name</th>
                        <th className='min-w-70px py-2'>Low Stock at</th>
                        {stockType !== 'out_of_stock' && (
                          <th className='min-w-70px py-2'>Current Stock</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {lowStockAlert.length > 0 &&
                        lowStockAlert.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <p className='font-14 mb-1'>
                                  {isEmpty(item.product_name) ? '-' : item.product_name}
                                </p>
                                <p className='font-13 mb-0  text-muted'>
                                  {isEmpty(item.brand_name) ? '-' : item.brand_name}
                                </p>
                              </td>
                              <td>
                                {isEmpty(item.alert_count)
                                  ? '-'
                                  : numberFormat(item.alert_count).displayFormat}{' '}
                                {measurementLabel(
                                  item.fix_size
                                    ? item.material_name
                                    : item.approx_weight > 0
                                    ? item.material_name
                                    : item.measurement_unit
                                )}
                              </td>
                              {stockType !== 'out_of_stock' && (
                                <td>
                                  {isEmpty(item.in_stock)
                                    ? '-'
                                    : numberFormat(item.in_stock).displayFormat}{' '}
                                  {measurementLabel(
                                    item.fix_size
                                      ? item.material_name
                                      : item.approx_weight > 0
                                      ? item.material_name
                                      : item.measurement_unit
                                  )}
                                </td>
                              )}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Top 5 Product skus */}
        <div className='col-xl-6'>
          <div className={`card card-xl-stretch mb-5`}>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <div>
                <h3 className='card-title align-items-start flex-column my-0'>
                  <span className='card-label fw-bold font-17'>Top 5 Product SKUs</span>
                </h3>
              </div>
              <div className='m-2'>
                <button
                  onClick={() => {
                    setReturnType('sales')
                  }}
                  className={`btn me-3 ${returnType === 'sales' ? 'btn-primary' : 'btn-secondary'}`}
                >
                  Sales
                </button>

                <button
                  onClick={() => {
                    setReturnType('return')
                  }}
                  className={`btn me-3 ${
                    returnType === 'return' ? 'btn-primary' : 'btn-secondary'
                  }`}
                >
                  Return
                </button>
              </div>
            </div>
            <div className='card-body py-0'>
              <div className='table-responsive'>
                {fetchingTopSkus ? (
                  <FetchingRecords />
                ) : topProductSkus.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered'>
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='py-2'>Product Name</th>
                        <th className='min-w-70px py-2'>
                          {returnType === 'return' ? 'Return Count' : 'Return Sales'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topProductSkus.length > 0 &&
                        topProductSkus.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <p className='font-14 mb-1'>
                                  {isEmpty(item.product_name) ? '-' : item.product_name}
                                </p>
                                <p className='font-13 mb-0  text-muted'>
                                  {isEmpty(item.brand_name) ? '-' : item.brand_name}
                                </p>
                              </td>
                              <td>
                                {returnType === 'return'
                                  ? isEmpty(item.total_return_sku)
                                    ? '-'
                                    : numberFormat(item.total_return_sku).displayFormat
                                  : isEmpty(item.return_product_sales_amount)
                                  ? '-'
                                  : numberFormatWithCode(item.return_product_sales_amount)
                                      .displayFormat}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    wId: state.saDashboard.warehouseId,
    month: state.saDashboard.month,
  }
}

export default connect(mapStateToProps, null)(OODashboard)
