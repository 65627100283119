import clsx from "clsx";
import "cropperjs/dist/cropper.css";
import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import Cropper from "react-cropper";
import { toAbsoluteUrl } from "../../../../../_eaFruitsDms/helpers";

const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')

export const Imagecropper = (props: any) => {
  const [image, setImage] = useState(defaultSrc)
  const [cropper, setCropper] = useState<any>()
  const [show, setShow] = useState(false)
  const [isLoadin, setLoading] = useState('Save')
  const [sizeValidation, setSizeValidation] = useState<boolean>(false)

  const handleShow = () => setShow(true)
  const handleHide = () => setShow(false)

  let files: any = ''
  const onChange = (e: any) => {
    setSizeValidation(false)
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
    if (allowedExtensions.exec(e.target.value)) {
      e.preventDefault()

      if (e.dataTransfer) {
        files = e.dataTransfer.files
      } else if (e.target) {
        files = e.target.files
      }

      const fileSize = files[0].size; // in bytes
      const maxSize = 5 * 1024 * 1024; // 10MB

      if (fileSize > maxSize) {
        e.target.value = null; // clear the input field
        setSizeValidation(true)
        return;
      }

      if (!sizeValidation) {
        handleShow()
        const reader = new FileReader()
        reader.onloadend = function () {}
        reader.onload = () => {
          setImage(reader.result as any)
        }
        reader.readAsDataURL(files[0])
        props.onImageChange(e)
        e.target.value = ''
      }
    }
  }

  const getCropData = () => {
    handleHide()
    if (typeof cropper !== 'undefined') {
      props.setCrop(cropper.getCroppedCanvas().toDataURL())
      let url = cropper.getCroppedCanvas().toDataURL()
      handleUpload(url)
    } else {
    }
  }

  const dataUrlToFileUsingFetch = async (url: string, fileName: string, mimeType: string) => {
    let data: any = ''
    const response = await fetch(url)
    const buffer = await response.arrayBuffer()

    data = new File([buffer], fileName, {type: mimeType})
    return data
  }

  const handleUpload = async (url: string) => {
    dataUrlToFileUsingFetch(url, 'tes.png', 'image/png').then((res) => {
      props.getImage(res)
    })
  }

  return (
    <>
      <div>
        <div className='d-flex align-items-center file-group flex-column text-center'>
          <div className='position-relative form-file'>
            <div className={clsx('file-icon symbol symbol-100px', props.imageRequire)}>
              <span className='symbol-label bg-transparent'>
                <img
                  style={{width: '100%', objectFit: 'cover', objectPosition: 'center'}}
                  src={props.cropData ? props.cropData : '/media/icons/placeholder_img_crop.jpg'}
                  alt='cropped image'
                  className='h-100px w-100px rounded text-dark'
                />
              </span>
            </div>
            <input
              type='file'
              className='file-input opacity-0 position-absolute h-auto'
              placeholder='Type here...'
              id='group-icon-crop'
              onClick={() => setSizeValidation(false)}
              onChange={onChange}
              accept='image/*'
            />
            <div className='font-14 fw-500 mb-1 mt-3'>Add Customer Logo</div>
            <div className={clsx('font-13', props.imageSize)}>
              Max. File Size: 5MB & 500px × 500px
            </div>
          </div>
        </div>
        {props.imageRequireMessage && (
          <span className='font-13 text-danger'>This field is required.</span>
        )}
        {
          sizeValidation && <span className='font-13 text-danger' style={{fontWeight: "bolder"}}>Please select an image less than 5 MB</span>
        }
      </div>

      <Modal show={show} centered id='modal_img_crop'>
        <Modal.Header closeButton onClick={handleHide}>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cropper
            style={{height: '100%', width: '100%'}}
            zoomTo={0}
            aspectRatio={1 / 1}
            preview='.img-preview'
            src={image}
            viewMode={1}
            minCropBoxHeight={200}
            minCropBoxWidth={200}
            background={false}
            responsive={false}
            autoCropArea={1}
            scalable={false}
            // checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance)
            }}
            guides={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={getCropData}>
            {isLoadin}
          </button>
          <button className='btn btn-secondary' onClick={handleHide}>
            Cancel
          </button>
          {/* {
                        props.isBanner ? (
                            <button className="btn btn-primary" onClick={() => { }}>
                                Submit
                            </button>) : (
                            <button className="btn btn-primary" onClick={getCropData}>
                                Submit
                            </button>
                        )
                    } */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Imagecropper
